import { useQuery } from '@tanstack/react-query';

import { IProductAnnouncementReaction } from '../../interfaces';
import api from '../../services/api';

export const PRODUCT_ANNOUNCEMENT_REACTIONS_QUERY_KEY = 'product_announcement_reactions';

const getProductAnnouncementReactions = (
  productAnnouncementId: number
): Promise<{ product_announcement_reactions: IProductAnnouncementReaction[] }> =>
  api.get(`/api/frontend/product_announcements/${productAnnouncementId}/reactions`);

const useProductAnnouncementReactions = (productAnnouncementId: number) =>
  useQuery({
    queryKey: [PRODUCT_ANNOUNCEMENT_REACTIONS_QUERY_KEY, productAnnouncementId],
    queryFn: () => getProductAnnouncementReactions(productAnnouncementId),
    select: data => data.product_announcement_reactions
  });

export default useProductAnnouncementReactions;
