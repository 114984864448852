import { useMsal } from '@azure/msal-react';
import { Outlet } from 'react-router-dom';

import LoadingPage from '../components/LoadingPage';

const EntraRequiredAuth = ({ isAuthenticatedToBackend }: { isAuthenticatedToBackend: boolean }) => {
  const { inProgress } = useMsal();

  if (inProgress && !isAuthenticatedToBackend) {
    return <LoadingPage message="Signing in to Entra" />;
  }

  // Making sure that authInfo and featureFlags are present
  if (!isAuthenticatedToBackend) {
    return <LoadingPage message="Signing in to AMP" />;
  }

  return <Outlet />;
};

export default EntraRequiredAuth;
