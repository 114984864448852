import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { ShieldCheckIcon, TransactionIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, DISPLAY_DATE_FORMAT } from '../../../utils/formatter';

const Transaction = ({ transaction }: { transaction: PolicyTransaction }): JSX.Element => {
  const pickIconForTransaction = (transaction: PolicyTransaction) => {
    if (transaction.transaction_type === 'Policy change') {
      return <TransactionIcon />;
    }
    return <ShieldCheckIcon color={colors.statusGreen} />;
  };

  return (
    <FlexBox fitParentWidth>
      <FlexBox pr={spacings.px16}>{pickIconForTransaction(transaction)}</FlexBox>
      <FlexBox
        justifySpaceBetween
        customCss={css`
          flex: 1;
        `}
      >
        <Text>{transaction.transaction_type}</Text>
        <Text type="small" color={colors.grey60}>
          {dateFormatter(transaction.effective_date, DISPLAY_DATE_FORMAT)}
        </Text>
      </FlexBox>
    </FlexBox>
  );
};

export default Transaction;
