/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { DefinedQueryObserverResult } from '@tanstack/react-query';
import React from 'react';
import { Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';

import { Link } from '../../components/common';
import Button, { ButtonSize } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import AdminPolicyList from '../../components/PolicyList/AdminPolicyList';
import featureFlags from '../../constants/featureFlags';
import { useDNCTip, useDocumentTitle } from '../../hooks';
import { IPerson } from '../../interfaces';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { usePersonScheduledInteractions } from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { sizes, spacings } from '../../theme/variables';
import LeadTasks from '../Lead/LeadTasks';
import NotesView from '../NotesTab';
import ScheduleCall from '../ScheduleCall';
import { areAllCallsCompleted } from '../ScheduleCall/helpers';
import Indicator from '../ScheduleCall/Indicator';
import PersonExternalLinks from './_components/PersonExternalLinks';
import CustomerAssets from './CustomerAssets';
import CustomerDocuments from './CustomerDocuments';
import CustomerGeneral from './CustomerGeneral';
import DeprecatedCustomerGeneral from './CustomerGeneral/DeprecatedCustomerGeneral';
import CustomerLeads from './CustomerLeads';
import CustomerLoans from './CustomerLoans';
import CustomerNavigation from './CustomerNavigation';
import CustomerPolicies from './CustomerPolicies';
import CustomerPriorPolicies from './CustomerPriorPolicies';
import CustomerQuotes from './CustomerQuotes';
import CustomerRelatedPeople from './CustomerRelatedPeople';

const LoadedCustomer = ({ personQuery }: { personQuery: DefinedQueryObserverResult<IPerson, any> }) => {
  const person = personQuery.data;

  const { DNCTip, labelProps, doNotContact } = useDNCTip({ personGid: person.gid });
  const navigate = useNavigate();

  const { data: scheduledCalls, isFetching } = usePersonScheduledInteractions(person.gid, [
    ScheduledInteractionFlow.ScheduledCall,
    ScheduledInteractionFlow.ScheduledCallIsrs,
    ScheduledInteractionFlow.ScheduledCallV2,
    ScheduledInteractionFlow.ScheduledCallEmail,
    ScheduledInteractionFlow.Calendly,
    ScheduledInteractionFlow.ScheduledByRecapture,
    ScheduledInteractionFlow.CalendlyFollowUp
  ]);

  useDocumentTitle(person.name);

  const showCallButton = areAllCallsCompleted(scheduledCalls) && !isFetching;
  const isMaxWidthApplied = !useMatch('/people/:personGid/admin_policies/*');

  return (
    <Container fitParentWidth>
      <Container mb={spacings.px24} backgroundColor={colors.white} borderBottom>
        <FlexBox
          columnDirection
          maxWidth={sizes.containerMaxWidth}
          customCss={css`
            margin: auto;
          `}
        >
          <FlexBox
            justifySpaceBetween
            alignItemsCenter
            pr={spacings.px4}
            pl={spacings.px4}
            pt={spacings.px24}
            pb={spacings.px24}
            gap={spacings.px4}
          >
            <FlexBox>
              <Heading className="fs-mask">{`Customer ${person.first_name} ${person.last_name}`}</Heading>
              {authInfo.features.person_external_links_available && <PersonExternalLinks personGid={person.gid} />}
            </FlexBox>
            <FlexBox>
              {showCallButton && (
                <>
                  <Link className="schedule-call" to="schedule_call" {...labelProps}>
                    <Button disabled={!!doNotContact} size={ButtonSize.Small}>
                      Schedule call
                    </Button>
                    <DNCTip />
                  </Link>
                </>
              )}
              {person?.gid && (
                <Indicator
                  leadGid={undefined}
                  personGid={person.gid}
                  candidateGid={undefined}
                  containerCss={css`
                    margin-left: auto;
                  `}
                />
              )}
            </FlexBox>
          </FlexBox>
          <CustomerNavigation />
        </FlexBox>
      </Container>
      <FlexBox
        columnDirection
        maxWidth={isMaxWidthApplied ? sizes.containerMaxWidth : undefined}
        mt={spacings.px24}
        customCss={css`
          margin: auto;
        `}
      >
        <Routes>
          <Route
            path="general"
            element={
              featureFlags.hideSensitiveSataForPnc ? (
                <CustomerGeneral personQuery={personQuery} />
              ) : (
                <DeprecatedCustomerGeneral personQuery={personQuery} />
              )
            }
          />
          {!featureFlags.hideCustomerHouseholdMembersTab && (
            <Route path="household_members" element={<CustomerRelatedPeople person={person} />} />
          )}
          <Route path="leads" element={<CustomerLeads personGid={person.gid} />} />
          {!featureFlags.hideCustomerTasksTab && <Route path="tasks" element={<LeadTasks personGid={person.gid} />} />}
          {!featureFlags.hideCustomerAssetsTab && (
            <Route path="assets" element={<CustomerAssets personGid={person.gid} />} />
          )}
          {!featureFlags.hideCustomerLoansTab && (
            <Route path="loans" element={<CustomerLoans personGid={person.gid} />} />
          )}
          {!featureFlags.hideCustomerNotesTab && <Route path="notes" element={<NotesView personGid={person.gid} />} />}
          {!featureFlags.hideCustomerPriorPoliciesTab && (
            <Route path="prior_policies" element={<CustomerPriorPolicies personGid={person.gid} />} />
          )}
          <Route path="policies/*" element={<CustomerPolicies person={person} />} />
          {authInfo.features.extended_policy_list_view && (
            <Route path="admin_policies/*" element={<AdminPolicyList personGid={person.gid} />} />
          )}
          {!featureFlags.hideCustomerFilesTab && (
            <Route path="documents" element={<CustomerDocuments personGid={person.gid} leadId={undefined} />} />
          )}
          {authInfo.features.customer_profile_quoting && (
            <Route path="quotes" element={<CustomerQuotes personGid={person.gid} />} />
          )}
          <Route path="*" element={<Navigate to="general" replace />} />
          <Route
            path="schedule_call"
            element={
              <ScheduleCall
                personGid={person.gid}
                leadGid={undefined}
                candidateGid={undefined}
                cancelHandler={() => navigate(-1)}
                afterSubmitHandler={() => navigate(-1)}
              />
            }
          />
        </Routes>
      </FlexBox>
    </Container>
  );
};

export default LoadedCustomer;
