import * as React from 'react';

import cancelIcon from '../../assets/new_icons/close.svg';
import editIcon from '../../assets/new_icons/edit.svg';
import { DropDownMenu } from '../../components/common';
import Modal from '../../components/core/Modal';
import { IScheduledCall } from '../../interfaces/IScheduledCall';
import useCancelScheduledInteraction from '../../queries/scheduled_calls/useCancelScheduledInteraction';
import { useScheduledCallActions } from './helpers';
import ScheduleCall from './index';

interface IEditScheduledCall {
  scheduledCall: IScheduledCall;
  leadGid: string | undefined;
  candidateGid: string | undefined;
  refetch: () => Promise<any>;
  withActions?: boolean;
  disabled?: boolean;
}

const EditScheduledCall = ({
  scheduledCall,
  leadGid,
  candidateGid,
  refetch,
  withActions = true,
  disabled = false
}: IEditScheduledCall): JSX.Element => {
  const { editedScheduledCall, setCancelledScheduledCall, cancelledScheduledCall, setEditedScheduledCall } =
    useScheduledCallActions();

  const { mutate: cancelCall, isPending: cancellingCall } = useCancelScheduledInteraction(() => {
    setCancelledScheduledCall(null);
    refetch();
  });

  return (
    <>
      {withActions && (
        <DropDownMenu
          wrapperTestId="schedule-call-actions"
          right
          items={[
            {
              icon: editIcon,
              label: 'Edit',
              action: () => setEditedScheduledCall(scheduledCall),
              disabled
            },
            {
              icon: cancelIcon,
              label: 'Cancel',
              action: () => setCancelledScheduledCall(scheduledCall),
              disabled
            }
          ]}
        />
      )}
      {editedScheduledCall?.id === scheduledCall.id && (
        <ScheduleCall
          scheduledCall={editedScheduledCall}
          personGid={scheduledCall.person_gid}
          leadGid={leadGid}
          candidateGid={candidateGid}
          cancelHandler={() => setEditedScheduledCall(null)}
          afterSubmitHandler={() => {
            setEditedScheduledCall(null);
            refetch();
          }}
        />
      )}
      {cancelledScheduledCall?.id === scheduledCall.id && (
        <Modal
          title={
            (
              <>
                Cancel call with <span className="fs-mask">{scheduledCall.person_name}</span>?
              </>
            ) as any
          }
          confirmText="Cancel call"
          cancelText="Discard"
          cancelHandler={() => setCancelledScheduledCall(null)}
          confirmationInProgress={cancellingCall}
          confirmHandler={() => {
            cancelCall({
              personGid: scheduledCall.person_gid,
              contactFlowId: cancelledScheduledCall.id,
              dialerActionId: cancelledScheduledCall.action_type ? cancelledScheduledCall.id : undefined
            });
          }}
        >
          You are going to cancel the call. It means that the customer will be excluded from the call cadence.
        </Modal>
      )}
    </>
  );
};

export default EditScheduledCall;
