import React from 'react';
import { useNavigate } from 'react-router-dom';

import Button, { ButtonSize } from '../../../../components/core/buttons/Button';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import ICallLog from '../../../../interfaces/ICallLog';
import { useLinkToOngoingCall } from '../../../../queries/call_logs/useCallLog';
import guidedSellingExperienceNavigation from '../../navigation';

const ConnectToCall = (): JSX.Element | null => {
  const navigate = useNavigate();
  const { callLogId, personGid, candidateGid, leadGid, refetchCallLog } = useGuidedSellingExperienceContext();

  const replaceLocation = ({ call_log }: { call_log: ICallLog }) => {
    const newUrl = guidedSellingExperienceNavigation.forCall({ call_log_id: call_log.id });

    refetchCallLog().then(() => navigate(newUrl, { replace: true }));
  };

  const { mutate: updateCallLog, isPending } = useLinkToOngoingCall(replaceLocation);

  return callLogId ? (
    <Button
      loading={isPending}
      size={ButtonSize.Small}
      onClick={() =>
        updateCallLog({
          person_gid: personGid,
          candidate_gid: candidateGid,
          lead_gid: leadGid
        })
      }
    >
      Link to the active call
    </Button>
  ) : null;
};

export default ConnectToCall;
