import { useQuery } from '@tanstack/react-query';

import { IUserRole } from '../../interfaces';
import api from '../../services/api';

const useUsersRoles = () =>
  useQuery({
    queryKey: ['users_roles'],
    queryFn: (): Promise<{ users_roles: IUserRole[] }> => api.get('/api/frontend/users_roles'),
    select: data => data.users_roles,
    staleTime: Infinity
  });

export default useUsersRoles;
