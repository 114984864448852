export interface ISourceDimensions {
  partner?: PartnerKeys | string;
  sub_partner?: PartnerKeys | string;
  brand?: PartnerKeys | string;
  lead_type?: LeadTypes;
  business_type?: BusinessTypes;
  source_product?: SourceProducts;
  marketing_opportunity?: MarketingOpportunities;
  engagement_experience_type?: EngagementExperienceTypes;
  engagement_experience_id?: string;
  attributed_marketing_medium?: AttributedMarketingMediums;
  attributed_marketing_id?: string;
  lead_capture_method?: LeadCaptureMethods;
  primary_opportunity?: PrimaryOpportunities;
  marketing_product?: MarketingProducts;
  dnis?: string;
}

export enum PartnerKeys {
  Freedom = 'freedom',
  Roundpoint = 'roundpoint',
  NAF = 'new_american_funding',
  Valon = 'valon'
}

export enum LeadCaptureMethods {
  Sms = 'sms',
  Api = 'api',
  Email = 'email',
  Chat = 'chat',
  LandingPage = 'landing_page',
  OutboundCall = 'outbound_call',
  InboundCall = 'inbound_call',
  WarmTransfer = 'warm_transfer',
  ColdTransfer = 'cold_transfer',
  Unknown = 'unknown'
}

export const isDataLead = (lcm?: LeadCaptureMethods) => {
  if (!lcm) {
    return false;
  }

  return ![
    LeadCaptureMethods.InboundCall,
    LeadCaptureMethods.OutboundCall,
    LeadCaptureMethods.WarmTransfer,
    LeadCaptureMethods.ColdTransfer
  ].includes(lcm);
};

export enum BusinessTypes {
  AutoServicing = 'auto_servicing',
  HomeOrigination = 'home_origination',
  AutoOrigination = 'auto_origination',
  Paid = 'paid',
  Direct = 'direct',
  RealEstate = 'real_estate',
  ConsumerServices = 'consumer_services',
  Banking = 'banking',
  AutoDealerships = 'auto_dealerships',
  AutoWarranty = 'auto_warranty',
  HomeServicing = 'home_servicing',
  HomeBuilder = 'home_builder',
  Unknown = 'unknown'
}

export enum SourceProducts {
  Api = 'api',
  LoTools = 'lo_tools',
  Servicing = 'servicing',
  AgentManual = 'agent_manual',
  DirectSales = 'direct_sales',
  CustomerPortal = 'customer_portal',
  MortgageBridgeUnknown = 'mortgage_bridge_unknown',
  MortgageBridgeEncompass = 'mortgage_bridge_encompass',
  MortgageBridgePcLender = 'mortgage_bridge_pc_lender',
  MortgageBridgeLendingQb = 'mortgage_bridge_lending_qb',
  MortgageBridgeEncompassListener = 'mortgage_bridge_encompass_listener'
}

enum MarketingProducts {
  Pet = 'pet',
  Home = 'home',
  Auto = 'auto',
  Life = 'life',
  CoreBundle = 'core_bundle',
  Travel = 'travel',
  Renters = 'renters',
  Fire = 'fire'
}

enum EngagementExperienceTypes {
  Savings = 'savings',
  Unknown = 'unknown',
  LoTools = 'lo_tools',
  AllFunnel = 'all_funnel',
  MaticHome = 'matic_home',
  PartnerUi = 'partner_ui',
  RateReview = 'rate_review',
  DirectSales = 'direct_sales',
  CustomerLookup = 'customer_lookup',
  MortgageBridge = 'mortgage_bridge',
  AgentConversation = 'agent_conversation',
  Pathfinder = 'pathfinder'
}
export enum LeadTypes {
  WinBack = 'win_back',
  CrossSell = 'cross_sell',
  NewBusiness = 'new_business',
  UnknownEngagement = 'unknown_engagement'
}

export enum AttributedMarketingMediums {
  Sms = 'sms',
  Seo = 'seo',
  Email = 'email',
  Unknown = 'unknown',
  DirectMail = 'direct_mail',
  Telemarketing = 'telemarketing',
  PaidBannerAd = 'paid_banner_ad',
  PartnerBannerAd = 'partner_banner_ad',
  CallCenterPitch = 'call_center_pitch',
  PostLoginBanner = 'post_login_banner',
  Banner = 'banner'
}

export enum MarketingOpportunities {
  Bundle = 'bundle',
  Unknown = 'unknown',
  Referral = 'referral',
  CrossSell = 'cross_sell',
  RateReview = 'rate_review',
  LoanPurchase = 'loan_purchase',
  PolicyRenewal = 'policy_renewal',
  LoanRefinance = 'loan_refinance',
  EscrowAnalysis = 'escrow_analysis',
  PolicyExpiration = 'policy_expiration',
  ServicerWelcome = 'servicer_welcome',
  EmployeeOffer = 'employee_offer',
  AutoPurchase = 'auto_purchase',
  AutoService = 'auto_service',
  WarrantyExpiration = 'warranty_expiration',
  AdHocCampaign = 'ad_hoc_campaign',
  PostRenewalPremiumIncrease = 'post_renewal_premium_increase',
  ServicerPayoff = 'servicer_payoff',
  EscrowShortage = 'escrow_shortage',
  HomePurchase = 'home_purchase',
  LenderPlaced = 'lender_placed',
  AutoPointOfSale = 'auto_point_of_sale',
  AutoRefinance = 'auto_refinance'
}

export enum PrimaryOpportunities {
  Pet = 'pet',
  Home = 'home',
  Auto = 'auto',
  Life = 'life',
  CoreBundle = 'core_bundle',
  Travel = 'travel',
  Renters = 'renters',
  Fire = 'fire'
}
