/** @jsxImportSource @emotion/react */
import './index.scss';

import { css } from '@emotion/react';

import { EmptyResults } from '../../../components/common';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import useLeadActivities from '../../../queries/leads/activities/useLeadActivities';
import analytics from '../../../services/analytics';
import { dateTimeFormatter } from '../../../utils/formatter';

/* eslint-disable react/no-array-index-key -- static data */
const LeadActivity: React.FC<{ leadId: string }> = ({ leadId }) => {
  const { data, isFetching, refetch } = useLeadActivities(leadId);
  const onRefresh = () => {
    refetch();
    analytics.track('Lead Activity Refresh Clicked', { lead_id: leadId });
  };

  return (
    <>
      <Button
        data-testid="qa-refresh-button"
        size={ButtonSize.Small}
        css={css`
          align-self: flex-end;
          margin-bottom: 10px;
        `}
        onClick={onRefresh}
      >
        {isFetching ? 'Loading...' : 'Refresh'}
      </Button>
      {data?.length ? (
        <table
          className="table"
          css={css`
            opacity: ${isFetching ? 0.5 : 1};
          `}
        >
          <thead>
            <tr>
              <th>Author</th>
              <th>Event</th>
              <th>Ip address</th>
              <th>Description</th>
              <th>Date time</th>
            </tr>
          </thead>
          <tbody>
            {data.map(activity => (
              <tr key={activity.id} className="activity">
                <td>{activity.author_name}</td>
                <td>
                  {activity.item_type}.{activity.event}
                </td>
                <td>{activity.remote_ip}</td>
                <td className="fs-mask">
                  {activity.native
                    ? activity.changes &&
                      activity.changes.map((item, index) => (
                        <div key={`${item.diff}-${index}`} className={item.diff}>
                          {item.content}
                        </div>
                      ))
                    : activity.details &&
                      Object.keys(activity.details).map((key, index) => (
                        <div key={`${key}-${index}`}>
                          {key}:{activity.details![key]}
                        </div>
                      ))}
                </td>
                <td>{dateTimeFormatter(activity.created_at)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <EmptyResults message="No activity log yet" />
      )}
    </>
  );
};
/* eslint-enable react/no-array-index-key */

export default LeadActivity;
