/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { ErrorCol } from '../../../components/common';
import { DatePicker, MultiSelect, Select } from '../../../components/common/FormikComponents/FormikComponents';
import { CallStatus } from '../../../interfaces/IScheduledCall';
import { UserStatus } from '../../../interfaces/IUser';
import useAssignees from '../../../queries/assignees/useAssignees';
import { Filter as CallFilter } from '../../../queries/scheduled_calls/useScheduledInteractions';
import { spacings } from '../../../theme/variables';
import { isDateRangeValid, OLD_DATE, TO_IS_GREATER_THAN_FROM } from '../../../utils/dateRangeHelpers';
import { showFieldErrorGenerator } from '../../../utils/formHelpers';

interface FilterProps {
  onFilterSubmit: ({ assignees_emails, min_run_at, max_run_at, statuses, page }: CallFilter) => void;
  onFilterReset: () => void;
  initialValues: CallFilter;
}

const justifyEndCss = css`
  display: flex;
  justify-content: flex-end;
`;

const Filter = ({ onFilterSubmit, initialValues, onFilterReset }: FilterProps): JSX.Element => {
  const { data: agents } = useAssignees({ statuses: [UserStatus.Active, UserStatus.Suspended] });

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={values => onFilterSubmit({ ...values, page: 1 })}
      validationSchema={yup.object().shape({
        assignees_emails: yup.array().ensure().min(1, 'Select at least one assignee'),
        min_run_at: yup
          .date()
          .min(OLD_DATE, 'Only dates from 1990 are supported')
          .max(moment().add('3', 'months').toDate(), "Date can't be later than 3 month from today"),
        max_run_at: yup
          .date()
          .min(moment().subtract('1', 'days').toDate(), "Date can't be earlier than today")
          .max(moment().add('3', 'months').toDate(), "Date can't be later than 3 month from today")
      })}
    >
      {({ isValid, errors, resetForm, values, touched }) => {
        const showFieldError = showFieldErrorGenerator({ values, touched, isValid });

        return (
          <Form
            role="form"
            autoComplete="off"
            css={css`
              padding-top: ${spacings.px24}px;
            `}
          >
            <Row>
              <ErrorCol sm="4" error={errors.assignees_emails as string} when>
                <Label htmlFor="assignees_emails">Call assignees</Label>
                <Field
                  data-testid="huesos"
                  inputId="assignees_emails"
                  name="assignees_emails"
                  component={MultiSelect}
                  options={agents}
                  valueName="email"
                  labelName="name"
                  ordered
                />
              </ErrorCol>

              <Col sm={{ size: 2, offset: 2 }}>
                <Label htmlFor="completed">Completed</Label>
                <Field
                  inputId="completed"
                  data-testid="completed-select"
                  name="statuses"
                  component={Select}
                  options={[
                    { key: [CallStatus.ContactMade, CallStatus.CallStarted], value: 'Completed' },
                    {
                      key: [CallStatus.ContactFailed, CallStatus.InDialer, CallStatus.Ready, CallStatus.Scheduled],
                      value: 'Incomplete'
                    },
                    {
                      key: [CallStatus.Cancelled],
                      value: 'Cancelled'
                    }
                  ]}
                  showResetButton
                />
              </Col>

              <ErrorCol sm="2" when={showFieldError('min_run_at')} error={errors.min_run_at as string}>
                <Label htmlFor="min_run_at">From</Label>
                <Field
                  component={DatePicker}
                  maxDate={moment().add('3', 'months').toDate()}
                  id="min_run_at"
                  name="min_run_at"
                />
              </ErrorCol>
              <ErrorCol
                sm="2"
                warning={
                  !errors.max_run_at &&
                  !isDateRangeValid({ from: values.min_run_at, to: values.max_run_at }) &&
                  TO_IS_GREATER_THAN_FROM
                }
                when={showFieldError('max_run_at')}
                error={errors.max_run_at as string}
              >
                <Label htmlFor="max_run_at">To</Label>
                <Field
                  component={DatePicker}
                  minDate={moment().toDate()}
                  maxDate={moment().add('3', 'months').toDate()}
                  id="max_run_at"
                  name="max_run_at"
                />
              </ErrorCol>
            </Row>
            <Row>
              <Col css={justifyEndCss}>
                <Button
                  color="warning"
                  onClick={() => {
                    resetForm();
                    onFilterReset();
                  }}
                >
                  Reset to default
                </Button>
                <Button color="primary" type="submit" role="submit" disabled={!isValid}>
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Filter;
