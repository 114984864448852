import './Modal.scss';

import classNames from 'classnames';
import React from 'react';
import { Button, Modal as SModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import loader from '../../../assets/img/loader.gif';

const Modal = ({
  open = false,
  size = 'lg',
  className = '',
  confirmHandler = null,
  cancelHandler = null,
  confirmationInProgress = false,
  title = '',
  message = '',
  bodyClassNames = '',
  showCancelButton = true,
  cancelText = 'Cancel',
  color = 'primary',
  isSubmitting = false,
  confirmText = 'OK',
  children,
  backdrop = false,
  ...rest
}: any) => {
  const cancelClick = confirmationInProgress ? () => {} : cancelHandler;
  const confirmClick = confirmationInProgress ? () => {} : confirmHandler;

  return (
    <SModal
      {...rest}
      backdrop={backdrop}
      isOpen={open}
      toggle={cancelClick}
      size={size}
      className={classNames(className, 'confirmation-modal', {
        'confirmation-in-progress': confirmationInProgress
      })}
    >
      <ModalHeader tag="h6" toggle={cancelClick}>
        {title}
      </ModalHeader>
      <ModalBody className={bodyClassNames}>
        {message}
        {children}
      </ModalBody>
      <ModalFooter>
        {showCancelButton && cancelHandler && (
          <Button className="cancellation-button" onClick={cancelClick}>
            {cancelText}
          </Button>
        )}
        {confirmHandler && (
          <Button className="confirmation-button" onClick={confirmClick} color={color} disabled={isSubmitting}>
            <span className="confirmation-button-label">{confirmText}</span>
            <div className="confirmation-loader-image">
              <img src={loader} alt="loading" />
            </div>
          </Button>
        )}
      </ModalFooter>
    </SModal>
  );
};

export default Modal;
