import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import BaseForm from '../../../../../components/core/forms/BaseForm';
import { QUESTION_VERIFICATION_STATUS_TOOLTIP } from '../../../../../components/core/QuestionVerificationStatusIcon';
import featureFlags from '../../../../../constants/featureFlags';
import { IHome, IOpportunity, IUIFlow } from '../../../../../interfaces';
import { IQuestion } from '../../../../../interfaces/IWorkflow';
import { useReconcileOpportunities } from '../../../../../queries/leads/data_collection/useDataCollectionOpportunities';
// eslint-disable-next-line max-len
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../../../../queries/people/person_opportunities/usePersonOpportunities';
import { AGGREGATED_QUOTES_QUERY_KEY } from '../../../../../queries/people/person_quotes/useAggregatedQuotes';
import {
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
  HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3,
  useSaveAnswers
} from '../../../../../queries/workflows/useUiFlow';
import { InputSize, LabelSize, spacings } from '../../../../../theme/variables';
import { formInputLabelStyle } from '../_components/input.styles';
import HomeFormTitle from './HomeFormTitle';

interface HomeEligibilityFormProps {
  personGid: string;
  engagementGid: string | undefined;
  leadId: number | undefined;
  homeOpportunity: IOpportunity;
  homeEligibilityUIFlow: IUIFlow;

  onClose: () => void;
  onSubmit: () => void;
}

const HomeEligibilityForm = ({
  personGid,
  engagementGid,
  leadId,
  homeOpportunity,
  homeEligibilityUIFlow,
  onClose,
  onSubmit
}: HomeEligibilityFormProps) => {
  const home = homeOpportunity.assets![0] as IHome;

  const { mutateAsync: saveAnswers } = useSaveAnswers();
  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();

  const queryClient = useQueryClient();

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <BaseForm
      pt={spacings.px0}
      pr={spacings.px0}
      pb={spacings.px0}
      pl={spacings.px0}
      customControls
      initialValues={{
        [home.gid]: homeEligibilityUIFlow.initialValues()
      }}
      onSubmit={async values => {
        const homeValues = values[home.gid]!;

        await saveAnswers({
          uiFlowKey: featureFlags.homeEligibilityPropertyUnderMaintenance
            ? HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3
            : HOME_UNDERWRITING_FLOW_SIDEBAR_FORM,
          answers: homeEligibilityUIFlow.answersForFormValues({
            formValues: homeValues,
            personGid,
            engagementGid,
            assetGid: home.gid
          })
        });

        leadId && (await reconcileOpportunities({ leadId, assetsGids: [home.gid] }));

        queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });
        queryClient.invalidateQueries({ queryKey: [AGGREGATED_QUOTES_QUERY_KEY] });

        onSubmit();
      }}
      renderForm={({ values, submitForm, isSubmitting }) => {
        return (
          <FlexBox gap={spacings.px12} columnDirection>
            <HomeFormTitle
              personGid={personGid}
              homeOpportunity={homeOpportunity}
              home={home}
              isSubmitting={isSubmitting}
              onClose={onClose}
              submitForm={submitForm}
            />

            <Container>
              {homeEligibilityUIFlow.containers
                .flatMap(({ elements }) => elements)
                .filter(({ kind }) => kind === 'question')
                .map(element => {
                  const question = element.content as IQuestion;

                  const input = homeEligibilityUIFlow.inputForQuestion({
                    element,
                    formValues: values[home.gid]!,
                    propsProvider: () => ({
                      inline: true,
                      key: `${home.gid}.${question.key}`,
                      id: `${home.gid}.${question.key}`,
                      label: question.alt_label || question.label,
                      labelSize: LabelSize.Small,
                      inputSize: InputSize.Medium,
                      description: undefined,
                      testId: `${home.gid}-${question.key}`,
                      customLabelCss: formInputLabelStyle,
                      verificationStatus: question.verification_status
                    })
                  });

                  return input || <React.Fragment key={element.key} />;
                })}
            </Container>
            <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
          </FlexBox>
        );
      }}
    />
  );
};

export default HomeEligibilityForm;
