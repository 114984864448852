import React, { useCallback } from 'react';
// components
import { Label, Row } from 'reactstrap';

import ButtonWithoutBorder from '../../../../../core/buttons/ButtonWithoutBorder';
// constants
import { ACCIDENTS_TYPE, CLAIMS_TYPE, VIOLATIONS_TYPE } from '../../constants';
import AccidentFieldWithOptions from '../AccidentFieldWithOptions';
import ClaimFieldWithOptions from '../ClaimFieldWithOptions';
import IncidentTypeRadio from '../IncidentTypeRadio';
import ViolationWithOptions from '../ViolationWithOptions';

const Incident = ({
  showFieldError,
  formValuesName,
  radioButtonsId,
  formValue,
  incidentSelectId,
  index,
  errors,
  replace
}: any) => {
  const onDelete = useCallback(() => {
    replace(index, { ...formValue, deleted: true });
  }, [replace, index, formValue]);

  const incidentProps = {
    showFieldError,
    labelId: incidentSelectId,
    name: formValuesName,
    index,
    formValue,
    errors,
    replace
  };

  return (
    <div className="incidents__item">
      <div className="d-flex align-items-start mt-2">
        <div className="flex-grow-1 flex-shrink-1">
          <Label id={radioButtonsId}>Choose the type *</Label>
          <div role="group" aria-labelledby={radioButtonsId} className="radio-buttons-container">
            <IncidentTypeRadio formValue={formValue} name={formValuesName} formIndex={index} type={ACCIDENTS_TYPE} />
            <IncidentTypeRadio formValue={formValue} name={formValuesName} formIndex={index} type={VIOLATIONS_TYPE} />
            <IncidentTypeRadio formValue={formValue} name={formValuesName} formIndex={index} type={CLAIMS_TYPE} />
          </div>
        </div>
        <div className="flex-grow-0 flex-shrink-0">
          <ButtonWithoutBorder onClick={onDelete}>
            <i className="incidents__close-icon" />
          </ButtonWithoutBorder>
        </div>
      </div>
      <Row className="mt-3">
        {formValue.type === CLAIMS_TYPE && <ClaimFieldWithOptions {...incidentProps} />}
        {formValue.type === ACCIDENTS_TYPE && <AccidentFieldWithOptions {...incidentProps} />}
        {formValue.type === VIOLATIONS_TYPE && <ViolationWithOptions {...incidentProps} />}
      </Row>
    </div>
  );
};

export default Incident;
