/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import QuestionVerificationStatusIcon from '../../../../../components/core/QuestionVerificationStatusIcon';
import Text from '../../../../../components/core/Text';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
// eslint-disable-next-line max-len
import { useAdditionalPersonVehicleAnswers } from '../../../../GuidedDataCollection/VehicleProfilePage/_hooks/useVehicleProfileAnswersWithVerificationAnswers';
import CopyableAnswer from '../_components/CopyableAnswer';
import QuestionAnswerPair from '../_components/QuestionAnswerPair';

const AdditionalAnswers = ({ personGid }: { personGid: string }) => {
  const additionalPersonAnswers = useAdditionalPersonVehicleAnswers({
    personGid
  });

  const violationAnswer = additionalPersonAnswers[personGid]?.[DatapointKey.PersonHadViolationIn5PastYears];
  const vehiclesStatusAnswer =
    additionalPersonAnswers[personGid]?.[DatapointKey.PersonVehiclesTitledAndRegisteredToDrivers];

  if (!(violationAnswer && vehiclesStatusAnswer)) {
    return null;
  }

  return (
    <FlexBox columnDirection gap={spacings.px12}>
      <Paragraph bold>Additional questions</Paragraph>
      <QuestionAnswerPair
        questionLabel={
          <Text type="small" color={colors.grey60}>
            Tickets, accidents, violations in 5 years
          </Text>
        }
        answerLabel={
          <>
            <QuestionVerificationStatusIcon verificationStatus={violationAnswer.verificationStatus} />
            <CopyableAnswer
              customCss={css`
                text-transform: capitalize;
              `}
              textType="small"
              testId="collect-info-tab-vehicle-profile-results-violations-answer"
              value={(violationAnswer.value || '-') as string}
            />
          </>
        }
      />
      <QuestionAnswerPair
        questionLabel={
          <Text type="small" color={colors.grey60}>
            Vehicles titled and registered to drivers
          </Text>
        }
        answerLabel={
          <>
            <QuestionVerificationStatusIcon verificationStatus={vehiclesStatusAnswer.verificationStatus} />
            <CopyableAnswer
              customCss={css`
                text-transform: capitalize;
              `}
              textType="small"
              testId="collect-info-tab-vehicle-profile-results-registered-to-drivers-answer"
              value={(vehiclesStatusAnswer.value || '-') as string}
            />
          </>
        }
      />
    </FlexBox>
  );
};

export default AdditionalAnswers;
