import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import api from '../../services/api';

export const getPersonAmsId = (personGid: string): Promise<{ ams_id: string | null }> =>
  api.get(`/api/frontend/people/${personGid}/ams_id`);

const usePersonAmsId = (
  personGid: string | undefined,
  options?: UseQueryOptions<{ ams_id: string | null }, any, any, any>
) =>
  useQuery({
    queryKey: ['person_id', personGid],
    queryFn: personGid ? () => getPersonAmsId(personGid!) : skipToken,
    ...options,
    select: data => data.ams_id
  });

export default usePersonAmsId;
