import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { PolicyType } from '../../interfaces/IPolicyType';
import api from '../../services/api';

export enum LineOfBusinessResourceType {
  Auto = 'auto',
  Home = 'home',
  Umbrella = 'umbrella'
}

export interface LineOfBusiness {
  id: number;
  line_of_business_id: string;
  acord_type: string;
  acord_label: string;
  policy_type?: PolicyType;
  resource_type?: LineOfBusinessResourceType;
}

const usePolicyLinesOfBusiness = (
  policyId?: string | number,
  options?: UseQueryOptions<{ lines_of_business: LineOfBusiness[] }, any, any, any>
) =>
  useQuery({
    queryKey: ['lines_of_business', policyId],
    queryFn: policyId
      ? (): Promise<{ lines_of_business: LineOfBusiness[] }> =>
          api.get(`/api/frontend/policies/${policyId}/lines_of_business`)
      : skipToken,
    ...options,
    select: data => data.lines_of_business
  });

export default usePolicyLinesOfBusiness;
