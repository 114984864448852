import * as React from 'react';

import Container, { IContainer } from '../../../../components/core/Container';
import { spacings } from '../../../../theme/variables';

const VerticalTabContent = ({ children, ...rest }: IContainer) => {
  return (
    <Container ph={spacings.px12} pb={spacings.px12} {...rest}>
      {children}
    </Container>
  );
};

export default VerticalTabContent;
