import moment from 'moment';
import * as React from 'react';
import { toast } from 'react-toastify';

import useQueryParams from '../../hooks/useQueryParams';
import { IDashboardTask } from '../../interfaces';
import useTaskReminders from '../../queries/tasks/useTaskReminders';
import { Link } from '../common';
import alert from '../core/Alert';

export const ReminderBody = ({ task }: { task: IDashboardTask }): JSX.Element => {
  return (
    <>
      <Link
        to={{ pathname: `/leads/${task.lead_id}/summary`, search: `?task_id=${task.id}` }}
        target="_blank"
        onClick={() => toast.dismiss(task.id)}
      >
        <div className="fs-mask">{task.customer_name}</div>
      </Link>
      {`${task.description}: is scheduled for ${moment(task.due_time).format('hh:mm A')}`}
    </>
  );
};

const TaskReminders = () => {
  const scheduledReminders = React.useRef<number[]>([]);
  const queryParams = useQueryParams();
  const queryParamsRef = React.useRef<URLSearchParams>();
  queryParamsRef.current = queryParams;

  const { data: tasks } = useTaskReminders();

  React.useEffect(() => {
    const showReminder = (task: IDashboardTask, delay: number): void => {
      if (queryParamsRef.current?.get('task_id') === task.id.toString()) {
        return;
      }

      if (moment(task.due_time).diff(moment(), 'milliseconds') > 0) {
        const timerId = window.setTimeout(
          () => {
            alert({
              message: <ReminderBody task={task} />,
              title: `You have a scheduled ${task.kind}`,
              options: {
                toastId: task.id
              }
            }).info();
          },
          moment(task.due_time).subtract(delay, 'minutes').diff(moment(), 'milliseconds')
        );

        scheduledReminders.current.push(timerId);
      }
    };

    scheduledReminders.current.forEach(timerId => window.clearTimeout(timerId));
    (tasks || []).forEach(task => showReminder(task, 10));
  }, [tasks]);

  return null;
};

export default TaskReminders;
