/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import NewTooltip from '../../../components/common/Tooltip/NewTooltip';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { InfoIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { Coverage } from '../../../interfaces/ICoverage';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import ContentSection from '../ContentSection';

const DiscountsSection = ({ discounts }: { discounts: Coverage[] }): JSX.Element => {
  return (
    <ContentSection testId="discounts-section">
      <Container
        backgroundColor={colors.grey5}
        p={spacings.px4}
        css={css`
          border-bottom: 2px solid ${colors.grey10};
        `}
      >
        {discounts.map(discount => (
          <FlexBox key={discount.acord_code} justifySpaceBetween data-testid={`discount-${discount.acord_code}`}>
            <FlexBox alignItemsCenter>
              <Text type="large">{discount.acord_code}</Text>
              {discount.acord_description && (
                <span
                  css={css`
                    margin-left: 4px;
                  `}
                  data-tip={discount.acord_description}
                  data-for="discount-description-tooltip"
                >
                  <InfoIcon />
                </span>
              )}
            </FlexBox>
            <Text type="large" color={colors.statusGreen}>
              {discount.limit_description || 'Applied'}
            </Text>
          </FlexBox>
        ))}
      </Container>
      <NewTooltip id="discount-description-tooltip" />
    </ContentSection>
  );
};

export default DiscountsSection;
