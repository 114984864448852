import classNames from 'classnames';
import debounce from 'debounce-promise';
import PropTypes from 'prop-types';
import React from 'react';
import { components } from 'react-select';
import AsyncSimpleSelect from 'react-select/async';

import { dig, except } from '../../../utils/object';

const Control = (props: any) => (
  <components.Control {...props} className={classNames(props.className, 'form-control')} />
);

const SELECT_CLASS_NAME = 'react-select';

class AsyncSelect extends React.Component<any, any> {
  state = {
    selectOptions: []
  };

  onValueChange = (item = {}) => {
    const { name } = this.props;
    const value = dig(item, 'value');

    this.props.onChange(name ? { target: { name, value } } : value);
  };

  loadOptions = debounce(async term => {
    if (!term) {
      return;
    }

    const data = await this.props.asyncAction(term);
    const normalizedOptions = this.props.optionsNormalizer(data);

    this.setState({ selectOptions: normalizedOptions });

    return normalizedOptions;
  }, 500);

  render() {
    const { className, value, disabled, ...rest } = this.props;

    const restProps = except(
      rest,
      'value',
      'onChange',
      'loadOptions',
      'className',
      'classNamePrefix',
      'components',
      'onInputChange'
    );

    const pickedValue = this.state.selectOptions.find((option: any) => option.value === value);

    return (
      <AsyncSimpleSelect
        value={pickedValue}
        onChange={this.onValueChange}
        loadOptions={this.loadOptions}
        className={classNames(className, SELECT_CLASS_NAME)}
        classNamePrefix={SELECT_CLASS_NAME}
        components={{ Control, IndicatorSeparator: null }}
        isClearable
        defaultOptions
        isDisabled={disabled}
        {...restProps}
      />
    );
  }
}

// @ts-expect-error old code
AsyncSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.object]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  showResetButton: PropTypes.bool,
  className: PropTypes.string,
  asyncAction: PropTypes.func.isRequired,
  optionsNormalizer: PropTypes.func.isRequired
};

// @ts-expect-error old code
AsyncSelect.defaultProps = {
  name: '',
  value: '',
  placeholder: 'Please select an option',
  disabled: false,
  showResetButton: false,
  className: null
};

export default AsyncSelect;
