/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Container from '../../../components/core/Container';
import { IProductAnnouncementReactionType } from '../../../interfaces/IProductAnnouncementReaction';
// eslint-disable-next-line max-len
import useCreateProductAnnouncementReaction from '../../../queries/product_announcements/useCreateProductAnnouncementReaction';
// eslint-disable-next-line max-len
import useDeleteProductAnnouncementReaction from '../../../queries/product_announcements/useDeleteProductAnnouncementReaction';
import useProductAnnouncementReactions from '../../../queries/product_announcements/useProductAnnouncementReactions';
import colors from '../../../theme/colors';
import ReactionButton from './ReactionButton';

interface ProductAnnouncementReactionsProps {
  productAnnouncementId: number;
}

const ProductAnnouncementReactions = ({ productAnnouncementId }: ProductAnnouncementReactionsProps) => {
  const { data: reactions, isPending: isLoadingReactions } = useProductAnnouncementReactions(productAnnouncementId);
  const { mutateAsync: createReaction, isPending: isCreatingReaction } = useCreateProductAnnouncementReaction();
  const { mutateAsync: deleteReaction, isPending: isDeletingReaction } = useDeleteProductAnnouncementReaction();

  if (isLoadingReactions) {
    return (
      <SkeletonTheme baseColor={colors.grey5} highlightColor={colors.grey10}>
        <Container
          customCss={css`
            width: 124px;
            height: 28px;
          `}
        >
          <Skeleton style={{ height: '100%' }} />
        </Container>
      </SkeletonTheme>
    );
  }

  const positiveReactions =
    reactions?.filter(({ reaction_type }) => reaction_type === IProductAnnouncementReactionType.Positive) || [];
  const negativeReactions =
    reactions?.filter(({ reaction_type }) => reaction_type === IProductAnnouncementReactionType.Negative) || [];
  const neutralReactions =
    reactions?.filter(({ reaction_type }) => reaction_type === IProductAnnouncementReactionType.Neutral) || [];

  const isUpdating = isCreatingReaction || isDeletingReaction;

  return (
    <>
      <ReactionButton
        emoji="🔥"
        reactions={positiveReactions}
        disabled={isUpdating}
        onCreateReaction={() =>
          createReaction({ productAnnouncementId, reactionType: IProductAnnouncementReactionType.Positive })
        }
        onDeleteReaction={() =>
          deleteReaction({ productAnnouncementId, reactionType: IProductAnnouncementReactionType.Positive })
        }
      />
      <ReactionButton
        emoji="😿"
        reactions={negativeReactions}
        disabled={isUpdating}
        onCreateReaction={() =>
          createReaction({ productAnnouncementId, reactionType: IProductAnnouncementReactionType.Negative })
        }
        onDeleteReaction={() =>
          deleteReaction({ productAnnouncementId, reactionType: IProductAnnouncementReactionType.Negative })
        }
      />
      <ReactionButton
        emoji="😐"
        reactions={neutralReactions}
        disabled={isUpdating}
        onCreateReaction={() =>
          createReaction({ productAnnouncementId, reactionType: IProductAnnouncementReactionType.Neutral })
        }
        onDeleteReaction={() =>
          deleteReaction({ productAnnouncementId, reactionType: IProductAnnouncementReactionType.Neutral })
        }
      />
    </>
  );
};

export default ProductAnnouncementReactions;
