/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

import featureFlags from '../../../constants/featureFlags';
import usePersonOnlineSessions from '../../../queries/people/person_online_sessions/usePersonOnlineSessions';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';

export const navigationLinkCss = css`
  border-bottom: 4px solid transparent !important;
  border-left: 0 !important;
  border-right: 0 !important;
  border-top: 0 !important;
  font-size: 16px;
  padding-left: 20px;
  padding-right: 20px;

  &.active {
    border-bottom: 4px solid ${colors.azure50} !important;
  }
`;

const LeadNavigation = ({ personGid, leadGid }: { personGid: string; leadGid: string }) => {
  const { data: customerOnlineSessions } = usePersonOnlineSessions({ personGid, engagementGid: leadGid });

  return (
    <Nav
      tabs
      data-testid="navigation-tabs"
      css={css`
        border-bottom: 0;
        width: 100%;
      `}
    >
      <NavItem>
        <NavLink to="summary" tag={RouterLink} css={navigationLinkCss} data-testid="summary_tab">
          Summary
        </NavLink>
      </NavItem>
      {!!customerOnlineSessions?.length && (
        <NavItem>
          <NavLink to="person_online_sessions" tag={RouterLink} css={navigationLinkCss} data-testid="online_bind_tab">
            Online Bind
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideLeadTasksTab && (
        <NavItem>
          <NavLink to="tasks" tag={RouterLink} css={navigationLinkCss} data-testid="tasks_tab">
            Tasks
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideLeadLoansTab && (
        <NavItem>
          <NavLink to="loans" tag={RouterLink} css={navigationLinkCss} data-testid="loans_tab">
            Loans
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideLeadAssetsTab && (
        <NavItem>
          <NavLink to="assets" tag={RouterLink} css={navigationLinkCss} data-testid="assets_tab">
            Assets
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink to="policies" tag={RouterLink} css={navigationLinkCss} data-testid="policies_tab">
          Policies
        </NavLink>
      </NavItem>
      {authInfo.features.extended_policy_list_view && (
        <NavItem>
          <NavLink to="admin_policies" tag={RouterLink} css={navigationLinkCss}>
            Admin Policies
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideLeadDocumentsTab && (
        <NavItem>
          <NavLink to="files" tag={RouterLink} css={navigationLinkCss} data-testid="files_tab">
            Files
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideLeadNotesTab && (
        <NavItem>
          <NavLink to="notes" tag={RouterLink} css={navigationLinkCss} data-testid="notes_tab">
            Notes
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink to="prior_policies" tag={RouterLink} css={navigationLinkCss} data-testid="prior_policies_tab">
          Prior Policies
        </NavLink>
      </NavItem>
      {!featureFlags.hideLeadQuotesTab && (
        <NavItem>
          <NavLink to="quotes" tag={RouterLink} css={navigationLinkCss} data-testid="quotes_tab">
            Quotes
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink to="activity" tag={RouterLink} css={navigationLinkCss} data-testid="activity_tab">
          Activity log
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default LeadNavigation;
