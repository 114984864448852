import { createStore, useStore } from 'zustand';

import createZustandContext from '../../../contexts/ZustandContext';

interface ProposalContextState {
  currentProposalGid: string | undefined;
  setCurrentProposalGid: (proposal: string | undefined) => void;
  quotesForOpportunities: Record<number, string | undefined>;
  resetQuotesForOpportunities: () => void;
  setQuotesForOpportunities: (opportunityId: number, quoteKey: string) => void;
}
export const ProposalContext = createZustandContext((initialProposalGid: ProposalContextState['currentProposalGid']) =>
  createStore<ProposalContextState>()(set => ({
    currentProposalGid: initialProposalGid,
    setCurrentProposalGid: proposalGid => set({ currentProposalGid: proposalGid }),
    quotesForOpportunities: {},
    resetQuotesForOpportunities: () => set({ quotesForOpportunities: {} }),
    setQuotesForOpportunities: (opportunityId, quoteKey) =>
      set(state => ({ quotesForOpportunities: { ...state.quotesForOpportunities, [opportunityId]: quoteKey } }))
  }))
);

export const useProposalContext = () => useStore(ProposalContext.useContext());
