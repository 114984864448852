/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field, Form, Formik } from 'formik';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { ErrorCol } from '../../components/common';
import { DatePicker, MultiSelect, Select } from '../../components/common/FormikComponents/FormikComponents';
import { Translations } from '../../constants';
import { UserStatus } from '../../interfaces/IUser';
import useAssignees from '../../queries/assignees/useAssignees';
import { TasksDashboardFilters } from '../../queries/tasks/useTasks';
import { spacings } from '../../theme/variables';
import {
  filterDateValidation,
  FUTURE_DATE,
  isDateRangeValid,
  OLD_DATE,
  TO_IS_GREATER_THAN_FROM
} from '../../utils/dateRangeHelpers';
import { showFieldErrorGenerator } from '../../utils/formHelpers';

interface TasksFilterProps {
  onFilterReset(): void;
  onFilterSubmit(data: Record<string, string | any>): void;
  filters?: TasksDashboardFilters;
  message: string;
}

const completes = [
  { key: 'true', value: 'Complete' },
  { key: 'false', value: 'Incomplete' }
];

const TasksFilter = ({ filters = {}, message, onFilterReset, onFilterSubmit }: TasksFilterProps): JSX.Element => {
  const { data: assignees } = useAssignees({ statuses: [UserStatus.Active, UserStatus.Suspended] });

  return (
    <Formik
      initialValues={filters}
      enableReinitialize
      onSubmit={onFilterSubmit}
      validationSchema={yup.object().shape({
        date_from: filterDateValidation,
        date_to: filterDateValidation
      })}
    >
      {({ errors, values, touched, isValid, handleReset }) => {
        const showFieldError = showFieldErrorGenerator({ values, touched, isValid });

        return (
          <Form
            className="tasks-filter"
            autoComplete="off"
            css={css`
              padding-top: ${spacings.px24}px;
            `}
          >
            <h6>Filters</h6>

            <Row>
              <Col sm={4}>
                <FormGroup row id="agent-id-filter">
                  <Label sm={4}>Agent</Label>
                  <Col sm={8}>
                    <Field
                      name="agent_ids"
                      component={MultiSelect}
                      options={assignees || []}
                      valueName="id"
                      labelName="name"
                      showResetButton
                      ordered
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup row id="kind-filter">
                  <Label sm={4}>Type</Label>
                  <Col sm={8}>
                    <Field
                      name="kinds"
                      component={MultiSelect}
                      options={Translations.taskKindOptions}
                      showResetButton
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup row id="completed-filter">
                  <Label sm={4}>Completed</Label>
                  <Col sm={8}>
                    <Field name="completed" component={Select} options={completes} showResetButton />
                  </Col>
                </FormGroup>
              </Col>

              <Col sm={4}>
                <FormGroup row id="date-filter">
                  <Label sm={4}>Due Date</Label>
                  <ErrorCol sm={4} when={showFieldError('date_from')} error={errors.date_from}>
                    <Field
                      name="date_from"
                      testId="date_from"
                      component={DatePicker}
                      placeholder="From"
                      minDate={OLD_DATE}
                      maxDate={FUTURE_DATE}
                    />
                  </ErrorCol>
                  <ErrorCol
                    sm={4}
                    when={showFieldError('date_to')}
                    warning={
                      values &&
                      !errors.date_to &&
                      !isDateRangeValid({ from: values.date_from as string, to: values.date_to as string }) &&
                      TO_IS_GREATER_THAN_FROM
                    }
                    error={errors.date_to}
                  >
                    <Field
                      name="date_to"
                      testId="date_to"
                      component={DatePicker}
                      placeholder="To"
                      minDate={OLD_DATE}
                      maxDate={FUTURE_DATE}
                    />
                  </ErrorCol>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup row id="priority-filter">
                  <Label sm={4}>Priority</Label>
                  <Col sm={8}>
                    <Field
                      name="priorities"
                      component={MultiSelect}
                      options={Translations.taskPriorityOptions}
                      showResetButton
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col sm={6}>
                <span className="filter-info-message">{message}</span>
              </Col>
              <Col sm={6} className="btn-container">
                <Button
                  color="warning"
                  onClick={() => {
                    onFilterReset();
                    handleReset();
                  }}
                >
                  Reset to default
                </Button>
                <Button color="primary" type="submit">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TasksFilter;
