/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import { IQuote } from '../../../../../interfaces';
import { QuoteValueChange } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { moneyFormatter } from '../../../../../utils/formatter';

const Savings = ({
  quote: { premiumChange, premiumChangeType, predictedPremiumChange, predictedPremiumChangeType }
}: {
  quote: IQuote;
}) => {
  return (
    <FlexBox gap={spacings.px4}>
      <FlexBox columnDirection>
        {premiumChange && premiumChangeType === QuoteValueChange.Decrease ? (
          <Text
            bold
            customCss={css`
              white-space: nowrap;
            `}
          >
            {moneyFormatter(Math.abs(premiumChange), true)}
          </Text>
        ) : (
          <Text color={colors.grey60}>No</Text>
        )}
      </FlexBox>
      <FlexBox columnDirection justifyRight>
        <Text color={colors.grey60}>|</Text>
      </FlexBox>
      <FlexBox columnDirection>
        {predictedPremiumChange && predictedPremiumChangeType === QuoteValueChange.Decrease ? (
          <Text
            customCss={css`
              white-space: nowrap;
            `}
          >
            ~{moneyFormatter(Math.abs(predictedPremiumChange), true)}
          </Text>
        ) : (
          <Text color={colors.grey60}>No</Text>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default Savings;
