import { skipToken, useMutation, useQueries, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ILead } from '../../../interfaces';
import { IOpportunity } from '../../../interfaces/IOpportunity';
import { IPerson } from '../../../interfaces/IPerson';
import { InsurableInterest, PolicyType, Requirement } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

export const LEAD_OPPORTUNITIES_QUERY_KEY = 'lead_opportunities';
const LEAD_OPPORTUNITIES_COINSUREDS_QUERY_KEY = 'lead_opportunities_coinsureds';

const getLeadOpportunityCoinsureds = ({
  leadId,
  opportunityId
}: {
  leadId: number | string;
  opportunityId: IOpportunity['id'] | string;
}): Promise<{ people: IPerson[] }> =>
  api.get(`/api/frontend/leads/${leadId}/opportunities/${opportunityId}/coinsureds`);

export const useLeadOpportunityCoinsureds = ({
  leadId,
  opportunityId
}: {
  leadId: string | undefined;
  opportunityId: string;
}) => {
  return useQuery({
    queryKey: [LEAD_OPPORTUNITIES_QUERY_KEY, { leadId, opportunityId }, 'coinsureds'],
    queryFn:
      !!leadId && !!opportunityId ? () => getLeadOpportunityCoinsureds({ leadId: leadId!, opportunityId }) : skipToken,
    select: data => data.people
  });
};

export const useLeadOpportunitiesCoinsureds = ({
  leadId,
  opportunitiesIds
}: {
  leadId: number | undefined;
  opportunitiesIds: IOpportunity['id'][];
}) => {
  const data = useQueries({
    queries: opportunitiesIds.map(opportunityId => ({
      queryKey: [LEAD_OPPORTUNITIES_COINSUREDS_QUERY_KEY, { leadId, opportunityId }],
      queryFn: () => getLeadOpportunityCoinsureds({ leadId: leadId!, opportunityId }),
      enabled: !!leadId && !!opportunityId
    }))
  });

  return {
    data,
    isFetchedAfterMount: data.every(opportunityCoinsureds => opportunityCoinsureds.isFetchedAfterMount)
  };
};

const updateLeadOpportunityCoinsureds = ({
  leadId,
  opportunityId,
  peopleGids
}: {
  leadId: number | string;
  opportunityId: IOpportunity['id'];
  peopleGids: string[];
}): Promise<{ opportunity: IOpportunity }> =>
  api.put(`/api/frontend/leads/${leadId}/opportunities/${opportunityId}/coinsureds`, {
    body: { people_gids: peopleGids }
  });

export const useUpdateLeadOpportunityCoinsureds = () => useMutation({ mutationFn: updateLeadOpportunityCoinsureds });

export const setOpportunityAsPrimary = ({
  leadId,
  opportunityId
}: {
  leadId: ILead['id'] | ILead['gid'];
  opportunityId: IOpportunity['id'];
}): Promise<{ opportunity: IOpportunity }> =>
  api.put(`/api/frontend/leads/${leadId}/opportunities/${opportunityId}/set_as_primary`, { body: {} });

export const useSetOpportunityAsPrimary = () => useMutation({ mutationFn: setOpportunityAsPrimary });

export const createOpportunity = ({
  leadId,
  payload
}: {
  leadId?: number | string;
  payload: {
    policy_type: string;
    assets_gids?: string[];
    agent_selected_coverages?: IOpportunity['agent_selected_coverages'];
    post_sales?: boolean;
  };
}): Promise<{ opportunity: IOpportunity }> =>
  api.post(`/api/frontend/leads/${leadId}/opportunities`, { body: payload });

export const useCreateLeadOpportunity = () => useMutation({ mutationFn: createOpportunity });

interface UpdateOpportunityParams {
  status?: IOpportunity['status'];
  reason?: string | null;
  agent_selected_coverages?: IOpportunity['agent_selected_coverages'];
  policy_type?: PolicyType;
  assets_gids?: string[];
}

export const updateOpportunity = ({
  leadId,
  opportunityId,
  params
}: {
  leadId: string | number;
  opportunityId: IOpportunity['id'];
  params: UpdateOpportunityParams;
}): Promise<{ opportunity: IOpportunity }> =>
  api.put(`/api/frontend/leads/${leadId}/opportunities/${opportunityId}`, {
    body: params
  });

export const useUpdateLeadOpportunity = () => useMutation({ mutationFn: updateOpportunity });

export const getLeadOpportunities = ({
  leadId,
  requirement,
  insurableInterest
}: {
  leadId: number | string | undefined;
  requirement?: Requirement;
  insurableInterest?: InsurableInterest;
}): Promise<{ opportunities: IOpportunity[] }> =>
  api.get(
    `/api/frontend/leads/${leadId}/opportunities?${toQueryParams({
      requirement,
      insurable_interest: insurableInterest
    })}`
  );

const useLeadOpportunities = ({
  leadId,
  requirement,
  insurableInterest,
  options
}: {
  leadId: number | string | undefined;
  requirement?: Requirement;
  insurableInterest?: InsurableInterest;
  options?: UseQueryOptions<{ opportunities: IOpportunity[] }, any, any, any>;
}) =>
  useQuery({
    /* eslint-disable-next-line @tanstack/query/exhaustive-deps  */
    queryKey: [LEAD_OPPORTUNITIES_QUERY_KEY, leadId?.toString(), requirement, insurableInterest],
    queryFn: () => getLeadOpportunities({ leadId, requirement, insurableInterest }),
    ...options,
    enabled: !!leadId,
    select: data => data.opportunities
  });

export default useLeadOpportunities;
