import { useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';

import { ColumnGenerator, Link, Table } from '../../components/common';
import Checkbox from '../../components/common/Checkbox/Checkbox';
import Tooltip from '../../components/common/Tooltip/Tooltip';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import { Translations } from '../../constants';
import { IDashboardTask } from '../../interfaces';
import useAssignees from '../../queries/assignees/useAssignees';
import { TaskPayload } from '../../queries/people/person_tasks/useCreateTask';
import useUpdateTask from '../../queries/people/person_tasks/useUpdateTask';
import { TASK_REMINDERS_QUERY_KEY } from '../../queries/tasks/useTaskReminders';
import { TasksDashboardSorting } from '../../queries/tasks/useTasks';
import { dateFormatter, ellipsis, timeFormatter } from '../../utils/formatter';
import TaskEditor from '../Lead/LeadTasks/TaskEditor';

const renderCustomerNameCell = ({ person_gid, customer_name }: IDashboardTask) => (
  <Link to={{ pathname: `/people/${person_gid}` }} className="fs-mask">
    {customer_name}
  </Link>
);

const renderAgentCell = ({ assignee }: IDashboardTask) => (assignee ? assignee.name : null);

const renderDescriptionCell = ({ description }: IDashboardTask) => (
  <Tooltip message={description} className="fs-mask" left>
    <div className="fs-mask">{ellipsis(description)}</div>
  </Tooltip>
);

const renderCompletedCheckbox = ({
  task,
  onComplete
}: {
  task: IDashboardTask;
  onComplete: (task: IDashboardTask) => void;
}) => <Checkbox name={`completed-${task.id}`} value={task.completed || false} onChange={() => onComplete(task)} />;

const renderEditLink = ({
  task,
  setEditedTask
}: {
  task: IDashboardTask;
  setEditedTask: (task: IDashboardTask) => void;
}) => {
  return <IconButton icon={ButtonIcons.Edit} onClick={() => setEditedTask(task)} />;
};

const TasksList = ({
  tasks,
  refetchTasks,
  defaultSorting,
  onSortingChange,
  onComplete
}: {
  tasks: IDashboardTask[] | undefined;
  refetchTasks: () => Promise<any>;
  defaultSorting: TasksDashboardSorting;
  onSortingChange: (sorting: TasksDashboardSorting) => void;
  onComplete: (task: IDashboardTask) => void;
}) => {
  const [editedTask, setEditedTask] = useState<null | IDashboardTask>();
  const columns = [
    ColumnGenerator.simple({
      accessor: 'lead_id',
      Header: 'Lead ID',
      Cell: ({ original: { lead_id } }: { original: IDashboardTask }) => <Link to={`/leads/${lead_id}`}>{lead_id}</Link>
    }),
    ColumnGenerator.stretchable({
      accessor: 'assignee',
      Header: 'Agent',
      sortable: false,
      Cell: ({ original }: { original: IDashboardTask }) => renderAgentCell(original)
    }),
    ColumnGenerator.stretchable({
      accessor: 'customer_name',
      Header: 'Customer name',
      sortable: false,
      Cell: ({ original }: { original: IDashboardTask }) => renderCustomerNameCell(original)
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      accessor: 'kind',
      Header: 'Type',
      Cell: ({ original: { kind } }: { original: IDashboardTask }) => Translations.taskKind(kind)
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      accessor: 'priority',
      Header: 'Priority',
      Cell: ({ original: { priority } }: { original: IDashboardTask }) => Translations.taskPriority(priority)
    }),
    ColumnGenerator.stretchable({
      accessor: 'description',
      Header: 'Description',
      Cell: ({ original }: { original: IDashboardTask }) => renderDescriptionCell(original)
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      accessor: 'due_date',
      Header: 'Date',
      Cell: ({ original: { due_date } }: { original: IDashboardTask }) => dateFormatter(due_date)
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      accessor: 'due_time',
      Header: 'Your local time',
      sortable: false,
      Cell: ({ original: { due_time } }: { original: IDashboardTask }) => timeFormatter(due_time)
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      accessor: 'completed',
      Header: 'Completed',
      Cell: ({ original }: { original: IDashboardTask }) => renderCompletedCheckbox({ task: original, onComplete }),
      headerClassName: 'with-tooltip'
    }),
    ColumnGenerator.simple({
      width: 50,
      sortable: false,
      Cell: ({ original }: { original: IDashboardTask }) => renderEditLink({ task: original, setEditedTask })
    })
  ];
  const queryClient = useQueryClient();

  const { data: assignees } = useAssignees();
  const { mutate: updateTask } = useUpdateTask(() => {
    setEditedTask(null);
    refetchTasks().then(() => queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] }));
  });

  return (
    <>
      <Table
        onSortingChange={onSortingChange}
        defaultSorting={defaultSorting}
        data={tasks}
        columns={columns}
        noDataMessage="No tasks found"
        className="tasks-list"
      />
      {assignees && editedTask && (
        <TaskEditor
          open
          assignees={assignees}
          cancelBtnHandler={() => {
            setEditedTask(null);
          }}
          confirmBtnHandler={data => {
            const params = { ...data, lead_id: editedTask.lead_id };
            updateTask({ personGid: editedTask.person_gid, taskId: data.id, data: params as TaskPayload });
          }}
          task={editedTask}
          leadId={editedTask.lead_id}
        />
      )}
    </>
  );
};

export default TasksList;
