import './Task.scss';

import React from 'react';

import colors from '../../../theme/colors';
import { TaskIcon } from '../../core/icons';
import { Link, Tooltip } from '..';

const Task = ({ task, url }: any) =>
  task ? (
    <Tooltip message={task.description} className="task-info" right>
      <Link to={url} className="task-info-icon">
        <TaskIcon color={task.completed ? colors.black : colors.grey30} />
      </Link>
    </Tooltip>
  ) : null;

export default Task;
