/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import classNames from 'classnames';
import { omit, pick } from 'ramda';
import React, { useEffect, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { Dropdown, DropdownItem, DropdownMenu, DropdownMenuProps, DropdownToggle } from 'reactstrap';

import colors from '../../../theme/colors';
import { borderRadius, spacingKeys, Spacings, spacings } from '../../../theme/variables';
import Container from '../../core/Container';
import { MoreIcon } from '../../core/icons';
import Tooltip from '../Tooltip/NewTooltip';

export interface IDropdownItem {
  action: () => void;
  label: string | JSX.Element;
  disabled?: boolean;
  classes?: string;
  icon?: string;
  testId?: string;
}

interface DropdownProps extends DropdownMenuProps, Spacings {
  disabled?: boolean;
  items: IDropdownItem[];
  optionsComponent?: JSX.Element;
  wrapperClass?: string;
  tipId?: string | number | undefined;
  maxHeight?: number;
  wrapperTestId?: string;
}

const renderItem = (item: IDropdownItem, index: number, tipId: string | number | undefined) => (
  <React.Fragment key={index}>
    <span data-tip={item.disabled ? 'Action is not available' : ''} data-for={tipId}>
      <DropdownItem
        onClick={item.action}
        disabled={item.disabled}
        className={classNames('action', item.classes)}
        data-testid={item.testId}
      >
        {item.icon && <img src={item.icon} alt="" />}
        {typeof item.label === 'string' ? <span>{item.label}</span> : item.label}
      </DropdownItem>
    </span>
  </React.Fragment>
);
const defaultOption = <MoreIcon color={colors.black} data-testid="options" />;

const dropDownMenuStyles = () => css`
  .drop-down-menu-container {
    display: flex;
    align-items: center;

    .dropdown {
      width: 100%;

      .dropdown-button {
        width: 100%;
        padding: 0;

        &:hover {
          opacity: 0.7;
        }

        &:disabled {
          cursor: not-allowed;
        }
      }

      .dropdown-menu {
        background: ${colors.white};
        border: 1px solid ${colors.grey30};
        border-radius: 4px;
        box-shadow: 2px 4px 4px rgba(201, 201, 201, 0.4);

        .dropdown-item {
          display: flex;
          align-items: center;
          margin: 0;
          padding: 0.4rem 0.5rem;
          color: ${colors.black};
          background-color: ${colors.white};

          span {
            margin-left: 8px;
          }

          &:hover {
            background-color: ${colors.grey5};
          }

          &:focus,
          &:active,
          &.active {
            background-image: none;
            outline: 0;
            box-shadow: none;
          }

          &.disabled {
            cursor: not-allowed !important;
            pointer-events: all !important;
          }
        }

        .dropdown-divider {
          height: 1px;
          margin: 0;
          background: ${colors.grey5};
        }
      }
    }
  }
`;

const DropDownMenu: React.FC<DropdownProps> = ({
  wrapperClass,
  disabled,
  items,
  optionsComponent = defaultOption,
  tipId = '',
  maxHeight = 220,
  wrapperTestId = '',
  ...rest
}) => {
  const spacingProps = pick(spacingKeys, rest);
  const restOfProps = omit(spacingKeys, rest);

  const [isOpen, setOpen] = useState(false);

  const toggle = () => {
    if (!disabled) {
      setOpen(opened => !opened);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Global styles={dropDownMenuStyles()} />
      <Container
        className={classNames('drop-down-menu-container action', wrapperClass)}
        data-testid={`dropdown-menu-${wrapperTestId}`}
        p={spacings.px4}
        {...spacingProps}
        css={css`
          border-radius: ${borderRadius}px;
          &:hover {
            background-color: ${colors.grey5};
            cursor: pointer;
          }
        `}
      >
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle className="dropdown-button" disabled={disabled} data-testid="dropdown-button">
            {isOpen ? React.cloneElement(optionsComponent, { color: colors.azure50 }) : optionsComponent}
          </DropdownToggle>
          <DropdownMenu
            {...restOfProps}
            modifiers={{
              setMaxHeight: {
                enabled: true,
                fn: data => {
                  return {
                    ...data,
                    styles: {
                      ...data.styles,
                      overflow: 'auto',
                      maxHeight: `${maxHeight}px`
                    }
                  };
                }
              }
            }}
          >
            {items.map((item, index) => renderItem(item, index, tipId))}
          </DropdownMenu>
        </Dropdown>
        {tipId && <Tooltip id={`${tipId || ''}`} />}
      </Container>
    </>
  );
};

export default DropDownMenu;
