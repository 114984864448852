import moment from 'moment';

import { IPerson } from '../../interfaces';
import { IPrefilledDriver } from '../../queries/people/usePrefillVehiclesAndDrivers';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { dateFormatter } from '../../utils/formatter';
import Tooltip from '../common/Tooltip/NewTooltip';
import FlexBox from '../core/FlexBox';
import { CheckboxField } from '../core/forms/fields';
import Tag from '../core/Tag';
import Text from '../core/Text';

const PrefilledDriver = ({ driver, person, index }: { driver: IPrefilledDriver; person: IPerson; index: number }) => {
  const primaryContactTag = person.name === driver.name && 'Primary contact';
  const underageTag =
    driver.date_of_birth && moment().diff(moment(driver.date_of_birth), 'years') < 15 && 'Under 15 y.o.';

  return (
    <>
      <CheckboxField
        fsMask
        id={`prefilled_drivers.${index}.confirmed`}
        name={`prefilled_drivers.${index}.confirmed`}
        disabled={!!primaryContactTag || !!underageTag}
        label={
          <FlexBox alignItemsCenter gap={spacings.px8}>
            <Text className="fs-mask">{driver.name}</Text>
            {primaryContactTag && (
              <>
                <Tag
                  backgroundColor={colors.violet}
                  textColor={colors.violet}
                  transparent
                  label={primaryContactTag}
                  data-for={`primary-contact-${index}`}
                  data-tip="Driver already added to the drivers list"
                />
                <Tooltip id={`primary-contact-${index}`} />
              </>
            )}
            {underageTag && (
              <>
                <Tag
                  backgroundColor={colors.violet}
                  textColor={colors.violet}
                  transparent
                  label={underageTag}
                  data-for={`underaged-driver-${index}`}
                  data-tip="You cannot add a driver younger than 15 y.o "
                />
                <Tooltip id={`underaged-driver-${index}`} />
              </>
            )}
          </FlexBox>
        }
        preserveErrorSpace={false}
        description={driver.date_of_birth ? `DOB: ${dateFormatter(driver.date_of_birth)}` : undefined}
      />
    </>
  );
};

export default PrefilledDriver;
