import { useQueries } from '@tanstack/react-query';

import { IOpportunity } from '../../../interfaces';
import { CarrierKey } from '../../../interfaces/ICarrier';
import { IOpportunityPolicies } from '../../../interfaces/IOpportunityWithPolicies';
import { originAssetTypeForPolicyType } from '../../../interfaces/IPolicyType';
import { getPremiumPrediction } from '../../people/person_policies/usePremiumPrediction';

const useOpportunitiesWithPremiumPredictions = <Opportunity extends IOpportunity>({
  opportunitiesWithPolicies
}: {
  opportunitiesWithPolicies: (Opportunity & IOpportunityPolicies)[] | undefined;
}) => {
  const data = useQueries({
    queries: (opportunitiesWithPolicies || []).map(({ assets, state, latestPolicy, policy_type }) => {
      const assetType = originAssetTypeForPolicyType(policy_type);
      const opportunityState = assets?.[0]?.address?.state || state || undefined;
      const latestPolicyCarrier = latestPolicy?.carrier;
      const carrierGid = latestPolicyCarrier?.key === CarrierKey.Unknown ? undefined : latestPolicyCarrier?.gid;
      const premium = latestPolicy?.premium;
      const expirationDate = latestPolicy?.expiration_date;

      return {
        queryKey: [
          'opportunity_with_premium_prediction',
          assetType,
          opportunityState,
          carrierGid,
          premium,
          expirationDate
        ],
        queryFn: () =>
          getPremiumPrediction({
            asset_type: assetType,
            state: opportunityState,
            carrier_gid: carrierGid,
            premium,
            date: expirationDate
          }),
        enabled: !!opportunityState && !!assetType
      };
    })
  });

  const opportunitiesWithPremiumPredictions = opportunitiesWithPolicies?.map((opportunity, index) => ({
    ...opportunity,
    premium_prediction: data?.[index]?.data?.average_premium
  }));

  return {
    opportunitiesWithPremiumPredictions,
    isLoading: data.some(query => query.isLoading),
    isFetchedAfterMount: data.every(query => query.isFetchedAfterMount),
    refetch: () => Promise.all(data.map(query => query.refetch()))
  };
};

export default useOpportunitiesWithPremiumPredictions;
