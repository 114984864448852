import { skipToken, useMutation, useQuery } from '@tanstack/react-query';

import api from '../../services/api';

export const PERSON_DNC_QUERY_KEY = 'person-do-not-contact-status';
const usePersonDNC = (personGid?: string) =>
  useQuery({
    queryKey: [PERSON_DNC_QUERY_KEY, personGid],
    queryFn: personGid
      ? (): Promise<{ dnc_status: boolean | null }> => api.get(`/api/frontend/people/${personGid}/dnc_status`)
      : skipToken,
    select: data => data.dnc_status
  });

interface UpdatePayload {
  personGid: string;
  do_not_contact: boolean;
}

const updateDNCStatus = ({ personGid, do_not_contact }: UpdatePayload): Promise<unknown> =>
  api.put(`/api/frontend/people/${personGid}/dnc_status`, { body: { do_not_contact } });
export const useUpdateDnc = () => useMutation({ mutationFn: updateDNCStatus });

export default usePersonDNC;
