import { BrowserAuthError } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React from 'react';
import { Navigate } from 'react-router-dom';

import Button from '../components/core/buttons/Button';
import FlexBox from '../components/core/FlexBox';
import Heading from '../components/core/Heading';
import { MaticLogo } from '../components/core/icons';
import { useDocumentTitle } from '../hooks';
import { spacings } from '../theme/variables';

const EntraLoginPage = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  useDocumentTitle('Login');

  const [isLoginIn, setIsLoginIn] = React.useState(false);

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <FlexBox alignItemsCenter justifyCenter columnDirection fitParentHeight gap={spacings.px16}>
      <FlexBox gap={spacings.px12} alignItemsCenter>
        <MaticLogo />
        <Heading type="h1">AMP</Heading>
      </FlexBox>
      <Button
        loading={isLoginIn}
        onClick={async () => {
          setIsLoginIn(true);

          try {
            const loginResponse = await instance.loginPopup({
              scopes: ['User.Read'],
              prompt: 'select_account'
            });
            instance.setActiveAccount(loginResponse.account);
          } catch (err) {
            if (err instanceof BrowserAuthError && err.errorCode === 'user_cancelled') {
              setIsLoginIn(false);
            }
          }
        }}
      >
        Sign in via Entra
      </Button>
    </FlexBox>
  );
};

export default EntraLoginPage;
