import { useQuery } from '@tanstack/react-query';

import { IPersonAsset } from '../../../interfaces/IPersonAsset';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

const getPersonAssets = ({
  personGid,
  policyType
}: {
  personGid: string;
  policyType?: string;
}): Promise<{ assets: IPersonAsset[] }> =>
  api.get(`/api/frontend/people/${personGid}/assets?${toQueryParams({ policy_type: policyType })}`);

const usePersonAssets = ({
  personGid,
  policyType,
  enabled = true
}: {
  personGid: string | undefined;
  policyType?: string;
  enabled?: boolean;
}) =>
  useQuery({
    queryKey: ['person_assets', personGid, policyType],
    queryFn: () => getPersonAssets({ personGid: personGid!, policyType }),
    enabled: !!personGid && !!enabled,
    select: data => data.assets
  });

export default usePersonAssets;
