import { AxiosError } from 'axios';

import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import { ErrorFallbackProps } from '../../../../../components/ErrorFallback/ErrorFallback';
import { spacings } from '../../../../../theme/variables';

const CustomerChoiceErrorFallback = ({ error }: ErrorFallbackProps) => {
  const errorMessage =
    error instanceof AxiosError && error.response?.status === 404
      ? 'Quotes have been archived and are no longer available.'
      : 'Could not retrieve customer choices due to unexpected error.';

  return (
    <FlexBox columnDirection gap={spacings.px16}>
      <Paragraph type="large" bold>
        Customer choice
      </Paragraph>
      <Paragraph type="small">{errorMessage}</Paragraph>
    </FlexBox>
  );
};

export default CustomerChoiceErrorFallback;
