import { PopperPlacementType } from '@mui/material';
import React from 'react';

import CommunicationHistoryTab from '../CommunicationHistoryTab';
import ContactBookTab from '../ContactBookTab';

interface CommunicationTabProps {
  personGid: string | undefined;
  showClose?: boolean;
  showContactBook?: boolean;
  showOpenLead?: boolean;
  popperPlacement?: PopperPlacementType;
}

const CommunicationTab = ({
  personGid,
  showClose = true,
  showContactBook = false,
  showOpenLead = false,
  popperPlacement
}: CommunicationTabProps) => {
  const [isContactBookOpened, setContactBookOpened] = React.useState(false);

  if (isContactBookOpened) {
    return <ContactBookTab personGid={personGid} onClose={() => setContactBookOpened(false)} />;
  }

  return (
    <CommunicationHistoryTab
      personGid={personGid}
      showClose={showClose}
      showOpenLead={showOpenLead}
      popperPlacement={popperPlacement}
      onOpenContactBook={showContactBook ? () => setContactBookOpened(true) : undefined}
    />
  );
};

export default CommunicationTab;
