/** @jsxImportSource @emotion/react */
import { useQueryClient } from '@tanstack/react-query';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { CogIcon } from '../../../../components/core/icons';
import Select from '../../../../components/core/inputs/Select';
import Modal, { ModalSize } from '../../../../components/core/Modal';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { useToggle } from '../../../../hooks';
import { ILead } from '../../../../interfaces';
import useTestModeVersions from '../../../../queries/leads/test_mode/useTestModeVersions';
import { updateTestMode } from '../../../../queries/leads/test_mode/useUpdateTestMode';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { InputSize, spacings } from '../../../../theme/variables';
import { dateTimeFormatter, SCOUTING_REPORT_DATE_TIME_FORMAT } from '../../../../utils/formatter';
import QuestionAnswerPair from '../CollectedInfoTab/_components/QuestionAnswerPair';
import UserRoleTag from './_components/UserRoleTag';
import Version from './_components/Version';

const LeadType = ({ lead }: { lead: ILead }) => {
  const queryClient = useQueryClient();
  const [openedTestModeVersionsModal, toggleOpenedTestModeVVersionsModal] = useToggle(false);
  const { data: testModeVersions } = useTestModeVersions({ leadGid: lead!.gid });
  const isTestModeUpdated = testModeVersions?.find(version => version.event === 'update');

  return (
    <>
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <CogIcon />
        <Paragraph type="small" bold>
          Lead type
        </Paragraph>
        {isTestModeUpdated && (
          <Button
            onClick={() => {
              analytics.track('Lead operations viewed', { item: 'lead_test_mode', lead_gid: lead.gid });
              toggleOpenedTestModeVVersionsModal();
            }}
            variant={ButtonVariant.PlainText}
          >
            <Text underline dotted color={colors.statusOrange} type="small">
              Updated
            </Text>
          </Button>
        )}
      </FlexBox>
      <Container ml={spacings.px32}>
        <QuestionAnswerPair
          questionLabel={
            <Text color={colors.grey60} type="small">
              Test lead
            </Text>
          }
          answerLabel={
            <>
              {authInfo.features.lead_test_mode ? (
                <Select
                  options={[
                    { value: 'Yes', key: 'yes' },
                    { value: 'No', key: 'no' }
                  ]}
                  value={lead.test_mode ? 'yes' : 'no'}
                  inputSize={InputSize.Medium}
                  inline
                  onChange={() => {
                    analytics.track('Lead operations updated', {
                      item: 'lead_test_mode',
                      lead_gid: lead.gid
                    });
                    updateTestMode({ leadId: lead.id, testMode: !lead.test_mode }).then(() =>
                      queryClient.invalidateQueries()
                    );
                  }}
                />
              ) : (
                <Text type="small">{lead.test_mode ? 'Yes' : 'No'}</Text>
              )}
            </>
          }
        />
      </Container>
      {openedTestModeVersionsModal && (
        <Modal
          title="Log of changes made with lead test mode"
          showCancelButton={false}
          cancelHandler={toggleOpenedTestModeVVersionsModal}
          size={ModalSize.large}
        >
          <FlexBox gap={spacings.px8} columnDirection p={spacings.px12}>
            {testModeVersions?.map(version => {
              return (
                <Version key={version.id} created={version.event === 'create'} columnsTemplate="29% 50% 19%">
                  <Text color={colors.grey60} type="small" singleLine>
                    {version.changes?.[2]?.diff === 'ins' ? 'Test mode: yes' : 'Test mode: no'}
                  </Text>
                  <FlexBox gap={spacings.px8} alignItemsCenter>
                    <Text color={colors.grey60} singleLine type="small">
                      by {version.author_name || 'System'}
                    </Text>
                    <UserRoleTag processedBy={version.processed_by} processedWithRole={version.processed_with_role} />
                  </FlexBox>
                  <Text color={colors.grey60} type="small" singleLine>
                    {dateTimeFormatter(version.created_at, SCOUTING_REPORT_DATE_TIME_FORMAT)}
                  </Text>
                </Version>
              );
            })}
          </FlexBox>
        </Modal>
      )}
    </>
  );
};

export default LeadType;
