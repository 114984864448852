/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';

import colors from '../../theme/colors';
import { borderRadius } from '../../theme/variables';
import Text from './Text';

interface HotKeyProps {
  message: string;
  customCss?: SerializedStyles;
}

const HotKey = ({ message, customCss }: HotKeyProps): JSX.Element => {
  return (
    <Text
      type="small"
      customCss={css`
        background-color: ${colors.grey5};
        box-shadow: 0px 1px 0px rgba(179, 179, 179, 0.6);
        border-radius: ${borderRadius}px;
        color: ${colors.grey60};
        padding: 4px 8px;
        ${customCss}
      `}
    >
      {message}
    </Text>
  );
};

export default HotKey;
