import { useQuery } from '@tanstack/react-query';

import { ILender } from '../../interfaces/ILender';
import api from '../../services/api';

const getVerifiedLenders = (): Promise<{ lenders: ILender[] }> => api.get('/api/frontend/lenders/verified');

const useVerifiedLenders = () =>
  useQuery({
    queryKey: ['verified_lenders'],
    queryFn: () => getVerifiedLenders(),
    select: data => data?.lenders,
    staleTime: Infinity
  });

export default useVerifiedLenders;
