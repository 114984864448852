import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { NonEmptyArray, Unpacked } from '../../../interfaces';
import { IOpportunity, isHomeOpportunity, OpportunityStatus } from '../../../interfaces/IOpportunity';
import { InsurableInterest, PolicyType } from '../../../interfaces/IPolicyType';
import { QuoteKind } from '../../../interfaces/IQuote';
import useDataCollection from '../../../queries/leads/data_collection/useDataCollection';
import useLeadOpportunities, {
  useCreateLeadOpportunity
} from '../../../queries/leads/opportunities/useLeadOpportunities';
import useCreateQuotesRequest from '../../../queries/quotes_requests/useCreateQuotesRequest';

const isAssetPresent = <T extends IOpportunity>(
  op: T
): op is T & { assets: NonEmptyArray<Unpacked<NonNullable<IOpportunity['assets']>>> } => !!(op.assets && op.assets[0]);

const useSuperISRQuoting = () => {
  const { personGid, lead } = useGuidedSellingExperienceContext();
  const { data: leadOpportunities } = useLeadOpportunities({ leadId: lead?.id });
  const { mutateAsync: createLeadOpportunity } = useCreateLeadOpportunity();

  const { data: dataCollection } = useDataCollection(lead?.id);
  const { mutateAsync: createQuotesRequest } = useCreateQuotesRequest();

  const uniqueDataCollectionOpportunities =
    dataCollection?.pages
      .flatMap(p => p.opportunities)
      .filter(Boolean)
      .filter((opp, index, arrayOfOpps) => arrayOfOpps.findIndex(o => o.id === opp.id) === index) || [];

  const dataCollectionOpportunitiesToQuote = uniqueDataCollectionOpportunities.filter(
    opp => opp.is_data_collection_enabled
  );

  const firstHomeAssetGid = dataCollectionOpportunitiesToQuote.find(
    opp => opp.insurable_interest === InsurableInterest.RealProperty
  )?.assets?.[0]?.gid;

  const additionalOpportunitiesToQuote = (leadOpportunities || []).filter(
    leadOpp => !uniqueDataCollectionOpportunities.find(opp => opp.id === leadOpp.id)
  );

  const existingOpportunitiesToQuote = [
    ...dataCollectionOpportunitiesToQuote,
    ...additionalOpportunitiesToQuote
  ].filter(opp => opp.status === OpportunityStatus.NewRequest);

  const createFloodOpportunities = async () => {
    const essentialRealPropertyOpportunitiesWithAsset = existingOpportunitiesToQuote
      .filter(isHomeOpportunity)
      .filter(isAssetPresent);

    const realPropertyAssetPolicyTypeMap = essentialRealPropertyOpportunitiesWithAsset.map(o => {
      const assetGid = o.assets[0].gid;

      return assetGid + o.policy_type;
    });

    return await Promise.all(
      essentialRealPropertyOpportunitiesWithAsset
        .map(opp => {
          const assetGid = opp.assets[0].gid;

          if (realPropertyAssetPolicyTypeMap.includes(assetGid + PolicyType.Flood)) {
            return null;
          }

          return createLeadOpportunity({
            leadId: lead!.id,
            payload: { policy_type: PolicyType.Flood, assets_gids: opp.assets?.map(a => a.gid) }
          });
        })
        .filter(Boolean)
    );
  };

  const buildOpportunitiesObject = async () => {
    const ops: Parameters<typeof createQuotesRequest>[0]['opportunities'] = [];

    const newFloodOpportunities = await createFloodOpportunities();

    newFloodOpportunities.forEach(({ opportunity: floodOpp }) => {
      ops.push({
        policy_type: floodOpp.policy_type,
        assets: (floodOpp.assets || []).map(({ gid }) => ({
          gid
        })),
        params: {
          multi_policy_discount: true,
          quotes_kinds: [QuoteKind.NonIntegrated, QuoteKind.RC1, QuoteKind.RC2]
        }
      });
    });

    const pafOpportunity = existingOpportunitiesToQuote.find(o => o.policy_type === PolicyType.PAF);

    if (pafOpportunity && firstHomeAssetGid) {
      ops.push({
        policy_type: pafOpportunity.policy_type,
        assets: [{ gid: firstHomeAssetGid, coverages: [{ key: 'jewelry', limit_value: 5_000 }] }],
        params: {
          multi_policy_discount: true,
          quotes_kinds: [QuoteKind.NonIntegrated, QuoteKind.RC1, QuoteKind.RC2]
        }
      });
    }

    existingOpportunitiesToQuote.filter(isAssetPresent).forEach(o => {
      ops.push({
        policy_type: o.policy_type,
        assets: o.assets.map(({ gid }) => ({
          gid
        })),
        params: {
          multi_policy_discount: true,
          quotes_kinds: [QuoteKind.NonIntegrated, QuoteKind.RC1, QuoteKind.RC2]
        }
      });
    });

    return ops;
  };

  return async () => {
    const opportunities = await buildOpportunitiesObject();

    if (opportunities.length) {
      return createQuotesRequest({
        customer_gid: personGid!,
        engagement_gid: lead!.gid,
        opportunities
      });
    }
  };
};

export default useSuperISRQuoting;
