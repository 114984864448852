/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import { TrendingUpIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../../components/core/PolicyTypeIcon';
import Text from '../../../../components/core/Text';
import { usePopper } from '../../../../hooks';
import {
  IOpportunity,
  IOpportunityPolicies,
  IOpportunityPremiumPrediction,
  IVehicle,
  Pretty
} from '../../../../interfaces';
import { InsurableInterest } from '../../../../interfaces/IPolicyType';
import { generateAutoDescription } from '../../../../interfaces/IVehicle';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { moneyFormatter } from '../../../../utils/formatter';
import { predictedPremiumIncrease } from '../../_helpers';

type EnhancedOpportunity = Pretty<IOpportunity & IOpportunityPolicies & IOpportunityPremiumPrediction>;

export const policiesHistoryAndPrediction = ({ policies, latestPolicy, premium_prediction }: EnhancedOpportunity) => {
  const currentYear = new Date().getFullYear();

  const predictedPremiumIncreaseValues = predictedPremiumIncrease({
    premium: latestPolicy?.premium,
    premiumPrediction: premium_prediction
  });

  const isPredictedPremiumAvailable = latestPolicy?.premium && predictedPremiumIncreaseValues;
  const isLatestPolicyEffective = new Date(latestPolicy?.expiration_date || '2000-01-01').getFullYear() >= currentYear;
  const predictedPolicy = isPredictedPremiumAvailable
    ? {
        effectiveYear: isLatestPolicyEffective
          ? new Date(latestPolicy.expiration_date!).getFullYear() + 1
          : currentYear,
        premium: predictedPremiumIncreaseValues.predictedNewPremium,
        gid: 'predicted-policy-gid',
        isPredicted: true,
        predictedPremiumIncreaseValues,
        carrier: latestPolicy.carrier,
        coverages: latestPolicy.coverages
      }
    : null;

  const existingPoliciesHistory = policies?.map(({ gid, expiration_date, premium, carrier, coverages }) => ({
    gid,
    effectiveYear: expiration_date ? new Date(expiration_date).getFullYear() : null,
    premium: premium ? parseFloat(premium) : null,
    isPredicted: false,
    predictedPremiumIncreaseValues: null,
    carrier,
    coverages
  }));

  return predictedPolicy
    ? [predictedPolicy, ...(existingPoliciesHistory || [])].reverse()
    : [...(existingPoliciesHistory || [])].reverse();
};

export const roundedPremiumChange = (currentPremium: number | null, previousPremium: number | null | undefined) => {
  if (!currentPremium || !previousPremium) {
    return null;
  }

  const premiumChange = currentPremium - previousPremium;

  return premiumChange > -0.9 && premiumChange < 0.9 ? null : premiumChange;
};

const opportunityTitle = ({ assets, title, insurable_interest }: EnhancedOpportunity) => {
  return insurable_interest === InsurableInterest.Vehicle ? generateAutoDescription(assets as IVehicle[]) : title;
};

const PremiumAnalysis = ({
  opportunitiesWithPremiumPredictions,
  customCss
}: {
  opportunitiesWithPremiumPredictions: EnhancedOpportunity[];
  customCss?: SerializedStyles;
}) => {
  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps } = usePopper({
    placement: 'left',
    disablePortal: false,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      }
    ]
  });

  const opportunities = opportunitiesWithPremiumPredictions
    .map(opportunity => {
      const policiesHistory = policiesHistoryAndPrediction(opportunity);

      if (policiesHistory.length === 0 && !opportunity.premium_prediction?.avg_premium_change_percent) {
        return null;
      }

      return { ...opportunity, policiesHistory };
    })
    .filter(Boolean);

  return (
    <>
      <FlexBox
        alignItemsCenter
        gap={spacings.px20}
        backgroundColor={anchorEl ? colors.grey5 : undefined}
        css={css`
          flex: 1;
          &:hover {
            background-color: ${colors.grey5};
            cursor: pointer;
          }
          ${customCss}
        `}
        onMouseEnter={e => {
          triggerPopper(e);
        }}
        onMouseLeave={() => {
          setAnchorEl(null);
        }}
      >
        <TrendingUpIcon color={colors.statusRed} />
        <Paragraph bold color={colors.azure50}>
          Premium analysis
        </Paragraph>
        <PopperComponent {...popperProps}>
          <FlexBox
            columnDirection
            pv={spacings.px8}
            ph={spacings.px12}
            gap={spacings.px12}
            backgroundColor={colors.white}
            border
            boxShadow
            roundBorder
            css={css`
              z-index: 999;
            `}
          >
            <Paragraph type="small" bold>
              Premium history analysis
            </Paragraph>
            {opportunitiesWithPremiumPredictions.some(
              ({ policies, premium_prediction }) => !policies?.length && premium_prediction?.avg_premium_change_percent
            ) && <Paragraph type="small">When you add current insurance you get more accurate predictions.</Paragraph>}
            <div
              css={css`
                height: 1px;
                width: 100%;
                background-color: ${colors.grey10};
              `}
            />
            {opportunities.map((opportunity, index) => {
              const { id, premium_prediction, policy_type, policiesHistory, latestPolicy } = opportunity;

              return (
                <React.Fragment key={id}>
                  {index > 0 && (
                    <div
                      css={css`
                        height: 1px;
                        margin-top: ${spacings.px8}px;
                        background: ${colors.grey10};
                      `}
                    />
                  )}
                  <FlexBox alignItemsCenter gap={spacings.px4}>
                    <PolicyTypeIcon policyType={policy_type} />
                    <Text type="small">{opportunityTitle(opportunity)}</Text>
                  </FlexBox>
                  {policiesHistory.map(
                    ({ gid, effectiveYear, premium, isPredicted, predictedPremiumIncreaseValues }, index) => {
                      const previousPolicy = index > 0 ? policiesHistory[index - 1] : null;
                      const previousPolicyPremium = previousPolicy?.premium;
                      const premiumChange = roundedPremiumChange(premium, previousPolicyPremium);
                      const predictedPostfix = isPredicted ? '(next term prediction)' : '';
                      const currentPostfix = gid === latestPolicy?.gid ? '(current)' : '';

                      return (
                        <FlexBox key={gid} justifySpaceBetween>
                          {effectiveYear ? (
                            <Text color={colors.grey80} type="small">
                              {effectiveYear + currentPostfix + predictedPostfix}
                            </Text>
                          ) : (
                            <Text color={colors.grey80} type="small">
                              —
                            </Text>
                          )}
                          <FlexBox gap={spacings.px8} alignItemsCenter>
                            {predictedPremiumIncreaseValues?.predictedFlatIncrease && (
                              <Text
                                color={
                                  predictedPremiumIncreaseValues.predictedFlatIncrease > 0
                                    ? colors.statusRed
                                    : colors.statusGreen
                                }
                                type="small"
                              >
                                {predictedPremiumIncreaseValues.predictedFlatIncrease > 0 && '+'}
                                {moneyFormatter(predictedPremiumIncreaseValues.predictedFlatIncrease, true)}
                              </Text>
                            )}
                            {!predictedPremiumIncreaseValues?.predictedFlatIncrease && premiumChange && (
                              <Text color={premiumChange > 0 ? colors.statusRed : colors.statusGreen} type="small">
                                {premiumChange > 0 && '+'}
                                {moneyFormatter(premiumChange, true)}
                              </Text>
                            )}
                            <Text color={colors.grey80} type="small">
                              {premium ? moneyFormatter(premium, true) : '—'}
                            </Text>
                          </FlexBox>
                        </FlexBox>
                      );
                    }
                  )}
                  {premium_prediction && (
                    <FlexBox p={spacings.px12} backgroundColor={colors.warningBackground} roundBorder>
                      <Paragraph type="small">
                        Based on carrier history, address and home facts expected increase is{' '}
                        {Math.round(parseFloat(premium_prediction.avg_premium_change_percent))}%
                      </Paragraph>
                    </FlexBox>
                  )}
                </React.Fragment>
              );
            })}
          </FlexBox>
        </PopperComponent>
      </FlexBox>
    </>
  );
};

export default PremiumAnalysis;
