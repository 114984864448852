import * as Sentry from '@sentry/react';

import { useConstant } from '../../../hooks';
import { ILead } from '../../../interfaces';
import { TaskKind } from '../../../interfaces/ITask';
import useCreateTask from '../../../queries/people/person_tasks/useCreateTask';
import useCalendar from '../../../queries/scheduled_calls/useCalendar';
import authInfo from '../../../services/authInfo';
import { dateFormatter, INTERNAL_DATE_FORMAT } from '../../../utils/formatter';
import { calculateDueTime } from '../_helpers';

const useCreateInvoiceTask = ({ lead, personGid }: { lead: ILead | undefined; personGid: string }) => {
  const date = useConstant(() => dateFormatter(new Date(), INTERNAL_DATE_FORMAT));

  const { data: calendarData } = useCalendar({
    date,
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    spotsDuration: 30
  });

  const { mutateAsync: createTask } = useCreateTask({});

  return {
    createInvoiceTask: async ({ policyId }: { policyId: number }) => {
      const due_time = calculateDueTime(calendarData);

      try {
        return await createTask({
          personGid,
          data: {
            policy_id: policyId,
            kind: TaskKind.TrailingDoc,
            agent_id: authInfo.currentUserId!,
            lead_id: lead?.id,
            completed: false,
            due_date: date,
            due_time,
            priority: 'normal',
            description: 'Generate Invoice'
          }
        });
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  };
};
export default useCreateInvoiceTask;
