import React from 'react';
import ReactTooltip from 'react-tooltip';

import NewTooltip from '../../../../components/common/Tooltip/NewTooltip';
import Container from '../../../../components/core/Container';
import { IGroupedQuotes } from '../../../../interfaces';
import { IAssetsQuotesGroup } from '../../../../interfaces/IGroupedQuotes';
import { PolicyType } from '../../../../interfaces/IPolicyType';
import { IQuotesRequest } from '../../../../interfaces/IQuotesRequests';
import useQuotesRequests from '../../../../queries/quotes_requests/useQuotesRequests';
import { spacings } from '../../../../theme/variables';
import AssetsQuoteGroup from '../_components/AssetsQuoteGroup';

interface IQuotesGroupedByAssetsProps {
  groupedQuotes: IGroupedQuotes<IAssetsQuotesGroup>;
  leadId: number | undefined;
  personGid: string;
  refetchQuotesRequests: ReturnType<typeof useQuotesRequests>['refetch'];
  quotingFinished: boolean;
  quotesRequest: IQuotesRequest;
  groupedErrors: IGroupedQuotes<IAssetsQuotesGroup> | undefined;
}

const getErrorsByPolicyType = (
  groupedErrors: IGroupedQuotes<IAssetsQuotesGroup> | undefined,
  policyType: PolicyType
) => {
  return groupedErrors?.groups?.find(group => group.quotes[0]?.attributes.policy_type === policyType)?.quotes;
};

const QuotesGroupedByAssets = ({
  groupedQuotes: { groups, assets },
  leadId,
  personGid,
  refetchQuotesRequests,
  quotingFinished,
  quotesRequest,
  groupedErrors
}: IQuotesGroupedByAssetsProps) => {
  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container>
      {groups.map(group => (
        <Container mb={spacings.px48} key={group.opportunity.id}>
          <AssetsQuoteGroup
            quotesGroup={group}
            assets={assets}
            leadId={leadId}
            personGid={personGid}
            refetchQuotesRequests={refetchQuotesRequests}
            quotingFinished={quotingFinished}
            quotesRequest={quotesRequest}
            errors={getErrorsByPolicyType(groupedErrors, group.opportunity.policy_type)}
          />
        </Container>
      ))}
      <NewTooltip id="carrier-name-tooltip" />
      <NewTooltip id="near-final-price-tooltip" />
      <NewTooltip id="non-admitted-quote-tooltip" />
    </Container>
  );
};

export default QuotesGroupedByAssets;
