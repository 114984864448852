/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Select from '../../../../components/core/inputs/Select';
import Modal from '../../../../components/core/Modal';
import Text from '../../../../components/core/Text';
// eslint-disable-next-line max-len
import CancelTrailingScheduledCallModal from '../../../../components/DispositionsModals/CancelTrailingScheduledCallModal';
import ContactMadeModal from '../../../../components/DispositionsModals/ContactMadeModal';
import CouldNotContactModal from '../../../../components/DispositionsModals/CouldNotContactModal';
import {
  isDispositionLostOrSold,
  isEndDisposition
} from '../../../../components/DispositionsModals/dispositionsHelper';
import NotQualifiedModal from '../../../../components/DispositionsModals/NotQualifiedModal';
import PipelineModal from '../../../../components/DispositionsModals/PipelineModal';
import QualifiedModal from '../../../../components/DispositionsModals/QualifiedModal';
import TrailingOpportunitiesModal from '../../../../components/DispositionsModals/TrailingOpportunitiesModal';
import { DispositionType } from '../../../../interfaces/IDisposition';
import { ScheduledInteractionFlow } from '../../../../interfaces/IScheduledCall';
import useLeadAssignees from '../../../../queries/leads/assignees/useLeadAssignees';
import useUpdateLeadAssignee, { useTakeOverLead } from '../../../../queries/leads/assignees/useUpdateLeadAssignee';
// eslint-disable-next-line max-len
import useReconcileOpportunitiesWithPolicies from '../../../../queries/leads/current_opportunities/useReconcileOpportunitiesWithPolicies';
import useUpdateLeadDisposition from '../../../../queries/leads/dispositions/useUpdateLeadDisposition';
import useLeadOpportunities from '../../../../queries/leads/opportunities/useLeadOpportunities';
import useLead from '../../../../queries/leads/useLead';
import usePersonMaticPolicies from '../../../../queries/people/person_policies/usePersonMaticPolicies';
import {
  SCHEDULED_INTERACTIONS_QUERY_KEY,
  usePersonScheduledInteractions
} from '../../../../queries/scheduled_calls/useScheduledInteractions';
import { Subscription } from '../../../../services/ActionCable';
import authInfo from '../../../../services/authInfo';
import { spacings } from '../../../../theme/variables';
import { ActionCableContext } from '../../../../utils/actioncable';
import { findUncompletedCall } from '../../../ScheduleCall/helpers';
import { useLeadDispositionsOptions, useLeadOperationsDispositionChange } from './hooks';

const LeadDispositions = ({ personGid, leadId, leadGid }: { personGid: string; leadId: number; leadGid: string }) => {
  const { data: policies, isFetching: isFetchingPolicies } = usePersonMaticPolicies({
    personGid,
    filters: { lead_id: leadId }
  });

  const {
    data: opportunities,
    isFetching: isFetchingOpportunities,
    refetch: refetchOpportunities
  } = useLeadOpportunities({ leadId });
  const { mutateAsync: updateLeadDisposition } = useUpdateLeadDisposition();

  const [selectedDisposition, setSelectedDisposition] = useState('');

  const [contactMadeModalOpened, setContactMadeModalOpened] = useState(false);
  const openContactMadeModal = () => setContactMadeModalOpened(true);

  const [couldNotContactModalOpened, setCouldNotContactModalOpened] = useState(false);
  const openCouldNotContactModal = () => setCouldNotContactModalOpened(true);

  const [qualifiedModalOpened, setQualifiedModalOpened] = useState(false);
  const openQualifiedModal = () => setQualifiedModalOpened(true);

  const [notQualifiedModalOpened, setNotQualifiedModalOpened] = useState(false);
  const openNotQualifiedModal = () => setNotQualifiedModalOpened(true);

  const [pipelineModalOpened, setPipelineModalOpened] = useState(false);
  const openPipelineModal = () => setPipelineModalOpened(true);

  const [opportunitiesModalOpened, setOpportunitiesModalOpened] = useState(false);
  const openOpportunitiesModal = () => setOpportunitiesModalOpened(true);

  const [lostLeadModalOpened, setLostLeadModalOpened] = useState(false);
  const openLostLeadModal = () => setLostLeadModalOpened(true);

  const [cancelCallModal, setOpenCancelCallModal] = React.useState<boolean>(false);
  const openCancelCallModal = () => setOpenCancelCallModal(true);

  const { data: lead } = useLead(leadGid);

  const { data: scheduledCalls } = usePersonScheduledInteractions(lead?.person_gid, [
    ScheduledInteractionFlow.Calendly,
    ScheduledInteractionFlow.CalendlyFollowUp,
    ScheduledInteractionFlow.ScheduledCall,
    ScheduledInteractionFlow.ScheduledCallIsrs,
    ScheduledInteractionFlow.ScheduledCallV2,
    ScheduledInteractionFlow.ScheduledCallEmail,
    ScheduledInteractionFlow.ScheduledByRecapture
  ]);

  const { mutateAsync: reconcileOpportunitiesWithPolicies, isPending: isReconcilingOpportunities } =
    useReconcileOpportunitiesWithPolicies();
  const {
    currentDispositionType,
    dispositionReason,
    currentAssigneeId,
    currentAssigneeName,
    updateDisposition,
    updateDispositionReason,
    refetchCurrentDisposition,
    isFetchingCurrentDisposition,
    currentDisposition
  } = useLeadOperationsDispositionChange({
    leadId,
    policies: policies || [],
    openContactMadeModal,
    openCouldNotContactModal,
    openQualifiedModal,
    openNotQualifiedModal,
    openPipelineModal,
    openOpportunitiesModal,
    openLostLeadModal,
    openCancelCallModal
  });
  const queryClient = useQueryClient();

  const { data: assignees } = useLeadAssignees({ leadId, licensed: !!currentDisposition?.is_license_required });
  const { mutateAsync: updateLeadAssignee } = useUpdateLeadAssignee();
  const { mutateAsync: takeOverLead } = useTakeOverLead(() =>
    Promise.all([
      refetchCurrentDisposition(),
      queryClient.invalidateQueries({ queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY, lead?.person_gid] })
    ])
  );

  const {
    isLoading: isLoadingDispositionsOptions,
    typeOptions,
    reasonOptions,
    hasReason
  } = useLeadDispositionsOptions(leadId, currentDispositionType);

  const isPending =
    isFetchingOpportunities ||
    isFetchingPolicies ||
    isFetchingCurrentDisposition ||
    isLoadingDispositionsOptions ||
    isReconcilingOpportunities;

  const cable = useContext(ActionCableContext);
  const disableDispositionSelect = typeOptions.length === 1 || isEndDisposition(currentDispositionType) || isPending;

  useEffect(() => {
    let summaryChannel: Subscription;
    if (leadId && cable) {
      summaryChannel = cable.subscriptions.create(
        {
          channel: 'LeadSummaryChannel',
          lead_id: leadId
        },
        {
          received: () => {
            refetchCurrentDisposition();
          }
        }
      );
    }

    return () => {
      if (leadId && cable) {
        summaryChannel.unsubscribe();
      }
    };
  }, [cable, leadId, refetchCurrentDisposition]);

  const shouldDisableReasons = () => {
    if (currentDisposition?.disposition_type === DispositionType.Pipeline && !dispositionReason) {
      return true;
    }

    if (!(reasonOptions && dispositionReason)) {
      return false;
    }

    if (currentDisposition?.is_license_required && !authInfo.features.edit_disposition_reason) {
      return true;
    }

    return (
      reasonOptions?.filter((option: any) => option.unavailable).length === 1 ||
      reasonOptions?.find((option: any) => option.key === dispositionReason)?.unavailable
    );
  };

  const uncompletedCall = findUncompletedCall(scheduledCalls);
  const showCancelCallModal = cancelCallModal && uncompletedCall && lead?.person_gid;

  const isCurrentAgentLicensed = !!assignees?.find(assignee => assignee.id === authInfo.currentUserId);

  return (
    <div className="lead-operations-form">
      <FlexBox columnDirection>
        <Text>Disposition</Text>
        <Select
          css={css`
            margin-top: ${spacings.px4}px;
          `}
          name="disposition"
          placeholder="Please select a type"
          hideSelectedOptions
          value={currentDispositionType}
          options={typeOptions}
          onChange={({ target }) => {
            const dispositionType = target.value;

            if (isDispositionLostOrSold(dispositionType)) {
              reconcileOpportunitiesWithPolicies({ leadId })
                .then(() => refetchOpportunities())
                .then(({ data: freshOpportunities }) => {
                  setSelectedDisposition(dispositionType);
                  updateDisposition(dispositionType, freshOpportunities || []);
                });
            } else {
              setSelectedDisposition(dispositionType);
              updateDisposition(dispositionType, opportunities || []);
            }
          }}
          disabled={disableDispositionSelect}
          controlTestId="lead-disposition-type-select"
        />
      </FlexBox>
      {hasReason && (
        <FlexBox columnDirection mt={spacings.px16}>
          <Text>Disposition reason</Text>
          <Select
            css={css`
              margin-top: ${spacings.px4}px;
            `}
            name="disposition-reason"
            placeholder={shouldDisableReasons() ? '—' : 'Please select a reason'}
            value={dispositionReason}
            options={reasonOptions.map(({ key, value }) => ({ key, value }))}
            onChange={({ target }) => updateDispositionReason(target.value)}
            disabled={shouldDisableReasons()}
            controlTestId="lead-disposition-reason-select"
          />
        </FlexBox>
      )}
      <FlexBox columnDirection mt={spacings.px16}>
        <Text>Assignee</Text>
        {!authInfo.features.edit_lead_assignee ? (
          <Select
            css={css`
              margin-top: ${spacings.px4}px;
            `}
            name="assignee"
            placeholder={currentAssigneeName}
            value={currentAssigneeId}
            options={[{ key: authInfo.currentUserId!, value: 'Assign to me' }]}
            disabled={
              !isCurrentAgentLicensed ||
              isEndDisposition(currentDispositionType) ||
              isPending ||
              currentAssigneeId === authInfo.currentUserId
            }
            controlTestId="assignee-select"
            onChange={() => takeOverLead({ leadId, assigneeId: authInfo.currentUserId! })}
          />
        ) : (
          <Select
            css={css`
              margin-top: ${spacings.px4}px;
            `}
            name="assignee"
            placeholder="Please select an assignee"
            value={currentAssigneeId?.toString()}
            options={assignees?.map(({ id, name }) => ({ key: id.toString(), value: name })) || []}
            onChange={({ target }) => updateLeadAssignee({ leadId, assigneeId: target.value })}
            disabled={isEndDisposition(currentDispositionType) || isPending}
            controlTestId="assignee-select"
            ordered
          />
        )}
      </FlexBox>
      {contactMadeModalOpened && (
        <ContactMadeModal leadId={leadId} closeModal={() => setContactMadeModalOpened(false)} />
      )}
      {couldNotContactModalOpened && (
        <CouldNotContactModal
          leadId={leadId}
          closeModal={() => setCouldNotContactModalOpened(false)}
          onDispositionUpdated={() => {
            openCancelCallModal();
          }}
        />
      )}
      {qualifiedModalOpened && <QualifiedModal leadId={leadId} closeModal={() => setQualifiedModalOpened(false)} />}
      {notQualifiedModalOpened && (
        <NotQualifiedModal
          leadId={leadId}
          closeModal={() => setNotQualifiedModalOpened(false)}
          onDispositionUpdated={() => {
            openCancelCallModal();
          }}
        />
      )}
      {pipelineModalOpened && <PipelineModal leadId={leadId} closeModal={() => setPipelineModalOpened(false)} />}
      {opportunitiesModalOpened && (
        <TrailingOpportunitiesModal
          leadId={leadId}
          opportunities={opportunities || []}
          dispositionType={selectedDisposition}
          closeModal={() => setOpportunitiesModalOpened(false)}
          onDispositionUpdated={() => {
            refetchOpportunities();
            setOpenCancelCallModal(true);
          }}
        />
      )}
      {lostLeadModalOpened && (
        <Modal
          dangerModal
          confirmHandler={() => {
            updateLeadDisposition({ leadId, disposition_type: DispositionType.Lost })
              .then(() => {
                setLostLeadModalOpened(false);
                return refetchCurrentDisposition();
              })
              .catch(_e => null);
          }}
          cancelHandler={() => setLostLeadModalOpened(false)}
          title="Mark lead as lost (Archive)"
          confirmText="Archive"
          onDispositionUpdated={() => {
            openCancelCallModal();
          }}
        >
          Are you sure you want to mark this lead as lost? The lead has sold opportunities.
        </Modal>
      )}
      {showCancelCallModal && (
        <CancelTrailingScheduledCallModal
          assignee={currentDisposition?.assignee}
          personGid={lead.person_gid}
          scheduledCall={uncompletedCall}
          onCloseModal={() => setOpenCancelCallModal(false)}
        />
      )}
    </div>
  );
};

export default LeadDispositions;
