import classNames from 'classnames';
import * as React from 'react';
import SimpleSelect, { components, ControlProps } from 'react-select';
import CreatableSelect from 'react-select/creatable';

import { shallowEqual } from '../../../utils/array';
import { except } from '../../../utils/object';

const Control = (props: ControlProps<any, any>) => {
  const computedInnerProps = (props.selectProps as any)['data-testid']
    ? { ...props.innerProps, 'data-testid': (props.selectProps as any)['data-testid'] }
    : props.innerProps;

  return (
    <components.Control
      {...props}
      innerProps={computedInnerProps}
      className={classNames(props.className, 'form-control')}
    />
  );
};

const SELECT_CLASS_NAME = 'react-select';
const emptyArray = [] as any[];

interface SelectProps {
  options: Record<string | number, string | number | undefined>[];
  labelName?: string;
  valueName?: string;
  ordered?: boolean;
  name?: string;
  onChange: (args: any) => void;
  showResetButton?: boolean;
  small?: boolean;
  transparent?: boolean;
  outlined?: boolean;
  createOptionFromSearch?: boolean;
  className?: string;
  disabled?: boolean;
  value: any;
  placeholder?: string;
  formatOptionLabel?: (args: Record<string, string | undefined>) => JSX.Element;
  menuOptions?: JSX.Element;
  isSearchable?: boolean;
  hideSelectedOptions?: boolean;
  menuPlacement?: 'top' | 'bottom' | 'auto';
}

interface Option {
  label: string;
  value: string;
}

const Menu = (props: any) => {
  return (
    <components.Menu {...props}>
      {props.children}
      {props.selectProps.menuOptions}
    </components.Menu>
  );
};

const ClearIndicator = (props: any) =>
  components.ClearIndicator({ ...props, innerProps: { ...props.innerProps, 'data-testid': 'clear-indicator' } });

const Select = ({
  options = emptyArray,
  labelName = 'value',
  valueName = 'key',
  name = '',
  placeholder = 'Please select an option',
  disabled = false,
  ordered = false,
  className = '',
  createOptionFromSearch = false,
  showResetButton = false,
  small = false,
  transparent = false,
  outlined = false,
  hideSelectedOptions = false,
  onChange,
  value,
  isSearchable,
  ...props
}: SelectProps): any => {
  const [selectOptions, setSelectOptions] = React.useState(emptyArray);
  const [createdOption, setCreatedOption] = React.useState<Option | null>(null);

  React.useLayoutEffect(() => {
    let selectOptions = options.map(option => ({
      label: option[labelName],
      value: option[valueName],
      ...except(option, 'key', 'value')
    }));

    if (createdOption) {
      selectOptions = selectOptions.concat(createdOption);
    }

    if (ordered) {
      selectOptions.sort((a, b) => a.label.toString().localeCompare(b.label.toString()));
    }

    setSelectOptions(selectOptions);
  }, [createdOption, options, labelName, valueName, ordered]);

  const onValueChange = (item: any) => {
    if (item?.value !== 0 && !item?.value && !showResetButton) {
      return;
    }

    onChange(name ? { target: { name, value: item?.value } } : item?.value);
  };

  const createFromSearch = (inputValue: string) => {
    setCreatedOption({ label: inputValue, value: inputValue });

    onValueChange({ value: inputValue });
  };

  const getCustomStyles = () => {
    let styles = {};

    if (small) {
      styles = { height: 32, minHeight: 32, paddingTop: 0, paddingBottom: 0 };
    }

    if (transparent) {
      styles = { ...styles, border: 0, background: 0 };
    }

    if (outlined) {
      styles = { ...styles, borderColor: '#000 !important' };
    }

    return small || transparent || outlined
      ? {
          styles: {
            control: (base: any) => ({ ...base, ...styles }),
            input: (base: any) => ({ ...base, margin: 0 }),
            menu: (base: any) => ({ ...base, fontSize: '14px' })
          }
        }
      : {};
  };

  const customStyles = getCustomStyles();

  const availableOptions = selectOptions.filter(
    (option: any) => typeof option.unavailable === 'undefined' || !option.unavailable
  );

  const pickedOption = selectOptions.find((option: any) => {
    if (Array.isArray(option.value)) {
      return shallowEqual(option.value, value);
    }

    return option.value === value || null;
  });

  const SelectComponent = (createOptionFromSearch ? CreatableSelect : SimpleSelect) as React.ElementType;

  (SelectComponent as any).displayName = 'SimpleSelect';

  return (
    <SelectComponent
      {...props}
      {...customStyles}
      {...(createOptionFromSearch ? { onCreateOption: createFromSearch } : {})}
      placeholder={placeholder}
      value={pickedOption}
      onChange={onValueChange}
      options={availableOptions}
      className={classNames(className, SELECT_CLASS_NAME)}
      classNamePrefix={SELECT_CLASS_NAME}
      components={{ Control, IndicatorSeparator: null, ClearIndicator, Menu }}
      isClearable={showResetButton}
      isDisabled={disabled}
      isSearchable={isSearchable}
      hideSelectedOptions={hideSelectedOptions}
    />
  );
};

export default Select;
