import * as Sentry from '@sentry/react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as React from 'react';
import { create } from 'zustand';

import { apiBaseUrl } from '../services/api';

const MINUTES = 10;
const MINUTE_MILLISECONDS = 60000;

const POLLING_DELAY = MINUTE_MILLISECONDS * MINUTES;

const useAppVersionQuery = () =>
  useQuery({
    queryKey: ['app-current-version'],
    queryFn: () => axios.head(`${apiBaseUrl()}/api/current_version`),
    refetchInterval: POLLING_DELAY,
    refetchIntervalInBackground: true
  });

const useAppVersion = create(() => ({ initial: '', current: '' }));

export const useSyncVersions = () => {
  const { data, isSuccess } = useAppVersionQuery();
  const appVersion = (data?.['headers']?.['x-app-version'] as string) || '';

  if (isSuccess && !appVersion) {
    Sentry.captureMessage('Could not retrieve app version from headers.', 'error');
  }

  React.useEffect(() => {
    useAppVersion.setState(currentState => ({ initial: currentState.initial || appVersion, current: appVersion }));
  }, [appVersion]);
};

export default useAppVersion;
