/** @jsxImportSource @emotion/react */
import './styles.scss';

import { css } from '@emotion/react';
import classNames from 'classnames';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container } from 'reactstrap';

import { dig, verifyPromise } from '../../../utils/object';
import FullScreenModal from '../FullScreenModal';
import Loader from '../Loader';

const FullScreenFormModal = ({
  open,
  title,
  cancelText,
  cancelHandler,
  confirmText,
  confirmHandler,
  isSubmittingText,
  deleteText,
  deleteHandler,
  className,
  initialValues,
  validationSchema,
  validate,
  enableReinitialize,
  renderForm,
  formRef
}: any) => {
  const onSubmit = (values: any, { setSubmitting, ...rest }: any) =>
    verifyPromise(confirmHandler(values, rest)).catch(() => setSubmitting(false));

  return (
    open && (
      <FullScreenModal title={title} className={classNames(className)}>
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={validate}
          enableReinitialize={enableReinitialize}
          onSubmit={onSubmit}
        >
          {formProps => {
            const formValues = dig(formProps, 'values') || {};
            const showErrors = Object.keys(formProps.touched).length === Object.keys(formValues).length;

            return (
              <Form className="full-screen-modal-form" autoComplete="off">
                <div className="full-screen-modal-form__content">{renderForm({ ...formProps, showErrors })}</div>
                <div className="full-screen-modal-form__controls">
                  <Container>
                    <Col sm={12} className="btn-container d-flex">
                      {deleteHandler && (
                        <div className="flex-grow-1 flex-shrink-0">
                          <Button onClick={deleteHandler} color="danger" className="qa-delete-button">
                            {deleteText}
                          </Button>
                        </div>
                      )}
                      <div>
                        <Button onClick={cancelHandler} className="cancel-button">
                          {cancelText}
                        </Button>
                        <Button
                          color="primary"
                          type="submit"
                          className="confirm-button"
                          disabled={formProps.isSubmitting}
                        >
                          <span className="d-inline-block align-middle">
                            {formProps.isSubmitting ? isSubmittingText : confirmText}
                          </span>
                          {formProps.isSubmitting && (
                            <span className="ml-2">
                              <Loader
                                height={14}
                                width={14}
                                customCss={css`
                                  display: inline;
                                `}
                              />
                            </span>
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Container>
                </div>
              </Form>
            );
          }}
        </Formik>
      </FullScreenModal>
    )
  );
};

FullScreenFormModal.propTypes = {
  open: PropTypes.bool,
  enableReinitialize: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cancelText: PropTypes.string,
  cancelHandler: PropTypes.func,
  confirmText: PropTypes.string,
  isSubmittingText: PropTypes.string,
  confirmHandler: PropTypes.func,
  deleteText: PropTypes.string,
  deleteHandler: PropTypes.func,
  className: PropTypes.string,
  initialValues: PropTypes.any,
  validationSchema: PropTypes.any,
  validate: PropTypes.any,
  renderForm: PropTypes.func.isRequired,
  formRef: PropTypes.func
};

FullScreenFormModal.defaultProps = {
  open: false,
  enableReinitialize: false,
  title: '',
  cancelText: 'Cancel',
  cancelHandler: null,
  confirmText: 'Submit',
  isSubmittingText: 'Submitting...',
  confirmHandler: null,
  deleteText: 'Delete',
  deleteHandler: null,
  color: 'primary',
  className: '',
  isSubmitting: false,
  initialValues: null,
  validationSchema: null,
  validate: null,
  confirmationInProgress: false,
  formRef: () => {}
};

export default FullScreenFormModal;
