import React from 'react';

import FiltersSubHeader from '../../components/common/FiltersSubHeader/FiltersSubHeader';
import Container from '../../components/core/Container';
import TablePlaceholder from '../../components/TablePlaceholder';
import { useDocumentTitle } from '../../hooks';
import useAssignees from '../../queries/assignees/useAssignees';
import usePeople, { PeopleDashboardQuery } from '../../queries/people/usePeople';
import authInfo from '../../services/authInfo';
import { spacings } from '../../theme/variables';
import PeopleFilter from './PeopleFilter';
import PeopleList from './PeopleList';

const PEOPLE_FILTERS_STORAGE_KEY = 'peopleFilters';

const defaultFilters = () => ({ agent_ids: [authInfo.currentUserId] }) as any as PeopleDashboardQuery;

const restoreFilters = () => {
  const filters = JSON.parse(localStorage.getItem(PEOPLE_FILTERS_STORAGE_KEY) || '{}');

  return filters || defaultFilters();
};

const PeopleDashboard = () => {
  const [query, setQuery] = React.useState<PeopleDashboardQuery>(() => restoreFilters());

  const { data: peopleDashboard, isLoading: isLoadingPeople } = usePeople(query);
  const { data: assignees } = useAssignees();

  useDocumentTitle('Customers dashboard');

  return (
    <Container fitParentWidth>
      <FiltersSubHeader headerText="Customers">
        <PeopleFilter
          filters={query}
          assignees={assignees}
          onFilterReset={() => {
            setQuery(defaultFilters());
            localStorage.setItem(PEOPLE_FILTERS_STORAGE_KEY, JSON.stringify(defaultFilters()));
          }}
          onFilterSubmit={filters => {
            setQuery(filters);
            localStorage.setItem(PEOPLE_FILTERS_STORAGE_KEY, JSON.stringify(filters));
          }}
        />
      </FiltersSubHeader>
      <Container fitParentWidth mt={spacings.px8} ph={spacings.px8}>
        {isLoadingPeople ? (
          <TablePlaceholder testId="people-dashboard-loader" />
        ) : (
          <PeopleList people={peopleDashboard?.people} />
        )}
      </Container>
    </Container>
  );
};

export default PeopleDashboard;
