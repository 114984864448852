/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import * as yup from 'yup';

import AddHomeFormModal from '../../components/AddHomeForm';
import Blockquote from '../../components/core/Blockquote';
import AddButton from '../../components/core/buttons/AddButton';
import { ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import FormLoader from '../../components/core/FormLoader';
import BaseForm from '../../components/core/forms/BaseForm';
import buildUIFlow from '../../components/core/forms/uiFlowBuilder';
import Heading from '../../components/core/Heading';
import Paragraph from '../../components/core/Paragraph';
import { isServiceLead } from '../../components/DispositionsModals/dispositionsHelper';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../constants/addressForm';
import featureFlags from '../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import useToggle from '../../hooks/useToggle';
import { IAddress, IAddressSuggestion, IHome, IOpportunity, IPayloadAddress, IPerson } from '../../interfaces';
import { areAddressesEqual } from '../../interfaces/IAddress';
import { DataCollectionStepsKey } from '../../interfaces/IDataCollection';
import DatapointKey from '../../interfaces/IDatapoint';
import { isExtendedHomeInfoAvailable } from '../../interfaces/IHome';
import { OpportunityStatus } from '../../interfaces/IOpportunity';
import { assetIsNotRented } from '../../interfaces/IPersonAsset';
import { InsurableInterest } from '../../interfaces/IPolicyType';
import { useAttachHomeToDataCollection } from '../../queries/leads/data_collection/useDataCollectionHomes';
import { useReconcileOpportunities } from '../../queries/leads/data_collection/useDataCollectionOpportunities';
import {
  useLeadOpportunitiesCoinsureds,
  useUpdateLeadOpportunityCoinsureds
} from '../../queries/leads/opportunities/useLeadOpportunities';
import usePersonAddresses from '../../queries/people/usePersonAddresses';
import {
  FLORIDA_HOME_QUALIFICATION_GROUP,
  Qualification,
  qualifyPersonAsset
} from '../../queries/people/usePersonAssetQualification';
import { updateHome, useCreatePersonHome } from '../../queries/people/usePersonHomes';
import usePersonRelatedPeople from '../../queries/people/usePersonRelatedPeople';
import {
  HOME_BASIC_INFO_FLOW_V2,
  HOME_CROSS_SALE_INTENT_FLOW_V1,
  usePersonAssetsUIFlows,
  usePersonUIFlow,
  useSaveAnswers
} from '../../queries/workflows/useUiFlow';
import analytics from '../../services/analytics';
import { isISR } from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { renameKeys, slice } from '../../utils/object';
import { addressSchema, REQUIRED_MESSAGE, requiredAddressSchema, requiredField } from '../../utils/yupRules';
import { StepContentProps } from '../GuidedDataCollection';
import { LLC_VALUE, TRUST_VALUE } from '../GuidedSellingExperience/_helpers';
import HomeProfileQuestions from './_components/HomeProfileQuestions';
import LeadWarnings from './_components/LeadWarnings';
import { SyncRefAndValues } from './_helpers';

// redo to FieldArray as improvement

interface IPersonHome {
  property_address?: IAddress;
  property_type?: IHome['property_type'];
  person_mailing_address?: IPerson['mailing_address'];
  ownership?: IHome['ownership'];
}
interface FormValues {
  [key: string]: IPersonHome;
}

interface HomeAssetOpportunityPair {
  readonly asset: IHome;
  readonly opportunity: IOpportunity;
}

type HomeAssetGid = string;

export const isYearBuiltDisplayed = ({
  address,
  ownership
}: {
  address: IHome['address'] | undefined;
  ownership: IHome['ownership'] | undefined;
}) => {
  if (!ownership) {
    return false;
  } else {
    if (!assetIsNotRented({ ownership })) {
      return false;
    }

    if (!isISR()) {
      return true;
    }

    return address?.state === 'FL';
  }
};

const validationSchema = (
  homeAssetsAndOpportunities: Record<HomeAssetGid, HomeAssetOpportunityPair>,
  primaryOpportunityAsset: IHome | undefined
) => {
  const assetOpportunityPairs = Object.values(homeAssetsAndOpportunities);

  return yup.object({
    ...assetOpportunityPairs?.reduce((acc, assetOpportunityPair) => {
      const personHome = assetOpportunityPair.asset;
      const opportunity = assetOpportunityPair.opportunity;

      if (opportunity.is_data_collection_enabled && opportunity.status !== OpportunityStatus.NotQualified) {
        return {
          ...acc,
          [personHome.gid]: yup
            .object()
            .shape({
              [DatapointKey.PropertyAddress]: requiredAddressSchema,
              [DatapointKey.PersonMailingAddress]:
                personHome.gid === primaryOpportunityAsset?.gid ? requiredAddressSchema : yup.object(),
              [DatapointKey.PropertyUsageType]: requiredField,
              [DatapointKey.PropertyOwnershipType]: requiredField,
              [DatapointKey.PropertyYearBuilt]: yup
                .number()
                .when(
                  [DatapointKey.PropertyAddress, DatapointKey.PropertyOwnershipType],
                  ([address, property_ownership_type], schema) => {
                    if (isYearBuiltDisplayed({ address, ownership: property_ownership_type })) {
                      return schema
                        .positive('Must be a positive number')
                        .min(1000, 'Must be greater than or equal to 1000')
                        .max(new Date().getFullYear(), 'Must be less than or equal to current year')
                        .required(REQUIRED_MESSAGE);
                    }

                    return schema;
                  }
                ),
              [DatapointKey.PropertyOnTheDeed]: yup
                .array()
                .ensure()
                .when([DatapointKey.PropertyOwnershipType], ([property_ownership_type], schema) => {
                  if (isExtendedHomeInfoAvailable({ ownership: property_ownership_type })) {
                    return schema.min(1, REQUIRED_MESSAGE);
                  }

                  return schema;
                }),
              [DatapointKey.PropertyLLCName]: yup
                .string()
                .ensure()
                .when([DatapointKey.PropertyOnTheDeed], ([property_on_the_deed], schema) => {
                  return property_on_the_deed.includes('llc') ? schema.required(REQUIRED_MESSAGE) : schema;
                }),
              [DatapointKey.PropertyTrustName]: yup
                .string()
                .ensure()
                .when([DatapointKey.PropertyOnTheDeed], ([property_on_the_deed], schema) => {
                  return property_on_the_deed.includes('trust') ? schema.required(REQUIRED_MESSAGE) : schema;
                })
            })
            .uniqueProperty(
              DatapointKey.PropertyAddress,
              "Address should be unique across customer's homes",
              (left, right) => {
                const leftAddress = left as IAddress;
                const rightAddress = right as IAddress;

                return areAddressesEqual(leftAddress, rightAddress);
              }
            )
        };
      }

      return {
        ...acc,
        [personHome.gid]: yup.object().shape({
          [DatapointKey.PropertyAddress]: addressSchema,
          [DatapointKey.PersonMailingAddress]:
            personHome.gid === primaryOpportunityAsset?.gid ? addressSchema : yup.object(),
          [DatapointKey.PropertyUsageType]: yup.string(),
          [DatapointKey.PropertyOwnershipType]: yup.string(),
          [DatapointKey.PropertyYearBuilt]: yup
            .number()
            .when(
              [DatapointKey.PropertyAddress, DatapointKey.PropertyOwnershipType],
              ([address, property_ownership_type], schema) => {
                if (isYearBuiltDisplayed({ address, ownership: property_ownership_type })) {
                  return schema
                    .positive('Must be a positive number')
                    .min(1000, 'Must be greater than or equal to 1000')
                    .max(new Date().getFullYear(), 'Must be less than or equal to current year');
                }

                return schema;
              }
            )
        })
      };
    }, {})
  });
};

const HomeProfilePage = ({
  onSubmit,
  dataCollection,
  page,
  isDataEditingForbidden,
  onLeadClosed
}: StepContentProps): JSX.Element => {
  const { personGid, person, leadGid, lead, customerDataCompleteness } = useGuidedSellingExperienceContext();
  const [personAssetsQualification, setPersonAssetsQualification] = React.useState<
    Record<string, Qualification | false>
  >({});

  // enableReinitialize break formik.isSubmitting; refactor and remove this hack
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const primaryOpportunity = page.opportunities?.find(op => op.primary);
  const primaryOpportunityAsset = primaryOpportunity?.assets?.[0] as IHome | undefined;

  const {
    data: personAddresses,
    isFetchedAfterMount: personAddressesFetched,
    refetch: refetchPersonAddresses
  } = usePersonAddresses(personGid);

  const homeAssetsAndOpportunities = (page.opportunities || []).reduce(
    (acc, opp) => {
      const previousObjCopy = { ...acc };

      opp.assets?.forEach(asset => {
        previousObjCopy[asset.gid] = { asset: asset as IHome, opportunity: opp };
      });

      return previousObjCopy;
    },
    {} as Record<HomeAssetGid, HomeAssetOpportunityPair>
  );

  const { data: leadOpportunitiesCoinsureds } = useLeadOpportunitiesCoinsureds({
    leadId: lead?.id,
    opportunitiesIds: page.opportunities?.map(op => op.id) || []
  });

  const currentCoinsuredsPerOpportunity = (page.opportunities || []).reduce(
    (acc, opportunity, index) => {
      return { ...acc, [opportunity.id]: leadOpportunitiesCoinsureds[index]?.data?.people || [] };
    },
    {} as Record<IOpportunity['id'], IPerson[]>
  );

  const assetsGids = Object.keys(homeAssetsAndOpportunities);
  const homeAssets = Object.values(homeAssetsAndOpportunities).map(pair => pair.asset);

  const [addHome, toggleAddHome] = useToggle(false);

  const leadId = lead?.id;

  const {
    data: personAssetUIFlows,
    isFetching: isPersonAssetsWorkflowsFetching,
    isFetchedAfterMount: personAssetsWorkflowsFetched
  } = usePersonAssetsUIFlows({
    uiFlowKey: HOME_BASIC_INFO_FLOW_V2,
    assetsGids,
    personGid
  });

  const { data: relatedPeople, isPending: isPendingRelatedPeople } = usePersonRelatedPeople(personGid);

  const { mutateAsync: saveAnswers } = useSaveAnswers();

  const { mutateAsync: attachHomeToDataCollection, isPending: isAttachingHomeToDataCollection } =
    useAttachHomeToDataCollection();
  const { mutateAsync: createHome, isPending: isCreatingHome } = useCreatePersonHome();
  const { mutateAsync: updateOpportunityCoinsureds } = useUpdateLeadOpportunityCoinsureds();

  const intermediateValuesRef = React.useRef<FormValues>();

  const { data: homeCrossSaleIntentUIFlowData, isFetching: isFetchingHomeCrossSaleIntentUIFlow } = usePersonUIFlow({
    uiFlowKey: page.is_skippable ? HOME_CROSS_SALE_INTENT_FLOW_V1 : '',
    personGid
  });

  const homeCrossSaleIntentUIFlow = React.useMemo(
    () => buildUIFlow({ uiFlowResponse: homeCrossSaleIntentUIFlowData }),
    [homeCrossSaleIntentUIFlowData]
  );

  const submitHomeCrossSaleIntentUIFlow = ({ skip }: { skip: boolean }) => {
    const uiFlowAnswers =
      homeCrossSaleIntentUIFlow!.answersForFormValues({
        personGid: personGid!,
        engagementGid: leadGid!,
        assetGid: undefined,
        formValues: {
          [DatapointKey.PersonIsInterestedInHomeInsurance]: skip ? 'skipped' : 'qualified',
          [DatapointKey.PersonHomeInsuranceRejectedReason]: ''
        }
      }) || [];

    return saveAnswers({
      uiFlowKey: HOME_CROSS_SALE_INTENT_FLOW_V1,
      answers: uiFlowAnswers
    });
  };

  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();

  if (!personAddressesFetched || !personAssetsWorkflowsFetched) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  const personHomesInitialValues = (homeAssets || []).reduce(
    (acc, personHome, index) => {
      const homeProfileUIFlow = buildUIFlow({
        uiFlowResponse: personAssetUIFlows?.[index]?.data?.ui_flow
      });

      return {
        ...acc,
        [personHome.gid]: {
          ...homeProfileUIFlow?.initialValues(),
          ...intermediateValuesRef.current?.[personHome.gid]
        }
      };
    },
    {} as {
      [key: string]: Record<string, any>;
    }
  );

  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      enableReinitialize
      controlsWidth={320}
      submitText="Next"
      submitTestId="submit-home-profile-button"
      cancelTestId="skip-home-profile-button"
      cancelVariant={ButtonVariant.Secondary}
      validationSchema={validationSchema(homeAssetsAndOpportunities, primaryOpportunityAsset)}
      disabled={
        isPersonAssetsWorkflowsFetching || isAttachingHomeToDataCollection || isSubmitting || isDataEditingForbidden
      }
      loading={
        isPersonAssetsWorkflowsFetching ||
        isPendingRelatedPeople ||
        isAttachingHomeToDataCollection ||
        isSubmitting ||
        isFetchingHomeCrossSaleIntentUIFlow
      }
      initialValues={personHomesInitialValues}
      onSubmit={async ({ ...homes }) => {
        setIsSubmitting(true);

        const homesToSubmit = slice(homes, ...Object.keys(personHomesInitialValues));
        const homesGidsToSubmit = Object.keys(homesToSubmit).filter(
          homeGid =>
            homeAssetsAndOpportunities[homeGid]?.opportunity?.is_data_collection_enabled &&
            homeAssetsAndOpportunities[homeGid]?.opportunity?.status !== OpportunityStatus.NotQualified
        );

        // assetGid may be changed after PropertyAddress update
        // in that case we have to operate with a new gid and ignore stale
        const homesResponse = await Promise.all([
          ...homesGidsToSubmit.map(homeGid =>
            updateHome({
              personGid: personGid!,
              homeGid,
              data: { address: homesToSubmit[homeGid]![DatapointKey.PropertyAddress] }
            })
          )
        ]);

        // Update workflows for homes, person mailing address
        await Promise.all([
          ...homesGidsToSubmit.map((staleHomeGid, index) => {
            const homeProfileUIFlow = buildUIFlow({
              uiFlowResponse: personAssetUIFlows?.[index]?.data?.ui_flow
            });

            const homeGid = homesResponse[index]!.home.gid;

            const answers =
              homeProfileUIFlow!
                .answersForFormValues({
                  formValues: homes[staleHomeGid]!,
                  personGid: personGid!,
                  engagementGid: leadGid!,
                  assetGid: homeGid
                })
                .filter(
                  answer =>
                    answer.question_key !== DatapointKey.PersonMailingAddress ||
                    primaryOpportunityAsset?.gid === staleHomeGid
                ) || [];

            const currentCoinsuredsGids =
              currentCoinsuredsPerOpportunity[homeAssetsAndOpportunities[staleHomeGid]!.opportunity.id]?.map(
                p => p.gid
              ) || [];

            const deedListAnswerValue = answers.find(a => a.question_key === DatapointKey.PropertyOnTheDeed)?.value as
              | string[]
              | undefined;
            const personGidsOnTheDeed = deedListAnswerValue?.filter(
              (v: string) => ![TRUST_VALUE, LLC_VALUE].includes(v)
            );
            if (personGidsOnTheDeed?.length) {
              updateOpportunityCoinsureds({
                leadId: leadId!,
                opportunityId: homeAssetsAndOpportunities[staleHomeGid]!.opportunity.id,
                peopleGids: [
                  ...new Set(currentCoinsuredsGids.concat(personGidsOnTheDeed.filter(gid => gid !== personGid)))
                ]
              });
            }

            return saveAnswers({
              uiFlowKey: HOME_BASIC_INFO_FLOW_V2,
              answers
            });
          })
        ]);

        const assetGids = homesResponse.map(({ home }) => home.gid);

        const { data_collection: freshDataCollection } = await reconcileOpportunities({
          leadId: lead!.id,
          assetsGids: assetGids,
          insurableInterest: InsurableInterest.RealProperty
        });

        const freshPage = freshDataCollection.pages.find(p => p.key === DataCollectionStepsKey.HomeProfile)!;

        const freshHomeAssetsAndOpportunities = (freshPage.opportunities || []).reduce(
          (acc, opp) => {
            const previousObjCopy = { ...acc };

            opp.assets?.forEach(asset => {
              previousObjCopy[asset.gid] = { asset: asset as IHome, opportunity: opp };
            });

            return previousObjCopy;
          },
          {} as Record<HomeAssetGid, HomeAssetOpportunityPair>
        );

        // Run qualification for each home
        await Promise.all(
          assetGids.map(assetGid => {
            const opportunity = freshHomeAssetsAndOpportunities[assetGid]!.opportunity;

            if (
              opportunity.qualification_available &&
              !(featureFlags.cstGuidedSellingExperience && isServiceLead(dataCollection.current_disposition))
            ) {
              return qualifyPersonAsset({
                personGid,
                assetGid,
                leadGid,
                qualificationGroupKey: FLORIDA_HOME_QUALIFICATION_GROUP
              });
            } else {
              return { qualification: false as const };
            }
          })
        ).then(responses => {
          const qualificationState = {} as Record<string, Qualification | false>;
          let shouldNotMoveForward = false;
          assetGids.forEach((gid, index) => {
            qualificationState[gid] = responses[index]!.qualification;

            if (!shouldNotMoveForward) {
              shouldNotMoveForward =
                responses[index]!.qualification &&
                (responses[index]!.qualification as Qualification).result === 'disqualified';
            }
          });

          setPersonAssetsQualification(qualificationState);

          if (shouldNotMoveForward) {
            return null;
          } else {
            return onSubmit();
          }
        });
        !!homeCrossSaleIntentUIFlow && submitHomeCrossSaleIntentUIFlow({ skip: false });

        setIsSubmitting(false);
      }}
      renderForm={({ values }) => {
        const personHomeGids = Object.keys(values);
        return (
          <>
            <LeadWarnings dataCollection={dataCollection} />
            {addHome && (
              <AddHomeFormModal
                cancelHandler={toggleAddHome}
                defaultAddressOptions={
                  (personAddresses?.map(address => renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)) ||
                    []) as IAddressSuggestion[]
                }
                isLoading={isCreatingHome || isAttachingHomeToDataCollection}
                confirmHandler={async values => {
                  const data = await createHome({
                    personGid: personGid!,
                    data: {
                      address: values[DatapointKey.PropertyAddress] as unknown as IPayloadAddress,
                      residency_type: values.residence_type
                    }
                  });
                  analytics.track('Home added', {
                    lead_gid: leadGid,
                    person_gid: personGid,
                    place: 'guided_selling_experience',
                    home_gid: data.home.gid
                  });
                  refetchPersonAddresses();
                  await attachHomeToDataCollection({
                    assetGid: data.home.gid,
                    leadId: leadId!
                  });
                  toggleAddHome();
                }}
              />
            )}
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <Heading type="h4">Home profile</Heading>
              <AddButton onClick={toggleAddHome} content="Add home" disabled={isDataEditingForbidden} />
            </FlexBox>

            {!primaryOpportunity && (
              <Blockquote
                pt={spacings.px24}
                ml={spacings.px20}
                text={
                  <Paragraph type="large">
                    Thank you! Our insurance agents can shop for your home insurance. Some carriers provide up to 40%
                    discount if bundle.
                  </Paragraph>
                }
              />
            )}

            <SyncRefAndValues values={values} intermediateValuesRef={intermediateValuesRef} />
            {personAssetUIFlows.map(({ data }, index) => {
              const personHomeGid = personHomeGids[index]!;
              const homeFormValues = values[personHomeGid];
              const homeProfileUIFlow = buildUIFlow({ uiFlowResponse: data?.ui_flow });
              const homeAssetAndOpportunity = homeAssetsAndOpportunities[personHomeGid];

              if (!homeProfileUIFlow || !homeFormValues || !homeAssetAndOpportunity) {
                return null;
              }

              const isHomePrimary = personHomeGid === primaryOpportunityAsset?.gid;

              return (
                <React.Fragment key={personHomeGid}>
                  {index > 0 && (
                    <Container
                      fitParentWidth
                      mt={spacings.px24}
                      backgroundColor={colors.grey30}
                      css={css`
                        height: 1px;
                      `}
                    />
                  )}
                  <HomeProfileQuestions
                    index={index}
                    person={person!}
                    leadGid={leadGid!}
                    leadId={leadId!}
                    personHomeGid={personHomeGid}
                    page={page}
                    opportunity={homeAssetAndOpportunity.opportunity}
                    home={homeAssetAndOpportunity.asset}
                    otherOpportunities={(page.opportunities || []).filter(
                      op => op.id !== homeAssetAndOpportunity.opportunity.id
                    )}
                    personAssetQualification={personAssetsQualification[personHomeGid]!}
                    homeProfileUIFlow={homeProfileUIFlow}
                    isHomePrimary={isHomePrimary}
                    isDataEditingForbidden={isDataEditingForbidden}
                    customerDataCompleteness={customerDataCompleteness}
                    personAddresses={personAddresses}
                    formValues={homeFormValues}
                    relatedPeople={relatedPeople}
                    onLeadClosed={onLeadClosed}
                    onDisqualify={() =>
                      setPersonAssetsQualification(current => ({ ...current, [personHomeGid]: false }))
                    }
                  />
                </React.Fragment>
              );
            })}
          </>
        );
      }}
    />
  );
};

export default HomeProfilePage;
