import './PeopleFilter.scss';

import { Field, Form, Formik } from 'formik';
import React from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';

import { Input, MultiSelect } from '../../components/common/FormikComponents/FormikComponents';
import FlexBox from '../../components/core/FlexBox';
import { IUser } from '../../interfaces/IUser';
import { PeopleDashboardQuery } from '../../queries/people/usePeople';

interface PeopleFilterProps {
  filters: PeopleDashboardQuery;
  assignees: IUser[] | undefined;
  onFilterReset: () => void;
  onFilterSubmit: (filters: PeopleDashboardQuery) => void;
}

const PeopleFilter = ({ filters, assignees, onFilterReset, onFilterSubmit }: PeopleFilterProps) => (
  <Formik
    initialValues={{
      criteria: filters.criteria || '',
      agent_ids: filters.agent_ids || []
    }}
    enableReinitialize
    onSubmit={onFilterSubmit}
  >
    {({ handleReset }) => (
      <Form className="people-filter" autoComplete="off">
        <h6>Filters</h6>

        <Row>
          <Col sm={4}>
            <FormGroup row id="agent-filter">
              <Label sm={4}>Agent</Label>
              <Col sm={8}>
                <Field
                  name="agent_ids"
                  component={MultiSelect}
                  options={assignees}
                  valueName="id"
                  labelName="name"
                  showResetButton
                  ordered
                />
              </Col>
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup row id="people-filter">
              <Label sm={4}>Customer</Label>
              <Col sm={8}>
                <Field
                  name="criteria"
                  className="fs-mask"
                  component={Input}
                  placeholder="Please enter name, email or phone"
                />
              </Col>
            </FormGroup>
          </Col>
        </Row>

        <FlexBox justifyRight>
          <Button
            color="warning"
            onClick={() => {
              onFilterReset();
              handleReset();
            }}
          >
            Reset to default
          </Button>
          <Button color="primary" type="submit">
            Apply
          </Button>
        </FlexBox>
      </Form>
    )}
  </Formik>
);

export default PeopleFilter;
