import analytics from '../../services/analytics';
import Quotes from './Quotes';

const GuidedQuoting = ({
  onConfigureQuoting,
  personGid,
  leadGid,
  leadId
}: {
  onConfigureQuoting: () => void;
  personGid: string;
  leadGid: string | undefined;
  leadId: number | undefined;
}) => {
  return (
    <Quotes
      leadId={leadId}
      leadGid={leadGid}
      personGid={personGid}
      onGetQuotes={() => {
        analytics.track('Get quotes clicked', {
          lead_gid: leadGid,
          person_gid: personGid,
          place: 'guided_selling_experience'
        });
        return onConfigureQuoting();
      }}
    />
  );
};

export default GuidedQuoting;
