import { useQuery } from '@tanstack/react-query';

import { ILeadDispositions } from '../../../interfaces/IDisposition';
import api from '../../../services/api';

export const LEAD_DISPOSITIONS_QUERY_KEY = 'lead_dispositions';

export const getLeadDispositions = (id: number | string): Promise<ILeadDispositions> =>
  api.get(`/api/frontend/leads/${id}/dispositions`);

const useLeadDispositions = (id: string | number | undefined) =>
  useQuery({
    /* eslint-disable-next-line @tanstack/query/exhaustive-deps  */
    queryKey: [LEAD_DISPOSITIONS_QUERY_KEY, id?.toString()],
    queryFn: () => getLeadDispositions(id as number),
    enabled: !!id
  });

export default useLeadDispositions;
