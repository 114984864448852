import { Grid } from '@mui/material';
import React from 'react';

import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import BaseInput from '../../components/core/inputs/Base';
import Select from '../../components/core/inputs/Select';
import Label from '../../components/core/Label';
import TablePlaceholder from '../../components/TablePlaceholder';
import { useDocumentTitle } from '../../hooks';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import useCarriers from '../../queries/carriers/useCarriers';
import { CoveragesMappingsQueryParams } from '../../queries/coverages_mappings/useCoveagesMappings';
import colors from '../../theme/colors';
import { sizes, spacings } from '../../theme/variables';
import CoveragesMappingsList from './CoveragesMappingsList';

const CoveragesMappings = () => {
  const [selectedKind, setSelectedKind] = React.useState<CoveragesMappingsQueryParams['kind']>('home');
  const [selectedCarrierId, setSelectedCarrierId] = React.useState('');
  const [selectedIvansCode, setSelectedIvansCode] = React.useState('');
  const [selectedIvansDescription, setSelectedIvansDescription] = React.useState('');
  const [currentPage, setCurrentPage] = React.useState(1);
  useDocumentTitle('Coverages mappings');

  const ivansCode = useDebouncedValue(selectedIvansCode.trim(), 500);
  const ivansDescription = useDebouncedValue(selectedIvansDescription.trim(), 500);

  const { data: carriers } = useCarriers({ appointed_in_past: true, post_sale: true });

  return (
    <FlexBox p={spacings.px24} columnDirection gap={spacings.px24} backgroundColor={colors.white}>
      <Heading type="h3">Coverages mappings</Heading>
      <Grid container maxWidth={sizes.mediumFormInputWidth * 4} columnSpacing={2} columns={4}>
        <Grid item xs={1}>
          <Label htmlFor="carrier-id" mb={4}>
            Carrier
          </Label>
          <Select
            inputId="carrier-id"
            options={carriers?.map(carrier => ({ key: carrier.id, value: carrier.name })) || []}
            onChange={carrierId => {
              setSelectedCarrierId(carrierId);
              setCurrentPage(1);
            }}
            ordered
            value={selectedCarrierId}
          />
        </Grid>
        <Grid item xs={1}>
          <Label htmlFor="coverage-type" mb={4}>
            Type
          </Label>
          <Select
            inputId="coverage-type"
            options={[
              { key: 'home', value: 'Home' },
              { key: 'auto', value: 'Auto' }
            ]}
            onChange={kind => {
              setSelectedKind(kind);
              setCurrentPage(1);
            }}
            value={selectedKind}
          />
        </Grid>
        <Grid item xs={1}>
          <Label htmlFor="ivans-code" mb={4}>
            IVANS code
          </Label>
          <BaseInput
            id="ivans-code"
            clearable
            onClear={() => setSelectedIvansCode('')}
            onChange={({ target }) => {
              setSelectedIvansCode(target.value);
              setCurrentPage(1);
            }}
            value={selectedIvansCode}
          />
        </Grid>
        <Grid item xs={1}>
          <Label htmlFor="ivans-description" mb={4}>
            IVANS description
          </Label>
          <BaseInput
            id="ivans-description"
            clearable
            onClear={() => setSelectedIvansDescription('')}
            onChange={({ target }) => {
              setSelectedIvansDescription(target.value);
              setCurrentPage(1);
            }}
            value={selectedIvansDescription}
          />
        </Grid>
      </Grid>
      <React.Suspense fallback={<TablePlaceholder testId="coverages-mappings-loader" />}>
        <CoveragesMappingsList
          selectedKind={selectedKind}
          selectedCarrierId={selectedCarrierId}
          selectedIvansCode={ivansCode}
          selectedIvansDescription={ivansDescription}
          currentPage={currentPage}
          onCurrentPageChange={page => setCurrentPage(page)}
        />
      </React.Suspense>
    </FlexBox>
  );
};

export default CoveragesMappings;
