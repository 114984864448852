import { IPartner } from '../../interfaces';
import usePartner from './usePartner';

const NEW_AMERICAN_FUNDING_KEY = 'new_american_funding';
const NEW_AMERICAN_INSURANCE_KEY = 'new_american_insurance';

const fixNAFBrandKey = (brandKey: string | undefined) =>
  brandKey === NEW_AMERICAN_INSURANCE_KEY ? NEW_AMERICAN_FUNDING_KEY : brandKey;

export const findBrandName = (partners: IPartner[] | undefined, brandKey: string | undefined) => {
  const fixedBrandKey = fixNAFBrandKey(brandKey);
  return partners?.find(({ key }) => key === fixedBrandKey)?.name;
};

const useBrand = (brandKey: string | undefined) => {
  const { data: brand, isSuccess } = usePartner(fixNAFBrandKey(brandKey));

  return { brand, isSuccess };
};

export default useBrand;
