/** @jsxImportSource @emotion/react */
import './styles.scss';

import { css, Global } from '@emotion/react';
import { connect as formikConnect, FieldArray, FormikProps } from 'formik';
import { isNil } from 'ramda';
import React from 'react';
import * as yup from 'yup';

import closeIcon from '../../../../assets/new_icons/close.svg';
import { IDrivingRecord } from '../../../../interfaces/IDrivingRecord';
import { MaritalStatus } from '../../../../interfaces/IPerson';
import { dig, rejectEmpty } from '../../../../utils/object';
import { requiredField } from '../../../../utils/yupRules';
import Button, { ButtonSize, ButtonVariant } from '../../../core/buttons/Button';
import Incident from './components/Incident';
import { ACCIDENTS_TYPE, CLAIMS_TYPE, FORM_VALUES_NAME, VIOLATIONS_TYPE } from './constants';
import { generateDefaultIncident } from './helpers';

export const INCIDENTS_NESTED_FORM_NAME = 'incidents';

const generateNamePrefix = (formName: any, index: null | number) =>
  index !== null ? `${formName}.${index}.` : `${formName}.`;

interface IIncidents {
  formik: FormikProps<any>;
  formName: string;
  index?: number | null;
  residentialState?: string | null;
}
const Incidents = ({
  formik: { values, errors, isValid },
  formName,
  index = null,
  residentialState = null
}: IIncidents) => {
  const fieldNamePrefix = generateNamePrefix(formName, index);
  const formValuesName = `${fieldNamePrefix}${FORM_VALUES_NAME}`;

  const formValues = dig(values, ...formValuesName.split('.')) || [];

  return (
    <>
      <Global
        styles={() => css`
          .incidents__close-icon {
            mask: url(${closeIcon}) no-repeat center;
          }
        `}
      />
      <FieldArray name={formValuesName}>
        {({ replace, push }) => (
          <div className="incidents">
            {(formValues as any).map((formValue: any, index: number) => {
              const radioButtonsId = `${fieldNamePrefix}-type-radio-buttons-${index}`;
              const incidentSelectId = `${fieldNamePrefix}-incident-${index}`;

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="incidents__item-wrapper">
                  {formValue.deleted === false ? (
                    <Incident
                      radioButtonsId={radioButtonsId}
                      incidentSelectId={incidentSelectId}
                      showFieldError={() => {
                        return !isValid;
                      }}
                      formValuesName={formValuesName}
                      formValue={formValue}
                      index={index}
                      errors={errors}
                      replace={replace}
                    />
                  ) : null}
                </div>
              );
            })}
            <Button
              className="incidents__add-button"
              size={ButtonSize.Small}
              variant={ButtonVariant.Secondary}
              onClick={() =>
                push({
                  type: ACCIDENTS_TYPE,
                  type_gid: '',
                  key: '',
                  state: residentialState,
                  deleted: false
                })
              }
            >
              Add Incident
            </Button>
          </div>
        )}
      </FieldArray>
    </>
  );
};

const normalizeIncidentEntity = ({ gid, type_gid, key, deleted, state }: any) =>
  rejectEmpty({
    gid,
    type_gid,
    key,
    state,
    ...(deleted ? { destroy: deleted } : {})
  });

const filterDeletedItemsWithoutId = ({ gid, deleted }: any) => !isNil(gid) || !deleted;

export const normalizeValuesBeforeSend = (values: {
  gid: string;
  cocustomer: boolean;
  first_name: string;
  middle_name: string;
  last_name: string;
  marital_status: MaritalStatus;
  gender: string;
  kind: string;
  date_of_birth: string;
  ssn: string;
  fico_score: string | number;
  email: string;
  phone: string;
  education: string;
  occupation_type: string;
  occupation_since_date: string;
  license_status: string;
  license_number: string;
  license_state: string;
  age_first_licensed: string | number;
  incidents: { formValues: any[] };
}) => {
  const formValues = dig(values, INCIDENTS_NESTED_FORM_NAME, FORM_VALUES_NAME) as any;
  const normalizedFormValues = formValues.filter(filterDeletedItemsWithoutId);
  const violations = normalizedFormValues
    .filter(({ type }: any) => type === VIOLATIONS_TYPE)
    .map(normalizeIncidentEntity);
  const claims = normalizedFormValues.filter(({ type }: any) => type === CLAIMS_TYPE).map(normalizeIncidentEntity);
  const accidents = normalizedFormValues
    .filter(({ type }: any) => type === ACCIDENTS_TYPE)
    .map(normalizeIncidentEntity);

  return rejectEmpty({
    violations,
    claims,
    accidents
  });
};

export const generateInitialValues = (person: IDrivingRecord | undefined) => {
  const violations = dig(person, 'violations') || ([] as any);
  const claims = dig(person, 'claims') || ([] as any);
  const accidents = dig(person, 'accidents') || ([] as any);

  const normalizedViolations = violations.map(generateDefaultIncident(VIOLATIONS_TYPE));
  const normalizedClaims = claims.map(generateDefaultIncident(CLAIMS_TYPE));
  const normalizedAccidents = accidents.map(generateDefaultIncident(ACCIDENTS_TYPE));

  return {
    [FORM_VALUES_NAME]: [...normalizedViolations, ...normalizedClaims, ...normalizedAccidents]
  };
};

export const validationSchema = yup.object({
  [FORM_VALUES_NAME]: yup.array().of(
    yup.object().shape({
      type: requiredField,
      type_gid: yup.string().when('deleted', {
        is: false,
        then: schema => schema.concat(requiredField)
      })
    })
  )
});
export default formikConnect(Incidents);
