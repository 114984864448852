/** @jsxImportSource @emotion/react */
import { useQueryClient } from '@tanstack/react-query';
import { Formik, FormikProps, FormikValues } from 'formik';
import moment from 'moment';
import * as React from 'react';
import * as yup from 'yup';

import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import FlexBox from '../../../../components/core/FlexBox';
import { DateInputField, SelectField, TextareaField, TimeField } from '../../../../components/core/forms/fields';
import Paragraph from '../../../../components/core/Paragraph';
import { Translations } from '../../../../constants';
import { ITask, IUser } from '../../../../interfaces';
import { ILeadDispositions } from '../../../../interfaces/IDisposition';
import { TaskPayload } from '../../../../queries/people/person_tasks/useCreateTask';
import { PERSON_TASKS_QUERY_KEY } from '../../../../queries/people/person_tasks/usePersonTasks';
import useUpdateTask from '../../../../queries/people/person_tasks/useUpdateTask';
import { TASK_REMINDERS_QUERY_KEY } from '../../../../queries/tasks/useTaskReminders';
import authInfo from '../../../../services/authInfo';
import { LabelSize, spacings } from '../../../../theme/variables';
import { capitalize } from '../../../../utils/formatter';
import { requiredField } from '../../../../utils/yupRules';
import { getAvailableAssignees } from '../../../Lead/LeadTasks/TaskEditor';

const completedOptions = [
  { key: false, value: 'No' },
  { key: true, value: 'Yes' }
];

const EditTaskForm = ({
  personGid,
  task,
  toggleEditMode,
  leadDispositions,
  assignees
}: {
  personGid: string;
  task: ITask;
  toggleEditMode: () => void;
  leadDispositions?: ILeadDispositions;
  assignees?: IUser[];
}) => {
  const queryClient = useQueryClient();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const formRef = React.useRef<FormikProps<FormikValues> | null>(null);
  const { mutate: updateTask } = useUpdateTask(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] });
  });

  return (
    <FlexBox borderBottom pv={spacings.px8} ph={spacings.px12} columnDirection gap={spacings.px12}>
      <FlexBox justifySpaceBetween fitParentWidth>
        <Paragraph bold ml={spacings.px4}>
          {capitalize(task.kind)}
        </Paragraph>

        <FlexBox gap={spacings.px4}>
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Small}
            onClick={toggleEditMode}
            data-testid={`${task.id}-cancel-task-form`}
            disabled={isSubmitting}
          >
            Cancel
          </Button>
          <Button
            size={ButtonSize.Small}
            onClick={async () => {
              setIsSubmitting(true);
              if (formRef.current?.dirty) {
                await formRef.current.submitForm();
              }
              setIsSubmitting(false);
              if (formRef.current?.isValid) {
                toggleEditMode();
              }
            }}
            type="submit"
            data-testid={`${task.id}-submit-task-form`}
            loading={isSubmitting}
          >
            Save
          </Button>
        </FlexBox>
      </FlexBox>
      <Formik
        innerRef={formRef}
        initialValues={{
          id: task?.id,
          agent_id: task?.agent_id || leadDispositions?.current_disposition?.assignee?.id || authInfo.currentUserId,
          description: task?.description || '',
          completed: task?.completed || false,
          kind: task?.kind || '',
          due_date: task?.due_date || '',
          due_time: task?.due_time || '',
          priority: task?.priority || 'normal'
        }}
        validationSchema={yup.object().shape({
          description: requiredField,
          agent_id: requiredField,
          due_date: yup
            .date()
            .required('This field is required')
            .min(moment().subtract('3', 'years').toDate(), "Date can't be earlier than 3 years ago")
            .max(moment().add('3', 'years').toDate(), "Date can't be later than 3 years from today"),
          kind: requiredField
        })}
        onSubmit={values => updateTask({ personGid, taskId: task.id, data: values as TaskPayload })}
      >
        {() => {
          return (
            <>
              <SelectField
                options={Translations.taskKindOptions}
                name="kind"
                label="Task type"
                required
                id="task-type"
                inline
                labelSize={LabelSize.Small}
              />
              <DateInputField
                testId="task-date"
                name="due_date"
                label="Date"
                required
                id="task-date"
                maxDate={moment().add('3', 'years').toDate()}
                minDate={moment().subtract('3', 'years').toDate()}
                inline
                labelSize={LabelSize.Small}
              />
              <TimeField name="due_time" label="Time" id="task-time" inline labelSize={LabelSize.Small} />
              {assignees && (
                <SelectField
                  name="agent_id"
                  label="Agent"
                  options={getAvailableAssignees(assignees, task?.assignee)}
                  id="task-agent-id"
                  labelName="name"
                  valueName="id"
                  inline
                  labelSize={LabelSize.Small}
                />
              )}
              <SelectField
                label="Priority"
                options={Translations.taskPriorityOptions}
                id="task-priority"
                name="priority"
                inline
                labelSize={LabelSize.Small}
              />
              <TextareaField
                label="Description"
                required
                name="description"
                id="task-description"
                inline
                labelSize={LabelSize.Small}
              />
              <SelectField
                name="completed"
                id="task-completed-checkbox"
                label="Completed"
                inline
                labelSize={LabelSize.Small}
                options={completedOptions}
              />
            </>
          );
        }}
      </Formik>
    </FlexBox>
  );
};
export default EditTaskForm;
