import { useMutation } from '@tanstack/react-query';

import { IProductAnnouncement } from '../../interfaces';
import api from '../../services/api';

type ProductAnnouncementPayload = Omit<
  IProductAnnouncement,
  'id' | 'created_at' | 'updated_at' | 'author' | 'targeted_roles'
> & { targeted_roles_attributes: { role_id: number }[] };

const updateProductAnnouncement = ({
  productAnnouncementId,
  params
}: {
  productAnnouncementId: string;
  params: ProductAnnouncementPayload;
}) => api.put(`/api/frontend/product_announcements/${productAnnouncementId}`, { body: params });

const useUpdateProductAnnouncement = () => useMutation({ mutationFn: updateProductAnnouncement });

export default useUpdateProductAnnouncement;
