/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../../../components/core/Container';
import colors, { opacities } from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';

const Version = ({
  children,
  columnsTemplate,
  created
}: {
  children: React.ReactNode;
  columnsTemplate: string;
  created: boolean;
}) => {
  return (
    <Container
      backgroundColor={`${created ? colors.statusGreen : colors.statusRed}${opacities.opacity_16}`}
      p={spacings.px4}
      customCss={css`
        min-height: 34px;
        display: grid;
        grid-template-columns: ${columnsTemplate};
        gap: ${spacings.px12}px;
        align-items: center;
      `}
    >
      {children}
    </Container>
  );
};

export default Version;
