/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';

import NewTooltip from '../../../components/common/Tooltip/NewTooltip';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import FlexBox from '../../../components/core/FlexBox';
import { InfoIcon, WarningIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import PriorPolicyEditor from '../../../components/PriorPolicyEditor';
import { IPerson } from '../../../interfaces/IPerson';
import { AgencyName, IPriorPolicy } from '../../../interfaces/IPolicy';
import { coverageByKey, PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import analytics from '../../../services/analytics';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, DISPLAY_DATE_FORMAT, moneyFormatter } from '../../../utils/formatter';
import useDataEditingForbidden from '../../GuidedSellingExperience/_hooks/useDataEditingForbidden';
import { IQuotingConfigurationOpportunity } from './_interfaces/IQuotingConfigurationOpportunity';

interface OpportunityCurrentInsuranceProps {
  person: IPerson;
  opportunity: IQuotingConfigurationOpportunity;
  refetchCurrentPolicies: () => void;
}

const OpportunityCurrentInsurance = ({
  person,
  opportunity: { policy_type, assets, latestPolicy },
  refetchCurrentPolicies
}: OpportunityCurrentInsuranceProps) => {
  const [editorOpened, setEditorOpened] = React.useState(false);

  const dwelling = coverageByKey(latestPolicy?.coverages, PolicyCoverageKey.Dwelling);
  const carrier = latestPolicy?.carrier;
  const expirationDate = latestPolicy?.expiration_date;
  const premium = latestPolicy?.premium;
  const jewelry = coverageByKey(latestPolicy?.coverages, PolicyCoverageKey.Jewelry);
  const lenderPlacedInsuranceIssueDate = latestPolicy?.lender_placed_insurance_issue_date;

  const placedDate = lenderPlacedInsuranceIssueDate ? moment(lenderPlacedInsuranceIssueDate) : null;
  const duration = placedDate ? moment(new Date()).diff(placedDate, 'days') : null;

  const isDataEditingForbidden = useDataEditingForbidden();

  const isDataPresent = dwelling || carrier || expirationDate || premium || jewelry;

  return (
    <div>
      <FlexBox
        columnDirection
        p={spacings.px12}
        gap={spacings.px12}
        border
        borderColor={colors.grey30}
        roundBorder
        boxShadow
        customCss={css`
          height: auto;
          width: 242px;
          min-width: 242px;
        `}
      >
        <FlexBox justifySpaceBetween>
          <FlexBox gap={spacings.px4}>
            <Text bold>Current insurance</Text>
            {!!placedDate && !!duration && (
              <span
                data-for="lender-placed-insurance"
                data-tip={`Lender placed insurance\neffective date: ${placedDate.format(
                  DISPLAY_DATE_FORMAT
                )}\nduration: ${duration} days`}
              >
                <WarningIcon color={colors.statusOrange} />
              </span>
            )}
          </FlexBox>
          {latestPolicy?.agency_name !== AgencyName.Matic ? (
            <IconButton
              onClick={() => setEditorOpened(true)}
              data-testid="edit-current-insurance"
              focus={editorOpened}
              icon={ButtonIcons.Edit}
              disabled={isDataEditingForbidden}
            />
          ) : (
            <>
              <span
                data-for="policy-sold-by-matic"
                data-tip="You can't edit this policy because it's a policy with Matic"
              >
                <InfoIcon />
              </span>
              <NewTooltip id="policy-sold-by-matic" />
            </>
          )}
          <NewTooltip id="lender-placed-insurance" />
        </FlexBox>
        {!isDataPresent && (
          <Text italic ml={spacings.px8}>
            No information added
          </Text>
        )}
        <FlexBox columnDirection gap={spacings.px6}>
          {carrier && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Carrier
              </Text>
              <Text
                type="small"
                customCss={css`
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                `}
              >
                {carrier?.name}
              </Text>
            </FlexBox>
          )}
          {expirationDate && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Exp date
              </Text>
              <Text type="small">{dateFormatter(expirationDate)}</Text>
            </FlexBox>
          )}
          {premium && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Premium
              </Text>
              <Text type="small">{moneyFormatter(premium, true)}</Text>
            </FlexBox>
          )}
          {dwelling?.limit_value && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Dwelling
              </Text>
              <Text type="small">{moneyFormatter(dwelling.limit_value, true)}</Text>
            </FlexBox>
          )}
          {dwelling?.deductible_value && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Deductible
              </Text>
              <Text type="small">{moneyFormatter(dwelling.deductible_value, true)}</Text>
            </FlexBox>
          )}
          {jewelry?.limit_value && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Coverage
              </Text>
              <Text type="small">{moneyFormatter(jewelry?.limit_value, true)}</Text>
            </FlexBox>
          )}
          {editorOpened && (
            <PriorPolicyEditor
              personGid={person.gid}
              policyType={policy_type}
              assets={assets}
              priorPolicy={latestPolicy as IPriorPolicy}
              cancelHandler={() => setEditorOpened(false)}
              confirmHandler={() => {
                analytics.track(`Prior policy ${latestPolicy ? 'updated' : 'added'}`, {
                  person_gid: person.gid,
                  place: 'guided_selling_experience',
                  page: 'quoting_configuration'
                });
                setEditorOpened(false);
                refetchCurrentPolicies();
              }}
            />
          )}
        </FlexBox>
      </FlexBox>
    </div>
  );
};

export default OpportunityCurrentInsurance;
