/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import reportImage1 from '../../../../../assets/img/uni_reports_1.png';
import reportImage2 from '../../../../../assets/img/uni_reports_2.png';
import reportImage3 from '../../../../../assets/img/uni_reports_3.png';
import reportImage4 from '../../../../../assets/img/uni_reports_4.png';
import FlexBox from '../../../../../components/core/FlexBox';
import Modal, { ModalProps, ModalSize } from '../../../../../components/core/Modal';
import Paragraph from '../../../../../components/core/Paragraph';
import { spacings } from '../../../../../theme/variables';

const ReportsModal = (modalProps: ModalProps) => {
  return (
    <Modal
      size={ModalSize.large}
      title="How to run reports"
      showCancelButton={false}
      bodyCss={css`
        overflow: scroll;
        height: 600px;
        padding: 12px 24px;
      `}
      {...modalProps}
    >
      <FlexBox gap={spacings.px24} alignItemsCenter columnDirection>
        <FlexBox columnDirection gap={spacings.px12}>
          <Paragraph bold type="large">
            Roof age
          </Paragraph>
          <Paragraph>
            On the rating page you can confirm the roof year update. It&apos;s required to provide proof if this is
            different from what Verisk provided. If they cannot provide documentation, we need to leave the year roof
            updated as is
          </Paragraph>
          <img src={reportImage1} alt="" />
        </FlexBox>
        <FlexBox columnDirection gap={spacings.px12}>
          <Paragraph bold type="large">
            Prior insurance
          </Paragraph>
          <Paragraph>
            Universal does not have a requirement for prior home insurance. If nothing pulls through, we can update this
            information. But we must get documentation to show their previous declarations page at the time of binding
            the loss history section.
          </Paragraph>
          <img src={reportImage2} alt="" />
        </FlexBox>
        <FlexBox columnDirection gap={spacings.px12}>
          <Paragraph bold type="large">
            Loss history report
          </Paragraph>
          <Paragraph>
            If a loss is identified on the claims history report, we must update the drop down response accordingly.
          </Paragraph>
          <img src={reportImage3} alt="" />
          <Paragraph>
            To do so review the claims history report check, which claims should be included in the quote and then click
            the proceed with checked losses button.
          </Paragraph>
          <img src={reportImage4} alt="" />
        </FlexBox>
      </FlexBox>
    </Modal>
  );
};

export default ReportsModal;
