/* eslint-disable react/prop-types */
import React from 'react';

import featureFlags from '../../../../constants/featureFlags';
import { Link } from '../../index';
import ColumnGenerator from '../ColumnGenerator';

const LeadIdCell = ({ original: { id, gid } }: any) => (
  <Link to={featureFlags.scoutingReportRedirect ? `/scouting_report?lead_gid=${gid}` : `/leads/${id}`}>{id}</Link>
);

const LeadId = ColumnGenerator.simple({
  accessor: 'lead_id',
  Header: 'Lead id',
  Cell: LeadIdCell
});

export default LeadId;
