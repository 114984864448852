/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import {
  boxCss,
  boxCssPropsKeys,
  containerSizeCss,
  containerSizeKeys,
  spacingKeys,
  spacingsCss
} from '../../theme/variables';
import { IContainer } from './Container';

export interface IFlexBox extends IContainer {
  children: React.ReactNode;
  justifyCenter?: boolean;
  justifyEvenly?: boolean;
  alignItemsCenter?: boolean;
  alignItemsBaseline?: boolean;
  alignItemsFlexStart?: boolean;
  alignItemsFlexEnd?: boolean;
  justifyRight?: boolean;
  columnDirection?: boolean;
  justifySpaceBetween?: boolean;
  maxWidth?: number;
  ref?: React.Ref<HTMLDivElement>;
  autoMargin?: boolean;
}

const FlexBox = ({
  children,
  justifyCenter,
  alignItemsCenter,
  alignItemsBaseline,
  alignItemsFlexStart,
  alignItemsFlexEnd,
  justifyRight,
  columnDirection,
  justifySpaceBetween,
  justifyEvenly,
  maxWidth,
  autoMargin,
  customCss,
  ...rest
}: IFlexBox) => {
  const spacingProps = pick(spacingKeys, rest);
  const containerSizeProps = pick(containerSizeKeys, rest);
  const boxCssProps = pick(boxCssPropsKeys, rest);
  const restOfProps = omit([...spacingKeys, ...containerSizeKeys, ...boxCssPropsKeys], rest);

  return (
    <div
      {...restOfProps}
      css={css`
        display: flex;

        ${spacingsCss(spacingProps)};
        ${containerSizeCss(containerSizeProps)};
        ${boxCss(boxCssProps)};

        ${justifyCenter && 'justify-content: center'};
        ${justifyRight && 'justify-content: flex-end'};
        ${justifySpaceBetween && 'justify-content: space-between'};
        ${justifyEvenly && 'justify-content: space-evenly'};
        ${columnDirection && 'flex-direction: column'};
        ${alignItemsCenter && 'align-items: center'};
        ${alignItemsBaseline && 'align-items: baseline'};
        ${alignItemsFlexStart && 'align-items: flex-start'};
        ${alignItemsFlexEnd && 'align-items: flex-end'};

        ${maxWidth && `max-width: ${maxWidth}px`};
        ${autoMargin && 'margin: auto'};

        ${customCss}
      `}
    >
      {children}
    </div>
  );
};

export default React.forwardRef<HTMLDivElement, IFlexBox>((props: IFlexBox, ref) => FlexBox({ ...props, ref }));
