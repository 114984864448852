import { useMutation } from '@tanstack/react-query';

import api from '../../../services/api';

export const attachHome = ({ assetGid, leadId }: { assetGid: string; leadId: string | number }): Promise<null> =>
  api.put(`/api/frontend/leads/${leadId}/opportunities/home`, { body: { asset_gid: assetGid } });

const useAttachHome = () => useMutation({ mutationFn: attachHome });

export default useAttachHome;
