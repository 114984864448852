import { SerializedStyles } from '@emotion/react';
import { useFormikContext } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Translations } from '../../constants';
import { UsageType } from '../../interfaces/IPersonAsset';
import { IVehicle, notMotorcycleOrTrailer } from '../../interfaces/IVehicle';
import { VehicleFields } from '../../pages/GetQuotes/AssetsPicker/formHelpers';
import { InputSize, LabelSize } from '../../theme/variables';
import { REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';
import { InputField, NumericField, SelectField } from '../core/forms/fields';
import { calculateCommutingMileage, calculateDaysPerWeek, calculateMileage, hasWorkOrSchoolValue } from './helpers';

const EMPTY_USAGE = '' as UsageType;
const PREFILLED_ANNUAL_MILEAGE_INFO_TIP = 'This is an average value we recommend for the usage.';
const CALCULATED_ANNUAL_MILEAGE_INFO_TIP = 'We calculate this value based on daily driven distance.';

export const annualMileageCalculatorInitialValues = (vehicle: IVehicle) => ({
  [VehicleFields.Usage]: vehicle.usage || EMPTY_USAGE,
  [VehicleFields.DaysPerWeekDriven]: vehicle.days_per_week_driven || '',
  [VehicleFields.AnnualMileage]: vehicle.annual_mileage || '',
  [VehicleFields.OneWayMileage]: vehicle.one_way_mileage || ''
});

export const annualMileageCalculatorValidations = () => ({
  [VehicleFields.Usage]: yup.string().when(VehicleFields.Kind, {
    is: (kind: IVehicle['kind']) => notMotorcycleOrTrailer(kind),
    then: schema => schema.concat(requiredField),
    otherwise: undefined
  }),
  [VehicleFields.AnnualMileage]: yup.number().when(VehicleFields.Kind, {
    is: (kind: IVehicle['kind']) => notMotorcycleOrTrailer(kind),
    then: schema =>
      schema
        .required(REQUIRED_MESSAGE)
        .min(1000, 'Should be equal or greater than 1,000 miles')
        .max(99998, 'Should be lower than 99,999 miles'),
    otherwise: undefined
  }),
  [VehicleFields.OneWayMileage]: yup.number().when(VehicleFields.Kind, {
    is: (kind: IVehicle['kind']) => notMotorcycleOrTrailer(kind),
    then: schema =>
      schema
        .positive('Must be greater than 0')
        .max(399, 'Should be less than 400 miles')
        .when(VehicleFields.Usage, ([usage], schema) =>
          hasWorkOrSchoolValue(usage) ? schema.required(REQUIRED_MESSAGE) : schema
        ),
    otherwise: undefined
  }),
  [VehicleFields.DaysPerWeekDriven]: yup.number().when(VehicleFields.Kind, {
    is: (kind: IVehicle['kind']) => notMotorcycleOrTrailer(kind),
    then: schema =>
      schema.when(VehicleFields.Usage, ([usage], schema) => {
        if (hasWorkOrSchoolValue(usage)) {
          return schema
            .required(REQUIRED_MESSAGE)
            .min(1, 'Should be greater than or equal to 1 day')
            .max(7, 'Should be less than or equal to 7 days');
        }

        return schema;
      }),
    otherwise: undefined
  })
});

const AnnualMileageCalculator = ({
  vehicleValues,
  valuesPath = '',
  labelSize = LabelSize.Medium,
  inputSize = InputSize.Medium,
  disabled = false,
  customLabelCss
}: {
  vehicleValues: ReturnType<typeof annualMileageCalculatorInitialValues>;
  valuesPath?: string;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  disabled?: boolean;
  customLabelCss?: SerializedStyles;
}) => {
  const { setFieldValue } = useFormikContext();

  return (
    <>
      <SelectField
        id={`${valuesPath}${VehicleFields.Usage}`}
        name={`${valuesPath}${VehicleFields.Usage}`}
        label="Use"
        labelSize={labelSize}
        inputSize={inputSize}
        inline
        options={Translations.vehicleUsageOptions}
        required
        disabled={disabled}
        customLabelCss={customLabelCss}
        onChange={({ target }) => {
          React.startTransition(() => {
            setFieldValue(
              `${valuesPath}${VehicleFields.AnnualMileage}`,
              calculateMileage(
                target.value,
                vehicleValues.days_per_week_driven?.toString() || '',
                vehicleValues.one_way_mileage?.toString() || ''
              )
            );

            setFieldValue(`${valuesPath}${VehicleFields.Usage}`, target.value);
          });
        }}
      />
      {hasWorkOrSchoolValue(vehicleValues.usage || EMPTY_USAGE) && (
        <>
          <InputField
            id={`${valuesPath}${VehicleFields.DaysPerWeekDriven}`}
            name={`${valuesPath}${VehicleFields.DaysPerWeekDriven}`}
            label="Days driven weekly"
            labelSize={labelSize}
            inputSize={inputSize}
            customLabelCss={customLabelCss}
            type="number"
            inline
            secondary
            required
            disabled={disabled}
            onChange={({ target }) => {
              React.startTransition(() => {
                setFieldValue(
                  `${valuesPath}${VehicleFields.AnnualMileage}`,
                  calculateCommutingMileage(target.value, vehicleValues.one_way_mileage?.toString() || '')?.toString()
                );

                setFieldValue(`${valuesPath}${VehicleFields.DaysPerWeekDriven}`, target.value);
              });
            }}
          />
          <InputField
            id={`${valuesPath}${VehicleFields.OneWayMileage}`}
            name={`${valuesPath}${VehicleFields.OneWayMileage}`}
            label="One-way mileage"
            labelSize={labelSize}
            inputSize={inputSize}
            type="number"
            customLabelCss={customLabelCss}
            inline
            secondary
            required
            disabled={disabled}
            onChange={({ target }) => {
              React.startTransition(() => {
                setFieldValue(
                  `${valuesPath}${VehicleFields.AnnualMileage}`,
                  calculateCommutingMileage(
                    vehicleValues.days_per_week_driven?.toString() || '',
                    target.value
                  )?.toString()
                );

                setFieldValue(`${valuesPath}${VehicleFields.OneWayMileage}`, target.value);
              });
            }}
          />
        </>
      )}
      {vehicleValues.usage && (
        <NumericField
          id={`${valuesPath}${VehicleFields.AnnualMileage}`}
          name={`${valuesPath}${VehicleFields.AnnualMileage}`}
          label="Annual mileage"
          labelSize={labelSize}
          inputSize={inputSize}
          inline
          customLabelCss={customLabelCss}
          secondary
          required
          disabled={disabled}
          info={
            hasWorkOrSchoolValue(vehicleValues.usage)
              ? CALCULATED_ANNUAL_MILEAGE_INFO_TIP
              : PREFILLED_ANNUAL_MILEAGE_INFO_TIP
          }
          valueIsNumericString
          onValueChange={({ value }) => {
            React.startTransition(() => {
              setFieldValue(
                `${valuesPath}${VehicleFields.DaysPerWeekDriven}`,
                calculateDaysPerWeek(
                  vehicleValues.usage || EMPTY_USAGE,
                  value,
                  vehicleValues.one_way_mileage?.toString() || ''
                )?.toString()
              );

              setFieldValue(`${valuesPath}${VehicleFields.AnnualMileage}`, value);
            });
          }}
        />
      )}
    </>
  );
};

export default AnnualMileageCalculator;
