import alert from '../../../../components/core/Alert';
import {
  getDispositionReasonOptions,
  getDispositionTypesOptions,
  hasDispositionReason,
  isUserDifferentFromAssignee,
  lostLeadConfirmation,
  updateRequiresConfirmation
} from '../../../../components/DispositionsModals/dispositionsHelper';
import { DispositionType } from '../../../../interfaces/IDisposition';
import { IOpportunity } from '../../../../interfaces/IOpportunity';
import { IMaticPolicy } from '../../../../interfaces/IPolicy';
import useLeadDispositionConfig from '../../../../queries/leads/configuration/useLeadDispositionConfig';
import useLeadDispositions from '../../../../queries/leads/dispositions/useLeadDispositions';
import useUpdateLeadDisposition from '../../../../queries/leads/dispositions/useUpdateLeadDisposition';
import { capitalize } from '../../../../utils/formatter';

export const useLeadOperationsDispositionChange = ({
  leadId,
  policies,
  openContactMadeModal,
  openCouldNotContactModal,
  openQualifiedModal,
  openNotQualifiedModal,
  openPipelineModal,
  openOpportunitiesModal,
  openLostLeadModal,
  openCancelCallModal
}: {
  leadId: number;
  policies: IMaticPolicy[];
  openContactMadeModal: () => void;
  openCouldNotContactModal: () => void;
  openQualifiedModal: () => void;
  openNotQualifiedModal: () => void;
  openPipelineModal: () => void;
  openOpportunitiesModal: () => void;
  openLostLeadModal: () => void;
  openCancelCallModal: () => void;
}) => {
  const {
    data: leadDispositions,
    refetch: refetchCurrentDisposition,
    isFetching: isFetchingCurrentDisposition
  } = useLeadDispositions(leadId);
  const { mutateAsync: updateLeadDisposition } = useUpdateLeadDisposition();

  const currentDisposition = leadDispositions?.current_disposition;

  const currentDispositionType = currentDisposition?.disposition_type;
  const dispositionReason = currentDisposition?.disposition_reason;
  const currentAssigneeId = currentDisposition?.assignee?.id;
  const currentAssigneeName = currentDisposition?.assignee?.name;
  const isAssigneeLicensed = currentDisposition?.is_assignee_licensed;

  const updateDisposition = (type: DispositionType, opportunities: IOpportunity[]) => {
    if (type === DispositionType.PolicySold) {
      if (!policies.length) {
        const dispositionError = `In order to mark this lead as "${capitalize(
          DispositionType.PolicySold
        )}" you must first add a policy to the lead.`;

        alert({ message: dispositionError }).error();
      } else if (updateRequiresConfirmation(type, opportunities)) {
        openOpportunitiesModal();
      } else {
        updateLeadDisposition({ leadId, disposition_type: type })
          .then(() => openCancelCallModal())
          .catch(() => null);
      }
    } else if (type === DispositionType.ContactMade && isUserDifferentFromAssignee(currentAssigneeId)) {
      openContactMadeModal();
    } else if (type === DispositionType.CouldNotContact) {
      openCouldNotContactModal();
    } else if (type === DispositionType.Qualified && isUserDifferentFromAssignee(currentAssigneeId)) {
      openQualifiedModal();
    } else if (type === DispositionType.NotQualified) {
      openNotQualifiedModal();
    } else if (
      type === DispositionType.Pipeline &&
      (isUserDifferentFromAssignee(currentAssigneeId) || !isAssigneeLicensed)
    ) {
      openPipelineModal();
    } else if (updateRequiresConfirmation(type, opportunities)) {
      openOpportunitiesModal();
    } else if (lostLeadConfirmation(type, opportunities)) {
      openLostLeadModal();
    } else {
      updateLeadDisposition({ leadId, disposition_type: type }).catch(() => null);
    }
  };

  const updateDispositionReason = (reason: any) =>
    updateLeadDisposition({
      leadId,
      disposition_type: currentDispositionType!,
      disposition_reason: reason
    }).catch(() => null);

  return {
    currentDisposition,
    currentDispositionType,
    dispositionReason,
    currentAssigneeId,
    currentAssigneeName,
    updateDisposition,
    updateDispositionReason,
    refetchCurrentDisposition,
    isFetchingCurrentDisposition
  };
};

export const useLeadDispositionsOptions = (
  leadId: number | undefined,
  dispositionType: DispositionType | undefined
) => {
  const { data: dispositionConfig, isLoading } = useLeadDispositionConfig(leadId);

  const reasonOptions =
    dispositionConfig && dispositionType ? getDispositionReasonOptions(dispositionConfig, dispositionType) : [];

  const dispositionTypesOptions =
    dispositionType &&
    getDispositionTypesOptions(dispositionConfig, dispositionType).map(option => {
      return { key: option.key, value: option.value.label };
    });

  const typeOptions = dispositionTypesOptions || [];

  const hasReason =
    dispositionConfig && dispositionType ? hasDispositionReason(dispositionConfig, dispositionType) : undefined;

  return { isLoading, dispositionConfig, typeOptions, reasonOptions, hasReason };
};
