import { css, SerializedStyles } from '@emotion/react';

import colors from '../../../../theme/colors';
import { borderRadius } from '../../../../theme/variables';

export const navigation = css`
  position: relative;
  &::before {
    position: absolute;
    top: 24px;
    bottom: -16px;
    left: -12px;
    width: 1px;
    background-color: ${colors.statusGreen};
    content: '';
  }
  &:last-of-type::before {
    content: none;
  }
`;

export const navigationIndicator = (
  isCurrent: boolean,
  isCompleted: boolean,
  isEndReason: boolean
): SerializedStyles => css`
  border-radius: 50%;
  content: '';
  height: 8px;
  left: -16px;
  position: absolute;
  top: 12px;
  width: 8px;
  z-index: 1;

  ${isCurrent &&
  css`
    background-color: ${colors.grey30};
    border-color: ${colors.grey30};
  `};

  ${isCompleted &&
  css`
    background-color: ${colors.statusGreen};
    border-color: ${colors.statusGreen};
  `};
  ${isEndReason &&
  css`
    background-color: ${colors.statusRed};
    border-color: ${colors.statusRed};
  `};
`;

export const dispositionInputStyle = css`
  margin-top: 8px;
  background: ${colors.white};
  border: 1px solid ${colors.grey30};
  border-radius: ${borderRadius}px;
  padding: 8px;
  color: ${colors.grey60};

  &:hover {
    color: black;
    border-color: black;
    > div {
      visibility: visible;
    }
    span > div {
      color: ${colors.grey60};
    }
  }
`;

export const reasonPipeHorizontalStyle = (backgroundColor: string) => {
  return css`
    position: relative;
    margin-top: 8px;
    color: ${colors.grey60};

    &::after {
      position: absolute;
      top: 8px;
      left: -12px;
      display: block;
      width: 24px;
      height: 1px;
      background-color: ${backgroundColor};
      content: '';
    }
  `;
};

export const reasonPipeVerticalStyle = (backgroundColor: string) => {
  return css`
    &::after {
      position: absolute;
      top: -20px;
      left: -12px;
      width: 1px;
      height: 28px;
      background-color: ${backgroundColor};
      content: '';
    }
  `;
};
