/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { ColumnGenerator, Copy, Table } from '../../../components/common';
import NewTooltip from '../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { DeleteIcon, EditIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { SORT_ORDER_DESC } from '../../../constants';
import { IPriorPolicy } from '../../../interfaces/IPolicy';
import { coverageByKey, PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import authInfo from '../../../services/authInfo';
import { spacings } from '../../../theme/variables';
import { dateFormatter, moneyFormatter } from '../../../utils/formatter';

const DEFAULT_SORTING = { sort_column: 'expiration_date', sort_order: SORT_ORDER_DESC };

const PriorPoliciesList = ({
  priorPolicies,
  onEditPriorPolicy,
  onCreatePriorPolicy,
  onDeletePriorPolicy,
  createPriorPolicyEnabled
}: {
  priorPolicies: IPriorPolicy[];
  onEditPriorPolicy: (policy: IPriorPolicy) => void;
  onCreatePriorPolicy: () => void;
  onDeletePriorPolicy: (policy: IPriorPolicy) => void;
  createPriorPolicyEnabled?: boolean;
}) => {
  const renderPolicyNumber = ({ original: { policy_number } }: { original: IPriorPolicy }) => (
    <Text>{policy_number ? <Copy value={policy_number} testId="policy-number" /> : '-'}</Text>
  );

  const renderPolicyType = ({ original: { policy_type } }: { original: IPriorPolicy }) => (
    <Text>{findPolicyTypeLabel(policy_type) || '-'}</Text>
  );

  const renderAssets = ({ original: { assets } }: { original: IPriorPolicy }) => {
    return (
      <Container data-testid="assets">
        {assets?.map(asset => <Paragraph key={asset.gid}>{asset.description}</Paragraph>) || '-'}
      </Container>
    );
  };

  const renderCarrier = ({ original: { carrier } }: { original: IPriorPolicy }) => (
    <span
      data-tip={carrier.name}
      data-for="carrier-name"
      css={css`
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `}
    >
      <Text data-testid="carrier">{carrier.name || '-'}</Text>
    </span>
  );

  const renderPremium = ({ original: { premium } }: { original: IPriorPolicy }) => (
    <Text data-testid="premium">{moneyFormatter(premium) || '-'}</Text>
  );

  const renderCoverage = ({ original: { coverages } }: { original: IPriorPolicy }) => (
    <Text data-testid="coverage">
      {moneyFormatter(coverageByKey(coverages, PolicyCoverageKey.Dwelling)?.limit_value) || '-'}
    </Text>
  );

  const renderDeductible = ({ original: { coverages } }: { original: IPriorPolicy }) => (
    <Text data-testid="deductible">
      {moneyFormatter(coverageByKey(coverages, PolicyCoverageKey.Dwelling)?.deductible_value) || '-'}
    </Text>
  );

  const renderExpirationDate = ({ original: { expiration_date } }: { original: IPriorPolicy }) => (
    <Text data-testid="expiration-date">{dateFormatter(expiration_date) || '-'}</Text>
  );

  const renderEdit = ({ original }: { original: IPriorPolicy }) => (
    <div
      onClick={() => onEditPriorPolicy(original)}
      data-testid="edit-prior-policy"
      css={css`
        cursor: pointer;
      `}
    >
      <EditIcon />
    </div>
  );

  const renderDelete = ({ original }: { original: IPriorPolicy }) => (
    <div
      onClick={() => onDeletePriorPolicy(original)}
      className="u-cursor-pointer"
      data-testid="prior_policy_delete_button"
    >
      <DeleteIcon />
    </div>
  );

  const priorPolicyColumns = [
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      accessor: 'policy_number',
      Header: 'Policy #',
      className: 'fs-mask',
      Cell: renderPolicyNumber
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      accessor: 'policy_type',
      Header: 'Type',
      className: 'fs-mask',
      Cell: renderPolicyType
    }),

    ColumnGenerator.stretchable({
      accessor: 'assets',
      Header: 'Assets',
      className: 'fs-mask',
      Cell: renderAssets
    }),
    ColumnGenerator.stretchable({
      width: ColumnGenerator.WIDTH_XL,
      accessor: 'carrier',
      Header: 'Carrier',
      sortable: false,
      Cell: renderCarrier
    }),
    ColumnGenerator.stretchable({
      width: ColumnGenerator.WIDTH_L,
      accessor: 'premium',
      Header: 'Premium',
      Cell: renderPremium
    }),
    ColumnGenerator.stretchable({
      width: ColumnGenerator.WIDTH_L,
      accessor: 'coverage',
      Header: 'Coverage',
      sortable: false,
      Cell: renderCoverage
    }),
    ColumnGenerator.stretchable({
      width: ColumnGenerator.WIDTH_L,
      accessor: 'deductible',
      Header: 'Deductible',
      sortable: false,
      Cell: renderDeductible
    }),
    ColumnGenerator.stretchable({
      width: ColumnGenerator.WIDTH_XL,
      accessor: 'expiration_date',
      Header: 'Exp date',
      Cell: renderExpirationDate
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XS,
      sortable: false,
      Cell: renderEdit
    }),
    authInfo.features.asset_delete &&
      ColumnGenerator.simple({
        width: ColumnGenerator.WIDTH_XS,
        sortable: false,
        Cell: renderDelete
      })
  ].filter(Boolean);

  return (
    <>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Heading type="h4">Prior policies</Heading>
        <Button
          data-testid="create-prior-policy"
          size={ButtonSize.Small}
          onClick={() => onCreatePriorPolicy()}
          disabled={!createPriorPolicyEnabled}
        >
          + Add Prior Policy
        </Button>
      </FlexBox>
      <Container mt={spacings.px24}>
        <Table
          defaultSorting={DEFAULT_SORTING}
          columns={priorPolicyColumns}
          data={priorPolicies}
          manual={false}
          noDataMessage="No prior policies found"
          testId="lead-prior-policies"
        />
        <NewTooltip id="carrier-name" />
      </Container>
    </>
  );
};

export default PriorPoliciesList;
