export const mainAutoCoverageOptionDefinitions = {
  bodily_injury: 'BI Limits',
  umbi: 'UM | UIM',
  property_damage: 'Property damage',
  umpd: 'UMPD',
  medical_payments: 'Med pay',
  comprehensive: 'Comp deductible',
  collision: 'Coll deductible',
  full_glass: 'Glass',
  towing: 'Towing/RSE',
  rental: 'Rental'
};

const additionalAutoCoverageOptionDefinitions = {
  accidental_death: null,
  additional_pip: null,
  combined_first_party_benefits: null,
  extraordinary_medical: null,
  funeral: null,
  income_loss: null,
  limited_property_damage_liability: null,
  medical_expenses: null,
  no_fault_coverage_option: null,
  optional_basic_economic_loss: null,
  pip: null,
  pip_accidental_death_benefits: null,
  pip_deductible: null,
  pip_lost_wages: null,
  pip_medical_expense: null,
  pip_medpay_hospital_funeral_expenses: null,
  pip_stacking: null,
  pip_work_loss: null,
  pip_work_loss_and_replacement_cost: null,
  tort_option: null,
  uninsured_underinsured_stacking: null,
  work_loss: null
};

export const autoCoverageOptionDefinitions = {
  ...mainAutoCoverageOptionDefinitions,
  ...additionalAutoCoverageOptionDefinitions
};

type AutoCoverageOptionKey = keyof typeof autoCoverageOptionDefinitions;

export interface AutoCoverageLimits {
  per_person?: number;
  per_acc?: number;
  per_day?: number;
  per_week?: number;
  coverage?: number | 'Selected' | 'UL';
  max_amount?: number;
  inc_econ_loss?: number;
  medical?: number;
  monthly?: number;
  work_loss?: number;
  total_ben?: number;
  acc_death?: number;
  opt1?: string; // bunch of codes
  opt2?: string; // bunch of codes
  opt3?: string; // bunch of codes
}

export interface AutoCoverageOption {
  gid: string;
  key: AutoCoverageOptionKey;
  name: string;
  ui_values: string;

  basic: boolean;
  default: boolean;
  non_standard: boolean;
  premium: boolean;

  values: AutoCoverageLimits | null;
}

export interface AutoCoverageError {
  asset_gid: string;
  errors: Record<string, string[]>;
}

export interface AutoCoveragesValidation {
  errors?: AutoCoverageError[];
}

export type AutoCoveragesOptions = { key: AutoCoverageOptionKey; values: AutoCoverageOption[] }[];
