import FlexBox from '../../../components/core/FlexBox';
import RadioInput from '../../../components/core/inputs/Radio';
import Modal, { ModalSize } from '../../../components/core/Modal';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { IHome, IOpportunity } from '../../../interfaces';
import { useDetachHomeFromDataCollection } from '../../../queries/leads/data_collection/useDataCollectionHomes';
import { useSetOpportunityAsPrimary } from '../../../queries/leads/opportunities/useLeadOpportunities';
import { spacings } from '../../../theme/variables';
import AssetAssociatedInfo from './AssetAssociatedInfo';

interface IHomeDelete {
  confirmBtnHandler?: (homeGid: string) => void | Promise<void>;
  cancelBtnHandler: () => void;
  leadId: string | number;
  home: IHome;
  personGid: string;
  nonPrimaryOpportunities: IOpportunity[];
}

const RADIO_INPUT_NAME = 'editedOpportunityId';

const HomeDeleteModal = ({
  confirmBtnHandler,
  cancelBtnHandler,
  leadId,
  home,
  personGid,
  nonPrimaryOpportunities
}: IHomeDelete) => {
  const { mutateAsync: detachHome, isPending: isDetachingHome } = useDetachHomeFromDataCollection();
  const { mutateAsync: setOpportunityAsPrimary, isPending: isChangingOpportunity } = useSetOpportunityAsPrimary();

  return (
    <Modal
      title={`Do you want to delete the property${
        nonPrimaryOpportunities.length ? ' and change primary opportunity' : ''
      }?`}
      dangerModal
      size={ModalSize.large}
      cancelHandler={cancelBtnHandler}
      confirmHandler={async () => {
        const nonPrimaryOpportunityId =
          document.querySelector<HTMLInputElement>(`input[name="${RADIO_INPUT_NAME}"]:checked`)?.value ||
          nonPrimaryOpportunities[0]?.id;

        nonPrimaryOpportunityId &&
          (await setOpportunityAsPrimary({ leadId, opportunityId: Number(nonPrimaryOpportunityId) }));
        await detachHome({ leadId, assetGid: home.gid });
        confirmBtnHandler && (await confirmBtnHandler(home.gid));
        cancelBtnHandler();
      }}
      confirmText={`Yes, delete${nonPrimaryOpportunities.length ? ' and change' : ''}`}
      confirmationInProgress={isDetachingHome || isChangingOpportunity}
    >
      <FlexBox columnDirection gap={spacings.px8}>
        <Paragraph>
          You will remove property {home.address.full} from the customer&apos;s list of assets. This action cannot be
          undone.
        </Paragraph>
        {nonPrimaryOpportunities.length > 0 && (
          <>
            <Paragraph bold>
              Primary opportunity will be changed to{' '}
              {nonPrimaryOpportunities.length === 1 ? (
                <Text>{nonPrimaryOpportunities[0]?.assets?.[0]?.address?.full}</Text>
              ) : (
                ''
              )}
            </Paragraph>
            {nonPrimaryOpportunities.length > 1 ? (
              <Paragraph>
                {nonPrimaryOpportunities.map((opportunity, index) => (
                  <RadioInput
                    defaultChecked={index === 0}
                    key={opportunity.id}
                    name={RADIO_INPUT_NAME}
                    id={opportunity.id.toString()}
                    label={opportunity.assets?.[0]?.address.full || ''}
                    value={opportunity.id.toString()}
                  />
                ))}
              </Paragraph>
            ) : null}
          </>
        )}
      </FlexBox>
      <AssetAssociatedInfo personGid={personGid} assetGid={home.gid} />
    </Modal>
  );
};

export default HomeDeleteModal;
