import SearchInput, { SearchInputProps } from '../Search';

const searchByOptions = [
  { key: 'all', value: 'All' },
  { key: 'name', value: 'Name' },
  { key: 'loan_number', value: 'Loan Number' },
  { key: 'email', value: 'Email' },
  { key: 'phone', value: 'Phone' },
  { key: 'living_or_mailing_address', value: 'Living or Mailing Address' },
  { key: 'asset_address', value: 'Asset Address' },
  { key: 'policy_number', value: 'Policy Number' },
  { key: 'drivers_license', value: 'Drivers License' },
  { key: 'vehicle_vin', value: 'Vehicle VIN' },
  { key: 'vehicle_model', value: 'Vehicle Model' },
  { key: 'related_people', value: 'Related People' },
  { key: 'lead_id', value: 'Lead ID' }
];

const PersonSearchInput = (props: SearchInputProps): JSX.Element => {
  const searchInputProps = { ...props, searchByOptions };

  return <SearchInput {...searchInputProps} />;
};

export default PersonSearchInput;
