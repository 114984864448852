import { create } from 'zustand';

import { CallStatus, IScheduledCall, ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { ITimezone } from '../../interfaces/ITimezone';
import useLeadAssignees from '../../queries/leads/assignees/useLeadAssignees';
import useLead from '../../queries/leads/useLead';
import usePerson from '../../queries/people/usePerson';
import { ICalendarData } from '../../queries/scheduled_calls/useCalendar';
import useCallNotes from '../../queries/scheduled_calls/useCallNotes';
import { fixTZName, userTimezone } from '../../utils/formatter';

export const AGENT_SPOT_DURATION = 15;

export const ISR_LINK_MESSAGE =
  "To make our upcoming call as smooth and efficient as possible, we're going to send you a quick link via SMS. " +
  'This link leads to a form where you can fill in some key details about your property at your convenience. ' +
  'Completing this before our call helps us tailor the conversation to your exact needs, ensuring we can provide ' +
  'the best possible advice and options for you. Look out for a text from us shortly!';

export const findUncompletedCall = (scheduledCalls: undefined | IScheduledCall[]): undefined | IScheduledCall =>
  scheduledCalls?.find(scheduledCall =>
    [CallStatus.Ready, CallStatus.InDialer, CallStatus.Scheduled, CallStatus.InProgress].includes(scheduledCall.status)
  );

export const filterCompletedCalls = (scheduledCalls: IScheduledCall[] | undefined) =>
  scheduledCalls?.filter(
    scheduledCall =>
      scheduledCall.status !== CallStatus.Ready &&
      scheduledCall.status !== CallStatus.InDialer &&
      scheduledCall.status !== CallStatus.Scheduled
  );

const isUncompletedCallPresent = (scheduledCalls: undefined | IScheduledCall[]): boolean =>
  !!findUncompletedCall(scheduledCalls);

export const areAllCallsCompleted = (scheduledCalls: undefined | IScheduledCall[]): boolean =>
  !isUncompletedCallPresent(scheduledCalls);

export const isCallCompleted = (scheduledCall: IScheduledCall): boolean =>
  scheduledCall.status === CallStatus.ContactMade;

export const isCallFailed = (scheduledCall: IScheduledCall): boolean =>
  [CallStatus.ContactFailed, CallStatus.Cancelled].includes(scheduledCall.status);

export const getCallSource = (scheduledCall: IScheduledCall) => {
  switch (scheduledCall.flow) {
    case ScheduledInteractionFlow.Calendly:
    case ScheduledInteractionFlow.CalendlyFollowUp:
      return 'by Calendly';
    case ScheduledInteractionFlow.ScheduledByRecapture:
      return 'by AMP';
    default:
      return `by ${scheduledCall.assignor?.name || scheduledCall.assignee?.name || 'Unknown user'}`;
  }
};

export const buildTimezonesOptions = (
  calendarData: ICalendarData,
  personTimezone: ITimezone | null | undefined
): ITimezone[] => {
  const currentTimezone =
    calendarData.available_timezones.find(timezone => timezone.utc_offset_hours === userTimezone.utc_offset_hours) ||
    userTimezone;

  const timezonesExceptCurrent = calendarData.available_timezones.filter(
    timezone =>
      timezone.utc_offset_hours !== currentTimezone.utc_offset_hours &&
      timezone.utc_offset_hours !== personTimezone?.utc_offset_hours
  );

  const personTimezoneOption = personTimezone ? { ...personTimezone, postfix: 'Customer Local Time' } : null;

  return [
    personTimezoneOption,
    {
      ...currentTimezone,
      name: currentTimezone.name,
      display_name: fixTZName(currentTimezone.name),
      postfix: 'Your Local Time'
    },
    ...timezonesExceptCurrent
  ].filter(Boolean) as ITimezone[];
};

export const findTimezoneOption = (calendarData: ICalendarData, timezonesOptions: ITimezone[]) =>
  timezonesOptions.find(timezone => timezone.utc_offset_hours === calendarData.timezone.utc_offset_hours) ||
  timezonesOptions[0];

interface IScheduledCallActions {
  editedScheduledCall: null | IScheduledCall;
  setEditedScheduledCall: (call: IScheduledCallActions['editedScheduledCall']) => void;
  cancelledScheduledCall: null | IScheduledCall;
  setCancelledScheduledCall: (call: IScheduledCallActions['cancelledScheduledCall']) => void;
}
export const useScheduledCallActions = create<IScheduledCallActions>()(set => ({
  editedScheduledCall: null,
  setEditedScheduledCall: editedScheduledCall => set({ editedScheduledCall }),
  cancelledScheduledCall: null,
  setCancelledScheduledCall: cancelledScheduledCall => set({ cancelledScheduledCall })
}));

export const useScheduleCallData = ({
  personGid,
  leadGid,
  leadId
}: {
  personGid: string;
  leadGid?: string;
  leadId?: number;
}) => {
  const { data: person, refetch: refetchPerson, isFetching: isPersonFetching } = usePerson(personGid);

  const { data: callNotes } = useCallNotes(personGid);
  const { data: lead } = useLead(leadGid);
  const { data: possibleAssignees, isPending: isPendingAssignees } = useLeadAssignees({
    leadId: leadId || lead?.id,
    licensed: true
  });

  return {
    person,
    lead,
    refetchPerson,
    isPersonFetching,
    callNotes,
    possibleAssignees,
    isPendingAssignees,
    leadGid
  };
};
