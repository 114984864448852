/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { toast, ToastOptions } from 'react-toastify';

import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import ButtonWithoutBorder from '../buttons/ButtonWithoutBorder';
import Container from '../Container';
import { CloseIcon, ErrorIcon, InfoIcon, TickIcon, WarningIcon } from '../icons';
import Paragraph from '../Paragraph';
import Text from '../Text';

export const AlertMessage = (message?: string | JSX.Element, title?: string, items?: Array<string>): JSX.Element => {
  return (
    <Container ml={spacings.px12}>
      {title && <Paragraph mv={spacings.px4}>{title}</Paragraph>}
      {message && (
        <Text type="small" color={colors.grey60}>
          {message}
        </Text>
      )}
      {items?.length ? (
        <ul>
          {items.map(item => (
            <li key={item}>
              <Text type="small" color={colors.grey60}>
                {item}
              </Text>
            </li>
          ))}
        </ul>
      ) : null}
    </Container>
  );
};

const CloseButton = ({ closeToast }: { closeToast: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void }) => {
  return (
    <Container mv={spacings.px4} mh={spacings.px16}>
      <ButtonWithoutBorder onClick={closeToast}>
        <CloseIcon color={colors.black} />
      </ButtonWithoutBorder>
    </Container>
  );
};

const toastStyle = (color: string) => {
  return {
    border: `1px solid ${color}`,
    borderRadius: '2px',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
  };
};

interface IAlerts {
  success(): void;
  warning(): void;
  error(): void;
  info(): void;
  success_progress(): void;
}

const alert = ({
  message,
  title,
  items,
  options
}: {
  message?: string | JSX.Element;
  title?: string;
  items?: Array<string>;
  options?: ToastOptions;
}): IAlerts => ({
  success() {
    toast.success(() => AlertMessage(message, title, items), {
      position: 'top-center',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: true,
      draggable: false,
      icon: <TickIcon color={colors.statusGreen} />,
      style: toastStyle(colors.statusGreen),
      closeButton: CloseButton,
      ...options
    });
  },
  warning() {
    toast.warn(() => AlertMessage(message, title, items), {
      position: 'top-center',
      autoClose: 4000,
      hideProgressBar: true,
      closeOnClick: false,
      draggable: false,
      icon: <WarningIcon color={colors.statusOrange} />,
      style: toastStyle(colors.statusOrange),
      closeButton: CloseButton,
      ...options
    });
  },
  error() {
    toast.error(() => AlertMessage(message, title, items), {
      position: 'top-center',
      autoClose: 4000,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      icon: <ErrorIcon color={colors.statusRed} />,
      style: toastStyle(colors.statusRed),
      closeButton: CloseButton,
      ...options
    });
  },
  info() {
    toast.info(() => AlertMessage(message, title, items), {
      position: 'top-center',
      autoClose: false,
      closeOnClick: false,
      hideProgressBar: true,
      draggable: false,
      icon: <InfoIcon color={colors.azure50} />,
      style: toastStyle(colors.azure50),
      closeButton: CloseButton,
      ...options
    });
  },
  success_progress() {
    toast.success(() => AlertMessage(message, title, items), {
      position: 'top-center',
      autoClose: 10000,
      closeOnClick: false,
      pauseOnHover: false,
      hideProgressBar: false,
      draggable: false,
      icon: <TickIcon color={colors.statusGreen} />,
      style: toastStyle(colors.statusGreen),
      closeButton: CloseButton,
      ...options
    });
  }
});

export default alert;
