import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { HomeCoverage } from '../../interfaces/ICoverage';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const usePolicyHomeCoverages = (
  policyId: string | number,
  lineOfBusinessId?: number,
  transactionId?: number,
  ivansHomeId?: number,
  options?: Omit<UseQueryOptions<{ coverages: HomeCoverage[] }, any, any, any>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['policy_coverages', policyId, lineOfBusinessId, transactionId, ivansHomeId],
    queryFn:
      !!lineOfBusinessId && !!transactionId && !!ivansHomeId
        ? (): Promise<{ coverages: HomeCoverage[] }> =>
            api.get(
              `/api/frontend/policies/${policyId}/home/${lineOfBusinessId}/coverages?${toQueryParams({
                transaction_id: transactionId,
                ivans_home_id: ivansHomeId
              })}`
            )
        : skipToken,
    select: data => data.coverages,
    ...options
  });

export default usePolicyHomeCoverages;
