/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field, Form, Formik, useFormikContext } from 'formik';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { ErrorCol } from '../../../components/common';
import { DatePicker, MultiSelect } from '../../../components/common/FormikComponents/FormikComponents';
import NewTooltip from '../../../components/common/Tooltip/NewTooltip';
import { InfoIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { Translations } from '../../../constants';
import { DispositionType } from '../../../interfaces/IDisposition';
import { PrimaryOpportunities } from '../../../interfaces/ISourceDimensions';
import { LicensingTypes, UserRoleCategory, UserStatus } from '../../../interfaces/IUser';
import useAssignees from '../../../queries/assignees/useAssignees';
import { LeadsDashboardFilters as ILeadsDashboardFilters } from '../../../queries/leads/useLeads';
import usePartners from '../../../queries/partners/usePartners';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import {
  filterDateValidation,
  FUTURE_DATE,
  isDateRangeValid,
  OLD_DATE,
  TO_IS_GREATER_THAN_FROM
} from '../../../utils/dateRangeHelpers';
import { showFieldErrorGenerator } from '../../../utils/formHelpers';

const BLANK = '';
const EMPTY_ARRAY: string[] = [];

const LEAD_CAPTURE_METHOD_TOOLTIP =
  'Lead Capture Method\n\nThe initial way a person expressed interest in a ' +
  'Matic, that resulted in a lead being created.';

const ATTRIBUTE_MARKETING_MEDIUM_TOOLTIP =
  'Attributed Marketing Medium\n\nThe marketing medium that was used to send the customer to the ' +
  'lead capture method. The action that occurs just before the lead is captured.';
const PRIMARY_OPPORTUNITY_TOOLTIP =
  'The primary opportunity shows what kind of insurance customer wants in the first place.';

interface ConfigOption {
  key: string;
  value: string;
}

const filterReasons = (allKeys: string[], expectedReasons: ConfigOption[]) => {
  if (allKeys) {
    const expectedKeys = expectedReasons.map(item => item.key);

    return allKeys.filter(key => expectedKeys.includes(key));
  }

  return EMPTY_ARRAY;
};

const ClearValue = ({ name, value, children }: { name: string; value: any; children: JSX.Element }) => {
  const { setFieldValue } = useFormikContext();

  useEffect(() => () => setFieldValue(name, value), [name, value, setFieldValue]);

  return children;
};
interface LeadsDashboardFiltersProps {
  filters?: ILeadsDashboardFilters;
  message: string;
  onFilterReset(): void;
  onFilterSubmit(data: Record<string, string | any>): void;
}

const LeadsDashboardFilters = ({
  filters = {},
  message,
  onFilterReset,
  onFilterSubmit
}: LeadsDashboardFiltersProps) => {
  const { data: partners } = usePartners();
  const { data: assignees } = useAssignees({ statuses: [UserStatus.Active, UserStatus.Suspended] });

  const lifeOpportunityFilterIsAvailable = Boolean(
    authInfo.roleCategory === UserRoleCategory.Admin || authInfo.licensingTypes?.includes(LicensingTypes.Life)
  );

  const primaryOpportunitiesOptions = lifeOpportunityFilterIsAvailable
    ? Translations.primaryOpportunityTypeOptions
    : Translations.primaryOpportunityTypeOptions.filter(option => option.key !== PrimaryOpportunities.Life);

  return (
    <Formik
      initialValues={{
        agent_ids: filters.agent_ids || EMPTY_ARRAY,
        dispositions: filters.dispositions || EMPTY_ARRAY,
        disposition_not_qualified_reasons: filterReasons(
          filters.disposition_reasons as string[],
          Translations.notQualifiedReasonOptions
        ),
        disposition_could_not_contact_reasons: filterReasons(
          filters.disposition_reasons as string[],
          Translations.couldNotContactReasonOptions
        ),
        partners: filters.partners || EMPTY_ARRAY,
        source_products: filters.source_products || EMPTY_ARRAY,
        lead_capture_methods: filters.lead_capture_methods || EMPTY_ARRAY,
        attributed_marketing_mediums: filters.attributed_marketing_mediums || EMPTY_ARRAY,
        residential_states: filters.residential_states || EMPTY_ARRAY,
        tasks: filters.tasks || EMPTY_ARRAY,
        time: filters.time || BLANK,
        created_from: filters.created_from || BLANK,
        created_to: filters.created_to || BLANK,
        savings: filters.savings || EMPTY_ARRAY,
        primary_opportunities: filters.primary_opportunities || EMPTY_ARRAY,
        business_types: filters.business_types || EMPTY_ARRAY,
        apic_created_from: filters.apic_created_from || BLANK,
        apic_created_to: filters.apic_created_to || BLANK,
        lead_mode: filters.lead_mode || EMPTY_ARRAY
      }}
      enableReinitialize
      onSubmit={({ disposition_not_qualified_reasons, disposition_could_not_contact_reasons, ...filters }) =>
        onFilterSubmit({
          ...filters,
          disposition_reasons: [...disposition_not_qualified_reasons, ...disposition_could_not_contact_reasons],
          time: filters.time ? moment(filters.time).format() : BLANK,
          created_from: filters.created_from ? moment(filters.created_from).format() : BLANK,
          created_to: filters.created_to ? moment(filters.created_to).format() : BLANK,
          apic_created_from: filters.apic_created_from ? moment(filters.apic_created_from).format() : BLANK,
          apic_created_to: filters.apic_created_to ? moment(filters.apic_created_to).format() : BLANK
        })
      }
      validationSchema={yup.object().shape({
        created_from: filterDateValidation,
        created_to: filterDateValidation,
        apic_created_from: filterDateValidation,
        apic_created_to: filterDateValidation
      })}
    >
      {({ values, errors, touched, isValid }) => {
        const showFieldError = showFieldErrorGenerator({ values, touched, isValid });
        return (
          <Form
            css={css`
              padding-top: 24px;
            `}
            autoComplete="off"
          >
            <h6>Filters</h6>
            <Row>
              <Col sm={4}>
                <FormGroup row id="agent-filter">
                  <Label htmlFor="agent_ids" sm={4}>
                    Agent
                  </Label>
                  <Col sm={8}>
                    <Field
                      inputId="agent_ids"
                      name="agent_ids"
                      component={MultiSelect}
                      options={assignees}
                      valueName="id"
                      labelName="name"
                      showResetButton
                      ordered
                    />
                  </Col>
                </FormGroup>
                <FormGroup row id="disposition-filter">
                  <Label htmlFor="dispositions" sm={4}>
                    Disposition
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="dispositions"
                      inputId="dispositions"
                      component={MultiSelect}
                      options={Translations.dispositionTypeOptions}
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                {values.dispositions.includes(DispositionType.NotQualified) && (
                  <ClearValue name="disposition_not_qualified_reasons" value={EMPTY_ARRAY}>
                    <FormGroup row id="disposition-not-qualified-reason-filter">
                      <Label htmlFor="disposition_not_qualified_reasons" sm={4}>
                        Not qualified reason
                      </Label>
                      <Col sm={8}>
                        <Field
                          name="disposition_not_qualified_reasons"
                          inputId="disposition_not_qualified_reasons"
                          component={MultiSelect}
                          options={Translations.notQualifiedReasonOptions}
                          showResetButton
                        />
                      </Col>
                    </FormGroup>
                  </ClearValue>
                )}
                {values.dispositions.includes(DispositionType.CouldNotContact) && (
                  <ClearValue name="disposition_could_not_contact_reasons" value={EMPTY_ARRAY}>
                    <FormGroup row id="disposition-could-not-contact-reason-filter">
                      <Label htmlFor="disposition_could_not_contact_reasons" sm={4}>
                        Could not contact reason
                      </Label>
                      <Col sm={8}>
                        <Field
                          name="disposition_could_not_contact_reasons"
                          inputId="disposition_could_not_contact_reasons"
                          component={MultiSelect}
                          options={Translations.couldNotContactReasonOptions}
                          showResetButton
                        />
                      </Col>
                    </FormGroup>
                  </ClearValue>
                )}
                <FormGroup row id="residential_state-filter">
                  <Label htmlFor="residential_states" sm={4}>
                    Residential State
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="residential_states"
                      inputId="residential_states"
                      component={MultiSelect}
                      options={Translations.usaStates}
                      ordered
                      showResetButton
                    />
                  </Col>
                </FormGroup>

                <FormGroup row id="lead_mode-filter">
                  <Label htmlFor="lead_mode" sm={4}>
                    Lead Mode
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="lead_mode"
                      inputId="lead_mode"
                      component={MultiSelect}
                      options={
                        authInfo.roleCategory === UserRoleCategory.Admin
                          ? Translations.managerLeadModeOptions
                          : Translations.leadModeOptions
                      }
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                <FormGroup row id="business_types-filter">
                  <Label htmlFor="business_types" sm={4}>
                    Business type
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="business_types"
                      inputId="business_types"
                      component={MultiSelect}
                      options={Translations.businessTypeOptions}
                      ordered
                      showResetButton
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup row id="partner-filter">
                  <Label htmlFor="partners" sm={4}>
                    Partner
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="partners"
                      inputId="partners"
                      data-testid="partner"
                      component={MultiSelect}
                      options={partners}
                      valueName="key"
                      labelName="name"
                      ordered
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                <FormGroup row id="source-products-filter">
                  <Label htmlFor="source_products" sm={4}>
                    Source Product
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="source_products"
                      inputId="source_products"
                      component={MultiSelect}
                      options={Translations.sourceProductOptions}
                      ordered
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                <NewTooltip id="source-dimensions-tip" />
                <FormGroup row id="lead-capture-methods-filter">
                  <Label
                    htmlFor="lead_capture_methods"
                    sm={4}
                    data-tip={LEAD_CAPTURE_METHOD_TOOLTIP}
                    data-for="source-dimensions-tip"
                  >
                    <InfoIcon
                      width={18}
                      color={colors.grey60}
                      css={css`
                        margin: 0 2px;
                        position: relative;
                        bottom: 2px;
                      `}
                    />
                    LCM
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="lead_capture_methods"
                      inputId="lead_capture_methods"
                      component={MultiSelect}
                      options={Translations.leadCaptureMethodOptions}
                      ordered
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                <FormGroup row id="attributed-marketing-mediums-filter">
                  <Label
                    htmlFor="attributed_marketing_mediums"
                    sm={4}
                    data-tip={ATTRIBUTE_MARKETING_MEDIUM_TOOLTIP}
                    data-for="source-dimensions-tip"
                  >
                    <InfoIcon
                      width={18}
                      color={colors.grey60}
                      css={css`
                        margin: 0 2px;
                        position: relative;
                        bottom: 2px;
                      `}
                    />
                    AMM
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="attributed_marketing_mediums"
                      inputId="attributed_marketing_mediums"
                      component={MultiSelect}
                      options={Translations.attributedMarketingMediumOptions}
                      ordered
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                <FormGroup row id="primary_opportunities-filter">
                  <Label
                    htmlFor="primary_opportunities"
                    sm={4}
                    data-tip={PRIMARY_OPPORTUNITY_TOOLTIP}
                    data-for="source-dimensions-tip"
                  >
                    <InfoIcon
                      width={18}
                      color={colors.grey60}
                      css={css`
                        margin: 0 2px;
                        position: relative;
                        bottom: 2px;
                      `}
                    />
                    Primary oppor...
                  </Label>
                  <Col sm={8}>
                    <Field
                      name="primary_opportunities"
                      inputId="primary_opportunities"
                      component={MultiSelect}
                      options={primaryOpportunitiesOptions}
                      ordered
                      showResetButton
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col sm={4}>
                <FormGroup row>
                  <Label htmlFor="time" sm={4}>
                    Last activity
                  </Label>
                  <Col sm={8}>
                    <Field id="time" name="time" component={DatePicker} placeholder="Since" />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="created_from" sm={4}>
                    Created at
                  </Label>
                  <ErrorCol sm={4} when={showFieldError('created_from')} error={errors.created_from}>
                    <Field
                      id="created_from"
                      name="created_from"
                      component={DatePicker}
                      placeholder="From"
                      minDate={OLD_DATE}
                      maxDate={FUTURE_DATE}
                    />
                  </ErrorCol>
                  <Label hidden htmlFor="created_to">
                    Created to
                  </Label>
                  <ErrorCol
                    sm={4}
                    when={showFieldError('created_to')}
                    warning={
                      !errors.created_to &&
                      !isDateRangeValid({ from: values.created_from as string, to: values.created_to as string }) &&
                      TO_IS_GREATER_THAN_FROM
                    }
                    error={errors.created_to}
                  >
                    <Field
                      id="created_to"
                      name="created_to"
                      component={DatePicker}
                      placeholder="To"
                      minDate={OLD_DATE}
                      maxDate={FUTURE_DATE}
                    />
                  </ErrorCol>
                </FormGroup>
                <FormGroup row id="task-filter">
                  <Label htmlFor="tasks" sm={4}>
                    Task
                  </Label>
                  <Col sm={8}>
                    <Field
                      inputId="tasks"
                      name="tasks"
                      component={MultiSelect}
                      options={Translations.tasksFilter}
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                <FormGroup row id="savings-filter">
                  <Label htmlFor="savings" sm={4}>
                    Savings
                  </Label>
                  <Col sm={8}>
                    <Field
                      inputId="savings"
                      name="savings"
                      component={MultiSelect}
                      options={Translations.savingsOptions}
                      showResetButton
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="apic_created_from" sm={4}>
                    APIC Created at
                  </Label>
                  <ErrorCol sm={4} when={showFieldError('apic_created_from')} error={errors.apic_created_from}>
                    <Field
                      id="apic_created_from"
                      name="apic_created_from"
                      component={DatePicker}
                      placeholder="From"
                      minDate={OLD_DATE}
                      maxDate={FUTURE_DATE}
                    />
                  </ErrorCol>
                  <Label hidden htmlFor="apic_created_to">
                    APIC Created to
                  </Label>
                  <ErrorCol
                    sm={4}
                    when={showFieldError('apic_created_to')}
                    warning={
                      !errors.apic_created_to &&
                      !isDateRangeValid({
                        from: values.apic_created_from as string,
                        to: values.apic_created_to as string
                      }) &&
                      TO_IS_GREATER_THAN_FROM
                    }
                    error={errors.apic_created_to}
                  >
                    <Field
                      id="apic_created_to"
                      name="apic_created_to"
                      component={DatePicker}
                      placeholder="To"
                      minDate={OLD_DATE}
                      maxDate={FUTURE_DATE}
                    />
                  </ErrorCol>
                </FormGroup>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col sm={6}>
                <Text color={colors.grey80}>{message}</Text>
              </Col>
              <Col sm={6} className="btn-container">
                <Button
                  color="warning"
                  onClick={() => {
                    onFilterReset();
                  }}
                >
                  Reset to default
                </Button>
                <Button color="primary" type="submit" role="submit">
                  Apply
                </Button>
              </Col>
            </Row>
          </Form>
        );
      }}
    </Formik>
  );
};

export default LeadsDashboardFilters;
