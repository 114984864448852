import { apiBaseUrl } from '../services/api';

export interface FileToUpload extends FileToConfirm {
  document_type_id: number;
  fileOwner: string;
  document_type_title: string | null;
  document_type_key?: string | undefined;
}

export interface FileToConfirm {
  file: File;
  filesize: number;
  uid: string;
  name: string;
}

export interface InvalidFile {
  file: File;
  error: string;
}

export interface DocumentOwnerOption {
  key: string | undefined;
  value: string;
  default: boolean;
}

export enum DocumentOwnerType {
  Lead = 'Lead',
  Opportunity = 'Opportunity',
  Policy = 'Policy'
}

export interface PolicyFileToProcess {
  id?: number;
  deleted?: boolean;
  uid: string;
  name: string;
  filesize: number;
  file: File;
  owner_type: DocumentOwnerType;
  owner_id: number | null;
  document_type_id: number;
}

export interface IDocument {
  id: number;
  filesize: number;
  file_path: string;
  name: string;
  uploaded: string;
  owner_type: DocumentOwnerType;
  owner_id: number | null;
  document_type_id: number | null;
  document_type_key: string | null;
  document_type_title: string | null;
  document_type_allowed_for_fax: boolean;
}

const documentFileUrl = ({ file_path }: { file_path: IDocument['file_path'] }) => {
  if (import.meta.env.DEV) {
    // Use back-end URL to get around proxy when using local development server
    return `${import.meta.env.VITE_APP_BACKEND_URL}${file_path}`;
  }

  return `${apiBaseUrl()}${file_path}`;
};

export const documentFilePreviewUrl = ({ file_path }: { file_path: IDocument['file_path'] }) =>
  `${documentFileUrl({ file_path })}?disposition=inline`;

export const documentFileDownloadUrl = ({ file_path }: { file_path: IDocument['file_path'] }) =>
  `${documentFileUrl({ file_path })}?disposition=attachment`;
