/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import { Formik } from 'formik';
import * as React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import { Link } from '../../components/common';
import alert from '../../components/core/Alert';
import Button from '../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import Modal, { ModalSize } from '../../components/core/Modal';
import Paragraph from '../../components/core/Paragraph';
import {
  ConditionGroup,
  Operations,
  ScoutingStrategy,
  SSC_QUERY_KEY,
  useCreateScoutingStrategyConfig,
  useDeleteScoutingStrategyConfig,
  useScoutingStrategyConfigsList,
  useScoutingStrategyVariables,
  useUpdateScoutingStrategyConfig
} from '../../queries/scouting_strategies/useScoutingStrategy';
import useScoutingStrategySteps, {
  StepWithoutConditionGroups
} from '../../queries/scouting_strategy_steps/useScoutingStrategySteps';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { sortSteps } from './_utils';
import HelpModal from './HelpModal';
import StrategyConfigEditor from './StrategyConfigEditor';

const insertItemAtList = (list: ScoutingStrategy[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const itemToUpdate = result[startIndex];
  const sourceItem = result[endIndex];
  if (itemToUpdate && sourceItem) {
    itemToUpdate.priority = sourceItem.priority;
    result.splice(startIndex, 1);
    result.splice(endIndex, 0, itemToUpdate);
  }

  return result;
};

const LeftBar = ({ list }: { list: ScoutingStrategy[] }) => {
  const [configToDelete, setConfigToDelete] = React.useState<ScoutingStrategy>();
  const navigate = useNavigate();
  const { strategyId } = useParams();

  const { mutateAsync: deleteConfig } = useDeleteScoutingStrategyConfig();
  const { mutateAsync: updateConfig, isPending: isUpdatingConfig } = useUpdateScoutingStrategyConfig();
  const queryClient = useQueryClient();

  return (
    <div
      css={css`
        grid-area: left-bar;
        display: grid;
        overflow: hidden;
      `}
    >
      {configToDelete && (
        <Modal
          title="Help"
          size={ModalSize.medium}
          dangerModal
          confirmHandler={async () => {
            const needsReset = strategyId === configToDelete.id.toString();

            await deleteConfig(configToDelete.key).finally(() => {
              alert({ title: 'Config deleted' }).success();
              queryClient.invalidateQueries({ queryKey: [SSC_QUERY_KEY] });
              setConfigToDelete(undefined);
              needsReset && navigate('/scouting_report_scripts');
            });
          }}
          cancelHandler={() => setConfigToDelete(undefined)}
        >
          <Paragraph
            customCss={css`
              white-space: break-spaces;
            `}
          >
            You are going to delete config {configToDelete.name}
          </Paragraph>
        </Modal>
      )}
      <div
        css={css`
          overflow-y: scroll;
          &:last-child {
            padding-bottom: 24px;
          }
        `}
      >
        <DragDropContext
          onDragEnd={result => {
            if (!result.destination) {
              return;
            }

            const { draggableId: strategyKey, destination, source } = result;

            queryClient.setQueryData([SSC_QUERY_KEY], {
              scouting_strategy_configs: insertItemAtList(list, source.index, destination.index)
            });
            const sourceItem = list[source.index];
            const destinationItem = list[destination.index];
            if (sourceItem && destinationItem) {
              updateConfig({
                key: strategyKey,
                priority: destinationItem.priority,
                condition_groups: sourceItem.condition_groups
              }).finally(() => {
                queryClient.invalidateQueries({ queryKey: [SSC_QUERY_KEY] });
              });
            }
          }}
        >
          <Droppable droppableId="droppable">
            {provided => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                css={css`
                  opacity: ${isUpdatingConfig ? 0.5 : 1};
                `}
              >
                {list.map((strategy, index) => (
                  <NavLink key={strategy.id} to={`/scouting_report_scripts/${strategy.id}`}>
                    {({ isActive }) => (
                      <Draggable
                        key={strategy.id}
                        draggableId={strategy.key}
                        index={index}
                        isDragDisabled={isUpdatingConfig}
                      >
                        {provided => (
                          <FlexBox
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            justifySpaceBetween
                            alignItemsCenter
                            backgroundColor={isActive ? colors.grey5 : colors.white}
                            pv={spacings.px12}
                            ph={spacings.px24}
                            customCss={css`
                              .hidden-child {
                                visibility: hidden;
                              }
                              &:hover {
                                background-color: ${colors.grey5};
                                .hidden-child {
                                  visibility: visible;
                                }
                              }
                            `}
                          >
                            <FlexBox
                              alignItemsCenter
                              justifySpaceBetween
                              customCss={css`
                                min-width: 0px;
                                flex: 1;
                              `}
                            >
                              <Paragraph
                                type="small"
                                mb={spacings.px4}
                                customCss={css`
                                  text-transform: capitalize;
                                `}
                              >
                                {`${strategy.priority}. ${strategy.name}`}
                              </Paragraph>
                              <IconButton
                                className="hidden-child"
                                icon={ButtonIcons.Delete}
                                onClick={e => {
                                  e.preventDefault();
                                  e.stopPropagation();

                                  setConfigToDelete(strategy);
                                }}
                              />
                            </FlexBox>
                          </FlexBox>
                        )}
                      </Draggable>
                    )}
                  </NavLink>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

const Header = () => {
  return (
    <div
      css={css`
        grid-area: header;
        box-shadow: inset 0px -1px 0px ${colors.grey10};
      `}
    >
      <FlexBox justifySpaceBetween alignItemsCenter p={spacings.px24}>
        <Heading type="h3">Scouting report scripts</Heading>
        <FlexBox gap={spacings.px8}>
          <Link to="/scouting_report_scripts/new">
            <Button>New config</Button>
          </Link>
          <Link to="/scouting_steps">
            <Button>Switch to steps editor</Button>
          </Link>
          <HelpModal />
        </FlexBox>
      </FlexBox>
    </div>
  );
};

const RightBar = ({
  stepsList,
  stepsInFormIds
}: {
  stepsList: StepWithoutConditionGroups[];
  stepsInFormIds: number[];
}) => {
  return (
    <div
      css={css`
        border-left: solid 1px ${colors.grey10};
        grid-area: 'info-bar';
        overflow: auto;
        padding: 16px;
      `}
    >
      <Container mb={spacings.px24}>
        <div
          css={css`
            > div:nth-of-type(n) {
              margin-bottom: 12px;
            }
          `}
        >
          {stepsList
            .filter(s => !stepsInFormIds.includes(s.id))
            .map((s, index) => (
              <Draggable key={s.id} index={stepsInFormIds.length + index} draggableId={`${s.id}`}>
                {provided => (
                  <Container
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    customCss={css`
                      &:hover {
                        outline: 1px dashed ${colors.azure50};
                        outline-offset: 2px;
                      }
                    `}
                    innerRef={provided.innerRef}
                  >
                    <Paragraph>{s.name || s.title}</Paragraph>
                  </Container>
                )}
              </Draggable>
            ))}
        </div>
      </Container>
    </div>
  );
};

const ScoutingReportScripts = () => {
  const { strategyId } = useParams();
  const navigate = useNavigate();

  const { data: list } = useScoutingStrategyConfigsList();
  const { data: stepsList } = useScoutingStrategySteps();
  const queryClient = useQueryClient();
  const { mutateAsync: createConfig } = useCreateScoutingStrategyConfig();
  const { mutateAsync: updateConfig } = useUpdateScoutingStrategyConfig();

  const { data: variables } = useScoutingStrategyVariables();

  if (!list || !variables) {
    return null;
  }

  let strategyConfig = list.find(c => `${c.id}` === strategyId) || list[0] || ({} as ScoutingStrategy);
  if (strategyConfig.id) {
    // transform condition value from comma separated string to array of string
    // only for one_of and not_one_of operations that have possible values provided by backend
    // onSubmit transform it back to comma separated string
    strategyConfig.scouting_strategy_steps.forEach(s => {
      s.condition_groups.forEach(cg => {
        cg.conditions.forEach(c => {
          if (
            c.value &&
            typeof c.value === 'string' &&
            [Operations.ONE_OF, Operations.NOT_ONE_OF].includes(c.operation) &&
            variables.get(c.source)?.values
          ) {
            c.value = c.value.split(',');
          }
        });
      });
    });

    strategyConfig.condition_groups?.forEach(cg => {
      cg.conditions.forEach(c => {
        if (
          c.value &&
          typeof c.value === 'string' &&
          [Operations.ONE_OF, Operations.NOT_ONE_OF].includes(c.operation) &&
          variables.get(c.source)?.values
        ) {
          c.value = c.value.split(',');
        }
      });
    });
  }

  const createFlow = strategyId === 'new';

  if (createFlow) {
    strategyConfig = {
      id: 1,
      priority: 0,
      key: '',
      name: '',
      scouting_strategy_steps: [],
      steps: [],
      condition_groups: []
    };
  }

  const sortedStepsList = sortSteps(stepsList);

  if (!strategyConfig || !sortedStepsList) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      onSubmit={async values => {
        const reformatConditionGroup = (cg: ConditionGroup) => ({
          ...cg,
          conditions: cg.conditions.map(c => ({
            ...c,
            value: Array.isArray(c.value) ? c.value.toString() : c.value
          }))
        });

        if (createFlow) {
          const config = await createConfig({
            ...values,
            condition_groups: (values.condition_groups || []).map(reformatConditionGroup),
            steps: values.scouting_strategy_steps.map((s, index) => ({
              step_id: s.id,
              position: index,
              condition_groups: s.condition_groups.map(reformatConditionGroup)
            }))
          });

          alert({ title: 'Config created' }).success();
          await queryClient.refetchQueries({ queryKey: [SSC_QUERY_KEY] });
          return navigate(`/scouting_report_scripts/${config.scouting_strategy_config.id}`);
        }

        await updateConfig({
          ...values,
          condition_groups: (values.condition_groups || []).map(reformatConditionGroup),
          steps: values.scouting_strategy_steps.map((s, index) => ({
            step_id: s.id,
            position: index,
            condition_groups: s.condition_groups.map(reformatConditionGroup)
          }))
        });

        alert({ title: 'Config updated' }).success();
        queryClient.invalidateQueries({ queryKey: [SSC_QUERY_KEY] });
      }}
      initialValues={strategyConfig as ScoutingStrategy & Record<string, any>}
      validationSchema={yup.object().shape({
        key: yup.string().required(),
        name: yup.string().required(),
        condition_groups: yup.array().of(
          yup.object().shape({
            conditions: yup.array().of(
              yup.object().shape({
                operation: yup.string().required(),
                source: yup.string().required(),
                value: yup.mixed().required()
              })
            )
          })
        ),
        scouting_strategy_steps: yup.array().of(
          yup.object().shape({
            condition_groups: yup.array().of(
              yup.object().shape({
                conditions: yup.array().of(
                  yup.object().shape({
                    operation: yup.string().required(),
                    source: yup.string().required(),
                    value: yup.mixed().required()
                  })
                )
              })
            )
          })
        )
      })}
    >
      {({ values, setFieldValue, submitForm }) => {
        const stepsInForm = values.scouting_strategy_steps || [];
        const stepsInFormIds = stepsInForm.map(s => s.id);
        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              submitForm();
            }}
          >
            <DragDropContext
              onDragEnd={result => {
                if (!result.destination) {
                  return;
                }

                const reorderFlow = stepsInFormIds.includes(Number(result.draggableId));
                const stepsCopy = Array.from(stepsInForm);

                if (reorderFlow) {
                  const [reorderedItem] = stepsCopy.splice(result.source.index, 1);
                  reorderedItem && stepsCopy.splice(result.destination.index, 0, reorderedItem);
                } else {
                  const stepToAdd = sortedStepsList.find(s => s.id === Number(result.draggableId));

                  if (stepToAdd) {
                    stepsCopy.splice(result.destination.index, 0, { ...stepToAdd, condition_groups: [] });
                  }
                }

                setFieldValue('scouting_strategy_steps', stepsCopy);
              }}
            >
              <Droppable droppableId="steps">
                {provided => (
                  <div
                    css={css`
                      display: grid;
                      grid-auto-columns: 1fr;
                      grid-template-columns: 300px 1fr 300px;
                      grid-template-rows: fit-content(116px) 1fr;
                      grid-template-areas:
                        'header header header'
                        'left-bar guide info-bar';
                      height: 100vh;
                    `}
                  >
                    <LeftBar list={list} />
                    <Header />
                    <div
                      css={css`
                        background: ${colors.white};
                        grid-area: 'guide';
                        overflow: hidden;
                        display: grid;
                      `}
                    >
                      <div
                        css={css`
                          overflow-y: scroll;
                          padding: 20px 24px 0;
                        `}
                      >
                        {strategyConfig && (
                          <StrategyConfigEditor
                            configKey={createFlow ? undefined : strategyConfig.key}
                            droppableProvided={provided}
                          />
                        )}
                      </div>
                    </div>
                    <RightBar stepsList={sortedStepsList} stepsInFormIds={stepsInFormIds} />
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </form>
        );
      }}
    </Formik>
  );
};

export default ScoutingReportScripts;
