import PropTypes from 'prop-types';
import React from 'react';

import FilesListItem from '../FilesListItem';

const FilesList = ({ files = [], filter, onDelete }: any) =>
  files.filter(filter).map((file: { uid: any }) => {
    // @ts-expect-error old code
    return <FilesListItem key={file.uid} file={file} onDelete={onDelete} />;
  });

FilesList.propTypes = {
  files: PropTypes.arrayOf(
    PropTypes.shape({
      uid: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ),
  filter: PropTypes.func,
  onDelete: PropTypes.func
};

export default FilesList;
