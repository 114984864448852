import { Grid } from '@mui/material';
import * as Sentry from '@sentry/react';

import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import { spacings } from '../../../../theme/variables';
import CurrentPolicies from './CurrentPolicies';
import CustomerChoices from './CustomerChoices';
import CustomerChoiceErrorFallback from './CustomerChoices/CustomerChoiceErrorFallback';

const Comparison = () => {
  return (
    <FlexBox gap={spacings.px16} columnDirection>
      <Heading type="h4">Comparison</Heading>
      <Grid container spacing={3}>
        <Grid item md={12} lg>
          <CurrentPolicies />
        </Grid>
        <Grid item md={12} lg>
          <Sentry.ErrorBoundary fallback={CustomerChoiceErrorFallback}>
            <CustomerChoices />
          </Sentry.ErrorBoundary>
        </Grid>
      </Grid>
    </FlexBox>
  );
};

export default Comparison;
