import { skipToken, useQuery } from '@tanstack/react-query';

import { IDashboardLead } from '../../interfaces';
import { ILeadMode } from '../../interfaces/IDashboardLead';
import { UserRoleCategory } from '../../interfaces/IUser';
import api from '../../services/api';
import authInfo from '../../services/authInfo';
import { toQueryParams } from '../../utils/queryParams';

export const getRecentLeads = (leadIds: number[] | undefined): Promise<{ leads: IDashboardLead[] }> =>
  api.get(`/api/frontend/leads/recent?${toQueryParams({ leads_ids: leadIds })}`);

const useRecentLeads = (leadIds: number[]) =>
  useQuery({
    queryKey: ['recent_leads', leadIds],
    queryFn: leadIds.length > 0 ? () => getRecentLeads(leadIds) : skipToken,
    select: data =>
      data.leads.filter(
        lead => authInfo.roleCategory === UserRoleCategory.Admin || lead.lead_mode !== ILeadMode.FakeLead
      )
  });

export default useRecentLeads;
