/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import alert from '../../components/core/Alert';
import FlexBox from '../../components/core/FlexBox';
import FormModal from '../../components/core/FormModal';
import { CheckboxField, InputField, MultiSelectField } from '../../components/core/forms/fields';
import Heading from '../../components/core/Heading';
import { AppsIcon } from '../../components/core/icons';
import { ModalSize } from '../../components/core/Modal';
import Paragraph from '../../components/core/Paragraph';
import Table from '../../components/core/Table/Table';
import Tag from '../../components/core/Tag';
import Text from '../../components/core/Text';
import { useDocumentTitle } from '../../hooks';
import useAssignees from '../../queries/assignees/useAssignees';
import {
  IFullFeatureFlag,
  IUpdateFeatureFlagRequest,
  useFeatureFlag,
  useFeatureFlagsConfig,
  useUpdateFeatureFlag
} from '../../queries/feature_flags/useFeatureFlags';
import useUsersRoles from '../../queries/users_roles/useUsersRoles';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';

const EditFlagModal = ({ flagId, cancelHandler }: { flagId: number; cancelHandler: () => void }) => {
  const { data, isSuccess: isFFFetched } = useFeatureFlag(flagId);
  const { data: agents, isSuccess: isAgentsFetched } = useAssignees();
  const { data: roles, isSuccess: isRolesFetched } = useUsersRoles();
  const { mutateAsync: updateFF } = useUpdateFeatureFlag();

  const isLoading = !(isFFFetched && isAgentsFetched && isRolesFetched);

  const agentsOptions = React.useMemo(() => agents?.map(agent => ({ key: agent.id, value: agent.name })), [agents]);
  return (
    <FormModal
      size={ModalSize.extra}
      cancelHandler={cancelHandler}
      confirmHandler={(submittedFlag: IUpdateFeatureFlagRequest) =>
        updateFF(submittedFlag).then(() => {
          cancelHandler();
          alert({ message: 'Feature flag updated successfully' }).success();
        })
      }
      initialValues={{
        id: (data?.id || '') as unknown as IFullFeatureFlag['id'],
        description: data?.description || '',
        jira_ticket: data?.jira_ticket || '',
        enabled_for_all: data?.enabled_for_all || false,
        agent_ids: data?.agents?.map(agent => agent.id) || [],
        roles: (data?.roles?.map(role => role.name) || []) as unknown as IFullFeatureFlag['roles']
      }}
      renderForm={({ values }) => {
        if (isLoading) {
          return (
            <FlexBox columnDirection data-testid="lead-summary-lead-info-loader">
              <Heading type="h4">
                <Skeleton />
              </Heading>
              <Text mt={spacings.px16}>
                <Skeleton count={4} />
              </Text>
            </FlexBox>
          );
        }

        return (
          <FlexBox columnDirection gap={spacings.px24}>
            <Heading>{data?.feature_name}</Heading>
            <InputField label="Description name" id="description" name="description" />
            <InputField label="JIRA Ticket" id="jira_ticket" name="jira_ticket" />
            <CheckboxField label="Enabled for all agents" id="enabled_for_all" name="enabled_for_all" />
            <MultiSelectField
              disabled={values.enabled_for_all}
              id="agent_ids"
              name="agent_ids"
              label="Users"
              options={agentsOptions!}
              withValueRemove
            />
            <MultiSelectField
              disabled={values.enabled_for_all}
              id="roles"
              name="roles"
              label="Roles"
              options={roles!.map(role => ({ key: role.name, value: role.label }))}
              withValueRemove
            />
          </FlexBox>
        );
      }}
    />
  );
};

const columnHelper = createColumnHelper<IFullFeatureFlag>();

const FeatureFlags = (): JSX.Element => {
  const [flagIdToEdit, setFlagIdToEdit] = React.useState<number | ''>('');
  const { data: featureFlags } = useFeatureFlagsConfig();
  const cancelHandler = () => setFlagIdToEdit('');
  useDocumentTitle('Feature flags');

  const TABLE_COLUMNS = React.useMemo(
    () => [
      columnHelper.accessor(row => row.feature_name, {
        id: 'feature_name',
        cell: info => info.getValue(),
        header: () => <Paragraph>Feature key</Paragraph>
      }),
      columnHelper.display({
        id: 'status',
        cell: ({ row: { original } }) => {
          if (original.enabled_for_all) {
            return <Tag label="Enabled for all" backgroundColor={colors.statusGreen} />;
          }

          if (original.partially_enabled) {
            return <Tag label="Partially enabled" backgroundColor={colors.statusOrange} />;
          }

          return <Tag label="Disabled" backgroundColor={colors.grey30} />;
        },
        header: () => <Paragraph>Status</Paragraph>
      }),
      columnHelper.accessor(row => row.description, {
        id: 'description',
        cell: info => info.getValue(),
        header: () => <Paragraph>Description</Paragraph>,
        minSize: 500
      }),
      columnHelper.accessor(row => row.jira_ticket, {
        id: 'jira_ticket',
        cell: info => (
          <a href={info.getValue()} target="_blank" rel="noopener noreferrer">
            {info.getValue()}
          </a>
        ),
        header: () => <Paragraph>JIRA Ticket</Paragraph>
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row: { original } }) => (
          <AppsIcon
            onClick={() => setFlagIdToEdit(original.id)}
            css={css`
              cursor: pointer;
              &:hover {
                opacity: 0.8;
              }
            `}
          />
        ),
        header: () => <Paragraph>Show/Edit</Paragraph>
      })
    ],
    []
  );

  return (
    <>
      {flagIdToEdit && <EditFlagModal flagId={flagIdToEdit} cancelHandler={cancelHandler} />}
      {featureFlags && <Table data={featureFlags} columns={TABLE_COLUMNS} testId="feature-flags-table" />}
    </>
  );
};

export default FeatureFlags;
