import { IUserWithRole } from '../../../interfaces/IUser';
import authInfo from '../../../services/authInfo';

export const isAuthorizedUserFirstInConference = (participants: IUserWithRole[]): boolean => {
  if (participants.length < 2) {
    return false;
  }

  return participants[0]?.id === authInfo.currentUserId;
};
