import * as React from 'react';

import { useDebouncedValue } from '../../hooks';
import { IVehicle } from '../../interfaces';
import {
  useAutoMakesByYear,
  useAutoModelsByMake,
  useAutoSubmodelsByModelAndMake,
  useAutoYears
} from './useAutoSearchEntities';

const useAutoSearch = (vehicle?: IVehicle) => {
  const [make, setMake] = React.useState<string>(vehicle?.make || '');
  const [model, setModel] = React.useState<string>(vehicle?.model || '');

  const makeTerm = useDebouncedValue(make, 300);
  const modelTerm = useDebouncedValue(model, 300);

  const { data: makes = [], isPending: isPendingMakes } = useAutoMakesByYear();
  const { data: years = [], isPending: isPendingYears } = useAutoYears();
  const { data: models = [], isPending: isPendingModels } = useAutoModelsByMake(makeTerm);
  const { data: submodels = [], isPending: isPendingSubmodels } = useAutoSubmodelsByModelAndMake({
    model: modelTerm,
    make: makeTerm
  });
  const getMakes = (makeKey: string | null) => {
    const possibleMakes = !makeKey || makes.includes(makeKey) ? makes : [...makes, makeKey];
    return possibleMakes.map(make => ({ key: make, value: make }));
  };

  const getModels = (modelKey: string | null) => {
    const possibleModels = !modelKey || models.includes(modelKey) ? models : [...models, modelKey];
    return possibleModels.map(model => ({ key: model, value: model }));
  };

  const getSubmodels = (submodelKey: string | null) => {
    const possibleSubmodels = !submodelKey || submodels.includes(submodelKey) ? submodels : [...submodels, submodelKey];
    return possibleSubmodels.map(submodels => ({ key: submodels, value: submodels }));
  };

  return {
    years: years.map(year => ({ key: year.toString(), value: year.toString() })),
    makes: makes.map(make => ({ key: make, value: make })),
    models: models.map(model => ({ key: model, value: model })),
    submodels: submodels.map(submodel => ({ key: submodel, value: submodel })),
    setMake,
    getMakes,
    setModel,
    getModels,
    getSubmodels,
    isPendingMakes,
    isPendingModels: !!(makeTerm && isPendingModels),
    isPendingYears,
    isPendingSubmodels: !!(modelTerm && isPendingSubmodels)
  };
};

export default useAutoSearch;
