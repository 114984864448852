import { css } from '@emotion/react';

import colors from '../../../../../theme/colors';

export const addressOption = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: left;
  width: 100%;
  padding: 10px;
  font-variant: lining-nums;
  &:hover {
    background-color: ${colors.grey10};
  }
`;

export const azureColor = css`
  color: ${colors.azure50};
`;

export const caret = css`
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  width: 12px;
`;
