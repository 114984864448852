/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { IHome } from '../../../interfaces/IHome';
import { usePersonHomePanoramaView } from '../../../queries/people/usePersonHomes';
import { googleStreetPanoramaView, googleStreetSatelliteView } from '../../../services/googleStreetPanoramaView';
import { spacings } from '../../../theme/variables';
import FlexBox from '../../core/FlexBox';
import StreetViewTypeSwitch, { StreetViewType } from './StreetViewTypeSwitch';

interface MapViewProps {
  personGid: string;
  home: IHome;
}

const MapView = ({ personGid, home }: MapViewProps) => {
  const { data: panoramaView } = usePersonHomePanoramaView({
    personGid,
    homeGid: home.gid
  });

  const [selectedStreetViewType, setSelectedStreetViewType] = React.useState<StreetViewType>(StreetViewType.Panorama);

  if (!panoramaView?.panorama_id) {
    return null;
  }

  return (
    <FlexBox
      fitParentHeight
      fitParentWidth
      justifyCenter
      customCss={css`
        position: relative;
      `}
    >
      <FlexBox
        css={css`
          position: absolute;
          top: ${spacings.px24}px;
        `}
        justifyCenter
      >
        <StreetViewTypeSwitch
          selectedStreetViewType={selectedStreetViewType}
          onStreetViewTypeSelected={setSelectedStreetViewType}
        />
      </FlexBox>
      {selectedStreetViewType === StreetViewType.Panorama && (
        <iframe
          css={css`
            border: none;
            width: 100%;
            height: 100%;
          `}
          src={googleStreetPanoramaView(panoramaView.panorama_id)}
          title="Test"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      )}
      {selectedStreetViewType === StreetViewType.Satellite && (
        <iframe
          css={css`
            border: none;
            width: 100%;
            height: 100%;
          `}
          src={googleStreetSatelliteView(home.address)}
          title="Test"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        />
      )}
    </FlexBox>
  );
};

export default MapView;
