/** @jsxImportSource @emotion/react */
import './Address.scss';

import { css, Global } from '@emotion/react';
import classNames from 'classnames';
import debounce from 'debounce-promise';
import { getIn, useFormikContext } from 'formik';
import React, { useCallback, useRef, useState } from 'react';
import { Input, Label } from 'reactstrap';

import arrowDownIcon from '../../../assets/new_icons/arrow-down.svg';
import { CITY, SMARTY_LINE1, SMARTY_ZIP, STATE } from '../../../constants/addressForm';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import { IAddressSuggestion, ISuggestionResponse } from '../../../interfaces';
import streetsService from '../../../services/streets';
import CopyField from '../CopyField/CopyField';
import ErrorCol from '../ErrorCol';
import AddressOption from './AddressOption';
import FallbackAddress from './FallbackAddress';

interface Props {
  defaultValue?: IAddressSuggestion;
  placeholder: string;
  defaultOptions?: IAddressSuggestion[];
  fallbackNames: Record<string, string>;
  label: string;
  showManualOption: boolean;
  warningMessage?: string;
  errorMessage?: string;
}

interface Options {
  data: IAddressSuggestion[];
}

const Address: React.FC<Props> = ({
  label,
  placeholder,
  defaultValue,
  fallbackNames,
  defaultOptions = [],
  warningMessage,
  errorMessage,
  showManualOption = true
}) => {
  const { setFieldValue, errors } = useFormikContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLDivElement>(null);

  const [isFocused, setFocused] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showManually, setShowManually] = useState(false);
  const [options, setOptions] = useState<Options>({ data: defaultOptions });

  const clickOutsideHandler = useCallback(() => setFocused(false), []);
  useOnClickOutside(addressRef, clickOutsideHandler);

  const optionCallback = (option: IAddressSuggestion) =>
    Object.keys(fallbackNames).forEach(name =>
      setTimeout(() => setFieldValue(fallbackNames[name]!, option[name as keyof IAddressSuggestion] || ''), 0)
    );

  const addressError = () =>
    [SMARTY_LINE1, CITY, STATE, SMARTY_ZIP].map(name => getIn(errors, fallbackNames[name]!)).find(Boolean) ||
    errorMessage;

  const getDefaultValueString = () => {
    if (!defaultValue) {
      return '';
    }

    return [
      defaultValue.street_line,
      defaultValue.secondary,
      defaultValue.city,
      defaultValue.state,
      defaultValue.zipcode
    ]
      .filter(Boolean)
      .join(' ')
      .trim();
  };

  const onInput = debounce((inputValue: string) => {
    if (inputValue) {
      setLoading(true);

      streetsService
        .suggest(inputValue)
        .then((response: ISuggestionResponse) => {
          setOptions({ data: response.data.suggestions || defaultOptions });
        })
        .catch(() => {
          setOptions({ data: defaultOptions });
        })
        .finally(() => setLoading(false));
    } else {
      optionCallback({ street_line: '', secondary: '', city: '', zipcode: '', state: '', entries: 0 });
      setOptions({ data: defaultOptions });
    }
  }, 500);

  const onSelect = (option: IAddressSuggestion, optionString: string) => {
    if (+option.entries > 1) {
      setLoading(true);
      streetsService
        .suggestSecondary(inputRef.current?.value as string, optionString)
        .then((response: ISuggestionResponse) => setOptions({ data: response.data.suggestions || defaultOptions }))
        .catch(() => setOptions({ data: defaultOptions }))
        .finally(() => setLoading(false));

      if (inputRef.current) {
        inputRef.current.value = option.street_line;
        inputRef.current.focus();
      }
    } else {
      if (inputRef.current) {
        inputRef.current.value = optionString;
      }

      optionCallback(option);
      setFocused(false);
    }
  };

  const onManuallySelected = () => {
    setShowManually(true);
    setFocused(false);
    setOptions({ data: defaultOptions });
  };

  const showWarning = !!warningMessage && inputRef.current?.value === '';
  const showError = !!addressError();
  const when = (showWarning || showError) && !showManually;

  const chooseMenuOrientation = () => {
    if (!inputRef.current) {
      return;
    }

    const viewHeight = document.body.getBoundingClientRect().height;
    const lengthToBottom = inputRef.current.getBoundingClientRect().top;

    return viewHeight - lengthToBottom > 400 ? { top: '52px' } : { bottom: '90px' };
  };

  return (
    <>
      <Global
        styles={() => css`
          .c-address__icon {
            background: url(${arrowDownIcon}) no-repeat;
            background-position-x: calc(100% - 10px);
            background-position-y: center;
            background-size: 20px;
          }
        `}
      />
      <ErrorCol sm="9" className="pl-0" error={addressError()} when={when} warning={warningMessage}>
        <Label>{label}</Label>
        <div
          className={classNames('c-address', { 'c-address--open': isFocused })}
          ref={addressRef}
          data-testid="address"
        >
          <CopyField value={inputRef.current?.value}>
            <Input
              name="address-input"
              autoComplete="off"
              className={classNames('c-address__input fs-mask', { 'c-address__icon': defaultOptions[0] })}
              onInput={e => {
                onInput((e.target as HTMLInputElement).value);
              }}
              onFocus={() => setFocused(true)}
              placeholder={placeholder}
              innerRef={inputRef}
              defaultValue={getDefaultValueString()}
              data-testid="address-input"
              disabled={showManually}
            />
          </CopyField>
          <div className="c-address__menu" data-testid="address-menu" style={chooseMenuOrientation()}>
            <div className="c-address__menu-list">
              {isLoading && <div className="c-address__loading">Loading...</div>}
              {options.data.map(option => (
                <AddressOption
                  className="c-address__option fs-mask"
                  key={JSON.stringify(option)}
                  inputValue={inputRef.current?.value}
                  option={option}
                  onSelect={onSelect}
                />
              ))}

              {showManualOption && !isLoading && (
                <AddressOption
                  key="Manual"
                  className="c-address__manual fs-mask"
                  onSelect={onManuallySelected}
                  option={{
                    street_line: 'Enter manually',
                    secondary: '',
                    city: '',
                    state: '',
                    zipcode: '',
                    entries: ''
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </ErrorCol>
      {showManualOption && showManually && (
        <FallbackAddress
          label={label}
          names={fallbackNames}
          hideFallback={() => setShowManually(false)}
          warningMessage={warningMessage}
        />
      )}
    </>
  );
};

export default Address;
