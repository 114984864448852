/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../components/core/Container';
import { IProductAnnouncementReaction } from '../../../interfaces';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';

interface ReactionButtonProps {
  emoji: string;
  reactions: IProductAnnouncementReaction[];
  disabled: boolean;
  onCreateReaction: () => void;
  onDeleteReaction: () => void;
}

const ReactionButton = ({ emoji, reactions, disabled, onCreateReaction, onDeleteReaction }: ReactionButtonProps) => {
  const count = reactions.length;
  const currentUserId = authInfo.currentUserId;
  const hasCurrentUserReacted = reactions.some(({ author }) => author.id === currentUserId);

  return (
    <Container
      roundBorder
      backgroundColor={hasCurrentUserReacted ? colors.cornflower : colors.grey5}
      p={spacings.px4}
      onClick={() => (hasCurrentUserReacted ? onDeleteReaction() : onCreateReaction())}
      disabled={disabled}
      customCss={css`
        cursor: pointer;
        color: ${hasCurrentUserReacted ? colors.white : colors.black};
        &:hover {
          outline: 1px solid ${colors.grey30};
        }
      `}
    >
      {emoji} {count}
    </Container>
  );
};

export default ReactionButton;
