/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import colors from '../../../../theme/colors';
import Container, { IContainer } from '../../Container';
import Text from '../../Text';

type FormDescriptionProps = IContainer & { description: string; singleLine?: boolean };

const FormDescription = ({ description, singleLine, ...rest }: FormDescriptionProps) => (
  <Container
    {...rest}
    customCss={css`
      ${singleLine && 'max-width: 0px;'}
    `}
  >
    <Text
      type="small"
      color={colors.grey60}
      customCss={css`
        ${singleLine && 'white-space: nowrap;'}
      `}
    >
      {description}
    </Text>
  </Container>
);

export default FormDescription;
