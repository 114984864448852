import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import transferImage from '../../assets/img/transfer.svg';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { SelectField } from '../../components/core/forms/fields';
import Heading from '../../components/core/Heading';
import Paragraph from '../../components/core/Paragraph';
import { Translations } from '../../constants';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IOpportunity, OpportunityStatus } from '../../interfaces/IOpportunity';
import { isNonLifeAncillaryPolicyType } from '../../interfaces/IPolicyType';
import useLeadOpportunities, { updateOpportunity } from '../../queries/leads/opportunities/useLeadOpportunities';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { LabelSize, sizes, spacings } from '../../theme/variables';

const LostOpportunitiesFinishPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { lead } = useGuidedSellingExperienceContext();
  const { data: opportunities, isFetching: isFetchingOpportunities } = useLeadOpportunities({ leadId: lead!.id });
  const filteredOpportunities = (opportunities || []).filter(opportunity => !isNonLifeAncillaryPolicyType(opportunity));

  return (
    <>
      {isFetchingOpportunities ? (
        <>
          <Skeleton height={300} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
          <Skeleton count={6} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
        </>
      ) : (
        <FlexBox columnDirection gap={spacings.px24}>
          <img src={transferImage} alt="Transfer" width={660} />
          <FlexBox ml={spacings.px24} gap={spacings.px12} columnDirection>
            <>
              <Heading>You are all set</Heading>
              <Paragraph>
                {`Great job, ${authInfo.currentUserFirstName}!
          You’ve collected all the information.`}
              </Paragraph>
            </>

            <BaseForm
              pb={spacings.px0}
              pt={spacings.px0}
              pl={spacings.px0}
              pr={spacings.px0}
              controlsAlignment="left"
              controlsWidth={sizes.mediumFormInputWidth}
              initialValues={{ opportunities: filteredOpportunities }}
              validationSchema={yup.object().shape({
                opportunities: yup.array().of(
                  yup.object().shape({
                    reason: yup.string().nullable().required('Please, fill in the field')
                  })
                )
              })}
              submitText="Got it! Go to sales dashboard"
              submitTestId="finalize-lead-button"
              onSubmit={async values => {
                return await Promise.all(
                  values.opportunities.map(opportunity =>
                    updateOpportunity({
                      leadId: lead!.id,
                      opportunityId: opportunity.id,
                      params: { status: OpportunityStatus.Lost, reason: opportunity.reason }
                    })
                  )
                ).then(() => navigate('/leads'));
              }}
              renderForm={({ values }) => {
                return (
                  <>
                    {(values.opportunities || []).map((opportunity: IOpportunity, index: number) => {
                      const reasonFieldName = `opportunities.${index}.reason`;
                      return (
                        <SelectField
                          key={opportunity.id}
                          label={`${opportunity.title} opportunity lost due`}
                          id={reasonFieldName}
                          required
                          name={reasonFieldName}
                          options={Translations.opportunityLostReasonOptions}
                          testId={`${opportunity.policy_type}-select-opportunity-lost-reason`}
                          inline
                          labelSize={LabelSize.Large}
                        />
                      );
                    })}
                  </>
                );
              }}
            />
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default LostOpportunitiesFinishPage;
