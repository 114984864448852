import { css, SerializedStyles } from '@emotion/react';

import colors from '../../../../../theme/colors';
import { borderRadius, InputSize } from '../../../../../theme/variables';
import { fontCss } from '../../../inputs/Base';

export const addressMenu = (isFocused: boolean, inputSize: InputSize): SerializedStyles => css`
  background-color: ${colors.white};
  border: 1px solid ${colors.grey30};
  border-radius: ${borderRadius + 2}px;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
  display: none;
  position: absolute;
  top: 44px;
  width: 100%;
  z-index: 3;
  padding: 4px 0;
  ${isFocused &&
  css`
    display: block;
  `};
  ${fontCss(inputSize)};
`;

export const menuList = css`
  max-height: 400px;
  overflow: auto;
`;

export const address = css`
  position: relative;
`;

export const loading = css`
  color: ${colors.grey60};
  padding: 8px 20px;
  text-align: center;
`;
