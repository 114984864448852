export interface Coverage {
  acord_code: string;
  acord_description?: string;
  limit_description: string;
  deductible_description?: string;
  full_term_premium?: string;
}

export enum CoverageType {
  Excluded = 'excluded',
  SpecialLiabilityLimit = 'special_liability_limit',
  Discount = 'discount',
  OtherLimit = 'other_limit'
}

export type HomeCoverage = Coverage;
export interface HomeEndorsement extends Coverage {
  type: CoverageType;
}

export interface AutoCoverage extends Coverage {
  type: CoverageType;
  acord_description: never;
  full_term_premium: never;
}
