import { useMutation } from '@tanstack/react-query';

import { IOpportunity } from '../../../interfaces/IOpportunity';
import api from '../../../services/api';

interface ReconcileOpportunitiesParams {
  leadId: number;
}

const reconcileOpportunitiesWithPolicies = (
  params: ReconcileOpportunitiesParams
): Promise<{ opportunities: IOpportunity[] }> =>
  api.put(`/api/frontend/leads/${params.leadId}/opportunities/reconcile_with_policies`, {});

const useReconcileOpportunitiesWithPolicies = (
  onSuccess?: (data: { opportunities: IOpportunity[] }, variables: ReconcileOpportunitiesParams) => void
) => useMutation({ mutationFn: reconcileOpportunitiesWithPolicies, onSuccess });

export default useReconcileOpportunitiesWithPolicies;
