import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IPerson } from '../../interfaces';
import api from '../../services/api';

export const CUSTOMER_QUERY_KEY = 'customer';

const useCustomer = (
  customerId: string | number | undefined,
  options?: UseQueryOptions<{ customer: { origin_gid: IPerson['gid'] } }, any, any, any>
) =>
  useQuery({
    queryKey: [CUSTOMER_QUERY_KEY, `${customerId}`],
    queryFn: customerId
      ? (): Promise<{ customer: { origin_gid: IPerson['gid'] } }> => api.get(`/api/frontend/customers/${customerId}`)
      : skipToken,
    ...options,
    select: data => data.customer
  });

export default useCustomer;
