import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

import AppSwitch from './AppSwitch';
import { CallContextProvider } from './contexts/CallContext';
import ApiActivityMonitor from './utils/ApiActivityMonitor';
import queryClientConfig from './utils/queryClientConfig';

(() => {
  window.__amp_config = {};
  window.__amp_feature_flags = {};
  if (
    // clear storage if it exceeds 2mb
    Object.keys(window.localStorage)
      .map(k => ((window.localStorage.getItem(k) ?? '').length * 2) / 1024 / 1024)
      .reduce((a, b) => a + b, 0) > 2
  ) {
    window.localStorage.clear();
  }
})();

const container = document.getElementById('root') as Element;
const root = createRoot(container);

const queryClient = new QueryClient(queryClientConfig);

root.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <CallContextProvider>
        <AppSwitch />
        <ToastContainer transition={Slide} limit={1} />
      </CallContextProvider>
    </BrowserRouter>
    <ApiActivityMonitor />
    {import.meta.env.DEV && <ReactQueryDevtools />}
  </QueryClientProvider>
);
