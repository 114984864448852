import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IVehicle } from '../../interfaces/IVehicle';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const usePolicyVehicles = (
  policyId: string | number,
  lineOfBusinessId?: number,
  transactionId?: number,
  options?: UseQueryOptions<{ ivans_vehicles: IVehicle[] }, any, any, any>
) =>
  useQuery({
    queryKey: ['policy_vehicles', policyId, lineOfBusinessId, transactionId],
    queryFn:
      !!lineOfBusinessId && !!transactionId
        ? (): Promise<{ ivans_vehicles: IVehicle[] }> =>
            api.get(
              `/api/frontend/policies/${policyId}/auto/${lineOfBusinessId}/ivans_vehicles?${toQueryParams({
                transaction_id: transactionId
              })}`
            )
        : skipToken,
    ...options,
    select: data => data.ivans_vehicles
  });

export default usePolicyVehicles;
