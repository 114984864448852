import './Spinner.scss';

import classNames from 'classnames';
import React from 'react';

const Spinner = ({
  color = '#1e74d6',
  size = 50,
  inline = false,
  className,
  testId
}: {
  color?: string;
  size?: number;
  inline?: boolean;
  className?: string;
  testId?: string;
}) => (
  <div
    className={classNames('spinner', className, { 'spinner--inline': inline })}
    style={{ width: size, height: size }}
    data-testid={testId}
  >
    <svg className="spinner__svg" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet">
      <circle className="spinner__circle" cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke={color} />
    </svg>
  </div>
);

export default Spinner;
