import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import CircledPolicyTypeIcon from '../../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../../components/core/Container';
import Copy from '../../../../../components/core/Copy';
import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import { ILoan, IOpportunity } from '../../../../../interfaces';
import { AssetType } from '../../../../../interfaces/IPersonAsset';
import {
  findPolicyTypeLabel,
  InsurableInterest,
  isInsurableInterestRealProperty,
  isRequirementEssential,
  PolicyType
} from '../../../../../interfaces/IPolicyType';
import { buildVehicleTitle } from '../../../../../interfaces/IVehicle';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import MyCoverageInfo from './MyCoverageInfo';

interface OpportunityWithoutPolicyProps {
  opportunity: IOpportunity;
  loan: ILoan | undefined;
  onAddPolicy: () => void;
  isDataEditingForbidden: boolean;
}

const OpportunityWithoutPolicy = ({
  opportunity,
  loan,
  onAddPolicy,
  isDataEditingForbidden
}: OpportunityWithoutPolicyProps) => {
  const areAssetPresent = !!opportunity.assets?.length;
  const isAutoOpportunity = opportunity.insurable_interest === InsurableInterest.Vehicle;
  const isLifeOpportunity = opportunity.policy_type === PolicyType.TermLife;
  const isCoverageLookupAvailable =
    isInsurableInterestRealProperty(opportunity.policy_type) && isRequirementEssential(opportunity.policy_type);

  return (
    <Container p={spacings.px8} border roundBorder>
      <FlexBox gap={spacings.px12}>
        <CircledPolicyTypeIcon policyType={opportunity.policy_type} color={colors.grey30} />
        {areAssetPresent ? (
          <FlexBox columnDirection gap={spacings.px4}>
            <Paragraph type="small">
              Gather information about what your customer is currently paying for their{' '}
              {findPolicyTypeLabel(opportunity.policy_type)} insurance.
            </Paragraph>
            {!isLifeOpportunity && (
              <Paragraph bold type="small">
                {isInsurableInterestRealProperty(opportunity.policy_type) ? 'This home is' : 'These vehicles are'}{' '}
                already added to the profile
              </Paragraph>
            )}
            {!isLifeOpportunity && (
              <FlexBox gap={spacings.px8} columnDirection>
                {opportunity.assets?.map(asset => (
                  <FlexBox alignItemsCenter key={asset.gid}>
                    {asset.asset_name === AssetType.Auto ? (
                      <Text type="small">{buildVehicleTitle(asset)}</Text>
                    ) : (
                      <Text className="fs-mask" type="small">
                        {asset.description_without_icon}
                      </Text>
                    )}
                  </FlexBox>
                ))}
              </FlexBox>
            )}
            <Container>
              <ButtonWithoutBorder
                disabled={isDataEditingForbidden}
                textType="small"
                mt={spacings.px12}
                ml={spacings['-px4']}
                onClick={() => onAddPolicy()}
              >
                {areAssetPresent ? 'Add current policy' : `Add current ${isAutoOpportunity ? 'vehicles' : 'home'}`}
              </ButtonWithoutBorder>
            </Container>
          </FlexBox>
        ) : (
          <FlexBox columnDirection gap={spacings.px4}>
            <Paragraph type="small">
              No information is available at the moment. Please collect it as soon as possible, so that we can perform
              premium analysis.
            </Paragraph>
            {loan?.loan_number && (
              <FlexBox>
                <Paragraph type="small">Loan number: </Paragraph>
                <Copy type="small" value={loan.loan_number}>
                  <Paragraph type="small" className="fs-mask">
                    {loan.loan_number}
                  </Paragraph>
                </Copy>
              </FlexBox>
            )}
            {isCoverageLookupAvailable && <MyCoverageInfo />}
            {!isLifeOpportunity && (
              <Container>
                <ButtonWithoutBorder
                  disabled={isDataEditingForbidden}
                  textType="small"
                  mt={spacings.px12}
                  ml={spacings['-px4']}
                  onClick={() => onAddPolicy()}
                >
                  {areAssetPresent ? 'Add current policy' : `Add current ${isAutoOpportunity ? 'vehicles' : 'home'}`}
                </ButtonWithoutBorder>
              </Container>
            )}
          </FlexBox>
        )}
      </FlexBox>
    </Container>
  );
};

export default OpportunityWithoutPolicy;
