import { skipToken, useQuery } from '@tanstack/react-query';

import { ITimezone } from '../../interfaces/ITimezone';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

interface PersonTimezoneParams {
  personGid: string | undefined;
  engagementGid?: string;
  phone: string | undefined;
}

const usePersonTimezone = ({ personGid, engagementGid, phone }: PersonTimezoneParams) =>
  useQuery({
    queryKey: ['person_timezone', personGid, engagementGid, phone],
    queryFn:
      !!personGid && !!phone
        ? (): Promise<{ timezone: ITimezone | null }> =>
            api.get(
              `/api/frontend/people/${personGid}/timezone?${toQueryParams({ engagement_gid: engagementGid, phone })}`
            )
        : skipToken,
    select: data => data.timezone,
    staleTime: Infinity
  });

export default usePersonTimezone;
