/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import FlexBox from '../../../../components/core/FlexBox';
import { CogIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { INotification } from '../../../../interfaces';
import colors, { opacities } from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { relativeTimeFormatter } from '../../../../utils/formatter';

interface NotificationProps {
  notification: INotification;
}

const Notification = ({ notification: { author, message, read, created_at } }: NotificationProps) => {
  return (
    <FlexBox
      ph={spacings.px12}
      pv={spacings.px8}
      gap={spacings.px16}
      backgroundColor={read ? undefined : `${colors.violet}${opacities.opacity_10}`}
    >
      <FlexBox
        justifyCenter
        alignItemsCenter
        customCss={css`
          width: 40px;
          height: 40px;
          border-radius: 20px;
          background-color: ${colors.cherise50};
        `}
      >
        {author?.avatar_url ? (
          <img
            src={author.avatar_url}
            alt="avatar"
            css={css`
              border-radius: 20px;
            `}
          />
        ) : (
          <CogIcon color={colors.white} />
        )}
      </FlexBox>
      <FlexBox columnDirection gap={spacings.px4}>
        <Text>{message}</Text>
        <Text type="small" color={colors.grey80}>
          {relativeTimeFormatter(created_at)}
        </Text>
      </FlexBox>
    </FlexBox>
  );
};

export default Notification;
