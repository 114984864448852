import { create } from 'zustand';

interface ISelectedQuotesRequest {
  quotesRequestGid: string | undefined;
  setQuotesRequestGid: (quotesRequestGid: string) => void;
}

const useSelectedQuotesRequest = create<ISelectedQuotesRequest>()(set => ({
  quotesRequestGid: undefined,
  setQuotesRequestGid: quotesRequestGid => set({ quotesRequestGid })
}));

export default useSelectedQuotesRequest;
