import { PatternFormat, PatternFormatProps } from 'react-number-format';

import Input, { InputProps } from '../Base';

export type PhoneInputProps = Omit<PatternFormatProps<InputProps>, 'format'> & { format?: string };

const PhoneInput = ({ format, ...rest }: PhoneInputProps): JSX.Element => (
  <PatternFormat
    type="tel"
    customInput={Input}
    placeholder={rest.placeholder || rest.inline ? '—' : 'Enter phone number'}
    className="fs-mask"
    format={format || '(###) ###-####'}
    {...rest}
  />
);

export default PhoneInput;
