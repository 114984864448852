/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';

const StepOrder = ({ order, title, activeStepOrder }: { order: number; title: string; activeStepOrder: number }) => {
  let textColor;

  if (activeStepOrder > order) {
    textColor = colors.statusGreen;
  } else if (activeStepOrder === order) {
    textColor = colors.azure50;
  } else {
    textColor = colors.grey60;
  }

  return (
    <>
      <Text
        type="small"
        color={textColor}
        mr={spacings.px4}
        customCss={css`
          border-radius: 50%;
          width: 22px;
          height: 22px;
          border: 1px solid ${textColor};
          text-align: center;
        `}
      >
        {order}
      </Text>
      <Text bold color={textColor} type="small" mr={spacings.px4}>
        {title}
      </Text>
    </>
  );
};

const StepsBar = ({ activeStepOrder }: { activeStepOrder: number }) => (
  <FlexBox alignItemsCenter>
    <StepOrder order={1} title="Select Assets - " activeStepOrder={activeStepOrder} />
    <StepOrder order={2} title="Select Drivers - " activeStepOrder={activeStepOrder} />
    <StepOrder order={3} title="Select Coverages" activeStepOrder={activeStepOrder} />
  </FlexBox>
);

export default StepsBar;
