import { SerializedStyles } from '@emotion/react';
import { useFormikContext } from 'formik';
import * as React from 'react';

import { InputField, NumericField, SelectField } from '../../../../../components/core/forms/fields';
import { Translations } from '../../../../../constants';
import { IVehicle } from '../../../../../interfaces';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import { VehicleUsageType } from '../../../../../interfaces/IPersonAsset';
import { InputSize, LabelSize } from '../../../../../theme/variables';
import { VehicleFields } from '../../../../GetQuotes/AssetsPicker/formHelpers';
// eslint-disable-next-line max-len
import { useVehicleProfileAnswersWithVerificationAnswers } from '../../_hooks/useVehicleProfileAnswersWithVerificationAnswers';
import EnhanceWithVerificationStatus from '../EnhanceWithVerificationStatus';
import { calculateCommutingMileage, calculateDaysPerWeek, calculateMileage, hasWorkOrSchoolValue } from './helpers';

const EMPTY_USAGE = '' as VehicleUsageType;
const PREFILLED_ANNUAL_MILEAGE_INFO_TIP = 'This is an average value we recommend for the usage.';
const CALCULATED_ANNUAL_MILEAGE_INFO_TIP = 'We calculate this value based on daily driven distance.';

interface IVehicleValues {
  usage: VehicleUsageType | null;
  days_per_week_driven: number | string | null;
  one_way_mileage: number | string | null;
  annual_mileage: number | string | null;
}

export const annualMileageCalculatorInitialValues = (vehicle: IVehicle) => ({
  [VehicleFields.Usage]: vehicle.usage || EMPTY_USAGE,
  [VehicleFields.DaysPerWeekDriven]: vehicle.days_per_week_driven || '',
  [VehicleFields.AnnualMileage]: vehicle.annual_mileage || '',
  [VehicleFields.OneWayMileage]: vehicle.one_way_mileage || ''
});

const EmptyFragment = ({ children }: { children: React.ReactNode }) => <>{children}</>;

const AnnualMileageCalculator = ({
  vehicleValues,
  valuesPath = '',
  labelSize = LabelSize.Medium,
  inputSize = InputSize.Medium,
  disabled = false,
  customLabelCss,
  vehicleGid,
  answersWithVerificationStatus,
  verificationAlignment
}: {
  vehicleValues: IVehicleValues;
  valuesPath?: string;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  disabled?: boolean;
  customLabelCss?: SerializedStyles;
  vehicleGid: string;
  verificationAlignment: 'left' | 'right';
  answersWithVerificationStatus: ReturnType<typeof useVehicleProfileAnswersWithVerificationAnswers>;
}) => {
  const { setFieldValue } = useFormikContext();

  const Wrapper = verificationAlignment === 'left' ? EnhanceWithVerificationStatus : EmptyFragment;

  return (
    <>
      <Wrapper
        entityGid={vehicleGid}
        answerKey={DatapointKey.VehicleUsage}
        answersWithVerificationStatus={answersWithVerificationStatus}
        disabled={disabled}
      >
        <SelectField
          id={`${valuesPath}${VehicleFields.Usage}`}
          name={`${valuesPath}${VehicleFields.Usage}`}
          label="Use"
          labelSize={labelSize}
          inputSize={inputSize}
          inline
          options={Translations.vehicleUsageOptions}
          required
          disabled={disabled}
          customLabelCss={customLabelCss}
          onChange={({ target }) => {
            setFieldValue(
              `${valuesPath}${VehicleFields.AnnualMileage}`,
              calculateMileage({
                usage: target.value,
                daysPerWeekDriven: vehicleValues.days_per_week_driven?.toString() || '',
                oneWayMileageNumber: vehicleValues.one_way_mileage?.toString() || ''
              }) || ''
            );

            setFieldValue(`${valuesPath}${VehicleFields.Usage}`, target.value);
          }}
          verificationStatus={
            verificationAlignment === 'right'
              ? answersWithVerificationStatus[vehicleGid]?.[DatapointKey.VehicleUsage]?.verificationStatus
              : undefined
          }
        />
      </Wrapper>
      {hasWorkOrSchoolValue(vehicleValues.usage || EMPTY_USAGE) && (
        <>
          <Wrapper
            entityGid={vehicleGid}
            answerKey={DatapointKey.VehicleDaysDrivenWeekly}
            answersWithVerificationStatus={answersWithVerificationStatus}
            disabled={disabled}
          >
            <InputField
              id={`${valuesPath}${VehicleFields.DaysPerWeekDriven}`}
              name={`${valuesPath}${VehicleFields.DaysPerWeekDriven}`}
              label="Days driven weekly"
              labelSize={labelSize}
              inputSize={inputSize}
              customLabelCss={customLabelCss}
              type="number"
              inline
              secondary
              required
              disabled={disabled}
              onChange={({ target }) => {
                if (target.value) {
                  setFieldValue(
                    `${valuesPath}${VehicleFields.AnnualMileage}`,
                    calculateCommutingMileage({
                      daysPerWeekDriven: target.value,
                      oneWayMileage: vehicleValues.one_way_mileage?.toString() || ''
                    })?.toString() || ''
                  );
                }

                setFieldValue(`${valuesPath}${VehicleFields.DaysPerWeekDriven}`, target.value);
              }}
              verificationStatus={(() => {
                if (verificationAlignment === 'right') {
                  return answersWithVerificationStatus[vehicleGid]?.[DatapointKey.VehicleDaysDrivenWeekly]
                    ?.verificationStatus;
                }
              })()}
            />
          </Wrapper>
          <Wrapper
            entityGid={vehicleGid}
            answerKey={DatapointKey.VehicleDailyMileage}
            answersWithVerificationStatus={answersWithVerificationStatus}
            disabled={disabled}
          >
            <InputField
              id={`${valuesPath}${VehicleFields.OneWayMileage}`}
              name={`${valuesPath}${VehicleFields.OneWayMileage}`}
              label="One-way mileage"
              labelSize={labelSize}
              inputSize={inputSize}
              type="number"
              customLabelCss={customLabelCss}
              inline
              secondary
              required
              disabled={disabled}
              onChange={({ target }) => {
                if (target.value) {
                  setFieldValue(
                    `${valuesPath}${VehicleFields.AnnualMileage}`,
                    calculateCommutingMileage({
                      daysPerWeekDriven: vehicleValues.days_per_week_driven?.toString() || '',
                      oneWayMileage: target.value
                    })?.toString() || ''
                  );
                }

                setFieldValue(`${valuesPath}${VehicleFields.OneWayMileage}`, target.value);
              }}
              verificationStatus={
                verificationAlignment === 'right'
                  ? answersWithVerificationStatus[vehicleGid]?.[DatapointKey.VehicleDailyMileage]?.verificationStatus
                  : undefined
              }
            />
          </Wrapper>
        </>
      )}
      {vehicleValues.usage && (
        <Wrapper
          entityGid={vehicleGid}
          answerKey={DatapointKey.VehicleAnnualMileage}
          answersWithVerificationStatus={answersWithVerificationStatus}
          disabled={disabled}
        >
          <NumericField
            id={`${valuesPath}${VehicleFields.AnnualMileage}`}
            name={`${valuesPath}${VehicleFields.AnnualMileage}`}
            label="Annual mileage"
            labelSize={labelSize}
            inputSize={inputSize}
            inline
            customLabelCss={customLabelCss}
            secondary
            required
            disabled={disabled}
            info={
              hasWorkOrSchoolValue(vehicleValues.usage)
                ? CALCULATED_ANNUAL_MILEAGE_INFO_TIP
                : PREFILLED_ANNUAL_MILEAGE_INFO_TIP
            }
            valueIsNumericString
            onValueChange={({ value }, { event }) => {
              // event presence controls if the value is being changed by the user or by the system
              if (value && event) {
                setFieldValue(
                  `${valuesPath}${VehicleFields.DaysPerWeekDriven}`,
                  calculateDaysPerWeek({
                    usage: vehicleValues.usage || EMPTY_USAGE,
                    annualMileage: value,
                    oneWayMileageNumber: vehicleValues.one_way_mileage?.toString() || ''
                  })?.toString() || ''
                );
              }

              setFieldValue(`${valuesPath}${VehicleFields.AnnualMileage}`, value);
            }}
            verificationStatus={
              verificationAlignment === 'right'
                ? answersWithVerificationStatus[vehicleGid]?.[DatapointKey.VehicleAnnualMileage]?.verificationStatus
                : undefined
            }
          />
        </Wrapper>
      )}
    </>
  );
};

export default AnnualMileageCalculator;
