class LocalStorageManager {
  read(key: string, defaultValue: any = null) {
    const parsedValue = localStorage.getItem(key);
    if (parsedValue === null) {
      return defaultValue;
    }

    return JSON.parse(parsedValue);
  }

  write(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

export default new LocalStorageManager();
