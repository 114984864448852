import { IAddress } from './IAddress';
import { ILoan } from './ILoan';

export interface ILender {
  gid: string;
  name: string;
  business_name: string;
  verified: boolean;
  mortgagee_address: IAddress | null;
  fax_number: string;
}

export enum CompanyType {
  Lender = 'lender',
  Servicer = 'servicer'
}

export const servicerOrLender = ({ lender, servicer }: { servicer?: ILoan['servicer']; lender?: ILoan['lender'] }) => {
  const servicerOrLender =
    (servicer && { ...servicer, type: CompanyType.Servicer }) || (lender && { ...lender, type: CompanyType.Lender });

  return servicerOrLender;
};
