import { FormikValues } from 'formik';
import moment from 'moment';
import * as yup from 'yup';

import { IQuestion, QuestionType, Validation, ValidationType } from '../../../interfaces/IWorkflow';
import { DISPLAY_DATE_FORMAT, INTERNAL_DATE_FORMAT, INTERNAL_DATE_FORMAT_REGEXP } from '../../../utils/formatter';
import { addressSchema, minDate, REQUIRED_MESSAGE, requiredAddressSchema } from '../../../utils/yupRules';
import { isQuestionVisible } from './workflowConditions';

const valueIsPresent = (value: any) => value !== undefined && value !== null && value !== '';

export const validateFormValues = (questions: IQuestion[], formValues: FormikValues) => {
  // Makes all visible questions required
  const visibleQuestions = questions.filter(question => isQuestionVisible(question, formValues));
  return visibleQuestions?.reduce((acc, { key, type }) => {
    if (type === QuestionType.MultiSelect) {
      return formValues[key]?.length > 0 ? acc : { ...acc, ...{ [key]: REQUIRED_MESSAGE } };
    }
    if (valueIsPresent(formValues[key]) || type === QuestionType.Checkbox) {
      return acc;
    }
    return { ...acc, ...{ [key]: REQUIRED_MESSAGE } };
  }, {});
};

const buildAddressValidations = ({ validations }: IQuestion) =>
  validations?.some(({ key }) => key === ValidationType.Required) ? requiredAddressSchema : addressSchema;

const dateValidation = (schema: yup.DateSchema, validation: Validation) => {
  switch (validation.key) {
    case ValidationType.Required:
      return schema.required(validation.message || REQUIRED_MESSAGE);
    case ValidationType.MinDaysFromNow:
      return schema.min(
        moment()
          .add(validation.value as number, 'days')
          .format(INTERNAL_DATE_FORMAT),
        validation.message
      );
    case ValidationType.MaxDaysFromNow:
      return schema.max(
        moment()
          .add(validation.value as number, 'days')
          .format(INTERNAL_DATE_FORMAT),
        validation.message
      );
    case ValidationType.MinDaysBeforeNow:
      return schema.min(
        moment()
          .subtract(validation.value as number, 'days')
          .format(INTERNAL_DATE_FORMAT),
        validation.message
      );
    case ValidationType.MaxDaysBeforeNow:
      return schema.max(
        moment()
          .subtract(validation.value as number, 'days')
          .format(INTERNAL_DATE_FORMAT),
        validation.message
      );
    case ValidationType.MinYears:
      return schema.min(new Date(`${validation.value}-01-01`), validation.message);
    case ValidationType.MaxYears:
      return schema.max(new Date(`${validation.value}-01-01`), validation.message);

    default:
      return schema;
  }
};

const numberValidation = (schema: yup.NumberSchema, validation: Validation) => {
  switch (validation.key) {
    case ValidationType.Required:
      return schema.required(validation.message || REQUIRED_MESSAGE);
    case ValidationType.Min:
      return schema.min(validation.value as number, validation.message);
    case ValidationType.Max:
      return schema.max(validation.value as number, validation.message);
    default:
      return schema;
  }
};

const textValidation = (schema: yup.StringSchema, validation: Validation) => {
  switch (validation.key) {
    case ValidationType.Required:
      return schema.required(validation.message || REQUIRED_MESSAGE);
    case ValidationType.MinLength:
      return schema.min(validation.value as number);
    case ValidationType.MaxLength:
      return schema.max(validation.value as number);
    case ValidationType.Pattern:
      return schema.matches(/${validation.value}/);
    default:
      return schema;
  }
};

const buildDateValidations = ({ validations }: IQuestion) => {
  const baseValidation = yup
    .date()
    .test({
      test: (providedAnswer, { originalValue }) => {
        if (!originalValue) {
          return true;
        }

        return !!(originalValue as string).match(INTERNAL_DATE_FORMAT_REGEXP);
      },
      message: `Date has to be provided in ${DISPLAY_DATE_FORMAT} format`
    })
    .min(minDate, 'Cannot be earlier than 01/01/1800');

  return validations?.reduce((acc, validation) => dateValidation(acc, validation), baseValidation);
};

const buildSelectValidations = ({ validations }: IQuestion) => {
  const requiredValidation = validations?.find(({ key }) => key === ValidationType.Required);

  return requiredValidation ? yup.string().required(requiredValidation.message || REQUIRED_MESSAGE) : undefined;
};

const buildMultiSelectValidations = ({ validations }: IQuestion) => {
  const requiredValidation = validations?.find(({ key }) => key === ValidationType.Required);

  return requiredValidation ? yup.array().ensure().min(1, REQUIRED_MESSAGE) : undefined;
};

const buildNumberValidations = ({ validations }: IQuestion) =>
  validations?.reduce((acc, validation) => numberValidation(acc, validation), yup.number());

const buildTextValidations = ({ validations }: IQuestion) =>
  validations?.reduce((acc, validation) => textValidation(acc, validation), yup.string());

export const validationForQuestion = (question: IQuestion) => {
  switch (question.type) {
    case QuestionType.Checkbox:
      return;
    case QuestionType.Address:
      return buildAddressValidations(question);
    case QuestionType.Date:
      return buildDateValidations(question);
    case QuestionType.MultiSelect:
      return buildMultiSelectValidations(question);
    case QuestionType.Select:
    case QuestionType.Radio:
      return buildSelectValidations(question);
    case QuestionType.Number:
      return buildNumberValidations(question);
    case QuestionType.Text:
    case QuestionType.Phone:
    case QuestionType.Email:
      return buildTextValidations(question);
    case QuestionType.Slider:
    default:
  }
};
