/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import colors from '../../theme/colors';
import { sizes } from '../../theme/variables';
import Container, { IContainer } from './Container';
import FlexBox from './FlexBox';
import Heading from './Heading';

const FormLoader = (props: IContainer) => (
  <Container {...props}>
    <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
      {[1, 2, 3].map(value => (
        <div
          key={value}
          css={css`
            margin-bottom: 32px;
          `}
        >
          <Heading
            type="h4"
            customCss={css`
              width: 120px;
              margin-bottom: 16px;
            `}
          >
            <Skeleton />
          </Heading>
          {[1, 2, 3].map(value => (
            <FlexBox key={value}>
              <Heading
                customCss={css`
                  width: ${sizes.mediumFormInputWidth}px;
                `}
              >
                <Skeleton />
              </Heading>
              <Heading
                customCss={css`
                  width: ${sizes.mediumFormInputWidth}px;
                  margin-left: 24px;
                `}
              >
                <Skeleton />
              </Heading>
            </FlexBox>
          ))}
        </div>
      ))}
    </SkeletonTheme>
  </Container>
);

export default FormLoader;
