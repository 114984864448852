/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';

import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import Select from '../../../common/Select/Select';
import { ClearIcon } from '../../icons';
import { input } from '../Base/Input.style';

export interface SearchInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  clearable?: boolean;
  onClear?: () => void;
  customCss?: SerializedStyles;
  searchByOptions?: { key: string; value: string }[];
  searchBy?: string | null;
  setSearchBy?: any;
  inputRef?: React.Ref<HTMLInputElement>;
  small?: boolean;
}

const SearchInput = ({
  clearable = false,
  onClear,
  customCss,
  searchByOptions,
  searchBy,
  setSearchBy,
  inputRef,
  small,
  ...props
}: SearchInputProps): JSX.Element => (
  <div
    css={css`
      position: relative;
      display: flex;
    `}
  >
    {searchByOptions && (
      <Select
        transparent
        small={small}
        name="search_by"
        data-testid="search-by"
        options={searchByOptions}
        value={searchBy}
        onChange={selected => {
          analytics.track('Search by was changed', { search_by: selected.target.value });
          setSearchBy(selected.target.value);
        }}
        css={css`
          background-color: ${colors.grey5};
          border: 1px solid ${colors.grey30};
          border-right: 0;
          border-radius: 4px 0px 0px 4px;
          width: 120px;
          margin-bottom: 0 !important;
        `}
        isSearchable={false}
      />
    )}

    <input
      type="text"
      name="search-input"
      data-testid="search-input"
      {...props}
      ref={inputRef}
      css={[
        input(false),
        css`
          border: 1px solid ${colors.grey30};
          border-radius: ${searchByOptions ? '0px' : '4px'} 4px 4px ${searchByOptions ? '0px' : '4px'};
          height: ${small ? '34px' : '50px'};
          &:focus {
            border: 1px solid ${colors.azure50};
            box-shadow: 0px 0px 0px 2px rgba(64, 141, 247, 0.4);
          }
        `,
        customCss
      ]}
    />

    {clearable && props.value && (
      <ClearIcon
        data-testid="clear-input-icon"
        onClick={onClear}
        color={colors.black}
        width={18}
        height={18}
        css={css`
          position: absolute;
          right: 8px;
          top: ${small ? '8px' : '16px'};
          z-index: 2;
          cursor: pointer;
        `}
      />
    )}
  </div>
);

export default SearchInput;
