import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { spacings } from '../../../theme/variables';

const SummaryPlaceholder = () => (
  <div data-testid="lead-summary-loader">
    <FlexBox justifySpaceBetween>
      <Heading
        type="h3"
        customCss={css`
          width: 120px;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h3"
        customCss={css`
          width: 80px;
        `}
      >
        <Skeleton />
      </Heading>
    </FlexBox>
    <FlexBox mt={spacings.px32} justifySpaceBetween>
      <Heading
        type="h2"
        customCss={css`
          width: 35%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h2"
        customCss={css`
          width: 25%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h2"
        customCss={css`
          width: 25%;
        `}
      >
        <Skeleton />
      </Heading>
    </FlexBox>
    <FlexBox mt={spacings.px32} justifySpaceBetween>
      <Heading
        type="h2"
        customCss={css`
          width: 35%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h2"
        customCss={css`
          width: 25%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h2"
        customCss={css`
          width: 25%;
        `}
      >
        <Skeleton />
      </Heading>
    </FlexBox>
  </div>
);

export default SummaryPlaceholder;
