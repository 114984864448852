import { skipToken, useQuery } from '@tanstack/react-query';

import api from '../../services/api';

export interface PolicyTransaction {
  id: number;
  policy_id: number;
  entered_date: string;
  effective_date: string;
  transaction_type: string;
  reason?: string;
}

const usePolicyTransactions = (policyId?: string | number) =>
  useQuery({
    queryKey: ['policy_transactions', policyId],
    queryFn: policyId
      ? (): Promise<{ policy_transactions: PolicyTransaction[] }> =>
          api.get(`/api/frontend/policies/${policyId}/policy_transactions`)
      : skipToken,
    select: data => data.policy_transactions
  });

export default usePolicyTransactions;
