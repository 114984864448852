/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import alert from '../../../../components/core/Alert';
import Container from '../../../../components/core/Container';
import {
  InvoiceIcon,
  ScalesIcon,
  SecurePaymentIcon,
  TodoIcon,
  TransferIcon,
  UserVoiceIcon
} from '../../../../components/core/icons';
import {
  isDispositionLostOrSold,
  isLeadContactMade,
  isLeadTransferred
} from '../../../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { IDataCollection } from '../../../../interfaces';
import { DispositionType } from '../../../../interfaces/IDisposition';
import { IQuotesRequest } from '../../../../interfaces/IQuotesRequests';
import { UserRole, UserRoleCategory } from '../../../../interfaces/IUser';
import { usePersonProposals } from '../../../../queries/people/proposals/usePersonProposal';
import useQuotesRequests from '../../../../queries/quotes_requests/useQuotesRequests';
import { Subscription } from '../../../../services/ActionCable';
import analytics from '../../../../services/analytics';
import authInfo, { isISR, isSuperISR } from '../../../../services/authInfo';
import { ActionCableContext } from '../../../../utils/actioncable';
import DataCollectionNavigation from '../../../GuidedDataCollection/_components/DataCollectionNavigation';
import { DataCollectionStepper } from '../../../GuidedDataCollection/_hooks/useDataCollectionStepper';
import { useCurrentDispositionSubscriptionContext } from '../../_hooks/useCurrentDispositionSubscription';
import { GuidedSellingPathnames } from '../../navigation';
import CollectedPagesByISR, { DATA_COLLECTION_CONFIRMED_BY_ISR_KEY } from './CollectedPagesByISR';
import MenuItem from './MenuItem';

interface ILeadNavigation {
  dataCollection: IDataCollection;
  dataCollectionStepper: DataCollectionStepper;
  menuOpened: boolean;
}

const useQuotingProgressSubscription = ({
  lastQuotesRequestGid,
  isLastQuotesRequestFinished,
  quotesStepActiveRef,
  refetchQuotesRequests
}: {
  lastQuotesRequestGid: IQuotesRequest['gid'] | undefined;
  isLastQuotesRequestFinished: boolean;
  quotesStepActiveRef: React.RefObject<boolean>;
  refetchQuotesRequests: ReturnType<typeof useQuotesRequests>['refetch'];
}) => {
  const isUserISR = isISR();
  const isQuotesStepActive = quotesStepActiveRef.current;

  const cable = React.useContext(ActionCableContext);
  const [displayQuotesIndicator, setDisplayQuotesIndicator] = React.useState(false);

  React.useEffect(() => {
    let quotingProgressChannel: Subscription;

    if (!isUserISR && lastQuotesRequestGid && !isLastQuotesRequestFinished && cable) {
      quotingProgressChannel = cable.subscriptions.create(
        {
          channel: 'QuotingProgressChannel',
          quotes_request_gid: lastQuotesRequestGid
        },
        {
          received: () => {
            if (!quotesStepActiveRef.current) {
              alert({
                message: 'Your quotes are ready',
                options: {
                  position: 'bottom-center',
                  autoClose: 2000
                }
              }).success();
              setDisplayQuotesIndicator(true);
            }

            refetchQuotesRequests();
          }
        }
      );
    }

    return () => {
      if (!isUserISR && lastQuotesRequestGid && !isLastQuotesRequestFinished && cable) {
        quotingProgressChannel?.unsubscribe();
      }
    };
  }, [cable, isUserISR, lastQuotesRequestGid, isLastQuotesRequestFinished, quotesStepActiveRef, refetchQuotesRequests]);

  React.useEffect(() => {
    if (isQuotesStepActive) {
      setDisplayQuotesIndicator(false);
    }
  }, [isQuotesStepActive]);

  return { displayQuotesIndicator };
};

const LeadNavigation = ({ dataCollection, dataCollectionStepper: { steps }, menuOpened }: ILeadNavigation) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const { personGid, leadGid } = useGuidedSellingExperienceContext();

  const { leadDispositions, currentDisposition } = useCurrentDispositionSubscriptionContext();
  const { data: proposals } = usePersonProposals({ personGid, engagementGid: leadGid });

  const isUserISR = isISR();
  const isUserSuperISR = isSuperISR();

  const dataCollectionDisabled = !isLeadContactMade(leadDispositions?.dispositions || []);
  const dataCollectionActive = pathname === GuidedSellingPathnames.DataCollection;
  const dataCollectionCompleted = !!dataCollection.completed;
  const dataCollectionSteps = steps.map(step => ({
    ...step,
    is_navigation_disabled: dataCollectionDisabled ? true : step.is_navigation_disabled,
    active: dataCollectionActive ? step.active : false
  }));

  const { data: quotesRequests, refetch: refetchQuotesRequests } = useQuotesRequests({ personGid, leadGid });

  const quotesStepActive = pathname === GuidedSellingPathnames.Quotes;
  const quotesStepActiveRef = React.useRef(quotesStepActive);
  quotesStepActiveRef.current = quotesStepActive;

  const lastQuotesRequest = quotesRequests?.[0];
  const lastQuotesRequestGid = lastQuotesRequest?.gid;
  const isLastQuotesRequestFinished = !!lastQuotesRequest?.finished_at;

  const { displayQuotesIndicator } = useQuotingProgressSubscription({
    lastQuotesRequestGid,
    isLastQuotesRequestFinished,
    quotesStepActiveRef,
    refetchQuotesRequests
  });

  const isAnyPageCompletedByISR = dataCollection.pages.some(
    page => page.originally_completed_by?.role_category === UserRoleCategory.ISR
  );
  const isCurrentUserNotISR = authInfo.role !== UserRole.ISR && authInfo.role !== UserRole.SuperISR;
  const isCollectedByISRConfirmed = localStorage.getItem(`${DATA_COLLECTION_CONFIRMED_BY_ISR_KEY}-${leadGid}`);
  const displayCollectedByISRTip =
    personGid &&
    leadGid &&
    !isCollectedByISRConfirmed &&
    isAnyPageCompletedByISR &&
    isCurrentUserNotISR &&
    featureFlags.displayCollectedBySuperISRTip;

  return (
    <>
      <MenuItem
        IconComponent={UserVoiceIcon}
        label="Establish rapport"
        onClick={() => navigate(`${GuidedSellingPathnames.ScoutingReport}${search}`)}
        active={pathname === GuidedSellingPathnames.ScoutingReport}
        menuOpened={menuOpened}
        completed={isLeadContactMade(leadDispositions?.dispositions || [])}
        disabled={false}
      />

      <MenuItem
        IconComponent={TodoIcon}
        label="Data collection"
        onClick={() => navigate(`${GuidedSellingPathnames.DataCollection}${search}`)}
        active={dataCollectionActive}
        menuOpened={menuOpened}
        completed={dataCollectionCompleted}
        disabled={dataCollectionDisabled}
        submenuItemsCount={dataCollectionSteps.length}
        collectedByComponent={
          displayCollectedByISRTip && (
            <Container
              customCss={css`
                position: relative;
              `}
            >
              <Container
                customCss={css`
                  text-align: left;
                  position: absolute;
                  left: 36px;
                  top: 12px;
                `}
              >
                <CollectedPagesByISR leadGid={leadGid} dataCollection={dataCollection} personGid={personGid} />
              </Container>
            </Container>
          )
        }
      >
        <DataCollectionNavigation steps={dataCollectionSteps} />
      </MenuItem>

      {!isUserISR && (
        <>
          <MenuItem
            IconComponent={ScalesIcon}
            label="Quoting"
            onClick={() => {
              navigate(`${GuidedSellingPathnames.Quotes}${search}`);
            }}
            active={quotesStepActive}
            menuOpened={menuOpened}
            completed={!!lastQuotesRequestGid}
            disabled={
              (!leadDispositions || !isLeadTransferred(leadDispositions.dispositions)) &&
              !authInfo.features.review_quotes_outside_pipeline
            }
            progress={!!lastQuotesRequestGid && !isLastQuotesRequestFinished}
            itemsAvailable={displayQuotesIndicator}
          />
          {featureFlags.proposalBuilderPage && (
            <MenuItem
              IconComponent={InvoiceIcon}
              label="Proposal"
              onClick={() => {
                navigate(`${GuidedSellingPathnames.Proposal}${search}`);
              }}
              active={pathname === GuidedSellingPathnames.Proposal}
              menuOpened={menuOpened}
              completed={!!proposals?.find(proposal => proposal.sent_at)}
              disabled={!lastQuotesRequestGid}
            />
          )}
          <MenuItem
            IconComponent={SecurePaymentIcon}
            label="Finalize"
            onClick={() => {
              // using same event for BI convenience
              analytics.track('Finalize lead dropdown clicked', { value: DispositionType.PolicySold });
              navigate(`${GuidedSellingPathnames.Finalize}${search}`);
            }}
            active={pathname === GuidedSellingPathnames.Finalize}
            menuOpened={menuOpened}
            completed={isDispositionLostOrSold(currentDisposition?.disposition_type)}
            disabled={!leadDispositions || !isLeadTransferred(leadDispositions.dispositions)}
          />
        </>
      )}
      {isUserSuperISR && (
        <>
          <MenuItem
            IconComponent={TransferIcon}
            label="Transferring"
            onClick={() => navigate(`${GuidedSellingPathnames.HandOff}${search}`)}
            active={pathname === GuidedSellingPathnames.HandOff}
            menuOpened={menuOpened}
            completed={
              pathname === GuidedSellingPathnames.ISRFinish ||
              isLeadTransferred(leadDispositions?.dispositions || []) ||
              isDispositionLostOrSold(currentDisposition?.disposition_type)
            }
            disabled={![GuidedSellingPathnames.HandOff, GuidedSellingPathnames.ISRFinish].includes(pathname)}
          />
          <MenuItem
            IconComponent={SecurePaymentIcon}
            label="Final look"
            onClick={() => navigate(`${GuidedSellingPathnames.Finalize}${search}`)}
            active={pathname === GuidedSellingPathnames.ISRFinish}
            menuOpened={menuOpened}
            completed={isDispositionLostOrSold(currentDisposition?.disposition_type)}
            disabled={pathname !== GuidedSellingPathnames.ISRFinish}
          />
        </>
      )}
    </>
  );
};

export default LeadNavigation;
