import React from 'react';

import { API_TO_SMARTY_STREETS_MAPPING } from '../../../constants/addressForm';
import { IAddressSuggestion, IAnswers, IQuestion } from '../../../interfaces';
import { QuestionType, ValidationType } from '../../../interfaces/IWorkflow';
import { createSmartyStreetsMapping, renameKeys } from '../../../utils/object';
import {
  AddressField,
  CheckboxField,
  DateInputField,
  InputField,
  MultiSelectField,
  PhoneField,
  RadioGroupField,
  SelectField
} from './fields';

// TODO: create better generic after fix https://github.com/microsoft/TypeScript/issues/13995
// type InputForQuestion = <T extends Question>(
//   question: T,
//   inputProps: T['type'] extends QuestionType.Address
//     ? Partial<AddressProps>
//     : T['type'] extends QuestionType.Checkbox
//     ? Partial<CheckboxFieldProps>
//     : T['type'] extends QuestionType.Date
//     ? Partial<DateFieldProps>
//     : any,
//   answers?: Answers
// ) => JSX.Element;

type InputForQuestion = <T extends IQuestion>({
  question,
  inputProps,
  answers
}: {
  question: T;
  inputProps: any;
  answers?: IAnswers;
}) => JSX.Element;

const WorkflowInput: InputForQuestion = ({
  question: { type, key: questionKey, label, variants, validations, description },
  inputProps,
  answers
}) => {
  const inputKey = inputProps.key || questionKey;
  const isQuestionRequired = validations?.some(({ key }) => key === ValidationType.Required);

  switch (type) {
    case QuestionType.Checkbox:
      return (
        <CheckboxField testId={inputKey} label={label} id={inputKey} name={inputKey} {...inputProps} key={inputKey} />
      );
    case QuestionType.Address:
      return (
        <AddressField
          required={isQuestionRequired}
          testId={inputKey}
          placeholder=""
          label={label}
          id={inputKey}
          defaultValue={renameKeys(API_TO_SMARTY_STREETS_MAPPING, answers?.[inputKey] || {}) as IAddressSuggestion}
          fallbackNames={createSmartyStreetsMapping(`${inputKey}.`)}
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Date:
      return (
        <DateInputField
          required={isQuestionRequired}
          testId={inputKey}
          label={label}
          id={inputKey}
          placeholder=""
          name={inputKey}
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Text:
      return (
        <InputField
          required={isQuestionRequired}
          testId={inputKey}
          label={label}
          id={inputKey}
          name={inputKey}
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.MultiSelect:
      return (
        <MultiSelectField
          required={isQuestionRequired}
          testId={inputKey}
          label={label}
          name={inputKey}
          id={inputKey}
          options={variants as any}
          labelName="label"
          valueName="value"
          description={description}
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Select:
      return (
        <SelectField
          required={isQuestionRequired}
          testId={inputKey}
          placeholder=""
          label={label}
          id={inputKey}
          name={inputKey}
          options={variants as any}
          labelName="label"
          valueName="value"
          description={description}
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Number:
      return (
        <InputField
          required={isQuestionRequired}
          testId={inputKey}
          label={label}
          id={inputKey}
          name={inputKey}
          type="number"
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Radio:
      return (
        <RadioGroupField
          required={isQuestionRequired}
          testId={inputKey}
          label={label}
          id={inputKey}
          name={inputKey}
          description={description}
          options={variants!.map(({ value, label }) => ({
            id: value,
            value,
            label
          }))}
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Phone:
      return (
        <PhoneField
          required={isQuestionRequired}
          testId={inputKey}
          copyable
          placeholder=""
          label={label}
          id={inputKey}
          name={inputKey}
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Email:
      return (
        <InputField
          required={isQuestionRequired}
          testId={inputKey}
          copyable
          label={label}
          id={inputKey}
          name={inputKey}
          type="email"
          {...inputProps}
          key={inputKey}
        />
      );
    case QuestionType.Slider:
      // TODO: add slider component
      return <React.Fragment />;
    default:
      return <React.Fragment />;
  }
};

export default WorkflowInput;
