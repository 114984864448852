import { skipToken, useQuery } from '@tanstack/react-query';

import { DispositionType } from '../../../interfaces/IDisposition';
import api from '../../../services/api';

export interface DispositionTypeOption {
  key: DispositionType;
  value: {
    label: string;
    next_action: string;
  };
}

export interface DispositionReasonOption {
  key: string;
  value: string;
  unavailable?: boolean;
}

export interface DispositionsConfig {
  disposition_types: DispositionTypeOption[];
  disposition_transitions: Record<DispositionType, DispositionType[]>;
  dispositions_with_reason: DispositionType[];
  disposition_not_qualified_reasons: DispositionReasonOption[];
  disposition_could_not_contact_reasons: DispositionReasonOption[];
}

const useLeadDispositionConfig = (leadId?: number | string) =>
  useQuery({
    queryKey: ['leadDispositionConfig', { leadId }],
    queryFn: leadId
      ? (): Promise<DispositionsConfig> => api.get(`/api/frontend/leads/${leadId}/disposition_config`)
      : skipToken
  });

export default useLeadDispositionConfig;
