/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../../components/core/Container';
import { InfoIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const CSAACarrierInstructions = () => {
  return (
    <Container
      p={spacings.px12}
      backgroundColor={colors.violetBackground}
      roundBorder
      customCss={css`
        width: fit-content;
      `}
    >
      <Paragraph>
        <InfoIcon
          width={24}
          height={24}
          color={colors.violet}
          css={css`
            margin-right: ${spacings.px8}px;
          `}
        />
        <Text bold>Quick reminder:</Text> All CSAA policies <Text bold>MUST</Text> be set up with eSign for all
        documents.
      </Paragraph>
      <Paragraph mt={spacings.px8}>
        Follow steps below to ensure application is sent via eSign to avoid policy cancellation! (More info here:{' '}
        <a
          href="https://maticinsurance.atlassian.net/wiki/spaces/MI/pages/4168941676/CSAA"
          target="_blank"
          rel="noreferrer"
        >
          CSAA Guide
        </a>
        )
      </Paragraph>
    </Container>
  );
};

export default CSAACarrierInstructions;
