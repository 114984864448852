import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import MultiSelect, { components } from 'react-select';

import { compact } from '../../../utils/array';

// eslint-disable-next-line react/prop-types
const Control = (props: any) => (
  <components.Control
    {...props}
    innerProps={{
      ...props.innerProps,
      'data-testid': 'react-select-control'
    }}
    className={classNames(props.className, 'form-control')}
  />
);

// @ts-expect-error old code
MultiSelect.displayName = 'MultiSelect';

const SELECT_CLASS_NAME = 'react-select';
const MULTI_SELECT_CLASS_NAME = 'react-select--multi';

class MultiOptionsSelect extends React.Component<any, any> {
  onValuesChange = (items = []) => {
    const { name, onChange } = this.props;
    const values = (items || []).map((item: any) => item.value);

    onChange(name ? { target: { name, value: values } } : values);
  };

  displayOptions = () => {
    const { options, ordered } = this.props;

    const selectOptions = options.map((option: any) => this.displayOption(option));

    if (ordered) {
      this.sortDisplayOptions(selectOptions);
    }

    return selectOptions;
  };

  displayOption = (option: { [x: string]: any }) => {
    const { labelName, valueName } = this.props;

    return { label: option[labelName], value: option[valueName] };
  };

  sortDisplayOptions = (options: any[]) => options.sort((a, b) => a.label.toString().localeCompare(b.label.toString()));

  findSelectedOptions = (options: any[]) => {
    const { value } = this.props;

    if (value.length === 0 || options.length === 0) {
      return [];
    }

    const selectedOptions = value.map((item: any) => options.find(option => option.value === item));

    return compact(selectedOptions);
  };

  render = () => {
    const displayOptions = this.displayOptions();
    const selectedOptions = this.findSelectedOptions(displayOptions);

    return (
      <MultiSelect
        {...this.props}
        options={displayOptions}
        // @ts-expect-error old code
        onChange={this.onValuesChange}
        value={selectedOptions}
        className={classNames(this.props.className, SELECT_CLASS_NAME, MULTI_SELECT_CLASS_NAME)}
        classNamePrefix={SELECT_CLASS_NAME}
        components={{ Control, IndicatorSeparator: null }}
        isClearable={this.props.showResetButton}
        closeMenuOnSelect={false}
        isDisabled={this.props.disabled}
        isMulti
      />
    );
  };
}
// @ts-expect-error old code
MultiOptionsSelect.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.array,
  placeholder: PropTypes.string,
  labelName: PropTypes.string,
  valueName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  ordered: PropTypes.bool,
  showResetButton: PropTypes.bool,
  className: PropTypes.string
};
// @ts-expect-error old code
MultiOptionsSelect.defaultProps = {
  name: '',
  options: [],
  value: [],
  placeholder: 'Please select options',
  labelName: 'value',
  valueName: 'key',
  disabled: false,
  ordered: false,
  showResetButton: false,
  className: null
};

export default MultiOptionsSelect;
