import { Navigate, useParams } from 'react-router-dom';

import guidedSellingExperienceNavigation from '../../pages/GuidedSellingExperience/navigation';
import useLastLeadId from '../../queries/people/useLastLead';

const LastLeadRedirect = () => {
  const { personGid } = useParams();

  const { data: leadIds, isFetched } = useLastLeadId(personGid);

  if (isFetched && leadIds) {
    return <Navigate replace to={guidedSellingExperienceNavigation.forLead({ lead_gid: leadIds.gid })} />;
  }

  if (isFetched && !leadIds && personGid) {
    return <Navigate replace to={guidedSellingExperienceNavigation.forPerson({ person_gid: personGid })} />;
  }

  return null;
};

export default LastLeadRedirect;
