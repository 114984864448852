/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Copy, Link } from '../../../../components/common';
import DescriptionList from '../../../../components/core/DescriptionList';
import Text from '../../../../components/core/Text';
import featureFlags from '../../../../constants/featureFlags';
import { servicerOrLender } from '../../../../interfaces/ILender';
import { ILoan } from '../../../../interfaces/ILoan';
import { isInsurableInterestVehicle, PolicyType } from '../../../../interfaces/IPolicyType';
import colors from '../../../../theme/colors';
import LoanPersonInfo from './LoanPersonInfo';

const PrimaryOpportunityLoan = ({
  loan: { loan_number, lender, servicer, processor, officer },
  leadId,
  leadGid,
  primaryOpportunityPolicyType
}: {
  loan: ILoan;
  leadId: number | undefined;
  leadGid: string | undefined;
  primaryOpportunityPolicyType: PolicyType;
}) => {
  const company = servicerOrLender({ servicer, lender });

  if (!loan_number && !company && !processor && !officer) {
    return null;
  }

  const lenderName = company?.name;
  const mortgageeClause = company?.business_name;
  const mortgageeAddress = company?.mortgagee_address;

  return (
    <>
      <Text type="large" bold>
        {isInsurableInterestVehicle(primaryOpportunityPolicyType) ? 'Auto loan info' : 'Home loan info'}
      </Text>
      <div
        css={css`
          margin-top: 16px;
        `}
      >
        {!!lenderName && (
          <DescriptionList
            term={<Text color={colors.grey60}>Lender name</Text>}
            details={
              <Text>
                <Copy
                  testId="lender_name"
                  displayText={lenderName}
                  value={lenderName}
                  css={css`
                    margin-left: 4px;
                  `}
                />
              </Text>
            }
          />
        )}
        {!!mortgageeClause && (
          <DescriptionList
            term={<Text color={colors.grey60}>Mortgagee clause</Text>}
            details={
              <Text>
                <Copy
                  testId="mortgagee_clause"
                  displayText={mortgageeClause}
                  value={mortgageeClause}
                  css={css`
                    margin-left: 4px;
                  `}
                />
              </Text>
            }
          />
        )}
        {!!mortgageeAddress && (
          <DescriptionList
            term={<Text color={colors.grey60}>Address</Text>}
            details={
              <div>
                <Text>
                  <Copy
                    testId="mortgagee_address_line1"
                    displayText={`${mortgageeAddress.line1}, `}
                    value={mortgageeAddress.line1}
                    css={css`
                      margin-left: 4px;
                    `}
                  />
                </Text>
                {mortgageeAddress.line2 && (
                  <Text>
                    <Copy
                      testId="mortgagee_address_line2"
                      displayText={`${mortgageeAddress.line2}, `}
                      value={mortgageeAddress.line2}
                      css={css`
                        margin-left: 4px;
                      `}
                    />
                  </Text>
                )}
                <Text>
                  <Copy
                    testId="mortgagee_address_city"
                    displayText={`${mortgageeAddress.city}, `}
                    value={mortgageeAddress.city}
                    css={css`
                      margin-left: 4px;
                    `}
                  />
                </Text>
                <Text>
                  <Copy
                    testId="mortgagee_address_state"
                    displayText={`${mortgageeAddress.state}, `}
                    value={mortgageeAddress.state}
                    css={css`
                      margin-left: 4px;
                    `}
                  />
                </Text>
                <Text>
                  <Copy
                    testId="mortgagee_address_zip"
                    displayText={mortgageeAddress.zip}
                    value={mortgageeAddress.zip}
                    css={css`
                      white-space: nowrap;
                      margin-left: 4px;
                    `}
                  />
                </Text>
              </div>
            }
          />
        )}
        {!!loan_number && (
          <DescriptionList
            term={<Text color={colors.grey60}>Loan number</Text>}
            details={
              <Text>
                <Copy
                  testId="loan_number"
                  displayText={loan_number}
                  value={loan_number}
                  className="fs-mask"
                  css={css`
                    margin-left: 4px;
                  `}
                />
              </Text>
            }
          />
        )}
        {!!processor && (
          <div
            css={css`
              margin-top: 12px;
            `}
          >
            <LoanPersonInfo title="Loan processor info" person={processor} />
          </div>
        )}
        {!!officer && (
          <div
            css={css`
              margin-top: 12px;
            `}
          >
            <LoanPersonInfo title="Loan officer info" person={officer} />
          </div>
        )}
        <div
          css={css`
            margin-top: 12px;
            margin-bottom: 12px;
            width: 100%;
            height: 1px;
            background: ${colors.grey30};
          `}
        />
      </div>
      {leadId && (
        <Link
          to={featureFlags.scoutingReportRedirect ? `/scouting_report?lead_gid=${leadGid}` : `/leads/${leadId}/loans`}
        >
          <Text color={colors.azure50}>See loans for all assets</Text>
        </Link>
      )}
    </>
  );
};

export default PrimaryOpportunityLoan;
