import { Configuration } from '../constants';
import { IAddress } from '../interfaces/IAddress';

const MAPS_EMBED_URL = 'https://www.google.com/maps/embed/v1';

export const googleStreetPanoramaView = (panoramaId: string) =>
  `${MAPS_EMBED_URL}/streetview?key=${Configuration.googleStreetApiKey}&pano=${panoramaId}`;

export const googleStreetSatelliteView = (address: IAddress) => {
  const { line1, city, state, zip } = address;

  const q = `${line1}+${city}+${state}+${zip}`;

  return `${MAPS_EMBED_URL}/place?key=${Configuration.googleStreetApiKey}&q=${q}&maptype=satellite&zoom=21`;
};
