/** @jsxImportSource @emotion/react */
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useLocation, useNavigate } from 'react-router-dom';

import Tooltip from '../../components/common/Tooltip/NewTooltip';
import AddButton from '../../components/core/buttons/AddButton';
import Container from '../../components/core/Container';
import CopyableAddress from '../../components/core/CopyableAddress';
import FlexBox from '../../components/core/FlexBox';
import FormLoader from '../../components/core/FormLoader';
import BaseForm from '../../components/core/forms/BaseForm';
import Heading from '../../components/core/Heading';
import { CheckIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import CancelTrailingScheduledCallModal from '../../components/DispositionsModals/CancelTrailingScheduledCallModal';
import featureFlags from '../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../hooks';
import { IMaticPolicy, IOpportunity, IPerson, ITask, IUser } from '../../interfaces';
import { DispositionType, ICurrentDisposition } from '../../interfaces/IDisposition';
import { OpportunityStatus } from '../../interfaces/IOpportunity';
import {
  findPolicyTypeLabel,
  isInsurableInterestRealProperty,
  isInsurableInterestVehicle,
  isNonLifeAncillaryPolicyType
} from '../../interfaces/IPolicyType';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import useAssignees from '../../queries/assignees/useAssignees';
import useUpdateLeadDisposition from '../../queries/leads/dispositions/useUpdateLeadDisposition';
import { updateOpportunity } from '../../queries/leads/opportunities/useLeadOpportunities';
import usePersonMaticPolicies from '../../queries/people/person_policies/usePersonMaticPolicies';
import useCreateTask, { TaskPayload } from '../../queries/people/person_tasks/useCreateTask';
import usePersonTasks, { PERSON_TASKS_QUERY_KEY } from '../../queries/people/person_tasks/usePersonTasks';
import useUpdateTask from '../../queries/people/person_tasks/useUpdateTask';
import useSendPolicyDocumentsToLender from '../../queries/policies/useSendPolicyDocumentsToLender';
import { usePersonScheduledInteractions } from '../../queries/scheduled_calls/useScheduledInteractions';
import { TASK_REMINDERS_QUERY_KEY } from '../../queries/tasks/useTaskReminders';
import analytics from '../../services/analytics';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { GuidedSellingPathnames } from '../GuidedSellingExperience/navigation';
import TaskEditor from '../Lead/LeadTasks/TaskEditor';
import { findUncompletedCall } from '../ScheduleCall/helpers';
import PolicySummaryCard from './_components/PolicySummaryCard';
import PolicyTask from './_components/PolicyTask';
import useFaxTasks from './_contexts/useFaxTasks';
import useDisplayFinalizedSalesPage from './_contexts/useFinalizedPage';

const CONFIRM_BUTTON_TIP = 'You need to close opportunities to proceed.';
const CONFIRM_TIP_ID = 'confirm-button';

const PolicyTasks = ({
  policy,
  leadId,
  person,
  assignees,
  tasks,
  isLoadingPersonTasks,
  isDataEditingForbidden
}: {
  policy: IMaticPolicy;
  leadId: number;
  person: IPerson;
  assignees: IUser[];
  tasks: ITask[] | undefined;
  isLoadingPersonTasks: boolean;
  isDataEditingForbidden: boolean;
}) => {
  const queryClient = useQueryClient();
  const [addTask, toggleAddTask] = useToggle(false);
  const { mutateAsync: createTask } = useCreateTask({});
  const { mutateAsync: updateTask } = useUpdateTask(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY] });
  });

  const policyTasks = tasks?.filter(task => task.policy_id === policy.id);

  const isHome = isInsurableInterestRealProperty(policy.policy_type);
  const isAuto = isInsurableInterestVehicle(policy.policy_type);

  return (
    <FlexBox justifySpaceBetween gap={spacings.px24}>
      <FlexBox columnDirection gap={spacings.px12} fitParentWidth>
        <FlexBox alignItemsCenter>
          <Paragraph bold type="large">
            {findPolicyTypeLabel(policy.policy_type)}
          </Paragraph>
          <AddButton
            disabled={isDataEditingForbidden}
            content="Add task"
            onClick={() => {
              analytics.track('Final look, add task clicked', { policy_type: policy.policy_type });
              toggleAddTask();
            }}
            testId={`add-task-${policy.id}`}
          />
        </FlexBox>
        <FlexBox>
          {isHome && policy.assets && policy.assets[0] && <CopyableAddress address={policy.assets[0].address} />}
          {isAuto && policy.assets && policy.assets[0] && (
            <Paragraph>
              {policy.assets.map((auto, index) => `${index > 0 ? ', ' : ''}${auto.description_without_icon}`)}
            </Paragraph>
          )}
        </FlexBox>
        {isLoadingPersonTasks ? (
          <Skeleton count={2} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} />
        ) : (
          <>
            {policyTasks?.length ? (
              <>
                {policyTasks?.map(task => {
                  return (
                    <PolicyTask
                      task={task}
                      key={task.id}
                      assignees={assignees}
                      leadId={leadId}
                      policy={policy}
                      person={person}
                      updateTask={updateTask}
                      homeAssetGid={isHome ? policy.assets?.[0]?.gid : undefined}
                      isDataEditingForbidden={isDataEditingForbidden}
                    />
                  );
                })}
              </>
            ) : (
              <FlexBox gap={spacings.px8} alignItemsCenter>
                <CheckIcon color={colors.statusGreen} />
                <Paragraph>Yo, you&apos;re all good. Nothing needs to be done here.</Paragraph>
              </FlexBox>
            )}
          </>
        )}
      </FlexBox>
      <PolicySummaryCard policy={policy} />
      {addTask && (
        <TaskEditor
          open={addTask}
          assignees={assignees}
          cancelBtnHandler={toggleAddTask}
          confirmBtnHandler={data => {
            createTask({
              personGid: person!.gid,
              data: { ...data, lead_id: leadId, policy_id: policy.id } as TaskPayload
            });
            toggleAddTask();
          }}
          person={person}
          leadId={leadId}
        />
      )}
    </FlexBox>
  );
};

const FinalLookForm = ({
  opportunities,
  currentDisposition,
  isDataEditingForbidden
}: {
  isDataEditingForbidden: boolean;
  opportunities: IOpportunity[];
  currentDisposition: ICurrentDisposition | undefined;
}): JSX.Element => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { lead, person } = useGuidedSellingExperienceContext();
  const { data: policies = [], isFetchedAfterMount: isPoliciesFetched } = usePersonMaticPolicies({
    personGid: person!.gid,
    filters: { lead_id: lead!.id }
  });
  const [openCancelCallModal, setOpenCancelCallModal] = React.useState<boolean>(false);
  const { data: scheduledCalls, isFetchedAfterMount: isScheduledCallsFetched } = usePersonScheduledInteractions(
    person!.gid,
    [
      ScheduledInteractionFlow.Calendly,
      ScheduledInteractionFlow.CalendlyFollowUp,
      ScheduledInteractionFlow.ScheduledCall,
      ScheduledInteractionFlow.ScheduledCallIsrs,
      ScheduledInteractionFlow.ScheduledCallV2,
      ScheduledInteractionFlow.ScheduledCallEmail,
      ScheduledInteractionFlow.ScheduledByRecapture
    ]
  );
  const isDataLoaded = isPoliciesFetched && isScheduledCallsFetched;

  const uncompletedCall = findUncompletedCall(scheduledCalls);

  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();
  const { mutateAsync: sendPolicyDocumentsToLender } = useSendPolicyDocumentsToLender();
  const { mutateAsync: updateTask } = useUpdateTask(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] });
  });

  const { data: assignees } = useAssignees();

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const opportunitiesToConfirm = opportunities.filter(
    opportunity =>
      !isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened && opportunity.is_data_collection_enabled
  );

  const opportunitiesToAutoclose = opportunities
    .filter(
      opportunity =>
        (isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened) || !opportunity.is_data_collection_enabled
    )
    .map(opportunity => ({ ...opportunity, reason: opportunity.reason || 'not_interested' }));

  const sortedPolicies = [...policies].sort((a, b) => {
    const aIndex = opportunities.findIndex(opp => opp.policy_type === a.policy_type);
    const bIndex = opportunities.findIndex(opp => opp.policy_type === b.policy_type);

    if (aIndex < bIndex) {
      return -1;
    } else if (aIndex > bIndex) {
      return 1;
    } else {
      return 0;
    }
  });

  const { faxTasks } = useFaxTasks();

  const { data: tasks, isPending: isPendingPersonTasks } = usePersonTasks(person?.gid);

  if (!isDataLoaded) {
    return (
      <Container p={spacings.px24}>
        <FormLoader />
      </Container>
    );
  }

  return (
    <BaseForm
      controlsAlignment="right"
      controlsWidth={320}
      pb={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      pt={spacings.px24}
      type="fullPage"
      disabled={!!opportunitiesToConfirm.length || isDataEditingForbidden}
      submitText={
        <React.Fragment>
          <span data-for={CONFIRM_TIP_ID} data-tip={CONFIRM_BUTTON_TIP}>
            Proceed
          </span>
          {!!opportunitiesToConfirm.length && <Tooltip id={CONFIRM_TIP_ID} />}
        </React.Fragment>
      }
      initialValues={{}}
      loading={isSubmitting}
      onSubmit={async () => {
        setIsSubmitting(true);

        currentDisposition?.disposition_type !== DispositionType.PolicySold &&
          (await Promise.all(
            opportunitiesToAutoclose.map(opp =>
              updateOpportunity({
                leadId: lead!.id,
                opportunityId: opp.id,
                params: { status: OpportunityStatus.Lost, reason: opp.reason || 'not_interested' }
              })
            )
          )
            .then(() => {
              return updateDisposition({
                leadId: lead!.id,
                disposition_type: sortedPolicies.length ? DispositionType.PolicySold : DispositionType.Lost
              });
            })
            .catch(() => {
              setIsSubmitting(false);
            }));
        analytics.track('Final look confirm button clicked');

        await Promise.all(
          faxTasks.map(
            task =>
              task.fax &&
              sendPolicyDocumentsToLender({ policyId: task.policy_id, loan_gid: task.loan_gid }).then(() =>
                updateTask({ personGid: person!.gid, taskId: task.id, data: { ...task, completed: true } })
              )
          )
        ).catch(() => {
          setIsSubmitting(false);
        });

        if (uncompletedCall) {
          setOpenCancelCallModal(true);
        } else {
          setIsSubmitting(false);
          featureFlags.displayDisqualifyButton && navigate(`${GuidedSellingPathnames.AgentFinish}${search}`);
          !featureFlags.displayDisqualifyButton &&
            useDisplayFinalizedSalesPage.setState({
              displayFinalizedSalesPage: true
            });
        }
      }}
      renderForm={() => (
        <FlexBox columnDirection gap={spacings.px24}>
          <Heading>Your post-sales tasks</Heading>

          {person &&
            lead &&
            assignees &&
            sortedPolicies.map(policy => (
              <PolicyTasks
                policy={policy}
                key={policy.id}
                person={person}
                leadId={lead.id}
                assignees={assignees}
                tasks={tasks}
                isLoadingPersonTasks={isPendingPersonTasks}
                isDataEditingForbidden={isDataEditingForbidden}
              />
            ))}

          {openCancelCallModal && uncompletedCall && (
            <CancelTrailingScheduledCallModal
              assignee={currentDisposition?.assignee}
              personGid={person!.gid}
              scheduledCall={uncompletedCall}
              onCloseModal={() => {
                setOpenCancelCallModal(false);

                setIsSubmitting(false);
                featureFlags.displayDisqualifyButton && navigate(`${GuidedSellingPathnames.AgentFinish}${search}`);
                !featureFlags.displayDisqualifyButton &&
                  useDisplayFinalizedSalesPage.setState({
                    displayFinalizedSalesPage: true
                  });
              }}
            />
          )}
        </FlexBox>
      )}
    />
  );
};

export default FinalLookForm;
