import { useQuery } from '@tanstack/react-query';

import { IDispositionVersion } from '../../../interfaces/IDispositionVersion';
import api from '../../../services/api';

const useLeadDispositionsVersions = ({ leadGid }: { leadGid: string }) =>
  useQuery({
    queryKey: ['lead_dispositions_versions', leadGid],
    queryFn: (): Promise<{ paper_trail_versions: IDispositionVersion[] }> =>
      api.get(`/api/frontend/leads/${leadGid}/dispositions_versions`),
    select: data => data.paper_trail_versions
  });

export default useLeadDispositionsVersions;
