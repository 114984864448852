import { FormikValues } from 'formik';
import { isEmpty, isNil } from 'ramda';

import {
  IAnswers,
  OrConditionGroups,
  VisibilityCondition,
  VisibilityConditionOperation
} from '../../../interfaces/IWorkflow';

const isNotFilled = (value: any) => isEmpty(value) || isNil(value);

const isConditionSatisfied = (
  { source, operation, value: targetValue }: VisibilityCondition,
  formValues: FormikValues
) => {
  const formValue = formValues[source];

  const formValueIsArray = Array.isArray(formValue);

  switch (operation) {
    case VisibilityConditionOperation.Equal:
      return formValueIsArray ? formValue.some(value => value === targetValue) : formValue === targetValue;
    case VisibilityConditionOperation.NotEqual:
      return formValueIsArray ? !formValue.some(value => value === targetValue) : targetValue !== formValue;
    case VisibilityConditionOperation.OneOf: {
      const values = targetValue as string[];
      return values.some(entry => entry === formValue);
    }
    case VisibilityConditionOperation.NotOneOf: {
      const values = targetValue as string[];
      return !values.some(entry => entry === formValue);
    }
    case VisibilityConditionOperation.LessThan:
      return !!formValue && formValue < targetValue;
    case VisibilityConditionOperation.GreaterThan:
      return !!formValue && formValue > targetValue;
    case VisibilityConditionOperation.Filled:
      return !isNotFilled(formValue);
    case VisibilityConditionOperation.NotFilled:
      return isNotFilled(formValue);
    default:
      return true;
  }
};

export const isQuestionVisible = (
  { visibility_conditions }: { visibility_conditions: OrConditionGroups | undefined },
  formValues: FormikValues | IAnswers
) => {
  if (!visibility_conditions || visibility_conditions.length === 0) {
    return true;
  }

  return visibility_conditions.some(conditionGroup =>
    conditionGroup.every(condition => isConditionSatisfied(condition, formValues))
  );
};
