import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  IDataCollectionOpportunity,
  IDataCollectionPage,
  IDataCollectionPageType
} from '../../../interfaces/IDataCollection';
import { DataCollection } from '../../../queries/leads/data_collection/useDataCollection';
import { GuidedSellingPathnames } from '../../GuidedSellingExperience/navigation';
import { DataCollectionNavigationProps } from '../_components/DataCollectionNavigation';
import useDataCollectionStepsState from './useDataCollectionStepsState';

export type PartialStep = Omit<DataCollectionNavigationProps['steps'][number], 'onClick'>;

const DEFAULT_STEPS = [] as PartialStep[];

const injectGoToSelf = (steps: PartialStep[], onClick: (key: string) => void): DataCollectionNavigationProps['steps'] =>
  steps.map(step => ({ ...step, onClick: () => onClick(step.key) }));

const useStepsSync = ({
  pages,
  goToStep,
  setSteps
}: {
  pages: (IDataCollectionPage & { active: boolean; index: number })[];
  goToStep: (key: DataCollectionNavigationProps['steps'][0]['key']) => void;
  setSteps: (steps: PartialStep[] | undefined) => void;
}) => {
  React.useEffect(() => {
    setSteps(injectGoToSelf(pages || DEFAULT_STEPS, goToStep));
  }, [pages, goToStep, setSteps]);

  React.useEffect(() => {
    return () => {
      setSteps(undefined);
    };
  }, [setSteps]);
};

const useSaveLastStepById = ({
  leadId,
  currentStep,
  setLastActiveStepById
}: {
  leadId: number | undefined;
  currentStep: PartialStep | undefined;
  setLastActiveStepById: (id: number, step: PartialStep) => void;
}) => {
  React.useEffect(() => {
    if (leadId) {
      setLastActiveStepById(leadId, currentStep!);
    }
  }, [currentStep, setLastActiveStepById, leadId]);
};

const useStepSubscription = () => {
  const lastActiveStep = React.useRef(useDataCollectionStepsState.getState().lastActiveStep);

  React.useEffect(
    () => useDataCollectionStepsState.subscribe(state => (lastActiveStep.current = state.lastActiveStep)),
    []
  );

  return lastActiveStep.current;
};

const doesPageIncludeOpportunity = ({
  page,
  opportunity
}: {
  page: IDataCollectionPage;
  opportunity: IDataCollectionOpportunity;
}) =>
  page.insurable_interest === opportunity.insurable_interest &&
  page.opportunities?.some(({ id }) => id === opportunity.id);

const evaluateInitialActivePageIndex = ({
  dataCollection,
  configureQuoting,
  key
}: {
  dataCollection: DataCollection | undefined;
  configureQuoting: boolean;
  key: string | undefined;
}) => {
  if (!dataCollection) {
    return undefined;
  }

  if (configureQuoting) {
    return dataCollection.pages.findIndex(page => page.page_type === IDataCollectionPageType.QuotingConfiguration);
  }

  const lastSavedOrFirstUncompletedPage = dataCollection.pages.find(page =>
    key ? page.key === key : !page.is_completed && page.is_data_collection_enabled
  );

  return lastSavedOrFirstUncompletedPage
    ? dataCollection.pages.indexOf(lastSavedOrFirstUncompletedPage)
    : dataCollection.pages.length - 1;
};

const useInitialActivePageIndex = ({
  dataCollection,
  leadId,
  configureQuoting
}: {
  dataCollection: DataCollection | undefined;
  leadId: number | undefined;
  configureQuoting: boolean;
}) => {
  const lastActiveStepRecord = useStepSubscription();
  const { key } = (leadId && lastActiveStepRecord[leadId]) || {};

  const initialActivePageIndexState = React.useState<number | undefined>(() =>
    evaluateInitialActivePageIndex({ dataCollection, configureQuoting, key })
  );
  const [initialActivePageIndex, setInitialActivePageIndex] = initialActivePageIndexState;

  React.useEffect(() => {
    if (dataCollection && !initialActivePageIndex) {
      setInitialActivePageIndex(evaluateInitialActivePageIndex({ dataCollection, configureQuoting, key }));
    }
  }, [dataCollection, configureQuoting, key, initialActivePageIndex, setInitialActivePageIndex]);

  return initialActivePageIndexState;
};

const useDataCollectionStepper = ({
  dataCollection,
  leadId,
  configureQuoting
}: {
  dataCollection: DataCollection | undefined;
  leadId: number | undefined;
  configureQuoting: boolean;
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [activePageIndex, setActivePageIndex] = useInitialActivePageIndex({ dataCollection, leadId, configureQuoting });

  const pages = React.useMemo(
    () =>
      dataCollection?.pages.map((page, index) => ({ ...page, index, active: index === activePageIndex })) ||
      DEFAULT_STEPS,
    [dataCollection, activePageIndex]
  );

  const { steps = DEFAULT_STEPS, setSteps, setLastActiveStepById } = useDataCollectionStepsState();

  const currentStep = steps.find(s => s.active);

  const isLastStep = currentStep && currentStep.key === steps.findLast(s => s.is_data_collection_enabled)?.key;

  const goToStep = React.useCallback(
    (key: DataCollectionNavigationProps['steps'][0]['key']) => {
      const stepToActivate = pages.find(page => page.key === key);
      if (stepToActivate) {
        setActivePageIndex(pages.indexOf(stepToActivate) || 0);
        navigate(`${GuidedSellingPathnames.DataCollection}${search}`);
      }
    },
    [pages, navigate, search, setActivePageIndex]
  );

  useStepsSync({ pages, goToStep, setSteps });
  useSaveLastStepById({ leadId, setLastActiveStepById, currentStep });

  const stepsRef = React.useRef(steps);
  stepsRef.current = steps;

  const pagesRef = React.useRef(pages);
  pagesRef.current = pages;

  const moveToOpportunity = (opportunity: IDataCollectionOpportunity) =>
    setActivePageIndex(() => {
      const uncompletedPageIndex = !opportunity.is_data_collection_enabled
        ? pagesRef.current.findIndex(page => doesPageIncludeOpportunity({ page, opportunity }))
        : pagesRef.current.findIndex(page => !page.is_completed && doesPageIncludeOpportunity({ page, opportunity }));

      const targetPageIndex =
        uncompletedPageIndex === -1
          ? pagesRef.current.findIndex(page => doesPageIncludeOpportunity({ page, opportunity }))
          : uncompletedPageIndex;

      return targetPageIndex;
    });

  return {
    steps: stepsRef.current as DataCollectionNavigationProps['steps'],
    moveForward: () =>
      setActivePageIndex(current =>
        pagesRef.current.findIndex(
          (page, index) => index > (current || 0) && !page.is_disqualified && page.is_data_collection_enabled
        )
      ),
    moveToPage: (pageType: IDataCollectionPageType) =>
      setActivePageIndex(pagesRef.current.findIndex(page => page.page_type === pageType)),
    moveToOpportunity,
    isLastStep
  };
};

export type DataCollectionStepper = ReturnType<typeof useDataCollectionStepper>;

export default useDataCollectionStepper;
