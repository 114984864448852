/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import useKeyPress from '../../../hooks/useKeyPress';
import colors from '../../../theme/colors';
import { borderRadius, spacings } from '../../../theme/variables';
import Button, { ButtonVariant } from '../buttons/Button';
import FlexBox from '../FlexBox';
import Heading, { HeadingProps } from '../Heading';
import { CheckIcon, CircleIcon, ClearIcon, VisibilityOffIcon } from '../icons';
import Paragraph from '../Paragraph';

export interface IBaseStep {
  onClick: () => void;
  active: boolean;
  index: number;
  completed: boolean;
  lost: boolean;
  disabled: boolean;
  label: string;
  key: number | string;
}
export interface IBaseStepperProps {
  steps: IBaseStep[];
  headline?: HeadingProps['children'];
}

const useAltNavigation = ({ step, showIndex }: { step: IBaseStep; showIndex: boolean }) => {
  const pressHandler = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && event.code.includes(`Digit${step.index + 1}`)) {
        step.onClick();
      }
    },
    [step]
  );

  React.useEffect(() => {
    if (showIndex) {
      window.addEventListener('keypress', pressHandler);
    }

    return () => {
      if (showIndex) {
        window.removeEventListener('keypress', pressHandler);
      }
    };
  }, [showIndex, pressHandler]);
};

const SeparateStep = ({ currentStep, showIndex }: { currentStep: IBaseStep; showIndex: boolean }) => {
  useAltNavigation({ step: currentStep, showIndex });

  return (
    <>
      {
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Button
            onClick={() => {
              currentStep.onClick();
            }}
            variant={ButtonVariant.Text}
            customCss={css`
              position: relative;
              display: flex;
              align-items: center;
              padding: ${spacings.px8}px;
              padding-right: ${spacings.px24}px;
              background-color: ${currentStep.active ? colors.grey5 : 'transparent'};
              border-radius: ${borderRadius}px;
              &:hover {
                background-color: ${colors.grey5};
              }
            `}
          >
            <div
              css={css`
                height: 100%;
                margin-right: ${spacings.px8}px;
                position: relative;
                bottom: 1px;
              `}
            >
              {currentStep.completed && !currentStep.disabled && <CheckIcon color={colors.statusGreen} />}
              {currentStep.lost && !currentStep.disabled && <ClearIcon color={colors.statusRed} />}
              {(currentStep.completed && !currentStep.disabled) || currentStep.lost || currentStep.disabled ? null : (
                <CircleIcon color={currentStep.active ? colors.azure50 : colors.grey30} />
              )}
              {currentStep.disabled && (
                <VisibilityOffIcon color={colors.grey60} width={spacings.px20} height={spacings.px20} />
              )}
            </div>
            <Paragraph
              customCss={css`
                text-align: start;
                white-space: nowrap;
                ${showIndex &&
                `&:after {
                  margin-left: 4px;
                  content: '${currentStep.index + 1}';
                }`}
              `}
              bold={currentStep.active}
            >
              {currentStep.label}
            </Paragraph>
          </Button>
        </div>
      }
    </>
  );
};

const BaseStepper = ({ steps, headline }: IBaseStepperProps): JSX.Element => {
  const altPressed = useKeyPress('Alt');
  return (
    <FlexBox columnDirection>
      {headline && (
        <Heading
          customCss={css`
            margin-bottom: ${spacings.px12}px;
          `}
        >
          {headline}
        </Heading>
      )}
      {steps.map(step => (
        <SeparateStep currentStep={step} key={step.key} showIndex={altPressed} />
      ))}
    </FlexBox>
  );
};
export default BaseStepper;
