import { useMutation } from '@tanstack/react-query';

import { IQuote } from '../../../interfaces';
import { PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { IQuoteCoverage, QuoteSource } from '../../../interfaces/IQuote';
import api from '../../../services/api';

export interface QuotePayload {
  source: QuoteSource;
  policy_type: PolicyType;
  carrier_key: string;
  premium: string | number;
  engagement_gid: string;
  original_quote_gid?: string;
  coverages?: {
    key: PolicyCoverageKey;
    limit_type?: string;
    limit_value?: IQuoteCoverage['limit_value'];
    limit_description?: IQuoteCoverage['limit_description'];
    deductible_type?: string;
    deductible_value?: IQuoteCoverage['deductible_value'];
    deductible_description?: IQuoteCoverage['deductible_description'];
  }[];
  assets: {
    gid: string;
    coverages?: {
      key: PolicyCoverageKey;
      limit_type?: string;
      limit_value?: IQuoteCoverage['limit_value'];
      limit_description?: IQuoteCoverage['limit_description'];
      deductible_type?: string;
      deductible_value?: IQuoteCoverage['deductible_value'];
      deductible_description?: IQuoteCoverage['deductible_description'];
    }[];
  }[];
  coinsureds?: { gid: string }[];
  coverages_tier?: string | null;
  payment_options?: IQuote['attributes']['payment_options'];
  agent_bridge_url?: string;
}

const createQuote = ({ personGid, data }: { personGid: string; data: QuotePayload }): Promise<{ quote: IQuote }> =>
  api.post(`/api/frontend/people/${personGid}/quotes`, {
    body: data
  });

export const useCreateQuote = () => useMutation({ mutationFn: createQuote });
