import * as Sentry from '@sentry/react';

import alert from '../../../components/core/Alert';
import { useAcceptDisclosures, usePolicyDisclosure } from '../../../queries/disclosures/useDisclosure';
const usePolicyDisclosureConsent = () => {
  const { data: policyDisclosure } = usePolicyDisclosure();
  const { mutateAsync: acceptPolicyDisclosure } = useAcceptDisclosures();

  const acceptConsent = async ({ personGid }: { personGid: string }) => {
    if (policyDisclosure) {
      return await acceptPolicyDisclosure({
        personGid,
        disclosures: [policyDisclosure]
      }).catch(err => {
        Sentry.captureException(err);
        alert({ title: 'Consent was not saved', message: 'Refresh the page and try again' }).error();
      });
    } else {
      alert({ title: 'Policy Disclosure not found', message: 'Refresh the page and try again' }).error();
    }
  };

  return acceptConsent;
};

export default usePolicyDisclosureConsent;
