/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import * as yup from 'yup';

import ButtonWithoutBorder from '../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import { SelectField } from '../../../components/core/forms/fields';
import FormError from '../../../components/core/forms/fields/FormError';
import Heading from '../../../components/core/Heading';
import { SwapIcon } from '../../../components/core/icons';
import { IRelatedPerson } from '../../../interfaces/IPerson';
import colors from '../../../theme/colors';
import { borderRadius, sizes } from '../../../theme/variables';

interface IMergePeople {
  cancelBtnHandler: () => void;
  confirmBtnHandler: ({ fromGid, toGid }: { fromGid: string; toGid: string }) => void;
  person: IRelatedPerson;
  personsForMerge: IRelatedPerson[];
}

const MergePeople = ({ person, personsForMerge, cancelBtnHandler, confirmBtnHandler }: IMergePeople) => {
  const personOptions = personsForMerge.map(person => ({ key: person.gid, value: person.name }));
  return (
    <BaseForm
      type="fullScreen"
      header={<Heading type="h3">Merge people</Heading>}
      submitText="Merge customers"
      controlsWidth={180}
      cancelHandler={cancelBtnHandler}
      onSubmit={values => confirmBtnHandler(values)}
      validationSchema={yup.object().shape({
        custom: yup.string().test({
          test: (_custom, { parent: { fromGid, toGid } }) => fromGid && toGid && fromGid !== toGid,
          message: "It's required to choose two persons and they should not match"
        })
      })}
      initialValues={{
        fromGid: person.gid,
        toGid: personOptions.length === 2 ? personOptions.filter(option => option.key !== person.gid)[0]!.key : '',
        custom: ''
      }}
      renderForm={({ values, setValues, errors, touched }) => {
        return (
          <FlexBox columnDirection>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <SelectField
                  id="fromGid"
                  name="fromGid"
                  key="fromGid"
                  label="From"
                  options={personOptions}
                  preserveErrorSpace={false}
                  required
                />
              </Grid>
              <Grid item alignSelf="flex-end" textAlign="center">
                <ButtonWithoutBorder
                  data-testid="swap-values"
                  onClick={() => setValues(current => ({ ...current, fromGid: values.toGid, toGid: values.fromGid }))}
                  customCss={css`
                    border: solid 2px ${colors.azure50};
                    border-radius: ${borderRadius}px;
                    height: ${sizes.formsHeight}px;
                  `}
                >
                  <SwapIcon />
                </ButtonWithoutBorder>
              </Grid>
              <Grid item xs={2}>
                <SelectField
                  id="toGid"
                  name="toGid"
                  key="toGid"
                  label="To"
                  options={personOptions}
                  preserveErrorSpace={false}
                  required
                />
              </Grid>
            </Grid>
            <Field
              css={css`
                display: none;
              `}
              id="custom"
              name="custom"
              type="text"
            />
            <FormError id="custom" hasError={!!(errors.custom && touched.custom)} error={errors.custom} />
          </FlexBox>
        );
      }}
    />
  );
};

export default MergePeople;
