import { ILender } from '../../../interfaces';
import { REQUIRED_MESSAGE } from '../../../utils/yupRules';

const PHONE_EMAIL_ERROR_MESSAGE = 'Please, fill phone or email field';

export const buildVerifiedLendersOptions = (verifiedLenders: ILender[] | undefined) =>
  verifiedLenders?.map(({ gid, name }) => ({ key: gid, value: name })) || [];

export const buildCompanyOptions = (
  verifiedLendersOptions: Record<string, string>[],
  currentCompany: ILender | null | undefined
) => {
  const currentCompanyOption =
    !currentCompany || currentCompany.verified ? [] : [{ key: currentCompany.gid, value: currentCompany.name }];

  return [...currentCompanyOption, ...verifiedLendersOptions];
};

export const officerValidation = ({
  officer_gid,
  officer_first_name,
  officer_last_name,
  officer_email,
  officer_phone
}: {
  officer_gid?: string;
  officer_first_name?: string;
  officer_last_name?: string;
  officer_email?: string;
  officer_phone?: string;
}) => {
  if (officer_gid || officer_first_name || officer_last_name || officer_email || officer_phone) {
    return {
      ...(officer_first_name ? {} : { officer_first_name: REQUIRED_MESSAGE }),
      ...(officer_last_name ? {} : { officer_last_name: REQUIRED_MESSAGE }),
      ...(!officer_email && !officer_phone
        ? { officer_email: PHONE_EMAIL_ERROR_MESSAGE, officer_phone: PHONE_EMAIL_ERROR_MESSAGE }
        : {})
    };
  }

  return {};
};

export const processorValidation = ({
  processor_gid,
  processor_first_name,
  processor_last_name,
  processor_email,
  processor_phone
}: {
  processor_gid?: string;
  processor_first_name?: string;
  processor_last_name?: string;
  processor_email?: string;
  processor_phone?: string;
}) => {
  if (processor_gid || processor_first_name || processor_last_name || processor_email || processor_phone) {
    return {
      ...(processor_first_name ? {} : { processor_first_name: REQUIRED_MESSAGE }),
      ...(processor_last_name ? {} : { processor_last_name: REQUIRED_MESSAGE }),
      ...(!processor_email && !processor_phone
        ? { processor_email: PHONE_EMAIL_ERROR_MESSAGE, processor_phone: PHONE_EMAIL_ERROR_MESSAGE }
        : {})
    };
  }

  return {};
};
