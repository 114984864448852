/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import {
  BoxCss,
  boxCss,
  boxCssPropsKeys,
  ContainerSize,
  containerSizeCss,
  containerSizeKeys,
  spacingKeys,
  Spacings,
  spacingsCss
} from '../../theme/variables';

export interface IContainer extends React.HTMLProps<HTMLDivElement>, Spacings, ContainerSize, BoxCss {
  customCss?: SerializedStyles | SerializedStyles[];
  innerRef?: any;
}

const Container = ({ innerRef, children, customCss, ...rest }: IContainer) => {
  const spacingProps = pick(spacingKeys, rest);
  const containerSizeProps = pick(containerSizeKeys, rest);
  const boxCssProps = pick(boxCssPropsKeys, rest);
  const restOfProps = omit([...spacingKeys, ...containerSizeKeys, ...boxCssPropsKeys], rest);

  return (
    <div
      {...restOfProps}
      ref={innerRef}
      css={css`
        ${spacingsCss(spacingProps)};
        ${containerSizeCss(containerSizeProps)};
        ${boxCss(boxCssProps)};
        ${customCss}
      `}
    >
      {children}
    </div>
  );
};

export default Container;
