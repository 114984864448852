import { css } from '@emotion/react';

export const globalTaskEditorStyle = css`
  .task-editor {
    .row {
      transition: opacity 0.3s;
    }

    .checkbox-container {
      display: flex;
      align-items: center;
      margin-top: 12px;
    }

    .rc-time-picker {
      .rc-time-picker-input {
        font-size: 1rem;
        border: 0;
        outline: none;
      }

      .rc-time-picker-clear {
        top: 50%;
        transform: translateY(-50%);

        &:focus {
          outline: none;
        }
      }

      .rc-time-picker-clear-icon {
        display: block;

        &::after {
          display: block;
          font-weight: 700;
          font-size: 1.3125rem;
          line-height: 0.9;
          content: '×';
        }
      }
    }
  }
`;
