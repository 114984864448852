import { useLocation, useNavigate } from 'react-router-dom';

import { ScalesIcon, SecurePaymentIcon, TodoIcon, UserVoiceIcon } from '../../../../components/core/icons';
import { isISR } from '../../../../services/authInfo';
import { GuidedSellingPathnames } from '../../navigation';
import MenuItem from './MenuItem';

interface ICandidateNavigation {
  menuOpened: boolean;
}

const CandidateNavigation = ({ menuOpened }: ICandidateNavigation) => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const isUserISR = isISR();

  return (
    <>
      <MenuItem
        IconComponent={UserVoiceIcon}
        label="Establish rapport"
        onClick={() => navigate(`${GuidedSellingPathnames.ScoutingReport}${search}`)}
        active={true}
        menuOpened={menuOpened}
        completed={false}
        disabled={false}
      />

      <MenuItem
        IconComponent={TodoIcon}
        label="Data collection"
        onClick={() => {}}
        active={false}
        menuOpened={menuOpened}
        completed={false}
        disabled={true}
      />

      {!isUserISR && (
        <>
          <MenuItem
            IconComponent={ScalesIcon}
            label="Quoting"
            onClick={() => {}}
            active={false}
            menuOpened={menuOpened}
            completed={false}
            disabled={true}
          />

          <MenuItem
            IconComponent={SecurePaymentIcon}
            label="Finalize"
            onClick={() => {}}
            active={false}
            menuOpened={menuOpened}
            completed={false}
            disabled={true}
          />
        </>
      )}
    </>
  );
};

export default CandidateNavigation;
