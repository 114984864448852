/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import { spacings } from '../../theme/variables';
import PersonSearch from './ScoutingReport/PersonSearch';

const Layout = ({
  header,
  localNavigation,
  content,
  tabs,
  onSearch
}: {
  header: React.ReactNode;
  localNavigation?: React.ReactNode;
  content: React.ReactNode;
  tabs: React.ReactNode;
  onSearch?: (searchBy?: string, term?: string) => void;
}) => {
  return (
    <div
      id="main-container"
      css={css`
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: auto 1fr 1fr auto;
        grid-template-rows: auto 1fr;
        gap: 0px 0px;
        grid-template-areas:
          'header header header header'
          'local_navigation content content tabs';

        height: 100%;
      `}
    >
      <div
        css={css`
          grid-area: header;
        `}
      >
        {header}
      </div>

      {/* This container needs to stay as flex-box in order for children to occupy whole height */}
      <FlexBox
        css={css`
          grid-area: local_navigation;
        `}
      >
        {localNavigation}
      </FlexBox>

      <div
        css={css`
          grid-area: content;
          overflow: auto;
        `}
      >
        {onSearch && (
          <Container ph={spacings.px24} pv={spacings.px12}>
            <PersonSearch onSearch={onSearch} />
          </Container>
        )}
        {content}
      </div>

      {/* This container needs to stay as flex-box in order for children to occupy whole height */}
      <FlexBox
        css={css`
          grid-area: tabs;
          overflow: hidden;
        `}
      >
        {tabs}
      </FlexBox>
    </div>
  );
};

export default Layout;
