import {
  AncientGateLineIcon,
  CompassesIcon,
  DiscountIcon,
  HammerLineIcon,
  HomeLineIcon,
  PaintBrushLineIcon,
  SafetyIcon,
  TempHotLineIcon
} from '../../../../../components/core/icons';

const HomeSectionIcon = ({ sectionKey }: { sectionKey: string }) => {
  switch (sectionKey) {
    case 'rooms':
      return <PaintBrushLineIcon />;
    case 'home_design':
      return <CompassesIcon />;
    case 'garage':
      return <HomeLineIcon />;
    case 'roof':
      return <AncientGateLineIcon />;
    case 'updates':
      return <HammerLineIcon />;
    case 'heating':
      return <TempHotLineIcon />;
    case 'safety':
      return <SafetyIcon />;
    case 'discounts':
      return <DiscountIcon />;
    default:
      return null;
  }
};

export default HomeSectionIcon;
