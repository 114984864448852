/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { IPaperTrailVersion } from '../../interfaces';
import colors from '../../theme/colors';
import { dateTimeFormatter, userTimezone } from '../../utils/formatter';

const PaperTrailItem = ({ version }: { version: IPaperTrailVersion }) => {
  return (
    <tr
      key={version.id}
      css={css`
        font-family: monospace;
        font-size: 12px;
        word-break: break-word;

        td {
          padding: 10px;
        }

        .ins {
          color: ${colors.statusGreen};
        }

        .del {
          color: ${colors.statusRed};
          text-decoration-line: line-through;
        }
      `}
    >
      <td>{version.author_name}</td>
      <td>
        {version.item_type}.{version.event}
      </td>
      <td className="fs-mask">
        {version.changes &&
          version.changes.map((change, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={`${change.diff}-${index}`} className={change.diff}>
              {change.content}
            </div>
          ))}
      </td>
      <td>{`${dateTimeFormatter(version.created_at, 'MM/DD/YYYY h:mm:ss:SSS a')} ${userTimezone.abbreviation}`}</td>
    </tr>
  );
};

export default PaperTrailItem;
