import { useFormikContext } from 'formik';
import * as React from 'react';

// Make sure to disable validateOnBlur on form when using this
const AutoSaveForm = ({
  autoSave,
  allowSavingNotValid
}: {
  autoSave: (values: unknown, setSubmitting: (v: boolean) => void) => void;
  allowSavingNotValid?: boolean;
}) => {
  const { values, setSubmitting, isValid, isValidating } = useFormikContext();

  React.useEffect(() => {
    if (!isValidating && (isValid || allowSavingNotValid)) {
      setSubmitting(true);
      autoSave(values, setSubmitting);
    }
  }, [autoSave, values, setSubmitting, isValid, isValidating, allowSavingNotValid]);

  return null;
};

export default AutoSaveForm;
