/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { IAddress } from '../../interfaces';
import colors from '../../theme/colors';
import Container, { IContainer } from './Container';
import Copy from './Copy';
import Text, { TextProps } from './Text';

interface ICopyableAddress extends IContainer {
  address: Partial<IAddress>;
  textType?: TextProps['type'];
  textColor?: TextProps['color'];
}

const pseudoComaCss = css`
  &:after {
    content: ',';
  }
`;

const CopyableAddress = ({
  address: { line1, line2, city, state, zip },
  textType = 'default',
  textColor = colors.black,
  ...rest
}: ICopyableAddress) => (
  <Container {...rest}>
    {line1 && (
      <Copy className="fs-mask" value={line1} withIcon={false} type={textType}>
        <Text type={textType} color={textColor} customCss={line2 || city || state || zip ? pseudoComaCss : undefined}>
          {line1}
        </Text>
      </Copy>
    )}
    {line2 && (
      <Copy className="fs-mask" value={line2} withIcon={false} type={textType}>
        <Text type={textType} color={textColor} customCss={city || state || zip ? pseudoComaCss : undefined}>
          {line2}
        </Text>
      </Copy>
    )}
    {city && (
      <Copy className="fs-mask" value={city} withIcon={false} type={textType}>
        <Text type={textType} color={textColor} customCss={state || zip ? pseudoComaCss : undefined}>
          {city}
        </Text>
      </Copy>
    )}
    {state && (
      <Copy className="fs-mask" value={state} withIcon={false} type={textType}>
        <Text type={textType} color={textColor} customCss={zip ? pseudoComaCss : undefined}>
          {state}
        </Text>
      </Copy>
    )}
    {zip && (
      <Copy className="fs-mask" value={zip} withIcon={false} type={textType}>
        <Text type={textType} color={textColor}>
          {zip}
        </Text>
      </Copy>
    )}
  </Container>
);

export default CopyableAddress;
