/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import * as yup from 'yup';

import FormModal from '../../components/core/FormModal';
import { CheckboxField, InputField, SelectField } from '../../components/core/forms/fields';
import { ModalSize } from '../../components/core/Modal';
import { CoverageType } from '../../interfaces/ICoverage';
import { CoverageMapping } from '../../interfaces/ICoverageMapping';
import { updateCoverageMapping } from '../../queries/coverages_mappings/useCoveagesMappings';
import { capitalize } from '../../utils/formatter';
import { snakeCaseField } from '../../utils/yupRules';

const EditMappingModal = ({
  coverageMapping,
  cancelHandler,
  confirmHandler
}: {
  coverageMapping: CoverageMapping;
  cancelHandler: () => void;
  confirmHandler: () => void;
}) => (
  <FormModal
    returnFocusAfterClose={false}
    size={ModalSize.medium}
    validationSchema={yup.object().shape({
      mapped_code: snakeCaseField
    })}
    initialValues={{
      coverage_mapping_id: coverageMapping.id,
      mapped_code: coverageMapping.mapped_code || '',
      mapped_description: coverageMapping.mapped_description || '',
      mapped_category: coverageMapping.mapped_category || '',
      ignored: coverageMapping.ignored || ''
    }}
    confirmHandler={values => updateCoverageMapping(values).then(() => confirmHandler())}
    cancelHandler={() => cancelHandler()}
    confirmText="Save"
    title="Edit mapping"
    renderForm={() => (
      <div
        css={css`
          margin: 16px;
        `}
      >
        <InputField label="Mapped code (snake case)" id="mapped_code" name="mapped_code" />
        <InputField label="Mapped description" id="mapped_description" name="mapped_description" />
        <SelectField
          label="Mapped category"
          id="mapped_category"
          name="mapped_category"
          options={Object.values(CoverageType).map(kind => ({ key: kind, value: capitalize(kind) }))}
        />
        <CheckboxField label="Ignored" id="ignored" name="ignored" />
      </div>
    )}
  />
);

export default EditMappingModal;
