import * as React from 'react';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { spacings } from '../../../theme/variables';
import ContentSection from '../ContentSection';

const NonSyncedCoverageSection = ({
  heading,
  coverageName,
  coverageValue
}: {
  heading: string;
  coverageName: string;
  coverageValue: string;
}) => {
  return (
    <ContentSection heading={heading} testId="non-synced-coverage-section">
      <Container mt={spacings.px20}>
        <FlexBox justifySpaceBetween pt={spacings.px4} pb={spacings.px4} mb={spacings.px8} borderBottom>
          <FlexBox alignItemsCenter>
            <Text type="large">{coverageName}</Text>
          </FlexBox>
          <Text type="large">{coverageValue}</Text>
        </FlexBox>
      </Container>
    </ContentSection>
  );
};

export default NonSyncedCoverageSection;
