/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import QuestionVerificationStatusIcon from '../../../../components/core/QuestionVerificationStatusIcon';
import DatapointKey from '../../../../interfaces/IDatapoint';
import { spacings } from '../../../../theme/variables';
// eslint-disable-next-line max-len
import { useVehicleProfileAnswersWithVerificationAnswers } from '../_hooks/useVehicleProfileAnswersWithVerificationAnswers';

const EnhanceWithVerificationStatus = ({
  answersWithVerificationStatus,
  children,
  disabled,
  entityGid,
  answerKey
}: {
  answersWithVerificationStatus: ReturnType<typeof useVehicleProfileAnswersWithVerificationAnswers>;
  children: React.ReactNode;
  disabled: boolean;
  entityGid: string;
  answerKey: DatapointKey;
}) => {
  const verificationStatus = answersWithVerificationStatus[entityGid]?.[answerKey]?.verificationStatus;

  return (
    <FlexBox alignItemsBaseline>
      {verificationStatus && (
        <Container>
          <QuestionVerificationStatusIcon
            verificationStatus={verificationStatus}
            disabled={disabled}
            css={css`
              margin-left: -${spacings.px20}px;
            `}
          />
        </Container>
      )}
      {children}
    </FlexBox>
  );
};

export default EnhanceWithVerificationStatus;
