import Text, { TextProps } from '../../../../../components/core/Text';
import { spacings } from '../../../../../theme/variables';

interface EmptyAnswerProps {
  textType?: TextProps['type'];
}

const EmptyAnswer = ({ textType }: EmptyAnswerProps) => (
  // Spacing is needed to compensate for <Copy/> component internal padding
  <Text type={textType} pl={spacings.px4}>
    —
  </Text>
);

export default EmptyAnswer;
