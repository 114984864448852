import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as yup from 'yup';

import { CITY, LINE1, LINE2, STATE, ZIP } from '../../constants/addressForm';
import { useToggle } from '../../hooks';
import { IAddress, IPerson, IPersonAddress, IVehicle } from '../../interfaces';
import useAutoSearch from '../../queries/auto_search_entities/useAutoSearch';
import { PERSON_QUERY_KEY, useUpdatePerson } from '../../queries/people/usePerson';
import usePersonAddresses from '../../queries/people/usePersonAddresses';
import usePrefillVehiclesAndDrivers, {
  IPrefilledDriver,
  IPrefilledVehicle,
  IPrefilledVehiclesAndDrivers
} from '../../queries/people/usePrefillVehiclesAndDrivers';
import analytics from '../../services/analytics';
import { baseVehicleValidation, minDOBField, REQUIRED_MESSAGE, requiredAddressSchema } from '../../utils/yupRules';

export type ManualVehicle = {
  address: Omit<IAddress, 'id' | 'full'>;
  auto: {
    year: number;
    make: string;
    model: string;
    submodel: string;
    vin?: string;
  };
  gid: string;
};

const AutoSearchProvider = ({
  children,
  vehicle
}: {
  children: (v: ReturnType<typeof useAutoSearch>) => JSX.Element;
  vehicle?: IVehicle;
}) => children(useAutoSearch(vehicle));

const initialValues = ({
  dateOfBirth,
  prefilledAddress
}: {
  dateOfBirth?: IPerson['date_of_birth'];
  prefilledAddress?: IPersonAddress;
}) => ({
  vehicles: [] as ManualVehicle[],
  date_of_birth: dateOfBirth || '',
  prefilled_vehicles_garage_address: {
    [LINE1]: prefilledAddress?.[LINE1] || '',
    [LINE2]: prefilledAddress?.[LINE2] || '',
    [CITY]: prefilledAddress?.[CITY] || '',
    [STATE]: prefilledAddress?.[STATE] || '',
    [ZIP]: prefilledAddress?.[ZIP] || ''
  },
  prefilled_vehicles: [] as { confirmed: boolean; vehicle: IPrefilledVehicle }[],
  prefilled_drivers: [] as { confirmed: boolean; driver: IPrefilledDriver }[],
  vehicle_presence: ''
});

const validationSchema = ({ withPrefillValidation }: { withPrefillValidation?: boolean }) =>
  yup.object().shape({
    vehicles: yup.array().of(
      yup.object().shape({
        address: requiredAddressSchema,
        auto: yup.object().shape(baseVehicleValidation)
      })
    ),
    ...(withPrefillValidation
      ? {
          prefilled_vehicles_garage_address: requiredAddressSchema,
          date_of_birth: minDOBField().required(REQUIRED_MESSAGE)
        }
      : {}),
    vehicle_presence: yup
      .string()
      .when(
        ['vehicles', 'prefilled_vehicles', 'prefilled_drivers'],
        ([vehicles, prefilled_vehicles, prefilled_drivers], schema) => {
          const hasVehicles = Boolean(
            vehicles.length ||
              (prefilled_vehicles as ReturnType<typeof initialValues>['prefilled_vehicles']).filter(
                ({ confirmed }) => confirmed
              ).length
          );
          const hasDrivers = Boolean(
            (prefilled_drivers as ReturnType<typeof initialValues>['prefilled_drivers']).filter(
              ({ confirmed }) => confirmed
            ).length
          );

          return hasVehicles || hasDrivers
            ? schema
            : schema.required('At least one vehicle or driver should be added or selected');
        }
      )
  });

const useVehiclesForm = ({
  personGid,
  leadGid,
  withFenrisCollapse = true,
  uiSource
}: {
  personGid: string;
  leadGid: string | undefined;
  withFenrisCollapse?: boolean;
  uiSource?: string;
}) => {
  const queryClient = useQueryClient();
  const [showPrefill, toggleShowPrefill] = useToggle(true);
  const [prefilledVehiclesAndDrivers, setPrefilledVehiclesAndDrivers] = React.useState<IPrefilledVehiclesAndDrivers>();
  const { data: personAddresses } = usePersonAddresses(personGid);
  const { mutate: prefill, isPending: isPrefillingVehiclesAndDrivers } = usePrefillVehiclesAndDrivers({
    onSuccess: data => setPrefilledVehiclesAndDrivers(data)
  });
  const { mutate: updatePerson, isPending: isUpdatingPerson } = useUpdatePerson({
    onSuccess: data => {
      analytics.track('Fenris pre-fill triggered', { place: uiSource, lead_gid: leadGid, person_gid: personGid });
      prefill({ personGid, removeDuplicates: true });
      queryClient.setQueryData([PERSON_QUERY_KEY, personGid], data.person);
    }
  });

  return {
    showPrefill,
    toggleShowPrefill,
    updatePerson,
    isUpdatingPerson,
    isPrefillingVehiclesAndDrivers,
    prefilledVehiclesAndDrivers,
    personAddresses,
    withFenrisCollapse
  };
};

export { AutoSearchProvider, initialValues, useVehiclesForm, validationSchema };
