import { useNavigate } from 'react-router-dom';

import Button from '../../../../components/core/buttons/Button';
import FlexBox from '../../../../components/core/FlexBox';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import useFindOrCreateLead from '../../../../queries/people/person_leads/useFindOrCreateLead';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import { spacings } from '../../../../theme/variables';
import guidedSellingExperienceNavigation, { GuidedSellingPathnames } from '../../navigation';

const CandidateFooter = () => {
  const navigate = useNavigate();

  const { personGid, candidateGid, isLoading: isLoadingCall, insuranceCategory } = useGuidedSellingExperienceContext();

  const { mutateAsync: findOrCreateLead, isPending: isCreatingLead } = useFindOrCreateLead();

  if (!personGid) {
    return null;
  }

  return (
    <FlexBox pv={spacings.px24} ph={spacings.px24} gap={spacings.px12} justifyRight border>
      <Button
        data-testid="start-data-collection-button"
        loading={isCreatingLead || isLoadingCall}
        onClick={async () => {
          const { lead } = await findOrCreateLead({
            personGid,
            candidateGid,
            assigneeId: authInfo.currentUserId!,
            insuranceCategory
          });

          navigate(
            guidedSellingExperienceNavigation.forLead({
              lead_gid: lead.gid,
              path: GuidedSellingPathnames.DataCollection
            }),
            { replace: true }
          );

          analytics.track('Data collection step submitted', {
            person_gid: personGid,
            lead_gid: lead.gid,
            step_key: 'summary',
            opportunities_count: null,
            disabled_opportunities_count: null
          });
        }}
      >
        Collect customer profile
      </Button>
    </FlexBox>
  );
};

export default CandidateFooter;
