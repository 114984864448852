/** @jsxImportSource @emotion/react */
import { Field, FieldProps } from 'formik';

import { spacings } from '../../../../theme/variables';
import MultiSelect from '../../inputs/MultiSelect';
import FormDescription from './FormDescription';
import FormError from './FormError';
import LabeledInput from './LabeledInput';
import { SelectFieldProps } from './SelectField';

interface MultiSelectFieldProps extends SelectFieldProps {
  valuesSeparator?: boolean;
}

const MultiSelectField = ({
  label,
  id,
  name,
  required,
  inline,
  secondary = false,
  preserveErrorSpace = true,
  customLabelCss,
  labelSize,
  inputSize,
  description,
  testId,
  verificationStatus,
  ...rest
}: MultiSelectFieldProps): JSX.Element => {
  return (
    <LabeledInput
      id={id}
      required={required}
      label={label}
      inline={inline}
      secondary={secondary}
      disabled={rest.disabled}
      customCss={customLabelCss}
      labelSize={labelSize}
      inputSize={inputSize}
      verificationStatus={verificationStatus}
    >
      <Field name={name}>
        {({ field, meta, form }: FieldProps) => {
          const hasError = meta.error && meta.touched;

          return (
            <>
              <MultiSelect
                {...field}
                {...rest}
                controlTestId={testId}
                disabled={rest.disabled || form.status === 'disabled'}
                onBlur={() => form.setFieldTouched(name, true)}
                inputId={id}
                inline={inline}
                inputSize={inputSize}
                hasError={!!hasError}
                aria-invalid={!!hasError}
                aria-errormessage={`${id}-error`}
              />
              {inline && !!hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />}
              {!inline && (preserveErrorSpace || !!hasError) && (
                <FormError id={id} hasError={!!hasError} error={meta.error} />
              )}
              {description && <FormDescription description={description} mt={spacings.px4} />}
            </>
          );
        }}
      </Field>
    </LabeledInput>
  );
};

export default MultiSelectField;
