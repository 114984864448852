import moment from 'moment';
import * as yup from 'yup';

import { IPersonAsset } from '../../interfaces/IPersonAsset';
import { IMaticPolicy, PolicyStatus } from '../../interfaces/IPolicy';
import { assetsForPolicyType, doesPolicyTypeRequireAsset, isOnlyOneAssetSupported } from '../../interfaces/IPolicyType';
import { INTERNAL_DATE_FORMAT } from '../../utils/formatter';

export const REQUIRED_TEXT = 'Please, fill in the field';
export const TOP_PREMIUM_AMOUNT = 50_000;

// -----------------------------------------------------------------------------------------
// Policy statuses

export const isStatusLeadsToCancellation = (status: PolicyStatus): boolean =>
  [PolicyStatus.CANCELLED, PolicyStatus.REWRITTEN, PolicyStatus.NOT_TAKEN, PolicyStatus.NON_RENEWAL].includes(status);

// -----------------------------------------------------------------------------------------
// Policy term

export const getPredefinedPeriod = (policy: { effective_date: string; expiration_date: string }): number => {
  if (!policy.effective_date || !policy.expiration_date) {
    return 0;
  }

  return moment(policy.expiration_date).diff(moment(policy.effective_date), 'months');
};

export const isValidEffectiveDate = (values: { effective_date: string; sale_date: string; status: string }): boolean =>
  moment(values.effective_date) < moment(values.sale_date);

// -----------------------------------------------------------------------------------------
// Assets

export const getAssetGids = (policy: Pick<IMaticPolicy, 'policy_type' | 'assets'>): string[] =>
  (policy?.assets || []).map(({ gid }) => gid);

export const getAssetGid = (policy: Pick<IMaticPolicy, 'policy_type' | 'assets'>): string | '' =>
  getAssetGids(policy)[0] || '';

export const getFirstAssetState = (policy: IMaticPolicy, assets: IPersonAsset[]): string => {
  if (policy.state) {
    return policy.state;
  }

  if (doesPolicyTypeRequireAsset(policy.policy_type)) {
    const [preffiledAsset] = assetsForPolicyType(policy.policy_type, assets);

    return preffiledAsset?.address?.state || '';
  }

  return '';
};

export const assetGidsForSubmit = (values: Record<string, any>): any[] => {
  const assetGids = isOnlyOneAssetSupported(values.policy_type) ? [values.asset_gid] : values.asset_gids;
  return assetGids.filter(Boolean);
};

// yup rules

export const requiredDateForStatus = () =>
  yup
    .date()
    .test('Date format', 'Please enter date in MM/DD/YYYY format', (_value, { originalValue }) => {
      if (!originalValue) {
        return true;
      }

      return !!(originalValue && originalValue.length === 10);
    })
    .when('status', ([status], schema) =>
      isStatusLeadsToCancellation(status) ? schema.required(REQUIRED_TEXT) : schema
    )
    .min(moment().subtract(10, 'years').format(INTERNAL_DATE_FORMAT), "Date can't be more than 10 years before today")
    .max(moment().add(10, 'years').format(INTERNAL_DATE_FORMAT), "Date can't be more than 10 years from today");

export const requiredNewPolicyTerm = () =>
  yup
    .date()
    .required(REQUIRED_TEXT)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }) => !!(originalValue && originalValue.length === 10)
    )
    .max(
      moment().add(6, 'months').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 6 months from today"
    )
    .min(
      moment().subtract(60, 'days').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 60 days before today"
    );

export const requiredExistingPolicyTerm = () =>
  yup
    .date()
    .required(REQUIRED_TEXT)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }) => !!(originalValue && originalValue.length === 10)
    )
    .max(
      moment().add(6, 'months').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 6 months from today"
    )
    .min(
      moment().subtract(10, 'years').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 10 years before today"
    );

export const requiredForCancelStatus = yup
  .string()
  .when('status', ([status], schema) =>
    isStatusLeadsToCancellation(status) ? schema.required(REQUIRED_TEXT) : schema
  );

export const requiredForBoundOrActiveStatus = yup
  .string()
  .when('status', ([status], schema) =>
    PolicyStatus.BOUND === status || PolicyStatus.ACTIVE === status ? schema.required(REQUIRED_TEXT) : schema
  );
