import { isInsurableInterestVehicle, isRequirementEssential, PolicyType } from '../../../../interfaces/IPolicyType';
import usePersonAllGroupedPolicies from '../../../../queries/people/person_policies/usePersonAllGroupedPolicies';
import PoliciesTableSkeleton from '../../_components/PoliciesTableSkeleton';
import PoliciesGroups from './_components/PoliciesGroups';

const VehicleEssentialPolicies = ({ personGid }: { personGid: string }) => {
  const { data: personPoliciesGroups, isSuccess } = usePersonAllGroupedPolicies({
    personGid,
    filters: {
      policy_types: Object.values(PolicyType).filter(
        policyType => isInsurableInterestVehicle(policyType) && isRequirementEssential(policyType)
      ),
      show_predicted_policies: true,
      similar_policy_types: true,
      deduplicate_by_expiration_date: true
    }
  });

  if (!isSuccess) {
    return <PoliciesTableSkeleton />;
  }

  return <PoliciesGroups personPoliciesGroups={personPoliciesGroups} />;
};

export default VehicleEssentialPolicies;
