/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import FormModal from '../../../../components/core/FormModal';
import { CheckboxField } from '../../../../components/core/forms/fields';
import { ModalSize } from '../../../../components/core/Modal';
import Text from '../../../../components/core/Text';
import { IOpportunity } from '../../../../interfaces/IOpportunity';
import { findPolicyTypeLabel } from '../../../../interfaces/IPolicyType';
import { setOpportunityAsPrimary } from '../../../../queries/leads/opportunities/useLeadOpportunities';
import colors from '../../../../theme/colors';

const EditOpportunityModal = ({
  leadId,
  editedOpportunity,
  cancelHandler,
  confirmHandler
}: {
  leadId: number;
  editedOpportunity: IOpportunity;
  cancelHandler: () => void;
  confirmHandler: () => void;
}) => (
  <FormModal
    returnFocusAfterClose={false}
    size={ModalSize.medium}
    initialValues={{ edited_opportunity_is_primary: editedOpportunity.primary }}
    confirmHandler={({ edited_opportunity_is_primary }) =>
      edited_opportunity_is_primary
        ? setOpportunityAsPrimary({ leadId, opportunityId: editedOpportunity.id }).then(() => confirmHandler())
        : cancelHandler()
    }
    cancelHandler={() => cancelHandler()}
    confirmText="Save"
    title={`Edit opportunity - ${findPolicyTypeLabel(editedOpportunity.policy_type)}`}
    renderForm={() => (
      <div
        css={css`
          margin: 16px;
        `}
      >
        <CheckboxField
          label="Make it a primary opportunity"
          id="edited_opportunity_is_primary"
          name="edited_opportunity_is_primary"
          disabled={editedOpportunity.primary}
        />
        <Text type="small" color={colors.grey60}>
          Other opportunities will be marked as optional
        </Text>
      </div>
    )}
  />
);

export default EditOpportunityModal;
