import { useMutation, UseQueryResult } from '@tanstack/react-query';
import React, { useRef } from 'react';

import Button from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import CustomerEditor from '../../../components/CustomerEditor/CustomerEditor';
import { IPerson } from '../../../interfaces/IPerson';
import { updatePerson, UpdatePersonPayload } from '../../../queries/people/usePerson';
import usePersonDrivingRecord, {
  UpdateDrivingRecordPayload,
  updatePersonDrivingRecord
} from '../../../queries/people/usePersonDrivingRecord';
import { spacings } from '../../../theme/variables';
import CustomerProfileView from './CustomerProfileView';

type FormData = UpdatePersonPayload & UpdateDrivingRecordPayload;

const updateCustomer = ({ personGid, formData }: { personGid: string; formData: FormData }) => {
  const { accidents, claims, violations, ...updatePersonPayload } = formData;

  return updatePerson({ ...updatePersonPayload, gid: personGid }).then(() =>
    updatePersonDrivingRecord({
      personGid,
      drivingRecord: {
        accidents,
        claims,
        violations
      }
    })
  );
};
const useUpdateCustomer = (onSuccess: () => void) => useMutation({ mutationFn: updateCustomer, onSuccess });

const CustomerGeneral = ({ personQuery }: { personQuery: UseQueryResult<IPerson, any> }) => {
  const [editCustomerProfile, setEditCustomerProfile] = React.useState(false);
  const customerRef = useRef<any>();

  const { data: person, isLoading: isLoadingPerson, refetch: refetchPerson } = personQuery;
  const {
    data: drivingRecord,
    isLoading: isLoadingDrivingRecord,
    refetch: refetchDrivingRecord
  } = usePersonDrivingRecord(person?.gid);

  const { mutateAsync: updateCustomer, isPending: isUpdatingCustomer } = useUpdateCustomer(() => {
    refetchDrivingRecord();
    refetchPerson();
  });

  const updateCustomerCallback = () => {
    if (!customerRef.current.validate()) {
      return;
    }

    const customerFormParams = customerRef.current.getData() as FormData;
    return updateCustomer({ personGid: person!.gid, formData: customerFormParams }).then(() =>
      setEditCustomerProfile(false)
    );
  };

  if (isLoadingPerson || isLoadingDrivingRecord) {
    return null;
  }

  return (
    <Container fitParentWidth pb={spacings.px32}>
      <FlexBox pb={spacings.px8} alignItemsCenter justifySpaceBetween>
        <Text type="large" bold>
          Customer profile
        </Text>
        {editCustomerProfile && (
          <Button
            onClick={updateCustomerCallback}
            data-testid="save-customer-profile-button"
            loading={isUpdatingCustomer}
          >
            Save
          </Button>
        )}
        {!editCustomerProfile && (
          <Button
            onClick={event => {
              event.stopPropagation();
              setEditCustomerProfile(true);
            }}
            data-testid="edit-customer-profile-button"
          >
            Edit
          </Button>
        )}
      </FlexBox>
      {!editCustomerProfile && person && <CustomerProfileView person={person} />}

      {editCustomerProfile && (
        <CustomerEditor
          customer={person}
          drivingRecord={drivingRecord}
          ref={customerRef}
          updateCustomer={updateCustomerCallback}
          isUpdatingCustomer={isUpdatingCustomer}
        />
      )}
    </Container>
  );
};

export default CustomerGeneral;
