/** @jsxImportSource @emotion/react */
import * as React from 'react';

import FlexBox from '../../components/core/FlexBox';
import { HomeIcon, PrimaryHomeIcon, SecondaryHomeIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import { IOpportunity, IQuote } from '../../interfaces';
import { IProposal } from '../../interfaces/IProposal';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { useProposalContext } from './_hooks/useProposalContext';
import HomeQuoteForm from './HomeQuoteForm';
import HomeQuoteInfo from './HomeQuoteInfo';
import QuotesSelect from './QuotesSelect';

const HomeTitleIcon = ({ title }: { title: string }) => {
  if (title === 'Primary home') {
    return <PrimaryHomeIcon />;
  } else if (title === 'Second home') {
    return <SecondaryHomeIcon />;
  }
  return <HomeIcon />;
};

const HomeQuotes = ({
  opportunity,
  quotes,
  isFetchingQuotes,
  currentProposal
}: {
  opportunity: IOpportunity;
  quotes: IQuote[];
  currentProposal: IProposal | undefined;
  isFetchingQuotes?: boolean;
}) => {
  const [openQuoteEditor, setOpenQuoteEditor] = React.useState<boolean>(false);
  const getInitialSelectedQuote = () =>
    currentProposal?.proposal_options?.[0]?.quotes.find(
      quote => quote.attributes.asset_gids?.[0] === opportunity.assets?.[0]?.gid
    )?.gid;

  const [selectedQuoteKey, setSelectedQuoteKey] = React.useState(getInitialSelectedQuote() || '');
  const selectedQuote = quotes.find(quote => quote.gid === selectedQuoteKey);

  const { setQuotesForOpportunities } = useProposalContext();

  const opportunityId = opportunity.id;

  React.useEffect(() => {
    setQuotesForOpportunities(opportunityId, selectedQuoteKey);
  }, [opportunityId, selectedQuoteKey, setQuotesForOpportunities]);

  const showResetButton =
    currentProposal?.proposal_options?.[0]?.quotes && currentProposal?.proposal_options?.[0]?.quotes.length > 1;

  return (
    <FlexBox mv={spacings.px20} gap={spacings.px16}>
      <HomeTitleIcon title={opportunity.title} />
      <FlexBox columnDirection>
        <Paragraph>{opportunity.title}</Paragraph>
        <Paragraph color={colors.grey60}>{opportunity.assets?.[0]?.address.full}</Paragraph>
        <QuotesSelect
          quotes={quotes}
          selectId={opportunity.id.toString()}
          setOpenQuoteEditor={setOpenQuoteEditor}
          openQuoteEditor={openQuoteEditor}
          selectedQuoteKey={selectedQuoteKey}
          setSelectedQuoteKey={setSelectedQuoteKey}
          loading={isFetchingQuotes}
          showResetButton={showResetButton}
          disabled={!!currentProposal?.sent_at}
        />
        {openQuoteEditor && (
          <HomeQuoteForm
            policyType={opportunity.policy_type}
            setOpenQuoteEditor={setOpenQuoteEditor}
            quote={selectedQuote}
            assetGid={opportunity.assets?.[0]?.gid || ''}
            setSelectedQuoteKey={setSelectedQuoteKey}
            quotes={quotes}
            selectedQuoteKey={selectedQuoteKey}
          />
        )}
        {selectedQuoteKey && !openQuoteEditor && selectedQuote && (
          <HomeQuoteInfo
            setOpenQuoteEditor={setOpenQuoteEditor}
            quote={selectedQuote}
            disabled={!!currentProposal?.sent_at}
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default HomeQuotes;
