/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { Input, Label } from 'reactstrap';
import { v4 as uidGenerator } from 'uuid';

import { IMaticPolicy, IPerson } from '../../interfaces';
import { DocumentOwnerType } from '../../interfaces/IDocument';
import useFindAssignee from '../../queries/assignees/useFindAssignee';
import useCarrier from '../../queries/carriers/useCarrier';
import useDocumentTypes from '../../queries/document_types/useDocumentTypes';
import { useCreateDocument } from '../../queries/people/person_documents/usePersonDocuments';
import colors from '../../theme/colors';
import { Modal } from '../common';
import { DownloadIcon } from '../core/icons';
import generateDocxReport, { CarrierAddress } from '../PolicyList/generateDocxReport';
import {
  CONFIRM_TEXT,
  REPORT_IS_GENERATED_MESSAGE,
  REPORT_IS_NOT_GENERATED_MESSAGE,
  USE_OVERNIGHT_ADDRESS,
  USE_REMITTANCE_ADDRESS
} from './constants';
import { collectPolicyAssetsAddresses } from './helpers';

const GenerateInvoiceModal = ({
  handleOpened,
  person,
  policy
}: {
  handleOpened: (value: boolean) => void;
  person: IPerson;
  policy: IMaticPolicy;
}): JSX.Element => {
  const [reportCreated, setReportCreated] = React.useState(false);
  const formRef = React.useRef<React.ElementRef<'form'>>(null);
  const reportTitle = `${person.first_name}_${person.last_name}_${policy.policy_number}_invoice.docx`;

  const { data: docTypes } = useDocumentTypes();
  const { data: policyAgent } = useFindAssignee(policy.agent_id);
  // This is needed to load carrier remittance and overnight address
  const { data: policyCarrier } = useCarrier(policy.carrier.id);

  const { mutate: createDocument } = useCreateDocument({ attachDocs: false });
  return (
    <Modal
      open={true}
      title={reportCreated ? REPORT_IS_GENERATED_MESSAGE : REPORT_IS_NOT_GENERATED_MESSAGE}
      confirmText={CONFIRM_TEXT}
      confirmHandler={
        reportCreated
          ? () => {}
          : () => {
              generateDocxReport({
                title: reportTitle,
                person,
                policy,
                carrier: {
                  address:
                    policyCarrier?.[
                      (formRef.current!.elements as any).address_type.value as
                        | CarrierAddress.OvernightAddress
                        | CarrierAddress.RemittanceAddress
                    ],
                  name: policyCarrier?.name
                },
                agentName: policyAgent?.name,
                assetsAddresses: collectPolicyAssetsAddresses(policy)
              }).then(blob => {
                const invoiceDocTypeId = docTypes?.document_types?.find(
                  docment_type => docment_type.key === 'invoice'
                )?.id;

                if (invoiceDocTypeId) {
                  createDocument({
                    personGid: person.gid,
                    request: {
                      file: new File([blob], reportTitle),
                      uid: uidGenerator(),
                      document_type_id: invoiceDocTypeId,
                      owner_id: policy.id,
                      owner_type: DocumentOwnerType.Policy
                    }
                  });
                }
              });
              setReportCreated(true);
            }
      }
      cancelHandler={() => {
        handleOpened(false);
      }}
      onClosed={() => setReportCreated(false)}
      showCancelButton={!reportCreated}
      isSubmitting={!(policyAgent && policyCarrier)}
    >
      {reportCreated ? (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;
          `}
        >
          <DownloadIcon color={colors.grey30} width={72} height={72} />

          <span className="fs-mask">{`Your report is ready, see ${reportTitle} in downloads`}</span>
        </div>
      ) : (
        <form
          ref={formRef}
          css={css`
            display: flex;
            flex-direction: column;
            padding: 24px;
          `}
        >
          <div>
            <Input
              id={CarrierAddress.RemittanceAddress}
              type="radio"
              name="address_type"
              value={CarrierAddress.RemittanceAddress}
              defaultChecked
            />
            <Label className="mb-0" htmlFor={CarrierAddress.RemittanceAddress}>
              {USE_REMITTANCE_ADDRESS}
            </Label>
            <br />
            <span>{policyCarrier?.remittance_address?.full || '-'}</span>
          </div>
          <br />
          <div>
            <Input
              id={CarrierAddress.OvernightAddress}
              type="radio"
              name="address_type"
              value={CarrierAddress.OvernightAddress}
            />
            <Label className="mb-0" htmlFor={CarrierAddress.OvernightAddress}>
              {USE_OVERNIGHT_ADDRESS}
            </Label>
            <br />
            <span>{policyCarrier?.overnight_address?.full || '-'}</span>
          </div>
        </form>
      )}
    </Modal>
  );
};

export default GenerateInvoiceModal;
