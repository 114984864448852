import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import Modal, { ModalSize } from '../../../components/core/Modal';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useCustomerEventsState } from '../_hooks';

const PeopleMergedAutomaticallyModal = () => {
  const { leadGid } = useGuidedSellingExperienceContext();

  const navigate = useNavigate();
  const { personMerged, resetPersonMerged } = useCustomerEventsState();
  const queryClient = useQueryClient();

  if (!personMerged) {
    return null;
  }

  const closeModalHandler = () => {
    queryClient.invalidateQueries();

    if (leadGid !== personMerged.active_lead_gid) {
      navigate(
        personMerged.active_lead_gid
          ? `/scouting_report?lead_gid=${personMerged.active_lead_gid}`
          : `/scouting_report?person_gid=${personMerged.merged_person_gid}`
      );
    }
    resetPersonMerged();
  };

  return (
    <Modal
      title="Your customer was merged!"
      dangerModal
      size={ModalSize.large}
      confirmHandler={closeModalHandler}
      cancelHandler={closeModalHandler}
      showCancelButton={false}
    >
      AMP has identified this customer already has a lead in our system and will now merge the two leads for you. No
      information previously collected will be lost. Please click OK to continue
    </Modal>
  );
};

export default PeopleMergedAutomaticallyModal;
