/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import colors from '../../theme/colors';
import Button from './buttons/Button';
import Container, { IContainer } from './Container';
import FlexBox from './FlexBox';
import { ChevronLeft } from './icons';
import Paragraph, { ParagraphType } from './Paragraph';

interface ICollapsingContainer extends IContainer {
  title: string;
  titleSize?: ParagraphType;
  children: React.ReactNode;
  iconPosition?: 'left' | 'right' | 'none';
  onClick?: () => void;
  openedByDefault?: boolean;
  preserveChildrenInClosedState?: boolean;
  // Set max height of the container to approximate height of content (with a bit of extra on top) in order
  // to ensure smooth open/close animation.
  maxContentHeight?: number;
  buttonTestId?: string;
}

const CollapsingContainer = ({
  title,
  titleSize = ParagraphType.Large,
  children,
  iconPosition = 'right',
  onClick,
  openedByDefault = true,
  preserveChildrenInClosedState = false,
  maxContentHeight,
  buttonTestId,
  ...rest
}: ICollapsingContainer): JSX.Element => {
  const [open, setOpen] = React.useState(openedByDefault);

  return (
    <Container {...rest}>
      <Button
        data-testid={buttonTestId}
        customCss={css`
          border: none;
          padding: 0;
          background: transparent;
          width: 100%;
          &:hover {
            background: transparent;
            cursor: ${iconPosition === 'none' ? 'default' : 'pointer'};
            opacity: ${iconPosition === 'none' ? 1 : 0.8};
          }
          &:focus {
            background: transparent;
            opacity: ${iconPosition === 'none' ? 1 : 0.8};
          }
        `}
        onClick={() => {
          if (iconPosition === 'none') {
            return null;
          }
          if (onClick) {
            onClick();
          }
          return setOpen(current => !current);
        }}
      >
        <FlexBox
          gap={4}
          alignItemsCenter
          justifySpaceBetween={iconPosition === 'right'}
          justifyRight={iconPosition === 'left'}
          css={css`
            flex-direction: ${iconPosition === 'left' ? 'row-reverse' : 'row'};
          `}
        >
          <Paragraph type={titleSize} bold>
            {title}
          </Paragraph>
          {iconPosition !== 'none' && (
            <ChevronLeft
              css={css`
                transform: rotate(${open ? '90deg' : '270deg'});
                transition: transform 0.2s;
              `}
              color={colors.black}
            />
          )}
        </FlexBox>
      </Button>
      <div
        css={css`
          visibility: ${open ? 'visible' : 'hidden'};
          opacity: ${open ? 1 : 0};
          max-height: ${open ? maxContentHeight || 'unset' : 0}px;

          ${!open && 'overflow: hidden;'}

          transition-property: visibility, opacity, max-height;
          transition-duration: 0.2s;
          transition-timing-function: ease-in-out;
        `}
      >
        {preserveChildrenInClosedState ? children : open && children}
      </div>
    </Container>
  );
};
export default CollapsingContainer;
