/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import colors from '../../../theme/colors';
import { defaultFontCss, largeParagraphCss } from '../../../theme/typography';
import { borderRadius, spacingKeys, Spacings, spacingsCss } from '../../../theme/variables';
import Loader from '../../common/Loader';

export enum ButtonSize {
  Small = 'SMALL',
  Normal = 'NORMAL',
  Large = 'LARGE'
}

export enum ButtonVariant {
  Default = 'default',
  Secondary = 'secondary',
  SecondaryDanger = 'secondary-danger',
  Text = 'text',
  Danger = 'danger',
  PlainText = 'plain-text',
  Simple = 'simple'
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, Spacings {
  size?: ButtonSize;
  type?: 'submit' | 'reset' | 'button';
  variant?: ButtonVariant;
  loading?: boolean;
  customCss?: SerializedStyles;
  innerRef?:
    | React.RefObject<HTMLButtonElement>
    | React.MutableRefObject<HTMLButtonElement>
    | React.LegacyRef<HTMLButtonElement>;
}

const smallButtonCss = css`
  ${defaultFontCss};
  padding: 4px 12px;
`;

const normalButtonCss = css`
  ${defaultFontCss};
  padding: 12px;
`;

const largeButtonCss = css`
  ${largeParagraphCss};
  padding: 14px;
`;

export const buttonSizeCss = (buttonSize: ButtonSize) => {
  switch (buttonSize) {
    case ButtonSize.Small:
      return smallButtonCss;
    case ButtonSize.Normal:
      return normalButtonCss;
    case ButtonSize.Large:
      return largeButtonCss;
  }
};

const textButtonCss = css`
  padding: 0;
  border: none;
`;

const nonTextButtonCss = css`
  border-radius: ${borderRadius}px;
  border-style: solid;
  border-width: 2px;
`;

export const buttonColors = (bgc: string, bc: string, c: string): SerializedStyles => css`
  background-color: ${bgc};
  border-color: ${bc};
  color: ${c};
`;

const getButtonColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case ButtonVariant.Text:
      return buttonColors('transparent', 'transparent', colors.azure50);
    case ButtonVariant.PlainText:
      return buttonColors('transparent', 'transparent', colors.black);
    case ButtonVariant.Secondary:
      return buttonColors(colors.white, colors.azure50, colors.azure50);
    case ButtonVariant.Simple:
      return buttonColors(colors.grey5, colors.grey5, colors.black);
    case ButtonVariant.Danger:
      return buttonColors(colors.statusRed, colors.statusRed, colors.white);
    case ButtonVariant.SecondaryDanger:
      return buttonColors(colors.white, colors.statusRed, colors.statusRed);
    case ButtonVariant.Default:
    default:
      return buttonColors(colors.azure50, colors.azure50, colors.white);
  }
};

export const getHoverColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case ButtonVariant.Text:
      return buttonColors('transparent', 'transparent', colors.cornflower);
    case ButtonVariant.PlainText:
      return buttonColors('transparent', 'transparent', colors.black);
    case ButtonVariant.Secondary:
      return buttonColors(colors.cornflower, colors.cornflower, colors.white);
    case ButtonVariant.Simple:
      return buttonColors(colors.grey5, colors.grey5, colors.black);
    case ButtonVariant.Danger:
      return buttonColors(colors.cherise50, colors.cherise50, colors.white);
    case ButtonVariant.SecondaryDanger:
      return buttonColors(colors.cherise50, colors.cherise50, colors.white);

    case ButtonVariant.Default:
    default:
      return buttonColors(colors.cornflower, colors.cornflower, colors.white);
  }
};

export const getActiveFocusColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case ButtonVariant.Text:
      return buttonColors('transparent', 'transparent', colors.denim);
    case ButtonVariant.PlainText:
      return buttonColors('transparent', 'transparent', colors.black);
    case ButtonVariant.Secondary:
      return buttonColors(colors.denim, colors.denim, colors.white);
    case ButtonVariant.Simple:
      return buttonColors(colors.grey5, colors.grey5, colors.black);
    case ButtonVariant.Danger:
      return buttonColors(colors.statusRed, colors.statusRed, colors.white);
    case ButtonVariant.SecondaryDanger:
      return buttonColors(colors.statusRed, colors.statusRed, colors.white);

    case ButtonVariant.Default:
    default:
      return buttonColors(colors.denim, colors.denim, colors.white);
  }
};

const getDisabledColors = (variant: ButtonVariant): SerializedStyles => {
  switch (variant) {
    case ButtonVariant.Text:
      return buttonColors('transparent', colors.grey30, colors.grey60);
    case ButtonVariant.PlainText:
      return buttonColors('transparent', colors.grey30, colors.grey60);
    case ButtonVariant.Secondary:
      return buttonColors(colors.white, colors.grey30, colors.grey60);
    case ButtonVariant.Simple:
      return buttonColors(colors.white, colors.white, colors.black);
    case ButtonVariant.Danger:
      return buttonColors(colors.grey10, colors.grey10, colors.grey60);
    case ButtonVariant.SecondaryDanger:
      return buttonColors(colors.grey10, colors.grey10, colors.grey60);

    case ButtonVariant.Default:
    default:
      return buttonColors(colors.grey10, colors.grey10, colors.grey60);
  }
};

const pickButtonFontWeight = (variant: ButtonVariant) => {
  switch (variant) {
    case ButtonVariant.PlainText:
      return 400;
    default:
      return 700;
  }
};

const buttonCss = (buttonSize: ButtonSize, variant: ButtonVariant = ButtonVariant.Default): SerializedStyles => css`
  ${buttonSizeCss(buttonSize)};
  ${variant === ButtonVariant.Text || variant === ButtonVariant.PlainText ? textButtonCss : nonTextButtonCss};
  ${getButtonColors(variant)};
  font-weight: ${pickButtonFontWeight(variant)};
  box-sizing: border-box;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  max-width: 100%;
  transition:
    color 0.3s,
    background-color 0.3s,
    border-color 0.3s;
  position: relative;
  &:hover {
    ${getHoverColors(variant)}
  }
  &:active,
  &:focus {
    ${getActiveFocusColors(variant)}
  }
  &:disabled,
  &:disabled:hover {
    cursor: not-allowed;
    ${getDisabledColors(variant)}
  }
`;

export const iconComponentSize = (dropDownSize: ButtonSize): string =>
  dropDownSize === ButtonSize.Large ? '20px' : '16px';

export const loaderComponent = (loading: boolean, dropDownSize: ButtonSize): JSX.Element | null =>
  loading ? (
    <Loader
      width={iconComponentSize(dropDownSize)}
      height={iconComponentSize(dropDownSize)}
      customCss={css`
        margin-left: 8px;
        display: inline;
      `}
    />
  ) : null;

const Button = ({
  size = ButtonSize.Normal,
  children,
  variant = ButtonVariant.Default,
  customCss,
  disabled,
  type = 'button',
  loading = false,
  innerRef,
  ...props
}: ButtonProps): JSX.Element => {
  const spacingProps = pick(spacingKeys, props);
  const restOfProps = omit(spacingKeys, props);
  return (
    <button
      {...restOfProps}
      ref={innerRef}
      type={type}
      disabled={disabled || loading}
      css={[spacingsCss(spacingProps), buttonCss(size, variant), customCss]}
    >
      {children}
      {loaderComponent(loading, size)}
    </button>
  );
};

export default Button;
