import { useMutation, useQuery } from '@tanstack/react-query';

import { IPayloadAddress, IVehicle, Pretty } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const usePersonVehicles = (personGid: string | undefined) =>
  useQuery({
    queryKey: ['vehicles', personGid],
    queryFn: (): Promise<{ vehicles: IVehicle[] }> => api.get(`/api/frontend/people/${personGid}/vehicles`),
    enabled: !!personGid,
    select: data => data.vehicles
  });

interface DeletePayload {
  personGid: string;
  vehicleGid: string;
  leadId: number | undefined;
}

const detachVehicle = ({ personGid, vehicleGid, leadId }: DeletePayload): Promise<unknown> =>
  api.delete(`/api/frontend/people/${personGid}/vehicles/${vehicleGid}?${toQueryParams({ lead_id: leadId })}`);

export const useDetachVehicle = () => useMutation({ mutationFn: detachVehicle });

type RequiredProperties<T, P extends keyof T> = Omit<T, P> & Required<Pick<T, P>>;
type RequiredVehicleData =
  | RequiredProperties<BaseVehicleData, 'year' | 'make' | 'model'>
  | RequiredProperties<BaseVehicleData, 'vin'>;

type BaseVehicleData = {
  data_source?: 'fenris' | undefined;
  address: IPayloadAddress;

  year?: number | null;
  make?: string | null;
  model?: string | null;
  submodel?: string | null;
  vin?: string | null;
  kind?: string | null;
  ownership?: string | null;
  usage?: string | null;
  annual_mileage?: string | number | null;
  days_per_week_driven?: string | number | null;
  used_for_ridesharing?: boolean | null;
  plate_type?: string | null;
  plate_number?: string | null;
};

export type VehiclePayload = Pretty<BaseVehicleData & RequiredVehicleData>;

export const createPersonVehicle = ({
  personGid,
  data
}: {
  personGid: string;
  data: VehiclePayload;
}): Promise<{ vehicle: IVehicle }> =>
  api.post(`/api/frontend/people/${personGid}/vehicles`, {
    body: data
  });

export const useCreateVehicle = () => useMutation({ mutationFn: createPersonVehicle });

export const updatePersonVehicle = ({
  personGid,
  vehicleGid,
  data
}: {
  personGid: string;
  vehicleGid: string;
  data: VehiclePayload;
}): Promise<{ vehicle: IVehicle }> =>
  api.put(`/api/frontend/people/${personGid}/vehicles/${vehicleGid}`, {
    body: data
  });

export const useUpdateVehicle = () => useMutation({ mutationFn: updatePersonVehicle });

export default usePersonVehicles;
