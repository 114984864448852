import { isEndDisposition } from '../../../components/DispositionsModals/dispositionsHelper';
import {
  ICurrentDisposition,
  ICustomerDataCompleteness,
  IHome,
  ILead,
  IOpportunity,
  IPolicy,
  IPremiumPrediction
} from '../../../interfaces';
import { Completeness } from '../../../interfaces/ICustomerDataCompleteness';
import { IDataCollectionOpportunity } from '../../../interfaces/IDataCollection';
import { arePolicyTypesSimilar, PolicyType, Requirement } from '../../../interfaces/IPolicyType';
import {
  IQuotesRequest,
  isQuotesRequestedByAmp,
  isQuotesRequestedByCustomer
} from '../../../interfaces/IQuotesRequests';
import { isAutoAssetNotQuotable, IVehicle } from '../../../interfaces/IVehicle';

export const LLC_VALUE = 'llc';
export const TRUST_VALUE = 'trust';
export const llcTrustPair = { [LLC_VALUE]: 'LLC', [TRUST_VALUE]: 'Trust' } as const;

export const isCustomerDataCompletenessHigh = (customerDataCompleteness: ICustomerDataCompleteness | undefined) =>
  !!customerDataCompleteness && [Completeness.Full, Completeness.High].includes(customerDataCompleteness.completeness);

export const isCustomerDataCompletenessFull = (customerDataCompleteness: ICustomerDataCompleteness | undefined) =>
  !!customerDataCompleteness && customerDataCompleteness.completeness === Completeness.Full;

export const predictedPremiumIncrease = ({
  premium,
  premiumPrediction
}: {
  premium: IPolicy['premium'] | undefined;
  premiumPrediction: IPremiumPrediction | undefined;
}) => {
  if (!premiumPrediction) {
    return null;
  }

  const predictedPercentageIncrease = parseFloat(premiumPrediction.avg_premium_change_percent);
  const floatPremium = premium ? parseFloat(premium) : null;

  const predictedFlatIncrease = floatPremium ? (predictedPercentageIncrease / 100.0) * floatPremium : null;

  return {
    predictedPercentageIncrease: Math.round(predictedPercentageIncrease),
    predictedFlatIncrease: predictedFlatIncrease ? predictedFlatIncrease : null,
    predictedNewPremium: predictedFlatIncrease ? predictedFlatIncrease + floatPremium! : null
  };
};

export const isLeadOutdated = ({
  activeLead,
  openedLead
}: {
  activeLead: ILead | undefined;
  openedLead: ILead | undefined;
}) => !!activeLead && activeLead.gid !== openedLead?.gid;

export const isDataCollectionControlsAvailable = ({
  activeLead,
  openedLead,
  currentDisposition,
  isPersonLinkedToActiveCall
}: {
  activeLead: ILead | undefined;
  openedLead: ILead | undefined;
  currentDisposition: ICurrentDisposition | undefined;
  isPersonLinkedToActiveCall: boolean;
}) => {
  if (
    isLeadOutdated({ activeLead, openedLead }) ||
    (currentDisposition && isEndDisposition(currentDisposition?.disposition_type))
  ) {
    return false;
  }

  return isPersonLinkedToActiveCall;
};

export const secondaryCoreBundleOpportunity = ({ opportunities }: { opportunities: IOpportunity[] | undefined }) => {
  const primaryOpportunity = opportunities?.find(({ primary }) => primary);

  if (!opportunities || !primaryOpportunity) {
    return undefined;
  }

  return opportunities.find(
    ({ insurable_interest, requirement }) =>
      insurable_interest !== primaryOpportunity.insurable_interest && requirement === Requirement.Essential
  );
};

export const isOpportunityDataCollectionCompleted = ({ opportunity }: { opportunity: IDataCollectionOpportunity }) =>
  opportunity.is_data_collection_completed && opportunity.is_data_collection_enabled;

const isOpportunityQuotesRequested = ({
  opportunity,
  quotesRequest
}: {
  opportunity: IOpportunity;
  quotesRequest: IQuotesRequest;
}) =>
  quotesRequest.quoting_references?.some(({ policy_type, assets_gids }) => {
    const oppAssetsGids = opportunity?.assets?.map(({ gid }) => gid);
    const doAssetsGidsIntersect = (assets_gids || []).some(gid => oppAssetsGids?.includes(gid));

    return (
      arePolicyTypesSimilar(policy_type, opportunity.policy_type) && (!opportunity.assets || doAssetsGidsIntersect)
    );
  });

export const isOpportunityQuotedFromAmp = ({
  opportunity,
  quotesRequests
}: {
  opportunity: IOpportunity;
  quotesRequests: IQuotesRequest[] | undefined;
}) =>
  quotesRequests?.some(
    quotesRequest =>
      isQuotesRequestedByAmp(quotesRequest) && isOpportunityQuotesRequested({ opportunity, quotesRequest })
  ) || false;

export const isOpportunityQuotedByCustomer = ({
  opportunity,
  quotesRequests
}: {
  opportunity: IOpportunity;
  quotesRequests: IQuotesRequest[] | undefined;
}) =>
  quotesRequests?.some(
    quotesRequest =>
      isQuotesRequestedByCustomer(quotesRequest) && isOpportunityQuotesRequested({ opportunity, quotesRequest })
  ) || false;

export const isOpportunityQuoted = ({
  opportunity,
  quotesRequests
}: {
  opportunity: IOpportunity;
  quotesRequests: IQuotesRequest[] | undefined;
}) =>
  isOpportunityQuotedFromAmp({ opportunity, quotesRequests }) ||
  isOpportunityQuotedByCustomer({ opportunity, quotesRequests });

export const isOpportunityAssetQuotable = ({
  opportunity,
  asset
}: {
  opportunity: IOpportunity;
  asset: IVehicle | IHome;
}) => {
  if (opportunity.policy_type === PolicyType.Auto) {
    return !isAutoAssetNotQuotable((asset as IVehicle).kind);
  }

  return true;
};

const quotesRequestContainsOpportunity = ({
  opportunity,
  quotesRequest
}: {
  opportunity: IOpportunity;
  quotesRequest: IQuotesRequest;
}) => {
  return (
    quotesRequest.options?.homes?.some(({ opportunity_id }) => opportunity_id === opportunity.id) ||
    quotesRequest.options?.autos?.some(({ opportunity_id }) => opportunity_id === opportunity.id) ||
    quotesRequest.options?.motorcycles?.some(({ opportunity_id }) => opportunity_id === opportunity.id) ||
    quotesRequest.options?.recreational_vehicles?.some(({ opportunity_id }) => opportunity_id === opportunity.id) ||
    quotesRequest.options?.new_paf?.opportunity_id === opportunity.id ||
    quotesRequest.options?.pet?.opportunity_id === opportunity.id
  );
};

export const isOpportunityQuotingInProgress = ({
  opportunity,
  quotesRequests
}: {
  opportunity: IOpportunity;
  quotesRequests: IQuotesRequest[] | undefined;
}) =>
  quotesRequests?.some(
    quotesRequest => !quotesRequest.finished_at && quotesRequestContainsOpportunity({ opportunity, quotesRequest })
  );
