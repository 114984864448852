import moment from 'moment';
import * as yup from 'yup';

import { annualMileageCalculatorValidations } from '../components/AnnualMileageCalculator';
import { REQUIRED_TEXT } from '../components/PolicyModals/helpers';
import { IVehicle } from '../interfaces';
import { notMotorcycleOrTrailer } from '../interfaces/IVehicle';
import { VehicleFields } from '../pages/GetQuotes/AssetsPicker/formHelpers';
import { INTERNAL_DATE_FORMAT } from './formatter';

yup.setLocale({
  mixed: {
    notType: ({ type, originalValue }) => {
      return `Provided value is ${originalValue}, which is not correct ${type}. Please use correct value format`;
    }
  }
});

yup.addMethod<yup.ObjectSchema<object, any, any, any, any>>(
  yup.object,
  'uniqueProperty',
  function (propertyName, message, comparator) {
    return this.test('unique', message, function (value: any) {
      if (!value || !value[propertyName]) {
        return true;
      }

      const { path } = this;
      const formItems = Array.isArray(this.parent) ? [...this.parent] : (Object.values(this.parent) as any[]);
      const currentIndex = formItems.indexOf(value);

      const subOptions = formItems.slice(0, currentIndex);

      if (
        subOptions.some(option =>
          comparator
            ? comparator(option[propertyName], value[propertyName])
            : option[propertyName] === value[propertyName]
        )
      ) {
        throw this.createError({
          path: `${path}.${propertyName}`,
          message
        });
      }

      return true;
    });
  }
);

declare module 'yup' {
  interface ObjectSchema<
    TIn extends yup.Maybe<yup.AnyObject>,
    TContext = yup.AnyObject, // eslint-disable-line @typescript-eslint/no-unused-vars
    TDefault = any, // eslint-disable-line @typescript-eslint/no-unused-vars
    TFlags extends yup.Flags = '', // eslint-disable-line @typescript-eslint/no-unused-vars
    T = any // eslint-disable-line @typescript-eslint/no-unused-vars
  > {
    uniqueProperty(
      propertyName: keyof TIn,
      message: string,
      comparator?: (left: unknown, right: unknown) => boolean
    ): this;
  }
}
export const getTodayDate = () => new Date();
export const minDate = new Date('1800-01-01');
export const maxDate = new Date('2100-12-31');
export const oldDate = new Date('1990-12-31');

export const REQUIRED_MESSAGE = 'Please, fill in the field';
export const requiredField = yup.string().required(REQUIRED_MESSAGE);
export const ssnField = yup
  .string()
  .matches(/^((\d{3})-?(\d{2})-?(\d{4}))|(\*{3}-?(\*{2})-?(\d{4}))$/, 'Please, enter correct ssn');
export const emailField = yup.string().email('Please, enter correct email');
export const zipField = yup.string().matches(/^(\d{5}(-?\d{4})?)$/, 'Incorrect zip');
export const yearField = yup.string().min(4, 'Please, enter correct year').max(4, 'Please, enter correct year');

/*
  Use this during render if `loose` is not derived from constant
*/
export const phoneField = ({ loose }: { loose: boolean }) =>
  yup
    .string()
    .matches(
      loose
        ? /^(\([2-9][0-9]{2}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/
        : /^\((?![8](00|33|44|55|66|77|88)\)\s)[2-9][0-9]{2}\)\s[0-9]{3}-[0-9]{4}$/,
      'Please start with area code and use (000) 000-0000 format'.concat(loose ? '' : '. No toll-free numbers')
    );

export const deprecatedPhoneField = ({ loose }: { loose: boolean }) =>
  yup
    .string()
    .matches(
      loose ? /^([2-9][0-9]{2})[0-9]{7}$/ : /^(?!800|833|844|855|866|877|888)([2-9][0-9]{2})[0-9]{7}$/,
      'Please start with area code and use (000) 000-0000 format'.concat(loose ? '' : '. No toll-free numbers')
    );

export const minDOBField = () =>
  yup
    .date()
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }: { originalValue: string }) => {
        if (!originalValue) {
          return true;
        }

        return originalValue.trim().length === 10;
      }
    )
    .min(minDate, 'Date of birth cannot be earlier than 01/01/1800')
    .max(getTodayDate(), 'Date of birth cannot be future date');

export const MIN_AGE_DRIVER = 15;
export const MAX_AGE_DRIVER = 120;
export const requiredMinMaxDOBField = ({ min = MIN_AGE_DRIVER, max = MAX_AGE_DRIVER } = {}) =>
  yup
    .date()
    .test('Required validation', REQUIRED_MESSAGE, value => !!value)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }) => !!(originalValue && originalValue.trim().length === 10)
    )
    .test('Age validation', `Age can not be less than ${min} and more than ${max}`, value => {
      const diff = moment().diff(moment(value), 'years');

      return diff >= min && diff <= max;
    });

export const combine = (...rules: any[]) => rules.reduce((acc, rule) => acc.concat(rule), yup.mixed());
export const addressSchema = yup.object().shape({
  line1: yup.string().test({
    test: (line1, { parent: { city, state, zip } }) =>
      [state, line1, city, zip].every(item => !item) ? true : !!line1,
    message: REQUIRED_MESSAGE
  }),

  city: yup.string().test({
    test: (city, { parent: { line1, state, zip } }) => ([state, line1, city, zip].every(item => !item) ? true : !!city),
    message: REQUIRED_MESSAGE
  }),
  state: yup.string().test({
    test: (state, { parent: { line1, city, zip } }) =>
      [state, line1, city, zip].every(item => !item) ? true : !!state,
    message: REQUIRED_MESSAGE
  }),
  zip: yup
    .string()
    .test({
      test: (zip, { parent: { line1, city, state } }) =>
        [state, line1, city, zip].every(item => !item) ? true : !!zip,
      message: REQUIRED_MESSAGE
    })
    .matches(/^(\d{5}(-?\d{4})?)$/, 'Incorrect zip')
});

export const requiredAddressSchema = yup.object().shape({
  line1: yup.string().required(REQUIRED_MESSAGE),
  city: yup.string().required(REQUIRED_MESSAGE),
  state: yup.string().required(REQUIRED_MESSAGE),
  zip: yup
    .string()
    .required(REQUIRED_MESSAGE)
    .matches(/^(\d{5}(-?\d{4})?)$/, 'Incorrect zip')
});

export const requiredDate = () =>
  yup
    .date()
    .required(REQUIRED_TEXT)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }) => !!(originalValue && originalValue.length === 10)
    )
    .min(moment().subtract(10, 'years').format(INTERNAL_DATE_FORMAT), "Date can't be more than 10 years before today")
    .max(moment().add(10, 'years').format(INTERNAL_DATE_FORMAT), "Date can't be more than 10 years from today");
/*
  This should be defined at a runtime, due to
  today date being cached at app init
*/
export const requiredDateMaxToday = () =>
  yup
    .date()
    .required(REQUIRED_TEXT)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }) => !!(originalValue && originalValue.trim().length === 10)
    )
    .min(moment().subtract(10, 'years').format(INTERNAL_DATE_FORMAT), "Date can't be more than 10 years before today")
    .max(moment().format(INTERNAL_DATE_FORMAT), "Date can't be in the future");
export const optionalDate = () =>
  yup
    .date()
    .test('Date format', 'Please enter date in MM/DD/YYYY format', (_value, { originalValue }) => {
      if (!originalValue) {
        return true;
      }

      return !!(originalValue && originalValue.trim().length === 10);
    })
    .min(moment().subtract(10, 'years').format(INTERNAL_DATE_FORMAT), "Date can't be more than 10 years before today")
    .max(moment().add(10, 'years').format(INTERNAL_DATE_FORMAT), "Date can't be more than 10 years from today");

export const snakeCaseField = yup
  .string()
  .matches(
    /^[a-z]+(?:_[a-z]+)*$/,
    'Value should start and end with lower case letter, can contain underscore, cannot contain digits and whitespaces.'
  );

// Kind in initial values is needed to determine if address and ownership are required

export const baseVehicleValidation = {
  [VehicleFields.Year]: yup.number().when(VehicleFields.VIN, {
    is: (vin: string) => !vin,
    then: schema => schema.required('Please fill in year').min(1801, 'Should greater than 1800'),
    otherwise: undefined
  }),
  [VehicleFields.Make]: yup.string().when(VehicleFields.VIN, {
    is: (vin: string) => !vin,
    then: schema => schema.required('Please fill in make'),
    otherwise: undefined
  }),
  [VehicleFields.Model]: yup.string().when(VehicleFields.VIN, {
    is: (vin: string) => !vin,
    then: schema => schema.required('Please fill in model'),
    otherwise: undefined
  }),
  [VehicleFields.VIN]: yup.string()
};

export const vehicleValidation = () =>
  yup
    .object()
    .shape({
      [VehicleFields.Address]: yup.object().when(VehicleFields.Kind, {
        is: (kind: IVehicle['kind']) => notMotorcycleOrTrailer(kind),
        then: schema => schema.concat(requiredAddressSchema),
        otherwise: undefined
      }),
      [VehicleFields.Ownership]: yup.string().when(VehicleFields.Kind, {
        is: (kind: IVehicle['kind']) => notMotorcycleOrTrailer(kind),
        then: schema => schema.concat(requiredField),
        otherwise: undefined
      }),
      ...baseVehicleValidation,
      ...annualMileageCalculatorValidations()
    })
    .uniqueProperty(VehicleFields.VIN, 'VIN should be unique across customer vehicles');

export const expirationDateValidation = (schema: yup.DateSchema, effectiveDate: string) =>
  schema
    .required(REQUIRED_MESSAGE)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }: { originalValue: string }) => {
        if (!originalValue) {
          return true;
        }

        return originalValue.trim().length === 10;
      }
    )
    .test(
      'Expiration date more then Effective',
      'Please enter expiration date more than effective date',
      (_value, { originalValue }: { originalValue: string }) => {
        if (!originalValue && !effectiveDate) {
          return true;
        }

        return moment(originalValue, 'YYYY-MM-DD').isAfter(moment(effectiveDate, 'YYYY-MM-DD'));
      }
    )
    .max(
      moment().add(10, 'years').format(INTERNAL_DATE_FORMAT),
      "Expiration date can't be more than 10 years from today"
    );
export const newPolicyEffectiveDateValidation = (schema: yup.DateSchema) =>
  schema
    .required(REQUIRED_MESSAGE)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }: { originalValue: string }) => {
        if (!originalValue) {
          return true;
        }

        return originalValue.trim().length === 10;
      }
    )
    .max(
      moment().add(6, 'months').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 6 months from today"
    )
    .min(
      moment().subtract(60, 'days').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 60 days before today"
    );

export const existingPolicyEffectiveDateValidation = (schema: yup.DateSchema) =>
  schema
    .required(REQUIRED_MESSAGE)
    .test(
      'Date format',
      'Please enter date in MM/DD/YYYY format',
      (_value, { originalValue }: { originalValue: string }) => {
        if (!originalValue) {
          return true;
        }

        return originalValue.trim().length === 10;
      }
    )
    .max(
      moment().add(6, 'months').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 6 months from today"
    )
    .min(
      moment().subtract(10, 'years').format(INTERNAL_DATE_FORMAT),
      "Effective date can't be more than 10 years before today"
    );
