/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import DatapointKey, { DatapointsUsageKey } from '../../../../interfaces/IDatapoint';
import { InsurableInterest } from '../../../../interfaces/IPolicyType';
import { useAnswers } from '../../../../queries/answers/useAnswers';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import { defaultFontValues } from '../../../../theme/typography';
import { spacings } from '../../../../theme/variables';

export const IntroTip = () => {
  return (
    <Paragraph>
      ...my name is{' '}
      <span
        css={css`
          font-weight: ${defaultFontValues.boldFontWeight};
        `}
      >
        {authInfo.currentUserFirstName}
      </span>{' '}
      with Matic Insurance on a{' '}
      <span
        css={css`
          font-weight: ${defaultFontValues.boldFontWeight};
        `}
      >
        recorded line
      </span>
    </Paragraph>
  );
};

export const ConsumerReportTip = () => {
  return <Paragraph>The insurance carriers will use your consumer report information to generate a quote.</Paragraph>;
};

export const HouseholdMembersReminderTip = () => {
  return (
    <Paragraph>
      Could you please confirm who is listed on the property deed? Is it just you, or are there others, like a spouse,
      family member, or an entity such as a trust or LLC, also on the deed?
    </Paragraph>
  );
};

export const LifeInsuranceTip = () => {
  const { leadGid, personGid } = useGuidedSellingExperienceContext();
  const trackLifeTipImpression = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        analytics.track('Life Insurance Tip is shown', { lead_gid: leadGid, person_gid: personGid });
      }
    },
    [leadGid, personGid]
  );

  return (
    <FlexBox columnDirection gap={spacings.px8} ref={trackLifeTipImpression}>
      <Paragraph>
        {
          // eslint-disable-next-line max-len
          "Now that we have your {XYZ} policies in place, I'd love to connect you with one of our life insurance advisors to review some quick quotes. As a homeowner, life insurance could help your family continue paying the mortgage if the unexpected happens. Should I see if an agent is available or coordinate a call for a later date?"
        }
      </Paragraph>
      <Paragraph>
        <a
          target="_blank"
          rel="noopener noreferrer"
          // eslint-disable-next-line max-len
          href="https://calendly.com/matic_cs/life-insurance-consultation?month=2024-01&date=2024-01-05&utm_source=Team+Clark&utm_medium=cross-sale&utm_campaign=2024&utm_id=NAF+cross+sell"
        >
          Book a life insurance appointment
        </a>
      </Paragraph>
    </FlexBox>
  );
};

export const IssuePoliciesTip = () => {
  return (
    <Paragraph>Reminder: The &quot;permission to issue&quot; verbatim question is scripted in Post Sale.</Paragraph>
  );
};

export const HouseholdMembersOnQuotingConfigurationTip = () => {
  const { personGid, leadGid } = useGuidedSellingExperienceContext();
  const { data: relatedPeople = [] } = usePersonRelatedPeople(personGid!);
  const { data: opportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    insurableInterest: InsurableInterest.RealProperty
  });
  const realPropertyOpportunity = opportunities?.[0];
  const { data: answers = [] } = useAnswers({
    datapointsUsageKey: DatapointsUsageKey.PropertyOnTheDeed,
    personGid: personGid!,
    assetGid: realPropertyOpportunity?.assets?.[0]?.gid || null,
    relatedPersonGid: null
  });

  if (!realPropertyOpportunity) {
    return null;
  }

  const entitiesOnTheDeed =
    (answers.find(answer => answer.key === DatapointKey.PropertyOnTheDeed)?.value as string[] | undefined) || [];
  const peopleOnTheDeed = relatedPeople.filter(person => entitiesOnTheDeed.includes(person.gid));
  const peopleNames =
    peopleOnTheDeed.length > 0 ? ` and ${peopleOnTheDeed.map(person => person.first_name).join(' and ')}` : '';

  const LLCOnTheDeed = entitiesOnTheDeed.includes('llc')
    ? (answers.find(answer => answer.key === DatapointKey.PropertyLLCName)?.value as string | undefined)
    : undefined;
  const LCCName = LLCOnTheDeed ? ` and LCC - ${LLCOnTheDeed}` : '';

  const trustOnTheDeed = entitiesOnTheDeed.includes('trust')
    ? (answers.find(answer => answer.key === DatapointKey.PropertyTrustName)?.value as string | undefined)
    : undefined;
  const trustName = trustOnTheDeed ? ` and Trust - ${trustOnTheDeed}` : '';

  return (
    <Paragraph>
      {
        // eslint-disable-next-line max-len
        `Could you please confirm who should be included in your insurance? Is it just you${peopleNames}${LCCName}${trustName}?
        This would mean these individuals are eligible to file a claim.`
      }
    </Paragraph>
  );
};
