/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import { TrendingUpIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { Translations } from '../../../../../constants';
import { IPolicy } from '../../../../../interfaces';
import { AgencyName, UNKNOWN_POLICY_STATUS } from '../../../../../interfaces/IPolicy';
import { coverageByKey, PolicyCoverageKey } from '../../../../../interfaces/IPolicyCoverage';
import { isInsurableInterestRealProperty, isInsurableInterestVehicle } from '../../../../../interfaces/IPolicyType';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { dateFormatter, moneyFormatter, typedMoneyFormatter } from '../../../../../utils/formatter';

const tagCss = css`
  border-radius: 4px;
  padding: 2px 8px;
`;

const DISABLED_EDIT_TIP_ID = 'disabled-edit';
const DISABLED_EDIT_TIP_TEXT = "This is Matic's policy";

const expirationDateLabel = (expiration_date: string | null) => {
  if (!expiration_date) {
    return '-';
  }
  const expiresIn = moment(expiration_date).diff(moment(), 'd');

  if (expiresIn > 0 && expiresIn <= 7) {
    return `${dateFormatter(expiration_date)} (in ${expiresIn} days)`;
  }

  return dateFormatter(expiration_date);
};

export const PremiumHeading = ({
  isPremiumAnnual,
  setAnnualPremium
}: {
  isPremiumAnnual: boolean;
  setAnnualPremium: (b: boolean) => void;
}) => (
  <Paragraph type="tiny" bold color={colors.grey60}>
    Premium
    <>
      <ButtonWithoutBorder
        onClick={() => {
          setAnnualPremium(true);
        }}
        ph={spacings.px4}
      >
        <Text
          bold
          type="tiny"
          customCss={css`
            color: ${colors.azure50};
            &[aria-selected='true'] {
              color: ${colors.black};
              border-bottom: 2px solid ${colors.black};
            }

            &:hover {
              color: inherit;
            }
          `}
          aria-selected={isPremiumAnnual}
        >
          yr
        </Text>
      </ButtonWithoutBorder>
      <ButtonWithoutBorder
        onClick={() => {
          setAnnualPremium(false);
        }}
        ph={spacings.px4}
      >
        <Text
          bold
          type="tiny"
          customCss={css`
            color: ${colors.azure50};
            &[aria-selected='true'] {
              color: ${colors.black};
              border-bottom: 2px solid ${colors.black};
            }

            &:hover {
              color: inherit;
            }
          `}
          aria-selected={!isPremiumAnnual}
        >
          mo
        </Text>
      </ButtonWithoutBorder>
    </>
  </Paragraph>
);

export const PolicyStatusCell = ({ isPredicted, isCurrent, isUnknown }: TableDataModel) => {
  if (isPredicted) {
    return (
      <Tag
        customCss={tagCss}
        textType="tiny"
        label="Prediction"
        backgroundColor={colors.grey10}
        textColor={colors.grey60}
      />
    );
  }

  if (isCurrent) {
    return (
      <Tag
        customCss={tagCss}
        textType="tiny"
        label="Current"
        backgroundColor={colors.statusGreen}
        textColor={colors.white}
      />
    );
  }

  if (isUnknown) {
    return (
      <Tag
        customCss={tagCss}
        textType="tiny"
        label="Unknown"
        backgroundColor={colors.grey60}
        textColor={colors.white}
      />
    );
  }

  return (
    <Tag customCss={tagCss} textType="tiny" label="Expired" backgroundColor={colors.grey60} textColor={colors.white} />
  );
};

export const DwellingCell = ({ policy_type, coverages, isCurrent }: TableDataModel) => {
  let value = '-';

  if (isInsurableInterestRealProperty(policy_type)) {
    value = moneyFormatter(coverageByKey(coverages, PolicyCoverageKey.Dwelling)?.limit_value, true) || '-';
  }
  if (isInsurableInterestVehicle(policy_type)) {
    value =
      moneyFormatter(coverageByKey(coverages, PolicyCoverageKey.PropertyDamage)?.limit_value, true, 'compact') || '-';
  }

  return <Paragraph color={isCurrent ? colors.black : colors.grey60}>{value}</Paragraph>;
};

export type TableDataModel = IPolicy & {
  isPredicted: boolean;
  isCurrent: boolean;
  isUnknown: boolean;
  isPremiumAnnual: boolean;
  setIsPremiumAnnual: (s: boolean) => void;
  rateChange: string | number;
  onEdit: () => void;
  rowCustomCSS?: ReturnType<typeof css>;
  disabled: boolean;
};
const columnHelper = createColumnHelper<TableDataModel>();

export const CarrierCell = ({ carrier, isCurrent }: TableDataModel) => (
  <Container
    title={carrier.name}
    customCss={css`
      max-width: 100px;
    `}
  >
    <Text bold color={isCurrent ? colors.black : colors.grey60} singleLine>
      {carrier.name}
    </Text>
  </Container>
);

export const StatusCell = ({ status, isPredicted, isCurrent }: TableDataModel) => {
  if (isPredicted || status === UNKNOWN_POLICY_STATUS) {
    return null;
  }

  return (
    <FlexBox
      title={status}
      alignItemsCenter
      customCss={css`
        max-width: 80px;
      `}
    >
      <Container
        m={spacings.px8}
        customCss={css`
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background: ${isCurrent ? colors.statusGreen : colors.grey60};
        `}
      />
      <Text type="small" color={isCurrent ? colors.black : colors.grey60} singleLine>
        {Translations.policyStatus(status)}
      </Text>
    </FlexBox>
  );
};

export const PremiumCell = ({ premium, isCurrent, isPremiumAnnual }: TableDataModel) => (
  <FlexBox alignItemsCenter gap={spacings.px4}>
    <Paragraph type="small" bold color={isCurrent ? colors.black : colors.grey60}>
      {premium ? moneyFormatter(isPremiumAnnual === true ? premium : Number(premium) / 12, true) : '-'}
    </Paragraph>
  </FlexBox>
);

export const DwellingHeading = ({ policyType }: { policyType: IPolicy['policy_type'] }) => {
  if (isInsurableInterestRealProperty(policyType)) {
    return (
      <Paragraph type="tiny" color={colors.grey60}>
        Dwelling
      </Paragraph>
    );
  }

  if (isInsurableInterestVehicle(policyType)) {
    return (
      <Paragraph type="tiny" color={colors.grey60}>
        PD
      </Paragraph>
    );
  }

  return (
    <Paragraph type="tiny" color={colors.grey60}>
      -
    </Paragraph>
  );
};

export const DeductibleCell = ({ policy_type, coverages, isCurrent }: TableDataModel) => {
  let value = '-';
  if (isInsurableInterestRealProperty(policy_type)) {
    const coverageNode = coverageByKey(coverages, PolicyCoverageKey.Dwelling);
    value = typedMoneyFormatter(coverageNode?.deductible_type, coverageNode?.deductible_value, true) || '-';
  }

  if (isInsurableInterestVehicle(policy_type)) {
    const biPerPerson = coverageByKey(coverages, PolicyCoverageKey.BiPerPerson);
    const piPerAccident = coverageByKey(coverages, PolicyCoverageKey.BiPerAccident);

    value = `${moneyFormatter(biPerPerson?.limit_value, true, 'compact') || '-'} / ${
      moneyFormatter(piPerAccident?.limit_value, true, 'compact') || '-'
    }`;
  }

  return <Paragraph color={isCurrent ? colors.black : colors.grey60}>{value}</Paragraph>;
};

export const DeductibleHeading = ({ policyType }: { policyType: IPolicy['policy_type'] }) => {
  if (isInsurableInterestRealProperty(policyType)) {
    return (
      <Paragraph type="tiny" color={colors.grey60}>
        Deductible
      </Paragraph>
    );
  }

  if (isInsurableInterestVehicle(policyType)) {
    return (
      <Paragraph type="tiny" color={colors.grey60}>
        BI Limits
      </Paragraph>
    );
  }

  return (
    <Paragraph type="tiny" color={colors.grey60}>
      -
    </Paragraph>
  );
};

export const RateChangeCell = ({ rateChange, isCurrent }: TableDataModel) => {
  return (
    <FlexBox alignItemsCenter gap={spacings.px4}>
      <Paragraph type="small" color={isCurrent ? colors.black : colors.grey60}>
        {typeof rateChange === 'number' ? (
          <>
            <Text type="small">
              <TrendingUpIcon color={rateChange > 0 ? colors.statusRed : colors.statusGreen} />
            </Text>
            <Text color={rateChange > 0 ? colors.statusRed : colors.statusGreen} type="small">
              {rateChange > 0 && '+'}
              {moneyFormatter(rateChange, true)}
            </Text>
          </>
        ) : (
          rateChange
        )}
      </Paragraph>
    </FlexBox>
  );
};

export const RateChangeHeading = () => (
  <Paragraph type="tiny" color={colors.grey60}>
    Rate change
  </Paragraph>
);

export const ExpirationDateCell = ({ expiration_date, isCurrent }: TableDataModel) => {
  return (
    <FlexBox alignItemsCenter gap={spacings.px4}>
      <Paragraph type="small" color={isCurrent ? colors.black : colors.grey60}>
        {expirationDateLabel(expiration_date)}
      </Paragraph>
    </FlexBox>
  );
};

export const ExpirationDateHeading = () => (
  <Paragraph type="tiny" color={colors.grey60}>
    Expiration
  </Paragraph>
);

export const tableColumns = (isCstExperienceEnabled: boolean) =>
  [
    columnHelper.display({
      id: 'policy_status',
      cell: ({ row: { original } }) => <PolicyStatusCell {...original} />,
      header: () => null,
      size: 80
    }),
    columnHelper.display({
      id: 'carrier',
      cell: ({ row: { original } }) => <CarrierCell {...original} />,
      header: () => null,
      size: 110
    }),
    isCstExperienceEnabled &&
      columnHelper.display({
        id: 'status',
        cell: ({ row: { original } }) => <StatusCell {...original} />,
        header: () => (
          <Text ml={spacings.px20} type="tiny">
            Status
          </Text>
        ),
        size: 80
      }),
    columnHelper.display({
      id: 'premium',
      cell: ({ row: { original } }) => <PremiumCell {...original} />,
      header: props => {
        return (
          <PremiumHeading
            isPremiumAnnual={props.table.getRow('0').original.isPremiumAnnual}
            setAnnualPremium={props.table.getRow('0').original.setIsPremiumAnnual}
          />
        );
      },
      size: 120
    }),
    columnHelper.display({
      id: 'dwelling',
      cell: ({ row: { original } }) => <DwellingCell {...original} />,
      header: props => {
        const policyType = props.table.getRow('0').original.policy_type;

        return <DwellingHeading policyType={policyType} />;
      },
      size: 80
    }),
    columnHelper.display({
      id: 'deductible',
      cell: ({ row: { original } }) => <DeductibleCell {...original} />,
      header: props => {
        const policyType = props.table.getRow('0').original.policy_type;

        return <DeductibleHeading policyType={policyType} />;
      },
      size: 80
    }),
    columnHelper.display({
      id: 'rate_change',
      cell: ({ row: { original } }) => <RateChangeCell {...original} />,
      header: () => <RateChangeHeading />,
      size: 100
    }),
    columnHelper.display({
      id: 'expiration_date',
      cell: ({ row: { original } }) => <ExpirationDateCell {...original} />,
      header: () => <ExpirationDateHeading />
    }),
    columnHelper.display({
      id: 'actions',
      cell: ({
        row: {
          original: { isCurrent, agency_name, gid, onEdit, disabled },
          index
        }
      }) => {
        if ((index > 0 && !isCurrent) || agency_name === AgencyName.Prediction) {
          return null;
        }
        return (
          <>
            <IconButton
              icon={ButtonIcons.Edit}
              onClick={onEdit}
              disabled={agency_name === AgencyName.Matic || disabled}
              p={0}
              data-for={DISABLED_EDIT_TIP_ID + gid}
              data-tip={DISABLED_EDIT_TIP_TEXT}
            />
            {agency_name === AgencyName.Matic && <Tooltip id={DISABLED_EDIT_TIP_ID + gid} />}
          </>
        );
      },
      header: () => null,
      size: 24
    })
  ].filter(Boolean);
