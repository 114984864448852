import { useQuery } from '@tanstack/react-query';

import { ICallLog } from '../../interfaces';
import api from '../../services/api';

export const getOngoingCallLog = (): Promise<{ call_log: ICallLog | undefined }> =>
  api.get('/api/frontend/call_logs/ongoing');

const useOngoingCallLog = () =>
  useQuery({
    queryKey: ['ongoing_call_log'],
    queryFn: () => getOngoingCallLog(),
    staleTime: Infinity
  });

export default useOngoingCallLog;
