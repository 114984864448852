import { ScheduledInteractionFlow } from './IScheduledCall';
import { IUser } from './IUser';

export enum InteractionType {
  Email = 'email',
  SMS = 'sms',
  Call = 'call'
}

export enum InteractionDirection {
  Outbound = 'outbound',
  Inbound = 'inbound'
}

export type IInteraction = ISMSInteraction | ICallInteraction | IEmailInteraction;

export interface ISMSInteraction {
  id: number;
  type: InteractionType.SMS;
  person_gid: string;
  body: string | null;
  created_at: string | null;
  direction: InteractionDirection | null;
  flow: null | string;
  matic_phone: string | null;
  person_phone: string | null;
  processed_with_role: string | null;
  processed_by: IUser | null;
  contacted_at: string | null;
  scheduled_by: IUser | null;
  sent_by_proposal_builder: boolean | null;
}

export interface ICallInteraction {
  id: number;
  person_gid: string;
  created_at: string | null;
  direction: InteractionDirection | null;
  ended_at: string | null;
  flow: ScheduledInteractionFlow | null | string;
  matic_phone: null | string;
  person_phone: null | string;
  started_at: string | null;
  type: InteractionType.Call;
  processed_with_role: string | null;
  processed_by: IUser | null;
  contact_made: boolean;
  contacted_at: string | null;
  scheduled_by: IUser | null;
  call_record_url: string | null;
}

export interface IEmailInteraction {
  id: number;
  person_gid: string;
  processed_with_role: string;
  processed_by: IUser | null;
  body: string;
  created_at: string | null;
  direction: InteractionDirection | null;
  matic_email: string | null;
  person_email: string | null;
  subject: string | null;
  type: InteractionType.Email;
  sent_by_proposal_builder: boolean | null;
}
