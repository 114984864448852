import React from 'react';

import { useDetachHome } from '../../queries/people/usePersonHomes';
import Modal, { ModalSize } from '../core/Modal';
import Paragraph from '../core/Paragraph';

interface IHomeDelete {
  cancelBtnHandler: () => void;
  confirmBtnHandler: (homeGid: string) => void;
  personGid: string;
  homeGid: string;
  leadId: number | undefined;
}

const HomeDeleteModal = ({
  cancelBtnHandler,
  confirmBtnHandler,
  personGid,
  homeGid,
  leadId
}: IHomeDelete): JSX.Element => {
  const { mutateAsync: detachHome, isPending: isDetachingHome } = useDetachHome();

  return (
    <Modal
      title="Do you want to delete the property?"
      dangerModal
      size={ModalSize.large}
      cancelHandler={cancelBtnHandler}
      confirmHandler={async () => {
        await detachHome({ personGid, homeGid, leadId });
        await confirmBtnHandler(homeGid);
        cancelBtnHandler();
      }}
      confirmText="Yes, delete"
      confirmationInProgress={isDetachingHome}
    >
      <Paragraph>
        You&apos;re going to delete property from the list. You will not see this property in the list of assets related
        to this customer.
      </Paragraph>
    </Modal>
  );
};

export default HomeDeleteModal;
