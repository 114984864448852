import { ICarrier } from './ICarrier';
import { PolicyType } from './IPolicyType';

export enum IPersonOnlineSessionStatus {
  InProgress = 'in_progress',
  ContactRequested = 'contact_requested',
  Failed = 'failed',
  Success = 'success'
}

export enum IPersonOnlineSessionQualificationStatus {
  Unknown = 'unknown',
  Qualified = 'qualified',
  Disqualified = 'disqualified'
}

export enum IPersonOnlineSessionFlowType {
  OnlineBind = 'online_bind',
  DigitalProfile = 'digital_profile'
}

export interface IPersonOnlineSession {
  session_gid: string;
  carrier: ICarrier | undefined;
  policy_type: PolicyType;
  flow_type: IPersonOnlineSessionFlowType;

  status: IPersonOnlineSessionStatus;
  qualification_status: IPersonOnlineSessionQualificationStatus;
  failure_reason: string | null;
  current_page_key: string;
  original_quote_gid: string;
  best_quote_gid: string;

  status_label: string;
  failure_reason_label: string | null;
  current_page_label: string;

  created_at: string;
  last_interaction_at: string;
}
