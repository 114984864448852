import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import { IDataCollection } from '../../../../interfaces';
import { IDataCollectionPageType } from '../../../../interfaces/IDataCollection';
import { UserRole } from '../../../../interfaces/IUser';
import usePersonAllPolicies from '../../../../queries/people/person_policies/usePersonAllPolicies';
import { useCleanupConfirmedDataCollection } from '../../_hooks';
import CollectedBy from '../../tabs/CollectedInfoTab/_components/CollectedBy';

export const DATA_COLLECTION_CONFIRMED_BY_ISR_KEY = 'data-collection-collected-by-isr-confirmed';

const CollectedPagesByISR = ({
  leadGid,
  personGid,
  dataCollection
}: {
  leadGid: string;
  personGid: string;
  dataCollection: IDataCollection;
}) => {
  const policyTypes = dataCollection.pages
    .filter(page =>
      [IDataCollectionPageType.HomeProfile, IDataCollectionPageType.VehicleProfile].includes(page.page_type)
    )
    .flatMap(page => page.opportunities)
    .filter(Boolean)
    .map(opp => opp.policy_type);

  const { data: personPolicies } = usePersonAllPolicies({
    personGid,
    filters: { policy_types: policyTypes }
  });

  const homeProfilePage = dataCollection.pages.find(page => page.page_type === IDataCollectionPageType.HomeProfile);
  const homeEligibilityPage = dataCollection.pages.find(
    page => page.page_type === IDataCollectionPageType.HomeEligibility
  );
  const vehicleProfilePage = dataCollection.pages.find(
    page => page.page_type === IDataCollectionPageType.VehicleProfile
  );
  const pageCompletedByISR = dataCollection.pages.find(page => page.originally_completed_by?.role === UserRole.ISR);
  const pageCompletedBySuperISR = dataCollection.pages.find(
    page => page.originally_completed_by?.role === UserRole.SuperISR
  );
  const assetsGidsFromPolicies = personPolicies?.flatMap(policy => (policy.assets || []).map(asset => asset.gid)) || [];
  // eslint-disable-next-line max-len
  const collectedTitle = `${(pageCompletedByISR || pageCompletedBySuperISR)?.originally_completed_by?.role_label}(${(pageCompletedByISR || pageCompletedBySuperISR)?.originally_completed_by?.first_name}) already completed:`;
  const collectedHomePages = `${(homeProfilePage?.opportunities || []).map(opp => {
    const dataCollectionResult = homeProfilePage?.is_completed && opp.is_data_collection_enabled ? '✅' : '❌';
    const firstAssetGid = (opp.assets || [])[0]?.gid || '';
    const policyPresence = assetsGidsFromPolicies.includes(firstAssetGid);
    const eligibilityResult = homeEligibilityPage?.is_completed && dataCollectionResult === '✅' ? '✅' : '❌';
    const policyResult = pageCompletedBySuperISR ? `\n${opp.title} insurance ${policyPresence ? '✅' : '❌'}` : '';
    // eslint-disable-next-line max-len
    return `\n${opp.title} collection ${dataCollectionResult}${policyResult}\n${opp.title} eligibility ${eligibilityResult}`;
  })}`;

  const collectedVehiclePages = `${(vehicleProfilePage?.opportunities || []).map(opp => {
    const dataCollectionResult = vehicleProfilePage?.is_completed && opp.is_data_collection_enabled ? '✅' : '❌';
    const policyPresence = (opp.assets || []).some(asset => assetsGidsFromPolicies.includes(asset.gid));

    const policyResult = `\n${opp.title} insurance ${policyPresence ? '✅' : '❌'}`;
    return `\n${opp.title} collection ${dataCollectionResult}${policyResult}`;
  })}`;

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  useCleanupConfirmedDataCollection(DATA_COLLECTION_CONFIRMED_BY_ISR_KEY);

  return (
    <CollectedBy
      message={collectedTitle + collectedHomePages + (pageCompletedBySuperISR ? collectedVehiclePages : '')}
      onConfirm={() =>
        localStorage.setItem(`${DATA_COLLECTION_CONFIRMED_BY_ISR_KEY}-${leadGid}`, Date.now().toString())
      }
    />
  );
};

export default CollectedPagesByISR;
