/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { Field, FieldProps } from 'formik';

import colors from '../../../../theme/colors';
import { InputSize, LabelSize } from '../../../../theme/variables';
import Textarea, { TextareaProps } from '../../inputs/Textarea';
import Text from '../../Text';
import FormError from './FormError';
import LabeledInput from './LabeledInput';

interface TextareaFieldProps extends TextareaProps {
  label: string | JSX.Element;
  id: string;
  name: string;
  required?: boolean;
  inline?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  preserveErrorSpace?: boolean;
}
const TextareaField = ({
  label,
  id,
  name,
  required,
  inline,
  customLabelCss,
  labelSize,
  inputSize,
  preserveErrorSpace = true,
  ...rest
}: TextareaFieldProps): JSX.Element => {
  return (
    <LabeledInput
      id={id}
      required={required}
      label={label}
      inline={inline}
      disabled={rest.disabled}
      customCss={customLabelCss}
      labelSize={labelSize}
      inputSize={inputSize}
      secondary={false}
    >
      <Field name={name}>
        {({ field, meta, form }: FieldProps) => {
          const hasError = meta.error && meta.touched;
          const containsNumber = /\d/.test(field.value);

          return (
            <>
              <Textarea
                {...field}
                {...rest}
                disabled={rest.disabled || form.status === 'disabled'}
                id={id}
                inline={inline}
                aria-invalid={!!hasError}
                aria-errormessage={`${id}-error`}
              />
              {containsNumber && (
                <Text type="small" color={colors.statusOrange}>
                  Verify that {typeof label === 'string' ? label.toLowerCase() : 'text'} does not contain sensitive
                  data. For example, credit card or banking information
                </Text>
              )}
              {inline && !!hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />}
              {!inline && (preserveErrorSpace || !!hasError) && (
                <FormError id={id} hasError={!!hasError} error={meta.error} />
              )}
            </>
          );
        }}
      </Field>
    </LabeledInput>
  );
};

export default TextareaField;
