import React from 'react';

import { Checkbox, ColumnGenerator, DropDownMenu, MailLink, Table } from '../../../components/common';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import TelephonyActions from '../../../components/core/TelephonyActions';
import TelephonyTooltip from '../../../components/core/TelephonyTooltip';
import Text from '../../../components/core/Text';
import { SORT_ORDER_DESC } from '../../../constants';
import { IRelatedPerson } from '../../../interfaces/IPerson';
import useMergePerson from '../../../queries/people/useMergePerson';
import { spacings } from '../../../theme/variables';
import { dateFormatter, phoneFormatter } from '../../../utils/formatter';
import DOBWrapper from '../../GuidedSellingExperience/tabs/CollectedInfoTab/_components/DOBWrapper';
import MergePeople from './MergePeople';

const DEFAULT_SORTING = { sort_column: 'gid', sort_order: SORT_ORDER_DESC };

const renderCocustomer = ({ original }: { original: IRelatedPerson }) =>
  original.cocustomer && <Checkbox name={`cocustomer-read-only-${original.gid}`} value={true} onChange={() => null} />;

const renderLicenseNumber = ({ original: { license_number } }: { original: IRelatedPerson }) =>
  license_number && (
    <div>
      <Copy value={license_number} className="fs-mask">
        <Text>{license_number}</Text>
      </Copy>
    </div>
  );

const RelatedPeopleList = ({
  personGid,
  relatedPeople,
  onEditPerson,
  onAddPerson,
  onPeopleMerged
}: {
  personGid: string;
  relatedPeople: IRelatedPerson[];
  onEditPerson: (person: IRelatedPerson) => void;
  onAddPerson: () => void;
  onPeopleMerged: () => void;
}) => {
  const [personToMerge, setPersonToMerge] = React.useState<IRelatedPerson | null>(null);

  const { mutateAsync: mergePerson } = useMergePerson();

  const renderPhone = ({ original: { phone } }: any) => (
    <FlexBox>
      <TelephonyTooltip phone={phone} personGid={personGid}>
        <Copy withIcon={false} value={phoneFormatter(phone)} className="fs-mask">
          <Text singleLine>{phoneFormatter(phone)}</Text>
        </Copy>
      </TelephonyTooltip>
    </FlexBox>
  );
  const getDropDownList = (person: IRelatedPerson) => {
    const items = [
      {
        label: 'Edit',
        action: () => onEditPerson(person)
      }
    ];

    if (relatedPeople.length > 1) {
      items.push({
        label: 'Merge',
        action: () => setPersonToMerge(person)
      });
    }

    return items;
  };

  const renderActions = ({ original }: { original: IRelatedPerson }) => (
    <DropDownMenu wrapperTestId="household-member-actions" items={getDropDownList(original)} maxHeight={264} right />
  );

  const columns = [
    ColumnGenerator.stretchable({
      minWidth: ColumnGenerator.WIDTH_L,
      className: 'fs-mask',
      Header: 'First name',
      accessor: 'first_name'
    }),
    ColumnGenerator.stretchable({
      minWidth: ColumnGenerator.WIDTH_L,
      className: 'fs-mask',
      Header: 'Last name',
      accessor: 'last_name'
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      Header: 'Co-customer',
      sortable: false,
      Cell: renderCocustomer
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XXL,
      className: 'fs-mask',
      sortable: false,
      Header: 'Phone',
      Cell: renderPhone
    }),
    ColumnGenerator.stretchable({
      minWidth: ColumnGenerator.WIDTH_L,
      sortable: false,
      className: 'fs-mask',
      Header: 'Email',
      Cell: ({ original: { email } }: { original: IRelatedPerson }) =>
        email && (
          <FlexBox>
            <TelephonyActions email={email} personGid={personGid}>
              <MailLink className="text-truncate" mail={email} />
            </TelephonyActions>
          </FlexBox>
        )
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      className: 'fs-mask',
      Header: 'DOB',
      Cell: ({ original: { date_of_birth } }: any) => <DOBWrapper>{dateFormatter(date_of_birth) || null}</DOBWrapper>
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      className: 'fs-mask',
      Header: 'License state',
      sortable: false,
      accessor: 'license_state'
    }),
    ColumnGenerator.stretchable({
      minWidth: ColumnGenerator.WIDTH_L,
      className: 'fs-mask',
      Header: 'License number',
      sortable: false,
      Cell: renderLicenseNumber
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XS,
      sortable: false,
      Cell: renderActions
    })
  ];

  return (
    <>
      <FlexBox alignItemsCenter justifySpaceBetween pb={spacings.px24}>
        <Text type="large" bold>
          Household members
        </Text>
        <Button size={ButtonSize.Small} onClick={onAddPerson}>
          + Add Household Member
        </Button>
      </FlexBox>
      <Container>
        <Table
          testId="customer-related-people"
          data={relatedPeople}
          columns={columns}
          defaultSorting={DEFAULT_SORTING}
          manual={false}
          noDataMessage="No household members found"
        />
        {personToMerge && (
          <MergePeople
            person={personToMerge}
            personsForMerge={relatedPeople}
            cancelBtnHandler={() => setPersonToMerge(null)}
            confirmBtnHandler={({ toGid, fromGid }) =>
              mergePerson({
                origin_person_gid: fromGid,
                destination_gid: toGid
              }).then(() => {
                setPersonToMerge(null);
                onPeopleMerged();
              })
            }
          />
        )}
      </Container>
    </>
  );
};

export default RelatedPeopleList;
