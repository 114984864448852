/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import { useDocumentTitle } from '../../hooks';
import useDashboards, { IDashboard } from '../../queries/dashboards/useDashboards';
import colors from '../../theme/colors';

const MetricsDashboards = (): JSX.Element => {
  const { data: dashboards } = useDashboards();
  useDocumentTitle('Metrics dashboard');

  return (
    <>
      <Heading
        type="h1"
        customCss={css`
          text-align: center;
          margin-top: 20px;
        `}
      >
        Dashboards
      </Heading>
      {dashboards && (
        <FlexBox columnDirection>
          {dashboards.map((dashboard: IDashboard) => (
            <iframe
              css={css`
                border: 4px solid ${colors.grey10};
                border-radius: 8px;
                margin: 40px;
              `}
              height="800px"
              key={dashboard.dashboard_url}
              src={dashboard.dashboard_url}
              title={dashboard.dashboard_url}
              loading="lazy"
            ></iframe>
          ))}
        </FlexBox>
      )}
    </>
  );
};

export default MetricsDashboards;
