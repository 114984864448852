import { Field } from 'formik';
import React, { useCallback } from 'react';

// utils
import { dig } from '../../../../../../utils/object';
import ErrorCol from '../../../../ErrorCol';
import { Select } from '../../../../FormikComponents';
import { getFieldValue } from '../../helpers';
// components
import IncidentLabel from '../IncidentLabel';

const IncidentField = ({ showFieldError, name, labelId, formValue, index, errors, replace, loading, options }: any) => {
  const onChange = useCallback(
    (value: any) => {
      const incident = options.find(({ gid }: any) => gid === value);
      replace(index, { ...formValue, type_gid: value, key: dig(incident, 'key') });
    },
    [replace, formValue, options, index]
  );

  const label = `${formValue.type} type`;

  return (
    <ErrorCol
      sm={12}
      error={getFieldValue(errors, `${name}.${index}`, 'type_gid') as any}
      when={showFieldError('type_gid')}
    >
      <IncidentLabel id={labelId} label={label} />
      <Field
        small
        disabled={loading}
        labelName="description"
        valueName="gid"
        name={`${name}[${index}].type_gid`}
        component={Select}
        options={options}
        onChange={onChange}
      />
    </ErrorCol>
  );
};

export default IncidentField;
