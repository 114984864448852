import Container from '../../../../components/core/Container';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useSuspenseScoutingStrategyConfig } from '../../../../queries/scouting_strategies/useScoutingStrategy';
import parseHtmlString from '../../../../utils/htmlStringParser';

const Case = () => {
  const { callLogId, personGid, leadGid, candidateGid } = useGuidedSellingExperienceContext();

  const { data: scoutingStrategy } = useSuspenseScoutingStrategyConfig({
    callLogId,
    personGid,
    leadGid,
    candidateGid,
    scoutingStrategyConfigKey: 'lead_summary_case'
  });

  if (!scoutingStrategy.steps.length) {
    return <Text>No information about this case available.</Text>;
  }

  return (
    <Container>
      {scoutingStrategy.steps.map(({ id, description }) => (
        <Paragraph key={id}>{parseHtmlString(description)}</Paragraph>
      ))}
    </Container>
  );
};

export default Case;
