import React from 'react';

import Button, { ButtonSize } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Text from '../../../../components/core/Text';
import { IOpportunityPolicies, IQuote } from '../../../../interfaces';
import { ICurrentDisposition } from '../../../../interfaces/IDisposition';
import { IOpportunity } from '../../../../interfaces/IOpportunity';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import CreateOpportunityModal from './CreateOpportunityModal';
import EditOpportunityModal from './EditOpportunityModal';
import OpportunityRow from './OpportunityRow';

const Opportunities = ({
  personGid,
  leadId,
  opportunities,
  currentDisposition,
  onOpportunitiesUpdated,
  allowCreatingOpportunities
}: {
  personGid: string;
  leadId: number;
  opportunities: (IOpportunity & IOpportunityPolicies & { customerChoice: IQuote | undefined })[];
  allowCreatingOpportunities: boolean;
  currentDisposition: ICurrentDisposition;
  onOpportunitiesUpdated: () => void;
}) => {
  const [editedOpportunity, setEditedOpportunity] = React.useState<IOpportunity>();
  const [showNewOpportunityModal, setShowNewOpportunityModal] = React.useState<boolean>();

  return (
    <FlexBox columnDirection>
      <FlexBox justifySpaceBetween alignItemsCenter pb={spacings.px24}>
        <Text type="large" bold>
          Opportunities
        </Text>
        <Button
          size={ButtonSize.Small}
          onClick={() => setShowNewOpportunityModal(true)}
          disabled={!allowCreatingOpportunities}
        >
          + Add Opportunity
        </Button>
      </FlexBox>

      <Container backgroundColor={colors.white} pv={spacings.px8} ph={spacings.px16} border>
        {opportunities.map(opportunity => (
          <Container key={opportunity.id} pv={spacings.px8}>
            <OpportunityRow
              personGid={personGid}
              opportunity={opportunity}
              currentDisposition={currentDisposition}
              onEditOpportunity={opportunity.primary ? null : setEditedOpportunity}
            />
          </Container>
        ))}

        {editedOpportunity && (
          <EditOpportunityModal
            leadId={leadId}
            editedOpportunity={editedOpportunity}
            cancelHandler={() => setEditedOpportunity(undefined)}
            confirmHandler={() => {
              onOpportunitiesUpdated();
              return setEditedOpportunity(undefined);
            }}
          />
        )}
        {showNewOpportunityModal && (
          <CreateOpportunityModal
            leadId={leadId}
            personGid={personGid}
            cancelHandler={() => setShowNewOpportunityModal(false)}
            confirmHandler={() => {
              onOpportunitiesUpdated();
              return setShowNewOpportunityModal(false);
            }}
          />
        )}
      </Container>
    </FlexBox>
  );
};

export default Opportunities;
