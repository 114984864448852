import moment from 'moment';

export const ASC = 1;
export const DESC = -1;

const getNestedProp = (obj: any, nesting: any) =>
  nesting.split('.').reduce((acc: any, nest: any) => acc[nest] || '', obj);

const compareDates = (aDate: any, bDate: any) => {
  if (!aDate) {
    return DESC;
  }
  if (!bDate) {
    return ASC;
  }

  return moment(aDate).diff(moment(bDate));
};

const compareNumbers = (aNum: any, bNum: any) => {
  if (!aNum) {
    return DESC;
  }
  if (!bNum) {
    return ASC;
  }

  return Number(aNum) - Number(bNum);
};

const compareStrings = (aStr: any, bStr: any) => {
  if (!bStr) {
    return DESC;
  }
  if (!aStr) {
    return ASC;
  }

  return aStr.toUpperCase() >= bStr.toUpperCase() ? ASC : DESC;
};

const sort = (array: any, fieldName: any, compare: any) =>
  fieldName ? [...array].sort((a, b) => compare(a, b)) : array;

export const sortByDate = (array: any, fieldName: any, order = DESC) =>
  sort(array, fieldName, (a: any, b: any) => order * compareDates(a[fieldName], b[fieldName]));

export const sortByNumber = (array: any, fieldName: any, order = DESC) =>
  sort(array, fieldName, (a: any, b: any) => order * compareNumbers(a[fieldName], b[fieldName]));

export const sortByString = (array: any, fieldName: any, order = DESC) =>
  sort(
    array,
    fieldName,
    (a: any, b: any) => order * compareStrings(getNestedProp(a, fieldName), getNestedProp(b, fieldName))
  );
