import featureFlags from '../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';

const useDataEditingForbidden = () => {
  const { isPersonLinkedToActiveCall } = useGuidedSellingExperienceContext();
  if (!featureFlags.activeCallRestriction) {
    return false;
  }

  return !isPersonLinkedToActiveCall;
};

export default useDataEditingForbidden;
