/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import colors from '../../theme/colors';
import { heading1Css, heading2Css, heading3Css, heading4Css } from '../../theme/typography';
import { spacingKeys, Spacings, spacingsCss } from '../../theme/variables';

export interface HeadingProps extends React.HTMLProps<HTMLHeadingElement>, Spacings {
  type?: 'h1' | 'h2' | 'h3' | 'h4';
  customCss?: SerializedStyles;
  color?: (typeof colors)[keyof typeof colors];
}

const appliedCss = {
  h1: heading1Css,
  h2: heading2Css,
  h3: heading3Css,
  h4: heading4Css
};

const Heading = ({ customCss, children, color, type = 'h4', ...rest }: HeadingProps): JSX.Element => {
  const spacingProps = pick(spacingKeys, rest);
  const restOfProps = omit(spacingKeys, rest);
  const Component = type;

  return (
    <Component
      {...restOfProps}
      css={css`
        ${appliedCss[type]};
        ${spacingsCss(spacingProps)};
        ${color ? `color: ${color};` : ''}
        ${customCss}
      `}
    >
      {children}
    </Component>
  );
};

export default Heading;
