/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import featureFlags from '../../../constants/featureFlags';
import ICallLog from '../../../interfaces/ICallLog';
import { IUserWithRole } from '../../../interfaces/IUser';
import guidedSellingExperienceNavigation from '../../../pages/GuidedSellingExperience/navigation';
import useLeadAssignees from '../../../queries/leads/assignees/useLeadAssignees';
import useMovePersonToPipeline from '../../../queries/people/useMovePersonToPipeline';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import Select from '../../common/Select/Select';
import Button from '../buttons/Button';
import FlexBox from '../FlexBox';
import { ChevronLeftDouble, ChevronRightDouble, PhoneIcon, PhoneMissedIcon, TickIcon, TransferIcon } from '../icons';
import Text from '../Text';
import { isAuthorizedUserFirstInConference } from './helpers';

const CallIndicator = ({
  currentCallLog,
  title,
  type,
  isFakeLead,
  action,
  participants = [],
  isConference,
  canAssignToAgent,
  collapsed,
  setCollapsed
}: {
  currentCallLog: ICallLog;
  title: string;
  type: string;
  isFakeLead: boolean;
  action?: JSX.Element;
  participants?: IUserWithRole[];
  isConference?: boolean;
  canAssignToAgent?: boolean;
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
}): JSX.Element => {
  const { person_gid: personGid, candidate_gid: candidateGid, lead_gid: leadGid, lead_id: leadId } = currentCallLog;

  const { data: assignees } = useLeadAssignees({ leadId, licensed: true });
  const navigate = useNavigate();

  const [setAssigneeId, setSelectedAssigneeId] = React.useState();
  const [isMovingToPipeline, setMovingToPipeline] = React.useState(false);

  const lastCallParticipantId = participants[participants.length - 1]?.id;
  const agentForPipeline = setAssigneeId || lastCallParticipantId;
  const pipelineUserId = isAuthorizedUserFirstInConference(participants) ? agentForPipeline : authInfo.currentUserId;
  const showAssigneeSelect =
    personGid &&
    isConference &&
    isAuthorizedUserFirstInConference(participants) &&
    canAssignToAgent &&
    !featureFlags.hideCallIndicatorAssignmentControls;
  const showAssignButton =
    personGid &&
    isConference &&
    !isAuthorizedUserFirstInConference(participants) &&
    canAssignToAgent &&
    !featureFlags.hideCallIndicatorAssignmentControls;

  const moveToPipeline = useMovePersonToPipeline({
    personGid: personGid as string,
    candidateGid: candidateGid as string,
    pipelineUserId: pipelineUserId as number,
    leadGid,
    insuranceCategory: currentCallLog.insurance_category
  });

  const movePersonToPipeline = async () => {
    setMovingToPipeline(true);

    await moveToPipeline()
      .then(response => {
        if (response?.lead) {
          navigate(guidedSellingExperienceNavigation.forLead({ lead_gid: response.lead.gid }));
        } else {
          navigate(guidedSellingExperienceNavigation.forCall({ call_log_id: currentCallLog.id }));
        }
      })
      .finally(() => setMovingToPipeline(false));
  };

  const Icon = React.useMemo(() => {
    if (currentCallLog.is_call_ended) {
      return PhoneMissedIcon;
    }
    if (!canAssignToAgent) {
      return TickIcon;
    }
    if (isConference) {
      return TransferIcon;
    }
    return PhoneIcon;
  }, [currentCallLog.is_call_ended, isConference, canAssignToAgent]);

  return (
    <>
      {collapsed ? (
        <div
          css={css`
            background: ${colors.grey80};
            opacity: 0.6;
            border-radius: 8px 0px 0px 8px;
            box-sizing: border-box;
            box-shadow: 4px 4px 4px hsl(0deg 0% 0% / 20%);
            cursor: grab;
            width: 50px;
            height: 74px;

            border-left: 4px solid ${colors.grey80};
            border-top: 4px solid ${colors.grey80};
            border-bottom: 4px solid ${colors.grey80};

            .expand-button {
              visibility: hidden;
            }

            :hover {
              border-top: 4px solid ${colors.aqua};
              border-bottom: 4px solid ${colors.aqua};
              border-left: 4px solid ${colors.aqua};
              opacity: 1;
            }

            :hover .expand-button {
              visibility: visible;
            }
          `}
        >
          <FlexBox fitParentHeight mt={spacings.px4} columnDirection alignItemsCenter>
            <ChevronLeftDouble
              className="expand-button"
              width={20}
              height={20}
              color={colors.aqua}
              css={css`
                cursor: pointer;
              `}
              onClick={() => setCollapsed(false)}
            />
            <Icon width={18} height={18} color={currentCallLog.is_call_ended ? colors.statusRed : colors.aqua} />
          </FlexBox>
        </div>
      ) : (
        <div
          css={css`
            background: ${colors.grey80};
            border-radius: 8px;
            width: 420px;
            display: flex;
            flex-direction: column;
            box-shadow: 4px 4px 4px hsl(0deg 0% 0% / 20%);
            cursor: grab;
          `}
        >
          <FlexBox
            justifySpaceBetween
            mv={16}
            mh={16}
            customCss={css`
              height: 56px;
            `}
          >
            <FlexBox
              customCss={css`
                display: flex;
                flex: 1 1 0%;
                align-items: center;
                min-width: 0px;
              `}
            >
              <FlexBox alignItemsCenter pr={12}>
                <Icon height={16} width={16} color={currentCallLog.is_call_ended ? colors.statusRed : colors.aqua} />
              </FlexBox>

              <FlexBox columnDirection>
                <FlexBox
                  justifySpaceBetween
                  alignItemsCenter
                  customCss={css`
                    margin-bottom: 10px;
                  `}
                >
                  <Text
                    color={colors.white}
                    customCss={css`
                      a {
                        position: relative;
                        color: ${colors.aqua};
                      }
                      a::after {
                        position: absolute;
                        bottom: -2px;
                        left: 0;
                        width: 100%;
                        height: 1px;
                        background-color: ${colors.aqua};
                        content: '';
                      }
                    `}
                    bold
                    className="fs-mask"
                  >
                    {isFakeLead ? (
                      title
                    ) : (
                      <Link to={guidedSellingExperienceNavigation.forCall({ call_log_id: currentCallLog.id })}>
                        {title}
                      </Link>
                    )}
                  </Text>
                  <FlexBox ml={6}>{action}</FlexBox>
                </FlexBox>
                <Text type="small" color={colors.white}>
                  {type}
                </Text>
              </FlexBox>

              {showAssignButton && (
                <FlexBox
                  justifyRight
                  customCss={css`
                    flex: 1;
                  `}
                >
                  <Button
                    loading={isMovingToPipeline}
                    onClick={() => movePersonToPipeline()}
                    customCss={css`
                      white-space: nowrap;
                      margin: 0 4px;
                    `}
                  >
                    Assign to you
                  </Button>
                </FlexBox>
              )}
            </FlexBox>
            <FlexBox
              alignItemsCenter
              customCss={css`
                cursor: pointer;
              `}
              onClick={() => setCollapsed(true)}
            >
              <ChevronRightDouble color={colors.aqua} />
              <Text ml={spacings.px8} color={colors.aqua}>
                Hide
              </Text>
            </FlexBox>
          </FlexBox>
          {showAssigneeSelect && (
            <>
              <div
                css={css`
                  border: 1px solid ${colors.grey60};
                `}
              />
              <FlexBox columnDirection mv={16} mh={16}>
                <Text color={colors.white} pb={spacings.px4}>
                  Assign customer to agent
                </Text>
                <FlexBox alignItemsCenter>
                  <div
                    css={css`
                      flex: 1;
                      margin-right: 10px;
                      .react-select {
                        margin: 0;
                      }
                    `}
                  >
                    <Select
                      options={assignees?.map(assignee => ({ key: assignee.id, value: assignee.name })) || []}
                      onChange={assigneeId => {
                        setSelectedAssigneeId(assigneeId);
                      }}
                      value={agentForPipeline}
                      menuPlacement="top"
                    />
                  </div>
                  <FlexBox>
                    <Button loading={isMovingToPipeline} onClick={() => movePersonToPipeline()}>
                      Assign
                    </Button>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default CallIndicator;
