/** @jsxImportSource @emotion/react */
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../components/core/FlexBox';
import { PuzzlePieceIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { dispositionLabel } from '../../../../components/DispositionsModals/dispositionsHelper';
import { useToggle } from '../../../../hooks';
import { IDispositionVersion, ILead } from '../../../../interfaces';
import { ILeadDispositions } from '../../../../interfaces/IDisposition';
import useRollbackDisposition from '../../../../queries/leads/dispositions/useRollbackDisposition';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import DispositionsVersionModal from './_components/DispositionsVersionsModal';
import UserRoleTag from './_components/UserRoleTag';

const LeadStatuses = ({
  lead,
  leadDispositions,
  dispositionsVersions
}: {
  lead: ILead;
  leadDispositions: ILeadDispositions;
  dispositionsVersions: IDispositionVersion[];
}) => {
  const { mutateAsync: rollbackDisposition, isPending: isDispositionMutating } = useRollbackDisposition();
  const queryClient = useQueryClient();
  const [openedDispositionVersionsModal, toggleOpenedDispositionVersionsModal] = useToggle(false);
  const isDispositionsUpdated = !!dispositionsVersions.find(d => d.deleted_by);

  return (
    <>
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <PuzzlePieceIcon />
        <Paragraph type="small" bold>
          Lead statuses
        </Paragraph>
        {isDispositionsUpdated && (
          <Button
            onClick={() => {
              analytics.track('Lead operations viewed', { item: 'lead_dispositions', lead_gid: lead.gid });
              toggleOpenedDispositionVersionsModal();
            }}
            variant={ButtonVariant.PlainText}
          >
            <Text underline dotted color={colors.statusOrange} type="small">
              Updated
            </Text>
          </Button>
        )}
      </FlexBox>
      <FlexBox columnDirection gap={spacings.px4} ml={spacings.px32} mt={spacings.px8} mb={spacings.px16}>
        {leadDispositions.dispositions.map((d, index) => {
          const canRollbackDisposition =
            index > 0 && leadDispositions.dispositions.length - 1 === index && authInfo.features.rollback_dispositions;

          return (
            <FlexBox
              justifySpaceBetween
              alignItemsCenter
              key={d.disposition_type}
              pv={canRollbackDisposition ? 0 : spacings.px4}
            >
              <FlexBox alignItemsCenter gap={spacings.px4}>
                <Text type="small">{dispositionLabel(d)}</Text>
                <UserRoleTag processedWithRole={d.assignee_role?.label} />
              </FlexBox>

              {canRollbackDisposition && (
                <IconButton
                  icon={ButtonIcons.Delete}
                  color={colors.black}
                  disabled={isDispositionMutating}
                  onClick={() => {
                    analytics.track('Lead operations updated', {
                      item: 'lead_disposition',
                      lead_gid: lead.gid
                    });
                    rollbackDisposition({ leadId: lead.id }).then(() => queryClient.invalidateQueries());
                  }}
                />
              )}
            </FlexBox>
          );
        })}
      </FlexBox>
      {openedDispositionVersionsModal && (
        <DispositionsVersionModal
          cancelHandler={toggleOpenedDispositionVersionsModal}
          dispositionsVersions={dispositionsVersions}
        />
      )}
    </>
  );
};

export default LeadStatuses;
