import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Tag from '../../../../components/core/Tag';
import { isServiceLead } from '../../../../components/DispositionsModals/dispositionsHelper';
import { Translations } from '../../../../constants';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { ICurrentDisposition, ILead } from '../../../../interfaces';
import { ISourceDimensions, LeadTypes } from '../../../../interfaces/ISourceDimensions';
import useLeadDispositions from '../../../../queries/leads/dispositions/useLeadDispositions';
import colors from '../../../../theme/colors';

const leadTypeLabel = ({
  sourceDimensions,
  lead,
  currentDisposition
}: {
  sourceDimensions: ISourceDimensions | undefined;
  lead: ILead | undefined;
  currentDisposition: ICurrentDisposition | undefined;
}) => {
  if (featureFlags.cstGuidedSellingExperience && isServiceLead(currentDisposition)) {
    return 'Service';
  }

  const leadType = sourceDimensions?.lead_type || lead?.source_dimensions?.lead_type || LeadTypes.NewBusiness;

  return Translations.leadType(leadType) || '';
};

const LeadType = () => {
  const { sourceDimensions, lead } = useGuidedSellingExperienceContext();
  const { data: leadDispositions, isFetched } = useLeadDispositions(lead?.id);

  if (!isFetched) {
    return null;
  }

  const label = leadTypeLabel({
    sourceDimensions,
    lead,
    currentDisposition: leadDispositions?.current_disposition
  });

  return (
    <FlexBox justifyCenter columnDirection>
      <Tag testId="lead-type" label={label} backgroundColor={colors.cherise} />
    </FlexBox>
  );
};

export default LeadType;
