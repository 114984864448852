import { useMutation } from '@tanstack/react-query';

import { AutoCoveragesValidation } from '../../interfaces/IAutoCoveragesOptions';
import api from '../../services/api';

interface ValidateAutoCoveragesOptionsParams {
  coverages: Record<string, string>;
  ownership: string;
  state: string;
  asset_gid: string;
}

export const validateAutoCoveragesOptions = ({
  coverages,
  ownership,
  state,
  asset_gid
}: ValidateAutoCoveragesOptionsParams): Promise<AutoCoveragesValidation> =>
  api.post('/api/frontend/auto_coverages/validation', { body: { coverages, ownership, state, asset_gid } });

const useValidateAutoCoveragesOptions = (
  onSuccess: (data: AutoCoveragesValidation, variables: ValidateAutoCoveragesOptionsParams) => void
) => useMutation({ mutationFn: validateAutoCoveragesOptions, onSuccess });

export default useValidateAutoCoveragesOptions;
