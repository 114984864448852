import { createContext } from 'react';

import * as ActionCable from '../services/ActionCable';

let lastConsumer: ActionCable.Consumer | null = null;

const createConsumer = (authToken: string | undefined | null) => {
  if (!authToken) {
    return null;
  }

  const websocketEndpoint = import.meta.env.DEV
    ? `${import.meta.env.VITE_APP_WSS_URL}?auth_token=${authToken}`
    : `wss://ws-${window.location.host}?auth_token=${authToken}`;

  // ActionCable.logger.enabled = import.meta.env.DEV;
  return ActionCable.createConsumer(websocketEndpoint);
};

export const setupCableConsumer = (authToken: string | undefined | null) => {
  if (lastConsumer) {
    // This branch should be executed in either of cases:
    // 1. After Entra token is refreshed. As a result, we need to setup a new connection by providing
    // fresh auth token to WS backend.
    // 2. Right after manual logout. In this case after disconnect() no new consumer will actually be created.
    lastConsumer.disconnect();
    lastConsumer = createConsumer(authToken);
    return lastConsumer;
  }

  // This branch should be executed after user authenticates to Entra and AMP back-end
  lastConsumer = createConsumer(authToken);

  return lastConsumer;
};

export const ActionCableContext = createContext<ActionCable.Consumer | null>(null);
