import { ILeadMode } from '../interfaces/IDashboardLead';
import { DispositionType } from '../interfaces/IDisposition';
import { LoanPurpose } from '../interfaces/ILoan';
import { NoteKind } from '../interfaces/INote';
import { Gender, MaritalStatus, RelationKind } from '../interfaces/IPerson';
import { HomeUsageType, OwnershipType, VehicleUsageType } from '../interfaces/IPersonAsset';
import { PolicyPaymentMethod, PolicySource, PolicyStatus, PolicyTransactionType } from '../interfaces/IPolicy';
import {
  AttributedMarketingMediums,
  BusinessTypes,
  LeadCaptureMethods,
  LeadTypes,
  PrimaryOpportunities,
  SourceProducts
} from '../interfaces/ISourceDimensions';
import { TaskKind } from '../interfaces/ITask';
import { VehicleKind } from '../interfaces/IVehicle';

const appTranslations = {
  // People -----------------------------------------
  get maritalStatusOptions(): { key: MaritalStatus; value: string }[] {
    return window.__amp_config.marital_status;
  },
  maritalStatus(maritalStatus: MaritalStatus | null) {
    return this.maritalStatusOptions.find(option => option.key === maritalStatus)?.value || '—';
  },
  get genderOptions(): { key: Gender; value: string }[] {
    return window.__amp_config.gender;
  },
  gender(gender: string | null) {
    return this.genderOptions.find(option => option.key === gender)?.value || '—';
  },
  get educationOptions(): { key: string; value: string }[] {
    return window.__amp_config.education;
  },
  education(education: string | null) {
    return this.educationOptions.find(option => option.key === education)?.value || '—';
  },
  get occupationTypeOptions(): { key: string; value: string }[] {
    return window.__amp_config.occupation_type;
  },
  occupation(occupation: string | null) {
    return this.occupationTypeOptions.find(option => option.key === occupation)?.value || '—';
  },
  get licenseStatusOptions(): { key: string; value: string }[] {
    return window.__amp_config.license_status;
  },
  get relationshipOptions(): { key: RelationKind; value: string }[] {
    return window.__amp_config.relation_kind;
  },
  relationKind(relationKind: RelationKind | null) {
    return this.relationshipOptions.find(option => option.key === relationKind)?.value || 'Other';
  },
  // Homes --------------------------------------------
  get homeUsageOptions(): { key: HomeUsageType; value: string }[] {
    return window.__amp_config.home_usage;
  },
  homeUsage(homeUsageType: HomeUsageType | null) {
    return this.homeUsageOptions.find(option => option.key === homeUsageType)?.value;
  },
  get homeOwnershipOptions(): { key: OwnershipType; value: string }[] {
    return window.__amp_config.home_ownership;
  },
  get propertyTypeOptions(): { key: string; value: string }[] {
    return window.__amp_config.property_type;
  },
  propertyType(propertyType: string | null) {
    return this.propertyTypeOptions.find(option => option.key === propertyType)?.value || '—';
  },
  get storiesOptions(): { key: string; value: string }[] {
    return window.__amp_config.stories;
  },
  get constructionOptions(): { key: string; value: string }[] {
    return window.__amp_config.construction;
  },
  get exteriorWallsOptions(): { key: string; value: string }[] {
    return window.__amp_config.exterior_walls;
  },
  get foundationOptions(): { key: string; value: string }[] {
    return window.__amp_config.foundation;
  },
  get bathroomGradeOptions(): { key: string; value: string }[] {
    return window.__amp_config.bathroom_grade;
  },
  get kitchenGradeOptions(): { key: string; value: string }[] {
    return window.__amp_config.kitchen_grade;
  },
  get roofMaterialOptions(): { key: string; value: string }[] {
    return window.__amp_config.roof_material;
  },
  get roofDesignOptions(): { key: string; value: string }[] {
    return window.__amp_config.roof_design;
  },
  get heatingOptions(): { key: string; value: string }[] {
    return window.__amp_config.heating;
  },
  get airConditioningOptions(): { key: string; value: string }[] {
    return window.__amp_config.air_conditioning;
  },
  get swimmingPoolOptions(): { key: string; value: string }[] {
    return window.__amp_config.swimming_pool;
  },
  get garageTypeOptions(): { key: string; value: string }[] {
    return window.__amp_config.garage_type;
  },
  // Vehicles --------------------------------------
  get vehicleUsageOptions(): { key: VehicleUsageType; value: string }[] {
    return window.__amp_config.vehicle_usage;
  },
  vehicleUsage(usage: VehicleUsageType) {
    return this.vehicleUsageOptions.find(option => option.key === usage)?.value || '—';
  },
  get vehicleOwnershipOptions(): { key: OwnershipType; value: string }[] {
    return window.__amp_config.vehicle_ownership;
  },
  vehicleOwnership(ownership: OwnershipType) {
    return this.vehicleOwnershipOptions.find(option => option.key === ownership)?.value || '—';
  },
  get vehicleKindOptions(): { key: VehicleKind; value: string }[] {
    return window.__amp_config.vehicle_kind;
  },
  vehicleKind(vehicleKind: VehicleKind | null) {
    return this.vehicleKindOptions.find(option => option.key === vehicleKind)?.value || '—';
  },
  // Policies --------------------------------------
  get newClosingOptions(): { key: string; value: string }[] {
    return window.__amp_config.new_closing;
  },
  get paymentMethodOptions(): { key: PolicyPaymentMethod; value: string }[] {
    return window.__amp_config.payment_method;
  },
  paymentMethod(paymentMethod: PolicyPaymentMethod | null) {
    return this.paymentMethodOptions.find(option => option.key === paymentMethod)?.value || '—';
  },
  get policyStatusOptions(): { key: PolicyStatus; value: string }[] {
    return window.__amp_config.policy_status;
  },
  policyStatus(policyStatus: PolicyStatus | null) {
    return this.policyStatusOptions.find(option => option.key === policyStatus)?.value || '—';
  },
  get policySourceOptions(): { key: PolicySource; value: string }[] {
    return window.__amp_config.policy_source;
  },
  policySource(policySource: PolicySource | null) {
    return this.policySourceOptions.find(option => option.key === policySource)?.value || '—';
  },
  get policyTransactionOptions(): { key: PolicyTransactionType; value: string }[] {
    return window.__amp_config.policy_transaction_type;
  },
  get newBusinessPolicyTransactionOptions(): { key: PolicyTransactionType; value: string }[] {
    return window.__amp_config.new_business_policy_transactions;
  },
  get policyCancellationReasonOptions(): { key: string; value: string; unavailable: boolean }[] {
    return window.__amp_config.policy_cancellation_reason;
  },
  policyCancellationReason(cancellationReason: string | null) {
    return this.policyCancellationReasonOptions.find(option => option.key === cancellationReason)?.value || '';
  },
  get deductibleTypeOptions(): { key: string; value: string }[] {
    return window.__amp_config.deductible_type;
  },
  // Loans -------------------------------------------
  get loanPurposeOptions(): { key: LoanPurpose; value: string }[] {
    return window.__amp_config.loan_purpose;
  },
  loanPurpose(loanPurpose: LoanPurpose | null) {
    return this.loanPurposeOptions.find(option => option.key === loanPurpose)?.value || '—';
  },
  // Leads -------------------------------------------
  get testModeOptions(): { key: string; value: string }[] {
    return window.__amp_config.test_mode_values;
  },
  get leadModeOptions(): { key: ILeadMode; value: string }[] {
    return window.__amp_config.lead_mode_values;
  },
  get managerLeadModeOptions(): { key: ILeadMode; value: string }[] {
    return window.__amp_config.manager_lead_mode_values;
  },
  get dispositionTypeOptions(): { key: DispositionType; value: string }[] {
    return (
      window.__amp_config.disposition_types?.map((dispositionType: { key: string; value: { label: string } }) => ({
        key: dispositionType.key,
        value: dispositionType.value.label
      })) || []
    );
  },
  dispositionType(dispositionType: DispositionType | null) {
    return this.dispositionTypeOptions.find(option => option.key === dispositionType)?.value || '—';
  },
  get notQualifiedReasonOptions(): { key: string; value: string; unavailable: boolean }[] {
    return window.__amp_config.disposition_not_qualified_reasons;
  },
  get couldNotContactReasonOptions(): { key: string; value: string; unavailable: boolean }[] {
    return window.__amp_config.disposition_could_not_contact_reasons;
  },
  dispositionReason(dispositionReason: string | null) {
    return (
      [...this.notQualifiedReasonOptions, ...this.couldNotContactReasonOptions].find(
        option => option.key === dispositionReason
      )?.value || '—'
    );
  },
  get leadTypeOptions(): { key: LeadTypes; value: string }[] {
    return window.__amp_config.lead_types;
  },
  leadType(leadType: LeadTypes | null) {
    return this.leadTypeOptions.find(option => option.key === leadType)?.value;
  },
  get leadCaptureMethodOptions(): { key: LeadCaptureMethods; value: string }[] {
    return window.__amp_config.lead_capture_methods;
  },
  get businessTypeOptions(): { key: BusinessTypes; value: string }[] {
    return window.__amp_config.business_types;
  },
  businessType(businessType: BusinessTypes | undefined) {
    return this.businessTypeOptions.find(option => option.key === businessType)?.value;
  },
  get sourceProductOptions(): { key: SourceProducts; value: string }[] {
    return window.__amp_config.source_products;
  },
  get attributedMarketingMediumOptions(): { key: AttributedMarketingMediums; value: string }[] {
    return window.__amp_config.attributed_marketing_mediums;
  },
  get primaryOpportunityTypeOptions(): { key: PrimaryOpportunities; value: string }[] {
    return window.__amp_config.primary_opportunity_types;
  },
  get savingsOptions(): { key: string; value: string }[] {
    return window.__amp_config.savings_options;
  },
  // Opportunities ------------------------------------
  get opportunityLostReasonOptions(): { key: string; value: string; unavailable: boolean }[] {
    return window.__amp_config.opportunity_lost_reasons;
  },
  opportunityLostReason(opportunityReason: string | null) {
    return this.opportunityLostReasonOptions.find(option => option.key === opportunityReason)?.value;
  },
  // Tasks --------------------------------------------
  get taskKindOptions(): { key: TaskKind; value: string }[] {
    return window.__amp_config.task_kinds;
  },
  get taskKindsAllOptions(): { key: TaskKind; value: string }[] {
    return window.__amp_config.task_kinds_all;
  },
  taskKind(taskKind: TaskKind | null) {
    return this.taskKindsAllOptions.find(option => option.key === taskKind)?.value || '—';
  },
  get taskPriorityOptions(): { key: string; value: string }[] {
    return window.__amp_config.task_priorities;
  },
  taskPriority(taskPriority: string | null) {
    return this.taskPriorityOptions.find(option => option.key === taskPriority)?.value || '—';
  },
  get tasksFilter(): { key: string; value: string }[] {
    return [{ key: 'none', value: 'None' }, ...this.taskKindOptions];
  },
  // Notes --------------------------------------------
  get manualNoteKindOptions(): { key: NoteKind; value: string }[] {
    return window.__amp_config.manual_note_kinds;
  },
  // Common -------------------------------------------
  get usaStates() {
    const states = window.__amp_config.usa_states || {};

    return Object.keys(states).map(key => ({ key: states[key] as string, value: key }));
  },
  usaState(state: string | null) {
    return this.usaStates.find(option => option.key === state)?.value || '';
  }
};

export default appTranslations;
