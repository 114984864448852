import { FormikValues } from 'formik';

import { IQuestion } from '../../../interfaces/IWorkflow';
import { isQuestionVisible } from './workflowConditions';

const formValueForQuestion = (question: IQuestion, formValues: FormikValues) => {
  if (isQuestionVisible(question, formValues)) {
    return formValues[question.key];
  }

  return null;
};

export const answersForQuestions = ({
  questions,
  formValues,
  personGid,
  engagementGid,
  assetGid
}: {
  questions: IQuestion[];
  formValues: FormikValues;
  personGid: string;
  engagementGid: string | undefined;
  assetGid: string | undefined;
}) =>
  questions.map(question => ({
    question_key: question.key,
    person_gid: personGid,
    engagement_gid: engagementGid,
    asset_gid: assetGid,
    value: formValueForQuestion(question, formValues)
  }));
