import { useMutation } from '@tanstack/react-query';

import alert from '../../../components/core/Alert';
import { PolicyFileToProcess } from '../../../interfaces/IDocument';
import { IMaticPolicy, PolicyStatus, PolicyTransactionType } from '../../../interfaces/IPolicy';
import { PolicyType } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { DocumentRule } from '../../document_rules/useDocumentRules';
import { processFiles } from './useUpdatePolicy';

export interface CreatePolicyRequest {
  person_gid: string;
  lead_id?: number;

  transaction_type: PolicyTransactionType;
  policy_type: PolicyType;

  premium: number;
  carrier_id: number;
  status: PolicyStatus;

  primary_coverage?: number;
  deductible_type?: string;
  deductible_value?: string;
  policy_number: string;
  sale_date: string;

  effective_date: string;
  expiration_date: string;
  cancellation_date?: string;
  payment_method?: string;
  cancellation_reason?: string;
  state?: string;
  document_rules_policies_attributes: Pick<
    Required<DocumentRule>,
    'document_rule_id' | 'demand' | 'could_not_collect' | 'responsible'
  >[];

  new_closing?: boolean;

  agent_id: string;
  asset_gids: string[];

  files?: PolicyFileToProcess[];

  trust_name?: string;
  llc_name?: string;
}

export const createMaticPolicy = (data: CreatePolicyRequest): Promise<{ policy: IMaticPolicy; warnings?: string[] }> =>
  api.post(`/api/frontend/people/${data.person_gid}/policies`, { body: data });

const useCreateMaticPolicy = (onSuccess?: (policy: IMaticPolicy) => void) => {
  return useMutation({
    mutationFn: createMaticPolicy,
    onSuccess: async (response, variables) => {
      if ('warnings' in response) {
        alert({ message: '', title: '', items: response.warnings }).warning();
      }

      await processFiles({ ...variables, policy_id: response.policy.id });
      onSuccess?.(response.policy);
    }
  });
};

export default useCreateMaticPolicy;
