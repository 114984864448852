import { useQuery } from '@tanstack/react-query';

import { IUser } from '../../../interfaces/IUser';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

const useLeadAssignees = ({ leadId, licensed = false }: { leadId?: number | string; licensed?: boolean }) =>
  useQuery({
    queryKey: ['lead_assignees', leadId, licensed],
    queryFn: (): Promise<{ agents: IUser[] }> =>
      api.get(`/api/frontend/leads/${leadId}/assignees?${toQueryParams({ licensed })}`),
    enabled: !!leadId,
    select: data => data?.agents
  });

export default useLeadAssignees;
