/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field, FieldProps } from 'formik';
import * as React from 'react';

import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import Checkbox, { CheckboxProps } from '../../inputs/Checkbox';
import Paragraph from '../../Paragraph';
import FormError from './FormError';

export interface CheckboxFieldProps extends CheckboxProps {
  name: string;
  testId?: string;
  description?: string;
  inline?: boolean;
  preserveErrorSpace?: boolean;
  descriptionFsMask?: boolean;
}

const CheckboxField = ({
  description,
  label,
  id,
  name,
  required,
  testId,
  inline = false,
  preserveErrorSpace = true,
  descriptionFsMask = false,
  ...rest
}: CheckboxFieldProps): JSX.Element => {
  return (
    <div>
      <Field name={name} type="checkbox">
        {({ field, meta, form }: FieldProps) => {
          const hasError = meta.error && meta.touched;

          return (
            <>
              <Checkbox
                data-testid={testId}
                {...field}
                {...rest}
                disabled={rest.disabled || form.status === 'disabled'}
                id={id}
                label={label}
                required={required}
                aria-invalid={!!hasError}
                aria-errormessage={`${id}-error`}
              />
              {description
                ? hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />
                : !inline &&
                  (preserveErrorSpace || !!hasError) && <FormError id={id} hasError={!!hasError} error={meta.error} />}
              {description && (
                <Paragraph
                  className={rest.fsMask || descriptionFsMask ? 'fs-mask' : ''}
                  type="small"
                  color={colors.grey60}
                  customCss={css`
                    margin-left: ${spacings.px28}px;
                  `}
                >
                  {description}
                </Paragraph>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

export default CheckboxField;
