import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IPerson } from '../../interfaces/IPerson';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const getAutoPolicyDrivers = (
  policyId: string | number,
  lineOfBusinessId?: string,
  effectiveDate?: string
): Promise<{ drivers: IPerson[] }> =>
  api.get(
    `/api/frontend/policies/${policyId}/auto/${lineOfBusinessId}/drivers?${toQueryParams({
      effective_date: effectiveDate
    })}`
  );

const useAutoPolicyDrivers = (
  policyId: string | number,
  lineOfBusinessId?: string,
  effectiveDate?: string,
  options?: UseQueryOptions<{ drivers: IPerson[] }, any, any, any>
) =>
  useQuery({
    queryKey: ['auto_policy_drivers', policyId, lineOfBusinessId, effectiveDate],
    queryFn:
      !!lineOfBusinessId && !!effectiveDate
        ? () => getAutoPolicyDrivers(policyId, lineOfBusinessId, effectiveDate)
        : skipToken,
    ...options,
    select: data => data.drivers
  });

export default useAutoPolicyDrivers;
