import { keepPreviousData, useQuery } from '@tanstack/react-query';

import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';
import { ScoutingStrategy } from './useScoutingStrategy';

const getScoutingStrategyScriptDemo = ({
  configKey,
  variables
}: {
  configKey: string | undefined;
  variables: Record<string, any> | undefined;
}): Promise<{ scouting_strategy_config: ScoutingStrategy }> =>
  api.get(`/api/frontend/scouting_strategy_configs/${configKey}/demo?${toQueryParams(variables)}`);

const useScoutingStrategyScriptDemo = ({
  configKey,
  variables
}: {
  configKey: string | undefined;
  variables: Record<string, any> | undefined;
}) =>
  useQuery({
    queryKey: ['scouting_strategy_config_demo', configKey, variables],
    queryFn: () => getScoutingStrategyScriptDemo({ configKey, variables }),
    enabled: !!configKey,
    select: data => data.scouting_strategy_config,
    placeholderData: keepPreviousData
  });

export default useScoutingStrategyScriptDemo;
