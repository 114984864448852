/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import CircledPolicyTypeIcon from '../../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import { IOpportunity, IQuote } from '../../../../../interfaces';
import { opportunityDescription } from '../../../../../interfaces/IOpportunity';
import { QuoteKind, QuoteStatus } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { moneyFormatter } from '../../../../../utils/formatter';
import QuotesModal from '../QuotesModal';
import CustomerChoiceHeader from './CustomerChoiceHeader';
import CustomerChoicePremiumAndCoverages from './CustomerChoicePremiumAndCoverages';

interface IOnlineBindCustomerChoice {
  opportunity: IOpportunity;
  onlineSessionInitialQuote: IQuote;
  customerChoices: IQuote[] | undefined;
}

const OnlineBindCustomerChoices = ({
  opportunity,
  onlineSessionInitialQuote,
  customerChoices
}: IOnlineBindCustomerChoice) => {
  const [quotesModelOpened, setQuotesModalOpened] = React.useState(false);

  const initialPremium = onlineSessionInitialQuote.attributes.premium;

  // Fallback to onlineSessionInitialQuote is necessary because of the scenario that should exist only on staging,
  // where OLB team creates fake OLB sessions without selecting quote on Direct Sales, which results in customerChoices
  // being absent before customer generates new quote on OLB.
  const customerChoice = customerChoices?.[0] || onlineSessionInitialQuote;

  const isCustomerChoiceFinalized = customerChoice?.kind === QuoteKind.RC2;
  const isCustomerChoiceSuccessful = customerChoice?.status === QuoteStatus.Success;

  return (
    <FlexBox gap={spacings.px8} columnDirection>
      <FlexBox p={spacings.px8} gap={spacings.px8} border roundBorder>
        <CircledPolicyTypeIcon policyType={customerChoice.attributes.policy_type} />
        <FlexBox gap={spacings.px4} columnDirection>
          <CustomerChoiceHeader customerChoice={customerChoice} />
          <Text type="tiny" color={colors.grey60} className="fs-mask">
            {opportunityDescription(opportunity)}
          </Text>
          {isCustomerChoiceFinalized ? (
            <Text type="small">Initial price they saw - {moneyFormatter(initialPremium, true)}</Text>
          ) : (
            <Text type="small">The final premium is not yet ready as data collection is not yet complete.</Text>
          )}
          {isCustomerChoiceSuccessful && <CustomerChoicePremiumAndCoverages customerChoice={customerChoice} />}
          <Container>
            <ButtonWithoutBorder
              onClick={() => setQuotesModalOpened(true)}
              customCss={css`
                padding: ${spacings.px8}px 0px;
              `}
            >
              See customer’s quotes
            </ButtonWithoutBorder>
          </Container>
        </FlexBox>
      </FlexBox>
      {quotesModelOpened && (
        <QuotesModal opportunity={opportunity} customerQuotesOnly={true} onClose={() => setQuotesModalOpened(false)} />
      )}
      <Tooltip id="carrier-name-tooltip" />
      <Tooltip id="near-final-price-tooltip" />
      <Tooltip id="quoted-outdated-tooltip" />
    </FlexBox>
  );
};

export default OnlineBindCustomerChoices;
