import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import { spacings } from '../../../../theme/variables';

const LeadOperationsPlaceholder = () => (
  <div data-testid="lead-operations-loader">
    <Heading
      type="h3"
      customCss={css`
        width: 60%;
      `}
    >
      <Skeleton />
    </Heading>
    <FlexBox mt={spacings.px24} columnDirection>
      {[1, 2, 3, 4].map(value => (
        <Heading key={value} type="h4" mt={spacings.px12} pr={spacings.px12}>
          <Skeleton />
        </Heading>
      ))}
    </FlexBox>
  </div>
);

export default LeadOperationsPlaceholder;
