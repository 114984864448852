/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Paragraph from '../../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import FilesTabContent from './FilesTabContent';

const FilesTab = () => {
  const { lead, personGid } = useGuidedSellingExperienceContext();

  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Files</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        {lead && personGid ? (
          <FilesTabContent leadId={lead.id} personGid={personGid} />
        ) : (
          <Paragraph
            mt={spacings.px24}
            color={colors.grey60}
            customCss={css`
              text-align: center;
            `}
          >
            No files here
          </Paragraph>
        )}
      </VerticalTabContent>
    </>
  );
};

export default FilesTab;
