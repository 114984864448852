/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ReactElement, useState } from 'react';
import { Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import alert from '../../../components/core/Alert';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { isLeadDataEditingForbidden } from '../../../components/DispositionsModals/dispositionsHelper';
import PolicyList from '../../../components/PolicyList/PolicyList';
import CancelModal from '../../../components/PolicyModals/CancelModal';
import NonRenewModal from '../../../components/PolicyModals/NonRenewModal';
import PolicyCreator from '../../../components/PolicyModals/PolicyCreator';
import PolicyEditor from '../../../components/PolicyModals/PolicyEditor';
import PolicyRenewer from '../../../components/PolicyModals/PolicyRenewer';
import PolicyRewriter from '../../../components/PolicyModals/PolicyRewriter';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { IPersonAsset } from '../../../interfaces/IPersonAsset';
import { IMaticPolicy, POLICY_RENEWABLE_STATUSES } from '../../../interfaces/IPolicy';
import { PolicyType } from '../../../interfaces/IPolicyType';
import PolicyDetails from '../../../pages/PolicyDetails';
import useAssignees from '../../../queries/assignees/useAssignees';
import useCarriers from '../../../queries/carriers/useCarriers';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import useLead from '../../../queries/leads/useLead';
import useCreateMaticPolicy from '../../../queries/people/person_policies/useCreateMaticPolicy';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import useRenewPolicy from '../../../queries/people/person_policies/useRenewPolicy';
import useRewritePolicy from '../../../queries/people/person_policies/useRewritePolicy';
import useUpdatePolicy from '../../../queries/people/person_policies/useUpdatePolicy';
import usePersonVehicles from '../../../queries/people/personVehicles';
import usePerson from '../../../queries/people/usePerson';
import usePersonHomes from '../../../queries/people/usePersonHomes';
import authInfo, { isAgent } from '../../../services/authInfo';
import { spacings } from '../../../theme/variables';
import PolicyAssetsPicker from './PolicyAssetsPicker';

const NEW_POLICY_DISABLED_MSG = 'You cannot add policy to a lost/sold lead. Create a new one.';

const defaultState = {
  openCreator: false,
  selectedPolicy: null
};

interface LeadPoliciesProps {
  personGid: string;
  leadGid: string;
  leadId: number;
}

const PolicyIdProvider = ({ children }: { children: (policyId: string | undefined) => JSX.Element }) => {
  const { policyId } = useParams();

  return children(policyId);
};

const LeadPolicies = ({ personGid, leadGid, leadId }: LeadPoliciesProps): ReactElement => {
  const [state, setState] = useState<{
    openCreator: boolean;
    selectedPolicy: null | Pick<IMaticPolicy, 'policy_type' | 'assets'>;
  }>(defaultState);
  const navigate = useNavigate();

  const { data: leadDispositions } = useLeadDispositions(leadId);
  const { data: person } = usePerson(personGid);
  const { data: policies, refetch: refetchPolicies } = usePersonMaticPolicies({
    personGid,
    filters: {
      lead_id: leadId
    }
  });
  const { data: carriers } = useCarriers({
    appointed_in_past: true,
    post_sale: authInfo.features.allow_post_sale_carriers
  });
  const { data: assignees } = useAssignees();
  const { data: homes } = usePersonHomes(personGid);
  const { data: vehicles } = usePersonVehicles(personGid);
  const assets = [...(homes || []), ...(vehicles || [])];
  const dataIsPresent = person && policies && carriers && assignees && homes && vehicles && leadDispositions;

  const { mutateAsync: createPolicy } = useCreateMaticPolicy();
  const { mutateAsync: updatePolicy } = useUpdatePolicy();
  const { mutateAsync: renewPolicy } = useRenewPolicy();
  const { mutateAsync: rewritePolicy } = useRewritePolicy();

  const closeModal = () => {
    navigate(`/leads/${leadId}/policies`);
  };

  const onCreatePolicy = (policyType: PolicyType, personAssets: IPersonAsset[]) => {
    setState(state => ({
      ...state,
      openCreator: false,
      selectedPolicy: { policy_type: policyType, assets: personAssets }
    }));
    navigate(`/leads/${leadId}/policies/new`);

    return Promise.resolve();
  };

  const onPolicyCreated = async (policyParams: any) => {
    try {
      await createPolicy({ ...policyParams, person_gid: person!.gid, lead_id: leadId });
    } catch (e: any) {
      alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const onPolicyEdited = async (policyParams: any) => {
    try {
      const { id, ...rest } = policyParams;

      await updatePolicy({ person_gid: person!.gid, policy_id: id, ...rest });
    } catch (e: any) {
      e.message && alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const onPolicyRenewed = async (policy: any) => {
    try {
      const { id, ...rest } = policy;
      await renewPolicy({ person_gid: person!.gid, policy_id: id, ...rest });
    } catch (e: any) {
      e.message && alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const onPolicyRewritten = async (policy: any) => {
    try {
      const { id, ...rest } = policy;
      await rewritePolicy({ person_gid: person!.gid, policy_id: id, ...rest });
    } catch (e: any) {
      e.message && alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const showTooltip = isLeadDataEditingForbidden(leadDispositions?.current_disposition?.disposition_type);
  const tooltipProps = showTooltip
    ? {
        'data-tip': NEW_POLICY_DISABLED_MSG,
        'data-for': 'disabled-creation'
      }
    : {};

  const adminPolicyActionsPossible = authInfo.features.edit_policy_status;
  const rewritePossible = authInfo.features.rewrite_policies;

  const { data: lead, isPending: isPendingLead } = useLead(leadGid);

  const isAddPolicyButtonShown = !isAgent();

  return dataIsPresent ? (
    <Container fitParentWidth>
      <FlexBox mb={spacings.px24} justifySpaceBetween alignItemsCenter>
        <Heading type="h4">Policies</Heading>
        {isAddPolicyButtonShown && (
          <>
            <span {...tooltipProps}>
              <Button
                id="add-policy-btn"
                size={ButtonSize.Small}
                onClick={() => setState(state => ({ ...state, openCreator: true }))}
                disabled={showTooltip && !isPendingLead}
              >
                + Add Policy
              </Button>
            </span>
            {showTooltip && <Tooltip id="disabled-creation" />}
          </>
        )}
      </FlexBox>
      <PolicyList person={person} policies={policies} />
      {state.openCreator && (
        <PolicyAssetsPicker
          cancelHandler={() => setState(state => ({ ...state, openCreator: false }))}
          confirmHandler={({ policyType, personAssets }) => onCreatePolicy(policyType, personAssets)}
          leadId={leadId}
          personGid={person.gid}
        />
      )}
      <Routes>
        <Route
          path="new"
          element={
            state.selectedPolicy ? (
              <PolicyCreator
                sourceDimensions={lead?.source_dimensions}
                carriers={carriers}
                assignees={assignees}
                cancelBtnHandler={() => navigate(`/leads/${leadId}/policies`)}
                confirmBtnHandler={onPolicyCreated}
                person={person}
                policy={state.selectedPolicy}
                assets={assets}
              />
            ) : (
              <Navigate to={`/leads/${leadId}/policies`} replace />
            )
          }
        />

        <Route
          path=":policyId/edit"
          element={
            <PolicyIdProvider>
              {policyId => {
                const policy = policies?.find((policy: any) => `${policy.id}` === policyId);
                return policy ? (
                  <PolicyEditor
                    carriers={carriers}
                    assignees={assignees}
                    cancelBtnHandler={() => navigate(-1)}
                    confirmBtnHandler={onPolicyEdited}
                    person={person}
                    policy={policy}
                    assets={assets}
                  />
                ) : (
                  <Navigate to={`/leads/${leadId}/policies`} replace />
                );
              }}
            </PolicyIdProvider>
          }
        />
        <Route
          path=":policyId/rewrite"
          element={
            <PolicyIdProvider>
              {policyId => {
                const policy = policies?.find((policy: any) => `${policy.id}` === policyId);
                return !policy || !rewritePossible ? (
                  <Navigate to={`/leads/${leadId}/policies`} replace />
                ) : (
                  <PolicyRewriter
                    carriers={carriers}
                    assignees={assignees || []}
                    cancelBtnHandler={closeModal}
                    confirmBtnHandler={onPolicyRewritten}
                    person={person}
                    policy={policy}
                    assets={assets}
                  />
                );
              }}
            </PolicyIdProvider>
          }
        />
        <Route
          path=":policyId/renew"
          element={
            <PolicyIdProvider>
              {policyId => {
                const policy = policies?.find((policy: any) => `${policy.id}` === policyId);
                const renewalPossible =
                  adminPolicyActionsPossible && policy?.status && POLICY_RENEWABLE_STATUSES.includes(policy.status);

                return policy && renewalPossible ? (
                  <PolicyRenewer
                    carriers={carriers}
                    assignees={assignees}
                    cancelBtnHandler={closeModal}
                    confirmBtnHandler={onPolicyRenewed}
                    person={person}
                    policy={policy}
                    assets={assets}
                  />
                ) : (
                  <Navigate to={`/leads/${leadId}/policies`} replace />
                );
              }}
            </PolicyIdProvider>
          }
        />
        <Route
          path=":policyId/cancel"
          element={
            <PolicyIdProvider>
              {policyId => {
                const policy = policies?.find((policy: any) => `${policy.id}` === policyId);

                return policy ? (
                  <CancelModal
                    person={person}
                    policy={policy}
                    closeModal={closeModal}
                    onPolicyCancelled={() => refetchPolicies()}
                  />
                ) : (
                  <Navigate to={`/leads/${leadId}/policies`} replace />
                );
              }}
            </PolicyIdProvider>
          }
        />
        <Route
          path=":policyId/non_renew"
          element={
            <PolicyIdProvider>
              {policyId => {
                const policy = policies?.find((policy: any) => `${policy.id}` === policyId);

                return policy ? (
                  <NonRenewModal
                    person={person}
                    policy={policy}
                    closeModal={closeModal}
                    onPolicyNonRenewed={() => refetchPolicies()}
                  />
                ) : (
                  <Navigate to={`/leads/${leadId}/policies`} replace />
                );
              }}
            </PolicyIdProvider>
          }
        />
        <Route
          path=":policyId/details"
          element={
            policies?.length ? (
              <div
                css={css`
                  position: fixed;
                  width: 100%;
                  height: 100%;
                  left: 0;
                  top: 0;
                  z-index: calc(var(--nav-z-index) - 1);
                `}
              >
                <PolicyDetails person={person} policies={policies} />
              </div>
            ) : (
              <Navigate to={`/leads/${person.gid}/policies`} replace />
            )
          }
        />
      </Routes>
    </Container>
  ) : (
    <TablePlaceholder testId="lead-policies-loader" />
  );
};

export default LeadPolicies;
