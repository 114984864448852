import React from 'react';

import featureFlags from '../../../constants/featureFlags';
// eslint-disable-next-line max-len
import useMarkProductAnnouncementsAsRead from '../../../queries/product_announcements/useMarkProductAnnouncementsAsRead';
import useProductAnnouncements from '../../../queries/product_announcements/useProductAnnouncements';
import authInfo from '../../../services/authInfo';

const useProductAnnouncementsForUser = () => {
  const role = authInfo.role || undefined;
  const released = true;

  const { data: releasedProductAnnouncements } = useProductAnnouncements({
    role,
    released,
    options: { staleTime: Infinity, enabled: featureFlags.productAnnouncements }
  });
  const { data: unreadProductAnnouncements } = useProductAnnouncements({
    role,
    released,
    unread: true,
    options: { staleTime: Infinity, enabled: featureFlags.productAnnouncements }
  });
  const { mutate: markProductAnnouncementsAsRead } = useMarkProductAnnouncementsAsRead();

  const areAnnouncementsPresent = !!releasedProductAnnouncements?.length;
  const isUnreadAnnouncementPresent = !!unreadProductAnnouncements?.length;

  const markProductAnnouncementsAsReadCallback = React.useCallback(
    () =>
      !!unreadProductAnnouncements?.length &&
      markProductAnnouncementsAsRead({
        productAnnouncementsIds: unreadProductAnnouncements.map(({ id }) => id) || [],
        role,
        released,
        unread: true
      }),
    [unreadProductAnnouncements, markProductAnnouncementsAsRead, role, released]
  );

  return {
    markProductAnnouncementsAsRead: markProductAnnouncementsAsReadCallback,
    areAnnouncementsPresent,
    isUnreadAnnouncementPresent
  };
};

export default useProductAnnouncementsForUser;
