import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { DispositionType, ILeadDispositions } from '../../../interfaces/IDisposition';
import api from '../../../services/api';
import { LEAD_DISPOSITIONS_QUERY_KEY } from './useLeadDispositions';

interface EditDispositionAssigneeRequest {
  leadId: number | string;
  dispositionType: DispositionType;
  assigneeId: number | string;
}

const editDispositionAssignee = (data: EditDispositionAssigneeRequest): Promise<ILeadDispositions> =>
  api.put(`/api/frontend/leads/${data.leadId}/edit_disposition`, {
    body: { assignee_id: data.assigneeId, disposition_type: data.dispositionType }
  });

const useEditDispositionAssignee = (
  onSuccess?: () => void
): UseMutationResult<ILeadDispositions, any, EditDispositionAssigneeRequest> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: editDispositionAssignee,
    onSuccess: (result, variables) => {
      queryClient.setQueryData([LEAD_DISPOSITIONS_QUERY_KEY, variables.leadId?.toString()], () => result);
      onSuccess?.();
    }
  });
};

export default useEditDispositionAssignee;
