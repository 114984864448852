/** @jsxImportSource @emotion/react */
import 'react-calendar/dist/Calendar.css';

import { css, Global, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import ReactCalendar, { CalendarProps } from 'react-calendar';

import colors from '../../../theme/colors';
import { borderRadius } from '../../../theme/variables';
import { dateFormatter } from '../../../utils/formatter';
import { ChevronLeft, ChevronLeftDouble, ChevronRight, ChevronRightDouble } from '../icons';

interface ICalendar {
  onDateChange: CalendarProps['onChange'];
  date: CalendarProps['value'];
  maxDate?: Date;
  minDate?: Date;
  size?: 'small' | 'default';
  customCss?: SerializedStyles;
  fsMask?: boolean;
}

const Calendar = ({ onDateChange, date, maxDate, minDate, size, fsMask, customCss }: ICalendar): JSX.Element => {
  return (
    <div>
      <Global
        styles={css`
          .react-calendar__month-view__weekdays {
            color: ${colors.black};
            font-weight: 400;
            font-size: 14px;
            text-transform: none;
          }

          .react-calendar__tile {
            font-size: 14px;
            border-radius: ${borderRadius}px;

            &:disabled {
              background-color: transparent;
              cursor: not-allowed;
            }
          }

          .react-calendar__navigation__label {
            color: ${colors.black};
            font-weight: bold;
            font-size: 16px;
          }

          .react-calendar__navigation {
            height: ${size === 'small' && '30px'};
          }

          .react-calendar__navigation button[disabled] {
            background: none;
            cursor: not-allowed;
          }

          .react-calendar__navigation button {
            min-width: ${size === 'small' && '30px'};
            border-radius: ${borderRadius}px;
          }

          .react-calendar__tile--now {
            &:enabled:hover {
              background-color: ${colors.grey10};
            }
            background-color: transparent;
            border: 1px solid ${colors.azure50} !important;
          }

          .react-calendar__tile--active {
            background: ${colors.azure50} !important;
          }

          .react-calendar {
            abbr[title],
            abbr[data-original-title] {
              text-decoration: none;
              cursor: auto;
            }

            button:disabled {
              color: ${colors.grey60};
            }

            .react-calendar__navigation {
              margin-bottom: ${size === 'small' ? '0' : '1rem'};
            }
            width: ${size === 'small' ? '280px' : '350px'};
            border-radius: ${borderRadius}px;
          }
        `}
      />
      <ReactCalendar
        onChange={onDateChange}
        className={fsMask ? 'fs-mask' : undefined}
        value={date}
        defaultView="month"
        calendarType="gregory"
        minDetail="year"
        maxDate={maxDate}
        minDate={minDate}
        navigationLabel={({ date }) => dateFormatter(date, 'MMMM, YYYY')}
        prevLabel={<ChevronLeft />}
        nextLabel={<ChevronRight />}
        formatShortWeekday={(_locale, date) => dateFormatter(date, 'dd')}
        next2Label={size === 'small' ? <ChevronRightDouble /> : null}
        prev2Label={size === 'small' ? <ChevronLeftDouble /> : null}
        css={customCss}
        tileClassName="qa-test-class-calendar-day"
      />
    </div>
  );
};

export default Calendar;
