import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import Text from '../../../../components/core/Text';

const PrimaryContactPlaceholder = () => (
  <FlexBox columnDirection data-testid="lead-summary-primary-contact-loader">
    <Heading
      type="h4"
      customCss={css`
        width: 50%;
      `}
    >
      <Skeleton />
    </Heading>
    <Text
      customCss={css`
        width: 60%;
        padding-left: 16px;
        margin-top: 16px;
      `}
    >
      <Skeleton count={4} />
    </Text>
  </FlexBox>
);

export default PrimaryContactPlaceholder;
