import React from 'react';

import { ColumnGenerator, Table } from '../../../components/common';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import Text from '../../../components/core/Text';
import { SORT_ORDER_DESC } from '../../../constants';
import { IPersonOnlineSession } from '../../../interfaces';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import usePersonOnlineSessions from '../../../queries/people/person_online_sessions/usePersonOnlineSessions';
import { spacings } from '../../../theme/variables';
import { dateTimeFormatter } from '../../../utils/formatter';

const PersonOnlineSessions = ({ personGid, leadGid }: { personGid: string; leadGid: string }) => {
  const { data: customerOnlineSessions } = usePersonOnlineSessions({
    personGid,
    engagementGid: leadGid
  });

  if (!customerOnlineSessions?.length) {
    return null;
  }

  return (
    <Container fitParentWidth>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Heading type="h4">Online bind sessions</Heading>
      </FlexBox>
      <Container mt={spacings.px12}>
        <Table
          defaultSorting={{ sort_column: 'created_at', sort_order: SORT_ORDER_DESC }}
          columns={[
            ColumnGenerator.stretchable({
              accessor: 'carrier',
              Header: 'Carrier',
              sortable: false,
              Cell: ({ original: { carrier } }: { original: IPersonOnlineSession }) =>
                carrier && <Text singleLine>{carrier.name}</Text>
            }),
            ColumnGenerator.simple({
              width: ColumnGenerator.WIDTH_L,
              accessor: 'policy_type',
              Header: 'Policy type',
              sortable: false,
              Cell: ({ original: { policy_type } }: { original: IPersonOnlineSession }) => (
                <Text>{findPolicyTypeLabel(policy_type)}</Text>
              )
            }),
            ColumnGenerator.stretchable({
              accessor: 'status_label',
              Header: 'Status',
              sortable: false,
              Cell: ({ original: { status_label } }: { original: IPersonOnlineSession }) => <Text>{status_label}</Text>
            }),
            ColumnGenerator.simple({
              width: ColumnGenerator.WIDTH_XXL,
              accessor: 'current_page',
              Header: 'Last visited page',
              sortable: false,
              Cell: ({ original: { current_page_label } }: { original: IPersonOnlineSession }) => (
                <Text>{current_page_label}</Text>
              )
            }),
            ColumnGenerator.stretchable({
              accessor: 'failure_reason_label',
              Header: 'Failure reason',
              className: 'fs-mask',
              sortable: false,
              Cell: ({ original: { failure_reason_label } }: { original: IPersonOnlineSession }) => (
                <Text>{failure_reason_label}</Text>
              )
            }),
            ColumnGenerator.simple({
              width: ColumnGenerator.WIDTH_XXL,
              accessor: 'created_at',
              Header: 'Session started at',
              sortable: false,
              Cell: ({ original: { created_at } }: { original: IPersonOnlineSession }) => (
                <Text>{dateTimeFormatter(created_at)}</Text>
              )
            }),
            ColumnGenerator.simple({
              width: ColumnGenerator.WIDTH_XXL,
              accessor: 'last_interaction_at',
              Header: 'Last activity at',
              sortable: false,
              Cell: ({ original: { last_interaction_at } }: { original: IPersonOnlineSession }) => (
                <Text>{dateTimeFormatter(last_interaction_at)}</Text>
              )
            })
          ]}
          data={customerOnlineSessions}
          manual={false}
          noDataMessage="No online bind sessions found"
        />
      </Container>
    </Container>
  );
};

export default PersonOnlineSessions;
