import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IPerson } from '../../interfaces/IPerson';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const useHomePolicyCoinsureds = (
  policyId: string | number,
  effectiveDate?: string,
  options?: UseQueryOptions<{ coinsureds: IPerson[] }, any, any, any>
) =>
  useQuery({
    queryKey: ['policy_coinsureds', policyId, effectiveDate],
    queryFn: effectiveDate
      ? (): Promise<{ coinsureds: IPerson[] }> =>
          api.get(
            `/api/frontend/policies/${policyId}/home/coinsureds?${toQueryParams({
              effective_date: effectiveDate
            })}`
          )
      : skipToken,
    ...options,
    select: data => data.coinsureds
  });

export default useHomePolicyCoinsureds;
