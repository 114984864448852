import { Navigate, useLocation } from 'react-router-dom';

const DeprecatedNamespaceRedirect = () => {
  const { pathname, search } = useLocation();

  const newPathname = pathname.replace('/crm', '');
  const newPath = `${newPathname}${search}` || '/';

  return <Navigate to={newPath} replace />;
};

export default DeprecatedNamespaceRedirect;
