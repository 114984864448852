import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { contextGridStyle } from './index.style';

const ContextSkeleton = () => {
  return (
    <Container customCss={contextGridStyle}>
      {[1, 2].map(i => (
        <React.Fragment key={i}>
          <Container>
            <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
              <Skeleton
                circle
                style={{
                  width: '16px',
                  height: '16px'
                }}
              />
            </SkeletonTheme>
          </Container>
          <Container>
            <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px',
                  width: '91px'
                }}
              />
            </SkeletonTheme>
          </Container>
          <Container>
            <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px',
                  width: '308px'
                }}
              />
            </SkeletonTheme>
          </Container>
        </React.Fragment>
      ))}

      <Container>
        <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
          <Skeleton
            circle
            style={{
              width: '16px',
              height: '16px'
            }}
          />
        </SkeletonTheme>
      </Container>
      <FlexBox columnDirection gap={spacings.px8}>
        <Container>
          <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
            <Skeleton
              style={{
                height: '16px',
                borderRadius: '50px',
                width: '40px'
              }}
            />
          </SkeletonTheme>
        </Container>
        <Container>
          <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
            <Skeleton
              style={{
                height: '16px',
                borderRadius: '50px',
                width: '91px'
              }}
            />
          </SkeletonTheme>
        </Container>
      </FlexBox>
      <FlexBox columnDirection gap={spacings.px16}>
        {[1, 2, 3].map(i => (
          <FlexBox key={i} gap={spacings.px16}>
            <Container>
              <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
                <Skeleton
                  style={{
                    height: '16px',
                    borderRadius: '50px',
                    width: '308px'
                  }}
                />
              </SkeletonTheme>
            </Container>
            <Container ml={spacings.px4}>
              <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
                <Skeleton
                  style={{
                    height: '16px',
                    borderRadius: '50px',
                    width: '50px'
                  }}
                />
              </SkeletonTheme>
            </Container>
          </FlexBox>
        ))}
      </FlexBox>
    </Container>
  );
};

export default ContextSkeleton;
