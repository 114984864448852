/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';

import Tag from '../../../components/core/Tag';
import { FloodRisk } from '../../../interfaces/IHome';
import colors from '../../../theme/colors';
import { borderRadius, spacings } from '../../../theme/variables';

const colorByRisk = {
  [FloodRisk.Low]: colors.statusGreen,
  [FloodRisk.Moderate]: colors.statusOrange,
  [FloodRisk.Dangerous]: colors.statusRed
};

const prefixByRisk = {
  [FloodRisk.Low]: 'Low',
  [FloodRisk.Moderate]: 'Moderate',
  [FloodRisk.Dangerous]: 'High'
};

const FloodRiskTag = ({ floodRisk }: { floodRisk: FloodRisk | null }) => {
  if (floodRisk && floodRisk !== FloodRisk.Unknown) {
    return (
      <Tag
        label={`${prefixByRisk[floodRisk]} risk area`}
        textType="tiny"
        backgroundColor={colorByRisk[floodRisk]}
        borderRadius={borderRadius}
        customCss={css`
          padding: 2px ${spacings.px8}px;
          height: fit-content;
        `}
      />
    );
  }

  return null;
};

export default FloodRiskTag;
