// @ts-expect-error old code
import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';

class DateInput extends React.Component<any, any> {
  focus() {
    //Don't remove it, fix console error while using with datepicker component
  }

  render() {
    const { onChange, onFocus, onBlur, value, placeholder, id, name, setDateInputRef, ...rest } = this.props;

    return (
      <Cleave
        {...rest}
        id={id}
        name={name}
        className="form-control"
        value={value}
        options={{
          date: true,
          datePattern: ['m', 'd', 'Y']
        }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder={placeholder}
        htmlRef={(ref: any) => setDateInputRef(ref)}
      />
    );
  }
}

// @ts-expect-error old code
DateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  setDateInputRef: PropTypes.func
};
// @ts-expect-error old code
DateInput.defaultProps = {
  onFocus: null,
  onBlur: null,
  id: null,
  value: '',
  name: null,
  placeholder: '',
  setDateInputRef: () => null
};

export default DateInput;
