import { IAddress } from './IAddress';
import { IHome } from './IHome';
import { IVehicle } from './IVehicle';

export enum AssetType {
  Home = 'home',
  Auto = 'auto'
}

export enum OriginAssetType {
  Home = 'home',
  Vehicle = 'vehicle'
}

export enum OwnershipType {
  Own = 'own',
  Loan = 'loan', // vehicle-only
  Rent = 'rent',
  Lease = 'lease' // vehicle-only
}

export enum HomeUsageType {
  Investment = 'investment',
  SecondHome = 'second_home',
  PrimaryResidence = 'primary_residence'
}

export enum VehicleUsageType {
  Work = 'work',
  Farm = 'farm',
  School = 'school',
  Pleasure = 'pleasure',
  Business = 'business'
}

export type UsageType = HomeUsageType | VehicleUsageType;

export const COMMUTE_DESTINATIONS = [VehicleUsageType.Work, VehicleUsageType.School];

export const isAssetLeased = ({ ownership }: { ownership: IPersonAsset['ownership'] | undefined }) =>
  ownership === OwnershipType.Lease;

export const assetIsNotRented = ({ ownership }: { ownership: IPersonAsset['ownership'] | undefined }) =>
  ownership !== OwnershipType.Rent;

export const isAutoAsset = (asset: IVehicle | IHome): asset is IVehicle => asset.asset_name === AssetType.Auto;

export interface IPersonAsset {
  gid: string;

  usage: UsageType | null;
  ownership: OwnershipType | null;
  owner_since: string | null;
  owner_till: string | null;

  address: IAddress;

  asset_name: AssetType;
  icon: string;
  description: string;
  description_without_icon: string;
}
