import { FormikValues } from 'formik';
import * as yup from 'yup';

import { hasWorkOrSchoolValue } from '../../../components/AnnualMileageCalculator/helpers';
import { CITY, LINE1, LINE2, STATE, ZIP } from '../../../constants/addressForm';
import { IHome, IRelatedPerson, IVehicle } from '../../../interfaces';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { isAutoAssetNotQuotable } from '../../../interfaces/IVehicle';
import { getAutoCoveragesOptions } from '../../../queries/auto_coverages/useAutoCoveragesOptions';
import { getCarriers } from '../../../queries/carriers/useCarriers';
import { createOpportunity } from '../../../queries/leads/opportunities/useLeadOpportunities';
import { updatePersonVehicle } from '../../../queries/people/personVehicles';
import { REQUIRED_MESSAGE, requiredAddressSchema, requiredField } from '../../../utils/yupRules';
import { SelectedHome, SelectedVehicle } from '../_interfaces/ISelectedAsset';
import { HomeQuotingOptionsFields } from './HomeQuotingOptions';
import { PAF_CHECKBOX_ID, PAF_INPUT_FIELD_ID } from './index';

export enum VehicleFields {
  VIN = 'vin',
  Usage = 'usage',
  DaysPerWeekDriven = 'days_per_week_driven',
  AnnualMileage = 'annual_mileage',
  PlateType = 'plate_type',
  PlateNumber = 'plate_number',
  Ownership = 'ownership',
  UsedForRidesharing = 'used_for_ridesharing',
  Address = 'address',
  OneWayMileage = 'one_way_mileage',
  Kind = 'kind',
  Year = 'year',
  Make = 'make',
  Model = 'model',
  Submodel = 'submodel'
}

const DIFFERENT_STATES_ERROR = 'Quotes can be requested for vehicles from one state at a time';
const HOME_QUOTING_UNAVAILABLE_ERROR = 'No integrated or non-integrated carriers in this state are available';
const AUTO_QUOTING_UNAVAILABLE_ERROR =
  "Auto quoting doesn't work in specified state. Go to carrier portal to get auto quote";

export const initialValues = ({
  isFcraDisclosureAccepted,
  homes,
  vehicles,
  selectedHomes,
  selectedVehicles,
  isPafOpportunity,
  selectedJewelry,
  relatedPeople = []
}: {
  isFcraDisclosureAccepted: boolean | undefined;
  homes: IHome[] | undefined;
  vehicles: IVehicle[] | undefined;
  selectedHomes: SelectedHome[] | undefined;
  selectedVehicles: SelectedVehicle[] | undefined;
  isPafOpportunity: boolean;
  selectedJewelry: number | null | undefined;
  relatedPeople: IRelatedPerson[] | undefined;
}) =>
  ({
    ...homes?.reduce((acc, { gid }) => {
      const selectedHome = selectedHomes?.find(home => home.gid === gid);

      return {
        ...acc,
        [gid]: !!selectedHome,
        [`${gid}_${HomeQuotingOptionsFields.Deductible}`]: selectedHome?.deductible || '',
        [`${gid}_${HomeQuotingOptionsFields.DwellingCoverage}`]: selectedHome?.dwelling_coverage || '',
        [`${gid}_${HomeQuotingOptionsFields.PersonalProperty}`]: selectedHome?.personal_property || '',
        [`${gid}_${HomeQuotingOptionsFields.Carriers}`]: selectedHome?.carriers || [],
        [`${gid}_${HomeQuotingOptionsFields.AllCarriers}`]: true,
        [`${gid}_${HomeQuotingOptionsFields.Coinsured}`]: relatedPeople.find(p => p.kind === 'spouse')?.gid || ''
      };
    }, {}),
    ...vehicles?.reduce(
      (
        acc,
        {
          gid,
          vin,
          usage,
          days_per_week_driven,
          one_way_mileage,
          annual_mileage,
          plate_type,
          plate_number,
          ownership,
          used_for_ridesharing,
          address
        }
      ) => {
        const selectedVehicle = selectedVehicles?.find(
          vehicle => vehicle.gid === gid && !isAutoAssetNotQuotable(vehicle.kind)
        );

        return {
          ...acc,
          [gid]: !!selectedVehicle,
          [`${gid}_${VehicleFields.VIN}`]: vin || '',
          [`${gid}_${VehicleFields.Usage}`]: usage || '',
          [`${gid}_${VehicleFields.DaysPerWeekDriven}`]: days_per_week_driven || '',
          [`${gid}_${VehicleFields.OneWayMileage}`]: one_way_mileage || '',
          [`${gid}_${VehicleFields.AnnualMileage}`]: annual_mileage || '',
          [`${gid}_${VehicleFields.PlateType}`]: plate_type || '',
          [`${gid}_${VehicleFields.PlateNumber}`]: plate_number || '',
          [`${gid}_${VehicleFields.Ownership}`]: ownership || '',
          [`${gid}_${VehicleFields.UsedForRidesharing}`]: used_for_ridesharing || '',
          [`${gid}_${VehicleFields.Address}`]: {
            [LINE1]: address?.[LINE1] || '',
            [LINE2]: address?.[LINE2] || '',
            [CITY]: address?.[CITY] || '',
            [STATE]: address?.[STATE] || '',
            [ZIP]: address?.[ZIP] || ''
          }
        };
      },
      {}
    ),
    fcra: isFcraDisclosureAccepted ? 'yes' : '',
    [PAF_CHECKBOX_ID]: typeof selectedJewelry === 'undefined' ? isPafOpportunity : !!selectedJewelry,
    [PAF_INPUT_FIELD_ID]: selectedJewelry || ''
  }) as FormikValues;

export const validations = ({
  isFcraDisclosureAccepted,
  homes,
  vehicles
}: {
  isFcraDisclosureAccepted: boolean | undefined;
  homes: IHome[] | undefined;
  vehicles: IVehicle[] | undefined;
}) =>
  yup.object().shape({
    ...(isFcraDisclosureAccepted
      ? {}
      : { fcra: yup.string().required('Must be provided').oneOf(['yes'], 'Must be provided') }),
    ...homes?.reduce((acc, { gid }) => {
      return {
        ...acc,
        [`${gid}_${HomeQuotingOptionsFields.Deductible}`]: yup.number().when(gid, {
          is: true,
          then: schema => schema.min(1, 'must be greater than zero')
        }),
        [`${gid}_${HomeQuotingOptionsFields.DwellingCoverage}`]: yup.number().when(gid, {
          is: true,
          then: schema => schema.min(1, 'must be greater than zero')
        }),
        [`${gid}_${HomeQuotingOptionsFields.PersonalProperty}`]: yup.number().when(gid, {
          is: true,
          then: schema => schema.min(1, 'must be greater than zero')
        })
      };
    }, {}),
    ...vehicles?.reduce((acc, { gid }) => {
      return {
        ...acc,
        [`${gid}_${VehicleFields.Usage}`]: yup.string().when(gid, {
          is: true,
          then: schema => schema.concat(requiredField)
        }),
        [`${gid}_${VehicleFields.AnnualMileage}`]: yup.number().when(gid, {
          is: true,
          then: schema =>
            schema
              .required(REQUIRED_MESSAGE)
              .min(1000, 'Should be equal or greater than 1,000 miles')
              .max(99998, 'Should be lower than 99,999 miles')
        }),
        [`${gid}_${VehicleFields.OneWayMileage}`]: yup.number().when(gid, {
          is: true,
          then: schema =>
            schema
              .positive('Must be greater than 0')
              .max(399, 'Should be less than 400 miles')
              .when(`${gid}_${VehicleFields.Usage}`, ([usage], schema) =>
                hasWorkOrSchoolValue(usage) ? schema.required(REQUIRED_MESSAGE) : schema
              )
        }),
        [`${gid}_${VehicleFields.DaysPerWeekDriven}`]: yup.number().when(gid, {
          is: true,
          then: schema =>
            schema.when(`${gid}_${VehicleFields.Usage}`, ([usage], schema) => {
              if (hasWorkOrSchoolValue(usage)) {
                return schema
                  .min(1, 'Should be greater than or equal to 1 day')
                  .max(7, 'Should be less than or equal to 7 days');
              }

              return schema;
            })
        }),
        [`${gid}_${VehicleFields.Ownership}`]: yup.string().when(gid, {
          is: true,
          then: schema => schema.concat(requiredField)
        }),
        [`${gid}_${VehicleFields.Address}`]: yup.object().when(gid, {
          is: true,
          then: schema => schema.concat(requiredAddressSchema)
        })
      };
    }, {}),
    [PAF_INPUT_FIELD_ID]: yup.number().min(1, 'Should be greater then 0')
  });

export const validateVehiclesAddressConsistency = (selectedVehicles: IVehicle[], values: FormikValues) => {
  const states = selectedVehicles
    .map(({ gid }) => values[`${gid}_${VehicleFields.Address}`]?.['state'])
    .filter(state => state);

  if (!states) {
    return {};
  }

  if ([...Array.from(new Set(states))].length === 1) {
    return {};
  }

  return selectedVehicles?.reduce((acc, { gid }) => {
    return {
      ...acc,
      [`${gid}_${VehicleFields.Address}`]: { state: DIFFERENT_STATES_ERROR }
    };
  }, {});
};

export const submitVehiclesFormData = ({
  personGid,
  selectedVehicles,
  values
}: {
  personGid: string;
  selectedVehicles: IVehicle[];
  values: FormikValues;
}) =>
  Promise.all(
    selectedVehicles.map(({ gid }) => {
      const data = {
        vin: values[`${gid}_${VehicleFields.VIN}`],
        usage: values[`${gid}_${VehicleFields.Usage}`],
        days_per_week_driven: values[`${gid}_${VehicleFields.DaysPerWeekDriven}`],
        one_way_mileage: values[`${gid}_${VehicleFields.OneWayMileage}`],
        annual_mileage: values[`${gid}_${VehicleFields.AnnualMileage}`],
        plate_type: values[`${gid}_${VehicleFields.PlateType}`],
        plate_number: values[`${gid}_${VehicleFields.PlateNumber}`],
        ownership: values[`${gid}_${VehicleFields.Ownership}`],
        used_for_ridesharing: values[`${gid}_${VehicleFields.UsedForRidesharing}`],
        address: values[`${gid}_${VehicleFields.Address}`]
      };

      return updatePersonVehicle({ personGid, vehicleGid: gid, data });
    })
  );

const validateHomeQuotingAvailability = ({ selectedHomes }: { selectedHomes: IHome[] }) => {
  if (!selectedHomes.length) {
    return Promise.resolve({});
  }

  return Promise.all(
    selectedHomes.map(({ gid, address }) =>
      getCarriers({
        state: address.state,
        appointed: true,
        policy_type: PolicyType.Home
      }).then(response => {
        if (response.carriers.length) {
          return {};
        }

        return { [gid]: HOME_QUOTING_UNAVAILABLE_ERROR };
      })
    )
  ).then(validationResults =>
    validationResults.reduce((acc, validationResult) => {
      return {
        ...acc,
        ...validationResult
      };
    }, {})
  );
};

const validateAutoQuotingAvailability = ({
  selectedVehicles,
  values
}: {
  selectedVehicles: IVehicle[];
  values: FormikValues;
}) => {
  if (!selectedVehicles.length) {
    return Promise.resolve({});
  }

  return Promise.all(
    selectedVehicles.map(async ({ gid }) => {
      const coveragesValidations = await getAutoCoveragesOptions({
        state: values[`${gid}_${VehicleFields.Address}`]?.['state'],
        year: null,
        ownership: null
      }).then(coveragesOptions => {
        if (Object.values(coveragesOptions || {}).length === 0 || coveragesOptions.auto_coverage_options.length === 0) {
          return { [`${gid}_${VehicleFields.Address}`]: { state: AUTO_QUOTING_UNAVAILABLE_ERROR } };
        }

        return null;
      });

      const appointmentsValidations = await getCarriers({
        state: values[`${gid}_${VehicleFields.Address}`]?.['state'],
        appointed: true,
        policy_type: PolicyType.Auto
      }).then(response => {
        if (response.carriers.length) {
          return null;
        }

        return { [`${gid}_${VehicleFields.Address}`]: { state: AUTO_QUOTING_UNAVAILABLE_ERROR } };
      });

      return coveragesValidations || appointmentsValidations || {};
    }) || []
  ).then(validationResults =>
    validationResults.reduce((acc, validationResult) => {
      return {
        ...acc,
        ...validationResult
      };
    }, {})
  );
};

export const validateQuotingAvailability = async ({
  selectedHomes,
  selectedVehicles,
  values
}: {
  selectedHomes: IHome[];
  selectedVehicles: IVehicle[];
  values: FormikValues;
}) => {
  const homeQuotingValidations = await validateHomeQuotingAvailability({ selectedHomes });
  const autoQuotingValidations = await validateAutoQuotingAvailability({ selectedVehicles, values });

  return { ...homeQuotingValidations, ...autoQuotingValidations };
};

export const createPafOpportunity = (leadId: number | string) =>
  createOpportunity({ leadId, payload: { policy_type: PolicyType.PAF } });

export const submitSelectedAssets = ({
  selectedHomes,
  selectedVehicles,
  selectedJewelry,
  values,
  onSubmit
}: {
  selectedHomes: IHome[];
  selectedVehicles: IVehicle[];
  selectedJewelry: number | null;
  values: FormikValues;
  onSubmit: (
    selectedHomes: SelectedHome[],
    selectedVehicles: SelectedVehicle[],
    selectedJewelry: number | null
  ) => void;
}) =>
  onSubmit(
    selectedHomes.map(home => ({
      ...home,
      deductible: values[`${home.gid}_${HomeQuotingOptionsFields.Deductible}`],
      dwelling_coverage: values[`${home.gid}_${HomeQuotingOptionsFields.DwellingCoverage}`],
      personal_property: values[`${home.gid}_${HomeQuotingOptionsFields.PersonalProperty}`],
      carriers: values[`${home.gid}_${HomeQuotingOptionsFields.Carriers}`],
      all_carriers: values[`${home.gid}_${HomeQuotingOptionsFields.AllCarriers}`],
      coinsured_gid: values[`${home.gid}_${HomeQuotingOptionsFields.Coinsured}`]
    })),
    selectedVehicles,
    selectedJewelry
  );
