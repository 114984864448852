/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

export const announcementsGridStyle = css`
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 916px 1fr;
  grid-template-rows: auto 1fr;
  gap: 0px 0px;
  grid-template-areas:
    'back header close'
    '. content content';
`;

export const announcementContainerStyle = css`
  width: 916px;
`;
