import { Grid } from '@mui/material';
import React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../../../../components/core/FlexBox';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const QuotesPlaceholder = () => {
  return (
    <FlexBox columnDirection pv={spacings.px16} ph={spacings.px16} roundBorder backgroundColor={colors.white} border>
      <Grid container padding={0} alignItems="center" columns={24} columnSpacing={2} rowSpacing={4}>
        {[1, 2, 3, 4, 5].map(value => (
          <React.Fragment key={value}>
            <Grid item xs={2}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
            <Grid item xs={4}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
            <Grid item xs={1}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
            <Grid item xs={3}>
              <Skeleton style={{ height: spacings.px32 }} />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
    </FlexBox>
  );
};

export default QuotesPlaceholder;
