/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { Highlight, MatchedSecondaryField } from '../../../queries/people/usePeopleByTerm';
import { smallParagraphCss } from '../../../theme/typography';
import { spacings } from '../../../theme/variables';
import parseHtmlString from '../../../utils/htmlStringParser';

export const labelForField = (field: MatchedSecondaryField): string => {
  switch (field) {
    case MatchedSecondaryField.BusinessPhone:
    case MatchedSecondaryField.FormattedBusinessPhone:
      return 'Business phone';
    case MatchedSecondaryField.SecondaryPhone:
    case MatchedSecondaryField.FormattedSecondaryPhone:
      return 'Secondary phone';
    case MatchedSecondaryField.LivingAddress:
      return 'Living address';
    case MatchedSecondaryField.MailingAddress:
      return 'Mailing address';
    case MatchedSecondaryField.LicenseNumber:
      return 'License number';
    case MatchedSecondaryField.HomesAddresses:
      return 'Home address';
    case MatchedSecondaryField.VehiclesAddresses:
      return 'Garaging address';
    case MatchedSecondaryField.VehiclesVinNumbers:
      return 'Car VIN';
    case MatchedSecondaryField.VehiclesCarModels:
      return 'Car';
    case MatchedSecondaryField.PoliciesNumbers:
      return 'Policy number';
    case MatchedSecondaryField.RelatedPeopleNames:
      return 'Co-customer';
    case MatchedSecondaryField.RelatedPeopleEmails:
      return 'Co-customer email';
    case MatchedSecondaryField.RelatedPeoplePhones:
    case MatchedSecondaryField.RelatedPeopleBusinessPhones:
    case MatchedSecondaryField.RelatedPeopleSecondaryPhones:
    case MatchedSecondaryField.RelatedPeopleFormattedPhones:
    case MatchedSecondaryField.RelatedPeopleFormattedBusinessPhones:
    case MatchedSecondaryField.RelatedPeopleFormattedSecondaryPhones:
      return 'Co-customer phone';
    case MatchedSecondaryField.LeadGid:
      return 'Lead GID';
    case MatchedSecondaryField.CandidateGid:
      return 'Candidate GID';
    case MatchedSecondaryField.PersonGid:
      return 'Person GID';
    case MatchedSecondaryField.LeadId:
      return 'Lead ID';
  }
};

const MatchedBy = ({ highlight }: { highlight: Highlight }): JSX.Element => {
  const sortedHighlights = Object.values(MatchedSecondaryField)
    .map(matchedField => ({ matchedField, value: highlight[matchedField] }))
    .filter(({ value }) => value);

  return (
    <FlexBox columnDirection>
      {sortedHighlights.map(({ matchedField, value }) => {
        const textToHighlight = value[0];

        return (
          <FlexBox key={matchedField} mt={spacings.px4}>
            <Text type="small">{`${labelForField(matchedField)}:`}</Text>
            <Container
              ml={spacings.px4}
              data-testid="highlighted-search-text"
              className="fs-mask"
              css={css`
                ${smallParagraphCss};
                font-weight: bold;
              `}
            >
              {textToHighlight ? parseHtmlString(textToHighlight) : ''}
            </Container>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};

export default MatchedBy;
