import * as React from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';

const canvasStyles: React.CSSProperties = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 99999999
};

interface Props {
  fireOnMount?: boolean;
  times?: number;
}
const Confetti = ({ fireOnMount = false, times = 1 }: Props) => {
  const refAnimationInstance = React.useRef<confetti.CreateTypes | null>(null);

  const getInstance = React.useCallback((instance: confetti.CreateTypes | null) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = React.useCallback((particleRatio: number, opts: confetti.Options) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio)
      });
  }, []);

  const fire = React.useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55
    });

    makeShot(0.2, {
      spread: 60
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45
    });
  }, [makeShot]);

  React.useEffect(() => {
    if (fireOnMount) {
      for (let i = 0; i < times; i++) {
        setTimeout(() => {
          fire();
        }, 1000 * i);
      }
    }
  }, [fireOnMount, fire, times]);

  return <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />;
};

export default Confetti;
