import * as React from 'react';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';

const TOOLTIP_TEXT = 'If you need to remove this policy please contact your manager';
const TOOLTIP_ID = 'sold-policy-tip';

const useSoldPolicyTooltip = () => {
  const Tip = React.useCallback(() => <Tooltip id={TOOLTIP_ID} />, []);

  const labelProps = { 'data-for': TOOLTIP_ID, 'data-tip': TOOLTIP_TEXT };

  return { Tip, labelProps };
};

export default useSoldPolicyTooltip;
