import * as React from 'react';
import * as yup from 'yup';

import FormModal from '../../../components/core/FormModal';
import { RadioGroupField } from '../../../components/core/forms/fields';
import { ModalSize } from '../../../components/core/Modal';
import Paragraph from '../../../components/core/Paragraph';
import { ILoan } from '../../../interfaces';
import { servicerOrLender } from '../../../interfaces/ILender';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { requiredField } from '../../../utils/yupRules';

const SelectLoanModal = ({
  selectedLoanGid,
  loans,
  title,
  cancelHandler,
  confirmHandler
}: {
  selectedLoanGid: string;
  loans: ILoan[];
  title: string;
  cancelHandler: () => void;
  confirmHandler: (loan: ILoan) => void;
}) => {
  return (
    <FormModal
      initialValues={{ loanGid: selectedLoanGid }}
      confirmHandler={values => confirmHandler(loans.find(loan => loan.gid === values.loanGid)!)}
      confirmText="Save"
      cancelHandler={cancelHandler}
      size={ModalSize.large}
      title={title}
      validationSchema={yup.object().shape({ loanGid: requiredField })}
      renderForm={() => (
        <RadioGroupField
          preserveErrorSpace={false}
          columnDirection
          id="loanGid"
          name="loanGid"
          required
          options={loans.map(loan => ({
            id: loan.gid,
            value: loan.gid,
            label: `Loan with ${servicerOrLender(loan)?.name || '-'}`,
            description: (
              <Paragraph type="tiny" color={colors.grey60} mb={spacings.px24}>
                Loan number {loan.loan_number} | Est.closing{' '}
                {loan.est_closing_date ? new Date(loan.est_closing_date).getFullYear() : '-'}
              </Paragraph>
            )
          }))}
        />
      )}
    />
  );
};

export default SelectLoanModal;
