import { useQuery } from '@tanstack/react-query';

import { IPhoneAddressBookEntry } from '../../interfaces/IPhoneAddressBookEntry';
import api from '../../services/api';

const usePhoneAddressBookEntries = () =>
  useQuery({
    queryKey: ['phone_address_book_entries'],
    queryFn: (): Promise<{ phone_address_book_entries: IPhoneAddressBookEntry[] }> =>
      api.get('/api/frontend/phone_address_book_entries'),
    select: data => data.phone_address_book_entries,
    staleTime: Infinity
  });

export default usePhoneAddressBookEntries;
