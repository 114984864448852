import { useMutation, UseMutationResult } from '@tanstack/react-query';

import api from '../../services/api';

interface UpdateLeadStarredParams {
  leadId: number;
  starred: boolean;
}

const updateLeadStarred = ({ leadId, starred }: UpdateLeadStarredParams): Promise<null> =>
  api.put(`/api/frontend/leads/${leadId}/starred`, {
    body: { starred }
  });

const useUpdateLeadStarred = (onSuccess?: () => void): UseMutationResult<any, any, any> =>
  useMutation({ mutationFn: updateLeadStarred, onSuccess });

export default useUpdateLeadStarred;
