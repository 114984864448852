import { AutoCoverage, CoverageType, HomeCoverage, HomeEndorsement } from '../../interfaces/ICoverage';

export const homeCoveragesDeductiblesSelector = ({ coverages }: { coverages: HomeCoverage[] }): HomeCoverage[] =>
  coverages.filter(coverage => !!coverage.deductible_description);

export const homeEndorsementsDeductiblesSelector = ({
  endorsements
}: {
  endorsements: HomeEndorsement[];
}): HomeCoverage[] => endorsements.filter(endorsement => !!endorsement.deductible_description);

export const homeExcludedSelector = ({ endorsements }: { endorsements: HomeEndorsement[] }): HomeCoverage[] =>
  endorsements.filter(endorsement => endorsement.type === CoverageType.Excluded);

export const homeSpecialLiabilityLimitsSelector = ({
  endorsements
}: {
  endorsements: HomeEndorsement[];
}): HomeCoverage[] => endorsements.filter(endorsement => endorsement.type === CoverageType.SpecialLiabilityLimit);

export const homeOtherLimitsSelector = ({ endorsements }: { endorsements: HomeEndorsement[] }): HomeCoverage[] =>
  endorsements.filter(endorsement => endorsement.type === CoverageType.OtherLimit);

export const homeDiscountsSelector = ({ endorsements }: { endorsements: HomeEndorsement[] }): HomeCoverage[] =>
  endorsements.filter(endorsement => endorsement.type === CoverageType.Discount);

export const autoDeductiblesSelector = ({ coverages }: { coverages: AutoCoverage[] }): HomeCoverage[] =>
  coverages.filter(coverage => !!coverage.deductible_description);

export const autoExcludedSelector = ({ coverages }: { coverages: AutoCoverage[] }): HomeCoverage[] =>
  coverages.filter(coverage => coverage.type === CoverageType.Excluded);

export const autoSpecialLiabilityLimitsSelector = ({ coverages }: { coverages: AutoCoverage[] }): AutoCoverage[] =>
  coverages.filter(coverage => coverage.type === CoverageType.SpecialLiabilityLimit);

export const autoOtherLimitsSelector = ({ coverages }: { coverages: AutoCoverage[] }): HomeCoverage[] =>
  coverages.filter(coverage => coverage.type === CoverageType.OtherLimit && !coverage.deductible_description);

export const autoDiscountsSelector = ({ coverages }: { coverages: AutoCoverage[] }): HomeCoverage[] =>
  coverages.filter(coverage => coverage.type === CoverageType.Discount);
