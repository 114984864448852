import { ClassNames } from '@emotion/react';
import React from 'react';

import { ColumnGenerator, Copy, Link, StarIndicator, Table, TableColumns } from '../../../components/common';
import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../components/core/FlexBox';
import Tag from '../../../components/core/Tag';
import TelephonyTooltip from '../../../components/core/TelephonyTooltip';
import Text from '../../../components/core/Text';
import featureFlags from '../../../constants/featureFlags';
import { IDashboardLead } from '../../../interfaces';
import { ILeadMode } from '../../../interfaces/IDashboardLead';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { borderRadius, spacings } from '../../../theme/variables';
import { phoneFormatter } from '../../../utils/formatter';

interface DashboardLeadsListProps {
  onStarredChange: (leadId: number, isStarred: boolean) => void;
  onSortingChange?: ({ sort_column, sort_order }: { sort_column: string; sort_order: string }) => void;
  defaultSorting?: { sort_column: string; sort_order: string };
  manual?: boolean;
  leads: IDashboardLead[] | undefined;
}

const renderStarIndicatorCell = ({
  dashboardLead: { lead_id, starred },
  onStarredChange
}: {
  dashboardLead: IDashboardLead;
  onStarredChange: DashboardLeadsListProps['onStarredChange'];
}) => {
  return <StarIndicator starred={starred} onChange={(starred: boolean) => onStarredChange(lead_id, starred)} />;
};

const renderCustomerNameCell = ({ customer_id, customer_name, lead_mode }: IDashboardLead) => {
  const isFakeLead = lead_mode === ILeadMode.FakeLead;
  const customerName = isFakeLead ? 'Unknown Unknown' : customer_name;

  return (
    <FlexBox alignItemsCenter gap={spacings.px8}>
      {customer_id && (
        <Link className="fs-mask" to={{ pathname: `/customers/${customer_id}` }}>
          {customerName}
        </Link>
      )}
      {isFakeLead && (
        <Tag
          backgroundColor={colors.statusRed}
          label="John Doe lead"
          borderRadius={borderRadius}
          textType="tiny"
          data-for="fake-lead-tip"
          data-tip="Doesn’t take part in search, invisible for agents"
        />
      )}
    </FlexBox>
  );
};

const renderPhoneCell = ({ phone, customer_gid, do_not_contact }: IDashboardLead) => {
  const canCallWithDNC = authInfo.features.do_not_contact_call_available;

  const number = (!do_not_contact || canCallWithDNC) && (
    <FlexBox>
      <TelephonyTooltip phone={phone} personGid={customer_gid}>
        <ClassNames>
          {({ css }) => (
            <Copy
              value={phoneFormatter(phone)}
              className={
                do_not_contact && authInfo.features.do_not_contact_call_available
                  ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                  : 'fs-mask'
              }
            />
          )}
        </ClassNames>
      </TelephonyTooltip>
    </FlexBox>
  );

  const dnc = do_not_contact && (
    <Text color={colors.statusRed} data-testid="do-not-contact">
      Do not contact
    </Text>
  );

  return number || dnc;
};

const DashboardLeadsList = ({
  onStarredChange,
  onSortingChange = () => {},
  defaultSorting,
  manual = true,
  leads
}: DashboardLeadsListProps) => {
  const columns = [
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XS,
      accessor: 'starred',
      Cell: ({ original }: { original: IDashboardLead }) =>
        renderStarIndicatorCell({ dashboardLead: original, onStarredChange })
    }),
    ColumnGenerator.simple({
      accessor: 'lead_id',
      Header: 'Lead id',
      Cell: ({ original: { lead_id, lead_gid } }: { original: IDashboardLead }) => (
        <Link to={featureFlags.scoutingReportRedirect ? `/scouting_report?lead_gid=${lead_gid}` : `/leads/${lead_id}`}>
          {lead_id}
        </Link>
      )
    }),
    ColumnGenerator.stretchable({
      accessor: 'customer_name',
      Header: 'Customer name',
      Cell: ({ original }: { original: IDashboardLead }) => renderCustomerNameCell(original)
    }),
    TableColumns.LeadLender,
    ColumnGenerator.stretchable({
      accessor: 'partner',
      Header: 'Partner',
      className: 'partner-cell'
    }),
    ColumnGenerator.stretchable({
      accessor: 'source_product',
      Header: 'Source product',
      className: 'source-product-cell'
    }),
    TableColumns.LeadDisposition,
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XXL,
      sortable: false,
      Header: 'Phone number',
      Cell: ({ original }: { original: IDashboardLead }) => renderPhoneCell(original)
    }),
    ColumnGenerator.simple({
      accessor: 'residential_state',
      className: 'fs-mask state-cell',
      Header: 'State'
    }),
    TableColumns.LeadAge,
    TableColumns.DashboardLeadLastActions
  ];

  return (
    <>
      <Table
        onSortingChange={onSortingChange}
        defaultSorting={defaultSorting || {}}
        data={leads || []}
        columns={columns}
        manual={manual}
        noDataMessage="No leads found"
        className="leads"
      />
      <Tooltip id="fake-lead-tip" />
    </>
  );
};

export default DashboardLeadsList;
