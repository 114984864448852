import { skipToken, useQuery } from '@tanstack/react-query';

import { IPartner } from '../../interfaces';
import api from '../../services/api';

const usePartner = (key?: string) =>
  useQuery({
    queryKey: ['partner', key],
    queryFn: key ? (): Promise<IPartner> => api.get(`/api/frontend/partners/${key}`) : skipToken
  });

export default usePartner;
