/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { documentFileDownloadUrl, documentFilePreviewUrl, IDocument } from '../../interfaces/IDocument';
import colors from '../../theme/colors';
import { ellipsis } from '../../utils/formatter';
import { AttachmentIcon } from '../core/icons';

const ELLIPSIS_NAME_COUNT = 120;

const renderAttachment = (attachment: IDocument) => {
  const { document_type_title, file_path, name, id } = attachment;

  return (
    <div
      key={id}
      css={css`
        align-items: center;
        display: flex;
        min-height: 3rem;
        padding: 1rem;
      `}
    >
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={documentFileDownloadUrl({ file_path })}
        css={css`
          margin-left: 3rem;
          margin-right: 1rem;
        `}
      >
        <AttachmentIcon color={colors.grey60} />
      </a>
      <a target="_blank" rel="noopener noreferrer" href={documentFilePreviewUrl({ file_path })}>
        {ellipsis(name, ELLIPSIS_NAME_COUNT)}
      </a>
      {document_type_title && (
        <span
          css={css`
            border: 1px solid #2eb566;
            border-radius: 2px;
            color: #2eb566;
            font-size: 0.3rem;
            font-weight: bold;
            margin-left: 0.5rem;
            background-color: #b8eece;
            margin-top: 0.3rem;
            padding: 0 0.2rem;
            text-transform: uppercase;
          `}
        >
          {document_type_title}
        </span>
      )}
    </div>
  );
};

const Attachments = ({
  arrowOffset,
  attachments = []
}: {
  arrowOffset: string;
  attachments: IDocument[];
}): JSX.Element => {
  return (
    <div
      css={css`
        background-color: #fafafa;
        border-top: solid 1px #eaedf2;
      `}
    >
      <div
        css={css`
          border-bottom: 7px solid #eaedf2;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          float: right;
          height: 0;
          position: relative;
          top: -7px;
          width: 0;
        `}
        style={{ right: arrowOffset }}
      >
        <div
          css={css`
            border-bottom: 7px solid #fafafa;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            height: 0;
            margin-left: -10px;
            margin-top: 1px;
            width: 0;
          `}
        />
      </div>
      {attachments.map(renderAttachment)}
    </div>
  );
};

export default Attachments;
