import { useMutation } from '@tanstack/react-query';

import api from '../../../services/api';

interface UpdateLeadCaptureMethodParams {
  leadId: number;
  leadCaptureMethod: string;
}

export const updateLeadCaptureMethod = (params: UpdateLeadCaptureMethodParams): Promise<null> =>
  api.put(`/api/frontend/leads/${params.leadId}/lead_capture_method`, {
    body: { lead_capture_method: params.leadCaptureMethod }
  });

const useUpdateLeadCaptureMethod = (onSuccess: (data: null, variables: UpdateLeadCaptureMethodParams) => void) =>
  useMutation({ mutationFn: updateLeadCaptureMethod, onSuccess });

export default useUpdateLeadCaptureMethod;
