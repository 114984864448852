import { capitalize, titleize } from '../utils/formatter';
import { AssetType, IPersonAsset, VehicleUsageType } from './IPersonAsset';

export enum VehicleKind {
  VAN = 'van',
  BUS = 'bus',
  WAGON = 'wagon',
  SEDAN = 'sedan',
  TRUCK = 'truck',
  PICKUP = 'pickup',
  LUXURY = 'luxury',
  TRAILER = 'trailer',
  UNKNOWN = 'unknown',
  HATCHBACK = 'hatchback',
  MOTORCYCLE = 'motorcycle',
  CONVERTIBLE = 'convertible',
  COUPE_2_DOOR = 'coupe 2 door',
  PASSENGER_CAR = 'passenger car',
  INCOMPLETE_VEHICLE = 'incomplete vehicle',
  MPV = 'multipurpose passenger vehicle (mpv)',
  ALL_TERRAIN_VEHICLE = 'all terrain vehicle'
}

export const isAutoAssetNotQuotable = (vehicleKind: VehicleKind | null): boolean => {
  if (!vehicleKind) {
    return false;
  }
  return [
    VehicleKind.TRAILER,
    VehicleKind.MOTORCYCLE,
    VehicleKind.INCOMPLETE_VEHICLE,
    VehicleKind.BUS,
    VehicleKind.ALL_TERRAIN_VEHICLE
  ].includes(vehicleKind);
};

export const notMotorcycleOrTrailer = (kind: IVehicle['kind']) =>
  kind !== VehicleKind.MOTORCYCLE && kind !== VehicleKind.TRAILER;

export interface IVehicle extends IPersonAsset {
  asset_name: AssetType.Auto;
  usage: VehicleUsageType | null;
  make: string | null;
  model: string | null;
  kind: VehicleKind | null;
  submodel: string | null;
  year: number | null;
  vin: string | null;
  plate_number: string | null;
  plate_type: string | null;
  annual_mileage: number | null;
  distance_driven_daily: number | null;
  days_per_week_driven: number | null;
  used_for_ridesharing: boolean | null;
  last_service_date: string | null;
  last_service_writer_name: string | null;
  first_owner: string | null;
  sale_person_name: string | null;
  one_way_mileage: number | null;
}

export const buildVehicleTitle = (vehicle?: IVehicle) => {
  if (!vehicle) {
    return 'Unknown vehicle';
  }
  if (vehicle.year && vehicle.make && vehicle.model) {
    return titleize(`${vehicle.year} ${vehicle.make} ${vehicle.model}`);
  }
  return capitalize(vehicle.vin) || vehicle.description_without_icon || 'Unknown vehicle';
};

export const generateAutoDescription = (assets: IVehicle[] | null) =>
  assets?.map((auto, index) => `${index > 0 ? ', ' : ''}${buildVehicleTitle(auto)}`);

export const VIN_REGEXP = /^[A-HJ-NPR-Z0-9]{17}$/i;
