/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { Field, FieldProps } from 'formik';

import { InputSize, LabelSize } from '../../../../theme/variables';
import TimeInput, { TimeInputProps } from '../../inputs/Time';
import FormError from './FormError';
import LabeledInput from './LabeledInput';

export interface TimeFieldProps extends Partial<TimeInputProps> {
  label: string | JSX.Element;
  id: string;
  name: string;
  required?: boolean;
  testId?: string;
  secondary?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  inputSize?: InputSize;
}

const TimeField = ({
  label,
  id,
  name,
  required,
  testId,
  inline,
  secondary = false,
  customLabelCss,
  labelSize,
  inputSize,
  ...rest
}: TimeFieldProps): JSX.Element => {
  return (
    <LabeledInput
      id={id}
      required={required}
      label={label}
      inline={inline}
      secondary={secondary}
      disabled={rest.disabled}
      customCss={customLabelCss}
      labelSize={labelSize}
      inputSize={inputSize}
    >
      <Field name={name}>
        {({ field: { onChange, value }, meta, form }: FieldProps) => {
          const hasError = meta.error && meta.touched;
          const onTimeChange = (newValue: Date | string) => {
            onChange({
              target: {
                name,
                value: newValue
              }
            });
          };

          return (
            <>
              <TimeInput
                data-testid={testId}
                {...rest}
                disabled={rest.disabled || form.status === 'disabled'}
                id={id}
                inline={inline}
                hasError={!!hasError}
                aria-invalid={!!hasError}
                aria-errormessage={`${id}-error`}
                onTimeChange={onTimeChange}
                value={value}
              />
              {inline && !!hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />}
              {!inline && <FormError id={id} hasError={!!hasError} error={meta.error} />}
            </>
          );
        }}
      </Field>
    </LabeledInput>
  );
};

export default TimeField;
