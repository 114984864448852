/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { Link } from '../../../../components/common';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import HorizontalTabs from '../../../../components/core/Tabs/HorizontalTabs';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { InsurableInterest, Requirement } from '../../../../interfaces/IPolicyType';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import usePerson from '../../../../queries/people/usePerson';
import usePersonAddresses from '../../../../queries/people/usePersonAddresses';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import { isCustomerDataCompletenessHigh } from '../../_helpers';
import { GuidedSellingRoutes } from '../../navigation';
import CustomerProfile from './CustomerProfile';
import CustomerProfileForm from './CustomerProfile/CustomerProfileForm';
import CustomerProfileSkeleton from './CustomerProfile/CustomerProfileSkeleton';
import Homes from './Homes';
import Vehicles from './Vehicles';

interface CollectedInfoTabProps {
  isEditModeAvailable: boolean;
  fullScreen?: boolean;
}

const CollectedInfoTab = ({ isEditModeAvailable, fullScreen = false }: CollectedInfoTabProps) => {
  const { search } = useLocation();
  const { personGid, leadGid, lead, candidateGid, isLifeLead, customerDataCompleteness } =
    useGuidedSellingExperienceContext();

  const [editCustomerProfile, setEditCustomerProfile] = React.useState(false);

  const { data: person, refetch: refetchPerson } = usePerson(personGid);
  const { data: relatedPeople, refetch: refetchRelatedPeople } = usePersonRelatedPeople(
    isLifeLead ? undefined : personGid
  );
  const { data: personAddresses, refetch: refetchPersonAddresses } = usePersonAddresses(personGid);

  const { data: homeOpportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    candidateGid,
    insurableInterest: InsurableInterest.RealProperty,
    requirement: Requirement.Essential
  });
  const { data: vehicleOpportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    candidateGid,
    insurableInterest: InsurableInterest.Vehicle,
    requirement: Requirement.Essential
  });

  const isLoadingCustomerProfile = !person || !personAddresses || (isLifeLead ? false : !relatedPeople);

  const TabContent = fullScreen ? React.Fragment : VerticalTabContent;

  return (
    <FlexBox columnDirection fitParentHeight>
      {fullScreen ? (
        <Paragraph type="large" pb={spacings.px16}>
          Collected info
        </Paragraph>
      ) : (
        <VerticalTabHeader>
          <FlexBox alignItemsCenter mb={fullScreen ? spacings.px12 : undefined}>
            <Paragraph type="large">Collected info</Paragraph>
            <Link
              to={`${GuidedSellingRoutes.CollectedInfo}${search}`}
              onClick={e => {
                e.preventDefault();
                window.open(e.currentTarget.href, '', 'width=600,height=2000');
                analytics.track('Open collected info in new window clicked');
              }}
            >
              <IconButton
                icon={ButtonIcons.OpenInNewArrowless}
                color={colors.black}
                ml={spacings.px4}
                data-testid="new-window-collected-info-button"
              />
            </Link>
          </FlexBox>
        </VerticalTabHeader>
      )}
      <TabContent>
        {isLoadingCustomerProfile && <CustomerProfileSkeleton />}
        {!isLoadingCustomerProfile && !editCustomerProfile && (
          <CustomerProfile
            person={person}
            relatedPeople={relatedPeople || []}
            isDataVerifiedByCustomer={isCustomerDataCompletenessHigh(customerDataCompleteness)}
            isEditModeAvailable={isEditModeAvailable}
            onEdit={() => setEditCustomerProfile(true)}
          />
        )}
        {!isLoadingCustomerProfile && editCustomerProfile && (
          <CustomerProfileForm
            person={person}
            relatedPeople={relatedPeople || []}
            personAddresses={personAddresses}
            lead={lead}
            isDataVerifiedByCustomer={isCustomerDataCompletenessHigh(customerDataCompleteness)}
            onClose={() => setEditCustomerProfile(false)}
            onSubmit={async () => {
              await Promise.all([
                refetchPerson(),
                isLifeLead ? null : refetchRelatedPeople(),
                refetchPersonAddresses()
              ]);
              setEditCustomerProfile(false);
            }}
          />
        )}
      </TabContent>
      <TabContent
        fitParentHeight
        customCss={css`
          overflow-y: auto;
          flex: 1;
        `}
      >
        {personGid && homeOpportunities && vehicleOpportunities && (
          <HorizontalTabs
            tabs={[
              {
                tabName: 'Home',
                content: (
                  <Homes
                    key="homes"
                    personGid={personGid}
                    engagementGid={leadGid || candidateGid}
                    leadId={lead?.id}
                    homeOpportunities={homeOpportunities}
                    isEditModeAvailable={isEditModeAvailable}
                    relatedPeople={relatedPeople}
                  />
                )
              },
              {
                tabName: 'Auto',
                content: (
                  <Vehicles
                    key="autos"
                    vehicleOpportunities={vehicleOpportunities}
                    personGid={personGid}
                    lead={lead}
                    isEditModeAvailable={isEditModeAvailable}
                  />
                )
              },
              isLifeLead && {
                tabName: 'Ancillary',
                content: <Container key="ancillary" />,
                active: true
              }
            ].filter(Boolean)}
            stickyHeader
          />
        )}
      </TabContent>
    </FlexBox>
  );
};

export default CollectedInfoTab;
