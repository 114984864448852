/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { Link } from '../../../components/common';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { IPerson } from '../../../interfaces/IPerson';
import colors from '../../../theme/colors';

const Header = ({ person, leadId }: { person: IPerson; leadId: string | undefined }) =>
  leadId ? (
    <FlexBox>
      <Heading type="h3">Request quotes for lead ID</Heading>
      <Link
        to={`/leads/${leadId}`}
        css={css`
          margin-left: 4px;
        `}
      >
        <Heading
          type="h3"
          color={colors.azure50}
          css={css`
            color: inherit;
          `}
        >
          {leadId}
        </Heading>
      </Link>
      <Heading
        type="h3"
        css={css`
          margin-left: 2px;
        `}
      >
        , Customer:
      </Heading>
      <Link
        to={`/people/${person.gid}`}
        css={css`
          margin-left: 4px;
        `}
      >
        <Heading
          type="h3"
          color={colors.azure50}
          className="fs-mask"
          css={css`
            color: inherit;
          `}
        >
          {person.name}
        </Heading>
      </Link>
    </FlexBox>
  ) : (
    <FlexBox>
      <Heading type="h3">Request quotes for customer:</Heading>
      <Link
        to={`/people/${person.gid}`}
        css={css`
          margin-left: 4px;
        `}
      >
        <Heading
          type="h3"
          color={colors.azure50}
          className="fs-mask"
          css={css`
            color: inherit;
          `}
        >
          {person.name}
        </Heading>
      </Link>
    </FlexBox>
  );

export default Header;
