import { useParams } from 'react-router-dom';

import { IMaticPolicy } from '../../interfaces/IPolicy';
import usePersonMaticPolicies from '../people/person_policies/usePersonMaticPolicies';
import usePolicyLinesOfBusiness, { LineOfBusiness } from './usePolicyLinesOfBusiness';
import usePolicyTransactions, { PolicyTransaction } from './usePolicyTransactions';

interface Result {
  isCurrentPolicySyncedWithIVANS: boolean;
  linesOfBusiness: LineOfBusiness[];
  policyTransactions: PolicyTransaction[];
  policies: IMaticPolicy[];
  isCurrentPolicyLoaded: boolean;
  currentPolicy?: IMaticPolicy;
  policyId?: string;
}

const usePoliciesDetails = (personGid: string): Result => {
  const { policyId } = useParams();

  const { data: policies, isLoading: isLoadingPolicies } = usePersonMaticPolicies({
    personGid,
    filters: { show_renewed_policies: false }
  });
  const { data: linesOfBusiness, isLoading: isLoadingLinesOfBusiness } = usePolicyLinesOfBusiness(policyId);
  const { data: policyTransactions, isLoading: isLoadingTransactions } = usePolicyTransactions(policyId);

  const currentPolicy = policies?.find(policy => `${policy.id}` === policyId);
  const isLoading = isLoadingTransactions || isLoadingLinesOfBusiness || isLoadingPolicies;
  const isCurrentPolicyLoaded = !!currentPolicy && !isLoading;
  const isCurrentPolicySyncedWithIVANS =
    isCurrentPolicyLoaded && !!linesOfBusiness?.length && !!policyTransactions?.length;

  return {
    isCurrentPolicySyncedWithIVANS,
    linesOfBusiness: linesOfBusiness || [],
    policyTransactions: policyTransactions || [],
    policies: policies || [],
    currentPolicy,
    policyId,
    isCurrentPolicyLoaded
  };
};

export default usePoliciesDetails;
