import { create } from 'zustand';

interface PopoversQueue {
  popovers: string[];
  active: string | null;
  addPopover: (popover: string) => void;
  removePopover: (popover: string) => void;
}
const usePopoversQueue = create<PopoversQueue>(set => ({
  popovers: [],
  active: null,
  addPopover: (popover: string) =>
    set(state => {
      const nextStack = [...state.popovers, popover];
      return { popovers: nextStack, active: state.active || nextStack[0] || null };
    }),
  removePopover: (popover: string) =>
    set(state => {
      const nextStack = state.popovers.filter(p => p !== popover);
      return { popovers: nextStack, active: nextStack[0] || null };
    })
}));

export default usePopoversQueue;
