import React from 'react';

import Paragraph from '../../../../components/core/Paragraph';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import Notes from './Notes';

const NotesTab = () => (
  <>
    <VerticalTabHeader>
      <Paragraph type="large">Notes</Paragraph>
    </VerticalTabHeader>
    <VerticalTabContent>
      <Notes />
    </VerticalTabContent>
  </>
);

export default NotesTab;
