/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { createColumnHelper } from '@tanstack/react-table';
import React from 'react';

import NewPagination from '../../components/common/NewPagination/NewPagination';
import { EditIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import Table from '../../components/core/Table/Table';
import Text from '../../components/core/Text';
import useDebouncedValue from '../../hooks/useDebouncedValue';
import { CoverageMapping } from '../../interfaces/ICoverageMapping';
import useSuspenseCoveragesMappings, {
  CoveragesMappingsQueryParams
} from '../../queries/coverages_mappings/useCoveagesMappings';
import { capitalize } from '../../utils/formatter';
import EditMappingModal from './EditMappingModal';

const PAGE_SIZE = 15;

interface CoveragesMappingsListProps {
  selectedKind: CoveragesMappingsQueryParams['kind'];
  selectedCarrierId: string | undefined;
  selectedIvansCode: string | undefined;
  selectedIvansDescription: string | undefined;
  currentPage: number;
  onCurrentPageChange: (page: number) => void;
}

const columnHelper = createColumnHelper<CoverageMapping>();

const CoveragesMappingsList = ({
  selectedKind,
  selectedCarrierId,
  selectedIvansCode,
  selectedIvansDescription,
  currentPage,
  onCurrentPageChange
}: CoveragesMappingsListProps) => {
  const ivansCode = useDebouncedValue(selectedIvansCode?.trim(), 300);
  const ivansDescription = useDebouncedValue(selectedIvansDescription?.trim(), 300);

  const { data: coveragesMappingsDashboard, refetch: refetchMappings } = useSuspenseCoveragesMappings({
    carrier_id: selectedCarrierId,
    kind: selectedKind,
    ivans_code: ivansCode,
    ivans_description: ivansDescription,
    page: currentPage,
    per_page: PAGE_SIZE
  });

  const [editedMapping, setEditedMapping] = React.useState<CoverageMapping | undefined>();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor(row => row.carrier_name, {
        id: 'carrier_name',
        cell: info => info.getValue(),
        header: () => <Paragraph>Carrier</Paragraph>
      }),
      columnHelper.accessor(row => row.ivans_code, {
        id: 'ivans_code',
        cell: info => info.getValue(),
        header: () => <Paragraph>IVANS code</Paragraph>
      }),
      columnHelper.accessor(row => row.ivans_description, {
        id: 'ivans_description',
        cell: info => info.getValue(),
        header: () => <Paragraph>IVANS description</Paragraph>
      }),
      columnHelper.accessor(row => row.mapped_code, {
        id: 'mapped_code',
        cell: info => info.getValue(),
        header: () => <Paragraph>Mapped code</Paragraph>
      }),
      columnHelper.accessor(row => row.mapped_description, {
        id: 'mapped_description',
        cell: info => info.getValue(),
        header: () => <Paragraph>Mapped description</Paragraph>
      }),
      columnHelper.accessor(row => row.mapped_category, {
        id: 'mapped_category',
        cell: info => <Text>{capitalize(info.getValue() || '')}</Text>,
        header: () => <Paragraph>Mapped category</Paragraph>
      }),
      columnHelper.accessor(row => row.count, {
        id: 'count',
        cell: info => info.getValue(),
        header: () => <Paragraph>Count</Paragraph>
      }),
      columnHelper.accessor(row => row.ignored, {
        id: 'ignored',
        cell: info => <Text>{info.getValue() ? 'Yes' : 'No'}</Text>,
        header: () => <Paragraph>Ignored</Paragraph>
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ row: { original } }) => (
          <div
            onClick={() => setEditedMapping(original)}
            css={css`
              cursor: pointer;
            `}
          >
            <EditIcon />
          </div>
        ),
        header: () => <Paragraph>Actions</Paragraph>
      })
    ],
    []
  );

  return (
    <>
      <Table
        data={coveragesMappingsDashboard?.coverages_mappings || []}
        columns={columns}
        testId="coverages-mappings-table"
      />
      <NewPagination
        pageLimit={PAGE_SIZE}
        totalRecords={coveragesMappingsDashboard?.count || 0}
        currentPage={currentPage}
        onPageChanged={({ currentPage }) => onCurrentPageChange(currentPage)}
      />
      {editedMapping && (
        <EditMappingModal
          coverageMapping={editedMapping}
          cancelHandler={() => setEditedMapping(undefined)}
          confirmHandler={() => {
            refetchMappings();
            setEditedMapping(undefined);
          }}
        />
      )}
    </>
  );
};

export default CoveragesMappingsList;
