import { ColumnGenerator, Link, Table, TableColumns } from '../../../components/common';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { SORT_ORDER_DESC } from '../../../constants';
import { ICandidate } from '../../../interfaces';
import { ILead } from '../../../interfaces/ILead';
import usePersonCandidates from '../../../queries/people/usePersonCandidates';
import usePersonLeads from '../../../queries/people/usePersonLeads';
import authInfo from '../../../services/authInfo';
import { spacings } from '../../../theme/variables';
import CreateCustomerLeadButton from './CreateCustomerLeadButton';
import LeadDisposition from './LeadDisposition';

const LEAD_COLUMNS = [
  TableColumns.LeadId,
  ColumnGenerator.stretchable({
    accessor: 'gid',
    Header: 'GID',
    sortable: false,
    Cell: ({ original: { gid } }: { original: ILead }) => <Link to={`/scouting_report?lead_gid=${gid}`}>{gid}</Link>
  }),
  ColumnGenerator.stretchable({
    accessor: 'partner',
    Header: 'Partner',
    Cell: ({
      original: {
        source_dimensions: { partner }
      }
    }: {
      original: ILead;
    }) => partner
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XL,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ original: { insurance_category } }: { original: ILead }) => insurance_category
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XXL,
    accessor: 'source_product',
    Header: 'Source Product',
    Cell: ({
      original: {
        source_dimensions: { source_product }
      }
    }: {
      original: ILead;
    }) => source_product
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XL,
    accessor: 'disposition_type',
    Header: 'Disposition',
    Cell: ({ original: { id } }: { original: ILead }) => <LeadDisposition leadId={id} />
  }),
  TableColumns.LeadAge
];

const CANDIDATE_COLUMNS = [
  ColumnGenerator.stretchable({
    accessor: 'gid',
    Header: 'GID',
    sortable: false,
    Cell: ({ original: { gid } }: { original: ICandidate }) => (
      <Link to={`/scouting_report?candidate_gid=${gid}`}>{gid}</Link>
    )
  }),
  ColumnGenerator.stretchable({
    accessor: 'partner',
    Header: 'Partner',
    Cell: ({
      original: {
        source_dimensions: { partner }
      }
    }: {
      original: ICandidate;
    }) => partner
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XL,
    accessor: 'category',
    Header: 'Category',
    Cell: ({ original: { insurance_category } }: { original: ICandidate }) => insurance_category
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XXL,
    accessor: 'source_product',
    Header: 'Source Product',
    Cell: ({
      original: {
        source_dimensions: { source_product }
      }
    }: {
      original: ICandidate;
    }) => source_product
  }),
  TableColumns.LeadAge
];

const CustomerLeads = ({ personGid }: { personGid: string }) => {
  const { data: leads, isFetching: isFetchingLeads } = usePersonLeads(personGid);
  const { data: candidates, isFetching: isFetchingCandidates } = usePersonCandidates(personGid);

  const isFetching = isFetchingLeads || isFetchingCandidates;

  return isFetching ? (
    <TablePlaceholder testId="customer-leads-loader" />
  ) : (
    <FlexBox fitParentWidth columnDirection gap={spacings.px24}>
      <FlexBox alignItemsCenter justifySpaceBetween>
        <Text type="large" bold>
          Leads
        </Text>
        <CreateCustomerLeadButton personGid={personGid} />
      </FlexBox>
      <Table
        data={leads}
        columns={LEAD_COLUMNS}
        defaultSorting={{ sort_column: 'lead_id', sort_order: SORT_ORDER_DESC }}
        manual={false}
        noDataMessage="No leads found"
        testId="customer-leads"
      />
      {authInfo.features.customer_candidates_view && (
        <>
          <Text type="large" bold>
            Candidates
          </Text>
          <Table
            data={candidates}
            columns={CANDIDATE_COLUMNS}
            defaultSorting={{ sort_column: 'age', sort_order: SORT_ORDER_DESC }}
            manual={false}
            noDataMessage="No candidates found"
            testId="customer-candidates"
          />
        </>
      )}
    </FlexBox>
  );
};

export default CustomerLeads;
