import './Status.scss';

import classNames from 'classnames';
import React from 'react';

import Tooltip from '../Tooltip/Tooltip';

const getTooltipMessage = (tooltipInfo: any) =>
  Object.keys(tooltipInfo).map(
    key =>
      tooltipInfo[key] && (
        <div className={`tooltip-${key.toLowerCase()}`} key={key}>
          <span className="status-title">{key.toUpperCase()} </span>
          <span className="text-capitalize">{tooltipInfo[key]}</span>
        </div>
      )
  );

const Status = ({
  className = 'unknown',
  tooltipInfo,
  children
}: {
  tooltipInfo: any;
  className?: string;
  children?: React.ReactNode;
}) => {
  const message = getTooltipMessage(tooltipInfo);
  const isEmpty = !message.filter(Boolean)[0];
  const Component = isEmpty ? React.Fragment : Tooltip;
  const props = isEmpty ? {} : { right: true, message, left: true };

  return (
    <div className="status-container">
      <Component {...props}>
        <div className={classNames('status', `status-${className}`)} />
        {children}
      </Component>
    </div>
  );
};

export default Status;
