/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import { CheckIcon, CircleIcon, VisibilityOffIcon, WarningIcon } from '../../../components/core/icons';
import ProgressCircle from '../../../components/core/navigation/ProgressCircle';
import Paragraph from '../../../components/core/Paragraph';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { IDataCollectionStep } from '../_interfaces/IDataCollectionStep';

interface StepIndicatorProps {
  step: IDataCollectionStep;
  subSteps: IDataCollectionStep[] | undefined;
}

const DataCollectionStepIndicator = ({ step, subSteps }: StepIndicatorProps) => {
  if (step.is_disqualified) {
    return (
      <>
        <WarningIcon
          data-tip
          data-for={`${step.key}-tip`}
          color={colors.statusRed}
          css={css`
            overflow: visible;
          `}
        />
        <Tooltip id={`${step.key}-tip`} theme="light">
          <Paragraph
            customCss={css`
              max-width: 300px;
            `}
          >
            {step.opportunities?.[0]?.disqualification_message}
          </Paragraph>
        </Tooltip>
      </>
    );
  }

  if (!step.is_data_collection_enabled) {
    return <VisibilityOffIcon color={colors.grey60} width={spacings.px20} height={spacings.px20} />;
  }

  const showGreyCircle = subSteps ? step.is_navigation_disabled : !step.active && !step.is_completed;
  const dataCollectionEnabledSubSteps = subSteps?.filter(step => step.is_data_collection_enabled);

  const allSubStepsCompleted = (): boolean => {
    if (!dataCollectionEnabledSubSteps && step.is_completed) {
      return true;
    }
    if (dataCollectionEnabledSubSteps) {
      const subStepLength = dataCollectionEnabledSubSteps.length;
      const completedSubStepsLength = dataCollectionEnabledSubSteps.filter(subStep => subStep.is_completed).length;

      if (subStepLength === completedSubStepsLength && step.is_completed) {
        return true;
      }
    }
    return false;
  };

  const calculateProgress = (subSteps: IDataCollectionStep[], parentStep: IDataCollectionStep): number => {
    const isActive = subSteps.some(subStep => subStep.active) || parentStep.active;
    const sections = subSteps.length + 1;
    let enabledSubSteps = sections - subSteps.filter(subStep => subStep.is_navigation_disabled).length;
    if (!isActive) {
      enabledSubSteps -= 1;
    }
    return (100 / sections) * enabledSubSteps;
  };

  return (
    <>
      {showGreyCircle && <CircleIcon color={colors.grey30} />}
      {step.active && !step.is_completed && !subSteps && <CircleIcon color={colors.azure50} />}
      {allSubStepsCompleted() && <CheckIcon color={colors.statusGreen} />}
      {dataCollectionEnabledSubSteps && !step.is_navigation_disabled && !allSubStepsCompleted() && (
        <ProgressCircle progress={calculateProgress(dataCollectionEnabledSubSteps, step)} />
      )}
    </>
  );
};

export default DataCollectionStepIndicator;
