import { Modal } from '../../../components/common';

const MODAL_MESSAGE =
  'The system tries to sync policy with AMS and IVANS. ' +
  'Sync might take up to 5 minutes, but the page will NOT refresh automatically. Try to refresh the page in a minute.';

const SyncPolicyModal = ({ onCancel, isOpen }: { onCancel: () => void; isOpen: boolean }) => {
  return (
    <Modal
      open={isOpen}
      title="Policy sync scheduled"
      confirmText="Ok"
      confirmHandler={onCancel}
      message={MODAL_MESSAGE}
    />
  );
};

export default SyncPolicyModal;
