import { skipToken, useMutation, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IDrivingRecord } from '../../interfaces/IDrivingRecord';
import api from '../../services/api';

export interface UpdateDrivingRecordPayload {
  claims: { gid: string | undefined; type_gid: string; key: string; destroy: boolean | undefined }[] | undefined;
  accidents: { gid: string | undefined; type_gid: string; key: string; destroy: boolean | undefined }[] | undefined;
  violations:
    | { gid: string | undefined; type_gid: string; key: string; state: string; destroy: boolean | undefined }[]
    | undefined;
}

export const updatePersonDrivingRecord = ({
  personGid,
  drivingRecord
}: {
  personGid: string;
  drivingRecord: UpdateDrivingRecordPayload;
}) => api.put(`/api/frontend/people/${personGid}/driving_record`, { body: drivingRecord });

export const useUpdatePersonDrivingRecord = () => useMutation({ mutationFn: updatePersonDrivingRecord });

export const getPersonDrivingRecord = (
  personGid: string | undefined
): Promise<{ driving_record: IDrivingRecord | undefined }> =>
  api.get(`/api/frontend/people/${personGid}/driving_record`);

const usePersonDrivingRecord = (
  personGid: string | undefined,
  options?: UseQueryOptions<{ driving_record: IDrivingRecord | undefined }, any, any, any>
) =>
  useQuery({
    queryKey: ['person_driving_record', personGid],
    queryFn: personGid ? () => getPersonDrivingRecord(personGid) : skipToken,
    ...options,
    select: data => data.driving_record
  });

export default usePersonDrivingRecord;
