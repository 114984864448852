/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import DescriptionList from '../../../../components/core/DescriptionList';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { spacings } from '../../../../theme/variables';
import { phoneFormatter } from '../../../../utils/formatter';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import BrandInfo from './BrandInfo';
import ContextInfo from './ContextInfo';

const UnknownPersonInfoTab = () => {
  const { callLog, sourceDimensions } = useGuidedSellingExperienceContext();

  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Customer details</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        <div
          data-testid="unknown-person-info"
          css={css`
            > div:last-of-type {
              margin-bottom: 150px;
            }
          `}
        >
          <div data-testid="person">
            <Text type="large" bold>
              Not identified
            </Text>
            {callLog?.external_phone && (
              <FlexBox columnDirection>
                <DescriptionList
                  term={<Text>Phone</Text>}
                  details={<Text className="fs-mask">{phoneFormatter(callLog.external_phone)}</Text>}
                />
              </FlexBox>
            )}
          </div>
          {sourceDimensions?.brand && (
            <div
              data-testid="brand"
              css={css`
                margin-top: ${spacings.px12}px;
              `}
            >
              <BrandInfo />
            </div>
          )}
          {sourceDimensions?.marketing_opportunity && (
            <div
              data-testid="context"
              css={css`
                margin-top: ${spacings.px32}px;
              `}
            >
              <ContextInfo />
            </div>
          )}
        </div>
      </VerticalTabContent>
    </>
  );
};

export default UnknownPersonInfoTab;
