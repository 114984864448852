/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import CircledPolicyTypeIcon from '../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { TrendingUpIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { IOpportunity } from '../../../../interfaces';
import useOpportunitiesWithPolicies from '../../../../queries/leads/opportunities/useOpportunitiesWithPolicies';
// eslint-disable-next-line max-len
import useOpportunitiesWithPremiumPredictions from '../../../../queries/leads/opportunities/useOpportunitiesWithPremiumPredictions';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { dateFormatter, moneyFormatter } from '../../../../utils/formatter';
import PremiumAnalysis from '../../_components/PremiumAnalysis';
import { predictedPremiumIncrease } from '../../_helpers';

const CurrentPolicies = ({ personGid, opportunities }: { personGid: string; opportunities: IOpportunity[] }) => {
  const { opportunitiesWithPolicies, isPending } = useOpportunitiesWithPolicies({
    personGid,
    opportunities
  });
  const { opportunitiesWithPremiumPredictions } = useOpportunitiesWithPremiumPredictions({
    opportunitiesWithPolicies
  });

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  if (isPending) {
    return null;
  }

  const opportunitiesWithPresentPolicies = opportunitiesWithPolicies?.filter(({ latestPolicy }) => !!latestPolicy);
  const opportunitiesWithPoliciesOrPremiumPrediction = opportunitiesWithPremiumPredictions?.filter(
    ({ policies, premium_prediction }) => !!policies?.length || premium_prediction
  );

  return (
    <>
      <Text type="large" bold>
        Current policies
      </Text>
      <Container mt={spacings.px16}>
        {!!opportunitiesWithPresentPolicies && opportunitiesWithPresentPolicies.length > 0 ? (
          opportunitiesWithPresentPolicies.map(({ id, latestPolicy }) => {
            const { policy_type, carrier, expiration_date, premium } = latestPolicy!;
            const premiumPrediction = opportunitiesWithPremiumPredictions?.find(
              opportunity => opportunity.id === id
            )?.premium_prediction;

            const predictedPremiumIncreaseValues = predictedPremiumIncrease({ premium, premiumPrediction });

            return (
              <FlexBox key={id} pv={spacings.px8}>
                <CircledPolicyTypeIcon policyType={policy_type} />
                <FlexBox columnDirection fitParentWidth ml={spacings.px8}>
                  <FlexBox alignItemsCenter justifySpaceBetween gap={spacings.px4}>
                    <span
                      data-tip={carrier.name}
                      data-for="current-policy-carrier-name-tooltip"
                      css={css`
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        width: 150px;
                      `}
                    >
                      <Text bold>{carrier.name}</Text>
                    </span>
                    {expiration_date && (
                      <Text type="small" color={colors.grey60}>
                        expiration {dateFormatter(expiration_date)}
                      </Text>
                    )}
                  </FlexBox>
                  <FlexBox alignItemsCenter gap={spacings.px4}>
                    {premium && <Text>{moneyFormatter(premium, true)}</Text>}
                    {predictedPremiumIncreaseValues && (
                      <>
                        <TrendingUpIcon color={colors.statusRed} />
                        <Text color={colors.statusRed} underline pv={spacings.px4}>
                          +
                          {premium
                            ? moneyFormatter(predictedPremiumIncreaseValues.predictedFlatIncrease, true)
                            : `${predictedPremiumIncreaseValues.predictedPercentageIncrease}%`}
                        </Text>
                      </>
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            );
          })
        ) : (
          <Text color={colors.grey60}>No policy added</Text>
        )}
      </Container>
      {!!opportunitiesWithPoliciesOrPremiumPrediction && opportunitiesWithPoliciesOrPremiumPrediction.length > 0 && (
        <>
          <div
            css={css`
              height: 1px;
              margin-top: ${spacings.px8}px;
              margin-left: 32px;
              margin-right: -12px;
              background: ${colors.grey10};
            `}
          />
          <FlexBox ml={spacings['-px12']} mr={spacings['-px12']}>
            <PremiumAnalysis
              opportunitiesWithPremiumPredictions={opportunitiesWithPremiumPredictions || []}
              customCss={css`
                padding: ${spacings.px12}px ${spacings.px16}px;
              `}
            />
          </FlexBox>
        </>
      )}
      <Tooltip id="current-policy-carrier-name-tooltip" />
    </>
  );
};

export default CurrentPolicies;
