/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import * as React from 'react';

import loader from '../../../assets/img/new-loader.gif';

interface Props {
  width: string | number;
  height: string | number;
  customCss?: SerializedStyles;
}

const Loader = ({ width, height, customCss }: Props) => (
  <img css={customCss} src={loader} style={{ height, width }} alt="loading" data-testid="loader-img" />
);

export default Loader;
