/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { ColumnGenerator, Table } from '../../../components/common';
import Copy from '../../../components/core/Copy';
import CopyableAddress from '../../../components/core/CopyableAddress';
import { DeleteIcon, EditIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { IVehicle } from '../../../interfaces/IVehicle';
import authInfo from '../../../services/authInfo';

interface VehiclesListProps {
  vehicles: IVehicle[];
  defaultSorting: { sort_column: string; sort_order: string };
  onEditVehicle: (vehicle: IVehicle) => void;
  onDeleteVehicle: (vehicle: IVehicle) => void;
}

const singleLineCss = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const renderGidCell = ({ original: { gid } }: { original: IVehicle }) => (
  <Copy value={gid}>
    <Text data-testid="asset_gid" customCss={singleLineCss}>
      {gid}
    </Text>
  </Copy>
);

const renderMake = ({ original: { make } }: { original: IVehicle }) =>
  !!make && (
    <Copy value={make}>
      <Text customCss={singleLineCss}>{make}</Text>
    </Copy>
  );

const renderModel = ({ original: { model } }: { original: IVehicle }) =>
  !!model && (
    <Copy value={model}>
      <Text customCss={singleLineCss}>{model}</Text>
    </Copy>
  );

const renderSubmodel = ({ original: { submodel } }: { original: IVehicle }) =>
  !!submodel && (
    <Copy value={submodel}>
      <Text customCss={singleLineCss}>{submodel}</Text>
    </Copy>
  );

const renderYear = ({ original: { year } }: { original: IVehicle }) =>
  !!year && (
    <Copy value={year.toString()}>
      <Text>{year}</Text>
    </Copy>
  );

const renderVin = ({ original: { vin } }: { original: IVehicle }) =>
  !!vin && (
    <div css={singleLineCss}>
      <Copy value={vin}>
        <Text customCss={singleLineCss}>{vin}</Text>
      </Copy>
    </div>
  );

const renderGaragingAddress = ({ original: { address } }: { original: IVehicle }) => (
  <CopyableAddress address={address} />
);

const AutosList = ({ vehicles, defaultSorting, onEditVehicle, onDeleteVehicle }: VehiclesListProps) => {
  const renderEdit = ({ original }: { original: IVehicle }) => (
    <div
      onClick={() => onEditVehicle(original)}
      data-testid="auto_edit_button"
      className="u-cursor-pointer qa-auto-edit-button"
    >
      <EditIcon />
    </div>
  );

  const renderDelete = ({ original }: { original: IVehicle }) => (
    <div onClick={() => onDeleteVehicle(original)} className="u-cursor-pointer" data-testid="auto_delete_button">
      <DeleteIcon />
    </div>
  );

  const autoColumns = [
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_L,
      Header: 'ID',
      accessor: 'gid',
      sortable: false,
      Cell: renderGidCell
    }),
    ColumnGenerator.simple({
      Header: 'Year',
      accessor: 'year',
      Cell: renderYear
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Make',
      accessor: 'make',
      Cell: renderMake
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Model',
      accessor: 'model',
      Cell: renderModel
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XL,
      Header: 'Submodel',
      accessor: 'submodel',
      Cell: renderSubmodel
    }),
    ColumnGenerator.stretchable({
      Header: 'VIN',
      accessor: 'vin',
      sortable: false,
      className: 'fs-mask',
      Cell: renderVin
    }),
    ColumnGenerator.stretchable({
      Header: 'GARAGING ADDRESS',
      accessor: 'asset',
      className: 'fs-mask',
      Cell: renderGaragingAddress
    }),
    ColumnGenerator.simple({
      width: ColumnGenerator.WIDTH_XS,
      sortable: false,
      Cell: renderEdit
    }),
    authInfo.features.asset_delete &&
      ColumnGenerator.simple({
        width: ColumnGenerator.WIDTH_XS,
        sortable: false,
        Cell: renderDelete
      })
  ].filter(Boolean);

  return (
    <Table
      defaultSorting={defaultSorting}
      columns={autoColumns}
      data={vehicles}
      manual={false}
      noDataMessage="No vehicles found"
    />
  );
};

export default AutosList;
