const AUTO_FORM_TITLE = 'Auto details';
const AUTO_SEARCH_HINT = 'Start typing make, model, submodel, and year';
const AUTO_OWN_OWNERSHIP = 'own';
const AUTO_WORK_USAGE = 'work';
const CANCEL_EDITING_WARNING = 'Do you want to leave this page? Changes you made may not be saved.';
const ENTER_MANUALLY_MESSAGE = 'Enter manually';
const SEARCH_AUTOMATICALLY_MESSAGE = 'Search automatically';
const EDIT_AUTO_SEARCH_ENTITY = 'edit';

const autoForm = {
  AUTO_FORM_TITLE,
  AUTO_SEARCH_HINT,
  AUTO_OWN_OWNERSHIP,
  AUTO_WORK_USAGE,
  CANCEL_EDITING_WARNING,
  ENTER_MANUALLY_MESSAGE,
  SEARCH_AUTOMATICALLY_MESSAGE,
  EDIT_AUTO_SEARCH_ENTITY
};

export default autoForm;
