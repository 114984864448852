import { useMutation } from '@tanstack/react-query';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import api from '../../../services/api';

interface NonRenewPolicyRequest {
  person_gid: string;
  policy_id: number;

  cancellation_reason: string;
}

const nonRenewPolicy = (data: NonRenewPolicyRequest): Promise<{ policy: IMaticPolicy }> =>
  api.put(`/api/frontend/people/${data.person_gid}/policies/${data.policy_id}/non_renew`, { body: data });

const useNonRenewPolicy = () => useMutation({ mutationFn: nonRenewPolicy });

export default useNonRenewPolicy;
