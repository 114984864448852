import { skipToken, useQuery } from '@tanstack/react-query';

import { IMaticPolicy } from '../../interfaces/IPolicy';
import api from '../../services/api';
import { PolicyTransaction } from './usePolicyTransactions';

export interface TransactionsGroup {
  policy: IMaticPolicy;
  transactions: PolicyTransaction[];
}

const usePolicyTransactionsGroups = (policyId?: string | number) =>
  useQuery({
    queryKey: ['transactions_groups', policyId],
    queryFn: policyId
      ? (): Promise<{ transactions_groups: TransactionsGroup[] }> =>
          api.get(`/api/frontend/policies/${policyId}/transactions_groups`)
      : skipToken,
    select: data => data.transactions_groups
  });

export default usePolicyTransactionsGroups;
