/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../../components/core/Container';
import { InfoIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const ObieCarrierInstructions = () => {
  return (
    <Container
      p={spacings.px12}
      backgroundColor={colors.violetBackground}
      roundBorder
      customCss={css`
        width: fit-content;
      `}
    >
      <Paragraph>
        <InfoIcon
          width={24}
          height={24}
          color={colors.violet}
          css={css`
            margin-right: ${spacings.px8}px;
          `}
        />
        <Text bold>Quick reminder:</Text> Obie policies require the customer to complete the application and payment on
        their own.
      </Paragraph>
      <Paragraph mt={spacings.px8}>
        <Text bold>Please stay on the phone with your customer</Text> to help them navigate to their email to complete
        the binding process. (More info here:{' '}
        <a
          href="https://maticinsurance.atlassian.net/wiki/spaces/MI/pages/3982262383/Obie"
          target="_blank"
          rel="noreferrer"
        >
          Obie Guide
        </a>
        )
      </Paragraph>
    </Container>
  );
};

export default ObieCarrierInstructions;
