import Text, { TextProps } from '../../../../../components/core/Text';
import { INFO_NOT_PRESENT } from '../../../../../constants';
import { IQuote } from '../../../../../interfaces';
import { coverageByKey, PolicyCoverageKey } from '../../../../../interfaces/IPolicyCoverage';
import {
  isInsurableInterestRealProperty,
  isInsurableInterestVehicle,
  PolicyType
} from '../../../../../interfaces/IPolicyType';
import colors from '../../../../../theme/colors';
import { moneyFormatter } from '../../../../../utils/formatter';

const Deductible = ({
  quote: {
    attributes: { policy_type, coverages }
  },
  type = 'default',
  color = colors.black
}: {
  quote: IQuote;
  type?: TextProps['type'];
  color?: string;
}) => {
  if (isInsurableInterestRealProperty(policy_type)) {
    const dwellingCoverage = coverageByKey(coverages, PolicyCoverageKey.Dwelling);

    return (
      <Text type={type} color={color}>
        {dwellingCoverage?.deductible_description || INFO_NOT_PRESENT}
      </Text>
    );
  }

  if (isInsurableInterestVehicle(policy_type)) {
    const biPerPerson = coverageByKey(coverages, PolicyCoverageKey.BiPerPerson);
    const piPerAccident = coverageByKey(coverages, PolicyCoverageKey.BiPerAccident);

    const biPerPersonLabel = biPerPerson?.limit_value
      ? moneyFormatter(biPerPerson?.limit_value, true, 'compact')
      : INFO_NOT_PRESENT;
    const piPerAccidentLabel = biPerPerson?.limit_value
      ? moneyFormatter(piPerAccident?.limit_value, true, 'compact')
      : INFO_NOT_PRESENT;

    return <Text type={type} color={color}>{`${biPerPersonLabel} / ${piPerAccidentLabel}`}</Text>;
  }

  if (policy_type === PolicyType.PAF) {
    const jewelryCoverage = coverageByKey(coverages, PolicyCoverageKey.Jewelry);

    return (
      <Text type={type} color={color}>
        {jewelryCoverage?.deductible_description || INFO_NOT_PRESENT}
      </Text>
    );
  }

  return null;
};

export default Deductible;
