import { create } from 'zustand';

import { PartialStep } from './useDataCollectionStepper';

interface IDataCollectionStepsState {
  steps: PartialStep[] | undefined;
  lastActiveStep: Record<string, PartialStep | undefined>;
  setSteps: (steps: PartialStep[] | undefined) => void;
  setLastActiveStepById: (id: number, step: PartialStep) => void;
}

const useDataCollectionStepsState = create<IDataCollectionStepsState>()(set => ({
  steps: undefined,
  lastActiveStep: {},
  setSteps: steps => set({ steps }),
  setLastActiveStepById: (id, step) => set({ lastActiveStep: { [id]: step } })
}));

export default useDataCollectionStepsState;
