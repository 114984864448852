/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FieldArray, useFormikContext } from 'formik';
import * as React from 'react';
import { Draggable, DroppableProvided } from 'react-beautiful-dnd';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';

import { Link } from '../../components/common';
import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import { InputField } from '../../components/core/forms/fields';
import Heading from '../../components/core/Heading';
import Paragraph from '../../components/core/Paragraph';
import SwitchToggle from '../../components/core/SwitchToggle';
import Text from '../../components/core/Text';
import { useToggle } from '../../hooks';
import { ScoutingStrategy } from '../../queries/scouting_strategies/useScoutingStrategy';
import colors from '../../theme/colors';
import { heading4Css } from '../../theme/typography';
import { spacings } from '../../theme/variables';
import parseHtmlString from '../../utils/htmlStringParser';
import { formatConditionGroups, transformString } from './_utils';
import ConditionGroupsModal from './ConditionGroupsModal';

const StrategyConfigEditor = ({
  configKey,
  droppableProvided: provided
}: {
  configKey: string | undefined;
  droppableProvided: DroppableProvided;
}) => {
  const navigate = useNavigate();

  const [configureStepConditions, setConfigureStepConditions] = React.useState<ScoutingStrategy['steps'][0]>();
  const [conditionsModalOpened, toggleConditionsModal] = useToggle(false);
  const [stepControlsEnabled, toggleStepControlsEnabled] = useToggle(true);

  const { initialValues, values, isSubmitting, dirty } = useFormikContext<ScoutingStrategy>();

  return (
    <ErrorBoundary fallback={<Paragraph>Error appeared while trying to display your config</Paragraph>}>
      <>
        {conditionsModalOpened && (
          <ConditionGroupsModal
            values={{ condition_groups: values.condition_groups || [] }}
            toggleConditionsModal={toggleConditionsModal}
            fieldPrefix="condition_groups"
          />
        )}
        <FieldArray
          name="scouting_strategy_steps"
          render={arrayHelpers => {
            return (
              <>
                <FlexBox justifySpaceBetween mb={spacings.px12}>
                  <Heading>Config editor</Heading>
                  <FlexBox gap={spacings.px8}>
                    <Button
                      size={ButtonSize.Small}
                      variant={ButtonVariant.Secondary}
                      disabled={!configKey || dirty}
                      onClick={() => navigate(`/scouting_report_scripts/${configKey}/preview`)}
                    >
                      Preview
                    </Button>
                    <Button
                      size={ButtonSize.Small}
                      variant={ButtonVariant.Secondary}
                      onClick={() => toggleConditionsModal()}
                    >
                      Configure conditions
                    </Button>
                    <Button size={ButtonSize.Small} type="submit" loading={isSubmitting}>
                      Save config
                    </Button>
                  </FlexBox>
                </FlexBox>
                <code
                  css={css`
                    white-space: break-spaces;
                  `}
                >
                  {formatConditionGroups(values.condition_groups || [])}
                </code>
                <FlexBox gap={spacings.px32} mt={spacings.px12} alignItemsCenter justifySpaceBetween>
                  <FlexBox gap={spacings.px32}>
                    <InputField label="Key" id="key" name="key" required disabled={!!initialValues.key} />
                    <InputField label="Name" id="name" name="name" required />
                  </FlexBox>
                  <FlexBox alignItemsCenter>
                    <SwitchToggle
                      checked={stepControlsEnabled}
                      disabled={false}
                      onChange={() => toggleStepControlsEnabled()}
                    />
                    <Text>Show steps controls</Text>
                  </FlexBox>
                </FlexBox>
                <Container {...provided.droppableProps} innerRef={provided.innerRef} mb={spacings.px24}>
                  <div>
                    {values.scouting_strategy_steps.map((s, index) => (
                      <Draggable key={s.id} index={index} draggableId={`${s.id}`} isDragDisabled={!stepControlsEnabled}>
                        {provided => (
                          <FlexBox
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                            gap={spacings.px24}
                            customCss={css`
                              &:hover {
                                ${stepControlsEnabled && `outline: 1px dashed ${colors.azure50}; outline-offset: 2px;`}
                              }
                              ${index > 0 && s.title && 'margin-top: 40px;'}
                            `}
                          >
                            <FlexBox
                              columnDirection
                              customCss={css`
                                flex-basis: 75%;
                              `}
                            >
                              <FlexBox justifySpaceBetween>
                                <Heading type="h4">{s.title}</Heading>
                                {stepControlsEnabled && (
                                  <FlexBox alignItemsCenter gap={spacings.px8}>
                                    <Link to={`/scouting_steps/${s.id}`}>{s.name || 'Step editor'}</Link>
                                    <IconButton
                                      icon={ButtonIcons.Delete}
                                      onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        arrayHelpers.remove(index);
                                      }}
                                    />
                                  </FlexBox>
                                )}
                              </FlexBox>
                              <div
                                css={css`
                                  ${heading4Css};
                                  font-weight: normal;
                                `}
                              >
                                {parseHtmlString(transformString(s.description))}
                              </div>
                            </FlexBox>
                            {stepControlsEnabled && (
                              <FlexBox
                                columnDirection
                                gap={spacings.px12}
                                customCss={css`
                                  flex-basis: 25%;
                                `}
                              >
                                <Button
                                  size={ButtonSize.Small}
                                  variant={ButtonVariant.Secondary}
                                  onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setConfigureStepConditions(s);
                                  }}
                                >
                                  Configure step conditions
                                </Button>
                                {configureStepConditions?.id === s.id && (
                                  <ConditionGroupsModal
                                    values={s}
                                    fieldPrefix={`scouting_strategy_steps.${index}.condition_groups`}
                                    toggleConditionsModal={() => setConfigureStepConditions(undefined)}
                                  />
                                )}
                                <code
                                  css={css`
                                    white-space: break-spaces;
                                  `}
                                >
                                  {formatConditionGroups(s.condition_groups)}
                                </code>
                              </FlexBox>
                            )}
                          </FlexBox>
                        )}
                      </Draggable>
                    ))}
                  </div>
                  {provided.placeholder}
                </Container>
              </>
            );
          }}
        />
      </>
    </ErrorBoundary>
  );
};

export default StrategyConfigEditor;
