import { useFormikContext } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import FormModal from '../components/core/FormModal';
import { NumericField } from '../components/core/forms/fields';
import { DollarIcon } from '../components/core/icons';
import { requiredField } from '../utils/yupRules';

const TOP_PREMIUM_AMOUNT = 50_000;

interface IParams {
  premiumFieldName?: string;
  isOptional?: boolean;
}

const validations = (isOptional: IParams['isOptional']) => {
  if (isOptional) {
    return undefined;
  }

  return yup.object().shape({
    premium_confirmation: requiredField
  });
};

const usePremiumConfirmation = ({ premiumFieldName, isOptional }: IParams) => {
  const name = premiumFieldName || 'premium';
  const { setFieldValue } = useFormikContext();
  const [showPremiumConfirmation, setShowPremiumConfirmation] = React.useState(false);
  const confirmedPremiumRef = React.useRef<number | string>(0);

  const ConfirmationModal = React.useCallback(() => {
    return showPremiumConfirmation ? (
      <FormModal
        confirmText="Confirm"
        cancelText="It's mistake"
        title="You entered premium that exceeds $50,000"
        initialValues={{ premium_confirmation: '' }}
        validationSchema={validations(isOptional)}
        confirmHandler={({ premium_confirmation }) => {
          confirmedPremiumRef.current = premium_confirmation;
          setFieldValue(name, premium_confirmation, true);
          setShowPremiumConfirmation(false);
        }}
        cancelHandler={() => {
          confirmedPremiumRef.current = 0;
          setFieldValue(name, 0, true);
          setShowPremiumConfirmation(false);
        }}
        renderForm={() => (
          <NumericField
            label="Confirm premium"
            required={!isOptional}
            id="premium_confirmation"
            name="premium_confirmation"
            valueIsNumber
            prefixIcon={<DollarIcon />}
          />
        )}
      />
    ) : null;
  }, [name, setFieldValue, showPremiumConfirmation, isOptional]);

  const validatePremiumCallback = React.useCallback((value: number) => {
    if (value >= TOP_PREMIUM_AMOUNT && value !== confirmedPremiumRef.current) {
      setShowPremiumConfirmation(true);

      return 'Please confirm premium amount';
    }
  }, []);

  return { ConfirmationModal, validatePremiumCallback, confirmedPremiumRef };
};

export default usePremiumConfirmation;
