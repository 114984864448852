import { skipToken, useMutation, useQuery } from '@tanstack/react-query';

import { IProposal } from '../../../interfaces/IProposal';
import api from '../../../services/api';

export const PERSON_PROPOSAL_QUERY_KEY = 'person-proposal';
export const PERSON_PROPOSALS_QUERY_KEY = 'person-proposals';

interface ProposalPayload {
  personGid: string;
  quoteGids: string[];
  engagementGid: string;
}

interface ProposalUpdatePayload {
  personGid: string;
  quoteGids: string[];
  proposalOptionGid: string;
  proposalGid: string;
}

interface TrackProposalSentPayload {
  personGid: string;
  proposalGid: string;
}

const getProposal = ({ personGid, proposalGid }: { personGid: string; proposalGid: string }) =>
  api.get(`/api/frontend/people/${personGid}/proposals/${proposalGid}`);

export const useProposal = ({ personGid, proposalGid }: { personGid?: string; proposalGid: string }) =>
  useQuery({
    queryKey: [PERSON_PROPOSAL_QUERY_KEY, personGid, proposalGid],
    queryFn: personGid ? () => getProposal({ personGid, proposalGid }) : skipToken
  });

const getPersonProposals = ({ personGid, engagementGid }: { personGid: string; engagementGid: string }) =>
  api.get(`/api/frontend/people/${personGid}/proposals?engagement_gid=${engagementGid}`);

export const usePersonProposals = ({
  personGid,
  engagementGid
}: {
  personGid: string | undefined;
  engagementGid: string | undefined;
}) =>
  useQuery({
    queryKey: [PERSON_PROPOSALS_QUERY_KEY, personGid, engagementGid],
    queryFn:
      personGid && engagementGid
        ? (): Promise<{ proposals: IProposal[] }> => getPersonProposals({ personGid, engagementGid })
        : skipToken,
    select: data => data.proposals
  });

const createProposal = ({ personGid, quoteGids, engagementGid }: ProposalPayload): Promise<{ proposal: IProposal }> =>
  api.post(`/api/frontend/people/${personGid}/proposals`, {
    body: { quotes_gids: quoteGids, engagement_gid: engagementGid }
  });

export const useCreateProposal = () => useMutation({ mutationFn: createProposal });

const updateProposal = ({
  personGid,
  quoteGids,
  proposalOptionGid,
  proposalGid
}: ProposalUpdatePayload): Promise<{ proposal: IProposal }> =>
  api.put(`/api/frontend/people/${personGid}/proposals/${proposalGid}`, {
    body: { proposal_option_gid: proposalOptionGid, quotes_gids: quoteGids }
  });

export const useUpdateProposal = () => useMutation({ mutationFn: updateProposal });

const trackProposalSent = ({ personGid, proposalGid }: TrackProposalSentPayload) =>
  api.put(`/api/frontend/people/${personGid}/proposals/${proposalGid}/track_sent`);

export const useTrackProposalSent = () => useMutation({ mutationFn: trackProposalSent });
