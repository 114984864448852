import React from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import { isEndDisposition } from '../../../components/DispositionsModals/dispositionsHelper';
import useQueryParams from '../../../hooks/useQueryParams';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import { isAgent } from '../../../services/authInfo';
import GetQuotes from '../../GetQuotes';
import useQuotingAssetsData from './useQuotingAssetsData';

const LeadGetQuotes = () => {
  const { personGid } = useParams();
  const leadId = useQueryParams().get('lead_id') || undefined;

  const quotingAssetsData = useQuotingAssetsData({ personGid, leadId });
  const { data, isSuccess } = useLeadDispositions(leadId);

  const isTerminalDisposition = isEndDisposition(data?.current_disposition.disposition_type);
  const forbidQuoting = isAgent() && isTerminalDisposition;

  const navigate = useNavigate();

  const onClose = () => navigate(`/leads/${leadId}/quotes`, { replace: true });

  if (!isSuccess) {
    return null;
  }

  if (forbidQuoting) {
    return <Navigate to={`/leads/${leadId}/quotes`} replace={true} />;
  }

  return (
    <GetQuotes
      {...quotingAssetsData}
      leadId={leadId}
      onClose={() => onClose()}
      onQuotingStarted={() => onClose()}
      selectAllAssets={false}
    />
  );
};

export default LeadGetQuotes;
