import axios from 'axios';
import React from 'react';

const API_ACTIVITY_ID = 'api-requests-monitor';

class ApiActivityMonitor extends React.Component<any, any> {
  state = { activeRequests: 0 };
  requestInterceptorId: any;
  responseInterceptorId: any;

  requestStarted = (config: any) => {
    this.setState(({ activeRequests }: any) => ({ activeRequests: activeRequests + 1 }));
    return config;
  };

  requestFinishedSuccess = (response: any) => {
    this.setState(({ activeRequests }: any) => ({ activeRequests: activeRequests - 1 }));
    return response;
  };

  requestFinishedError = (error: any) => {
    this.setState(({ activeRequests }: any) => ({ activeRequests: activeRequests - 1 }));
    return Promise.reject(error);
  };

  apiActivityClass = (activeRequestsCount: any) => {
    return activeRequestsCount > 0 ? 'qa-api-request-active' : 'qa-api-request-inactive';
  };

  componentDidMount() {
    this.requestInterceptorId = axios.interceptors.request.use(this.requestStarted, error => {
      return Promise.reject(error);
    });

    this.responseInterceptorId = axios.interceptors.response.use(
      this.requestFinishedSuccess,
      this.requestFinishedError
    );
  }

  componentWillUnmount() {
    axios.interceptors.request.eject(this.requestInterceptorId);
    axios.interceptors.response.eject(this.responseInterceptorId);
  }

  render() {
    return (
      <span
        data-testid={API_ACTIVITY_ID}
        id={API_ACTIVITY_ID}
        className={this.apiActivityClass(this.state.activeRequests)}
      />
    );
  }
}

export default ApiActivityMonitor;
