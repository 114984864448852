/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Text from '../../../components/core/Text';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import useHomePolicyCoinsureds from '../../../queries/policies/useHomePolicyCoinsureds';
import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import colors from '../../../theme/colors';
import BasicInfoSection from '../BasicInfoSection';
import ContentSection from '../ContentSection';

const UmbrellaLineOfBusiness = ({
  policy,
  lineOfBusiness,
  lastTransaction
}: {
  policy: IMaticPolicy;
  lineOfBusiness: LineOfBusiness;
  lastTransaction: PolicyTransaction;
}): JSX.Element => {
  const { data: coinsureds } = useHomePolicyCoinsureds(lastTransaction.policy_id, lastTransaction.effective_date);

  return (
    <>
      <BasicInfoSection policy={policy} lineOfBusiness={lineOfBusiness} insureds={coinsureds} />
      <ContentSection heading="Basic coverages">
        <div
          css={css`
            margin-top: 20px;
          `}
        >
          <Text type="large" color={colors.grey60}>
            Coverages for umbrella are not supported yet
          </Text>
        </div>
      </ContentSection>
    </>
  );
};

export default UmbrellaLineOfBusiness;
