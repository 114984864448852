/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import colors from '../../theme/colors';
import {
  defaultFontValues,
  highlightedCss,
  largeParagraphCss,
  paragraphCss,
  singleLineCss,
  smallParagraphCss,
  tinyParagraphCss
} from '../../theme/typography';
import { spacingKeys, Spacings, spacings, spacingsCss } from '../../theme/variables';

export interface TextProps extends React.HTMLProps<HTMLSpanElement>, Spacings {
  type?: 'large' | 'default' | 'small' | 'tiny';
  customCss?: SerializedStyles;
  italic?: boolean;
  bold?: boolean;
  highlighted?: boolean;
  lineThrough?: boolean;
  uppercase?: boolean;
  underline?: boolean;
  dotted?: boolean;
  color?: (typeof colors)[keyof typeof colors];
  block?: boolean;
  singleLine?: boolean;
}

const appliedCss = {
  default: paragraphCss,
  large: largeParagraphCss,
  small: smallParagraphCss,
  tiny: tinyParagraphCss
};

const Text = ({
  italic,
  bold,
  highlighted,
  customCss,
  type = 'default',
  color,
  lineThrough,
  uppercase,
  underline,
  dotted,
  children,
  block,
  singleLine,
  ...rest
}: TextProps): JSX.Element => {
  const spacingProps = pick(spacingKeys, rest);
  const restOfProps = omit(spacingKeys, rest);
  const dottedCss = dotted ? 'dotted' : '';
  const underlineCss = css`
    ${underline ? `text-decoration: underline ${dottedCss}; text-underline-offset: ${spacings.px6}px;` : ''}
  `;

  return (
    <span
      {...restOfProps}
      css={css`
        ${spacingsCss(spacingProps)};
        ${appliedCss[type]};
        font-weight: ${bold ? defaultFontValues.boldFontWeight : defaultFontValues.fontWeight};
        font-style: ${italic ? 'italic' : defaultFontValues.fontStyle};
        ${color ? `color: ${color};` : ''}
        ${customCss};
        ${highlighted ? highlightedCss : ''};
        ${lineThrough ? 'text-decoration: line-through' : ''};
        ${uppercase ? 'text-transform: uppercase' : ''};
        ${block && 'display: block'};
        ${singleLine && singleLineCss}
        ${underlineCss}
      `}
    >
      {children}
    </span>
  );
};

export default Text;
