/** @jsxImportSource @emotion/react */
import './Sortable.scss';

import { css, Global } from '@emotion/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { ASC, DESC, sortByDate, sortByNumber, sortByString } from '../utils/sorting';

const DATE = 'ORDER_DATE';
const NUMBER = 'ORDER_NUMBER';
const STRING = 'ORDER_STRING';

import arrowUpAndDownIcon from '../assets/img/icons/arrow-up-and-down.svg';
import arrowDownIcon from '../assets/new_icons/arrow-down.svg';
import arrowUpIcon from '../assets/new_icons/arrow-up.svg';

export const ORDER_TYPES = {
  DATE,
  NUMBER,
  STRING
};

class Sortable extends React.Component<any, any> {
  state = {
    column: this.props.defaultColumn,
    order: this.props.defaultOrder
  };

  mockHandler = () => {};

  sortBy = (column: any) => () => {
    const { column: currentColumn, order: currentOrder } = this.state;

    this.setState({
      column,
      order: currentColumn === column && currentOrder === DESC ? ASC : DESC
    });
  };

  getSortableClasses = (column: any) => {
    const isSorted = column === this.state.column;
    const isDesc = this.state.order === DESC;

    return classNames(
      'sortable',
      { 'active-sorting-desc': isSorted && isDesc },
      { 'active-sorting-asc': isSorted && !isDesc }
    );
  };

  sortList = (list: any) => {
    const { column, order } = this.state;
    const sort = this.props.sortableColumns[column];
    let sorted = list;

    if (sort instanceof Function) {
      return sort(list, order);
    }

    switch (sort) {
      case DATE:
        sorted = sortByDate(list, column, order);
        break;
      case NUMBER:
        sorted = sortByNumber(list, column, order);
        break;
      case STRING:
        sorted = sortByString(list, column, order);
        break;
      default:
        break;
    }

    return sorted;
  };

  render() {
    const isSortable = Boolean(Object.keys(this.props.sortableColumns).length);

    return (
      <div className={this.props.className}>
        <Global
          styles={() => css`
            .sortable.active-sorting-desc::after {
              mask-image: url(${arrowDownIcon});
            }
            .sortable.active-sorting-asc::after {
              mask-image: url(${arrowUpIcon});
            }
            .sortable:after {
              content: url(${arrowUpAndDownIcon});
            }
          `}
        />
        {this.props.render({
          list: this.sortList(this.props.list),
          sortBy: isSortable ? this.sortBy : this.mockHandler,
          getSortableClasses: isSortable ? this.getSortableClasses : this.mockHandler
        })}
      </div>
    );
  }
}

// @ts-expect-error old code
Sortable.propTypes = {
  className: PropTypes.string,
  defaultColumn: PropTypes.string,
  defaultOrder: PropTypes.number,
  sortableColumns: (
    props: { [x: string]: { [s: string]: unknown } | ArrayLike<unknown> },
    propName: string | number
  ) =>
    Object.values(props[propName]!).every(
      type => [DATE, NUMBER, STRING].includes(type as any) || type instanceof Function
    )
      ? null
      : new Error('Should contain valid ordering types'),
  list: PropTypes.array,
  render: PropTypes.func.isRequired
};

// @ts-expect-error old code
Sortable.defaultProps = {
  className: '',
  defaultColumn: null,
  defaultOrder: DESC,
  sortableColumns: {},
  list: []
};

export default Sortable;
