import React from 'react';

import { Pagination } from '../../components/common';
import FiltersSubHeader from '../../components/common/FiltersSubHeader/FiltersSubHeader';
import Container from '../../components/core/Container';
import TablePlaceholder from '../../components/TablePlaceholder';
import { SORT_ORDER_DESC, Translations } from '../../constants';
import { useDocumentTitle } from '../../hooks';
import { ILeadMode } from '../../interfaces/IDashboardLead';
import { PrimaryOpportunities } from '../../interfaces/ISourceDimensions';
import { LicensingTypes, UserRoleCategory } from '../../interfaces/IUser';
import useLeads, {
  LeadsDashboardFilters as ILeadsDashboardFilters,
  LeadsDashboardResponse,
  LeadsDashboardSorting
} from '../../queries/leads/useLeads';
import useUpdateLeadStarred from '../../queries/leads/useUpdateLeadStarred';
import authInfo from '../../services/authInfo';
import { spacings } from '../../theme/variables';
import DashboardLeadsList from './DashboardLeadsList';
import LeadsDashboardFilters from './LeadsDashboardFilters';

const LEADS_PER_PAGE = 25;
const LEADS_FILTERS_STORAGE_KEY = 'leadsFilters';
const DEFAULT_SORTING = { sort_column: 'lead_id', sort_order: SORT_ORDER_DESC };

const defaultFilters = () => ({ lead_mode: [ILeadMode.RegularLead] });

const restoreFilters = (): Record<string, string | string[]> => {
  let cachedFilters = JSON.parse(localStorage.getItem(LEADS_FILTERS_STORAGE_KEY) || '{}') || ({} as any);
  if (cachedFilters.lead_mode === undefined) {
    cachedFilters = { ...cachedFilters, ...defaultFilters() };
  }

  return cachedFilters;
};

const currentlyDisplayedLeadsMessage = (leadsDashboard: LeadsDashboardResponse | undefined, currentPage: number) => {
  const { leads, count } = leadsDashboard || {};
  const start = leads?.length ? (currentPage - 1) * LEADS_PER_PAGE + 1 : 0;
  const end = leads?.length ? (currentPage - 1) * LEADS_PER_PAGE + leads.length : 0;

  return `Displaying leads ${start} - ${end} of ${count} in total`;
};

const getPrimaryOpportunityFilterKeysWithoutLife = () =>
  Translations.primaryOpportunityTypeOptions
    .filter(option => option.key !== PrimaryOpportunities.Life)
    .map(option => option.key);

const LeadsDashboard = () => {
  const [page, setPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<LeadsDashboardSorting>(DEFAULT_SORTING);
  const [filters, setFilters] = React.useState<ILeadsDashboardFilters>(() => restoreFilters());

  const lifeOpportunityFilterIsAvailable = Boolean(
    authInfo.roleCategory === UserRoleCategory.Admin || authInfo.licensingTypes?.includes(LicensingTypes.Life)
  );

  const adjustedFilters = (() => {
    if (lifeOpportunityFilterIsAvailable) {
      return filters;
    }

    if (filters['primary_opportunities'] && filters['primary_opportunities'].length > 0) {
      const filterWithoutLife = filters['primary_opportunities'].filter(option => option !== PrimaryOpportunities.Life);

      const nonEmptyFilter =
        filterWithoutLife.length > 0 ? filterWithoutLife : getPrimaryOpportunityFilterKeysWithoutLife();

      return {
        ...filters,
        primary_opportunities: nonEmptyFilter
      };
    } else {
      return {
        ...filters,
        primary_opportunities: getPrimaryOpportunityFilterKeysWithoutLife()
      };
    }
  })();

  useDocumentTitle('Sales dashboard');

  const {
    data: leadsDashboard,
    isLoading,
    refetch: refetchDashboard
  } = useLeads({
    page,
    ...sorting,
    ...adjustedFilters
  });
  const { mutate: updateLeadStarred } = useUpdateLeadStarred(() => refetchDashboard());

  return (
    <Container fitParentWidth>
      <FiltersSubHeader headerText="Sales Dashboard" salesDashboardHeader>
        <LeadsDashboardFilters
          filters={filters}
          onFilterReset={() => {
            setFilters(defaultFilters());
            localStorage.setItem(LEADS_FILTERS_STORAGE_KEY, JSON.stringify(defaultFilters()));
          }}
          onFilterSubmit={filters => {
            setPage(1);
            setFilters(filters);
            localStorage.setItem(LEADS_FILTERS_STORAGE_KEY, JSON.stringify(filters));
          }}
          message={currentlyDisplayedLeadsMessage(leadsDashboard, page)}
        />
      </FiltersSubHeader>
      <Container fitParentWidth mt={spacings.px8} ph={spacings.px8}>
        {isLoading ? (
          <TablePlaceholder testId="sales-dashboard-loader" />
        ) : (
          <>
            <DashboardLeadsList
              leads={leadsDashboard?.leads}
              defaultSorting={sorting}
              onSortingChange={(sorting: LeadsDashboardSorting) => setSorting(sorting)}
              onStarredChange={(leadId: number, starred: boolean) => updateLeadStarred({ leadId, starred })}
            />
            <Pagination
              current={page - 1}
              pages={leadsDashboard?.pages}
              onPageChange={({ selected }: { selected: number }) => setPage(selected + 1)}
            />
          </>
        )}
      </Container>
    </Container>
  );
};

export default LeadsDashboard;
