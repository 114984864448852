import { skipToken, useQuery } from '@tanstack/react-query';

import { ICallNote } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const useCallNotes = (personGid?: string, scheduled_interaction_created_at?: string) =>
  useQuery({
    queryKey: ['notes', personGid, scheduled_interaction_created_at],
    queryFn: personGid
      ? (): Promise<{ notes: ICallNote[] }> =>
          api.get(
            `/api/frontend/people/${personGid}/scheduled_interactions/call_notes${
              scheduled_interaction_created_at ? `?${toQueryParams({ scheduled_interaction_created_at })}` : ''
            }`
          )
      : skipToken,
    select: data => data.notes
  });

export default useCallNotes;
