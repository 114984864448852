const LINE1 = 'line1';
const LINE2 = 'line2';
const CITY = 'city';
const STATE = 'state';
const ZIP = 'zip';
const ID = 'id';
const GARAGING_ADDRESS = 'address';
const GARAGING_ADDRESS_ATTRIBUTES = 'address_attributes';
const ADDRESS = 'address';

const ADDRESS_LINE1 = 'address_line1';
const ADDRESS_LINE2 = 'address_line2';
const ADDRESS_CITY = 'address_city';
const ADDRESS_STATE = 'address_state';
const ADDRESS_ZIP = 'address_zip';

const SMARTY_LINE1 = 'street_line';
const SMARTY_LINE2 = 'secondary';
const SMARTY_ZIP = 'zipcode';

const SMARTY_STREETS_KEY = '10745329668025033';
const SMARTY_STREETS_AUTOCOMPLETE_API = 'https://us-autocomplete-pro.api.smartystreets.com/lookup';
const API_TO_SMARTY_STREETS_MAPPING = {
  [LINE1]: SMARTY_LINE1,
  [LINE2]: SMARTY_LINE2,
  [CITY]: CITY,
  [STATE]: STATE,
  [ZIP]: SMARTY_ZIP
};
export {
  ADDRESS,
  ADDRESS_CITY,
  ADDRESS_LINE1,
  ADDRESS_LINE2,
  ADDRESS_STATE,
  ADDRESS_ZIP,
  API_TO_SMARTY_STREETS_MAPPING,
  CITY,
  GARAGING_ADDRESS,
  GARAGING_ADDRESS_ATTRIBUTES,
  ID,
  LINE1,
  LINE2,
  SMARTY_LINE1,
  SMARTY_LINE2,
  SMARTY_STREETS_AUTOCOMPLETE_API,
  SMARTY_STREETS_KEY,
  SMARTY_ZIP,
  STATE,
  ZIP
};
