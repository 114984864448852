import { useMutation } from '@tanstack/react-query';

import api from '../../../services/api';

interface UpdateLeadTypeParams {
  leadId: number;
  leadType: string;
}

export const updateLeadType = (params: UpdateLeadTypeParams): Promise<null> =>
  api.put(`/api/frontend/leads/${params.leadId}/lead_type`, {
    body: { lead_type: params.leadType }
  });

const useUpdateLeadType = (onSuccess: (data: null, variables: UpdateLeadTypeParams) => void) =>
  useMutation({ mutationFn: updateLeadType, onSuccess });

export default useUpdateLeadType;
