import { useQuery } from '@tanstack/react-query';

import { ITask } from '../../../interfaces/ITask';
import api from '../../../services/api';

export const PERSON_TASKS_QUERY_KEY = 'person_tasks';

const getPersonTasks = (personGid: string | undefined): Promise<{ tasks: ITask[] }> =>
  api.get(`/api/frontend/people/${personGid}/tasks`);

const usePersonTasks = (personGid: string | undefined) =>
  useQuery({
    queryKey: [PERSON_TASKS_QUERY_KEY, personGid],
    queryFn: () => getPersonTasks(personGid),
    enabled: !!personGid,
    select: data => data.tasks
  });

export default usePersonTasks;
