import { useMutation } from '@tanstack/react-query';

import api from '../../../services/api';

interface UpdateTestModeParams {
  leadId: number;
  testMode: boolean;
}

export const updateTestMode = (params: UpdateTestModeParams): Promise<null> =>
  api.put(`/api/frontend/leads/${params.leadId}/test_mode`, {
    body: { test_mode: params.testMode }
  });

const useUpdateTestMode = (onSuccess: (data: null, variables: UpdateTestModeParams) => void) =>
  useMutation({ mutationFn: updateTestMode, onSuccess });

export default useUpdateTestMode;
