import { IUser } from './IUser';
import { IUserRole } from './IUserRole';

export enum DispositionType {
  SelfProcessed = 'self_processed',
  NewRequest = 'new_request',
  ContactAttempt = 'contact_attempt',
  ContactMade = 'contact_made',
  CouldNotContact = 'could_not_contact',
  NotQualified = 'not_qualified',
  Qualified = 'qualified',
  Pipeline = 'pipeline',
  PolicySold = 'policy_sold',
  Lost = 'lost'
}

export enum DispositionSource {
  Agent = 'agent',
  OLB = 'olb',
  Telephony = 'telephony',
  API = 'api',
  GlobalSearch = 'global_search',
  Unknown = 'unknown',
  AUTOMATIC_LEAD_CLOSER = 'automatic_lead_closer'
}

export interface IDisposition {
  disposition_type: DispositionType;
  disposition_reason: null | string;
  assignee: null | IUser;
  author: null | IUser;
  source: DispositionSource;
  label: string;
  assignee_prefix: string;
  next_action: string;
  is_license_required: boolean;
  created_at: string;
  updated_at: string;
  assignee_role: IUserRole | null;
  author_role: IUserRole | null;
}

export interface ICurrentDisposition extends IDisposition {
  is_assignee_licensed: boolean;
}

export interface ILeadDispositions {
  current_disposition: ICurrentDisposition;
  dispositions: IDisposition[];
}
