/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import LocalStorageManager from '../../../../services/LocalStorageManager';
import colors from '../../../../theme/colors';
import {
  getCurrentWidth,
  LOCAL_STORAGE_KEY,
  MAX_TAB_WIDTH,
  MIN_TAB_WIDTH,
  setReactTabsWidth,
  VERTICAL_TAB_HEADER_Z_INDEX
} from './helpers';

const VerticalTabResizer = () => {
  const startXRef = React.useRef(0);
  const savedWidthRef = React.useRef(getCurrentWidth());

  const handleMouseMove = React.useCallback((e: { clientX: number }) => {
    const deltaX = e.clientX - startXRef.current;

    const computedWidthWithLimits = Math.min(
      Math.max(savedWidthRef.current + deltaX * -1, MIN_TAB_WIDTH),
      MAX_TAB_WIDTH
    );

    setReactTabsWidth(`${computedWidthWithLimits}px`);
  }, []);

  const handleMouseUp = React.useCallback(() => {
    savedWidthRef.current = getCurrentWidth();
    LocalStorageManager.write(LOCAL_STORAGE_KEY, savedWidthRef.current);
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }, [handleMouseMove]);

  const handleMouseDown = React.useCallback(
    (e: { clientX: number }) => {
      startXRef.current = e.clientX;
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [handleMouseMove, handleMouseUp]
  );

  return (
    <div
      css={css`
        position: absolute;
        width: 3px;
        left: 0;
        height: 100%;
        cursor: col-resize;
        user-select: none;
        z-index: ${VERTICAL_TAB_HEADER_Z_INDEX + 2};
        transition: background-color 0.15s ease-out;

        &:active,
        &:hover {
          background-color: ${colors.azure50};
        }
      `}
      onMouseDown={handleMouseDown}
    />
  );
};

export default VerticalTabResizer;
