import featureFlags from '../../constants/featureFlags';
import { DispositionSource, DispositionType, ICurrentDisposition, IDisposition } from '../../interfaces/IDisposition';
import { IOpportunity, OpportunityStatus } from '../../interfaces/IOpportunity';
import { IMaticPolicy } from '../../interfaces/IPolicy';
import { UserRoleCategory } from '../../interfaces/IUser';
import useLeadDispositionConfig, {
  DispositionReasonOption,
  DispositionsConfig,
  DispositionTypeOption
} from '../../queries/leads/configuration/useLeadDispositionConfig';
import authInfo from '../../services/authInfo';
import { compact } from '../../utils/array';

// Checks --------------------------------------------------------------------------------------------------------------

const isOpportunityOpened = (opportunity: IOpportunity) =>
  opportunity.status !== OpportunityStatus.Lost && opportunity.status !== OpportunityStatus.Sold;

export const isDispositionSold = (dispositionType?: DispositionType) => dispositionType === DispositionType.PolicySold;

export const isDispositionLostOrSold = (dispositionType?: DispositionType) =>
  dispositionType === DispositionType.Lost || dispositionType === DispositionType.PolicySold;

export const isEndDisposition = (dispositionType?: DispositionType) =>
  dispositionType === DispositionType.Lost ||
  dispositionType === DispositionType.PolicySold ||
  dispositionType === DispositionType.NotQualified ||
  dispositionType === DispositionType.CouldNotContact;

export const isActiveLeadDisposition = (dispositionType?: DispositionType) =>
  dispositionType === DispositionType.ContactMade ||
  dispositionType === DispositionType.Qualified ||
  dispositionType === DispositionType.NewRequest ||
  dispositionType === DispositionType.ContactAttempt ||
  dispositionType === DispositionType.SelfProcessed;

export const isLeadDataEditingForbidden = (dispositionType: DispositionType | undefined) => {
  if (!dispositionType) {
    return true;
  }

  return (
    !authInfo.features.edit_closed_lead_data &&
    (dispositionType === DispositionType.Lost ||
      dispositionType === DispositionType.NotQualified ||
      dispositionType === DispositionType.CouldNotContact)
  );
};

export const isSidebarDataEditingEnabled = (dispositionType: DispositionType | undefined) => {
  return dispositionType === DispositionType.Pipeline || authInfo.features.edit_closed_lead_data;
};

export const updateRequiresConfirmation = (dispositionType: DispositionType, opportunities: IOpportunity[]) =>
  isEndDisposition(dispositionType) &&
  opportunities.some((opportunity: IOpportunity) => isOpportunityOpened(opportunity));

export const lostLeadConfirmation = (dispositionType: DispositionType, opportunities: IOpportunity[]) =>
  dispositionType === DispositionType.Lost &&
  opportunities.some((opportunity: IOpportunity) => opportunity.status === OpportunityStatus.Sold);

export const isUserDifferentFromAssignee = (assigneeId?: number | string) => authInfo.currentUserId !== assigneeId;

export const isLeadTransferred = (dispositions: IDisposition[]) =>
  dispositions.some(disposition => disposition.disposition_type === DispositionType.Pipeline);

export const isLeadContactMade = (dispositions: IDisposition[]) =>
  dispositions.some(disposition => disposition.disposition_type === DispositionType.ContactMade);

export const isServiceLead = (currentDisposition: IDisposition | undefined) =>
  currentDisposition?.assignee_role?.category === UserRoleCategory.CSR;

// Readers -------------------------------------------------------------------------------------------------------------

export const getDispositionTypesOptions = (
  dispositionConfig: DispositionsConfig | undefined,
  currentDispositionType: DispositionType
): DispositionTypeOption[] => {
  const dispositionTypes = dispositionConfig?.disposition_transitions?.[currentDispositionType] || [];
  const allowedKeys = compact([...dispositionTypes, currentDispositionType]);

  return (
    dispositionConfig?.disposition_types?.filter(({ key }: DispositionTypeOption) => allowedKeys.includes(key)) || []
  );
};

export const hasDispositionReason = (dispositionConfig: DispositionsConfig, dispositionType: DispositionType) =>
  dispositionConfig.dispositions_with_reason?.includes(dispositionType);

export const getDispositionReasonOptions = (
  dispositionConfig: DispositionsConfig,
  dispositionType: DispositionType
): DispositionReasonOption[] => {
  switch (dispositionType) {
    case DispositionType.NotQualified:
      return dispositionConfig.disposition_not_qualified_reasons;
    case DispositionType.CouldNotContact:
      return dispositionConfig.disposition_could_not_contact_reasons;
    default:
      return [];
  }
};

export const dispositionAssigneeLabel = (disposition: IDisposition): string => {
  if (disposition.source === DispositionSource.AUTOMATIC_LEAD_CLOSER) {
    return 'by system';
  }

  const assigneeName = authInfo.currentUserId === disposition?.assignee?.id ? 'you' : disposition.assignee?.name;

  return assigneeName ? `${disposition.assignee_prefix} ${assigneeName}` : '';
};

export const dispositionLabel = (disposition?: IDisposition): string => {
  if (
    featureFlags.cstGuidedSellingExperience &&
    isEndDisposition(disposition?.disposition_type) &&
    isServiceLead(disposition)
  ) {
    return 'Serviced';
  }

  if (disposition) {
    //TODO: make this change on backend?
    if (disposition.disposition_type === DispositionType.NotQualified) {
      return `Disqualified ${dispositionAssigneeLabel(disposition)}`;
    }
    return `${disposition.label} ${dispositionAssigneeLabel(disposition)}`;
  }
  return '';
};

// Hooks ---------------------------------------------------------------------------------------------------------------

export const useLeadDispositionsOptions = (
  leadId: number,
  currentDispositionType: DispositionType
): { isLoading: boolean; dispositionsOptions: { value: string; label: string }[] } => {
  const { data: dispositionsConfig, isLoading } = useLeadDispositionConfig(leadId);

  const dispositionsOptions = getDispositionTypesOptions(dispositionsConfig, currentDispositionType)
    .filter(option => option.key !== currentDispositionType)
    .map(option => {
      return { value: option.key, label: option.value.label };
    });

  return { isLoading, dispositionsOptions };
};

interface ValidationInput {
  currentDisposition: ICurrentDisposition;
  newDispositionType: DispositionType;
  opportunities: IOpportunity[] | undefined;
  policies: IMaticPolicy[] | undefined;
  openContactMadeModal: () => void;
  openCouldNotContactModal: () => void;
  openQualifiedModal: () => void;
  openNotQualifiedModal: () => void;
  openPipelineModal: () => void;
  openTrailingOpportunitiesModal?: (closingDisposition: DispositionType) => void;
  openCancelCallModal: () => void;
  onPolicyRequiredToCloseLead: () => void;
  onSuccess: (dispositionType: DispositionType) => void;
}

export const validateLeadDispositionChange = ({
  currentDisposition,
  newDispositionType,
  opportunities,
  policies,
  openContactMadeModal,
  openCouldNotContactModal,
  openQualifiedModal,
  openNotQualifiedModal,
  openPipelineModal,
  openTrailingOpportunitiesModal,
  openCancelCallModal,
  onPolicyRequiredToCloseLead,
  onSuccess
}: ValidationInput): void => {
  const currentAssigneeId = currentDisposition.assignee?.id;
  const isAssigneeLicensed = currentDisposition.is_assignee_licensed;

  if (newDispositionType === DispositionType.ContactMade && isUserDifferentFromAssignee(currentAssigneeId)) {
    openContactMadeModal();
  } else if (newDispositionType === DispositionType.CouldNotContact) {
    openCouldNotContactModal();
  } else if (newDispositionType === DispositionType.Qualified && isUserDifferentFromAssignee(currentAssigneeId)) {
    openQualifiedModal();
  } else if (newDispositionType === DispositionType.NotQualified) {
    openNotQualifiedModal();
  } else if (
    newDispositionType === DispositionType.Pipeline &&
    (isUserDifferentFromAssignee(currentAssigneeId) || !isAssigneeLicensed)
  ) {
    openPipelineModal();
  } else if (newDispositionType === DispositionType.Lost && opportunities?.length) {
    openTrailingOpportunitiesModal && openTrailingOpportunitiesModal(newDispositionType);
  } else if (newDispositionType === DispositionType.PolicySold && !policies?.length) {
    onPolicyRequiredToCloseLead();
  } else if (newDispositionType === DispositionType.PolicySold) {
    if (opportunities?.some(opportunity => isOpportunityOpened(opportunity))) {
      openTrailingOpportunitiesModal && openTrailingOpportunitiesModal(newDispositionType);
    } else {
      onSuccess(newDispositionType);
      openCancelCallModal();
    }
  } else {
    onSuccess(newDispositionType);
  }
};
