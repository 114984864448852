/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import { IQuote } from '../../../../../interfaces';
import { EligibilityResults, QuoteKind, QuoteStatus } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import PromotionTooltip from './PromotionTooltip';
import StatusTooltip from './StatusTooltip';

const DottedTag = ({ text, color, ...rest }: { text: string; color: string }) => {
  return (
    <FlexBox {...rest} alignItemsCenter gap={spacings.px4}>
      <Container
        customCss={css`
          width: 6px;
          height: 6px;
          border-radius: 3px;
          background-color: ${color};
        `}
      />
      <Text type="small" singleLine>
        {text}
      </Text>
    </FlexBox>
  );
};

const Status = ({ quote }: { quote: IQuote }) => {
  const {
    gid: quoteGid,
    kind,
    status,
    attributes: { eligibility, bundle_options }
  } = quote;

  if (status === QuoteStatus.DeclinedBusiness) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <DottedTag text="Declined business" color={colors.statusRed} data-for={`${quoteGid}-eligibility`} data-tip />
      </>
    );
  }

  if (status === QuoteStatus.InvalidData) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <DottedTag text="Data not valid" color={colors.statusOrange} data-for={`${quoteGid}-eligibility`} data-tip />
      </>
    );
  }

  if (eligibility?.result === EligibilityResults.Ineligible) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <DottedTag text="Ineligible" color={colors.statusRed} data-for={`${quoteGid}-eligibility`} data-tip />
      </>
    );
  }

  if (eligibility?.result === EligibilityResults.Uncertain || (bundle_options && !bundle_options.monoline_enabled)) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <DottedTag text="Partly eligible" color={colors.statusOrange} data-for={`${quoteGid}-eligibility`} data-tip />
      </>
    );
  }

  if (kind === QuoteKind.NonIntegrated || status === QuoteStatus.CompleteManually) {
    return (
      <>
        <StatusTooltip quote={quote} />
        <DottedTag text="Manual quote required" color={colors.azure50} data-for={`${quoteGid}-eligibility`} data-tip />
      </>
    );
  }

  if (quote.attributes.promotion_message && quote.attributes.promotion_rank === 1) {
    return (
      <>
        <PromotionTooltip
          promotionMessage={quote.attributes.promotion_message}
          quoteGid={quote.gid}
          promotionTitle="Carrier growth area"
        />
        <DottedTag text="Carrier growth area" color={colors.statusGreen} data-for={`${quoteGid}-promotion`} data-tip />
      </>
    );
  }

  if (status === QuoteStatus.Success) {
    return null;
  }

  return (
    <>
      <StatusTooltip quote={quote} />
      <DottedTag text="Unknown error" color={colors.statusRed} data-for={`${quote.gid}-eligibility`} data-tip />
    </>
  );
};

export default Status;
