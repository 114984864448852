import { keepPreviousData, useMutation, UseMutationOptions, useQuery } from '@tanstack/react-query';

import { IScheduledCall, ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import api from '../../services/api';
import { isEmpty } from '../../utils/object';
import { toQueryParams } from '../../utils/queryParams';

export const SCHEDULED_INTERACTIONS_QUERY_KEY = 'person_scheduled_interactions';

interface IScheduledInteractionsParams {
  phone: string;
  run_at: string;
  assignee_id: number | string;
  note: string;
  lead_gid?: string;
  candidate_gid?: string;
  dialer_action_id?: string | number;
}

interface IMutateScheduledInteractionsArgs {
  personGid: string;
  params: IScheduledInteractionsParams;
  scheduledCallId?: number;
}

//post

const createScheduledInteractions = async ({
  personGid,
  params
}: IMutateScheduledInteractionsArgs): Promise<unknown> => {
  return await api.post(`/api/frontend/people/${personGid}/scheduled_interactions`, { body: params });
};

export const useCreateScheduledInteractions = (
  options?: Omit<UseMutationOptions<unknown, unknown, IMutateScheduledInteractionsArgs, unknown>, 'mutationFn'>
) => useMutation({ mutationFn: createScheduledInteractions, ...options });

//put

const updateScheduledInteractions = async ({
  personGid,
  scheduledCallId,
  params
}: IMutateScheduledInteractionsArgs): Promise<unknown> => {
  return await api.put(`/api/frontend/people/${personGid}/scheduled_interactions/${scheduledCallId}`, { body: params });
};

export const useUpdateScheduledInteractions = (
  options?: Omit<UseMutationOptions<unknown, unknown, IMutateScheduledInteractionsArgs, unknown>, 'mutationFn'>
) => useMutation({ mutationFn: updateScheduledInteractions, ...options });

//get

export const usePersonScheduledInteractions = (gid?: string, flows?: ScheduledInteractionFlow[]) =>
  useQuery({
    queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY, gid, flows],
    queryFn: (): Promise<{ scheduled_interactions: IScheduledCall[] }> =>
      api.get(`/api/frontend/people/${gid}/scheduled_interactions?${toQueryParams({ flows })}`),
    enabled: !!gid,
    select: data => data.scheduled_interactions
  });

export interface Filter {
  assignees_emails: string[];
  min_run_at: Date | string;
  max_run_at: Date | string;
  page: number;
  statuses: string[];
}

interface PaginatedCalls {
  page: number | null;
  scheduled_interactions: IScheduledCall[];
  total_pages: number;
}

export const scheduledInteractionsQueryFn = (filter: Filter) => (): Promise<PaginatedCalls> =>
  api.get(`/api/frontend/scheduled_interactions?${toQueryParams(filter)}`);

// be aware that queryKey change impacts <ScheduledCalls />
const useScheduledInteractions = (filter: Filter) =>
  useQuery({
    queryKey: ['scheduled_interactions', filter],
    queryFn: scheduledInteractionsQueryFn(filter),
    enabled: !isEmpty(filter),
    placeholderData: keepPreviousData
  });

export default useScheduledInteractions;
