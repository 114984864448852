import Skeleton from 'react-loading-skeleton';

import Container from '../../../components/core/Container';
import DescriptionList from '../../../components/core/DescriptionList';
import FlexBox from '../../../components/core/FlexBox';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { IPolicy } from '../../../interfaces';
import { PolicyTypes } from '../../../interfaces/IPolicyType';
import usePersonAllPolicies from '../../../queries/people/person_policies/usePersonAllPolicies';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, moneyFormatter } from '../../../utils/formatter';
import LoanInfo from '../../GuidedSellingExperience/tabs/PersonLoansTab/LoanInfo';

const ShortPolicyInfo = ({ policy }: { policy: IPolicy }) => {
  return (
    <Container ml={spacings['-px4']}>
      {policy.policy_type && (
        <DescriptionList
          term={<Text>Policy type</Text>}
          details={<Text>{PolicyTypes.find(pt => pt.key === policy.policy_type)?.name}</Text>}
        />
      )}
      <DescriptionList term={<Text>Carrier</Text>} details={<Text>{policy.carrier.name}</Text>} />
      {policy.policy_number && (
        <DescriptionList term={<Text>Policy number</Text>} details={<Text>{policy.policy_number}</Text>} />
      )}
      {policy.premium && (
        <DescriptionList term={<Text>Premium</Text>} details={<Text>{moneyFormatter(policy.premium)}</Text>} />
      )}
      {policy.expiration_date && (
        <DescriptionList
          term={<Text>Expiration date</Text>}
          details={<Text>{dateFormatter(policy.expiration_date)}</Text>}
        />
      )}
    </Container>
  );
};

const AssetAssociatedInfo = ({ personGid, assetGid }: { personGid: string; assetGid: string }) => {
  const { data: loans, isSuccess: isLoansLoaded } = usePersonLoans({
    personGid,
    assetGid
  });

  const { data: policies, isSuccess: isPoliciesLoaded } = usePersonAllPolicies({
    personGid,
    filters: { asset_gid: assetGid }
  });

  return (
    <>
      {isLoansLoaded && isPoliciesLoaded && (
        <>
          {policies[0] && (
            <Container backgroundColor={colors.grey5} roundBorder p={spacings.px12}>
              <Paragraph bold>Associated policy information will be lost:</Paragraph>
              <FlexBox columnDirection gap={spacings.px12}>
                <ShortPolicyInfo key={policies[0].gid} policy={policies[0]} />
                {policies.length - 1 > 0 && <Paragraph bold>And {policies.length - 1} more</Paragraph>}
              </FlexBox>
            </Container>
          )}
          {loans.length > 0 && (
            <Container backgroundColor={colors.grey5} roundBorder p={spacings.px12}>
              <Paragraph bold>Associated loan information will be lost:</Paragraph>
              <FlexBox columnDirection gap={spacings.px12}>
                {loans.map(loan => (
                  <LoanInfo key={loan.gid} loan={loan} />
                ))}
              </FlexBox>
            </Container>
          )}
        </>
      )}

      {!(isLoansLoaded && isPoliciesLoaded) ? (
        <FlexBox columnDirection gap={spacings.px12} mv={spacings.px12}>
          <Skeleton height={spacings.px20} />
          <Skeleton height={spacings.px20} />
          <Skeleton height={spacings.px20} />
          <Skeleton height={spacings.px20} />
          <Skeleton height={spacings.px20} />
        </FlexBox>
      ) : null}
    </>
  );
};

export default AssetAssociatedInfo;
