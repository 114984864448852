import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Tag from '../../../../components/core/Tag';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import colors from '../../../../theme/colors';

const TestLead = () => {
  const { lead } = useGuidedSellingExperienceContext();

  const testMode = lead?.test_mode;

  if (!testMode) {
    return null;
  }

  return (
    <FlexBox justifyCenter columnDirection>
      <Tag testId="test-lead" label="Test lead" backgroundColor={colors.violet} />
    </FlexBox>
  );
};

export default TestLead;
