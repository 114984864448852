/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { NewPolicyOptions } from '../../interfaces/INewPolicyOptions';
import { opportunityDescription } from '../../interfaces/IOpportunity';
import { IPolicy } from '../../interfaces/IPolicy';
import { findPolicyTypeLabel } from '../../interfaces/IPolicyType';
import { ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import BaseForm from '../core/forms/BaseForm';
import Modal, { ModalSize } from '../core/Modal';
import Paragraph from '../core/Paragraph';
import {
  buildInitialValues,
  PriorPolicyHelperSubmitHandler,
  usePriorPolicyEditorSubmit,
  validationSchema
} from './helpers';
import MainForm from './MainForm';

export interface PriorPolicyEditorProps {
  personGid: string;
  policyType?: NewPolicyOptions['policyType'];
  assets?: NewPolicyOptions['assets'] | null;
  priorPolicy?: IPolicy;
  cancelHandler: () => void;
  confirmHandler: PriorPolicyHelperSubmitHandler;
}

const PriorPolicyEditor = ({
  personGid,
  policyType: selectedPolicyType,
  assets,
  priorPolicy,
  cancelHandler,
  confirmHandler
}: PriorPolicyEditorProps) => {
  const policyType = priorPolicy?.policy_type || selectedPolicyType;
  const assetsGids = assets?.map(({ gid }) => gid);
  const submitHandler = usePriorPolicyEditorSubmit({
    personGid,
    priorPolicy,
    confirmHandler,
    policyType,
    assetsGids
  });

  return (
    <Modal
      headerCss={css`
        align-items: center;
      `}
      header={
        <FlexBox columnDirection>
          <Paragraph type="large" bold>
            {priorPolicy ? 'Update' : 'Add'} prior policy details for {findPolicyTypeLabel(policyType) || ''}
          </Paragraph>
          {priorPolicy && <Paragraph className="fs-mask">{opportunityDescription(priorPolicy)}</Paragraph>}
          {assets && policyType && (
            /* @ts-expect-error: TODO consider using description_without_icon method and allow IPersonAsset */
            <Paragraph className="fs-mask">{opportunityDescription({ policy_type: policyType, assets })}</Paragraph>
          )}
        </FlexBox>
      }
      size={ModalSize.large}
      showCancelButton={false}
      cancelHandler={cancelHandler}
    >
      <BaseForm
        initialValues={buildInitialValues({ priorPolicy, policyType })}
        validationSchema={validationSchema}
        onSubmit={values => submitHandler(values)}
        cancelHandler={cancelHandler}
        cancelVariant={ButtonVariant.Secondary}
        submitText={priorPolicy ? 'Update' : 'Add'}
        pt={16}
        pb={16}
        pl={16}
        pr={16}
        renderForm={formikProps => (
          <MainForm selectedPolicyType={policyType} priorPolicy={priorPolicy} formikProps={formikProps} />
        )}
      />
    </Modal>
  );
};

export default PriorPolicyEditor;
