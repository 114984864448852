// @ts-expect-error old code
import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';

const NumberInput = ({
  name,
  value = '',
  maxLength = '16',
  onChange,
  intOnly = false,
  noThousandsGroups = false,
  onBlur,
  id
}: any) => {
  const onInput = ({ target: { name, rawValue: value } }: any) => onChange({ target: { name, value } });

  return (
    <Cleave
      id={id}
      name={name}
      className="form-control"
      value={value}
      maxLength={maxLength}
      options={{
        numeral: true,
        numeralPositiveOnly: true,
        numeralThousandsGroupStyle: noThousandsGroups ? 'none' : 'thousand',
        numeralDecimalScale: intOnly ? 0 : 2
      }}
      onChange={onInput}
      onBlur={onBlur}
    />
  );
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  maxLength: PropTypes.string,
  intOnly: PropTypes.bool,
  noThousandsGroups: PropTypes.bool,
  id: PropTypes.string
};

export default NumberInput;
