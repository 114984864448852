import { useMutation } from '@tanstack/react-query';

import api from '../../../services/api';

export const attachVehicle = ({ assetGid, leadId }: { assetGid: string; leadId: string | number }): Promise<null> =>
  api.put(`/api/frontend/leads/${leadId}/opportunities/vehicle`, { body: { asset_gid: assetGid } });

const useAttachVehicle = () => useMutation({ mutationFn: attachVehicle });

export default useAttachVehicle;
