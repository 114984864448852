import React from 'react';

import PriorPolicyDeleteModal from '../../../components/Assets/PriorPolicyDeleteModal';
import Container from '../../../components/core/Container';
import PriorPolicyEditor from '../../../components/PriorPolicyEditor';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { NewPolicyOptions } from '../../../interfaces/INewPolicyOptions';
import { IPriorPolicy } from '../../../interfaces/IPolicy';
import usePersonPriorPolicies from '../../../queries/people/person_policies/usePersonPriorPolicies';
import analytics from '../../../services/analytics';
import PriorPoliciesList from './PriorPoliciesList';
import PriorPolicyAssetsPicker from './PriorPolicyAssetsPicker';

const CustomerPriorPolicies = ({
  personGid,
  leadId,
  createPolicyEnabled = true
}: {
  personGid: string;
  leadId?: number;
  createPolicyEnabled?: boolean;
}) => {
  const {
    data: policies,
    isLoading: isLoadingPolicies,
    isRefetching: isRefetchingPolicies,
    refetch: refetchPolicies
  } = usePersonPriorPolicies({
    personGid
  });

  const [newPolicyOptions, setNewPolicyOptions] = React.useState<NewPolicyOptions>();
  const [assetPickerOpened, setAssetPickerOpened] = React.useState(false);

  const [editedPolicy, setEditedPolicy] = React.useState<IPriorPolicy>();
  const [editorOpened, setEditorOpened] = React.useState(false);

  const [priorPolicyGidToDelete, setPriorPolicyGidToDelete] = React.useState<string>();

  const hidePriorPolicyEditor = () => {
    setEditedPolicy(undefined);
    setEditorOpened(false);
  };

  const hideAssetPicker = () => {
    setNewPolicyOptions(undefined);
    setAssetPickerOpened(false);
  };

  if (isLoadingPolicies || isRefetchingPolicies) {
    return <TablePlaceholder testId="customer-prior-policies-loader" />;
  }

  return (
    <Container fitParentWidth>
      {assetPickerOpened && (
        <PriorPolicyAssetsPicker
          leadId={leadId}
          personGid={personGid}
          cancelHandler={hideAssetPicker}
          confirmHandler={options => {
            hideAssetPicker();
            setNewPolicyOptions(options);
            setEditorOpened(true);
          }}
        />
      )}
      {editorOpened && (
        <PriorPolicyEditor
          personGid={personGid}
          policyType={newPolicyOptions?.policyType}
          assets={newPolicyOptions?.assets}
          priorPolicy={editedPolicy}
          cancelHandler={hidePriorPolicyEditor}
          confirmHandler={() => {
            analytics.track(`Prior policy ${editedPolicy ? 'updated' : 'added'}`, {
              lead_id: leadId,
              person_gid: personGid,
              place: 'old_customer_profile_ui'
            });
            hidePriorPolicyEditor();
            hideAssetPicker();
            refetchPolicies();
          }}
        />
      )}
      <PriorPoliciesList
        priorPolicies={policies || []}
        onEditPriorPolicy={policy => {
          setEditedPolicy(policy);
          setEditorOpened(true);
        }}
        onCreatePriorPolicy={() => setAssetPickerOpened(true)}
        onDeletePriorPolicy={priorPolicy => setPriorPolicyGidToDelete(priorPolicy.gid)}
        createPriorPolicyEnabled={createPolicyEnabled}
      />
      {priorPolicyGidToDelete && (
        <PriorPolicyDeleteModal
          cancelBtnHandler={() => setPriorPolicyGidToDelete(undefined)}
          confirmBtnHandler={() => refetchPolicies()}
          personGid={personGid}
          priorPolicyGid={priorPolicyGidToDelete}
        />
      )}
    </Container>
  );
};

export default CustomerPriorPolicies;
