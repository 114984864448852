/** @jsxImportSource @emotion/react */
import Grid from '@mui/material/Grid';
import * as React from 'react';
import * as yup from 'yup';

import { FileToConfirm, FileToUpload } from '../../interfaces/IDocument';
import useDocumentTypes from '../../queries/document_types/useDocumentTypes';
import { requiredField } from '../../utils/yupRules';
import IconButton, { ButtonIcons } from '../core/buttons/IconButton';
import FormModal from '../core/FormModal';
import { SelectField } from '../core/forms/fields';
import { ModalSize } from '../core/Modal';
import Text from '../core/Text';

interface ConfirmModalProps {
  files: (FileToConfirm & { document_type_id: number; fileOwner: string })[];
  cancelHandler: () => void;
  confirmHandler: (files: FileToUpload[]) => Promise<unknown>;
  owners: { key: string; value: string; description: string | string[] | undefined }[];
}

const ConfirmModal = ({ files, cancelHandler, confirmHandler, owners }: ConfirmModalProps) => {
  const { transformedData: documentTypes = [], isPending: isPendingDocumentTypes } = useDocumentTypes();
  const docTypesOptions = documentTypes.map(type => ({ key: type.id, value: type.title }));

  return (
    <FormModal
      title="Upload File"
      size={ModalSize.large}
      cancelHandler={cancelHandler}
      confirmText="Upload"
      confirmHandler={({ files }: { files: ConfirmModalProps['files'] }) => {
        confirmHandler(
          files.map(f => ({
            ...f,
            document_type_title: docTypesOptions.find(option => option.key === f.document_type_id)?.value || 'Unknown'
          }))
        );
      }}
      initialValues={{
        files
      }}
      validationSchema={yup.object().shape({
        files: yup.array().of(
          yup.object().shape({
            document_type_id: requiredField,
            fileOwner: requiredField
          })
        )
      })}
      renderForm={({ setValues, values }) => {
        const handleDeleteFile = (fileIndex: number) => {
          const newFiles = [...values.files];
          newFiles.splice(fileIndex, 1);
          setValues({ ...values, files: newFiles });
        };

        return (
          <>
            {(values.files as ConfirmModalProps['files']).map((file, index: number) => {
              return (
                <Grid container justifyContent="space-between" key={file.uid}>
                  <Grid item container xs={12} justifyContent="space-between">
                    <Grid item xs={3} alignSelf="center" mb="16px">
                      <Text className="fs-mask" singleLine>
                        {file.name}
                      </Text>
                    </Grid>
                    <Grid item xs={4}>
                      <SelectField
                        label=""
                        id={`document_type_id-${file.uid}`}
                        name={`files.${index}.document_type_id`}
                        options={docTypesOptions}
                        isLoading={isPendingDocumentTypes}
                        placeholder="Select doc type"
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectField
                        label=""
                        id={`fileOwner-${file.uid}`}
                        name={`files.${index}.fileOwner`}
                        options={owners}
                        placeholder="Select owner"
                      />
                    </Grid>

                    <Grid item alignSelf="center" mb="16px">
                      <IconButton icon={ButtonIcons.Delete} onClick={() => handleDeleteFile(index)} />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </>
        );
      }}
    />
  );
};

export default ConfirmModal;
