/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../../components/core/Container';
import Copy from '../../../../components/core/Copy';
import CopyableAddress from '../../../../components/core/CopyableAddress';
import DescriptionList from '../../../../components/core/DescriptionList';
import { ChevronRight } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { Translations } from '../../../../constants';
import { useToggle } from '../../../../hooks';
import { ILoan } from '../../../../interfaces';
import { servicerOrLender } from '../../../../interfaces/ILender';
import { spacings } from '../../../../theme/variables';
import { dateFormatter, moneyFormatter } from '../../../../utils/formatter';
import LoanPersonInfo from './LoanPersonInfo';

const LoanInfo = ({ loan }: { loan: ILoan }) => {
  const [showMore, toggleShowMore] = useToggle(false);
  const company = servicerOrLender({ servicer: loan.servicer, lender: loan.lender });

  if (
    !loan.loan_number &&
    !loan.payment_method &&
    !loan.amount &&
    !loan.purpose &&
    !loan.est_closing_date &&
    !company &&
    !loan.processor &&
    !loan.officer
  ) {
    return null;
  }

  const lenderName = company?.name;
  const mortgageeClause = company?.business_name;
  const mortgageeAddress = company?.mortgagee_address;

  return (
    <>
      <Container ml={spacings['-px4']}>
        {!!lenderName && !mortgageeClause && (
          <DescriptionList
            term={<Text>Lender name</Text>}
            details={
              <Copy
                testId="lender_name"
                value={lenderName}
                css={css`
                  margin-left: ${spacings.px4}px;
                `}
              >
                <Text>{lenderName}</Text>
              </Copy>
            }
          />
        )}
        {!!mortgageeClause && (
          <DescriptionList
            term={<Text>Mortgagee clause</Text>}
            details={
              <Copy testId="mortgagee_clause" value={mortgageeClause}>
                <Text>{mortgageeClause}</Text>
              </Copy>
            }
          />
        )}
        {!!mortgageeAddress && (
          <DescriptionList term={<Text>Address</Text>} details={<CopyableAddress address={mortgageeAddress} />} />
        )}
        {!!loan.loan_number && (
          <DescriptionList
            term={<Text>Loan number</Text>}
            details={
              <Copy testId="loan_number" value={loan.loan_number} className="fs-mask">
                <Text>{loan.loan_number}</Text>
              </Copy>
            }
          />
        )}
        {!!loan.purpose && (
          <DescriptionList
            term={<Text>Purpose</Text>}
            details={
              <Copy testId="purpose" value={Translations.loanPurpose(loan.purpose)}>
                <Text>{Translations.loanPurpose(loan.purpose)}</Text>
              </Copy>
            }
          />
        )}
        {!!loan.payment_method && (
          <DescriptionList
            term={<Text>Billing type</Text>}
            details={
              <Copy testId="payment_method" value={Translations.paymentMethod(loan.payment_method)}>
                <Text>{Translations.paymentMethod(loan.payment_method)}</Text>
              </Copy>
            }
          />
        )}
        {!!loan.amount && (
          <DescriptionList
            term={<Text>Amount</Text>}
            details={
              <Copy testId="amount" value={loan.amount.toString()}>
                <Text>{moneyFormatter(loan.amount, true)}</Text>
              </Copy>
            }
          />
        )}
        {!!loan.est_closing_date && (
          <DescriptionList
            term={<Text>Est closing</Text>}
            details={
              <Copy testId="est_closing_date" value={dateFormatter(loan.est_closing_date)}>
                <Text>{dateFormatter(loan.est_closing_date)}</Text>
              </Copy>
            }
          />
        )}
        {(loan.officer || loan.processor) && (
          <>
            {showMore ? (
              <ButtonWithoutBorder onClick={toggleShowMore} pt={spacings.px12}>
                <>
                  Show less{' '}
                  <ChevronRight
                    css={css`
                      transform: rotate(270deg);
                    `}
                  />
                </>
              </ButtonWithoutBorder>
            ) : (
              <ButtonWithoutBorder onClick={toggleShowMore} pt={spacings.px12}>
                <>
                  Show more{' '}
                  <ChevronRight
                    css={css`
                      transform: rotate(90deg);
                    `}
                  />
                </>
              </ButtonWithoutBorder>
            )}
          </>
        )}
        {showMore && (
          <>
            {!!loan.officer && (
              <Container mt={spacings.px12}>
                <LoanPersonInfo title="Officer info" person={loan.officer} />
              </Container>
            )}
            {!!loan.processor && (
              <Container mt={spacings.px12}>
                <LoanPersonInfo title="Processor info" person={loan.processor} />
              </Container>
            )}
          </>
        )}
      </Container>
    </>
  );
};

export default LoanInfo;
