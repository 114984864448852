/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import HomeLinks from '../../../../components/common/HomeLinks';
import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import Container from '../../../../components/core/Container';
import CopyableAddress from '../../../../components/core/CopyableAddress';
import DescriptionList from '../../../../components/core/DescriptionList';
import FlexBox from '../../../../components/core/FlexBox';
import { StarIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { IOpportunity } from '../../../../interfaces';
import { findPolicyTypeLabel, InsurableInterest } from '../../../../interfaces/IPolicyType';
import { DataCollection } from '../../../../queries/leads/data_collection/useDataCollection';
import colors from '../../../../theme/colors';
import { smallParagraphCss } from '../../../../theme/typography';
import { spacings } from '../../../../theme/variables';
import { capitalize } from '../../../../utils/formatter';
import { isCustomerDataCompletenessHigh } from '../../_helpers';
import useOpportunitiesWithVerificationStatus from '../../_hooks/useOpportunitiesWithVerificationStatus';

const OpportunitiesInfo = ({
  personGid,
  dataCollection,
  opportunities
}: {
  personGid: string;
  dataCollection: DataCollection | undefined;
  opportunities: IOpportunity[] | undefined;
}) => {
  const { customerDataCompleteness } = useGuidedSellingExperienceContext();

  const opportunitiesWithVerificationStatus = useOpportunitiesWithVerificationStatus({
    personGid,
    dataCollection,
    opportunities,
    isCustomerDataCompletenessHigh: isCustomerDataCompletenessHigh(customerDataCompleteness)
  });

  return (
    <Container>
      <Text type="large" bold>
        Opportunities
      </Text>
      {opportunitiesWithVerificationStatus?.map(({ opportunity, verificationStatus, rejectionReason }) => {
        const isRealPropertyOpportunity = opportunity.insurable_interest === InsurableInterest.RealProperty;
        const isVehicleOpportunity = opportunity.insurable_interest === InsurableInterest.Vehicle;

        const homeAsset = isRealPropertyOpportunity ? opportunity?.assets?.[0] : undefined;

        return (
          <React.Fragment key={opportunity.id}>
            <FlexBox justifySpaceBetween ml={spacings['-px4']}>
              <DescriptionList
                term={
                  <FlexBox gap={spacings.px4} alignItemsCenter>
                    <Text singleLine>{findPolicyTypeLabel(opportunity.policy_type)}</Text>
                    {opportunity.primary && (
                      <>
                        <StarIcon
                          data-for="primary-opp-tip"
                          data-tip="Primary opportunity"
                          color={colors.statusGreen}
                          fill={colors.statusGreen}
                        />
                        <Tooltip id="primary-opp-tip" />
                      </>
                    )}
                  </FlexBox>
                }
                details={<Text>{capitalize(verificationStatus)}</Text>}
              />
              {homeAsset && <HomeLinks homeGid={homeAsset.gid} personGid={personGid} mt={spacings.px6} />}
            </FlexBox>
            {isVehicleOpportunity && (
              <Container>
                {opportunity.assets?.map(({ gid, description_without_icon }) => (
                  <Paragraph key={gid} type="small" color={colors.grey60} mt={spacings.px4} className="fs-mask">
                    {description_without_icon}
                  </Paragraph>
                ))}
              </Container>
            )}
            {isRealPropertyOpportunity && (
              <Container>
                {opportunity.assets?.map(({ gid, address }) => (
                  <Container
                    key={gid}
                    customCss={[
                      smallParagraphCss,
                      css`
                        color: ${colors.grey60};
                      `
                    ]}
                    mt={spacings.px4}
                    ml={spacings['-px4']}
                    className="fs-mask"
                  >
                    <CopyableAddress address={address} />
                  </Container>
                ))}
              </Container>
            )}
            {rejectionReason && (
              <Container ml={spacings['-px4']} mt={spacings['-px8']}>
                <DescriptionList
                  term={<Text color={colors.grey60}>Reason</Text>}
                  details={<Text>{capitalize(rejectionReason)}</Text>}
                />
              </Container>
            )}
          </React.Fragment>
        );
      })}
    </Container>
  );
};

export default OpportunitiesInfo;
