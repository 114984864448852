import { COMMUTE_DESTINATIONS, UsageType } from '../../interfaces/IPersonAsset';

export const calculateNotCommutingMileage = (usage: string) => {
  switch (usage) {
    case 'pleasure':
      return 8000;
    case 'business':
      return 15000;
    case 'farm':
      return 15000;
    default:
  }
};

export const mileageToWork = (distanceValue: number) => {
  if (distanceValue <= 6000) {
    return 9000;
  }
  if (distanceValue <= 7500) {
    return 10000;
  }
  if (distanceValue <= 10000) {
    return 12000;
  }
  if (distanceValue > 10000) {
    return distanceValue;
  }
};

export const calculateCommutingMileage = (daysPerWeekDriven: string, oneWayMileage: string) => {
  const daysPerWeekDrivenNumber = parseInt(daysPerWeekDriven);
  const oneWayMileageNumber = parseInt(oneWayMileage);

  const distanceValue = daysPerWeekDrivenNumber * oneWayMileageNumber * 2 * 52;
  if (!daysPerWeekDrivenNumber || !oneWayMileageNumber) {
    return '';
  }
  if (daysPerWeekDrivenNumber && oneWayMileageNumber) {
    return mileageToWork(distanceValue);
  }
};

export const daysPerWeekDriven = (annualMileage: string, oneWayMileageNumber: string) => {
  const annualMileageNumber = parseInt(annualMileage);
  const oneWayMileageNumberNumber = parseInt(oneWayMileageNumber);

  const daysPerWeekFromForm = annualMileageNumber / 52 / 2 / oneWayMileageNumberNumber;
  if (daysPerWeekFromForm >= 7) {
    return 7;
  }
  if (daysPerWeekFromForm <= 1) {
    return 1;
  }
  if (daysPerWeekFromForm > 1 && daysPerWeekFromForm < 7) {
    return Math.round(daysPerWeekFromForm);
  }
};

export const hasWorkOrSchoolValue = (usage: UsageType) => COMMUTE_DESTINATIONS.includes(usage);

export const calculateDaysPerWeek = (usage: UsageType, annualMileage: string, oneWayMileageNumber: string) => {
  if (hasWorkOrSchoolValue(usage)) {
    return daysPerWeekDriven(annualMileage, oneWayMileageNumber);
  }
  return '';
};

export const calculateMileage = (usage: UsageType, daysPerWeekDriven: string, oneWayMileageNumber: string) => {
  if (hasWorkOrSchoolValue(usage)) {
    return calculateCommutingMileage(daysPerWeekDriven, oneWayMileageNumber);
  }
  return calculateNotCommutingMileage(usage);
};
