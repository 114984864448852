/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React, { ReactNode, useCallback, useRef, useState } from 'react';

import useOnClickOutside from '../../../hooks/useOnClickOutside';
import colors, { opacities } from '../../../theme/colors';
import { defaultFontCss } from '../../../theme/typography';
import { borderRadius } from '../../../theme/variables';
import { ArrowDownIcon, ArrowUpIcon } from '../icons';
import Text from '../Text';
import Button, {
  buttonColors,
  ButtonSize,
  buttonSizeCss,
  ButtonVariant,
  getActiveFocusColors,
  getHoverColors,
  iconComponentSize,
  loaderComponent
} from './Button';

interface DropDownOption {
  value: string | number;
  label: ReactNode;
  disabled?: boolean;
  action?: () => void;
  description?: string;
  customCss?: SerializedStyles;
}

export enum DropDownPosition {
  Top = 'top',
  Bottom = 'bottom'
}

export interface DropDownProps<TOption> {
  children: React.ReactNode;
  options: readonly TOption[];
  onSelected: (selectedOption: TOption) => void;
  size?: ButtonSize;
  optionsSize?: ButtonSize;
  disabled?: boolean;
  loading?: boolean;
  customCss?: SerializedStyles;
  optionsCss?: SerializedStyles;
  variant?: ButtonVariant;
  noArrow?: boolean;
  iconChildren?: boolean;
  testId?: string;
  staticView?: boolean;
  iconChildrenActiveProperty?: { [key: string]: string };
  customButtonCss?: SerializedStyles;
  position?: DropDownPosition;
}

// TODO: refactor to some Combobox component (adobe aria / material)
const DropDown = <TOption extends DropDownOption>({
  children,
  options,
  onSelected,
  size = ButtonSize.Normal,
  optionsSize = ButtonSize.Normal,
  disabled = false,
  loading = false,
  variant = ButtonVariant.Default,
  noArrow = false,
  customCss,
  optionsCss,
  iconChildren = false,
  testId,
  staticView = false,
  iconChildrenActiveProperty,
  customButtonCss,
  position = DropDownPosition.Bottom
}: DropDownProps<TOption>): JSX.Element => {
  const [showOptions, setShowOptions] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);

  const clickOutsideHandler = useCallback(() => setShowOptions(false), []);
  useOnClickOutside(dropDownRef, clickOutsideHandler);

  const IconComponent = showOptions ? ArrowUpIcon : ArrowDownIcon;

  const onClickHandler = () => {
    if (options.length === 1) {
      onSelected(options[0]!);
    } else {
      setShowOptions(!showOptions);
    }
  };

  const pickArrowColor = useCallback(() => {
    if (variant === ButtonVariant.Text) {
      return colors.azure50;
    }
    if (variant === ButtonVariant.PlainText) {
      return colors.black;
    }

    return colors.white;
  }, [variant]);

  const pickOptionColors = (variant: ButtonVariant): SerializedStyles => {
    switch (variant) {
      case ButtonVariant.Text:
        return buttonColors(colors.white, colors.white, colors.azure50);
      case ButtonVariant.PlainText:
        return buttonColors(colors.white, colors.white, colors.black);
      case ButtonVariant.Simple:
        return buttonColors(colors.white, colors.grey5, colors.white);
      default:
        return buttonColors(colors.white, colors.white, colors.black);
    }
  };

  const pickOptionHoverColors = (variant: ButtonVariant): SerializedStyles => {
    switch (variant) {
      case ButtonVariant.Text:
        return buttonColors(colors.azure50, colors.azure50, colors.white);
      case ButtonVariant.PlainText:
        return buttonColors(colors.azure50, colors.azure50, colors.white);
      case ButtonVariant.Simple:
        return buttonColors(colors.grey5, colors.grey5, colors.white);
      default:
        return getHoverColors(variant);
    }
  };

  const pickOptionFontWeight = (variant: ButtonVariant) => {
    switch (variant) {
      case ButtonVariant.Text:
        return 700;
      default:
        return 400;
    }
  };

  const renderOption = (option: TOption) => {
    return (
      <li
        key={option.value}
        onClick={() => {
          if (!option.disabled) {
            onSelected(option);
            setShowOptions(false);
          }
        }}
        css={[
          css`
            ${defaultFontCss}
            ${buttonSizeCss(optionsSize)};
            ${pickOptionColors(variant)}
            font-weight: ${pickOptionFontWeight(variant)};
            &:first-of-type {
              border-top-left-radius: ${borderRadius}px;
              border-top-right-radius: ${borderRadius}px;
            }
            &:last-of-type {
              border-bottom-right-radius: ${borderRadius}px;
              border-bottom-left-radius: ${borderRadius}px;
            }
            &:hover {
              ${pickOptionHoverColors(variant)}
              a {
                ${pickOptionHoverColors(variant)}
              }
            }
            &:active,
            &:focus {
              ${getActiveFocusColors(variant)}
            }
            &:disabled:hover {
              cursor: not-allowed;
            }

            list-style-type: none;
            cursor: ${option.disabled ? 'not-allowed' : 'pointer'};
            ${option.customCss}
          `
        ]}
      >
        {option.label}
        {option.description && (
          <div>
            <Text color={colors.grey60} type="tiny">
              {option.description}
            </Text>
          </div>
        )}
      </li>
    );
  };

  const iconChildrenActive = iconChildrenActiveProperty ? iconChildrenActiveProperty : { color: colors.azure50 };
  const optionsPositionCss =
    position === DropDownPosition.Top
      ? css`
          margin-bottom: 56px;
        `
      : css`
          margin-top: 8px;
        `;
  const topPositionLayout = css`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  `;

  return (
    <div
      ref={dropDownRef}
      css={[
        css`
          position: relative;
          ${position === DropDownPosition.Top ? topPositionLayout : ''}
        `,
        customCss
      ]}
    >
      {iconChildren ? (
        <Button
          variant={ButtonVariant.Text}
          disabled={disabled || loading}
          data-testid={testId}
          onClick={event => {
            event.stopPropagation();
            onClickHandler();
          }}
          customCss={customButtonCss}
        >
          {showOptions ? React.cloneElement(children as JSX.Element, iconChildrenActive) : children}
        </Button>
      ) : (
        <Button
          data-testid={testId}
          size={size}
          disabled={disabled || loading}
          variant={variant}
          onClick={event => {
            event.stopPropagation();
            onClickHandler();
          }}
          customCss={customButtonCss}
        >
          {children}
          {!(disabled || loading || options.length === 1) && !noArrow && (
            <IconComponent
              data-testid={`${testId}-dropdown-icon`}
              color={pickArrowColor()}
              css={css`
                height: ${iconComponentSize(size)};
                width: ${iconComponentSize(size)};
                margin-left: 8px;
              `}
            />
          )}
          {loaderComponent(loading, size)}
        </Button>
      )}

      {showOptions && (
        <div
          css={css`
            box-sizing: border-box;
            box-shadow: 0px 4px 0px ${colors.black}${opacities.opacity_10};
            border: 1px solid ${colors.grey30};
            border-radius: ${borderRadius}px;
            position: absolute;
            z-index: 1000;
            width: ${staticView ? '320px' : '200px'};
            max-height: ${staticView ? undefined : '340px'};
            overflow: auto;
            ${optionsPositionCss}
            ${optionsCss}
          `}
          key={options[1]?.value}
        >
          <div>{options.map(option => renderOption(option))}</div>
        </div>
      )}
    </div>
  );
};

export default DropDown;
