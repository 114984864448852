import { useQuery } from '@tanstack/react-query';
import * as React from 'react';

import { Resources } from '../../constants';
import api from '../../services/api';

export interface DocumentType {
  id: number;
  key: string;
  title: string;
  regexp: RegExp;
  could_not_collect: boolean;
}

const useDocumentTypes = (allowed_for_policy?: boolean) => {
  const query = useQuery({
    queryKey: ['documentTypes', { allowed_for_policy }],
    queryFn: (): Promise<{ document_types: DocumentType[] }> =>
      api.get(Resources.DOCUMENT_TYPES.format(), allowed_for_policy ? { params: { allowed_for_policy } } : {}),
    staleTime: Infinity
  });

  const transformedData = React.useMemo(() => {
    if (query.data) {
      return query.data.document_types.map(type => ({
        ...type,
        regexp: new RegExp(type.regexp, 'gi')
      })) as DocumentType[];
    }
  }, [query.data]);

  return { ...query, transformedData };
};

export default useDocumentTypes;
