/* eslint-disable no-console */
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { create } from 'zustand';

import { Configuration } from '../constants';

let msalInstance: PublicClientApplication;

export const getEntraAuthInstance = async () => {
  if (msalInstance) {
    return msalInstance;
  }

  msalInstance = new PublicClientApplication({
    auth: {
      clientId: Configuration.entraClientId,
      authority: Configuration.entraAuthority,
      redirectUri: window.location.origin,
      postLogoutRedirectUri: '/login'
    },
    cache: {
      cacheLocation: 'localStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        logLevel: LogLevel.Error,
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              console.log(message);
          }
        }
      }
    }
  });

  await msalInstance.initialize();

  return msalInstance;
};

export const useEntraIdToken = create(() => ({ idToken: '' }));
export const getIdToken = () => useEntraIdToken.getState().idToken;
