import {
  AutoCoverageOption,
  autoCoverageOptionDefinitions,
  AutoCoveragesOptions
} from '../interfaces/IAutoCoveragesOptions';
import { ISelectedCoverages, OpportunityCoveragesTier } from '../interfaces/IOpportunity';

const INVALID_CURRENCY_SUFFIX = ',00 US$';

const isOptionWithCurrencySuffix = (coverageLabel: string) => coverageLabel.includes(INVALID_CURRENCY_SUFFIX);
const removeCurrencySuffix = (coverageLabel: string) => {
  const numberString = coverageLabel.replace(INVALID_CURRENCY_SUFFIX, '').replaceAll(' ', '');

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumSignificantDigits: 1
  });

  return currencyFormatter.format(parseInt(numberString));
};

export const findCoverageOptionsForTier = ({
  coveragesOptions,
  tier,
  selectedVehicleCoverages
}: {
  coveragesOptions: AutoCoveragesOptions;
  tier: OpportunityCoveragesTier;
  selectedVehicleCoverages?: ISelectedCoverages | null;
}) =>
  coveragesOptions
    .map(coverageOptions =>
      coverageOptions.values.find(option => {
        switch (tier) {
          case OpportunityCoveragesTier.Basic:
            return option.basic ? option : null;
          case OpportunityCoveragesTier.Default:
            return option.default ? option : null;
          case OpportunityCoveragesTier.Premium:
            return option.premium ? option : null;
          case OpportunityCoveragesTier.NonStandard:
            return option.non_standard ? option : null;
          case OpportunityCoveragesTier.Mixed:
            return selectedVehicleCoverages?.coverages?.find(coverage => coverage.limit_option_gid === option.gid);
          default:
            return option.default ? option : null;
        }
      })
    )
    .filter(Boolean);

const descriptionForCustomerChoice = (tier: string, choice: string | null | undefined) =>
  choice === tier ? "Customer's choice" : undefined;

export const uiDataForCoverageOption = (autoCoverageOption: AutoCoverageOption) => {
  let limitsLabel = autoCoverageOption.ui_values.replaceAll(',000', 'K');
  if (isOptionWithCurrencySuffix(limitsLabel)) {
    limitsLabel = removeCurrencySuffix(limitsLabel);
  }

  return {
    ...autoCoverageOption,
    coverageLabel: autoCoverageOptionDefinitions[autoCoverageOption.key] || autoCoverageOption.name,
    limitsLabel
  };
};

const vehicleTierOptionBILimit = ({
  coveragesOptions,
  tier
}: {
  coveragesOptions: AutoCoveragesOptions;
  tier: OpportunityCoveragesTier;
}) => {
  const coverageOption = findCoverageOptionsForTier({
    coveragesOptions,
    tier
  })?.find(coverageOption => coverageOption?.key === 'bodily_injury');

  if (!coverageOption) {
    return null;
  }

  return uiDataForCoverageOption(coverageOption)?.limitsLabel;
};

export const labelForCoverageTier = (tier: OpportunityCoveragesTier | undefined | null) => {
  switch (tier) {
    case OpportunityCoveragesTier.Basic:
      return 'Good (state minimum)';
    case OpportunityCoveragesTier.Default:
      return 'Better';
    case OpportunityCoveragesTier.Premium:
      return 'Best';
    case OpportunityCoveragesTier.NonStandard:
      return 'Non-standard';
    case OpportunityCoveragesTier.Mixed:
      return 'Custom';
    default:
      return null;
  }
};

export const vehicleCoverageTierOptions = ({
  coveragesOptions,
  customerChoiceCoverageTier
}: {
  coveragesOptions: AutoCoveragesOptions;
  customerChoiceCoverageTier?: string | null;
}) => {
  if (!coveragesOptions.length) {
    return [];
  }

  return [
    {
      key: OpportunityCoveragesTier.Basic,
      value: `${labelForCoverageTier(OpportunityCoveragesTier.Basic)} (${vehicleTierOptionBILimit({
        coveragesOptions,
        tier: OpportunityCoveragesTier.Basic
      })})`,
      description: descriptionForCustomerChoice(OpportunityCoveragesTier.Basic, customerChoiceCoverageTier)
    },
    {
      key: OpportunityCoveragesTier.Default,
      value: `${labelForCoverageTier(OpportunityCoveragesTier.Default)} (${vehicleTierOptionBILimit({
        coveragesOptions,
        tier: OpportunityCoveragesTier.Default
      })})`,
      description: descriptionForCustomerChoice(OpportunityCoveragesTier.Default, customerChoiceCoverageTier)
    },
    {
      key: OpportunityCoveragesTier.Premium,
      value: `${labelForCoverageTier(OpportunityCoveragesTier.Premium)} (${vehicleTierOptionBILimit({
        coveragesOptions,
        tier: OpportunityCoveragesTier.Premium
      })})`,
      description: descriptionForCustomerChoice(OpportunityCoveragesTier.Premium, customerChoiceCoverageTier)
    },
    {
      key: OpportunityCoveragesTier.NonStandard,
      value: `${labelForCoverageTier(OpportunityCoveragesTier.NonStandard)} (${vehicleTierOptionBILimit({
        coveragesOptions,
        tier: OpportunityCoveragesTier.NonStandard
      })})`,
      description: descriptionForCustomerChoice(OpportunityCoveragesTier.NonStandard, customerChoiceCoverageTier)
    },
    {
      key: OpportunityCoveragesTier.Mixed,
      value: labelForCoverageTier(OpportunityCoveragesTier.Mixed)
    }
  ];
};
