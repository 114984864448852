/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../components/core/FlexBox';
import { AudioIcon, UploadIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { IPerson } from '../../../../interfaces/IPerson';
import { ICallInteraction, InteractionDirection } from '../../../../interfaces/IPersonInteraction';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { capitalize, phoneFormatterBracesless } from '../../../../utils/formatter';
import { callDuration, displayedDateAndTime } from './helpers';
import InteractionDetailsLayout, { CommunicatorLayout } from './InteractionDetailsLayout';
import useAudioUrl from './useAudioUrl';

const CallDetails = ({
  interaction,
  interactionPerson,
  relatedPersonKind,
  userRole
}: {
  interaction: ICallInteraction;
  interactionPerson: IPerson | undefined;
  userRole: string | null;
  relatedPersonKind?: string;
}): JSX.Element => {
  const personFullName = interactionPerson
    ? `${interactionPerson.first_name} ${interactionPerson.last_name}`
    : 'Unknown';
  const userFullName = interaction.processed_by
    ? `${interaction.processed_by.first_name} ${interaction.processed_by.last_name}`
    : 'Matic phone';

  const { setAudioUrl } = useAudioUrl();
  const audioUrl = interaction.call_record_url;

  return (
    <InteractionDetailsLayout
      header={
        <>
          <Paragraph type="small">{`${capitalize(interaction.direction || 'Scheduled')} ${
            interaction.type
          }`}</Paragraph>
          <Paragraph type="small">
            {interaction.started_at && displayedDateAndTime(moment(interaction.started_at))}
          </Paragraph>
        </>
      }
      sender={
        <CommunicatorLayout
          name={interaction.direction === InteractionDirection.Inbound ? personFullName : userFullName}
          tagLabel={interaction.direction === InteractionDirection.Inbound ? relatedPersonKind : userRole}
          info={phoneFormatterBracesless(
            interaction.direction === InteractionDirection.Inbound ? interaction.person_phone : interaction.matic_phone
          )}
        />
      }
      receiver={
        <CommunicatorLayout
          name={interaction.direction === InteractionDirection.Inbound ? userFullName : personFullName}
          tagLabel={interaction.direction === InteractionDirection.Inbound ? userRole : relatedPersonKind}
          info={phoneFormatterBracesless(
            interaction.direction === InteractionDirection.Inbound ? interaction.matic_phone : interaction.person_phone
          )}
        />
      }
      body={
        <FlexBox gap={spacings.px12} columnDirection>
          {interaction.scheduled_by && (
            <Paragraph type="small" bold>
              Call scheduled by {interaction.scheduled_by.name}
            </Paragraph>
          )}
          {interaction.started_at && interaction.ended_at && (
            <FlexBox justifySpaceBetween alignItemsCenter>
              <FlexBox alignItemsCenter gap={spacings.px4}>
                {audioUrl && (
                  <ButtonWithoutBorder onClick={() => setAudioUrl(audioUrl)}>
                    <AudioIcon
                      width={24}
                      height={24}
                      color={colors.azure50}
                      css={css`
                        &:hover {
                          opacity: 0.8;
                        }
                      `}
                    />
                  </ButtonWithoutBorder>
                )}
                <Paragraph color={colors.grey60} inline>
                  Duration
                </Paragraph>
                <Text>{callDuration({ start: interaction.started_at, end: interaction.ended_at })}</Text>
              </FlexBox>
              {audioUrl && authInfo.features.download_call_recordings && (
                <a download href={audioUrl} target="_blank" rel="noreferrer">
                  <UploadIcon width={24} height={24} />
                </a>
              )}
            </FlexBox>
          )}
        </FlexBox>
      }
    />
  );
};

export default CallDetails;
