import './Note.scss';

import React from 'react';

import { NoteIcon } from '../../core/icons';
import { Link } from '..';
import Tooltip from '../Tooltip/Tooltip';

const Note = ({ note, url }: { note: string | null; url: string }) => {
  const NoteInfoWrapper = url ? Link : 'div';

  return note ? (
    <Tooltip message={note} className="note-info" right>
      <NoteInfoWrapper to={url} className="note-info-icon">
        <NoteIcon />
      </NoteInfoWrapper>
    </Tooltip>
  ) : null;
};

export default Note;
