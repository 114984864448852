import { useQuery } from '@tanstack/react-query';

import { IPersonWithDncStatus } from '../../interfaces';
import { INote } from '../../interfaces/INote';
import { IMaticPolicy, PolicySource, PolicyStatus } from '../../interfaces/IPolicy';
import { PolicyType } from '../../interfaces/IPolicyType';
import { ITask } from '../../interfaces/ITask';
import { IUser } from '../../interfaces/IUser';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface DashboardPolicy
  extends Omit<IMaticPolicy, 'document_transaction' | 'files' | 'assets' | 'last_ams_sync_at'> {
  person: IPersonWithDncStatus;
  source: PolicySource;
  agent: IUser | null;
  latest_note: INote | null;
  latest_task: ITask | null;
  lead_gid: string | null;
}

export interface PoliciesDashboardSorting {
  sort_column: string;
  sort_order: string;
}

export interface PoliciesDashboardFilters {
  policy_types?: PolicyType[];
  statuses?: PolicyStatus[];
  agent_ids?: string[];
  carrier_ids?: string[];
  effective_from?: string;
  effective_to?: string;
  expiration_from?: string;
  expiration_to?: string;
  cancellation_from?: string;
  cancellation_to?: string;
  cancellation_reasons?: string[];
  test_lead?: boolean;
  policy_state?: string;
  policy_number?: string;
  customer_term?: string;
  page?: number;
}

type PoliciesDashboardQuery = PoliciesDashboardSorting & PoliciesDashboardFilters;

export interface PoliciesDashboardResponse {
  policies: DashboardPolicy[];
  pages: number;
  count: number;
}

const usePolicies = (query?: PoliciesDashboardQuery) =>
  useQuery({
    queryKey: ['policies', query],
    queryFn: (): Promise<PoliciesDashboardResponse> => api.get(`/api/frontend/policies?${toQueryParams(query)}`)
  });

export default usePolicies;
