import * as React from 'react';

import { useDebouncedValue } from '../../../hooks';
import useValidatePolicyNumber from '../../../queries/policies/useValidatePolicyNumber';

const usePolicyNumberValidation = ({
  policy_number,
  carrier_id,
  policy_type,
  state
}: Parameters<typeof useValidatePolicyNumber>[0]) =>
  useValidatePolicyNumber(
    useDebouncedValue(
      React.useMemo(
        () => ({
          policy_number,
          carrier_id,
          policy_type,
          state
        }),
        [policy_number, carrier_id, policy_type, state]
      ),
      500
    )
  ).data;

export default usePolicyNumberValidation;
