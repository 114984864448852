import React from 'react';

import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { IPersonOnlineSessionFlowType } from '../../../../interfaces/IPersonOnlineSession';
import usePersonOnlineSessions from '../../../../queries/people/person_online_sessions/usePersonOnlineSessions';
import DigitalProfileSessionContextInfo from './DigitalProfileSessionContextInfo';
import MarketingOpportunityContextInfo from './MarketingOpportunityContextInfo';
import OnlineBindSessionContextInfo from './OnlineBindSessionContextInfo';

const ContextInfo = () => {
  const { personGid, leadGid, candidateGid, lead, candidate, sourceDimensions } = useGuidedSellingExperienceContext();
  const { data: customerOnlineSessions } = usePersonOnlineSessions({
    personGid,
    engagementGid: leadGid || candidateGid
  });

  const recentOnlineSession = customerOnlineSessions?.[0];

  if (personGid && leadGid && recentOnlineSession) {
    if (recentOnlineSession.flow_type === IPersonOnlineSessionFlowType.DigitalProfile) {
      return (
        <DigitalProfileSessionContextInfo
          personGid={personGid}
          leadGid={leadGid}
          personOnlineSession={recentOnlineSession}
        />
      );
    }

    if (recentOnlineSession.flow_type === IPersonOnlineSessionFlowType.OnlineBind) {
      return (
        <OnlineBindSessionContextInfo
          personGid={personGid}
          leadGid={leadGid}
          personOnlineSession={recentOnlineSession}
        />
      );
    }
  }

  if (personGid) {
    return (
      <MarketingOpportunityContextInfo
        personGid={personGid}
        lead={lead}
        candidate={candidate}
        sourceDimensions={sourceDimensions}
      />
    );
  }

  return null;
};

export default ContextInfo;
