import localStorageManager from './LocalStorageManager';

class RecentLeadManager {
  get savedList(): string[] {
    return localStorageManager.read('recentLeads', []);
  }

  get list(): number[] {
    return this.savedList.map(id => parseInt(id));
  }

  _addToRecentLeads(leadId: string): string[] {
    const leads = this.savedList.filter(item => item !== leadId);
    leads.unshift(leadId);

    return leads;
  }

  addLead(leadId: string) {
    const updatedRecentLeads = this._addToRecentLeads(leadId);
    const limitedRecentLeadList = updatedRecentLeads.slice(0, 24);
    localStorageManager.write('recentLeads', limitedRecentLeadList);
  }
}

export default new RecentLeadManager();
