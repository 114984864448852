/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Blockquote from '../../../../components/core/Blockquote';
import Container from '../../../../components/core/Container';
import Heading from '../../../../components/core/Heading';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { ScoutingStrategy } from '../../../../queries/scouting_strategies/useScoutingStrategy';
import analytics from '../../../../services/analytics';
import { isSuperISR } from '../../../../services/authInfo';
import { heading4Css } from '../../../../theme/typography';
import { spacings } from '../../../../theme/variables';
import parseHtmlString from '../../../../utils/htmlStringParser';

const removeSummaryStepIfSuperISR = (step: ScoutingStrategy['steps'][number]) => {
  const isSuperISRFlow = isSuperISR();

  if (!isSuperISRFlow) {
    return true;
  }

  return step.title !== 'Summary';
};

const ScoutingStrategyGuide = ({
  scoutingStrategy,
  demo = false
}: {
  scoutingStrategy: ScoutingStrategy;
  demo?: boolean;
}): JSX.Element | null => {
  const { callLogId, personGid, leadGid, candidateGid } = useGuidedSellingExperienceContext() || {};

  const { key, steps } = scoutingStrategy;

  React.useEffect(() => {
    if (key && !demo) {
      analytics.track('Scouting strategy config served', {
        call_log_id: callLogId,
        person_gid: personGid,
        lead_gid: leadGid,
        candidate_gid: candidateGid,
        config_key: key,
        steps_ids: steps.map(step => step.id)
      });
    }
  }, [callLogId, candidateGid, leadGid, key, personGid, demo, steps]);

  if (!key) {
    return null;
  }

  return (
    <Container pl={spacings.px32}>
      {steps.filter(removeSummaryStepIfSuperISR).map((step, index) => (
        <Container
          key={step.id}
          css={css`
            ${index > 0 && step.title && 'margin-top: 40px;'}
          `}
        >
          {step.title && <Blockquote iconBacking={10} text={<Heading type="h4">{step.title}</Heading>} />}
          <Container
            css={css`
              ${heading4Css};
              font-weight: normal;
            `}
          >
            {parseHtmlString(step.description)}
          </Container>
        </Container>
      ))}
    </Container>
  );
};

export default ScoutingStrategyGuide;
