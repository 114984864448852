import './PolicyEditor.scss';

import classNames from 'classnames';
import debounce from 'debounce';
import { Field } from 'formik';
import moment from 'moment';
import * as React from 'react';
import { Col, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import {
  DEFAULT_DEDUCTIBLE_TYPE,
  DWELLING_COVERAGE_MAX_LENGTH,
  EFFECTIVE_DATE_INVALID_WARNING,
  PERCENTAGE_DEDUCTIBLE_TYPE,
  POLICY_NUMBER_GENERIC_MESSAGE,
  POLICY_NUMBER_INVALID_WARNING,
  Translations
} from '../../constants';
import { ICarrier, IMaticPolicy, IPerson, IPersonAsset, IUser } from '../../interfaces';
import { PolicyStatus, PolicyTransactionType } from '../../interfaces/IPolicy';
import {
  assetsForPolicyType,
  doesPolicyTypeHavePrimaryCoverage,
  doesPolicyTypeRequireAsset,
  doesPolicyTypeSupportAgentOfRecord,
  doesPolicyTypeSupportNewClosing,
  isOnlyOneAssetSupported,
  possiblePolicyTerms
} from '../../interfaces/IPolicyType';
import { PolicyValidationResponse, validatePolicyNumber } from '../../queries/policies/useValidatePolicyNumber';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { INTERNAL_DATE_FORMAT } from '../../utils/formatter';
import { showFieldErrorGenerator } from '../../utils/formHelpers';
import { except } from '../../utils/object';
import { getTodayDate, requiredDateMaxToday, requiredField } from '../../utils/yupRules';
import { CopyField, ErrorCol, FullScreenFormModal } from '../common';
import {
  DatePicker,
  Input,
  MoneyInput,
  MultiSelect,
  Select,
  TypeAddOn
} from '../common/FormikComponents/FormikComponents';
import TimePeriod from '../common/TimePeriod/TimePeriod';
import TimeTerm from '../common/TimeTerm/TimeTerm';
import NewTooltip from '../common/Tooltip/NewTooltip';
import FormModal from '../core/FormModal';
import NumericField from '../core/forms/fields/NumericField';
import { DollarIcon, InfoIcon } from '../core/icons';
import * as helpers from './helpers';
import PolicyModalHeader from './PolicyModalHeader';
import PolicyShortInfo from './PolicyShortInfo';

interface RenewProps {
  policy: IMaticPolicy;
  carriers: ICarrier[];
  assignees: IUser[];
  cancelBtnHandler: (...args: any) => unknown;
  confirmBtnHandler: (...args: any) => unknown;
  assets: IPersonAsset[];
  person: IPerson;
}

const PolicyRenewer = ({
  policy,
  carriers,
  assignees,
  cancelBtnHandler,
  confirmBtnHandler,
  assets,
  person
}: RenewProps): JSX.Element => {
  const [showPremiumConfirmation, setShowPremiumConfirmation] = React.useState(false);
  const confirmedPremiumRef = React.useRef<number | string>(0);

  const [policyNumberValidation, setPolicyNumberValidation] = React.useState<PolicyValidationResponse>({
    valid: true,
    sample: '',
    warning: null,
    error: null
  });

  const onSubmit = (values: any) => {
    if (
      Number(values.premium) >= helpers.TOP_PREMIUM_AMOUNT &&
      Number(values.premium) !== confirmedPremiumRef.current
    ) {
      setShowPremiumConfirmation(true);

      return Promise.resolve();
    }

    return confirmBtnHandler({
      ...except(values, 'asset_gid'),
      primary_coverage: doesPolicyTypeHavePrimaryCoverage(values.policy_type) ? values.primary_coverage : '',
      cancellation_reason: helpers.isStatusLeadsToCancellation(values.status) ? values.cancellation_reason : '',
      cancellation_date: helpers.isStatusLeadsToCancellation(values.status) ? values.cancellation_date : '',
      policy_number: values.policy_number.trim(),
      asset_gids: helpers.assetGidsForSubmit(values),
      lead_id: policy.lead_id
    });
  };

  const validatePolicyNumberCallback = debounce((policy_number: any, carrier_id: any, policy_type: any, state: any) => {
    if (carrier_id) {
      validatePolicyNumber({ policy_number, carrier_id, policy_type, state }).then(({ policy_number }) =>
        setPolicyNumberValidation(policy_number)
      );
    }
  }, 500);

  const validationSchema = () => {
    if (authInfo.features.loose_policy_validation) {
      return undefined;
    }

    return yup.object().shape({
      transaction_type: requiredField,
      policy_type: requiredField,
      carrier_id: requiredField,
      premium: requiredField,
      status: requiredField, // required for cancellation_reason validation
      primary_coverage: yup
        .string()
        .when('policy_type', ([policy_type], schema) =>
          doesPolicyTypeHavePrimaryCoverage(policy_type) ? schema.required(helpers.REQUIRED_TEXT) : schema
        ),
      cancellation_reason: helpers.requiredForCancelStatus,
      cancellation_date: helpers.requiredDateForStatus(),
      policy_number: helpers.requiredForBoundOrActiveStatus,
      payment_method: helpers.requiredForBoundOrActiveStatus,
      sale_date: requiredDateMaxToday(),
      period: requiredField,
      effective_date: helpers.requiredExistingPolicyTerm(),
      state: yup
        .string()
        .when('policy_type', ([policyType], schema) =>
          doesPolicyTypeRequireAsset(policyType) ? schema : requiredField
        )
    });
  };

  return (
    <FullScreenFormModal
      open
      cancelHandler={cancelBtnHandler}
      confirmHandler={onSubmit}
      className="policy-editor"
      title={<PolicyModalHeader person={person} heading="Renew policy." closeHandler={cancelBtnHandler} />}
      enableReinitialize
      initialValues={{
        id: policy.id,
        transaction_type: PolicyTransactionType.RENEWAL,
        new_closing: policy.new_closing,
        policy_type: policy.policy_type || '',
        agent_id: policy.id ? policy.agent_id || '' : authInfo.currentUserId,
        carrier_id: policy.carrier.id || '',
        premium: policy.premium || '',
        deductible_type: policy.deductible_type || DEFAULT_DEDUCTIBLE_TYPE,
        deductible_value: policy.deductible_value || '',
        primary_coverage: policy.primary_coverage || '',
        status: PolicyStatus.RENEWED,
        cancellation_reason: policy.cancellation_reason || '',
        cancellation_date: policy.cancellation_date || '',
        sale_date: policy.sale_date || moment().format(INTERNAL_DATE_FORMAT),
        period: helpers.getPredefinedPeriod(policy),
        effective_date: policy.expiration_date,
        expiration_date: moment(policy.expiration_date).add(helpers.getPredefinedPeriod(policy), 'months').format(),

        policy_number: policy.policy_number || '',
        payment_method: policy.payment_method || '',
        asset_gids: helpers.getAssetGids(policy),
        asset_gid: helpers.getAssetGid(policy),
        files: policy.files || [],
        state: helpers.getFirstAssetState(policy, assets)
      }}
      validationSchema={validationSchema()}
      renderForm={({ values, errors, touched, setFieldValue, isValid }) => {
        const showFieldError = showFieldErrorGenerator({ values, touched, isValid });

        const carrieIdField = (
          <Col md="6">
            <Label htmlFor="policy-editor-carrier-select">Carrier *</Label>
            <FormGroup row>
              <ErrorCol error={errors.carrier_id} when={showFieldError('carrier_id')}>
                <Field
                  inputId="policy-editor-carrier-select"
                  name="carrier_id"
                  component={Select}
                  options={carriers}
                  valueName="id"
                  labelName="name"
                  onChange={(carrier_id: string) =>
                    validatePolicyNumberCallback(values.policy_number, carrier_id, null, null)
                  }
                  ordered
                  disabled
                />
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const getPremiumWarning = () => {
          if (parseFloat(values.premium) > 9999) {
            return 'Premium is over 9,999$';
          }

          if (
            values.deductible_type === DEFAULT_DEDUCTIBLE_TYPE &&
            parseFloat(values.deductible_value) === parseFloat(values.premium)
          ) {
            return 'Premium equals deductible';
          }

          return '';
        };
        const premiumField = (
          <Col md="6">
            <Label htmlFor="policy-editor-premium-input">Premium *</Label>
            <FormGroup row>
              <ErrorCol error={errors.premium} warning={getPremiumWarning()} when={showFieldError('premium')}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Field
                    id="policy-editor-premium-input"
                    name="premium"
                    component={MoneyInput}
                    withAddOn
                    onBlur={(_name: string, value: string) => {
                      if (
                        Number(value) >= helpers.TOP_PREMIUM_AMOUNT &&
                        Number(value) !== confirmedPremiumRef.current
                      ) {
                        setShowPremiumConfirmation(true);
                      }
                    }}
                  />
                </InputGroup>
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const stateField = (
          <Col md="6">
            <Label htmlFor="policy-editor-state-select">State *</Label>
            <FormGroup row>
              <ErrorCol error={errors.state} when={showFieldError('state')}>
                <Field
                  inputId="policy-editor-state-select"
                  name="state"
                  component={Select}
                  options={Translations.usaStates}
                  disabled
                />
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const assetsField = doesPolicyTypeRequireAsset(values.policy_type) && (
          <Col md="12">
            <Label htmlFor="policy-editor-assets-select">
              {isOnlyOneAssetSupported(values.policy_type) ? 'Asset' : 'Assets'}
            </Label>
            <FormGroup row>
              <Col>
                <Field
                  inputId="policy-editor-assets-select"
                  name={isOnlyOneAssetSupported(values.policy_type) ? 'asset_gid' : 'asset_gids'}
                  component={isOnlyOneAssetSupported(values.policy_type) ? Select : MultiSelect}
                  className="fs-mask qa-select-asset"
                  options={assetsForPolicyType(values.policy_type, assets).map(asset => ({
                    value: asset.description,
                    key: asset.gid
                  }))}
                  disabled
                />
              </Col>
            </FormGroup>
          </Col>
        );

        const transactionTypeField = (
          <Col md="12">
            <Label htmlFor="transaction_type">Transaction Type *</Label>
            <FormGroup row>
              <ErrorCol error={errors.transaction_type} when={showFieldError('transaction_type')}>
                <Row className="ml-1 mb-4">
                  <Col md={Math.floor(12 / Translations.paymentMethodOptions.length - 1)} className="d-flex mb-2">
                    <Field
                      id="policy-editor-transaction-type-select-0"
                      name="transaction_type"
                      type="radio"
                      value={PolicyTransactionType.RENEWAL}
                      disabled
                    />
                    <Label htmlFor="policy-editor-transaction-type-select-0" className="ml-2 mb-0">
                      {PolicyTransactionType.RENEWAL}
                    </Label>
                  </Col>
                </Row>
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const newClosingField = (
          <Col md="12">
            <Label htmlFor="new_closing">New closing</Label>
            <FormGroup row>
              <ErrorCol error={errors.new_closing} when={showFieldError('new_closing')}>
                <Row className="ml-1 mb-4">
                  {Translations.newClosingOptions.map((option, index) => (
                    <Col
                      key={option.value}
                      md={Math.floor(12 / Translations.newClosingOptions.length - 1)}
                      className="d-flex mb-2"
                    >
                      <Field
                        id={`policy-editor-new-closing-select-${index}`}
                        name="new_closing"
                        type="radio"
                        value={option.key}
                      />
                      <Label className="ml-2" htmlFor={`policy-editor-new-closing-select-${index}`}>
                        {option.value}
                      </Label>
                    </Col>
                  ))}
                </Row>
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const billingTypeField = (
          <Col md="12">
            <Label>Billing type</Label>
            <FormGroup row>
              <ErrorCol error={errors.payment_method} when={showFieldError('payment_method')}>
                <Row className="ml-1 mb-4">
                  {Translations.paymentMethodOptions.map((option: any, index: number) => (
                    <Col
                      key={option.value}
                      md={Math.floor(12 / Translations.paymentMethodOptions.length - 1)}
                      className="d-flex mb-2"
                    >
                      <Field
                        id={`policy-editor-payment-method-select-${index}`}
                        name="payment_method"
                        type="radio"
                        value={option.key}
                        disabled
                      />
                      <Label className="ml-2" htmlFor={`policy-editor-payment-method-select-${index}`}>
                        {option.value}
                      </Label>
                    </Col>
                  ))}
                </Row>
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const numberField = (
          <Col md="6">
            <Label htmlFor="policy-editor-policy-number-input">Policy Number</Label>
            {!policyNumberValidation.valid && (
              <>
                <InfoIcon
                  className="ml-1"
                  height={16}
                  width={16}
                  data-for="number-field-tip"
                  data-tip
                  data-event="click"
                  color={colors.grey60}
                />
                <NewTooltip id="number-field-tip" isCapture clickable globalEventOff="click">
                  <span>
                    {!values.carrier_id && 'Choose carrier to have more info'}
                    {values.carrier_id &&
                      policyNumberValidation.sample &&
                      `Number format should be like: ${policyNumberValidation.sample}`}
                    {values.carrier_id && !policyNumberValidation.sample && POLICY_NUMBER_GENERIC_MESSAGE}
                  </span>
                </NewTooltip>
              </>
            )}
            <FormGroup row className="fs-mask">
              <ErrorCol
                error={errors.policy_number}
                warning={
                  !errors.policy_number &&
                  policyNumberValidation &&
                  !policyNumberValidation.valid &&
                  POLICY_NUMBER_INVALID_WARNING
                }
                when={showFieldError('policy_number')}
              >
                <CopyField value={values.policy_number}>
                  <Field
                    id="policy-editor-policy-number-input"
                    name="policy_number"
                    component={Input}
                    placeholder={policyNumberValidation?.sample && `E.g.<${policyNumberValidation.sample}>`}
                    onChange={(policy_number: string) =>
                      validatePolicyNumberCallback(policy_number, values.carrier_id, null, null)
                    }
                  />
                </CopyField>
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const cancellationReasonField = (
          <Col md="6">
            <Label htmlFor="policy-editor-cancellation-reason-select">Cancel reason *</Label>
            <FormGroup row>
              <ErrorCol error={errors.cancellation_reason} when={showFieldError('cancellation_reason')}>
                <Field
                  inputId="policy-editor-cancellation-reason-select"
                  name="cancellation_reason"
                  component={Select}
                  options={Translations.policyCancellationReasonOptions}
                />
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const cancellationDateField = (
          <Col md="6">
            <Label htmlFor="policy-editor-cancellation-datepicker">Cancel date *</Label>
            <FormGroup row>
              <ErrorCol error={errors.cancellation_date} when={showFieldError('cancellation_date')}>
                <Field
                  id="policy-editor-cancellation-datepicker"
                  name="cancellation_date"
                  component={DatePicker}
                  maxDate={moment().add(10, 'years').toDate()}
                  minDate={moment().subtract(10, 'years').toDate()}
                />
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const saleDateField = (
          <Col md="6">
            <Label htmlFor="policy-editor-sale-date-datepicker">Sale Date</Label>
            <FormGroup row>
              <ErrorCol error={errors.sale_date} when={showFieldError('sale_date')}>
                <Field
                  id="policy-editor-sale-date-datepicker"
                  name="sale_date"
                  component={DatePicker}
                  disabled
                  maxDate={getTodayDate()}
                  minDate={moment().subtract(10, 'years').toDate()}
                />
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const periodField = (
          <Col md="6">
            <Label htmlFor="period">Policy Period</Label>
            <FormGroup row>
              <ErrorCol error={errors.period} when={showFieldError('period')}>
                <Field
                  name="period"
                  component={TimePeriod}
                  period={values.period}
                  possiblePeriods={possiblePolicyTerms(values.policy_type)}
                />
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const effectiveExpirationDateFields = (
          <Col md="12">
            <Label htmlFor="policy-editor-effective-date-datepicker">Policy Term</Label>
            <FormGroup row>
              <ErrorCol
                error={errors.effective_date}
                warning={
                  !errors.effective_date && helpers.isValidEffectiveDate(values) && EFFECTIVE_DATE_INVALID_WARNING
                }
                when={showFieldError('effective_date') || helpers.isValidEffectiveDate(values)}
              >
                <Field
                  id="policy-editor-effective-date-datepicker"
                  name="time_term"
                  component={TimeTerm}
                  startDateName="effective_date"
                  endDateName="expiration_date"
                  startDate={values.effective_date}
                  endDate={values.expiration_date}
                  period={values.period}
                  maxDate={moment().add(6, 'months').toDate()}
                />
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const agentIdField = (
          <Col md="12">
            <Col md="6" className="pl-0">
              <Label htmlFor="policy-editor-agent-of-record-select">Agent of record</Label>
              <FormGroup
                row
                className={classNames('ml-0', { disabled: !doesPolicyTypeSupportAgentOfRecord(values.policy_type) })}
              >
                <ErrorCol className="pl-0" error={errors.agent_id} when={showFieldError('agent_id')}>
                  <Field
                    inputId="policy-editor-agent-of-record-select"
                    name="agent_id"
                    component={Select}
                    disabled={!doesPolicyTypeSupportAgentOfRecord(values.policy_type)}
                    options={assignees}
                    valueName="id"
                    labelName="name"
                    className="qa-agent-id"
                  />
                </ErrorCol>
              </FormGroup>
            </Col>
          </Col>
        );

        const primaryCoverageField = (
          <Col md="6">
            <Label htmlFor="policy-editor-primary-coverage-input">Primary coverage *</Label>
            <FormGroup row className={classNames({ disabled: !doesPolicyTypeHavePrimaryCoverage(values.policy_type) })}>
              <ErrorCol error={errors.primary_coverage} when={showFieldError('primary_coverage')}>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                  <Field
                    id="policy-editor-primary-coverage-input"
                    name="primary_coverage"
                    component={MoneyInput}
                    disabled={!doesPolicyTypeHavePrimaryCoverage(values.policy_type)}
                    maxLength={DWELLING_COVERAGE_MAX_LENGTH}
                    intOnly
                    withAddOn
                  />
                </InputGroup>
              </ErrorCol>
            </FormGroup>
          </Col>
        );

        const deductibleField = (
          <Col md="6">
            <Label htmlFor="policy-editor-deductible-input">Deductible</Label>
            <FormGroup row>
              <Col>
                <InputGroup>
                  <Field
                    name="deductible_type"
                    component={TypeAddOn}
                    options={Translations.deductibleTypeOptions}
                    onChange={() => setFieldValue('deductible_value', '')}
                  />
                  <Field
                    id="policy-editor-deductible-input"
                    name="deductible_value"
                    component={MoneyInput}
                    percentageMode={values.deductible_type === PERCENTAGE_DEDUCTIBLE_TYPE}
                    positiveOnly
                    withAddOn
                  />
                </InputGroup>
              </Col>
            </FormGroup>
          </Col>
        );

        const showNewClosing = doesPolicyTypeSupportNewClosing(policy.policy_type);

        return (
          <div className="d-flex">
            <Col md="6">
              <Row className="mb-4">
                <Col md="12">
                  <PolicyShortInfo policy={policy} />
                </Col>
              </Row>
              <Row>
                {carrieIdField}
                {premiumField}
                {stateField}
                {assetsField}
                {showNewClosing && newClosingField}
                {transactionTypeField}
                {billingTypeField}
                {numberField}
                <Col md="6" />
                {helpers.isStatusLeadsToCancellation(values.status) && (
                  <>
                    {cancellationReasonField}
                    {cancellationDateField}
                  </>
                )}
                {saleDateField}
                {periodField}
                {effectiveExpirationDateFields}
                {agentIdField}
                {primaryCoverageField}
                {deductibleField}
              </Row>
            </Col>
            <Col md="1" />
            {showPremiumConfirmation && (
              <FormModal
                confirmText="Confirm"
                cancelText="It's mistake"
                title="You entered premium that exceeds $50,000"
                initialValues={{ premium_confirmation: '' }}
                validationSchema={yup.object().shape({
                  premium_confirmation: requiredField
                })}
                confirmHandler={({ premium_confirmation }) => {
                  setFieldValue('premium', premium_confirmation);
                  setShowPremiumConfirmation(false);
                  confirmedPremiumRef.current = premium_confirmation;
                }}
                cancelHandler={() => {
                  setFieldValue('premium', '');
                  setShowPremiumConfirmation(false);
                }}
                renderForm={() => (
                  <NumericField
                    label="Confirm premium"
                    required
                    id="premium_confirmation"
                    name="premium_confirmation"
                    valueIsNumber
                    prefixIcon={<DollarIcon />}
                  />
                )}
              />
            )}
          </div>
        );
      }}
    />
  );
};

export default PolicyRenewer;
