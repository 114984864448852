/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Button from '../../../../components/core/buttons/Button';
import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import PersonSearchInput from '../../../../components/core/inputs/PersonSearch';
import useEventListener from '../../../../hooks/useEventListener';

interface PersonSearchProps {
  onSearch: (searchBy: string, term: string) => void;
}

const PersonSearch: React.FC<PersonSearchProps> = ({ onSearch }): JSX.Element => {
  const [term, setTerm] = React.useState<null | string>();
  const [searchBy, setSearchBy] = React.useState<string>('all');

  useEventListener('keydown', event => {
    if (event.key === 'Enter' && term) {
      onSearch(searchBy, term);
    }
  });

  return (
    <div
      css={css`
        margin-bottom: 16px;
      `}
    >
      <Heading type="h4">Add customer info</Heading>
      <div
        css={css`
          margin-top: 8px;
        `}
      >
        <FlexBox>
          <PersonSearchInput
            className="fs-mask"
            placeholder="Search by email, phone number, loan number, customer name, address"
            customCss={css`
              width: 580px;
            `}
            onChange={event => setTerm(event.target.value)}
            searchBy={searchBy}
            setSearchBy={setSearchBy}
          />
          <Button
            disabled={!term}
            onClick={() => onSearch(searchBy, term as string)}
            customCss={css`
              margin-left: 8px;
              padding: 4px 32px;
            `}
          >
            Search
          </Button>
        </FlexBox>
      </div>
    </div>
  );
};

export default PersonSearch;
