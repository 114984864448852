/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { moneyFormatter } from '../../../utils/formatter';
import { IQuotingConfigurationOpportunity } from './_interfaces/IQuotingConfigurationOpportunity';
import { getCoverageValue } from './_utils';

const OpportunityCustomerChoice = ({ opportunity }: { opportunity: IQuotingConfigurationOpportunity }) => {
  if (!opportunity.customerChoice) {
    return null;
  }

  const {
    attributes: { premium, coverages: rawCoverages, carrier }
  } = opportunity.customerChoice;

  const coverages = Object.values(rawCoverages || {});
  const dwelling = getCoverageValue(coverages, PolicyCoverageKey.Dwelling, 'limit_description');
  const deductible = getCoverageValue(coverages, PolicyCoverageKey.Dwelling, 'deductible_description');
  const bodilyInjuryPerPerson = getCoverageValue(coverages, PolicyCoverageKey.BiPerPerson, 'limit_value');
  const bodilyInjuryPerAccident = getCoverageValue(coverages, PolicyCoverageKey.BiPerAccident, 'limit_value');
  const propertyDamage = getCoverageValue(coverages, PolicyCoverageKey.PropertyDamage, 'limit_value');

  return (
    <div>
      <FlexBox
        columnDirection
        p={spacings.px12}
        gap={spacings.px12}
        border
        borderColor={colors.grey30}
        roundBorder
        boxShadow
        customCss={css`
          height: auto;
          width: 242px;
          min-width: 242px;
        `}
      >
        <Text bold>Customer’s choice online</Text>
        <FlexBox columnDirection gap={spacings.px6}>
          <FlexBox justifySpaceBetween>
            <Text type="small" color={colors.grey60}>
              Carrier
            </Text>
            <Text
              type="small"
              customCss={css`
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 160px;
                text-align: end;
              `}
            >
              {carrier.name}
            </Text>
          </FlexBox>
          {!!premium && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Premium
              </Text>
              <Text type="small">{moneyFormatter(premium, true)}</Text>
            </FlexBox>
          )}
          {!!dwelling && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Dwelling
              </Text>
              <Text type="small">{dwelling}</Text>
            </FlexBox>
          )}
          {!!deductible && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Deductible
              </Text>
              <Text type="small">{deductible}</Text>
            </FlexBox>
          )}
          {!!(bodilyInjuryPerPerson && bodilyInjuryPerAccident) && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Liability
              </Text>
              <Text type="small">{`${moneyFormatter(bodilyInjuryPerPerson, true, 'compact')} / ${moneyFormatter(
                bodilyInjuryPerAccident,
                true,
                'compact'
              )}`}</Text>
            </FlexBox>
          )}
          {!!propertyDamage && (
            <FlexBox justifySpaceBetween>
              <Text type="small" color={colors.grey60}>
                Property damage
              </Text>
              <Text type="small">{`${moneyFormatter(propertyDamage, true, 'compact')}`}</Text>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </div>
  );
};

export default OpportunityCustomerChoice;
