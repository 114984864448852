import queryString from 'query-string';

const PEOPLE_SEARCH_BASE_URL = '/people_search';

export enum PeopleSearchOpenedFrom {
  ScoutingReport = 'scouting_report',
  Header = 'header',
  GlobalNavigation = 'global_navigation'
}

export interface LocationState {
  openedFrom?: PeopleSearchOpenedFrom;
}

interface PeopleSearchQueryParams {
  term?: string;
  search_by?: string;
  call_log_id?: string | number;
}

export const readQueryParams = (searchParams: URLSearchParams): PeopleSearchQueryParams => {
  return {
    term: searchParams.get('term') || undefined,
    search_by: searchParams.get('search_by') || undefined,
    call_log_id: searchParams.get('call_log_id') || undefined
  };
};

const peopleSearchNavigation = {
  fromExternalSource: ({ term }: { term: string }): string => {
    const query = { term };

    return queryString.stringifyUrl({ url: PEOPLE_SEARCH_BASE_URL, query });
  },

  fromHeaderSearch: ({ term, search_by }: { term: string; search_by: string }): string => {
    const query = { term, search_by };

    return queryString.stringifyUrl({ url: PEOPLE_SEARCH_BASE_URL, query });
  },

  fromScoutingReport: ({ term, search_by, call_log_id }: PeopleSearchQueryParams): string => {
    const query = {
      term,
      search_by,
      call_log_id
    };

    return queryString.stringifyUrl({ url: PEOPLE_SEARCH_BASE_URL, query });
  },

  fromGlobalNavigation: (): string => {
    return queryString.stringifyUrl({ url: PEOPLE_SEARCH_BASE_URL });
  }
};

export default peopleSearchNavigation;
