import { useQueryClient } from '@tanstack/react-query';

import { useCallState } from '../../../contexts/CallContext';
import useQueryParams from '../../../hooks/useQueryParams';
import {
  ICallLog,
  ICandidate,
  ICustomerDataCompleteness,
  ILead,
  IPerson,
  ISourceDimensions
} from '../../../interfaces';
import { InsuranceCategory } from '../../../interfaces/IPolicyType';
import { CALL_LOG_QUERY_KEY, useScoutingCallLog } from '../../../queries/call_logs/useCallLog';
import useCandidate from '../../../queries/candidates/useCandidate';
import useCustomerDataCompleteness from '../../../queries/leads/data_collection/useCustomerDataCompleteness';
import useLead from '../../../queries/leads/useLead';
import usePerson from '../../../queries/people/usePerson';
import { readQueryParams } from '../navigation';

export interface GuidedSellingExperienceData {
  callLogId?: number;
  callLog?: ICallLog;
  personGid?: string;
  person?: IPerson;
  leadGid?: string;
  lead?: ILead;
  candidateGid?: string;
  candidate?: ICandidate;
  sourceDimensions?: ISourceDimensions;
  customerDataCompleteness?: ICustomerDataCompleteness;
  insuranceCategory?: InsuranceCategory;
  isLoading: boolean;
  isPersonLinkedToActiveCall: boolean;
  isLifeLead?: boolean;
  refetchCallLog: () => Promise<any>;
  refetchPerson: () => Promise<any>;
}

const useGuidedSellingExperienceData = (): GuidedSellingExperienceData => {
  const searchParams = useQueryParams();

  const {
    call_log_id,
    person_gid: openedFromPersonGid,
    candidate_gid: openedFromCandidateGid,
    lead_gid: openedFromLeadGid
  } = readQueryParams(searchParams);

  const callLogId = call_log_id ? Number(call_log_id) : undefined;

  const { data: callLog, isLoading: isLoadingCallLog, refetch: refetchCallLog } = useScoutingCallLog(callLogId);
  const { data: lead, isLoading: isLoadingLead } = useLead(openedFromLeadGid || callLog?.lead_gid);
  const { data: candidate, isLoading: isLoadingCandidate } = useCandidate(
    openedFromCandidateGid || callLog?.candidate_gid
  );

  const personGid = (() => {
    if (openedFromPersonGid) {
      return openedFromPersonGid;
    }

    if (openedFromLeadGid) {
      return lead?.person_gid;
    }

    if (openedFromCandidateGid) {
      return candidate?.person_gid;
    }

    return callLog?.person_gid;
  })();

  const engagementGid = (() => {
    if (openedFromPersonGid) {
      return undefined;
    }

    if (openedFromLeadGid) {
      return lead?.gid;
    }

    if (openedFromCandidateGid) {
      return candidate?.gid;
    }

    return callLog?.lead_gid || callLog?.candidate_gid;
  })();

  const { data: person, isLoading: isLoadingPerson, refetch: refetchPerson } = usePerson(personGid);

  const { data: customerDataCompleteness, isLoading: isLoadingCustomerDataCompleteness } = useCustomerDataCompleteness({
    personGid,
    engagementGid
  });

  const queryClient = useQueryClient();
  const { currentCallId } = useCallState();

  const isPersonLinkedToActiveCall = (function () {
    if (!currentCallId) {
      return true;
    }

    // CallLog from Indicator
    const queryData = queryClient.getQueryData<{ call_log: ICallLog }>([CALL_LOG_QUERY_KEY, currentCallId]);

    return !!queryData?.call_log?.person_gid && queryData.call_log.person_gid === personGid;
  })();

  const insuranceCategory = (lead || candidate || callLog)?.insurance_category;
  const isLifeLead = insuranceCategory === InsuranceCategory.Life;
  const commonParams = { isPersonLinkedToActiveCall, refetchCallLog, refetchPerson, insuranceCategory, isLifeLead };

  if (openedFromPersonGid) {
    return {
      callLogId,
      callLog,
      personGid,
      person,
      sourceDimensions: callLog?.source_dimensions || undefined,
      isLoading: isLoadingPerson || isLoadingCustomerDataCompleteness,
      ...commonParams
    };
  }

  if (openedFromLeadGid) {
    return {
      callLogId,
      callLog,
      personGid,
      person,
      leadGid: openedFromLeadGid,
      lead,
      sourceDimensions: lead?.source_dimensions || callLog?.source_dimensions,
      customerDataCompleteness,
      isLoading: isLoadingLead || isLoadingPerson || isLoadingCustomerDataCompleteness,
      ...commonParams
    };
  }

  if (openedFromCandidateGid) {
    return {
      callLogId,
      callLog,
      personGid,
      person,
      candidateGid: openedFromCandidateGid,
      candidate,
      sourceDimensions: callLog?.source_dimensions || candidate?.source_dimensions,
      customerDataCompleteness,
      isLoading: isLoadingCandidate || isLoadingPerson,
      ...commonParams
    };
  }

  return {
    callLogId,
    callLog,
    personGid,
    person,
    leadGid: callLog?.lead_gid,
    lead,
    candidateGid: callLog?.candidate_gid,
    candidate,
    sourceDimensions: callLog?.source_dimensions || undefined,
    customerDataCompleteness,
    isLoading:
      isLoadingCallLog || isLoadingLead || isLoadingCandidate || isLoadingPerson || isLoadingCustomerDataCompleteness,
    ...commonParams
  };
};

export default useGuidedSellingExperienceData;
