/** @jsxImportSource @emotion/react */
import { ClassNames, css } from '@emotion/react';
import React from 'react';

import Copy from '../../../../../components/core/Copy';
import FlexBox from '../../../../../components/core/FlexBox';
import QuestionVerificationStatusIcon from '../../../../../components/core/QuestionVerificationStatusIcon';
import TelephonyActions from '../../../../../components/core/TelephonyActions';
import TelephonyTooltip from '../../../../../components/core/TelephonyTooltip';
import Text from '../../../../../components/core/Text';
import { Translations } from '../../../../../constants';
import featureFlags from '../../../../../constants/featureFlags';
import { IPerson, IRelatedPerson } from '../../../../../interfaces';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import { useActiveTCPAConsent } from '../../../../../queries/disclosures/useDisclosure';
import usePersonDNC from '../../../../../queries/people/dncStatus';
import authInfo from '../../../../../services/authInfo';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { dateFormatter, phoneFormatter } from '../../../../../utils/formatter';
import { useAnswersWithVerificationStatus } from '../../../_hooks';
import CopyableAnswer from '../_components/CopyableAnswer';
import DOBWrapper from '../_components/DOBWrapper';
import EmptyAnswer from '../_components/EmptyAnswer';
import QuestionAnswerPair from '../_components/QuestionAnswerPair';

interface PersonInfoProps {
  person: IPerson | IRelatedPerson;
  testId: string;
  primaryCustomer?: boolean;
  primaryCustomerGid: string;
  header: React.ReactNode;
  expanded: boolean;
  answers: ReturnType<typeof useAnswersWithVerificationStatus>[string];
}

const PersonInfo = ({
  person,
  testId,
  primaryCustomerGid,
  primaryCustomer = false,
  header,
  expanded,
  answers
}: PersonInfoProps) => {
  const { data: doNotContact, isSuccess } = usePersonDNC(person.gid);
  const { data: tcpaConsent } = useActiveTCPAConsent(person.gid);
  const canCallWithDNC = authInfo.features.do_not_contact_call_available;

  return (
    <FlexBox
      key={person.gid}
      columnDirection
      gap={spacings.px8}
      customCss={css`
        flex: 1 0 auto;
        @media (max-width: 800px) {
          flex: 1 0 100%;
        }
      `}
    >
      {header}

      <FlexBox gap={spacings.px8} columnDirection pl={spacings.px28}>
        {(expanded || primaryCustomer) && (
          <>
            {person.phone && isSuccess && (
              <>
                <QuestionAnswerPair
                  questionLabel={
                    <Text type="small" color={colors.grey60}>
                      Phone
                    </Text>
                  }
                  answerLabel={
                    doNotContact && !canCallWithDNC ? (
                      <Text type="small" color={colors.statusRed} data-testid="do-not-contact">
                        Do not contact
                      </Text>
                    ) : (
                      <TelephonyTooltip phone={person.phone} personGid={primaryCustomerGid}>
                        {answers[DatapointKey.PersonPhone] && (
                          <QuestionVerificationStatusIcon
                            verificationStatus={answers[DatapointKey.PersonPhone].verificationStatus}
                          />
                        )}
                        <ClassNames>
                          {({ css }) => (
                            <Copy
                              type="small"
                              className={
                                doNotContact && canCallWithDNC
                                  ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                  : 'fs-mask'
                              }
                              value={phoneFormatter(person.phone)}
                              testId="collect-info-tab-customer-profile-results-phone"
                            >
                              {phoneFormatter(person.phone)}
                            </Copy>
                          )}
                        </ClassNames>
                      </TelephonyTooltip>
                    )
                  }
                />
                {!tcpaConsent && featureFlags.displayTCPAConsent && (
                  <QuestionAnswerPair
                    questionLabel={<></>}
                    answerLabel={
                      <Text color={colors.statusOrange} type="small">
                        No TCPA consent obtained
                      </Text>
                    }
                  />
                )}
              </>
            )}

            {expanded && person.secondary_phone && isSuccess && (
              <QuestionAnswerPair
                questionLabel={
                  <Text type="small" color={colors.grey60}>
                    Second phone
                  </Text>
                }
                answerLabel={
                  doNotContact && !canCallWithDNC ? (
                    <Text type="small" color={colors.statusRed} data-testid="do-not-contact">
                      Do not contact
                    </Text>
                  ) : (
                    <TelephonyTooltip phone={person.secondary_phone} personGid={primaryCustomerGid}>
                      {answers[DatapointKey.PersonPhoneSecondary] && (
                        <QuestionVerificationStatusIcon
                          verificationStatus={answers[DatapointKey.PersonPhoneSecondary].verificationStatus}
                        />
                      )}
                      <ClassNames>
                        {({ css }) => (
                          <Copy
                            type="small"
                            className={
                              doNotContact && canCallWithDNC
                                ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                : 'fs-mask'
                            }
                            value={phoneFormatter(person.secondary_phone)}
                            testId="collect-info-tab-customer-profile-results-secondary_phone"
                          >
                            {phoneFormatter(person.secondary_phone)}
                          </Copy>
                        )}
                      </ClassNames>
                    </TelephonyTooltip>
                  )
                }
              />
            )}

            {expanded && person.business_phone && isSuccess && (
              <QuestionAnswerPair
                questionLabel={
                  <Text type="small" color={colors.grey60}>
                    Business phone
                  </Text>
                }
                answerLabel={
                  doNotContact && !canCallWithDNC ? (
                    <Text type="small" color={colors.statusRed} data-testid="do-not-contact">
                      Do not contact
                    </Text>
                  ) : (
                    <TelephonyTooltip phone={person.business_phone} personGid={primaryCustomerGid}>
                      {answers[DatapointKey.PersonPhoneBusiness] && (
                        <QuestionVerificationStatusIcon
                          verificationStatus={answers[DatapointKey.PersonPhoneBusiness].verificationStatus}
                        />
                      )}
                      <ClassNames>
                        {({ css }) => (
                          <Copy
                            type="small"
                            className={
                              doNotContact && canCallWithDNC
                                ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                                : 'fs-mask'
                            }
                            value={phoneFormatter(person.business_phone)}
                            testId="collect-info-tab-customer-profile-results-business_phone"
                          >
                            {phoneFormatter(person.business_phone)}
                          </Copy>
                        )}
                      </ClassNames>
                    </TelephonyTooltip>
                  )
                }
              />
            )}
          </>
        )}

        {(expanded || primaryCustomer) && (
          <>
            {isSuccess && (
              <QuestionAnswerPair
                questionLabel={
                  <Text type="small" color={colors.grey60}>
                    Email
                  </Text>
                }
                answerLabel={
                  <>
                    {doNotContact && !canCallWithDNC ? (
                      <Text type="small" color={colors.statusRed} data-testid="do-not-contact">
                        Do not contact
                      </Text>
                    ) : (
                      <>
                        {person.email && (
                          <TelephonyActions email={person.email} personGid={primaryCustomerGid}>
                            {answers[DatapointKey.PersonEmail] && (
                              <QuestionVerificationStatusIcon
                                verificationStatus={answers[DatapointKey.PersonEmail].verificationStatus}
                              />
                            )}
                            <CopyableAnswer
                              withIcon={false}
                              textType="small"
                              fsMask
                              value={person.email}
                              customCss={
                                doNotContact && canCallWithDNC
                                  ? css`
                                      color: ${colors.statusRed} !important;
                                    `
                                  : undefined
                              }
                              testId={`${testId}-email`}
                            />
                          </TelephonyActions>
                        )}
                        {!person.email && <EmptyAnswer textType="small" />}
                      </>
                    )}
                  </>
                }
              />
            )}
          </>
        )}

        <QuestionAnswerPair
          questionLabel={
            <Text type="small" color={colors.grey60}>
              DOB
            </Text>
          }
          answerLabel={
            person.date_of_birth ? (
              <>
                {answers[DatapointKey.PersonDateOfBirth] && (
                  <QuestionVerificationStatusIcon
                    verificationStatus={answers[DatapointKey.PersonDateOfBirth].verificationStatus}
                  />
                )}
                <DOBWrapper>
                  <CopyableAnswer
                    textType="small"
                    fsMask
                    value={dateFormatter(person.date_of_birth)}
                    testId={`${testId}-dob`}
                  />
                </DOBWrapper>
              </>
            ) : (
              <EmptyAnswer textType="small" />
            )
          }
        />

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                Gender
              </Text>
            }
            answerLabel={
              person.gender ? (
                <>
                  {answers[DatapointKey.PersonGender] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonGender].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-gender`}
                    value={Translations.gender(person.gender)}
                  />
                </>
              ) : (
                <EmptyAnswer textType="small" />
              )
            }
          />
        )}

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                Marital status
              </Text>
            }
            answerLabel={
              person.marital_status ? (
                <>
                  {answers[DatapointKey.PersonMaritalStatus] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonMaritalStatus].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-marital-status`}
                    value={Translations.maritalStatus(person.marital_status)}
                  />
                </>
              ) : (
                <EmptyAnswer textType="small" />
              )
            }
          />
        )}

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                License number
              </Text>
            }
            answerLabel={
              person.license_number ? (
                <>
                  {answers[DatapointKey.PersonLicenseNumber] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonLicenseNumber].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-license-number`}
                    fsMask
                    value={person.license_number}
                  />
                </>
              ) : (
                <EmptyAnswer textType="small" />
              )
            }
          />
        )}

        {expanded && (
          <QuestionAnswerPair
            questionLabel={
              <Text type="small" color={colors.grey60}>
                License state
              </Text>
            }
            answerLabel={
              person.license_state ? (
                <>
                  {answers[DatapointKey.PersonLicenseState] && (
                    <QuestionVerificationStatusIcon
                      verificationStatus={answers[DatapointKey.PersonLicenseState].verificationStatus}
                    />
                  )}
                  <CopyableAnswer
                    textType="small"
                    testId={`${testId}-license-state`}
                    value={Translations.usaState(person.license_state)}
                  />
                </>
              ) : (
                <EmptyAnswer textType="small" />
              )
            }
          />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default PersonInfo;
