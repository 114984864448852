import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { CarIcon } from '../../../components/core/icons';
import IconWithText from '../../../components/core/IconWithText';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import usePolicyVehicles from '../../../queries/policies/usePolicyVehicles';
import { spacings } from '../../../theme/variables';
import AssetDetailsPlaceholder from '../AssetDetailsPlaceholder';
import VehicleInfo from '../VehicleInfo';

const SyncedPolicyVehicles = ({
  lineOfBusiness,
  lastTransaction
}: {
  lineOfBusiness: LineOfBusiness;
  lastTransaction: PolicyTransaction;
}): JSX.Element => {
  const { data: vehicles, isLoading } = usePolicyVehicles(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id
  );
  if (isLoading) {
    return <AssetDetailsPlaceholder />;
  }

  return (
    <>
      {Boolean(vehicles?.length) && (
        <FlexBox columnDirection>
          <FlexBox justifySpaceBetween alignItemsCenter>
            <IconWithText text={`${findPolicyTypeLabel(lineOfBusiness.policy_type) || lineOfBusiness.acord_label}`}>
              <CarIcon />
            </IconWithText>
          </FlexBox>
          <FlexBox pt={spacings.px12} mh={spacings.px32} columnDirection>
            {vehicles?.map(vehicle => <VehicleInfo vehicle={vehicle} key={vehicle.vin} />)}
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default SyncedPolicyVehicles;
