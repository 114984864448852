/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { API_TO_SMARTY_STREETS_MAPPING } from '../../constants/addressForm';
import { IAddressSuggestion } from '../../interfaces';
import { NewPolicyOptions } from '../../interfaces/INewPolicyOptions';
import { opportunityDescription } from '../../interfaces/IOpportunity';
import usePersonAssets from '../../queries/people/person_assets/usePersonAssets';
import usePersonAddresses from '../../queries/people/usePersonAddresses';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { renameKeys } from '../../utils/object';
import {
  HomeFormRenderer,
  initialValuesWithPolicy as homeInitialValuesWithPolicy,
  validationSchema as homeValidationSchema
} from '../AddHomeForm';
import { ButtonVariant } from '../core/buttons/Button';
import CollapsingContainer from '../core/CollapsingContainer';
import Container from '../core/Container';
import FlexBox from '../core/FlexBox';
import BaseForm from '../core/forms/BaseForm';
import Heading from '../core/Heading';
import { HomeIcon } from '../core/icons';
import Modal, { ModalSize } from '../core/Modal';
import Paragraph, { ParagraphType } from '../core/Paragraph';
import Text from '../core/Text';
import { buildInitialValues, usePriorPolicyEditorSubmit, validationSchema as mainSchema } from './helpers';
import MainForm from './MainForm';

interface PriorPolicyWithFormHomeProps {
  personGid: string;
  policyType?: NewPolicyOptions['policyType'];
  assets?: NewPolicyOptions['assets'] | null;
  cancelHandler: () => void;
  confirmHandler: Parameters<typeof usePriorPolicyEditorSubmit>[0]['confirmHandler'];
  leadGid: string | undefined;
}

const PriorPolicyWithHomeForm = ({
  personGid,
  policyType,
  assets,
  cancelHandler,
  confirmHandler,
  leadGid
}: PriorPolicyWithFormHomeProps) => {
  const [displayErrors, setDisplayErrors] = React.useState(false);
  const assetsGids = assets?.map(({ gid }) => gid);
  const submitHandler = usePriorPolicyEditorSubmit({
    personGid,
    priorPolicy: undefined,
    confirmHandler,
    policyType,
    assetsGids,
    leadGid
  });

  const { data: personAssets, isSuccess: isPersonAssetsSucceed } = usePersonAssets({ personGid, policyType });
  const { data: personAddresses, isSuccess: isPersonAddressesSucceed } = usePersonAddresses(personGid);

  const isPersonAssetsAdded = isPersonAssetsSucceed && personAssets.length > 0;

  return (
    <Modal
      headerCss={css`
        align-items: center;
      `}
      header={
        <FlexBox columnDirection>
          <Paragraph type="large" bold>
            Add prior policy details for {policyType || ''}
          </Paragraph>
          {assets && policyType && (
            /* @ts-expect-error: TODO consider using description_without_icon method and allow IPersonAsset */
            <Paragraph className="fs-mask">{opportunityDescription({ policy_type: policyType, assets })}</Paragraph>
          )}
        </FlexBox>
      }
      size={ModalSize.large}
      showCancelButton={false}
      cancelHandler={cancelHandler}
    >
      <BaseForm
        initialValues={{
          ...buildInitialValues({ priorPolicy: undefined, policyType }),
          ...homeInitialValuesWithPolicy(policyType)
        }}
        validationSchema={mainSchema.concat(homeValidationSchema)}
        onSubmit={values => submitHandler(values)}
        cancelHandler={cancelHandler}
        cancelVariant={ButtonVariant.Secondary}
        submitText="Add"
        validate={() => setDisplayErrors(true)}
        loading={!isPersonAssetsSucceed || !isPersonAddressesSucceed}
        pt={16}
        pb={16}
        pl={16}
        pr={16}
        renderForm={formikProps => (
          <>
            <MainForm selectedPolicyType={policyType} formikProps={formikProps} />
            {isPersonAssetsSucceed && isPersonAddressesSucceed ? (
              <>
                {isPersonAssetsAdded && (
                  <>
                    <Container borderBottom borderColor={colors.grey80} />
                    <Paragraph bold pt={spacings.px20}>
                      These homes are already added to the profile
                    </Paragraph>
                    <FlexBox mt={spacings.px8} mb={spacings.px16} columnDirection gap={spacings.px8}>
                      {personAssets?.map(asset => (
                        <FlexBox gap={spacings.px8} alignItemsCenter key={asset.gid}>
                          <HomeIcon />
                          <Text className="fs-mask">{asset.description_without_icon}</Text>
                        </FlexBox>
                      ))}
                    </FlexBox>
                  </>
                )}
                <Container borderBottom borderColor={colors.grey80} />
                <CollapsingContainer
                  pt={spacings.px12}
                  openedByDefault={!isPersonAssetsAdded || displayErrors}
                  title="Add home asset"
                  iconPosition="left"
                  titleSize={ParagraphType.Default}
                  onClick={() => setDisplayErrors(prev => !prev)}
                  key={displayErrors.toString()}
                >
                  <HomeFormRenderer
                    defaultAddressOptions={
                      personAddresses.map(address =>
                        renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
                      ) as IAddressSuggestion[]
                    }
                  />
                </CollapsingContainer>
              </>
            ) : (
              <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
                <FlexBox gap={spacings.px24}>
                  <Heading
                    customCss={css`
                      width: 50%;
                    `}
                  >
                    <Skeleton />
                  </Heading>
                  <Heading
                    customCss={css`
                      width: 50%;
                    `}
                  >
                    <Skeleton />
                  </Heading>
                </FlexBox>
              </SkeletonTheme>
            )}
          </>
        )}
      />
    </Modal>
  );
};

export default PriorPolicyWithHomeForm;
