/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';

import colors from '../../../../theme/colors';
import { defaultFontCss } from '../../../../theme/typography';
import { borderRadius } from '../../../../theme/variables';

export interface TextareaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  resizeForbidden?: boolean;
  height?: number;
  width?: number;
  inline?: boolean;
  customCss?: SerializedStyles;
}

const Textarea = ({ width, height, resizeForbidden, inline, customCss, ...rest }: TextareaProps): JSX.Element => {
  return (
    <textarea
      {...rest}
      css={css`
        ${defaultFontCss}
        padding: 8px;
        width: ${width ? `${width}px` : '100%'};
        min-height: ${height}px;
        ${resizeForbidden ? 'resize: none;' : ''}
        background: ${colors.white};
        border: ${inline ? 'none' : `1px solid ${colors.black}`};
        border-radius: ${borderRadius}px;
        outline-color: ${colors.azure50};
        outline-offset: ${inline ? '1px' : '3px'};

        ::placeholder {
          font-style: italic;
        }

        &:disabled {
          background-color: ${colors.grey10};
          cursor: not-allowed;
        }

        ${customCss}
      `}
      className="fs-mask"
    />
  );
};

export default Textarea;
