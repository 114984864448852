import * as React from 'react';

import colors from '../../theme/colors';
import { Sparkles1, Sparkles2, Sparkles3, Sparkles4 } from './icons';

const Sparkles = ({ type = 'success' }: { type: 'success' | 'warning' }) => {
  const [currentSparkle, setCurrentSparkle] = React.useState(0);
  React.useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSparkle(prevIndex => (prevIndex + 1) % 4);
    }, 180);

    return () => clearInterval(interval);
  }, []);

  const color = type === 'success' ? colors.statusGreen : colors.statusOrange;

  const sparkles = [
    <Sparkles1 color={color} key={Sparkles1.name} />,
    <Sparkles2 color={color} key={Sparkles2.name} />,
    <Sparkles3 color={color} key={Sparkles3.name} />,
    <Sparkles4 color={color} key={Sparkles4.name} />
  ];
  return <span>{sparkles[currentSparkle]}</span>;
};

export default Sparkles;
