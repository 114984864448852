import { useMutation } from '@tanstack/react-query';

import { ILead } from '../../interfaces/ILead';
import api from '../../services/api';

interface IPayload {
  leadGid: string;
  pipelineUserId: number;
}

interface IResult {
  lead: ILead;
}

const moveLeadToPipeline = ({ leadGid, pipelineUserId }: IPayload): Promise<IResult> => {
  return api.post(`/api/frontend/leads/${leadGid}/move_to_pipeline`, {
    body: { pipeline_user_id: pipelineUserId }
  });
};

const useMoveLeadToPipeline = () => useMutation({ mutationFn: moveLeadToPipeline });

export default useMoveLeadToPipeline;
