import Tag from '../../../components/core/Tag';
import { IProductAnnouncementTag } from '../../../interfaces/IProductAnnouncement';
import colors from '../../../theme/colors';

const AnnouncementTag = ({ tag }: { tag: IProductAnnouncementTag }) => {
  switch (tag) {
    case IProductAnnouncementTag.AMP:
      return <Tag label="AMP" textColor={colors.white} backgroundColor={colors.violet} />;
    case IProductAnnouncementTag.Quoting:
      return <Tag label="Quoting" textColor={colors.white} backgroundColor={colors.cherise} />;
    case IProductAnnouncementTag.Telephony:
      return <Tag label="Telephony" textColor={colors.white} backgroundColor={colors.statusOrange} />;
    case IProductAnnouncementTag.News:
      return <Tag label="News" textColor={colors.black} backgroundColor={colors.grey10} />;
    case IProductAnnouncementTag.Features:
      return <Tag label="Features" textColor={colors.black} backgroundColor={colors.grey10} />;
    case IProductAnnouncementTag.TipsAndGuides:
      return <Tag label="Tips and guides" textColor={colors.black} backgroundColor={colors.grey10} />;
    default:
      return <Tag label="AMP" textColor={colors.black} backgroundColor={colors.grey10} />;
  }
};

export default AnnouncementTag;
