import { useMutation } from '@tanstack/react-query';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import api from '../../../services/api';

interface CancelPolicyRequest {
  person_gid: string;
  policy_id: number;

  cancellation_date: string;
  cancellation_reason: string;
}

const cancelPolicy = (data: CancelPolicyRequest): Promise<{ policy: IMaticPolicy }> =>
  api.put(`/api/frontend/people/${data.person_gid}/policies/${data.policy_id}/cancel`, { body: data });

const useCancelPolicy = () => useMutation({ mutationFn: cancelPolicy });

export default useCancelPolicy;
