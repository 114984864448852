import React from 'react';

import useClaimTypes from '../../../../../../queries/claim_types/useClaimTypes';
import IncidentField from '../IncidentField';

const ClaimFieldWithOptions = (props: any) => {
  const { data: claimTypes, isLoading } = useClaimTypes();

  return <IncidentField {...props} loading={isLoading} options={claimTypes} />;
};

export default ClaimFieldWithOptions;
