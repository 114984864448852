/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Checkbox from '../../../../components/core/inputs/Checkbox';
import Paragraph, { ParagraphType } from '../../../../components/core/Paragraph';
import SideBarPopperLayout from '../../../../components/core/SideBarPopperLayout';
import { usePopper, useToggle } from '../../../../hooks';
import { ITask, IUser } from '../../../../interfaces';
import { ILeadDispositions } from '../../../../interfaces/IDisposition';
import { TaskPayload } from '../../../../queries/people/person_tasks/useCreateTask';
import { PERSON_TASKS_QUERY_KEY } from '../../../../queries/people/person_tasks/usePersonTasks';
import useUpdateTask from '../../../../queries/people/person_tasks/useUpdateTask';
import { TASK_REMINDERS_QUERY_KEY } from '../../../../queries/tasks/useTaskReminders';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { capitalize } from '../../../../utils/formatter';
import EditTaskForm from './EditTaskForm';
import { DueDate } from './helpers';

const Task = ({
  personGid,
  task,
  assignees,
  leadDispositions,
  testId
}: {
  personGid: string;
  task: ITask;
  assignees?: IUser[];
  leadDispositions?: ILeadDispositions;
  testId: string;
}): JSX.Element => {
  const queryClient = useQueryClient();
  const [editMode, toggleEditMode] = useToggle(false);
  const [completedTask, toggleCompletedTask] = useToggle(task.completed);
  const { mutate: updateTaskCompletion } = useUpdateTask(() => {
    queryClient.invalidateQueries({ queryKey: [PERSON_TASKS_QUERY_KEY] });
    queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] });
  });

  const { setAnchorEl, anchorEl, triggerPopper, PopperComponent, popperProps } = usePopper({
    placement: 'left',
    disablePortal: false,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      }
    ]
  });

  return (
    <>
      {editMode ? (
        <EditTaskForm
          personGid={personGid}
          task={task}
          toggleEditMode={toggleEditMode}
          leadDispositions={leadDispositions}
          assignees={assignees}
        />
      ) : (
        <FlexBox
          pv={spacings.px8}
          ph={spacings.px12}
          gap={spacings.px8}
          backgroundColor={anchorEl ? colors.grey5 : undefined}
          alignItemsFlexStart
          customCss={css`
            &:hover {
              background-color: ${colors.grey5};
            }
          `}
          onMouseEnter={triggerPopper}
          onMouseLeave={() => setAnchorEl(null)}
          borderBottom
          data-testid={testId}
        >
          <FlexBox fitParentWidth alignItemsFlexStart justifySpaceBetween>
            <FlexBox gap={spacings.px8}>
              <Container p={spacings.px4}>
                <Checkbox
                  label=""
                  id={`${task.id}`}
                  onChange={() => {
                    toggleCompletedTask();
                    updateTaskCompletion({
                      personGid,
                      taskId: task.id,
                      data: { ...task, completed: !task.completed } as TaskPayload
                    });
                  }}
                  name={`${task.id}-checkbox`}
                  checked={completedTask}
                />
              </Container>

              <FlexBox columnDirection>
                <Paragraph bold crossed={task.completed} color={task.completed ? colors.grey60 : colors.black}>
                  {capitalize(task.kind)}
                </Paragraph>
                <DueDate completed={task.completed} dueTime={task.due_time} dueDate={task.due_date} />
              </FlexBox>
            </FlexBox>
          </FlexBox>

          <Container data-tip="You can’t edit because it’s completed" data-for={`edit-task-${task.id}`}>
            <IconButton
              icon={ButtonIcons.Edit}
              onClick={() => {
                toggleEditMode();
                setAnchorEl(null);
              }}
              focus={editMode}
              disabled={task.completed}
              customCss={css`
                &:hover {
                  background-color: ${task.completed ? colors.grey5 : colors.white};
                }
              `}
            />
            {task.completed && <Tooltip id={`edit-task-${task.id}`} />}
          </Container>
          <PopperComponent {...popperProps}>
            <SideBarPopperLayout
              header={
                <>
                  <Paragraph type={ParagraphType.Small}>{capitalize(task.kind)}</Paragraph>
                  <DueDate
                    decoration={false}
                    completed={task.completed}
                    dueTime={task.due_time}
                    dueDate={task.due_date}
                  />
                </>
              }
              firstSubHeaderTerm="Assignee"
              firstSubHeader={task.assignee.name}
              secondSubHeaderTerm="Priority"
              secondSubHeader={capitalize(task.priority)}
              body={
                <FlexBox
                  gap={spacings.px12}
                  columnDirection
                  customCss={css`
                    white-space: pre-wrap;
                    overflow-wrap: anywhere;
                  `}
                >
                  {task.description}
                </FlexBox>
              }
            />
          </PopperComponent>
        </FlexBox>
      )}
    </>
  );
};

export default Task;
