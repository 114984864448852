/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import colors from '../../theme/colors';
import {
  defaultFontValues,
  largeParagraphCss,
  paragraphCss,
  singleLineCss,
  smallParagraphCss,
  tinyParagraphCss
} from '../../theme/typography';
import { spacingKeys, Spacings, spacingsCss } from '../../theme/variables';

export enum ParagraphType {
  Large = 'large',
  Default = 'default',
  Small = 'small',
  Tiny = 'tiny'
}
interface ParagraphProps extends React.HTMLProps<HTMLParagraphElement>, Spacings {
  type?: ParagraphType | 'large' | 'default' | 'small' | 'tiny';
  customCss?: SerializedStyles;
  italic?: boolean;
  bold?: boolean;
  color?: (typeof colors)[keyof typeof colors];
  inline?: boolean;
  crossed?: boolean;
  singleLine?: boolean;
}

const appliedCss = {
  default: paragraphCss,
  large: largeParagraphCss,
  small: smallParagraphCss,
  tiny: tinyParagraphCss
};

const Paragraph = ({
  italic,
  bold,
  customCss,
  type = ParagraphType.Default,
  children,
  color,
  inline,
  crossed,
  singleLine,
  ...rest
}: ParagraphProps): JSX.Element => {
  const spacingProps = pick(spacingKeys, rest);
  const restOfProps = omit(spacingKeys, rest);

  return (
    <p
      {...restOfProps}
      css={css`
        ${appliedCss[type]};
        font-weight: ${bold ? defaultFontValues.boldFontWeight : defaultFontValues.fontWeight};
        font-style: ${italic ? 'italic' : defaultFontValues.fontStyle};
        ${color ? `color: ${color};` : ''}
        ${inline && 'display: inline;'}
        ${crossed && 'text-decoration: line-through;'}
        ${spacingsCss(spacingProps)};
        ${customCss}
        ${singleLine && singleLineCss}
      `}
    >
      {children}
    </p>
  );
};

export default Paragraph;
