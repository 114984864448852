import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import transferImage from '../../assets/img/transfer.svg';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { SelectField } from '../../components/core/forms/fields';
import Heading from '../../components/core/Heading';
import { CheckIcon, ClearIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IOpportunity, OpportunityStatus } from '../../interfaces/IOpportunity';
import { findPolicyTypeLabel } from '../../interfaces/IPolicyType';
import useLeadOpportunities from '../../queries/leads/opportunities/useLeadOpportunities';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { LabelSize, sizes, spacings } from '../../theme/variables';
import { capitalize } from '../../utils/formatter';
import useDisplayFinalizedSalesPage from './_contexts/useFinalizedPage';

const DeprecatedFinalizedSalesPage = () => {
  const navigate = useNavigate();
  const { lead } = useGuidedSellingExperienceContext();
  const { data: opportunities, isFetchedAfterMount: isOpportunitiesFetched } = useLeadOpportunities({
    leadId: lead!.id
  });

  React.useEffect(
    () => () =>
      useDisplayFinalizedSalesPage.setState({
        displayFinalizedSalesPage: false
      }),
    []
  );

  if (!isOpportunitiesFetched) {
    return (
      <>
        <Skeleton height={300} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
        <Skeleton count={6} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
      </>
    );
  }
  return (
    <>
      <FlexBox columnDirection gap={spacings.px24}>
        <img src={transferImage} alt="Transfer" width={660} />
        <FlexBox ml={spacings.px24} gap={spacings.px12} columnDirection>
          <>
            <Heading>Your finalized sales</Heading>
            <Paragraph>
              {`Great job, ${authInfo.currentUserFirstName}!
          You’re all set.`}
            </Paragraph>
          </>

          <BaseForm
            pb={spacings.px0}
            pt={spacings.px0}
            pl={spacings.px0}
            pr={spacings.px0}
            controlsAlignment="left"
            controlsWidth={sizes.mediumFormInputWidth}
            initialValues={{ opportunities }}
            submitText="Got it! Go to sales dashboard"
            submitTestId="finalize-lead-button"
            onSubmit={() => {
              return navigate('/leads');
            }}
            renderForm={({ values }) => {
              return (
                <>
                  {(values.opportunities || []).map((opportunity: IOpportunity, index: number) => {
                    const opportunityStatusFieldName = `opportunities.${index}.status`;
                    return (
                      <FlexBox alignItemsCenter gap={spacings.px4} key={opportunity.id}>
                        {opportunity.status === OpportunityStatus.Sold ? (
                          <CheckIcon color={colors.statusGreen} />
                        ) : (
                          <ClearIcon color={colors.statusRed} />
                        )}
                        <SelectField
                          label={findPolicyTypeLabel(opportunity.policy_type)}
                          id={opportunityStatusFieldName}
                          name={opportunityStatusFieldName}
                          options={[
                            {
                              value: capitalize(opportunity.status),
                              key: opportunity.status
                            }
                          ]}
                          testId={`${opportunity.policy_type}-status`}
                          inline
                          disabled
                          labelSize={LabelSize.Small}
                        />
                      </FlexBox>
                    );
                  })}
                </>
              );
            }}
          />
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default DeprecatedFinalizedSalesPage;
