import { useParams } from 'react-router-dom';

import CommunicationTab from '../GuidedSellingExperience/tabs/CommunicationTab';

const PersonCommunicationHistory = () => {
  const { personGid } = useParams();

  return (
    <CommunicationTab
      personGid={personGid}
      showClose={false}
      showContactBook
      showOpenLead
      popperPlacement="bottom-end"
    />
  );
};

export default PersonCommunicationHistory;
