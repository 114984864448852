/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { spacings } from '../../../theme/variables';
import ContentSection from '../ContentSection';

const PolicyDetailsSectionPlaceholder = ({ linesCount }: { linesCount: number }): JSX.Element => (
  <Container pt={spacings.px12} pr={spacings.px32}>
    <ContentSection
      heading={
        <div
          css={css`
            width: 120px;
          `}
        >
          <Skeleton />
        </div>
      }
    >
      <FlexBox columnDirection>
        {new Array(linesCount).fill(0).map((_value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <FlexBox key={index}>
            <Text
              pt={spacings.px20}
              customCss={css`
                width: 980px;
              `}
            >
              <Skeleton />
            </Text>
          </FlexBox>
        ))}
      </FlexBox>
    </ContentSection>
  </Container>
);

export default PolicyDetailsSectionPlaceholder;
