import { uniqBy } from 'ramda';
import React from 'react';

import buildUIFlow from '../../../components/core/forms/uiFlowBuilder';
import { IOpportunity } from '../../../interfaces';
import { IDataCollectionOpportunity } from '../../../interfaces/IDataCollection';
import DatapointKey from '../../../interfaces/IDatapoint';
import { OpportunityStatus, OpportunityVerificationStatus } from '../../../interfaces/IOpportunity';
import { InsurableInterest } from '../../../interfaces/IPolicyType';
import { DataCollection } from '../../../queries/leads/data_collection/useDataCollection';
import { HOME_CROSS_SALE_INTENT_FLOW_V1, usePersonUIFlow } from '../../../queries/workflows/useUiFlow';

const useCandidateOpportunitiesWithVerificationStatus = (opportunities: IOpportunity[] | undefined) =>
  opportunities?.map(opportunity => ({
    opportunity,
    verificationStatus: OpportunityVerificationStatus.NotVerified,
    rejectionReason: undefined
  }));

const leadOpportunityVerificationStatus = (
  opportunity: IDataCollectionOpportunity,
  isHomeProfileSkippedByISR: boolean,
  homeOpportunitiesRejectionReason: string | undefined,
  isCustomerDataCompletenessHigh: boolean
) => {
  if ([OpportunityStatus.Lost, OpportunityStatus.Sold].includes(opportunity.status)) {
    return {
      verificationStatus: opportunity.status,
      rejectionReason: opportunity.reason
    };
  }

  if (opportunity.disqualification_reason || opportunity.status === OpportunityStatus.NotQualified) {
    return {
      verificationStatus: OpportunityVerificationStatus.Ineligible,
      rejectionReason: opportunity.disqualification_message || undefined
    };
  }

  if (
    opportunity.is_data_collection_completed ||
    // TODO: qualify opportunity on FE once data collection for it is completed. This will allow to drop
    // first condition
    opportunity.status === OpportunityStatus.Qualified ||
    // TODO: remove last condition once OLB flow is migrated to
    // use qualification status on BE
    (opportunity.primary && isCustomerDataCompletenessHigh)
  ) {
    return {
      verificationStatus: OpportunityVerificationStatus.Qualified,
      rejectionReason: undefined
    };
  }

  if (opportunity.insurable_interest === InsurableInterest.RealProperty && isHomeProfileSkippedByISR) {
    return {
      verificationStatus: OpportunityVerificationStatus.Skipped,
      rejectionReason: homeOpportunitiesRejectionReason
    };
  }

  return {
    verificationStatus: OpportunityVerificationStatus.NotVerified,
    rejectionReason: undefined
  };
};

const useLeadOpportunitiesWithVerificationStatus = (
  personGid: string,
  dataCollection: DataCollection | undefined,
  isCustomerDataCompletenessHigh: boolean
) => {
  const leadOpportunities = dataCollection?.pages
    ?.flatMap(({ opportunities }) => opportunities)
    ?.filter(opportunity => !!opportunity) as IDataCollectionOpportunity[] | undefined;
  const uniqOpportunities = uniqBy(
    (opportunity: IDataCollectionOpportunity) => opportunity.id,
    leadOpportunities || []
  );
  const primaryOpportunity = uniqOpportunities.find(({ primary }) => primary);

  const { data: homeCrossSaleIntentWorkflowData } = usePersonUIFlow({
    uiFlowKey:
      primaryOpportunity?.insurable_interest === InsurableInterest.RealProperty ? HOME_CROSS_SALE_INTENT_FLOW_V1 : '',
    personGid
  });
  const homeCrossSaleIntentUIFlow = React.useMemo(
    () => buildUIFlow({ uiFlowResponse: homeCrossSaleIntentWorkflowData }),
    [homeCrossSaleIntentWorkflowData]
  );

  const isHomeProfileSkippedByISR =
    homeCrossSaleIntentUIFlow?.initialValues()?.[DatapointKey.PersonIsInterestedInHomeInsurance] ===
    OpportunityVerificationStatus.Skipped;

  const homeOpportunitiesRejectionReason =
    homeCrossSaleIntentUIFlow?.initialValues()?.[DatapointKey.PersonHomeInsuranceRejectedReason];

  return uniqOpportunities.map(opportunity => ({
    opportunity,
    ...leadOpportunityVerificationStatus(
      opportunity,
      isHomeProfileSkippedByISR,
      homeOpportunitiesRejectionReason,
      isCustomerDataCompletenessHigh
    )
  }));
};

const useOpportunitiesWithVerificationStatus = ({
  personGid,
  dataCollection,
  opportunities,
  isCustomerDataCompletenessHigh
}: {
  personGid: string;
  dataCollection: DataCollection | undefined;
  opportunities: IOpportunity[] | undefined;
  isCustomerDataCompletenessHigh: boolean;
}) => {
  const leadOpportunitiesWithVerificationStatus = useLeadOpportunitiesWithVerificationStatus(
    personGid,
    dataCollection,
    isCustomerDataCompletenessHigh
  );
  const candidateOpportunitiesWithVerificationStatus = useCandidateOpportunitiesWithVerificationStatus(opportunities);

  if (leadOpportunitiesWithVerificationStatus.length) {
    return leadOpportunitiesWithVerificationStatus;
  }

  return candidateOpportunitiesWithVerificationStatus;
};

export default useOpportunitiesWithVerificationStatus;
