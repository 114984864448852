import { InsuranceCategory } from '../../interfaces/IPolicyType';
import authInfo from '../../services/authInfo';
import useMoveLeadToPipeline from '../leads/useMoveLeadToPipeline';
import useFindOrCreateLead from './person_leads/useFindOrCreateLead';
import { useSyncPerson } from './usePerson';

interface IPayload {
  personGid: string;
  leadGid: string | undefined;
  candidateGid: string | undefined;
  pipelineUserId: number;
  insuranceCategory: InsuranceCategory;
}

const useMovePersonToPipeline = ({ personGid, leadGid, candidateGid, pipelineUserId, insuranceCategory }: IPayload) => {
  const { mutateAsync: syncPerson } = useSyncPerson();
  const { mutateAsync: createLead } = useFindOrCreateLead();
  const { mutateAsync: moveToPipeline } = useMoveLeadToPipeline();

  return async () =>
    await syncPerson(personGid)
      .then(async person => {
        if (leadGid) {
          return { lead: { gid: leadGid } };
        }
        return await createLead({
          personGid: person.gid,
          candidateGid,
          assigneeId: authInfo.currentUserId!,
          insuranceCategory
        });
      })
      .then(({ lead }) => moveToPipeline({ leadGid: lead.gid, pipelineUserId }))
      .catch(() => null);
};

export default useMovePersonToPipeline;
