const appConfiguration = {
  get environment() {
    return window.__amp_config.environment;
  },
  get ampAdminUrl() {
    return window.__amp_config.admin_url;
  },
  get originAdminUrl() {
    return window.__amp_config.origin_admin_url;
  },
  get ams360AdminUrl() {
    return window.__amp_config.ams360_admin_url;
  },
  get googleStreetApiKey() {
    return window.__amp_config.google_street_api_key;
  },
  get twilioFlexSignInUrl() {
    return window.__amp_config.twilio_flex_sign_in_url;
  },
  get twilioFlexUrl() {
    return window.__amp_config.twilio_flex_url;
  },
  get customerPortalPublicUrl() {
    return window.__amp_config.customer_portal_public_url;
  },
  get entraClientId() {
    return window.__amp_config.entra_oidc_client_id;
  },
  get entraAuthority() {
    return window.__amp_config.entra_oidc_authority;
  }
};

export default appConfiguration;
