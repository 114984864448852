/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import featureFlags from '../../../../constants/featureFlags';
import { DataCollectionStepsKey } from '../../../../interfaces/IDataCollection';
import { PartialStep } from '../../../GuidedDataCollection/_hooks/useDataCollectionStepper';
import { GuidedSellingPathnames } from '../../navigation';
import {
  ConsumerReportTip,
  HouseholdMembersOnQuotingConfigurationTip,
  HouseholdMembersReminderTip,
  IntroTip,
  IssuePoliciesTip,
  LifeInsuranceTip
} from './tips';

export const circleIconCss = css`
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
`;

export enum DynamicTipsKeys {
  IntroTip = 'introTip',
  ConsumerReportTip = 'consumerReportTip',
  IssuePolicyTip = 'issuePolicyTip',
  HouseholdMembersReminderTip = 'householdMembersReminderTip',
  HouseholdMembersOnQuotingConfigurationTip = 'householdMembersOnQuotingConfigurationTip',
  LifeInsuranceTip = 'lifeInsuranceTip'
}

export interface DynamicTip {
  component: React.ReactElement;
  key: DynamicTipsKeys;
  displayed: boolean;
  displayedInHistory: boolean;
  isPreviousButtonAvailable: boolean;
}

export const getDynamicTips = ({
  pathname,
  isDisclosureAccepted,
  withLifeTip,
  activeDataCollectionStep
}: {
  pathname: string;
  isDisclosureAccepted: boolean;
  withLifeTip: boolean;
  activeDataCollectionStep?: PartialStep;
}): DynamicTip[] => {
  return [
    featureFlags.dynamicTipOnSummary && {
      component: <IntroTip />,
      key: DynamicTipsKeys.IntroTip,
      displayed: pathname === GuidedSellingPathnames.ScoutingReport,
      displayedInHistory: true,
      isPreviousButtonAvailable: false
    },
    featureFlags.dynamicTipOnDataCollection && {
      component: <ConsumerReportTip />,
      key: DynamicTipsKeys.ConsumerReportTip,
      displayed:
        pathname === GuidedSellingPathnames.DataCollection &&
        activeDataCollectionStep?.key === DataCollectionStepsKey.CustomerProfile &&
        !isDisclosureAccepted,
      displayedInHistory: !isDisclosureAccepted,
      isPreviousButtonAvailable: featureFlags.dynamicTipOnSummary
    },
    {
      component: <HouseholdMembersReminderTip />,
      key: DynamicTipsKeys.HouseholdMembersReminderTip,
      displayed:
        pathname === GuidedSellingPathnames.DataCollection &&
        (activeDataCollectionStep?.key === DataCollectionStepsKey.CustomerProfile ||
          activeDataCollectionStep?.key === DataCollectionStepsKey.HomeProfile) &&
        isDisclosureAccepted,
      displayedInHistory: true,
      isPreviousButtonAvailable: featureFlags.dynamicTipOnDataCollection
    },
    {
      component: <HouseholdMembersOnQuotingConfigurationTip />,
      key: DynamicTipsKeys.HouseholdMembersOnQuotingConfigurationTip,
      displayed:
        pathname === GuidedSellingPathnames.DataCollection &&
        activeDataCollectionStep?.key === DataCollectionStepsKey.QuotingConfiguration &&
        isDisclosureAccepted,
      displayedInHistory: true,
      isPreviousButtonAvailable: featureFlags.dynamicTipOnDataCollection
    },
    (featureFlags.dynamicTipOnQuotes || featureFlags.lifeInsurancePitch) && {
      component: <IssuePoliciesTip />,
      key: DynamicTipsKeys.IssuePolicyTip,
      displayed: pathname === GuidedSellingPathnames.Quotes,
      displayedInHistory: true,
      isPreviousButtonAvailable: true
    },
    featureFlags.lifeInsurancePitch && {
      component: <LifeInsuranceTip />,
      key: DynamicTipsKeys.LifeInsuranceTip,
      displayed: withLifeTip && pathname === GuidedSellingPathnames.Quotes,
      displayedInHistory: false,
      isPreviousButtonAvailable: false
    }
  ].filter(Boolean);
};
