import * as React from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import { BriefcaseIcon, HistoryIcon, LightbulbIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { spacings } from '../../../../theme/variables';
import CallHistory from './CallHistory';
import Case from './Case';
import ContextSkeleton from './ContextSkeleton';
import { contextGridStyle } from './index.style';
import Opportunities from './Opportunities';
import UserMissingLicenseWarning from './UserMissingLicenseWarning';
import WhiteLabelWarning from './WhiteLabelWarning';

const Context = () => {
  return (
    <FlexBox columnDirection gap={spacings.px16}>
      <Heading type="h4">Context</Heading>
      <React.Suspense fallback={<ContextSkeleton />}>
        <WhiteLabelWarning />
        <UserMissingLicenseWarning />
        <Container customCss={contextGridStyle}>
          <BriefcaseIcon />
          <Text bold>This case:</Text>
          <Case />

          <HistoryIcon />
          <Text bold>Call history:</Text>
          <CallHistory />

          <LightbulbIcon />
          <Text bold>Your opportunities:</Text>
          <Opportunities />
        </Container>
      </React.Suspense>
    </FlexBox>
  );
};

export default Context;
