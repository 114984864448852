import '../../common/TimeTerm/TimeTerm.scss';

import { Field } from 'formik';
import React from 'react';
import { Col, Container, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { Translations } from '../../../constants';
import { IMaticPolicy, IPerson } from '../../../interfaces';
import useCancelPolicy from '../../../queries/people/person_policies/useCancelPolicy';
import { maxDate, oldDate, requiredField } from '../../../utils/yupRules';
import { ErrorCol, FormModal } from '../../common';
import { DatePicker, Select } from '../../common/FormikComponents';
import PersonNavLink from '../../PersonNavLink';
import PolicyShortInfo from '../PolicyShortInfo';

interface CancelModalProps {
  person: IPerson;
  policy: IMaticPolicy;
  closeModal: () => void;
  onPolicyCancelled: () => Promise<unknown>;
}

const CancelModal: React.FC<CancelModalProps> = ({ person, policy, closeModal, onPolicyCancelled }) => {
  const { mutateAsync: cancelPolicy } = useCancelPolicy();

  return (
    <FormModal
      open
      returnFocusAfterClose={false}
      initialValues={{
        carrier_id: policy.carrier.id,
        effective_date: policy.effective_date,
        expiration_date: policy.expiration_date,
        cancellation_reason: policy.cancellation_reason || '',
        cancellation_date: policy.cancellation_date || ''
      }}
      validationSchema={yup.object().shape({
        cancellation_reason: requiredField,
        cancellation_date: yup
          .date()
          .required('Please, fill in the field')
          .min(oldDate, "Date can't be earlier than 1990")
          .max(maxDate, "Date can't be later than 2100")
      })}
      confirmHandler={values =>
        cancelPolicy({
          person_gid: person.gid,
          policy_id: policy.id,
          cancellation_reason: values.cancellation_reason as string,
          cancellation_date: values.cancellation_date as string
        })
          .then(() => onPolicyCancelled())
          .then(() => closeModal())
      }
      cancelHandler={closeModal}
      title={<PersonNavLink person={person} linkPrefix="Cancel Policy." />}
      confirmText="Confirm"
      renderForm={({ errors, touched }) => (
        <Container>
          <Row className="mb-4">
            <Col>
              <PolicyShortInfo policy={policy} />
            </Col>
          </Row>
          <Row>
            <ErrorCol
              sm={4}
              name="cancellation_reason"
              error={errors['cancellation_reason']}
              when={errors['cancellation_reason'] && touched['cancellation_reason']}
            >
              <Label htmlFor="cancel-reason-select">Cancellation reason *</Label>
              <Field
                inputId="cancel-reason-select"
                name="cancellation_reason"
                component={Select}
                options={Translations.policyCancellationReasonOptions}
                title="Cancellation reason"
                placeholder="Please select an option"
                labelName="value"
                valueName="key"
              />
            </ErrorCol>
            <ErrorCol
              sm={4}
              name="cancellation_date"
              error={errors['cancellation_date']}
              when={errors['cancellation_date'] && touched['cancellation_date']}
            >
              <Label htmlFor="cancel-date-input">Cancellation date *</Label>
              <Field
                id="cancel-date-input"
                name="cancellation_date"
                component={DatePicker}
                maxDate={maxDate}
                minDate={oldDate}
              />
            </ErrorCol>
          </Row>
        </Container>
      )}
    />
  );
};

export default CancelModal;
