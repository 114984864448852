import Tag from '../../../../../components/core/Tag';
import { IUser } from '../../../../../interfaces';
import authInfo from '../../../../../services/authInfo';
import colors from '../../../../../theme/colors';

const UserRoleTag = ({
  processedBy,
  processedWithRole
}: {
  processedWithRole: string | null | undefined;
  processedBy?: IUser | null;
}) => {
  const tagLabel = processedBy?.email === authInfo.currentUserEmail ? 'You' : processedWithRole;

  if (!tagLabel) {
    return null;
  }

  return <Tag label={tagLabel} backgroundColor={colors.grey10} textColor={colors.grey60} borderRadius={4} />;
};

export default UserRoleTag;
