import { useQuery } from '@tanstack/react-query';

import { IPersonWithDncStatus } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface PeopleDashboardQuery {
  agent_ids?: string[];
  criteria?: string;
}

interface PeopleDashboardResponse {
  people: IPersonWithDncStatus[];
}

const usePeople = (query?: PeopleDashboardQuery) =>
  useQuery({
    queryKey: ['people', query],
    queryFn: (): Promise<PeopleDashboardResponse> => api.get(`/api/frontend/people?${toQueryParams(query)}`)
  });

export default usePeople;
