import './TimeTerm.scss';

import { Field } from 'formik';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { dateFormatter, INTERNAL_DATE_FORMAT } from '../../../utils/formatter';
import DatePicker from '../DatePicker/DatePicker';
import { Input } from '../FormikComponents/FormikComponents';

export default class TimeTerm extends PureComponent<any, any> {
  componentDidUpdate({ period: prevPeriod }: any) {
    const { period, startDate } = this.props;

    if (prevPeriod === period) {
      return;
    }

    if (!period) {
      this.clearEndDate();
    } else if (period && startDate) {
      this.updateEndDate({ target: { value: startDate } });
    }
  }

  updateEndDate = ({ target: { value } }: any) => {
    const {
      form: { values, setValues },
      onChange,
      period,
      startDateName,
      endDateName
    } = this.props;

    const endDate =
      value && period
        ? moment(value, INTERNAL_DATE_FORMAT).add(period, 'months').format(INTERNAL_DATE_FORMAT)
        : values[endDateName];

    setValues({ ...values, [startDateName]: value, [endDateName]: endDate });
    onChange({ [startDateName]: value, [endDateName]: endDate });
  };

  clearEndDate = () => {
    const {
      form: { values, setValues },
      onChange,
      startDateName,
      endDateName
    } = this.props;

    setValues({ ...values, [endDateName]: '' });
    onChange({ [startDateName]: values[startDateName], [endDateName]: '' });
  };

  render() {
    const { startDateName, endDateName, startDate, endDate, id, toDate, ...rest } = this.props;

    return (
      <div className="time-term">
        <div className="time-term__date">
          <DatePicker
            {...rest}
            id={id}
            name={startDateName}
            value={startDate}
            onChange={this.updateEndDate}
            onBlur={() => {}}
            // @ts-expect-error old-code
            maxDate={toDate}
          />
        </div>
        <span className="time-term__delimiter"> – </span>
        <div className="time-term__date">
          <Field type="hidden" name={endDateName} component={Input} disabled />
          <input className="form-control time-term__end-date" value={dateFormatter(endDate)} disabled />
        </div>
      </div>
    );
  }
}

// @ts-expect-error old-code
TimeTerm.propTypes = {
  period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  startDateName: PropTypes.string.isRequired,
  endDateName: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  form: PropTypes.shape({
    values: PropTypes.object,
    setValues: PropTypes.func
  }).isRequired,
  id: PropTypes.string,
  // @ts-expect-error old-code
  toDate: PropTypes.objectOf(Date)
};

// @ts-expect-error old-code
TimeTerm.defaultProps = {
  period: null,
  startDate: null,
  endDate: null,
  onChange: () => {},
  id: null,
  toDate: null
};
