import React, { useState } from 'react';

import { DocumentOwnerOption, FileToConfirm, FileToUpload } from '../../../../interfaces/IDocument';
import alert from '../../../core/Alert';
import Dropzone from '../../../core/Dropzone/Dropzone';
import ConfirmModal from '../ConfirmModal';

interface UploadAreaProps {
  onAttach: (filesToUpload: FileToUpload[]) => Promise<unknown>;
  isForPolicy: boolean;
  ownersOptions?: DocumentOwnerOption[];
  inline?: boolean;
}

const UploadArea = ({ onAttach, ownersOptions, inline, isForPolicy }: UploadAreaProps) => {
  const [filesToConfirm, setFilesToConfirm] = useState<FileToConfirm[]>([]);
  const [confirmModalIsOpen, setOpenConfirmModal] = useState(false);

  const closeModal = () => {
    setOpenConfirmModal(false);
    setFilesToConfirm([]);
  };

  return (
    <>
      <Dropzone
        onUpload={filesToConfirm => {
          setOpenConfirmModal(true);
          setFilesToConfirm(files => [...files, ...filesToConfirm]);
        }}
        onReject={invalidFiles =>
          invalidFiles.length > 0 &&
          alert({
            title: 'Error uploading files',
            items: invalidFiles.map(({ file, error }) => `${file.name} - ${error}`)
          }).error()
        }
        inline={inline}
      />
      {filesToConfirm.length > 0 && (
        <ConfirmModal
          open={confirmModalIsOpen}
          files={filesToConfirm}
          cancelHandler={() => closeModal()}
          confirmHandler={filesToUpload => onAttach(filesToUpload).then(() => closeModal())}
          ownersOptions={ownersOptions}
          isForPolicy={isForPolicy}
        />
      )}
    </>
  );
};

export default UploadArea;
