import axios from 'axios';
import axiosRetry, { isNetworkOrIdempotentRequestError } from 'axios-retry';
import * as React from 'react';

import AppWithEntra from './AppWithEntra';
import BrokenConnectionPage from './components/BrokenConnectionPage';
import LoadingPage from './components/LoadingPage';
import { apiBaseUrl } from './services/api';
import { setupConfig, setupFullStory, setupMaskHighlight, setupSentry } from './utils/initializers/application';

const AppSwitch = () => {
  const [isConfigReady, setConfigIsReady] = React.useState(false);
  const [isBackendUnreachable, setIsBackendUnreachable] = React.useState(false);

  React.useEffect(() => {
    const prepareConfig = async () => {
      const configRequester = axios.create({ baseURL: apiBaseUrl(), timeout: 5000 });
      axiosRetry(configRequester, {
        retries: 1000,
        shouldResetTimeout: true,
        retryCondition: error => isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED',
        onRetry: () => setIsBackendUnreachable(true)
      });

      configRequester('/api/frontend_config').then(configResponse => {
        if (configResponse.data) {
          const configResponseJson = configResponse.data;
          const config = configResponseJson['data'];

          setupConfig(config);
          setupMaskHighlight(config.environment);
          setupSentry(window.__amp_config);
          setupFullStory(window.__amp_config);
          setConfigIsReady(true);
          setIsBackendUnreachable(false);
        } else {
          setIsBackendUnreachable(true);
        }
      });
    };
    prepareConfig();
  }, []);

  if (isConfigReady) {
    return <AppWithEntra />;
  }

  if (isBackendUnreachable) {
    return <BrokenConnectionPage />;
  }

  return <LoadingPage message="Preparing AMP" />;
};

export default AppSwitch;
