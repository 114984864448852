/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { Translations } from '../../../constants';
import { IMaticPolicy } from '../../../interfaces';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, ellipsis } from '../../../utils/formatter';
import NewTooltip from '../../common/Tooltip/NewTooltip';
import Container from '../../core/Container';
import FlexBox from '../../core/FlexBox';
import { MapIcon } from '../../core/icons';

interface PolicyShortInfoProps {
  policy: IMaticPolicy;
  heading?: JSX.Element;
}

const PolicyShortInfo: React.FC<PolicyShortInfoProps> = ({ policy, heading }) => {
  const policyTypeLabel = findPolicyTypeLabel(policy.policy_type);
  const policyAsset = policy.assets?.[0];

  return (
    <Container backgroundColor={colors.grey5}>
      <Container p={spacings.px8}>
        <h6>{heading || 'Policy Details'}</h6>
        <Container className="fs-mask" mt={spacings.px8}>
          <MapIcon />
          Asset address - {policyAsset?.address?.full || policy.state || 'unknown'}
        </Container>
        <Container mt={spacings.px8} backgroundColor={colors.white} border borderColor={colors.grey30} roundBorder>
          <FlexBox p={spacings.px8}>
            <div
              css={css`
                width: 110px;
              `}
            >
              <div>Status</div>
              <div>
                <b>{Translations.policyStatus(policy.status)}</b>
              </div>
            </div>
            <div
              css={css`
                width: 95px;
              `}
            >
              <div>Type</div>
              <div>
                <b>{policyTypeLabel}</b>
              </div>
            </div>
            <div
              css={css`
                width: 120px;
              `}
            >
              <div>Carrier</div>
              <div>
                <span data-tip={policy.carrier.name} data-for="carrier-name">
                  <b>{ellipsis(policy.carrier.name, 12)}</b>
                </span>
                <NewTooltip id="carrier-name" />
              </div>
            </div>
            <div
              css={css`
                width: 175px;
              `}
            >
              <div>Policy term</div>
              <div>
                <b>
                  {dateFormatter(policy.effective_date)} - {dateFormatter(policy.expiration_date)}
                </b>
              </div>
            </div>
            <div>
              <div>Policy number</div>
              <div>
                <b className="fs-mask">{policy.policy_number}</b>
              </div>
            </div>
          </FlexBox>
        </Container>
      </Container>
    </Container>
  );
};

export default PolicyShortInfo;
