import { css } from '@emotion/react';

import colors, { opacities } from './colors';
import { borderRadius } from './variables';

export const defaultFontValues = {
  fontFamily: 'freight-sans-pro',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '16px',
  lineHeight: '1.25',
  color: colors.black,
  fontFeatureSettings: "'pnum' on, 'lnum' on",
  marginBottom: 0,
  boldFontWeight: 700
};

const largeFontValues = {
  fontSize: '20px',
  lineHeight: '28px'
};

export const smallFontValues = {
  fontSize: '14px',
  lineHeight: '18px'
};

const tinyFontValues = {
  fontSize: '12px',
  lineHeight: '16px'
};

export const defaultFontCss = css`
  font-family: ${defaultFontValues.fontFamily};
  font-style: ${defaultFontValues.fontStyle};
  font-weight: ${defaultFontValues.fontWeight};
  font-size: ${defaultFontValues.fontSize};
  line-height: ${defaultFontValues.lineHeight};
  color: ${defaultFontValues.color};
  font-feature-settings: ${defaultFontValues.fontFeatureSettings};
  margin-bottom: ${defaultFontValues.marginBottom};
`;

export const paragraphCss = defaultFontCss;

export const largeParagraphCss = css`
  ${defaultFontCss};
  font-size: ${largeFontValues.fontSize};
  line-height: ${largeFontValues.lineHeight};
`;

export const smallParagraphCss = css`
  ${defaultFontCss};
  font-size: ${smallFontValues.fontSize};
  line-height: ${smallFontValues.lineHeight};
`;

export const tinyParagraphCss = css`
  ${defaultFontCss};
  font-size: ${tinyFontValues.fontSize};
  line-height: ${tinyFontValues.lineHeight};
`;

export const heading1Css = css`
  ${defaultFontCss};
  font-size: 48px;
  font-weight: 900;
  line-height: 68px;
`;

export const heading2Css = css`
  ${defaultFontCss};
  font-size: 40px;
  font-weight: bold;
  line-height: 52px;
`;

export const heading3Css = css`
  ${defaultFontCss};
  font-size: 32px;
  font-weight: bold;
  line-height: 44px;
`;

export const heading4Css = css`
  ${defaultFontCss};
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
`;

export const highlightedCss = css`
  background-color: ${colors.violet}${opacities.opacity_16};
  border-radius: ${borderRadius}px;
  box-shadow:
    -2px 0 0 0 ${colors.violet}${opacities.opacity_16},
    2px 0 0 0 ${colors.violet}${opacities.opacity_16};
`;

export const singleLineCss = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block;
`;
