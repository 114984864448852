/** @jsxImportSource @emotion/react */

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

import alert from '../../core/Alert';

export const PDFContext = React.createContext<any>([]);

export const PDFContextProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const state = React.useState();

  return <PDFContext.Provider value={state}>{children}</PDFContext.Provider>;
};

const controlCss = css`
  color: white;
  background-color: transparent;
  border: 0;
  padding: 10px 20px;

  &:hover {
    opacity: 0.7;
  }
`;

const PDFViewer = ({ headingCss, bodyCss }: { headingCss?: SerializedStyles; bodyCss?: SerializedStyles }) => {
  const [numPages, setNumPages] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [currentScale, setCurrentScale] = React.useState(1.3);
  const [file, setFile] = React.useContext(PDFContext);

  React.useEffect(() => {
    setCurrentPage(1);
    setNumPages(1);
  }, [file]);

  const onDocumentLoadSuccess = (
    pdf: Parameters<NonNullable<React.ComponentProps<typeof Document>['onLoadSuccess']>>[0]
  ) => {
    setNumPages(pdf.numPages);
  };

  if (file && (file.file || file.link)) {
    return (
      <>
        <div
          css={[
            css`
              position: absolute;
              display: flex;
              justify-content: space-between;
              width: 100%;
              background-color: #3e3e3e;
              z-index: 3;
              padding: 0 30px;
              font-size: 16px;
            `,
            headingCss
          ]}
        >
          <div>
            <button css={controlCss} type="button" onClick={() => setCurrentScale(scale => scale + 0.2)}>
              +
            </button>
            <button css={controlCss} type="button" onClick={() => setCurrentScale(scale => scale - 0.2)}>
              -
            </button>
            <button css={controlCss} type="button" onClick={() => setCurrentPage(page => Math.max(page - 1, 1))}>
              {'<'}
            </button>
            <span
              css={css`
                color: white;
              `}
            >
              Page {currentPage} of {numPages}
            </span>
            <button css={controlCss} type="button" onClick={() => setCurrentPage(page => Math.min(page + 1, numPages))}>
              {'>'}
            </button>
          </div>
          <div>
            <button css={controlCss} type="button" onClick={() => setFile({})}>
              Close
            </button>
          </div>
        </div>
        <div
          css={[
            css`
              position: absolute;
              background-color: #3e3e3e;
              z-index: 2;
              overflow: scroll;
              width: 100%;
              height: 100%;
              padding: 50px 15px 15px 15px;
            `,
            bodyCss
          ]}
        >
          <div
            css={css`
              display: flex;
            `}
          >
            <div
              css={css`
                margin: auto;
              `}
            >
              <Document
                file={file.file || file.link}
                onLoadSuccess={onDocumentLoadSuccess}
                onSourceError={error => alert({ message: error.message }).error()}
              >
                <Page key={`page_${currentPage}`} pageNumber={currentPage} scale={currentScale} />
              </Document>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default PDFViewer;
