import * as React from 'react';
import * as yup from 'yup';

import { Link } from '../../../components/common';
import BaseForm from '../../../components/core/forms/BaseForm';
import { SelectField } from '../../../components/core/forms/fields';
import Modal, { ModalSize } from '../../../components/core/Modal';
import Text from '../../../components/core/Text';
import { IHome, IPersonAsset, IVehicle } from '../../../interfaces';
import { spacings } from '../../../theme/variables';
import { requiredField } from '../../../utils/yupRules';

interface LoanAssetPickerProps {
  leadId?: number;
  assets: (IVehicle | IHome | IPersonAsset)[];
  guidedSellingContext?: boolean;
  cancelHandler: () => void;
  confirmHandler: (assetGid: string) => void;
}

const LoanAssetPicker = ({
  leadId,
  assets,
  guidedSellingContext,
  cancelHandler,
  confirmHandler
}: LoanAssetPickerProps): JSX.Element => {
  return (
    <Modal title="Add loan" size={ModalSize.medium} showCancelButton={false} cancelHandler={cancelHandler}>
      <BaseForm
        enableReinitialize
        initialValues={{
          asset_gid: ''
        }}
        validationSchema={yup.object().shape({
          asset_gid: requiredField
        })}
        submitText="Add loan"
        onSubmit={({ asset_gid }) => confirmHandler(asset_gid)}
        cancelHandler={cancelHandler}
        pt={spacings.px16}
        pb={spacings.px16}
        pl={spacings.px16}
        pr={spacings.px16}
        renderForm={() => {
          return (
            <>
              <SelectField
                required
                label="Asset"
                id="asset_gid"
                name="asset_gid"
                testId="select-assets"
                options={assets.map(asset => ({ key: asset.gid, value: asset.description }))}
              />
              {leadId && !guidedSellingContext && (
                <Text bold mt={spacings.px8}>
                  <Link to={`/leads/${leadId}/assets`}>Or create new asset</Link>
                </Text>
              )}
            </>
          );
        }}
      />
    </Modal>
  );
};

export default LoanAssetPicker;
