/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Field, FieldConfig, FieldProps } from 'formik';
import * as React from 'react';

import { QuestionVerificationStatus } from '../../../../interfaces/IWorkflow';
import { InputSize, LabelSize } from '../../../../theme/variables';
import Container from '../../Container';
import NumericInput, { NumericInputProps } from '../../inputs/Numeric';
import FormError from './FormError';
import LabeledInput from './LabeledInput';

interface InputFieldProps extends NumericInputProps {
  label: string | JSX.Element;
  id: string;
  name: string;
  required?: boolean;
  testId?: string;
  secondary?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  valueIsNumber?: boolean;
  info?: string;
  fieldProps?: Omit<FieldConfig, 'name'>;
  verificationStatus?: QuestionVerificationStatus;
  selectPrefix?: JSX.Element;
}

const NumericField = ({
  label,
  id,
  name,
  required,
  testId,
  inline,
  secondary = false,
  customLabelCss,
  labelSize,
  inputSize,
  valueIsNumber,
  info,
  fieldProps,
  verificationStatus,
  selectPrefix,
  ...rest
}: InputFieldProps) => {
  const valueRef = React.useRef<any>();
  return (
    <LabeledInput
      id={id}
      required={required}
      label={label}
      inline={inline}
      secondary={secondary}
      disabled={rest.disabled}
      customCss={customLabelCss}
      labelSize={labelSize}
      inputSize={inputSize}
      info={info}
      verificationStatus={verificationStatus}
    >
      <Field {...fieldProps} name={name}>
        {({ field: { onBlur, value }, meta, form }: FieldProps) => {
          const hasError = meta.error && meta.touched;
          valueRef.current = value;

          return (
            <Container
              css={css`
                ${selectPrefix && 'display: flex;'}
              `}
            >
              {selectPrefix && (
                <Container
                  css={css`
                    width: 150px;
                  `}
                >
                  {selectPrefix}
                </Container>
              )}
              <Container css={css`${selectPrefix && 'width: 100%;'}'`}>
                <NumericInput
                  name={name}
                  value={value}
                  onValueChange={values => {
                    form.setFieldValue(name, valueIsNumber ? values.floatValue : values.value);
                  }}
                  data-testid={testId}
                  id={id}
                  inline={inline}
                  inputSize={inputSize}
                  hasError={!!hasError}
                  aria-invalid={!!hasError}
                  aria-errormessage={`${id}-error`}
                  {...rest}
                  onBlur={onBlur}
                  disabled={rest.disabled || form.status === 'disabled'}
                />
                {inline && !!hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />}
                {!inline && <FormError id={id} hasError={!!hasError} error={meta.error} />}
              </Container>
            </Container>
          );
        }}
      </Field>
    </LabeledInput>
  );
};

export default NumericField;
