import { Field } from 'formik';
import React, { useCallback } from 'react';

import { Translations } from '../../../../../../constants';
// utils
import { dig } from '../../../../../../utils/object';
import ErrorCol from '../../../../ErrorCol';
import { Select } from '../../../../FormikComponents';
import { getFieldValue } from '../../helpers';
// components
import IncidentLabel from '../IncidentLabel';

const ViolationField = ({
  showFieldError,
  name,
  labelId,
  formValue,
  index,
  errors,
  replace,
  options = [],
  loading
}: any) => {
  const onTypeChange = useCallback(
    (value: any) => {
      const incident = options.find(({ gid }: any) => gid === value);
      replace(index, { ...formValue, type_gid: value, key: dig(incident, 'key') });
    },
    [replace, formValue, index, options]
  );

  const handleStateChange = useCallback(
    (value: any) => {
      replace(index, { ...formValue, state: value, type_gid: '', key: '' });
    },
    [index, formValue, replace]
  );

  const disableTypeInput = loading || !formValue.state;
  const showViolationTypeError = showFieldError('type_gid') && getFieldValue(errors, `${name}.${index}`, 'type_gid');

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <ErrorCol error={getFieldValue(errors, `${name}.${index}`, 'state') as any} when={showFieldError('state')}>
        <IncidentLabel id={labelId} label="violation state" />
        <Field
          small
          name={`${name}[${index}].state`}
          component={Select}
          options={Translations.usaStates}
          onChange={handleStateChange}
        />
      </ErrorCol>
      <ErrorCol
        sm={8}
        error={showViolationTypeError}
        when={!formValue.state || showFieldError('type_gid')}
        warning={!formValue.state && 'State is required for violation'}
      >
        <IncidentLabel id={labelId} label="violations type" />
        <Field
          small
          labelName="description"
          valueName="gid"
          name={`${name}[${index}].type_gid`}
          component={Select}
          options={options}
          onChange={onTypeChange}
          disabled={disableTypeInput}
        />
      </ErrorCol>
    </div>
  );
};

export default ViolationField;
