/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import colors from '../../../../../theme/colors';
import { smallParagraphCss } from '../../../../../theme/typography';

export const formInputLabelStyle = css`
  ${smallParagraphCss};
  color: ${colors.grey60};
  min-width: 132px;
  width: 132px;
`;
