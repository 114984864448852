import { IUserWithRole } from './IUser';
import { IUserRole } from './IUserRole';

export enum IProductAnnouncementTag {
  AMP = 'amp',
  Quoting = 'quoting',
  Telephony = 'telephony',
  News = 'news',
  Features = 'features',
  TipsAndGuides = 'tips_and_guides'
}

export interface IProductAnnouncement {
  id: number;
  release_date: string;
  title: string;
  tags: IProductAnnouncementTag[];
  preview: string;
  content: string | null;
  author: IUserWithRole;
  targeted_roles: IUserRole[];
  created_at: string;
  updated_at: string;
}
