/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field, Form, Formik } from 'formik';
import moment from 'moment';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { ErrorCol } from '../../components/common';
import { DatePicker, Input, MultiSelect, Select } from '../../components/common/FormikComponents/FormikComponents';
import { Translations } from '../../constants';
import { ICarrier, IUser } from '../../interfaces';
import { PolicyTypes } from '../../interfaces/IPolicyType';
import { PoliciesDashboardFilters } from '../../queries/policies/usePolicies';
import { spacings } from '../../theme/variables';
import {
  filterDateValidation,
  FUTURE_DATE,
  isDateRangeValid,
  OLD_DATE,
  TO_IS_GREATER_THAN_FROM
} from '../../utils/dateRangeHelpers';
import { showFieldErrorGenerator } from '../../utils/formHelpers';

const BLANK = '';
const UNDEFINED_AGENT_OF_RECORD = { id: 'undefined', name: 'Undefined' };

const preparedAgentsOfRecord = (assignees: IUser[]) => {
  const sortedAssignees = assignees.sort((a, b) => a.name.toString().localeCompare(b.name.toString()));

  return [UNDEFINED_AGENT_OF_RECORD, ...sortedAssignees];
};

interface PolicyFiltersProps {
  assignees: IUser[];
  carriers: ICarrier[];
  onFilterReset: () => void;
  onFilterSubmit: (filters: Record<string, string | any>) => void;
  message?: string;
  filters?: PoliciesDashboardFilters;
}

const PoliciesFilter = ({
  filters = {},
  assignees,
  carriers,
  message,
  onFilterReset,
  onFilterSubmit
}: PolicyFiltersProps): JSX.Element => (
  <Formik
    initialValues={{
      agent_ids: filters.agent_ids || [],
      statuses: filters.statuses || [],
      policy_types: filters.policy_types || [],
      carrier_ids: filters.carrier_ids || [],
      policy_number: filters.policy_number || BLANK,
      customer_term: filters.customer_term || BLANK,
      effective_from: filters.effective_from || BLANK,
      effective_to: filters.effective_to || BLANK,
      expiration_from: filters.expiration_from || BLANK,
      expiration_to: filters.expiration_to || BLANK,
      cancellation_to: filters.cancellation_to || BLANK,
      cancellation_from: filters.cancellation_from || BLANK,
      cancellation_reasons: filters.cancellation_reasons || [],
      test_lead: filters.test_lead || BLANK,
      policy_state: filters.policy_state || BLANK
    }}
    enableReinitialize
    onSubmit={filters =>
      onFilterSubmit({
        ...filters,
        effective_from: filters.effective_from ? moment(filters.effective_from).format() : BLANK,
        effective_to: filters.effective_to ? moment(filters.effective_to).format() : BLANK,
        expiration_from: filters.expiration_from ? moment(filters.expiration_from).format() : BLANK,
        expiration_to: filters.expiration_to ? moment(filters.expiration_to).format() : BLANK,
        cancellation_from: filters.cancellation_from ? moment(filters.cancellation_from).format() : BLANK,
        cancellation_to: filters.cancellation_to ? moment(filters.cancellation_to).format() : BLANK
      })
    }
    validationSchema={yup.object().shape({
      effective_from: filterDateValidation,
      effective_to: filterDateValidation,
      expiration_from: filterDateValidation,
      expiration_to: filterDateValidation,
      cancellation_from: filterDateValidation,
      cancellation_to: filterDateValidation
    })}
  >
    {({ handleReset, errors, values, touched, isValid }) => {
      const showFieldError = showFieldErrorGenerator({ values, touched, isValid });

      return (
        <Form
          autoComplete="off"
          css={css`
            padding-top: ${spacings.px24}px;
          `}
        >
          <h6>Filters</h6>

          <Row>
            <Col sm={4}>
              <FormGroup row id="agent-filter">
                <Label sm={4}>Agent of record</Label>
                <Col sm={8}>
                  <Field
                    name="agent_ids"
                    component={MultiSelect}
                    options={preparedAgentsOfRecord(assignees)}
                    valueName="id"
                    labelName="name"
                    showResetButton
                  />
                </Col>
              </FormGroup>
              <FormGroup row id="customer-name-filter">
                <Label sm={4}>Customer</Label>
                <Col sm={8}>
                  <Field
                    name="customer_term"
                    className="fs-mask"
                    component={Input}
                    placeholder="Please enter name, email, or phone"
                  />
                </Col>
              </FormGroup>
              <FormGroup row id="carriers-filter">
                <Label sm={4}>Carrier</Label>
                <Col sm={8}>
                  <Field
                    name="carrier_ids"
                    component={MultiSelect}
                    options={carriers}
                    valueName="id"
                    labelName="name"
                    ordered
                    showResetButton
                  />
                </Col>
              </FormGroup>
            </Col>

            <Col sm={4}>
              <FormGroup row id="policy-types-filter">
                <Label sm={4}>Type</Label>
                <Col sm={8}>
                  <Field
                    name="policy_types"
                    component={MultiSelect}
                    options={PolicyTypes}
                    labelName="name"
                    ordered
                    showResetButton
                  />
                </Col>
              </FormGroup>
              <FormGroup row id="policy-statuses-filter">
                <Label sm={4}>Status</Label>
                <Col sm={8}>
                  <Field
                    name="statuses"
                    component={MultiSelect}
                    options={Translations.policyStatusOptions}
                    ordered
                    showResetButton
                  />
                </Col>
              </FormGroup>
              <FormGroup row id="cancellation-reason-filter">
                <Label sm={4}>Cancellation reason</Label>
                <Col sm={8}>
                  <Field
                    name="cancellation_reasons"
                    component={MultiSelect}
                    options={Translations.policyCancellationReasonOptions}
                    valueName="key"
                    labelName="value"
                    ordered
                    showResetButton
                  />
                </Col>
              </FormGroup>
            </Col>

            <Col sm={4}>
              <FormGroup row>
                <Label sm={4}>Effective Date</Label>
                <ErrorCol sm={4} when error={errors.effective_from as string}>
                  <Field
                    name="effective_from"
                    component={DatePicker}
                    placeholder="From"
                    minDate={OLD_DATE}
                    maxDate={FUTURE_DATE}
                  />
                </ErrorCol>
                <ErrorCol
                  sm={4}
                  when={showFieldError('effective_to')}
                  warning={
                    !errors.effective_to &&
                    !isDateRangeValid({ from: values.effective_from as string, to: values.effective_to as string }) &&
                    TO_IS_GREATER_THAN_FROM
                  }
                  error={errors.effective_to as string}
                >
                  <Field
                    name="effective_to"
                    component={DatePicker}
                    placeholder="To"
                    minDate={OLD_DATE}
                    maxDate={FUTURE_DATE}
                  />
                </ErrorCol>
              </FormGroup>
              <FormGroup row>
                <Label sm={4}>Expiration Date</Label>
                <ErrorCol sm={4} when error={errors.expiration_from as string}>
                  <Field
                    name="expiration_from"
                    component={DatePicker}
                    placeholder="From"
                    minDate={OLD_DATE}
                    maxDate={FUTURE_DATE}
                  />
                </ErrorCol>
                <ErrorCol
                  sm={4}
                  when={showFieldError('expiration_to')}
                  warning={
                    !errors.expiration_to &&
                    !isDateRangeValid({ from: values.expiration_from as string, to: values.expiration_to as string }) &&
                    TO_IS_GREATER_THAN_FROM
                  }
                  error={errors.expiration_to as string}
                >
                  <Field
                    name="expiration_to"
                    component={DatePicker}
                    placeholder="To"
                    minDate={OLD_DATE}
                    maxDate={FUTURE_DATE}
                  />
                </ErrorCol>
              </FormGroup>
              <FormGroup row>
                <Label sm={4}>Cancellation Date</Label>
                <ErrorCol sm={4} when error={errors.cancellation_from as string}>
                  <Field
                    name="cancellation_from"
                    component={DatePicker}
                    placeholder="From"
                    minDate={OLD_DATE}
                    maxDate={FUTURE_DATE}
                  />
                </ErrorCol>
                <ErrorCol
                  sm={4}
                  when={showFieldError('cancellation_to')}
                  warning={
                    !errors.cancellation_to &&
                    !isDateRangeValid({
                      from: values.cancellation_from as string,
                      to: values.cancellation_to as string
                    }) &&
                    TO_IS_GREATER_THAN_FROM
                  }
                  error={errors.cancellation_to as string}
                >
                  <Field
                    name="cancellation_to"
                    component={DatePicker}
                    placeholder="To"
                    minDate={OLD_DATE}
                    maxDate={FUTURE_DATE}
                  />
                </ErrorCol>
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup row>
                <Label sm={4}>Marked as test</Label>
                <Col sm={8}>
                  <Field name="test_lead" component={Select} options={Translations.testModeOptions} showResetButton />
                </Col>
              </FormGroup>
            </Col>
            <Col sm={4}>
              <FormGroup row>
                <Label sm={4}>Policy State</Label>
                <Col sm={8}>
                  <Field name="policy_state" component={Select} options={Translations.usaStates} showResetButton />
                </Col>
              </FormGroup>
            </Col>
          </Row>

          <Row className="align-items-center">
            <Col sm={6}>
              <span
                css={css`
                  color: #2a323d;
                  line-height: 1.14;
                `}
              >
                {message}
              </span>
            </Col>
            <Col sm={6} className="btn-container">
              <Button
                color="warning"
                onClick={() => {
                  onFilterReset();
                  handleReset();
                }}
              >
                Reset to default
              </Button>
              <Button color="primary" type="submit">
                Apply
              </Button>
            </Col>
          </Row>
        </Form>
      );
    }}
  </Formik>
);

export default PoliciesFilter;
