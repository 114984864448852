import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import GetQuotes from '../../GetQuotes';
import useQuotingAssetsData from './useQuotingAssetsData';

const CustomerGetQuotes = () => {
  const { personGid } = useParams();
  const quotingAssetsData = useQuotingAssetsData({ personGid });

  const navigate = useNavigate();

  const onClose = () => navigate(-1);

  return (
    <GetQuotes
      {...quotingAssetsData}
      leadId={undefined}
      onClose={() => onClose()}
      onQuotingStarted={() => onClose()}
      selectAllAssets={false}
    />
  );
};

export default CustomerGetQuotes;
