import * as React from 'react';

import { IDocument, IMaticPolicy, IPerson } from '../../interfaces';
import { uploadDocumentsForSignature } from '../../queries/people/person_policies/useUploadDocumentsForSignature';
import { Modal } from '../common';
import Checkbox from '../common/Checkbox/Checkbox';
import { APP_IS_SENT_FOR_E_SIGN, GO_TO_DOCU_SIGN, SEND_APP_FOR_E_SIGN, SEND_APP_FOR_E_SIGN_TITLE } from './constants';

const ESignModal = ({
  isOpened,
  policy,
  person,
  handleOpened,
  documents
}: {
  isOpened: boolean;
  policy: IMaticPolicy;
  person: IPerson;
  handleOpened: (value: boolean) => void;
  documents: IDocument[];
}): JSX.Element => {
  const [appIsSentForESign, setAppIsSentForESign] = React.useState(!!policy.document_transaction?.docusign_envelope_id);
  const [appIsBeingSentForESign, setAppIsBeingSentForESign] = React.useState(false);

  const [documentsForSignature, setDocumentsForSignature] = React.useState(new Set(documents));

  const renderFileCheckbox = (file: any) => {
    const { name, id } = file;
    const toggleChecked = (change: any) => {
      const docs = new Set(documentsForSignature);
      change.target.value ? docs.add(file) : docs.delete(file);
      setDocumentsForSignature(docs);
    };

    return (
      <p key={`doc_${id}`}>
        <Checkbox name={`doc_${id}`} value={documentsForSignature.has(file)} label={name} onChange={toggleChecked} />
      </p>
    );
  };

  return (
    <Modal
      open={isOpened}
      title={appIsSentForESign ? APP_IS_SENT_FOR_E_SIGN : SEND_APP_FOR_E_SIGN_TITLE}
      confirmText={appIsSentForESign ? GO_TO_DOCU_SIGN : SEND_APP_FOR_E_SIGN}
      confirmHandler={
        appIsSentForESign
          ? () => {
              window.open(policy.document_transaction?.docusign_envelope_url, '_blank', 'noopener,noreferrer');
            }
          : () => {
              setAppIsBeingSentForESign(true);
              uploadDocumentsForSignature({
                person_gid: person.gid,
                policy_id: policy.id,
                document_ids: Array.from(documentsForSignature).map((document: any) => document.id)
              })
                .then(() => {
                  setAppIsSentForESign(true);
                  setAppIsBeingSentForESign(false);
                })
                .catch(() => {
                  setAppIsBeingSentForESign(false);
                  handleOpened(false);
                });
            }
      }
      cancelHandler={() => handleOpened(false)}
      showCancelButton={!appIsBeingSentForESign}
      isSubmitting={appIsBeingSentForESign}
    >
      {appIsSentForESign ? (
        <div>
          <p>
            <a href={policy.document_transaction?.docusign_envelope_url}> DocuSign Envelope </a>
            is created. You can also check
            <a href={policy.document_transaction?.zendesk_ticket_url}> Zendesk ticket</a>.
          </p>
          <p>As application documents are signed, they will be automatically uploaded into files for this policy.</p>
        </div>
      ) : (
        <form>
          {documents.length ? (
            <div>{documents.map(renderFileCheckbox)}</div>
          ) : (
            <div>
              <p> No application documents. </p>
            </div>
          )}
        </form>
      )}
    </Modal>
  );
};

export default ESignModal;
