import './StepsProgress.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import stepDoneIcon from '../../../assets/img/icons/step-done.svg';

const StepsProgress = ({ className = '', steps, currentStepIndex }: any) => {
  const renderProgressIcon = (currentIndex: any) => {
    if (currentIndex < currentStepIndex) {
      return <img src={stepDoneIcon} alt="stepDone" className="steps-progress__icon" />;
    }

    const classList = [
      'steps-progress__icon',
      'steps-progress__icon--next',
      ...(currentStepIndex === currentIndex ? ['steps-progress__icon--current'] : [])
    ];

    return <div className={classNames(...classList)}>{currentIndex + 1}</div>;
  };

  const renderProgressSeparator = (currentIndex: any, steps: any[]) =>
    currentIndex + 1 !== steps.length && <div className="steps-progress__separator" />;

  return (
    <div className={classNames('steps-progress', className)}>
      {steps.map((step: any, index: any) => (
        <div className={classNames('steps-progress__step')} key={step}>
          {renderProgressIcon(index)}
          {step}
          {renderProgressSeparator(index, steps)}
        </div>
      ))}
    </div>
  );
};

StepsProgress.displayName = 'Step';

StepsProgress.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentStepIndex: PropTypes.number.isRequired
};

export default StepsProgress;
