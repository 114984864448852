import Grid from '@mui/material/Grid';
import * as React from 'react';
import * as yup from 'yup';

import { Translations } from '../../constants';
import { IAddressSuggestion, IPayloadAddress } from '../../interfaces';
import { HomeUsageType } from '../../interfaces/IPersonAsset';
import { PolicyType } from '../../interfaces/IPolicyType';
import { createSmartyStreetsMapping } from '../../utils/object';
import { requiredAddressSchema, requiredField } from '../../utils/yupRules';
import FormModal, { FormModalProps } from '../core/FormModal';
import { AddressField, SelectField } from '../core/forms/fields';
import { ModalSize } from '../core/Modal';

export const initialValues = {
  property_address: {} as IPayloadAddress,
  residence_type: ''
};

export const initialValuesWithPolicy = (policyType: PolicyType | undefined) => ({
  ...initialValues,
  residence_type: policyType === PolicyType.Fire ? HomeUsageType.Investment : ''
});

export const validationSchema = yup.object().shape({
  property_address: requiredAddressSchema,
  residence_type: requiredField
});

interface Props {
  cancelHandler: FormModalProps<typeof initialValues>['cancelHandler'];
  confirmHandler: (values: typeof initialValues) => void;
  isLoading?: FormModalProps<typeof initialValues>['confirmationInProgress'];
  defaultAddressOptions: IAddressSuggestion[];
}

export const HomeFormRenderer = ({
  defaultAddressOptions
}: {
  defaultAddressOptions: Props['defaultAddressOptions'];
}) => {
  return (
    <Grid container justifyContent="space-between">
      <Grid item container xs={12} columnSpacing={2} justifyContent="space-between">
        <Grid item xs={6}>
          <AddressField
            label="Property address"
            id="property_address"
            required
            defaultOptions={defaultAddressOptions}
            fallbackNames={createSmartyStreetsMapping('property_address.')}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectField
            required
            label="Residence type"
            id="residence_type"
            name="residence_type"
            options={Translations.homeUsageOptions}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const AddHomeFormModal = ({ cancelHandler, confirmHandler, isLoading, defaultAddressOptions }: Props) => (
  <FormModal
    size={ModalSize.large}
    confirmationInProgress={isLoading}
    initialValues={initialValues}
    title="Add home"
    confirmText="Add"
    confirmHandler={confirmHandler}
    cancelHandler={cancelHandler}
    validationSchema={validationSchema}
    renderForm={() => <HomeFormRenderer defaultAddressOptions={defaultAddressOptions} />}
  />
);

export default AddHomeFormModal;
