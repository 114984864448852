import './CustomerInfo.scss';

import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { API_TO_SMARTY_STREETS_MAPPING } from '../../../constants/addressForm';
import { dig, renameKeys, swapKeyValue } from '../../../utils/object';
import { getTodayDate, minDate, REQUIRED_MESSAGE } from '../../../utils/yupRules';
import { ErrorCol } from '../../common';
import SmartyStreetsAddress from '../../common/Address/Address';
import { DatePicker } from '../../common/FormikComponents/FormikComponents';
import Loader from '../../common/Loader';

const DEFAULT_DOB = '1981-01-01';
const BLANK = '';

class CustomerInfo extends React.Component<any, any> {
  isDefaultDOB = (values: { date_of_birth: any; line1?: any; line2?: any; city?: any; state?: any; zip?: any }) =>
    values.date_of_birth === DEFAULT_DOB;

  onSubmit = async ({ date_of_birth, ...address }: any, { setSubmitting }: any) => {
    try {
      await this.props.next({
        date_of_birth,
        address
      });
    } finally {
      (this as any).formik && setSubmitting(false);
    }
  };

  renderFooter = (disabled: boolean | undefined) => (
    <Col sm={12} className="btn-container p-0">
      <Button onClick={this.props.cancelHandler} className="cancel-button">
        Cancel
      </Button>
      <Button color="primary" type="submit" className="confirm-button" disabled={disabled}>
        Next
      </Button>
    </Col>
  );

  manualAddressValidation = (name: string) =>
    yup.string().test(name, 'Please, fill in the field', (value: any) => value && value.length > 0);

  render() {
    const { dateOfBirth, address, customer } = this.props;
    const initialValues = {
      date_of_birth: dateOfBirth || dig(customer, 'date_of_birth') || BLANK,
      line1: address?.line1 || BLANK,
      line2: address?.line2 || BLANK,
      city: address?.city || BLANK,
      state: address?.state || BLANK,
      zip: address?.zip || BLANK
    };

    return (
      <Formik
        innerRef={formik => ((this as any).formik = formik)}
        initialValues={initialValues}
        validationSchema={yup.object({
          date_of_birth: yup
            .date()
            .required(REQUIRED_MESSAGE)
            .min(minDate, 'Date of birth cannot be earlier than 01/01/1800')
            .max(getTodayDate(), 'Date of birth cannot be future date'),
          address: yup.mixed(),
          line1: this.manualAddressValidation('line1'),
          line2: yup.string(),
          city: this.manualAddressValidation('city'),
          state: this.manualAddressValidation('state'),
          zip: this.manualAddressValidation('zip')
        })}
        onSubmit={this.onSubmit}
      >
        {({ errors, values, touched, isSubmitting }) => {
          const showErrors = Object.keys(touched).length === Object.keys(values || {}).length;

          return (
            <>
              {isSubmitting && (
                <div className="mt-2 mb-2 ml-3 mr-3 d-flex flex-column justify-content-center align-items-center">
                  <Loader height={100} width={100} />
                </div>
              )}
              <Form className={classNames('step', 'customer-info', { 'd-none': isSubmitting })} autoComplete="off">
                <div className="step__title">Required customer information</div>
                <div className="customer-info__customer-name fs-mask">{customer.name}</div>
                <div className="mt-3">
                  <Row>
                    <ErrorCol
                      id="date_of_birth"
                      warning={this.isDefaultDOB(values) && 'That is the default DOB'}
                      error={showErrors && (errors.date_of_birth as any)}
                      when
                      sm={4}
                    >
                      <Label>Date of birth *</Label>
                      <Field
                        component={DatePicker}
                        name="date_of_birth"
                        placeholder="MM/DD/YYYY"
                        disabled={isSubmitting}
                        className="fs-mask"
                        maxDate={getTodayDate()}
                      />
                    </ErrorCol>
                    <div className="d-flex flex-column col-sm-8 p-0">
                      <ErrorCol id="address" error={showErrors && (errors as any).address} when sm={12}>
                        <SmartyStreetsAddress
                          label="Customer address *"
                          placeholder="Search for an address"
                          defaultValue={renameKeys(API_TO_SMARTY_STREETS_MAPPING, address || {}) as any}
                          defaultOptions={this.props.customerAddresses.map((address: any) =>
                            renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
                          )}
                          fallbackNames={swapKeyValue(API_TO_SMARTY_STREETS_MAPPING)}
                          showManualOption={false}
                        />
                      </ErrorCol>
                    </div>
                  </Row>
                </div>
                {this.renderFooter(isSubmitting)}
              </Form>
            </>
          );
        }}
      </Formik>
    );
  }
}

const ADDRESS_PROPS = PropTypes.shape({
  name: PropTypes.string,
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string
});
// @ts-expect-error old-code
CustomerInfo.propTypes = {
  customer: PropTypes.object.isRequired,
  customerAddresses: PropTypes.arrayOf(ADDRESS_PROPS),
  cancelHandler: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  dateOfBirth: PropTypes.string,
  address: ADDRESS_PROPS
};
// @ts-expect-error old-code
CustomerInfo.defaultProps = {
  customerAddresses: [],
  dateOfBirth: null,
  address: null
};

export default CustomerInfo;
