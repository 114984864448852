import * as React from 'react';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import useHomePolicyCoinsureds from '../../../queries/policies/useHomePolicyCoinsureds';
import usePolicyHomeCoverages from '../../../queries/policies/usePolicyHomeCoverages';
import usePolicyHomeEndorsements from '../../../queries/policies/usePolicyHomeEndorsements';
import usePolicyIvansHome from '../../../queries/policies/usePolicyIvansHome';
import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import BasicInfoSection from '../BasicInfoSection';
import CoverageSection from '../CoverageSection';
import DiscountsSection from '../DiscountsSection';
import {
  homeCoveragesDeductiblesSelector,
  homeDiscountsSelector,
  homeEndorsementsDeductiblesSelector,
  homeExcludedSelector,
  homeOtherLimitsSelector,
  homeSpecialLiabilityLimitsSelector
} from '../helpers';
import usePolicyTransactionFullTermPremium from '../hooks/usePolicyTransactionFullTermPremium';
import PolicyDetailsSectionPlaceholder from '../PolicyDetailsSectionPlaceholder';
import PremiumSection from '../PremiumSection';

const HomeLineOfBusiness = ({
  policy,
  lineOfBusiness,
  lastTransaction
}: {
  policy: IMaticPolicy;
  lineOfBusiness: LineOfBusiness;
  lastTransaction: PolicyTransaction;
}): JSX.Element => {
  const { data: ivansHome } = usePolicyIvansHome(lastTransaction.policy_id, lineOfBusiness.id, lastTransaction.id);
  const { data: coverages, isLoading: isLoadingCoverages } = usePolicyHomeCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    ivansHome?.id
  );
  const { data: coveragesDeductibles } = usePolicyHomeCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    ivansHome?.id,
    { select: homeCoveragesDeductiblesSelector }
  );

  const { data: endorsementsDeductibles } = usePolicyHomeEndorsements(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    ivansHome?.id,
    { select: homeEndorsementsDeductiblesSelector }
  );

  const { data: excluded, isLoading: isLoadingExcluded } = usePolicyHomeEndorsements(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    ivansHome?.id,
    {
      select: homeExcludedSelector
    }
  );
  const { data: specialLiabilityLimits, isLoading: isLoadingSpecialLiabilityLimits } = usePolicyHomeEndorsements(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    ivansHome?.id,
    {
      select: homeSpecialLiabilityLimitsSelector
    }
  );
  const { data: otherLimits, isLoading: isLoadingOtherLimitsSelector } = usePolicyHomeEndorsements(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    ivansHome?.id,
    {
      select: homeOtherLimitsSelector
    }
  );
  const { data: discounts, isLoading: isLoadingDiscounts } = usePolicyHomeEndorsements(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    ivansHome?.id,
    {
      select: homeDiscountsSelector
    }
  );

  const deductibles = [...(coveragesDeductibles || []), ...(endorsementsDeductibles || [])];

  const isEndorsementsLoading =
    isLoadingExcluded || isLoadingSpecialLiabilityLimits || isLoadingOtherLimitsSelector || isLoadingDiscounts;
  const showLoader = isLoadingCoverages || isEndorsementsLoading;

  const { data: coinsureds } = useHomePolicyCoinsureds(lastTransaction.policy_id, lastTransaction.effective_date);
  const premium = usePolicyTransactionFullTermPremium(lastTransaction, lineOfBusiness);

  return (
    <>
      <BasicInfoSection policy={policy} lineOfBusiness={lineOfBusiness} insureds={coinsureds} />
      {showLoader ? (
        <PolicyDetailsSectionPlaceholder linesCount={10} />
      ) : (
        <>
          {!!discounts?.length && <DiscountsSection discounts={discounts} />}
          {typeof premium === 'number' && <PremiumSection premium={premium} />}
          {!!deductibles?.length && <CoverageSection heading="Deductibles" isDeductible coverages={deductibles} />}
          {!!excluded?.length && <CoverageSection heading="Excluded" coverages={excluded} />}
          {!!coverages?.length && <CoverageSection heading="Basic coverages" coverages={coverages} />}
          {!!specialLiabilityLimits?.length && (
            <CoverageSection heading="Special liability limits" coverages={specialLiabilityLimits} />
          )}
          {!!otherLimits?.length && <CoverageSection heading="Other limits" coverages={otherLimits} />}
        </>
      )}
    </>
  );
};

export default HomeLineOfBusiness;
