import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
// eslint-disable-next-line max-len
import useSuspenseInteractionsStatusMessage from '../../../../queries/people/omni_channel_interactions/useInteractionsStatusMessages';
import parseHtmlString from '../../../../utils/htmlStringParser';

const CallHistory = () => {
  const { personGid } = useGuidedSellingExperienceContext();

  const { data: interactionsStatusMessage } = useSuspenseInteractionsStatusMessage(personGid);

  return <>{interactionsStatusMessage?.message && <Text>{parseHtmlString(interactionsStatusMessage.message)}</Text>}</>;
};

export default CallHistory;
