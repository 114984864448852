import { anyPass, isEmpty as isEmptyRamda, isNil, mergeDeepRight, omit, path, pick, reject } from 'ramda';

import { API_TO_SMARTY_STREETS_MAPPING } from '../constants/addressForm';

const isAPromise = (object: any) => Promise.resolve(object) === object;

export const isImageCached = (src: HTMLImageElement['src']) => {
  const image = new Image();
  image.src = src;

  return image.complete;
};

export const entries = (object: any) => Object.keys(object || {}).map(key => [key, object[key]]);

export const verifyPromise = (promise: any) => {
  if (isAPromise(promise)) {
    return promise;
  }

  throw new Error('Is not a promise');
};

export const dig = (object: any, ...rest: any[]) => path([...rest], object);
export const except = (object: any, ...keys: any[]) => object && omit([...keys], object);
export const slice = (object: any, ...keys: any[]) => object && pick([...keys], object);

export const rejectEmpty = (object: any) => object && reject(anyPass([isEmptyRamda, isNil]))(object);
export const values = (object: any) => object && Object.values(object);
export const keys = (object: any) => object && Object.keys(object);
export const swapKeyValue = (object: any) =>
  object && Object.assign({}, ...Object.entries(object).map(([a, b]) => ({ [b as string]: a })));
export const renameKeys = (keysMap: any, obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] }
    }),
    {}
  );

export const isEmpty = (object: any) => {
  for (const _ in object) {
    return false;
  }
  return true;
};

export const hasDifferentValues = (initial: any, current: any) =>
  Object.keys(initial).some(key => initial[key] !== current[key]);

// prefix should start with id prop of AddressField and end with dot symbol
export const createSmartyStreetsMapping = (prefix: any) =>
  Object.keys(swapKeyValue(API_TO_SMARTY_STREETS_MAPPING)).reduce((acc: any, key) => {
    acc[key] = `${prefix}${swapKeyValue(API_TO_SMARTY_STREETS_MAPPING)[key]}`;
    return acc;
  }, {});

export const deepMerge = (firstObj: any, secondObj: any) => mergeDeepRight(firstObj, secondObj);
