/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import Layout from './Layout';

const LoadingPage = () => (
  <Layout
    header={
      <FlexBox
        ph={spacings.px8}
        justifySpaceBetween
        customCss={css`
          height: 52px;
        `}
      >
        <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
          <FlexBox alignItemsCenter gap={spacings.px12}>
            <Heading
              type="h3"
              customCss={css`
                width: 140px;
              `}
            >
              <Skeleton />
            </Heading>

            <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
              <Heading
                css={css`
                  width: 24px;
                `}
              >
                <Skeleton circle />
              </Heading>
              <Heading
                type="h3"
                customCss={css`
                  width: 140px;
                `}
              >
                <Skeleton />
              </Heading>
              <Heading
                type="h3"
                customCss={css`
                  width: 140px;
                `}
              >
                <Skeleton />
              </Heading>
              <Heading
                type="h3"
                customCss={css`
                  width: 80px;
                `}
              >
                <Skeleton />
              </Heading>
            </SkeletonTheme>
          </FlexBox>
        </SkeletonTheme>
        <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
          <FlexBox alignItemsCenter gap={spacings.px12}>
            <Skeleton
              style={{
                width: '169px',
                height: '16px',
                borderRadius: '50px'
              }}
            />

            <Skeleton
              style={{
                width: '102px',
                height: '44px',
                borderRadius: '50px'
              }}
            />
            <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
              <Skeleton
                style={{
                  width: '102px',
                  height: '44px',
                  borderRadius: '50px'
                }}
              />
            </SkeletonTheme>
          </FlexBox>
        </SkeletonTheme>
      </FlexBox>
    }
    localNavigation={null}
    content={null}
    tabs={null}
  />
);

export default LoadingPage;
