/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { UseQueryResult } from '@tanstack/react-query';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import FlexBox from '../../../../components/core/FlexBox';
import { ChevronLeftDouble } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../../hooks';
import { DataCollection } from '../../../../queries/leads/data_collection/useDataCollection';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { DataCollectionStepper } from '../../../GuidedDataCollection/_hooks/useDataCollectionStepper';
import CandidateNavigation from './CandidateNavigation';
import EngagementNavigationSkeleton from './EngagementNavigationSkeleton';
import LeadNavigation from './LeadNavigation';
import { MENU_TRANSITION_DURATION } from './MenuItem';

interface EngagementNavigationProps {
  dataCollectionQuery: UseQueryResult<DataCollection, unknown>;
  dataCollectionStepper: DataCollectionStepper;
}

const EngagementNavigation = ({ dataCollectionQuery, dataCollectionStepper }: EngagementNavigationProps) => {
  const [menuOpened, toggleMenuOpened] = useToggle(false);

  const { leadGid } = useGuidedSellingExperienceContext();

  if (leadGid && !dataCollectionQuery.data) {
    return <EngagementNavigationSkeleton />;
  }

  return (
    <FlexBox
      columnDirection
      gap={spacings.px16}
      pv={spacings.px24}
      ph={spacings.px16}
      onClick={toggleMenuOpened}
      customCss={css`
        background-color: ${colors.grey5};
        width: ${menuOpened ? '240px' : '54px'};
        transition: width ${MENU_TRANSITION_DURATION} ease;

        &:hover {
          cursor: pointer;
        }
      `}
    >
      <FlexBox justifyRight>
        <Button variant={ButtonVariant.Text} data-testid="engagement-navigation-button">
          <FlexBox>
            <Text
              bold
              singleLine
              type="small"
              color={colors.black}
              customCss={css`
                visibility: ${menuOpened ? 'visible' : 'hidden'};
                opacity: ${menuOpened ? 1 : 0};
                transition-property: visibility, opacity;
                transition-duration: ${MENU_TRANSITION_DURATION};
                transition-timing-function: ease;
              `}
            >
              Hide menu
            </Text>
            <ChevronLeftDouble
              css={css`
                color: ${colors.black};
                min-width: fit-content;
                min-height: fit-content;

                margin-left: ${menuOpened ? spacings.px8 : spacings.px0}px;
                transform: ${menuOpened ? '' : 'rotate(-180deg)'};

                transition-property: transform, margin-left;
                transition-duration: ${MENU_TRANSITION_DURATION};
                transition-timing-function: ease;
              `}
            />
          </FlexBox>
        </Button>
      </FlexBox>
      {dataCollectionQuery.data ? (
        <LeadNavigation
          dataCollection={dataCollectionQuery.data}
          dataCollectionStepper={dataCollectionStepper}
          menuOpened={menuOpened}
        />
      ) : (
        <CandidateNavigation menuOpened={menuOpened} />
      )}
    </FlexBox>
  );
};

export default EngagementNavigation;
