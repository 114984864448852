/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Paragraph from '../../../components/core/Paragraph';
import colors from '../../../theme/colors';
import { borderRadius, spacings } from '../../../theme/variables';

interface NewAnnouncementNotificationProps {
  onConfirm: () => void;
}

const NewAnnouncementNotification = ({ onConfirm }: NewAnnouncementNotificationProps) => {
  const tipRef = React.useRef<any>(null);
  const outerRef = React.useRef<any>(null);

  React.useEffect(() => {
    outerRef.current && ReactTooltip.show(outerRef.current);
  }, []);

  return (
    <>
      <div data-tip data-for="new-announcement" ref={outerRef} />
      <Tooltip
        id="new-announcement"
        place="right"
        innerRef={tipRef}
        clickable
        scrollHide={false}
        theme="custom"
        primaryColor={colors.violet}
        secondaryColor={colors.violet}
        offset={{ top: 24 }}
        customCss={css`
          border-radius: ${borderRadius}px !important;
        `}
      >
        <Container
          customCss={css`
            max-width: 282px;
          `}
        >
          <Paragraph color={colors.white} mb={spacings.px8}>
            Check out our release notes for the latest updates and improvements. Stay informed about exciting features
            and enhancements.
          </Paragraph>
          <FlexBox
            customCss={css`
              justify-content: flex-end;
            `}
          >
            <Button
              data-testid="new-announcement-confirm-button"
              onClick={e => {
                tipRef.current.tooltipRef = null;

                ReactTooltip.hide(tipRef.current);
                ReactTooltip.hide(outerRef.current);
                onConfirm();
                e.stopPropagation();
              }}
              customCss={css`
                color: ${colors.white};
                background-color: ${colors.violet};
                border-color: ${colors.white};
              `}
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
            >
              Got it!
            </Button>
          </FlexBox>
        </Container>
      </Tooltip>
    </>
  );
};

export default NewAnnouncementNotification;
