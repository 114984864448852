/** @jsxImportSource @emotion/react */
import 'rc-time-picker/assets/index.css';

import { css, Global } from '@emotion/react';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import * as React from 'react';

import colors from '../../../../theme/colors';
import { borderRadius } from '../../../../theme/variables';
import { DISPLAY_TIME_FORMAT } from '../../../../utils/formatter';
import { InputProps } from '../Base';

export interface TimeInputProps extends InputProps {
  onTimeChange: (newValue: Date | string) => void;
  value?: string;
}

const TimeInput = ({ value, onTimeChange, inline, ...props }: TimeInputProps): JSX.Element => {
  const defaultValue = value ? moment(value) : undefined;

  return (
    <div
      css={css`
        position: relative;
      `}
    >
      <Global
        styles={css`
          .time-picker {
            display: block;
            box-sizing: border-box;
          }
          .rc-time-picker-panel-select {
            overflow-y: auto;
          }
          .rc-time-picker-input {
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 36px;
            padding-right: 12px;
            padding-left: 12px;
            color: ${colors.black};
            font-weight: 500;
            font-size: 16px;
            font-family: freight-sans-pro;
            border: ${inline ? 'none' : `1px solid ${colors.black}`};
            border-radius: ${borderRadius}px;
            outline-color: ${colors.azure50};
            outline-offset: 3px;
          }
          .rc-time-picker-clear-icon {
            &::after {
              position: relative;
              top: 4px;
              color: ${colors.black};
              font-size: 18px;
            }
          }
          .rc-time-picker-panel-inner {
            color: ${colors.black} !important;
            font-weight: 500 !important;
            font-size: 16px !important;
            font-family: freight-sans-pro !important;
            border: 1px solid ${colors.grey30} !important;
            box-shadow: none !important;
          }
          .rc-time-picker-panel-combobox {
            li {
              color: ${colors.black};
              font-weight: 500;
              font-size: 16px;
              font-family: freight-sans-pro;
              &:hover {
                background: ${colors.grey5};
              }
            }
            li.rc-time-picker-panel-select-option-selected {
              background: ${colors.grey10};
            }
          }
        `}
      />
      <TimePicker
        {...props}
        showSecond={false}
        defaultValue={defaultValue}
        className="time-picker"
        onChange={onTimeChange as any}
        format={DISPLAY_TIME_FORMAT}
        use12Hours
      />
    </div>
  );
};

export default TimeInput;
