import { skipToken, useQuery } from '@tanstack/react-query';

import { IPremiumPrediction } from '../../../interfaces';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

enum OriginAssetType {
  Home = 'home',
  Vehicle = 'vehicle'
}

interface PremiumPredictionFilters {
  asset_type?: OriginAssetType;
  state?: string;
  carrier_gid?: string;
  premium?: string | null;
  date?: string | null;
}

export const getPremiumPrediction = (
  filters: PremiumPredictionFilters
): Promise<{ average_premium: IPremiumPrediction }> =>
  api.get(`/api/frontend/policies/average_premium?${toQueryParams(filters)}`);

const usePremiumPrediction = (filters: PremiumPredictionFilters) =>
  useQuery({
    queryKey: ['premium_prediction', filters],
    queryFn: !!filters.state && !!filters.asset_type ? () => getPremiumPrediction(filters) : skipToken,
    select: data => data.average_premium
  });

export default usePremiumPrediction;
