import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import useLeadLicensingStatus from '../../../../queries/leads/useLeadLicensingStatus';
import LeadLicensingWarning from '../../../GuidedDataCollection/_components/LeadLicensingWarning';

const UserMissingLicenseWarning = () => {
  const { lead } = useGuidedSellingExperienceContext();

  const { data: licensingStatus } = useLeadLicensingStatus(lead?.id);

  if (!licensingStatus) {
    return null;
  }

  return (
    <LeadLicensingWarning
      is_user_licensed={licensingStatus.is_user_licensed}
      primary_state={licensingStatus.primary_state}
    />
  );
};

export default UserMissingLicenseWarning;
