/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';

import FlexBox from '../../components/core/FlexBox';
import Text from '../../components/core/Text';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { usePersonScheduledInteractions } from '../../queries/scheduled_calls/useScheduledInteractions';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { DASHBOARD_DATE_TIME_FORMAT, dateTimeFormatter, fixTZName, userTimezone } from '../../utils/formatter';
import CallPopper from './CallPopper';
import EditScheduledCall from './EditScheduledCall';
import { findUncompletedCall, getCallSource } from './helpers';

const Indicator = ({
  personGid,
  containerCss,
  leadGid,
  candidateGid,
  withPopper,
  disabled = false
}: {
  personGid: string;
  containerCss?: SerializedStyles;
  leadGid: string | undefined;
  candidateGid: string | undefined;
  withPopper?: boolean;
  disabled?: boolean;
}): JSX.Element => {
  const { data: scheduledCalls, refetch: refetchInteractions } = usePersonScheduledInteractions(personGid, [
    ScheduledInteractionFlow.ScheduledCall,
    ScheduledInteractionFlow.ScheduledCallIsrs,
    ScheduledInteractionFlow.ScheduledCallV2,
    ScheduledInteractionFlow.ScheduledCallEmail,
    ScheduledInteractionFlow.Calendly,
    ScheduledInteractionFlow.ScheduledByRecapture,
    ScheduledInteractionFlow.CalendlyFollowUp
  ]);

  const scheduledCall = findUncompletedCall(scheduledCalls);

  const callTime = moment(scheduledCall?.run_at);

  return (
    <>
      {scheduledCall && (
        <FlexBox justifyCenter customCss={containerCss}>
          <FlexBox
            data-testid="schedule-call-indicator"
            roundBorder
            border={!withPopper}
            backgroundColor={withPopper ? colors.grey5 : undefined}
            customCss={css`
              padding: 2px ${spacings.px12}px;
            `}
          >
            <FlexBox gap={spacings.px12} alignItemsCenter>
              {withPopper ? (
                <CallPopper
                  scheduledCall={scheduledCall}
                  time={callTime}
                  personGid={personGid}
                  engagementGid={leadGid || candidateGid}
                  disabled={disabled}
                >
                  <EditScheduledCall
                    scheduledCall={scheduledCall}
                    refetch={refetchInteractions}
                    leadGid={leadGid}
                    candidateGid={candidateGid}
                    withActions={false}
                    disabled={disabled}
                  />
                </CallPopper>
              ) : (
                <>
                  <Text bold color={colors.statusGreen}>
                    Call scheduled
                  </Text>
                  <Text type="tiny" color={colors.grey60}>
                    {`${dateTimeFormatter(callTime, DASHBOARD_DATE_TIME_FORMAT)}
               (${userTimezone.abbreviation || fixTZName(userTimezone.name)}) ${getCallSource(scheduledCall)}`}
                  </Text>
                </>
              )}
            </FlexBox>
            {!withPopper && (
              <EditScheduledCall
                scheduledCall={scheduledCall}
                refetch={refetchInteractions}
                leadGid={leadGid}
                candidateGid={candidateGid}
                disabled={disabled}
              />
            )}
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default Indicator;
