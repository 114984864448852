/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Loader from '../../components/common/Loader';
import useCallNotes from '../../queries/scheduled_calls/useCallNotes';
import { dateTimeFormatter } from '../../utils/formatter';

const Description = ({ gid, created_at }: { gid: string; created_at: string }): JSX.Element => {
  const { data } = useCallNotes(gid, created_at);

  const notesBlock = data?.map(note => (
    <div
      data-testid="expended-note"
      className="fs-mask"
      key={`note-${note.created_at}`}
      css={css`
        margin-top: 16px;

        :first-of-type {
          margin-top: 0;
        }
      `}
    >
      <div
        data-testid="note-description"
        css={css`
          font-size: 18px;
        `}
      >
        {note.description}
      </div>
      <div
        data-testid="note-sign"
        css={css`
          margin-top: 4px;
        `}
      >
        Left at <b>{dateTimeFormatter(note.created_at)}</b> by <b>{note.agent.name}</b>
      </div>
    </div>
  ));

  return <>{notesBlock || <Loader width="24px" height="24px" />}</>;
};

export default Description;
