/** @jsxImportSource @emotion/react */
import * as React from 'react';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import BasicInfoSection from '../BasicInfoSection';
import usePolicyTransactionFullTermPremium from '../hooks/usePolicyTransactionFullTermPremium';
import PremiumSection from '../PremiumSection';

const OtherLineOfBusiness = ({
  policy,
  lineOfBusiness,
  lastTransaction
}: {
  policy: IMaticPolicy;
  lineOfBusiness: LineOfBusiness;
  lastTransaction: PolicyTransaction;
}): JSX.Element => {
  const premium = usePolicyTransactionFullTermPremium(lastTransaction, lineOfBusiness);

  return (
    <>
      <BasicInfoSection policy={policy} lineOfBusiness={lineOfBusiness} />
      {typeof premium === 'number' && <PremiumSection premium={premium} />}
    </>
  );
};

export default OtherLineOfBusiness;
