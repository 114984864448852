enum DatapointKey {
  PersonMailingAddress = 'person_mailing_address',
  PersonIsInterestedInHomeInsurance = 'person_is_interested_in_home_insurance',
  PersonHomeInsuranceRejectedReason = 'person_home_insurance_rejection_reason',
  PersonHasCoreBundleInterest = 'person_has_core_bundle_interest',

  PropertyAddress = 'property_address',
  PropertyUsageType = 'property_usage_type',
  PropertyOwnershipType = 'property_ownership_type',
  PropertyYearBuilt = 'property_year_built',
  PropertyOnTheDeed = 'property_on_the_deed',
  OpportunityIsHomePurchase = 'opportunity_is_home_purchase', // TODO: Drop question and datapoint from CRE.
  PropertyPurchaseDate = 'property_purchase_date',
  PropertyType = 'property_type',
  PropertySquareFootage = 'property_square_feet',
  PropertyLLCName = 'property_llc_name',
  PropertyTrustName = 'property_trust_name',

  PersonRelationKind = 'person_relation_kind',

  PersonFirstName = 'person_first_name',
  PersonLastName = 'person_last_name',
  PersonMiddleName = 'person_middle_name',
  PersonPhone = 'person_phone',
  PersonPhoneSecondary = 'person_phone_secondary',
  PersonPhoneBusiness = 'person_phone_business',
  PersonEmail = 'person_email',
  PersonDateOfBirth = 'person_date_of_birth',
  PersonGender = 'person_gender',
  PersonMaritalStatus = 'person_marital_status',
  PersonLicenseNumber = 'person_license_number',
  PersonLicenseState = 'person_license_state',

  VehicleAddress = 'vehicle_address',
  VehicleAnnualMileage = 'vehicle_annual_mileage',
  VehicleDailyMileage = 'vehicle_daily_mileage',
  VehicleDaysDrivenWeekly = 'vehicle_days_driven_weekly',
  VehicleKind = 'vehicle_kind',
  VehicleMake = 'vehicle_make',
  VehicleModel = 'vehicle_model',
  VehicleYear = 'vehicle_year',
  VehicleSubmodel = 'vehicle_submodel',
  VehicleOwnership = 'vehicle_ownership',
  VehicleUsage = 'vehicle_usage',
  VehicleVin = 'vehicle_vin',

  PersonVehiclesTitledAndRegisteredToDrivers = 'person_vehicles_titled_and_registered_to_drivers',
  PersonHadViolationIn5PastYears = 'person_had_violations_in_5_past_years',

  // Life profile
  PersonLivingAddress = 'person_living_address',
  PersonSex = 'person_sex',
  PersonTobaccoUse = 'person_tobacco_use',
  PersonHealthStatus = 'person_health_status',
  PersonCoverageLimit = 'person_coverage_limit',
  PersonTermLength = 'person_term_length'
}

export enum PropertyTypeDatapointValues {
  SingleFamilyDetached = 'single_family_detached',
  Condominium = 'condominium',
  Duplex = 'duplex',
  Triplex = 'triplex',
  Townhouse = 'townhouse',
  RowHome = 'rowhome'
}

export enum RoofShapeDatapointValues {
  Hip = 'hip',
  Gable = 'gable',
  Flat = 'flat',
  Gambrel = 'gambrel',
  Shed = 'shed'
}

export enum FoundationDatapointValues {
  Slab = 'slab',
  CrawlSpace = 'crawl_space',
  Basement = 'basement',
  Pilings = 'pilings',
  NoFoundation = 'no_foundation'
}

export const MY_SPOUSE_VALUE = 'my_spouse';

const NO_VALUE = 'no';
export const YES_VALUE = 'yes';

export const NO_YES_OPTIONS = [
  { id: NO_VALUE, value: NO_VALUE, label: 'No' },
  { id: YES_VALUE, value: YES_VALUE, label: 'Yes' }
];

export const PROPERTY_TYPE_CONDO = 'condominium';
export const PROPERTY_TYPE_APARTMENT = 'apartment';

export enum DatapointsUsageKey {
  PersonHasCoreBundleInterest = 'amp_person_has_core_bundle_interest',
  CustomerProfileInfo = 'amp_customer_profile_info',
  PersonRelationKind = 'amp_person_relation_kind',
  VehicleProfileInfo = 'amp_vehicle_profile_info',
  VehicleProfileAdditionalInfo = 'amp_vehicle_profile_additional_info',
  PropertyOnTheDeed = 'amp_on_the_deed'
}

export default DatapointKey;
