/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import { PDFContext } from '../../../components/common/PDFViewer';
import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import alert from '../../../components/core/Alert';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import CollapsingContainer from '../../../components/core/CollapsingContainer';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { DocumentIcon, DocumentWithTickIcon } from '../../../components/core/icons';
import Modal, { ModalSize } from '../../../components/core/Modal';
import Paragraph from '../../../components/core/Paragraph';
import Tag from '../../../components/core/Tag';
import ConfirmUploadedFilesModal from '../../../components/FileModals/ConfirmModal';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { ICarrier } from '../../../interfaces';
import { FileToConfirm, FileToUpload, IDocument } from '../../../interfaces/IDocument';
import { PolicyType } from '../../../interfaces/IPolicyType';
import {
  DocumentRule,
  RuleDemands,
  useDocumentRules,
  usePolicyDocumentRules
} from '../../../queries/document_rules/useDocumentRules';
import useDocumentTypes, { DocumentType } from '../../../queries/document_types/useDocumentTypes';
import { CreatePolicyRequest } from '../../../queries/people/person_policies/useCreateMaticPolicy';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import useVerticalTabsCallbacks from '../../GuidedSellingExperience/_hooks/useVerticalTabsCallbacks';
import Dropzone from '../../GuidedSellingExperience/tabs/FilesTab/Dropzone';
import DocumentInstructions from '../_modals/DocumentInstructions';
import DocumentRow from './DocumentRow';

interface DocumentsProps {
  policyType: PolicyType;
  state: string | undefined;
  carrier: ICarrier;
  policyId: number | null;
  onAttach: (files: FileToUpload[]) => Promise<unknown>;
  onDelete: (file: FileToUpload | IDocument) => Promise<unknown>;
  files: (FileToUpload | IDocument)[];
  // Please memo it!!!
  syncDocumentRules: (rules: CreatePolicyRequest['document_rules_policies_attributes']) => void;
  newClosing?: boolean;
}

const demandPriority: { [key: string]: number } = {
  [RuleDemands.Required]: 0,
  [RuleDemands.Optional]: 1,
  [RuleDemands.Unavailable]: 2
};
const sortByDemand = (a: DocumentRule, b: DocumentRule) => {
  const demandA = a.demand;
  const demandB = b.demand;

  if (demandA === demandB) {
    return 0;
  }

  return demandPriority[demandA]! < demandPriority[demandB]! ? -1 : 1;
};

const filterRulesWithoutDocType = (rules: DocumentRule[], docTypes: DocumentType[]) => {
  const existingDocTypesIds = docTypes.map(t => t.id);

  return rules.filter(r => existingDocTypesIds.includes(r.document_type_id));
};

const DEC_PAGE_KEY = 'carrier_dec_page';
const EMPTY_ARRAY: DocumentRule[] = [];
const STABLE_DOC_TYPES_ARRAY: DocumentType[] = [];

const responsibleHash = {
  cst: 'CST',
  agent: 'Agent'
} as const;

const Documents = ({
  files,
  policyType,
  state,
  carrier,
  policyId,
  onAttach,
  onDelete,
  syncDocumentRules,
  newClosing
}: DocumentsProps) => {
  const { sourceDimensions } = useGuidedSellingExperienceContext();
  const [, setFileToPreview] = React.useContext(PDFContext);
  const pdfPreviewShownRef = React.useRef(false);
  const { data: documentRules = EMPTY_ARRAY } = useDocumentRules({
    carrier_id: carrier.id,
    state,
    policy_type: policyType,
    partner: sourceDimensions?.partner
  });
  const { onTabClose } = useVerticalTabsCallbacks();

  const { transformedData: docTypes = STABLE_DOC_TYPES_ARRAY, isSuccess: documentTypesLoaded } = useDocumentTypes(true);
  const { data: policyDocumentRules = EMPTY_ARRAY } = usePolicyDocumentRules(policyId);

  const [docsAttributes, setDocsAttributes] = React.useState<DocumentRule[]>(EMPTY_ARRAY);

  React.useEffect(() => {
    setDocsAttributes(filterRulesWithoutDocType(policyId ? policyDocumentRules : documentRules, docTypes));
  }, [policyId, policyDocumentRules, documentRules, docTypes]);

  React.useEffect(() => {
    syncDocumentRules(
      docsAttributes.map(d => ({
        demand: d.demand,
        responsible: d.responsible,
        could_not_collect: d.could_not_collect || false,
        document_rule_id: d.document_rule_id
      }))
    );
  }, [docsAttributes, syncDocumentRules]);

  const [filesToConfirm, setFilesToConfirm] = React.useState<FileToConfirm[]>([]);
  const [documentToDelete, setDocumentToDelete] = React.useState<DocumentRule>();

  const closeModal = () => setFilesToConfirm([]);

  const suggestDocType = (name: string): number =>
    (docTypes.find(type => !!(name.match(type.regexp) || [])[0])?.id || '') as number;

  const couldNotCollectAvailable = (r: DocumentRule) =>
    authInfo.features.set_policy_documents_could_not_collect &&
    (r.could_not_collect || docTypes.find(t => t.id === r.document_type_id)?.could_not_collect);

  const invoiceTypeId = docTypes.find(d => d.key === 'invoice')?.id;
  const filteredDocsAttributes = docsAttributes.map(obj => {
    if (obj.document_type_id === invoiceTypeId && !newClosing) {
      return { ...obj, demand: RuleDemands.Optional };
    }

    return obj;
  });

  const requiredDocs = filteredDocsAttributes.filter(d => d.demand === RuleDemands.Required);
  const sortedDocs = filteredDocsAttributes
    .filter(d => d.demand !== RuleDemands.Required)
    .sort(sortByDemand)
    .filter(d => !d.could_not_collect);

  const filesToUpload = files.filter((f): f is FileToUpload => 'uid' in f);
  const documents = files.filter((f): f is IDocument => 'id' in f);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <>
      <Container>
        <Paragraph type="large" bold>
          Documents
        </Paragraph>
        <FlexBox justifySpaceBetween mt={spacings.px16} mb={spacings.px12}>
          <Paragraph type="large" bold>
            Recommended list of documents
          </Paragraph>
          <DocumentInstructions carrier={carrier} documentTypes={docTypes} />
        </FlexBox>
        <Paragraph>
          You still can proceed without docs, we will create automatically a task for you to upload them.
        </Paragraph>
        {documentTypesLoaded && requiredDocs[0] && (
          <FlexBox
            mt={spacings.px12}
            customCss={css`
              flex-wrap: wrap;
            `}
          >
            {requiredDocs.map(rule => {
              return (
                <FlexBox
                  alignItemsCenter
                  customCss={css`
                    flex-basis: 50%;
                  `}
                  pv={spacings.px12}
                  gap={spacings.px8}
                  key={rule.document_rule_id}
                >
                  {files.find(f => f.document_type_id === rule.document_type_id) ? (
                    <DocumentWithTickIcon color={colors.statusGreen} />
                  ) : (
                    <DocumentIcon color={colors.grey60} />
                  )}
                  {docTypes.find(t => t.id === rule.document_type_id)?.title || 'Unknown'}
                  <Tag
                    data-tip="Responsible for collecting"
                    data-for="responsible-tip"
                    label={responsibleHash[rule.responsible]}
                    transparent
                    bold
                    backgroundColor={colors.grey80}
                    textColor={colors.grey60}
                  />
                  {couldNotCollectAvailable(rule) && (
                    <IconButton
                      data-for="could-not-collect-tip"
                      data-tip="Could not collect"
                      icon={ButtonIcons.Clear}
                      color={colors.black}
                      onClick={() => setDocumentToDelete(rule)}
                    />
                  )}
                </FlexBox>
              );
            })}
          </FlexBox>
        )}

        {documentTypesLoaded && sortedDocs[0] && (
          <Container mt={spacings.px16}>
            <CollapsingContainer
              title="Other documents"
              openedByDefault={false}
              preserveChildrenInClosedState
              iconPosition="left"
            >
              <FlexBox
                customCss={css`
                  flex-wrap: wrap;
                `}
              >
                {sortedDocs.map(rule => {
                  return (
                    <FlexBox
                      alignItemsCenter
                      customCss={css`
                        flex-basis: 50%;
                      `}
                      pv={spacings.px12}
                      gap={spacings.px8}
                      key={rule.document_rule_id}
                    >
                      {files.find(f => f.document_type_id === rule.document_type_id) ? (
                        <DocumentWithTickIcon color={colors.statusGreen} />
                      ) : (
                        <DocumentIcon color={colors.grey60} />
                      )}
                      {docTypes.find(t => t.id === rule.document_type_id)?.title || 'Unknown'}
                      <Tag
                        data-tip="Responsible for collecting"
                        data-for="responsible-tip"
                        label={responsibleHash[rule.responsible]}
                        transparent
                        bold
                        backgroundColor={colors.grey80}
                        textColor={colors.grey60}
                      />
                      {couldNotCollectAvailable(rule) && (
                        <IconButton
                          data-for="could-not-collect-tip"
                          data-tip="Could not collect"
                          icon={ButtonIcons.Clear}
                          color={colors.black}
                          onClick={() => setDocumentToDelete(rule)}
                        />
                      )}
                    </FlexBox>
                  );
                })}
              </FlexBox>
            </CollapsingContainer>
          </Container>
        )}
        <Tooltip id="could-not-collect-tip" />
        <Tooltip id="responsible-tip" />
        {filesToConfirm.length > 0 && (
          <ConfirmUploadedFilesModal
            files={filesToConfirm.map(f => ({ ...f, document_type_id: suggestDocType(f.name), fileOwner: 'policy' }))}
            cancelHandler={() => closeModal()}
            confirmHandler={filesToUpload => {
              closeModal();
              onAttach(filesToUpload);

              if (!pdfPreviewShownRef.current) {
                const decPageId = docTypes.find(doc => doc.key === DEC_PAGE_KEY)?.id || -1;
                const fileToPreview = filesToUpload.find(file => file.document_type_id === decPageId);
                if (fileToPreview) {
                  setFileToPreview(fileToPreview);
                  pdfPreviewShownRef.current = true;
                  onTabClose();
                }
              }

              return Promise.resolve();
            }}
            owners={[{ key: 'policy', value: 'Policy', description: '' }]}
          />
        )}
        <Dropzone
          onReject={invalidFiles =>
            invalidFiles.length > 0 &&
            alert({
              title: 'Error uploading files',
              items: invalidFiles.map(({ file, error }) => `${file.name} - ${error}`)
            }).error()
          }
          onUpload={files => setFilesToConfirm(files)}
        />

        {documentToDelete && (
          <Modal
            confirmText="Delete document"
            size={ModalSize.large}
            dangerModal
            confirmHandler={() => {
              setDocumentToDelete(undefined);
              setDocsAttributes(current => {
                const ruleIndex = current.findIndex(
                  oldRule => oldRule.document_rule_id === documentToDelete.document_rule_id
                );
                current[ruleIndex] = { ...documentToDelete, could_not_collect: true };

                return [...current];
              });
            }}
            cancelHandler={() => setDocumentToDelete(undefined)}
            title="You are going to delete a document type from the recommended list"
          >
            <Paragraph>
              Only delete this document if the carrier does not require it for your case. Otherwise, it may lead to the
              cancellation of customer&apos;s policy. Once you delete this document from the list, we will not remind
              you to collect it.
            </Paragraph>
          </Modal>
        )}
      </Container>

      <FlexBox columnDirection>
        {filesToUpload.map(file => (
          <DocumentRow key={file.uid} file={file} onDelete={onDelete} />
        ))}
        {documents.map(file => (
          <DocumentRow key={file.id} file={file} onDelete={onDelete} />
        ))}
      </FlexBox>
    </>
  );
};

export default Documents;
