/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import { ChevronLeft } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import colors from '../../../theme/colors';
import { borderRadius, spacings } from '../../../theme/variables';
import { IDataCollectionStep } from '../_interfaces/IDataCollectionStep';
import DataCollectionStepIndicator from './DataCollectionStepIndicator';
import DataCollectionSubStep, { stepColor } from './DataCollectionSubStep';

interface DataCollectionStepProps {
  currentStep: IDataCollectionStep;
  steps: IDataCollectionStep[];
  showIndex: boolean;
}

const useAltNavigation = ({ step, showIndex }: { step: IDataCollectionStep; showIndex: boolean }) => {
  const pressHandler = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.altKey && event.code.includes(`Digit${step.index + 1}`)) {
        !step.is_navigation_disabled && step.is_data_collection_enabled && step.onClick();
      }
    },
    [step]
  );

  React.useEffect(() => {
    if (showIndex) {
      window.addEventListener('keypress', pressHandler);
    }

    return () => {
      if (showIndex) {
        window.removeEventListener('keypress', pressHandler);
      }
    };
  }, [showIndex, pressHandler]);
};

const DataCollectionStep = ({ currentStep, steps, showIndex }: DataCollectionStepProps) => {
  const [showSubSteps, setShowSubSteps] = React.useState<boolean>(false);

  useAltNavigation({ step: currentStep, showIndex });

  const stepSubSteps = currentStep.is_parent ? steps.filter(step => step.parent_key === currentStep.key) : undefined;

  React.useEffect(() => {
    if ((currentStep.is_parent && currentStep.active) || stepSubSteps?.some(subStep => subStep.active)) {
      setShowSubSteps(true);
    } else {
      setShowSubSteps(false);
    }
  }, [currentStep.active, currentStep.is_parent, stepSubSteps]);

  return (
    <>
      {!currentStep.parent_key && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
          `}
        >
          <Button
            onClick={event => {
              currentStep.onClick();
              event.stopPropagation();
            }}
            variant={ButtonVariant.Text}
            disabled={currentStep.is_navigation_disabled || !currentStep.is_data_collection_enabled}
            customCss={css`
              position: relative;
              display: flex;
              align-items: center;
              padding: ${spacings.px8}px;
              padding-right: ${spacings.px24}px;
              background-color: ${currentStep.active && stepSubSteps === undefined ? colors.grey5 : 'transparent'};
              border-radius: ${borderRadius}px;
              &:hover {
                background-color: ${colors.grey5};
              }
            `}
          >
            <div
              css={css`
                height: 100%;
                margin-right: ${spacings.px8}px;
                position: relative;
                bottom: 1px;
              `}
            >
              <DataCollectionStepIndicator step={currentStep} subSteps={stepSubSteps} />
            </div>
            <Paragraph
              customCss={css`
                text-align: start;
                white-space: nowrap;
                ${showIndex &&
                `&:after {
                  margin-left: 4px;
                  content: '${currentStep.index + 1}';
                }`}
              `}
              type="small"
              bold
              color={stepColor(currentStep)}
            >
              {currentStep.label}
              {stepSubSteps?.length ? (
                <span
                  css={css`
                    margin-left: ${spacings.px8}px;
                    &:hover {
                      opacity: 0.7;
                    }
                  `}
                  onClick={event => {
                    setShowSubSteps(current => !current);
                    event.stopPropagation();
                  }}
                >
                  <ChevronLeft
                    color={currentStep.is_navigation_disabled ? colors.grey60 : colors.black}
                    css={css`
                      transform: rotate(${showSubSteps ? '90deg' : '270deg'});
                    `}
                  />
                </span>
              ) : null}
            </Paragraph>
          </Button>

          {stepSubSteps &&
            showSubSteps &&
            stepSubSteps.map(subStep => {
              return <DataCollectionSubStep key={subStep.key} subStep={subStep} showIndex={showIndex} />;
            })}
        </div>
      )}
    </>
  );
};

export default DataCollectionStep;
