import { useQuery } from '@tanstack/react-query';

import { AutoCoveragesOptions } from '../../interfaces/IAutoCoveragesOptions';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export const getAutoCoveragesOptions = ({
  state,
  year,
  ownership
}: {
  state: string | null;
  year: number | null;
  ownership: string | null;
}): Promise<{ auto_coverage_options: AutoCoveragesOptions }> =>
  api.get(
    `/api/frontend/auto_coverages?${toQueryParams({
      state,
      year,
      ownership
    })}`
  );

const useAutoCoveragesOptions = ({
  state,
  year,
  ownership
}: {
  state: string | null;
  year: number | null;
  ownership: string | null;
}) =>
  useQuery({
    queryKey: ['auto_coverages_options', state, year, ownership],
    queryFn: () => getAutoCoveragesOptions({ state, year, ownership }),
    enabled: !!state,
    select: data => data.auto_coverage_options
  });

export default useAutoCoveragesOptions;
