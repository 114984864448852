import * as yup from 'yup';

import { CITY, LINE1, LINE2, STATE, ZIP } from '../../../constants/addressForm';
import { IPerson, IRelatedPerson } from '../../../interfaces/IPerson';
import authInfo from '../../../services/authInfo';
import { phoneFormatter } from '../../../utils/formatter';
import {
  emailField,
  getTodayDate,
  minDate,
  phoneField,
  REQUIRED_MESSAGE,
  requiredAddressSchema,
  requiredField,
  requiredMinMaxDOBField
} from '../../../utils/yupRules';

export enum DriverFields {
  Phone = 'phone',
  Email = 'email',
  LivingAddress = 'living_address',

  DateOfBirth = 'date_of_birth',
  Gender = 'gender',
  MaritalStatus = 'marital_status',
  LicenseStatus = 'license_status',
  LicenseNumber = 'license_number',
  LicenseState = 'license_state',
  AgeFirstLicensed = 'age_first_licensed',
  Education = 'education',
  OccupationType = 'occupation_type',
  OccupationSinceDate = 'occupation_since_date',
  PrimaryDrivenVehicleGid = 'primary_driven_vehicle_gid',
  Relationship = 'kind'
}

const initialValuesForDriver = ({
  gid,
  date_of_birth,
  gender,
  marital_status,
  education,
  occupation_type,
  occupation_since_date,
  license_status,
  license_number,
  license_state,
  age_first_licensed,
  primary_driven_vehicle_gid
}: IPerson) => ({
  [gid]: true,
  [`${gid}_${DriverFields.DateOfBirth}`]: date_of_birth || '',
  [`${gid}_${DriverFields.Gender}`]: gender || '',
  [`${gid}_${DriverFields.MaritalStatus}`]: marital_status || '',
  [`${gid}_${DriverFields.Education}`]: education || '',
  [`${gid}_${DriverFields.OccupationType}`]: occupation_type || '',
  [`${gid}_${DriverFields.OccupationSinceDate}`]: occupation_since_date || '',
  [`${gid}_${DriverFields.LicenseStatus}`]: license_status || '',
  [`${gid}_${DriverFields.LicenseNumber}`]: license_number || '',
  [`${gid}_${DriverFields.LicenseState}`]: license_state || '',
  [`${gid}_${DriverFields.AgeFirstLicensed}`]: age_first_licensed || '',
  [`${gid}_${DriverFields.PrimaryDrivenVehicleGid}`]: primary_driven_vehicle_gid || ''
});

const initialValuesForRelatedPeople = (relatedPeople: IRelatedPerson[] | undefined) =>
  (relatedPeople || []).reduce((acc, relatedPerson) => {
    return {
      ...acc,
      ...initialValuesForDriver(relatedPerson),
      [`${relatedPerson.gid}_${DriverFields.Relationship}`]: relatedPerson.kind || ''
    };
  }, {}) || {};

export const initialValues = (person: IPerson, relatedPeople: IRelatedPerson[] | undefined) =>
  ({
    [DriverFields.Email]: person.email || '',
    [DriverFields.Phone]: phoneFormatter(person.phone) || '',
    [DriverFields.LivingAddress]: {
      [LINE1]: person.living_address?.[LINE1] || '',
      [LINE2]: person.living_address?.[LINE2] || '',
      [CITY]: person.living_address?.[CITY] || '',
      [STATE]: person.living_address?.[STATE] || '',
      [ZIP]: person.living_address?.[ZIP] || ''
    },
    ...initialValuesForDriver(person),
    ...initialValuesForRelatedPeople(relatedPeople)
  }) as Record<string, any>;

const buildRelationshipValidation = (driverGid: string) => ({
  [`${driverGid}_${DriverFields.Relationship}`]: yup.string().when(driverGid, {
    is: true,
    then: schema => schema.concat(requiredField)
  })
});

export const validations = (person: IPerson, drivers: IRelatedPerson[] | undefined) =>
  yup.object().shape({
    [DriverFields.Email]: emailField.required(REQUIRED_MESSAGE),
    [DriverFields.Phone]: phoneField({ loose: authInfo.features.loose_phone_validation }).required(REQUIRED_MESSAGE),
    [DriverFields.LivingAddress]: requiredAddressSchema,
    ...[person, ...(drivers || [])].reduce((acc, { gid }) => {
      const relationshipValidation = gid === person.gid ? {} : buildRelationshipValidation(gid);

      return {
        ...acc,
        ...relationshipValidation,
        [`${gid}_${DriverFields.DateOfBirth}`]: yup.date().when(gid, {
          is: true,
          then: schema => schema.concat(requiredMinMaxDOBField())
        }),
        [`${gid}_${DriverFields.Gender}`]: yup.string().when(gid, {
          is: true,
          then: schema => schema.concat(requiredField)
        }),
        [`${gid}_${DriverFields.MaritalStatus}`]: yup.string().when(gid, {
          is: true,
          then: schema => schema.concat(requiredField)
        }),
        [`${gid}_${DriverFields.Education}`]: yup.string().when(gid, {
          is: true,
          then: schema => schema.concat(requiredField)
        }),
        [`${gid}_${DriverFields.OccupationType}`]: yup.string().when(gid, {
          is: true,
          then: schema => schema.concat(requiredField)
        }),
        [`${gid}_${DriverFields.OccupationSinceDate}`]: yup.date().when(gid, {
          is: true,
          then: schema =>
            schema.min(minDate, "Date can't be earlier than 1800").max(getTodayDate(), "Date can't be later than today")
        }),
        [`${gid}_${DriverFields.LicenseStatus}`]: yup.string().when(gid, {
          is: true,
          then: schema => schema.concat(requiredField)
        }),
        [`${gid}_${DriverFields.AgeFirstLicensed}`]: yup.number().when(gid, {
          is: true,
          then: schema => schema.min(15, 'Please, enter correct age').max(100, 'Please, enter correct age')
        })
      };
    }, {})
  });
