import { useQuery } from '@tanstack/react-query';

import { IPaperTrailVersion } from '../../../interfaces';
import api from '../../../services/api';

const getPolicyVersions = ({
  personGid,
  policyId
}: {
  personGid: string;
  policyId: number;
}): Promise<{ paper_trail_versions: IPaperTrailVersion[] }> =>
  api.get(`/api/frontend/people/${personGid}/policies/${policyId}/versions`);

const usePersonPolicyVersions = ({ personGid, policyId }: { personGid: string; policyId: number }) =>
  useQuery({
    queryKey: [personGid, policyId, 'policy_versions'],
    queryFn: () => getPolicyVersions({ personGid, policyId }),
    select: data => data.paper_trail_versions
  });

export default usePersonPolicyVersions;
