/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import SystemMessage from '../../../components/core/Alert/SystemMessage';
import Container from '../../../components/core/Container';
import { Translations } from '../../../constants';
import { IOpportunity } from '../../../interfaces';
import { isISR } from '../../../services/authInfo';
import { Spacings } from '../../../theme/variables';

interface OpportunityLicensingWarningProps extends Spacings {
  opportunity: IOpportunity;
}

const OpportunityLicensingWarning = ({ opportunity, ...rest }: OpportunityLicensingWarningProps) => {
  if (opportunity.is_user_licensed || isISR()) {
    return null;
  }

  return (
    <Container
      {...rest}
      customCss={css`
        max-width: 880px;
      `}
    >
      <SystemMessage
        type="warning"
        heading="You're not licensed in this state"
        description={`Either your license expired or you're not licensed in ${Translations.usaState(
          opportunity.state
        )}. Offer to transfer the customer to a licensed agent in this state after you complete
        your quoting of the lead.`}
      />
    </Container>
  );
};

export default OpportunityLicensingWarning;
