import { skipToken, useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { IQuotesRequest } from '../../interfaces/IQuotesRequests';
import { SourceProducts } from '../../interfaces/ISourceDimensions';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export const QUOTES_REQUESTS_QUERY_KEY = 'quotes_requests';

const getQuotesRequests = (
  personGid: string | undefined,
  leadGid: string | undefined,
  sourceProducts: (SourceProducts | string)[] | undefined
): Promise<{ quotes_requests: IQuotesRequest[] }> =>
  api.get(
    `/api/frontend/quotes_requests?${toQueryParams({
      person_gid: personGid,
      lead_gid: leadGid,
      source_products: sourceProducts
    })}`
  );

const useQuotesRequests = ({
  personGid,
  leadGid,
  sourceProducts,
  options
}: {
  personGid: string | undefined;
  leadGid: string | undefined;
  sourceProducts?: string[];
  options?: Omit<UseQueryOptions<{ quotes_requests: IQuotesRequest[] }, any, IQuotesRequest[], any>, 'queryKey'>;
}) =>
  useQuery({
    queryKey: [QUOTES_REQUESTS_QUERY_KEY, personGid, leadGid, sourceProducts],
    queryFn: personGid ? () => getQuotesRequests(personGid, leadGid, sourceProducts) : skipToken,
    select: data => data.quotes_requests,
    ...options
  });

export const useSuspenseQuotesRequests = ({
  personGid,
  leadGid,
  sourceProducts,
  options
}: {
  personGid: string | undefined;
  leadGid: string | undefined;
  sourceProducts?: string[];
  options?: Omit<UseQueryOptions<{ quotes_requests: IQuotesRequest[] }, any, IQuotesRequest[], any>, 'queryKey'>;
}) =>
  useSuspenseQuery({
    queryKey: [QUOTES_REQUESTS_QUERY_KEY, personGid, leadGid, sourceProducts, options?.enabled],
    queryFn: () =>
      personGid && options?.enabled
        ? getQuotesRequests(personGid, leadGid, sourceProducts)
        : Promise.resolve({ quotes_requests: [] }),
    select: data => data.quotes_requests,
    ...options
  });

export default useQuotesRequests;
