/* eslint-disable no-restricted-syntax */
import './styles.scss';

import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col } from 'reactstrap';

import { CollapsePanel } from '../../../common';
import { driverName } from '../../utils';
import PersonEditor from './PersonEditor';

class Drivers extends React.Component<any, any> {
  state = {
    driverRefs: []
  };

  renderAutoForm = (driver: any) => (
    <PersonEditor ref={(ref: any) => this.driverRef(ref, driver)} person={driver} className="mt-2 mb-2" />
  );

  driverRef = (ref: any, driver: any) => {
    const name = driverName(driver);
    const existingRecord = this.state.driverRefs.find((element: any) => element.name === name) as any;

    if (existingRecord) {
      existingRecord.ref = ref;
    } else {
      this.setState({ driverRefs: [...this.state.driverRefs, { name, ref }] });
    }
  };

  renderDrivers = () =>
    this.props.drivers.map((driver: { [s: string]: unknown } | ArrayLike<unknown>) => (
      <div className="driver" key={Object.values(driver).join('.')}>
        <CollapsePanel title={<div className="driver__title">{driverName(driver)}</div>}>
          {this.renderAutoForm(driver)}
        </CollapsePanel>
      </div>
    ));

  onSubmit = async (params: any, { setSubmitting }: any) => {
    if (this.driversValid()) {
      const driversInfo = this.state.driverRefs.map(driver => (driver as any).ref.getData());

      try {
        await this.props.next({ drivers: driversInfo });
      } finally {
        setSubmitting(false);
      }
    }
  };

  driversValid = () => this.state.driverRefs.every(driver => (driver as any).ref.validate());

  renderFooter = (submitIsDisabled: boolean | undefined, handleSubmit: any) => (
    <Col sm={12} className="btn-container p-0">
      <Button onClick={this.props.cancelHandler}>Cancel</Button>
      <Button onClick={this.props.prev}>Prev</Button>
      <Button onClick={handleSubmit} color="primary" className="confirm-button" disabled={submitIsDisabled}>
        Finish
      </Button>
    </Col>
  );

  render() {
    return (
      <Formik initialValues={{ drivers: this.props.drivers || [] }} onSubmit={this.onSubmit}>
        {({ isSubmitting, handleSubmit }) => (
          <div className="step drivers">
            <div className="step__title">Drivers</div>
            <div>{this.renderDrivers()}</div>
            <div className="d-flex flex-column mt-3">{this.renderFooter(isSubmitting, handleSubmit)}</div>
          </div>
        )}
      </Formik>
    );
  }
}

// @ts-expect-error old-code
Drivers.propTypes = {
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      middle_name: PropTypes.string
    })
  ),
  cancelHandler: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired
};
// @ts-expect-error old-code
Drivers.defaultProps = {
  drivers: []
};

export default Drivers;
