import classNames from 'classnames';

const ColumnGenerator = {
  WIDTH_XS: 40,
  WIDTH_S: 80,
  WIDTH_L: 100,
  WIDTH_XL: 140,
  WIDTH_XXL: 180,
  simple(params: any) {
    return this.handleSorting({
      width: this.WIDTH_S,
      ...params
    });
  },
  stretchable(params: any) {
    return this.handleSorting({
      minWidth: this.WIDTH_XL,
      ...params
    });
  },
  handleSorting(params: any) {
    if (typeof params.sortable === 'boolean' && !params.sortable) {
      params.headerClassName = classNames(params.headerClassName, 'unsortable');
    }

    return params;
  }
};

export default ColumnGenerator;
