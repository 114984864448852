import ButtonWithoutBorder from '../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import Modal, { ModalSize } from '../../../components/core/Modal';
import Paragraph from '../../../components/core/Paragraph';
import { useToggle } from '../../../hooks';
import { ICarrier } from '../../../interfaces';
import useDocumentTips from '../../../queries/document_tips/useDocumentTips';
import { DocumentType } from '../../../queries/document_types/useDocumentTypes';
import analytics from '../../../services/analytics';
import { spacings } from '../../../theme/variables';

const DocumentInstructions = ({ carrier, documentTypes }: { carrier: ICarrier; documentTypes: DocumentType[] }) => {
  const [modalIsOpen, toggleModal] = useToggle(false);
  const { data: documentTips = [], isPending } = useDocumentTips(modalIsOpen ? carrier.id : undefined);

  return (
    <>
      <ButtonWithoutBorder
        onClick={() => {
          toggleModal();
          analytics.track('Where to find docs clicked', { carrier_key: carrier.key });
        }}
      >
        Where to find docs?
      </ButtonWithoutBorder>
      {modalIsOpen && (
        <Modal
          size={ModalSize.large}
          showCancelButton={false}
          title={`Find documents on ${carrier.name}`}
          cancelHandler={toggleModal}
        >
          {isPending && <FormLoader />}
          <FlexBox gap={spacings.px20} columnDirection>
            {documentTips.map(tip => (
              <FlexBox key={tip.document_type_key} gap={spacings.px8} columnDirection>
                <Paragraph bold>
                  {documentTypes.find(doc => doc.key === tip.document_type_key)?.title || 'Other'}
                </Paragraph>
                <Paragraph>{tip.instructions}</Paragraph>
              </FlexBox>
            ))}
          </FlexBox>
          {!isPending && documentTips.length === 0 && (
            <Paragraph>There are no instructions provided. Please contact support</Paragraph>
          )}
        </Modal>
      )}
    </>
  );
};

export default DocumentInstructions;
