/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { AxiosError } from 'axios';
import { Formik, FormikValues } from 'formik';

import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import alertFromApiResponse from '../../../utils/alertFromApiResponse';
import alert from '../Alert';
import Button from '../buttons/Button';
import ButtonWithoutBorder from '../buttons/ButtonWithoutBorder';
import FlexBox from '../FlexBox';
import HotKey from '../HotKey';
import { CloseIcon } from '../icons';
import { BaseFormProps } from './BaseForm';

const DefaultForm = <T extends FormikValues>({
  renderForm,
  cancelHandler,
  closeHandler,
  header,
  footer,
  submitText,
  cancelText,
  cancelWithEscape,
  disabled,
  loading,
  pt,
  pr,
  pb,
  pl,
  controlsAlignment,
  controlsWidth,
  submitVariant,
  cancelVariant,
  customControls,
  buttonsSize,
  cancelTestId,
  submitTestId,
  revertButtonsPosition,
  ...rest
}: BaseFormProps<T>) => {
  return (
    <Formik {...rest}>
      {formikProps => {
        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              e.stopPropagation();
              formikProps.submitForm().catch((e: Error) => {
                if (e instanceof AxiosError) {
                  alertFromApiResponse(e.response);
                } else {
                  alert({ title: 'An unexpected error occurred', message: e.message }).error();
                }
              });
            }}
            css={css`
              padding-top: ${pt}px;
              padding-right: ${pr}px;
              padding-bottom: ${pb}px;
              padding-left: ${pl}px;
              height: 100%;
              display: flex;
              flex-direction: column;
              gap: ${spacings.px24}px;
            `}
          >
            {header && (
              <FlexBox justifySpaceBetween alignItemsCenter>
                {typeof header === 'function' ? header(formikProps) : header}
                {(closeHandler || cancelHandler) && (
                  <FlexBox alignItemsCenter>
                    {cancelWithEscape && (
                      <HotKey
                        message="Press ESC"
                        customCss={css`
                          margin-right: ${spacings.px8}px;
                        `}
                      />
                    )}
                    <ButtonWithoutBorder
                      onClick={closeHandler || cancelHandler}
                      disabled={formikProps.isSubmitting}
                      aria-label="Close"
                    >
                      <CloseIcon color={colors.black} width={40} height={40} />
                    </ButtonWithoutBorder>
                  </FlexBox>
                )}
              </FlexBox>
            )}
            <FlexBox columnDirection>{renderForm(formikProps)}</FlexBox>
            {!customControls && (
              <FlexBox
                customCss={css`
                  justify-content: ${controlsAlignment === 'right' ? 'end' : 'start'};
                  gap: ${spacings.px24}px;
                `}
              >
                {footer && (
                  <FlexBox
                    alignItemsCenter
                    css={css`
                      width: 100%;
                    `}
                  >
                    {typeof footer === 'function' ? footer(formikProps) : footer}
                  </FlexBox>
                )}

                <FlexBox
                  gap={spacings.px8}
                  css={css`
                    flex-direction: ${revertButtonsPosition ? 'row-reverse' : 'row'};
                  `}
                >
                  {cancelHandler && (
                    <Button
                      data-testid={cancelTestId}
                      variant={cancelVariant}
                      size={buttonsSize}
                      customCss={css`
                        color: ${cancelVariant ? colors.azure50 : colors.grey80};
                        min-width: ${controlsWidth}px;
                      `}
                      type="button"
                      disabled={formikProps.isSubmitting}
                      onClick={cancelHandler}
                    >
                      {cancelText || 'Cancel'}
                    </Button>
                  )}
                  <Button
                    data-testid={submitTestId}
                    disabled={
                      formikProps.isSubmitting ||
                      (typeof disabled === 'function' ? disabled(formikProps) : disabled) ||
                      loading
                    }
                    loading={formikProps.isSubmitting || formikProps.isValidating || loading}
                    type="submit"
                    variant={submitVariant}
                    size={buttonsSize}
                    customCss={css`
                      min-width: ${controlsWidth}px;
                    `}
                  >
                    {typeof submitText === 'function' ? submitText(formikProps) : submitText || 'Create'}
                  </Button>
                </FlexBox>
              </FlexBox>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default DefaultForm;
