import { CITY, ID, LINE1, LINE2, STATE, ZIP } from '../../../../constants/addressForm';
import { dig } from '../../../../utils/object';

const BLANK = '';

export const generateInitialValues = (values: any) => ({
  [ID]: dig(values, ID),
  [LINE1]: dig(values, LINE1) || BLANK,
  [LINE2]: dig(values, LINE2) || BLANK,
  [CITY]: dig(values, CITY) || BLANK,
  [STATE]: dig(values, STATE) || BLANK,
  [ZIP]: dig(values, ZIP) || BLANK
});
