/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as yup from 'yup';

import FormModal from '../../components/core/FormModal';
import { SelectField } from '../../components/core/forms/fields';
import TextareaField from '../../components/core/forms/fields/TextareaField';
import { ModalSize } from '../../components/core/Modal';
import { Translations } from '../../constants';
import { NoteKind } from '../../interfaces/INote';
import { requiredField } from '../../utils/yupRules';

const NoteCreator = ({
  cancelHandler,
  confirmHandler
}: {
  cancelHandler: () => void;
  confirmHandler: ({ kind, description }: { kind: NoteKind; description: string }) => void;
}) => (
  <FormModal
    returnFocusAfterClose={false}
    size={ModalSize.medium}
    cancelHandler={cancelHandler}
    confirmText="Create"
    confirmHandler={note => confirmHandler(note)}
    className="note-creator"
    title="Create note"
    initialValues={{
      description: '',
      kind: '' as NoteKind
    }}
    validationSchema={yup.object().shape({
      kind: requiredField,
      description: requiredField
    })}
    renderForm={() => (
      <div
        css={css`
          padding-left: 8px;
          padding-right: 8px;
        `}
      >
        <SelectField required label="Type" id="kind" name="kind" options={Translations.manualNoteKindOptions} />
        <TextareaField required label="Description" name="description" id="description" />
      </div>
    )}
  />
);

export default NoteCreator;
