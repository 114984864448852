import * as React from 'react';

import alert from '../../../../components/core/Alert';
import ConfirmModal from '../../../../components/FileModals/ConfirmModal';
import { IVehicle } from '../../../../interfaces';
import { FileToConfirm, FileToUpload } from '../../../../interfaces/IDocument';
import { PolicyType } from '../../../../interfaces/IPolicyType';
import { generateAutoDescription } from '../../../../interfaces/IVehicle';
import useDocumentTypes from '../../../../queries/document_types/useDocumentTypes';
import useLeadOpportunities from '../../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonDocuments, {
  UpdateDocumentRequest,
  useCreateDocument,
  useDeleteDocument,
  useUpdateDocument
} from '../../../../queries/people/person_documents/usePersonDocuments';
// eslint-disable-next-line max-len
import usePersonAllLeadsOpportunities from '../../../../queries/people/person_opportunities/usePersonAllLeadsOpportunities';
import usePersonMaticPolicies from '../../../../queries/people/person_policies/usePersonMaticPolicies';
import analytics from '../../../../services/analytics';
import { spacings } from '../../../../theme/variables';
import { dateFormatter } from '../../../../utils/formatter';
import useDataEditingForbidden from '../../_hooks/useDataEditingForbidden';
import TabSkeleton from '../_components/TabSkeleton';
import DocumentsList from './DocumentsList';
import Dropzone from './Dropzone';
import { getOwnerType } from './helpers';

const FilesTabContent = ({ leadId, personGid }: { leadId: number; personGid: string }) => {
  const { data: documents, isPending: isPendingDocuments } = usePersonDocuments({ personGid });
  const { data: personOpportunities } = usePersonAllLeadsOpportunities({ personGid });
  const { data: leadOpportunities } = useLeadOpportunities({ leadId });
  const { transformedData: documentTypes = [] } = useDocumentTypes();
  const { data: policies } = usePersonMaticPolicies({ personGid });
  const { mutateAsync: createDocument } = useCreateDocument({ attachDocs: true });
  const { mutateAsync: deleteDocument } = useDeleteDocument({ invalidate: true });
  const { mutateAsync: updateDocument } = useUpdateDocument({ invalidate: true });
  const dataEditingForbidden = useDataEditingForbidden();

  const [filesToConfirm, setFilesToConfirm] = React.useState<FileToConfirm[]>([]);
  const [filesToUpload, setFilesToUpload] = React.useState<FileToUpload[]>([]);

  const opportunitiesOwners =
    leadOpportunities?.map(opportunity => {
      return {
        key: opportunity.id.toString(),
        value: `${opportunity.title}`,
        description:
          opportunity.policy_type === PolicyType.Auto
            ? generateAutoDescription(opportunity.assets as IVehicle[])
            : opportunity.assets?.[0]?.description_without_icon
      };
    }) || [];

  const policiesOwners =
    policies?.map(policy => {
      return {
        key: policy.id.toString(),
        value: `Policy #${policy.policy_number}`,
        description: `Effective date ${dateFormatter(policy.effective_date)}-${dateFormatter(policy.expiration_date)}`
      };
    }) || [];

  const owners = opportunitiesOwners
    .concat(policiesOwners)
    .concat({ key: leadId.toString(), value: 'Other', description: '' });

  const onAttach = (files: FileToUpload[], leadId: number) =>
    Promise.all(
      files.map(({ fileOwner, ...rest }) => {
        const owner_id = fileOwner === 'lead' ? leadId : Number(fileOwner);
        const owner_type = getOwnerType({ fileOwner, opportunities: leadOpportunities, policies });

        return createDocument({
          personGid,
          request: { ...rest, owner_id, owner_type }
        });
      })
    );

  const closeModal = () => setFilesToConfirm([]);

  const suggestDocType = (name: string): number =>
    (documentTypes.find(type => !!(name.match(type.regexp) || [])[0])?.id || '') as number;

  return (
    <>
      {isPendingDocuments ? (
        <TabSkeleton pb={spacings.px24} />
      ) : (
        <DocumentsList
          filesToUpload={filesToUpload}
          documents={documents}
          onDelete={({ id }) => deleteDocument({ personGid, documentId: id })}
          onUpdate={(documentId: number, request: UpdateDocumentRequest) =>
            updateDocument({ personGid, documentId, request })
          }
          owners={owners}
          opportunities={personOpportunities}
          policies={policies}
          disabled={dataEditingForbidden}
        />
      )}
      <Dropzone
        disabled={dataEditingForbidden}
        small
        onUpload={filesToConfirm => setFilesToConfirm(filesToConfirm)}
        onReject={invalidFiles =>
          invalidFiles.length > 0 &&
          alert({
            title: 'Error uploading files',
            items: invalidFiles.map(({ file, error }) => `${file.name} - ${error}`)
          }).error()
        }
      />
      {filesToConfirm.length > 0 && (
        <ConfirmModal
          files={filesToConfirm.map(f => ({ ...f, document_type_id: suggestDocType(f.name), fileOwner: '' }))}
          cancelHandler={() => closeModal()}
          confirmHandler={async filesToUpload => {
            setFilesToUpload(filesToUpload);

            analytics.track('Files are uploaded through right-side tab');
            closeModal();
            await onAttach(filesToUpload, leadId);

            setFilesToUpload([]);
          }}
          owners={owners}
        />
      )}
    </>
  );
};

export default FilesTabContent;
