/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import NewTooltip from '../../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import featureFlags from '../../../../constants/featureFlags';
import { IQuote } from '../../../../interfaces';
import { coverageByKey, PolicyCoverageKey } from '../../../../interfaces/IPolicyCoverage';
import { findPolicyTypeLabel } from '../../../../interfaces/IPolicyType';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { moneyFormatter } from '../../../../utils/formatter';
import DeprecatedStatus from '../_components/QuoteRow/DeprecatedStatus';
import Status from '../_components/QuoteRow/Status';

const CustomerChoices = ({ customerChoices }: { customerChoices: IQuote[] }) => {
  return (
    <FlexBox columnDirection mb={spacings.px24} gap={spacings.px8}>
      <FlexBox>
        <Text bold>Customer’s choice</Text>
      </FlexBox>
      <FlexBox columnDirection gap={spacings.px8}>
        {customerChoices.map(customerChoice => {
          const {
            gid,
            attributes: { policy_type, carrier, coverages, premium }
          } = customerChoice;

          const dwelling = coverageByKey(coverages!, PolicyCoverageKey.Dwelling)?.limit_description;
          const deductible = coverageByKey(coverages!, PolicyCoverageKey.Dwelling)?.deductible_description;
          const bodilyInjuryPerPerson = coverageByKey(coverages!, PolicyCoverageKey.BiPerPerson)?.limit_value;
          const bodilyInjuryPerAccident = coverageByKey(coverages!, PolicyCoverageKey.BiPerAccident)?.limit_value;
          const propertyDamage = coverageByKey(coverages!, PolicyCoverageKey.PropertyDamage)?.limit_value;

          return (
            <FlexBox key={gid} columnDirection gap={spacings.px4}>
              <FlexBox alignItemsCenter justifySpaceBetween>
                <Paragraph bold type="small">
                  {findPolicyTypeLabel(policy_type)}
                </Paragraph>
                {featureFlags.newQuotesUI ? (
                  <Status quote={customerChoice} />
                ) : (
                  <DeprecatedStatus quote={customerChoice} withIcon />
                )}
              </FlexBox>
              <FlexBox justifySpaceBetween>
                <Text type="small" color={colors.grey60}>
                  Carrier
                </Text>
                <Text
                  type="small"
                  customCss={css`
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 160px;
                    text-align: end;
                  `}
                >
                  {carrier.name}
                </Text>
              </FlexBox>
              {!!premium && (
                <FlexBox justifySpaceBetween>
                  <Text type="small" color={colors.grey60}>
                    Premium
                  </Text>
                  <Text type="small">{moneyFormatter(premium, true)}</Text>
                </FlexBox>
              )}
              {!!dwelling && (
                <FlexBox justifySpaceBetween>
                  <Text type="small" color={colors.grey60}>
                    Dwelling
                  </Text>
                  <Text type="small">{dwelling}</Text>
                </FlexBox>
              )}
              {!!deductible && (
                <FlexBox justifySpaceBetween>
                  <Text type="small" color={colors.grey60}>
                    Deductible
                  </Text>
                  <Text type="small">{deductible}</Text>
                </FlexBox>
              )}
              {!!(bodilyInjuryPerPerson && bodilyInjuryPerAccident) && (
                <FlexBox justifySpaceBetween>
                  <Text type="small" color={colors.grey60}>
                    Liability
                  </Text>
                  <Text type="small">{`${moneyFormatter(bodilyInjuryPerPerson, true, 'compact')} / ${moneyFormatter(
                    bodilyInjuryPerAccident,
                    true,
                    'compact'
                  )}`}</Text>
                </FlexBox>
              )}
              {!!propertyDamage && (
                <FlexBox justifySpaceBetween>
                  <Text type="small" color={colors.grey60}>
                    Property damage
                  </Text>
                  <Text type="small">{`${moneyFormatter(propertyDamage, true, 'compact')}`}</Text>
                </FlexBox>
              )}
            </FlexBox>
          );
        })}
      </FlexBox>
      <NewTooltip id="carrier-name-tooltip" />
    </FlexBox>
  );
};

export default CustomerChoices;
