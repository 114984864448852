import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IAms360Policy } from '../../interfaces';
import api from '../../services/api';

const useAms360Policy = (
  policyId?: string | number,
  options?: UseQueryOptions<{ ams360_policy: IAms360Policy | null }, any, any, any>
) =>
  useQuery({
    queryKey: ['ams360_policy', policyId],
    queryFn: policyId
      ? (): Promise<{ ams360_policy: IAms360Policy | null }> =>
          api.get(`/api/frontend/policies/${policyId}/ams360_policy`)
      : skipToken,
    ...options,
    select: data => data.ams360_policy
  });

export default useAms360Policy;
