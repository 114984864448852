/** @jsxImportSource @emotion/react */
import 'react-quill/dist/quill.snow.css';

import { css } from '@emotion/react';
// @ts-expect-error TODO
import ImageUploader from 'quill-image-uploader';
import ReactQuill, { Quill } from 'react-quill';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';

import { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import { DateInputField, InputField, MultiSelectField } from '../../../components/core/forms/fields';
import LabeledInput from '../../../components/core/forms/fields/LabeledInput';
import Heading from '../../../components/core/Heading';
import { REQUIRED_TEXT } from '../../../components/PolicyModals/helpers';
import useNavigateBack from '../../../hooks/useNavigateBack';
import { IProductAnnouncementTag } from '../../../interfaces/IProductAnnouncement';
import { attachMedia } from '../../../queries/media_attachments/useAttachMedia';
import useCreateProductAnnouncement from '../../../queries/product_announcements/useCreateProductAnnouncement';
import { useProductAnnouncement } from '../../../queries/product_announcements/useProductAnnouncements';
import useUpdateProductAnnouncement from '../../../queries/product_announcements/useUpdateProductAnnouncements';
import useUsersRoles from '../../../queries/users_roles/useUsersRoles';
import colors from '../../../theme/colors';
import { InputSize, spacings } from '../../../theme/variables';
import { requiredDate } from '../../../utils/yupRules';
import AnnouncementTag from './AnnouncementTag';
import { announcementContainerStyle } from './grid';

Quill.register('modules/imageUploader', ImageUploader);

const QUILL_EDITOR_MODULES = {
  // See https://quilljs.com/docs/modules/toolbar/ for toolbar options
  toolbar: [
    [{ header: [4, false] }],
    ['bold', 'italic', 'underline', 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['image'],
    ['clean']
  ],
  imageUploader: {
    upload: (file: File) => {
      return new Promise((resolve, reject) => {
        attachMedia(file)
          .then((result: any) => resolve(result.file_url))
          .catch(() => reject('Upload failed'));
      });
    }
  }
};

const QUILL_EDITOR_FORMATS = ['header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link', 'image'];

const ProductAnnouncementEditor = () => {
  const { productAnnouncementId } = useParams();
  const navigateBack = useNavigateBack();

  const { data: productAnnouncement } = useProductAnnouncement(productAnnouncementId);
  const { data: roles } = useUsersRoles();

  const { mutateAsync: updateAnnouncement } = useUpdateProductAnnouncement();
  const { mutateAsync: createAnnouncement } = useCreateProductAnnouncement();

  return (
    <BaseForm
      type="fullPage"
      pt={spacings.px12}
      pb={spacings.px12}
      pl={spacings.px24}
      pr={spacings.px24}
      enableReinitialize
      header={<Heading type="h3">{productAnnouncementId ? 'Update' : 'Create'} product announcement</Heading>}
      cancelWithEscape
      initialValues={{
        title: productAnnouncement?.title || '',
        release_date: productAnnouncement?.release_date?.slice(0, 10) || '',
        tags: productAnnouncement?.tags || '',
        targeted_roles: productAnnouncement?.targeted_roles?.map(role => role.id) || [],
        preview: productAnnouncement?.preview || '',
        content: productAnnouncement?.content || ''
      }}
      validationSchema={yup.object().shape({
        title: yup.string().required(REQUIRED_TEXT),
        release_date: requiredDate(),
        tags: yup.array().required(REQUIRED_TEXT),
        preview: yup.string().required(REQUIRED_TEXT)
      })}
      onSubmit={async ({ title, release_date, targeted_roles, tags, preview, content }) => {
        if (productAnnouncementId) {
          await updateAnnouncement({
            productAnnouncementId,
            params: {
              title,
              release_date,
              tags: tags as IProductAnnouncementTag[],
              targeted_roles_attributes: targeted_roles.map(roleId => ({ role_id: roleId })),
              preview,
              content
            }
          });
        } else {
          await createAnnouncement({
            title,
            release_date,
            tags: tags as IProductAnnouncementTag[],
            targeted_roles_attributes: targeted_roles.map(roleId => ({ role_id: roleId })),
            preview,
            content
          });
        }

        navigateBack();
      }}
      cancelHandler={() => navigateBack()}
      cancelVariant={ButtonVariant.Secondary}
      submitText={productAnnouncementId ? 'Update announcement' : 'Create announcement'}
      renderForm={({ values, setFieldValue, errors }) => (
        <FlexBox justifyCenter>
          <FlexBox columnDirection gap={spacings.px8} mb={spacings.px32} customCss={announcementContainerStyle}>
            <InputField inline required id="title" label="Title" name="title" inputSize={InputSize.Large} />
            <MultiSelectField
              inline
              required
              id="tags"
              label="Tags"
              name="tags"
              inputSize={InputSize.Large}
              valuesSeparator={false}
              options={Object.values(IProductAnnouncementTag).map(tag => ({
                key: tag,
                value: <AnnouncementTag tag={tag} />
              }))}
            />
            <MultiSelectField
              inline
              id="targeted_roles"
              label="Targeted roles (optional)"
              name="targeted_roles"
              inputSize={InputSize.Large}
              options={
                roles?.map(role => ({
                  key: role.id,
                  value: role.label
                })) || []
              }
            />
            <DateInputField inline required id="release_date" label="Release date" name="release_date" />

            <Container mt={spacings.px8} ml={spacings.px4}>
              <LabeledInput
                id="preview"
                label="Preview content"
                required
                inline={false}
                secondary={false}
                disabled={false}
              >
                <ReactQuill
                  theme="snow"
                  modules={QUILL_EDITOR_MODULES}
                  formats={QUILL_EDITOR_FORMATS}
                  value={values.preview}
                  onChange={value => setFieldValue('preview', value)}
                  css={css`
                    ${errors['preview'] && 'border: 1px solid red;'}
                  `}
                />
              </LabeledInput>
            </Container>
            <Container mt={spacings.px8} ml={spacings.px4}>
              <LabeledInput
                id="content"
                label="Extended content (optional)"
                required={false}
                inline={false}
                secondary={false}
                disabled={false}
                css={css`
                  .ql-editor img {
                    ::selection {
                      background-color: ${colors.cornflower};
                    }
                  }
                `}
              >
                <ReactQuill
                  theme="snow"
                  modules={QUILL_EDITOR_MODULES}
                  formats={QUILL_EDITOR_FORMATS}
                  value={values.content}
                  onChange={value => setFieldValue('content', value)}
                />
              </LabeledInput>
            </Container>
          </FlexBox>
        </FlexBox>
      )}
    />
  );
};

export default ProductAnnouncementEditor;
