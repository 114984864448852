/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Text from '../../../components/core/Text';

interface ContentSectionProps {
  heading?: string | JSX.Element;
  children: React.ReactNode;
  testId?: string;
}

const ContentSection = ({ heading, children, testId }: ContentSectionProps) => {
  return (
    <div
      css={css`
        padding-top: 12px;
      `}
    >
      {heading && <Text bold>{heading}</Text>}
      <div
        css={css`
          display: grid;
          grid-auto-columns: 1fr;
          grid-template-columns: 2fr 5fr 2fr;
          gap: 0px 0px;
        `}
      >
        <div />
        <div data-testid={testId}>{children}</div>
        <div />
      </div>
    </div>
  );
};

export default ContentSection;
