import { FormikValues } from 'formik';
import * as yup from 'yup';

import { IPriorPolicy, IVehicle } from '../../../interfaces';
import { AutoCoveragesOptions } from '../../../interfaces/IAutoCoveragesOptions';
import { validateAutoCoveragesOptions } from '../../../queries/auto_coverages/useValidateAutoCoverages';
import { optionalDate } from '../../../utils/yupRules';

export enum VehicleCoveragesFields {
  CoveragesTier = 'coverages_tier'
}

export enum VehicleCoveragesTiers {
  Recommended = 'recommended',
  Custom = 'custom'
}

export const initialCoveragesForVehicle = (vehicle: IVehicle, coveragesOptions: AutoCoveragesOptions | undefined) => {
  return (
    coveragesOptions?.reduce((acc, coverageOptions) => {
      const recommendedCoverageOption = coverageOptions.values.find(coverageOption => coverageOption.default);

      return {
        ...acc,
        ...{
          [vehicle.gid]: {
            coverages: {
              ...(acc as any)[vehicle.gid]?.['coverages'],
              ...{ [recommendedCoverageOption!.key]: recommendedCoverageOption?.gid }
            }
          }
        }
      };
    }, {}) || {}
  );
};

const initialValuesForVehicles = (vehicles: IVehicle[], coverages: Record<string, AutoCoveragesOptions>) =>
  vehicles.reduce(
    (acc, vehicle) => ({
      ...acc,
      [`${vehicle.gid}_${VehicleCoveragesFields.CoveragesTier}`]: VehicleCoveragesTiers.Recommended,
      ...initialCoveragesForVehicle(vehicle, coverages[vehicle.gid])
    }),
    {}
  );

export const initialValues = ({
  recentPolicy,
  selectedVehicles,
  coverages
}: {
  recentPolicy: IPriorPolicy | undefined;
  selectedVehicles: IVehicle[];
  coverages: Record<string, AutoCoveragesOptions>;
}) =>
  ({
    prior_policy_expiration_date: recentPolicy?.expiration_date || '',
    ...initialValuesForVehicles(selectedVehicles, coverages)
  }) as Record<string, any>;

export const validations = () =>
  yup.object().shape({
    prior_policy_expiration_date: optionalDate()
  });

export const validateVehiclesCoverages = ({
  selectedVehicles,
  values
}: {
  selectedVehicles: IVehicle[];
  values: FormikValues;
}) =>
  Promise.all(
    selectedVehicles.map(({ gid, ownership, address }) => {
      const selectedCoverages = values[gid]?.['coverages'];

      return validateAutoCoveragesOptions({
        coverages: selectedCoverages,
        ownership: ownership!,
        state: address!.state,
        asset_gid: gid
      }).then(({ errors }) => {
        if (!errors?.length) {
          return {};
        }

        return errors
          .filter(({ asset_gid }) => asset_gid === gid)
          .reduce((acc, { errors }) => {
            return {
              ...acc,
              [gid]: {
                coverages: errors
              }
            };
          }, {});
      });
    })
  ).then(validationResults =>
    validationResults.reduce((acc, validationResult) => {
      return {
        ...acc,
        ...validationResult
      };
    }, {})
  );
