/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { Copy } from '../../../components/common';
import DescriptionList from '../../../components/core/DescriptionList';
import FlexBox from '../../../components/core/FlexBox';
import { UserIcon } from '../../../components/core/icons';
import IconWithText from '../../../components/core/IconWithText';
import Text from '../../../components/core/Text';
import { IPerson, RelationKind } from '../../../interfaces/IPerson';
import usePersonRelatedPeople from '../../../queries/people/usePersonRelatedPeople';
import usePoliciesDetails from '../../../queries/policies/usePoliciesDetails';
import { spacings } from '../../../theme/variables';
import { dateFormatter, phoneFormatter } from '../../../utils/formatter';
import CustomerInfoPlaceholder from '../CustomerInfoPlaceholder';
import NonSyncedPolicyAssetsDetails from '../NonSyncedPolicyAssetsDetails';
import SyncedPolicyAssetsDetails from '../SyncedPolicyAssetsDetails';

const CustomerInfo = ({
  person,
  isCurrentPolicySyncedWithIVANS
}: {
  person: IPerson;
  isCurrentPolicySyncedWithIVANS: boolean;
}): JSX.Element | null => {
  const { data: personRelatedPeople } = usePersonRelatedPeople(person.gid, { kinds: [RelationKind.Spouse] });
  const spouse = personRelatedPeople?.[0];
  const { isCurrentPolicyLoaded } = usePoliciesDetails(person.gid);

  if (!isCurrentPolicyLoaded) {
    return <CustomerInfoPlaceholder />;
  }
  return (
    <>
      {person && (
        <>
          <IconWithText text={person.name} extendedClassName="fs-mask">
            <UserIcon />
          </IconWithText>
          <FlexBox pl={spacings.px24} columnDirection>
            {person.phone && (
              <DescriptionList
                term={<Text>Phone</Text>}
                details={
                  <Text className="fs-mask">
                    <Copy value={phoneFormatter(person.phone)} />
                  </Text>
                }
              />
            )}
            {person.date_of_birth && (
              <DescriptionList
                term={<Text>DOB</Text>}
                details={
                  <Text
                    className="fs-mask"
                    customCss={css`
                      overflow-wrap: anywhere;
                    `}
                  >
                    {dateFormatter(person.date_of_birth)}
                  </Text>
                }
              />
            )}
            {person.email && (
              <DescriptionList
                term={<Text>Email</Text>}
                details={
                  <Text
                    className="fs-mask"
                    customCss={css`
                      overflow-wrap: anywhere;
                    `}
                  >
                    <Copy value={person.email} />
                  </Text>
                }
              />
            )}
            {person.living_address && (
              <DescriptionList
                term={<Text>Address</Text>}
                details={
                  <Text
                    className="fs-mask"
                    customCss={css`
                      overflow-wrap: anywhere;
                    `}
                  >
                    <Copy value={person.living_address.full} />
                  </Text>
                }
              />
            )}

            {spouse && (
              <DescriptionList term={<Text>Spouse</Text>} details={<Text className="fs-mask">{spouse.name}</Text>} />
            )}
          </FlexBox>
        </>
      )}

      {isCurrentPolicySyncedWithIVANS ? (
        <>
          <FlexBox pt={spacings.px32}>
            <SyncedPolicyAssetsDetails personGid={person.gid} />
          </FlexBox>
        </>
      ) : (
        <>
          <FlexBox pt={spacings.px32}>
            <NonSyncedPolicyAssetsDetails personGid={person.gid} />
          </FlexBox>
        </>
      )}
    </>
  );
};

export default CustomerInfo;
