import { useMutation } from '@tanstack/react-query';

import api from '../../../services/api';

interface UpdateLeadPartnerParams {
  leadId: number;
  partner: string;
}

export const updateLeadPartner = (params: UpdateLeadPartnerParams): Promise<null> =>
  api.put(`/api/frontend/leads/${params.leadId}/partner`, {
    body: { partner_key: params.partner }
  });

const useUpdateLeadPartner = (onSuccess: (data: null, variables: UpdateLeadPartnerParams) => void) =>
  useMutation({ mutationFn: updateLeadPartner, onSuccess });

export default useUpdateLeadPartner;
