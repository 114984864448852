/** @jsxImportSource @emotion/react */
import moment from 'moment';
import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import { IPerson, ISMSInteraction } from '../../../../interfaces';
import { InteractionDirection } from '../../../../interfaces/IPersonInteraction';
import { spacings } from '../../../../theme/variables';
import { capitalize, phoneFormatterBracesless } from '../../../../utils/formatter';
import { displayedDateAndTime } from './helpers';
import InteractionDetailsLayout, { CommunicatorLayout } from './InteractionDetailsLayout';

const SMSDetails = ({
  interaction,
  interactionPerson,
  relatedPersonKind,
  userRole
}: {
  interaction: ISMSInteraction;
  interactionPerson: IPerson | undefined;
  userRole: string | null;
  relatedPersonKind?: string;
}): JSX.Element => {
  const personFullName = interactionPerson
    ? `${interactionPerson.first_name} ${interactionPerson.last_name}`
    : 'Unknown';
  const userFullName = interaction.processed_by
    ? `${interaction.processed_by.first_name} ${interaction.processed_by.last_name}`
    : 'Matic phone';

  return (
    <InteractionDetailsLayout
      header={
        <>
          <Paragraph type="small">{`${capitalize(interaction.direction)} ${interaction.type.toUpperCase()}`}</Paragraph>
          <Paragraph type="small">{displayedDateAndTime(moment(interaction.contacted_at))}</Paragraph>
        </>
      }
      sender={
        <CommunicatorLayout
          name={interaction.direction === InteractionDirection.Inbound ? personFullName : userFullName}
          tagLabel={interaction.direction === InteractionDirection.Inbound ? relatedPersonKind : userRole}
          info={phoneFormatterBracesless(
            interaction.direction === InteractionDirection.Inbound ? interaction.person_phone : interaction.matic_phone
          )}
        />
      }
      receiver={
        <CommunicatorLayout
          name={interaction.direction === InteractionDirection.Inbound ? userFullName : personFullName}
          tagLabel={interaction.direction === InteractionDirection.Inbound ? userRole : relatedPersonKind}
          info={phoneFormatterBracesless(
            interaction.direction === InteractionDirection.Inbound ? interaction.matic_phone : interaction.person_phone
          )}
        />
      }
      body={
        <FlexBox gap={spacings.px12} columnDirection>
          {interaction.scheduled_by && (
            <Paragraph type="small" bold>
              SMS scheduled by {interaction.scheduled_by.name}
            </Paragraph>
          )}
          <Paragraph>{interaction.body}</Paragraph>
        </FlexBox>
      }
    />
  );
};
export default SMSDetails;
