import { skipToken, useQuery } from '@tanstack/react-query';

import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

interface IDocumentTip {
  document_type_key: string;
  instructions: string;
}
const useDocumentTips = (carrier_id?: number | string) =>
  useQuery({
    queryKey: ['document_tips_by_carrier', carrier_id],
    queryFn: carrier_id
      ? (): Promise<{ document_tips: IDocumentTip[] }> =>
          api.get(`/api/frontend/document_tips?${toQueryParams({ carrier_id })}`)
      : skipToken,
    select: data => data.document_tips
  });

export default useDocumentTips;
