import * as React from 'react';

import Text from '../../../components/core/Text';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import colors from '../../../theme/colors';
import { dateFormatter, DISPLAY_DATE_FORMAT } from '../../../utils/formatter';

const SyncStatus = ({ lastTransaction, policy }: { lastTransaction: PolicyTransaction; policy: IMaticPolicy }) => {
  const supportsPolicySync = policy.carrier.supports_policy_sync;

  if (supportsPolicySync) {
    return (
      <>
        {lastTransaction && (
          <Text color={colors.grey60} type="small">
            Last transaction change: {dateFormatter(lastTransaction.effective_date, DISPLAY_DATE_FORMAT)}
          </Text>
        )}
        {!lastTransaction && (
          <Text color={colors.grey60} type="small">
            Not synced yet
          </Text>
        )}
      </>
    );
  }

  return (
    <Text color={colors.grey60} type="small">
      Not integrated
    </Text>
  );
};

export default SyncStatus;
