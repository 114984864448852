import { skipToken, useQuery } from '@tanstack/react-query';

import { PolicyType } from '../../interfaces/IPolicyType';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface DocumentRuleFilter {
  carrier_id: number | string;
  state: string;
  policy_type: string;
  partner?: string;
}

export enum RuleDemands {
  Required = 'required',
  Optional = 'optional',
  Unavailable = 'unavailable'
}

export interface DocumentRule {
  carrier_id: number;
  policy_type: PolicyType;
  state: string;
  requestor: string;
  responsible: 'cst' | 'agent';
  due_date: string;
  demand: RuleDemands;
  document_type_id: number;
  document_rule_id: number;
  changeable?: boolean;
  could_not_collect?: boolean;
}

const getPolicyDocumentRules = (
  policyId: string | number
): Promise<{ document_rules_policies: DocumentRule[]; document_rules: DocumentRule[] }> =>
  api.get(`/api/frontend/policies/${policyId}/document_rules_policies`);

const getDocumentRules = (
  filter: DocumentRuleFilter
): Promise<{ document_rules: DocumentRule[]; document_rules_policies: DocumentRule[] }> =>
  api.get(`/api/frontend/document_rules?${toQueryParams(filter)}`);

const useDeprecatedDocumentRules = (filter: DocumentRuleFilter, policyId: string | number | null | undefined) =>
  useQuery({
    queryKey: ['documentRules', { filter, policyId }],
    queryFn:
      !!policyId || !!(filter.carrier_id && filter.policy_type && filter.state)
        ? () => (policyId ? getPolicyDocumentRules(policyId) : getDocumentRules(filter))
        : skipToken,
    select: data => (policyId ? data.document_rules_policies : data.document_rules)
  });

export const usePolicyDocumentRules = (policyId: number | null) =>
  useQuery({
    queryKey: ['policy_document_rules', { policyId }],
    queryFn: policyId
      ? () =>
          api.get(`/api/frontend/policies/${policyId}/document_rules_policies`) as Promise<{
            document_rules_policies: DocumentRule[];
          }>
      : skipToken,
    select: data => data.document_rules_policies
  });

export const useDocumentRules = (filter: Partial<DocumentRuleFilter>) =>
  useQuery({
    queryKey: ['document_rules', filter],
    queryFn:
      filter.carrier_id && filter.policy_type && filter.state
        ? () =>
            api.get(`/api/frontend/document_rules?${toQueryParams(filter)}`) as Promise<{
              document_rules: DocumentRule[];
            }>
        : skipToken,
    select: data => data.document_rules
  });

export default useDeprecatedDocumentRules;
