import { useMutation, UseQueryResult } from '@tanstack/react-query';
import React, { useRef } from 'react';
import { Col, Row } from 'reactstrap';

import Button from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import CustomerEditor from '../../../components/CustomerEditor/CustomerEditor';
import { IPerson } from '../../../interfaces/IPerson';
import { updatePerson, UpdatePersonPayload } from '../../../queries/people/usePerson';
import usePersonDrivingRecord, {
  UpdateDrivingRecordPayload,
  updatePersonDrivingRecord
} from '../../../queries/people/usePersonDrivingRecord';

type FormData = UpdatePersonPayload & UpdateDrivingRecordPayload;

const updateCustomer = ({ personGid, formData }: { personGid: string; formData: FormData }) => {
  const { accidents, claims, violations, ...updatePersonPayload } = formData;

  return updatePerson({ ...updatePersonPayload, gid: personGid }).then(() =>
    updatePersonDrivingRecord({
      personGid,
      drivingRecord: {
        accidents,
        claims,
        violations
      }
    })
  );
};
const useUpdateCustomer = (onSuccess: () => void) => useMutation({ mutationFn: updateCustomer, onSuccess });

const DeprecatedCustomerGeneral = ({ personQuery }: { personQuery: UseQueryResult<IPerson, any> }) => {
  const customerRef = useRef<any>();

  const { data: person, isLoading: isLoadingPerson, refetch: refetchPerson } = personQuery;
  const {
    data: drivingRecord,
    isLoading: isLoadingDrivingRecord,
    refetch: refetchDrivingRecord
  } = usePersonDrivingRecord(person?.gid);

  const { mutateAsync: updateCustomer, isPending: isUpdatingCustomer } = useUpdateCustomer(() => {
    refetchDrivingRecord();
    refetchPerson();
  });

  const updateCustomerCallback = () => {
    if (!customerRef.current.validate()) {
      return;
    }

    const customerFormParams = customerRef.current.getData() as FormData;
    return updateCustomer({ personGid: person!.gid, formData: customerFormParams });
  };

  if (isLoadingPerson || isLoadingDrivingRecord) {
    return null;
  }

  return (
    <Container fitParentWidth>
      <FlexBox pb={24}>
        <Text type="large" bold>
          Customer profile
        </Text>
      </FlexBox>

      <div className="customer-form">
        <CustomerEditor
          customer={person}
          drivingRecord={drivingRecord}
          ref={customerRef}
          updateCustomer={updateCustomerCallback}
          isUpdatingCustomer={isUpdatingCustomer}
        />

        <Row>
          <Col sm={11} className="btn-container">
            <Button onClick={updateCustomerCallback} loading={isUpdatingCustomer}>
              Update
            </Button>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default DeprecatedCustomerGeneral;
