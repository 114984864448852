import api from './api';

const autoSearchEntities = {
  get(
    term?: string | undefined
  ): Promise<{ auto_search_entities: { make: string; model: string; submodel: string; year: number }[] }> {
    return api.get(`/api/frontend/auto_search_entities?term=${term}`);
  },
  getAllYears() {
    return api.get('/api/frontend/auto_search_entities/all_years');
  },
  getAllMakes() {
    return api.get('/api/frontend/auto_search_entities/all_makes');
  },
  getAllModels(make: string) {
    return api.get(`/api/frontend/auto_search_entities/all_models?make=${make}`);
  },
  getAllSubmodels({ make, model }: { make: string; model: string }) {
    return api.get(`/api/frontend/auto_search_entities/all_submodels?make=${make}&model=${model}`);
  }
};

export default autoSearchEntities;
