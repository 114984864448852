/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';

const CustomerChoicesTableSkeleton = () => (
  <FlexBox
    p={spacings.px8}
    gap={spacings.px16}
    border
    roundBorder
    customCss={css`
      height: 210px;
      min-width: 530px;
    `}
  >
    <Container>
      <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
        <Skeleton
          circle
          style={{
            width: '32px',
            height: '32px'
          }}
        />
      </SkeletonTheme>
    </Container>
    <Container
      mt={spacings.px8}
      fitParentWidth
      customCss={css`
        max-width: 700px;
      `}
    >
      <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
        <FlexBox alignItemsCenter gap={spacings.px12}>
          <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
            <Container
              fitParentWidth
              customCss={css`
                max-width: 131px;
              `}
            >
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px'
                }}
              />
            </Container>
            <Container
              fitParentWidth
              customCss={css`
                max-width: 100px;
              `}
            >
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px'
                }}
              />
            </Container>
            {[1, 2, 3].map(i => (
              <Container
                key={i}
                fitParentWidth
                customCss={css`
                  max-width: 100px;
                `}
              >
                <div style={{ height: '16px' }} />
              </Container>
            ))}
            <Container
              fitParentWidth
              customCss={css`
                max-width: 100px;
              `}
            >
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px'
                }}
              />
            </Container>
          </SkeletonTheme>
        </FlexBox>
        <FlexBox alignItemsCenter gap={spacings.px24} mt={spacings.px16}>
          <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
            {[1, 2, 3].map(i => (
              <Container
                key={i}
                fitParentWidth
                customCss={css`
                  max-width: 100px;
                `}
              >
                <Skeleton
                  style={{
                    height: '16px',
                    borderRadius: '50px'
                  }}
                />
              </Container>
            ))}
          </SkeletonTheme>
          {[1, 2, 3].map(i => (
            <Container
              key={i}
              fitParentWidth
              customCss={css`
                max-width: 100px;
              `}
            >
              <div style={{ height: '16px' }} />
            </Container>
          ))}
        </FlexBox>
        <FlexBox alignItemsCenter gap={spacings.px24} mt={spacings.px16}>
          <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
            {[1, 2, 3, 4, 5].map(i => (
              <Container
                key={i}
                fitParentWidth
                customCss={css`
                  max-width: 100px;
                `}
              >
                <Skeleton
                  style={{
                    height: '16px',
                    borderRadius: '50px'
                  }}
                />
              </Container>
            ))}
          </SkeletonTheme>
          <Container
            fitParentWidth
            customCss={css`
              max-width: 100px;
            `}
          >
            <div style={{ height: '16px' }} />
          </Container>
        </FlexBox>
        <FlexBox alignItemsCenter gap={spacings.px24} mt={spacings.px16}>
          <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
            <Container
              fitParentWidth
              customCss={css`
                max-width: 131px;
              `}
            >
              <Skeleton
                style={{
                  height: '16px',
                  borderRadius: '50px'
                }}
              />
            </Container>
          </SkeletonTheme>
        </FlexBox>
      </SkeletonTheme>
    </Container>
  </FlexBox>
);

export default CustomerChoicesTableSkeleton;
