import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AutoCoverage } from '../../interfaces/ICoverage';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const usePolicyVehicleCoverages = (
  policyId: number,
  lineOfBusinessId?: number,
  transactionId?: number,
  ivansVehicleId?: number,
  options?: Omit<UseQueryOptions<{ coverages: AutoCoverage[] }, any, any, any>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['policy_vehicle_coverage', policyId, lineOfBusinessId, transactionId, ivansVehicleId],
    queryFn:
      !!lineOfBusinessId && !!transactionId
        ? (): Promise<{ coverages: AutoCoverage[] }> =>
            api.get(
              `/api/frontend/policies/${policyId}/auto/${lineOfBusinessId}/vehicle_coverages?${toQueryParams({
                transaction_id: transactionId,
                ivans_vehicle_id: ivansVehicleId
              })}`
            )
        : skipToken,
    select: data => data.coverages,
    ...options
  });

export default usePolicyVehicleCoverages;
