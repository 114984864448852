import './styles.scss';

import React from 'react';

import Step from './Step';

class Wizard extends React.Component<any, any> {
  state = {
    stepIndex: 0,
    //@ts-expect-error TODO
    names: React.Children.toArray(this.props.children).map((step, i) => step.props.name || `Step ${i + 1}`)
  };

  render() {
    return (
      //@ts-expect-error TODO
      <div className="wizard" ref={ref => (this.wizardRef = ref)}>
        {this.props.header && this.renderHeader()}
        {this.renderStep()}
      </div>
    );
  }

  steps = () => React.Children.toArray(this.props.children);

  currentStep = () => this.steps()[this.state.stepIndex];

  renderHeader = () => {
    const { stepIndex: currentStepIndex, names: steps } = this.state;

    return this.props.header({ steps, currentStepIndex, className: 'wizard__header' });
  };

  renderStep = () =>
    //@ts-expect-error TODO
    React.cloneElement(this.currentStep(), {
      prev: this.prev,
      next: this.next,
      cancelHandler: this.props.cancelHandler,
      stepNames: this.state.names,
      stepIndex: this.state.stepIndex
    });

  //@ts-expect-error TODO
  shouldSkipStep = stepIndex => {
    const nextStep = this.steps()[stepIndex];
    //@ts-expect-error TODO
    const skipFunc = nextStep.props.skipIf;

    return skipFunc && skipFunc();
  };

  prev = async (params = {}) => {
    try {
      //@ts-expect-error TODO
      const result = await this.currentStep().props.onStepBack(params);

      //@ts-expect-error TODO
      if (!this.wizardRef) {
        return;
      }

      if (this.state.stepIndex > 0) {
        const step = this.shouldSkipStep(this.state.stepIndex - 1) ? 2 : 1;

        //@ts-expect-error TODO
        this.setState(({ stepIndex }) => ({ stepIndex: stepIndex - step }));
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  next = async (params = {}) => {
    try {
      //@ts-expect-error TODO
      const result = await this.currentStep().props.onStepForward(params);

      //@ts-expect-error TODO
      if (!this.wizardRef) {
        return;
      }

      if (this.steps().length > this.state.stepIndex + 1) {
        const step = this.shouldSkipStep(this.state.stepIndex + 1) ? 2 : 1;

        this.setState(({ stepIndex }: any) => ({ stepIndex: stepIndex + step }));
      }

      return Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

//@ts-expect-error TODO
Wizard.Step = Step;

//@ts-expect-error TODO
Wizard.defaultProps = {
  header: null,
  cancelHandler: () => {},
  children: []
};

export default Wizard;
