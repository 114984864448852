import * as React from 'react';

import { PDFContext } from '../../../components/common/PDFViewer';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import FlexBox from '../../../components/core/FlexBox';
import Paragraph from '../../../components/core/Paragraph';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import DeleteDocumentModal from '../../../components/FileModals/DeleteModal';
import { useToggle } from '../../../hooks';
import { FileToUpload, IDocument } from '../../../interfaces/IDocument';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { fileSizeFormatter } from '../../../utils/formatter';
import useVerticalTabsCallbacks from '../../GuidedSellingExperience/_hooks/useVerticalTabsCallbacks';

const DocumentRow = ({
  file,
  onDelete,
  preview = true
}: {
  file: IDocument | FileToUpload;
  onDelete: (file: IDocument | FileToUpload) => Promise<unknown>;
  preview?: boolean;
}) => {
  const documentTypeTitle = file.document_type_title?.toString();
  const [fileToPreview, setFileToPreview] = React.useContext(PDFContext);
  const [deleteFile, toggleDeleteFile] = useToggle(false);
  const { onTabClose } = useVerticalTabsCallbacks();
  const fileIsPreviewed = file === fileToPreview;

  return (
    <>
      <FlexBox pv={spacings.px6} alignItemsCenter justifySpaceBetween>
        <FlexBox alignItemsCenter fitParentWidth>
          <Tag
            label={documentTypeTitle || 'Unknown'}
            backgroundColor={documentTypeTitle ? colors.violet : colors.grey60}
          />
          <Text className="fs-mask" singleLine pl={spacings.px8} title={file.name}>
            {file.name}
          </Text>
        </FlexBox>
        <FlexBox gap={spacings.px8} alignItemsCenter>
          <Paragraph type="small" color={colors.grey60} pl={spacings.px8} singleLine>
            {fileSizeFormatter(file.filesize)}
          </Paragraph>
          {preview && (
            <IconButton
              focus={fileIsPreviewed}
              color={colors.azure50}
              icon={ButtonIcons.PictureInPicture}
              onClick={() => {
                setFileToPreview(fileIsPreviewed ? undefined : file);
                onTabClose();
              }}
            />
          )}
          <IconButton
            color={colors.black}
            icon={ButtonIcons.Delete}
            onClick={() => {
              toggleDeleteFile();
            }}
          />
        </FlexBox>
      </FlexBox>
      {deleteFile && (
        <DeleteDocumentModal document={file} toggleDeleteDocument={toggleDeleteFile} onDelete={() => onDelete(file)} />
      )}
    </>
  );
};

export default DocumentRow;
