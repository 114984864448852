/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Container from '../../../../components/core/Container';
import DescriptionList from '../../../../components/core/DescriptionList';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const popperWidth = 400;
const maxPopperHeight = 470;

export const CommunicatorLayout = ({
  name,
  tagLabel,
  info
}: {
  name: string;
  info: string | null;
  tagLabel?: string | null;
}): JSX.Element => {
  return (
    <FlexBox
      alignItemsCenter
      gap={spacings.px4}
      customCss={css`
        height: 100%;
        max-width: ${popperWidth - 70}px;
      `}
    >
      <Paragraph
        type="small"
        bold
        customCss={css`
          white-space: nowrap;
        `}
        className="fs-mask"
      >
        {name}
      </Paragraph>
      {tagLabel && <Tag label={tagLabel} transparent backgroundColor={colors.grey80} textColor={colors.grey80} />}
      {info && (
        <Paragraph
          color={colors.grey60}
          type="small"
          customCss={css`
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          `}
          className="fs-mask"
        >
          ({info})
        </Paragraph>
      )}
    </FlexBox>
  );
};

const InteractionDetailsLayout = ({
  header,
  sender,
  receiver,
  body,
  expandBody
}: {
  header: React.ReactNode;
  sender: React.ReactNode;
  receiver: React.ReactNode;
  body: React.ReactNode;
  expandBody?: boolean;
}): JSX.Element => {
  return (
    <FlexBox
      ph={spacings.px12}
      pv={spacings.px8}
      columnDirection
      backgroundColor={colors.white}
      roundBorder
      border
      boxShadow
      customCss={css`
        max-height: ${expandBody ? '90vh' : `${maxPopperHeight}px`};
        width: ${popperWidth}px;
      `}
    >
      <FlexBox pb={spacings.px12} justifySpaceBetween borderBottom>
        {header}
      </FlexBox>
      <FlexBox pb={spacings.px12} borderBottom columnDirection>
        <DescriptionList
          type="small"
          term={
            <FlexBox
              alignItemsCenter
              customCss={css`
                height: 100%;
              `}
            >
              <Paragraph type="small" color={colors.grey60}>
                from
              </Paragraph>
            </FlexBox>
          }
          details={sender}
        />
        <DescriptionList
          type="small"
          term={
            <FlexBox
              alignItemsCenter
              customCss={css`
                height: 100%;
              `}
            >
              <Paragraph type="small" color={colors.grey60}>
                to
              </Paragraph>
            </FlexBox>
          }
          details={receiver}
        />
      </FlexBox>
      <Container
        pt={spacings.px12}
        className="fs-mask"
        customCss={css`
          overflow: ${expandBody ? 'scroll' : 'hidden'};
        `}
      >
        {body}
      </Container>
    </FlexBox>
  );
};
export default InteractionDetailsLayout;
