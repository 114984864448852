/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { Col, Container, Row } from 'reactstrap';

import deleteIcon from '../../../../assets/new_icons/delete.svg';
import { EmptyResults } from '../../../../components/common';
import { documentFilePreviewUrl, FileToUpload, IDocument } from '../../../../interfaces/IDocument';
import colors from '../../../../theme/colors';
import { dateTimeFormatter, fileSizeFormatter } from '../../../../utils/formatter';

interface DocumentsListProps {
  documents: IDocument[] | undefined;
  filesToUpload: FileToUpload[] | undefined;
  onDelete: (fileId: number) => void;
}

const uploadProgressStyles = css`
  background: transparent;
  bottom: 0;
  left: 0;
  opacity: 0.3;
  position: absolute;
  top: 0;
  transition:
    width 1s,
    background 3s;
  animation: progress-upload 1s;

  @keyframes progress-upload {
    from {
      width: 0;
      background: ${colors.statusGreen};
    }

    to {
      width: 100%;
      background: ${colors.statusGreen};
    }
  }
`;

const DocumentsList = ({ documents, filesToUpload, onDelete }: DocumentsListProps) => {
  if (!documents?.length && !filesToUpload?.length) {
    return <EmptyResults message="No files yet" />;
  }
  return (
    <Container className="table" data-testid="lead-files-table">
      <Row className="table-headers">
        <Col sm={3} className="table-cell">
          Filename
        </Col>
        <Col sm={2} className="table-cell">
          Type
        </Col>
        <Col sm={2} className="table-cell">
          Attached to
        </Col>
        <Col sm={2} className="table-cell">
          Size
        </Col>
        <Col sm={2} className="table-cell">
          Uploaded at
        </Col>
        <Col sm={1} className="table-cell actions">
          Actions
        </Col>
      </Row>
      {filesToUpload?.map(file => {
        return (
          <Row
            key={file.uid}
            className="table-row"
            css={css`
              position: relative;
            `}
          >
            <div css={uploadProgressStyles} />
            <Col sm={3} className="table-cell fs-mask">
              {file.name}
            </Col>
            <Col sm={2} className="table-cell">
              {file.document_type_title}
            </Col>
            <Col sm={2} className="table-cell">
              -
            </Col>
            <Col sm={2} className="table-cell">
              {fileSizeFormatter(file.filesize)}
            </Col>
            <Col sm={2} className="table-cell">
              -
            </Col>
            <Col sm={1} className="table-cell">
              -
            </Col>
          </Row>
        );
      })}
      {documents?.map((document, index) => {
        return (
          <Row
            key={document.id}
            className="table-row"
            data-testid="file-row"
            css={css`
              word-wrap: break-word;
            `}
          >
            <Col sm={3} className="table-cell">
              <a target="_blank" rel="noopener noreferrer" href={documentFilePreviewUrl(document)} className="fs-mask">
                {document.name}
              </a>
            </Col>
            <Col sm={2} className="table-cell">
              {document.document_type_title}
            </Col>
            <Col sm={2} className="table-cell">
              {document.owner_type}
            </Col>
            <Col sm={2} className="table-cell">
              {fileSizeFormatter(document.filesize)}
            </Col>
            <Col sm={2} className="table-cell">
              {dateTimeFormatter(document.uploaded)}
            </Col>
            <Col sm={1} className="table-cell">
              <img
                src={deleteIcon}
                alt="Delete"
                data-testid={`delete-file-${index}`}
                css={css`
                  &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                  }
                `}
                onClick={() => onDelete(document.id)}
              />
            </Col>
          </Row>
        );
      })}
    </Container>
  );
};

export default DocumentsList;
