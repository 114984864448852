/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { NavLink as RouterLink } from 'react-router-dom';
import { Nav, NavItem, NavLink } from 'reactstrap';

import featureFlags from '../../../constants/featureFlags';
import authInfo from '../../../services/authInfo';
import { navigationLinkCss } from '../../Lead/LeadNavigation';

const CustomerNavigation = () => {
  return (
    <Nav
      tabs
      data-testid="navigation-tabs"
      css={css`
        border-bottom: 0;
        width: 100%;
      `}
    >
      <NavItem>
        <NavLink to="general" tag={RouterLink} css={navigationLinkCss}>
          General
        </NavLink>
      </NavItem>
      {!featureFlags.hideCustomerHouseholdMembersTab && (
        <NavItem>
          <NavLink to="household_members" tag={RouterLink} css={navigationLinkCss}>
            Household members
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink to="leads" tag={RouterLink} css={navigationLinkCss}>
          Leads
        </NavLink>
      </NavItem>
      {!featureFlags.hideCustomerTasksTab && (
        <NavItem>
          <NavLink to="tasks" tag={RouterLink} css={navigationLinkCss}>
            Tasks
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideCustomerLoansTab && (
        <NavItem>
          <NavLink to="loans" tag={RouterLink} css={navigationLinkCss}>
            Loans
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideCustomerAssetsTab && (
        <NavItem>
          <NavLink to="assets" tag={RouterLink} css={navigationLinkCss}>
            Assets
          </NavLink>
        </NavItem>
      )}
      <NavItem>
        <NavLink to="policies" tag={RouterLink} css={navigationLinkCss}>
          Policies
        </NavLink>
      </NavItem>
      {authInfo.features.extended_policy_list_view && (
        <NavItem>
          <NavLink to="admin_policies" tag={RouterLink} css={navigationLinkCss}>
            Admin Policies
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideCustomerFilesTab && (
        <NavItem>
          <NavLink to="documents" tag={RouterLink} css={navigationLinkCss}>
            Files
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideCustomerNotesTab && (
        <NavItem>
          <NavLink to="notes" tag={RouterLink} css={navigationLinkCss}>
            Notes
          </NavLink>
        </NavItem>
      )}
      {!featureFlags.hideCustomerPriorPoliciesTab && (
        <NavItem>
          <NavLink to="prior_policies" tag={RouterLink} css={navigationLinkCss}>
            Prior policies
          </NavLink>
        </NavItem>
      )}
      {authInfo.features.customer_profile_quoting && !featureFlags.hideCustomerQuotesTab && (
        <NavItem>
          <NavLink to="quotes" tag={RouterLink} css={navigationLinkCss}>
            Quotes
          </NavLink>
        </NavItem>
      )}
    </Nav>
  );
};

export default CustomerNavigation;
