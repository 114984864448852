/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import { WarningIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { Qualification } from '../../../queries/people/usePersonAssetQualification';
import colors from '../../../theme/colors';

const DisqualificationTip = ({ qualification, questionKey }: { qualification: Qualification; questionKey: string }) => {
  const showTip =
    qualification.result === 'disqualified' && qualification.disqualification_datapoints?.includes(questionKey);

  React.useEffect(() => {
    ReactTooltip.rebuild();
  }, [showTip]);

  return showTip ? (
    <Text color={colors.statusRed} data-tip data-for={`${questionKey}-tip`}>
      <WarningIcon
        css={css`
          margin: 0 4px;
          vertical-align: text-top;
        `}
      />
      Ineligible
      <Tooltip id={`${questionKey}-tip`} theme="light">
        <Paragraph
          customCss={css`
            max-width: 300px;
          `}
        >
          {qualification.agent_explanation}
        </Paragraph>
      </Tooltip>
    </Text>
  ) : null;
};

export default DisqualificationTip;
