/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import CollapsingContainer from '../../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph, { ParagraphType } from '../../../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../../../components/core/PolicyTypeIcon';
import Text from '../../../../../components/core/Text';
import featureFlags from '../../../../../constants/featureFlags';
import { PolicyType } from '../../../../../interfaces/IPolicyType';
import { QuoteStatus } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { isQuoteEligibleOrPartlyEligible, isQuoteNonEligible } from '../../_helpers';
import useQuotesModalView from '../../_hooks/useModalView';
import QuotingErrorsByAssets from '../../QuotingErrors/QuotingErrorsByAssets';
import LifeGroupHeader from '../GroupHeaders/LifeGroupHeader';
import LifeQuoteRow from '../QuoteRow/LifeQuoteRow';
import { QuoteGroupProps } from '.';

const LifeQuoteGroup = ({ quotesGroup: { quotes }, quotingFinished, errors, quotesRequest }: QuoteGroupProps) => {
  const { quotesModalView } = useQuotesModalView();

  const [isPremiumAnnual, setAnnualPremium] = React.useState(true);

  const realQuotes = quotes.filter(({ status }) => status !== QuoteStatus.CompleteManually);

  const eligibleQuotes = realQuotes.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleQuotes = realQuotes.filter(quote => isQuoteNonEligible(quote));

  const carriersRecommendations = quotes.filter(({ status }) => status === QuoteStatus.CompleteManually);

  const eligibleRecommendations = carriersRecommendations.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleRecommendations = carriersRecommendations.filter(quote => !isQuoteEligibleOrPartlyEligible(quote));

  const eligibleEntriesCount = eligibleQuotes.length + eligibleRecommendations.length;
  const nonEligibleEntriesCount = notEligibleQuotes.length + notEligibleRecommendations.length;

  return (
    <FlexBox columnDirection gap={spacings.px8}>
      {!quotesModalView && (
        <>
          <FlexBox justifySpaceBetween alignItemsCenter>
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <PolicyTypeIcon policyType={PolicyType.TermLife} />
              <Text
                bold={!featureFlags.newQuotesUI}
                type="large"
                customCss={css`
                  white-space: pre-wrap;
                `}
              >
                Life ({quotes.length})
              </Text>
            </FlexBox>
            {featureFlags.newQuotesUI && errors && errors.length > 0 && (
              <QuotingErrorsByAssets errors={errors} quotesRequest={quotesRequest} />
            )}
          </FlexBox>
        </>
      )}

      {eligibleEntriesCount > 0 && (
        <>
          <LifeGroupHeader isPremiumAnnual={isPremiumAnnual} setAnnualPremium={setAnnualPremium} />
          <CollapsingContainer
            title={`Eligible and partly eligible (${eligibleEntriesCount})`}
            titleSize={ParagraphType.Default}
            iconPosition="left"
          >
            {eligibleQuotes.map(quote => (
              <LifeQuoteRow key={quote.gid} quote={quote} isPremiumAnnual={isPremiumAnnual} />
            ))}

            {eligibleRecommendations.map(recommendation => (
              <LifeQuoteRow key={recommendation.gid} quote={recommendation} isPremiumAnnual={isPremiumAnnual} />
            ))}
          </CollapsingContainer>
        </>
      )}
      {quotingFinished && eligibleEntriesCount === 0 && nonEligibleEntriesCount ? (
        <CollapsingContainer
          title={`Eligible and partly eligible (${nonEligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          <FlexBox alignItemsCenter justifyCenter pv={spacings.px24}>
            <Paragraph color={colors.grey60}>No quotes available</Paragraph>
          </FlexBox>
        </CollapsingContainer>
      ) : null}

      {nonEligibleEntriesCount > 0 && (
        <CollapsingContainer
          title={`Not eligible (${nonEligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
          openedByDefault={false}
        >
          {notEligibleQuotes.map(quote => (
            <LifeQuoteRow key={quote.gid} quote={quote} isPremiumAnnual={isPremiumAnnual} />
          ))}

          {notEligibleRecommendations.map(recommendation => (
            <LifeQuoteRow key={recommendation.gid} quote={recommendation} isPremiumAnnual={isPremiumAnnual} />
          ))}
        </CollapsingContainer>
      )}
      {quotingFinished && !eligibleEntriesCount && !nonEligibleEntriesCount && (
        <FlexBox justifyCenter pv={spacings.px24}>
          <Paragraph color={colors.grey60}>No quotes available</Paragraph>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default LifeQuoteGroup;
