import React from 'react';

import useAccidentTypes from '../../../../../../queries/accident_types/useAccidentTypes';
import IncidentField from '../IncidentField';

const AccidentFieldWithOptions = (props: any) => {
  const { data: accidentTypes, isLoading } = useAccidentTypes();

  return <IncidentField {...props} loading={isLoading} options={accidentTypes} />;
};

export default AccidentFieldWithOptions;
