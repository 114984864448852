import { Modal as ReactStrapModal, ModalBody } from 'reactstrap';

interface ImageModalProps {
  source: string;
  onClose: () => void;
}

const ImageModal = ({ source, onClose }: ImageModalProps) => (
  <ReactStrapModal
    isOpen
    toggle={() => onClose()}
    style={{ width: 'fit-content', height: 'fit-content', maxWidth: '90%', maxHeight: '90%' }}
  >
    <ModalBody>
      <img src={source} />
    </ModalBody>
  </ReactStrapModal>
);

export default ImageModal;
