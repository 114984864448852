import './TimePeriod.scss';

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Select from '../Select/Select';

export default class TimePeriod extends PureComponent<any, any> {
  constructor(props: { period: any; possiblePeriods: any; form: { setFieldValue: any } }) {
    super(props);

    const {
      period,
      possiblePeriods: periods,
      form: { setFieldValue }
    } = props;
    !period && setFieldValue('period', (periods && periods.length === 1 && periods[0]) || '');
  }

  componentDidUpdate({ possiblePeriods: prevPossiblePeriods }: any) {
    const { possiblePeriods, period } = this.props;
    const isOptionsUpdated = prevPossiblePeriods.toString() !== possiblePeriods.toString();
    const isPeriodInvalid = isOptionsUpdated && !possiblePeriods.includes(period);

    if (isPeriodInvalid) {
      if (possiblePeriods.length === 1) {
        this.updatePeriod(possiblePeriods[0]);
      } else {
        this.updatePeriod(0);
      }
    }
  }

  getPeriodLabel = (period: number) => {
    if (period % 12) {
      return `${period} months`;
    }

    const years = period / 12;

    return `${years} year${years === 1 ? '' : 's'}`;
  };

  getPeriodOptions = () =>
    this.props.possiblePeriods.map((period: any) => ({
      key: period,
      value: this.getPeriodLabel(period)
    }));

  onSelectHandler = ({ target: { value } }: any) => this.updatePeriod(value);

  updatePeriod = (period: number) => {
    const {
      form: { setFieldValue },
      onChange,
      period: prevPeriod
    } = this.props;

    if (period === prevPeriod) {
      return;
    }

    setFieldValue('period', period);
    onChange({ period });
  };

  render() {
    const { period, possiblePeriods } = this.props;

    return (
      <Select
        name="period"
        value={period}
        options={this.getPeriodOptions()}
        onChange={this.onSelectHandler}
        showResetButton={false}
        disabled={possiblePeriods.length < 2}
      />
    );
  }
}

// @ts-expect-error old code
TimePeriod.propTypes = {
  period: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  possiblePeriods: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  form: PropTypes.shape({
    setFieldValue: PropTypes.func
  }).isRequired
};
// @ts-expect-error old code
TimePeriod.defaultProps = {
  period: null,
  onChange: () => {}
};
