import { useQuery } from '@tanstack/react-query';

import { ICandidate } from '../../interfaces';
import api from '../../services/api';

export const getPersonCandidates = (personGid: string | undefined): Promise<{ candidates: ICandidate[] }> =>
  api.get(`/api/frontend/people/${personGid}/candidates`);

const usePersonCandidates = (personGid: string | undefined) =>
  useQuery({
    queryKey: ['person_candidates', personGid],
    queryFn: () => getPersonCandidates(personGid),
    select: data => data.candidates,
    enabled: !!personGid
  });

export default usePersonCandidates;
