import { useQuery } from '@tanstack/react-query';

import { IPolicy } from '../../../interfaces';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

interface PersonPoliciesFilters {
  policy_types?: null | string[];
  similar_policy_types?: null | boolean;
  asset_gid?: null | string;
  deduplicate_by_expiration_date?: boolean;
}

export const getPersonAllPolicies = ({
  personGid,
  filters
}: {
  personGid: string;
  filters?: PersonPoliciesFilters;
}): Promise<{ person_policies: IPolicy[] }> =>
  api.get(
    `/api/frontend/people/${personGid}/policies/v2?${toQueryParams({
      ...filters
    })}`
  );

const usePersonAllPolicies = ({ personGid, filters }: { personGid: string; filters?: PersonPoliciesFilters }) =>
  useQuery({
    queryKey: [personGid, 'person_policies', filters],
    queryFn: () => getPersonAllPolicies({ personGid, filters }),
    select: data => data.person_policies
  });

export default usePersonAllPolicies;
