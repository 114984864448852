/** @jsxImportSource @emotion/react */

import './FallbackAddress.scss';

import { css, Global } from '@emotion/react';
import { Field, getIn, useFormikContext } from 'formik';
import * as React from 'react';
import { Button, Label, Row } from 'reactstrap';

import closeIcon from '../../../assets/new_icons/close.svg';
import { Translations } from '../../../constants';
import { CITY, SMARTY_LINE1, SMARTY_LINE2, SMARTY_ZIP, STATE } from '../../../constants/addressForm';
import CopyField from '../CopyField/CopyField';
import ErrorCol from '../ErrorCol';
import { Input, Select } from '../FormikComponents';

interface Props {
  hideFallback: () => void;
  label: string;
  names: Record<string, string>;
  warningMessage?: string;
}
const FallbackAddress: React.FC<Props> = ({ names, hideFallback, label, warningMessage }) => {
  const { errors, touched, values: formikValues } = useFormikContext();
  const values = formikValues as Record<string, string>;

  return (
    <div className="c-fallback">
      <Global
        styles={() => css`
          .c-fallback__close {
            mask: url(${closeIcon}) no-repeat center;
          }
        `}
      />
      <div className="d-flex align-items-center justify-content-between">
        <Label className="c-fallback__label">{label}</Label>
        <Button aria-label="Close" variant="transparent" size="for-icon" type="button" onClick={hideFallback}>
          <i className="c-fallback__close" />
        </Button>
      </div>
      <Row>
        <ErrorCol
          sm={6}
          error={getIn(errors, names[SMARTY_LINE1]!)}
          when={getIn(touched, names[SMARTY_LINE1]!)}
          warning={warningMessage}
        >
          <Label htmlFor={names[SMARTY_LINE1]}>Address *</Label>
          <CopyField value={values[names[SMARTY_LINE1]!]}>
            <Field component={Input} id={names[SMARTY_LINE1]} name={names[SMARTY_LINE1]} className="fs-mask" />
          </CopyField>
        </ErrorCol>
        <ErrorCol sm={6}>
          <Label htmlFor={names[SMARTY_LINE2]}>Address 2</Label>
          <CopyField value={values[names[SMARTY_LINE2]!]}>
            <Field component={Input} name={names[SMARTY_LINE2]} id={names[SMARTY_LINE2]} className="fs-mask" />
          </CopyField>
        </ErrorCol>
      </Row>
      <Row>
        <ErrorCol
          sm={6}
          error={getIn(errors, names[CITY]!)}
          when={getIn(touched, names[CITY]!)}
          warning={warningMessage}
        >
          <Label htmlFor={names[CITY]}>City *</Label>
          <CopyField value={values[names[CITY]!]}>
            <Field component={Input} name={names[CITY]} id={names[CITY]} className="fs-mask" />
          </CopyField>
        </ErrorCol>
        <ErrorCol
          sm={3}
          error={getIn(errors, names[STATE]!)}
          when={getIn(touched, names[STATE]!)}
          warning={warningMessage}
        >
          <Label htmlFor={names[STATE]}>State *</Label>
          <Field
            component={Select}
            name={names[STATE]}
            inputId={names[STATE]}
            options={Translations.usaStates}
            className="fs-mask"
            placeholder="Select"
          />
        </ErrorCol>
        <ErrorCol
          sm={3}
          error={getIn(errors, names[SMARTY_ZIP]!)}
          when={getIn(touched, names[SMARTY_ZIP]!)}
          warning={warningMessage}
        >
          <Label htmlFor={names[SMARTY_ZIP]}>Zip *</Label>
          <CopyField value={values[names[SMARTY_ZIP]!]}>
            <Field component={Input} name={names[SMARTY_ZIP]} id={names[SMARTY_ZIP]} className="fs-mask" />
          </CopyField>
        </ErrorCol>
      </Row>
    </div>
  );
};

export default FallbackAddress;
