import usePersonVehicles from '../../../../queries/people/personVehicles';
import usePerson from '../../../../queries/people/usePerson';
import usePersonAddresses from '../../../../queries/people/usePersonAddresses';
import usePersonHomes from '../../../../queries/people/usePersonHomes';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';

const usePersonAssetsData = (personGid: string) => {
  const { data: person, isFetching: isFetchingPerson } = usePerson(personGid);
  const { data: homes, isFetching: isFetchingHomes, refetch: refetchHomes } = usePersonHomes(personGid);
  const { data: vehicles, isFetching: isFetchingVehicles, refetch: refetchVehicles } = usePersonVehicles(personGid);
  const {
    data: personAddresses,
    isFetching: isFetchingPersonAddresses,
    refetch: refetchPersonAddresses
  } = usePersonAddresses(personGid);
  const { data: relatedPeople, isFetching: isFetchingRelatedPeople } = usePersonRelatedPeople(personGid);

  const isFetching =
    isFetchingPerson || isFetchingHomes || isFetchingVehicles || isFetchingPersonAddresses || isFetchingRelatedPeople;

  return {
    person,
    homes,
    vehicles,
    relatedPeople,
    personAddresses,
    isFetching,
    refetch: () => {
      refetchHomes();
      refetchVehicles();
      refetchPersonAddresses();
    }
  };
};

export default usePersonAssetsData;
