import Grid from '@mui/material/Grid';
import { FormikHelpers, FormikValues, getIn } from 'formik';
import * as React from 'react';

import {
  calculateCommutingMileage,
  calculateDaysPerWeek,
  calculateMileage,
  hasWorkOrSchoolValue
} from '../../../components/AnnualMileageCalculator/helpers';
import {
  AddressField,
  CheckboxField,
  InputField,
  NumericField,
  SelectField
} from '../../../components/core/forms/fields';
import { Translations } from '../../../constants';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../constants/addressForm';
import { IAddressSuggestion, IPersonAddress } from '../../../interfaces';
import { sizes } from '../../../theme/variables';
import { createSmartyStreetsMapping, renameKeys } from '../../../utils/object';
import { VehicleFields } from './formHelpers';

const VehicleEditor = ({
  vehicleGid,
  formikValues,
  setValues,
  personAddresses
}: {
  vehicleGid: string;
  formikValues: FormikValues;
  setValues: FormikHelpers<any>['setValues'];
  personAddresses: IPersonAddress[] | undefined;
}) => {
  return (
    <Grid container maxWidth={sizes.mediumFormInputWidth * 5}>
      <Grid container columnSpacing={2} columns={10}>
        <Grid item xs={2}>
          <InputField
            label="VIN"
            id={`${vehicleGid}_${VehicleFields.VIN}`}
            name={`${vehicleGid}_${VehicleFields.VIN}`}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectField
            required
            label="Vehicle usage"
            id={`${vehicleGid}_${VehicleFields.Usage}`}
            name={`${vehicleGid}_${VehicleFields.Usage}`}
            options={Translations.vehicleUsageOptions}
            onChange={({ target }) => {
              setValues((oldValues: FormikValues) => ({
                ...oldValues,
                [`${vehicleGid}_${VehicleFields.AnnualMileage}`]: calculateMileage(
                  target.value,
                  getIn(formikValues, `${vehicleGid}_${VehicleFields.DaysPerWeekDriven}`),
                  getIn(formikValues, `${vehicleGid}_${VehicleFields.OneWayMileage}`)
                )?.toString(),
                [`${vehicleGid}_${VehicleFields.Usage}`]: target.value
              }));
            }}
          />
        </Grid>
        {hasWorkOrSchoolValue(getIn(formikValues, `${vehicleGid}_${VehicleFields.Usage}`)) && (
          <>
            <Grid item xs={2}>
              <InputField
                label="Days driven weekly"
                id={`${vehicleGid}_${VehicleFields.DaysPerWeekDriven}`}
                name={`${vehicleGid}_${VehicleFields.DaysPerWeekDriven}`}
                type="number"
                onChange={({ target }) => {
                  setValues((oldValues: FormikValues) => ({
                    ...oldValues,
                    [`${vehicleGid}_${VehicleFields.AnnualMileage}`]: calculateCommutingMileage(
                      target.value,
                      getIn(formikValues, `${vehicleGid}_${VehicleFields.OneWayMileage}`)
                    )?.toString(),
                    [`${vehicleGid}_${VehicleFields.DaysPerWeekDriven}`]: target.value
                  }));
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <NumericField
                label="One-way mileage"
                id={`${vehicleGid}_${VehicleFields.OneWayMileage}`}
                name={`${vehicleGid}_${VehicleFields.OneWayMileage}`}
                valueIsNumericString
                onValueChange={({ value }) => {
                  setValues((oldValues: FormikValues) => ({
                    ...oldValues,
                    [`${vehicleGid}_${VehicleFields.AnnualMileage}`]: calculateCommutingMileage(
                      getIn(formikValues, `${vehicleGid}_${VehicleFields.DaysPerWeekDriven}`),
                      value
                    )?.toString(),
                    [`${vehicleGid}_${VehicleFields.OneWayMileage}`]: value
                  }));
                }}
              />
            </Grid>
          </>
        )}
        <Grid item xs={2}>
          <NumericField
            required
            label="Annual mileage"
            id={`${vehicleGid}_${VehicleFields.AnnualMileage}`}
            name={`${vehicleGid}_${VehicleFields.AnnualMileage}`}
            valueIsNumericString
            onValueChange={({ value }) => {
              setValues((oldValues: FormikValues) => ({
                ...oldValues,
                [`${vehicleGid}_${VehicleFields.DaysPerWeekDriven}`]: calculateDaysPerWeek(
                  getIn(formikValues, `${vehicleGid}_${VehicleFields.Usage}`),
                  value,
                  getIn(formikValues, `${vehicleGid}_${VehicleFields.OneWayMileage}`)
                )?.toString(),
                [`${vehicleGid}_${VehicleFields.AnnualMileage}`]: value
              }));
            }}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} columns={10} alignItems="end">
        <Grid item xs={2}>
          <InputField
            label="Plate type"
            id={`${vehicleGid}_${VehicleFields.PlateType}`}
            name={`${vehicleGid}_${VehicleFields.PlateType}`}
          />
        </Grid>
        <Grid item xs={2}>
          <InputField
            label="Plate number"
            id={`${vehicleGid}_${VehicleFields.PlateNumber}`}
            name={`${vehicleGid}_${VehicleFields.PlateNumber}`}
          />
        </Grid>
        <Grid item xs={2}>
          <SelectField
            required
            label="Ownership"
            id={`${vehicleGid}_${VehicleFields.Ownership}`}
            name={`${vehicleGid}_${VehicleFields.Ownership}`}
            options={Translations.vehicleOwnershipOptions}
          />
        </Grid>
        <Grid item>
          <CheckboxField
            label="Used for ridesharing"
            id={`${vehicleGid}_${VehicleFields.UsedForRidesharing}`}
            name={`${vehicleGid}_${VehicleFields.UsedForRidesharing}`}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} columns={10}>
        <Grid item xs={4}>
          <AddressField
            required
            label="Garaging address"
            testId="garaging_address"
            id={`${vehicleGid}_${VehicleFields.Address}`}
            placeholder=""
            defaultValue={
              renameKeys(
                API_TO_SMARTY_STREETS_MAPPING,
                formikValues[`${vehicleGid}_${VehicleFields.Address}`]
              ) as IAddressSuggestion
            }
            defaultOptions={
              (personAddresses?.map(address => renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)) ||
                []) as IAddressSuggestion[]
            }
            fallbackNames={createSmartyStreetsMapping(`${vehicleGid}_${VehicleFields.Address}.`)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default VehicleEditor;
