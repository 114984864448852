import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IProductAnnouncementReactionType } from '../../interfaces/IProductAnnouncementReaction';
import api from '../../services/api';
import { PRODUCT_ANNOUNCEMENT_REACTIONS_QUERY_KEY } from './useProductAnnouncementReactions';

const createProductAnnouncementReaction = ({
  productAnnouncementId,
  reactionType
}: {
  productAnnouncementId: number;
  reactionType: IProductAnnouncementReactionType;
}) =>
  api.post(`/api/frontend/product_announcements/${productAnnouncementId}/reactions`, {
    body: { reaction_type: reactionType }
  });

const useCreateProductAnnouncementReaction = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: createProductAnnouncementReaction,
    onSuccess: (result, variables) =>
      queryClient.setQueryData(
        [PRODUCT_ANNOUNCEMENT_REACTIONS_QUERY_KEY, variables.productAnnouncementId],
        () => result
      )
  });
};

export default useCreateProductAnnouncementReaction;
