import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ILead } from '../../interfaces/ILead';
import api from '../../services/api';

export const getLead = (gid: string | number | undefined): Promise<{ lead: ILead }> =>
  api.get(`/api/frontend/leads/${gid}`);

const useLead = (gid: string | number | undefined, options?: UseQueryOptions<{ lead: ILead }, any, any, any>) =>
  useQuery({
    queryKey: ['lead_by_gid', gid],
    queryFn: () => getLead(gid),
    ...options,
    enabled: !!gid,
    select: data => data.lead
  });

export default useLead;
