/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React, { ReactElement } from 'react';
import { Navigate, Route, Routes, useLocation, useMatch, useNavigate, useParams } from 'react-router-dom';

import alert from '../../../components/core/Alert';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import PolicyList from '../../../components/PolicyList/PolicyList';
import CancelModal from '../../../components/PolicyModals/CancelModal';
import NonRenewModal from '../../../components/PolicyModals/NonRenewModal';
import PolicyCreator from '../../../components/PolicyModals/PolicyCreator';
import PolicyEditor from '../../../components/PolicyModals/PolicyEditor';
import PolicyRenewer from '../../../components/PolicyModals/PolicyRenewer';
import PolicyRewriter from '../../../components/PolicyModals/PolicyRewriter';
import TablePlaceholder from '../../../components/TablePlaceholder';
import useToggle from '../../../hooks/useToggle';
import { IMaticPolicy, IPerson, IPersonAsset } from '../../../interfaces';
import { POLICY_RENEWABLE_STATUSES } from '../../../interfaces/IPolicy';
import { PolicyType } from '../../../interfaces/IPolicyType';
import PolicyDetails from '../../../pages/PolicyDetails';
import useAssignees from '../../../queries/assignees/useAssignees';
import useCarriers from '../../../queries/carriers/useCarriers';
import useCreateMaticPolicy from '../../../queries/people/person_policies/useCreateMaticPolicy';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import useRenewPolicy from '../../../queries/people/person_policies/useRenewPolicy';
import useRewritePolicy from '../../../queries/people/person_policies/useRewritePolicy';
import useUpdatePolicy from '../../../queries/people/person_policies/useUpdatePolicy';
import usePersonVehicles from '../../../queries/people/personVehicles';
import usePersonHomes from '../../../queries/people/usePersonHomes';
import authInfo from '../../../services/authInfo';
import { spacings } from '../../../theme/variables';
import { GuidedSellingPathnames } from '../../GuidedSellingExperience/navigation';
import PolicyAssetsPicker from '../../Lead/LeadPolicies/PolicyAssetsPicker';

const PolicyIdProvider = ({ children }: { children: (policyId: string | undefined) => JSX.Element }) => {
  const { policyId } = useParams();

  return children(policyId);
};

const policiesPath = ({
  person,
  postSalesExperience,
  search,
  subPath
}: {
  person: IPerson;
  postSalesExperience: boolean | undefined;
  search: string;
  subPath?: string;
}) => {
  if (postSalesExperience) {
    return `${GuidedSellingPathnames.Finalize}${subPath || ''}${search}`;
  }

  return `/people/${person.gid}/policies${subPath || ''}`;
};

const CustomerPolicies = ({
  person,
  postSalesExperience,
  leadId
}: {
  person: IPerson;
  postSalesExperience?: boolean;
  leadId?: number;
}): ReactElement => {
  const { search } = useLocation();

  const [openPolicyCreator, togglePolicyCreator] = useToggle(false);
  const [policyDetails, setPolicyDetails] = React.useState<undefined | Pick<IMaticPolicy, 'policy_type' | 'assets'>>();

  const {
    data: policies,
    isPending: isPendingPolicies,
    refetch: refetchPolicies
  } = usePersonMaticPolicies({
    personGid: person.gid
  });
  const { data: carriers, isPending: isPendingCarriers } = useCarriers({
    appointed_in_past: true,
    post_sale: authInfo.features.allow_post_sale_carriers
  });
  const { data: assignees, isPending: isPendingAssignees } = useAssignees();
  const { data: homes, isPending: isPendingHomes } = usePersonHomes(person.gid);
  const { data: vehicles, isPending: isPendingVehicles } = usePersonVehicles(person.gid);
  const assets = [...(homes || []), ...(vehicles || [])];

  const isPending = isPendingPolicies || isPendingCarriers || isPendingAssignees || isPendingHomes || isPendingVehicles;

  const { mutateAsync: createPolicy } = useCreateMaticPolicy();
  const { mutateAsync: updatePolicy } = useUpdatePolicy();
  const { mutateAsync: renewPolicy } = useRenewPolicy();
  const { mutateAsync: rewritePolicy } = useRewritePolicy();

  const navigate = useNavigate();

  const closeModal = () => navigate(-1);

  const onCreatePolicy = (policyType: PolicyType, personAssets: IPersonAsset[]) => {
    setPolicyDetails({ policy_type: policyType, assets: personAssets });
    togglePolicyCreator();

    navigate(policiesPath({ person, postSalesExperience, search, subPath: '/new' }));

    return Promise.resolve();
  };

  const onPolicyCreated = async (policy: any) => {
    try {
      await createPolicy({ ...policy, person_gid: person.gid, lead_id: leadId });
    } catch (e: any) {
      alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const onPolicyEdited = async (policy: any) => {
    try {
      const { id, ...rest } = policy;
      await updatePolicy({ person_gid: person!.gid, policy_id: id, ...rest });
    } catch (e: any) {
      alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const onPolicyRenewed = async (policy: any) => {
    try {
      const { id, ...rest } = policy;
      await renewPolicy({ person_gid: person!.gid, policy_id: id, ...rest, lead_id: leadId });
    } catch (e: any) {
      alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const onPolicyRewritten = async (policy: any) => {
    try {
      const { id, ...rest } = policy;
      await rewritePolicy({ person_gid: person!.gid, policy_id: id, ...rest, lead_id: leadId });
    } catch (e: any) {
      alert({ message: e.message }).error();
      console.error(e); // eslint-disable-line no-console
    } finally {
      refetchPolicies();
      closeModal();
    }
  };

  const adminPolicyActionsPossible = authInfo.features.edit_policy_status;
  const rewritePossible = authInfo.features.rewrite_policies;
  const onDetailsRoute = useMatch('/people/:gid/policies/:policyId/details');

  if (isPending) {
    return (
      <FlexBox pt={spacings.px24}>
        <TablePlaceholder testId="customer-policies-loader" />
      </FlexBox>
    );
  }

  return (
    <FlexBox
      fitParentWidth
      columnDirection
      customCss={css`
        max-width: 100%;
      `}
    >
      <FlexBox pb={spacings.px24} justifySpaceBetween alignItemsCenter>
        <Text type="large" bold>
          Policies
        </Text>
        <span>
          {authInfo.features.create_policy_from_customer_level && (
            <Button
              id="add-policy-btn"
              size={ButtonSize.Small}
              onClick={() => togglePolicyCreator()}
              disabled={isPending}
            >
              + Add Policy
            </Button>
          )}
        </span>
      </FlexBox>
      <>
        {/**
         * PolicyList -> PolicyRow uses useNavigate which changes itself on route change
         * in case there are a lot of policies, PolicyList rerender is quite time consuming.
         * so we do not render list in case we are on Details route
         */}
        {!onDetailsRoute && <PolicyList person={person} policies={policies || []} />}
        {openPolicyCreator && (
          <PolicyAssetsPicker
            cancelHandler={togglePolicyCreator}
            confirmHandler={({ policyType, personAssets }) => onCreatePolicy(policyType, personAssets)}
            personGid={person.gid}
          />
        )}

        <Routes>
          <Route
            path="new"
            element={
              policyDetails ? (
                <PolicyCreator
                  carriers={carriers || []}
                  assignees={assignees || []}
                  cancelBtnHandler={() => navigate(policiesPath({ person, postSalesExperience, search }))}
                  confirmBtnHandler={onPolicyCreated}
                  person={person}
                  policy={policyDetails}
                  assets={assets}
                />
              ) : (
                <Navigate to={policiesPath({ person, postSalesExperience, search })} replace />
              )
            }
          />
          <Route
            path=":policyId/edit"
            element={
              <PolicyIdProvider>
                {policyId => {
                  const policy = policies?.find(policy => `${policy.id}` === policyId);

                  return policy ? (
                    <PolicyEditor
                      carriers={carriers || []}
                      assignees={assignees || []}
                      cancelBtnHandler={closeModal}
                      confirmBtnHandler={onPolicyEdited}
                      person={person}
                      policy={policy}
                      assets={assets}
                    />
                  ) : (
                    <Navigate to={policiesPath({ person, postSalesExperience, search })} replace />
                  );
                }}
              </PolicyIdProvider>
            }
          />
          <Route
            path=":policyId/rewrite"
            element={
              <PolicyIdProvider>
                {policyId => {
                  const policy = policies?.find(policy => `${policy.id}` === policyId);

                  if (!policy || !rewritePossible) {
                    return <Navigate to={policiesPath({ person, postSalesExperience, search })} replace />;
                  } else {
                    return (
                      <PolicyRewriter
                        carriers={carriers || []}
                        assignees={assignees || []}
                        cancelBtnHandler={closeModal}
                        confirmBtnHandler={onPolicyRewritten}
                        person={person}
                        policy={policy}
                        assets={assets}
                      />
                    );
                  }
                }}
              </PolicyIdProvider>
            }
          />
          <Route
            path=":policyId/renew"
            element={
              <PolicyIdProvider>
                {policyId => {
                  const policy = policies?.find(policy => `${policy.id}` === policyId);
                  const renewalPossible =
                    adminPolicyActionsPossible && policy?.status && POLICY_RENEWABLE_STATUSES.includes(policy.status);

                  if (!policy || !renewalPossible) {
                    return <Navigate to={policiesPath({ person, postSalesExperience, search })} replace />;
                  } else {
                    return (
                      <PolicyRenewer
                        carriers={carriers || []}
                        assignees={assignees || []}
                        cancelBtnHandler={closeModal}
                        confirmBtnHandler={onPolicyRenewed}
                        person={person}
                        policy={policy}
                        assets={assets}
                      />
                    );
                  }
                }}
              </PolicyIdProvider>
            }
          />
          <Route
            path=":policyId/cancel"
            element={
              <PolicyIdProvider>
                {policyId => {
                  const policy = policies?.find(policy => `${policy.id}` === policyId);

                  return policy ? (
                    <CancelModal
                      person={person}
                      policy={policy}
                      closeModal={closeModal}
                      onPolicyCancelled={() => refetchPolicies()}
                    />
                  ) : (
                    <Navigate to={policiesPath({ person, postSalesExperience, search })} replace />
                  );
                }}
              </PolicyIdProvider>
            }
          />
          <Route
            path=":policyId/non_renew"
            element={
              <PolicyIdProvider>
                {policyId => {
                  const policy = policies?.find(policy => `${policy.id}` === policyId);

                  return policy ? (
                    <NonRenewModal
                      person={person}
                      policy={policy}
                      closeModal={closeModal}
                      onPolicyNonRenewed={() => refetchPolicies()}
                    />
                  ) : (
                    <Navigate to={policiesPath({ person, postSalesExperience, search })} replace />
                  );
                }}
              </PolicyIdProvider>
            }
          />
          <Route
            path=":policyId/details"
            element={
              policies?.length ? (
                <div
                  css={css`
                    position: fixed;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    z-index: calc(var(--nav-z-index) - 1);
                  `}
                >
                  <PolicyDetails person={person} policies={policies as IMaticPolicy[]} />
                </div>
              ) : (
                <Navigate to={policiesPath({ person, postSalesExperience, search })} replace />
              )
            }
          />
        </Routes>
      </>
    </FlexBox>
  );
};

export default CustomerPolicies;
