/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ButtonWithoutBorder from '../../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { ArrowLeft2Icon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { useEventListener } from '../../../hooks';
import useNavigateBack from '../../../hooks/useNavigateBack';
import { useProductAnnouncement } from '../../../queries/product_announcements/useProductAnnouncements';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, SHORT_DATE_FORMAT } from '../../../utils/formatter';
import useProductAnnouncementsForUser from '../_hooks/useProductAnnouncementsForUser';
import AnnouncementTag from './AnnouncementTag';
import CancelAnnouncementModal from './CancelAnnouncementModal';
import { announcementContainerStyle, announcementsGridStyle } from './grid';
import ProductAnnouncement from './ProductAnnouncement';

interface ProductAnnouncementPageProps {
  adminView?: boolean;
}

const ProductAnnouncementPage = ({ adminView }: ProductAnnouncementPageProps) => {
  const { productAnnouncementId } = useParams();
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  const { data: productAnnouncement } = useProductAnnouncement(productAnnouncementId);

  const [showDeleteProductAnnouncement, setDeleteProductAnnouncement] = React.useState<boolean>(false);

  const { markProductAnnouncementsAsRead } = useProductAnnouncementsForUser();

  React.useEffect(() => {
    if (!adminView) {
      markProductAnnouncementsAsRead();
    }
  }, [adminView, markProductAnnouncementsAsRead]);

  useEventListener('keydown', event => {
    if (event.key === 'Escape') {
      navigateBack();
    }
  });

  if (!productAnnouncement) {
    return null;
  }

  return (
    <Container fitParentHeight customCss={announcementsGridStyle}>
      <Container
        ph={spacings.px24}
        pv={spacings.px16}
        customCss={css`
          grid-area: back;
        `}
      >
        <ButtonWithoutBorder pv={spacings.px12} onClick={() => navigateBack()}>
          <FlexBox alignItemsCenter gap={spacings.px8}>
            <ArrowLeft2Icon width={spacings.px20} height={spacings.px20} color={colors.black} />
            <Paragraph bold>Back</Paragraph>
          </FlexBox>
        </ButtonWithoutBorder>
      </Container>

      <Container
        pt={spacings.px16}
        pb={spacings.px24}
        ph={spacings.px8}
        customCss={css`
          grid-area: header;
        `}
      >
        <FlexBox gap={spacings.px8} justifySpaceBetween alignItemsCenter>
          <FlexBox alignItemsCenter gap={spacings.px8}>
            <Heading type="h3">{productAnnouncement.title}</Heading>
            {adminView && (
              <FlexBox gap={spacings.px4}>
                <IconButton
                  icon={ButtonIcons.Edit}
                  onClick={() => navigate(`/product_announcements_admin/${productAnnouncement.id}/edit`)}
                />
                <IconButton icon={ButtonIcons.Delete} onClick={() => setDeleteProductAnnouncement(true)} />
              </FlexBox>
            )}
          </FlexBox>

          <Text singleLine color={colors.grey80}>
            {dateFormatter(productAnnouncement.release_date, SHORT_DATE_FORMAT)}
          </Text>
        </FlexBox>
        <FlexBox
          gap={spacings.px8}
          mt={spacings.px8}
          customCss={css`
            max-width: 240px;
            flex-wrap: wrap;
          `}
        >
          {productAnnouncement.tags.map(tag => (
            <AnnouncementTag key={tag} tag={tag} />
          ))}
        </FlexBox>
      </Container>

      <Container
        pb={spacings.px48}
        ph={spacings.px8}
        customCss={css`
          grid-area: content;
          overflow-y: auto;
        `}
      >
        <Container customCss={announcementContainerStyle}>
          <ProductAnnouncement productAnnouncement={productAnnouncement} adminView={adminView} fullView />
        </Container>
      </Container>

      {productAnnouncementId && showDeleteProductAnnouncement && (
        <CancelAnnouncementModal
          productAnnouncementId={parseInt(productAnnouncementId)}
          onCancel={() => setDeleteProductAnnouncement(false)}
          onDeleted={() => {
            setDeleteProductAnnouncement(false);
            navigateBack();
          }}
        />
      )}
    </Container>
  );
};

export default ProductAnnouncementPage;
