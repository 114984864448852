import classNames from 'classnames';
import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { EmptyResults } from '../../components/common';
import NewTooltip from '../../components/common/Tooltip/NewTooltip';
import Sortable, { ORDER_TYPES } from '../../hocs/Sortable';
import { INote } from '../../interfaces/INote';
import NoteRow from './NoteRow';

const KIND = 'kind';
const DESCRIPTION = 'description';
const AGENT = 'agent.name';
const CREATED_AT = 'created_at';

const NotesList = ({ notes }: { notes: INote[] | undefined }) =>
  notes?.length ? (
    <Sortable
      sortableColumns={{
        [KIND]: ORDER_TYPES.STRING,
        [DESCRIPTION]: ORDER_TYPES.STRING,
        [AGENT]: ORDER_TYPES.STRING,
        [CREATED_AT]: ORDER_TYPES.DATE
      }}
      list={notes}
      defaultColumn={CREATED_AT}
      render={({
        list,
        sortBy,
        getSortableClasses
      }: {
        list: INote[];
        sortBy: (column: string) => (() => void) | undefined;
        getSortableClasses: (column: string) => string;
      }) => (
        <Container className="table">
          <Row className="table-headers">
            <Col sm={1} className={classNames('table-cell', getSortableClasses(KIND))} onClick={() => sortBy(KIND)?.()}>
              Type
            </Col>
            <Col
              sm={7}
              className={classNames('table-cell', getSortableClasses(DESCRIPTION))}
              onClick={() => sortBy(DESCRIPTION)?.()}
            >
              Description
            </Col>
            <Col className={classNames('table-cell', getSortableClasses(AGENT))} onClick={() => sortBy(AGENT)?.()}>
              Created by
            </Col>
            <Col
              className={classNames('table-cell', getSortableClasses(CREATED_AT))}
              onClick={() => sortBy(CREATED_AT)?.()}
            >
              Create date
            </Col>
          </Row>
          {list.map((current, index) => (
            <NoteRow current={current} key={current.id} index={index} />
          ))}
          <NewTooltip id="note-description-tooltip" />
        </Container>
      )}
    />
  ) : (
    <EmptyResults message="No notes yet" />
  );

export default NotesList;
