/** @jsxImportSource @emotion/react */
import { useQueryClient } from '@tanstack/react-query';
import { FormikComputedProps, FormikHelpers } from 'formik';
import * as React from 'react';

import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import { useToggle } from '../../../../hooks';
import { ILoan, IPersonAsset } from '../../../../interfaces';
import { isAssetLeased } from '../../../../interfaces/IPersonAsset';
import { PERSON_LOANS_QUERY_KEY } from '../../../../queries/people/usePersonLoans';
import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import LoanForm from './LoanForm';
import LoanInfo from './LoanInfo';

type FormRef = FormikComputedProps<unknown> & FormikHelpers<unknown>;

const Loan = ({
  loan,
  vehicles,
  personGid,
  disabled = false
}: {
  loan: ILoan;
  vehicles: IPersonAsset[] | undefined;
  personGid?: string;
  disabled?: boolean;
}) => {
  const queryClient = useQueryClient();
  const [editLoan, toggleEditLoan] = useToggle(false);

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const formRef = React.useRef<FormRef>();

  // Origin loan#assets response does not include person_asset information.
  const personVehicle = vehicles?.find(({ gid }) => gid === loan.vehicle?.gid);
  const vehicleOwnershipTypeIsLease = !!personVehicle && isAssetLeased(personVehicle);
  const loanAsset = loan.home || loan.vehicle;

  return (
    <Container>
      <FlexBox gap={spacings.px8} alignItemsCenter justifySpaceBetween>
        <Paragraph type="large" bold>
          {vehicleOwnershipTypeIsLease ? 'Lease info' : 'Loan info'}
        </Paragraph>
        <>
          {editLoan ? (
            <FlexBox gap={spacings.px4}>
              <Button
                variant={ButtonVariant.Secondary}
                size={ButtonSize.Small}
                onClick={toggleEditLoan}
                data-testid={`${loan.gid}-cancel-loan-info-panel-form`}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                size={ButtonSize.Small}
                onClick={async () => {
                  setIsSubmitting(true);
                  if (formRef.current?.dirty) {
                    await formRef.current
                      .submitForm()
                      .then(() => queryClient.invalidateQueries({ queryKey: [PERSON_LOANS_QUERY_KEY] }));
                  }
                  setIsSubmitting(false);
                  if (formRef.current?.isValid) {
                    toggleEditLoan();
                  }
                }}
                type="submit"
                data-testid={`${loan.gid}-submit-loan-info-form`}
                loading={isSubmitting}
              >
                Save
              </Button>
            </FlexBox>
          ) : (
            <IconButton
              disabled={disabled}
              onClick={() => {
                analytics.track('Edit loan clicked', {
                  person_gid: personGid,
                  loan_gid: loan.gid,
                  place: 'guided_selling_side_panel'
                });
                toggleEditLoan();
              }}
              data-testid={`${loan.gid}-edit-loan`}
              focus={editLoan}
              icon={ButtonIcons.Edit}
            />
          )}
        </>
      </FlexBox>

      <Paragraph className="fs-mask" type="small" color={colors.grey60} mt={spacings.px12}>
        {loanAsset?.description_without_icon}
      </Paragraph>
      {editLoan ? <LoanForm loan={loan} personGid={personGid!} formRef={formRef} /> : <LoanInfo loan={loan} />}
    </Container>
  );
};

export default Loan;
