import { useQuery } from '@tanstack/react-query';

import { ILead } from '../../interfaces/ILead';
import api from '../../services/api';

export const getPersonLeads = (personGid: string | undefined): Promise<{ leads: ILead[] }> =>
  api.get(`/api/frontend/people/${personGid}/leads`);

const usePersonLeads = (personGid: string | undefined) =>
  useQuery({
    queryKey: ['person_leads', personGid],
    queryFn: () => getPersonLeads(personGid),
    enabled: !!personGid,
    select: data => data.leads
  });

export default usePersonLeads;
