import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { PolicyType } from '../../interfaces/IPolicyType';
import { QuoteKind } from '../../interfaces/IQuote';
import { StartQuotingResponse } from '../../interfaces/IQuotesRequests';
import api from '../../services/api';

interface QuotesRequestParams {
  customer_gid: string;
  engagement_gid: string | undefined;
  emergency_timeout?: number;
  opportunities: QuotesRequestOpportunity[];
}

interface QuotesRequestOpportunity {
  policy_type: PolicyType;
  assets: {
    gid: string;
    coverages?: QuotesRequestCoverage[];
  }[];
  params: {
    term?: number;
    co_insureds?: { gid: string }[];
    quotes_kinds?: QuoteKind[];
    primary_insured_gid?: string;
    carriers?: string[];
    stub_mode?: boolean;
    multi_policy_discount?: boolean;
    coverages_tiers?: string[];
    carrier_options?: any;
  };
}

interface QuotesRequestCoverage {
  key: string;
  gid?: string;
  limit_value?: number;
  deductible_value?: number;
}

const createQuotesRequest = (params: QuotesRequestParams): Promise<StartQuotingResponse> =>
  api.post('/api/frontend/quotes_requests/v2', {
    body: { quotes_request: params }
  });

const useCreateQuotesRequest = (
  onSuccess?: UseMutationOptions<
    StartQuotingResponse,
    unknown,
    Parameters<typeof createQuotesRequest>[0],
    unknown
  >['onSuccess']
) => useMutation({ mutationFn: createQuotesRequest, onSuccess, throwOnError: false });

export default useCreateQuotesRequest;
