import { GuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import GuidedQuoting from '../../GuidedQuoting';
// eslint-disable-next-line max-len
import { CurrentDispositionSubscriptionContext } from '../../GuidedSellingExperience/_hooks/useCurrentDispositionSubscription';

const Quotes = ({
  leadId,
  leadGid,
  personGid,
  onGetQuotes
}: {
  leadId: number;
  leadGid: string;
  personGid: string;
  onGetQuotes: () => void;
}) => {
  const { data: leadDispositions } = useLeadDispositions(leadId);

  return (
    <GuidedSellingExperienceContext.Provider value={{ isPersonLinkedToActiveCall: true } as any}>
      <CurrentDispositionSubscriptionContext.Provider
        value={
          {
            currentDispositions: leadDispositions
          } as any
        }
      >
        <GuidedQuoting
          leadGid={leadGid}
          leadId={leadId}
          personGid={personGid}
          onConfigureQuoting={() => onGetQuotes()}
        />
      </CurrentDispositionSubscriptionContext.Provider>
    </GuidedSellingExperienceContext.Provider>
  );
};

export default Quotes;
