/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import DescriptionList from '../../../components/core/DescriptionList';
import FlexBox from '../../../components/core/FlexBox';
import { OpenInNewIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { Translations } from '../../../constants';
import { IMaticPolicy, IPerson } from '../../../interfaces';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import colors from '../../../theme/colors';
import { dateFormatter, DISPLAY_DATE_FORMAT } from '../../../utils/formatter';
import ContentSection from '../ContentSection';

const BasicInfoSection = ({
  policy,
  lineOfBusiness,
  insureds = []
}: {
  policy: IMaticPolicy;
  lineOfBusiness?: LineOfBusiness;
  insureds?: IPerson[];
}): JSX.Element => {
  const heading =
    findPolicyTypeLabel(lineOfBusiness?.policy_type) ||
    lineOfBusiness?.acord_label ||
    findPolicyTypeLabel(policy.policy_type);

  return (
    <ContentSection heading={`${heading} policy details`} testId={`${heading}-basic-info-section`}>
      <DescriptionList
        term={<Text type="large">Policy #</Text>}
        details={
          <Text type="large" className="fs-mask">
            {policy.policy_number}
          </Text>
        }
      />
      <DescriptionList
        term={<Text type="large">Carrier </Text>}
        details={
          policy.carrier.agency_portal_url ? (
            <a href={policy.carrier.agency_portal_url} target="_blank" rel="noopener noreferrer">
              <FlexBox alignItemsCenter>
                <Text type="large" bold color={colors.azure50}>
                  {policy.carrier.name}
                </Text>
                <OpenInNewIcon
                  css={css`
                    margin-left: 12px;
                  `}
                />
              </FlexBox>
            </a>
          ) : (
            <Text type="large" bold>
              {policy.carrier.name}
            </Text>
          )
        }
      />
      <DescriptionList
        term={<Text type="large">Term</Text>}
        details={
          <Text type="large">{`${dateFormatter(policy.effective_date, DISPLAY_DATE_FORMAT)} - ${dateFormatter(
            policy.expiration_date,
            DISPLAY_DATE_FORMAT
          )}`}</Text>
        }
      />
      <DescriptionList
        term={<Text type="large">Billing type</Text>}
        details={<Text type="large">{Translations.paymentMethod(policy.payment_method)}</Text>}
      />
      {Boolean(insureds?.length) && (
        <DescriptionList
          term={<Text type="large">Insureds</Text>}
          details={
            <Text type="large" className="fs-mask">
              {insureds.map(person => person.name).join(', ')}
            </Text>
          }
        />
      )}
    </ContentSection>
  );
};

export default BasicInfoSection;
