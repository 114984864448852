import { useInfiniteQuery } from '@tanstack/react-query';

import { ICandidate, ILead, IPerson } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export enum MatchedMainField {
  Name = 'name',
  Email = 'email',
  Phone = 'phone',
  FormattedPhone = 'formatted_phone',
  LivingAddress = 'living_address',
  LoanNumbers = 'loans_numbers'
}

export enum MatchedSecondaryField {
  BusinessPhone = 'business_phone',
  FormattedBusinessPhone = 'formatted_business_phone',
  SecondaryPhone = 'secondary_phone',
  FormattedSecondaryPhone = 'formatted_secondary_phone',
  LivingAddress = 'living_address',
  MailingAddress = 'mailing_address',
  LicenseNumber = 'license_number',
  HomesAddresses = 'homes_addresses',
  VehiclesAddresses = 'vehicles_addresses',
  VehiclesVinNumbers = 'vehicles_vin_numbers',
  VehiclesCarModels = 'vehicles_car_models',
  PoliciesNumbers = 'policies_numbers',
  RelatedPeopleNames = 'related_people_names',
  RelatedPeopleEmails = 'related_people_emails',
  RelatedPeoplePhones = 'related_people_phones',
  RelatedPeopleBusinessPhones = 'related_people_business_phones',
  RelatedPeopleSecondaryPhones = 'related_people_secondary_phones',
  RelatedPeopleFormattedPhones = 'related_people_formatted_phones',
  RelatedPeopleFormattedBusinessPhones = 'related_people_formatted_business_phones',
  RelatedPeopleFormattedSecondaryPhones = 'related_people_formatted_secondary_phones',
  LeadGid = 'lead_gid',
  CandidateGid = 'candidate_gid',
  PersonGid = 'person_gid',
  LeadId = 'lead_id'
}

export type Highlight = Record<MatchedMainField | MatchedSecondaryField, string[]>;

interface Attributes {
  id: string;
  person_gid: string | null;
  name: string | null;
  email: string | null;
  phone: string | null;
  business_phone: string | null;
  secondary_phone: string | null;
  formatted_phone: string | null;
  formatted_business_phone: string | null;
  formatted_secondary_phone: string | null;
  living_address: string | null;
  mailing_address: string | null;
  license_number: string | null;
  homes_addresses: string[] | null;
  vehicles_addresses: string[] | null;
  vehicles_vin_numbers: string[] | null;
  vehicles_car_models: string[] | null;
  loans_numbers: string[] | null;
  policies_numbers: string[] | null;
  related_people_names: string[] | null;
  related_people_emails: string[] | null;
  related_people_phones: string[] | null;
  related_people_formatted_phones: string[] | null;
  related_people_business_phones: string[] | null;
  related_people_formatted_business_phones: string[] | null;
  related_people_secondary_phones: string[] | null;
  related_people_formatted_secondary_phones: string[] | null;
  _score: number | null;
  _explanation: number | string | null;
}
export interface PersonMatch {
  person: IPerson;
  highlight: Highlight;
  attributes: Attributes | null;
  last_lead?: ILead;
  last_candidate?: ICandidate;
  leads_count?: number;
}

interface Result {
  people_matches: PersonMatch[];
}

interface SearchInput {
  term?: string;
  limit?: number;
  offset?: number;
  search_by?: string;
}

export const useInfinitePeopleByTerm = ({ term, search_by }: SearchInput) =>
  useInfiniteQuery({
    queryKey: ['infinite-people-by-term', { term, search_by }],
    queryFn: ({ pageParam }): Promise<Result> =>
      api.get(`/api/frontend/people/search?${toQueryParams({ term, offset: pageParam.offset, search_by })}`),
    initialPageParam: { offset: 0 },
    enabled: !!term,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.people_matches.length === 10
        ? {
            offset: allPages.length * 10
          }
        : undefined
  });

const usePeopleByTerm = ({ term, search_by }: SearchInput): { data: PersonMatch[]; isFetching: boolean } => {
  const { data, isFetching } = useInfinitePeopleByTerm({ term, search_by });
  return { data: data?.pages[0]?.people_matches || [], isFetching };
};

export default usePeopleByTerm;
