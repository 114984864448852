import { skipToken, useQuery } from '@tanstack/react-query';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import api from '../../../services/api';

const getPersonDeletedPolicies = ({ personGid }: { personGid: string }): Promise<{ policies: IMaticPolicy[] }> =>
  api.get(`/api/frontend/people/${personGid}/policies/deleted`);

const usePersonDeletedPolicies = ({ personGid }: { personGid: string | undefined }) =>
  useQuery({
    queryKey: [personGid, 'person_deleted_policies'],
    queryFn: personGid ? () => getPersonDeletedPolicies({ personGid }) : skipToken,
    select: data => data.policies
  });

export default usePersonDeletedPolicies;
