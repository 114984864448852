import * as React from 'react';

import Container from '../../../../components/core/Container';
import DescriptionList from '../../../../components/core/DescriptionList';
import FlexBox from '../../../../components/core/FlexBox';
import { OpenInNewIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { IOpportunity, IVehicle } from '../../../../interfaces';
import useBrand from '../../../../queries/partners/useBrand';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const AutoFirstLeadBrandInfo = ({
  brandKey,
  primaryOpportunity: { assets }
}: {
  brandKey: string | undefined;
  primaryOpportunity: IOpportunity;
}) => {
  const { brand } = useBrand(brandKey);
  const vehicles = assets as IVehicle[] | undefined;

  const { salesPerson, servicerName } = React.useMemo(() => {
    return {
      salesPerson: vehicles?.find(vehicle => vehicle.sale_person_name)?.sale_person_name,
      servicerName: vehicles?.find(vehicle => vehicle.last_service_writer_name)?.last_service_writer_name
    };
  }, [vehicles]);

  if ((!brand || brandKey === 'unknown') && !salesPerson && !servicerName) {
    return null;
  }

  return (
    <Container data-testid="auto">
      <Text type="large" bold>
        Partner
      </Text>
      <FlexBox columnDirection>
        {brand && (
          <>
            <FlexBox mt={spacings.px12}>
              <FlexBox mr={spacings.px6}>
                <Text>{brand.name}</Text>
              </FlexBox>
              {brand.website && (
                <a href={brand.website} rel="noreferrer" target="_blank">
                  <OpenInNewIcon color={colors.azure50} />
                </a>
              )}
              {brand.definition && (
                <FlexBox ml={spacings.px16}>
                  <Text>{brand.definition}</Text>
                </FlexBox>
              )}
            </FlexBox>
            {brand.description && (
              <FlexBox mt={spacings.px12}>
                <Text color={colors.grey60}>{brand.description}</Text>
              </FlexBox>
            )}
          </>
        )}
        {salesPerson && <DescriptionList term={<Text>Sales Person</Text>} details={<Text>{salesPerson}</Text>} />}
        {servicerName && <DescriptionList term={<Text>Servicer</Text>} details={<Text>{servicerName}</Text>} />}
      </FlexBox>
    </Container>
  );
};

export default AutoFirstLeadBrandInfo;
