/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import colors from '../../../../theme/colors';
import { defaultFontValues, smallFontValues } from '../../../../theme/typography';
import { InputSize } from '../../../../theme/variables';
import alert from '../../Alert';
import FlexBox from '../../FlexBox';
import { ClearIcon, CopyIcon } from '../../icons';
import { copyBlockStyle, copyIconStyle, focusHelper, input } from './Input.style';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  prefixIcon?: JSX.Element;
  clearable?: boolean;
  copyable?: boolean;
  onClear?: () => void;
  customCss?: SerializedStyles;
  inputRef?: any;
  inline?: boolean;
  testId?: string;
  fsMask?: boolean;
  inputSize?: InputSize;
}

export const fontCss = (inputSize: InputSize) => {
  switch (inputSize) {
    case InputSize.Small:
      return css`
        font-family: ${defaultFontValues.fontFamily};
        font-size: ${smallFontValues.fontSize};
      `;
    case InputSize.Medium:
    case InputSize.Large:
    default:
      return css`
        font-family: ${defaultFontValues.fontFamily};
        font-size: ${defaultFontValues.fontSize};
      `;
  }
};

const BaseInput = ({
  hasError = false,
  prefixIcon,
  clearable = false,
  copyable = false,
  inline = false,
  onClear,
  customCss,
  inputRef,
  placeholder,
  fsMask,
  inputSize = InputSize.Medium,
  ...props
}: InputProps): JSX.Element => (
  <FlexBox
    alignItemsCenter
    css={css`
      position: relative;
    `}
  >
    {prefixIcon && (
      <div
        css={css`
          position: absolute;
          left: 8px;
          z-index: 2;
          cursor: pointer;
        `}
      >
        {prefixIcon}
      </div>
    )}
    <input
      type="text"
      {...props}
      css={[input(hasError, props.disabled, !!prefixIcon, clearable, inline), fontCss(inputSize), customCss]}
      ref={inputRef}
      className={fsMask ? 'fs-mask' : ''}
      placeholder={placeholder || (inline ? '—' : '')}
      autoComplete="off"
    />
    {!inline && <div css={focusHelper} className="input-focus-helper" />}

    {clearable && props.value && !copyable && (
      <ClearIcon
        onClick={onClear}
        color={colors.black}
        css={css`
          position: absolute;
          right: 8px;
          top: 8px;
          z-index: 2;
          cursor: pointer;
        `}
      />
    )}
    {copyable && !clearable && (
      <CopyToClipboard
        text={props.value as string}
        onCopy={
          alert({ message: 'Copied to clipboard', options: { position: 'bottom-center', autoClose: 1000 } }).success
        }
      >
        <span css={copyBlockStyle}>
          <CopyIcon color={colors.white} css={copyIconStyle} />
        </span>
      </CopyToClipboard>
    )}
  </FlexBox>
);

export default BaseInput;
