import * as React from 'react';

import Tooltip from '../components/common/Tooltip/NewTooltip';
import usePersonDNC from '../queries/people/dncStatus';

const TOOLTIP_ID = 'dnc-tip';
const TOOLTIP_TEXT = 'Customer is on the do not contact list';

const useDNCTip = ({ personGid }: { personGid: string | undefined }) => {
  const { data: doNotContact } = usePersonDNC(personGid);
  const DNCTip = React.useCallback(() => <Tooltip id={TOOLTIP_ID} />, []);

  const labelProps = { 'data-for': TOOLTIP_ID, 'data-tip': doNotContact ? TOOLTIP_TEXT : '' };

  return { DNCTip, labelProps, doNotContact };
};

export default useDNCTip;
