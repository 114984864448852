import { skipToken, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IDocument } from '../../../interfaces';
import { DocumentOwnerType } from '../../../interfaces/IDocument';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

export const DOCS_QUERY_KEY = 'person_documents';

const getPersonDocuments = ({
  personGid,
  leadId,
  policyId
}: {
  personGid: string;
  leadId?: number;
  policyId?: number;
}): Promise<{ documents: IDocument[] }> =>
  api.get(`/api/frontend/people/${personGid}/documents?${toQueryParams({ lead_id: leadId, policy_id: policyId })}`);

const usePersonDocuments = ({
  personGid,
  leadId,
  policyId
}: {
  personGid: string | undefined;
  leadId?: number;
  policyId?: number;
}) =>
  useQuery({
    queryKey: [DOCS_QUERY_KEY, personGid, leadId, policyId],
    queryFn: personGid ? () => getPersonDocuments({ personGid, leadId, policyId }) : skipToken,
    select: data => data.documents
  });

export interface CreateDocumentRequest {
  uid: string;
  owner_id?: number;
  owner_type?: DocumentOwnerType;
  file: File;
  document_type_id: number;
}

interface CreateDocumentResponse extends IDocument {
  uid: string;
}

export const createDocument = ({
  personGid,
  request
}: {
  personGid: string;
  request: CreateDocumentRequest;
}): Promise<{ document: CreateDocumentResponse }> =>
  api.upload(`/api/frontend/people/${personGid}/documents`, { body: request });

export const useCreateDocument = ({ attachDocs = false }: { attachDocs?: boolean }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createDocument,
    onMutate: ({ personGid, request }) => {
      if (attachDocs) {
        queryClient.setQueryData(
          [DOCS_QUERY_KEY, personGid],
          (data: { documents: (IDocument | CreateDocumentRequest)[] } | undefined) => {
            return { documents: [...(data?.documents || []), request] };
          }
        );
      }
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, variables.personGid] });
    }
  });
};

export interface UpdateDocumentRequest {
  owner_id: number | undefined;
  owner_type: DocumentOwnerType | undefined;
  document_type_id: number | undefined;
}

interface UpdateDocumentResponse extends IDocument {
  uid: string;
}

export const updateDocument = ({
  personGid,
  documentId,
  request
}: {
  personGid: string;
  documentId: number;
  request: UpdateDocumentRequest;
}): Promise<{ document: UpdateDocumentResponse }> =>
  api.patch(`/api/frontend/people/${personGid}/documents/${documentId}`, { body: request });

export const useUpdateDocument = ({ invalidate = false }: { invalidate?: boolean }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateDocument,
    onSuccess: (data, variables) => {
      invalidate && queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, variables.personGid] });
    }
  });
};

export const deleteDocument = ({
  personGid,
  documentId
}: {
  personGid: string;
  documentId: number;
}): Promise<{ document: { id: number } }> => api.delete(`/api/frontend/people/${personGid}/documents/${documentId}`);

export const useDeleteDocument = ({ invalidate = false }: { invalidate?: boolean }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDocument,
    onSuccess: (data, variables) => {
      invalidate && queryClient.invalidateQueries({ queryKey: [DOCS_QUERY_KEY, variables.personGid] });
    }
  });
};

export default usePersonDocuments;
