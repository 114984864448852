/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import * as yup from 'yup';

import SystemMessage from '../../../components/core/Alert/SystemMessage';
import Blockquote from '../../../components/core/Blockquote';
import { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import { RadioGroupField } from '../../../components/core/forms/fields';
import Heading from '../../../components/core/Heading';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { NO_YES_OPTIONS, YES_VALUE } from '../../../interfaces/IDatapoint';
import { IOpportunity, OpportunityStatus } from '../../../interfaces/IOpportunity';
import { PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import {
  useAcceptDisclosures,
  useActiveFCRAConsent,
  useFCRADisclosure
} from '../../../queries/disclosures/useDisclosure';
import { useUpdateLeadOpportunity } from '../../../queries/leads/opportunities/useLeadOpportunities';
import useCreateQuotesRequest from '../../../queries/quotes_requests/useCreateQuotesRequest';
import analytics from '../../../services/analytics';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';
import { requiredField } from '../../../utils/yupRules';
import useQuotingAvailability from '../../GuidedQuoting/Quotes/_hooks/useQuotingAvailability';
import { StepContentProps } from '..';
import LeadWarnings from '../_components/LeadWarnings';
import LifeOpportunity from './LifeOpportunity';

const FCRA_ALERT_DESC =
  // eslint-disable-next-line
  "We cannot provide quotes without consent. This allows us to look up insurance score and claims history. Please note this does NOT impact credit score.";

type LifeQuotingConfigurationPageValues = Record<
  string,
  IOpportunity & { enabled: boolean; term: string; face_amount: string }
>;

const LifeQuotingConfigurationPage = ({ onSubmit, dataCollection, page, isDataEditingForbidden }: StepContentProps) => {
  const scrollToTopOnMount = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollIntoView(true);
    }
  }, []);
  const { personGid, person, lead } = useGuidedSellingExperienceContext();

  const { data: fcraDisclosure } = useFCRADisclosure();
  const { data: isFcraDisclosureAccepted, isSuccess: isActiveConsentFinishedLoading } = useActiveFCRAConsent(personGid);

  const { mutateAsync: acceptDisclosures } = useAcceptDisclosures();

  const { mutateAsync: createQuotesRequest } = useCreateQuotesRequest();
  const { mutateAsync: updateLeadOpportunity } = useUpdateLeadOpportunity();

  const { forbidQuoting } = useQuotingAvailability();
  if (!person || !lead || !fcraDisclosure || !isActiveConsentFinishedLoading) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  const initialValues = {
    fcra: isFcraDisclosureAccepted ? YES_VALUE : '',
    opportunities: {
      ...(page.opportunities || []).reduce(
        (acc, opportunity) => ({
          ...acc,
          [opportunity.id.toString()]: {
            term:
              opportunity.agent_selected_coverages
                ?.find(({ coverages }) => coverages?.find(({ key }) => key === 'term'))
                ?.coverages?.find(({ key }) => key === 'term')?.limit_value || '',
            face_amount:
              opportunity.agent_selected_coverages
                ?.find(({ coverages }) => coverages?.find(({ key }) => key === PolicyCoverageKey.FaceAmount))
                ?.coverages?.find(({ key }) => key === PolicyCoverageKey.FaceAmount)?.limit_value || '',
            enabled: opportunity.is_data_collection_completed && opportunity.status !== OpportunityStatus.NotQualified,
            ...opportunity
          }
        }),
        {} as LifeQuotingConfigurationPageValues
      )
    }
  };

  const validationSchema = yup.object({
    fcra: requiredField.test('given', 'Consent must be given in order to run quotes', value => value === YES_VALUE),
    opportunities: yup.object().shape(
      Object.fromEntries(
        (page.opportunities || []).map(opportunity => [
          opportunity.id.toString(),
          yup.object({
            term: requiredField,
            face_amount: requiredField
          })
        ])
      )
    )
  });

  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      controlsWidth={sizes.mediumFormInputWidth}
      submitText="Request quotes"
      submitVariant={ButtonVariant.Default}
      submitTestId="request-quotes-button"
      initialValues={initialValues}
      validationSchema={validationSchema}
      disabled={({ values }) => {
        if (!page.is_data_sufficient || isDataEditingForbidden) {
          return true;
        }

        const isAnyOpportunityEnabled = Object.values(values.opportunities).some(opportunity => opportunity.enabled);

        return !isAnyOpportunityEnabled || forbidQuoting;
      }}
      onSubmit={async values => {
        if (!isFcraDisclosureAccepted) {
          await acceptDisclosures({ personGid: person.gid, disclosures: [fcraDisclosure] });
        }

        const buildOpportunitiesObject = () => {
          return Object.values(values.opportunities)
            .filter(opportunity => opportunity.enabled)
            .map(opportunity => ({
              policy_type: opportunity.policy_type,
              assets: (opportunity.assets || []).map(({ gid }) => ({
                gid,
                coverages: [{ key: PolicyCoverageKey.FaceAmount, limit_value: Number(opportunity.face_amount) }]
              })),
              params: {
                term: Number(opportunity.term)
              }
            }));
        };

        Promise.all(
          Object.values(values.opportunities)
            .filter(opportunity => opportunity.enabled)
            .map(opportunity => {
              return updateLeadOpportunity({
                leadId: lead.id,
                opportunityId: opportunity.id,
                params: {
                  agent_selected_coverages: [
                    {
                      asset_gid: null,
                      coverages_tier: null,
                      coverages: [
                        { key: 'term', limit_value: opportunity.term, limit_option_gid: null, deductible_value: null },
                        {
                          key: PolicyCoverageKey.FaceAmount,
                          limit_value: opportunity.face_amount,
                          limit_option_gid: null,
                          deductible_value: null
                        }
                      ]
                    }
                  ]
                }
              });
            })
        );

        const isQuotingStarted = await createQuotesRequest({
          customer_gid: person.gid,
          engagement_gid: lead.gid,
          opportunities: buildOpportunitiesObject()
        });

        analytics.track('Quotes request started', {
          lead_gid: lead.gid,
          person_gid: person.gid,
          place: 'guided_selling_experience',
          page: 'life_quoting_configuration'
        });

        if (isQuotingStarted) {
          await onSubmit();
        }
      }}
      renderForm={({ values }) => (
        <FlexBox
          columnDirection
          ref={scrollToTopOnMount}
          customCss={css`
            scroll-margin-top: 24px;
          `}
        >
          <LeadWarnings dataCollection={dataCollection} />

          {fcraDisclosure && !isFcraDisclosureAccepted && (
            <>
              <SystemMessage type="error" heading="Consent was not granted" description={FCRA_ALERT_DESC} />
              <Container mt={spacings.px24} ml={spacings.px32} mb={spacings.px24}>
                <RadioGroupField
                  label={() => (
                    <Blockquote
                      text={
                        <Heading
                          customCss={css`
                            &::after {
                              color: ${colors.statusRed};
                              content: '*';
                            }
                          `}
                        >
                          {fcraDisclosure.prompt}
                        </Heading>
                      }
                    />
                  )}
                  inline
                  required
                  id="fcra"
                  name="fcra"
                  options={NO_YES_OPTIONS}
                />
              </Container>
            </>
          )}
          <Heading type="h4" mb={spacings.px24}>
            {page.label}
          </Heading>
          <FlexBox columnDirection gap={spacings.px24}>
            {Object.values(values.opportunities).map(opportunity => (
              <LifeOpportunity key={opportunity.id} opportunity={opportunity} />
            ))}
          </FlexBox>
        </FlexBox>
      )}
    />
  );
};

export default LifeQuotingConfigurationPage;
