import * as React from 'react';

import { useDebouncedValue } from '../../../hooks';
import { IOpportunity } from '../../../interfaces';
import { buildLocalStorageKey } from '../_helpers';

interface IPostSalesSyncHelp {
  opportunityId: IOpportunity['id'];
  values: any;
}

const useSaveDraftPolicy = ({ opportunityId, values }: IPostSalesSyncHelp) => {
  const debouncedValues = useDebouncedValue(values, 1000);
  React.useEffect(() => {
    const policy = {
      ...debouncedValues,
      timestamp: Date.now()
    };
    localStorage.setItem(buildLocalStorageKey(opportunityId), JSON.stringify(policy));
  }, [opportunityId, debouncedValues]);

  // Cleanup policies from 2 days ago
  React.useEffect(() => {
    const keys = Object.keys(localStorage).filter(key => key.includes(buildLocalStorageKey('')));
    const twoDaysAgo = Date.now() - 2 * 24 * 60 * 60 * 1000;
    keys.forEach(key => {
      const policy = JSON.parse(localStorage.getItem(key) || '{}') as Record<string, any>;
      if ('timestamp' in policy && policy.timestamp < twoDaysAgo) {
        localStorage.removeItem(key);
      }
    });
  }, []);
};

export default useSaveDraftPolicy;
