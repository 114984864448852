/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import FlexBox from '../../../components/core/FlexBox';
import { DataCollection } from '../../../queries/leads/data_collection/useDataCollection';
import { isISR, isSuperISR } from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import CrossSellWarning from '../../GuidedDataCollection/_components/CrossSellWarning';
import { DataCollectionStepper } from '../../GuidedDataCollection/_hooks/useDataCollectionStepper';
import { useDisplayCrossSellWarning } from '../_hooks';
import ScoutingReport from '../ScoutingReport';
import Comparison from './Comparison';
import Context from './Context';
import Footer from './Footer';

// TODO: move this component up directories hierarchy to be at the same level
// as GuidedQuoting, GuidedDataCollection, etc.

interface ISummary {
  dataCollection: DataCollection | undefined;
  dataCollectionStepper: DataCollectionStepper;
}

const ISRSummary = () => {
  const superISRFlow = isSuperISR();

  if (superISRFlow) {
    return (
      <>
        <FlexBox
          pv={spacings.px12}
          ph={spacings.px24}
          gap={spacings.px16}
          columnDirection
          customCss={css`
            overflow: auto;
          `}
        >
          <Context />
          <ScoutingReport />
        </FlexBox>
      </>
    );
  }

  return (
    <FlexBox
      pt={spacings.px12}
      customCss={css`
        overflow: auto;
      `}
    >
      <ScoutingReport />
    </FlexBox>
  );
};

const Summary = ({ dataCollection, dataCollectionStepper }: ISummary) => {
  const { displayWarning } = useDisplayCrossSellWarning();

  return (
    <div
      css={css`
        height: 100%;
        width: 100%;

        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr auto;
      `}
    >
      {isISR() ? (
        <ISRSummary />
      ) : (
        <FlexBox
          pv={spacings.px12}
          ph={spacings.px24}
          gap={spacings.px16}
          columnDirection
          customCss={css`
            overflow: auto;
          `}
        >
          {displayWarning && <CrossSellWarning />}
          <Context />
          <div
            css={css`
              min-height: 1px;
              background-color: ${colors.grey60};
            `}
          />
          <Comparison />
        </FlexBox>
      )}
      <Footer dataCollection={dataCollection} dataCollectionStepper={dataCollectionStepper} />
    </div>
  );
};

export default Summary;
