import * as React from 'react';

type Action = { type: 'setCurrentCallId'; payload: number | null } | { type: 'callEnded' };

interface State {
  currentCallId: number | null;
}

const initialState: State = {
  currentCallId: null
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'setCurrentCallId':
      return { ...state, currentCallId: action.payload };
    case 'callEnded':
      return { ...state, currentCallId: null };
    default:
      return state;
  }
};

const retrieveState = () => {
  const storedState = localStorage.getItem('callContextState') || '';

  return storedState ? (JSON.parse(storedState) as State) : initialState;
};

const CallStateContext = React.createContext(initialState);
const CallDispatchContext = React.createContext<React.Dispatch<Action>>(() => null);

const CallContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = React.useReducer(reducer, {}, retrieveState);

  React.useEffect(() => {
    localStorage.setItem('callContextState', JSON.stringify(state));
  }, [state]);

  return (
    <CallStateContext.Provider value={state}>
      <CallDispatchContext.Provider value={dispatch}>{children}</CallDispatchContext.Provider>
    </CallStateContext.Provider>
  );
};

const useCallState = () => React.useContext(CallStateContext);
const useCallDispatch = () => React.useContext(CallDispatchContext);

export { CallContextProvider, useCallDispatch, useCallState };
