import { useNavigate } from 'react-router-dom';

import { Configuration } from '../../constants';
import { useEventListener } from '../../hooks';

function isAbsoluteUrl(url: string) {
  return /^[a-z][a-z0-9+.-]*:/i.test(url);
}

function toRelativeUrl(url = '', baseUrl: string) {
  if (isAbsoluteUrl(url)) {
    url = url.substring(baseUrl.length);
  }

  return url[0] === '/' ? url : `/${url}`;
}

const useTwilioRedirectEvent = () => {
  const navigate = useNavigate();

  useEventListener('message', event => {
    if (event.origin !== Configuration.twilioFlexUrl) {
      return;
    }

    if (event.data.type === 'NAVIGATE_TO_URL') {
      const url = event.data.url;
      const path = toRelativeUrl(url, window.location.origin);
      const normalizedPath = path.replace(/^\/crm/, '');

      // eslint-disable-next-line no-console
      console.log('Navigating from Twilio Flex window event', url, normalizedPath);

      navigate(normalizedPath, { replace: true });
    }
  });
};

export default useTwilioRedirectEvent;
