import { isRequirementNonEssential, PolicyType } from '../../../../interfaces/IPolicyType';
import usePersonAllGroupedPolicies from '../../../../queries/people/person_policies/usePersonAllGroupedPolicies';
import PoliciesTableSkeleton from '../../_components/PoliciesTableSkeleton';
import PoliciesGroups from './_components/PoliciesGroups';

const AncillaryPolicies = ({ personGid }: { personGid: string }) => {
  const { data: personPoliciesGroups, isSuccess } = usePersonAllGroupedPolicies({
    personGid,
    filters: {
      policy_types: Object.values(PolicyType).filter(policyType => isRequirementNonEssential(policyType)),
      group_by_policy_type: true,
      show_predicted_policies: true,
      deduplicate_by_expiration_date: true
    }
  });

  if (!isSuccess) {
    return <PoliciesTableSkeleton />;
  }

  return <PoliciesGroups personPoliciesGroups={personPoliciesGroups} />;
};

export default AncillaryPolicies;
