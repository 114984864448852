/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import * as yup from 'yup';

import { ButtonSize } from '../../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import BaseForm from '../../../../../components/core/forms/BaseForm';
import { NumericField, SelectField } from '../../../../../components/core/forms/fields';
import { DollarIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import usePopper from '../../../../../hooks/usePopper';
import {
  ISelectedAssetCoverage,
  opportunityCoveragesForAsset,
  OpportunityCoveragesTier,
  realPropertyCoverageTierOptions,
  RealPropertyOpportunity
} from '../../../../../interfaces/IOpportunity';
import { coverageByKey, PolicyCoverageKey } from '../../../../../interfaces/IPolicyCoverage';
// eslint-disable-next-line max-len
import { useUpdateOpportunityCoverages } from '../../../../../queries/leads/data_collection/useDataCollectionOpportunities';
import useStartQuoting from '../../../../../queries/quotes_requests/useStartQuoting';
import analytics from '../../../../../services/analytics';
import colors from '../../../../../theme/colors';
import { InputSize, LabelSize, spacings } from '../../../../../theme/variables';
import { moneyFormatter } from '../../../../../utils/formatter';
import { REQUIRED_MESSAGE } from '../../../../../utils/yupRules';
import useQuotingAvailability from '../../_hooks/useQuotingAvailability';

interface IHomeCoverageLevel {
  opportunity: RealPropertyOpportunity;
  assetGid: string;
  leadId: number;
  personGid: string;
  refetchQuotesRequests: () => void;
}
const HomeCoverageLevel = ({ personGid, opportunity, assetGid, leadId, refetchQuotesRequests }: IHomeCoverageLevel) => {
  const { mutateAsync: updateCoverages } = useUpdateOpportunityCoverages();

  const { anchorEl, setAnchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    style: { padding: '12px', zIndex: 999 }
  });

  const { mutateAsync: startQuoting } = useStartQuoting();
  const updateHomeOpportunityCoverages = (
    coverages_tier: OpportunityCoveragesTier,
    coverages: ISelectedAssetCoverage[] | null
  ) =>
    updateCoverages({
      opportunityId: opportunity.id,
      leadId,
      coverages: [{ asset_gid: assetGid, coverages_tier, coverages }]
    }).then(() =>
      startQuoting({
        leadId,
        personGid,
        startQuotingRequest: {
          homes: [
            {
              asset_gid: assetGid,
              opportunity_id: opportunity.id,
              quote_kind: 'rc2'
            }
          ]
        }
      }).then(() => {
        analytics.track('Home re-quoted', {
          lead_id: leadId,
          person_gid: personGid,
          place: 'guided_selling_experience'
        });
        refetchQuotesRequests();
      })
    );

  const selectedHomeCoverages = opportunityCoveragesForAsset(opportunity, assetGid);

  const selectedDwellingCoverage = coverageByKey(
    selectedHomeCoverages?.coverages,
    PolicyCoverageKey.Dwelling
  )?.limit_value;
  const selectedDeductible = coverageByKey(
    selectedHomeCoverages?.coverages,
    PolicyCoverageKey.Dwelling
  )?.deductible_value;

  const { forbidQuoting } = useQuotingAvailability();

  return (
    <FlexBox>
      <FlexBox
        gap={spacings.px6}
        alignItemsCenter
        css={css`
          position: relative;
        `}
      >
        <Container
          border
          css={css`
            border-radius: 8px;
            white-space: nowrap;
            &:hover {
              cursor: pointer;
            }
          `}
          pl={spacings.px12}
          onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            triggerPopper(e);
            e.stopPropagation();
          }}
        >
          <Text bold type="small">
            Dwelling {moneyFormatter(selectedDwellingCoverage, true) || '-'}
          </Text>
          <Text mh={spacings.px4} bold type="small">
            |
          </Text>
          <Text bold type="small">
            Deductible {moneyFormatter(selectedDeductible, true) || '-'}
          </Text>
          <IconButton focus={!!anchorEl} icon={ButtonIcons.Edit} color={colors.black} m={spacings.px4} />
        </Container>
        <div ref={elementRef}>
          <PopperComponent {...popperProps}>
            <FlexBox
              ph={spacings.px24}
              pv={spacings.px12}
              gap={spacings.px8}
              roundBorder
              border
              boxShadow
              backgroundColor={colors.white}
              columnDirection
              customCss={css`
                min-width: 400px;
              `}
            >
              <Paragraph bold>Edit quoting parameters</Paragraph>
              <BaseForm
                buttonsSize={ButtonSize.Small}
                enableReinitialize
                initialValues={{
                  tier: OpportunityCoveragesTier.Custom,
                  dwelling: selectedDwellingCoverage || '',
                  deductible: selectedDeductible || ''
                }}
                validationSchema={yup.object().shape({
                  tier: yup.string().required(REQUIRED_MESSAGE),
                  dwelling: yup
                    .number()
                    .when('tier', ([tier], schema) =>
                      tier === OpportunityCoveragesTier.Custom ? schema.min(1, 'must be greater than zero') : schema
                    ),
                  deductible: yup
                    .number()
                    .when('tier', ([tier], schema) =>
                      tier === OpportunityCoveragesTier.Custom ? schema.min(1, 'must be greater than zero') : schema
                    )
                })}
                pt={0}
                pb={0}
                pl={0}
                pr={0}
                submitText="Save and quote"
                disabled={forbidQuoting}
                cancelHandler={() => setAnchorEl(null)}
                onSubmit={({ dwelling, deductible, tier }) => {
                  if (tier === OpportunityCoveragesTier.Custom) {
                    return updateHomeOpportunityCoverages(tier, [
                      {
                        key: PolicyCoverageKey.Dwelling,
                        limit_value: dwelling,
                        deductible_value: deductible,
                        limit_option_gid: null
                      }
                    ]);
                  }

                  return updateHomeOpportunityCoverages(tier, null);
                }}
                renderForm={({ values }) => (
                  <FlexBox gap={spacings.px12} columnDirection>
                    <SelectField
                      inline
                      label="Coverage tier"
                      id="tier"
                      name="tier"
                      options={realPropertyCoverageTierOptions}
                      labelSize={LabelSize.Small}
                      inputSize={InputSize.Medium}
                    />
                    {values.tier === OpportunityCoveragesTier.Custom && (
                      <>
                        <NumericField
                          inline
                          label="Dwelling"
                          id="dwelling"
                          name="dwelling"
                          prefixIcon={<DollarIcon />}
                          valueIsNumericString
                          labelSize={LabelSize.Small}
                          inputSize={InputSize.Medium}
                        />
                        <NumericField
                          inline
                          label="Deductible"
                          id="deductible"
                          name="deductible"
                          prefixIcon={<DollarIcon />}
                          valueIsNumericString
                          labelSize={LabelSize.Small}
                          inputSize={InputSize.Medium}
                        />
                      </>
                    )}
                  </FlexBox>
                )}
              />
            </FlexBox>
          </PopperComponent>
        </div>
      </FlexBox>
    </FlexBox>
  );
};

export default HomeCoverageLevel;
