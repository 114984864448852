/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import { LineOfBusiness, LineOfBusinessResourceType } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import AutoLineOfBusiness from '../AutoLineOfBusiness';
import HomeLineOfBusiness from '../HomeLineOfBusiness';
import OtherLineOfBusiness from '../OtherLineOfBusiness';
import UmbrellaLineOfBusiness from '../UmbrellaLineOfBusiness';

const pickLineOfBusinessComponent = (lineOfBusiness: LineOfBusiness) => {
  switch (lineOfBusiness.resource_type) {
    case LineOfBusinessResourceType.Home:
      return HomeLineOfBusiness;
    case LineOfBusinessResourceType.Umbrella:
      return UmbrellaLineOfBusiness;
    case LineOfBusinessResourceType.Auto:
      return AutoLineOfBusiness;
    default:
      return OtherLineOfBusiness;
  }
};

const LineOfBusinessContainer = ({
  policy,
  lineOfBusiness,
  policyTransactions
}: {
  policy: IMaticPolicy;
  lineOfBusiness: LineOfBusiness;
  policyTransactions: PolicyTransaction[];
}): JSX.Element => {
  const LineOfBusinessComponent = pickLineOfBusinessComponent(lineOfBusiness);
  const lastTransaction = policyTransactions[0];

  const policyType = findPolicyTypeLabel(lineOfBusiness?.policy_type) || lineOfBusiness.acord_label;

  return (
    <>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Heading>{`${policyType} insurance`}</Heading>
      </FlexBox>
      <div
        data-testid={`${policyType}-line-of-business-section`}
        css={css`
          padding-top: 12px;
          padding-right: 32px;
          padding-left: 32px;
        `}
      >
        {lastTransaction && <LineOfBusinessComponent {...{ policy, lineOfBusiness, lastTransaction }} />}
      </div>
    </>
  );
};

export default LineOfBusinessContainer;
