import React from 'react';

import { Pagination } from '../../components/common';
import FiltersSubHeader from '../../components/common/FiltersSubHeader/FiltersSubHeader';
import Container from '../../components/core/Container';
import TablePlaceholder from '../../components/TablePlaceholder';
import { SORT_ORDER_DESC } from '../../constants';
import { useDocumentTitle } from '../../hooks';
import { UserStatus } from '../../interfaces/IUser';
import useAssignees from '../../queries/assignees/useAssignees';
import useCarriers from '../../queries/carriers/useCarriers';
import usePolicies, {
  PoliciesDashboardFilters,
  PoliciesDashboardResponse,
  PoliciesDashboardSorting
} from '../../queries/policies/usePolicies';
import { spacings } from '../../theme/variables';
import PoliciesFilter from './PoliciesFilter';
import PoliciesList from './PoliciesList';

const POLICIES_PER_PAGE = 25;
const POLICIES_FILTERS_STORAGE_KEY = 'policiesFilters';
const DEFAULT_SORTING = { sort_column: 'effective_date', sort_order: SORT_ORDER_DESC };

const restoreFilters = () => JSON.parse(localStorage.getItem(POLICIES_FILTERS_STORAGE_KEY) || '{}') || {};

const currentlyDisplayedPoliciesMessage = (
  policiesDashboard: PoliciesDashboardResponse | undefined,
  currentPage: number
) => {
  const { policies, count } = policiesDashboard || {};
  const start = policies?.length ? (currentPage - 1) * POLICIES_PER_PAGE + 1 : 0;
  const end = policies?.length ? (currentPage - 1) * POLICIES_PER_PAGE + policies.length : 0;

  return `Displaying policies ${start} - ${end} of ${count} in total`;
};

const PoliciesDashboard = () => {
  useDocumentTitle('Policies dashboard');
  const [page, setPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<PoliciesDashboardSorting>(DEFAULT_SORTING);
  const [filters, setFilters] = React.useState<PoliciesDashboardFilters>(() => restoreFilters());

  const { data: assignees } = useAssignees({ statuses: [UserStatus.Active, UserStatus.Suspended] });
  const { data: carriers } = useCarriers({
    appointed_in_past: true,
    post_sale: true
  });

  const { data: policiesDashboard, isLoading } = usePolicies({
    ...sorting,
    ...filters,
    page
  });

  return (
    <Container fitParentWidth>
      <FiltersSubHeader headerText="Policies Dashboard">
        <PoliciesFilter
          filters={filters}
          assignees={assignees || []}
          carriers={carriers || []}
          onFilterReset={() => {
            setFilters({});
            localStorage.setItem(POLICIES_FILTERS_STORAGE_KEY, JSON.stringify({}));
          }}
          onFilterSubmit={filters => {
            setPage(1);
            setFilters(filters);
            localStorage.setItem(POLICIES_FILTERS_STORAGE_KEY, JSON.stringify(filters));
          }}
          message={currentlyDisplayedPoliciesMessage(policiesDashboard, page)}
        />
      </FiltersSubHeader>
      <Container fitParentWidth mt={spacings.px8} ph={spacings.px8}>
        {isLoading ? (
          <TablePlaceholder testId="policies-dashboard-loader" />
        ) : (
          <>
            <PoliciesList
              policies={policiesDashboard?.policies}
              onSortingChange={(sorting: PoliciesDashboardSorting) => setSorting(sorting)}
              defaultSorting={sorting}
            />
            <Pagination
              current={page - 1}
              pages={policiesDashboard?.pages}
              onPageChange={({ selected }: { selected: number }) => setPage(selected + 1)}
            />
          </>
        )}
      </Container>
    </Container>
  );
};

export default PoliciesDashboard;
