import { useMutation } from '@tanstack/react-query';

import api from '../../services/api';

const sendPolicyDocumentsToLender = ({
  policyId,
  loan_gid
}: {
  policyId: number | null;
  loan_gid?: string;
}): Promise<unknown> => api.post(`/api/frontend/policies/${policyId}/documents/send_to_lender`, { body: { loan_gid } });

const useSendPolicyDocumentsToLender = () => useMutation({ mutationFn: sendPolicyDocumentsToLender });

export default useSendPolicyDocumentsToLender;
