import React from 'react';

import { PolicyType } from '../../interfaces/IPolicyType';
import {
  BoatIcon,
  CarIcon,
  EarthquakeIcon,
  FireIcon,
  FloodIcon,
  HomeIcon,
  JewelryIcon,
  LifeIcon,
  MotorbikeIcon,
  PetIcon,
  RVIcon,
  UmbrellaIcon
} from './icons';

interface PolicyTypeIconProps extends React.SVGProps<SVGSVGElement> {
  policyType: PolicyType;
}

const PolicyTypeIcon = ({ policyType, ...other }: PolicyTypeIconProps) => {
  switch (policyType) {
    case PolicyType.Home:
    case PolicyType.Condo:
    case PolicyType.Renters:
    case PolicyType.ManufacturedOrMobileHome:
      return <HomeIcon {...other} />;
    case PolicyType.Earthquake:
      return <EarthquakeIcon {...other} />;
    case PolicyType.Fire:
      return <FireIcon {...other} />;
    case PolicyType.Auto:
      return <CarIcon {...other} />;
    case PolicyType.Moto:
      return <MotorbikeIcon {...other} />;
    case PolicyType.RV:
      return <RVIcon {...other} />;
    case PolicyType.Flood:
      return <FloodIcon {...other} />;
    case PolicyType.Umbrella:
      return <UmbrellaIcon {...other} />;
    case PolicyType.PAF:
      return <JewelryIcon {...other} />;
    case PolicyType.Pet:
      return <PetIcon {...other} />;
    case PolicyType.Boat:
      return <BoatIcon {...other} />;
    case PolicyType.TermLife:
    case PolicyType.ADND:
      return <LifeIcon {...other} />;

    default:
      return <React.Fragment />;
  }
};

export default PolicyTypeIcon;
