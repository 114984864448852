/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import CollapsingContainer from '../../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../../components/core/FlexBox';
import { InfoIcon } from '../../../../../components/core/icons';
import Paragraph, { ParagraphType } from '../../../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../../../components/core/PolicyTypeIcon';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import featureFlags from '../../../../../constants/featureFlags';
import { isPAFOpportunity } from '../../../../../interfaces/IOpportunity';
import { isPersonMarried } from '../../../../../interfaces/IPerson';
import { PolicyType } from '../../../../../interfaces/IPolicyType';
import { QuoteStatus } from '../../../../../interfaces/IQuote';
import usePerson from '../../../../../queries/people/usePerson';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { PAF_TIP } from '../../../../GuidedDataCollection/QuotingConfigurationPage/_utils';
import { isQuoteEligibleOrPartlyEligible, isQuoteNonEligible } from '../../_helpers';
import useQuotesModalView from '../../_hooks/useModalView';
import QuotingErrorsByAssets from '../../QuotingErrors/QuotingErrorsByAssets';
import DeprecatedPAFCoverageLevel from '../CoverageLevel/DeprecatedPAFCoverageLevel';
import PAFCoverageLevel from '../CoverageLevel/PAFCoverageLevel';
import QuotesGroupHeader from '../GroupHeaders/RealPropertyGroupHeader';
import QuoteRow from '../QuoteRow/QuoteRow';
import { QuoteGroupProps } from '.';

const PAFQuotesGroup = ({
  quotesGroup: { quotes, opportunity },
  assets,
  leadId,
  personGid,
  refetchQuotesRequests,
  quotingFinished,
  errors,
  quotesRequest
}: QuoteGroupProps) => {
  const { quotesModalView } = useQuotesModalView();
  const [isPremiumAnnual, setAnnualPremium] = React.useState(true);
  const realQuotes = quotes.filter(({ status }) => status !== QuoteStatus.CompleteManually);
  const { data: person } = usePerson(personGid);

  const eligibleQuotes = realQuotes.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleQuotes = realQuotes.filter(quote => isQuoteNonEligible(quote));

  const pafOpportunity = isPAFOpportunity(opportunity) ? opportunity : undefined;

  // This is very complicated business decision driven by Agency fear of displaying Travelers' PAF quote in addition to
  // JM quote, because of some very obscure thing related to agents commissions
  const quotesLength = realQuotes.length >= 1 ? 1 : 0;

  return (
    <FlexBox columnDirection gap={spacings.px8}>
      {!quotesModalView && (
        <>
          <FlexBox justifySpaceBetween>
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <PolicyTypeIcon policyType={PolicyType.PAF} />
              <Text
                bold={!featureFlags.newQuotesUI}
                type="large"
                customCss={css`
                  white-space: pre-wrap;
                `}
              >
                PAF ({quotesLength})
              </Text>
              {isPersonMarried(person?.marital_status) && (
                <>
                  <Tag
                    data-for="paf-recommendation"
                    data-tip={PAF_TIP}
                    label={
                      <FlexBox gap={spacings.px8}>
                        Recommended
                        <InfoIcon height={spacings.px16} width={spacings.px16} />
                      </FlexBox>
                    }
                    transparent
                    backgroundColor={colors.statusGreen}
                    textColor={colors.statusGreen}
                  />
                  <Tooltip id="paf-recommendation" />
                </>
              )}
            </FlexBox>
            {featureFlags.newQuotesUI && (
              <FlexBox alignItemsCenter gap={spacings.px8}>
                {leadId && pafOpportunity && quotesLength > 0 && (
                  <PAFCoverageLevel
                    pafOpportunity={pafOpportunity}
                    leadId={leadId}
                    personGid={personGid}
                    refetchQuotesRequests={refetchQuotesRequests}
                  />
                )}
                {errors && errors.length > 0 && <QuotingErrorsByAssets errors={errors} quotesRequest={quotesRequest} />}
              </FlexBox>
            )}
          </FlexBox>
          {leadId && pafOpportunity && quotesLength > 0 && !featureFlags.newQuotesUI && (
            <DeprecatedPAFCoverageLevel
              pafOpportunity={pafOpportunity}
              leadId={leadId}
              personGid={personGid}
              refetchQuotesRequests={refetchQuotesRequests}
            />
          )}
        </>
      )}
      {quotesLength > 0 && (
        <>
          <QuotesGroupHeader isPremiumAnnual={isPremiumAnnual} setAnnualPremium={setAnnualPremium} />
          <CollapsingContainer
            title={`Eligible and partly eligible (${quotesLength})`}
            titleSize={ParagraphType.Default}
            iconPosition="left"
          >
            {eligibleQuotes.map(quote => (
              <QuoteRow
                key={quote.gid}
                quote={quote}
                assets={assets}
                showMpd={false}
                isPremiumAnnual={isPremiumAnnual}
              />
            ))}
          </CollapsingContainer>
        </>
      )}
      {quotingFinished && quotesLength === 0 && notEligibleQuotes.length ? (
        <CollapsingContainer
          title={`Eligible and partly eligible (${quotesLength})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          <FlexBox alignItemsCenter justifyCenter pv={spacings.px24}>
            <Paragraph color={colors.grey60}>No quotes available</Paragraph>
          </FlexBox>
        </CollapsingContainer>
      ) : null}
      {notEligibleQuotes.length > 0 && (
        <CollapsingContainer
          title={`Not eligible (${notEligibleQuotes.length})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
          openedByDefault={false}
        >
          {notEligibleQuotes.map(quote => (
            <QuoteRow key={quote.gid} quote={quote} assets={assets} showMpd={false} isPremiumAnnual={isPremiumAnnual} />
          ))}
        </CollapsingContainer>
      )}
      {quotingFinished && !quotesLength && !notEligibleQuotes.length && (
        <FlexBox justifyCenter pv={spacings.px24}>
          <Paragraph color={colors.grey60}>No quotes available</Paragraph>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default PAFQuotesGroup;
