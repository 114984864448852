/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Blockquote from '../../../../../components/core/Blockquote';
import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import CollapsingContainer from '../../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../../components/core/FlexBox';
import { InfoIcon } from '../../../../../components/core/icons';
import Paragraph, { ParagraphType } from '../../../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../../../components/core/PolicyTypeIcon';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import featureFlags from '../../../../../constants/featureFlags';
import { useToggle } from '../../../../../hooks';
import { PolicyType } from '../../../../../interfaces/IPolicyType';
import { QuoteStatus } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { isQuoteEligibleOrPartlyEligible, isQuoteNonEligible, isQuoteRequiresDataFix } from '../../_helpers';
import useQuotesModalView from '../../_hooks/useModalView';
import QuotingErrorsByAssets from '../../QuotingErrors/QuotingErrorsByAssets';
import RealPropertyGroupHeader from '../GroupHeaders/RealPropertyGroupHeader';
import HowToQuoteUmbrellaModal from '../HowToQuoteUmbrellaModal';
import QuoteRow from '../QuoteRow/QuoteRow';
import { QuoteGroupProps } from '.';

const UmbrellaQuotesGroup = ({
  quotesGroup: { quotes },
  assets,
  quotingFinished,
  errors,
  quotesRequest
}: QuoteGroupProps) => {
  const { quotesModalView } = useQuotesModalView();
  const [howToQuote, toggleHowToQuote] = useToggle(false);
  const realQuotes = quotes.filter(({ status }) => status !== QuoteStatus.CompleteManually);

  const eligibleQuotes = realQuotes.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const invalidDataQuotes = realQuotes.filter(quote => isQuoteRequiresDataFix(quote));
  const notEligibleQuotes = realQuotes.filter(quote => isQuoteNonEligible(quote));

  const carriersRecommendations = quotes.filter(({ status }) => status === QuoteStatus.CompleteManually);

  const eligibleRecommendations = carriersRecommendations.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleRecommendations = carriersRecommendations.filter(quote => isQuoteNonEligible(quote));

  const eligibleEntriesCount = eligibleQuotes.length + invalidDataQuotes.length + eligibleRecommendations.length;
  const nonEligibleEntriesCount = notEligibleQuotes.length + notEligibleRecommendations.length;

  return (
    <FlexBox columnDirection gap={spacings.px8}>
      {!quotesModalView && (
        <>
          <FlexBox justifySpaceBetween alignItemsCenter>
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <PolicyTypeIcon policyType={PolicyType.Umbrella} />
              <Text
                bold={!featureFlags.newQuotesUI}
                type="large"
                customCss={css`
                  white-space: pre-wrap;
                `}
              >
                Umbrella ({quotes.length})
              </Text>
              <>
                <Tag
                  data-for="umbrella-recommendation"
                  data-tip="TBD"
                  label={
                    <FlexBox gap={spacings.px8} alignItemsCenter>
                      Recommended
                      <InfoIcon height={spacings.px16} width={spacings.px16} />
                    </FlexBox>
                  }
                  transparent
                  backgroundColor={colors.statusGreen}
                  textColor={colors.statusGreen}
                  mt={spacings.px4}
                />
                {/* <Tooltip id="umbrella-recommendation" /> TBD: READ REASON FROM BACKEND*/}
              </>
            </FlexBox>
            <FlexBox alignItemsCenter>
              <ButtonWithoutBorder onClick={toggleHowToQuote}>How to quote?</ButtonWithoutBorder>
              {featureFlags.newQuotesUI && errors && errors.length > 0 && (
                <QuotingErrorsByAssets errors={errors} quotesRequest={quotesRequest} />
              )}
            </FlexBox>
          </FlexBox>

          {eligibleEntriesCount > 0 && (
            <Blockquote
              textColor={colors.grey80}
              ml={spacings.px32}
              mv={spacings.px4}
              text="An umbrella insurance policy is extra liability insurance coverage that goes
            beyond your liability limits of the insured's homeowners, auto, or watercraft insurance.
            It provides an additional layer of security to those who are at risk of being sued for damages
            to other people's property or injuries caused to others in an accident."
            />
          )}
        </>
      )}

      {eligibleEntriesCount > 0 && (
        <>
          <RealPropertyGroupHeader />
          <CollapsingContainer
            title={`Eligible and partly eligible (${eligibleEntriesCount})`}
            titleSize={ParagraphType.Default}
            iconPosition="left"
          >
            {eligibleQuotes.map(quote => {
              return <QuoteRow key={quote.gid} quote={quote} assets={assets} showMpd={false} />;
            })}
            {invalidDataQuotes.map(quote => {
              return <QuoteRow key={quote.gid} quote={quote} assets={assets} showMpd={false} />;
            })}
            {!!eligibleRecommendations.length &&
              eligibleRecommendations.map(recommendation => {
                return <QuoteRow key={recommendation.gid} quote={recommendation} assets={assets} showMpd={false} />;
              })}
          </CollapsingContainer>
        </>
      )}
      {quotingFinished && eligibleEntriesCount === 0 && nonEligibleEntriesCount ? (
        <CollapsingContainer
          title={`Eligible and partly eligible (${eligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          <FlexBox justifyCenter pv={spacings.px24}>
            <Paragraph color={colors.grey60}>No quotes available</Paragraph>
          </FlexBox>
        </CollapsingContainer>
      ) : null}
      {(!!notEligibleQuotes.length || !!notEligibleRecommendations.length) && (
        <CollapsingContainer
          title={`Not eligible (${nonEligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
          openedByDefault={false}
        >
          {!!notEligibleQuotes.length &&
            notEligibleQuotes.map(quote => {
              return <QuoteRow key={quote.gid} quote={quote} assets={assets} showMpd={false} />;
            })}
          {!!notEligibleRecommendations.length &&
            notEligibleRecommendations.map(recommendation => {
              return <QuoteRow key={recommendation.gid} quote={recommendation} assets={assets} showMpd={false} />;
            })}
        </CollapsingContainer>
      )}
      {quotingFinished && !eligibleEntriesCount && !nonEligibleEntriesCount && (
        <FlexBox justifyCenter pv={spacings.px24}>
          <Paragraph color={colors.grey60}>No quotes available</Paragraph>
        </FlexBox>
      )}
      {howToQuote && <HowToQuoteUmbrellaModal cancelHandler={toggleHowToQuote} />}
    </FlexBox>
  );
};

export default UmbrellaQuotesGroup;
