/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as yup from 'yup';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { InputField, MultiSelectField, NumericField, SelectField } from '../../components/core/forms/fields';
import { DollarIcon } from '../../components/core/icons';
import Text from '../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IQuote, IVehicle } from '../../interfaces';
import { isDriverInfoCollected, isPersonAgedToDrive, personAge } from '../../interfaces/IPerson';
import { coverageByKey, LimitType, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../interfaces/IPolicyType';
import { QuoteSource } from '../../interfaces/IQuote';
import { buildVehicleTitle } from '../../interfaces/IVehicle';
import useCarriers from '../../queries/carriers/useCarriers';
import { AGGREGATED_QUOTES_QUERY_KEY } from '../../queries/people/person_quotes/useAggregatedQuotes';
import { useCreateQuote } from '../../queries/people/person_quotes/useCreateQuote';
import usePersonRelatedPeople from '../../queries/people/usePersonRelatedPeople';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { moneyFormatter } from '../../utils/formatter';
import { REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';

const buildInitialValues = (quote: IQuote | undefined, opportunityAssets: IVehicle[], personGid: string) => ({
  carrier_key: quote?.attributes.carrier.key || '',
  premium: quote?.attributes.premium || '',
  downpayment: quote?.attributes.payment_options?.[0]?.downpayment || '',
  subsequent_payment: quote?.attributes.payment_options?.[0]?.subsequent_payment || '',
  property_damage_liability:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PropertyDamage)?.limit_value || '',
  bodily_injury_liability_per_person:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.BiPerPerson)?.limit_value || '',
  bodily_injury_liability_per_accident:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.BiPerAccident)?.limit_value || '',
  uninsured_motorist_property_damage:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.UninsuredMpd)?.limit_value || '',
  insured_gids: quote?.attributes.coinsureds_gids?.concat(personGid) || [personGid],
  agent_bridge_url: quote?.attributes.binding_url || '',

  ...opportunityAssets.reduce(
    (acc, { gid: vehicleGid }) => {
      acc[`${vehicleGid}_collision_deductible`] =
        quote?.attributes.coverages?.find(
          coverage => PolicyCoverageKey.Collision === coverage.key && coverage.asset_gid === vehicleGid
        )?.deductible_value || '';

      acc[`${vehicleGid}_comprehensive_deductible`] =
        quote?.attributes.coverages?.find(
          coverage => PolicyCoverageKey.Comprehensive === coverage.key && coverage.asset_gid === vehicleGid
        )?.deductible_value || '';

      acc[`${vehicleGid}_towing`] =
        quote?.attributes.coverages?.find(
          coverage => PolicyCoverageKey.Towing === coverage.key && coverage.asset_gid === vehicleGid
        )?.limit_value || '';

      return acc;
    },
    {} as Record<string, string | number>
  )
});

const buildCoverages = ({
  property_damage_liability,
  bodily_injury_liability_per_person,
  bodily_injury_liability_per_accident,
  uninsured_motorist_property_damage
}: ReturnType<typeof buildInitialValues>) => {
  const propertyDamageNode = property_damage_liability
    ? {
        key: PolicyCoverageKey.PropertyDamage,
        limit_value: property_damage_liability,
        deductible_value: 0,
        limit_description: moneyFormatter(property_damage_liability, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const biPerPersonNode = bodily_injury_liability_per_person
    ? {
        key: PolicyCoverageKey.BiPerPerson,
        limit_value: bodily_injury_liability_per_person,
        deductible_value: 0,
        limit_description: moneyFormatter(bodily_injury_liability_per_person, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const biPerAccidentNode = bodily_injury_liability_per_accident
    ? {
        key: PolicyCoverageKey.BiPerAccident,
        limit_value: bodily_injury_liability_per_accident,
        deductible_value: 0,
        limit_description: moneyFormatter(bodily_injury_liability_per_accident, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const uninsuredMotoristPropertyDamageNode = uninsured_motorist_property_damage
    ? {
        key: PolicyCoverageKey.UninsuredMpd,
        limit_value: uninsured_motorist_property_damage,
        deductible_value: 0,
        limit_description: moneyFormatter(uninsured_motorist_property_damage, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;

  return [propertyDamageNode, biPerPersonNode, biPerAccidentNode, uninsuredMotoristPropertyDamageNode].filter(Boolean);
};

const generateAssetsWithCoverages = (values: Record<string, any>, opportunityAssets: IVehicle[]) => {
  return opportunityAssets.map(vehicle => {
    return {
      gid: vehicle.gid,
      coverages: [
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.Collision,
          limit_value: 0,
          deductible_value: values[`${vehicle.gid}_collision_deductible`] || 0,
          limit_description: '',
          limit_type: undefined,
          deductible_description: moneyFormatter(values[`${vehicle.gid}_collision_deductible`], true) || '',
          deductible_type: undefined,
          title: 'Collision deductible',
          description: 'Collision deductible'
        },
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.Comprehensive,
          limit_value: 0,
          deductible_value: values[`${vehicle.gid}_comprehensive_deductible`] || 0,
          limit_description: '',
          limit_type: undefined,
          deductible_description: moneyFormatter(values[`${vehicle.gid}_comprehensive_deductible`], true) || '',
          deductible_type: undefined,
          title: 'Comprehensive deductible',
          description: 'Comprehensive deductible'
        },
        {
          asset_gid: vehicle.gid,
          key: PolicyCoverageKey.Towing,
          limit_value: values[`${vehicle.gid}_towing`] || 0,
          deductible_value: 0,
          limit_description: moneyFormatter(values[`${vehicle.gid}_towing`], true) || '',
          limit_type: undefined,
          deductible_description: '',
          deductible_type: undefined,
          title: 'Roadside assistance',
          description: 'Roadside assistance'
        },
        ...buildCoverages(values as ReturnType<typeof buildInitialValues>)
      ]
    };
  });
};

const AutoQuoteForm = ({
  policyType,
  setOpenQuoteEditor,
  quote,
  quotes,
  opportunityAssets,
  setSelectedQuoteKey
}: {
  policyType: PolicyType;
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote?: IQuote;
  opportunityAssets: IVehicle[];
  quotes: IQuote[];
  setSelectedQuoteKey: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const queryClient = useQueryClient();
  const { personGid, person, leadGid } = useGuidedSellingExperienceContext();
  const { data: carriers, isPending: isPendingCarriers } = useCarriers({
    policy_type: policyType,
    appointed: true
  });
  const { mutateAsync: createQuote } = useCreateQuote();
  const carriersToExclude = quotes.map(quote => quote.attributes.carrier.key);
  const carrierOptions = React.useMemo(() => {
    return (carriers || [])
      .filter(
        carrier => !carriersToExclude.find(key => key === carrier.key) || carrier.key === quote?.attributes.carrier.key
      )
      .map(({ key, name }) => ({ key, value: name }));
  }, [carriers, carriersToExclude, quote?.attributes.carrier.key]);
  const { data: relatedPeople, isPending: isPendingRelatedPeople } = usePersonRelatedPeople(personGid);

  const primaryInsuredOption = { key: person?.gid, value: person?.name, isDisabled: true, isSelected: true };
  const coinsuredPeopleOptions =
    relatedPeople?.map(person => {
      const { name, gid } = person;
      let optionConfig;

      if (!isDriverInfoCollected(person)) {
        optionConfig = { value: `${name} (driver info is not collected)`, isDisabled: true };
      } else if (!isPersonAgedToDrive(person)) {
        optionConfig = { value: `${name} (${personAge(person)} years old)`, isDisabled: true };
      } else {
        optionConfig = { value: name, isDisabled: false };
      }

      return { key: gid, ...optionConfig };
    }) || [];

  const insuredDriversOptions = [
    primaryInsuredOption,
    ...coinsuredPeopleOptions.sort((option1, option2) => (option1.isDisabled ? 1 : 0) - (option2.isDisabled ? 1 : 0))
  ];

  return (
    <Container
      border
      borderColor={colors.azure50}
      boxShadow
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 400px;
      `}
      backgroundColor={colors.ghostWhite}
      p={spacings.px12}
    >
      <BaseForm
        pt={spacings.px0}
        pr={spacings.px0}
        pb={spacings.px0}
        pl={spacings.px0}
        initialValues={buildInitialValues(quote, opportunityAssets, personGid!)}
        onSubmit={values => {
          createQuote({
            personGid: personGid!,
            data: {
              premium: values.premium,
              source: QuoteSource.AU,
              policy_type: policyType,
              carrier_key: values.carrier_key,
              engagement_gid: leadGid!,
              coverages: buildCoverages(values),
              assets: generateAssetsWithCoverages(values, opportunityAssets),
              coinsureds: values.insured_gids.filter(gid => gid !== personGid).map(gid => ({ gid })),
              coverages_tier: 'mixed',
              payment_options: [
                { downpayment: Number(values.downpayment), subsequent_payment: Number(values.subsequent_payment) }
              ],
              agent_bridge_url: values.agent_bridge_url
            }
          }).then(data =>
            queryClient
              .invalidateQueries({ queryKey: [AGGREGATED_QUOTES_QUERY_KEY, personGid] })
              .then(() => setSelectedQuoteKey(data.quote.gid))
          );

          setOpenQuoteEditor(false);
        }}
        validationSchema={yup.object().shape({
          carrier_key: requiredField,
          premium: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE)
        })}
        customControls
        renderForm={() => {
          return (
            <FlexBox columnDirection gap={spacings.px12}>
              <FlexBox justifySpaceBetween>
                <Text bold ml={spacings.px4}>
                  Add insurance details
                </Text>
                <IconButton
                  icon={ButtonIcons.Close}
                  color={colors.black}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                />
              </FlexBox>
              <FlexBox gap={spacings.px4} columnDirection>
                <SelectField
                  name="carrier_key"
                  id="carrier_key"
                  label="Carrier"
                  options={carrierOptions}
                  inline
                  ordered
                  isLoading={isPendingCarriers}
                  customCss={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                  required
                  menuMinWidth="180px"
                />
                <InputField name="agent_bridge_url" id="agent_bridge_url" label="Carrier portal URL" inline />
                <NumericField
                  name="premium"
                  id="premium"
                  label="Premium(6 months)"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  required
                />
                <NumericField
                  name="downpayment"
                  id="downpayment"
                  label="First month (Downpayment)"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                />
                <NumericField
                  name="subsequent_payment"
                  id="subsequent_payment"
                  label="Subsequent monthly payments"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                />
                <Text bold m={spacings.px4}>
                  General automobile coverages
                </Text>
                <NumericField
                  name="property_damage_liability"
                  id="property_damage_liability"
                  label="Property damage"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                />
                <NumericField
                  name="bodily_injury_liability_per_person"
                  id="bodily_injury_liability_per_person"
                  label="Bodily injury liability per person"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                />
                <NumericField
                  name="bodily_injury_liability_per_accident"
                  id="bodily_injury_liability_per_accident"
                  label="Bodily injury liability per accident"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                />
                <NumericField
                  name="uninsured_motorist_property_damage"
                  id="uninsured_motorist_property_damage"
                  label="Uninsured motorist property damage"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                />
                <MultiSelectField
                  fsMask
                  inline
                  label="Insured drivers"
                  key="insured_gids"
                  id="insured_gids"
                  name="insured_gids"
                  options={insuredDriversOptions}
                  disabled={isPendingRelatedPeople}
                  isLoading={isPendingRelatedPeople}
                  css={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                />
                {opportunityAssets.map(vehicle => {
                  return (
                    <React.Fragment key={vehicle.gid}>
                      <Text bold m={spacings.px4}>
                        {buildVehicleTitle(vehicle)}
                      </Text>
                      <NumericField
                        name={`${vehicle.gid}_collision_deductible`}
                        id={`${vehicle.gid}_collision_deductible`}
                        label="Collision deductible"
                        inline
                        valueIsNumericString
                        prefixIcon={<DollarIcon />}
                        decimalScale={0}
                      />
                      <NumericField
                        name={`${vehicle.gid}_comprehensive_deductible`}
                        id={`${vehicle.gid}_comprehensive_deductible`}
                        label="Comprehensive deductible"
                        inline
                        valueIsNumericString
                        prefixIcon={<DollarIcon />}
                        decimalScale={0}
                      />
                      <NumericField
                        name={`${vehicle.gid}_towing`}
                        id={`${vehicle.gid}_towing`}
                        label="Roadside Assistance Coverage"
                        inline
                        valueIsNumericString
                        prefixIcon={<DollarIcon />}
                        decimalScale={0}
                      />
                    </React.Fragment>
                  );
                })}
              </FlexBox>
              <FlexBox gap={spacings.px4} justifyRight>
                <Button
                  type="button"
                  size={ButtonSize.Small}
                  variant={ButtonVariant.SecondaryDanger}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" size={ButtonSize.Small}>
                  Save
                </Button>
              </FlexBox>
            </FlexBox>
          );
        }}
      />
    </Container>
  );
};

export default AutoQuoteForm;
