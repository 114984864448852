/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FlexBox from '../../../../components/core/FlexBox';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const EngagementNavigationSkeleton = () => (
  <FlexBox
    columnDirection
    gap={spacings.px16}
    pv={spacings.px24}
    alignItemsCenter
    customCss={css`
      background-color: ${colors.grey5};
      width: 54px;
    `}
  >
    {[1, 2, 3, 4, 5, 6].map(i => (
      <SkeletonTheme key={i} baseColor={colors.grey30} highlightColor={colors.grey10}>
        <Skeleton
          circle
          style={{
            width: '24px',
            height: '24px'
          }}
        />
      </SkeletonTheme>
    ))}
  </FlexBox>
);

export default EngagementNavigationSkeleton;
