export enum UserRoleCategory {
  ISR = 'isr',
  Agent = 'agent',
  CSR = 'customer_service_representative',
  Admin = 'admin',
  Other = 'other'
}

export enum UserRole {
  SuperISR = 'super_isr',
  ISR = 'isr',
  Agent = 'agent',
  Admin = 'admin'
}

export enum LicensingTypes {
  PCLicense = 'pc_license',
  PersonalLines = 'personal_lines',
  Life = 'life'
}

export enum UserStatus {
  Active = 'active',
  Suspended = 'suspended'
}

export interface IUser {
  id: number;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  status: UserStatus;
  avatar_url: string | null;
}

export interface IUserWithRole extends IUser {
  role: UserRole;
  role_category: UserRoleCategory;
  role_label: string;
}
