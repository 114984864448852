import React from 'react';

import featureFlags from '../../constants/featureFlags';
import { useCallState } from '../../contexts/CallContext';
import LeadClassificationCheckBanner from './LeadClassificationCheckBanner';

const LeadClassificationCheck = () => {
  const { currentCallId } = useCallState();

  if (!currentCallId || !featureFlags.leadClassificationCheck) {
    return null;
  }

  return <LeadClassificationCheckBanner key={currentCallId} currentCallId={currentCallId} />;
};

export default LeadClassificationCheck;
