/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { IPerson } from '../../interfaces/IPerson';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { Link } from '../common';
import ButtonWithoutBorder from '../core/buttons/ButtonWithoutBorder';
import FlexBox from '../core/FlexBox';
import Heading from '../core/Heading';
import { CloseIcon } from '../core/icons';

const PolicyModalHeader = ({
  heading,
  person,
  closeHandler
}: {
  heading: string;
  person: IPerson;
  closeHandler: () => void;
}) => (
  <FlexBox
    alignItemsCenter
    justifySpaceBetween
    p={spacings.px24}
    customCss={css`
      border-bottom: 1px solid ${colors.grey10};
    `}
  >
    <FlexBox gap={spacings.px4}>
      <Heading type="h3">{heading} Customer:</Heading>
      <Link to={`/people/${person.gid}`}>
        <Heading
          type="h3"
          color={colors.azure50}
          className="fs-mask"
          css={css`
            &:hover {
              color: ${colors.blueHoverLink};
            }
          `}
        >
          {person.name}
        </Heading>
      </Link>
    </FlexBox>
    <ButtonWithoutBorder onClick={closeHandler} aria-label="Close">
      <CloseIcon color={colors.black} width={40} height={40} />
    </ButtonWithoutBorder>
  </FlexBox>
);

export default PolicyModalHeader;
