import { Grid } from '@mui/material';
import * as React from 'react';

import { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import { DateInputField } from '../../../components/core/forms/fields';
import Heading from '../../../components/core/Heading';
import { WarningIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { IPerson, IVehicle } from '../../../interfaces';
import { AutoCoveragesOptions } from '../../../interfaces/IAutoCoveragesOptions';
import { PolicyType } from '../../../interfaces/IPolicyType';
import usePersonPriorPolicies from '../../../queries/people/person_policies/usePersonPriorPolicies';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';
import Header from '../_components/Header';
import StepsBar from '../_components/StepsBar';
import { SelectedVehicleWithCoverages } from '../_interfaces/ISelectedAsset';
import { initialValues, validateVehiclesCoverages, validations } from './formHelpers';
import VehicleCoverages from './VehicleCoverages';

const VehiclesCoveragesPicker = ({
  person,
  leadId,
  selectedVehicles,
  onBack,
  onClose,
  onSubmit
}: {
  person: IPerson;
  leadId: string | undefined;
  selectedVehicles: IVehicle[];
  onBack: () => void;
  onClose: () => void;
  onSubmit: (vehiclesWithCoverages: SelectedVehicleWithCoverages[]) => void;
}) => {
  const { data: policies } = usePersonPriorPolicies({
    personGid: person.gid,
    filters: {
      policy_types: [PolicyType.Auto],
      similar_policy_types: true,
      asset_gid: selectedVehicles[0]!.gid
    }
  });
  const recentPolicy = policies?.[0];

  const [coverages, setCoverages] = React.useState<Record<string, AutoCoveragesOptions>>({});

  const onInitialCoveragesLoadedCallback = React.useCallback(
    (autoCoveragesOptions: AutoCoveragesOptions, vehicleGid: string) => {
      setCoverages(prevState => ({ ...prevState, [vehicleGid]: autoCoveragesOptions }));
    },
    []
  );

  return (
    <BaseForm
      type="fullPage"
      pt={spacings.px12}
      pb={spacings.px12}
      pl={spacings.px24}
      pr={spacings.px24}
      enableReinitialize
      cancelWithEscape
      header={<Header person={person} leadId={leadId} />}
      footer={<StepsBar activeStepOrder={3} />}
      initialValues={initialValues({ recentPolicy, selectedVehicles, coverages })}
      validationSchema={validations()}
      // Some validation triggers are disabled in order to not trigger too many validation requests via API
      validateOnBlur={false}
      validateOnMount={false}
      validate={values => validateVehiclesCoverages({ selectedVehicles, values })}
      onSubmit={values =>
        onSubmit(
          selectedVehicles.map(vehicle => ({
            ...vehicle,
            prior_policy_expiration_date: values.prior_policy_expiration_date,
            coverages: values[vehicle.gid]['coverages']
          }))
        )
      }
      submitText="Get quotes"
      controlsWidth={130}
      cancelText="Back"
      cancelHandler={onBack}
      cancelVariant={ButtonVariant.Secondary}
      closeHandler={onClose}
      disabled={Object.keys(coverages).length === 0}
      renderForm={({ values, setValues }) => {
        return (
          <Grid container maxWidth={sizes.mediumFormInputWidth * 4} flexDirection="column">
            <Grid container columnSpacing={2}>
              <Grid item xs={3}>
                <DateInputField
                  label="Prior auto insurance expiration date"
                  id="prior_policy_expiration_date"
                  name="prior_policy_expiration_date"
                />
                {!values.prior_policy_expiration_date && (
                  <FlexBox mt={spacings['-px20']}>
                    <WarningIcon color={colors.statusOrange} />
                    <Text type="small" color={colors.statusOrange} ml={spacings.px4}>
                      It can impact premium
                    </Text>
                  </FlexBox>
                )}
              </Grid>
            </Grid>
            <Heading type="h4" mv={spacings.px12}>
              Auto coverages
            </Heading>
            {selectedVehicles.map(vehicle => (
              <div key={vehicle.gid}>
                <Text bold type="large">
                  {vehicle.description}
                </Text>
                <Container mt={spacings.px8}>
                  <VehicleCoverages
                    vehicle={vehicle}
                    formikValues={values}
                    setValues={setValues}
                    onInitialCoveragesLoaded={onInitialCoveragesLoadedCallback}
                  />
                </Container>
              </div>
            ))}
          </Grid>
        );
      }}
    />
  );
};

export default VehiclesCoveragesPicker;
