/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Button, { ButtonSize } from '../../../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../components/core/FlexBox';
import { PhoneIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { phoneFormatter } from '../../../../utils/formatter';

interface ContactBookEntryProps {
  title: string;
  phone: string;
  description?: string;
  tag?: string;
  disabled: boolean;
  loading: boolean;
  onCall: () => void;
}

const ContactBookEntry = ({ title, phone, description, tag, disabled, loading, onCall }: ContactBookEntryProps) => {
  return (
    <ButtonWithoutBorder
      disabled={disabled}
      onClick={() => onCall()}
      customCss={css`
        .call-button {
          visibility: collapse;
        }

        &:hover {
          background-color: ${colors.grey5};

          .call-button {
            visibility: visible;
          }
        }
      `}
    >
      <FlexBox ph={spacings.px12} pv={spacings.px8} justifySpaceBetween alignItemsCenter gap={spacings.px12}>
        <FlexBox columnDirection gap={spacings.px4}>
          <FlexBox alignItemsCenter gap={spacings.px4}>
            <Paragraph bold>{title}</Paragraph>
            {tag && <Tag label={tag} transparent backgroundColor={colors.grey80} textColor={colors.grey80} />}
          </FlexBox>
          <Text
            type="small"
            color={colors.grey60}
            customCss={css`
              text-align: start;
            `}
          >
            {phoneFormatter(phone)}
            {description && ` · ${description}`}
          </Text>
        </FlexBox>
        <div>
          <Button size={ButtonSize.Small} className="call-button" loading={loading} disabled={disabled}>
            <PhoneIcon /> Call
          </Button>
        </div>
      </FlexBox>
    </ButtonWithoutBorder>
  );
};

export default ContactBookEntry;
