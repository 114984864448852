/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import AddButton from '../../components/core/buttons/AddButton';
import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import HotKey from '../../components/core/HotKey';
import { CloseIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import Text from '../../components/core/Text';
import { useEventListener } from '../../hooks';
import useNavigateBack from '../../hooks/useNavigateBack';
import useProductAnnouncements from '../../queries/product_announcements/useProductAnnouncements';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { dateFormatter, SHORT_DATE_FORMAT } from '../../utils/formatter';
import AnnouncementTag from './_components/AnnouncementTag';
import CancelAnnouncementModal from './_components/CancelAnnouncementModal';
import { announcementContainerStyle, announcementsGridStyle } from './_components/grid';
import ProductAnnouncement from './_components/ProductAnnouncement';
import useProductAnnouncementsForUser from './_hooks/useProductAnnouncementsForUser';

interface ProductAnnouncementsProps {
  adminView?: boolean;
}

const ProductAnnouncements = ({ adminView = false }: ProductAnnouncementsProps) => {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();

  const [productAnnouncementToDelete, setProductAnnouncementToDelete] = React.useState<number | null>(null);

  const { data: productAnnouncements, refetch: refetchProductAnnouncements } = useProductAnnouncements(
    adminView ? {} : { role: authInfo.role, released: true }
  );

  const { markProductAnnouncementsAsRead } = useProductAnnouncementsForUser();

  React.useEffect(() => {
    if (!adminView) {
      markProductAnnouncementsAsRead();
    }
  }, [adminView, markProductAnnouncementsAsRead]);

  useEventListener('keydown', event => {
    if (event.key === 'Escape') {
      navigateBack();
    }
  });

  return (
    <Container fitParentHeight customCss={announcementsGridStyle}>
      <Container
        pt={spacings.px16}
        pb={spacings.px24}
        customCss={css`
          grid-area: header;
        `}
      >
        {adminView ? (
          <FlexBox alignItemsBaseline gap={spacings.px8} ph={spacings.px8}>
            <Heading type="h3">Product announcements</Heading>
            <AddButton onClick={() => navigate('/new_product_announcement')} content="New draft" />
          </FlexBox>
        ) : (
          <Heading type="h3" ph={spacings.px8}>
            What’s new
          </Heading>
        )}
      </Container>

      <FlexBox
        justifyRight
        alignItemsFlexStart
        p={spacings.px16}
        customCss={css`
          grid-area: close;
        `}
      >
        <FlexBox alignItemsCenter>
          <HotKey
            message="Press ESC"
            customCss={css`
              margin-right: ${spacings.px8}px;
            `}
          />
          <ButtonWithoutBorder onClick={() => navigateBack()} aria-label="Close">
            <CloseIcon color={colors.black} width={40} height={40} />
          </ButtonWithoutBorder>
        </FlexBox>
      </FlexBox>

      <Container
        customCss={css`
          grid-area: content;
          overflow-y: auto;
        `}
      >
        <FlexBox
          columnDirection
          gap={spacings.px24}
          pb={spacings.px48}
          ph={spacings.px8}
          customCss={announcementContainerStyle}
        >
          {productAnnouncements?.map(productAnnouncement => (
            <Container key={productAnnouncement.id}>
              <FlexBox alignItemsCenter gap={spacings.px8} justifySpaceBetween>
                <FlexBox alignItemsCenter gap={spacings.px8}>
                  <Paragraph bold type="large">
                    {productAnnouncement.title}
                  </Paragraph>
                  <FlexBox
                    gap={spacings.px8}
                    customCss={css`
                      max-width: 240px;
                      flex-wrap: wrap;
                    `}
                  >
                    {productAnnouncement.tags.map(tag => (
                      <AnnouncementTag key={tag} tag={tag} />
                    ))}
                  </FlexBox>
                  {adminView && (
                    <FlexBox gap={spacings.px4}>
                      <IconButton
                        icon={ButtonIcons.Edit}
                        onClick={() => navigate(`/product_announcements_admin/${productAnnouncement.id}/edit`)}
                      />
                      <IconButton
                        icon={ButtonIcons.Delete}
                        onClick={() => setProductAnnouncementToDelete(productAnnouncement.id)}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
                <Text
                  type="small"
                  color={colors.grey80}
                  customCss={css`
                    min-width: 86px;
                    text-align: end;
                  `}
                >
                  {dateFormatter(productAnnouncement.release_date, SHORT_DATE_FORMAT)}
                </Text>
              </FlexBox>

              <ProductAnnouncement
                productAnnouncement={productAnnouncement}
                adminView={adminView}
                onSelectProductAnnouncement={() =>
                  navigate(
                    adminView
                      ? `/product_announcements_admin/${productAnnouncement.id}`
                      : `/product_announcements/${productAnnouncement.id}`
                  )
                }
              />
            </Container>
          ))}
        </FlexBox>

        {productAnnouncementToDelete && (
          <CancelAnnouncementModal
            productAnnouncementId={productAnnouncementToDelete}
            onCancel={() => setProductAnnouncementToDelete(null)}
            onDeleted={() => {
              setProductAnnouncementToDelete(null);
              refetchProductAnnouncements();
            }}
          />
        )}
      </Container>
    </Container>
  );
};

export default ProductAnnouncements;
