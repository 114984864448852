/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import ButtonWithoutBorder from '../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../components/core/FlexBox';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import { IPartner } from '../../../interfaces';
import { findBrandName } from '../../../queries/partners/useBrand';
import { MatchedMainField, PersonMatch } from '../../../queries/people/usePeopleByTerm';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { phoneFormatter } from '../../../utils/formatter';
import parseHtmlString from '../../../utils/htmlStringParser';
import MatchedBy from '../MatchedBy';

const SuggestionLink = ({
  onSelected,
  linkText,
  disabled
}: {
  onSelected: any;
  linkText: string;
  disabled: boolean;
}): JSX.Element => {
  return (
    <ButtonWithoutBorder onClick={onSelected} disabled={disabled}>
      {linkText}
    </ButtonWithoutBorder>
  );
};

const Suggestion = ({
  personMatch,
  partners,
  fullScreen,
  onCustomerPageSelected,
  onScoutingReportSelected
}: {
  personMatch: PersonMatch;
  partners?: IPartner[];
  fullScreen?: boolean;
  onCustomerPageSelected: () => void;
  onScoutingReportSelected: () => void;
}): JSX.Element => {
  const { person, last_candidate: candidate, last_lead: lead, highlight, attributes } = personMatch;
  const brandKey = candidate?.source_dimensions?.brand || lead?.source_dimensions?.brand;
  const brandName = findBrandName(partners, brandKey);
  const phone = person.phone || person.secondary_phone || person.business_phone;
  const loanNumbers = attributes?.loans_numbers;

  const matchedName = highlight[MatchedMainField.Name];
  const matchedEmail = highlight[MatchedMainField.Email];
  const matchedPhone = highlight[MatchedMainField.Phone] || highlight[MatchedMainField.FormattedPhone];
  const matchedLoanNumbers = highlight[MatchedMainField.LoanNumbers];
  const matchedLivingAddress = highlight[MatchedMainField.LivingAddress];

  return (
    <FlexBox
      p={fullScreen ? 12 : 4}
      justifySpaceBetween
      fitParentWidth
      borderBottom
      customCss={css`
        cursor: default;
      `}
    >
      <FlexBox columnDirection>
        <FlexBox alignItemsCenter>
          <Text className="fs-mask" bold type="large">
            {(matchedName && parseHtmlString(matchedName[0]!)) || person.name}
          </Text>
          {brandName && (
            <Tag label={brandName} backgroundColor={colors.grey10} textColor={colors.black} ml={spacings.px12} />
          )}
        </FlexBox>
        <FlexBox mt={spacings.px8}>
          {phone && (
            <Text className="fs-mask" type="small" bold color={colors.grey80}>
              {(matchedPhone && parseHtmlString(phoneFormatter(matchedPhone[0]))) || phoneFormatter(phone)}
            </Text>
          )}
          {person.email && (
            <Text className="fs-mask" type="small" bold color={colors.grey80} ml={phone ? spacings.px12 : spacings.px0}>
              {(matchedEmail && parseHtmlString(matchedEmail[0]!)) || person.email}
            </Text>
          )}
        </FlexBox>
        {person.living_address?.full && (
          <FlexBox mt={spacings.px8}>
            <Text className="fs-mask" type="small" color={colors.grey80}>
              {(matchedLivingAddress && parseHtmlString(matchedLivingAddress[0]!)) || (
                <>
                  <span>{`${person.living_address.line1}, ${person.living_address.city}, `}</span>
                  <b>{person.living_address.state}</b>
                  <span>{`, ${person.living_address.zip}`}</span>
                </>
              )}
            </Text>
          </FlexBox>
        )}
        {loanNumbers?.length ? (
          <FlexBox mt={spacings.px8}>
            <Text className="fs-mask" type="small" color={colors.grey80}>
              <span>Loan number: </span>
              <b>{(matchedLoanNumbers && parseHtmlString(matchedLoanNumbers[0]!)) || loanNumbers[0]}</b>
            </Text>
          </FlexBox>
        ) : null}
        <FlexBox mt={spacings.px8}>
          <MatchedBy highlight={highlight} />
        </FlexBox>
        <FlexBox mt={spacings.px4}>
          <>
            <SuggestionLink onSelected={onCustomerPageSelected} linkText="Customer page" disabled={false} />
            <span
              css={css`
                color: ${colors.grey30};
                margin: 0 8px;
                bottom: ${fullScreen && '4px'};
                position: relative;
              `}
            >
              .
            </span>
          </>
          <SuggestionLink onSelected={onScoutingReportSelected} linkText="Lead summary" disabled={false} />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default Suggestion;
