import './styles.scss';

import classNames from 'classnames';
import React from 'react';

interface IFullScreenModal {
  title: string | React.ReactNode;
  className: string;
  children: React.ReactNode;
}
const FullScreenModal = React.forwardRef(({ children, className, title }: IFullScreenModal, ref: any) => (
  <section className={classNames('full-screen-modal', className)}>
    <header className="full-screen-modal__header">{title}</header>
    <div ref={ref} className="full-screen-modal__content">
      {children}
    </div>
  </section>
));

FullScreenModal.displayName = 'FullScreenModal';

export default FullScreenModal;
