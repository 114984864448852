import alert from '../../../../components/core/Alert';
import { InsurableInterest, PolicyType } from '../../../../interfaces/IPolicyType';
// Create via data_collection endpoint will trigger loader in the middle of quote request
import { createOpportunity } from '../../../../queries/leads/opportunities/useLeadOpportunities';
import useStartQuoting from '../../../../queries/quotes_requests/useStartQuoting';
import {
  IQuotingConfigurationFormValues,
  IQuotingConfigurationOpportunity
} from '../_interfaces/IQuotingConfigurationOpportunity';
import { STUB_FLOOD_PREFIX, STUB_PAF_OPPORTUNITY_ID } from '../_utils';

type ConfiguredOpportunities = (IQuotingConfigurationOpportunity & { selectedAssetsGids: string[] })[];

export const selectConfiguredOpportunities = (
  opportunities: IQuotingConfigurationOpportunity[],
  opportunitiesConfiguration: IQuotingConfigurationFormValues
) =>
  Object.keys(opportunitiesConfiguration.opportunities)
    .filter(opportunityId => opportunityId !== STUB_PAF_OPPORTUNITY_ID)
    .filter(opportunityId => !opportunityId.startsWith(STUB_FLOOD_PREFIX))
    .map(opportunityId => {
      const opportunity = opportunities.find(opp => opp.id.toString() === opportunityId.toString())!;
      const opportunityConfiguration = opportunitiesConfiguration.opportunities[opportunityId.toString()]!;

      if (!opportunityConfiguration.enabled) {
        return null;
      }

      if (opportunity.assets?.length && opportunity.assets?.length > 0) {
        const selectedAssetsGids = opportunityConfiguration.assets_gids;

        return selectedAssetsGids?.length
          ? {
              ...opportunity,
              agent_selected_coverages: opportunityConfiguration.agent_selected_coverages,
              selectedAssetsGids
            }
          : null;
      }

      return { ...opportunity, agent_selected_coverages: opportunityConfiguration.agent_selected_coverages };
    })
    ?.filter(opportunity => !!opportunity) as ConfiguredOpportunities;

const homesToQuote = (configuredOpportunities: ConfiguredOpportunities) => {
  const homesToQuote = configuredOpportunities
    .filter(
      opportunity =>
        opportunity.insurable_interest === InsurableInterest.RealProperty &&
        opportunity.policy_type !== PolicyType.Umbrella
    )
    .map(opportunity => {
      const homeGid = opportunity.selectedAssetsGids[0]!;

      return {
        asset_gid: homeGid,
        opportunity_id: opportunity.id,
        quote_kind: 'rc2'
      };
    })
    // remove duplicate home entry populated by corresponding flood opportunity
    .filter((home, index, self) => index === self.findIndex(h => h.asset_gid === home.asset_gid));

  return homesToQuote.length ? { homes: homesToQuote } : {};
};

const autosToQuote = (configuredOpportunities: ConfiguredOpportunities) => {
  const autosToQuote = configuredOpportunities
    .filter(opportunity => opportunity.policy_type === PolicyType.Auto)
    .map(opportunity => {
      const vehiclesToQuote = opportunity.selectedAssetsGids.map(gid => ({
        asset_gid: gid
      }));

      return { vehicles: vehiclesToQuote, drivers_gids: [], opportunity_id: opportunity.id };
    })
    .filter(Boolean);

  return autosToQuote.length ? { autos: autosToQuote } : {};
};

const motorcyclesToQuote = (configuredOpportunities: ConfiguredOpportunities) => {
  const motorcyclesToQuote = configuredOpportunities
    .filter(opportunity => opportunity.policy_type === PolicyType.Moto)
    .map(opportunity => {
      // Uncomment and remove previous method when moto select is available
      // const vehiclesToQuote = opportunity.selectedAssetsGids.map(gid => ({
      //   asset_gid: gid
      // }));
      const vehiclesToQuote = opportunity.assets!.map(({ gid }) => ({
        asset_gid: gid
      }));

      return { vehicles: vehiclesToQuote, opportunity_id: opportunity.id };
    })
    .filter(Boolean);

  return motorcyclesToQuote.length ? { motorcycles: motorcyclesToQuote } : {};
};

const recreationalVehiclesToQuote = (configuredOpportunities: ConfiguredOpportunities) => {
  const recreationalVehiclesToQuote = configuredOpportunities
    .filter(opportunity => opportunity.policy_type === PolicyType.RV)
    .map(opportunity => {
      // Uncomment and remove previous method when RV select is available
      // const vehiclesToQuote = opportunity.selectedAssetsGids.map(gid => ({
      //   asset_gid: gid
      // }));
      const vehiclesToQuote = opportunity.assets!.map(({ gid }) => ({
        asset_gid: gid
      }));

      return { vehicles: vehiclesToQuote, opportunity_id: opportunity.id };
    })
    .filter(Boolean);

  return recreationalVehiclesToQuote.length ? { recreational_vehicles: recreationalVehiclesToQuote } : {};
};

const pafToQuote = (configuredOpportunities: ConfiguredOpportunities) => {
  const pafOpportunity = configuredOpportunities.find(({ policy_type }) => policy_type === PolicyType.PAF);

  if (!pafOpportunity) {
    return {};
  }

  if (!pafOpportunity.agent_selected_coverages?.[0]?.coverages?.length) {
    return {};
  }

  return {
    new_paf: { opportunity_id: pafOpportunity.id }
  };
};

const petToQuote = (configuredOpportunities: ConfiguredOpportunities) => {
  const petOpportunity = configuredOpportunities.find(({ policy_type }) => policy_type === PolicyType.Pet);

  if (!petOpportunity) {
    return {};
  }

  return {
    pet: { opportunity_id: petOpportunity.id }
  };
};

const umbrellaToQuote = (configuredOpportunities: ConfiguredOpportunities) => {
  const umbrellaOpportunity = configuredOpportunities.find(
    opportunity => opportunity.policy_type === PolicyType.Umbrella
  );

  return umbrellaOpportunity
    ? {
        umbrella: {
          opportunity_id: umbrellaOpportunity.id,
          asset_gid: umbrellaOpportunity.selectedAssetsGids[0]!
        }
      }
    : {};
};

export const useStartConfiguredQuoting = ({ personGid, leadId }: { personGid: string; leadId: number }) => {
  const { mutateAsync: startQuoting } = useStartQuoting();

  return {
    startConfiguredQuoting: async (
      opportunities: IQuotingConfigurationOpportunity[],
      opportunitiesConfiguration: IQuotingConfigurationFormValues
    ) => {
      const stubPAFOpportunity = opportunitiesConfiguration.opportunities[STUB_PAF_OPPORTUNITY_ID];
      const stubFloodOpportunities = Object.keys(opportunitiesConfiguration.opportunities)
        .filter(opportunityId => opportunityId.startsWith(STUB_FLOOD_PREFIX))
        .filter(key => opportunitiesConfiguration.opportunities[key]!.enabled);

      if (stubFloodOpportunities.length > 0) {
        await Promise.all(
          stubFloodOpportunities.map(key =>
            createOpportunity({
              leadId,
              payload: {
                policy_type: PolicyType.Flood,
                assets_gids: opportunitiesConfiguration.opportunities[key]!.assets_gids,
                agent_selected_coverages: opportunitiesConfiguration.opportunities[key]!.agent_selected_coverages
              }
            })
          )
        );
      }

      if (stubPAFOpportunity?.enabled) {
        const { opportunity: pafOpportunity } = await createOpportunity({
          leadId,
          payload: {
            policy_type: PolicyType.PAF,
            agent_selected_coverages: stubPAFOpportunity.agent_selected_coverages
          }
        });

        const configuredOpportunities = selectConfiguredOpportunities(
          [
            ...opportunities,
            {
              ...pafOpportunity,
              latestPolicy: undefined,
              coinsureds: undefined,
              customerChoice: undefined,
              is_data_collection_completed: true
            }
          ],
          {
            opportunities: {
              ...opportunitiesConfiguration.opportunities,
              [pafOpportunity.id.toString()]: {
                enabled: true,
                agent_selected_coverages: pafOpportunity.agent_selected_coverages,
                insured_gids: undefined,
                assets_gids: undefined,
                primary_insured_gid: '',
                insurable_interest: pafOpportunity.insurable_interest
              }
            }
          }
        );
        const startQuotingRequest = {
          ...homesToQuote(configuredOpportunities),
          ...autosToQuote(configuredOpportunities),
          ...pafToQuote(configuredOpportunities),
          ...petToQuote(configuredOpportunities),
          ...motorcyclesToQuote(configuredOpportunities),
          ...recreationalVehiclesToQuote(configuredOpportunities),
          ...umbrellaToQuote(configuredOpportunities)
        };

        if (Object.values(startQuotingRequest).length) {
          await startQuoting({
            personGid,
            leadId,
            startQuotingRequest
          });

          return true;
        }
        alert({ message: 'At least one opportunity has to be selected', options: { autoClose: 3000 } }).warning();
        return false;
      } else {
        const configuredOpportunities = selectConfiguredOpportunities(opportunities, opportunitiesConfiguration);

        const startQuotingRequest = {
          ...homesToQuote(configuredOpportunities),
          ...autosToQuote(configuredOpportunities),
          ...pafToQuote(configuredOpportunities),
          ...petToQuote(configuredOpportunities),
          ...motorcyclesToQuote(configuredOpportunities),
          ...recreationalVehiclesToQuote(configuredOpportunities),
          ...umbrellaToQuote(configuredOpportunities)
        };

        if (Object.values(startQuotingRequest).length) {
          await startQuoting({
            personGid,
            leadId,
            startQuotingRequest
          });

          return true;
        }
        alert({ message: 'At least one opportunity has to be selected', options: { autoClose: 3000 } }).warning();
        return false;
      }
    }
  };
};
