/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { FileError, useDropzone } from 'react-dropzone';
import { v4 as uidGenerator } from 'uuid';

import { FileToConfirm, InvalidFile } from '../../../interfaces/IDocument';
import colors from '../../../theme/colors';
import { borderRadius } from '../../../theme/variables';
import { formatFileName } from '../../../utils/formatter';
import { AttachmentIcon } from '../icons';
import Paragraph from '../Paragraph';
import Text from '../Text';

interface IDropzone {
  onUpload: (files: FileToConfirm[]) => void;
  onReject: (invalidFiles: InvalidFile[]) => void;
  uploadOneAtTheTime?: boolean;
  inline?: boolean;
}

interface FileRejection {
  file: File;
  errors: FileError[];
}

const dropzoneStyle = css`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 5px;
  transition: opacity 0.3s;
  width: 100%;
  background: ${colors.grey5};
  border: 1px dashed ${colors.grey30};
  border-radius: ${borderRadius}px;
  height: 200px;

  &:hover {
    opacity: 0.8;
  }
`;

export const MAX_FILE_SIZE = 30 * 1024 * 1000; // 30 MB
const ALLOWED_TYPES = '.xlsx, .csv, .txt, .doc, .docx, .mp3, .mp4, .jpeg, .jpg, .png, .pdf, .xml';

const maxSizeValidator = (file: File) => {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'file-too-large',
      message: `File ${file.name} is larger than 30 MB.`
    };
  }
  return null;
};

const Dropzone = ({ onUpload, onReject, uploadOneAtTheTime, inline }: IDropzone): JSX.Element => {
  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const filesToUpload = acceptedFiles.map(file => ({
      filesize: file.size,
      uid: uidGenerator(),
      file,
      name: formatFileName(file.name)
    }));
    onUpload(filesToUpload);

    const filesToReject = fileRejections.map(({ errors, file }) => ({ file, error: errors[0]!.message }));
    onReject(filesToReject);
  };

  const { getRootProps, getInputProps } = useDropzone({
    useFsAccessApi: false,
    maxFiles: uploadOneAtTheTime ? 1 : undefined,
    validator: maxSizeValidator,
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/jpg': [],
      'image/png': [],
      'text/csv': [],
      'text/plain': [],
      'text/xml': [],
      'audio/mpeg': [],
      'video/mp4': [],
      'application/pdf': [],
      'application/msword': [],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
      'application/vnd.ms-excel': [],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [],
      'application/xml': []
    }
  });

  return (
    <div {...getRootProps()} css={inline ? '' : dropzoneStyle}>
      <input {...getInputProps()} data-testid="file-input" className="dz-hidden-input" />
      {!inline && (
        <div
          css={css`
            text-align: center;
          `}
        >
          <Text>Drop files here or click to upload</Text>
          <br />
          <Text type="small">{`(Max size 30MB; ${ALLOWED_TYPES} only)`}</Text>
        </div>
      )}
      {inline && (
        <Paragraph
          color={colors.azure50}
          bold
          customCss={css`
            display: inline-block;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          `}
        >
          <AttachmentIcon
            color={colors.azure50}
            css={css`
              margin-right: 10px;
            `}
          />
          Upload files
        </Paragraph>
      )}
    </div>
  );
};

export default Dropzone;
