import { useFormikContext } from 'formik';

import AddVehiclesModal from '../../../components/AddVehiclesForm';
import AddButton from '../../../components/core/buttons/AddButton';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../hooks';
import { VehicleOpportunity } from '../../../interfaces/IOpportunity';
import { useReplaceDataCollectionVehicles } from '../../../queries/leads/data_collection/useDataCollectionVehicles';
import { useCreateVehicle } from '../../../queries/people/personVehicles';
import { useCreateRelatedPerson } from '../../../queries/people/usePersonRelatedPeople';
import analytics from '../../../services/analytics';
import { AutoValues } from '../AutoPolicyForm';

const CreateVehicle = ({ opportunity }: { opportunity: VehicleOpportunity }) => {
  const { lead, personGid, person } = useGuidedSellingExperienceContext();
  const [addVehicles, toggleAddVehicles] = useToggle(false);

  const { mutateAsync: createVehicle, isPending: isCreatingVehicle } = useCreateVehicle();
  const { mutateAsync: replaceDataCollectionVehicles } = useReplaceDataCollectionVehicles();
  const { mutateAsync: addRelatedPerson, isPending: isAddingRelatedPerson } = useCreateRelatedPerson();
  const {
    values: { assets_gids },
    setFieldValue
  } = useFormikContext<AutoValues>();

  return (
    <>
      <AddButton onClick={toggleAddVehicles} content="Add vehicle" />
      {addVehicles && person && personGid && lead && (
        <AddVehiclesModal
          personGid={personGid}
          person={person}
          leadGid={lead.gid}
          cancelHandler={toggleAddVehicles}
          isLoading={isCreatingVehicle || isAddingRelatedPerson}
          confirmHandler={async ({
            vehicles: vehiclesValues,
            prefilledVehiclesGarageAddress,
            prefilledVehicles,
            prefilledDrivers
          }) => {
            if (vehiclesValues.length > 0 || prefilledVehicles.length > 0) {
              const manualVehiclesResponse = await Promise.all(
                vehiclesValues.map(({ address, auto: { year, make, model, submodel, vin } }) =>
                  createVehicle({
                    personGid,
                    data: { address, year, make, model, submodel, vin }
                  })
                )
              );

              const prefilledVehiclesResponse = await Promise.all(
                prefilledVehicles.map(vehicle =>
                  createVehicle({
                    personGid,
                    data: {
                      ...vehicle,
                      address: prefilledVehiclesGarageAddress,
                      data_source: 'fenris'
                    }
                  })
                )
              );

              const vehiclesResponse = manualVehiclesResponse.concat(prefilledVehiclesResponse);

              manualVehiclesResponse.forEach(({ vehicle }) =>
                analytics.track('Vehicle added', {
                  lead_gid: lead.gid,
                  person_gid: personGid,
                  place: 'guided_selling_experience',
                  source: 'manual',
                  vehicle_gid: vehicle.gid
                })
              );

              prefilledVehiclesResponse.forEach(({ vehicle }) =>
                analytics.track('Vehicle added', {
                  lead_gid: lead.gid,
                  person_gid: personGid,
                  place: 'guided_selling_experience',
                  source: 'fenris',
                  vehicle_gid: vehicle.gid
                })
              );

              const {
                data_collection: { pages }
              } = await replaceDataCollectionVehicles({
                assetsGids: [
                  ...vehiclesResponse.map(({ vehicle }) => vehicle.gid),
                  ...(opportunity.assets || []).map(v => v.gid)
                ],
                leadId: lead.id
              });

              // Find fresh version of current opportunity
              // Get new assets gids that were not present before, set them as new form value
              const dataCollectionPage = pages.find(p =>
                (p.opportunities || []).map(o => o.id).includes(opportunity.id)
              );
              if (dataCollectionPage) {
                const freshOpportunity = (dataCollectionPage.opportunities || []).find(o => o.id === opportunity.id);
                if (freshOpportunity) {
                  const newGids = (freshOpportunity.assets || [])
                    .map(({ gid }) => gid)
                    .filter(assetGid => !(opportunity.assets || []).map(({ gid }) => gid).includes(assetGid));

                  setFieldValue('assets_gids', [...assets_gids, ...newGids]);
                }
              }
            }

            if (prefilledDrivers.length) {
              await Promise.all(prefilledDrivers.map(data => addRelatedPerson({ sourcePersonGid: personGid, data })));

              analytics.track('Driver added', {
                lead_gid: lead.gid,
                person_gid: personGid,
                place: 'post_sales_experience',
                source: 'fenris'
              });
            }

            toggleAddVehicles();
          }}
        />
      )}
    </>
  );
};

export default CreateVehicle;
