import { useMutation } from '@tanstack/react-query';

import api from '../../services/api';
import { rejectEmpty } from '../../utils/object';

export enum MediaType {
  Call = 'call',
  SMS = 'sms',
  Email = 'email'
}

interface ITelephonyContactAttempt {
  phone?: string;
  email?: string;
  personGid: string;
  leadGid: string;
  mediaType: MediaType;
}

export const contactPerson = ({ phone, email, personGid, leadGid, mediaType }: ITelephonyContactAttempt) =>
  api.post('/api/frontend/contact_attempts', {
    body: rejectEmpty({
      phone,
      email,
      media_type: mediaType,
      lead_gid: leadGid,
      customer_gid: personGid
    })
  });

const useCreateContactAttempt = ({ onSuccess }: { onSuccess?: () => void }) =>
  useMutation({ mutationFn: contactPerson, onSuccess });

export default useCreateContactAttempt;
