import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { CarIcon } from '../../../components/core/icons';
import IconWithText from '../../../components/core/IconWithText';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import useNonSyncedPolicyVehicles from '../../../queries/policies/useNonSyncedPolicyVehicles';
import { spacings } from '../../../theme/variables';
import AssetDetailsPlaceholder from '../AssetDetailsPlaceholder';
import VehicleInfo from '../VehicleInfo';

const NonSyncedPolicyVehicles = ({ policy }: { policy: IMaticPolicy }) => {
  const { data: vehicles, isLoading } = useNonSyncedPolicyVehicles(policy.id);

  if (isLoading) {
    return <AssetDetailsPlaceholder />;
  }

  return (
    <>
      {Boolean(vehicles?.length) && (
        <FlexBox columnDirection>
          <FlexBox justifySpaceBetween alignItemsCenter>
            <IconWithText text={`${findPolicyTypeLabel(policy.policy_type)}`}>
              <CarIcon />
            </IconWithText>
          </FlexBox>
          <FlexBox pt={spacings.px12} mh={spacings.px24} columnDirection>
            {vehicles?.map(vehicle => <VehicleInfo vehicle={vehicle} key={vehicle.vin} />)}
          </FlexBox>
        </FlexBox>
      )}
    </>
  );
};

export default NonSyncedPolicyVehicles;
