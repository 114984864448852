import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { HelpIcon, UserIcon } from '../../components/core/icons';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import analytics from '../../services/analytics';
import { isISR } from '../../services/authInfo';
import peopleSearchNavigation, { PeopleSearchOpenedFrom } from '../PeopleSearch/navigation';
import VerticalTabs from './_components/VerticalTabs';
import UnknownPersonHeader from './header/UnknownPersonHeader';
import Layout from './Layout';
import UnknownPersonScoutingReport from './ScoutingReport/UnknownPersonScoutingReport';
import ObjectionsGuideTab from './tabs/ObjectionsGuideTab';
import UnknownPersonInfoTab from './tabs/UnknownPersonInfoTab';

const UnknownPersonPage = () => {
  const navigate = useNavigate();

  const { callLogId, personGid, leadGid, candidateGid } = useGuidedSellingExperienceContext();

  const tabContent = [
    {
      tabIcon: <UserIcon key="info" />,
      tabName: 'Info',
      content: <UnknownPersonInfoTab />,
      openedByDefault: true
    },
    {
      tabIcon: <HelpIcon key="help" />,
      tabName: 'Help',
      content: <ObjectionsGuideTab panel="rapport" />
    }
  ];

  const onSearch = (searchBy?: string, term?: string) => {
    const params = {
      call_log_id: callLogId,
      person_gid: personGid,
      lead_gid: leadGid,
      candidate_gid: candidateGid
    };

    analytics.track('Search button clicked', params);

    navigate(
      peopleSearchNavigation.fromScoutingReport({
        term,
        search_by: searchBy,
        call_log_id: callLogId
      }),
      { state: { openedFrom: PeopleSearchOpenedFrom.ScoutingReport } }
    );
  };

  return (
    <Layout
      header={<UnknownPersonHeader />}
      content={isISR() && <UnknownPersonScoutingReport />}
      tabs={<VerticalTabs tabs={tabContent} collapsedByDefault={false} />}
      onSearch={onSearch}
    />
  );
};

export default UnknownPersonPage;
