import { useQueries } from '@tanstack/react-query';

import { IOpportunity, IOpportunityPolicies } from '../../../interfaces';
import { getPersonAllPolicies } from '../../people/person_policies/usePersonAllPolicies';

export const OPPORTUNITIES_WITH_POLICIES_QUERY_KEY = 'opportunity_with_policies';
const useOpportunitiesWithPolicies = <Opportunity extends IOpportunity>({
  personGid,
  opportunities
}: {
  personGid: string | undefined;
  opportunities: Opportunity[] | undefined;
}) => {
  const data = useQueries({
    queries: (opportunities || []).map(({ id, assets, policy_type }) => ({
      queryKey: [OPPORTUNITIES_WITH_POLICIES_QUERY_KEY, personGid, id, assets?.[0]?.gid, policy_type],
      queryFn: () =>
        getPersonAllPolicies({
          personGid: personGid!,
          filters: {
            asset_gid: assets?.[0]?.gid,
            policy_types: [policy_type],
            similar_policy_types: true,
            deduplicate_by_expiration_date: true
          }
        }),
      enabled: !!personGid && !!opportunities
    }))
  });

  const opportunitiesWithPolicies: (Opportunity & IOpportunityPolicies)[] | undefined = opportunities?.map(
    (opportunity, index) => ({
      ...opportunity,
      policies: data?.[index]?.data?.person_policies,
      latestPolicy: data?.[index]?.data?.person_policies?.[0]
    })
  );

  return {
    opportunitiesWithPolicies,
    isPending: data.some(query => query.isPending),
    isFetchedAfterMount: data.every(query => query.isFetchedAfterMount),
    refetch: () => Promise.all(data.map(query => query.refetch()))
  };
};

export default useOpportunitiesWithPolicies;
