import React from 'react';
import { useParams } from 'react-router-dom';

import usePerson from '../../queries/people/usePerson';
import usePersonId from '../../queries/people/usePersonId';
import LoadedCustomer from './LoadedCustomer';

const Customer = () => {
  const { gid } = useParams();
  const personQuery = usePerson(gid);
  const { isPending: isSyncingPerson } = usePersonId(gid);

  if (!personQuery.data?.gid || isSyncingPerson) {
    return null;
  }

  return <LoadedCustomer personQuery={personQuery} />;
};

export default Customer;
