import CustomPropTypes from '../../../../utils/customPropTypes';
import PersonEditor from '../../../PersonEditor/PersonEditor';

/* @ts-expect-error TODO */
const originalPropTypes = PersonEditor.propTypes;

/* @ts-expect-error TODO */
PersonEditor.propTypes = {
  ...originalPropTypes,
  person: CustomPropTypes.PersonFromAugmentation
};

export default PersonEditor;
