/** @jsxImportSource @emotion/react */
import { Grid } from '@mui/material';
import { FieldArray } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { Translations } from '../../../constants';
import { IVehicle } from '../../../interfaces';
import { IOpportunity, OpportunityStatus } from '../../../interfaces/IOpportunity';
import { isNonLifeAncillaryPolicyType, PolicyType } from '../../../interfaces/IPolicyType';
import { generateAutoDescription } from '../../../interfaces/IVehicle';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import { updateOpportunity } from '../../../queries/leads/opportunities/useLeadOpportunities';
import colors from '../../../theme/colors';
import FlexBox from '../../core/FlexBox';
import FormModal from '../../core/FormModal';
import { SelectField } from '../../core/forms/fields';
import { ModalSize } from '../../core/Modal';
import Paragraph from '../../core/Paragraph';
import Text from '../../core/Text';

interface NewTrailingOpportunitiesModalProps {
  leadId: string | number;
  dispositionType: string;
  opportunities: IOpportunity[];
  closeModal: () => void;
  onDispositionUpdated?: () => void;
}

const NewTrailingOpportunitiesModal = ({
  leadId,
  dispositionType,
  opportunities,
  closeModal,
  onDispositionUpdated
}: NewTrailingOpportunitiesModalProps): JSX.Element => {
  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  const opportunitiesToConfirm = opportunities.filter(
    opportunity => !isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened
  );
  const opportunitiesToAutoclose = opportunities
    .filter(opportunity => isNonLifeAncillaryPolicyType(opportunity) && opportunity.is_opened)
    .map(opportunity => ({ ...opportunity, reason: opportunity.reason || 'not_interested' }));

  return (
    <FormModal
      returnFocusAfterClose={false}
      size={ModalSize.large}
      initialValues={{
        opportunities: opportunitiesToConfirm
      }}
      validationSchema={yup.object().shape({
        opportunities: yup.array().of(
          yup.object().shape({
            reason: yup.string().nullable().required('Please, fill in the field')
          })
        )
      })}
      confirmHandler={async values => {
        async function makeRequests(opportunities: IOpportunity[]) {
          for (const opportunity of opportunities) {
            await updateOpportunity({
              leadId,
              opportunityId: opportunity.id,
              params: { status: OpportunityStatus.Lost, reason: opportunity.reason }
            });
          }
        }

        return await makeRequests([...values.opportunities, ...opportunitiesToAutoclose])
          .then(() => updateDisposition({ leadId, disposition_type: dispositionType }))
          .then(() => {
            closeModal();
            onDispositionUpdated?.();
          });
      }}
      cancelHandler={() => {
        closeModal();
      }}
      confirmText="Set statuses"
      title="Set statuses for lost opportunities"
      renderForm={({ values }) => {
        return (
          <FieldArray
            name="opportunities"
            render={() => {
              return (
                <>
                  {values.opportunities.map((opportunity: IOpportunity, index: number) => {
                    const reasonFieldName = `opportunities.${index}.reason`;
                    return (
                      <Grid key={opportunity.id} container justifyContent="space-between">
                        <Grid item container xs={12}>
                          <Grid item xs={6} pt={2}>
                            <FlexBox columnDirection>
                              <Paragraph>{opportunity.title}</Paragraph>
                              <Text color={colors.grey60} type="small">
                                {opportunity.policy_type === PolicyType.Auto
                                  ? generateAutoDescription(opportunity.assets as IVehicle[])
                                  : opportunity?.assets?.[0]?.description_without_icon}
                              </Text>
                            </FlexBox>
                          </Grid>
                          <Grid item xs={6}>
                            <SelectField
                              label="Reason"
                              required
                              id={reasonFieldName}
                              name={reasonFieldName}
                              options={Translations.opportunityLostReasonOptions}
                              testId={`${opportunity.policy_type}-select-opportunity-lost-reason`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </>
              );
            }}
          />
        );
      }}
    />
  );
};

export default NewTrailingOpportunitiesModal;
