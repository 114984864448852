import * as yup from 'yup';

export const OLD_DATE = new Date('1990/12/12');
export const FUTURE_DATE = new Date('2100/12/12');
export const TO_IS_GREATER_THAN_FROM = "'To' date is greater than 'from' date";

export const isDateRangeValid = ({ from, to }: { from?: Date | string; to?: Date | string }) => {
  if (from && to) {
    return new Date(to).getTime() >= new Date(from).getTime();
  }
  return true;
};

export const filterDateValidation = yup
  .date()
  .min(OLD_DATE, 'Only dates from 1990 are supported')
  .max(FUTURE_DATE, "Date can't be greater than 2100");
