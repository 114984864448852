/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';

import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import Confetti from '../core/Confetti';
import Heading from '../core/Heading';

const HappyBirthday = () => {
  const [show, setShow] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShow(false);
      localStorage.setItem(`birthday-${new Date().getFullYear()}`, 'true');
    }, 7000);
  }, []);

  if (
    show &&
    authInfo.dateOfBirth &&
    !localStorage.getItem(`birthday-${new Date().getFullYear()}`) &&
    moment().date() === moment(authInfo.dateOfBirth).date() &&
    moment().month() === moment(authInfo.dateOfBirth).month()
  ) {
    return (
      <>
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9999999999;
          `}
        >
          <Heading type="h1" color={colors.cornflower}>
            Happy birthday {authInfo.currentUserFirstName} {authInfo.currentUserLastName}!
          </Heading>
        </div>
        <Confetti fireOnMount times={5} />
      </>
    );
  }

  return null;
};

export default HappyBirthday;
