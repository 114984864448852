import { skipToken, useMutation, useQuery } from '@tanstack/react-query';

import { IFullStorySession } from '../../interfaces';
import api from '../../services/api';

const getFullStorySessions = ({
  engagementGid,
  personGid
}: {
  engagementGid?: string;
  personGid?: string;
}): Promise<{ full_story_sessions: IFullStorySession[] }> =>
  api.get(`/api/frontend/full_story_sessions?engagement_gid=${engagementGid}&person_gid=${personGid}`);

const FULL_STORY_SESSIONS_QUERY_KEY = 'full-story-sessions';

const useFullStorySessions = ({ engagementGid, personGid }: { engagementGid?: string; personGid?: string }) =>
  useQuery({
    queryKey: [FULL_STORY_SESSIONS_QUERY_KEY, engagementGid, personGid],
    queryFn: engagementGid && personGid ? () => getFullStorySessions({ engagementGid, personGid }) : skipToken,
    select: data => data.full_story_sessions,
    throwOnError: false
  });

const createFullStorySession = ({
  engagementGid,
  sessionUrl
}: {
  engagementGid: string;
  sessionUrl: string;
}): Promise<{ full_story_session: IFullStorySession }> =>
  api.post('/api/frontend/full_story_sessions', { body: { engagement_gid: engagementGid, session_url: sessionUrl } });

export const useCreateFullStorySession = () => useMutation({ mutationFn: createFullStorySession });

export default useFullStorySessions;
