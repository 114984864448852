import { css } from '@emotion/react';
import Grid from '@mui/material/Grid';
import * as React from 'react';

import { ButtonVariant } from '../../../components/core/buttons/Button';
import BaseForm from '../../../components/core/forms/BaseForm';
import { AddressField, CheckboxField, InputField, PhoneField } from '../../../components/core/forms/fields';
import Heading from '../../../components/core/Heading';
import Text from '../../../components/core/Text';
import { API_TO_SMARTY_STREETS_MAPPING } from '../../../constants/addressForm';
import { IAddressSuggestion, IPerson, IPersonAddress, IRelatedPerson, IVehicle } from '../../../interfaces';
import { updatePerson } from '../../../queries/people/usePerson';
import { updateRelationKind } from '../../../queries/people/usePersonRelatedPeople';
import { sizes, spacings } from '../../../theme/variables';
import { createSmartyStreetsMapping, renameKeys } from '../../../utils/object';
import Header from '../_components/Header';
import StepsBar from '../_components/StepsBar';
import DriverEditor from './DriverEditor';
import { DriverFields, initialValues, validations } from './formHelpers';

const DriversPicker = ({
  person,
  leadId,
  personAddresses,
  selectedVehicles,
  relatedPeople,
  refetchDrivers,
  onBack,
  onClose,
  onSubmit
}: {
  person: IPerson;
  leadId: string | undefined;
  personAddresses: IPersonAddress[] | undefined;
  selectedVehicles: IVehicle[] | undefined;
  relatedPeople: IRelatedPerson[] | undefined;
  refetchDrivers: () => void;
  onBack: () => void;
  onClose: () => void;
  onSubmit: (drivers?: IRelatedPerson[]) => void;
}) => {
  return (
    <BaseForm
      type="fullPage"
      pt={spacings.px12}
      pb={spacings.px12}
      pl={spacings.px24}
      pr={spacings.px24}
      enableReinitialize
      cancelWithEscape
      header={<Header person={person} leadId={leadId} />}
      footer={<StepsBar activeStepOrder={2} />}
      initialValues={initialValues(person, relatedPeople)}
      validationSchema={validations(person, relatedPeople)}
      onSubmit={values => {
        const selectedDrivers = relatedPeople?.filter(relatedPerson => values[relatedPerson.gid]);

        return Promise.all([
          updatePerson({
            gid: person.gid,
            phone: values.phone,
            email: values.email,
            living_address: values.living_address,
            date_of_birth: values[`${person.gid}_${DriverFields.DateOfBirth}`],
            gender: values[`${person.gid}_${DriverFields.Gender}`],
            marital_status: values[`${person.gid}_${DriverFields.MaritalStatus}`],
            education: values[`${person.gid}_${DriverFields.Education}`],
            occupation_type: values[`${person.gid}_${DriverFields.OccupationType}`],
            occupation_since_date: values[`${person.gid}_${DriverFields.OccupationSinceDate}`],
            license_status: values[`${person.gid}_${DriverFields.LicenseStatus}`],
            license_number: values[`${person.gid}_${DriverFields.LicenseNumber}`],
            license_state: values[`${person.gid}_${DriverFields.LicenseState}`],
            age_first_licensed: values[`${person.gid}_${DriverFields.AgeFirstLicensed}`],
            primary_driven_vehicle_gid: values[`${person.gid}_${DriverFields.PrimaryDrivenVehicleGid}`]
          }),
          ...(selectedDrivers || []).map(driver =>
            updatePerson({
              gid: driver.gid,
              date_of_birth: values[`${driver.gid}_${DriverFields.DateOfBirth}`],
              gender: values[`${driver.gid}_${DriverFields.Gender}`],
              marital_status: values[`${driver.gid}_${DriverFields.MaritalStatus}`],
              education: values[`${driver.gid}_${DriverFields.Education}`],
              occupation_type: values[`${driver.gid}_${DriverFields.OccupationType}`],
              occupation_since_date: values[`${driver.gid}_${DriverFields.OccupationSinceDate}`],
              license_status: values[`${driver.gid}_${DriverFields.LicenseStatus}`],
              license_number: values[`${driver.gid}_${DriverFields.LicenseNumber}`],
              license_state: values[`${driver.gid}_${DriverFields.LicenseState}`],
              age_first_licensed: values[`${driver.gid}_${DriverFields.AgeFirstLicensed}`],
              primary_driven_vehicle_gid: values[`${driver.gid}_${DriverFields.PrimaryDrivenVehicleGid}`]
            }).then(() =>
              updateRelationKind({
                sourcePersonGid: person.gid,
                relatedPersonGid: driver.gid,
                relationKind: values[`${driver.gid}_${DriverFields.Relationship}`]
              })
            )
          )
        ])
          .then(() => refetchDrivers())
          .then(() => onSubmit(selectedDrivers || []));
      }}
      submitText="Next"
      cancelText="Back"
      cancelHandler={onBack}
      cancelVariant={ButtonVariant.Secondary}
      closeHandler={onClose}
      renderForm={({ values }) => {
        return (
          <>
            <Heading
              type="h4"
              customCss={css`
                margin-bottom: 12px;
              `}
            >
              Customer
            </Heading>
            <Grid container maxWidth={sizes.mediumFormInputWidth * 4}>
              <Grid container columnSpacing={2}>
                <Grid item xs={3}>
                  <InputField
                    required
                    copyable
                    label="Email"
                    type="email"
                    id={DriverFields.Email}
                    name={DriverFields.Email}
                  />
                </Grid>
                <Grid item xs={3}>
                  <PhoneField required copyable label="Phone" id={DriverFields.Phone} name={DriverFields.Phone} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={2}>
                <Grid item xs={6}>
                  <AddressField
                    required
                    testId="living_address"
                    label="Living address"
                    id={DriverFields.LivingAddress}
                    placeholder=""
                    defaultValue={
                      renameKeys(API_TO_SMARTY_STREETS_MAPPING, values.living_address) as IAddressSuggestion
                    }
                    defaultOptions={
                      (personAddresses?.map(address => renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)) ||
                        []) as IAddressSuggestion[]
                    }
                    fallbackNames={createSmartyStreetsMapping(`${DriverFields.LivingAddress}.`)}
                  />
                </Grid>
              </Grid>
              <DriverEditor driver={person} selectedVehicles={selectedVehicles} showRelationship={false} />
            </Grid>
            {!!relatedPeople?.length && (
              <Heading
                type="h4"
                customCss={css`
                  margin-top: 12px;
                  margin-bottom: 12px;
                `}
              >
                Drivers
              </Heading>
            )}
            {relatedPeople?.map(driver => (
              <div key={driver.gid}>
                <CheckboxField id={driver.gid} name={driver.gid} label={<Text type="large">{driver.name}</Text>} />
                {values[driver.gid] && (
                  <Grid container maxWidth={sizes.mediumFormInputWidth * 4}>
                    <DriverEditor driver={driver} selectedVehicles={selectedVehicles} showRelationship />
                  </Grid>
                )}
              </div>
            ))}
          </>
        );
      }}
    />
  );
};

export default DriversPicker;
