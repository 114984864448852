/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field } from 'formik';
import React from 'react';

import { IDisposition } from '../../../interfaces/IDisposition';
import { useLeadDispositionsOptions } from '../../../pages/Lead/LeadSummary/LeadOperations/hooks';
import useLeadAssignees from '../../../queries/leads/assignees/useLeadAssignees';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import colors from '../../../theme/colors';
import { defaultFontValues } from '../../../theme/typography';
import { ErrorCol } from '../../common';
import { Select } from '../../common/FormikComponents';
import FlexBox from '../../core/FlexBox';
import FormModal from '../../core/FormModal';
import { ModalSize } from '../../core/Modal';
import Paragraph from '../../core/Paragraph';
import Text from '../../core/Text';

interface EditDispositionProps {
  leadId: number;
  disposition: IDisposition;
  closeModal: () => void;
  onDispositionUpdated?: () => void;
}

const EditDispositionModal = ({
  leadId,
  disposition,
  closeModal,
  onDispositionUpdated
}: EditDispositionProps): JSX.Element => {
  const { data: assignees, isFetching: isFetchingAssignees } = useLeadAssignees({
    leadId,
    licensed: disposition.is_license_required
  });
  const { reasonOptions, isLoading: isLoadingDispositionsOptions } = useLeadDispositionsOptions(
    leadId,
    disposition?.disposition_type
  );

  const { mutate: updateLeadDisposition } = useUpdateLeadDisposition(() => {
    closeModal();
    onDispositionUpdated?.();
  });

  const reasonSelectOptions = reasonOptions
    .filter(option => !option.unavailable)
    .map(({ key, value }) => ({ key, value }));

  return (
    <FormModal
      returnFocusAfterClose={false}
      size={ModalSize.large}
      title="Edit disposition details"
      confirmText="Update record"
      initialValues={{
        current_assignee: disposition.assignee?.id,
        new_assignee: '',
        current_reason_code: disposition.disposition_reason,
        new_reason_code: ''
      }}
      confirmHandler={values => {
        return updateLeadDisposition({
          leadId,
          disposition_type: disposition.disposition_type,
          agent_id: values.new_assignee ? Number(values.new_assignee) : values.current_assignee,
          disposition_reason: values.new_reason_code ? values.new_reason_code : values.current_reason_code || undefined
        });
      }}
      cancelHandler={() => {
        closeModal();
      }}
      dangerModal
      confirmationInProgress={isFetchingAssignees}
      renderForm={({ errors, touched, values }) => (
        <>
          <Paragraph>
            Are you sure you want to change the record? This action will affect company&apos;s data and will be
            reflected in the activity log.
          </Paragraph>
          <Paragraph bold>Assignee</Paragraph>
          <FlexBox
            alignItemsCenter
            customCss={css`
              ${defaultFontValues}
            `}
          >
            <ErrorCol sm={4}>
              <Text bold uppercase color={colors.grey30} type="small">
                From
              </Text>
              <Field
                css={css`
                  margin-top: 4px;
                `}
                disabled
                small
                name="current_assignee"
                component={Select}
                options={[disposition.assignee]}
                labelName="name"
                valueName="id"
              />
            </ErrorCol>
            <Text>&#8594;</Text>
            <ErrorCol
              sm={4}
              name="new_assignee"
              error={errors['new_assignee']}
              when={errors['new_assignee'] && touched['new_assignee']}
            >
              <Text bold uppercase color={colors.grey30} type="small">
                To
              </Text>
              <Field
                css={css`
                  margin-top: 4px;
                `}
                small
                name="new_assignee"
                component={Select}
                options={assignees}
                placeholder="Please select an assignee"
                labelName="name"
                valueName="id"
              />
            </ErrorCol>
          </FlexBox>

          <Paragraph bold>Reason code</Paragraph>
          <FlexBox
            alignItemsCenter
            customCss={css`
              ${defaultFontValues}
            `}
          >
            <ErrorCol sm={4}>
              <Text bold uppercase color={colors.grey30} type="small">
                From
              </Text>
              <Field
                css={css`
                  margin-top: 4px;
                `}
                disabled
                small
                name="current_reason_code"
                component={Select}
                options={reasonOptions.filter(reason => reason.key === values.current_reason_code)}
              />
            </ErrorCol>
            <Text>&#8594;</Text>
            <ErrorCol
              sm={4}
              name="new_reason_code"
              error={errors['new_reason_code']}
              when={errors['new_reason_code'] && touched['new_reason_code']}
            >
              <Text bold uppercase color={colors.grey30} type="small">
                To
              </Text>
              <Field
                css={css`
                  margin-top: 4px;
                `}
                small
                name="new_reason_code"
                component={Select}
                options={reasonSelectOptions}
                placeholder="Please select a reason code"
                disabled={isLoadingDispositionsOptions}
              />
            </ErrorCol>
          </FlexBox>
        </>
      )}
    />
  );
};

export default EditDispositionModal;
