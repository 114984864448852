import * as Sentry from '@sentry/react';

import { Translations } from '../../../constants';
import { useConstant } from '../../../hooks';
import { ILead, IMaticPolicy } from '../../../interfaces';
import { findPolicyTypeLabel } from '../../../interfaces/IPolicyType';
import { BusinessTypes } from '../../../interfaces/ISourceDimensions';
import useCreateTask from '../../../queries/people/person_tasks/useCreateTask';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import useCalendar from '../../../queries/scheduled_calls/useCalendar';
import authInfo from '../../../services/authInfo';
import { dateFormatter, INTERNAL_DATE_FORMAT } from '../../../utils/formatter';
import { calculateDueTime } from '../_helpers';

const buildTaskDescription = ({
  loanNumber,
  policy
}: {
  loanNumber: string | null | undefined;
  policy: IMaticPolicy;
}) => {
  const paymentMethod = Translations.paymentMethod(policy.payment_method);

  return loanNumber
    ? `Loan ${loanNumber}. Policy type: ${findPolicyTypeLabel(policy.policy_type)}. ` +
        `New policy carrier is ${policy.carrier.name} effective ${dateFormatter(policy.effective_date)}. ` +
        `Customer chose ${paymentMethod} as a payment for new policy`
    : `You have selected ${paymentMethod} payment, but the loan information is missing`;
};

const useCreateFaxTask = ({
  lead,
  assetGid,
  personGid
}: {
  lead: ILead | undefined;
  assetGid: string | undefined;
  personGid: string;
}) => {
  const date = useConstant(() => dateFormatter(new Date(), INTERNAL_DATE_FORMAT));
  const { data: loans } = usePersonLoans({ assetGid, personGid });
  // BE returns it in created_at reverse order
  const lastLoan = loans && loans[0];

  const { data: calendarData } = useCalendar({
    date,
    userTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    spotsDuration: 30
  });

  const { mutateAsync: createTask } = useCreateTask({});

  return {
    createFaxTask: ({ policy }: { policy: IMaticPolicy }) => {
      if (
        lead &&
        lead.source_dimensions.partner === 'new_american_funding' &&
        lead.source_dimensions.business_type === BusinessTypes.HomeOrigination
      ) {
        return Promise.resolve();
      }

      const due_time = calculateDueTime(calendarData);
      const description = buildTaskDescription({ loanNumber: lastLoan?.loan_number, policy });

      return createTask({
        personGid,
        data: {
          policy_id: policy.id,
          kind: 'fax',
          agent_id: authInfo.currentUserId!,
          lead_id: lead?.id,
          completed: false,
          due_date: date,
          due_time,
          priority: 'normal',
          description
        }
      }).catch(error => {
        Sentry.captureException(error);
      });
    }
  };
};
export default useCreateFaxTask;
