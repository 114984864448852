import React from 'react';

import alert from '../../../../components/core/Alert';
import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ArrowLeft2Icon } from '../../../../components/core/icons';
import SearchInput from '../../../../components/core/inputs/Search';
import Paragraph from '../../../../components/core/Paragraph';
import { Translations } from '../../../../constants';
import useCreateContactAttempt, { MediaType } from '../../../../queries/contact_attempts/useCreateContactAttempt';
import useLastLeadId from '../../../../queries/people/useLastLead';
import usePerson from '../../../../queries/people/usePerson';
import usePersonRelatedPeople from '../../../../queries/people/usePersonRelatedPeople';
import usePhoneAddressBookEntries from '../../../../queries/phone_address_book_entries/usePhoneAddressBookEntries';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import TabSkeleton from '../_components/TabSkeleton';
import ContactBookEntry from './ContactBookEntry';

interface ContactBookTabProps {
  personGid: string | undefined;
  onClose: () => void;
}

interface IContactBookEntry {
  phone: string;
  title: string;
  description?: string;
  tag?: string;
}

const stripFormatting = (phone: string) => phone.replace(/[^a-zA-Z0-9]/g, '');

const filterContactBookEntries = (contactBookEntries: IContactBookEntry[], searchTerm: string) =>
  contactBookEntries.filter(
    entry =>
      stripFormatting(entry.title.toLowerCase()).includes(stripFormatting(searchTerm.toLowerCase())) ||
      stripFormatting(entry.phone).includes(stripFormatting(searchTerm))
  );

const ContactBookTab = ({ personGid, onClose }: ContactBookTabProps) => {
  const { data: person, isFetched: isPersonFetched } = usePerson(personGid);
  const { data: relatedPeople, isFetched: isRelatedPeopleFetched } = usePersonRelatedPeople(personGid);
  const { data: lastLead, isFetched: isLastLeadFetched } = useLastLeadId(personGid);
  const { data: phoneAddressBookEntries, isFetched: isPhoneAddressBookFetched } = usePhoneAddressBookEntries();

  const isFetched = isPersonFetched && isRelatedPeopleFetched && isLastLeadFetched && isPhoneAddressBookFetched;

  const { mutateAsync: createContactAttempt, isPending } = useCreateContactAttempt({
    onSuccess: () => alert({ message: 'Request successfully passed to Twilio' }).success()
  });
  const [selectedPhone, setSelectedPhone] = React.useState<string>('');
  const [searchTerm, setSearchTerm] = React.useState<string>('');

  const personName = `${person?.first_name} ${person?.last_name}`;

  const customerPhones = [
    {
      phone: person?.phone,
      title: personName,
      description: 'Primary phone',
      tag: 'Customer'
    },
    {
      phone: person?.secondary_phone,
      title: personName,
      description: 'Secondary phone',
      tag: 'Customer'
    },
    {
      phone: person?.business_phone,
      title: personName,
      description: 'Business phone',
      tag: 'Customer'
    }
  ].filter(({ phone }) => phone) as IContactBookEntry[];

  const relatedPeoplePhones = relatedPeople
    ?.map(({ first_name, last_name, phone, secondary_phone, business_phone, kind }) => [
      {
        phone,
        title: `${first_name} ${last_name}`,
        description: 'Primary phone',
        tag: Translations.relationKind(kind)
      },
      {
        phone: secondary_phone,
        title: `${first_name} ${last_name}`,
        description: 'Secondary phone',
        tag: Translations.relationKind(kind)
      },
      {
        phone: business_phone,
        title: `${first_name} ${last_name}`,
        description: 'Business phone',
        tag: Translations.relationKind(kind)
      }
    ])
    ?.flat()
    ?.filter(({ phone }) => phone) as IContactBookEntry[] | undefined;

  const globalPhones = phoneAddressBookEntries?.map(({ name, phone }) => ({
    phone,
    title: name
  })) as IContactBookEntry[];

  const contactBookEntries = [...customerPhones, ...(relatedPeoplePhones || []), ...(globalPhones || [])];

  const filteredContactBookEntries = filterContactBookEntries(contactBookEntries, searchTerm);

  return (
    <>
      <VerticalTabHeader showClose={false}>
        <ButtonWithoutBorder pv={spacings.px12} onClick={() => onClose()}>
          <FlexBox alignItemsCenter gap={spacings.px12}>
            <ArrowLeft2Icon width={spacings.px20} height={spacings.px20} color={colors.black} />
            <Paragraph type="large">Contact book</Paragraph>
          </FlexBox>
        </ButtonWithoutBorder>
      </VerticalTabHeader>
      <VerticalTabContent ph={spacings.px0} pv={spacings.px0}>
        {isFetched ? (
          <>
            <Container ph={spacings.px12} pv={spacings.px8}>
              <SearchInput
                placeholder="Search"
                onChange={event => setSearchTerm(event.target.value)}
                clearable
                value={searchTerm}
                onClear={() => setSearchTerm('')}
              />
            </Container>
            <FlexBox columnDirection gap={spacings.px12}>
              {filteredContactBookEntries.map(({ phone, title, description, tag }) => (
                <ContactBookEntry
                  key={phone}
                  title={title}
                  phone={phone}
                  description={description}
                  tag={tag}
                  disabled={isPending}
                  loading={selectedPhone === phone}
                  onCall={async () => {
                    setSelectedPhone(phone);

                    try {
                      if (lastLead) {
                        await createContactAttempt({
                          phone,
                          personGid: person!.gid,
                          leadGid: lastLead.gid,
                          mediaType: MediaType.Call
                        });
                      } else {
                        alert({ message: 'No lead associated with this customer' }).error();
                      }
                    } finally {
                      setSelectedPhone('');
                    }
                  }}
                />
              ))}
            </FlexBox>
          </>
        ) : (
          <TabSkeleton ph={spacings.px12} pb={spacings.px12} />
        )}
      </VerticalTabContent>
    </>
  );
};

export default ContactBookTab;
