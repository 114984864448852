import { AssetType, IPersonAsset, OriginAssetType } from './IPersonAsset';

export enum PolicyType {
  Home = 'home',
  HomeWarranty = 'home_warranty',
  Condo = 'condo',
  Fire = 'fire',
  Renters = 'renters',
  ManufacturedOrMobileHome = 'manufactured_or_mobile_home',

  Auto = 'auto',
  Moto = 'moto',
  RV = 'rv',
  Boat = 'boat',

  Flood = 'flood',
  Earthquake = 'earthquake',
  WindOnly = 'wind_only',
  Umbrella = 'umbrella',

  PAF = 'paf',
  TermLife = 'term_life',
  WholeLife = 'whole_life',
  ADND = 'accidental_death_and_dismemberment',
  Pet = 'pet'
}

export enum InsuranceCategory {
  PersonalLines = 'personal_lines',
  Life = 'life'
}

export enum Requirement {
  Essential = 'essential',
  NonEssential = 'non_essential'
}

export enum InsurableInterest {
  RealProperty = 'real_property',
  Vehicle = 'vehicle',
  PersonalBelongings = 'personal_belongings',
  Life = 'life'
}

const PERIOD_YEAR = 12;

const type = ({
  key,
  name,
  requirement,
  insurable_interest,
  with_primary_coverage,
  possible_origination,
  possible_terms,
  is_ancillary,
  category
}: {
  key: PolicyType;
  name: string;
  requirement: Requirement;
  insurable_interest: InsurableInterest | null;
  with_primary_coverage: boolean;
  possible_origination: boolean;
  possible_terms: number[];
  is_ancillary: boolean;
  category: InsuranceCategory;
}) =>
  ({
    key,
    name,
    requirement,
    insurable_interest,
    with_primary_coverage,
    possible_origination,
    possible_terms,
    is_ancillary,
    with_variable_period: possible_terms.length > 1,
    category
  }) as const;

export const PolicyTypes = [
  type({
    key: PolicyType.Home,
    name: 'Home',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: true,
    possible_origination: true,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: false,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Condo,
    name: 'Condo',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: true,
    possible_origination: true,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: false,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Fire,
    name: 'Dwelling/Fire',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: true,
    possible_origination: true,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: false,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Renters,
    name: 'Renters',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: false,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.ManufacturedOrMobileHome,
    name: 'Mobile / manufactured home',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: true,
    possible_origination: true,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: false,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Auto,
    name: 'Auto',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.Vehicle,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR / 2, PERIOD_YEAR],
    is_ancillary: false,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Moto,
    name: 'Motorcycle',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.Vehicle,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR / 2, PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.RV,
    name: 'RV',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.Vehicle,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR / 2, PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Boat,
    name: 'Boat',
    requirement: Requirement.Essential,
    insurable_interest: InsurableInterest.Vehicle,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),

  type({
    key: PolicyType.Flood,
    name: 'Flood',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: true,
    possible_origination: true,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Earthquake,
    name: 'Earthquake',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: true,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.WindOnly,
    name: 'Wind only',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: true,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Umbrella,
    name: 'Umbrella',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.HomeWarranty,
    name: 'Home Warranty',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.RealProperty,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.PAF,
    name: 'PAF',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.PersonalBelongings,
    with_primary_coverage: true,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.Pet,
    name: 'Pet',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.Life,
    with_primary_coverage: false,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.PersonalLines
  }),
  type({
    key: PolicyType.TermLife,
    name: 'Term life',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.Life,
    with_primary_coverage: true,
    possible_origination: false,
    possible_terms: [
      5 * PERIOD_YEAR,
      10 * PERIOD_YEAR,
      15 * PERIOD_YEAR,
      20 * PERIOD_YEAR,
      25 * PERIOD_YEAR,
      30 * PERIOD_YEAR
    ],
    is_ancillary: true,
    category: InsuranceCategory.Life
  }),
  type({
    key: PolicyType.ADND,
    name: 'AD&D',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.Life,
    with_primary_coverage: true,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.Life
  }),
  type({
    key: PolicyType.WholeLife,
    name: 'Whole life',
    requirement: Requirement.NonEssential,
    insurable_interest: InsurableInterest.Life,
    with_primary_coverage: true,
    possible_origination: false,
    possible_terms: [PERIOD_YEAR],
    is_ancillary: true,
    category: InsuranceCategory.Life
  })
] as const;

export const findPolicyType = (policyType: PolicyType | string) => PolicyTypes.find(({ key }) => key === policyType);

export const isRequirementEssential = (policyType: PolicyType) =>
  findPolicyType(policyType)?.requirement === Requirement.Essential;

export const isRequirementNonEssential = (policyType: PolicyType) =>
  findPolicyType(policyType)?.requirement === Requirement.NonEssential;

export const isInsurableInterestRealProperty = (policyType: PolicyType) =>
  findPolicyType(policyType)?.insurable_interest === InsurableInterest.RealProperty;

export const isInsurableInterestVehicle = (policyType: PolicyType) =>
  findPolicyType(policyType)?.insurable_interest === InsurableInterest.Vehicle;

export const isInsurableInterestLife = (policyType: PolicyType) =>
  findPolicyType(policyType)?.insurable_interest === InsurableInterest.Life;

export const isAncillary = (policyType: PolicyType) => findPolicyType(policyType)?.is_ancillary;

const assetTypeForPolicyType = (policyType: PolicyType) => {
  if (isInsurableInterestRealProperty(policyType)) {
    return AssetType.Home;
  }

  if (isInsurableInterestVehicle(policyType)) {
    return AssetType.Auto;
  }
};

export const originAssetTypeForPolicyType = (policyType: PolicyType) => {
  if (isInsurableInterestRealProperty(policyType)) {
    return OriginAssetType.Home;
  }

  if (isInsurableInterestVehicle(policyType)) {
    return OriginAssetType.Vehicle;
  }
};

export const assetsForPolicyType = (policyType: PolicyType, allAssets: IPersonAsset[]) => {
  if (isInsurableInterestRealProperty(policyType)) {
    return allAssets.filter(asset => asset?.asset_name === AssetType.Home);
  }

  if (isInsurableInterestVehicle(policyType)) {
    return allAssets.filter(asset => asset?.asset_name === AssetType.Auto);
  }

  return [];
};

export const doesPolicyTypeRequireAsset = (policyType: PolicyType) => !!assetTypeForPolicyType(policyType);

export const isOnlyOneAssetSupported = (policyType: PolicyType) => isInsurableInterestRealProperty(policyType);

export const areManyAssetsSupported = (policyType: PolicyType) => isInsurableInterestVehicle(policyType);

export const doesPolicyTypeHavePrimaryCoverage = (policyType: PolicyType) =>
  findPolicyType(policyType)?.with_primary_coverage;

export const possiblePolicyTerms = (policyType: PolicyType) =>
  findPolicyType(policyType)?.possible_terms || [PERIOD_YEAR];

export const findPolicyTypeLabel = (policyType: PolicyType | null | undefined) =>
  policyType ? findPolicyType(policyType)?.name || '' : '';

export const doesPolicyTypeSupportAgentOfRecord = (policyType: PolicyType) => policyType !== PolicyType.TermLife;

export const doesPolicyTypeSupportNewClosing = (policyType: PolicyType): boolean =>
  isInsurableInterestRealProperty(policyType) && isRequirementEssential(policyType);

export const arePolicyTypesSimilar = (left: PolicyType, right: PolicyType) =>
  left === right ||
  (isRequirementEssential(left) &&
    isInsurableInterestRealProperty(left) &&
    isRequirementEssential(right) &&
    isInsurableInterestRealProperty(right)) ||
  (isInsurableInterestVehicle(left) && isInsurableInterestVehicle(right));

export const isNonLifeAncillaryPolicyType = ({ policy_type }: { policy_type: PolicyType }) => {
  const policyType = findPolicyType(policy_type);
  if (!policyType) {
    return false;
  }

  return policyType.is_ancillary && policyType.category !== InsuranceCategory.Life;
};

export const policyTypesSimilarTo = ({ policy_type }: { policy_type: PolicyType }) =>
  PolicyTypes.filter(({ key }) => arePolicyTypesSimilar(key, policy_type));

export const policyTypesForCategory = ({ insuranceCategory }: { insuranceCategory: InsuranceCategory }) =>
  PolicyTypes.filter(({ category }) => insuranceCategory === category).map(({ key }) => key);

export const PL_DEFAULT_OPTIONS = [
  {
    value: PolicyType.Home,
    label: findPolicyTypeLabel(PolicyType.Home)
  },
  {
    value: PolicyType.Auto,
    label: findPolicyTypeLabel(PolicyType.Auto)
  },
  {
    value: PolicyType.Fire,
    label: findPolicyTypeLabel(PolicyType.Fire)
  },
  {
    value: PolicyType.PAF,
    label: findPolicyTypeLabel(PolicyType.PAF)
  },
  {
    value: PolicyType.Umbrella,
    label: findPolicyTypeLabel(PolicyType.Umbrella)
  },
  {
    value: PolicyType.Condo,
    label: findPolicyTypeLabel(PolicyType.Condo)
  }
];

export const LIFE_DEFAULT_OPTIONS = [
  {
    value: PolicyType.TermLife,
    label: findPolicyTypeLabel(PolicyType.TermLife)
  },
  {
    value: PolicyType.ADND,
    label: findPolicyTypeLabel(PolicyType.ADND)
  }
];
