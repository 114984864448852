import { useQuery } from '@tanstack/react-query';

import { IncidentType } from '../../interfaces/IIncidentType';
import api from '../../services/api';

const getClaimTypes = (): Promise<IncidentType[]> => api.get('/api/frontend/claim_types');

const useClaimTypes = () =>
  useQuery({ queryKey: ['claim_types'], queryFn: () => getClaimTypes(), staleTime: Infinity });

export default useClaimTypes;
