import { useMutation } from '@tanstack/react-query';

import api from '../../services/api';

interface QuickReplyPayload {
  personGid: string;
  proposalUrl: string;
  personFirstName: string;
}

const createQuickReply = ({ personGid, proposalUrl, personFirstName }: QuickReplyPayload) =>
  api.post(`/api/frontend/people/${personGid}/omni_channel_interactions/quick_replies`, {
    body: { context: { proposal_url: proposalUrl, person: { first_name: personFirstName } } }
  });

export const useCreateQuickReply = () => useMutation({ mutationFn: createQuickReply });
