/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { QuestionVerificationStatus } from '../../interfaces/IWorkflow';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { CheckCircleIcon, WarningIcon } from './icons';

interface QuestionVerificationStatusIconProps extends React.SVGProps<SVGSVGElement> {
  verificationStatus: QuestionVerificationStatus;
  disabled?: boolean;
}

export const QUESTION_VERIFICATION_STATUS_TOOLTIP = 'question-verification-status-tooltip';

const QuestionVerificationStatusIcon = ({
  verificationStatus,
  disabled = false,
  ...other
}: QuestionVerificationStatusIconProps) => {
  switch (verificationStatus) {
    case QuestionVerificationStatus.Verified: {
      const props = { color: disabled ? colors.grey30 : colors.statusGreen, ...other };

      return (
        <CheckCircleIcon
          css={css`
            width: ${spacings.px16}px;
            min-width: ${spacings.px16}px;
            height: ${spacings.px16}px;
          `}
          data-for={QUESTION_VERIFICATION_STATUS_TOOLTIP}
          data-tip="Verified"
          {...props}
        />
      );
    }
    case QuestionVerificationStatus.NeedsVerification: {
      const props = { color: disabled ? colors.grey30 : colors.statusOrange, ...other };

      return (
        <WarningIcon
          css={css`
            width: ${spacings.px16}px;
            min-width: ${spacings.px16}px;
            height: ${spacings.px16}px;
          `}
          data-for={QUESTION_VERIFICATION_STATUS_TOOLTIP}
          data-tip="Need verification"
          {...props}
        />
      );
    }
    default:
      return null;
  }
};

export default QuestionVerificationStatusIcon;
