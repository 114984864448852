/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';

import googleMapIcon from '../../../assets/img/icons/g-maps.svg';
import redfinIcon from '../../../assets/img/icons/redfin.svg';
import zillowIcon from '../../../assets/img/icons/zillow.svg';
import {
  usePersonHomePanoramaView,
  usePersonHomeRedfinUrl,
  usePersonHomeZillowUrl
} from '../../../queries/people/usePersonHomes';
import { Spacings, spacings } from '../../../theme/variables';
import alert from '../../core/Alert';
import ButtonWithoutBorder from '../../core/buttons/ButtonWithoutBorder';
import FlexBox from '../../core/FlexBox';

interface HomeLinksProps extends Spacings {
  personGid: string;
  homeGid: string;
  customCss?: SerializedStyles;
}

const openZillowLink = (link?: string | null) => {
  if (link?.includes('http')) {
    window.open(link, '_blank', 'noopener,noreferrer');
  } else {
    alert({ message: 'Zillow link cannot be found' }).warning();
  }
};

const openRedfinLink = (link?: string | null) => {
  if (link?.includes('http')) {
    window.open(link, '_blank', 'noopener,noreferrer');
  } else {
    alert({ message: 'Redfin link cannot be found' }).warning();
  }
};

const openGoogleLink = (link?: string | null) => {
  if (link?.includes('http')) {
    window.open(link, '_blank', 'noopener,noreferrer');
  } else {
    alert({ message: 'Google Street panorama view cannot be found' }).warning();
  }
};

const HomeLinks = ({ personGid, homeGid, customCss, ...rest }: HomeLinksProps): JSX.Element => {
  const { refetch: fetchZillowLink, isFetching: isFetchingZillowUrl } = usePersonHomeZillowUrl({
    personGid,
    homeGid,
    fetchLink: false
  });
  const { refetch: fetchGoogleLink, isFetching: isFetchingGoogleLink } = usePersonHomePanoramaView({
    personGid,
    homeGid,
    fetchLink: false
  });
  const { refetch: fetchRedfinLink, isFetching: isFetchingRedfinUrl } = usePersonHomeRedfinUrl({
    personGid,
    homeGid,
    fetchLink: false
  });

  return (
    <FlexBox {...rest} customCss={customCss} data-testid="home-links-component">
      <ButtonWithoutBorder
        loading={isFetchingGoogleLink}
        customCss={css`
          width: ${spacings.px32}px;
          height: ${spacings.px32}px;
          &:hover {
            opacity: 0.8;
          }
        `}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          fetchGoogleLink().then(({ data: panoramaView }) => {
            openGoogleLink(panoramaView?.panorama_view_url);
          });
        }}
      >
        <img
          src={googleMapIcon}
          alt="GMap"
          css={css`
            min-width: ${spacings.px20}px;
          `}
        />
      </ButtonWithoutBorder>
      <ButtonWithoutBorder
        loading={isFetchingZillowUrl}
        customCss={css`
          width: ${spacings.px32}px;
          height: ${spacings.px32}px;
          &:hover {
            opacity: 0.8;
          }
        `}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          fetchZillowLink().then(({ data: zillowLink }) => openZillowLink(zillowLink));
        }}
      >
        <img
          src={zillowIcon}
          alt="Zillow"
          css={css`
            min-width: ${spacings.px20}px;
          `}
        />
      </ButtonWithoutBorder>
      <ButtonWithoutBorder
        loading={isFetchingRedfinUrl}
        customCss={css`
          width: ${spacings.px32}px;
          height: ${spacings.px32}px;
          &:hover {
            opacity: 0.8;
          }
        `}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          fetchRedfinLink().then(({ data: redfinLink }) => openRedfinLink(redfinLink));
        }}
      >
        <img
          src={redfinIcon}
          alt="redfin"
          css={css`
            min-width: ${spacings.px20}px;
          `}
        />
      </ButtonWithoutBorder>
    </FlexBox>
  );
};

export default HomeLinks;
