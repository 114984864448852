import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { IPerson, IVehicle } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface IPrefilledDriver extends IPerson {
  gid: never;
}

export interface IPrefilledVehicle extends IVehicle {
  gid: never;
}

export interface IPrefilledVehiclesAndDrivers {
  drivers: IPrefilledDriver[];
  vehicles: IPrefilledVehicle[];
}

export const prefillVehiclesAndDrivers = ({
  personGid,
  removeDuplicates
}: {
  personGid: string;
  removeDuplicates: boolean;
}): Promise<IPrefilledVehiclesAndDrivers> =>
  api.get(
    `/api/frontend/people/${personGid}/fenris_vehicles_data?${toQueryParams({
      remove_duplicates: removeDuplicates
    })}`
  );

const usePrefillVehiclesAndDrivers = (
  options?: Omit<
    UseMutationOptions<
      IPrefilledVehiclesAndDrivers,
      unknown,
      { personGid: string; removeDuplicates: boolean | undefined },
      unknown
    >,
    'mutationFn'
  >
) => useMutation({ mutationFn: prefillVehiclesAndDrivers, ...options });

export default usePrefillVehiclesAndDrivers;
