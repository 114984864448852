import { IAnswers, IQuestion, QuestionType } from '../../../interfaces/IWorkflow';
import { convertNullishToEmpty } from '../../../utils/objectUtils';

const ensureArrayAnswer = (answer: unknown): any[] => {
  if (answer) {
    return [answer];
  }

  return [];
};

export const initialValueForQuestion = ({ key, type }: IQuestion, answers?: IAnswers): any => {
  const existingAnswer = answers?.[key];

  switch (type) {
    case QuestionType.Checkbox:
      return existingAnswer || false;
    case QuestionType.Address:
      return convertNullishToEmpty(existingAnswer) ?? {};
    case QuestionType.MultiSelect:
      return Array.isArray(existingAnswer) ? existingAnswer : ensureArrayAnswer(existingAnswer);
    case QuestionType.Select:
      return existingAnswer ?? '';
    case QuestionType.Date:
    case QuestionType.Text:
    case QuestionType.Number:
    case QuestionType.Radio:
    case QuestionType.Phone:
    case QuestionType.Email:
    case QuestionType.Slider:
    default:
      return existingAnswer ?? '';
  }
};
