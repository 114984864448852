import React from 'react';

import { isLeadDataEditingForbidden } from '../../../components/DispositionsModals/dispositionsHelper';
import TablePlaceholder from '../../../components/TablePlaceholder';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import CustomerLoans from '../../Customer/CustomerLoans';

const LeadLoans = ({ personGid, leadId }: { personGid: string; leadId: number }) => {
  const { data: leadDispositions, isLoading: isLoadingDispositions } = useLeadDispositions(leadId);

  const createLoanEnabled = !isLeadDataEditingForbidden(leadDispositions?.current_disposition?.disposition_type);

  if (isLoadingDispositions) {
    return <TablePlaceholder testId="lead-loans-loader" />;
  }

  return <CustomerLoans personGid={personGid} leadId={leadId} createLoanEnabled={createLoanEnabled} />;
};

export default LeadLoans;
