import { useQuery } from '@tanstack/react-query';

import { IPaperTrailVersion } from '../../../interfaces/IPaperTrailVersion';
import api from '../../../services/api';

const useLeadBusinessTypeVersions = ({ leadGid }: { leadGid: string }) =>
  useQuery({
    queryKey: ['lead_business_type_versions', leadGid],
    queryFn: (): Promise<{ paper_trail_versions: IPaperTrailVersion[] }> =>
      api.get(`/api/frontend/leads/${leadGid}/business_type/versions`),
    select: data => data.paper_trail_versions
  });

export default useLeadBusinessTypeVersions;
