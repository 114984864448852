/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../components/core/buttons/Button';
import DropDown, { DropDownPosition } from '../../components/core/buttons/DropDown';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import CancelTrailingScheduledCallModal from '../../components/DispositionsModals/CancelTrailingScheduledCallModal';
// eslint-disable-next-line max-len
import NewTrailingOpportunitiesModal from '../../components/DispositionsModals/TrailingOpportunitiesModal/NewTrailingOpportunitiesModal';
import featureFlags from '../../constants/featureFlags';
import { DispositionType } from '../../interfaces/IDisposition';
import { OpportunityStatus } from '../../interfaces/IOpportunity';
import { ScheduledInteractionFlow } from '../../interfaces/IScheduledCall';
import { UserRoleCategory } from '../../interfaces/IUser';
import useLeadOpportunities from '../../queries/leads/opportunities/useLeadOpportunities';
import { usePersonScheduledInteractions } from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { useCurrentDispositionSubscriptionContext } from '../GuidedSellingExperience/_hooks';
import { GuidedSellingPathnames } from '../GuidedSellingExperience/navigation';
import { findUncompletedCall } from '../ScheduleCall/helpers';
import Quotes from './Quotes';

const GuidedQuotingForm = ({
  onConfigureQuoting,
  personGid,
  leadGid,
  leadId
}: {
  onConfigureQuoting: () => void;
  personGid: string;
  leadGid: string | undefined;
  leadId: number | undefined;
}) => {
  const navigate = useNavigate();
  const { search } = useLocation();

  const [trailingOpportunitiesModalOpened, setTrailingOpportunitiesModalOpened] = React.useState(false);

  const [cancelCallModalOpened, setCancelCallModalOpened] = React.useState<boolean>(false);

  const { data: opportunities, refetch: refetchOpportunities } = useLeadOpportunities({ leadId });
  const { data: scheduledCalls } = usePersonScheduledInteractions(personGid, [
    ScheduledInteractionFlow.Calendly,
    ScheduledInteractionFlow.CalendlyFollowUp,
    ScheduledInteractionFlow.ScheduledCall,
    ScheduledInteractionFlow.ScheduledCallIsrs,
    ScheduledInteractionFlow.ScheduledCallV2,
    ScheduledInteractionFlow.ScheduledCallEmail,
    ScheduledInteractionFlow.ScheduledByRecapture
  ]);
  const uncompletedCall = personGid && findUncompletedCall(scheduledCalls);

  const { currentDisposition, isUpdatingDisposition } = useCurrentDispositionSubscriptionContext();
  const isLoading = !opportunities || isUpdatingDisposition;
  const disableFinalizeDropdown =
    currentDisposition?.assignee?.id !== authInfo.currentUserId && authInfo.roleCategory === UserRoleCategory.Agent;
  const atLeastOneOpportunitySold = !!opportunities?.find(opp => opp.status === OpportunityStatus.Sold);
  const showFinalizeButton =
    currentDisposition &&
    (currentDisposition.disposition_type === DispositionType.Pipeline ||
      currentDisposition.disposition_type === DispositionType.Qualified);

  return (
    <>
      <Container
        customCss={css`
          height: 100%;
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr auto;
        `}
      >
        <FlexBox
          p={spacings.px24}
          customCss={css`
            overflow: auto;
          `}
        >
          <Quotes leadId={leadId} leadGid={leadGid} personGid={personGid} onGetQuotes={() => onConfigureQuoting()} />
        </FlexBox>
        <FlexBox
          justifyRight
          p={spacings.px24}
          gap={spacings.px12}
          customCss={css`
            border-top: 1px solid ${colors.grey10};
          `}
        >
          {showFinalizeButton && (
            <>
              {atLeastOneOpportunitySold ? (
                <Button
                  customCss={css`
                    min-width: 150px;
                  `}
                  onClick={() => navigate(`${GuidedSellingPathnames.Finalize}${search}`)}
                >
                  Finalize
                </Button>
              ) : (
                <DropDown
                  options={[
                    { label: 'At least one sold', value: DispositionType.PolicySold },
                    !featureFlags.cstGuidedSellingExperience && { label: 'All lost', value: DispositionType.Lost }
                  ].filter(Boolean)}
                  disabled={disableFinalizeDropdown}
                  loading={isLoading}
                  onSelected={selected => {
                    if (selected.value === DispositionType.PolicySold) {
                      return navigate(`${GuidedSellingPathnames.Finalize}${search}`);
                    } else {
                      setTrailingOpportunitiesModalOpened(true);
                    }
                  }}
                  customButtonCss={css`
                    min-width: 150px;
                  `}
                  position={DropDownPosition.Top}
                >
                  Finalize
                </DropDown>
              )}
            </>
          )}
          {featureFlags.proposalBuilderPage && (
            <Button onClick={() => navigate(`${GuidedSellingPathnames.Proposal}${search}`)}>Build proposal</Button>
          )}
        </FlexBox>
      </Container>
      {trailingOpportunitiesModalOpened && opportunities && leadId && (
        <NewTrailingOpportunitiesModal
          leadId={leadId}
          opportunities={opportunities}
          dispositionType={DispositionType.Lost}
          closeModal={() => {
            setTrailingOpportunitiesModalOpened(false);
          }}
          onDispositionUpdated={() => {
            refetchOpportunities().then(() => {
              uncompletedCall && setCancelCallModalOpened(true);

              navigate(`${GuidedSellingPathnames.Finalize}${search}`);
            });
          }}
        />
      )}
      {uncompletedCall && cancelCallModalOpened && (
        <CancelTrailingScheduledCallModal
          personGid={personGid}
          scheduledCall={uncompletedCall}
          assignee={currentDisposition?.assignee}
          onCloseModal={() => setCancelCallModalOpened(false)}
        />
      )}
    </>
  );
};

export default GuidedQuotingForm;
