/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { Copy } from '../../../components/common';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { servicerOrLender } from '../../../interfaces/ILender';

const MortgageeClauseColumn = ({ lender, servicer }: Parameters<typeof servicerOrLender>[0]) => {
  const currentCompany = servicerOrLender({ servicer, lender });

  const mortgageeClause = currentCompany?.business_name;
  const mortgageeAddress = currentCompany?.mortgagee_address;

  return (
    <FlexBox columnDirection>
      <Text data-testid="loan-mortgagee_clause">{mortgageeClause ? <Copy value={mortgageeClause} /> : '-'}</Text>
      {!!mortgageeAddress && (
        <div>
          <Text>
            <Copy
              testId="mortgagee_address_line1"
              displayText={`${mortgageeAddress.line1}, `}
              value={mortgageeAddress.line1}
            />
          </Text>
          {mortgageeAddress.line2 && (
            <Text>
              <Copy
                testId="mortgagee_address_line2"
                displayText={`${mortgageeAddress.line2}, `}
                value={mortgageeAddress.line2}
              />
            </Text>
          )}
          <Text>
            <Copy
              testId="mortgagee_address_city"
              displayText={`${mortgageeAddress.city}, `}
              value={mortgageeAddress.city}
            />
          </Text>
          <Text>
            <Copy
              testId="mortgagee_address_state"
              displayText={`${mortgageeAddress.state}, `}
              value={mortgageeAddress.state}
            />
          </Text>
          <Text>
            <Copy
              testId="mortgagee_address_zip"
              displayText={mortgageeAddress.zip}
              value={mortgageeAddress.zip}
              css={css`
                white-space: nowrap;
              `}
            />
          </Text>
        </div>
      )}
    </FlexBox>
  );
};

export default MortgageeClauseColumn;
