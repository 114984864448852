import classNames from 'classnames';
import React from 'react';

const MailLink = ({ className, mail }: { className: string; mail?: string | null }): JSX.Element => {
  return (
    <>
      {mail && (
        <a href={`mailto:${mail}`} className={classNames(className)} onClick={event => event.stopPropagation()}>
          {mail}
        </a>
      )}
    </>
  );
};

export default MailLink;
