/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import PDFViewer, { PDFContext } from '../../../components/common/PDFViewer';
import FlexBox from '../../../components/core/FlexBox';

const PDFFileViewer = () => {
  const [pdfFile] = React.useContext(PDFContext);

  if (!pdfFile) {
    return null;
  }

  return (
    <FlexBox columnDirection>
      <PDFViewer
        headingCss={css`
          position: static;
        `}
        bodyCss={css`
          position: static;
        `}
      />
    </FlexBox>
  );
};

export default PDFFileViewer;
