/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import { ButtonVariant } from '../../components/core/buttons/Button';
import BaseForm from '../../components/core/forms/BaseForm';
import AddressField from '../../components/core/forms/fields/Address';
import InputField from '../../components/core/forms/fields/InputField';
import PhoneField from '../../components/core/forms/fields/PhoneField';
import Heading from '../../components/core/Heading';
import Paragraph from '../../components/core/Paragraph';
import { CITY, LINE1, LINE2, STATE, ZIP } from '../../constants/addressForm';
import featureFlags from '../../constants/featureFlags';
import { useDocumentTitle } from '../../hooks';
import { InsuranceCategory } from '../../interfaces/IPolicyType';
import useFindOrCreateLead from '../../queries/people/person_leads/useFindOrCreateLead';
import { useCreatePerson } from '../../queries/people/usePerson';
import { useCreatePersonHome } from '../../queries/people/usePersonHomes';
import analytics from '../../services/analytics';
import authInfo from '../../services/authInfo';
import { sizes, spacings } from '../../theme/variables';
import { humanize } from '../../utils/formatter';
import { createSmartyStreetsMapping } from '../../utils/object';
import { addressSchema, emailField, phoneField, REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';

const mb40css = css`
  margin-bottom: 40px;
`;

const LIVING_ADDRESS = 'living_address';
const MAILING_ADDRESS = 'mailing_address';

const NewLead = (): JSX.Element => {
  useDocumentTitle('New lead');
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state as { from: string; insuranceCategory: InsuranceCategory | undefined };
  const insuranceCategory = state?.insuranceCategory;

  const { mutateAsync: createPerson } = useCreatePerson();
  const { mutateAsync: createHome } = useCreatePersonHome();

  const { mutateAsync: createLead } = useFindOrCreateLead(({ lead: { id, gid } }) => {
    const leadNavigationUrl = featureFlags.scoutingReportRedirect
      ? `/scouting_report?lead_gid=${gid}`
      : `/leads/${id}/summary`;

    navigate(leadNavigationUrl);

    analytics.track('New Lead was created', { from: state.from, lead_gid: gid });
  });

  const cancelHandler = () => navigate(-1);

  return (
    <BaseForm
      type="fullPage"
      pt={spacings.px12}
      pb={spacings.px12}
      pl={spacings.px24}
      pr={spacings.px24}
      cancelWithEscape
      initialValues={{
        first_name: '',
        last_name: '',
        middle_name: '',
        email: '',
        phone: '',
        [LIVING_ADDRESS]: {
          [LINE1]: '',
          [LINE2]: '',
          [CITY]: '',
          [STATE]: '',
          [ZIP]: ''
        },
        [MAILING_ADDRESS]: {
          [LINE1]: '',
          [LINE2]: '',
          [CITY]: '',
          [STATE]: '',
          [ZIP]: ''
        }
      }}
      validationSchema={yup.object().shape({
        first_name: requiredField,
        last_name: requiredField,
        email: emailField.required(REQUIRED_MESSAGE),
        phone: phoneField({ loose: authInfo.features.loose_phone_validation }).required(REQUIRED_MESSAGE),
        [LIVING_ADDRESS]: addressSchema,
        [MAILING_ADDRESS]: addressSchema
      })}
      header={<Heading type="h3">Create {humanize(insuranceCategory)} lead</Heading>}
      onSubmit={async values => {
        const person = await createPerson(values);
        if (values[LIVING_ADDRESS][LINE1]) {
          await createHome({ personGid: person.gid, data: { address: values[LIVING_ADDRESS] } });
        }

        await createLead({ personGid: person.gid, assigneeId: authInfo.currentUserId!, insuranceCategory });
      }}
      cancelHandler={cancelHandler}
      cancelVariant={ButtonVariant.Secondary}
      renderForm={() => {
        return (
          <>
            <div css={mb40css}>
              <Paragraph type="large" bold>
                Contact Info
              </Paragraph>
              <div
                css={css`
                  display: grid;
                  max-width: calc(3 * ${sizes.mediumFormInputWidth}px + 2 * 24px);
                  grid-template-columns: repeat(auto-fit, ${sizes.mediumFormInputWidth}px);
                  gap: 24px;
                `}
              >
                <InputField required id="first_name" label="First name" name="first_name" />
                <InputField id="middle-name" label="Middle name" name="middle_name" />
                <InputField required id="last-name" label="Last name" name="last_name" />
              </div>
              <div
                css={css`
                  display: grid;
                  max-width: calc(2 * ${sizes.mediumFormInputWidth}px + 24px);
                  grid-template-columns: repeat(auto-fit, ${sizes.mediumFormInputWidth}px);
                  gap: 24px;
                  margin-top: 24px;
                `}
              >
                <PhoneField required id="phone" label="Phone number" name="phone" placeholder="" />
                <InputField required id="email" label="Email" name="email" />
              </div>
            </div>
            <div css={mb40css}>
              <Paragraph type="large" bold>
                Addresses
              </Paragraph>
              <div
                css={css`
                  display: grid;
                  max-width: 664px;
                  grid-template-columns: repeat(auto-fit, minmax(${sizes.mediumFormInputWidth}px, 664px));
                  gap: 24px;
                `}
              >
                <AddressField
                  id={LIVING_ADDRESS}
                  placeholder=""
                  label="Living address"
                  testId="living_address"
                  fallbackNames={createSmartyStreetsMapping(`${LIVING_ADDRESS}.`)}
                />
                <AddressField
                  id={MAILING_ADDRESS}
                  placeholder=""
                  label="Mailing address"
                  testId="mailing_address"
                  fallbackNames={createSmartyStreetsMapping(`${MAILING_ADDRESS}.`)}
                />
              </div>
            </div>
          </>
        );
      }}
    />
  );
};

export default NewLead;
