/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../../components/core/buttons/IconButton';
import FlexBox from '../../../../components/core/FlexBox';
import FormModal from '../../../../components/core/FormModal';
import { SelectField } from '../../../../components/core/forms/fields';
import Text from '../../../../components/core/Text';
import { isEndDisposition } from '../../../../components/DispositionsModals/dispositionsHelper';
import { useToggle } from '../../../../hooks';
import { IDispositionVersion, ILead, IUser } from '../../../../interfaces';
import { ILeadDispositions } from '../../../../interfaces/IDisposition';
import useEditDispositionAssignee from '../../../../queries/leads/dispositions/useEditDispositionAssignee';
import analytics from '../../../../services/analytics';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import QuestionAnswerPair from '../CollectedInfoTab/_components/QuestionAnswerPair';
import DispositionsVersionModal from './_components/DispositionsVersionsModal';
import UserRoleTag from './_components/UserRoleTag';

const Assignee = ({
  leadDispositions,
  dispositionsVersions,
  assignees,
  lead
}: {
  leadDispositions: ILeadDispositions;
  dispositionsVersions: IDispositionVersion[];
  assignees: IUser[] | undefined;
  lead: ILead;
}) => {
  const queryClient = useQueryClient();
  const [openedLeadAssigneeModal, toggleOpenedLeadAssigneeModal] = useToggle(false);
  const [openedAssigneeVersionsModal, toggleOpenedAssigneeVersionsModal] = useToggle(false);

  const { mutateAsync: editDispositionAssignee } = useEditDispositionAssignee();

  const assignee = leadDispositions.current_disposition.assignee?.name;
  const isAssigneeEditable =
    !isEndDisposition(leadDispositions.current_disposition.disposition_type) && authInfo.features.update_assignee;

  const isAssigneeUpdated = dispositionsVersions.find(
    d => d.changes?.[0]?.content.includes('assignee_id') && d.event === 'update'
  );

  return (
    <FlexBox pv={isAssigneeEditable ? 0 : spacings.px4}>
      <QuestionAnswerPair
        questionLabel={
          <Text color={colors.grey60} type="small">
            Assignee
          </Text>
        }
        answerLabel={
          <FlexBox gap={spacings.px4} fitParentWidth justifySpaceBetween>
            <FlexBox
              gap={spacings.px4}
              customCss={css`
                width: calc(100% - 32px);
              `}
              alignItemsCenter
            >
              <Text type="small" singleLine title={assignee ? assignee : ''}>
                {assignee || '—'}
              </Text>
              <UserRoleTag processedWithRole={leadDispositions.current_disposition.assignee_role?.label} />
              {isAssigneeUpdated && (
                <Button
                  onClick={() => {
                    analytics.track('Lead operations viewed', { item: 'assignee', lead_gid: lead.gid });
                    toggleOpenedAssigneeVersionsModal();
                  }}
                  variant={ButtonVariant.PlainText}
                >
                  <Text underline dotted color={colors.statusOrange} type="small">
                    Updated
                  </Text>
                </Button>
              )}
            </FlexBox>
            {isAssigneeEditable && (
              <IconButton icon={ButtonIcons.Edit} color={colors.black} onClick={toggleOpenedLeadAssigneeModal} />
            )}
          </FlexBox>
        }
      />

      {assignees && openedLeadAssigneeModal && (
        <FormModal
          initialValues={{ assignee: leadDispositions.current_disposition.assignee?.id }}
          title="You're updating assignee"
          confirmText="Update"
          confirmHandler={values => {
            analytics.track('Lead operations updated', { item: 'assignee', lead_gid: lead.gid });
            editDispositionAssignee({
              leadId: lead.id,
              dispositionType: leadDispositions.current_disposition.disposition_type,
              assigneeId: values.assignee!
            }).then(() => {
              toggleOpenedLeadAssigneeModal();
              queryClient.invalidateQueries();
            });
          }}
          cancelHandler={toggleOpenedLeadAssigneeModal}
          validationSchema={yup.object().shape({ assignee: yup.string().required('Please select an assignee') })}
          renderForm={() => (
            <SelectField
              options={assignees.map(assignee => ({ key: assignee.id, value: assignee.name }))}
              id="assignee"
              name="assignee"
              label="Assignee name"
              required
              customCss={css`
                max-width: 320px;
              `}
            />
          )}
        />
      )}
      {openedAssigneeVersionsModal && (
        <DispositionsVersionModal
          dispositionsVersions={dispositionsVersions}
          cancelHandler={toggleOpenedAssigneeVersionsModal}
        />
      )}
    </FlexBox>
  );
};

export default Assignee;
