import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Tag from '../../../../components/core/Tag';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import useBrand from '../../../../queries/partners/useBrand';
import colors from '../../../../theme/colors';

const EngagementBrand = () => {
  const { sourceDimensions, lead } = useGuidedSellingExperienceContext();

  const brandKey = sourceDimensions?.brand || lead?.source_dimensions?.brand;
  const { brand } = useBrand(brandKey);

  if (brandKey === 'unknown' || !brand) {
    return null;
  }

  return (
    <FlexBox justifyCenter columnDirection>
      <Tag testId="scouting-engagement-brand" label={brand.name} backgroundColor={colors.violet} />
    </FlexBox>
  );
};

export default EngagementBrand;
