/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import { PersonPoliciesGroup } from '../../../../../queries/people/person_policies/usePersonAllGroupedPolicies';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import PolicyGroupTable from './PolicyGroupTable';

const PoliciesGroups = ({ personPoliciesGroups }: { personPoliciesGroups: PersonPoliciesGroup[] }) => (
  <FlexBox columnDirection gap={spacings.px24}>
    {personPoliciesGroups.length ? (
      personPoliciesGroups.map(policyGroup => {
        return (
          <Container key={policyGroup.group.map(policy => policy.gid).join(',')}>
            <PolicyGroupTable policyGroup={policyGroup} />
          </Container>
        );
      })
    ) : (
      <Paragraph
        mt={spacings.px24}
        color={colors.grey60}
        customCss={css`
          text-align: center;
        `}
      >
        No policies found
      </Paragraph>
    )}
  </FlexBox>
);

export default PoliciesGroups;
