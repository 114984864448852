import { isEmpty, isNil } from 'ramda';

import { compact } from './array';
import { dig } from './object';

/* eslint-disable prettier/prettier */
export const getFieldValueGenerator = ({ values }: any, fieldPrefix = '') => (fieldName: any) =>
  dig(values, ...compact(fieldPrefix.split('.')), fieldName);

export const showFieldErrorGenerator = ({ touched, values, isValid }: any, fieldPrefix = '') => (fieldName: any) => {
  const fieldTouched = dig(touched, ...compact(fieldPrefix.split('.')), fieldName);

  const fieldValue = getFieldValueGenerator({ values }, fieldPrefix)(fieldName);

  return !!fieldTouched || (!isNil(fieldValue) && !isEmpty(fieldValue) && !isValid);
};
/* eslint-enable prettier/prettier */
