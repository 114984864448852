/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import colors from '../../../theme/colors';
import { borderRadius, spacingKeys, spacings } from '../../../theme/variables';
import Container from '../Container';
import {
  BellHollowIcon,
  BellIcon,
  ChevronLeft,
  ChevronRight,
  ClearIcon,
  CloseIcon,
  ContactCalendarIcon,
  DeleteIcon,
  EditIcon,
  FullscreenIcon,
  MoreIcon,
  OpenInNewArrowlessIcon,
  OpenInNewIcon,
  PictureInPictureIcon,
  QuestionMarkHollowIcon,
  QuestionMarkIcon
} from '../icons';
import ButtonWithoutBorder, { IButton } from './ButtonWithoutBorder';

export enum ButtonIcons {
  Edit = 'edit',
  Delete = 'delete',
  More = 'more',
  FullScreen = 'full_screen',
  Close = 'close',
  PictureInPicture = 'pip',
  Clear = 'clear',
  OpenInNew = 'open_in_new',
  OpenInNewArrowless = 'open_in_new_arrowless',
  ChevronLeft = 'chevron_left',
  ChevronRight = 'chevron_right',
  Bell = 'bell',
  BellHollow = 'bell_hollow',
  QuestionMark = 'question_mark',
  QuestionMarkHollow = 'question_mark_hollow',
  ContactCalendar = 'contact_calendar'
}

interface IIconButton extends Omit<IButton, 'children'> {
  icon: ButtonIcons;
  focus?: boolean;
  color?: string;
  badge?: boolean;
  badgeColor?: string;
}

const generateIcon = ({ icon, color }: { icon: ButtonIcons; color: string }) => {
  switch (icon) {
    case ButtonIcons.Edit:
      return <EditIcon color={color} />;
    case ButtonIcons.Delete:
      return <DeleteIcon color={color} />;
    case ButtonIcons.More:
      return <MoreIcon color={color} />;
    case ButtonIcons.FullScreen:
      return <FullscreenIcon color={color} />;
    case ButtonIcons.Close:
      return <CloseIcon color={color} />;
    case ButtonIcons.PictureInPicture:
      return <PictureInPictureIcon color={color} />;
    case ButtonIcons.Clear:
      return <ClearIcon color={color} />;
    case ButtonIcons.OpenInNew:
      return <OpenInNewIcon color={color} />;
    case ButtonIcons.OpenInNewArrowless:
      return <OpenInNewArrowlessIcon color={color} />;
    case ButtonIcons.ChevronLeft:
      return <ChevronLeft color={color} />;
    case ButtonIcons.ChevronRight:
      return <ChevronRight color={color} />;
    case ButtonIcons.Bell:
      return <BellIcon color={color} />;
    case ButtonIcons.BellHollow:
      return <BellHollowIcon color={color} />;
    case ButtonIcons.QuestionMark:
      return <QuestionMarkIcon color={color} />;
    case ButtonIcons.QuestionMarkHollow:
      return <QuestionMarkHollowIcon color={color} />;
    case ButtonIcons.ContactCalendar:
      return <ContactCalendarIcon color={color} />;

    default:
      return '';
  }
};

const IconButton = ({
  onClick,
  focus,
  customCss,
  icon,
  color = colors.azure50,
  disabled = false,
  badge,
  badgeColor = colors.cherise50,
  ...props
}: IIconButton) => {
  const spacingProps = pick(spacingKeys, props);
  const restOfProps = omit(spacingKeys, props);
  return (
    <ButtonWithoutBorder
      p={spacings.px4}
      disabled={disabled}
      customCss={css`
        background-color: ${focus ? colors.grey5 : 'transparent'};
        border-radius: ${borderRadius}px;
        position: relative;
        &:hover {
          background-color: ${colors.grey5};
        }
        line-height: 1;
        ${customCss}
      `}
      onClick={onClick}
      {...spacingProps}
      {...restOfProps}
    >
      {generateIcon({ icon, color: disabled ? colors.grey30 : color })}
      {badge && (
        <Container
          customCss={css`
            position: absolute;

            top: 8px;
            right: 8px;
            width: 8px;
            height: 8px;

            border-radius: 4px;

            background-color: ${badgeColor};
          `}
        />
      )}
    </ButtonWithoutBorder>
  );
};

export default IconButton;
