/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import Blockquote from '../../../../../components/core/Blockquote';
import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import CollapsingContainer from '../../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../../components/core/FlexBox';
import { InfoIcon } from '../../../../../components/core/icons';
import Paragraph, { ParagraphType } from '../../../../../components/core/Paragraph';
import PolicyTypeIcon from '../../../../../components/core/PolicyTypeIcon';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import featureFlags from '../../../../../constants/featureFlags';
import { useToggle } from '../../../../../hooks';
import { PolicyType } from '../../../../../interfaces/IPolicyType';
import { QuoteStatus } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { PET_TIP } from '../../../../GuidedDataCollection/QuotingConfigurationPage/_utils';
import { isQuoteEligibleOrPartlyEligible, isQuoteNonEligible } from '../../_helpers';
import useQuotesModalView from '../../_hooks/useModalView';
import QuotingErrorsByAssets from '../../QuotingErrors/QuotingErrorsByAssets';
import QuotesGroupHeader from '../GroupHeaders/RealPropertyGroupHeader';
import HowToQuotePetModal from '../HowToQuotePetModal';
import QuoteRow from '../QuoteRow/QuoteRow';
import { QuoteGroupProps } from '.';

const PetQuotesGroup = ({
  quotesGroup: { quotes },
  assets,
  quotingFinished,
  errors,
  quotesRequest
}: QuoteGroupProps) => {
  const { quotesModalView } = useQuotesModalView();
  const [howToQuote, toggleHowToQuote] = useToggle(false);
  const realQuotes = quotes.filter(({ status }) => status !== QuoteStatus.CompleteManually);

  const eligibleQuotes = realQuotes.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleQuotes = realQuotes.filter(quote => isQuoteNonEligible(quote));

  const carriersRecommendations = quotes.filter(({ status }) => status === QuoteStatus.CompleteManually);

  const eligibleRecommendations = carriersRecommendations.filter(quote => isQuoteEligibleOrPartlyEligible(quote));
  const notEligibleRecommendations = carriersRecommendations.filter(quote => !isQuoteEligibleOrPartlyEligible(quote));

  const eligibleEntriesCount = eligibleQuotes.length + eligibleRecommendations.length;
  const nonEligibleEntriesCount = notEligibleQuotes.length + notEligibleRecommendations.length;

  return (
    <FlexBox columnDirection gap={spacings.px8}>
      {!quotesModalView && (
        <>
          <FlexBox justifySpaceBetween alignItemsCenter>
            <FlexBox alignItemsCenter gap={spacings.px8}>
              <PolicyTypeIcon policyType={PolicyType.Pet} />
              <Text
                bold={!featureFlags.newQuotesUI}
                type="large"
                customCss={css`
                  white-space: pre-wrap;
                `}
              >
                Pet ({quotes.length})
              </Text>
              <Tag
                data-for="pet-recommendation"
                data-tip={PET_TIP}
                label={
                  <FlexBox gap={spacings.px8} alignItemsCenter>
                    Recommended
                    <InfoIcon height={spacings.px16} width={spacings.px16} />
                  </FlexBox>
                }
                transparent
                backgroundColor={colors.statusGreen}
                textColor={colors.statusGreen}
                mt={spacings.px4}
              />
              <Tooltip id="pet-recommendation" />
            </FlexBox>
            <FlexBox alignItemsCenter gap={spacings.px4}>
              <ButtonWithoutBorder onClick={toggleHowToQuote}>How to quote?</ButtonWithoutBorder>
              {featureFlags.newQuotesUI && errors && errors.length > 0 && (
                <QuotingErrorsByAssets errors={errors} quotesRequest={quotesRequest} />
              )}
            </FlexBox>
          </FlexBox>

          {eligibleEntriesCount > 0 && (
            <Blockquote
              textColor={colors.grey80}
              ml={spacings.px32}
              mv={spacings.px4}
              text="Do you have pet insurance?
           We have a good carrier Embrace that covers dogs and cats.
          The process is really quick and they are a reliable carrier, they paid 92% of our claims in 2021.
          I can quickly check for you some good options, you can consider later."
            />
          )}
        </>
      )}

      {eligibleEntriesCount > 0 && (
        <>
          <QuotesGroupHeader />
          <CollapsingContainer
            title={`Eligible and partly eligible (${eligibleEntriesCount})`}
            titleSize={ParagraphType.Default}
            iconPosition="left"
          >
            {eligibleQuotes.map(quote => (
              <QuoteRow key={quote.gid} quote={quote} assets={assets} showMpd={false} />
            ))}

            {eligibleRecommendations.map(recommendation => (
              <QuoteRow key={recommendation.gid} quote={recommendation} assets={assets} showMpd={false} />
            ))}
          </CollapsingContainer>
        </>
      )}
      {quotingFinished && eligibleEntriesCount === 0 && nonEligibleEntriesCount && (
        <CollapsingContainer
          title={`Eligible and partly eligible (${nonEligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
        >
          <FlexBox alignItemsCenter justifyCenter pv={spacings.px24}>
            <Paragraph color={colors.grey60}>No quotes available</Paragraph>
          </FlexBox>
        </CollapsingContainer>
      )}

      {nonEligibleEntriesCount > 0 && (
        <CollapsingContainer
          title={`Not eligible (${nonEligibleEntriesCount})`}
          titleSize={ParagraphType.Default}
          iconPosition="left"
          openedByDefault={false}
        >
          {notEligibleQuotes.map(quote => (
            <QuoteRow key={quote.gid} quote={quote} assets={assets} showMpd={false} />
          ))}
          {notEligibleRecommendations.map(recommendation => (
            <QuoteRow key={recommendation.gid} quote={recommendation} assets={assets} showMpd={false} />
          ))}
        </CollapsingContainer>
      )}
      {quotingFinished && !eligibleEntriesCount && !nonEligibleEntriesCount && (
        <FlexBox justifyCenter pv={spacings.px24}>
          <Paragraph color={colors.grey60}>No quotes available</Paragraph>
        </FlexBox>
      )}
      {howToQuote && <HowToQuotePetModal cancelHandler={toggleHowToQuote} />}
    </FlexBox>
  );
};

export default PetQuotesGroup;
