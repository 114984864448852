import * as React from 'react';

import DescriptionList from '../../../components/core/DescriptionList';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { INFO_NOT_PRESENT } from '../../../constants';
import { buildVehicleTitle, IVehicle } from '../../../interfaces/IVehicle';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { titleize } from '../../../utils/formatter';

const VehicleInfo = ({ vehicle }: { vehicle: IVehicle }): JSX.Element => {
  return (
    <FlexBox pb={spacings.px24} columnDirection>
      <Text bold color={colors.black}>
        {buildVehicleTitle(vehicle)}
      </Text>
      <DescriptionList term={<Text>VIN</Text>} details={<Text className="fs-mask">{vehicle.vin}</Text>} />
      <DescriptionList
        term={<Text>Annual mileage</Text>}
        details={<Text className="fs-mask">{vehicle.annual_mileage || INFO_NOT_PRESENT}</Text>}
      />
      <DescriptionList
        term={<Text>Use</Text>}
        details={<Text className="fs-mask">{vehicle.usage || INFO_NOT_PRESENT}</Text>}
      />
      <DescriptionList
        term={<Text>Ownership</Text>}
        details={<Text className="fs-mask">{vehicle.ownership || INFO_NOT_PRESENT}</Text>}
      />
      <DescriptionList
        term={<Text>Garaging address</Text>}
        details={<Text className="fs-mask">{titleize(vehicle.address?.full || '') || INFO_NOT_PRESENT}</Text>}
      />
    </FlexBox>
  );
};

export default VehicleInfo;
