/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { PDFContextProvider } from '../../components/common/PDFViewer';
import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import Container from '../../components/core/Container';
import { Add2Icon } from '../../components/core/icons';
import BaseStepper from '../../components/core/navigation/BaseStepper';
import { isEndDisposition } from '../../components/DispositionsModals/dispositionsHelper';
import featureFlags from '../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../hooks';
import { IOpportunity } from '../../interfaces';
import { DispositionType } from '../../interfaces/IDisposition';
import { OpportunityStatus } from '../../interfaces/IOpportunity';
import { isNonLifeAncillaryPolicyType } from '../../interfaces/IPolicyType';
import { useActivePolicyConsent } from '../../queries/disclosures/useDisclosure';
import useLeadOpportunities from '../../queries/leads/opportunities/useLeadOpportunities';
import analytics from '../../services/analytics';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { DataCollectionStepper } from '../GuidedDataCollection/_hooks/useDataCollectionStepper';
// eslint-disable-next-line max-len
import { useCurrentDispositionSubscriptionContext } from '../GuidedSellingExperience/_hooks/useCurrentDispositionSubscription';
import useDataEditingForbidden from '../GuidedSellingExperience/_hooks/useDataEditingForbidden';
import useDisplayFinalizedSalesPage from './_contexts/useFinalizedPage';
import useFinalizeStepper, { FinalizeStepper } from './_hooks/useFinalizeStepper';
import AddPolicyModal from './_modals/AddPolicyModal';
import DeprecatedFinalizedSalesPage from './DeprecatedFinalizedSalesPage';
import FinalLookForm from './FinalLookForm';
import LostOpportunitiesFinishPage from './LostOpportunitiesFinishPage';
import PolicyEditor from './PolicyEditor';

const FinalizePage = ({
  allOpportunities,
  hiddenOpportunities,
  personGid,
  dataCollectionStepper
}: {
  allOpportunities: IOpportunity[];
  hiddenOpportunities: IOpportunity[];
  personGid: string;
  dataCollectionStepper: DataCollectionStepper;
}) => {
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();

  const stepper = useFinalizeStepper({
    opportunities: allOpportunities,
    currentDisposition: currentDisposition?.disposition_type
  });

  const [addPolicy, toggleAddPolicy] = useToggle(false);
  const { displayFinalizedSalesPage } = useDisplayFinalizedSalesPage();

  const isDataEditingForbidden = useDataEditingForbidden();
  const { data: activeConsent } = useActivePolicyConsent(personGid);

  if (displayFinalizedSalesPage) {
    return <DeprecatedFinalizedSalesPage />;
  }

  const displayFinalizeDeprecatedPage =
    currentDisposition &&
    currentDisposition.disposition_type === DispositionType.Lost &&
    !featureFlags.displayDisqualifyButton;

  return (
    <Container pl={spacings.px24} fitParentHeight>
      {displayFinalizeDeprecatedPage ? (
        <LostOpportunitiesFinishPage />
      ) : (
        <Container
          fitParentHeight
          css={css`
            display: grid;
            grid-template-columns: minmax(200px, max-content) auto;
          `}
        >
          <Container
            css={css`
              position: sticky;
              top: 0;
              align-self: start;
              z-index: 1;
              padding-right: 24px;
              border-right: 1px ${colors.grey10} solid;
              height: 100%;
              padding-top: ${spacings.px24}px;
            `}
          >
            <BaseStepper steps={stepper.steps} headline="Policy binding" />
            <Button
              disabled={isDataEditingForbidden || isEndDisposition(currentDisposition?.disposition_type)}
              onClick={() => {
                toggleAddPolicy();
                analytics.track('Add policy clicked');
              }}
              size={ButtonSize.Small}
              variant={ButtonVariant.Text}
              mh={spacings.px8}
              mv={spacings.px8}
            >
              <Add2Icon
                css={css`
                  margin-right: ${spacings.px8}px;
                  position: relative;
                  bottom: 2px;
                `}
              />
              Add policy
            </Button>
            {addPolicy && (
              <AddPolicyModal toggleAddPolicy={toggleAddPolicy} hiddenOpportunities={hiddenOpportunities} />
            )}
          </Container>

          {stepper.activeOpportunity ? (
            <PDFContextProvider key={stepper.activeOpportunity.id}>
              <PolicyEditor
                {...stepper}
                activeOpportunity={stepper.activeOpportunity}
                isDataEditingForbidden={isDataEditingForbidden}
                showPolicyDisclosure={(() => {
                  if (featureFlags.issuePolicyConsent) {
                    const firstSoldStep = stepper.steps.find(step => step.completed && typeof step.key === 'number');

                    return firstSoldStep && activeConsent ? firstSoldStep.key === stepper.activeOpportunity.id : true;
                  }

                  return false;
                })()}
                dataCollectionStepper={dataCollectionStepper}
              />
            </PDFContextProvider>
          ) : (
            <FinalLookForm
              opportunities={allOpportunities}
              currentDisposition={currentDisposition}
              isDataEditingForbidden={isDataEditingForbidden}
            />
          )}
        </Container>
      )}
    </Container>
  );
};

const EMPTY_ARRAY: IOpportunity[] = [];

const PostSalesExperience = ({ dataCollectionStepper }: { dataCollectionStepper: DataCollectionStepper }) => {
  const { lead, personGid } = useGuidedSellingExperienceContext();
  const { data: opportunities, isFetchedAfterMount } = useLeadOpportunities({ leadId: lead!.id });

  const visibleOpportunities = opportunities?.filter(
    opp =>
      opp.status === OpportunityStatus.Sold ||
      opp.status === OpportunityStatus.Lost ||
      !isNonLifeAncillaryPolicyType({ policy_type: opp.policy_type })
  );
  const hiddenOpportunities = opportunities?.filter(({ id }) =>
    visibleOpportunities?.every(({ id: visibleId }) => id !== visibleId)
  );

  return (
    <>
      {isFetchedAfterMount && opportunities && visibleOpportunities && personGid && (
        <FinalizeStepper.Provider initialValue={visibleOpportunities}>
          <FinalizePage
            allOpportunities={opportunities}
            hiddenOpportunities={hiddenOpportunities || EMPTY_ARRAY}
            personGid={personGid}
            dataCollectionStepper={dataCollectionStepper}
          />
        </FinalizeStepper.Provider>
      )}
    </>
  );
};

export default PostSalesExperience;
