import { skipToken, useMutation, useQuery } from '@tanstack/react-query';

import { IRelatedPerson } from '../../../interfaces';
import api from '../../../services/api';

const updatePolicyCoinsureds = ({ policyId, peopleGids }: { policyId: number; peopleGids: string[] }) =>
  api.put(`/api/frontend/policies/${policyId}/coinsureds`, { body: { people_gids: peopleGids } });

export const useUpdatePolicyCoinsureds = () =>
  useMutation({
    mutationFn: updatePolicyCoinsureds
  });

const getPolicyCoinsureds = ({ policyId }: { policyId: number | undefined }): Promise<{ people: IRelatedPerson[] }> =>
  api.get(`/api/frontend/policies/${policyId}/coinsureds`);

export const usePolicyCoinsureds = ({ policyId }: { policyId: number | undefined }) =>
  useQuery({
    queryKey: ['policies_coinsureds', policyId],
    queryFn: policyId ? () => getPolicyCoinsureds({ policyId }) : skipToken,
    select: data => data.people
  });
