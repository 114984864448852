import { useQuery } from '@tanstack/react-query';

import { IUserWithRole } from '../../interfaces/IUser';
import api from '../../services/api';

export const CALL_PARTICIPANTS_QUERY = 'callParticipants';

const useCallParticipants = (callLogId: number) =>
  useQuery({
    queryKey: [CALL_PARTICIPANTS_QUERY, callLogId],
    queryFn: (): Promise<{ participants: IUserWithRole[] }> =>
      api.get(`/api/frontend/call_logs/${callLogId}/participants`),
    select: data => data.participants
  });

export default useCallParticipants;
