import { skipToken, useMutation, useQuery } from '@tanstack/react-query';

import { CallLogLinkEngagementParams, ICallLog } from '../../interfaces/ICallLog';
import api from '../../services/api';

interface Result {
  call_log: ICallLog;
}

const linkToOngoingCall = (params: CallLogLinkEngagementParams): Promise<Result> =>
  api.put('/api/frontend/call_logs/ongoing/link_engagement', { body: params });

export const useLinkToOngoingCall = (onSuccess: ({ call_log }: Result) => void) =>
  useMutation({ mutationFn: linkToOngoingCall, onSuccess });

const getCallLog = (id: number): Promise<Result> => api.get(`/api/frontend/call_logs/${id}`);

export const SCOUTING_CALL_LOG_QUERY = 'scouting_call_log';

export const useScoutingCallLog = (id: number | undefined) =>
  useQuery({
    queryKey: [SCOUTING_CALL_LOG_QUERY, id],
    queryFn: id ? () => getCallLog(id!) : skipToken,
    select: data => data.call_log
  });

export const CALL_LOG_QUERY_KEY = 'call_log_by_id';

const useCallLog = (id: number | undefined) =>
  useQuery({
    queryKey: [CALL_LOG_QUERY_KEY, id],
    queryFn: id ? () => getCallLog(id!) : skipToken,
    select: data => data.call_log,
    staleTime: 5000
  });

export default useCallLog;
