/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import Copy from '../../components/core/Copy';
import FlexBox from '../../components/core/FlexBox';
import Text from '../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IQuote, IVehicle } from '../../interfaces';
import { isPersonAgedToDrive } from '../../interfaces/IPerson';
import { coverageByKey, PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { QuoteSource } from '../../interfaces/IQuote';
import { buildVehicleTitle } from '../../interfaces/IVehicle';
import usePersonRelatedPeople from '../../queries/people/usePersonRelatedPeople';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { moneyFormatter } from '../../utils/formatter';

const AutoQuoteInfo = ({
  setOpenQuoteEditor,
  quote,
  opportunityAssets,
  disabled
}: {
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote: IQuote;
  opportunityAssets: IVehicle[];
  disabled?: boolean;
}) => {
  const { personGid, person } = useGuidedSellingExperienceContext();
  const { data: relatedPeople } = usePersonRelatedPeople(personGid);
  const filteredRelatedPeople = relatedPeople?.filter(isPersonAgedToDrive) || [];
  const possiblyInsuredDrivers = [person, ...filteredRelatedPeople];

  const carrierName = quote.attributes.carrier.name || '—';
  const premium = moneyFormatter(quote.attributes.premium, true) || '—';
  const firstMonth = moneyFormatter(quote.attributes.payment_options?.[0]?.downpayment, true) || '—';
  const subsequentMonthlyPayments =
    moneyFormatter(quote.attributes.payment_options?.[0]?.subsequent_payment, true) || '—';
  const propertyDamage =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.PropertyDamage)?.limit_description || '—';
  const bILiabilityPerPerson =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.BiPerPerson)?.limit_description || '—';
  const bILiabilityPerAccident =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.BiPerAccident)?.limit_description || '—';
  const uninsuredMotoristPropertyDamage =
    coverageByKey(quote.attributes.coverages, PolicyCoverageKey.UninsuredMpd)?.limit_description || '—';
  const insuredDrivers =
    quote.attributes.coinsureds_gids
      ?.concat(personGid!)
      ?.map(gid => possiblyInsuredDrivers.find(driver => driver?.gid === gid)?.name)
      .join(', ') || '—';
  const bindingUrl = quote.attributes.binding_url || '—';

  return (
    <Container
      border
      borderColor={colors.grey10}
      p={spacings.px12}
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 400px;
      `}
    >
      <FlexBox columnDirection gap={spacings.px8}>
        <FlexBox justifySpaceBetween>
          <Text bold>Your proposal</Text>
          {quote.source === QuoteSource.AU && !disabled && (
            <IconButton
              icon={ButtonIcons.Edit}
              color={colors.black}
              onClick={() => {
                setOpenQuoteEditor(true);
              }}
            />
          )}
        </FlexBox>
        <FlexBox gap={spacings.px12} columnDirection>
          <FlexBox justifySpaceBetween>
            <Text>Carrier</Text>
            <Text>{carrierName}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text
              customCss={css`
                width: 160px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              `}
            >
              Bridge URL
            </Text>
            <Copy value={bindingUrl}>
              <Text>{bindingUrl}</Text>
            </Copy>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Premium(6 month)</Text>
            <Text>{premium}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>First month (Downpayment)</Text>
            <Text>{firstMonth}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Subsequent monthly payments</Text>
            <Text>{subsequentMonthlyPayments}</Text>
          </FlexBox>
          <Text bold mv={spacings.px4}>
            General automobile coverages
          </Text>
          <FlexBox justifySpaceBetween>
            <Text>Property damage</Text>
            <Text>{propertyDamage}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Bodily injury liability per person</Text>
            <Text>{bILiabilityPerPerson}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Bodily injury liability per accident</Text>
            <Text>{bILiabilityPerAccident}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Uninsured motorist property damage</Text>
            <Text>{uninsuredMotoristPropertyDamage}</Text>
          </FlexBox>
          <FlexBox justifySpaceBetween>
            <Text>Insured drivers</Text>
            <Text>{insuredDrivers}</Text>
          </FlexBox>
          {opportunityAssets.map(vehicle => {
            return (
              <React.Fragment key={vehicle.gid}>
                <Text bold mv={spacings.px4}>
                  {buildVehicleTitle(vehicle)}
                </Text>
                <FlexBox justifySpaceBetween>
                  <Text>Collision deductible</Text>
                  <Text>
                    {quote?.attributes.coverages?.find(
                      coverage => PolicyCoverageKey.Collision === coverage.key && coverage.asset_gid === vehicle.gid
                    )?.deductible_description || '—'}
                  </Text>
                </FlexBox>
                <FlexBox justifySpaceBetween>
                  <Text>Comprehensive deductible</Text>
                  <Text>
                    {quote?.attributes.coverages?.find(
                      coverage => PolicyCoverageKey.Comprehensive === coverage.key && coverage.asset_gid === vehicle.gid
                    )?.deductible_description || '—'}
                  </Text>
                </FlexBox>
                <FlexBox justifySpaceBetween>
                  <Text>Roadside assistance coverage</Text>
                  <Text>
                    {quote?.attributes.coverages?.find(
                      coverage => PolicyCoverageKey.Towing === coverage.key && coverage.asset_gid === vehicle.gid
                    )?.limit_description || '—'}
                  </Text>
                </FlexBox>
              </React.Fragment>
            );
          })}
        </FlexBox>
      </FlexBox>
    </Container>
  );
};
export default AutoQuoteInfo;
