/** @jsxImportSource @emotion/react */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';

import Button, { ButtonSize } from '../../../../components/core/buttons/Button';
import FlexBox from '../../../../components/core/FlexBox';
import FormModal from '../../../../components/core/FormModal';
import { CheckboxField } from '../../../../components/core/forms/fields';
import FormError from '../../../../components/core/forms/fields/FormError';
import { ShieldIcon } from '../../../../components/core/icons';
import { ModalSize } from '../../../../components/core/Modal';
import Paragraph from '../../../../components/core/Paragraph';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../../hooks';
import { opportunityDescription, OpportunityStatus } from '../../../../interfaces/IOpportunity';
import useCrossSellLead from '../../../../queries/leads/cross_sell/useCrossSellLead';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import analytics from '../../../../services/analytics';
import { spacings } from '../../../../theme/variables';
import guidedSellingExperienceNavigation from '../../navigation';
import { OpportunityStatus as OpportunityStatusTag, OpportunityTitle } from '../../Summary/Context/Opportunities';

const CrossSellButton = () => {
  const navigate = useNavigate();
  const { lead, personGid } = useGuidedSellingExperienceContext();
  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);
  const { mutateAsync: crossSellLead, isPending: isLeadCrossSelling } = useCrossSellLead();

  const { data: opportunities = [] } = usePersonOpportunities({
    personGid,
    leadGid: lead?.gid
  });

  const initialValues = {
    opportunities:
      opportunities.reduce(
        (acc, opportunity) => {
          if (opportunity.status !== OpportunityStatus.Sold) {
            acc[opportunity.id] = false;
          }
          return acc;
        },
        {} as Record<number, boolean>
      ) || {}
  };

  const confirmHandler = (values: typeof initialValues) => {
    return crossSellLead({
      leadGid: lead!.gid,
      opportunitiesIds: Object.keys(values.opportunities).filter(
        key => !!values.opportunities[key as unknown as number]
      )
    }).then(data => {
      const {
        lead: { gid: lead_gid }
      } = data;

      navigate(guidedSellingExperienceNavigation.forLead({ lead_gid }));
      analytics.track('New Lead was created', { from: 'scouting_report_cross_sell', lead_gid });
      toggleShowConfirmationModal();
    });
  };

  return (
    <>
      <Button onClick={toggleShowConfirmationModal} size={ButtonSize.Small}>
        {featureFlags.cstGuidedSellingExperience ? 'Service' : 'Cross-sell'}
      </Button>
      {showConfirmationModal && (
        <FormModal
          size={ModalSize.large}
          confirmHandler={values => confirmHandler(values)}
          cancelHandler={toggleShowConfirmationModal}
          confirmationInProgress={isLeadCrossSelling}
          title={`Select opportunities to ${featureFlags.cstGuidedSellingExperience ? 'service' : 'cross-sell'}`}
          confirmText="Create opportunities"
          initialValues={initialValues}
          validationSchema={yup.object().shape({
            opportunities: yup
              .object()
              .test('at-least-one-true', 'At least one opportunity must be checked', value =>
                Object.values(value).some(v => !!v)
              )
          })}
          renderForm={({ errors }) => {
            return (
              <>
                <FlexBox alignItemsCenter gap={spacings.px8} mb={spacings.px20}>
                  <ShieldIcon />
                  <Paragraph bold>Previous lead opportunities</Paragraph>
                </FlexBox>

                <FlexBox gap={spacings.px12} columnDirection mb={spacings.px20}>
                  {opportunities.map(opportunity => (
                    <FlexBox alignItemsCenter gap={spacings.px20} key={opportunity.id}>
                      <CheckboxField
                        disabled={
                          opportunity.status === OpportunityStatus.Sold && !featureFlags.cstGuidedSellingExperience
                        }
                        name={`opportunities.${opportunity.id}`}
                        id={`opportunities.${opportunity.id}`}
                        label={<OpportunityTitle personGid={personGid!} opportunity={opportunity} />}
                        inline
                        description={
                          opportunityDescription({
                            policy_type: opportunity.policy_type,
                            assets: opportunity.assets
                          }) as string
                        }
                      />
                      <OpportunityStatusTag opportunity={opportunity} isOpportunityQuoted={false} />
                    </FlexBox>
                  ))}
                  {!!errors.opportunities && <FormError id="opportunities" hasError error={errors.opportunities} />}
                </FlexBox>
              </>
            );
          }}
        />
      )}
    </>
  );
};

export default CrossSellButton;
