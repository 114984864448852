import { NavigationType, useNavigate, useNavigationType } from 'react-router-dom';

const useNavigateBack = () => {
  const navigate = useNavigate();
  const navigationType = useNavigationType();

  return () => (navigationType === NavigationType.Pop ? navigate('/') : navigate(-1));
};

export default useNavigateBack;
