/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as React from 'react';

import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ChatSmileIcon, ChevronLeft } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { circleIconCss, DynamicTip } from './helpers';

const PreviousTips = ({
  dynamicTips,
  currentTip,
  anchorEl
}: {
  dynamicTips: DynamicTip[];
  currentTip: DynamicTip;
  anchorEl: HTMLElement | null;
}) => {
  const [isPreviousShown, setIsPreviousShown] = React.useState(false);

  React.useEffect(() => {
    if (!anchorEl) {
      setIsPreviousShown(false);
    }
  }, [anchorEl]);

  return (
    <>
      {currentTip.isPreviousButtonAvailable && (
        <Button variant={ButtonVariant.Text} onClick={() => setIsPreviousShown(current => !current)}>
          <Paragraph
            customCss={css`
              color: inherit;
            `}
          >
            <ChevronLeft
              css={css`
                margin-right: ${spacings.px8}px;
                transform: rotate(${isPreviousShown ? '90deg' : '270deg'});
                transition: transform 0.2s;
              `}
            />
            {isPreviousShown ? 'Hide' : 'Show'} previous
          </Paragraph>
        </Button>
      )}
      {isPreviousShown && (
        <FlexBox
          ph={spacings.px12}
          gap={spacings.px8}
          roundBorder
          columnDirection
          customCss={css`
            opacity: 0.4;
          `}
        >
          {dynamicTips.map((tip, index) => {
            if (tip.key === currentTip.key) {
              return (
                <>
                  {dynamicTips.map((previousTip, previousIndex) => {
                    if (previousIndex < index && previousTip.displayedInHistory) {
                      return (
                        <FlexBox gap={spacings.px8} pv={spacings.px8} key={`previous-${previousTip.key}`}>
                          <Container p={spacings.px8} backgroundColor={colors.grey30} customCss={circleIconCss}>
                            <ChatSmileIcon color={colors.white} />
                          </Container>
                          {previousTip.component}
                        </FlexBox>
                      );
                    }
                    return null;
                  })}
                </>
              );
            }
            return null;
          })}
        </FlexBox>
      )}
    </>
  );
};

export default PreviousTips;
