import React from 'react';

import FlexBox from '../../core/FlexBox';

const ActionsCell = ({ children, className }: { children: React.ReactNode; className?: string }): JSX.Element => (
  <FlexBox justifySpaceBetween className={className}>
    {children}
  </FlexBox>
);

export default ActionsCell;
