import * as React from 'react';
import { create } from 'zustand';

import { INote } from '../../../interfaces';
import authInfo from '../../../services/authInfo';
import { ActionCableContext } from '../../../utils/actioncable';

interface PersonMergedResponse {
  message: 'person_merged';
  payload: {
    active_lead_gid: string | null;
    merged_person_gid: string;
  };
}

interface NoteCreatedResponse {
  message: 'note_created';
  payload: INote;
}
type EventResponse = PersonMergedResponse | NoteCreatedResponse;

interface EventState {
  personMerged: PersonMergedResponse['payload'] | null;
  createdNote: INote | null;
  setPersonMerged: (personMerged: PersonMergedResponse['payload']) => void;
  resetPersonMerged: () => void;
  setCreatedNote: (note: INote) => void;
  resetCreatedNote: () => void;
  resetEventState: () => void;
}

export const useCustomerEventsState = create<EventState>(set => ({
  personMerged: null,
  createdNote: null,
  setPersonMerged: (personMerged: PersonMergedResponse['payload']) => set({ personMerged }),
  resetPersonMerged: () => set({ personMerged: null }),
  setCreatedNote: note => set({ createdNote: note }),
  resetCreatedNote: () => set({ createdNote: null }),
  resetEventState: () => set({ personMerged: null, createdNote: null })
}));

const useCustomerEventsChannel = (personGid: string) => {
  const cable = React.useContext(ActionCableContext);

  const { setPersonMerged, setCreatedNote, resetEventState } = useCustomerEventsState();

  React.useEffect(() => {
    const customerEventChannel = cable?.subscriptions?.create(
      { channel: 'CustomerEventsChannel', person_gid: personGid },
      {
        received: ({ message, payload }: EventResponse) => {
          if (message === 'person_merged') {
            setPersonMerged(payload);
          }

          if (message === 'note_created') {
            if (payload.agent?.id !== authInfo.currentUserId) {
              setCreatedNote(payload);
            }
          }
        }
      }
    );

    return () => {
      customerEventChannel?.unsubscribe();
      resetEventState();
    };
  }, [cable, personGid, setPersonMerged, setCreatedNote, resetEventState]);
};

export default useCustomerEventsChannel;
