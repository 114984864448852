import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IDataCollection } from '../../../interfaces';
import api from '../../../services/api';
import { updateDataCollectionQueryState } from './useDataCollection';

const replaceDataCollectionVehicles = ({
  assetsGids,
  leadId
}: {
  assetsGids: string[];
  leadId: string | number;
}): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/vehicles`, { body: { assets_gids: assetsGids } });

export const useReplaceDataCollectionVehicles = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: replaceDataCollectionVehicles,
    onSuccess: (result, variables) => updateDataCollectionQueryState(queryClient, result, variables)
  });
};

const detachVehicleFromDataCollection = ({
  assetGid,
  leadId
}: {
  assetGid: string;
  leadId: string | number;
}): Promise<{ data_collection: IDataCollection }> =>
  api.delete(`/api/frontend/leads/${leadId}/data_collection/vehicles/${assetGid}`);

export const useDetachVehicleFromDataCollection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: detachVehicleFromDataCollection,
    onSuccess: (result, variables) => updateDataCollectionQueryState(queryClient, result, variables)
  });
};
