import { useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import { verificationStatusForAnswer } from '../../components/core/forms/uiFlowBuilder';
import { IAnswer } from '../../interfaces';
import { DatapointsUsageKey } from '../../interfaces/IDatapoint';
import { QuestionVerificationStatus } from '../../interfaces/IWorkflow';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const ANSWERS_BY_DATAPOINTS_USAGE_QUERY_KEY = 'answers';

const queryKey = ({
  datapointsUsageKey,
  personGid,
  assetGid,
  relatedPersonGid
}: {
  datapointsUsageKey: DatapointsUsageKey;
  personGid: string;
  assetGid: string | null;
  relatedPersonGid: string | null;
}) => [ANSWERS_BY_DATAPOINTS_USAGE_QUERY_KEY, datapointsUsageKey, personGid, assetGid, relatedPersonGid];

const getAnswers = ({
  datapointsUsageKey,
  personGid,
  assetGid,
  relatedPersonGid
}: {
  datapointsUsageKey: DatapointsUsageKey;
  personGid: string;
  assetGid: string | null;
  relatedPersonGid: string | null;
}): Promise<{ answers: IAnswer[] }> =>
  api.get(
    `/api/frontend/answers/${datapointsUsageKey}?${toQueryParams({
      person_gid: personGid,
      asset_gid: assetGid,
      related_person_gid: relatedPersonGid
    })}`
  );

export const useAnswers = ({
  datapointsUsageKey,
  personGid,
  assetGid,
  relatedPersonGid
}: {
  datapointsUsageKey: DatapointsUsageKey;
  personGid: string;
  assetGid: string | null;
  relatedPersonGid: string | null;
}) =>
  useQuery({
    queryKey: queryKey({ datapointsUsageKey, personGid, assetGid, relatedPersonGid }),
    queryFn: () => getAnswers({ datapointsUsageKey, personGid, assetGid, relatedPersonGid }),
    select: data => data.answers
  });

interface AnswerUpdateRequest {
  key: string;
  person_gid: string;
  engagement_gid: string | null;
  asset_gid: string | null;
  related_person_gid: string | null;
  value: any;
}

const saveAnswers = ({
  datapointsUsageKey,
  answers
}: {
  datapointsUsageKey: DatapointsUsageKey;
  answers: AnswerUpdateRequest[];
}): Promise<{ answers: IAnswer[] }> => api.put(`/api/frontend/answers/${datapointsUsageKey}`, { body: { answers } });

export const useSaveAnswers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: saveAnswers,
    onSuccess: (result, variables) => {
      const answerNode = variables.answers[0];
      if (answerNode) {
        queryClient.setQueryData(
          queryKey({
            datapointsUsageKey: variables.datapointsUsageKey,
            personGid: answerNode.person_gid,
            assetGid: answerNode.asset_gid,
            relatedPersonGid: answerNode.related_person_gid
          }),
          () => result
        );
      }
    }
  });
};

export interface IAnswerWithVerificationStatus extends IAnswer {
  verificationStatus: QuestionVerificationStatus;
}

export const usePeopleAnswers = ({
  datapointsUsageKey,
  peopleGids
}: {
  datapointsUsageKey: DatapointsUsageKey;
  peopleGids: string[];
}) => {
  const queries = useQueries({
    queries: peopleGids.map(personGid => ({
      queryKey: queryKey({ datapointsUsageKey, personGid, assetGid: null, relatedPersonGid: null }),
      queryFn: () => getAnswers({ datapointsUsageKey, personGid, assetGid: null, relatedPersonGid: null })
    }))
  });

  const data: Record<string, Record<string, IAnswerWithVerificationStatus>> = {};
  peopleGids.forEach(personGid => {
    const answers = queries.find(query => query.data?.answers[0]?.person_gid === personGid)?.data?.answers;
    if (!answers) {
      return;
    }
    data[personGid] = answers
      .map(a => ({ ...a, verificationStatus: verificationStatusForAnswer(a) }))
      .reduce((acc, answer) => ({ ...acc, [answer.key]: answer }), {});
  });

  return data;
};

export const useRelatedPeopleAnswers = ({
  datapointsUsageKey,
  personGid,
  assetGid,
  relatedPeopleGids
}: {
  datapointsUsageKey: DatapointsUsageKey;
  personGid: string;
  assetGid: string | null;
  relatedPeopleGids: string[];
}) => {
  const queries = useQueries({
    queries: relatedPeopleGids.map(relatedPersonGid => ({
      queryKey: queryKey({ datapointsUsageKey, personGid, assetGid, relatedPersonGid }),
      queryFn: () => getAnswers({ datapointsUsageKey, personGid, assetGid, relatedPersonGid })
    }))
  });

  const data: Record<string, Record<string, IAnswerWithVerificationStatus>> = {};
  relatedPeopleGids.forEach(relatedPersonGid => {
    const answers = queries.find(query => query.data?.answers[0]?.related_person_gid === relatedPersonGid)?.data
      ?.answers;
    if (!answers) {
      return;
    }
    data[relatedPersonGid] = answers
      .map(a => ({ ...a, verificationStatus: verificationStatusForAnswer(a) }))
      .reduce((acc, answer) => ({ ...acc, [answer.key]: answer }), {});
  });

  return data;
};

export const useVehicleAnswers = ({
  datapointsUsageKey,
  vehicleGids,
  personGid
}: {
  datapointsUsageKey: DatapointsUsageKey;
  vehicleGids: string[];
  personGid: string;
}) => {
  const queries = useQueries({
    queries: vehicleGids.map(vehicleGid => ({
      queryKey: queryKey({ datapointsUsageKey, personGid, assetGid: vehicleGid, relatedPersonGid: null }),
      queryFn: () => getAnswers({ datapointsUsageKey, personGid, assetGid: vehicleGid, relatedPersonGid: null })
    }))
  });

  const data: Record<string, Record<string, IAnswerWithVerificationStatus>> = {};
  vehicleGids.forEach(vehicleGid => {
    const answers = queries.find(query => query.data?.answers[0]?.asset_gid === vehicleGid)?.data?.answers;
    if (!answers) {
      return;
    }
    data[vehicleGid] = answers
      .map(a => ({ ...a, verificationStatus: verificationStatusForAnswer(a) }))
      .reduce((acc, answer) => ({ ...acc, [answer.key]: answer }), {});
  });

  return data;
};
