import SystemMessage from '../../../components/core/Alert/SystemMessage';
import FlexBox from '../../../components/core/FlexBox';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IDataCollection } from '../../../interfaces';
import { spacings } from '../../../theme/variables';
import { useDisplayCrossSellWarning } from '../../GuidedSellingExperience/_hooks';
import CrossSellWarning from './CrossSellWarning';

interface ILeadWarnings {
  dataCollection: IDataCollection;
}

const LeadWarnings = ({ dataCollection }: ILeadWarnings) => {
  const { lead } = useGuidedSellingExperienceContext();
  const { displayWarning } = useDisplayCrossSellWarning();

  return !dataCollection.completed && lead?.source_lead_id ? (
    <FlexBox columnDirection>
      {displayWarning ? (
        <CrossSellWarning />
      ) : (
        <SystemMessage
          type="warning"
          heading="Please verify information. Data was retrieved from a closed lead."
          mb={spacings.px16}
        />
      )}
    </FlexBox>
  ) : null;
};

export default LeadWarnings;
