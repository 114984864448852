import { skipToken, useQuery } from '@tanstack/react-query';

import { IPersonOnlineSession } from '../../../interfaces';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

const getPersonOnlineSessions = ({
  personGid,
  engagementGid
}: {
  personGid: string;
  engagementGid?: string;
}): Promise<{ online_sessions: IPersonOnlineSession[] }> =>
  api.get(`/api/frontend/people/${personGid}/online_sessions?${toQueryParams({ engagement_gid: engagementGid })}`);

const usePersonOnlineSessions = ({
  personGid,
  engagementGid
}: {
  personGid: string | undefined;
  engagementGid?: string;
}) =>
  useQuery({
    queryKey: ['person_online_sessions', personGid, engagementGid],
    queryFn: personGid ? () => getPersonOnlineSessions({ personGid: personGid!, engagementGid }) : skipToken,
    select: data => data.online_sessions
  });

export default usePersonOnlineSessions;
