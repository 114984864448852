import './Tooltip.scss';

import classNames from 'classnames';
import * as React from 'react';

interface ITooltip {
  message?: React.ReactNode | null;
  className?: string | null;
  center?: boolean;
  right?: boolean;
  left?: boolean;
  children: React.ReactNode;
}
const Tooltip = ({
  message = null,
  className = null,
  center = false,
  right = false,
  left = false,
  children
}: ITooltip) => (
  <span className={classNames(className, 'with-tooltip')}>
    {children}

    {message && (
      <div
        className={classNames(
          'with-tooltip-message',
          { 'with-tooltip-message--center': center },
          { 'with-tooltip-message--right': right },
          { 'with-tooltip-message--left': left }
        )}
      >
        {message}
      </div>
    )}
  </span>
);

export default Tooltip;
