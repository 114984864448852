/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';
import * as React from 'react';
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from 'react-tabs';

import FlexBox from '../../../../components/core/FlexBox';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import useVerticalTabsCallbacks from '../../_hooks/useVerticalTabsCallbacks';
import { getComputedTabWidth, PANEL_CLASS_NAME, VERTICAL_TAB_HEADER_Z_INDEX } from './helpers';
import VerticalTabResizer from './VerticalTabResizer';

type TabType = {
  // don't forget to pass key to tabIcon
  tabName: string;
  tabIcon: JSX.Element;
  content: JSX.Element | string;
  onClick?: (tabKey: string) => void;
  openedByDefault?: boolean;
};

interface TabsInterface {
  tabs: TabType[];
  collapsedByDefault: boolean;
  wide?: boolean;
}

const verticalTabsStyles = (width: string) => css`
  .react-tabs {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    background-color: ${colors.grey10};
  }
  .react-tabs__tab-list {
    position: sticky;
    top: 0;
    z-index: ${VERTICAL_TAB_HEADER_Z_INDEX + 1};
    width: 60px;
    height: 100%;
    padding: 0;
    background: ${colors.white};
    border-left: solid 1px ${colors.grey10};
  }
  .react-tabs__tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    list-style: none;
    cursor: pointer;
    &:focus-visible {
      outline: none;
    }
  }
  .react-tabs__tab--selected {
    background-color: ${colors.grey5};
  }
  .${PANEL_CLASS_NAME} {
    display: none;
    width: ${width};
    padding: 0;
    overflow-y: auto;
    border-left: solid 1px ${colors.grey10};
  }
  .${PANEL_CLASS_NAME}--selected {
    position: relative;
    display: block;
    padding: 0;
    background-color: ${colors.white};
  }
`;

const VerticalTabs = ({ tabs, collapsedByDefault, wide = false }: TabsInterface): JSX.Element => {
  const defaultOpenedIndex = tabs.findIndex(tab => tab.openedByDefault);
  const [selectedIndex, setSelectedIndex] = React.useState(collapsedByDefault ? -1 : defaultOpenedIndex);
  const collapsed = !(selectedIndex + 1);

  React.useEffect(() => {
    useVerticalTabsCallbacks.setState({
      onTabClose: () => setSelectedIndex(-1)
    });
  }, []);

  return (
    <>
      <Global styles={verticalTabsStyles(`${getComputedTabWidth(wide)}px`)} />
      <ReactTabs
        selectedIndex={selectedIndex}
        onSelect={(index, lastIndex) => {
          setSelectedIndex(index === lastIndex ? -1 : index);
        }}
      >
        <TabList>
          {tabs.map((tab, index) => {
            const tabKey = tab.tabIcon.key;

            return (
              <Tab key={tabKey} data-testid={`${tabKey}-tab-testid`} onClick={() => tab.onClick?.(tabKey as string)}>
                <FlexBox
                  columnDirection
                  justifyCenter
                  alignItemsCenter
                  customCss={css`
                    color: ${selectedIndex === index ? colors.azure50 : colors.black};
                  `}
                >
                  {tab.tabIcon}
                  <Text type="small">{tab.tabName}</Text>
                </FlexBox>
              </Tab>
            );
          })}
        </TabList>
        {tabs.map(tab => {
          const tabKey = tab.tabIcon.key;
          return (
            <TabPanel key={tabKey} data-testid={`${tabKey}-tab-content-testid`}>
              {tab.content}
            </TabPanel>
          );
        })}
        {!collapsed && wide && <VerticalTabResizer />}
      </ReactTabs>
    </>
  );
};

export default VerticalTabs;
