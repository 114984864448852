import { skipToken, useQuery } from '@tanstack/react-query';

import { IncidentType } from '../../interfaces/IIncidentType';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const getViolationTypes = ({ state }: { state: string | undefined }): Promise<IncidentType[]> =>
  api.get(`/api/frontend/violation_types?${toQueryParams({ state })}`);

const useViolationTypes = ({ state }: { state: string | undefined }) =>
  useQuery({
    queryKey: ['violation_types', state],
    queryFn: state ? () => getViolationTypes({ state }) : skipToken,
    staleTime: Infinity
  });

export default useViolationTypes;
