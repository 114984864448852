import { useQuery } from '@tanstack/react-query';

import { IPersonAddress } from '../../interfaces/IAddress';
import api from '../../services/api';

const usePersonAddresses = (personGid?: string) =>
  useQuery({
    queryKey: ['addresses', personGid],
    queryFn: (): Promise<{ addresses: IPersonAddress[] }> => api.get(`/api/frontend/people/${personGid}/addresses`),
    enabled: !!personGid,
    select: data => data.addresses
  });

export default usePersonAddresses;
