import { skipToken, useMutation, useQuery } from '@tanstack/react-query';

import { IPriorPolicy } from '../../../interfaces/IPolicy';
import { PolicyCoverage, PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

interface CreatePriorPolicyParams {
  policy_type: PolicyType;
  asset_gids: null | string[];
  carrier_gid: null | string;
  premium: null | string;
  expiration_date: null | string;
  policy_number: null | string;
  coverages:
    | null
    | {
        key: PolicyCoverageKey;
        limit_type: undefined | PolicyCoverage['limit_type'];
        limit_value: undefined | PolicyCoverage['limit_value'];
        deductible_type: undefined | PolicyCoverage['deductible_type'];
        deductible_value: undefined | PolicyCoverage['deductible_value'];
      }[];
}

const createPriorPolicy = ({
  personGid,
  params
}: {
  personGid: string;
  params: CreatePriorPolicyParams;
}): Promise<{ prior_policy: IPriorPolicy }> =>
  api.post(`/api/frontend/people/${personGid}/prior_policies`, {
    body: params
  });

export const useCreatePriorPolicy = () => useMutation({ mutationFn: createPriorPolicy });

// =====================================================================================================================

interface UpdatePriorPolicyParams {
  carrier_gid: null | string;
  premium: null | string;
  expiration_date: null | string;
  policy_number: null | string;
  coverages:
    | null
    | {
        key: PolicyCoverageKey;
        limit_type: undefined | PolicyCoverage['limit_type'];
        limit_value: undefined | PolicyCoverage['limit_value'];
        deductible_type: undefined | PolicyCoverage['deductible_type'];
        deductible_value: undefined | PolicyCoverage['deductible_value'];
      }[];
}

const updatePriorPolicy = ({
  personGid,
  priorPolicyGid,
  params
}: {
  personGid: string;
  priorPolicyGid: string;
  params: UpdatePriorPolicyParams;
}): Promise<{ prior_policy: IPriorPolicy }> =>
  api.put(`/api/frontend/people/${personGid}/prior_policies/${priorPolicyGid}`, {
    body: params
  });

export const useUpdatePriorPolicy = () => useMutation({ mutationFn: updatePriorPolicy });

const deletePriorPolicy = ({
  personGid,
  priorPolicyGid
}: {
  personGid: string;
  priorPolicyGid: string;
}): Promise<null> => api.delete(`/api/frontend/people/${personGid}/prior_policies/${priorPolicyGid}`);

export const useDeletePriorPolicy = () => useMutation({ mutationFn: deletePriorPolicy });

// =====================================================================================================================

interface PersonPriorPoliciesFilters {
  policy_types?: null | string[];
  similar_policy_types?: null | boolean;
  asset_gid?: null | string;
}

// Deprecated, use usePersonAllPolicies instead.
const usePersonPriorPolicies = ({ personGid, filters }: { personGid?: string; filters?: PersonPriorPoliciesFilters }) =>
  useQuery({
    queryKey: [personGid, 'person_prior_policies', filters],
    queryFn: personGid
      ? (): Promise<{ prior_policies: IPriorPolicy[] }> =>
          api.get(
            `/api/frontend/people/${personGid}/prior_policies?${toQueryParams({
              ...filters
            })}`
          )
      : skipToken,
    select: data => data.prior_policies
  });

export default usePersonPriorPolicies;
