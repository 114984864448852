import { useQuery } from '@tanstack/react-query';

import { ICarrier } from '../../interfaces/ICarrier';
import api from '../../services/api';

const useCarrier = (carrierId: number | undefined) =>
  useQuery({
    queryKey: ['carrier', carrierId],
    queryFn: (): Promise<{ carrier: ICarrier }> => api.get(`/api/frontend/carriers/${carrierId}`),
    select: data => data?.carrier,
    enabled: !!carrierId,
    staleTime: Infinity
  });

export default useCarrier;
