import { useMutation } from '@tanstack/react-query';

import api from '../../../services/api';

interface UpdateBusinessTypeParams {
  leadId: number;
  businessType: string;
}

export const updateBusinessType = (params: UpdateBusinessTypeParams): Promise<null> =>
  api.put(`/api/frontend/leads/${params.leadId}/business_type`, {
    body: { business_type: params.businessType }
  });

const useUpdateBusinessType = (onSuccess: (data: null, variables: UpdateBusinessTypeParams) => void) =>
  useMutation({ mutationFn: updateBusinessType, onSuccess });

export default useUpdateBusinessType;
