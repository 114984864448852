import React from 'react';

import Text from '../../../components/core/Text';
import { DispositionType } from '../../../interfaces/IDisposition';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import colors from '../../../theme/colors';

const LeadDisposition = ({ leadId }: { leadId: number }) => {
  const { data: leadDispositions } = useLeadDispositions(leadId);
  const currentDisposition = leadDispositions?.current_disposition;

  return (
    <Text color={currentDisposition?.disposition_type === DispositionType.NewRequest ? colors.statusRed : colors.black}>
      {currentDisposition?.label || ''}
    </Text>
  );
};

export default LeadDisposition;
