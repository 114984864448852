/** @jsxImportSource @emotion/react */
import * as React from 'react';

import Button, { ButtonSize } from '../../components/core/buttons/Button';
import ButtonWithoutBorder from '../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import TablePlaceholder from '../../components/TablePlaceholder';
import useInfiniteNotes from '../../queries/people/person_notes/useInfiniteNotes';
import useCreatePersonNote from '../../queries/people/useCreatePersonNote';
import { spacings } from '../../theme/variables';
import NoteCreator from './NoteCreator';
import NotesList from './NotesList';

const NotesView = ({ personGid, leadGid }: { personGid: string; leadGid?: string }) => {
  const [isNotesCreatorOpened, setNotesCreatorOpened] = React.useState(false);
  const { data, hasNextPage, fetchNextPage, refetch, isFetched, isFetching } = useInfiniteNotes({
    personGid,
    perPage: 20
  });

  const notes = data?.pages.flatMap(({ notes }) => notes) || [];
  const { mutate: createNote } = useCreatePersonNote(() => {
    setNotesCreatorOpened(false);
    refetch();
  });

  if (!isFetched) {
    return <TablePlaceholder testId="customer-notes-loader" />;
  }

  return (
    <Container fitParentWidth>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Heading type="h4">Notes</Heading>
        <Button id="add-note-btn" size={ButtonSize.Small} onClick={() => setNotesCreatorOpened(true)}>
          + Add Note
        </Button>
      </FlexBox>
      <Container mt={spacings.px24}>
        <NotesList notes={notes} />
      </Container>
      {hasNextPage && (
        <Container mt={spacings.px24}>
          <ButtonWithoutBorder onClick={() => fetchNextPage()} disabled={isFetching}>
            Show more
          </ButtonWithoutBorder>
        </Container>
      )}
      {isNotesCreatorOpened && (
        <NoteCreator
          cancelHandler={() => setNotesCreatorOpened(false)}
          confirmHandler={({ kind, description }) =>
            createNote({ person_gid: personGid, kind, description, lead_gid: leadGid })
          }
        />
      )}
    </Container>
  );
};

export default NotesView;
