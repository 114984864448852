import * as React from 'react';

import Text from '../../../components/core/Text';
import { servicerOrLender } from '../../../interfaces/ILender';

const LenderNameColumn = ({ lender, servicer }: Parameters<typeof servicerOrLender>[0]) => {
  const company = servicerOrLender({ servicer, lender });

  return <Text data-testid="loan-lender_name">{company?.name || '-'}</Text>;
};

export default LenderNameColumn;
