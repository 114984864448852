import { useMutation, useQuery } from '@tanstack/react-query';

import { AtLeast, Pretty } from '../../interfaces';
import api from '../../services/api';
import { ConditionGroup } from '../scouting_strategies/useScoutingStrategy';

export const SSS_QUERY_KEY = 'scouting_strategy_steps';

export interface ScoutingStrategyStep {
  id: number;
  position: number;
  title: string | undefined;
  description: string;
  name: null | string;
  condition_groups: ConditionGroup[];
}

export type StepWithoutConditionGroups = Omit<ScoutingStrategyStep, 'condition_groups'>;

type IUpdateStep = Pretty<Partial<ScoutingStrategyStep> & { id: number | string }>;
type ICreateStep = Pretty<AtLeast<Omit<ScoutingStrategyStep, 'id'>, 'position' | 'title' | 'description'>>;

const useScoutingStrategySteps = () =>
  useQuery({
    queryKey: [SSS_QUERY_KEY],
    queryFn: (): Promise<{ scouting_strategy_steps: StepWithoutConditionGroups[] }> =>
      api.get('/api/frontend/scouting_strategy_steps'),
    select: data => data.scouting_strategy_steps
  });

export const useUpdateScoutingStrategyStep = (options?: {
  onSuccess?: (data: { scouting_strategy_step: StepWithoutConditionGroups }, variables: IUpdateStep) => void;
}) =>
  useMutation({
    mutationFn: (params: IUpdateStep) =>
      api.put(`/api/frontend/scouting_strategy_steps/${params.id}`, { body: params }),
    ...options
  });

export const useCreateScoutingStrategyStep = (options?: {
  onSuccess?: (data: { scouting_strategy_step: StepWithoutConditionGroups }, variables: ICreateStep) => void;
}) =>
  useMutation({
    mutationFn: (params: ICreateStep) => api.post('/api/frontend/scouting_strategy_steps', { body: params }),
    ...options
  });

export const useDeleteScoutingStrategyStep = (options?: {
  onSuccess?: (_data: any, variables: number | string) => void;
}) =>
  useMutation({
    mutationFn: (id: number | string) => api.delete(`/api/frontend/scouting_strategy_steps/${id}`),
    ...options
  });

export default useScoutingStrategySteps;
