export const convertNullishToEmpty = <T extends Record<string, any> | null | undefined>(obj: T, withCopy = true) => {
  if (!obj) {
    return obj;
  }

  const copy = withCopy ? { ...obj } : obj;

  Object.keys(copy).forEach(k => {
    let nestedCopy = copy[k];
    if (nestedCopy && typeof nestedCopy === 'object' && !Array.isArray(nestedCopy)) {
      nestedCopy = convertNullishToEmpty(nestedCopy, withCopy);
    }

    (copy as Record<string, any>)[k] = nestedCopy ?? '';
  });

  return copy;
};
