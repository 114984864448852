import { useFormikContext } from 'formik';
import * as React from 'react';

const ScrollToFirstError = () => {
  const { errors, submitCount } = useFormikContext();

  const firstErrorId = Object.keys(errors)[0];
  const lastSubmitCountRef = React.useRef(0);

  React.useEffect(() => {
    if (submitCount !== lastSubmitCountRef.current && firstErrorId) {
      document.getElementById(`${firstErrorId}-error`)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      lastSubmitCountRef.current = lastSubmitCountRef.current + 1;
    }
  }, [submitCount, firstErrorId]);

  return null;
};

export default ScrollToFirstError;
