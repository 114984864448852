/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ErrorIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { IGroupedQuotes } from '../../../../interfaces';
import { IAssetsQuotesGroup } from '../../../../interfaces/IGroupedQuotes';
import {
  findPolicyTypeLabel,
  isInsurableInterestRealProperty,
  isInsurableInterestVehicle
} from '../../../../interfaces/IPolicyType';
import { IQuotesRequest, SchedulingError } from '../../../../interfaces/IQuotesRequests';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import StatusTooltip from '../_components/QuoteRow/StatusTooltip';

interface IQuotingErrors {
  quotesRequest: IQuotesRequest;
  groupedErrors: IGroupedQuotes<IAssetsQuotesGroup> | undefined;
}

const QuotingErrors = ({ quotesRequest, groupedErrors }: IQuotingErrors) => {
  const groupedSchedulingErrors =
    quotesRequest.scheduling_errors?.reduce(
      (acc, schedulingError) => {
        const { assets_gids } = schedulingError;
        const assetsKey = assets_gids.join(',');

        return {
          ...acc,
          [assetsKey]: [...(acc[assetsKey] || []), schedulingError]
        };
      },
      {} as Record<string, SchedulingError[]>
    ) || {};

  const allErrors = groupedErrors?.groups?.flatMap(({ quotes }) => quotes);

  if (!quotesRequest.scheduling_errors?.length && !allErrors?.length) {
    return null;
  }

  const schedulingErrorsCount = quotesRequest.scheduling_errors?.length || 0;
  const quoteErrorsCount = allErrors?.length || 0;
  const errorsCount = schedulingErrorsCount + quoteErrorsCount;

  return (
    <Container>
      <FlexBox alignItemsCenter gap={spacings.px8}>
        <ErrorIcon color={colors.statusRed} width={spacings.px24} height={spacings.px24} />
        <Text bold>Errors ({errorsCount})</Text>
      </FlexBox>

      {Object.keys(groupedSchedulingErrors).map(key => {
        const schedulingErrors = groupedSchedulingErrors[key];
        const firstError = schedulingErrors?.[0];

        if (!firstError) {
          return null;
        }

        return (
          <div key={key}>
            <Text
              customCss={css`
                white-space: pre-wrap;
              `}
            >
              {findPolicyTypeLabel(firstError.policy_type)}
            </Text>
            <FlexBox columnDirection gap={spacings.px4} mt={spacings.px4}>
              {schedulingErrors.map(({ gid, error_message }) => (
                <Text key={gid}>{error_message}</Text>
              ))}
            </FlexBox>
          </div>
        );
      })}
      {groupedErrors?.groups?.map(({ quotes: errors }) => {
        const policyType = errors[0]!.attributes.policy_type;

        let label;

        if (isInsurableInterestRealProperty(policyType)) {
          label = 'Home';
        } else if (isInsurableInterestVehicle(policyType)) {
          label = 'Auto';
        } else {
          label = findPolicyTypeLabel(policyType);
        }

        const errorsGids = errors.map(({ gid }) => gid).join(',');

        return (
          <Container key={errorsGids} mt={spacings.px8}>
            <Paragraph bold>
              {label} ({errors.length})
            </Paragraph>
            {errors.map((quote, index) => {
              const {
                gid: quoteGid,
                attributes: { carrier }
              } = quote;
              return (
                <React.Fragment key={quoteGid}>
                  <StatusTooltip quote={quote} />
                  <Text underline type="small" data-for={`${quoteGid}-eligibility`} data-tip>
                    {carrier.name}
                  </Text>
                  <Text type="small">{index < errors.length - 1 ? ', ' : ''}</Text>
                </React.Fragment>
              );
            })}
          </Container>
        );
      })}
    </Container>
  );
};

export default React.memo(QuotingErrors);
