import React from 'react';

import { Translations } from '../../../../constants';
import { DispositionType } from '../../../../interfaces/IDisposition';
import colors from '../../../../theme/colors';
import Text from '../../../core/Text';
import ColumnGenerator from '../ColumnGenerator';

const leadDispositionCell = ({ original: { disposition_type } }: any) => (
  <Text color={disposition_type === DispositionType.NewRequest ? colors.statusRed : ''}>
    {Translations.dispositionType(disposition_type)}
  </Text>
);

const LeadDisposition = ColumnGenerator.simple({
  width: ColumnGenerator.WIDTH_XL,
  accessor: 'disposition_type',
  Header: 'Disposition',
  Cell: leadDispositionCell,
  className: 'disposition-cell'
});

export default LeadDisposition;
