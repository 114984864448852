import { useMutation, useQueryClient } from '@tanstack/react-query';

import api from '../../services/api';
import { PRODUCT_ANNOUNCEMENTS_QUERY_KEY, ProductAnnouncementsQueryParams } from './useProductAnnouncements';

const markProductAnnouncementsAsRead = ({
  productAnnouncementsIds,
  role,
  released,
  unread
}: ProductAnnouncementsQueryParams & { productAnnouncementsIds: number[] }) =>
  api.put('/api/frontend/product_announcements/mark_as_read', {
    body: { role, released, unread, product_announcements_ids: productAnnouncementsIds }
  });

const useMarkProductAnnouncementsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markProductAnnouncementsAsRead,
    onSuccess: (result, variables) =>
      queryClient.setQueryData(
        [PRODUCT_ANNOUNCEMENTS_QUERY_KEY, variables.role, variables.released, variables.unread],
        () => result
      )
  });
};

export default useMarkProductAnnouncementsAsRead;
