/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Copy } from '../../../../components/common';
import FlexBox from '../../../../components/core/FlexBox';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { phoneFormatter } from '../../../../utils/formatter';

const LoanPersonInfo = ({
  title,
  person: { name, phone, email }
}: {
  title: string;
  person: { name: string; phone: string | null; email: string | null };
}) => (
  <>
    <Text bold>{title}</Text>
    {!!name && (
      <FlexBox alignItemsCenter>
        <Text color={colors.grey60}>Name</Text>
        <Copy
          css={css`
            margin-left: 4px;
          `}
          className="fs-mask"
          displayText={name}
          value={name}
        />
      </FlexBox>
    )}
    {!!phone && (
      <FlexBox alignItemsCenter>
        <Text color={colors.grey60}>Phone</Text>
        <Copy
          css={css`
            margin-left: 4px;
          `}
          className="fs-mask"
          displayText={phoneFormatter(phone)}
          value={phoneFormatter(phone)}
        />
      </FlexBox>
    )}
    {!!email && (
      <FlexBox alignItemsCenter>
        <Text color={colors.grey60}>Email</Text>
        <Copy
          css={css`
            margin-left: 4px;
          `}
          className="fs-mask"
          displayText={email}
          value={email}
        />
      </FlexBox>
    )}
  </>
);

export default LoanPersonInfo;
