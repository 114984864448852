import { skipToken, useQuery } from '@tanstack/react-query';

import { IQuote } from '../../../interfaces/IQuote';
import api from '../../../services/api';

const getQuote = ({ personGid, quoteGid }: { personGid: string; quoteGid: string }): Promise<{ quote: IQuote }> =>
  api.get(`/api/frontend/people/${personGid}/quotes/${quoteGid}`);

const useQuote = ({ personGid, quoteGid }: { personGid: string | undefined; quoteGid: string | undefined }) =>
  useQuery({
    queryKey: ['quote', personGid, quoteGid],
    queryFn: !!personGid && !!quoteGid ? () => getQuote({ personGid: personGid!, quoteGid: quoteGid! }) : skipToken,
    select: data => data.quote
  });

export default useQuote;
