import { skipToken, useMutation, useQueries, useQuery, useQueryClient } from '@tanstack/react-query';

import { IUIFlowResponse } from '../../interfaces/IUIFlow';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export const PERSON_ASSET_UI_FLOW_QUERY_KEY = 'person_asset_ui_flow';

export const HOME_CROSS_SALE_INTENT_FLOW_V1 = 'amp_home_cross_sale_intent_v1';
export const HOME_PROFILE_FORM_V3 = 'amp_home_profile_form_v3';
export const HOME_PROFILE_FORM_V4 = 'amp_home_profile_form_v4';
export const HOME_BASIC_INFO_FLOW_V2 = 'amp_home_basic_info_main_form_v2';
export const HOME_UNDERWRITING_FLOW_MAIN_FORM = 'amp_home_underwriting_questions_main_form';
export const HOME_UNDERWRITING_FLOW_MAIN_FORM_V3 = 'amp_home_underwriting_questions_main_form_v3';
export const HOME_UNDERWRITING_FLOW_SIDEBAR_FORM = 'amp_home_underwriting_questions_sidebar_form';
export const HOME_UNDERWRITING_FLOW_SIDEBAR_FORM_V3 = 'amp_home_underwriting_questions_sidebar_form_v3';
export const SIDEBAR_HOME_BASIC_INFO_V2 = 'amp_sidebar_home_basic_info_v2';
export const SIDEBAR_HOME_EXTENDED_INFO_V1 = 'amp_sidebar_home_extended_info_v1';
export const SIDEBAR_HOME_EXTENDED_INFO_V2 = 'amp_sidebar_home_extended_info_v2';
export const LIFE_PROFILE_FLOW = 'amp_life_profile_v1';

const getPersonUIFlow = ({
  uiFlowKey,
  personGid
}: {
  uiFlowKey: string;
  personGid: string | undefined;
}): Promise<{ ui_flow: IUIFlowResponse }> =>
  api.get(`/api/frontend/ui_flows/${uiFlowKey}?${toQueryParams({ person_gid: personGid })}`);

export const usePersonUIFlow = ({ uiFlowKey, personGid }: { uiFlowKey: string; personGid: string | undefined }) =>
  useQuery({
    queryKey: [PERSON_ASSET_UI_FLOW_QUERY_KEY, uiFlowKey, personGid],
    queryFn: !!uiFlowKey && !!personGid ? () => getPersonUIFlow({ uiFlowKey, personGid }) : skipToken,
    select: data => data.ui_flow
  });

const getPersonAssetUIFlow = ({
  uiFlowKey,
  personGid,
  assetGid
}: {
  uiFlowKey: string;
  personGid: string | undefined;
  assetGid: string | undefined;
}): Promise<{ ui_flow: IUIFlowResponse }> =>
  api.get(`/api/frontend/ui_flows/${uiFlowKey}?${toQueryParams({ person_gid: personGid, asset_gid: assetGid })}`);

const usePersonAssetUIFlow = ({
  uiFlowKey,
  personGid,
  assetGid
}: {
  uiFlowKey: string;
  personGid: string | undefined;
  assetGid: string | undefined;
}) =>
  useQuery({
    queryKey: [PERSON_ASSET_UI_FLOW_QUERY_KEY, uiFlowKey, personGid, assetGid],
    queryFn:
      !!uiFlowKey && !!personGid && !!assetGid
        ? () => getPersonAssetUIFlow({ uiFlowKey, personGid, assetGid })
        : skipToken,
    select: data => data?.ui_flow
  });

export const usePersonAssetsUIFlows = ({
  uiFlowKey,
  personGid,
  assetsGids
}: {
  uiFlowKey: string;
  personGid: string | undefined;
  assetsGids: string[];
}) => {
  const data = useQueries({
    queries: assetsGids.map(assetGid => ({
      queryKey: [PERSON_ASSET_UI_FLOW_QUERY_KEY, uiFlowKey, personGid, assetGid],
      queryFn: () => getPersonAssetUIFlow({ uiFlowKey, personGid, assetGid }),
      enabled: !!uiFlowKey && !!personGid && !!assetsGids
    }))
  });
  return {
    data,
    isPending: data.some(uiFlow => uiFlow.isPending),
    isFetching: data.some(uiFlow => uiFlow.isFetching),
    isFetchedAfterMount: data.every(uiFlow => uiFlow.isFetchedAfterMount)
  };
};

export interface AnswerUpdateRequest {
  question_key: string;
  person_gid: string;
  engagement_gid: string | undefined;
  asset_gid: string | undefined;
  value: any;
}

interface AnswersUpdateRequest {
  uiFlowKey: string;
  answers: AnswerUpdateRequest[];
}

export const saveAnswers = ({ uiFlowKey, answers }: AnswersUpdateRequest): Promise<{ ui_flow: IUIFlowResponse }> =>
  api.put(`/api/frontend/ui_flows/${uiFlowKey}/answers`, {
    body: { answers }
  });

export const useSaveAnswers = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: saveAnswers,
    onSuccess: (result, variables) => {
      const answerNode = variables.answers[0];
      if (answerNode) {
        queryClient.setQueryData(
          [PERSON_ASSET_UI_FLOW_QUERY_KEY, variables.uiFlowKey, answerNode.person_gid, answerNode.asset_gid].filter(
            Boolean
          ),
          () => result
        );
        queryClient.invalidateQueries({ queryKey: [PERSON_ASSET_UI_FLOW_QUERY_KEY] });
      }
    }
  });
};

export default usePersonAssetUIFlow;
