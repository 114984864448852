import React from 'react';

import { useDeletePriorPolicy } from '../../queries/people/person_policies/usePersonPriorPolicies';
import Modal, { ModalSize } from '../core/Modal';
import Paragraph from '../core/Paragraph';

interface IHomeDelete {
  cancelBtnHandler: () => void;
  confirmBtnHandler: () => void;
  personGid: string;
  priorPolicyGid: string;
}

const PriorPolicyDeleteModal = ({
  cancelBtnHandler,
  confirmBtnHandler,
  personGid,
  priorPolicyGid
}: IHomeDelete): JSX.Element => {
  const { mutateAsync: deletePriorPolicy, isPending: isDeletingHome } = useDeletePriorPolicy();

  return (
    <Modal
      title="Do you want to delete the property?"
      dangerModal
      size={ModalSize.large}
      cancelHandler={cancelBtnHandler}
      confirmHandler={async () => {
        await deletePriorPolicy({ personGid, priorPolicyGid });
        await confirmBtnHandler();
        cancelBtnHandler();
      }}
      confirmText="Yes, delete"
      confirmationInProgress={isDeletingHome}
    >
      <Paragraph>
        You&apos;re going to delete prior policy from the list. You will not see it in the list of prior policies
        related to this customer.
      </Paragraph>
    </Modal>
  );
};

export default PriorPolicyDeleteModal;
