import * as React from 'react';

import { UploadArea } from '../../../components/common/UploadFiles';
import FlexBox from '../../../components/core/FlexBox';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { DocumentOwnerType, FileToUpload } from '../../../interfaces/IDocument';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { findPolicyType } from '../../../interfaces/IPolicyType';
import usePersonDocuments, {
  useCreateDocument,
  useDeleteDocument
} from '../../../queries/people/person_documents/usePersonDocuments';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import { spacings } from '../../../theme/variables';
import { compact } from '../../../utils/array';
import { moneyFormatter } from '../../../utils/formatter';
import DocumentsList from './DocumentsList';

const CONFIRM_DELETE_TEXT = 'Are you sure you want to delete this file?';

const generateLeadOwnerOption = (leadId: number) => ({
  key: JSON.stringify({
    owner_id: leadId,
    owner_type: DocumentOwnerType.Lead
  }),
  value: 'Other',
  default: false
});

const generatePolicyOwnersOptions = (policies: IMaticPolicy[] | undefined) =>
  policies?.map(policy => {
    const policyType = findPolicyType(policy.policy_type);
    const policyPremium = moneyFormatter(policy.premium);

    const value = compact([
      policy.status,
      policyType?.name,
      'policy.',
      `Carrier - ${policy.carrier.name}, ${policyPremium}`
    ]).join(' ');

    return {
      key: JSON.stringify({
        owner_id: policy.id,
        owner_type: DocumentOwnerType.Policy
      }),
      value,
      default: false
    };
  });

const LeadDocuments = ({ personGid, leadId }: { personGid: string; leadId: number }) => {
  const {
    data: documents,
    isFetching: isFetchingDocuments,
    refetch: refetchDocuments
  } = usePersonDocuments({ personGid, leadId });
  const { data: policies, isFetching: isFetchingPolicies } = usePersonMaticPolicies({
    personGid,
    filters: { lead_id: leadId }
  });

  const [filesToUpload, setFilesToUpload] = React.useState<FileToUpload[]>([]);

  const { mutateAsync: createDocument } = useCreateDocument({ attachDocs: false });
  const { mutateAsync: deleteDocument } = useDeleteDocument({ invalidate: false });

  const isLoading = isFetchingDocuments || isFetchingPolicies;

  const onAttachHandler = (files: FileToUpload[]) => {
    setFilesToUpload(files);

    return Promise.all(
      files.map(({ fileOwner, ...rest }) => {
        const owner = JSON.parse(fileOwner) as any;

        return createDocument({
          personGid,
          request: {
            owner_id: owner.owner_id,
            owner_type: owner.owner_type,
            ...rest
          }
        });
      })
    ).then(() => refetchDocuments().then(() => setFilesToUpload([])));
  };

  const policyOwnersOptions = generatePolicyOwnersOptions(policies);
  const leadOwnerOption = generateLeadOwnerOption(leadId);

  return isLoading ? (
    <TablePlaceholder testId="lead-files-loader" />
  ) : (
    <FlexBox fitParentWidth columnDirection gap={spacings.px16}>
      <UploadArea
        onAttach={onAttachHandler}
        ownersOptions={policyOwnersOptions?.concat([leadOwnerOption])}
        isForPolicy={false}
      />
      <DocumentsList
        documents={documents}
        filesToUpload={filesToUpload}
        onDelete={documentId =>
          window.confirm(CONFIRM_DELETE_TEXT) &&
          deleteDocument({ personGid, documentId }).then(() => refetchDocuments())
        }
      />
    </FlexBox>
  );
};

export default LeadDocuments;
