import { useInfiniteQuery } from '@tanstack/react-query';

import { INote } from '../../../interfaces/INote';
import api from '../../../services/api';

interface Meta {
  page: number;
  per_page: number;
  total: number;
  total_pages: number;
}
interface Result {
  notes: INote[];
  meta: Meta;
}

export const INFINITE_NOTES_QUERY_KEY = 'infinite-notes';

const useInfiniteNotes = ({ personGid, perPage = 5 }: { personGid: string; perPage?: number }) => {
  return useInfiniteQuery({
    queryKey: [INFINITE_NOTES_QUERY_KEY, personGid, perPage],
    queryFn: ({ pageParam }): Promise<Result> =>
      api.get(`/api/frontend/people/${personGid}/notes?page=${pageParam}&per_page=${perPage}`),
    initialPageParam: 1,
    getNextPageParam: ({ meta }) => (meta.total_pages <= meta.page ? null : meta.page + 1)
  });
};

export default useInfiniteNotes;
