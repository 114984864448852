import { Grid } from '@mui/material';
import * as yup from 'yup';

import { IDocument, IMaticPolicy, IOpportunity } from '../../interfaces';
import { getOwnerType } from '../../pages/GuidedSellingExperience/tabs/FilesTab/helpers';
import useDocumentTypes from '../../queries/document_types/useDocumentTypes';
import { UpdateDocumentRequest } from '../../queries/people/person_documents/usePersonDocuments';
import { requiredField } from '../../utils/yupRules';
import FormModal from '../core/FormModal';
import { SelectField } from '../core/forms/fields';
import { ModalSize } from '../core/Modal';
import Text from '../core/Text';

interface IEditDocumentModal {
  document: IDocument;
  toggleEditDocument: () => void;
  onUpdate: (documentId: number, request: UpdateDocumentRequest) => Promise<unknown>;
  owners: { key: string; value: string; description: string | string[] | undefined }[];
  opportunities: IOpportunity[] | undefined;
  policies: IMaticPolicy[] | undefined;
}

const EditDocumentModal = ({
  document,
  toggleEditDocument,
  onUpdate,
  owners,
  opportunities,
  policies
}: IEditDocumentModal) => {
  const { transformedData: documentTypes = [], isPending: isPendingDocumentTypes } = useDocumentTypes();
  const docTypesOptions = documentTypes.map(type => ({ key: type.id, value: type.title }));
  return (
    <FormModal
      title="Edit file"
      cancelHandler={toggleEditDocument}
      confirmText="Edit"
      size={ModalSize.large}
      confirmHandler={async values => {
        await onUpdate(document.id, {
          owner_id: values.owner_id ? parseInt(values.owner_id) : undefined,
          owner_type: getOwnerType({ fileOwner: values.owner_id, opportunities, policies }),
          document_type_id: values.document_type_id || undefined
        });
        toggleEditDocument();
      }}
      initialValues={{
        owner_id: document.owner_id?.toString(),
        document_type_id: document.document_type_id
      }}
      validationSchema={yup.object().shape({ document_type_id: requiredField, owner_id: requiredField })}
      renderForm={() => {
        return (
          <Grid container justifyContent="space-between">
            <Grid item container xs={12} justifyContent="space-between">
              <Grid item xs={3.5} alignSelf="center" mb="16px">
                <Text className="fs-mask" singleLine>
                  {document.name}
                </Text>
              </Grid>
              <Grid item xs={4}>
                <SelectField
                  label=""
                  id="document_type_id"
                  name="document_type_id"
                  options={docTypesOptions}
                  isLoading={isPendingDocumentTypes}
                  placeholder="Select doc type"
                />
              </Grid>
              <Grid item xs={4}>
                <SelectField label="" id="owner_id" name="owner_id" options={owners} placeholder="Select owner" />
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default EditDocumentModal;
