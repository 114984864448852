import { IUser } from './IUser';

export enum NoteKind {
  Memo = 'memo',
  Phone = 'phone',
  Email = 'email',
  ScheduledCall = 'scheduled_call',
  Partner = 'partner',
  System = 'system'
}

export interface INote {
  id: number;
  kind: NoteKind;
  description: string;
  created_at: string;
  agent?: IUser;
  partner_signature?: string;
}
