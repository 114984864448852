/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field, FieldProps } from 'formik';
import React from 'react';

import { spacings } from '../../../../theme/variables';
import FlexBox from '../../FlexBox';
import RadioInput, { IRadioInput } from '../../inputs/Radio';
import { labelCss } from '.';
import FormDescription from './FormDescription';
import FormError from './FormError';

interface RadioGroupFieldProps {
  id: string;
  name: string;
  options: readonly IRadioInput[];
  label?: string | (({ required }: { required: boolean | undefined }) => JSX.Element);
  required?: boolean;
  columnDirection?: boolean;
  testId?: string;
  inline?: boolean;
  preserveErrorSpace?: boolean;
  description?: string;
  fsMask?: boolean;
}

const RadioGroupField = ({
  label,
  id,
  name,
  options,
  required,
  columnDirection,
  testId,
  inline,
  preserveErrorSpace = true,
  description,
  fsMask
}: RadioGroupFieldProps): JSX.Element => {
  return (
    <fieldset>
      {label && (
        <legend
          css={css`
            ${typeof label === 'string' ? labelCss(required) : ''}
          `}
        >
          {typeof label === 'string' ? label : label({ required })}
        </legend>
      )}
      <FlexBox columnDirection={columnDirection}>
        {options.map(({ value, ...rest }, index) => (
          <Field name={name} key={value} value={value} type="radio">
            {({ field, meta, form }: FieldProps) => {
              const hasError = meta.touched && meta.error;

              const isColumnDirectionAndLastOption = columnDirection && index === options.length - 1;
              const isRowDirectionAndFirstOption = !columnDirection && index === 0;

              return (
                <div>
                  <FlexBox mr={spacings.px32} columnDirection>
                    <RadioInput
                      data-testid={`${testId}_${value}`}
                      {...field}
                      {...rest}
                      fsMask={fsMask}
                      disabled={rest.disabled || form.status === 'disabled'}
                      id={`${id}_${value}`}
                      aria-invalid={!!hasError}
                      aria-errormessage={`${id}-error`}
                    />
                  </FlexBox>

                  {(isColumnDirectionAndLastOption || isRowDirectionAndFirstOption) && (
                    <>
                      {inline && !!hasError && (
                        <FormError id={id} hasError={!!hasError} error={meta.error} singleLine />
                      )}
                      {!inline && (preserveErrorSpace || !!hasError) && (
                        <FormError id={id} hasError={!!hasError} error={meta.error} singleLine />
                      )}
                      {description && <FormDescription description={description} singleLine />}
                    </>
                  )}
                </div>
              );
            }}
          </Field>
        ))}
      </FlexBox>
    </fieldset>
  );
};

export default RadioGroupField;
