/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import colors from '../../../../theme/colors';
import Text from '../../Text';

interface ErrorProps {
  id: string;
  hasError?: boolean;
  error?: string;
  singleLine?: boolean;
}
const FormError = ({ id, hasError, error, singleLine }: ErrorProps): JSX.Element => (
  <div
    css={css`
      ${hasError ? 'visibility: visible; opacity: 1;' : 'visibility: hidden; opacity: 0;'}
      transition: visibility 0s, opacity 0.5s linear;
      ${singleLine && 'max-width: 0px;'}
      padding: 8px 0;
    `}
  >
    <Text
      id={`${id}-error`}
      type="small"
      color={colors.statusRed}
      customCss={css`
        ${singleLine && 'white-space: nowrap;'}
      `}
    >
      {error || '-'}
      {/* "-" character is needed so that Text area renders with line height even when there is no error */}
    </Text>
  </div>
);

export default FormError;
