import { useInfiniteQuery } from '@tanstack/react-query';

import { IInteraction } from '../../../interfaces/IPersonInteraction';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

interface IPersonOmniChannelInteractionsParams {
  from_created_at?: string;
  engagement_gids?: string[];
  interaction_types?: string[];
  limit?: number;
  newest_first?: boolean;
  offset?: number;
  to_created_at?: string;
  agent_ids?: number[];
}

const useInfinitePersonOmniChannelInteractions = ({
  personGid,
  params
}: {
  personGid: string | undefined;
  params?: IPersonOmniChannelInteractionsParams;
}) => {
  const limit = params?.limit || 10;
  return useInfiniteQuery({
    queryKey: ['omni_channel_interactions', personGid, params],
    queryFn: ({ pageParam }): Promise<{ interactions: IInteraction[] }> =>
      api.get(
        `/api/frontend/people/${personGid}/omni_channel_interactions?${toQueryParams({
          ...params,
          offset: pageParam.offset
        })}`
      ),
    initialPageParam: { offset: 0 },
    enabled: !!personGid,
    getNextPageParam: (lastPage, allPages) =>
      lastPage.interactions.length === limit
        ? {
            offset: allPages.length * limit
          }
        : undefined
  });
};

export default useInfinitePersonOmniChannelInteractions;
