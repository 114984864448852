/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { moneyFormatter } from '../../../utils/formatter';
import ContentSection from '../ContentSection';

const PremiumSection = ({ premium }: { premium?: number | string }): JSX.Element => {
  return (
    <ContentSection testId="premium-section">
      <FlexBox
        justifySpaceBetween
        pt={spacings.px8}
        customCss={css`
          border-top: 2px solid ${colors.grey10};
        `}
      >
        <Text type="large" bold>
          Total premium
        </Text>
        <Text type="large" bold>
          {moneyFormatter(premium)}
        </Text>
      </FlexBox>
    </ContentSection>
  );
};

export default PremiumSection;
