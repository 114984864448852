/** @jsxImportSource @emotion/react */
import React from 'react';

import amplifier from '../../assets/img/amplifier.gif';
import { spacings } from '../../theme/variables';
import FlexBox from '../core/FlexBox';
import Heading from '../core/Heading';
import { MaticLogoBroken } from '../core/icons';

const backendConnectionProvider = () =>
  window.location.host.includes('.sdm') || window.location.host.includes('.lvh') ? 'SDM' : 'VPN';

const BrokenConnectionPage = () => (
  <FlexBox alignItemsCenter justifyCenter columnDirection fitParentHeight gap={spacings.px32}>
    <img src={amplifier} alt="amplifier" data-testid="amplifier-img" />
    <FlexBox gap={spacings.px12} alignItemsCenter>
      <MaticLogoBroken width={36} height={36} />
      <Heading type="h3">{`Check your ${backendConnectionProvider()} connection.`}</Heading>
    </FlexBox>
  </FlexBox>
);

export default BrokenConnectionPage;
