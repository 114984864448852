/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import featureFlags from '../../../constants/featureFlags';
import { InsuranceCategory } from '../../../interfaces/IPolicyType';
import { LicensingTypes } from '../../../interfaces/IUser';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { ButtonSize } from '../../core/buttons/Button';
import DropDown from '../../core/buttons/DropDown';
import Container from '../../core/Container';
import FlexBox from '../../core/FlexBox';
import Heading from '../../core/Heading';
import { FilterIcon } from '../../core/icons/index';

interface Props {
  headerText: string;
  isOpen?: boolean;
  salesDashboardHeader?: boolean;
  children: React.ReactNode;
}

const FiltersSubHeader = ({
  headerText,
  isOpen = false,
  salesDashboardHeader = false,
  children
}: Props): JSX.Element => {
  const [show, setShow] = React.useState(isOpen);
  const navigate = useNavigate();
  const isUserLifeLicensed = authInfo.licensingTypes?.includes(LicensingTypes.Life);

  return (
    <Container
      p={spacings.px24}
      borderBottom
      borderColor={colors.grey10}
      customCss={css`
        background-color: ${colors.white};
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 5rem;
      `}
    >
      <FlexBox alignItemsCenter justifySpaceBetween fitParentWidth>
        <Heading type="h3">{headerText}</Heading>
        <FlexBox alignItemsCenter gap={spacings.px24}>
          {children ? (
            <div
              onClick={() => setShow(current => !current)}
              id="filtering_button"
              data-testid="filtering_button"
              css={css`
                display: inline-block;
                cursor: pointer;
              `}
            >
              <FilterIcon color={show ? colors.azure50 : undefined} />
            </div>
          ) : null}

          {salesDashboardHeader && !featureFlags.hideDashboardCreateButton && (
            <DropDown
              options={[
                {
                  label: isUserLifeLicensed ? 'Lead(Personal lines)' : 'Lead',
                  value: 'pl_lead',
                  entity: 'lead',
                  insuranceCategory: InsuranceCategory.PersonalLines
                },
                isUserLifeLicensed && {
                  label: 'Lead(Life)',
                  value: 'life_lead',
                  entity: 'lead',
                  insuranceCategory: InsuranceCategory.Life
                },
                authInfo.features.create_customer && {
                  label: 'Customer',
                  entity: 'customer',
                  value: 'customer',
                  insuranceCategory: undefined
                }
              ].filter(Boolean)}
              onSelected={selected => {
                navigate(`/new_${selected.entity}`, {
                  state: { from: 'sales_dashboard', insuranceCategory: selected.insuranceCategory }
                });
              }}
              size={ButtonSize.Small}
              optionsCss={css`
                min-width: 100px;
                right: 0;
              `}
            >
              + Create
            </DropDown>
          )}
        </FlexBox>
      </FlexBox>
      <Collapse isOpen={show}>{children}</Collapse>
    </Container>
  );
};

export default FiltersSubHeader;
