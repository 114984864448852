import { useMutation, UseMutationResult } from '@tanstack/react-query';

import api from '../../services/api';

interface PhoneRequestPayload {
  personGid: string;
  phone: string;
  secondary_phone: string;
  business_phone: string;
}
const updatePhone = async ({ personGid, phone, secondary_phone, business_phone }: PhoneRequestPayload) => {
  return await api.put(`/api/frontend/people/${personGid}`, {
    body: { phone, secondary_phone, business_phone }
  });
};

const useUpdatePersonPhone = (onSuccess: () => void): UseMutationResult<any, any, PhoneRequestPayload> =>
  useMutation({ mutationFn: updatePhone, onSuccess });

export default useUpdatePersonPhone;
