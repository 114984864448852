import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IOpportunity } from '../../../interfaces/IOpportunity';
import { InsurableInterest, Requirement } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

export const PERSON_ALL_LEADS_OPPORTUNITIES_QUERY_KEY = 'person_all_leads_opportunities';

const getPersonAllLeadsOpportunities = ({
  personGid,
  requirement,
  insurableInterest
}: {
  personGid: string | undefined;
  requirement?: Requirement;
  insurableInterest?: InsurableInterest;
}): Promise<{ opportunities: IOpportunity[] }> =>
  api.get(
    `/api/frontend/people/${personGid}/opportunities?${toQueryParams({
      requirement,
      insurable_interest: insurableInterest
    })}`
  );

const usePersonAllLeadsOpportunities = ({
  personGid,
  requirement,
  insurableInterest,
  options
}: {
  personGid: string | undefined;
  requirement?: Requirement;
  insurableInterest?: InsurableInterest;
  options?: UseQueryOptions<{ opportunities: IOpportunity[] }, any, any, any>;
}) =>
  useQuery({
    queryKey: [PERSON_ALL_LEADS_OPPORTUNITIES_QUERY_KEY, personGid, requirement, insurableInterest],
    queryFn: personGid
      ? () => getPersonAllLeadsOpportunities({ personGid, requirement, insurableInterest })
      : skipToken,
    ...options,
    select: data => data.opportunities
  });

export default usePersonAllLeadsOpportunities;
