import * as React from 'react';

function useEventListener<B extends keyof WindowEventMap, T extends HTMLElement = HTMLDivElement>(
  eventName: B,
  handler: (event: WindowEventMap[B]) => void,
  element?: React.RefObject<T>
): void {
  const savedHandler = React.useRef(handler);

  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(() => {
    const targetElement: T | Window = element?.current || window;
    if (!(targetElement && targetElement.addEventListener)) {
      return;
    }

    const eventListener = (event: WindowEventMap[B]) => {
      savedHandler.current(event);
    };

    targetElement.addEventListener(eventName, eventListener as (event: Event) => void);

    return () => {
      targetElement.removeEventListener(eventName, eventListener as (event: Event) => void);
    };
  }, [eventName, element]);
}

export default useEventListener;
