import './AddressOption.scss';

import classNames from 'classnames';
import React from 'react';

import { IAddressSuggestion } from '../../../interfaces';
import { ArrowDownIcon } from '../../core/icons';

const getOptionString = ({ street_line, secondary, city, state, zipcode, entries }: IAddressSuggestion): string => {
  const secondaryStr = secondary ? ` ${secondary}` : '';
  const entriesStr = +entries > 1 ? ` (${entries})` : '';
  const ending = [state, zipcode].filter(Boolean).join(' ');

  return `${street_line}${secondaryStr}${entriesStr} ${city}${ending ? `, ${ending}` : ''}`;
};

const renderOptionLabel = (
  inputValue = '',
  optionString: string,
  entries: number,
  name: string | undefined
): JSX.Element => {
  const inputLow = inputValue.toLowerCase().trim();
  const optionLow = optionString.toLowerCase().trim();
  let i = 0;

  for (; i < optionString.length; i++) {
    if (inputLow[i] === undefined || inputLow[i] !== optionLow[i]) {
      break;
    }
  }

  const common = optionString.slice(0, i);
  const suggested: (string | JSX.Element)[] = optionString.slice(i).split(`(${entries})`);

  if (entries > 1) {
    suggested.splice(1, 0, <span key="entries">({entries} entries)</span>);
  }

  return (
    <>
      <strong className="c-address-option__bold" data-testid="address-bold">
        {common}
      </strong>
      {suggested.map(sub => sub)}
      {name && <span className="c-address-option__name">{name}</span>}
      {entries > 1 && <ArrowDownIcon className="c-address-option__caret" />}
    </>
  );
};

interface Props {
  inputValue?: string;
  className?: string;
  option: IAddressSuggestion;
  onSelect(option: IAddressSuggestion, optionString: string): void;
}

const AddressOption: React.FC<Props> = ({ inputValue = '', option, onSelect, className }) => {
  const optionString = getOptionString(option);

  return (
    <button
      type="button"
      className={classNames('c-address-option', className)}
      data-testid="address-option"
      onClick={() => onSelect(option, optionString)}
      title={optionString}
    >
      {renderOptionLabel(inputValue, optionString, +option.entries, option.name)}
    </button>
  );
};

export default AddressOption;
