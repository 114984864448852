import { skipToken, useQuery, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';

import { ITimezone } from '../../interfaces/ITimezone';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export enum Status {
  Available = 'available',
  Unavailable = 'unavailable',
  Reserved = 'reserved',
  Past = 'past'
}

export interface ISpot {
  start_time: string;
  status: string;
}

interface IHoliday {
  name: string;
  regions: string[];
}

interface ICalendarDay {
  date: string;
  holidays: IHoliday[] | null;
  status: Status;
  spots: ISpot[];
}

export interface ICalendarData {
  available_timezones: ITimezone[];
  day: ICalendarDay;
  timezone: ITimezone;
}

const useCalendar = ({
  userTimezone,
  date,
  preferredTimezone,
  spotsDuration,
  options
}: {
  userTimezone: string;
  date?: string;
  preferredTimezone?: string;
  spotsDuration?: number;
  options?: UseQueryOptions<ICalendarData, any, any, any>;
}): UseQueryResult<ICalendarData, unknown> =>
  useQuery({
    queryKey: ['calendar', userTimezone, date, preferredTimezone, spotsDuration],
    queryFn: date
      ? (): Promise<ICalendarData> =>
          api.get(
            `/api/frontend/calendar?${toQueryParams({
              user_timezone: userTimezone,
              date,
              preferred_timezone: preferredTimezone,
              spots_duration: spotsDuration
            })}`
          )
      : skipToken,
    ...options
  });

export default useCalendar;
