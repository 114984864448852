import { QueryClientConfig } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { isNetworkError } from 'axios-retry';

import alertFromApiResponse, { genericNetworkError } from './alertFromApiResponse';

const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 3, // 3 minutes
      // Pay attention to all of the instances of `isFetchedAfterMount` in the codebase.
      // staleTime: 1000 * 60 * 3,
      refetchOnWindowFocus: false,
      retry: false,
      throwOnError: (error, _query) => {
        if (isNetworkError(error)) {
          genericNetworkError();

          return false;
        }
        if ((error as AxiosError).response?.status === 422) {
          alertFromApiResponse((error as AxiosError).response);

          return false;
        }

        return true;
      }
    },
    mutations: {
      throwOnError: error => {
        if ((error as AxiosError).response?.status === 422) {
          alertFromApiResponse((error as AxiosError).response);
          return false;
        }

        return true;
      }
    }
  }
};

export default queryClientConfig;
