// TODO @ilko.f: add specs
import './styles.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ size = 3, children, className }: any) => {
  const Component = `h${size}` as any;

  return (
    <Component className={classNames('header-component', `header-component--size-${size}`, className)}>
      {children}
    </Component>
  );
};

Header.propTypes = {
  size: PropTypes.oneOf([1, 2, 3, 4]),
  className: PropTypes.string,
  children: PropTypes.any
};

export default Header;
