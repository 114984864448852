import { compact } from '../../../../utils/array';
import { dig } from '../../../../utils/object';

export const getFieldValue = (values: any, fieldNamePrefix: string, fieldName: any) =>
  dig(values, ...compact(fieldNamePrefix.split('.')), fieldName);

/* eslint-disable prettier/prettier */
export const generateDefaultIncident = (type: any) => ({ gid, type_gid, key, state }: any) => ({
  gid,
  type,
  type_gid,
  key,
  state,
  deleted: false
});
/* eslint-enable prettier/prettier */
