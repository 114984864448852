/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Col, Row } from 'reactstrap';

import Text from '../../components/core/Text';
import useToggle from '../../hooks/useToggle';
import { INote } from '../../interfaces/INote';
import colors from '../../theme/colors';
import { dateTimeFormatter, ellipsis } from '../../utils/formatter';
import NoteKindIcon from './NoteKindIcon';

const DESCRIPTION_MAX_LENGTH = 70;

const NoteRow = ({
  current: { id, kind, description, agent, partner_signature, created_at },
  index
}: {
  current: INote;
  index: number;
}) => {
  const [isExpanded, toggleExpanded] = useToggle(false);

  return (
    <div>
      <Row key={id} className="note table-row" data-testid={`${index}-notes-row`}>
        <Col sm={1} className="table-cell" data-testid={`${index}-type-column`}>
          <span data-tip={kind} data-for="note-description-tooltip">
            <NoteKindIcon kind={kind} />
          </span>
        </Col>
        <Col sm={7} className="table-cell fs-mask" data-testid={`${index}-description-column`}>
          <div
            css={css`
              white-space: pre-line;
              word-break: break-word;
            `}
          >
            <Text>{isExpanded ? description : ellipsis(description, DESCRIPTION_MAX_LENGTH)}</Text>
            <Text
              color={colors.azure50}
              customCss={css`
                padding: 0 8px;
                cursor: pointer;
                visibility: ${description.length > DESCRIPTION_MAX_LENGTH ? 'shown' : 'hidden'};
              `}
              onClick={() => toggleExpanded()}
              data-testid={`${index}-see-more`}
            >
              {isExpanded ? 'Less' : 'More'}
            </Text>
          </div>
        </Col>
        <Col className="table-cell" data-testid={`${index}-created-by-column`}>
          <Text>{(agent && agent.name) || partner_signature}</Text>
        </Col>
        <Col className="table-cell" data-testid={`${index}-create-date-column`}>
          <Text>{dateTimeFormatter(created_at)}</Text>
        </Col>
      </Row>
    </div>
  );
};

export default NoteRow;
