/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import FlexBox from '../../../../../components/core/FlexBox';
import { CheckIcon } from '../../../../../components/core/icons';
import Paragraph from '../../../../../components/core/Paragraph';
import Text from '../../../../../components/core/Text';
import colors from '../../../../../theme/colors';

const PromotionTooltip = ({
  promotionMessage,
  promotionTitle,
  quoteGid
}: {
  promotionMessage: string;
  promotionTitle: string;
  quoteGid: string;
}) => {
  return (
    <Tooltip id={`${quoteGid}-promotion`} theme="light">
      <FlexBox width={300}>
        <CheckIcon
          color={colors.statusGreen}
          css={css`
            margin-right: 8px;
          `}
        />
        <FlexBox
          columnDirection
          customCss={css`
            max-width: 320px;
          `}
        >
          <Paragraph>{promotionTitle}</Paragraph>
          <Text color={colors.grey60} type="small">
            {promotionMessage}
          </Text>
        </FlexBox>
      </FlexBox>
    </Tooltip>
  );
};

export default PromotionTooltip;
