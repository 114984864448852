import React from 'react';

import { isLeadDataEditingForbidden } from '../../../components/DispositionsModals/dispositionsHelper';
import TablePlaceholder from '../../../components/TablePlaceholder';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import CustomerPriorPolicies from '../../Customer/CustomerPriorPolicies';

const LeadPriorPolicies = ({ personGid, leadId }: { personGid: string; leadId: number }) => {
  const { data: leadDispositions, isLoading: isLoadingDisposition } = useLeadDispositions(leadId);

  const createPolicyEnabled = !isLeadDataEditingForbidden(leadDispositions?.current_disposition?.disposition_type);

  if (isLoadingDisposition) {
    return <TablePlaceholder testId="lead-prior-policies-loader" />;
  }

  return <CustomerPriorPolicies personGid={personGid} leadId={leadId} createPolicyEnabled={createPolicyEnabled} />;
};

export default LeadPriorPolicies;
