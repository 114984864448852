/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Paragraph from '../../components/core/Paragraph';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import ButtonWithoutBorder from './buttons/ButtonWithoutBorder';
import { ChevronRight } from './icons';

const popperWidth = 400;
const maxPopperHeight = 470;
const maxDisplayedBodyContentHeight = 300;

const SideBarPopperLayout = ({
  header,
  firstSubHeader,
  firstSubHeaderTerm,
  secondSubHeaderTerm,
  secondSubHeader,
  body
}: {
  header: React.ReactNode;
  firstSubHeader: string;
  secondSubHeader?: string;
  body: React.ReactNode;
  firstSubHeaderTerm?: string;
  secondSubHeaderTerm?: string;
}): JSX.Element => {
  const [expandBody, setExpandBody] = React.useState(false);
  const [bodyContentHeight, setBodyContentHeight] = React.useState(0);

  const bodyContentRef = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setBodyContentHeight(node.getBoundingClientRect().height);
    }
  }, []);

  return (
    <FlexBox
      ph={spacings.px12}
      pv={spacings.px8}
      columnDirection
      backgroundColor={colors.white}
      roundBorder
      border
      boxShadow
      customCss={css`
        max-height: ${expandBody ? '90vh' : `${maxPopperHeight}px`};
        width: ${popperWidth}px;
      `}
    >
      <FlexBox pb={spacings.px12} justifySpaceBetween borderBottom>
        {header}
      </FlexBox>
      <Container pv={spacings.px12} borderBottom>
        <Container
          gap={spacings.px8}
          p={spacings.px4}
          css={css`
            display: grid;
            grid-template-columns: 64px 1fr;
          `}
        >
          <Paragraph
            type="small"
            color={colors.grey60}
            css={css`
              grid-column: 1;
            `}
          >
            {firstSubHeaderTerm}
          </Paragraph>
          <Paragraph
            type="small"
            css={css`
              grid-column: 2;
            `}
          >
            {firstSubHeader}
          </Paragraph>
        </Container>
        {secondSubHeader && secondSubHeaderTerm && (
          <Container
            gap={spacings.px8}
            p={spacings.px4}
            css={css`
              display: grid;
              grid-template-columns: 64px 1fr;
            `}
          >
            <Paragraph
              type="small"
              color={colors.grey60}
              css={css`
                grid-column: 1;
              `}
            >
              {secondSubHeaderTerm}
            </Paragraph>
            <Paragraph
              type="small"
              css={css`
                grid-column: 2;
              `}
            >
              {secondSubHeader}
            </Paragraph>
          </Container>
        )}
      </Container>
      <Container
        pt={spacings.px12}
        className="fs-mask"
        customCss={css`
          overflow: ${expandBody ? 'scroll' : 'hidden'};
        `}
      >
        <div ref={bodyContentRef}>{body}</div>
        {bodyContentHeight > maxDisplayedBodyContentHeight && (
          <Container
            mv={spacings.px24}
            backgroundColor={colors.white}
            customCss={css`
              position: sticky;
              bottom: 0;
              white-space: pre-wrap;
              ${expandBody && 'display:none;'}
              box-shadow: 0px 0px 40px 40px ${colors.white};
            `}
          >
            <ButtonWithoutBorder
              onClick={() => setExpandBody(true)}
              pl={spacings.px0}
              customCss={css`
                width: 100%;
                text-align: left;
              `}
            >
              <>
                Show more{' '}
                <ChevronRight
                  css={css`
                    transform: rotate(90deg);
                  `}
                />
              </>
            </ButtonWithoutBorder>
          </Container>
        )}
      </Container>
    </FlexBox>
  );
};
export default SideBarPopperLayout;
