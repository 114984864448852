import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query';

import { IUser } from '../../interfaces/IUser';
import api from '../../services/api';

export interface IFeatureFlag {
  id: number;
  feature_name: string;
  enabled: boolean;
}

interface UserRole {
  name: string;
  label: string;
}

export interface IFullFeatureFlag extends Omit<IFeatureFlag, 'enabled'> {
  description: string;
  jira_ticket: string;
  enabled_for_all: boolean;
  partially_enabled: boolean;
  agents: IUser[];
  roles: UserRole[];
}

export interface IUpdateFeatureFlagRequest {
  description: string;
  jira_ticket: string;
  enabled_for_all: boolean;
  roles: UserRole[];
  agent_ids: number[];
  id: number;
}

export const getFeatureFlags = (): Promise<{ feature_flags: IFeatureFlag[] }> => api.get('/api/frontend/feature_flags');

const FF_QUERY_KEY = 'feature-flags';
const useFeatureFlags = (options?: UseQueryOptions<{ feature_flags: IFeatureFlag[] }, any, IFeatureFlag[], any>) =>
  useQuery({
    queryKey: [FF_QUERY_KEY],
    queryFn: () => getFeatureFlags(),
    staleTime: Infinity,
    select: data => data.feature_flags,
    ...options
  });

export const useFeatureFlagsConfig = () =>
  useQuery({
    queryKey: ['feature-flags-config'],
    queryFn: (): Promise<{ feature_flags: IFullFeatureFlag[] }> => api.get('/api/frontend/feature_flags/config'),
    select: data => data.feature_flags
  });

const updateFeatureFlag = (flag: IUpdateFeatureFlagRequest): Promise<null> =>
  api.put(`/api/frontend/feature_flags/${flag.id}`, { body: flag });

export const useUpdateFeatureFlag = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateFeatureFlag,
    onSuccess: async () => {
      queryClient.refetchQueries({ queryKey: ['feature-flags-config'], exact: true });

      const data = await queryClient.fetchQuery({ queryKey: [FF_QUERY_KEY], queryFn: () => getFeatureFlags() });
      const newFlags = {} as Record<string, boolean | undefined>;
      data.feature_flags.forEach(feature_flag => (newFlags[feature_flag.feature_name] = feature_flag.enabled));
      window.__amp_feature_flags = newFlags;
    }
  });
};

export const useFeatureFlag = (flagId: number) =>
  useQuery({
    queryKey: ['feature-flag-by-id', flagId],
    queryFn: (): Promise<{ feature_flag: IFullFeatureFlag }> => api.get(`/api/frontend/feature_flags/${flagId}`),
    select: data => data.feature_flag
  });

export default useFeatureFlags;
