import { AuthError, InteractionType } from '@azure/msal-browser';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { FullStory, isInitialized as isFullstoryInitialized } from '@fullstory/browser';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { getFeatureFlags, IFeatureFlag } from '../queries/feature_flags/useFeatureFlags';
import analytics from '../services/analytics';
import authService from '../services/auth';
import { useEntraIdToken } from './entraAuthConfig';

const setupFeatureFlags = (featureFlagsData: IFeatureFlag[]) => {
  if (featureFlagsData && Object.keys(window.__amp_feature_flags).length === 0) {
    featureFlagsData.forEach(
      feature_flag => (window.__amp_feature_flags[feature_flag.feature_name] = feature_flag.enabled)
    );
  }
};

const authenticationRequest = {
  scopes: ['User.Read'],
  forceRefresh: true
};

const useRefreshTokenEveryHalfHourQuery = ({
  acquireToken
}: {
  acquireToken: ReturnType<typeof useMsalAuthentication>['acquireToken'];
}) =>
  useQuery({
    queryKey: ['msal-auth-refresh-token-query'],
    queryFn: () => acquireToken(InteractionType.Silent, authenticationRequest),
    refetchInterval: 1000 * 60 * 30,
    refetchIntervalInBackground: true,
    staleTime: 1000 * 60 * 30,
    initialData: () => null as Awaited<ReturnType<typeof acquireToken>>,
    throwOnError: false
  });

const EntraUserAuthenticationState = ({
  isAuthenticatedToBackend,
  setIsAuthenticatedToBackend
}: {
  isAuthenticatedToBackend: boolean;
  setIsAuthenticatedToBackend: (isAuthenticatedToBackend: boolean) => void;
}) => {
  const { instance } = useMsal();

  const navigate = useNavigate();

  // arguments MUST be stable values, see hook's implementation
  const { login, result, error, acquireToken } = useMsalAuthentication(InteractionType.Silent, authenticationRequest);
  const { error: errorFromQuery } = useRefreshTokenEveryHalfHourQuery({ acquireToken });

  React.useEffect(() => {
    if (result) {
      instance.setActiveAccount(result.account);
      useEntraIdToken.setState({ idToken: result.idToken });
      localStorage.setItem('entraIdToken', result.idToken);
    }

    if (result && !isAuthenticatedToBackend) {
      const authInAmp = async () => {
        try {
          await authService.authenticate();

          const featureFlagsData = await getFeatureFlags();
          setupFeatureFlags(featureFlagsData.feature_flags);
          setIsAuthenticatedToBackend(true);
        } catch (err) {
          setIsAuthenticatedToBackend(false);
        }
      };

      authInAmp();
    }
  }, [instance, result, isAuthenticatedToBackend, setIsAuthenticatedToBackend]);

  const navigateRef = React.useRef(navigate);

  React.useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  React.useEffect(() => {
    const derivedError = error || errorFromQuery;

    if (derivedError instanceof AuthError) {
      // eslint-disable-next-line no-console
      console.error('AuthError', derivedError);
      isFullstoryInitialized() && FullStory('trackEvent', { name: 'AuthError', properties: { error: derivedError } });

      login(derivedError.errorCode === 'popup_window_error' ? InteractionType.Redirect : InteractionType.Popup, {
        ...authenticationRequest,
        prompt: 'select_account'
      }).catch(() => {
        navigateRef.current('/login');
      });
    }
  }, [login, error, errorFromQuery]);

  const location = useLocation();

  React.useEffect(() => {
    analytics.page(location);
  }, [location]);

  return null;
};

export default EntraUserAuthenticationState;
