/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';

import colors, { opacities } from '../../theme/colors';
import { borderRadius as defaultBorderRadius, Spacings, spacings } from '../../theme/variables';
import FlexBox from './FlexBox';
import Text, { TextProps } from './Text';

interface TagProps extends Spacings {
  label: JSX.Element | string;
  prefix?: JSX.Element;
  backgroundColor?: string;
  textColor?: string;
  textType?: TextProps['type'];
  customCss?: SerializedStyles;
  testId?: string;
  transparent?: boolean;
  border?: boolean;
  bold?: boolean;
  borderRadius?: number;
  singleLine?: TextProps['singleLine'];
}

const Tag = ({
  testId,
  label,
  prefix,
  backgroundColor = colors.statusOrange,
  textColor = colors.white,
  textType,
  customCss,
  transparent = false,
  border = false,
  borderRadius,
  singleLine = true,
  bold,
  ...rest
}: TagProps): JSX.Element => {
  return (
    <FlexBox
      {...rest}
      alignItemsCenter
      backgroundColor={transparent ? `${backgroundColor}${opacities.opacity_10}` : backgroundColor}
      pv={spacings.px4}
      ph={spacings.px8}
      gap={spacings.px4}
      customCss={css`
        border-radius: ${borderRadius || (transparent ? defaultBorderRadius : 40)}px;
        width: fit-content;
        ${border && `border: 1px solid ${textColor}`};
        ${customCss};
      `}
    >
      {prefix}
      <Text
        data-testid={testId}
        type={textType || (transparent ? 'tiny' : 'small')}
        bold={!transparent || bold}
        color={textColor}
        singleLine={singleLine}
      >
        {label}
      </Text>
    </FlexBox>
  );
};

export default Tag;
