import { ClassNames } from '@emotion/react';

import { ActionsCell, ColumnGenerator, Copy, Link, Note, Status, Table, Task, Tooltip } from '../../components/common';
import FlexBox from '../../components/core/FlexBox';
import Tag from '../../components/core/Tag';
import TelephonyActions from '../../components/core/TelephonyActions';
import TelephonyTooltip from '../../components/core/TelephonyTooltip';
import Text from '../../components/core/Text';
import { INFO_NOT_PRESENT, Translations } from '../../constants';
import { PolicyTypes } from '../../interfaces/IPolicyType';
import { DashboardPolicy, PoliciesDashboardSorting } from '../../queries/policies/usePolicies';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { dateFormatter, ellipsis, moneyFormatter, phoneFormatter } from '../../utils/formatter';

interface PoliciesListProps {
  policies: DashboardPolicy[] | undefined;
  defaultSorting: PoliciesDashboardSorting;
  onSortingChange: (sorting: PoliciesDashboardSorting) => void;
}

const ELLIPSIS_COUNT = 20;

const renderPolicyDetails = (policy: DashboardPolicy) => {
  const policyType = PolicyTypes.find(type => type.key === policy.policy_type)!;

  return (
    <>
      <FlexBox gap={spacings.px8} justifySpaceBetween>
        <Text color={colors.white}>Type</Text>
        <Text color={colors.white}>{policyType.name}</Text>
      </FlexBox>
      <FlexBox gap={spacings.px8} justifySpaceBetween>
        <Text color={colors.white}>Premium</Text>
        <Text color={colors.white}>{moneyFormatter(policy.premium, true)}</Text>
      </FlexBox>
      <FlexBox gap={spacings.px8} justifySpaceBetween>
        <Text color={colors.white}>Coverage</Text>
        <Text color={colors.white}>
          {policyType.with_primary_coverage && policy.primary_coverage
            ? moneyFormatter(policy.primary_coverage, true)
            : INFO_NOT_PRESENT}
        </Text>
      </FlexBox>
      <FlexBox gap={spacings.px8} justifySpaceBetween>
        <Text color={colors.white}>Policy #</Text>
        <Text className="fs-mask" color={colors.white}>
          {policy.policy_number}
        </Text>
      </FlexBox>
    </>
  );
};

const renderStatusCell = ({ original }: { original: DashboardPolicy }) => (
  <Status
    className={original.status}
    tooltipInfo={{
      status: Translations.policyStatus(original.status),
      reason: Translations.policyCancellationReason(original.cancellation_reason),
      date: dateFormatter(original.cancellation_date)
    }}
  />
);

const renderLeadIdCell = ({ original: { lead_id, source } }: { original: DashboardPolicy }) => {
  return lead_id ? <Link to={`/leads/${lead_id}`}>{lead_id}</Link> : <Tag label={Translations.policySource(source)} />;
};

const renderAgentCell = ({ original: { agent } }: { original: DashboardPolicy }) => {
  if (!agent) {
    return null;
  }

  return (
    <Tooltip message={agent.name} left>
      {ellipsis(agent.name)}
    </Tooltip>
  );
};

const renderPolicyNumberCell = ({ original }: { original: DashboardPolicy }) => (
  <Tooltip message={renderPolicyDetails(original)} className="fs-mask" left>
    <Link
      to={{
        pathname: `/leads/${original.lead_id}/policies`
      }}
    >
      {ellipsis(original.policy_number, ELLIPSIS_COUNT)}
    </Link>
  </Tooltip>
);

const renderCustomerCell = ({ original: { person } }: { original: DashboardPolicy }) => (
  <Link to={{ pathname: `/people/${person.gid}/policies` }} className="fs-mask">
    {ellipsis(person.name, ELLIPSIS_COUNT)}
  </Link>
);

const renderCarrierCell = ({ original: { carrier } }: { original: DashboardPolicy }) => (
  <Tooltip message={carrier.name} left>
    {ellipsis(carrier.name)}
  </Tooltip>
);

const renderEffectiveDateCell = ({ original: { effective_date } }: { original: DashboardPolicy }) =>
  dateFormatter(effective_date);

const renderExpirationDateCell = ({ original: { expiration_date } }: { original: DashboardPolicy }) =>
  dateFormatter(expiration_date);

const renderCancellationDateCell = ({ original: { cancellation_date } }: { original: DashboardPolicy }) =>
  dateFormatter(cancellation_date);

const renderCustomerPhoneCell = ({
  original: {
    person: { phone, gid, do_not_contact }
  }
}: {
  original: DashboardPolicy;
}) => {
  if (!phone) {
    return null;
  }

  const canCallWithDNC = authInfo.features.do_not_contact_call_available;

  const dnc = do_not_contact && (
    <Text color={colors.statusRed} data-testid="do-not-contact">
      Do not contact
    </Text>
  );

  const number = (!do_not_contact || canCallWithDNC) && (
    <FlexBox>
      <TelephonyTooltip phone={phone} personGid={gid}>
        <ClassNames>
          {({ css }) => (
            <Copy
              value={phoneFormatter(phone)}
              className={
                do_not_contact && authInfo.features.do_not_contact_call_available
                  ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                  : 'fs-mask'
              }
            />
          )}
        </ClassNames>
      </TelephonyTooltip>
    </FlexBox>
  );

  return number || dnc;
};

const renderCustomerEmailCell = ({
  original: {
    person: { email, gid, do_not_contact }
  }
}: {
  original: DashboardPolicy;
}) => {
  if (!email) {
    return null;
  }

  const canCallWithDNC = authInfo.features.do_not_contact_call_available;

  const dnc = do_not_contact && (
    <Text color={colors.statusRed} data-testid="do-not-contact">
      Do not contact
    </Text>
  );

  const emailNode = (!do_not_contact || canCallWithDNC) && (
    <FlexBox>
      <TelephonyActions email={email} personGid={gid}>
        <ClassNames>
          {({ css }) => (
            <Copy
              value={email}
              displayText={ellipsis(email, ELLIPSIS_COUNT)}
              className={
                do_not_contact && authInfo.features.do_not_contact_call_available
                  ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                  : 'fs-mask'
              }
            />
          )}
        </ClassNames>
      </TelephonyActions>
    </FlexBox>
  );

  return emailNode || dnc;
};

const renderLastActionsCell = ({ original: { latest_note, latest_task, lead_id } }: { original: DashboardPolicy }) => (
  <ActionsCell className="fs-mask">
    <Note note={latest_note && latest_note.description} url={`/leads/${lead_id}/notes`} />
    <Task task={latest_task} url={`/leads/${lead_id}/tasks`} />
  </ActionsCell>
);

const columns = () => [
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XS,
    accessor: 'policy_status',
    sortable: false,
    Cell: renderStatusCell
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_S,
    accessor: 'lead_id',
    Header: 'Lead',
    Cell: renderLeadIdCell
  }),
  ColumnGenerator.stretchable({
    accessor: 'agent',
    Header: 'Agent of record',
    Cell: renderAgentCell
  }),
  ColumnGenerator.stretchable({
    minWidth: ColumnGenerator.WIDTH_XL,
    accessor: 'policy_number',
    Header: 'Policy #',
    Cell: renderPolicyNumberCell
  }),
  ColumnGenerator.stretchable({
    accessor: 'customer',
    Header: 'Customer',
    sortable: false,
    Cell: renderCustomerCell
  }),
  ColumnGenerator.stretchable({
    accessor: 'carrier',
    Header: 'Carrier',
    Cell: renderCarrierCell
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XL,
    accessor: 'effective_date',
    Header: 'Effective Date',
    Cell: renderEffectiveDateCell
  }),
  ColumnGenerator.simple({
    width: 110,
    accessor: 'expiration_date',
    Header: 'Expiration',
    Cell: renderExpirationDateCell
  }),
  ColumnGenerator.simple({
    width: 120,
    accessor: 'cancellation_date',
    Header: 'Cancel date',
    Cell: renderCancellationDateCell
  }),
  ColumnGenerator.simple({
    width: ColumnGenerator.WIDTH_XL,
    accessor: 'customer_phone',
    Header: 'Customer phone',
    sortable: false,
    Cell: renderCustomerPhoneCell
  }),
  ColumnGenerator.stretchable({
    accessor: 'customer_email',
    Header: 'Customer email',
    Cell: renderCustomerEmailCell,
    sortable: false,
    className: 'elipsis'
  }),
  ColumnGenerator.simple({
    sortable: false,
    Cell: renderLastActionsCell,
    width: ColumnGenerator.WIDTH_XS
  })
];

const PoliciesList = ({ policies, defaultSorting, onSortingChange }: PoliciesListProps) => {
  return (
    <Table
      data={policies}
      onSortingChange={onSortingChange}
      defaultSorting={defaultSorting}
      columns={columns()}
      noDataMessage="No policies found"
    />
  );
};

export default PoliciesList;
