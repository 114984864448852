import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import usePolicyTransactionPremiums from '../../../queries/policies/usePolicyTransactionPremiums';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';

const usePolicyTransactionFullTermPremium = (
  transaction: PolicyTransaction,
  lineOfBusiness: LineOfBusiness
): number | undefined => {
  const { data: premiums } = usePolicyTransactionPremiums(transaction.policy_id, transaction.id);
  const premium = premiums?.find(premium => premium.line_of_business_acord_type === lineOfBusiness.acord_type);

  return premium?.full_term_premium;
};

export default usePolicyTransactionFullTermPremium;
