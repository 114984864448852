import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { ILeadDispositions } from '../../../interfaces/IDisposition';
import api from '../../../services/api';
import { LEAD_DISPOSITIONS_QUERY_KEY } from './useLeadDispositions';

interface RollbackDispositionRequest {
  leadId: string | number;
}

const rollbackDisposition = (data: RollbackDispositionRequest): Promise<ILeadDispositions> =>
  api.put(`/api/frontend/leads/${data.leadId}/rollback_disposition`);

const useRollbackDisposition = (
  onSuccess?: () => void
): UseMutationResult<ILeadDispositions, any, RollbackDispositionRequest> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: rollbackDisposition,
    onSuccess: (result, variables) => {
      queryClient.setQueryData([LEAD_DISPOSITIONS_QUERY_KEY, variables.leadId?.toString()], () => result);
      onSuccess?.();
    }
  });
};

export default useRollbackDisposition;
