/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { useToggle } from '../../hooks';
import Container from './Container';
import FlexBox from './FlexBox';
import { VisibilityOffIcon } from './icons';
import Text from './Text';

interface HiddenTextProps {
  children: React.ReactNode;
  hiddenValue?: string;
}

const HiddenText = ({ children, hiddenValue }: HiddenTextProps) => {
  const [isValueHidden, toggleIsValueHidden] = useToggle(true);

  React.useEffect(() => {
    if (!isValueHidden) {
      const hide = setTimeout(() => {
        toggleIsValueHidden();
      }, 10000);

      return () => {
        clearTimeout(hide);
      };
    }
  }, [isValueHidden, toggleIsValueHidden]);

  return (
    <FlexBox alignItemsCenter>
      {isValueHidden ? (
        <Container
          customCss={css`
            filter: blur(0.3rem);
          `}
        >
          <Text type="small" singleLine>
            {hiddenValue || 'Hidden text'}
          </Text>
        </Container>
      ) : (
        children
      )}
      {isValueHidden && (
        <VisibilityOffIcon
          onClick={toggleIsValueHidden}
          css={css`
            cursor: pointer;
          `}
        />
      )}
    </FlexBox>
  );
};

export default HiddenText;
