/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import moment from 'moment';
import * as React from 'react';

import Spinner from '../../components/common/Spinner/Spinner';
import Tooltip from '../../components/common/Tooltip/NewTooltip';
import Calendar from '../../components/core/Calendar/Calendar';
import FlexBox from '../../components/core/FlexBox';
import { CalendarIcon } from '../../components/core/icons';
import Select from '../../components/core/inputs/Select';
import { CallStatus } from '../../interfaces/IScheduledCall';
import { ITimezone } from '../../interfaces/ITimezone';
import useCalendar, { ISpot, Status } from '../../queries/scheduled_calls/useCalendar';
import useScheduledInteractions from '../../queries/scheduled_calls/useScheduledInteractions';
import authInfo, { isISR } from '../../services/authInfo';
import colors from '../../theme/colors';
import { borderRadius, spacings } from '../../theme/variables';
import { dateFormatter, DISPLAY_TIME_FORMAT, INTERNAL_DATE_FORMAT, userTimezone } from '../../utils/formatter';
import { AGENT_SPOT_DURATION, buildTimezonesOptions, findTimezoneOption } from './helpers';

interface CalendarModalProps {
  onChange: (spotTime: string) => void;
  value: Date | null | undefined;
  personTimezone: ITimezone | null | undefined;
  assigneeEmail?: string;
  scheduledCallTime?: string;
}

interface ICalendarSpot extends ISpot {
  unavailableForISR?: boolean;
  unavailableForAgent?: boolean;
  tooltip?: string;
}

enum Meridiems {
  AM = 'AM',
  PM = 'PM'
}

const activeMeridiemCss = css`
  color: ${colors.black};
  background-color: ${colors.white};
  font-weight: bold;
  border-radius: ${borderRadius}px;
`;

const headerGrid = css`
  height: 44px;
  align-items: center;
  margin-bottom: ${spacings.px16}px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  gap: ${spacings.px12}px ${spacings.px12}px;
  grid-template-areas: 'a a a b';
`;

const spotsGrid = css`
  color: ${colors.black};
  overflow: auto;
  max-height: 100%;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: ${spacings.px8}px ${spacings.px8}px;
  grid-template-areas:
    '. . . .'
    '. . . .'
    '. . . .';
`;

const backgroundColor = (spot: ISpot, spotUnavailableForAgent?: boolean) =>
  `${
    spot.status !== Status.Available
      ? `repeating-linear-gradient(-45deg,
    ${colors.white},
    ${colors.white} 2px,
    ${colors.grey10} 2px,
    ${colors.grey10} 4px
  )`
      : spotUnavailableForAgent && colors.grey10
  }`;

const CalendarModal = ({ onChange, value, personTimezone, assigneeEmail }: CalendarModalProps): JSX.Element => {
  const [date, setDate] = React.useState<Date>(moment(value).toDate());
  const [meridiem, setMeridiem] = React.useState<Meridiems>(Meridiems.AM);
  const [preferredTimezoneName, setPreferredTimezoneName] = React.useState<string | undefined>(personTimezone?.name);
  const isISRRole = isISR();
  const spotsDuration = isISRRole ? 5 : AGENT_SPOT_DURATION;
  const [options, setOptions] = React.useState<Record<string, string | undefined>[]>([]);
  const { data: calendar, isFetched: isCalendarFetched } = useCalendar({
    userTimezone: userTimezone.name,
    date: moment(date).format(INTERNAL_DATE_FORMAT),
    preferredTimezone: preferredTimezoneName,
    spotsDuration
  });

  React.useEffect(() => {
    if (calendar) {
      const timezonesOptions = buildTimezonesOptions(calendar, personTimezone);
      const selectedTimezoneOption = findTimezoneOption(calendar, timezonesOptions);
      if (selectedTimezoneOption) {
        setPreferredTimezoneName(selectedTimezoneOption.name);
      }
      setOptions(
        timezonesOptions.map(value => ({
          key: value.name,
          value: value.display_name || value.name,
          postfix: value.postfix
        }))
      );
    }
  }, [calendar, personTimezone]);

  const calendarAssigneeEmail = assigneeEmail || authInfo.currentUserEmail;

  const { data: scheduledCallbacks } = useScheduledInteractions({
    assignees_emails: [calendarAssigneeEmail as unknown as string],
    max_run_at: moment(date).format(INTERNAL_DATE_FORMAT),
    min_run_at: moment(date).format(INTERNAL_DATE_FORMAT),
    page: 1,
    statuses: [CallStatus.Ready, CallStatus.InDialer, CallStatus.Scheduled]
  });

  const filteredCalendarSpots = React.useMemo(() => {
    const filteredSpots: ICalendarSpot[] | undefined = calendar?.day.spots.filter(spot =>
      moment.parseZone(spot.start_time).format(DISPLAY_TIME_FORMAT).endsWith(meridiem.toLowerCase())
    );

    filteredSpots?.forEach(spot => {
      const spotStartTime = moment(spot.start_time);

      scheduledCallbacks?.scheduled_interactions.forEach(scheduledCallback => {
        const scheduledCallbackStartTime = moment(scheduledCallback.run_at);

        const unavailableForISR = spotStartTime.format() === scheduledCallbackStartTime.format();

        const unavailableForAgent =
          moment.duration(spotStartTime.diff(scheduledCallbackStartTime)).asMinutes() === AGENT_SPOT_DURATION;

        const tooltip = `${moment.parseZone(spot.start_time).format(DISPLAY_TIME_FORMAT)} - ${
          scheduledCallback.person_name
        }`;

        if (unavailableForISR) {
          spot.tooltip = tooltip;
          spot.unavailableForISR = unavailableForISR;
        }
        if (unavailableForAgent) {
          spot.tooltip = tooltip;
          spot.unavailableForAgent = unavailableForAgent;
        }
      });
    });

    return filteredSpots;
  }, [calendar, meridiem, scheduledCallbacks]);

  React.useEffect(() => {
    if (isCalendarFetched && !filteredCalendarSpots?.length) {
      setMeridiem(meridiem => (meridiem === Meridiems.AM ? Meridiems.PM : Meridiems.AM));
    }
    /* eslint-disable-next-line */ // Won't work after changing to AM meridiem with no slots
  }, [isCalendarFetched]);

  const holiday = calendar?.day?.holidays?.[0]?.name;
  const renderSpotsHeader = (
    <div css={headerGrid} data-testid="spots-header-container">
      <div
        css={css`
          grid-area: a;
          text-align: center;
          font-weight: bold;
          color: ${colors.black};
        `}
      >
        {holiday ? `${dateFormatter(date, 'ddd, D')} - ${holiday}` : dateFormatter(date, 'ddd, D')}
      </div>
      <FlexBox
        p={spacings.px4}
        justifyEvenly
        roundBorder
        backgroundColor={colors.grey30}
        customCss={css`
          grid-area: b;
        `}
        data-testid="meridiems-container"
      >
        {Object.values(Meridiems).map(m => (
          <span
            key={m}
            onClick={() => setMeridiem(m)}
            css={[
              css`
                color: ${colors.white};
                cursor: pointer;
                padding: 0 2px;
                &:hover {
                  opacity: 0.8;
                }
              `,
              meridiem === m && activeMeridiemCss
            ]}
          >
            {m}
          </span>
        ))}
      </FlexBox>
    </div>
  );

  const useSpots = (data: ICalendarSpot[] | undefined) => {
    const [spotTime, setSpotTime] = React.useState<string>();

    React.useLayoutEffect(() => {
      if (data) {
        document
          .getElementById(data?.find(spot => spot.status === Status.Available)?.start_time || '')
          ?.scrollIntoView();
      }
    }, [data]);

    const spots = data?.map(spot => {
      const spotUnavailableForAgent = !isISRRole && spot.unavailableForAgent;

      return spotTime === spot.start_time ? (
        <span
          key={`${spot.start_time} - confirm`}
          css={css`
            text-align: center;
            background-color: ${colors.azure50};
            border-radius: ${borderRadius}px;
            border: solid 1px;
            cursor: pointer;
            font-weight: 700;
            color: ${colors.white};
            padding: ${spacings.px4}px;

            &:hover {
              opacity: 0.8;
            }
          `}
          onClick={() => onChange(spot.start_time)}
        >
          Confirm
        </span>
      ) : (
        <React.Fragment key={spot.start_time}>
          <span
            id={spot.start_time}
            data-tip={spot.tooltip}
            data-for={spot.start_time + date}
            css={css`
              text-align: center;
              border: solid 1px ${colors.grey10};
              border-radius: ${borderRadius}px;
              cursor: pointer;
              padding: ${spacings.px4}px;
              background: ${backgroundColor(spot, spotUnavailableForAgent)};
              &:hover {
                opacity: 0.8;
              }
              opacity: ${spotUnavailableForAgent ? 0.8 : 1};
              color: ${spotUnavailableForAgent ? colors.grey80 : colors.black};
            `}
            onClick={() => setSpotTime(spot.start_time)}
          >
            {moment.parseZone(spot.start_time).format(DISPLAY_TIME_FORMAT)}
            {isISRRole && spot.unavailableForISR && (
              <CalendarIcon
                height={16}
                css={css`
                  position: relative;
                  bottom: 2px;
                  left: 2px;
                `}
              />
            )}
            {spotUnavailableForAgent && (
              <CalendarIcon
                height={16}
                css={css`
                  position: relative;
                  bottom: 2px;
                  left: 2px;
                `}
                color={colors.grey60}
              />
            )}
          </span>
          {spot.tooltip && <Tooltip id={spot.start_time + date} />}
        </React.Fragment>
      );
    });

    return (
      <>
        {spots?.[0] && (
          <div css={spotsGrid} data-testid="spots-container">
            {spots}
          </div>
        )}
        {!!spots || (
          <div
            css={css`
              position: relative;
              height: 100%;
            `}
          >
            <Spinner />
          </div>
        )}
        {spots && !spots[0] && 'No spots available'}
      </>
    );
  };

  return (
    <FlexBox p={spacings.px16}>
      <FlexBox columnDirection justifySpaceBetween p={spacings.px8}>
        <Calendar
          onDateChange={date => {
            if (Array.isArray(date)) {
              return;
            }

            setDate(moment(date).toDate());
          }}
          date={date}
          maxDate={moment().add(3, 'months').toDate()}
          minDate={moment().toDate()}
          size="default"
        />
        <Select
          placeholder="Current timezone"
          onChange={(zone: string) => setPreferredTimezoneName(zone)}
          value={preferredTimezoneName}
          options={options}
          formatOptionLabel={({ label, postfix }: any) => (
            <>
              <span>
                {label}
                {postfix && (
                  <span
                    css={css`
                      color: ${colors.grey30};
                      margin-left: ${spacings.px12}px;
                    `}
                  >
                    {postfix}
                  </span>
                )}
              </span>
            </>
          )}
          controlTestId="current_timezone"
        />
      </FlexBox>
      <FlexBox
        p={spacings.px8}
        columnDirection
        customCss={css`
          flex: 1;
          height: 380px;
        `}
      >
        {renderSpotsHeader}
        {useSpots(filteredCalendarSpots)}
      </FlexBox>
    </FlexBox>
  );
};

export default CalendarModal;
