/** @jsxImportSource @emotion/react */
import 'react-table-6/react-table.css';
import './Table.scss';

import { css, Global } from '@emotion/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import ReactTable from 'react-table-6';

import arrowDownIcon from '../../../assets/new_icons/arrow-down.svg';
import arrowUpIcon from '../../../assets/new_icons/arrow-up.svg';
import { SORT_ORDER_ASC, SORT_ORDER_DESC } from '../../../constants';
import { EmptyResults } from '..';

const DEFAULT_PAGE_SIZE = 25;

class Table extends React.Component<any, any> {
  onSortingChange = (sorting: any) => {
    if (!sorting.length) {
      return null;
    }

    (this.props as any).onSortingChange(this.normalizeSorting(sorting));
  };

  normalizeSorting = ([sorting]: any) => ({
    sort_column: sorting.id,
    sort_order: sorting.desc ? SORT_ORDER_DESC : SORT_ORDER_ASC
  });

  denormalizeSorting = (sorting: any) =>
    sorting
      ? [
          {
            id: sorting.sort_column,
            desc: sorting.sort_order === SORT_ORDER_DESC
          }
        ]
      : undefined;

  render = () => {
    const { data, noDataMessage, className, manual, loading, testId, defaultSorting } = this.props as any;

    if (!data.length && !loading) {
      return noDataMessage ? <EmptyResults message={noDataMessage} /> : null;
    }

    return (
      <>
        <Global
          styles={() => css`
            .react-table .rt-thead .rt-th.-sort-asc::after {
              mask-image: url(${arrowUpIcon});
            }
            .react-table .rt-thead .rt-th.-sort-desc::after {
              mask-image: url(${arrowDownIcon});
            }
          `}
        />
        <ReactTable
          {...this.props}
          data={data}
          loading={loading}
          showPagination={false}
          resizable={false}
          //@ts-expect-error TODO
          onSortedChange={manual ? this.onSortingChange : null}
          defaultSorted={this.denormalizeSorting(defaultSorting)}
          pageSize={DEFAULT_PAGE_SIZE}
          className={classNames('react-table', className)}
          minRows={0}
          manual={manual}
          getProps={(): { ['data-testid']: string } => ({ 'data-testid': testId })}
          //@ts-expect-error TODO
          getTrGroupProps={(_tableState, rowInfo) => ({ 'data-testid': `${rowInfo.index}-row-testid` })}
        />
      </>
    );
  };
}
//@ts-expect-error TODO
Table.propTypes = {
  onSortingChange: PropTypes.func,
  defaultSorting: PropTypes.object,
  columns: PropTypes.array.isRequired,
  noDataMessage: PropTypes.string,
  className: PropTypes.string,
  data: PropTypes.array,
  manual: PropTypes.bool,
  loading: PropTypes.bool,
  testId: PropTypes.string
};

//@ts-expect-error TODO
Table.defaultProps = {
  defaultSorting: undefined,
  onSortingChange: null,
  noDataMessage: null,
  className: '',
  data: [],
  manual: true,
  loading: false,
  testId: ''
};

export default Table;
