import React from 'react';

import Container from '../../../components/core/Container';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { IPerson, IRelatedPerson } from '../../../interfaces/IPerson';
import { updatePerson } from '../../../queries/people/usePerson';
import { updatePersonDrivingRecord } from '../../../queries/people/usePersonDrivingRecord';
import usePersonRelatedPeople, {
  createRelatedPerson,
  updateRelationKind
} from '../../../queries/people/usePersonRelatedPeople';
import analytics from '../../../services/analytics';
import EditRelatedPersonModal from './EditRelatedPersonModal';
import RelatedPeopleList from './RelatedPeopleList';

const CustomerRelatedPeople = ({ person }: { person: IPerson }) => {
  const {
    data: relatedPeople,
    isLoading: isLoadingRelatedPeople,
    refetch: refetchRelatedPeople
  } = usePersonRelatedPeople(person.gid);

  const [editorOpened, setEditorOpened] = React.useState<boolean>(false);
  const [editedPerson, setEditedPerson] = React.useState<IRelatedPerson>();

  const closeEditor = () => {
    setEditedPerson(undefined);
    setEditorOpened(false);
  };

  if (isLoadingRelatedPeople) {
    return <TablePlaceholder testId="customer-related-people-loader" />;
  }

  return (
    <Container fitParentWidth>
      <RelatedPeopleList
        personGid={person.gid}
        relatedPeople={relatedPeople || []}
        onEditPerson={editedPerson => {
          setEditedPerson(editedPerson);
          setEditorOpened(true);
        }}
        onAddPerson={() => setEditorOpened(true)}
        onPeopleMerged={() => refetchRelatedPeople()}
      />
      {person && editorOpened && (
        <EditRelatedPersonModal
          person={person}
          relatedPerson={editedPerson}
          onClose={() => closeEditor()}
          onUpdatePerson={({ editedRelatedPerson, personRelation, drivingRecord }) => {
            return updatePerson(editedRelatedPerson)
              .then(() =>
                updateRelationKind({
                  sourcePersonGid: person.gid,
                  relatedPersonGid: editedRelatedPerson.gid,
                  relationKind: personRelation.kind,
                  cocustomer: personRelation.cocustomer
                })
              )
              .then(() => updatePersonDrivingRecord({ personGid: editedRelatedPerson.gid, drivingRecord }))
              .then(() => {
                refetchRelatedPeople();
                closeEditor();
              });
          }}
          onCreatePerson={({ newRelatedPerson, drivingRecord }) =>
            createRelatedPerson({ sourcePersonGid: person.gid, data: newRelatedPerson }).then(({ related_person }) => {
              analytics.track('Related person added', {
                lead_gid: undefined,
                person_gid: person.gid,
                place: 'old_customer_profile_ui'
              });
              return updatePersonDrivingRecord({ personGid: related_person.gid, drivingRecord }).then(() => {
                refetchRelatedPeople();
                closeEditor();
              });
            })
          }
        />
      )}
    </Container>
  );
};

export default CustomerRelatedPeople;
