import { create } from 'zustand';

interface ISelectedQuote {
  selectedQuoteGid: string | undefined;
  setSelectedQuoteGid: (selectedQuoteGid: string | undefined) => void;
}

const useSelectedQuote = create<ISelectedQuote>()(set => ({
  selectedQuoteGid: undefined,
  setSelectedQuoteGid: selectedQuoteGid => set({ selectedQuoteGid })
}));

export default useSelectedQuote;
