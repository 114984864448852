import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { doesPolicyTypeRequireAsset } from '../../../interfaces/IPolicyType';
import usePoliciesDetails from '../../../queries/policies/usePoliciesDetails';
import AssetDetailsPlaceholder from '../AssetDetailsPlaceholder';
import NonSyncedPolicyAssetsContainer from '../NonSyncedPolicyAssetsContainer';

const NonSyncedPolicyAssetsDetails = ({ personGid }: { personGid: string }): JSX.Element => {
  const { isCurrentPolicyLoaded, currentPolicy } = usePoliciesDetails(personGid);

  return (
    <>
      {!isCurrentPolicyLoaded && <AssetDetailsPlaceholder />}
      {isCurrentPolicyLoaded && currentPolicy && doesPolicyTypeRequireAsset(currentPolicy.policy_type) && (
        <FlexBox columnDirection>
          <NonSyncedPolicyAssetsContainer policy={currentPolicy} />
        </FlexBox>
      )}
    </>
  );
};

export default NonSyncedPolicyAssetsDetails;
