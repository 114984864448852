import * as React from 'react';

import SystemMessage from '../../../components/core/Alert/SystemMessage';
import analytics from '../../../services/analytics';
import { spacings } from '../../../theme/variables';
import { useDisplayCrossSellWarning } from '../../GuidedSellingExperience/_hooks';

const CrossSellWarning = () => {
  const { displayWarning, isCrossSell, leadGid } = useDisplayCrossSellWarning();

  const trackWarningMessage = React.useCallback(
    (node: HTMLDivElement | null) => {
      if (node) {
        analytics.track('Cross-sell warning is shown', { lead_gid: leadGid });
      }
    },
    [leadGid]
  );

  if (!displayWarning) {
    return null;
  }

  return (
    <SystemMessage
      type="warning"
      heading={`Looks like this customer already ha${isCrossSell ? 's' : 'd'} a policy with Matic`}
      mb={spacings.px16}
      description="Initiate a transfer to Customer Service for this customer's additional support needs."
      innerRef={trackWarningMessage}
    />
  );
};
export default CrossSellWarning;
