/** @jsxImportSource @emotion/react */
import * as React from 'react';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import useAutoPolicyDrivers from '../../../queries/policies/useAutoPolicyDrivers';
import usePolicyAutoCoverages from '../../../queries/policies/usePolicyAutoCoverages';
import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import usePolicyVehicles from '../../../queries/policies/usePolicyVehicles';
import BasicInfoSection from '../BasicInfoSection';
import CoverageSection from '../CoverageSection';
import DiscountsSection from '../DiscountsSection';
import {
  autoDeductiblesSelector,
  autoDiscountsSelector,
  autoExcludedSelector,
  autoOtherLimitsSelector,
  autoSpecialLiabilityLimitsSelector
} from '../helpers';
import usePolicyTransactionFullTermPremium from '../hooks/usePolicyTransactionFullTermPremium';
import PolicyDetailsSectionPlaceholder from '../PolicyDetailsSectionPlaceholder';
import PremiumSection from '../PremiumSection';
import VehicleSection from '../VehicleSection';

const AutoLineOfBusiness = ({
  policy,
  lineOfBusiness,
  lastTransaction
}: {
  policy: IMaticPolicy;
  lineOfBusiness: LineOfBusiness;
  lastTransaction: PolicyTransaction;
}): JSX.Element => {
  const { data: vehicles, isLoading: isLoadingVehicles } = usePolicyVehicles(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id
  );
  const { data: insuredDrivers } = useAutoPolicyDrivers(
    lastTransaction.policy_id,
    lineOfBusiness.line_of_business_id,
    lastTransaction.effective_date
  );

  const premium = usePolicyTransactionFullTermPremium(lastTransaction, lineOfBusiness);

  const { data: deductibles, isLoading: isLoadingDeductibles } = usePolicyAutoCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    {
      select: autoDeductiblesSelector
    }
  );
  const { data: excluded, isLoading: isLoadingExcluded } = usePolicyAutoCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    {
      select: autoExcludedSelector
    }
  );
  const { data: specialLiabilityLimits, isLoading: isLoadingSpecialLiabilityLimits } = usePolicyAutoCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    {
      select: autoSpecialLiabilityLimitsSelector
    }
  );
  const { data: otherLimits, isLoading: isLoadingOtherLimits } = usePolicyAutoCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    {
      select: autoOtherLimitsSelector
    }
  );
  const { data: discounts, isLoading: isLoadingDiscounts } = usePolicyAutoCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    {
      select: autoDiscountsSelector
    }
  );

  const showLoader =
    isLoadingDeductibles ||
    isLoadingExcluded ||
    isLoadingSpecialLiabilityLimits ||
    isLoadingOtherLimits ||
    isLoadingDiscounts ||
    isLoadingVehicles;

  return (
    <>
      <BasicInfoSection policy={policy} lineOfBusiness={lineOfBusiness} insureds={insuredDrivers} />
      {showLoader ? (
        <PolicyDetailsSectionPlaceholder linesCount={10} />
      ) : (
        <>
          {!!discounts?.length && <DiscountsSection discounts={discounts} />}
          {typeof premium === 'number' && <PremiumSection premium={premium} />}
          {!!deductibles?.length && <CoverageSection heading="Deductibles" isDeductible coverages={deductibles} />}
          {!!excluded?.length && <CoverageSection heading="Excluded" coverages={excluded} />}
          {!!specialLiabilityLimits?.length && (
            <CoverageSection heading="Special liability limits" coverages={specialLiabilityLimits} />
          )}
          {!!otherLimits?.length && <CoverageSection heading="Other limits" coverages={otherLimits} />}
          {!!vehicles?.length &&
            vehicles.map((vehicle: any) => (
              <VehicleSection
                key={vehicle.id}
                lineOfBusiness={lineOfBusiness}
                lastTransaction={lastTransaction}
                vehicle={vehicle}
              />
            ))}
        </>
      )}
    </>
  );
};

export default AutoLineOfBusiness;
