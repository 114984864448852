import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { ILeadDispositions } from '../../../interfaces/IDisposition';
import api from '../../../services/api';
import { LEAD_DISPOSITIONS_QUERY_KEY } from './useLeadDispositions';

export interface DispositionUpdate {
  leadId: number | string;
  disposition_type: string;
  disposition_reason?: string;
  agent_id?: number;
}

const updateLeadDisposition = (data: DispositionUpdate): Promise<ILeadDispositions> =>
  api.put(`/api/frontend/leads/${data.leadId}/disposition`, {
    body: data
  });

const useUpdateLeadDisposition = (
  onSuccess?: () => void
): UseMutationResult<ILeadDispositions, any, DispositionUpdate> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateLeadDisposition,
    onSuccess: (result, variables) => {
      queryClient.setQueryData([LEAD_DISPOSITIONS_QUERY_KEY, variables.leadId?.toString()], () => result);
      onSuccess?.();
    }
  });
};

export default useUpdateLeadDisposition;
