import React from 'react';

import Container from '../../components/core/Container';
import Heading from '../../components/core/Heading';
import TablePlaceholder from '../../components/TablePlaceholder';
import { useDocumentTitle } from '../../hooks';
import useRecentLeads from '../../queries/leads/useRecentLeads';
import useUpdateLeadStarred from '../../queries/leads/useUpdateLeadStarred';
import RecentLeadManager from '../../services/RecentLeadManager';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import DashboardLeadsList from './DashboardLeadsList';

const RecentLeadsDashboard = () => {
  const { data: recentLeads, isLoading, refetch: refetchRecentLeads } = useRecentLeads(RecentLeadManager.list);
  const { mutate: updateLeadStarred } = useUpdateLeadStarred(() => refetchRecentLeads());
  useDocumentTitle('Recent leads');

  return (
    <Container fitParentWidth>
      <Container p={spacings.px24} borderBottom borderColor={colors.grey10}>
        <Heading type="h3">Recent Leads</Heading>
      </Container>
      <Container fitParentWidth mt={spacings.px8} ph={spacings.px8}>
        {isLoading ? (
          <TablePlaceholder testId="recent-leads-dashboard-loader" />
        ) : (
          <DashboardLeadsList
            leads={recentLeads}
            onStarredChange={(leadId: number, starred: boolean) => updateLeadStarred({ leadId, starred })}
            manual={false}
          />
        )}
      </Container>
    </Container>
  );
};

export default RecentLeadsDashboard;
