import { skipToken, useQuery } from '@tanstack/react-query';

import api from '../../services/api';

export interface ILeadLicensingStatus {
  is_user_licensed: boolean;
  primary_state: string | null;
}

const getLeadLicensingStatus = (leadId: number | undefined): Promise<ILeadLicensingStatus> =>
  api.get(`/api/frontend/leads/${leadId}/licensing_status`);

export const LEAD_LICENSING_STATUS_QUERY_KEY = 'lead_licensing_status';

const useLeadLicensingStatus = (leadId: number | undefined) =>
  useQuery({
    queryKey: [LEAD_LICENSING_STATUS_QUERY_KEY, leadId],
    queryFn: leadId ? () => getLeadLicensingStatus(leadId) : skipToken,
    select: data => data
  });

export default useLeadLicensingStatus;
