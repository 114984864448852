import { useMutation } from '@tanstack/react-query';

import { ICallLog } from '../../interfaces';
import api from '../../services/api';

export const createJohnDoeLead = (callLogId: number): Promise<{ call_log: ICallLog | undefined }> =>
  api.put(`/api/frontend/call_logs/${callLogId}/john_doe_lead`);

const useCreateJohnDoeLead = () => useMutation({ mutationFn: createJohnDoeLead });

export default useCreateJohnDoeLead;
