export enum ILeadMode {
  RegularLead = 'regular',
  TestLead = 'test',
  FakeLead = 'fake'
}

export interface IDashboardLead {
  id: number;
  lead_id: number;
  lead_gid: string;
  starred: boolean;
  phone: string | null;
  residential_state: string | null;
  disposition_type: string | null;
  disposition_reason: string | null;

  lender_name: string | null;

  agent_id: string | null;

  customer_id: number;
  customer_gid: string;
  customer_name: string;
  do_not_contact: boolean | null;

  partner: string;
  source_product: string;
  lead_mode: ILeadMode;

  latest_task_id: number | null;
  latest_task_kind: string | null;
  latest_task_description: string | null;
  latest_task_completed: boolean | null;

  latest_note_id: number | null;
  latest_note_description: string | null;

  created_at: string;
  test_mode: boolean;
}
