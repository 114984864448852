import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactstrap';

import { DeleteIcon } from '../../../core/icons';

const FilesListItem = ({ file, onDelete }: any) => {
  return (
    <div className="files-list-item" key={file.uid}>
      <div className="files-list-item__info fs-mask">
        {file.name}
        <span className="files-list-item__label ml-2">{file.document_type_title}</span>
      </div>
      <div className="files-list-item__controls">
        <Button onClick={() => onDelete(file)}>
          <DeleteIcon />
        </Button>
      </div>
    </div>
  );
};

FilesListItem.propTypes = {
  file: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    document_type_title: PropTypes.string.isRequired
  }),
  onDelete: PropTypes.func
};

export default FilesListItem;
