import DatapointKey, { DatapointsUsageKey, YES_VALUE } from '../../../interfaces/IDatapoint';
import { useAnswers } from '../../../queries/answers/useAnswers';

const usePersonCoreBundleInterest = ({ personGid }: { personGid: string }) => {
  const { data: answers, isLoading } = useAnswers({
    datapointsUsageKey: DatapointsUsageKey.PersonHasCoreBundleInterest,
    personGid,
    assetGid: null,
    relatedPersonGid: null
  });

  const isPersonInterestedInCoreBundle =
    answers?.find(({ key }) => key === DatapointKey.PersonHasCoreBundleInterest)?.value === YES_VALUE;

  return { isPersonInterestedInCoreBundle, isLoading };
};

export default usePersonCoreBundleInterest;
