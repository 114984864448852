import { Navigate, useParams } from 'react-router-dom';

import useLead from '../../queries/leads/useLead';

const LeadGidRedirect = () => {
  const { gid } = useParams();
  const { data } = useLead(gid);

  if (!data?.id) {
    return null;
  }

  return <Navigate to={`/leads/${data.id}`} replace />;
};

export default LeadGidRedirect;
