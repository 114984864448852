import { css } from '@emotion/react';

import colors from '../../../../theme/colors';
import AddressField from './Address';
import CheckboxField from './CheckboxField';
import DateInputField from './DateInputField';
import InputField from './InputField';
import MultiSelectField from './MultiSelectField';
import NumericField from './NumericField';
import PhoneField from './PhoneField';
import RadioGroupField from './RadioGroupField';
import SelectField from './SelectField';
import TextareaField from './TextareaField';
import TimeField from './TimeField';

export const labelCss = (required: boolean | undefined) => css`
  ${required ? `&::after { content: '*'; color: ${colors.statusRed}; }` : ''}
`;
export {
  AddressField,
  CheckboxField,
  DateInputField,
  InputField,
  MultiSelectField,
  NumericField,
  PhoneField,
  RadioGroupField,
  SelectField,
  TextareaField,
  TimeField
};
