import * as Sentry from '@sentry/react';

const adapters = {
  logger: self.console,
  WebSocket: self.WebSocket
};

const logger = {
  enabled: false,
  log(...messages: any[]) {
    if (this.enabled) {
      messages.push(Date.now());
      adapters.logger.log('[ActionCable]', ...messages);
    }
  }
};

const now = () => new Date().getTime();

const secondsSince = (time: number) => (now() - time) / 1e3;

function createWebSocketURL(url: string | (() => string)) {
  if (typeof url === 'function') {
    url = url();
  }
  return url;
}

function getConfig(name: string | number) {
  const element = document.head.querySelector(`meta[name='action-cable-${name}']`);
  if (element) {
    return element.getAttribute('content');
  }
}

const staleThreshold = 6;
const reconnectionBackoffRate = 0.15;

class ConnectionMonitor {
  connection: Connection;
  reconnectAttempts: number;
  startedAt: number;
  stoppedAt: number;
  pingedAt: number;
  disconnectedAt: number;
  pollTimeout: NodeJS.Timeout | undefined;

  constructor(connection: Connection) {
    this.visibilityDidChange = this.visibilityDidChange.bind(this);
    this.connection = connection;
    this.reconnectAttempts = 0;
    this.startedAt = 0;
    this.stoppedAt = 0;
    this.pingedAt = 0;
    this.disconnectedAt = 0;
    this.pollTimeout = undefined;
  }
  start() {
    if (!this.isRunning()) {
      this.startedAt = now();

      this.startPolling();
      addEventListener('visibilitychange', this.visibilityDidChange);
      logger.log(`ConnectionMonitor started. stale threshold = ${staleThreshold} s`);
    }
  }
  stop() {
    if (this.isRunning()) {
      this.stoppedAt = now();
      this.stopPolling();
      removeEventListener('visibilitychange', this.visibilityDidChange);
      logger.log('ConnectionMonitor stopped');
    }
  }
  isRunning() {
    return this.startedAt && !this.stoppedAt;
  }
  recordPing() {
    this.pingedAt = now();
  }
  recordConnect() {
    this.reconnectAttempts = 0;
    this.recordPing();

    logger.log('ConnectionMonitor recorded connect');
  }
  recordDisconnect() {
    this.disconnectedAt = now();
    logger.log('ConnectionMonitor recorded disconnect');
  }
  startPolling() {
    this.stopPolling();
    this.poll();
  }
  stopPolling() {
    clearTimeout(this.pollTimeout);
  }
  poll() {
    this.pollTimeout = setTimeout(() => {
      this.reconnectIfStale();
      this.poll();
    }, this.getPollInterval());
  }
  getPollInterval() {
    const backoff = Math.pow(1 + reconnectionBackoffRate, Math.min(this.reconnectAttempts, 10));
    const jitterMax = this.reconnectAttempts === 0 ? 1 : reconnectionBackoffRate;
    const jitter = jitterMax * Math.random();
    return staleThreshold * 1e3 * backoff * (1 + jitter);
  }
  reconnectIfStale() {
    if (this.connectionIsStale()) {
      logger.log(
        `ConnectionMonitor detected stale connection. reconnectAttempts = ${
          this.reconnectAttempts
        }, time stale = ${secondsSince(this.refreshedAt)} s, stale threshold = ${staleThreshold} s`
      );
      this.reconnectAttempts++;
      if (this.disconnectedRecently()) {
        logger.log(
          `ConnectionMonitor skipping reopening recent disconnect. time disconnected = ${secondsSince(
            this.disconnectedAt
          )} s`
        );
      } else {
        logger.log('ConnectionMonitor reopening');
        this.connection.reopen();
      }
    }
  }
  get refreshedAt() {
    return this.pingedAt ? this.pingedAt : this.startedAt;
  }
  connectionIsStale() {
    return secondsSince(this.refreshedAt) > staleThreshold;
  }
  disconnectedRecently() {
    return this.disconnectedAt && secondsSince(this.disconnectedAt) < staleThreshold;
  }
  visibilityDidChange() {
    if (document.visibilityState === 'visible') {
      setTimeout(() => {
        if (this.connectionIsStale() || !this.connection.isOpen()) {
          logger.log(
            // eslint-disable-next-line max-len
            `ConnectionMonitor reopening stale connection on visibilitychange. visibilityState = ${document.visibilityState}`
          );
          this.connection.reopen();
        }
      }, 200);
    }
  }
}

const INTERNAL = {
  message_types: {
    welcome: 'welcome',
    disconnect: 'disconnect',
    ping: 'ping',
    confirmation: 'confirm_subscription',
    rejection: 'reject_subscription'
  },
  disconnect_reasons: {
    unauthorized: 'unauthorized',
    invalid_request: 'invalid_request',
    server_restart: 'server_restart',
    remote: 'remote'
  },
  default_mount_path: '/cable',
  protocols: ['actioncable-v1-json', 'actioncable-unsupported']
};

const { message_types: message_types, protocols: protocols } = INTERNAL;

const supportedProtocols = protocols.slice(0, protocols.length - 1);

const indexOf = [].indexOf;

const reopenDelay = 500;
class Connection {
  consumer: Consumer;
  subscriptions: Subscriptions;
  monitor: ConnectionMonitor;
  disconnected: boolean;
  webSocket: WebSocket | undefined;
  events: any;
  constructor(consumer: Consumer) {
    this.open = this.open.bind(this);
    this.consumer = consumer;
    this.subscriptions = this.consumer.subscriptions;
    this.monitor = new ConnectionMonitor(this);
    this.disconnected = true;
    this.webSocket = undefined;
    this.events = {
      message(event: any) {
        if (!this.isProtocolSupported()) {
          return;
        }
        const { identifier, message, reason, reconnect, type } = JSON.parse(event.data) as any;
        switch (type) {
          case message_types.welcome:
            if (this.triedToReconnect()) {
              this.reconnectAttempted = true;
            }
            this.monitor.recordConnect();
            return this.subscriptions.reload();

          case message_types.disconnect:
            logger.log(`Disconnecting. Reason: ${reason}`);
            return this.close({
              allowReconnect: reconnect
            });

          case message_types.ping:
            return this.monitor.recordPing();

          case message_types.confirmation:
            this.subscriptions.confirmSubscription(identifier);
            if (this.reconnectAttempted) {
              this.reconnectAttempted = false;
              return this.subscriptions.notify(identifier, 'connected', {
                reconnected: true
              });
            } else {
              return this.subscriptions.notify(identifier, 'connected', {
                reconnected: false
              });
            }

          case message_types.rejection:
            return this.subscriptions.reject(identifier);

          default:
            return this.subscriptions.notify(identifier, 'received', message);
        }
      },
      open() {
        logger.log(`WebSocket onopen event, using '${this.getProtocol()}' subprotocol`);
        this.disconnected = false;
        if (!this.isProtocolSupported()) {
          logger.log('Protocol is unsupported. Stopping monitor and disconnecting.');
          return this.close({
            allowReconnect: false
          });
        }
      },
      close(_event: any) {
        logger.log('WebSocket onclose event');
        if (this.disconnected) {
          return;
        }
        this.disconnected = true;
        this.monitor.recordDisconnect();
        return this.subscriptions.notifyAll('disconnected', {
          willAttemptReconnect: this.monitor.isRunning()
        });
      },
      error() {
        logger.log('WebSocket onerror event');
      }
    };
  }
  send(data: any) {
    if (this.isOpen() && this.webSocket) {
      this.webSocket.send(JSON.stringify(data));
      return true;
    } else {
      return false;
    }
  }
  open() {
    if (this.isActive()) {
      logger.log(`Attempted to open WebSocket, but existing socket is ${this.getState()}`);
      return false;
    } else {
      const socketProtocols = [...protocols, ...(this.consumer.subprotocols || [])];
      logger.log(`Opening WebSocket, current state is ${this.getState()}, subprotocols: ${socketProtocols}`);
      if (this.webSocket) {
        this.uninstallEventHandlers();
      }
      this.webSocket = new adapters.WebSocket(this.consumer.url, socketProtocols);
      this.installEventHandlers();
      this.monitor.start();
      return true;
    }
  }
  close(
    { allowReconnect: allowReconnect } = {
      allowReconnect: true
    }
  ) {
    if (!allowReconnect) {
      this.monitor.stop();
    }
    if (this.isOpen() && this.webSocket) {
      return this.webSocket.close();
    }
  }
  reopen() {
    logger.log(`Reopening WebSocket, current state is ${this.getState()}`);
    if (this.isActive()) {
      try {
        return this.close();
      } catch (error) {
        logger.log('Failed to reopen WebSocket', error);
        Sentry.captureMessage('Failed to reopen WebSocket');
        Sentry.captureException(error);
      } finally {
        logger.log(`Reopening WebSocket in ${reopenDelay}ms`);
        setTimeout(this.open, reopenDelay);
      }
    } else {
      return this.open();
    }
  }
  getProtocol() {
    if (this.webSocket) {
      return this.webSocket.protocol;
    }
  }
  isOpen() {
    return this.isState('open');
  }
  isActive() {
    return this.isState('open', 'connecting');
  }
  triedToReconnect() {
    return this.monitor.reconnectAttempts > 0;
  }
  isProtocolSupported() {
    // @ts-expect-error meh
    return indexOf.call(supportedProtocols, this.getProtocol()) >= 0;
  }
  isState(...states: any[]) {
    // @ts-expect-error meh
    return indexOf.call(states, this.getState()) >= 0;
  }
  getState() {
    if (this.webSocket) {
      for (const state in adapters.WebSocket) {
        // @ts-expect-error meh
        if (adapters.WebSocket[state] === this.webSocket.readyState) {
          return state.toLowerCase();
        }
      }
    }
    return null;
  }
  installEventHandlers() {
    for (const eventName in this.events) {
      const handler = this.events[eventName].bind(this);

      // @ts-expect-error meh
      this.webSocket[`on${eventName}`] = handler;
    }
  }
  uninstallEventHandlers() {
    for (const eventName in this.events) {
      // @ts-expect-error meh
      this.webSocket[`on${eventName}`] = function () {};
    }
  }
}

const extend = function (object: any, properties: any) {
  if (properties !== null) {
    for (const key in properties) {
      const value = properties[key];
      object[key] = value;
    }
  }
  return object;
};

class Subscription {
  consumer: Consumer;
  identifier: string;
  constructor(consumer: Consumer, params = {}, mixin: any) {
    this.consumer = consumer;
    this.identifier = JSON.stringify(params);
    extend(this, mixin);
  }
  perform(action: string, data: Record<string, string> = {}) {
    data.action = action;
    return this.send(data);
  }
  send(data: any) {
    return this.consumer.send({
      command: 'message',
      identifier: this.identifier,
      data: JSON.stringify(data)
    });
  }
  unsubscribe() {
    return this.consumer.subscriptions.remove(this);
  }
}

class SubscriptionGuarantor {
  subscriptions: Subscriptions;
  pendingSubscriptions: Subscription[];
  retryTimeout: NodeJS.Timeout | undefined;
  constructor(subscriptions: Subscriptions) {
    this.subscriptions = subscriptions;
    this.pendingSubscriptions = [];
  }
  guarantee(subscription: Subscription) {
    if (this.pendingSubscriptions.indexOf(subscription) === -1) {
      logger.log(`SubscriptionGuarantor guaranteeing ${subscription.identifier}`);
      this.pendingSubscriptions.push(subscription);
    } else {
      logger.log(`SubscriptionGuarantor already guaranteeing ${subscription.identifier}`);
    }
    this.startGuaranteeing();
  }
  forget(subscription: Subscription) {
    logger.log(`SubscriptionGuarantor forgetting ${subscription.identifier}`);
    this.pendingSubscriptions = this.pendingSubscriptions.filter(s => s !== subscription);
  }
  startGuaranteeing() {
    this.stopGuaranteeing();
    this.retrySubscribing();
  }
  stopGuaranteeing() {
    clearTimeout(this.retryTimeout);
  }
  retrySubscribing() {
    this.retryTimeout = setTimeout(() => {
      if (this.subscriptions && typeof this.subscriptions.subscribe === 'function') {
        this.pendingSubscriptions.forEach(subscription => {
          logger.log(`SubscriptionGuarantor resubscribing ${subscription.identifier}`);
          this.subscriptions.subscribe(subscription);
        });
      }
    }, 500);
  }
}

class Subscriptions {
  consumer: Consumer;
  // guarantor: SubscriptionGuarantor;
  subscriptions: Subscription[];
  constructor(consumer: Consumer) {
    this.consumer = consumer;
    // this.guarantor = new SubscriptionGuarantor(this);
    this.subscriptions = [];
  }
  create(channelName: string | object, mixin: any) {
    const channel = channelName;
    const params =
      typeof channel === 'object'
        ? channel
        : {
            channel
          };
    const subscription = new Subscription(this.consumer, params, mixin);
    return this.add(subscription);
  }
  add(subscription: Subscription) {
    this.subscriptions.push(subscription);
    this.consumer.ensureActiveConnection();
    this.notify(subscription, 'initialized');
    this.subscribe(subscription);
    return subscription;
  }
  remove(subscription: Subscription) {
    this.forget(subscription);
    if (!this.findAll(subscription.identifier).length) {
      this.sendCommand(subscription, 'unsubscribe');
    }
    return subscription;
  }
  reject(identifier: string) {
    return this.findAll(identifier).map(subscription => {
      this.forget(subscription);
      this.notify(subscription, 'rejected');
      return subscription;
    });
  }
  forget(subscription: Subscription) {
    // this.guarantor.forget(subscription);
    this.subscriptions = this.subscriptions.filter(s => s !== subscription);
    return subscription;
  }
  findAll(identifier: string) {
    return this.subscriptions.filter(s => s.identifier === identifier);
  }
  reload() {
    return this.subscriptions.map(subscription => this.subscribe(subscription));
  }
  notifyAll(callbackName: string, ...args: any[]) {
    return this.subscriptions.map(subscription => this.notify(subscription, callbackName, ...args));
  }
  notify(subscription: Subscription, callbackName: string, ...args: any[]) {
    let subscriptions;
    if (typeof subscription === 'string') {
      subscriptions = this.findAll(subscription);
    } else {
      subscriptions = [subscription];
    }
    return subscriptions.map(subscription =>
      // @ts-expect-error meh
      typeof subscription[callbackName] === 'function' ? subscription[callbackName](...args) : undefined
    );
  }
  subscribe(subscription: Subscription) {
    if (this.sendCommand(subscription, 'subscribe')) {
      // this.guarantor.guarantee(subscription);
    }
  }
  confirmSubscription(identifier: string) {
    logger.log(`Subscription confirmed ${identifier}`);
    // this.findAll(identifier).map(subscription => this.guarantor.forget(subscription));
  }
  sendCommand(subscription: Subscription, command: string) {
    const { identifier } = subscription;
    return this.consumer.send({
      command,
      identifier
    });
  }
}

class Consumer {
  _url: string | (() => string);
  connection: Connection;
  subprotocols: string[];
  subscriptions: Subscriptions;
  constructor(url: string | (() => string)) {
    this._url = url;
    this.subscriptions = new Subscriptions(this);
    this.connection = new Connection(this);
    this.subprotocols = [];
  }
  get url() {
    return createWebSocketURL(this._url);
  }
  send(data: any) {
    return this.connection.send(data);
  }
  connect() {
    return this.connection.open();
  }
  disconnect() {
    return this.connection.close({
      allowReconnect: false
    });
  }
  ensureActiveConnection() {
    if (!this.connection.isActive()) {
      return this.connection.open();
    }
  }
  addSubProtocol(subprotocol: string) {
    this.subprotocols = [...this.subprotocols, subprotocol];
  }
}

function createConsumer(url: string | (() => string) = getConfig('url') || INTERNAL.default_mount_path) {
  return new Consumer(url);
}

export {
  adapters,
  Connection,
  ConnectionMonitor,
  Consumer,
  createConsumer,
  createWebSocketURL,
  getConfig,
  INTERNAL,
  logger,
  Subscription,
  SubscriptionGuarantor,
  Subscriptions
};
