import * as React from 'react';

import FlexBox from '../../../../../components/core/FlexBox';
import { OpenInNewIcon } from '../../../../../components/core/icons';
import Text from '../../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import useBrand from '../../../../../queries/partners/useBrand';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';

const BrandInfo = () => {
  const { sourceDimensions } = useGuidedSellingExperienceContext();
  const { brand } = useBrand(sourceDimensions?.brand as string);

  if (!brand || sourceDimensions?.brand === 'unknown') {
    return null;
  }

  return (
    <>
      <Text type="large" bold>
        Partner
      </Text>
      <FlexBox mt={spacings.px12}>
        <FlexBox mr={spacings.px6}>
          <Text>{brand.name}</Text>
        </FlexBox>
        {brand.website && (
          <a href={brand.website} rel="noreferrer" target="_blank">
            <OpenInNewIcon color={colors.azure50} />
          </a>
        )}
        {brand.definition && (
          <FlexBox ml={spacings.px16}>
            <Text>{brand.definition}</Text>
          </FlexBox>
        )}
      </FlexBox>
      {brand.description && (
        <FlexBox mt={spacings.px12}>
          <Text color={colors.grey60}>{brand.description}</Text>
        </FlexBox>
      )}
    </>
  );
};

export default BrandInfo;
