/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { Field, FieldProps } from 'formik';

import { QuestionVerificationStatus } from '../../../../interfaces/IWorkflow';
import colors from '../../../../theme/colors';
import { InputSize, LabelSize, spacings } from '../../../../theme/variables';
import FlexBox from '../../FlexBox';
import { WarningIcon } from '../../icons';
import Input, { InputProps } from '../../inputs/Base';
import Text from '../../Text';
import FormError from './FormError';
import LabeledInput from './LabeledInput';

interface InputFieldProps extends InputProps {
  label: string | JSX.Element;
  id: string;
  name: string;
  required?: boolean;
  description?: string;
  testId?: string;
  secondary?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  verificationStatus?: QuestionVerificationStatus;
  warning?: string;
  preserveErrorSpace?: boolean;
}
const InputField = ({
  label,
  id,
  name,
  required,
  description,
  testId,
  inline,
  secondary = false,
  customLabelCss,
  labelSize,
  inputSize,
  verificationStatus,
  warning,
  preserveErrorSpace = true,
  ...rest
}: InputFieldProps): JSX.Element => (
  <LabeledInput
    id={id}
    required={required}
    label={label}
    inline={inline}
    secondary={secondary}
    disabled={rest.disabled}
    customCss={customLabelCss}
    labelSize={labelSize}
    inputSize={inputSize}
    verificationStatus={verificationStatus}
  >
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => {
        const hasError = meta.error && meta.touched;

        return (
          <>
            <Input
              data-testid={testId}
              {...field}
              {...rest}
              disabled={rest.disabled || form.status === 'disabled'}
              inputSize={inputSize}
              id={id}
              inline={inline}
              hasError={!!hasError}
              aria-invalid={!!hasError}
              aria-errormessage={`${id}-error`}
            />
            {description || inline || !preserveErrorSpace ? (
              hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />
            ) : (
              <FormError id={id} hasError={!!hasError} error={meta.error} />
            )}
            {description && (
              <Text type="small" color={colors.grey60} ml={inline ? spacings.px12 : undefined}>
                {description}
              </Text>
            )}
            {warning && (
              <>
                <FlexBox alignItemsCenter>
                  <div>
                    <WarningIcon color={colors.statusOrange} />
                  </div>
                  <Text type="small" color={colors.statusOrange} ml={inline ? spacings.px4 : undefined}>
                    {warning}
                  </Text>
                </FlexBox>
              </>
            )}
          </>
        );
      }}
    </Field>
  </LabeledInput>
);

export default InputField;
