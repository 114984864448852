import './TypeAddOn.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Button, ButtonGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

const TypeAddOn = ({ name, value, disabled, onChange, options, labelName, valueName }: any) => {
  const typeSelected = (type: any) => onChange(name ? { target: { name, value: type } } : type);

  const normalizedOptions = options.map((option: { [x: string]: any }) => ({
    label: option[labelName],
    value: option[valueName]
  }));

  const renderDisabled = () => {
    const option = normalizedOptions.find((option: { value: any }) => option.value === value);

    return option && option.label;
  };

  const renderEnabled = () => (
    <ButtonGroup>
      {normalizedOptions.map(
        (option: {
          value: React.Key | null | undefined;
          label:
            | string
            | number
            | boolean
            | React.ReactElement<any, string | React.JSXElementConstructor<any>>
            | React.ReactFragment
            | React.ReactPortal
            | null
            | undefined;
        }) => (
          <Button key={option.value} onClick={() => typeSelected(option.value)} active={value === option.value}>
            {option.label}
          </Button>
        )
      )}
    </ButtonGroup>
  );

  return (
    <InputGroupAddon className="type-add-on" addonType="prepend">
      <InputGroupText>{disabled ? renderDisabled() : renderEnabled()}</InputGroupText>
    </InputGroupAddon>
  );
};

TypeAddOn.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  labelName: PropTypes.string,
  valueName: PropTypes.string,
  disabled: PropTypes.bool
};

TypeAddOn.defaultProps = {
  name: '',
  value: '',
  labelName: 'value',
  valueName: 'key',
  disabled: false,
  options: []
};

export default TypeAddOn;
