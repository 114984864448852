import { skipToken, useQuery } from '@tanstack/react-query';

import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

interface PolicyValidationRequest {
  carrier_id: number | string;
  policy_number: string;
  policy_type: string | null;
  state: string | undefined | null;
}

export interface PolicyValidationResponse {
  sample: string;
  valid: boolean;
  warning: string | null;
  error: string | null;
}

export const validatePolicyNumber = (
  request: PolicyValidationRequest
): Promise<{ policy_number: PolicyValidationResponse }> =>
  api.get(`/api/frontend/policies/validate?${toQueryParams(request)}`);

const useValidatePolicyNumber = (request: PolicyValidationRequest) =>
  useQuery({
    queryKey: ['policy_validation', request],
    queryFn: !!request.carrier_id && !!request.policy_number ? () => validatePolicyNumber(request) : skipToken,
    initialData: { policy_number: { sample: '', valid: false, warning: null, error: null } },
    select: data => data.policy_number
  });

export default useValidatePolicyNumber;
