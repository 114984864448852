import { css } from '@emotion/react';
import React, { useState } from 'react';

import AutoEditor from '../../../components/Assets/AutoEditor';
import HomeDeleteModal from '../../../components/Assets/HomeDeleteModal';
import HomeEditor from '../../../components/Assets/HomeEditor';
import VehicleDeleteModal from '../../../components/Assets/VehicleDeleteModal';
import AutoAugmentationModal from '../../../components/AutoAugmentationModal';
import { ButtonSize } from '../../../components/core/buttons/Button';
import DropDown from '../../../components/core/buttons/DropDown';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { isLeadDataEditingForbidden } from '../../../components/DispositionsModals/dispositionsHelper';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { SORT_ORDER_DESC } from '../../../constants';
import { IHome, IVehicle } from '../../../interfaces';
import { useReplaceDataCollectionVehicles } from '../../../queries/leads/data_collection/useDataCollectionVehicles';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import { attachHome } from '../../../queries/leads/opportunities/useAttachHome';
import { attachVehicle } from '../../../queries/leads/opportunities/useAttachVehicle';
import { createPersonVehicle, updatePersonVehicle, VehiclePayload } from '../../../queries/people/personVehicles';
import { updatePerson } from '../../../queries/people/usePerson';
import { createHome, HomePayload, updateHome } from '../../../queries/people/usePersonHomes';
import { createRelatedPerson } from '../../../queries/people/usePersonRelatedPeople';
import { prefillVehiclesAndDrivers } from '../../../queries/people/usePrefillVehiclesAndDrivers';
import analytics from '../../../services/analytics';
import { spacings } from '../../../theme/variables';
import usePersonAssetsData from '../../Customer/CustomerAssets/_hooks/usePersonAssetsData';
import HomesList from '../../Customer/CustomerAssets/HomesList';
import AutosList from '../../Customer/CustomerAssets/VehiclesList';

interface LeadAssetsProps {
  personGid: string;
  leadGid: string;
  leadId: number;
}

const LeadAssets = ({ personGid, leadGid, leadId }: LeadAssetsProps) => {
  const { data: leadDispositions } = useLeadDispositions(leadId);

  const { mutateAsync: replaceVehicles } = useReplaceDataCollectionVehicles();

  const [openHomeEditor, setOpenHomeEditor] = useState(false);
  const [openAutoEditor, setOpenAutoEditor] = useState(false);
  const [openAutoAugmentationModal, setOpenAutoAugmentationModal] = useState(false);
  const [editedHome, setEditedHome] = useState<IHome>();
  const [editedVehicle, setEditedVehicle] = useState<IVehicle>();
  const [homeToDelete, setHomeToDelete] = useState<string>();
  const [vehicleToDelete, setVehicleToDelete] = useState<string>();

  const closeHomeEditor = () => {
    setOpenHomeEditor(false);
    setEditedHome(undefined);
  };

  const closeVehicleEditor = () => {
    setOpenAutoEditor(false);
    setEditedVehicle(undefined);
  };

  const { person, homes, vehicles, relatedPeople, personAddresses, isFetching, refetch } =
    usePersonAssetsData(personGid);

  const onSubmitHome = (home: HomePayload) => {
    if (editedHome?.gid) {
      return updateHome({ personGid, homeGid: editedHome.gid, data: home }).then(() => {
        refetch();
        closeHomeEditor();
      });
    } else {
      return createHome({ personGid, data: home })
        .then(({ home }) => {
          analytics.track('Home added', {
            lead_gid: leadGid,
            person_gid: personGid,
            place: 'old_lead_profile_ui',
            home_gid: home.gid
          });
          attachHome({ assetGid: home.gid, leadId });
        })
        .then(() => {
          refetch();
          closeHomeEditor();
        });
    }
  };

  const onSubmitVehicle = (vehicle: VehiclePayload) => {
    if (editedVehicle?.gid) {
      return updatePersonVehicle({ personGid, vehicleGid: editedVehicle.gid, data: vehicle }).then(() => {
        refetch();
        closeVehicleEditor();
      });
    } else {
      return createPersonVehicle({ personGid, data: vehicle })
        .then(({ vehicle }) => {
          attachVehicle({ assetGid: vehicle.gid, leadId });
          analytics.track('Vehicle added', {
            lead_gid: leadGid,
            person_gid: personGid,
            place: 'old_lead_profile_ui',
            source: 'manual',
            vehicle_gid: vehicle.gid
          });
        })
        .then(() => {
          refetch();
          closeVehicleEditor();
        });
    }
  };

  return isFetching ? (
    <TablePlaceholder testId="lead-assets-loader" />
  ) : (
    <Container fitParentWidth>
      <FlexBox pb={spacings.px24} justifySpaceBetween>
        <Text type="large" bold>
          Homes
        </Text>
        <div className="d-flex flex-row-reverse">
          <DropDown
            testId="add_asset"
            disabled={!!isLeadDataEditingForbidden(leadDispositions?.current_disposition?.disposition_type)}
            options={[
              { label: 'Add home', value: 'add_home', action: () => setOpenHomeEditor(true) },
              { label: 'Add auto', value: 'add_auto', action: () => setOpenAutoEditor(true) },
              { label: 'Data prefill', value: 'data_prefill', action: () => setOpenAutoAugmentationModal(true) }
            ]}
            onSelected={selectedOption => {
              selectedOption.action && selectedOption.action();
            }}
            size={ButtonSize.Small}
            optionsCss={css`
              width: 140px;
            `}
          >
            + Add Asset
          </DropDown>
        </div>
      </FlexBox>
      <HomesList
        homes={homes || []}
        onEditHome={home => {
          setEditedHome(home);
          setOpenHomeEditor(true);
        }}
        onDeleteHome={home => setHomeToDelete(home.gid)}
        defaultSorting={{ sort_column: 'id', sort_order: SORT_ORDER_DESC }}
        testId="customer-home-assets"
        personGid={personGid}
      />
      <FlexBox pt={spacings.px24} pb={spacings.px24}>
        <Text type="large" bold>
          Autos
        </Text>
      </FlexBox>
      <AutosList
        vehicles={vehicles || []}
        onEditVehicle={vehicle => {
          setEditedVehicle(vehicle);
          setOpenAutoEditor(true);
        }}
        onDeleteVehicle={vehicle => setVehicleToDelete(vehicle.gid)}
        defaultSorting={{ sort_column: 'id', sort_order: SORT_ORDER_DESC }}
      />
      {openHomeEditor && (
        <HomeEditor
          open
          home={{
            ...editedHome,
            kind: editedHome?.garages?.[0]?.kind,
            car_count: editedHome?.garages?.[0]?.car_count
          }}
          cancelBtnHandler={() => closeHomeEditor()}
          confirmBtnHandler={(home: any) => {
            const { address_attributes, ...rest } = home;
            const homeWithAddress = { ...rest, address: address_attributes };

            return onSubmitHome(homeWithAddress);
          }}
          person={person!}
          customerAddresses={personAddresses}
        />
      )}
      {openAutoEditor && (
        <AutoEditor
          open
          auto={editedVehicle}
          cancelBtnHandler={() => closeVehicleEditor()}
          person={person}
          confirmBtnHandler={(vehicle: any) => onSubmitVehicle({ ...vehicle, address: vehicle.address_attributes })}
          customerAddresses={personAddresses}
        />
      )}
      {homeToDelete && (
        <HomeDeleteModal
          cancelBtnHandler={() => setHomeToDelete(undefined)}
          confirmBtnHandler={homeGid => {
            analytics.track('Home removed', {
              lead_gid: leadGid,
              person_gid: personGid,
              place: 'old_lead_profile_ui',
              home_gid: homeGid
            });
            return refetch();
          }}
          personGid={personGid}
          homeGid={homeToDelete}
          leadId={leadId}
        />
      )}
      {vehicleToDelete && (
        <VehicleDeleteModal
          cancelBtnHandler={() => setVehicleToDelete(undefined)}
          confirmBtnHandler={vehicleGid => {
            analytics.track('Vehicle removed', {
              lead_gid: leadGid,
              person_gid: personGid,
              place: 'old_lead_profile_ui',
              vehicle_gid: vehicleGid
            });
            return refetch();
          }}
          personGid={personGid}
          vehicleGid={vehicleToDelete}
          leadId={leadId}
        />
      )}
      {openAutoAugmentationModal && (
        <AutoAugmentationModal
          open
          cancelBtnHandler={() => {
            setOpenAutoAugmentationModal(false);

            refetch();
          }}
          getAutos={(data: any) =>
            updatePerson({
              gid: personGid,
              date_of_birth: data.date_of_birth,
              living_address: {
                line1: data.address_line1,
                line2: data.address_line2,
                city: data.city,
                zip: data.zip,
                state: data.state
              }
            }).then(() =>
              prefillVehiclesAndDrivers({ personGid, removeDuplicates: false }).then(({ vehicles, drivers }) => ({
                payload: {
                  autos: vehicles,
                  drivers
                }
              }))
            )
          }
          customer={person}
          customerAddresses={personAddresses}
          leadId={leadId}
          leadGid={leadGid}
          createVehicles={async (params: any[]) => {
            const vehiclesResponses = await Promise.all(
              params.map(vehicleData =>
                createPersonVehicle({
                  personGid,
                  data: { ...vehicleData, address: vehicleData.address_attributes, data_source: 'fenris' }
                })
              )
            );

            vehiclesResponses.forEach(({ vehicle }) =>
              analytics.track('Vehicle added', {
                lead_id: leadId,
                lead_gid: leadGid,
                person_gid: personGid,
                place: 'old_lead_profile_ui',
                source: 'fenris',
                vehicle_gid: vehicle.gid
              })
            );

            const existingVehiclesGids = (vehicles || []).map(({ gid }) => gid);
            const createdVehiclesGids = vehiclesResponses.map(({ vehicle }) => vehicle.gid);

            return await replaceVehicles({
              assetsGids: [...existingVehiclesGids, ...createdVehiclesGids],
              leadId
            });
          }}
          createDriver={(params: any) => createRelatedPerson({ sourcePersonGid: personGid!, data: params })}
          existingAutos={vehicles?.map(vehicle => ({ asset: vehicle })) || []}
          existingDrivers={relatedPeople || []}
          showAssetsUrl={false}
        />
      )}
    </Container>
  );
};

export default LeadAssets;
