/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import ButtonWithoutBorder from '../../core/buttons/ButtonWithoutBorder';
import FlexBox from '../../core/FlexBox';
import Text from '../../core/Text';

export enum StreetViewType {
  Panorama = 'panorama',
  Satellite = 'satellite'
}

interface StreetViewTypeSwitchProps {
  selectedStreetViewType: StreetViewType;
  onStreetViewTypeSelected: (streetViewType: StreetViewType) => void;
}

const streetViewOptionStyles = (isSelected: boolean) => css`
  border-bottom: ${isSelected ? `2px solid ${colors.azure50}` : 'none'};
  padding: ${spacings.px12}px 0;
`;

const StreetViewTypeSwitch = ({ selectedStreetViewType, onStreetViewTypeSelected }: StreetViewTypeSwitchProps) => {
  return (
    <FlexBox ph={spacings.px24} gap={spacings.px24} roundBorder backgroundColor={colors.white}>
      <ButtonWithoutBorder
        onClick={() => onStreetViewTypeSelected(StreetViewType.Panorama)}
        customCss={streetViewOptionStyles(selectedStreetViewType === StreetViewType.Panorama)}
      >
        <Text bold={selectedStreetViewType === StreetViewType.Panorama}>Street view</Text>
      </ButtonWithoutBorder>
      <ButtonWithoutBorder
        onClick={() => onStreetViewTypeSelected(StreetViewType.Satellite)}
        customCss={streetViewOptionStyles(selectedStreetViewType === StreetViewType.Satellite)}
      >
        <Text bold={selectedStreetViewType === StreetViewType.Satellite}>Satellite view</Text>
      </ButtonWithoutBorder>
    </FlexBox>
  );
};

export default StreetViewTypeSwitch;
