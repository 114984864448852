import { create } from 'zustand';

interface IQuotesModalView {
  quotesModalView: boolean;
  setQuotesModalView: (quotesModalView: boolean) => void;
}

const useQuotesModalView = create<IQuotesModalView>()(set => ({
  quotesModalView: false,
  setQuotesModalView: quotesModalView => set({ quotesModalView })
}));

export default useQuotesModalView;
