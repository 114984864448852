import DatapointKey from './IDatapoint';

export interface IAnswer {
  key: string | DatapointKey;
  value: number | string | string[] | Record<string, string> | null;
  person_gid: string;
  asset_gid: string | null;
  engagement_gid: string | null;
  related_person_gid: string | null;
  answered_at: string | null;
  answered_by: 'customer' | 'agency_representative' | null;
  agency_user_gid: string | null;
}

interface AnswerOption {
  label: string;
  value: string;
  description?: string | null;
  icon_url?: string | null;
  underwriting_concern?: boolean;
}

export enum QuestionType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  Address = 'address',
  Phone = 'phone',
  Email = 'email',
  Select = 'single_select',
  MultiSelect = 'multi_select',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Slider = 'slider'
}

export enum VisibilityConditionOperation {
  Equal = 'equal',
  NotEqual = 'not_equal',
  OneOf = 'one_of',
  NotOneOf = 'not_one_of',
  LessThan = 'less_than',
  GreaterThan = 'greater_than',
  Filled = 'filled',
  NotFilled = 'not_filled'
}

export interface VisibilityCondition {
  value: string | string[];
  operation: VisibilityConditionOperation;
  source: string;
}

export type AndConditionsGroup = VisibilityCondition[];
export type OrConditionGroups = AndConditionsGroup[];
export enum ValidationType {
  Required = 'required',
  Pattern = 'pattern',
  MinYears = 'min_years',
  MaxYears = 'max_years',
  MinDaysFromNow = 'min_days_from_now',
  MaxDaysFromNow = 'max_days_from_now',
  MinDaysBeforeNow = 'min_days_before_now',
  MaxDaysBeforeNow = 'max_days_before_now',
  Min = 'min',
  Max = 'max',
  MinLength = 'min_length',
  MaxLength = 'max_length'
}

export interface Validation {
  key: ValidationType;
  value: boolean | string | number;
  message: string;
}

export enum QuestionVerificationStatus {
  Verified = 'verified',
  NeedsVerification = 'needs_verification',
  NotProvided = 'not_provided'
}

export interface IQuestion {
  key: string;
  label: string;
  alt_label: string | null;
  type: QuestionType;
  icon?: string;
  info?: string;
  variants?: AnswerOption[];
  description?: string;
  placeholder?: string;
  visibility_conditions: OrConditionGroups | undefined;
  validations?: Validation[];

  // Following props are provided by FE app itself
  secondary?: boolean;
  verification_status?: QuestionVerificationStatus;
}

export type IAnswers = Record<string, any>;
