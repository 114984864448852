/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Field } from 'formik';
import React from 'react';
import * as yup from 'yup';

import { DispositionType } from '../../../interfaces/IDisposition';
import useLeadAssignees from '../../../queries/leads/assignees/useLeadAssignees';
import useUpdateLeadDisposition from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { defaultFontValues } from '../../../theme/typography';
import { spacings } from '../../../theme/variables';
import { requiredField } from '../../../utils/yupRules';
import { ErrorCol } from '../../common';
import { Select } from '../../common/FormikComponents';
import FlexBox from '../../core/FlexBox';
import FormModal from '../../core/FormModal';
import { WarningIcon } from '../../core/icons';
import { ModalSize } from '../../core/Modal';
import Text from '../../core/Text';

interface PipelineModalProps {
  leadId: string | number;
  closeModal: () => void;
  onDispositionUpdated?: (dispositionType: DispositionType) => void;
}

const PipelineModal: React.FC<PipelineModalProps> = ({ leadId, closeModal, onDispositionUpdated }) => {
  const { data: assignees, isFetching: isFetchingAssignees } = useLeadAssignees({ leadId, licensed: true });
  const { mutateAsync: updateDisposition } = useUpdateLeadDisposition();

  return (
    <FormModal
      returnFocusAfterClose={false}
      initialValues={{
        assignee: ''
      }}
      validationSchema={yup.object().shape({
        assignee: requiredField
      })}
      confirmHandler={values => {
        return updateDisposition({
          leadId,
          disposition_type: DispositionType.Pipeline,
          agent_id: Number(values.assignee) || undefined
        }).then(() => {
          closeModal();
          onDispositionUpdated?.(DispositionType.Pipeline);
        });
      }}
      cancelHandler={() => {
        closeModal();
      }}
      title="Confirm agent to which Lead is transferred"
      confirmText="Transfer"
      size={ModalSize.medium}
      confirmationInProgress={isFetchingAssignees}
      renderForm={({ errors, touched }) => (
        <div
          css={css`
            ${defaultFontValues}
          `}
        >
          <FlexBox ml={spacings.px12} gap={spacings.px4} alignItemsCenter>
            <WarningIcon color={colors.statusOrange} />
            <Text>Lead can be transferred only to a licensed agent</Text>
          </FlexBox>
          <ErrorCol
            style={{ marginTop: '12px' }}
            name="assignee"
            error={errors['assignee']}
            when={errors['assignee'] && touched['assignee']}
          >
            {!authInfo.features.edit_lead_assignee ? (
              <Field
                name="assignee"
                component={Select}
                options={[{ key: authInfo.currentUserId, value: 'Assign to me' }]}
                title="Assignee"
                disabled={!assignees?.find(assignee => assignee.id === authInfo.currentUserId)}
                placeholder="Please select an assignee"
              />
            ) : (
              <Field
                name="assignee"
                component={Select}
                options={assignees}
                title="Assignee"
                placeholder="Please select an assignee"
                labelName="name"
                valueName="id"
              />
            )}
          </ErrorCol>
        </div>
      )}
    />
  );
};

export default PipelineModal;
