import { FullStory, isInitialized } from '@fullstory/browser';

import authInfo from './authInfo';

const analytics = {
  config: null,
  load(config: any) {
    if ((window as any).analytics && (window as any).analytics.load && config.segment) {
      (window as any).analytics.load(config.segment_key, {
        integrations: {
          'Segment.io': {
            deliveryStrategy: {
              strategy: 'batching',
              config: {
                size: 10,
                timeout: 5000
              }
            }
          }
        }
      });

      (this.config as any) = config;
      this.identify();
    }
  },
  identify() {
    if ((window as any).analytics && (this.config as any)?.segment) {
      (window as any).analytics.identify(authInfo.currentUserEmail, {
        name: authInfo.currentUserName,
        role: authInfo.role,
        email: authInfo.currentUserEmail
      });

      if (isInitialized()) {
        FullStory('setIdentity', {
          uid: `${authInfo.currentUserId}`,
          properties: {
            displayName: authInfo.currentUserName!,
            email: authInfo.currentUserEmail!,
            role: authInfo.role
          }
        });
      }
    }
  },
  page(location: any) {
    if ((window as any).analytics && (this.config as any)?.segment) {
      const name = this.parseName(location.pathname);
      const params = { path: location.pathname, title: '' };

      (window as any).analytics.page(name, params);
    }
  },
  parseName(path: string) {
    return path
      .split('/')
      .filter(word => !parseInt(word) && word.length > 0)
      .filter(word => !word.includes('-'))
      .join(' ');
  },
  track(name: any, params?: any) {
    if ((window as any).analytics && (this.config as any)?.segment) {
      (window as any).analytics.track(name, params);

      if (isInitialized()) {
        FullStory('trackEvent', { name, properties: params });
      }
    }
  }
};

export default analytics;
