import './Copy.scss';

import classNames from 'classnames';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import colors from '../../../theme/colors';
import alert from '../../core/Alert';
import { CopyIcon } from '../../core/icons';

interface ICopy {
  value: string;
  displayText?: string;
  className?: string;
  isBlock?: boolean;
  testId?: string;
}

const Copy = ({ value, displayText, className, isBlock = false, testId }: ICopy): JSX.Element => {
  return (
    <>
      {value && (
        <CopyToClipboard
          text={value}
          onCopy={
            alert({ message: 'Copied to clipboard', options: { position: 'bottom-center', autoClose: 1000 } }).success
          }
        >
          <span
            title={value}
            onClick={event => event.stopPropagation()}
            className={classNames('copy-to-clipboard', className, { block: isBlock })}
            data-testid={testId}
          >
            {displayText || value}
            <CopyIcon className="copy-icon" color={colors.grey60} />
          </span>
        </CopyToClipboard>
      )}
    </>
  );
};

export default Copy;
