import { css } from '@emotion/react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import useNavigateBack from '../../hooks/useNavigateBack';
import { ErrorResponse } from '../../services/api';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import Button from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import Heading from '../core/Heading';
import { MaticLogo } from '../core/icons';
import Paragraph, { ParagraphType } from '../core/Paragraph';

export interface ErrorFallbackProps {
  error: Error | AxiosError<ErrorResponse>;
  resetError: (...args: Array<unknown>) => void;
}

const fallbackContainerCss = css`
  position: absolute;
  margin: auto;
  top: 10%;
  right: 0;
  left: 0;
`;

const ctaCss = css`
  width: 200px;
  margin-bottom: 60px;
`;

const GenericFallback = ({ error, resetError }: ErrorFallbackProps) => {
  return (
    <FlexBox columnDirection justifyCenter alignItemsCenter gap={spacings.px16} customCss={fallbackContainerCss}>
      <MaticLogo />
      <Heading type="h1" color={colors.statusRed}>
        Something went wrong
      </Heading>
      <Paragraph color={colors.grey60}>* Please, screenshot this window and send it to support.</Paragraph>
      <pre
        style={{
          color: colors.statusRed,
          backgroundColor: colors.grey5,
          padding: '20px'
        }}
      >
        {'response' in error && error.response && (
          <Paragraph type={ParagraphType.Large} color={colors.statusRed} mb={spacings.px8}>
            {error.response.data.message}
          </Paragraph>
        )}
        {error.stack}
      </pre>
      <Button onClick={resetError} customCss={ctaCss}>
        Try again
      </Button>
    </FlexBox>
  );
};

const EntraUnauthorizedFallback = (props: ErrorFallbackProps) => {
  const navigate = useNavigate();

  return (
    <FlexBox columnDirection justifyCenter alignItemsCenter gap={spacings.px16} customCss={fallbackContainerCss}>
      <MaticLogo />
      <Heading
        type="h1"
        color={colors.statusRed}
        customCss={css`
          text-align: center;
        `}
      >
        Your session has expired.
      </Heading>
      <Paragraph type={ParagraphType.Large} color={colors.grey80}>
        {props.error.message}
      </Paragraph>
      {'response' in props.error && props.error.response && (
        <Paragraph type={ParagraphType.Large} color={colors.grey80}>
          {props.error.response.data.message}
        </Paragraph>
      )}
      <Button
        onClick={() => {
          props.resetError();

          navigate('/login');
        }}
        customCss={ctaCss}
      >
        Proceed to login page
      </Button>
    </FlexBox>
  );
};

const ForbiddenFallback = (props: ErrorFallbackProps) => {
  const navigateBack = useNavigateBack();
  return (
    <FlexBox columnDirection justifyCenter alignItemsCenter gap={spacings.px16} customCss={fallbackContainerCss}>
      <MaticLogo />
      <Heading
        type="h1"
        color={colors.statusRed}
        customCss={css`
          text-align: center;
        `}
      >
        You are not allowed to access the requested resource.
      </Heading>
      <Paragraph type={ParagraphType.Large} color={colors.grey80}>
        {props.error.message}
      </Paragraph>
      {'response' in props.error && props.error.response && (
        <Paragraph type={ParagraphType.Large} color={colors.grey80}>
          {props.error.response.data.message}
        </Paragraph>
      )}
      <Button
        onClick={() => {
          props.resetError();
          navigateBack();
        }}
        customCss={ctaCss}
      >
        Go back
      </Button>
    </FlexBox>
  );
};

const NotFoundFallback = (props: ErrorFallbackProps) => {
  const navigateBack = useNavigateBack();
  return (
    <FlexBox columnDirection justifyCenter alignItemsCenter gap={spacings.px16} customCss={fallbackContainerCss}>
      <MaticLogo />
      <Heading
        type="h1"
        color={colors.statusRed}
        customCss={css`
          text-align: center;
        `}
      >
        The requested resource cannot be found.
      </Heading>
      <Paragraph type={ParagraphType.Large} color={colors.grey80}>
        {props.error.message}
      </Paragraph>
      {'response' in props.error && props.error.response && (
        <Paragraph type={ParagraphType.Large} color={colors.grey80}>
          {props.error.response.data.message}
        </Paragraph>
      )}
      <Button
        onClick={() => {
          props.resetError();
          navigateBack();
        }}
        customCss={ctaCss}
      >
        Go back
      </Button>
    </FlexBox>
  );
};

const ErrorFallback = (props: ErrorFallbackProps) => {
  if (props.error instanceof AxiosError && props.error.response) {
    switch (props.error.response.status) {
      case 401:
        return <EntraUnauthorizedFallback {...props} />;
      case 403:
        return <ForbiddenFallback {...props} />;
      case 404:
        return <NotFoundFallback {...props} />;
      default:
        return <GenericFallback {...props} />;
    }
  }

  return <GenericFallback {...props} />;
};

export default ErrorFallback;
