import {
  AlignmentType,
  BorderStyle,
  Document,
  ImageRun,
  Packer,
  Paragraph,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  TextWrappingSide,
  TextWrappingType
} from 'docx';
/* @ts-expect-error TODO */
import { saveAs } from 'file-saver';
import moment from 'moment';

import maticLogo from '../../assets/img/matic-logo.png';
import { ICarrier, IMaticPolicy, IPerson } from '../../interfaces';
import { dateFormatter, moneyFormatter } from '../../utils/formatter';

const borderStyle = {
  top: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#a6a6a6'
  },
  right: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#a6a6a6'
  },
  bottom: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#a6a6a6'
  },
  left: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#a6a6a6'
  },
  insideHorizontal: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#a6a6a6'
  },
  insideVertical: {
    style: BorderStyle.SINGLE,
    size: 1,
    color: '#a6a6a6'
  }
};

class CustomTextRun extends TextRun {
  constructor({ font = 'Century Gothic', size = 20, ...options }) {
    super({ ...options, font, size });
  }
}

class CustomParagraph extends Paragraph {
  constructor({ spacing = { line: 276 }, ...options }) {
    super({ ...options, spacing });
  }
}

/* NOTE
  total page width is 9638 DXA for A4 portrait
  other types are not supported well by GoogleDocs
*/

export const enum CarrierAddress {
  OvernightAddress = 'overnight_address',
  RemittanceAddress = 'remittance_address'
}

const generateDocxReport = async ({
  title,
  person,
  policy,
  carrier,
  agentName,
  assetsAddresses,
  saveFile = true
}: {
  title: string;
  person: IPerson;
  policy: IMaticPolicy;
  carrier: {
    address: ICarrier[CarrierAddress.OvernightAddress | CarrierAddress.RemittanceAddress];
    name: string | undefined;
  };
  agentName: string | undefined;
  assetsAddresses: any[];
  saveFile?: boolean;
}) => {
  const carrierAddress = carrier.address
    ? [
        new CustomTextRun({
          text: [carrier.address.line1, carrier.address.line2].filter(Boolean).join(' ')
        }),
        new CustomTextRun({
          text: [carrier.address.city, carrier.address.state, carrier.address.zip].filter(Boolean).join(' '),
          break: 1
        })
      ]
    : [new CustomTextRun({ text: '-' })];

  /* eslint-disable indent */
  const locationInfo = assetsAddresses[0]
    ? assetsAddresses.map((address: any, index: any) => {
        return new CustomTextRun({ text: address, break: index && 1 });
      })
    : [new CustomTextRun({ text: '-' })];
  /* eslint-enable indent */

  const invoiceText = new CustomParagraph({
    alignment: AlignmentType.RIGHT,
    children: [
      new CustomTextRun({
        text: 'INVOICE',
        size: 64,
        color: '#595959'
      })
    ]
  });

  const readImageFromUrl = await fetch(maticLogo);
  const imageToArrayBuffer = await readImageFromUrl.arrayBuffer();

  const logo = new CustomParagraph({
    children: [
      new ImageRun({
        data: imageToArrayBuffer,
        transformation: {
          width: 400,
          height: 120
        },
        floating: {
          wrap: {
            type: TextWrappingType.SQUARE,
            side: TextWrappingSide.BOTH_SIDES
          },
          horizontalPosition: {
            offset: 514400
          },
          verticalPosition: {
            offset: 514400
          }
        }
      })
    ]
  });

  const dateText = new CustomParagraph({
    alignment: AlignmentType.RIGHT,
    children: [
      new CustomTextRun({
        text: `Date: ${moment().format('MMMM DD, YYYY')}`,
        break: 5
      })
    ]
  });

  const invoiceNumber = new CustomParagraph({
    alignment: AlignmentType.RIGHT,
    children: [
      new CustomTextRun({
        text: `INVOICE #${policy.policy_number}`
      }),
      new CustomTextRun({
        text: '',
        break: 4
      })
    ]
  });

  const addressTable = new Table({
    alignment: AlignmentType.RIGHT,
    columnWidths: [2600, 2600],
    layout: TableLayoutType.FIXED,
    borders: {
      top: {
        style: BorderStyle.NONE
      },
      right: {
        style: BorderStyle.NONE
      },
      bottom: {
        style: BorderStyle.NONE
      },
      left: {
        style: BorderStyle.NONE
      },
      insideHorizontal: {
        style: BorderStyle.NONE
      },
      insideVertical: {
        style: BorderStyle.NONE
      }
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new CustomParagraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new CustomTextRun({
                    text: 'Remit Payment',
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new CustomTextRun({
                    text: carrier.name
                  })
                ]
              })
            ]
          })
        ]
      }),
      new TableRow({
        children: [
          new TableCell({
            children: [
              new CustomParagraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new CustomTextRun({
                    text: 'To:',
                    bold: true
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                alignment: AlignmentType.RIGHT,
                children: carrierAddress
              })
            ]
          })
        ]
      })
    ]
  });

  const breakText = new CustomParagraph({
    children: [
      new CustomTextRun({
        text: '',
        break: 1
      })
    ]
  });

  const salesTable = new Table({
    columnWidths: [2200, 2600, 2600, 2200],
    layout: TableLayoutType.FIXED,
    margins: {
      top: 100,
      right: 100,
      bottom: 100,
      left: 100
    },
    borders: {
      top: {
        style: BorderStyle.NONE
      },
      right: {
        style: BorderStyle.NONE
      },
      bottom: {
        style: BorderStyle.NONE
      },
      left: {
        style: BorderStyle.NONE
      },
      insideHorizontal: {
        style: BorderStyle.NONE
      },
      insideVertical: {
        style: BorderStyle.NONE
      }
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new CustomParagraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new CustomTextRun({
                    text: 'Salesperson'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: 'Customer'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: 'Payment terms'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: 'Due date'
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });

  const salesInfoTable = new Table({
    columnWidths: [2200, 2600, 2600, 2200],
    layout: TableLayoutType.FIXED,
    margins: {
      top: 100,
      right: 100,
      bottom: 100,
      left: 100
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new CustomTextRun({
                    text: agentName || '-'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: `${person.first_name} ${person.last_name}`
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: 'Due on receipt'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: `${dateFormatter(policy.effective_date)}`
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });

  const policyTable = new Table({
    columnWidths: [2200, 2600, 2600, 2200],
    layout: TableLayoutType.FIXED,
    margins: {
      top: 100,
      right: 100,
      bottom: 100,
      left: 100
    },
    borders: {
      top: {
        style: BorderStyle.NONE
      },
      right: {
        style: BorderStyle.NONE
      },
      bottom: {
        style: BorderStyle.NONE
      },
      left: {
        style: BorderStyle.NONE
      },
      insideHorizontal: {
        style: BorderStyle.NONE
      },
      insideVertical: {
        style: BorderStyle.NONE
      }
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [
              new CustomParagraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new CustomTextRun({
                    text: 'Policy Number'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: 'Location'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: 'Policy Term'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: 'Premium total'
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });

  const policyInfoTable = new Table({
    columnWidths: [2200, 2600, 2600, 2200],
    layout: TableLayoutType.FIXED,
    margins: {
      top: 100,
      right: 100,
      bottom: 100,
      left: 100
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new CustomTextRun({
                    text: policy.policy_number
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                children: locationInfo
              })
            ]
          }),
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                children: [
                  new CustomTextRun({
                    text: `${dateFormatter(policy.effective_date)} - ${dateFormatter(policy.expiration_date)}`
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new CustomTextRun({
                    text: moneyFormatter(policy.premium)
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });

  const totalTable = new Table({
    columnWidths: [2200, 2600, 2600, 2200],
    layout: TableLayoutType.FIXED,
    margins: {
      top: 100,
      right: 100,
      bottom: 100,
      left: 100
    },
    borders: {
      top: {
        style: BorderStyle.NONE
      },
      right: {
        style: BorderStyle.NONE
      },
      bottom: {
        style: BorderStyle.NONE
      },
      left: {
        style: BorderStyle.NONE
      },
      insideHorizontal: {
        style: BorderStyle.NONE
      },
      insideVertical: {
        style: BorderStyle.NONE
      }
    },
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: []
          }),
          new TableCell({
            children: []
          }),
          new TableCell({
            children: [
              new CustomParagraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new CustomTextRun({
                    text: 'Total due:'
                  })
                ]
              })
            ]
          }),
          new TableCell({
            borders: borderStyle,
            children: [
              new CustomParagraph({
                alignment: AlignmentType.RIGHT,
                children: [
                  new CustomTextRun({
                    text: moneyFormatter(policy.premium)
                  })
                ]
              })
            ]
          })
        ]
      })
    ]
  });

  const footerText = new CustomParagraph({
    alignment: AlignmentType.CENTER,
    children: [
      new CustomTextRun({
        text: `Make all checks payable to ${carrier.name}`,
        color: '#595959',
        break: 2
      }),
      new CustomTextRun({
        text: 'Thanks for your business!',
        bold: true,
        italics: true,
        break: 1
      }),
      new CustomTextRun({
        text: 'Matic Insurance Services, Inc. Phone: 888-380-6623 Email: customerservice@matic.com',
        color: '#595959',
        break: 2
      })
    ]
  });

  const document = new Document({
    title,
    sections: [
      {
        children: [
          invoiceText,
          logo,
          dateText,
          invoiceNumber,
          addressTable,
          breakText,
          salesTable,
          salesInfoTable,
          breakText,
          policyTable,
          policyInfoTable,
          totalTable,
          footerText
        ]
      }
    ]
  });

  return Packer.toBlob(document).then(blob => {
    saveFile && saveAs(blob, title);

    return blob;
  });
};

export default generateDocxReport;
