/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { usePopper } from '../../hooks';
import colors from '../../theme/colors';
import { borderRadius, spacings } from '../../theme/variables';
import Container from './Container';
import FlexBox from './FlexBox';
import { CopyIcon } from './icons';
import Text, { TextProps } from './Text';

interface CopyProps extends React.HTMLProps<HTMLSpanElement> {
  value: string;
  testId?: string;
  withIcon?: boolean;
  type?: TextProps['type'];
  customCss?: SerializedStyles;
}

const copyIconSize = (type: CopyProps['type']) => {
  switch (type) {
    case 'large':
      return spacings.px28;
    case 'default':
      return spacings.px20;
    case 'small':
      return 18;
    case 'tiny':
      return spacings.px16;
  }
};

const Copy = ({ value, testId, withIcon = true, type = 'default', customCss, className, children }: CopyProps) => {
  const { setAnchorEl, PopperComponent, popperProps } = usePopper({
    placement: 'top',
    disablePortal: false,
    modifiers: [
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 4]
        }
      }
    ]
  });
  const popperTargetRef = React.useRef<HTMLDivElement>(null);
  const timeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

  return (
    <CopyToClipboard
      text={value}
      onCopy={() => {
        popperTargetRef.current && setAnchorEl(popperTargetRef.current);
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          setAnchorEl(null);
        }, 500);
      }}
    >
      <div
        ref={popperTargetRef}
        onClick={e => e.stopPropagation()}
        data-testid={testId}
        className={className}
        css={css`
          border-radius: ${borderRadius}px;
          padding: 0 ${spacings.px4}px;
          transition: background-color 0.3s;
          display: inline-flex;
          flex-wrap: nowrap;
          overflow: hidden;
          cursor: pointer;

          max-width: fit-content;
          max-height: fit-content;

          &:hover {
            background-color: ${colors.grey5};

            .copy-icon {
              opacity: 1;
            }
          }

          ${customCss}
        `}
      >
        {children}
        {withIcon && (
          <FlexBox>
            <CopyIcon
              className="copy-icon"
              color={colors.grey60}
              width={copyIconSize(type)}
              height={copyIconSize(type)}
              css={css`
                opacity: 0;
                padding-left: ${spacings.px4}px;
                pointer-events: none;
                transition: opacity 0.3s;
              `}
            />
          </FlexBox>
        )}
        <PopperComponent {...popperProps}>
          <Container roundBorder backgroundColor={colors.statusGreen} p={spacings.px4}>
            <Text type="small" color={colors.white}>
              Copied!
            </Text>
          </Container>
        </PopperComponent>
      </div>
    </CopyToClipboard>
  );
};

export default Copy;
