import { useMutation } from '@tanstack/react-query';

import api from '../../services/api';

const enum OmniChannelInteractionMediaType {
  Email = 'email',
  SMS = 'sms'
}

interface SendEmailPayload {
  personGid: string;
  to: { address: string }[];
  emailSubject: string;
  emailBody: string;
  engagementGid: string;
}

interface SendSMSPayload {
  personGid: string;
  to: { address: string }[];
  smsBody: string;
  engagementGid: string;
}

const sendEmail = ({ to, personGid, emailSubject, emailBody, engagementGid }: SendEmailPayload) =>
  api.post(`/api/frontend/people/${personGid}/omni_channel_interactions`, {
    body: {
      to,
      subject: emailSubject,
      body: emailBody,
      media_type: OmniChannelInteractionMediaType.Email,
      engagement_gid: engagementGid
    }
  });

export const useSendEmail = () => useMutation({ mutationFn: sendEmail });

const sendSMS = ({ to, personGid, smsBody, engagementGid }: SendSMSPayload) =>
  api.post(`/api/frontend/people/${personGid}/omni_channel_interactions`, {
    body: { to, body: smsBody, media_type: OmniChannelInteractionMediaType.SMS, engagement_gid: engagementGid }
  });

export const useSendSMS = () => useMutation({ mutationFn: sendSMS });
