import { FullStory, isInitialized } from '@fullstory/browser';
import React from 'react';

import { useCreateFullStorySession } from '../queries/full_story_sessions/useFullStorySessions';

const useTrackFullStorySession = ({
  leadGid,
  candidateGid
}: {
  leadGid: string | undefined;
  candidateGid: string | undefined;
}) => {
  const { mutate: createFullStorySession } = useCreateFullStorySession();

  const engagementGid = leadGid || candidateGid;
  const isFullStoryInitialized = isInitialized();

  React.useEffect(() => {
    if (engagementGid && isFullStoryInitialized) {
      const sessionUrl = FullStory('getSession', { format: 'url.now' });
      sessionUrl && createFullStorySession({ engagementGid, sessionUrl });
    }
  }, [engagementGid, createFullStorySession, isFullStoryInitialized]);
};

export default useTrackFullStorySession;
