import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { AutoCoverage } from '../../interfaces/ICoverage';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const usePolicyAutoCoverages = (
  policyId: number,
  lineOfBusinessId?: number,
  transactionId?: number,
  options?: Omit<UseQueryOptions<{ coverages: AutoCoverage[] }, any, any, any>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['policy_auto_coverages', policyId, lineOfBusinessId, transactionId],
    queryFn:
      !!lineOfBusinessId && !!transactionId
        ? (): Promise<{ coverages: AutoCoverage[] }> =>
            api.get(
              `/api/frontend/policies/${policyId}/auto/${lineOfBusinessId}/coverages?${toQueryParams({
                transaction_id: transactionId
              })}`
            )
        : skipToken,
    select: data => data.coverages,
    ...options
  });

export default usePolicyAutoCoverages;
