import React from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import FlexBox from '../../../../components/core/FlexBox';
import Text from '../../../../components/core/Text';
import { ILead } from '../../../../interfaces/ILead';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import { dateFormatter } from '../../../../utils/formatter';
import guidedSellingExperienceNavigation, {
  GuidedSellingExperienceOpenedFrom
} from '../../../GuidedSellingExperience/navigation';

const LeadInfo = ({ lead: { gid, created_at, source_dimensions } }: { lead: ILead }) => {
  const { source_product, partner, sub_partner, brand, marketing_opportunity, lead_type, lead_capture_method } =
    source_dimensions;

  const navigate = useNavigate();

  const scoutingReportLink = gid ? (
    <>
      <span>| </span>
      <ButtonWithoutBorder
        textType="large"
        onClick={() => {
          const newUrl = guidedSellingExperienceNavigation.forLead({ lead_gid: gid });
          navigate(newUrl, { state: { openedFrom: GuidedSellingExperienceOpenedFrom.OldUI } });
        }}
      >
        See report
      </ButtonWithoutBorder>
    </>
  ) : null;

  return (
    <>
      <Text type="large" bold>
        Lead info {scoutingReportLink}
      </Text>
      <FlexBox mt={spacings.px16}>
        <Text color={colors.grey60}>Source Product</Text>
        <Text ml={spacings.px4}>{source_product}</Text>
      </FlexBox>
      <FlexBox>
        <Text color={colors.grey60}>Partner</Text>
        <Text ml={spacings.px4}>{partner}</Text>
      </FlexBox>
      <FlexBox>
        <Text color={colors.grey60}>Sub-Partner</Text>
        <Text ml={spacings.px4}>{sub_partner}</Text>
      </FlexBox>
      <FlexBox>
        <Text color={colors.grey60}>Created at</Text>
        <Text ml={spacings.px4}>{dateFormatter(created_at)}</Text>
      </FlexBox>
      <FlexBox>
        <Text color={colors.grey60}>Brand</Text>
        <Text ml={spacings.px4}>{brand}</Text>
      </FlexBox>
      {marketing_opportunity && (
        <FlexBox>
          <Text color={colors.grey60}>Marketing Opportunity</Text>
          <Text ml={spacings.px4}>{marketing_opportunity}</Text>
        </FlexBox>
      )}
      {lead_type && (
        <FlexBox>
          <Text color={colors.grey60}>Lead Type</Text>
          <Text ml={spacings.px4}>{lead_type}</Text>
        </FlexBox>
      )}
      {lead_capture_method && (
        <FlexBox>
          <Text color={colors.grey60}>Lead Capture Method</Text>
          <Text ml={spacings.px4}>{lead_capture_method}</Text>
        </FlexBox>
      )}
    </>
  );
};

export default LeadInfo;
