import { useQuery } from '@tanstack/react-query';

import { IUser, UserStatus } from '../../interfaces/IUser';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const useAssignees = (filters?: { statuses: UserStatus[] }) =>
  useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: ['assignees', filters?.statuses],
    queryFn: (): Promise<{ agents: IUser[] }> =>
      api.get(`/api/frontend/assignees?${filters?.statuses ? toQueryParams({ statuses: filters.statuses }) : ''}`),
    select: data => data.agents,
    staleTime: Infinity
  });

export default useAssignees;
