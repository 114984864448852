/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';

import ButtonWithoutBorder from '../../../../components/core/buttons/ButtonWithoutBorder';
import { isEndDisposition } from '../../../../components/DispositionsModals/dispositionsHelper';
import { ILead } from '../../../../interfaces';
import useLeadAssignees from '../../../../queries/leads/assignees/useLeadAssignees';
import { useTakeOverLead } from '../../../../queries/leads/assignees/useUpdateLeadAssignee';
import { SCHEDULED_INTERACTIONS_QUERY_KEY } from '../../../../queries/scheduled_calls/useScheduledInteractions';
import authInfo from '../../../../services/authInfo';
import { spacings } from '../../../../theme/variables';
import { useCurrentDispositionSubscriptionContext, useDataEditingForbidden } from '../../_hooks';

const TakeOverLead = ({ lead }: { lead: ILead }) => {
  const { currentDisposition, refetchCurrentDisposition } = useCurrentDispositionSubscriptionContext();
  const { data: assignees } = useLeadAssignees({
    leadId: lead.id,
    licensed: !!currentDisposition?.is_license_required
  });
  const isCurrentAgentLicensed = !!assignees?.find(assignee => assignee.id === authInfo.currentUserId);
  const queryClient = useQueryClient();

  const { mutateAsync: takeOverLead, isPending: isTakingLeadOver } = useTakeOverLead(() =>
    Promise.all([
      refetchCurrentDisposition(),
      queryClient.invalidateQueries({ queryKey: [SCHEDULED_INTERACTIONS_QUERY_KEY, lead.person_gid] })
    ])
  );

  const isDataEditingForbidden = useDataEditingForbidden();

  if (!currentDisposition) {
    return null;
  }

  const isLeadTakeOverAvailable =
    isCurrentAgentLicensed &&
    !isEndDisposition(currentDisposition.disposition_type) &&
    currentDisposition.assignee?.id !== authInfo.currentUserId;

  if (isLeadTakeOverAvailable) {
    return (
      <ButtonWithoutBorder
        textType="small"
        onClick={() => takeOverLead({ leadId: lead.id, assigneeId: authInfo.currentUserId! })}
        disabled={isTakingLeadOver || isDataEditingForbidden}
        pv={spacings.px0}
        customCss={css`
          white-space: nowrap;
        `}
      >
        Assign to me
      </ButtonWithoutBorder>
    );
  }

  return null;
};

export default TakeOverLead;
