/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { InputGroup, InputGroupAddon } from 'reactstrap';

import colors from '../../../theme/colors';
import alert from '../../core/Alert';
import { CopyIcon } from '../../core/icons';

interface ICopyField {
  value: string | undefined;
  children: React.ReactNode;
}

const CopyField = ({ value, children }: ICopyField): JSX.Element => {
  return (
    <InputGroup>
      {children}
      <InputGroupAddon addonType="append">
        {value && (
          <CopyToClipboard
            text={value}
            onCopy={
              alert({ message: 'Copied to clipboard', options: { position: 'bottom-center', autoClose: 1000 } }).success
            }
          >
            <span
              className="input-group-text"
              css={css`
                border-radius: 0.125rem;
                cursor: pointer;
                padding-left: 8px;
                transition: background-color 0.3s;
                &:hover {
                  background-color: #eaedf2;
                }
              `}
            >
              <CopyIcon color={colors.grey60} />
            </span>
          </CopyToClipboard>
        )}
      </InputGroupAddon>
    </InputGroup>
  );
};

export default CopyField;
