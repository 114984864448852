/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useCallback } from 'react';

import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { findPolicyTypeLabel, isInsurableInterestRealProperty } from '../../../interfaces/IPolicyType';
import { moneyFormatter, percentMoneyFormatter } from '../../../utils/formatter';
import BasicInfoSection from '../BasicInfoSection';
import NonSyncedCoverageSection from '../NonSyncedCoverageSection';
import PremiumSection from '../PremiumSection';

const NonSyncedPolicy = ({ policy }: { policy: IMaticPolicy }): JSX.Element => {
  const formatDeductibleValue = useCallback((policy: IMaticPolicy) => {
    if (policy.deductible_type === 'percentage') {
      return percentMoneyFormatter(policy.deductible_value);
    }

    return moneyFormatter(policy.deductible_value, true);
  }, []);

  return (
    <>
      <FlexBox justifySpaceBetween alignItemsCenter>
        <Heading>{`${findPolicyTypeLabel(policy.policy_type)} insurance`}</Heading>
      </FlexBox>
      <div
        css={css`
          padding-top: 12px;
          padding-right: 32px;
          padding-left: 32px;
        `}
      >
        <BasicInfoSection policy={policy} />
        {!!policy.premium && <PremiumSection premium={policy.premium} />}
        {isInsurableInterestRealProperty(policy.policy_type) && (
          <>
            {policy.deductible_value && (
              <NonSyncedCoverageSection
                heading="Deductibles"
                coverageName="Deductible"
                coverageValue={formatDeductibleValue(policy)}
              />
            )}
            {policy.primary_coverage && (
              <NonSyncedCoverageSection
                heading="Basic Coverages"
                coverageName="Dwelling"
                coverageValue={moneyFormatter(policy.primary_coverage)}
              />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NonSyncedPolicy;
