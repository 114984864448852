import { Grid } from '@mui/material';
import { FormikHelpers, FormikValues } from 'formik';
import * as React from 'react';

import { SelectField } from '../../../components/core/forms/fields';
import { AutoCoveragesOptions } from '../../../interfaces/IAutoCoveragesOptions';
import { IVehicle } from '../../../interfaces/IVehicle';
import useAutoCoveragesOptions from '../../../queries/auto_coverages/useAutoCoveragesOptions';
import { initialCoveragesForVehicle, VehicleCoveragesFields, VehicleCoveragesTiers } from './formHelpers';

const VehicleCoverages = ({
  vehicle,
  formikValues,
  setValues,
  onInitialCoveragesLoaded
}: {
  vehicle: IVehicle;
  formikValues: FormikValues;
  setValues: FormikHelpers<any>['setValues'];
  onInitialCoveragesLoaded: (autoCoveragesOptions: AutoCoveragesOptions, vehicleGid: IVehicle['gid']) => void;
}) => {
  const { gid: vehicleGid, address, year, ownership } = vehicle;

  const { data: coveragesOptions } = useAutoCoveragesOptions({
    state: address!.state,
    year,
    ownership
  });

  React.useEffect(() => {
    if (coveragesOptions) {
      onInitialCoveragesLoaded(coveragesOptions, vehicleGid);
    }
  }, [onInitialCoveragesLoaded, coveragesOptions, vehicleGid]);

  return (
    <>
      <Grid container columnSpacing={2}>
        <Grid item xs={3}>
          <SelectField
            required
            label="Apply coverage"
            id={`${vehicleGid}_${VehicleCoveragesFields.CoveragesTier}`}
            name={`${vehicleGid}_${VehicleCoveragesFields.CoveragesTier}`}
            options={[
              { key: VehicleCoveragesTiers.Recommended, value: 'Matic recommended coverage' },
              { key: VehicleCoveragesTiers.Custom, value: 'Custom coverage' }
            ]}
            onChange={args => {
              const selectedTier = args.target.value;

              if (selectedTier === VehicleCoveragesTiers.Recommended) {
                const newValues = initialCoveragesForVehicle(vehicle, coveragesOptions);

                setValues((oldValues: FormikValues) => ({
                  ...oldValues,
                  ...newValues,
                  [`${vehicleGid}_${VehicleCoveragesFields.CoveragesTier}`]: selectedTier
                }));
              } else {
                setValues((oldValues: FormikValues) => ({
                  ...oldValues,
                  [`${vehicleGid}_${VehicleCoveragesFields.CoveragesTier}`]: selectedTier
                }));
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid container columnSpacing={2}>
        {coveragesOptions?.map(coverageOptions => {
          const { name, key } = coverageOptions.values[0]!;
          const selectOptions = coverageOptions.values.map(({ gid, ui_values }) => ({ key: gid, value: ui_values }));

          return (
            <Grid item xs={3} key={key}>
              <SelectField
                required
                requireTouchToShowError={false}
                disabled={
                  formikValues[`${vehicleGid}_${VehicleCoveragesFields.CoveragesTier}`] ===
                  VehicleCoveragesTiers.Recommended
                }
                label={name}
                id={`${vehicleGid}.coverages.${key}`}
                name={`${vehicleGid}.coverages.${key}`}
                options={selectOptions}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default VehicleCoverages;
