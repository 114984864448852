/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { PolicyType } from '../../interfaces/IPolicyType';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import PolicyTypeIcon from './PolicyTypeIcon';

interface CircledPolicyTypeIconProps {
  policyType: PolicyType;
  color?: string;
}

const CircledPolicyTypeIcon = ({ policyType, color = colors.statusOrange }: CircledPolicyTypeIconProps) => {
  return (
    <svg
      width={spacings.px32}
      height={spacings.px32}
      viewBox={`0 0 ${spacings.px32} ${spacings.px32}`}
      fill="none"
      css={css`
        min-width: ${spacings.px32}px;
        min-height: ${spacings.px32}px;
      `}
    >
      <circle opacity="0.2" cx={spacings.px16} cy={spacings.px16} r={spacings.px16} fill={color} />
      <PolicyTypeIcon policyType={policyType} x={spacings.px6} y={spacings.px6} />
    </svg>
  );
};

export default CircledPolicyTypeIcon;
