import 'cleave.js/dist/addons/cleave-phone.us';

import classNames from 'classnames';
// @ts-expect-error old code
import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';

const PhoneInput = ({ name, onChange, onBlur, value, className, ...otherProps }: any) => (
  <Cleave
    className={classNames('form-control fs-mask', className)}
    type="tel"
    value={value}
    name={name}
    options={{
      delimiters: ['(', ')', ' ', '-'],
      blocks: [0, 3, 0, 3, 4]
    }}
    onChange={onChange}
    onBlur={onBlur}
    {...otherProps}
  />
);

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string
};

export default PhoneInput;
