import { DatapointsUsageKey } from '../../../interfaces/IDatapoint';
import { QuestionVerificationStatus } from '../../../interfaces/IWorkflow';
import {
  IAnswerWithVerificationStatus,
  usePeopleAnswers,
  useRelatedPeopleAnswers
} from '../../../queries/answers/useAnswers';
import { deepMerge } from '../../../utils/object';

const overrideAnswerVerificationStatus = ({
  answers,
  isDataVerifiedByCustomer
}: {
  answers: Record<string, Record<string, IAnswerWithVerificationStatus>>;
  isDataVerifiedByCustomer?: boolean;
}) => {
  Object.keys(answers).forEach(gid => {
    const personAnswers = answers[gid]!;
    Object.keys(personAnswers).forEach(key => {
      const answer = personAnswers[key]!;
      if (isDataVerifiedByCustomer && answer.verificationStatus !== QuestionVerificationStatus.NotProvided) {
        answer.verificationStatus = QuestionVerificationStatus.Verified;
      }
    });
  });
};

export const useAnswersWithVerificationStatus = ({
  personGid,
  relatedPeopleGids,
  isDataVerifiedByCustomer
}: {
  personGid: string;
  relatedPeopleGids: string[];
  isDataVerifiedByCustomer?: boolean;
}) => {
  const mainInfoAnswers = usePeopleAnswers({
    datapointsUsageKey: DatapointsUsageKey.CustomerProfileInfo,
    peopleGids: [personGid, ...relatedPeopleGids]
  });

  overrideAnswerVerificationStatus({ answers: mainInfoAnswers, isDataVerifiedByCustomer });

  const relationKindAnswers = useRelatedPeopleAnswers({
    datapointsUsageKey: DatapointsUsageKey.PersonRelationKind,
    relatedPeopleGids,
    personGid,
    assetGid: null
  });

  overrideAnswerVerificationStatus({ answers: relationKindAnswers, isDataVerifiedByCustomer });

  return deepMerge(mainInfoAnswers, relationKindAnswers) as typeof mainInfoAnswers;
};
