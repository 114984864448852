/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import NewTooltip from '../../../components/common/Tooltip/NewTooltip';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { InfoIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { Coverage } from '../../../interfaces/ICoverage';
import { spacings } from '../../../theme/variables';
import ContentSection from '../ContentSection';

const CoverageSection = ({
  heading,
  isDeductible = false,
  coverages
}: {
  heading: string;
  isDeductible?: boolean;
  coverages: Coverage[];
}): JSX.Element => {
  return (
    <ContentSection heading={heading} testId={`${heading}-coverage-section`}>
      <Container mt={spacings.px20}>
        {coverages.map(coverage => {
          const description = isDeductible ? coverage.deductible_description : coverage.limit_description;

          return (
            <FlexBox
              key={coverage.acord_code}
              justifySpaceBetween
              pt={spacings.px4}
              pb={spacings.px4}
              mb={spacings.px8}
              borderBottom
            >
              <FlexBox alignItemsCenter>
                <Text type="large">{coverage.acord_code}</Text>
                {coverage.acord_description && (
                  <span
                    css={css`
                      margin-left: 4px;
                    `}
                    data-tip={coverage.acord_description}
                    data-for="coverage-description-tooltip"
                  >
                    <InfoIcon />
                  </span>
                )}
              </FlexBox>
              {description && <Text type="large">{description}</Text>}
            </FlexBox>
          );
        })}
      </Container>
      <NewTooltip id="coverage-description-tooltip" />
    </ContentSection>
  );
};

export default CoverageSection;
