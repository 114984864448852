/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { UploadArea } from '../../../components/common/UploadFiles';
import FlexBox from '../../../components/core/FlexBox';
import { DeleteIcon } from '../../../components/core/icons';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import { documentFilePreviewUrl, DocumentOwnerType } from '../../../interfaces/IDocument';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import usePersonDocuments, {
  useCreateDocument,
  useDeleteDocument
} from '../../../queries/people/person_documents/usePersonDocuments';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';

const CONFIRM_DELETE_TEXT = 'Are you sure you want to delete this file?';

const Documents = ({ personGid, policy }: { personGid: string; policy: IMaticPolicy }): JSX.Element => {
  const { data: documents, refetch: refetchDocuments } = usePersonDocuments({ personGid, policyId: policy.id });

  const { mutateAsync: createDocument } = useCreateDocument({ attachDocs: false });
  const { mutateAsync: deleteDocument } = useDeleteDocument({ invalidate: false });

  return (
    <>
      <UploadArea
        inline
        onAttach={files =>
          Promise.all(
            files.map(file =>
              createDocument({
                personGid,
                request: { ...file, owner_type: DocumentOwnerType.Policy, owner_id: policy.id }
              })
            )
          ).then(() => refetchDocuments())
        }
        isForPolicy={true}
      />
      {documents ? (
        <div
          css={css`
            margin: 0 -20px 24px;
          `}
        >
          {documents.map(document => (
            <FlexBox
              justifySpaceBetween
              alignItemsCenter
              key={document.id}
              pv={spacings.px12}
              ph={spacings.px20}
              customCss={css`
                &:hover {
                  background-color: ${colors.grey5};
                }
              `}
            >
              <Tag
                label={document.document_type_title || 'Other'}
                backgroundColor={colors.grey10}
                textColor={colors.black}
              />
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={documentFilePreviewUrl(document)}
                css={css`
                  margin-left: 8px;
                  display: flex;
                  flex: 1 1 0%;
                  align-items: center;
                  min-width: 0px;
                `}
              >
                <Text
                  className="fs-mask"
                  customCss={css`
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                  `}
                  color={colors.azure50}
                >
                  {document.name}
                </Text>
              </a>
              <DeleteIcon
                color={colors.black}
                css={css`
                  margin-left: 8px;
                  &:hover {
                    cursor: pointer;
                    opacity: 0.8;
                  }
                `}
                onClick={() =>
                  window.confirm(CONFIRM_DELETE_TEXT) &&
                  deleteDocument({ personGid, documentId: document.id }).then(() => refetchDocuments())
                }
              />
            </FlexBox>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default Documents;
