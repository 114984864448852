import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';

import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import featureFlags from '../../../../constants/featureFlags';
import { ILead } from '../../../../interfaces';
import { DispositionType } from '../../../../interfaces/IDisposition';
import { useDisqualifyLead } from '../../../../queries/leads/data_collection/useDataCollection';
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import authInfo, { isISR, isSuperISR } from '../../../../services/authInfo';
import { useCurrentDispositionSubscriptionContext } from '../../_hooks';
import { GuidedSellingPathnames } from '../../navigation';

const Disqualify = ({ lead, openCancelCallModal }: { lead: ILead; openCancelCallModal: () => void }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();

  const { mutateAsync: disqualifyLead, isPending: isDisqualifyingLead } = useDisqualifyLead();

  const displayDisqualifyButton =
    (!isISR() || isSuperISR()) &&
    featureFlags.displayDisqualifyButton &&
    currentDisposition?.assignee?.id === authInfo.currentUserId &&
    currentDisposition?.disposition_type === DispositionType.ContactMade;

  if (!displayDisqualifyButton) {
    return null;
  }

  return (
    <Button
      loading={isDisqualifyingLead}
      variant={ButtonVariant.SecondaryDanger}
      size={ButtonSize.Small}
      onClick={async () => {
        await disqualifyLead({ leadId: lead.id });

        queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });
        openCancelCallModal();

        return navigate(
          isSuperISR()
            ? `${GuidedSellingPathnames.ISRFinish}${search}`
            : `${GuidedSellingPathnames.AgentFinish}${search}`
        );
      }}
    >
      Disqualify
    </Button>
  );
};

export default Disqualify;
