/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import colors from '../../../theme/colors';

const ProgressCircle = ({
  progress,
  radius = 13,
  strokeWidth = 2
}: {
  progress: number;
  radius?: number;
  strokeWidth?: number;
}): JSX.Element => {
  const normalizedRadius = radius - strokeWidth * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  return (
    <svg
      width={radius * 2}
      height={radius * 2}
      css={css`
        position: relative;
        right: 2px;
        margin-right: -4px;
      `}
    >
      <circle
        css={css`
          transition: 0.35s stroke-dashoffset;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        `}
        stroke={colors.grey30}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
      <circle
        css={css`
          transition: 0.35s stroke-dashoffset;
          transform: rotate(-90deg);
          transform-origin: 50% 50%;
        `}
        stroke={colors.azure50}
        strokeWidth={strokeWidth}
        strokeDasharray={`${circumference} ${circumference}`}
        style={{ strokeDashoffset }}
        fill="transparent"
        r={normalizedRadius}
        cx={radius}
        cy={radius}
      />
    </svg>
  );
};

export default ProgressCircle;
