/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { Field, FieldProps } from 'formik';

import { QuestionVerificationStatus } from '../../../../interfaces/IWorkflow';
import { InputSize, LabelSize } from '../../../../theme/variables';
import PhoneInput, { PhoneInputProps } from '../../inputs/Phone';
import FormError from './FormError';
import LabeledInput from './LabeledInput';

interface InputFieldProps extends PhoneInputProps {
  label: string | JSX.Element;
  id: string;
  name: string;
  required?: boolean;
  testId?: string;
  secondary?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  verificationStatus?: QuestionVerificationStatus;
}

const PhoneField = ({
  label,
  id,
  name,
  required,
  testId,
  inline,
  secondary = false,
  customLabelCss,
  labelSize,
  inputSize,
  verificationStatus,
  ...rest
}: InputFieldProps): JSX.Element => (
  <LabeledInput
    id={id}
    required={required}
    label={label}
    inline={inline}
    secondary={secondary}
    disabled={rest.disabled}
    customCss={customLabelCss}
    labelSize={labelSize}
    inputSize={inputSize}
    verificationStatus={verificationStatus}
  >
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => {
        const hasError = meta.error && meta.touched;

        return (
          <>
            <PhoneInput
              {...field}
              data-testid={testId}
              id={id}
              inline={inline}
              inputSize={inputSize}
              hasError={!!hasError}
              aria-invalid={!!hasError}
              aria-errormessage={`${id}-error`}
              {...rest}
              disabled={rest.disabled || form.status === 'disabled'}
            />
            {inline && !!hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />}
            {!inline && <FormError id={id} hasError={!!hasError} error={meta.error} />}
          </>
        );
      }}
    </Field>
  </LabeledInput>
);

export default PhoneField;
