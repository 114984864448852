/** @jsxImportSource @emotion/react */
import './styles.scss';

import { ClassNames, css, Global } from '@emotion/react';
import classNames from 'classnames';
import * as React from 'react';
import { Col } from 'reactstrap';

import userIcon from '../../../../assets/new_icons/user.svg';
import Copy from '../../../../components/common/Copy/Copy';
import FlexBox from '../../../../components/core/FlexBox';
import Tag from '../../../../components/core/Tag';
import TelephonyActions from '../../../../components/core/TelephonyActions';
import Text from '../../../../components/core/Text';
import { IPerson, IRelatedPerson } from '../../../../interfaces/IPerson';
import usePersonDNC from '../../../../queries/people/dncStatus';
import authInfo from '../../../../services/authInfo';
import colors from '../../../../theme/colors';
import { dateFormatter } from '../../../../utils/formatter';
import Phone from './Phone';

const renderHouseholdMember = (leadGid: string, person: IPerson, householdMember: IRelatedPerson, last: boolean) => {
  return (
    <div
      key={householdMember.gid}
      className={last ? 'primary-contact__last-person-container' : 'primary-contact__person-container'}
    >
      <div key={householdMember.gid} className="primary-contact__person">
        <Col className="pb-1">
          <FlexBox>
            <Copy className="fs-mask mr-1" value={householdMember.name} />
            {householdMember.cocustomer && (
              <div>
                <Tag
                  testId={`${householdMember.name}-cocustomer-testid`}
                  label="Co-customer"
                  backgroundColor={colors.grey10}
                  textColor={colors.black}
                />
              </div>
            )}
          </FlexBox>
          {householdMember.phone && <Phone phone={householdMember.phone} textPrefix="Phone: " personGid={person.gid} />}
          {householdMember.email && (
            <div>
              <TelephonyActions email={householdMember.email} personGid={person.gid}>
                <Copy
                  className="fs-mask primary-contact__email text-truncate align-middle"
                  value={householdMember.email}
                />
              </TelephonyActions>
            </div>
          )}
          {householdMember.date_of_birth && (
            <Copy className="fs-mask" value={dateFormatter(householdMember.date_of_birth)} />
          )}
        </Col>
      </div>
    </div>
  );
};

const renderHouseholdMembers = (leadGid: string, person: IPerson, relatedPeople: IRelatedPerson[]) => {
  const lastPerson = relatedPeople[relatedPeople.length - 1];
  const otherPeople = relatedPeople.slice(0, -1);

  return (
    <>
      {otherPeople.map(householdMember => renderHouseholdMember(leadGid, person, householdMember, false))}
      {lastPerson && renderHouseholdMember(leadGid, person, lastPerson, true)}
    </>
  );
};

// TODO:
// Refactor styles to use inline CSS instead of scss.
const PrimaryContact = ({
  leadGid,
  person,
  relatedPeople
}: {
  leadGid: string;
  person: IPerson;
  relatedPeople: IRelatedPerson[];
}) => {
  const { data: doNotContact, isSuccess } = usePersonDNC(person.gid);
  const canCallWithDNC = authInfo.features.do_not_contact_call_available;
  const showContactInfo = (isSuccess && !doNotContact) || canCallWithDNC;

  return (
    <>
      <Global
        styles={() => css`
          .primary-contact__customer-name:before,
          .primary-contact__person:before {
            mask: url(${userIcon}) no-repeat center;
          }
        `}
      />
      <Text type="large" bold>
        Primary contact
      </Text>
      <section
        className={classNames('primary-contact', {
          'primary-contact--with-household-members': relatedPeople.length > 0
        })}
      >
        <div className="primary-contact__customer-name mt-3" data-testid="customer_name">
          <Copy className="fs-mask" value={person.name} />
        </div>
        <div className="primary-contact__all-people-container mt-1 fs-mask">
          <div className="primary-contact__person-container pb-1">
            {person.phone && showContactInfo && (
              <ClassNames>
                {({ css }) => (
                  <Phone
                    phone={person.phone!}
                    textPrefix="Phone: "
                    personGid={person.gid}
                    testId="phone"
                    className={
                      doNotContact && canCallWithDNC
                        ? css`
                            color: ${colors.statusRed};
                          `
                        : ''
                    }
                  />
                )}
              </ClassNames>
            )}
            {person.phone && isSuccess && doNotContact && !canCallWithDNC && (
              <Text>
                Phone:{' '}
                <Text color={colors.statusRed} data-testid="do-not-contact">
                  Do not contact
                </Text>
              </Text>
            )}
            {person.business_phone && showContactInfo && (
              <ClassNames>
                {({ css }) => (
                  <Phone
                    phone={person.business_phone!}
                    textPrefix="Business phone: "
                    personGid={person.gid}
                    testId="business_phone"
                    className={
                      doNotContact && canCallWithDNC
                        ? css`
                            color: ${colors.statusRed};
                          `
                        : ''
                    }
                  />
                )}
              </ClassNames>
            )}

            {person.secondary_phone && showContactInfo && (
              <ClassNames>
                {({ css }) => (
                  <Phone
                    phone={person.secondary_phone!}
                    textPrefix="Secondary phone: "
                    personGid={person.gid}
                    testId="secondary_phone"
                    className={
                      doNotContact && canCallWithDNC
                        ? css`
                            color: ${colors.statusRed};
                          `
                        : ''
                    }
                  />
                )}
              </ClassNames>
            )}
            {person.email && showContactInfo && (
              <div
                style={{
                  color: doNotContact && canCallWithDNC ? colors.statusRed : undefined
                }}
              >
                <FlexBox>
                  <TelephonyActions email={person.email} personGid={person.gid}>
                    <Copy
                      className="fs-mask primary-contact__email text-truncate align-middle"
                      value={person.email}
                      testId="email"
                    />
                  </TelephonyActions>
                </FlexBox>
              </div>
            )}
            {person.email && isSuccess && doNotContact && !canCallWithDNC && (
              <div>
                <Text>
                  Email:{' '}
                  <Text color={colors.statusRed} data-testid="do-not-contact">
                    Do not contact
                  </Text>
                </Text>
              </div>
            )}
            {person.living_address?.state && (
              <div className="fs-mask">
                Residential state{' '}
                <span className="primary-contact__highlighted-text fs-mask" data-testid="state">
                  {person.living_address.state}
                </span>
              </div>
            )}
          </div>
          {renderHouseholdMembers(leadGid, person, relatedPeople)}
        </div>
      </section>
    </>
  );
};

export default PrimaryContact;
