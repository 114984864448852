/** @jsxImportSource @emotion/react */
import './LeadPolicyRow.scss';

import classNames from 'classnames';
import { FC, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';

import cancelIcon from '../../assets/new_icons/close.svg';
import editIcon from '../../assets/new_icons/edit.svg';
import eSignIcon from '../../assets/new_icons/esign.svg';
import renewIcon from '../../assets/new_icons/renew.svg';
import generateIcon from '../../assets/new_icons/transaction.svg';
import { INFO_NOT_PRESENT, Translations } from '../../constants';
import { useToggle } from '../../hooks';
import { IPerson } from '../../interfaces/IPerson';
import { IMaticPolicy, POLICY_RENEWABLE_STATUSES } from '../../interfaces/IPolicy';
import { findPolicyType } from '../../interfaces/IPolicyType';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { dateFormatter, ellipsis, moneyFormatter, typedMoneyFormatter } from '../../utils/formatter';
import { isPolicyRenewed } from '../../utils/policies';
import { Copy, Link, Status } from '../common';
import DropDownMenu, { IDropdownItem } from '../common/DropDownMenu/DropDownMenu';
import { AttachmentIcon } from '../core/icons';
import ESignModal from '../ESignModal';
import GenerateInvoiceModal from '../GenerateInvoiceModal';
import Attachments from './Attachments';

const POLICY_PREMIUM_MAX_SYMBOLS_COUNT = 10;
const POLICY_NUMBER_MAX_SYMBOLS_COUNT = 13;

interface Props {
  person: IPerson;
  policy: IMaticPolicy;
}

const LeadPolicyRow: FC<Props> = ({ person, policy }) => {
  const [attachmentsExpanded, toggleAttachments] = useToggle(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);
  const [showESignUploadModal, setShowESignUploadModal] = useState(false);

  const { search } = useLocation();
  const navigate = useNavigate();
  const carrier = policy.carrier;

  const adminPolicyActionsPossible = authInfo.features.edit_policy_status;
  const allowPostSaleCarriers = authInfo.features.allow_post_sale_carriers;
  const editPostSalePossible = policy.carrier.post_sale ? !allowPostSaleCarriers : false;
  const renewalPossible = adminPolicyActionsPossible && POLICY_RENEWABLE_STATUSES.includes(policy.status);
  const rewritePossible = authInfo.features.rewrite_policies;
  const docuSignManageable = authInfo.features.send_policy_doc_for_esign;

  const documentsForSigning = policy.files?.filter(file => file.document_type_key === 'application') || [];

  const getDropDownList = (policy: any) => {
    const showDetails = !isPolicyRenewed(policy);

    return [
      showDetails && {
        label: 'Details',
        action: () => navigate(`${policy.id}/details${search}`)
      },
      {
        icon: editIcon,
        label: 'Edit',
        action: () => navigate(`${policy.id}/edit${search}`),
        classes: 'edit',
        disabled: editPostSalePossible
      },
      {
        icon: generateIcon,
        label: 'Generate invoice',
        action: () => setShowGenerateModal(true),
        disabled: !authInfo.features.generate_policy_invoice
      },
      {
        icon: eSignIcon,
        label: 'Send app for eSign',
        action: () => setShowESignUploadModal(true),
        disabled: !docuSignManageable
      },
      {
        icon: renewIcon,
        label: 'Rewrite',
        classes: 'renew',
        action: () => navigate(`${policy.id}/rewrite${search}`),
        disabled: !rewritePossible
      },
      {
        icon: renewIcon,
        label: 'Renew',
        action: () => navigate(`${policy.id}/renew${search}`),
        disabled: !renewalPossible
      },
      {
        icon: cancelIcon,
        label: 'Cancel',
        classes: 'cancel',
        action: () => navigate(`${policy.id}/cancel${search}`),
        disabled: !adminPolicyActionsPossible
      },
      {
        icon: cancelIcon,
        label: 'Non-renew',
        classes: 'non-renew',
        action: () => navigate(`${policy.id}/non_renew${search}`),
        disabled: !adminPolicyActionsPossible
      }
    ].filter(Boolean) as IDropdownItem[];
  };

  const type = findPolicyType(policy.policy_type);
  const dropDownList = getDropDownList(policy);

  return (
    <div className="table-row policy">
      <Row noGutters>
        <Col sm={1} className="table-cell" data-testid="status">
          <Status
            className={policy.status}
            tooltipInfo={{
              reason: Translations.policyCancellationReason(policy.cancellation_reason),
              date: dateFormatter(policy.cancellation_date)
            }}
          >
            <span className="ml-1">{Translations.policyStatus(policy.status)}</span>
          </Status>
        </Col>
        <Col sm={1} className="table-cell" data-testid="lead_id">
          <Link to={`/leads/${policy.lead_id}/summary`}>{policy.lead_id}</Link>
        </Col>
        <Col sm={1} className="table-cell" data-testid="policy_id">
          {policy.id}
        </Col>
        <Col sm={1} className="table-cell" data-testid="policy_type">
          {type!.name}
        </Col>
        <Col sm={1} className="table-cell" data-testid="carrier_name">
          {carrier &&
            (carrier.agency_portal_url ? (
              <a href={carrier.agency_portal_url} target="_blank" rel="noopener noreferrer">
                {ellipsis(carrier.name)}
              </a>
            ) : (
              ellipsis(carrier.name)
            ))}
        </Col>
        <Col sm={1} className="table-cell" data-testid="premium">
          {ellipsis(moneyFormatter(policy.premium), POLICY_PREMIUM_MAX_SYMBOLS_COUNT)}
        </Col>
        <Col sm={1} className="table-cell" data-testid="primary_coverage">
          {type!.with_primary_coverage ? moneyFormatter(policy.primary_coverage, true) : INFO_NOT_PRESENT}
        </Col>
        <Col sm={1} className="table-cell" data-testid="deductible_type">
          {typedMoneyFormatter(policy.deductible_type, policy.deductible_value)}
        </Col>
        <Col sm={1} className="table-cell" data-testid="sale_date">
          {dateFormatter(policy.sale_date)}
        </Col>
        <Col sm={1} className="table-cell" data-testid="effective_date">
          {dateFormatter(policy.effective_date)}
        </Col>
        <Col className={classNames('table-cell', 'policy-number, fs-mask')}>
          <Copy
            testId="policy_number"
            value={policy.policy_number}
            displayText={ellipsis(policy.policy_number, POLICY_NUMBER_MAX_SYMBOLS_COUNT)}
          />
        </Col>
        {policy.files && policy.files.length > 0 && (
          <span className="table-cell" onClick={toggleAttachments}>
            {attachmentsExpanded ? (
              <AttachmentIcon color={colors.azure50} className="action" />
            ) : (
              <AttachmentIcon className="action" color={colors.grey60} />
            )}
          </span>
        )}
        {dropDownList.length > 0 && (
          <DropDownMenu
            wrapperTestId="policy-actions"
            tipId={policy.id}
            items={dropDownList}
            maxHeight={dropDownList.length * 33}
            right
          />
        )}
      </Row>
      {policy.files && policy.files.length > 0 && attachmentsExpanded && (
        <Attachments attachments={policy.files} arrowOffset="3.6rem" />
      )}
      {showGenerateModal && (
        <GenerateInvoiceModal handleOpened={setShowGenerateModal} person={person} policy={policy} />
      )}
      <ESignModal
        isOpened={showESignUploadModal}
        policy={policy}
        person={person}
        handleOpened={setShowESignUploadModal}
        documents={documentsForSigning}
      />
    </div>
  );
};

export default LeadPolicyRow;
