import { anyPass, isEmpty, isNil, reject } from 'ramda';

export const compact = reject(anyPass([isEmpty, isNil]));

export const shallowEqual = (arrA: undefined | any[], arrB: undefined | any[]): boolean => {
  if (arrA === arrB) {
    return true;
  }

  if (!arrA || !arrB) {
    return false;
  }

  const len = arrA.length;

  if (arrB.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    if (arrA[i] !== arrB[i]) {
      return false;
    }
  }

  return true;
};
