import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IDataCollection } from '../../../interfaces';
import api from '../../../services/api';
import { PERSON_OPPORTUNITIES_QUERY_KEY } from '../../people/person_opportunities/usePersonOpportunities';
import { LEAD_DISPOSITIONS_QUERY_KEY } from '../dispositions/useLeadDispositions';
import { LEAD_OPPORTUNITIES_QUERY_KEY } from '../opportunities/useLeadOpportunities';

export type DataCollection = IDataCollection | null;
export const DATA_COLLECTION_QUERY_KEY = 'data_collection';
// Mutations ============================================================================

interface UpdatePayload {
  leadId: number;
  last_completed_step?: string;
}

export const updateDataCollectionQueryState = (
  queryClient: QueryClient,
  result: {
    data_collection: IDataCollection;
  },
  variables: {
    leadId: number | string;
  }
) => {
  queryClient.setQueryData([DATA_COLLECTION_QUERY_KEY, variables.leadId], () => result);
  queryClient.setQueryData([LEAD_DISPOSITIONS_QUERY_KEY, variables.leadId.toString()], {
    current_disposition: result.data_collection.current_disposition,
    dispositions: result.data_collection.dispositions
  });
  queryClient.invalidateQueries({ queryKey: [LEAD_OPPORTUNITIES_QUERY_KEY] });
  queryClient.invalidateQueries({ queryKey: [PERSON_OPPORTUNITIES_QUERY_KEY] });
};

const updateDataCollection = ({
  leadId,
  last_completed_step
}: UpdatePayload): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection`, { body: { last_completed_step } });

export const useUpdateDataCollection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateDataCollection,
    onSuccess: (result, variables) => updateDataCollectionQueryState(queryClient, result, variables)
  });
};

const disqualifyLead = ({ leadId }: { leadId: number }): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/disqualify`, {});

export const useDisqualifyLead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: disqualifyLead,
    onSuccess: (result, variables) => updateDataCollectionQueryState(queryClient, result, variables)
  });
};

// Readers ===============================================================================

const useDataCollection = (leadId: number | undefined) =>
  useQuery({
    queryKey: [DATA_COLLECTION_QUERY_KEY, leadId],
    queryFn: (): Promise<{ data_collection: DataCollection }> =>
      api.get(`/api/frontend/leads/${leadId}/data_collection`),
    enabled: !!leadId,
    select: data => data.data_collection
  });

export default useDataCollection;
