/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { labelStyle } from '../Base/Input.style';

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string | JSX.Element;
  id: string;
  labelProps?: React.LabelHTMLAttributes<HTMLLabelElement>;
  fsMask?: boolean;
}

const Checkbox = ({ label, required, id, labelProps, fsMask, ...props }: CheckboxProps): JSX.Element => {
  return (
    <label
      {...labelProps}
      htmlFor={id}
      css={css`
        display: flex;
        align-items: center;
        cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
        margin-bottom: 0;
      `}
    >
      <input
        {...props}
        className={fsMask ? 'fs-mask' : ''}
        type="checkbox"
        id={id}
        css={css`
          height: 20px;
          width: 20px;
          flex: 0 0 auto;
          cursor: pointer;
          &:disabled {
            cursor: not-allowed;
          }
        `}
      />
      <span
        css={css`
          ${labelStyle(required)};
          white-space: normal;
          ${props.disabled && 'opacity: 0.65;'}
        `}
      >
        {label}
      </span>
    </label>
  );
};

export default Checkbox;
