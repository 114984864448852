import * as React from 'react';

import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../../contexts/GuidedSellingExperienceContext';
import useMarketingOpportunity from '../../../../../queries/marketing_opportunities/useMarketingOpportunity';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';

const ContextInfo = () => {
  const { sourceDimensions, lead } = useGuidedSellingExperienceContext();

  const marketingOpportunityKey =
    sourceDimensions?.marketing_opportunity || lead?.source_dimensions?.marketing_opportunity;

  const { data: marketingOpportunity } = useMarketingOpportunity(marketingOpportunityKey);

  if (!marketingOpportunity) {
    return null;
  }

  return (
    <>
      <Text type="large" bold>
        Context
      </Text>
      {marketingOpportunity && (
        <>
          <FlexBox mt={spacings.px12} justifySpaceBetween>
            <Text>{marketingOpportunity.label}</Text>
          </FlexBox>
          <FlexBox mt={spacings.px12}>
            <Text color={colors.grey60}>{marketingOpportunity.description}</Text>
          </FlexBox>
        </>
      )}
    </>
  );
};

export default ContextInfo;
