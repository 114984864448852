/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Heading from '../../../../components/core/Heading';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { phoneFormatter } from '../../../../utils/formatter';

const UnknownPersonTitle = () => {
  const { callLog } = useGuidedSellingExperienceContext();

  const title = phoneFormatter(callLog?.external_phone);

  return title ? (
    <Heading
      data-testid="scouting-person-title"
      className="fs-mask"
      type="h4"
      customCss={css`
        white-space: nowrap;
      `}
    >
      {title}
    </Heading>
  ) : null;
};

export default UnknownPersonTitle;
