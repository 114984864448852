import './CollapsePanel.scss';

import classNames from 'classnames';
import React from 'react';
import { Collapse } from 'reactstrap';

import { ArrowDownIcon } from '../../core/icons';

const CollapsePanel = ({
  defaultOpen = true,
  title,
  children
}: {
  defaultOpen?: boolean;
  title: JSX.Element;
  children: JSX.Element;
}): JSX.Element => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen);

  return (
    <div className="collapse-panel">
      <div className="collapse-panel-title" onClick={() => setIsOpen(open => !open)}>
        <div className="mr-2">{title}</div>
        <ArrowDownIcon
          data-testid="toggle-collapse"
          className={classNames('collapse-panel-title__chevron', {
            'collapse-panel-title__chevron--collapsed': isOpen
          })}
        />
      </div>
      <Collapse isOpen={isOpen}>{children}</Collapse>
    </div>
  );
};

export default CollapsePanel;
