import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { doesPolicyTypeRequireAsset } from '../../../interfaces/IPolicyType';
import usePoliciesDetails from '../../../queries/policies/usePoliciesDetails';
import AssetDetailsPlaceholder from '../AssetDetailsPlaceholder';
import SyncedPolicyAssetsContainer from '../SyncedPolicyAssetsContainer';

const SyncedPolicyAssetsDetails = ({ personGid }: { personGid: string }): JSX.Element => {
  const { linesOfBusiness, policyTransactions, isCurrentPolicyLoaded, currentPolicy } = usePoliciesDetails(personGid);

  if (!isCurrentPolicyLoaded) {
    return <AssetDetailsPlaceholder />;
  }

  return (
    <>
      {isCurrentPolicyLoaded && currentPolicy && doesPolicyTypeRequireAsset(currentPolicy.policy_type) && (
        <FlexBox columnDirection>
          {linesOfBusiness?.map(lineOfBusiness => (
            <SyncedPolicyAssetsContainer
              key={lineOfBusiness.line_of_business_id}
              lineOfBusiness={lineOfBusiness}
              policyTransactions={policyTransactions || []}
              policy={currentPolicy as IMaticPolicy}
            />
          ))}
        </FlexBox>
      )}
    </>
  );
};

export default SyncedPolicyAssetsDetails;
