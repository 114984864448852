import { IUser } from './IUser';

export enum TaskKind {
  Fax = 'fax',
  Call = 'call',
  Email = 'email',
  TrailingDoc = 'trailing_doc',
  Underwriting = 'underwriting',
  Service = 'service',
  Escalation = 'escalation',
  AdvisorError = 'advisor_error',
  FollowUp = 'follow_up',
  PreBindApproval = 'pre_bind_approval'
}

export interface ITask {
  id: number;
  person_gid: string;
  lead_id: number;
  agent_id: number;
  assignee: IUser;
  priority: string;
  due_date: string;
  due_time: string;
  kind: TaskKind;
  completed: boolean;
  description: string;
  policy_id: number | null;
}
