/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import CircledPolicyTypeIcon from '../../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import { IOpportunity, IQuote } from '../../../../../interfaces';
import { opportunityDescription } from '../../../../../interfaces/IOpportunity';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { findMatchingQuote } from '../../../../GuidedQuoting/Quotes/_helpers/quoteSavingsHelper';
import QuotesModal from '../QuotesModal';
import CustomerChoiceHeader from './CustomerChoiceHeader';
import CustomerChoicePremiumAndCoverages from './CustomerChoicePremiumAndCoverages';

interface IInitialCustomerChoices {
  opportunities: IOpportunity[];
  customerChoices: IQuote[];
}

const InitialCustomerChoices = ({ opportunities, customerChoices }: IInitialCustomerChoices) => {
  const [selectedOpportunity, setSelectedOpportunity] = React.useState<IOpportunity | undefined>();

  const opportunitiesWithCustomerChoices = opportunities
    .map(opportunity => ({
      ...opportunity,
      customerChoice: findMatchingQuote(opportunity, customerChoices)
    }))
    .filter((opp): opp is IOpportunity & { customerChoice: IQuote } => !!opp.customerChoice);

  return (
    <FlexBox gap={spacings.px12} columnDirection>
      {opportunitiesWithCustomerChoices.map(opportunity => (
        <FlexBox key={opportunity.customerChoice.gid} p={spacings.px8} gap={spacings.px8} border roundBorder>
          <CircledPolicyTypeIcon policyType={opportunity.policy_type} />
          <FlexBox gap={spacings.px4} columnDirection>
            <CustomerChoiceHeader customerChoice={opportunity.customerChoice} />
            <Text type="tiny" color={colors.grey60} className="fs-mask">
              {opportunityDescription(opportunity)}
            </Text>
            <Text type="small">The final premium is not yet ready as data collection is not yet complete.</Text>
            <CustomerChoicePremiumAndCoverages customerChoice={opportunity.customerChoice} />
            <Container>
              <ButtonWithoutBorder
                onClick={() => setSelectedOpportunity(opportunity)}
                customCss={css`
                  padding: ${spacings.px8}px 0px;
                `}
              >
                See customer’s quotes
              </ButtonWithoutBorder>
            </Container>
          </FlexBox>
        </FlexBox>
      ))}
      {selectedOpportunity && (
        <QuotesModal
          opportunity={selectedOpportunity}
          customerQuotesOnly={true}
          onClose={() => setSelectedOpportunity(undefined)}
        />
      )}
      <Tooltip id="carrier-name-tooltip" />
      <Tooltip id="near-final-price-tooltip" />
      <Tooltip id="quoted-outdated-tooltip" />
    </FlexBox>
  );
};

export default InitialCustomerChoices;
