/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import { Translations } from '../../../../../constants';
import { CITY, SMARTY_LINE1, SMARTY_LINE2, SMARTY_ZIP, STATE } from '../../../../../constants/addressForm';
import colors from '../../../../../theme/colors';
import { borderRadius } from '../../../../../theme/variables';
import ButtonWithoutBorder from '../../../buttons/ButtonWithoutBorder';
import FlexBox from '../../../FlexBox';
import { CloseIcon } from '../../../icons';
import Label from '../../../Label';
import InputField from '../InputField';
import SelectField from '../SelectField';

interface Props {
  hideFallback: () => void;
  label: string;
  names: Record<string, string>;
}
const FallbackAddress: React.FC<Props> = ({ names, hideFallback, label }) => {
  return (
    <div
      css={css`
        background-color: ${colors.grey10};
        padding: 15px;
        border-radius: ${borderRadius}px;
      `}
    >
      <FlexBox justifySpaceBetween>
        <Label>{label}</Label>
        <ButtonWithoutBorder aria-label="Close" onClick={hideFallback}>
          <CloseIcon color={colors.black} />
        </ButtonWithoutBorder>
      </FlexBox>
      <div
        css={css`
          display: grid;
          gap: 10px;
          grid-template-columns: repeat(2, 1fr);
          margin: 10px 0;
        `}
      >
        <InputField
          fsMask
          id={names[SMARTY_LINE1]!}
          name={names[SMARTY_LINE1]!}
          label="Address"
          required
          className="fs-mask"
        />
        <InputField
          fsMask
          name={names[SMARTY_LINE2]!}
          id={names[SMARTY_LINE2]!}
          label="Address 2"
          className="fs-mask"
        />
      </div>
      <div
        css={css`
          display: grid;
          gap: 10px;
          grid-template-columns: repeat(3, 1fr);
        `}
      >
        <InputField fsMask name={names[CITY]!} id={names[CITY]!} label="City" required className="fs-mask" />
        <SelectField
          label="State"
          required
          name={names[STATE]!}
          id={names[STATE]!}
          options={Translations.usaStates}
          fsMask
          placeholder="Select"
        />
        <InputField fsMask name={names[SMARTY_ZIP]!} id={names[SMARTY_ZIP]!} label="Zip" required className="fs-mask" />
      </div>
    </div>
  );
};

export default FallbackAddress;
