import Configuration from './appConfiguration';
import Translations from './appTranslations';
import AutoForm from './autoForm';
import CustomerForm from './customerForm';
import Resources from './resources';

export const POLICY_NUMBER_INVALID_WARNING = 'Double check format. You can still submit';
export const POLICY_PREMIUM_INVALID_WARNING = 'Premium amount can’t be greater than $50K';
export const POLICY_NUMBER_GENERIC_MESSAGE =
  'Policy number does not match with carrier standard. Please, enter policy number from the DEC page';
export const EFFECTIVE_DATE_INVALID_WARNING =
  'Effective date is before Sale Date, please ensure this is correct before saving policy.';

export const DEFAULT_DEDUCTIBLE_TYPE = 'flat';
export const PERCENTAGE_DEDUCTIBLE_TYPE = 'percentage';

export const SORT_ORDER_ASC = 'asc';
export const SORT_ORDER_DESC = 'desc';

export const DWELLING_COVERAGE_MAX_LENGTH = 10;

export const LICENSE_NUMBER_MAX_LENGTH = 20;

export const DATA_SOURCE_AUGMENTED = 'augmentation';

export const ADDRESS_KEY_GARAGING = 'garaging';

export const INFO_NOT_PRESENT = 'N/A';

export const QUOTING_REQUIRED_MESSAGE = "It's required for quoting";

export { AutoForm, Configuration, CustomerForm, Resources, Translations };
