/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { TransactionsGroup } from '../../../queries/policies/usePolicyTransactionsGroups';
import { spacings } from '../../../theme/variables';
import { dateFormatter, DISPLAY_DATE_FORMAT } from '../../../utils/formatter';
import Transaction from '../Transaction';

const TransactionsHistory = ({ transactionsGroups }: { transactionsGroups: TransactionsGroup[] }): JSX.Element => {
  return (
    <>
      {transactionsGroups.map(({ transactions, policy }, index) => {
        const isFirstItem = index === 0;

        return (
          <React.Fragment key={policy.id}>
            {isFirstItem ? (
              <>
                <FlexBox>
                  <Text bold type="large">{`Latest term ${dateFormatter(
                    policy.effective_date,
                    DISPLAY_DATE_FORMAT
                  )} - ${dateFormatter(policy.expiration_date, DISPLAY_DATE_FORMAT)}`}</Text>
                </FlexBox>
                <FlexBox pt={spacings.px12} columnDirection>
                  {transactions.map(transaction => (
                    <FlexBox
                      key={transaction.effective_date}
                      mb={spacings.px12}
                      customCss={css`
                        &:last-child {
                          margin-bottom: 0;
                        }
                      `}
                    >
                      <Transaction transaction={transaction} />
                    </FlexBox>
                  ))}
                </FlexBox>
              </>
            ) : (
              <details
                key={policy.id}
                css={css`
                  padding: 12px 0;

                  &[open] {
                    padding-bottom: 0;

                    + details {
                      padding-top: 0;
                    }
                  }
                `}
              >
                <summary>
                  <Text>{dateFormatter(policy.effective_date, DISPLAY_DATE_FORMAT)} </Text>
                  <Text bold>{`(${transactions.length} transactions)`}</Text>
                </summary>
                <FlexBox mv={spacings.px12} columnDirection>
                  {transactions.map(transaction => (
                    <FlexBox
                      key={transaction.effective_date}
                      mb={spacings.px12}
                      customCss={css`
                        &:last-child {
                          margin-bottom: 0;
                        }
                      `}
                    >
                      <Transaction transaction={transaction} />
                    </FlexBox>
                  ))}
                </FlexBox>
              </details>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default TransactionsHistory;
