import React from 'react';

import Container from '../../../components/core/Container';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { ILoan } from '../../../interfaces/ILoan';
import usePersonVehicles from '../../../queries/people/personVehicles';
import usePersonHomes from '../../../queries/people/usePersonHomes';
import usePersonLoans from '../../../queries/people/usePersonLoans';
import LoanAssetPicker from './LoanAssetPicker';
import LoanEditor from './LoanEditor';
import LoansList from './LoansList';

const CustomerLoans = ({
  personGid,
  leadId,
  createLoanEnabled = true
}: {
  personGid: string;
  leadId?: number;
  createLoanEnabled?: boolean;
}) => {
  const { data: loans, isLoading: isLoadingLoans, isRefetching: isRefetchingLoans } = usePersonLoans({ personGid });
  const { data: vehicles, isLoading: isLoadingVehicles } = usePersonVehicles(personGid);
  const { data: homes, isLoading: isLoadingHomes } = usePersonHomes(personGid);

  const assets = [...(vehicles || []), ...(homes || [])];

  const [pickedAssetGid, setPickedAssetGid] = React.useState<string>();
  const [assetPickerOpened, setAssetPickerOpened] = React.useState(false);

  const [editedLoan, setEditedLoan] = React.useState<ILoan>();
  const [editorOpened, setEditorOpened] = React.useState(false);

  const hideLoanEditor = () => {
    setEditedLoan(undefined);
    setEditorOpened(false);
  };

  const hideAssetPicker = () => {
    setPickedAssetGid(undefined);
    setAssetPickerOpened(false);
  };

  if (isLoadingLoans || isRefetchingLoans || isLoadingHomes || isLoadingVehicles) {
    return <TablePlaceholder testId="customer-loans-loader" />;
  }

  return (
    <Container fitParentWidth>
      {assetPickerOpened && (
        <LoanAssetPicker
          leadId={leadId}
          assets={assets}
          cancelHandler={hideAssetPicker}
          confirmHandler={assetGid => {
            hideAssetPicker();
            setPickedAssetGid(assetGid);
            setEditorOpened(true);
          }}
        />
      )}
      {editorOpened && (pickedAssetGid || editedLoan) && (
        <LoanEditor
          personGid={personGid}
          assetGid={pickedAssetGid}
          loan={editedLoan}
          cancelHandler={hideLoanEditor}
          confirmHandler={() => hideLoanEditor()}
        />
      )}
      <LoansList
        loans={loans || []}
        onEditLoan={loan => {
          setEditedLoan(loan);
          setEditorOpened(true);
        }}
        onCreateLoan={() => setAssetPickerOpened(true)}
        createLoanEnabled={createLoanEnabled}
      />
    </Container>
  );
};

export default CustomerLoans;
