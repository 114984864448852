import { useMutation, UseMutationResult, useQueryClient } from '@tanstack/react-query';

import { ILeadDispositions } from '../../../interfaces/IDisposition';
import api from '../../../services/api';
import { LEAD_DISPOSITIONS_QUERY_KEY } from '../dispositions/useLeadDispositions';

interface UpdateLeadAssigneeParams {
  leadId: number;
  assigneeId: number;
}

const takeOverLead = (params: UpdateLeadAssigneeParams): Promise<null> =>
  api.put(`/api/frontend/leads/${params.leadId}/assignee/take_over`, {
    body: { agent_id: params.assigneeId }
  });

export const useTakeOverLead = (onSuccess?: (data: null, variables: UpdateLeadAssigneeParams) => void) =>
  useMutation({ mutationFn: takeOverLead, onSuccess });

const updateLeadAssignee = (params: UpdateLeadAssigneeParams): Promise<ILeadDispositions> =>
  api.put(`/api/frontend/leads/${params.leadId}/assignee`, {
    body: { agent_id: params.assigneeId }
  });

const useUpdateLeadAssignee = (
  onSuccess?: (
    data: ILeadDispositions,
    variables: UpdateLeadAssigneeParams
  ) => UseMutationResult<ILeadDispositions, any, UpdateLeadAssigneeParams>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateLeadAssignee,
    onSuccess: (result, variables) => {
      queryClient.setQueryData([LEAD_DISPOSITIONS_QUERY_KEY, variables.leadId?.toString()], () => result);
      onSuccess?.(result, variables);
    }
  });
};

export default useUpdateLeadAssignee;
