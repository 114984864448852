import { skipToken, useQuery } from '@tanstack/react-query';

import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const useAutoSearchByTerm = (term?: string) =>
  useQuery({
    queryKey: ['auto-search-by-term', term],
    queryFn: term
      ? (): Promise<any> => api.get(`/api/frontend/auto_search_entities/?${toQueryParams({ term })}`)
      : skipToken,
    staleTime: Infinity
  });

const useAutoYears = (year?: string) =>
  useQuery({
    queryKey: ['auto-years', year],
    queryFn: (): Promise<Array<number>> =>
      api.get(`/api/frontend/auto_search_entities/all_years?${toQueryParams({ year })}`),
    staleTime: Infinity
  });

const useAutoMakesByYear = (year?: string) =>
  useQuery({
    queryKey: ['auto-makes-by-year', year],
    queryFn: (): Promise<Array<string>> =>
      api.get(`/api/frontend/auto_search_entities/all_makes?${toQueryParams({ year })}`),
    staleTime: Infinity
  });

const useAutoModelsByMake = (make: string) =>
  useQuery({
    queryKey: ['auto-models-by-make', make],
    queryFn: make
      ? (): Promise<Array<string>> =>
          api.get(`/api/frontend/auto_search_entities/all_models?${toQueryParams({ make })}`)
      : skipToken,
    staleTime: Infinity
  });

const useAutoSubmodelsByModelAndMake = ({ model, make }: { model: string; make: string }) =>
  useQuery({
    queryKey: ['auto-submodels-by-model-make', model, make],
    queryFn:
      !!model && !!make
        ? (): Promise<Array<string>> =>
            api.get(`/api/frontend/auto_search_entities/all_submodels?${toQueryParams({ model, make })}`)
        : skipToken,
    staleTime: Infinity
  });

export { useAutoMakesByYear, useAutoModelsByMake, useAutoSearchByTerm, useAutoSubmodelsByModelAndMake, useAutoYears };
