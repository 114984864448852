import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { HomeEndorsement } from '../../interfaces/ICoverage';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const getPolicyHomeEndorsements = (
  policyId: number,
  lineOfBusinessId?: number,
  transactionId?: number,
  ivansHomeId?: number
): Promise<{ endorsements: HomeEndorsement[] }> =>
  api.get(
    `/api/frontend/policies/${policyId}/home/${lineOfBusinessId}/endorsements?${toQueryParams({
      transaction_id: transactionId,
      ivans_home_id: ivansHomeId
    })}`
  );

const usePolicyHomeEndorsements = (
  policyId: number,
  lineOfBusinessId?: number,
  transactionId?: number,
  ivansHomeId?: number,
  options?: Omit<UseQueryOptions<{ endorsements: HomeEndorsement[] }, any, any, any>, 'queryKey'>
) =>
  useQuery({
    queryKey: ['policy_endorsements', policyId, lineOfBusinessId, transactionId, ivansHomeId],
    queryFn:
      !!lineOfBusinessId && !!transactionId && !!ivansHomeId
        ? () => getPolicyHomeEndorsements(policyId, lineOfBusinessId, transactionId, ivansHomeId)
        : skipToken,
    select: data => data.endorsements,
    ...options
  });

export default usePolicyHomeEndorsements;
