import Tag from '../../../../components/core/Tag';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import useDataCollection from '../../../../queries/leads/data_collection/useDataCollection';
import colors from '../../../../theme/colors';
import { isCustomerDataCompletenessHigh } from '../../_helpers';

const DataCompletenessTag = () => {
  const { customerDataCompleteness, lead } = useGuidedSellingExperienceContext();
  const { data: dataCollection } = useDataCollection(lead?.id);
  const primaryOpportunity = dataCollection?.pages
    ?.flatMap(page => page.opportunities)
    ?.filter(Boolean)
    ?.find(opportunity => opportunity.primary);

  if (isCustomerDataCompletenessHigh(customerDataCompleteness) || primaryOpportunity?.is_data_collection_completed) {
    return (
      <Tag testId="data-completeness-tag" label="Completed data collection" backgroundColor={colors.statusGreen} />
    );
  }

  return null;
};

export default DataCompletenessTag;
