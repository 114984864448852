/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { omit, pick } from 'ramda';
import React from 'react';

import colors from '../../theme/colors';
import { paragraphCss } from '../../theme/typography';
import { spacingKeys, Spacings, spacingsCss } from '../../theme/variables';

const labelCSS = (required: boolean): SerializedStyles => css`
  ${paragraphCss};
  font-weight: normal;
  text-transform: none;
  margin-bottom: 0;
  ${required ? `&::after { content: '*'; color: ${colors.statusRed}; }` : ''}
`;

export interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement>, Spacings {
  required?: boolean;
  customCss?: SerializedStyles;
}

const Label = ({ required = false, customCss, children, ...rest }: LabelProps) => {
  const spacingProps = pick(spacingKeys, rest);
  const restOfProps = omit(spacingKeys, rest);

  return (
    <label
      {...restOfProps}
      css={css`
        ${labelCSS(required)}
        ${spacingsCss(spacingProps)}
        ${customCss}
      `}
    >
      {children}
    </label>
  );
};

export default Label;
