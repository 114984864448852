import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IVehicle } from '../../interfaces/IVehicle';
import api from '../../services/api';

const useNonSyncedPolicyVehicles = (
  policyId: string | number,
  options?: UseQueryOptions<{ vehicles: IVehicle[] }, any, any, any>
) =>
  useQuery({
    queryKey: ['non_synced_policy_vehicles', policyId],
    queryFn: (): Promise<{ vehicles: IVehicle[] }> => api.get(`/api/frontend/policies/non_synced/${policyId}/vehicles`),
    ...options,
    select: data => data.vehicles
  });

export default useNonSyncedPolicyVehicles;
