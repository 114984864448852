import './AutoDetails.scss';

import { Field, Form, Formik, FormikErrors, FormikTouched } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { ADDRESS_KEY_GARAGING, AutoForm, QUOTING_REQUIRED_MESSAGE, Translations } from '../../../constants';
import {
  API_TO_SMARTY_STREETS_MAPPING,
  CITY,
  GARAGING_ADDRESS,
  GARAGING_ADDRESS_ATTRIBUTES,
  LINE1,
  STATE,
  ZIP
} from '../../../constants/addressForm';
import autoSearchEntities from '../../../services/autoSearchEntities';
import CustomPropTypes from '../../../utils/customPropTypes';
import { getFieldValueGenerator } from '../../../utils/formHelpers';
import { createSmartyStreetsMapping, dig, except, rejectEmpty, renameKeys } from '../../../utils/object';
import { combine, requiredField, zipField } from '../../../utils/yupRules';
import { CollapsePanel, ErrorCol } from '../../common';
import SmartyStreetsAddress from '../../common/Address/Address';
import { Checkbox, Input, NumberInput, Select } from '../../common/FormikComponents/FormikComponents';
import { generateInitialValues as generateInitialGaragingAddress } from '../../common/nestedForms/Address';
import { autoName } from '../utils';

const SAVE = 'save';
const EDIT = 'edit';
const BLANK = '';
const MIN_VIN_SYMBOLS = 9;

const findGaragingAddress = (addresses: any[]) =>
  addresses.find((item: { address_key: string }) => item.address_key === ADDRESS_KEY_GARAGING);

class AutoDetails extends React.Component<any, any> {
  state = {
    onEditAuto: null,
    allYears: [],
    allMakes: [],
    allModels: [],
    allSubmodels: []
  };

  async componentDidMount() {
    const [makes, years] = await Promise.all([autoSearchEntities.getAllMakes(), autoSearchEntities.getAllYears()]);

    this.props.autos.map((auto: { model: any; submodel: any }) => {
      const models = [auto.model].map(model => ({ key: `${model}`, value: `${model}` }));
      const submodels = [auto.submodel].map(submodel => ({ key: `${submodel}`, value: `${submodel}` }));

      return this.setState((prevState: { allModels: any; allSubmodels: any }) => ({
        allModels: [...prevState.allModels, ...rejectEmpty(models)],
        allSubmodels: [...prevState.allSubmodels, ...rejectEmpty(submodels)]
      }));
    });

    this.setState({
      allMakes: makes.map((make: any) => ({ key: `${make}`, value: `${make}` })),
      allYears: years.map((year: any) => ({ key: year, value: year }))
    });
  }

  renderAutos = (
    errors: FormikErrors<{ autos: any }>,
    showErrors: boolean,
    touched: FormikTouched<{ autos: any }>,
    values: { autos: any }
  ) =>
    this.props.autos.map((auto: any, index: any) => (
      <div className="auto" key={Object.values(auto).join('.')}>
        <CollapsePanel title={this.renderAutoTitle(autoName(auto), auto.vin)}>
          {this.renderAutoForm(index, errors, showErrors, touched, values)}
        </CollapsePanel>
      </div>
    ));

  renderAutoTitle = (name: any, vin: any) => (
    <div className="d-flex flex-column ml-2">
      <div className="auto__title">{name}</div>
      {vin && <div>VIN: {vin}</div>}
    </div>
  );

  renderAutoForm = (index: any, errors: any, showErrors: boolean | undefined, touched: any, values: any) => {
    const { customerAddresses } = this.props;

    const useError = dig(errors, 'autos', index, 'use');
    const mileageError = dig(errors, 'autos', index, 'annual_mileage');
    const ownershipError = dig(errors, 'autos', index, 'ownership');
    const getFieldValue = getFieldValueGenerator({ touched, values }, `autos.${index}`);

    return (
      <div className="mt-3 auto-editor">
        <Row>
          <Col>{this.autoEntityView(index, errors, showErrors, values)}</Col>
        </Row>
        <Row>
          <ErrorCol
            sm={4}
            id="usage"
            error={(useError || false) as any}
            when
            warning={!getFieldValue('usage') && QUOTING_REQUIRED_MESSAGE}
          >
            <Label>Usage</Label>
            <Field component={Select} name={`autos[${index}].usage`} options={Translations.vehicleUsageOptions} />
          </ErrorCol>
          <ErrorCol
            sm={4}
            error={(mileageError || false) as any}
            when
            warning={!getFieldValue('annual_mileage') && QUOTING_REQUIRED_MESSAGE}
          >
            <Label>Annual Mileage</Label>
            <Field component={NumberInput} name={`autos[${index}].annual_mileage`} />
          </ErrorCol>
          <ErrorCol sm={4} error={(ownershipError || false) as any} when>
            <Label>Ownership *</Label>
            <Field
              component={Select}
              name={`autos[${index}].ownership`}
              options={Translations.vehicleOwnershipOptions}
            />
          </ErrorCol>
        </Row>
        <Row>
          <Col sm={5}>
            <div className="d-flex align-items-center">
              <Field name={`autos[${index}].used_for_ridesharing`} component={Checkbox} />
              <span className="ml-2">Used for ridesharing</span>
            </div>
          </Col>
        </Row>
        <Row>
          <ErrorCol
            id="vin"
            sm={12}
            error={dig(errors, 'autos', index, 'vin') as any}
            when={showErrors}
            className="mt-2"
          >
            <Label>VIN</Label>
            <Field component={Input} name={`autos[${index}].vin`} className="fs-mask" />
          </ErrorCol>
        </Row>
        <Row>
          <Col sm={12}>
            <SmartyStreetsAddress
              label="Garaging Address *"
              placeholder="Search for an address"
              /* @ts-expect-error TODO */
              defaultValue={renameKeys(
                API_TO_SMARTY_STREETS_MAPPING,
                generateInitialGaragingAddress(findGaragingAddress(this.props.customerAddresses))
              )}
              defaultOptions={customerAddresses.map((address: any) =>
                renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
              )}
              fallbackNames={createSmartyStreetsMapping(`autos.${index}.${GARAGING_ADDRESS}.`)}
            />
          </Col>
        </Row>
      </div>
    );
  };

  autoEntityView = (index: null, errors: any, showErrors: any, values: any) => {
    if (this.state.onEditAuto === index) {
      return (
        <React.Fragment>
          {this.editVehicleView({ index, errors, showErrors, values })}
          <div
            onClick={this.saveVehicleOnChangeHandler.bind(this)}
            className="qa-save-auto custom-vehicle-button u-cursor-pointer mt-n1 d-inline-block"
          >
            {SAVE}
          </div>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div
            onClick={this.editVehicleOnChangeHandler.bind(this, index)}
            className="qa-edit-auto custom-vehicle-button u-cursor-pointer"
          >
            {EDIT}
          </div>
        </React.Fragment>
      );
    }
  };

  editVehicleOnChangeHandler = async (index: string | number) => {
    const vehicle = this.props.autos[index];
    const vehicleModels = await autoSearchEntities.getAllModels(vehicle.make);
    const vehicleSubmodels = await autoSearchEntities.getAllSubmodels(vehicle.model);

    this.setState({
      allModels: rejectEmpty(vehicleModels).map((model: any) => ({ key: `${model}`, value: `${model}` })),
      allSubmodels: rejectEmpty(vehicleSubmodels).map((submodel: any) => ({ key: `${submodel}`, value: `${submodel}` }))
    });

    this.setState({ onEditAuto: index });
  };

  /* @ts-expect-error TODO */
  editVehicleView = ({ index, errors, showErrors, values }) => {
    return (
      <Row>
        <ErrorCol id="year" sm={3} error={this.autoSearchEntityErrors(errors).year} when={showErrors}>
          <Label>Year</Label>
          <Field
            component={Select}
            name={`autos[${index}].year`}
            maxLength="4"
            options={this.state.allYears}
            createOptionFromSearch
            showResetButton
          />
        </ErrorCol>
        <ErrorCol id="make" sm={3} error={this.autoSearchEntityErrors(errors).make} when={showErrors}>
          <Label>Make</Label>
          <Field
            component={Select}
            name={`autos[${index}].make`}
            placeholder="Ford"
            options={this.state.allMakes}
            onChange={this.autoSearchEntityMakeOnChangeHandler}
            createOptionFromSearch
            showResetButton
          />
        </ErrorCol>
        <ErrorCol id="model" sm={3} error={this.autoSearchEntityErrors(errors).model} when={showErrors}>
          <Label>Model</Label>
          <Field
            component={Select}
            name={`autos[${index}].model`}
            placeholder="Mustang"
            options={this.state.allModels}
            onChange={(value: any) =>
              this.autoSearchEntityModelOnChangeHandler({ model: value, make: values.autos[index].make })
            }
            createOptionFromSearch
            showResetButton
          />
        </ErrorCol>
        <ErrorCol id="submodel" sm={3} error={this.autoSearchEntityErrors(errors).submodel} when={showErrors}>
          <Label>Submodel</Label>
          <Field
            component={Select}
            name={`autos[${index}].submodel`}
            placeholder="Coupe"
            options={this.state.allSubmodels}
            createOptionFromSearch
            showResetButton
          />
        </ErrorCol>
      </Row>
    );
  };

  saveVehicleOnChangeHandler = () => {
    return this.setState({ onEditAuto: null });
  };

  autoSearchEntityMakeOnChangeHandler = async (value: string | undefined) => {
    if (!value) {
      return;
    }

    const models = await autoSearchEntities.getAllModels(value);
    const preparedModels = models.map((model: any) => ({ key: `${model}`, value: `${model}` }));

    this.setState({ allModels: preparedModels });
  };

  autoSearchEntityModelOnChangeHandler = async ({
    model,
    make
  }: {
    model: string | undefined;
    make: string | undefined;
  }) => {
    if (!model || !make) {
      return;
    }
    const submodels = await autoSearchEntities.getAllSubmodels({ make, model });
    const preparedSubmodels = submodels.map((submodel: any) => ({ key: `${submodel}`, value: `${submodel}` }));

    this.setState({ allSubmodels: preparedSubmodels });
  };

  autoSearchEntityErrors = (errors: any) => {
    return errors || {};
  };

  autoDefaultProps = (auto: any) => {
    const { customerAddresses } = this.props;

    return {
      vin: BLANK,
      submodel: BLANK,
      usage: AutoForm.AUTO_WORK_USAGE,
      ...rejectEmpty(auto),
      [GARAGING_ADDRESS]: generateInitialGaragingAddress(findGaragingAddress(customerAddresses))
    };
  };

  onSubmit = async (values: { autos: any[] }, { setSubmitting }: any) => {
    const normalizedValues = {
      ...values,
      autos: values.autos.map((auto: any) => ({
        ...except(auto, GARAGING_ADDRESS),
        [GARAGING_ADDRESS_ATTRIBUTES]: dig(auto, GARAGING_ADDRESS) || {}
      }))
    };

    try {
      await this.props.next(normalizedValues);
    } finally {
      (this as any).formik && setSubmitting(false);
    }
  };

  render() {
    const { autos, prev, cancelHandler } = this.props;

    return (
      <Formik
        innerRef={ref => ((this as any).formik = ref)}
        initialValues={{ autos: autos.map(this.autoDefaultProps) }}
        validationSchema={yup.object().shape({
          autos: yup.array().of(
            yup.object().shape({
              ownership: requiredField,
              vin: yup
                .string()
                .transform(value => (!value ? null : value))
                .nullable()
                .min(MIN_VIN_SYMBOLS, `should be equal or more than ${MIN_VIN_SYMBOLS} symbols length`),
              [GARAGING_ADDRESS]: yup.object().shape({
                [LINE1]: requiredField,
                [CITY]: requiredField,
                [STATE]: requiredField,
                [ZIP]: combine(requiredField, zipField)
              })
            })
          )
        })}
        onSubmit={this.onSubmit}
      >
        {({ errors, values, touched, isSubmitting }) => {
          const showErrors = Object.keys(touched).length === Object.keys(values).length;

          return (
            <Form className="step auto-details" autoComplete="off">
              <div className="step__title">Auto details</div>
              <div>{this.renderAutos(errors, showErrors, touched, values)}</div>
              <div className="d-flex flex-column mt-3">
                <Col sm={12} className="btn-container p-0">
                  <Button onClick={cancelHandler}>Cancel</Button>
                  <Button onClick={prev}>Prev</Button>
                  <Button color="primary" type="submit" className="confirm-button" disabled={isSubmitting}>
                    {this.props.isLastStep() ? 'Finish' : 'Next'}
                  </Button>
                </Col>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

/* @ts-expect-error TODO */
AutoDetails.propTypes = {
  autos: PropTypes.arrayOf(
    PropTypes.shape({
      vin: PropTypes.string,
      make: PropTypes.string,
      model: PropTypes.string,
      submodel: PropTypes.string,
      year: PropTypes.number
    })
  ),
  cancelHandler: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  isLastStep: PropTypes.func.isRequired,
  customerAddresses: CustomPropTypes.customerAddresses
};

/* @ts-expect-error TODO */
AutoDetails.defaultProps = {
  autos: [],
  customerAddresses: []
};

export default AutoDetails;
