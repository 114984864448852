import './ImportInfo.scss';

import classNames from 'classnames';
import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Label } from 'reactstrap';

import augmentationFailed from '../../../assets/img/icons/augmentation-fail.svg';
import CustomPropTypes from '../../../utils/customPropTypes';
import { values } from '../../../utils/object';
import { EmptyResults, Link } from '../../common';
import { Checkbox } from '../../common/FormikComponents/FormikComponents';
import { autoName, autosEqual, driverName, driversEqual } from '../utils';

class ImportInfo extends React.Component<any, any> {
  renderAutos = () =>
    this.props.autos.length === 0 ? (
      <EmptyResults message="No autos found" />
    ) : (
      this.props.autos.map(this.renderAutoCheckbox)
    );

  renderDrivers = () =>
    this.props.drivers.length === 0 ? (
      <EmptyResults message="No drivers found" />
    ) : (
      this.props.drivers.map(this.renderDriverCheckbox)
    );

  renderAutoCheckbox = ({ vin, key, ...auto }: any) => {
    const disabled = this.isAutoExists({ ...auto, vin });

    return (
      <div key={key}>
        <Label
          check
          className={classNames('checkbox-item', 'checkbox-item--auto', { 'checkbox-item--disabled': disabled })}
        >
          <Field name={key} component={Checkbox} disabled={disabled} />
          <div className="d-flex flex-column ml-2">
            <div>{autoName(auto)}</div>
            {vin && <div className="checkbox-item__vin fs-mask">VIN: {vin}</div>}
            {disabled && <div className="checkbox-item__already-imported">Already imported</div>}
          </div>
        </Label>
      </div>
    );
  };

  renderDriverCheckbox = ({ key, ...driver }: any) => {
    const disabled = this.isDriverExists(driver);

    return (
      <div key={key}>
        <Label check className={classNames('checkbox-item', { 'checkbox-item--disabled': disabled })}>
          <Field name={key} component={Checkbox} disabled={disabled} />
          <div className="d-flex flex-column ml-2">
            <div>{driverName(driver)}</div>
            {disabled && <div className="checkbox-item__already-imported">Already imported</div>}
          </div>
        </Label>
      </div>
    );
  };

  isAutoExists = (auto: { vin: any }) => this.props.existingAutos.some(({ asset }: any) => autosEqual(asset, auto));

  isDriverExists = (driver: { [x: string]: any; first_name?: string; last_name?: string }) =>
    this.props.existingDrivers.some((existingDriver: { first_name: string; last_name: string }) =>
      driversEqual(existingDriver, driver as any)
    );

  onSubmit = (selectedItems: ArrayLike<unknown> | { [s: string]: unknown }) => {
    const selectedKeys = Object.entries(selectedItems)
      .filter(([_key, value]) => value)
      .map(([key, _value]) => key);

    const selectedAutos = this.props.autos.filter((auto: { key: string }) => selectedKeys.includes(auto.key));
    const selectedDrivers = this.props.drivers.filter((driver: { key: string }) => selectedKeys.includes(driver.key));

    this.props.next({ selectedAutos, selectedDrivers });
  };

  renderInitialValues = () => {
    const { selectedAutos, selectedDrivers } = this.props;

    const values = {};

    selectedAutos.forEach(({ key }: any) => ((values as any)[key] = true));
    selectedDrivers.forEach(({ key }: any) => ((values as any)[key] = true));

    return values;
  };

  noItemsSelected = (props: string | any[]) => props.length === 0 || values(props).every((selected: any) => !selected);

  renderForm = () => (
    <Formik initialValues={this.renderInitialValues()} onSubmit={this.onSubmit}>
      {({ values, isSubmitting }) => (
        <Form className="step" autoComplete="off">
          <div className="d-flex flex-row">
            <div className="import-info__section">
              <div className="step__title">Autos</div>
              {this.renderAutos()}
            </div>
            <div className="import-info__section">
              <div className="step__title">Drivers</div>
              {this.renderDrivers()}
            </div>
          </div>
          <div className="d-flex flex-column mt-3">
            {this.renderFooter(isSubmitting || this.noItemsSelected(values as any))}
          </div>
        </Form>
      )}
    </Formik>
  );

  renderFailedAugmentation = () => (
    <div className="failed-augmentation">
      <div className="failed-augmentation__title">No data returned</div>
      {this.props.assetsUrl && (
        <Link to={{ pathname: this.props.assetsUrl, newAsset: true } as any} className="font-weight-bold">
          Create new asset
        </Link>
      )}
      <img src={augmentationFailed} alt="Augmentation failed" className="failed-augmentation__image" />
      <div className="failed-augmentation__footer">{this.renderFooter(true)}</div>
    </div>
  );

  renderFooter = (disabled: boolean | undefined) => (
    <Col className="btn-container p-0">
      <Button onClick={this.props.cancelHandler}>Cancel</Button>
      <Button onClick={this.props.prev}>Prev</Button>
      <Button color="primary" type="submit" className="confirm-button" disabled={disabled}>
        Next
      </Button>
    </Col>
  );

  isStepFailed = () => this.props.autos.length === 0 && this.props.drivers.length === 0;

  render() {
    return (
      <div className="import-info">{this.isStepFailed() ? this.renderFailedAugmentation() : this.renderForm()}</div>
    );
  }
}

const DRIVERS_PROPS = PropTypes.arrayOf(
  PropTypes.shape({
    first_name: PropTypes.string,
    middle_name: PropTypes.string,
    last_name: PropTypes.string
  })
);

// @ts-expect-error old-code
ImportInfo.propTypes = {
  autos: PropTypes.object.isRequired,
  drivers: DRIVERS_PROPS.isRequired,
  selectedAutos: PropTypes.object.isRequired,
  selectedDrivers: DRIVERS_PROPS.isRequired,
  existingAutos: PropTypes.arrayOf(CustomPropTypes.Auto).isRequired,
  existingDrivers: DRIVERS_PROPS.isRequired,
  prev: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  assetsUrl: PropTypes.string,
  cancelHandler: PropTypes.func.isRequired
};

// @ts-expect-error old-code
ImportInfo.defaultProps = {
  assetsUrl: null
};

export default ImportInfo;
