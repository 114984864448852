import alert from '../components/core/Alert';
import { humanize } from './formatter';

export const errorItems = (object: Record<string, any>) =>
  Object.entries(object).map(([key, value]) =>
    humanize(typeof value === 'string' || value instanceof String ? `${key} ${value}` : JSON.stringify(value))
  );

const alertFromApiResponse = (response: any) => {
  const data = response?.data || {};

  if (data.status === 'error') {
    const message = data.message;

    return message instanceof Array
      ? alert({ message: ' ', title: "Can't perform operation. Error(s): ", items: errorItems(message) }).error()
      : alert({ message: `Can't perform operation. Error(s): ${message}` }).error();
  }

  if (data.status === 'fail') {
    const message = errorItems(data.data);
    return alert({ message: ' ', title: "Can't perform operation. Error(s): ", items: message }).error();
  }

  if (data.errors instanceof Array) {
    return alert({ message: ' ', title: "Can't perform operation. Error(s): ", items: data.errors }).error();
  }

  return alert({ message: 'Response has wrong format' }).error();
};

export const genericNetworkError = () =>
  alert({
    message:
      // eslint-disable-next-line max-len
      'For AMP to work perfectly, please refresh the page. Just a heads-up, any unsaved changes might not make it through the reload.',
    title: 'It seems like your connection has hiccuped!'
  }).error();

export default alertFromApiResponse;
