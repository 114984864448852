// form
import { Field } from 'formik';
// utils
import { isNil } from 'ramda';
import React from 'react';
// components
import { Label } from 'reactstrap';

const IncidentTypeRadio = ({ name, formValue, formIndex, type }: any) => {
  const isChangeDisabled = (formValue: { gid: any }) => !isNil(formValue.gid);

  return (
    <Label className="text-capitalize">
      <Field type="radio" name={`${name}[${formIndex}].type`} disabled={isChangeDisabled(formValue)} value={type} />
      {type}
    </Label>
  );
};

export default IncidentTypeRadio;
