import './styles.scss';

import { Field } from 'formik';
import React from 'react';
import { Col, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { DocumentOwnerOption, FileToConfirm, FileToUpload } from '../../../../interfaces/IDocument';
import useDocumentTypes from '../../../../queries/document_types/useDocumentTypes';
import { showFieldErrorGenerator } from '../../../../utils/formHelpers';
import { dig } from '../../../../utils/object';
import { requiredField } from '../../../../utils/yupRules';
import ErrorCol from '../../ErrorCol/ErrorCol';
import { Select } from '../../FormikComponents/FormikComponents';
import FormModal from '../../FormModal/FormModal';

interface ConfirmModalProps {
  open: boolean;
  files: FileToConfirm[];
  ownersOptions: DocumentOwnerOption[] | undefined;
  cancelHandler: () => void;
  confirmHandler: (files: FileToUpload[]) => Promise<unknown>;
  isForPolicy: boolean;
}

const ConfirmModal = ({
  open,
  files,
  ownersOptions,
  cancelHandler,
  confirmHandler,
  isForPolicy
}: ConfirmModalProps) => {
  const { transformedData: documentTypes } = useDocumentTypes(!!isForPolicy);

  const confirm = ({ files }: { files: (FileToConfirm & { document_type_id: number; fileOwner: string })[] }) => {
    return confirmHandler(
      files.map(file => ({
        ...file,
        document_type_title: documentTypes?.find(type => type.id === file.document_type_id)?.title || null,
        document_type_key: documentTypes?.find(type => type.id === file.document_type_id)?.key
      }))
    );
  };

  const suggestDocType = (name: string) => documentTypes?.find(type => !!(name.match(type.regexp) || [])[0])?.id || '';

  const generateInitialValues = () => ({
    files: files.map(file => ({ ...file, document_type_id: suggestDocType(file.name), fileOwner: '' }))
  });

  return (
    <FormModal
      open={open}
      title="Upload File"
      size={ownersOptions ? 'xl' : 'md'}
      cancelHandler={cancelHandler}
      bodyClassNames="confirm-file-modal"
      confirmText="Upload"
      confirmHandler={confirm}
      initialValues={generateInitialValues()}
      validationSchema={yup.object().shape({
        files: yup.array().of(
          yup.object().shape({
            document_type_id: requiredField,
            ...(ownersOptions ? { fileOwner: requiredField } : {})
          })
        )
      })}
      enableReinitialize
      renderForm={({ touched, values, errors, isValid }) =>
        files.map((file, index) => {
          const fieldPrefix = `files.${index}.`;
          const showFieldError = showFieldErrorGenerator({ values, touched, isValid }, fieldPrefix);
          const generateFieldName = (fieldName: string) => `${fieldPrefix}${fieldName}`;
          const getFieldError = (fieldName: string) => dig(errors, 'files', index, fieldName);

          return (
            <Row className="d-flex align-items-center" key={file.uid}>
              <Col sm={ownersOptions ? 4 : 6}>
                <div className="confirm-file-modal__filename fs-mask">{file.name}</div>
              </Col>
              <Col sm={ownersOptions ? 8 : 6}>
                <Row>
                  <ErrorCol
                    sm={ownersOptions ? 6 : 12}
                    when={showFieldError('document_type_id') && !!getFieldError('document_type_id')}
                    error="Select a type of the document"
                  >
                    <Label hidden htmlFor={`document_type_id_${index}`}>
                      Select document type
                    </Label>
                    <Field
                      inputId={`document_type_id_${index}`}
                      name={generateFieldName('document_type_id')}
                      component={Select}
                      placeholder="Select Document Type"
                      className="confirm-file-modal__select qa-file-type-select"
                      options={documentTypes}
                      valueName="id"
                      labelName="title"
                    />
                  </ErrorCol>
                  {ownersOptions && (
                    <ErrorCol
                      sm={6}
                      when={showFieldError('fileOwner') && !!getFieldError('fileOwner')}
                      error="Select an owner of the document"
                    >
                      <Field
                        name={generateFieldName('fileOwner')}
                        component={Select}
                        options={ownersOptions}
                        placeholder="Select owner"
                        className="confirm-file-modal__select qa-file-owner-select"
                      />
                    </ErrorCol>
                  )}
                </Row>
              </Col>
            </Row>
          );
        })
      }
    />
  );
};

export default ConfirmModal;
