import { useMutation, useQuery } from '@tanstack/react-query';

import api from '../../../services/api';

interface FeedbackLeadArgs {
  leadGid: string;
  text: string;
  evaluation: string;
}

interface IFeedback {
  text: string;
  evaluation: number;
}

const feedbackLead = ({ leadGid, evaluation, text }: FeedbackLeadArgs): Promise<{ feedback: IFeedback }> =>
  api.post(`/api/frontend/leads/${leadGid}/feedback`, { body: { evaluation, text } });

export const useCreateLeadFeedback = () => useMutation({ mutationFn: feedbackLead });

export const useLeadFeedbacks = ({ leadGid }: { leadGid: string }) =>
  useQuery({
    queryKey: ['leadFeedbacks', leadGid],
    queryFn: (): Promise<{ feedbacks: IFeedback[] }> => api.get(`/api/frontend/leads/${leadGid}/feedback`),
    select: data => data.feedbacks
  });
