import { IUser } from './IUser';

export enum IProductAnnouncementReactionType {
  Positive = 'positive',
  Negative = 'negative',
  Neutral = 'neutral'
}

export interface IProductAnnouncementReaction {
  id: number;
  reaction_type: IProductAnnouncementReactionType;
  author: IUser;
}
