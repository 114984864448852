import { useMutation, useQuery } from '@tanstack/react-query';

import { IGarage, IHome, IPayloadAddress, IPersonAsset } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface HomePayload {
  address: IPayloadAddress;

  property_type?: string | null;
  residency_type?: string | null;
  year_built?: number | null;
  square_feet?: number | null;
  owner_since?: string | null;
  estimated_price?: number | null;
  construction?: string | null;
  foundation?: string | null;
  stories?: string | null;
  exterior_walls?: string | null;
  swimming_pool?: string | null;
  bathrooms_full?: number | null;
  bathrooms_half?: number | null;
  bathroom_grade?: string | null;
  kitchen_grade?: string | null;
  roof_year?: number | null;
  roof_material?: string | null;
  roof_design?: string | null;
  replacement_cost?: number | null;
  units?: number | null;
  total_rooms?: number | null;
  bedrooms?: number | null;
  heating?: string | null;
  air_conditioning?: string | null;
  sewer?: string | null;
  water?: string | null;
  quality?: string | null;
  architectural_style?: string | null;
  condition?: string | null;
  garages?: IGarage[] | null;
  ownership?: IPersonAsset['ownership'];
}

export const createHome = ({ personGid, data }: { personGid: string; data: HomePayload }): Promise<{ home: IHome }> =>
  api.post(`/api/frontend/people/${personGid}/homes`, { body: data });

export const updateHome = ({
  personGid,
  homeGid,
  data
}: {
  personGid: string;
  homeGid: string;
  data: HomePayload;
}): Promise<{ home: IHome }> => api.put(`/api/frontend/people/${personGid}/homes/${homeGid}`, { body: data });

export const useUpdatePersonHome = () => useMutation({ mutationFn: updateHome });

const usePersonHomes = (personGid: string | undefined) =>
  useQuery({
    queryKey: ['homes', personGid],
    queryFn: (): Promise<{ homes: IHome[] }> => api.get(`/api/frontend/people/${personGid}/homes`),
    enabled: !!personGid,
    select: data => data.homes
  });

export const useCreatePersonHome = () => useMutation({ mutationFn: createHome });

const detachHome = ({
  personGid,
  homeGid,
  leadId
}: {
  personGid: string;
  homeGid: string;
  leadId: number | undefined;
}): Promise<unknown> =>
  api.delete(`/api/frontend/people/${personGid}/homes/${homeGid}?${toQueryParams({ lead_id: leadId })}`);
export const useDetachHome = () => useMutation({ mutationFn: detachHome });

export const usePersonHomePanoramaView = ({
  personGid,
  homeGid,
  fetchLink
}: {
  personGid?: string;
  homeGid?: string;
  fetchLink?: boolean;
}) =>
  useQuery({
    queryKey: ['panorama_view_url', personGid, homeGid],
    queryFn: (): Promise<{ panorama_view_url: string | null; panorama_id: string | null }> =>
      api.get(`/api/frontend/people/${personGid}/homes/${homeGid}/panorama_view_url`),
    enabled: !!personGid && !!homeGid && fetchLink
  });

export const usePersonHomeZillowUrl = ({
  personGid,
  homeGid,
  fetchLink
}: {
  personGid?: string;
  homeGid?: string;
  fetchLink?: boolean;
}) =>
  useQuery({
    queryKey: ['zillow_url', personGid, homeGid],
    queryFn: (): Promise<{ zillow_url: string | null }> =>
      api.get(`/api/frontend/people/${personGid}/homes/${homeGid}/zillow_url`),
    enabled: !!personGid && !!homeGid && fetchLink,
    select: data => data.zillow_url
  });

export const usePersonHomeRedfinUrl = ({
  personGid,
  homeGid,
  fetchLink
}: {
  personGid?: string;
  homeGid?: string;
  fetchLink?: boolean;
}) =>
  useQuery({
    queryKey: ['redfin_url', personGid, homeGid],
    queryFn: (): Promise<{ redfin_url: string | null }> =>
      api.get(`/api/frontend/people/${personGid}/homes/${homeGid}/redfin_url`),
    enabled: !!personGid && !!homeGid && fetchLink,
    select: data => data.redfin_url
  });

export default usePersonHomes;
