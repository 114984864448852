import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IProductAnnouncement } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const getProductAnnouncement = (
  productAnnouncementId: string
): Promise<{ product_announcement: IProductAnnouncement }> =>
  api.get(`/api/frontend/product_announcements/${productAnnouncementId}`);

const getProductAnnouncements = ({
  role,
  released,
  unread
}: {
  role?: string | null;
  released?: boolean;
  unread?: boolean;
}): Promise<{ product_announcements: IProductAnnouncement[] }> =>
  api.get(`/api/frontend/product_announcements?${toQueryParams({ role, released, unread })}`);

export const useProductAnnouncement = (productAnnouncementId: string | undefined) =>
  useQuery({
    queryKey: ['product_announcement', productAnnouncementId],
    queryFn: productAnnouncementId ? () => getProductAnnouncement(productAnnouncementId!) : skipToken,
    select: data => data.product_announcement
  });

export const PRODUCT_ANNOUNCEMENTS_QUERY_KEY = 'product_announcements';

export interface ProductAnnouncementsQueryParams {
  role?: string | null;
  released?: boolean;
  unread?: boolean;
}

const useProductAnnouncements = ({
  role,
  released,
  unread,
  options
}: ProductAnnouncementsQueryParams & {
  options?: Omit<
    UseQueryOptions<{ product_announcements: IProductAnnouncement[] }, any, IProductAnnouncement[], any>,
    'queryKey'
  >;
}) =>
  useQuery({
    queryKey: ['product_announcements', role, released, unread],
    queryFn: () => getProductAnnouncements({ role, released, unread }),
    select: data => data.product_announcements,
    ...options
  });

export default useProductAnnouncements;
