import React from 'react';

import { Translations } from '../../constants';
import { IVehicle } from '../../interfaces/IVehicle';
import colors from '../../theme/colors';
import Tag from './Tag';

const VehicleKind = ({ kind }: { kind: IVehicle['kind'] }) => {
  if (!kind || kind === 'unknown') {
    return null;
  }

  return (
    <Tag backgroundColor={colors.violet} textColor={colors.violet} transparent label={Translations.vehicleKind(kind)} />
  );
};

export default VehicleKind;
