/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Drawer } from '@mui/material';

import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import { INotification } from '../../../../interfaces';
import { useMarkNotificationsAsRead } from '../../../../queries/notifications/useNotifications';
import { spacings } from '../../../../theme/variables';
import Notification from './Notification';

interface NotificationsProps {
  notifications: INotification[];
  opened: boolean;
  toggleOpened: () => void;
}

const NotificationsDrawer = ({ notifications, opened, toggleOpened }: NotificationsProps) => {
  const { mutateAsync: markNotificationsAsRead, isPending } = useMarkNotificationsAsRead();

  const unreadNotificationsIds = notifications.filter(({ read }) => !read).map(({ id }) => id);

  return (
    <Drawer anchor="left" open={opened} onClose={toggleOpened} PaperProps={{ sx: { marginLeft: 8 } }}>
      <Container
        pv={spacings.px24}
        ph={spacings.px12}
        customCss={css`
          width: 460px;
        `}
      >
        <FlexBox justifySpaceBetween alignItemsCenter mb={spacings.px12}>
          <Paragraph bold type="large">
            Your notifications
          </Paragraph>
          {!!unreadNotificationsIds.length && (
            <Button
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
              onClick={() => markNotificationsAsRead({ notificationsIds: unreadNotificationsIds })}
              loading={isPending}
            >
              Mark all read
            </Button>
          )}
        </FlexBox>
        <FlexBox columnDirection gap={spacings.px12}>
          {notifications.map(notification => (
            <Notification notification={notification} key={notification.id} />
          ))}
        </FlexBox>
      </Container>
    </Drawer>
  );
};

export default NotificationsDrawer;
