import { useMutation } from '@tanstack/react-query';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import api from '../../../services/api';

interface RenewPolicyRequest {
  person_gid: string;
  policy_id: number;

  policy_number: string;
  effective_date: string;
  expiration_date: string;
  premium: string;
  agent_id: string;

  deductible_type: string | undefined;
  deductible_value: number | undefined;
  primary_coverage: number | undefined;
  new_closing: boolean | undefined;

  lead_id: number | undefined;
}

const renewPolicy = (data: RenewPolicyRequest): Promise<{ policy: IMaticPolicy }> =>
  api.post(`/api/frontend/people/${data.person_gid}/policies/${data.policy_id}/renew`, { body: data });

const useRenewPolicy = () => useMutation({ mutationFn: renewPolicy });

export default useRenewPolicy;
