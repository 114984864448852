import { useQuery } from '@tanstack/react-query';

import { IDashboardTask } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export interface TasksDashboardSorting {
  sort_column: string;
  sort_order: string;
}

export interface TasksDashboardFilters {
  agent_ids?: string[];
  kinds?: string[];
  completed?: boolean;
  date_from?: string;
  date_to?: string;
  priorities?: string[];
  page?: number;
}

type TaskDashboardQuery = TasksDashboardSorting & TasksDashboardFilters;

export interface TasksDashboardResponse {
  tasks: IDashboardTask[];
  pages: number;
  count: number;
}

const useTasks = (query?: TaskDashboardQuery) =>
  useQuery({
    queryKey: ['tasks', query],
    queryFn: (): Promise<TasksDashboardResponse> => api.get(`/api/frontend/tasks?${toQueryParams(query)}`)
  });

export default useTasks;
