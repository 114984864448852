/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import ButtonWithoutBorder from '../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { ArrowRight2Icon } from '../../../components/core/icons';
import ImageModal from '../../../components/core/ImageModal';
import Paragraph from '../../../components/core/Paragraph';
import Text from '../../../components/core/Text';
import { IProductAnnouncement } from '../../../interfaces';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { dateFormatter, SHORT_DATE_FORMAT } from '../../../utils/formatter';
import parseHtmlString from '../../../utils/htmlStringParser';
import ProductAnnouncementReactions from './ProductAnnouncementReactions';

interface ProductAnnouncementProps {
  productAnnouncement: IProductAnnouncement;
  adminView?: boolean;
  fullView?: boolean;

  onSelectProductAnnouncement?: () => void;
}

const ProductAnnouncement = ({
  productAnnouncement: { id, release_date, preview, content, author, targeted_roles },
  adminView,
  fullView,
  onSelectProductAnnouncement
}: ProductAnnouncementProps) => {
  const [clickedImageSource, setClickedImageSource] = React.useState<string>();

  return (
    <>
      {adminView && (
        <Text type="small" color={colors.grey60}>
          {targeted_roles.length === 0
            ? 'Targeted at everyone'
            : `Targeted roles: ${targeted_roles.map(role => role.label).join(', ')}`}
        </Text>
      )}
      <Container mt={spacings.px6}>
        <Paragraph
          onClick={event => {
            const clickTarget = event.target as HTMLElement;
            if (clickTarget.tagName === 'IMG') {
              const source = (clickTarget as HTMLImageElement).currentSrc;
              setClickedImageSource(source);
            }
          }}
        >
          {parseHtmlString(preview)}
        </Paragraph>
      </Container>
      {content && fullView && (
        <Container>
          <Paragraph
            customCss={css`
              img {
                &:hover {
                  cursor: pointer;
                }
              }
            `}
            onClick={event => {
              const clickTarget = event.target as HTMLElement;
              if (clickTarget.tagName === 'IMG') {
                const source = (clickTarget as HTMLImageElement).currentSrc;
                setClickedImageSource(source);
              }
            }}
          >
            {parseHtmlString(content)}
          </Paragraph>
        </Container>
      )}
      {content && !fullView && (
        <ButtonWithoutBorder
          pv={spacings.px8}
          onClick={() => onSelectProductAnnouncement?.()}
          customCss={css`
            margin-left: -6px;
          `}
        >
          <FlexBox alignItemsCenter gap={spacings.px8}>
            <Paragraph bold type="small">
              Learn more
            </Paragraph>
            <ArrowRight2Icon width={spacings.px16} height={spacings.px16} color={colors.black} />
          </FlexBox>
        </ButtonWithoutBorder>
      )}
      {(!content || fullView) && (
        <FlexBox mt={spacings.px4} gap={spacings.px8}>
          <Paragraph type="small" color={colors.grey60}>
            {dateFormatter(release_date, SHORT_DATE_FORMAT)} by {author.name}
          </Paragraph>
        </FlexBox>
      )}

      <FlexBox gap={spacings.px8} mt={spacings.px4}>
        <ProductAnnouncementReactions productAnnouncementId={id} />
      </FlexBox>

      {clickedImageSource && (
        <ImageModal onClose={() => setClickedImageSource(undefined)} source={clickedImageSource} />
      )}
    </>
  );
};

export default ProductAnnouncement;
