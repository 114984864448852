import { IAddress } from './IAddress';

export enum CarrierKey {
  Universal = 'universal',
  Obie = 'obie',
  Allstate = 'allstate',
  JewelersMutual = 'jewelers_mutual',
  CSAA = 'csaa',
  Unknown = 'unknown'
}

export interface ICarrier {
  id: number;
  gid: string;
  key: CarrierKey | string;
  name: string;
  logo: null | string;
  quotable: boolean;
  post_sale: boolean;
  agency_portal_url: null | string;
  overnight_address?: null | IAddress;
  remittance_address?: null | IAddress;
  supports_policy_sync: boolean;
}
