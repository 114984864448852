import React from 'react';
import { Container } from 'reactstrap';

import { DATA_SOURCE_AUGMENTED } from '../../constants';
import analytics from '../../services/analytics';
import { Modal, StepsProgress, Wizard } from '../common';
import Text from '../core/Text';
import AutoDetails from './Steps/AutoDetails';
import CustomerInfo from './Steps/CustomerInfo';
import Drivers from './Steps/Drivers';
import ImportInfo from './Steps/ImportInfo';
import { addItemsKey } from './utils';

const CUSTOMER_INFO_STEP = 'Customer Info';
const IMPORT_INFO_STEP = 'Import Info';
const AUTO_DETAILS_STEP = 'Auto Details';
const DRIVERS_STEP = 'Drivers';

const INITIAL_STATE = {
  augmentationAutos: [],
  augmentationDrivers: [],
  selectedDateOfBirth: null,
  selectedAddress: null,
  selectedAutos: [],
  selectedDrivers: []
};

class AutoAugmentationModal extends React.Component<any, any> {
  state = INITIAL_STATE;

  onCustomerInfoStepCompleted = ({ date_of_birth, address }: any) => {
    const params = {
      date_of_birth,
      lead_id: this.props.leadId,
      address_line1: address.line1,
      address_line2: address.line2,
      city: address.city,
      state: address.state,
      zip: address.zip
    };

    this.setState({ selectedDateOfBirth: date_of_birth, selectedAddress: address });

    return this.props
      .getAutos(params)
      .then(({ payload: { autos, drivers } }: any) =>
        this.setState({ augmentationAutos: addItemsKey(autos), augmentationDrivers: addItemsKey(drivers) })
      );
  };

  onImportInfoStepCompleted = ({ selectedAutos, selectedDrivers }: any) => {
    this.setState({ selectedAutos, selectedDrivers });

    return Promise.resolve();
  };

  onAutoDetailsStepCompleted = ({ autos }: any) => {
    this.setState({ selectedAutos: autos });

    if (this.noDriversSelected()) {
      return this.sendAutos(autos).then(this.onCancel);
    }

    return Promise.resolve();
  };

  onDriversStepCompleted = ({ drivers }: any) => {
    const autoPromise = this.sendAutos(this.state.selectedAutos);
    const driverPromises = this.sendDrivers(drivers);

    return Promise.all([autoPromise, ...driverPromises]).then(this.onCancel);
  };

  sendAutos = (autos: any[]) =>
    this.props.createVehicles(
      autos.map((auto: { [x: string]: any; make: any; model: any; submodel: any; year: any; key: any }) =>
        this.prepareAuto(auto)
      )
    );

  sendDrivers = (drivers: any[]) => {
    analytics.track('Driver added', {
      lead_id: this.props.leadId,
      lead_gid: this.props.leadGid,
      person_gid: this.props.customer.gid,
      place: 'old_lead_profile_ui',
      source: 'fenris'
    });
    return drivers.map((driver: { [x: string]: any; key: any }) => this.props.createDriver(this.prepareDriver(driver)));
  };

  prepareAuto = ({ make, model, submodel, year, key: _key, ...rest }: any) => ({
    ...rest,
    make,
    model,
    submodel,
    year,
    data_source: DATA_SOURCE_AUGMENTED
  });

  prepareDriver = ({ key: _key, ...driver }: any) => ({ ...driver, data_source: DATA_SOURCE_AUGMENTED });

  noAutosSelected = () => this.state.selectedAutos.length === 0;

  onCancel = () => {
    this.setState(INITIAL_STATE);

    return this.props.cancelBtnHandler();
  };

  noDriversSelected = () => this.state.selectedDrivers.length === 0;

  render() {
    const { open, customer } = this.props;

    return (
      <>
        <Modal
          open={open}
          className="auto-augmentation-modal"
          title={<Text bold>Auto details</Text>}
          size="lg"
          bodyClassNames="p-0"
          cancelHandler={this.onCancel}
          showCancelButton={false}
        >
          <Container className="d-flex m-0 p-0">
            <Wizard header={StepsProgress} cancelHandler={this.onCancel}>
              {/* @ts-expect-error TODO */}
              <Wizard.Step name={CUSTOMER_INFO_STEP} onStepForward={this.onCustomerInfoStepCompleted}>
                {(props: JSX.IntrinsicAttributes) => (
                  <CustomerInfo
                    {...props}
                    customer={customer}
                    customerAddresses={this.props.customerAddresses}
                    dateOfBirth={this.state.selectedDateOfBirth}
                    address={this.state.selectedAddress}
                  />
                )}
                {/* @ts-expect-error TODO */}
              </Wizard.Step>
              {/* @ts-expect-error TODO */}
              <Wizard.Step name={IMPORT_INFO_STEP} onStepForward={this.onImportInfoStepCompleted}>
                {(props: JSX.IntrinsicAttributes) => (
                  <ImportInfo
                    {...props}
                    autos={this.state.augmentationAutos}
                    drivers={this.state.augmentationDrivers}
                    selectedAutos={this.state.selectedAutos}
                    selectedDrivers={this.state.selectedDrivers}
                    existingAutos={this.props.existingAutos}
                    existingDrivers={this.props.existingDrivers}
                    assetsUrl={this.props.showAssetsUrl ? `/leads/${this.props.leadId}/assets` : null}
                  />
                )}
                {/* @ts-expect-error TODO */}
              </Wizard.Step>
              {/* @ts-expect-error TODO */}
              <Wizard.Step
                name={AUTO_DETAILS_STEP}
                skipIf={this.noAutosSelected}
                onStepForward={this.onAutoDetailsStepCompleted}
              >
                {(props: JSX.IntrinsicAttributes) => (
                  <AutoDetails
                    {...props}
                    customerAddresses={this.props.customerAddresses}
                    autos={this.state.selectedAutos}
                    isLastStep={this.noDriversSelected}
                  />
                )}
                {/* @ts-expect-error TODO */}
              </Wizard.Step>
              {/* @ts-expect-error TODO */}
              <Wizard.Step name={DRIVERS_STEP} onStepForward={this.onDriversStepCompleted}>
                {(props: JSX.IntrinsicAttributes) => <Drivers {...props} drivers={this.state.selectedDrivers} />}
                {/* @ts-expect-error TODO */}
              </Wizard.Step>
            </Wizard>
          </Container>
        </Modal>
      </>
    );
  }
}

// @ts-expect-error TODO
AutoAugmentationModal.defaultProps = {
  customerAddresses: [],
  showAssetsUrl: true
};

export default AutoAugmentationModal;
