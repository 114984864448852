import useCarriers from '../../../queries/carriers/useCarriers';
import { useActiveFCRAConsent, useFCRADisclosure } from '../../../queries/disclosures/useDisclosure';
import usePersonVehicles from '../../../queries/people/personVehicles';
import usePerson from '../../../queries/people/usePerson';
import usePersonAddresses from '../../../queries/people/usePersonAddresses';
import usePersonHomes from '../../../queries/people/usePersonHomes';
import usePersonRelatedPeople from '../../../queries/people/usePersonRelatedPeople';
import useUserLicenses from '../../../queries/user_licenses/useUserLicenses';

const useQuotingAssetsData = ({ personGid }: { personGid: string | undefined }) => {
  const { data: person, isPending: isPendingPerson, refetch: refetchPerson } = usePerson(personGid);
  const { data: fcraDisclosure, isPending: isPendingFcraDisclosure } = useFCRADisclosure();
  const { data: isFcraDisclosureAccepted, isPending: isPendingFcraConsent } = useActiveFCRAConsent(personGid);
  const { data: homes, isPending: isPendingHomes } = usePersonHomes(personGid);
  const { data: vehicles, isPending: isPendingVehicles, refetch: refetchVehicles } = usePersonVehicles(personGid);
  const { data: carriers, isPending: isPendingCarriers } = useCarriers({ appointed: true, quotable: true });
  const { data: personAddresses, isPending: isPendingPersonAddresses } = usePersonAddresses(personGid);
  const { data: userLicenses, isPending: isPendingUserLicenses } = useUserLicenses();
  const {
    data: relatedPeople,
    isPending: isPendingRelatedPeople,
    refetch: refetchDrivers
  } = usePersonRelatedPeople(person?.gid);

  return {
    isPending:
      isPendingPerson ||
      isPendingFcraDisclosure ||
      isPendingFcraConsent ||
      isPendingHomes ||
      isPendingVehicles ||
      isPendingCarriers ||
      isPendingPersonAddresses ||
      isPendingRelatedPeople ||
      isPendingUserLicenses,
    person,
    fcraDisclosure,
    isFcraDisclosureAccepted,
    homes,
    vehicles,
    relatedPeople,
    carriers,
    personAddresses,
    refetchPerson,
    refetchVehicles,
    refetchDrivers,
    userLicenses,
    opportunities: undefined
  };
};

export default useQuotingAssetsData;
