/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';

import colors from '../../../theme/colors';
import { Spacings, spacings } from '../../../theme/variables';
import Container from '../Container';
import FlexBox from '../FlexBox';
import { CheckIcon, ErrorIcon, InfoIcon, WarningIcon } from '../icons';
import Text from '../Text';

interface ISystemMessage extends Spacings {
  type: 'info' | 'success' | 'error' | 'warning';
  heading?: string | JSX.Element;
  description?: React.ReactNode;
  customCss?: SerializedStyles;
  innerRef?: React.Ref<HTMLDivElement>;
}

const colorsMap = {
  error: colors.statusRed,
  info: colors.violet,
  success: colors.statusGreen,
  warning: colors.statusOrange
} as const;

const backgroundColorsMap = {
  error: colors.redBackground,
  info: colors.violetBackground,
  success: colors.white,
  warning: colors.warningBackground
} as const;

const Icon = ({ type }: { type: ISystemMessage['type'] }) => {
  switch (type) {
    case 'error':
      return <ErrorIcon color={colorsMap.error} />;
    case 'info':
      return <InfoIcon color={colorsMap.info} />;
    case 'success':
      return <CheckIcon color={colorsMap.success} />;
    case 'warning':
      return <WarningIcon color={colorsMap.warning} />;
  }
};

const SystemMessage = ({ type, description, heading, customCss, innerRef, ...rest }: ISystemMessage): JSX.Element => {
  return (
    <FlexBox
      pv={spacings.px12}
      ph={spacings.px24}
      ref={innerRef}
      {...rest}
      backgroundColor={backgroundColorsMap[type]}
      roundBorder
      css={css`
        ${type === 'success' && `border: 1px solid ${colorsMap[type]};`}
        ${customCss};
      `}
    >
      <Container mr={spacings.px12}>
        <Icon type={type} />
      </Container>
      <div>
        {typeof heading === 'string' ? <Text block>{heading}</Text> : heading}
        <Text mb={spacings.px0} type="small" color={colors.grey80}>
          {description}
        </Text>
      </div>
    </FlexBox>
  );
};

export default SystemMessage;
