/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import DropDown from '../../../../components/core/buttons/DropDown';
// eslint-disable-next-line max-len
import CancelTrailingScheduledCallModal from '../../../../components/DispositionsModals/CancelTrailingScheduledCallModal';
import { isDispositionSold, isEndDisposition } from '../../../../components/DispositionsModals/dispositionsHelper';
import TrailingOpportunitiesModal from '../../../../components/DispositionsModals/TrailingOpportunitiesModal';
// eslint-disable-next-line max-len
import NewTrailingOpportunitiesModal from '../../../../components/DispositionsModals/TrailingOpportunitiesModal/NewTrailingOpportunitiesModal';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { DispositionType } from '../../../../interfaces/IDisposition';
import { OpportunityStatus } from '../../../../interfaces/IOpportunity';
import { ScheduledInteractionFlow } from '../../../../interfaces/IScheduledCall';
import { UserRoleCategory } from '../../../../interfaces/IUser';
import useLeadOpportunities from '../../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonActiveLead from '../../../../queries/people/usePersonActiveLead';
import { usePersonScheduledInteractions } from '../../../../queries/scheduled_calls/useScheduledInteractions';
import analytics from '../../../../services/analytics';
import authInfo, { isISR, isSuperISR } from '../../../../services/authInfo';
import { findUncompletedCall } from '../../../ScheduleCall/helpers';
import { isLeadOutdated } from '../../_helpers';
import { useCurrentDispositionSubscriptionContext } from '../../_hooks/useCurrentDispositionSubscription';
import guidedSellingExperienceNavigation, { GuidedSellingPathnames, GuidedSellingRoutes } from '../../navigation';
import Disqualify from '../Disqualify';
import CrossSellButton from './CrossSellButton';
import ReopenButton from './ReopenButton';

const LeadDispositionsHeader = () => {
  const {
    lead: openedLead,
    personGid,
    insuranceCategory,
    isLoading: isLoadingContext
  } = useGuidedSellingExperienceContext();
  const leadId = openedLead?.id;
  const navigate = useNavigate();

  const { data: opportunities, refetch: refetchOpportunities } = useLeadOpportunities({ leadId });

  const { data: activeLead, isPending: isPendingActiveLead } = usePersonActiveLead({
    personGid,
    insuranceCategory
  });
  const leadOutdated = !isPendingActiveLead && !isLoadingContext && isLeadOutdated({ activeLead, openedLead });

  const { currentDisposition, isUpdatingDisposition } = useCurrentDispositionSubscriptionContext();

  const { data: scheduledCalls } = usePersonScheduledInteractions(personGid, [
    ScheduledInteractionFlow.Calendly,
    ScheduledInteractionFlow.CalendlyFollowUp,
    ScheduledInteractionFlow.ScheduledCall,
    ScheduledInteractionFlow.ScheduledCallIsrs,
    ScheduledInteractionFlow.ScheduledCallV2,
    ScheduledInteractionFlow.ScheduledCallEmail,
    ScheduledInteractionFlow.ScheduledByRecapture
  ]);

  const isLoading = !opportunities || isUpdatingDisposition;

  const { search, pathname } = useLocation();

  const [trailingOpportunitiesModalOpened, setTrailingOpportunitiesModalOpened] = React.useState(false);
  const [cancelCallModalOpened, setCancelCallModalOpened] = React.useState<boolean>(false);

  const uncompletedCall = personGid && findUncompletedCall(scheduledCalls);

  const superISRFlow = isSuperISR();

  const showFinalizeLead =
    !featureFlags.newQuotesUI &&
    currentDisposition &&
    (currentDisposition.disposition_type === DispositionType.Pipeline ||
      currentDisposition.disposition_type === DispositionType.Qualified) &&
    (!isISR() || superISRFlow);

  const atLeastOneOpportunitySold = !!opportunities?.find(opp => opp.status === OpportunityStatus.Sold);
  const isAllOpportunitiesSold = opportunities?.every(opp => opp.status === OpportunityStatus.Sold);
  const displayCrossSellButton = isDispositionSold(currentDisposition?.disposition_type) && !isAllOpportunitiesSold;
  const displayReopenButton = !displayCrossSellButton;

  const disableFinalizeDropdown =
    currentDisposition?.assignee?.id !== authInfo.currentUserId &&
    (authInfo.roleCategory === UserRoleCategory.Agent || superISRFlow);

  return (
    <>
      {openedLead && (
        <Disqualify
          lead={openedLead}
          openCancelCallModal={() => {
            uncompletedCall && setCancelCallModalOpened(true);
          }}
        />
      )}
      {currentDisposition && !pathname.startsWith(GuidedSellingPathnames.Finalize) && (
        <>
          {showFinalizeLead && (
            <>
              {atLeastOneOpportunitySold && !superISRFlow ? (
                <Button
                  size={ButtonSize.Small}
                  onClick={() => navigate(`${GuidedSellingRoutes.Finalize}${search}`)}
                  loading={isLoading}
                  customCss={css`
                    white-space: nowrap;
                  `}
                >
                  Finalize lead
                </Button>
              ) : (
                <div data-tip="Lead should be assigned to you" data-for="assign-to-self-tip">
                  {superISRFlow ? (
                    <Button
                      disabled={disableFinalizeDropdown}
                      size={ButtonSize.Small}
                      variant={ButtonVariant.SecondaryDanger}
                      onClick={() => setTrailingOpportunitiesModalOpened(true)}
                      loading={isLoading}
                      customCss={css`
                        white-space: nowrap;
                      `}
                    >
                      All lost
                    </Button>
                  ) : (
                    <DropDown
                      disabled={disableFinalizeDropdown}
                      loading={isLoading}
                      size={ButtonSize.Small}
                      optionsSize={ButtonSize.Small}
                      options={[
                        { label: 'At least one sold', value: DispositionType.PolicySold },
                        !featureFlags.cstGuidedSellingExperience && { label: 'All lost', value: DispositionType.Lost }
                      ].filter(Boolean)}
                      onSelected={selected => {
                        analytics.track('Finalize lead dropdown clicked', { value: selected.value });
                        if (selected.value === DispositionType.PolicySold) {
                          return navigate(`${GuidedSellingRoutes.Finalize}${search}`);
                        } else {
                          setTrailingOpportunitiesModalOpened(true);
                        }
                      }}
                      customCss={css`
                        white-space: nowrap;
                      `}
                    >
                      Finalize lead
                    </DropDown>
                  )}
                  {disableFinalizeDropdown && <Tooltip id="assign-to-self-tip" />}
                </div>
              )}
            </>
          )}
        </>
      )}
      {!leadOutdated && isEndDisposition(currentDisposition?.disposition_type) && (
        <>
          {displayCrossSellButton && <CrossSellButton />}
          {displayReopenButton && <ReopenButton />}
        </>
      )}
      {leadOutdated && activeLead && (
        <Button
          onClick={() => navigate(guidedSellingExperienceNavigation.forLead({ lead_gid: activeLead.gid }))}
          size={ButtonSize.Small}
        >
          Open active lead
        </Button>
      )}

      {trailingOpportunitiesModalOpened && opportunities && leadId && (
        <>
          {showFinalizeLead ? (
            <NewTrailingOpportunitiesModal
              leadId={leadId}
              opportunities={opportunities}
              dispositionType={DispositionType.Lost}
              closeModal={() => {
                setTrailingOpportunitiesModalOpened(false);
              }}
              onDispositionUpdated={() => {
                refetchOpportunities().then(() => {
                  uncompletedCall && setCancelCallModalOpened(true);
                  if (featureFlags.displayDisqualifyButton) {
                    navigate(
                      `${superISRFlow ? GuidedSellingRoutes.ISRFinish : GuidedSellingRoutes.AgentFinish}${search}`
                    );
                  } else {
                    navigate(`${GuidedSellingRoutes.Finalize}${search}`);
                  }
                });
              }}
            />
          ) : (
            <TrailingOpportunitiesModal
              leadId={leadId}
              opportunities={opportunities}
              dispositionType={DispositionType.Lost}
              closeModal={() => {
                setTrailingOpportunitiesModalOpened(false);
              }}
              onDispositionUpdated={() => {
                refetchOpportunities();
                uncompletedCall && setCancelCallModalOpened(true);
              }}
            />
          )}
        </>
      )}
      {uncompletedCall && cancelCallModalOpened && (
        <CancelTrailingScheduledCallModal
          personGid={personGid}
          scheduledCall={uncompletedCall}
          assignee={currentDisposition?.assignee}
          onCloseModal={() => setCancelCallModalOpened(false)}
        />
      )}
    </>
  );
};

export default LeadDispositionsHeader;
