/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import featureFlags from '../../../../constants/featureFlags';
import { useCallState } from '../../../../contexts/CallContext';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { spacings } from '../../../../theme/variables';
import DynamicTips from '../../_components/DynamicTips';
import EngagementBrand from '../EngagementBrand';
import UnknownPersonTitle from '../UnknownPersonTitle';

const UnknownPersonHeader = () => {
  const { currentCallId } = useCallState();
  const { callLogId } = useGuidedSellingExperienceContext();

  return (
    <FlexBox
      id="heading"
      justifySpaceBetween
      p={spacings.px8}
      borderBottom
      customCss={css`
        min-height: 52px;
        flex-flow: row wrap;
      `}
    >
      <FlexBox
        gap={spacings.px12}
        customCss={css`
          flex-flow: row wrap;
        `}
      >
        <UnknownPersonTitle />
        <EngagementBrand />
      </FlexBox>
      {featureFlags.dynamicTips && currentCallId && (
        <DynamicTips key={callLogId} isDisclosureAccepted={false} withLifeTip={false} />
      )}
    </FlexBox>
  );
};

export default UnknownPersonHeader;
