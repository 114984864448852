import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IDataCollection, IOpportunity } from '../../../interfaces';
import { InsurableInterest, Requirement } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { updateDataCollectionQueryState } from './useDataCollection';

const disqualifyDataCollectionOpportunity = ({
  opportunityId,
  leadId,
  disqualificationReason,
  disqualificationMessage
}: {
  opportunityId: IOpportunity['id'];
  leadId: string | number;
  disqualificationReason: string | undefined;
  disqualificationMessage: string | undefined;
}): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/opportunities/${opportunityId}/disqualify`, {
    body: { disqualification_reason: disqualificationReason, disqualification_message: disqualificationMessage }
  });

export const useDisqualifyDataCollectionOpportunity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: disqualifyDataCollectionOpportunity,
    onSuccess: (result, variables) => updateDataCollectionQueryState(queryClient, result, variables)
  });
};

const updateOpportunityCoverages = ({
  opportunityId,
  leadId,
  coverages
}: {
  opportunityId: IOpportunity['id'];
  leadId: string | number;
  coverages: IOpportunity['agent_selected_coverages'];
}): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/opportunities/${opportunityId}/coverages`, {
    body: { coverages }
  });

export const useUpdateOpportunityCoverages = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOpportunityCoverages,
    onSuccess: (result, variables) => {
      updateDataCollectionQueryState(queryClient, result, variables);

      onSuccess?.();
    }
  });
};

const updateOpportunitiesDataCollectionStatus = ({
  opportunitiesIds,
  leadId,
  dataCollectionEnabled
}: {
  opportunitiesIds: IOpportunity['id'][];
  leadId: string | number;
  dataCollectionEnabled: boolean;
}): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/opportunities/data_collection_status`, {
    body: { enabled: dataCollectionEnabled, opportunities_ids: opportunitiesIds }
  });

export const useUpdateOpportunityDataCollectionStatus = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateOpportunitiesDataCollectionStatus,
    onSuccess: (result, variables) => {
      updateDataCollectionQueryState(queryClient, result, variables);

      onSuccess?.();
    }
  });
};

const reconcileOpportunities = ({
  leadId,
  insurableInterest,
  requirement,
  assetsGids
}: {
  leadId: string | number;
  insurableInterest?: InsurableInterest;
  requirement?: Requirement;
  assetsGids?: string[];
}): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/reconcile_opportunities`, {
    body: { insurable_interest: insurableInterest, requirement, assets_gids: assetsGids }
  });

export const useReconcileOpportunities = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: reconcileOpportunities,
    onSuccess: (result, variables) => {
      updateDataCollectionQueryState(queryClient, result, variables);

      onSuccess?.();
    }
  });
};
