import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { StartQuotingRequest, StartQuotingResponse } from '../../interfaces/IQuotesRequests';
import { isStubQuotingModeEnabled } from '../../pages/GuidedDataCollection/_helpers';
import api from '../../services/api';

export const startQuoting = ({
  personGid,
  leadId,
  startQuotingRequest
}: {
  personGid: string | undefined;
  leadId: string | number | undefined;
  startQuotingRequest: StartQuotingRequest;
}): Promise<StartQuotingResponse> =>
  api.post('/api/frontend/quotes_requests', {
    body: { ...startQuotingRequest, person_gid: personGid, lead_id: leadId, stub_mode: isStubQuotingModeEnabled() }
  });

const useStartQuoting = (
  onSuccess?: UseMutationOptions<
    StartQuotingResponse,
    unknown,
    Parameters<typeof startQuoting>[0],
    unknown
  >['onSuccess']
) => useMutation({ mutationFn: startQuoting, onSuccess, throwOnError: false });

export default useStartQuoting;
