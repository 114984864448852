/** @jsxImportSource @emotion/react */
import './ErrorCol.scss';

import { css, Global } from '@emotion/react';
import classNames from 'classnames';
import React from 'react';
import { Col, ColProps } from 'reactstrap';

const SHOW_ERROR = 'error-column--error';
const SHOW_WARNING = 'error-column--warning';
const HIDE_ICON = 'error-column--without-icon';
const NOWRAP_MESSAGE = 'error-column--no-wrap-message';

import warningIcon from '../../../assets/new_icons/warning.svg';

interface Props extends ColProps {
  className?: string;
  error?: string | boolean | JSX.Element;
  when?: boolean;
  withoutIcon?: boolean;
  noWrapText?: boolean;
  messageClassName?: string;
}

const ErrorCol = ({ className, messageClassName, error, when, warning, withoutIcon, noWrapText, ...props }: Props) => {
  const showError = error && when && !warning;
  const showWarning = warning && when && !error;

  return (
    <Col
      className={classNames(className, 'error-column', {
        [SHOW_ERROR]: showError,
        [SHOW_WARNING]: showWarning,
        [HIDE_ICON]: withoutIcon,
        [NOWRAP_MESSAGE]: noWrapText
      })}
      {...props}
    >
      <Global
        styles={() => css`
          .error-column__msg {
            &::before {
              mask: url(${warningIcon}) no-repeat center center;
            }
          }
        `}
      />
      {props.children}
      <div className={classNames('error-column__msg', messageClassName)}>
        {showError ? error : null}
        {showWarning ? warning : null}
      </div>
    </Col>
  );
};

export default ErrorCol;
