import * as React from 'react';

import { UploadArea } from '../../../components/common/UploadFiles';
import FlexBox from '../../../components/core/FlexBox';
import TablePlaceholder from '../../../components/TablePlaceholder';
import { IMaticPolicy } from '../../../interfaces';
import { DocumentOwnerOption, DocumentOwnerType, FileToUpload } from '../../../interfaces/IDocument';
import { findPolicyType } from '../../../interfaces/IPolicyType';
import usePersonDocuments, {
  useCreateDocument,
  useDeleteDocument
} from '../../../queries/people/person_documents/usePersonDocuments';
import usePersonMaticPolicies from '../../../queries/people/person_policies/usePersonMaticPolicies';
import { spacings } from '../../../theme/variables';
import { compact } from '../../../utils/array';
import { moneyFormatter } from '../../../utils/formatter';
import DocumentsList from './DocumentsList';

const CONFIRM_DELETE_TEXT = 'Are you sure you want to delete this file?';

const generatePolicyOwnersOptions = (policies: IMaticPolicy[] | undefined): DocumentOwnerOption[] | undefined =>
  policies?.map(policy => {
    const policyType = findPolicyType(policy.policy_type);
    const policyPremium = moneyFormatter(policy.premium);

    const value = compact([
      policy.status,
      policyType?.name,
      'policy.',
      `Carrier - ${policy.carrier.name}, ${policyPremium}`
    ]).join(' ');

    return {
      key: JSON.stringify({
        owner_id: policy.id,
        owner_type: DocumentOwnerType.Policy
      }),
      value,
      default: false
    };
  });

const CustomerDocuments = ({ personGid, leadId }: { personGid: string; leadId: number | undefined }) => {
  const {
    data: documents,
    isFetching: isFetchingDocuments,
    refetch: refetchDocuments
  } = usePersonDocuments({ personGid });
  const { data: policies, isFetching: isFetchingPolicies } = usePersonMaticPolicies({
    personGid,
    filters: { lead_id: leadId }
  });

  const [filesToUpload, setFilesToUpload] = React.useState<FileToUpload[]>([]);

  const { mutateAsync: createDocument } = useCreateDocument({ attachDocs: false });
  const { mutateAsync: deleteDocument } = useDeleteDocument({ invalidate: false });

  const isFetching = isFetchingDocuments || isFetchingPolicies;

  const onAttachHandler = (files: FileToUpload[]) => {
    setFilesToUpload(files);

    return Promise.all(
      files.map(({ uid, document_type_id, fileOwner, ...rest }) => {
        const owner = fileOwner === 'person' ? undefined : (JSON.parse(fileOwner) as any);
        const request = owner
          ? {
              uid,
              document_type_id,
              owner_id: owner.owner_id,
              owner_type: owner.owner_type,
              ...rest
            }
          : {
              uid,
              document_type_id,
              ...rest
            };

        return createDocument({
          personGid,
          request
        });
      })
    ).then(() => refetchDocuments().then(() => setFilesToUpload([])));
  };

  const policyOwnersOptions = generatePolicyOwnersOptions(policies);
  const personOwnerOption = { key: 'person', value: 'Other', default: false };

  return isFetching ? (
    <TablePlaceholder testId="lead-files-loader" />
  ) : (
    <FlexBox fitParentWidth columnDirection gap={spacings.px16}>
      <UploadArea
        onAttach={onAttachHandler}
        ownersOptions={policyOwnersOptions?.concat([personOwnerOption])}
        isForPolicy={false}
      />
      <DocumentsList
        documents={documents}
        filesToUpload={filesToUpload}
        onDelete={documentId =>
          window.confirm(CONFIRM_DELETE_TEXT) &&
          deleteDocument({ personGid, documentId }).then(() => refetchDocuments())
        }
      />
    </FlexBox>
  );
};

export default CustomerDocuments;
