import { styled, Switch } from '@mui/material';

import colors from '../../theme/colors';

const MaterialUISwitch = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    padding: 0,
    top: '7px',
    transform: 'translateX(6px)',
    '&.Mui-checked': {
      color: '#fff',
      border: `2px solid ${colors.azure50}`,
      transform: 'translateX(28px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: colors.azure50
      }
    }
  },
  '& .MuiSwitch-track': {
    backgroundColor: colors.grey30,
    opacity: 1
  }
}));

const SwitchToggle = ({
  checked,
  onChange,
  disabled,
  testId
}: {
  checked: boolean;
  disabled: boolean;
  onChange: () => void;
  testId?: string;
}) => {
  return <MaterialUISwitch data-testid={testId} checked={checked} disabled={disabled} onChange={onChange} />;
};

export default SwitchToggle;
