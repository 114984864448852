/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from './FlexBox';
import Text from './Text';

interface IIconHeader {
  children: React.ReactNode;
  text: string;
  extendedClassName?: string;
}

const IconWithText = ({ children, text, extendedClassName }: IIconHeader): JSX.Element => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <FlexBox mr={8}>{children}</FlexBox>
      <Text type="large" className={extendedClassName}>
        {text}
      </Text>
    </div>
  );
};

export default IconWithText;
