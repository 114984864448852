import './PeopleList.scss';

import { ClassNames } from '@emotion/react';
import React from 'react';

import { Copy, EmptyResults, Link, MailLink } from '../../components/common';
import Tooltip from '../../components/common/Tooltip/Tooltip';
import { EditIcon } from '../../components/core/icons';
import TelephonyActions from '../../components/core/TelephonyActions';
import TelephonyTooltip from '../../components/core/TelephonyTooltip';
import Text from '../../components/core/Text';
import Sortable, { ORDER_TYPES } from '../../hocs/Sortable';
import { IPersonWithDncStatus } from '../../interfaces';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { ellipsis, phoneFormatter } from '../../utils/formatter';

const FIRST_NAME = 'first_name';
const LAST_NAME = 'last_name';
const EMAIL = 'email';
const PHONE = 'phone';
const DATE_OF_BIRTH = 'date_of_birth';
const ADDRESS = 'address';

const ADDRESS_ELLIPSIS_COUNT = 46;

const PeopleList = ({ people }: { people: IPersonWithDncStatus[] | undefined }) =>
  people?.length ? (
    <Sortable
      sortableColumns={{
        [FIRST_NAME]: ORDER_TYPES.STRING,
        [LAST_NAME]: ORDER_TYPES.STRING,
        [EMAIL]: ORDER_TYPES.STRING,
        [PHONE]: ORDER_TYPES.STRING,
        [DATE_OF_BIRTH]: ORDER_TYPES.DATE,
        [ADDRESS]: ORDER_TYPES.STRING
      }}
      list={people}
      render={({
        list,
        sortBy,
        getSortableClasses
      }: {
        list: IPersonWithDncStatus[];
        sortBy: (column: string) => (() => void) | undefined;
        getSortableClasses: (column: string) => string;
      }) => (
        <div className="people-list">
          <table className="table">
            <thead>
              <tr>
                <th className={getSortableClasses(FIRST_NAME)} onClick={sortBy(FIRST_NAME)}>
                  First name
                </th>
                <th className={getSortableClasses(LAST_NAME)} onClick={sortBy(LAST_NAME)}>
                  Last name
                </th>
                <th className={getSortableClasses(EMAIL)} onClick={sortBy(EMAIL)}>
                  Email
                </th>
                <th className={getSortableClasses(PHONE)} onClick={sortBy(PHONE)}>
                  Phone
                </th>
                <th className={getSortableClasses(ADDRESS)} onClick={sortBy(ADDRESS)}>
                  Address
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {list.map(current => {
                const editLink = { pathname: `/people/${current.gid}` };

                const canCallWithDNC = authInfo.features.do_not_contact_call_available;

                const dnc = current.do_not_contact && (
                  <Text color={colors.statusRed} data-testid="do-not-contact">
                    Do not contact
                  </Text>
                );

                const email = (!current.do_not_contact || canCallWithDNC) && (
                  <TelephonyActions email={current.email} personGid={current.gid}>
                    <ClassNames>
                      {({ css }) => (
                        <MailLink
                          className={
                            current.do_not_contact && authInfo.features.do_not_contact_call_available
                              ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                              : 'fs-mask'
                          }
                          mail={current.email}
                        />
                      )}
                    </ClassNames>
                  </TelephonyActions>
                );

                const number = (!current.do_not_contact || canCallWithDNC) && (
                  <TelephonyTooltip phone={current.phone || ''} personGid={current.gid}>
                    <ClassNames>
                      {({ css }) => (
                        <Copy
                          className={
                            current.do_not_contact && authInfo.features.do_not_contact_call_available
                              ? `${css(`color: ${colors.statusRed};`)} fs-mask`
                              : 'fs-mask'
                          }
                          value={phoneFormatter(current.phone)}
                        />
                      )}
                    </ClassNames>
                  </TelephonyTooltip>
                );

                return (
                  <tr key={current.gid} className="person-row">
                    <td>
                      <Link className="fs-mask" to={editLink}>
                        {current.first_name}
                      </Link>
                    </td>
                    <td>
                      <Link className="fs-mask" to={editLink}>
                        {current.last_name}
                      </Link>
                    </td>
                    <td>{email || dnc}</td>
                    <td className="phone-column">{number || dnc}</td>
                    <td>
                      <Tooltip className="fs-mask" message={current.living_address?.full} left>
                        <div className="fs-mask">{ellipsis(current.living_address?.full, ADDRESS_ELLIPSIS_COUNT)}</div>
                      </Tooltip>
                    </td>
                    <td className="edit-link" onClick={event => event.stopPropagation()}>
                      <Link to={editLink}>
                        <EditIcon color={colors.black} />
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    />
  ) : (
    <EmptyResults message="No customers found" />
  );

export default PeopleList;
