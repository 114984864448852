import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import Text from '../../../components/core/Text';

const CustomerInfoPlaceholder = (): JSX.Element => (
  <FlexBox columnDirection fitParentWidth>
    <Heading
      customCss={css`
        width: 50%;
        padding-top: 10px;
      `}
    >
      <Skeleton />
    </Heading>
    {[1, 2, 3, 4].map(value => (
      <Text
        key={value}
        customCss={css`
          padding-top: 10px;
        `}
      >
        <Skeleton />
      </Text>
    ))}
  </FlexBox>
);

export default CustomerInfoPlaceholder;
