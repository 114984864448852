/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FieldArray, useFormikContext } from 'formik';
import * as React from 'react';
import * as yup from 'yup';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import Blockquote from '../../../components/core/Blockquote';
import AddButton from '../../../components/core/buttons/AddButton';
import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import {
  DateInputField,
  InputField,
  PhoneField,
  RadioGroupField,
  SelectField
} from '../../../components/core/forms/fields';
import Heading from '../../../components/core/Heading';
import { ArrowDownIcon } from '../../../components/core/icons';
import Paragraph, { ParagraphType } from '../../../components/core/Paragraph';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../components/core/QuestionVerificationStatusIcon';
import { Translations } from '../../../constants';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import useConstant from '../../../hooks/useConstant';
import useToggle from '../../../hooks/useToggle';
import DatapointKey, { DatapointsUsageKey, NO_YES_OPTIONS, YES_VALUE } from '../../../interfaces/IDatapoint';
import { InsurableInterest } from '../../../interfaces/IPolicyType';
import { useSaveAnswers as useSaveAnswersByDatapointsUsage } from '../../../queries/answers/useAnswers';
import { acceptDisclosures } from '../../../queries/disclosures/useDisclosure';
import { useReconcileOpportunities } from '../../../queries/leads/data_collection/useDataCollectionOpportunities';
import { useCreateRelatedPerson } from '../../../queries/people/usePersonRelatedPeople';
import analytics from '../../../services/analytics';
import authInfo, { isISR } from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { phoneFormatter } from '../../../utils/formatter';
import { emailField, minDOBField, phoneField, REQUIRED_MESSAGE, requiredField } from '../../../utils/yupRules';
import { StepContentProps } from '..';
import LeadWarnings from '../_components/LeadWarnings';
import RelatedPersonFormModal from '../_components/RelatedPersonFormModal';
import RemovePersonRelationModal from '../_components/RemovePersonRelationModal';
import { FormValues, useCustomerProfilePageInitialValues } from './_hooks';

const OtherPhones = ({ children }: { children: React.ReactNode }) => {
  const [isShown, setIsShown] = React.useState(false);
  const { errors, submitCount } = useFormikContext<FormValues>();
  const phoneErrorExists = errors.person?.phone_secondary || errors.person?.phone_business;

  React.useEffect(() => {
    if (phoneErrorExists && submitCount > 0) {
      setIsShown(current => {
        if (!current) {
          return true;
        }
        return current;
      });
    }
  }, [phoneErrorExists, submitCount, setIsShown]);

  return (
    <Container>
      <Button
        data-testid="other-phones-toggle-button"
        disabled={!!phoneErrorExists}
        variant={ButtonVariant.Text}
        onClick={() => {
          setIsShown(current => !current);
        }}
      >
        <FlexBox
          gap={spacings.px4}
          customCss={css`
            &:hover {
              opacity: 0.8;
            }
          `}
        >
          <Paragraph type={ParagraphType.Small} color={colors.grey60} ml={spacings.px4}>
            Other phones
          </Paragraph>
          <ArrowDownIcon
            color={colors.grey60}
            css={css`
              transform: rotate(${isShown ? '180deg' : '0deg'});
              transition: transform 0.2s;
            `}
          />
        </FlexBox>
      </Button>
      {isShown && children}
    </Container>
  );
};

const EnhanceWithVerificationStatus = ({
  answersWithVerificationStatus,
  children,
  disabled,
  personGid,
  answerKey
}: {
  answersWithVerificationStatus: ReturnType<
    typeof useCustomerProfilePageInitialValues
  >['answersWithVerificationStatus'];
  children: React.ReactNode;
  disabled: boolean;
  personGid: string;
  answerKey: DatapointKey;
}) => {
  const verificationStatus = answersWithVerificationStatus[personGid]?.[answerKey]?.verificationStatus;

  return (
    <FlexBox alignItemsBaseline>
      {verificationStatus && (
        <Container>
          <QuestionVerificationStatusIcon
            verificationStatus={verificationStatus}
            disabled={disabled}
            css={css`
              margin-left: -${spacings.px20}px;
            `}
          />
        </Container>
      )}
      {children}
    </FlexBox>
  );
};

const CustomerProfilePage = ({ dataCollection, onSubmit, isDataEditingForbidden }: StepContentProps): JSX.Element => {
  const { personGid, person, refetchPerson, lead, isLifeLead } = useGuidedSellingExperienceContext();

  const {
    answersWithVerificationStatus,
    relatedPeopleInitialValues,
    personInitialValues,
    fcraDisclosure,
    isFcraDisclosureAccepted,
    refetchFcraConsent,
    refetchRelatedPeople,
    isLoading
  } = useCustomerProfilePageInitialValues();

  const { mutateAsync: saveAnswers } = useSaveAnswersByDatapointsUsage();

  const { mutateAsync: addRelatedPerson, isPending: isAddingRelatedPerson } = useCreateRelatedPerson();

  const [addRelatedPersonModalShown, toggleAddRelatedPersonModal] = useToggle(false);
  const [relatedPersonToDelete, setRelatedPersonToDelete] =
    React.useState<(typeof relatedPeopleInitialValues)[number]>();

  const todayDate = useConstant(() => new Date());

  const { mutateAsync: reconcileOpportunities } = useReconcileOpportunities();

  if (isLoading) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  const isISRFlow = isISR();

  const initialValues = {
    ...personInitialValues,
    related_people: relatedPeopleInitialValues
  };

  const relatedPersonValidationSchema = yup.object().shape({
    first_name: requiredField,
    last_name: requiredField,
    phone: phoneField({ loose: authInfo.features.loose_phone_validation }),
    email: emailField,
    marital_status: requiredField,
    relation_kind: requiredField,
    date_of_birth: isISRFlow ? minDOBField() : minDOBField().required(REQUIRED_MESSAGE)
  });

  const validationSchema = yup.object({
    person: yup.object().shape({
      first_name: requiredField,
      last_name: requiredField,
      email: emailField.required(REQUIRED_MESSAGE),
      phone: phoneField({ loose: authInfo.features.loose_phone_validation }).required(REQUIRED_MESSAGE),
      phone_secondary: phoneField({ loose: authInfo.features.loose_phone_validation }),
      phone_business: phoneField({ loose: authInfo.features.loose_phone_validation }),
      date_of_birth: minDOBField().required(REQUIRED_MESSAGE),
      marital_status: requiredField,
      fcra: isISRFlow ? requiredField : yup.string()
    }),
    related_people: yup.array().of(relatedPersonValidationSchema)
  });

  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      controlsWidth={320}
      submitText="Next"
      submitTestId="submit-customer-profile-button"
      disabled={isDataEditingForbidden}
      initialStatus={isDataEditingForbidden ? 'disabled' : undefined}
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={async ({ person: { fcra, ...restOfPerson }, related_people }) => {
        const buildPersonAnswers = (
          data:
            | Omit<(typeof initialValues)['person'], 'fcra'>
            | Omit<(typeof initialValues)['related_people'][number], 'relation_kind'>
        ) => {
          return Object.keys(data).map(key => ({
            key: `person_${key}`,
            person_gid: data.gid,
            engagement_gid: lead?.gid || null,
            value: data[key as keyof typeof data],
            asset_gid: null,
            related_person_gid: null
          }));
        };

        await Promise.all([
          saveAnswers({
            datapointsUsageKey: DatapointsUsageKey.CustomerProfileInfo,
            answers: buildPersonAnswers(restOfPerson)
          }),
          ...related_people.map(relatedPerson =>
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.PersonRelationKind,
              answers: [
                {
                  key: DatapointKey.PersonRelationKind,
                  person_gid: restOfPerson.gid,
                  engagement_gid: lead?.gid || null,
                  value: relatedPerson.relation_kind,
                  asset_gid: null,
                  related_person_gid: relatedPerson.gid
                }
              ]
            })
          ),
          ...related_people.map(({ relation_kind, ...rest }) =>
            saveAnswers({
              datapointsUsageKey: DatapointsUsageKey.CustomerProfileInfo,
              answers: buildPersonAnswers(rest)
            })
          )
        ]);

        // Disclosure should be accepted only after person is updated, to avoid problems with
        // automatic people merge during update.
        fcra === YES_VALUE &&
          !isFcraDisclosureAccepted &&
          (await acceptDisclosures({ personGid: personGid!, disclosures: [fcraDisclosure!] }));

        await reconcileOpportunities({ leadId: lead!.id, insurableInterest: InsurableInterest.PersonalBelongings });

        refetchPerson();
        !isLifeLead && refetchRelatedPeople();
        refetchFcraConsent();

        return await onSubmit();
      }}
      renderForm={({ values }) => {
        return (
          <>
            <LeadWarnings dataCollection={dataCollection} />
            <FlexBox alignItemsCenter gap={spacings.px8} mb={spacings.px24}>
              <Heading type="h4">Customer profile</Heading>
              {!isLifeLead && (
                <AddButton
                  onClick={toggleAddRelatedPersonModal}
                  content="Add person"
                  disabled={isDataEditingForbidden}
                />
              )}
            </FlexBox>
            <Paragraph type="large" bold>
              Primary customer
            </Paragraph>
            <FlexBox pl={spacings.px16} columnDirection>
              <EnhanceWithVerificationStatus
                personGid={values.person.gid}
                answerKey={DatapointKey.PersonFirstName}
                answersWithVerificationStatus={answersWithVerificationStatus}
                disabled={isDataEditingForbidden}
              >
                <InputField fsMask inline label="First name" id="person.first_name" name="person.first_name" required />
              </EnhanceWithVerificationStatus>
              <EnhanceWithVerificationStatus
                personGid={values.person.gid}
                answerKey={DatapointKey.PersonMiddleName}
                answersWithVerificationStatus={answersWithVerificationStatus}
                disabled={isDataEditingForbidden}
              >
                <InputField fsMask inline label="Middle name" id="person.middle_name" name="person.middle_name" />
              </EnhanceWithVerificationStatus>
              <EnhanceWithVerificationStatus
                personGid={values.person.gid}
                answerKey={DatapointKey.PersonLastName}
                answersWithVerificationStatus={answersWithVerificationStatus}
                disabled={isDataEditingForbidden}
              >
                <InputField fsMask inline label="Last name" id="person.last_name" name="person.last_name" required />
              </EnhanceWithVerificationStatus>
              <EnhanceWithVerificationStatus
                personGid={values.person.gid}
                answerKey={DatapointKey.PersonDateOfBirth}
                answersWithVerificationStatus={answersWithVerificationStatus}
                disabled={isDataEditingForbidden}
              >
                <DateInputField
                  fsMask
                  inline
                  label="DOB"
                  id="person.date_of_birth"
                  name="person.date_of_birth"
                  maxDate={todayDate}
                  required
                />
              </EnhanceWithVerificationStatus>
              <EnhanceWithVerificationStatus
                personGid={values.person.gid}
                answerKey={DatapointKey.PersonMaritalStatus}
                answersWithVerificationStatus={answersWithVerificationStatus}
                disabled={isDataEditingForbidden}
              >
                <SelectField
                  id="person.marital_status"
                  name="person.marital_status"
                  label="Marital status"
                  inline
                  required
                  options={Translations.maritalStatusOptions}
                />
              </EnhanceWithVerificationStatus>
              <Paragraph bold m={spacings.px4} mr={0}>
                Contact
              </Paragraph>
              <EnhanceWithVerificationStatus
                personGid={values.person.gid}
                answerKey={DatapointKey.PersonPhone}
                answersWithVerificationStatus={answersWithVerificationStatus}
                disabled={isDataEditingForbidden}
              >
                <PhoneField fsMask inline label="Phone number" id="person.phone" name="person.phone" required />
              </EnhanceWithVerificationStatus>
              <OtherPhones>
                <EnhanceWithVerificationStatus
                  personGid={values.person.gid}
                  answerKey={DatapointKey.PersonPhoneSecondary}
                  answersWithVerificationStatus={answersWithVerificationStatus}
                  disabled={isDataEditingForbidden}
                >
                  <PhoneField
                    fsMask
                    inline
                    label="Second phone"
                    id="person.phone_secondary"
                    name="person.phone_secondary"
                  />
                </EnhanceWithVerificationStatus>
                <EnhanceWithVerificationStatus
                  personGid={values.person.gid}
                  answerKey={DatapointKey.PersonPhoneBusiness}
                  answersWithVerificationStatus={answersWithVerificationStatus}
                  disabled={isDataEditingForbidden}
                >
                  <PhoneField
                    fsMask
                    inline
                    label="Business phone"
                    id="person.phone_business"
                    name="person.phone_business"
                  />
                </EnhanceWithVerificationStatus>
              </OtherPhones>

              <EnhanceWithVerificationStatus
                personGid={values.person.gid}
                answerKey={DatapointKey.PersonEmail}
                answersWithVerificationStatus={answersWithVerificationStatus}
                disabled={isDataEditingForbidden}
              >
                <InputField fsMask inline label="Email" id="person.email" name="person.email" required />
              </EnhanceWithVerificationStatus>
            </FlexBox>

            <FieldArray
              name="related_people"
              render={relatedPeopleArrayHelpers => {
                return (
                  <>
                    {addRelatedPersonModalShown && (
                      <RelatedPersonFormModal
                        cancelHandler={toggleAddRelatedPersonModal}
                        isLoading={isAddingRelatedPerson}
                        confirmHandler={async values => {
                          const {
                            related_person: {
                              gid,
                              first_name,
                              middle_name,
                              last_name,
                              name,
                              date_of_birth,
                              kind,
                              marital_status,
                              phone,
                              email
                            }
                          } = await addRelatedPerson({
                            sourcePersonGid: personGid!,
                            data: values
                          });
                          refetchRelatedPeople();

                          analytics.track('Related person added', {
                            lead_gid: lead?.gid,
                            person_gid: personGid,
                            place: 'guided_selling_experience'
                          });
                          relatedPeopleArrayHelpers.push({
                            gid,
                            first_name,
                            middle_name: middle_name || '',
                            last_name,
                            date_of_birth: date_of_birth || '',
                            relation_kind: kind || '',
                            name,
                            marital_status: marital_status || '',
                            phone: phoneFormatter(phone) || '',
                            email: email || ''
                          });
                          toggleAddRelatedPersonModal();
                        }}
                      />
                    )}
                    {values.related_people.map((relatedPerson, index) => {
                      return (
                        <React.Fragment key={relatedPerson.gid}>
                          {person && relatedPersonToDelete?.gid === relatedPerson.gid && (
                            <RemovePersonRelationModal
                              person={person}
                              relatedPerson={relatedPersonToDelete}
                              cancelHandler={() => setRelatedPersonToDelete(undefined)}
                              confirmHandler={() => {
                                analytics.track('Related person deleted', {
                                  lead_gid: lead?.gid,
                                  person_gid: personGid,
                                  place: 'guided_selling_experience'
                                });
                                refetchRelatedPeople();
                                const indexByGid = values.related_people.findIndex(
                                  person => person.gid === relatedPersonToDelete.gid
                                );
                                relatedPeopleArrayHelpers.remove(indexByGid);
                                setRelatedPersonToDelete(undefined);
                              }}
                            />
                          )}
                          <Container mt={spacings.px24}>
                            <FlexBox alignItemsCenter mb={spacings.px8}>
                              <Paragraph type="large" bold>
                                {Translations.relationKind(relatedPerson.relation_kind)}
                              </Paragraph>
                              <IconButton
                                data-testid="related-person-delete-button"
                                icon={ButtonIcons.Delete}
                                color={colors.grey60}
                                customCss={css`
                                  margin-left: ${spacings.px4}px;
                                `}
                                onClick={() => setRelatedPersonToDelete(relatedPerson)}
                                disabled={isDataEditingForbidden}
                              />
                            </FlexBox>
                            <FlexBox pl={spacings.px16} columnDirection>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonFirstName}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <InputField
                                  fsMask
                                  inline
                                  label="First name"
                                  required
                                  id={`related_people.${index}.first_name`}
                                  name={`related_people.${index}.first_name`}
                                />
                              </EnhanceWithVerificationStatus>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonMiddleName}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <InputField
                                  fsMask
                                  inline
                                  label="Middle name"
                                  id={`related_people.${index}.middle_name`}
                                  name={`related_people.${index}.middle_name`}
                                />
                              </EnhanceWithVerificationStatus>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonLastName}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <InputField
                                  fsMask
                                  inline
                                  label="Last name"
                                  required
                                  id={`related_people.${index}.last_name`}
                                  name={`related_people.${index}.last_name`}
                                />
                              </EnhanceWithVerificationStatus>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonDateOfBirth}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <DateInputField
                                  fsMask
                                  inline
                                  label="DOB"
                                  required={!isISRFlow}
                                  id={`related_people.${index}.date_of_birth`}
                                  name={`related_people.${index}.date_of_birth`}
                                  maxDate={todayDate}
                                />
                              </EnhanceWithVerificationStatus>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonRelationKind}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <SelectField
                                  id={`related_people.${index}.relation_kind`}
                                  name={`related_people.${index}.relation_kind`}
                                  label="Relationship to customer"
                                  inline
                                  required
                                  options={Translations.relationshipOptions}
                                />
                              </EnhanceWithVerificationStatus>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonMaritalStatus}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <SelectField
                                  id={`related_people.${index}.marital_status`}
                                  name={`related_people.${index}.marital_status`}
                                  label="Marital status"
                                  inline
                                  required
                                  options={Translations.maritalStatusOptions}
                                />
                              </EnhanceWithVerificationStatus>
                              <Paragraph bold m={spacings.px4} mr={0}>
                                Contact
                              </Paragraph>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonPhone}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <PhoneField
                                  fsMask
                                  inline
                                  label="Phone number"
                                  id={`related_people.${index}.phone`}
                                  name={`related_people.${index}.phone`}
                                />
                              </EnhanceWithVerificationStatus>
                              <EnhanceWithVerificationStatus
                                personGid={relatedPerson.gid}
                                answerKey={DatapointKey.PersonEmail}
                                answersWithVerificationStatus={answersWithVerificationStatus}
                                disabled={isDataEditingForbidden}
                              >
                                <InputField
                                  fsMask
                                  inline
                                  label="Email"
                                  id={`related_people.${index}.email`}
                                  name={`related_people.${index}.email`}
                                />
                              </EnhanceWithVerificationStatus>
                            </FlexBox>
                          </Container>
                        </React.Fragment>
                      );
                    })}
                  </>
                );
              }}
            />

            {fcraDisclosure && !isFcraDisclosureAccepted && (
              <Container
                mt={spacings.px24}
                ml={spacings.px20}
                css={css`
                  max-width: 600px;
                `}
              >
                <RadioGroupField
                  label={() => (
                    <Blockquote
                      text={
                        <Heading
                          customCss={css`
                            ${isISRFlow ? `&::after { content: '*'; color: ${colors.statusRed}; }` : ''}
                          `}
                        >
                          {fcraDisclosure.prompt}
                        </Heading>
                      }
                    />
                  )}
                  inline
                  required={isISRFlow}
                  id="person.fcra"
                  name="person.fcra"
                  options={NO_YES_OPTIONS}
                />
              </Container>
            )}
            <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
          </>
        );
      }}
    />
  );
};

export default CustomerProfilePage;
