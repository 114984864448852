import moment from 'moment';

import {
  IAssetsQuotesGroup,
  QuoteGroupingOption,
  QuoteOrderingOption,
  RESULTS_PER_PAGE
} from '../../../../interfaces/IGroupedQuotes';
import { QuoteStatus } from '../../../../interfaces/IQuote';
import { IQuotesRequest } from '../../../../interfaces/IQuotesRequests';
import useAggregatedQuotes from '../../../../queries/people/person_quotes/useAggregatedQuotes';
import { customerChoicesFromQuotes, findLatestCxQuotesRequest } from '../_helpers';

const useCustomerChoices = ({
  personGid,
  leadGid,
  quotesRequests
}: {
  personGid: string | undefined;
  leadGid: string | undefined;
  quotesRequests: IQuotesRequest[] | undefined;
}) => {
  const latestCxQuotesRequest = findLatestCxQuotesRequest(quotesRequests);
  const { data: groupedCxQuotes, isLoading } = useAggregatedQuotes<IAssetsQuotesGroup>({
    personGid,
    filters: {
      lead_gid: leadGid,
      quotes_request_gid: latestCxQuotesRequest?.gid,
      max_date: moment.parseZone(latestCxQuotesRequest?.created_at).add(2, 'seconds').toString(),
      group_by: QuoteGroupingOption.Asset,
      order_by: QuoteOrderingOption.CheapestFirst,
      page: 1,
      per_page: RESULTS_PER_PAGE,
      asset_gids: [],
      carrier_ids: [],
      policy_types: [],
      statuses: [
        QuoteStatus.Success,
        QuoteStatus.CompleteManually,
        QuoteStatus.DeclinedBusiness,
        QuoteStatus.CarrierError,
        QuoteStatus.InvalidQuote,
        QuoteStatus.InvalidData,
        QuoteStatus.QuotingServiceError,
        QuoteStatus.ProductUnavailable,
        QuoteStatus.CredentialsExpired,
        QuoteStatus.Unknown
      ]
    },
    options: {
      // AMP user should work with a lead much later after customer had a chance to select a quote,
      // so no reason to refetch quotes.
      refetchInterval: false,
      staleTime: Infinity
    }
  });

  return {
    customerChoices: customerChoicesFromQuotes(groupedCxQuotes),
    isLoading
  };
};

export default useCustomerChoices;
