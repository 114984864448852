/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Text from '../../../components/core/Text';
import { IMaticPolicy } from '../../../interfaces';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { capitalize, dateFormatter, moneyFormatter, typedMoneyFormatter } from '../../../utils/formatter';

const PolicySummaryCard = ({ policy }: { policy: IMaticPolicy }) => {
  return (
    <Container>
      <FlexBox
        columnDirection
        p={spacings.px12}
        gap={spacings.px12}
        mr={spacings.px24}
        border
        borderColor={colors.grey30}
        roundBorder
        boxShadow
        customCss={css`
          height: auto;
          width: 242px;
          min-width: 242px;
        `}
      >
        <FlexBox justifySpaceBetween>
          <FlexBox gap={spacings.px4}>
            <Text bold>{capitalize(policy.policy_type)}</Text>
          </FlexBox>
        </FlexBox>
        <FlexBox columnDirection gap={spacings.px6}>
          {policy.carrier && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text type="small" color={colors.grey60}>
                Carrier
              </Text>
              <Text type="small" singleLine>
                {policy.carrier.name}
              </Text>
            </FlexBox>
          )}
          {policy.policy_number && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text type="small" color={colors.grey60}>
                Policy number
              </Text>
              <Text className="fs-mask" type="small" singleLine>
                {policy.policy_number}
              </Text>
            </FlexBox>
          )}
          {policy.effective_date && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text type="small" color={colors.grey60}>
                Effective date
              </Text>
              <Text type="small">{dateFormatter(policy.effective_date)}</Text>
            </FlexBox>
          )}
          {policy.expiration_date && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text type="small" color={colors.grey60}>
                Expiration date
              </Text>
              <Text type="small">{dateFormatter(policy.expiration_date)}</Text>
            </FlexBox>
          )}
          {policy.deductible_value && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text type="small" color={colors.grey60}>
                Deductible
              </Text>
              <Text type="small">{typedMoneyFormatter(policy.deductible_type, policy.deductible_value)}</Text>
            </FlexBox>
          )}
          {policy.primary_coverage && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text type="small" color={colors.grey60}>
                Coverage
              </Text>
              <Text type="small">{moneyFormatter(policy.primary_coverage, true)}</Text>
            </FlexBox>
          )}
          {policy.premium && (
            <FlexBox justifySpaceBetween gap={spacings.px4}>
              <Text type="small" color={colors.grey60} bold>
                Premium
              </Text>
              <Text type="small" bold>
                {moneyFormatter(policy.premium, true)}
              </Text>
            </FlexBox>
          )}
        </FlexBox>
      </FlexBox>
    </Container>
  );
};

export default PolicySummaryCard;
