import './DashboardLeadTask.scss';

import React from 'react';

import TaskUncompleted from '../../../assets/img/icons/uncompleted-task.svg?react';
import colors from '../../../theme/colors';
import { TaskIcon } from '../../core/icons';
import { Link, Tooltip } from '..';

const DashboardLeadTask = ({
  task_description,
  task_completed = false,
  url
}: {
  url: string;
  task_description?: string;
  task_completed?: boolean;
}): JSX.Element => {
  return (
    <Tooltip message={task_description} className="task-info" right>
      <Link to={url} className="task-info-icon">
        {task_completed ? <TaskIcon color={colors.black} /> : <TaskUncompleted />}
      </Link>
    </Tooltip>
  );
};

export default DashboardLeadTask;
