import Grid from '@mui/material/Grid';
import * as React from 'react';
import * as yup from 'yup';

import FormModal, { FormModalProps } from '../../../components/core/FormModal';
import { DateInputField, InputField, PhoneField, SelectField } from '../../../components/core/forms/fields';
import { ModalSize } from '../../../components/core/Modal';
import { Translations } from '../../../constants';
import useConstant from '../../../hooks/useConstant';
import authInfo, { isISR } from '../../../services/authInfo';
import { emailField, minDOBField, phoneField, REQUIRED_MESSAGE, requiredField } from '../../../utils/yupRules';

type Values = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  kind: string;
  phone: string;
  email: string;
};
interface Props {
  isLoading: FormModalProps<Values>['confirmationInProgress'];
  cancelHandler: FormModalProps<Values>['cancelHandler'];
  confirmHandler: (values: Values) => void;
}

const RelatedPersonFormModal = ({ cancelHandler, confirmHandler, isLoading }: Props) => {
  const todayDate = useConstant(() => new Date());

  const isISRFlow = isISR();

  const validationSchema = yup.object().shape({
    first_name: requiredField,
    last_name: requiredField,
    phone: phoneField({ loose: authInfo.features.loose_phone_validation }),
    email: emailField,
    marital_status: requiredField,
    kind: requiredField,
    date_of_birth: isISRFlow ? minDOBField() : minDOBField().required(REQUIRED_MESSAGE)
  });

  return (
    <FormModal
      size={ModalSize.large}
      confirmationInProgress={isLoading}
      initialValues={{
        first_name: '',
        last_name: '',
        date_of_birth: '',
        marital_status: '',
        kind: '',
        phone: '',
        email: ''
      }}
      title="Add person"
      confirmText="Add"
      confirmHandler={confirmHandler}
      cancelHandler={cancelHandler}
      validationSchema={validationSchema}
      renderForm={() => {
        return (
          <Grid container justifyContent="space-between">
            <Grid item container xs={12} columnSpacing={2} justifyContent="space-between">
              <Grid item xs={6}>
                <InputField fsMask label="First name" id="first_name" name="first_name" required />
              </Grid>
              <Grid item xs={6}>
                <InputField fsMask label="Last name" id="last_name" name="last_name" required />
              </Grid>
              <Grid item xs={6}>
                <DateInputField
                  fsMask
                  label="DOB"
                  id="date_of_birth"
                  name="date_of_birth"
                  required={!isISRFlow}
                  maxDate={todayDate}
                />
              </Grid>

              <Grid item xs={6}>
                <SelectField
                  required
                  label="Marital status"
                  id="marital_status"
                  name="marital_status"
                  options={Translations.maritalStatusOptions}
                />
              </Grid>
              <Grid item xs={6}>
                <SelectField
                  required
                  label="Relationship to customer"
                  id="kind"
                  name="kind"
                  options={Translations.relationshipOptions}
                />
              </Grid>
              <Grid container justifyContent="space-between" xs={12} item columnSpacing={2}>
                <Grid item xs={6}>
                  <PhoneField fsMask label="Phone number" id="phone" name="phone" />
                </Grid>
                <Grid item xs={6}>
                  <InputField fsMask label="Email" id="email" name="email" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default RelatedPersonFormModal;
