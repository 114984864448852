import { useMutation } from '@tanstack/react-query';

import api from '../../services/api';

const deleteProductAnnouncement = (productAnnouncementId: number) =>
  api.delete(`/api/frontend/product_announcements/${productAnnouncementId}`);

const useDeleteProductAnnouncement = () => useMutation({ mutationFn: deleteProductAnnouncement });

export default useDeleteProductAnnouncement;
