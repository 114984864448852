/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

const dlGridTypeSize = (type?: 'default' | 'medium' | 'large' | 'small') => {
  switch (type) {
    case 'medium':
      return '1fr';
    case 'large':
      return '260px';
    case 'small':
      return '32px';
    case 'default':
    default:
      return '130px';
  }
};

const ddLeftMarginTypeSize = (type?: 'default' | 'medium' | 'large' | 'small') => {
  switch (type) {
    case 'medium':
      return '12px';
    case 'large':
      return '40px';
    case 'default':
    default:
      return undefined;
  }
};

const dlCss = (type?: 'default' | 'medium' | 'large' | 'small') => css`
  display: grid;
  grid-template-columns: ${dlGridTypeSize(type)} 1fr;
  margin-bottom: 12px;
  margin-left: 4px;
  gap: 0 4px;

  :first-of-type {
    margin-top: 12px;
  }
  :last-of-type {
    margin-bottom: 0;
  }
`;
const dtCss = css`
  grid-column: 1;
  ::after {
    content: '';
  }
`;

const ddCss = (type?: 'default' | 'medium' | 'large' | 'small') => css`
  grid-column: 2;
  margin-bottom: 0;
  word-wrap: break-word;
  word-break: break-word;
  margin-left: ${ddLeftMarginTypeSize(type)};
`;

const DescriptionList = ({
  term,
  details,
  type = 'default'
}: {
  term: React.ReactNode;
  details: React.ReactNode;
  type?: 'default' | 'medium' | 'large' | 'small';
}): JSX.Element => {
  return (
    <dl css={dlCss(type)}>
      <dt css={dtCss}>{term}</dt>
      <dd css={ddCss(type)}>{details}</dd>
    </dl>
  );
};

export default DescriptionList;
