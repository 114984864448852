import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import Text from '../../../../components/core/Text';
import { spacings } from '../../../../theme/variables';

const PrimaryOpportunityLoanPlaceholder = () => (
  <FlexBox columnDirection data-testid="lead-summary-home-loan-loader">
    <Heading
      type="h4"
      customCss={css`
        width: 60%;
      `}
    >
      <Skeleton />
    </Heading>
    <Text
      mt={spacings.px16}
      customCss={css`
        width: 60%;
      `}
    >
      <Skeleton count={4} />
    </Text>
  </FlexBox>
);

export default PrimaryOpportunityLoanPlaceholder;
