import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as yup from 'yup';

import FormModal from '../../../components/core/FormModal';
import { MultiSelectField, SelectField } from '../../../components/core/forms/fields';
import { ModalSize } from '../../../components/core/Modal';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { IOpportunity, IPersonAsset } from '../../../interfaces';
import {
  areManyAssetsSupported,
  arePolicyTypesSimilar,
  doesPolicyTypeRequireAsset,
  InsuranceCategory,
  isNonLifeAncillaryPolicyType,
  isOnlyOneAssetSupported,
  PolicyType,
  PolicyTypes
} from '../../../interfaces/IPolicyType';
import {
  createOpportunity,
  LEAD_OPPORTUNITIES_QUERY_KEY
} from '../../../queries/leads/opportunities/useLeadOpportunities';
import usePersonAssets from '../../../queries/people/person_assets/usePersonAssets';
import { REQUIRED_MESSAGE, requiredField } from '../../../utils/yupRules';
import { useStepperOpportunities } from '../_hooks/useFinalizeStepper';

const InsuredAssetsField = ({
  selectedPolicyType,
  opportunities,
  supportsManyAssets,
  supportsSingleAsset,
  assetsForNewPolicy,
  isAssetRequired
}: {
  selectedPolicyType: PolicyType | undefined;
  opportunities: IOpportunity[];
  supportsManyAssets: boolean;
  supportsSingleAsset: boolean;
  isAssetRequired: boolean;
  assetsForNewPolicy: IPersonAsset[];
}) => {
  const assetsOptions = assetsForNewPolicy.map(({ gid, description }) => {
    if (
      opportunities.some(
        opportunity =>
          opportunity.assets?.some(asset => asset.gid === gid) &&
          selectedPolicyType &&
          arePolicyTypesSimilar(opportunity.policy_type, selectedPolicyType)
      )
    ) {
      return { key: gid, value: `${description} (already added)`, isDisabled: true };
    }

    return { key: gid, value: description };
  });

  return (
    <>
      {supportsManyAssets && (
        <MultiSelectField
          placeholder=""
          label="Insured assets"
          id="asset_gids"
          name="asset_gids"
          required={isAssetRequired}
          testId="assets-select"
          options={assetsOptions}
        />
      )}
      {supportsSingleAsset && (
        <SelectField
          placeholder=""
          label="Insured asset"
          id="asset_gid"
          name="asset_gid"
          required={isAssetRequired}
          testId="assets-select"
          options={assetsOptions}
        />
      )}
    </>
  );
};

const AddPolicyModal = ({
  toggleAddPolicy,
  hiddenOpportunities
}: {
  toggleAddPolicy: () => void;
  hiddenOpportunities: IOpportunity[];
}) => {
  const { appendOpportunity, displayedOpportunities } = useStepperOpportunities();
  const { personGid, lead, isLifeLead } = useGuidedSellingExperienceContext();
  const [selectedPolicyType, setSelectedPolicyType] = React.useState<PolicyType>();
  const { data: assetsForNewPolicy = [] } = usePersonAssets({
    personGid,
    policyType: selectedPolicyType,
    enabled: !!selectedPolicyType
  });

  const queryClient = useQueryClient();

  const supportsManyAssets = !!selectedPolicyType && areManyAssetsSupported(selectedPolicyType);
  const supportsSingleAsset = !!selectedPolicyType && isOnlyOneAssetSupported(selectedPolicyType);

  const isAssetRequired = !!selectedPolicyType && doesPolicyTypeRequireAsset(selectedPolicyType);
  const policyTypes = isLifeLead ? PolicyTypes.filter(type => type.category === InsuranceCategory.Life) : PolicyTypes;

  return (
    <FormModal
      size={ModalSize.large}
      title="Add policy"
      confirmText="Add policy"
      confirmHandler={async ({ policy_type, asset_gids, asset_gid }) => {
        let assetGids: string[] = [];

        if (supportsManyAssets) {
          assetGids = asset_gids;
        } else if (supportsSingleAsset) {
          assetGids = [asset_gid];
        }
        const hiddenOpportunity = hiddenOpportunities.find(
          opp =>
            opp.policy_type === policy_type &&
            (opp.assets?.[0]?.gid === assetGids?.[0] || !isAssetRequired) &&
            isNonLifeAncillaryPolicyType({ policy_type: opp.policy_type }) &&
            opp.is_opened
        );

        const alreadyAdded = displayedOpportunities.find(op => op.id === hiddenOpportunity?.id);

        if (hiddenOpportunity && !alreadyAdded) {
          appendOpportunity(hiddenOpportunity);
        } else {
          await createOpportunity({
            leadId: lead!.id,
            payload: { policy_type, assets_gids: assetGids, post_sales: true }
          }).then(data => {
            appendOpportunity(data.opportunity);
          });
        }

        return queryClient
          .invalidateQueries({ queryKey: [LEAD_OPPORTUNITIES_QUERY_KEY] })
          .then(() => toggleAddPolicy());
      }}
      cancelHandler={toggleAddPolicy}
      initialValues={{
        policy_type: selectedPolicyType || '',
        asset_gids: [],
        asset_gid: ''
      }}
      validationSchema={yup.object().shape({
        policy_type: requiredField,
        ...(supportsManyAssets ? { asset_gids: yup.array().min(1, REQUIRED_MESSAGE) } : {}),
        ...(supportsSingleAsset ? { asset_gid: requiredField } : {})
      })}
      renderForm={({ values }) => {
        const selectedPolicyType = values.policy_type as PolicyType | undefined;

        return (
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item xs={5.9}>
              <SelectField
                required
                label="Policy type"
                id="policy_type"
                name="policy_type"
                testId="policy-type-select"
                options={policyTypes.map(({ key, name }) => ({ key, value: name }))}
                onChange={e => {
                  setSelectedPolicyType(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={5.9}>
              <InsuredAssetsField
                {...{
                  selectedPolicyType,
                  opportunities: displayedOpportunities,
                  supportsManyAssets,
                  supportsSingleAsset,
                  assetsForNewPolicy,
                  isAssetRequired
                }}
              />
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

export default AddPolicyModal;
