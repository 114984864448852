import { SerializedStyles } from '@emotion/react';

import Copy from '../../../../../components/core/Copy';
import Text, { TextProps } from '../../../../../components/core/Text';

interface CopyableAnswerProps {
  value: string;
  withIcon?: boolean;
  fsMask?: boolean;
  testId?: string;
  textType?: TextProps['type'];
  customCss?: SerializedStyles;
}

const CopyableAnswer = ({ value, withIcon = true, fsMask, testId, textType, customCss }: CopyableAnswerProps) => {
  return (
    <Copy type={textType} value={value} withIcon={withIcon} testId={testId}>
      <Text type={textType} className={fsMask ? 'fs-mask' : ''} customCss={customCss} singleLine>
        {value}
      </Text>
    </Copy>
  );
};

export default CopyableAnswer;
