import React from 'react';

import useAppVersion, { useSyncVersions } from '../../contexts/VersionContext';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import Button, { ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import { WarningIcon } from '../core/icons';
import Text from '../core/Text';

const AppOutdatedBanner = () => {
  const show = useAppVersion(state => state.current !== state.initial);
  useSyncVersions();

  if (!show) {
    return null;
  }

  return (
    <FlexBox
      data-test-id="outdated-message-box"
      backgroundColor={colors.statusOrange}
      ph={spacings.px16}
      pv={spacings.px12}
      justifySpaceBetween
      alignItemsCenter
    >
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <WarningIcon color={colors.white} />
        <Text type="large" color={colors.white}>
          <b>You have to reload the page to use new AMP version.</b> Make sure your data is copied out because any
          unsaved changes will be lost.
        </Text>
      </FlexBox>
      <Button variant={ButtonVariant.Secondary} onClick={() => window.location.reload()}>
        Reload page
      </Button>
    </FlexBox>
  );
};

export default AppOutdatedBanner;
