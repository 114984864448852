/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FlexBox from '../../../../components/core/FlexBox';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

const FooterSkeleton = () => {
  return (
    <FlexBox
      pv={spacings.px24}
      ph={spacings.px24}
      gap={spacings.px12}
      justifyRight
      customCss={css`
        border-top: 1px solid ${colors.grey10};
      `}
    >
      <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
        <Skeleton height={46} width={160} borderRadius={24} />
      </SkeletonTheme>
      <SkeletonTheme baseColor={colors.grey30} highlightColor={colors.grey10}>
        <Skeleton height={46} width={160} borderRadius={24} />
      </SkeletonTheme>
    </FlexBox>
  );
};

export default FooterSkeleton;
