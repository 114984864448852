import '../../common/TimeTerm/TimeTerm.scss';

import { Field } from 'formik';
import React from 'react';
import { Col, Container, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { Translations } from '../../../constants';
import { IMaticPolicy, IPerson } from '../../../interfaces';
import useNonRenewPolicy from '../../../queries/people/person_policies/useNonRenewPolicy';
import { requiredField } from '../../../utils/yupRules';
import { ErrorCol, FormModal } from '../../common';
import { Select } from '../../common/FormikComponents';
import PersonNavLink from '../../PersonNavLink';
import PolicyShortInfo from '../PolicyShortInfo';

interface NonRenewModalProps {
  person: IPerson;
  policy: IMaticPolicy;
  closeModal: () => void;
  onPolicyNonRenewed: () => Promise<unknown>;
}

const NonRenewModal: React.FC<NonRenewModalProps> = ({ person, policy, closeModal, onPolicyNonRenewed }) => {
  const { mutateAsync: nonRenewPolicy } = useNonRenewPolicy();

  const underwritingReason = Translations.policyCancellationReasonOptions.find(
    (key: { key: string }) => key.key === 'underwriting'
  )?.key;

  return (
    <FormModal
      open
      returnFocusAfterClose={false}
      initialValues={{
        carrier_id: policy.carrier.id,
        transaction_type: policy.transaction_type,
        policy_number: policy.policy_number,
        effective_date: policy.effective_date,
        expiration_date: policy.expiration_date,
        cancellation_reason: policy.cancellation_reason || underwritingReason || ''
      }}
      validationSchema={yup.object().shape({
        cancellation_reason: requiredField
      })}
      confirmHandler={values =>
        nonRenewPolicy({
          person_gid: person.gid,
          policy_id: policy.id,
          cancellation_reason: values.cancellation_reason
        })
          .then(() => onPolicyNonRenewed())
          .then(() => closeModal())
      }
      cancelHandler={closeModal}
      title={<PersonNavLink person={person} linkPrefix="Non-renew Policy." />}
      confirmText="Confirm"
      renderForm={({ errors, touched }) => (
        <Container>
          <Row className="mb-4">
            <Col>
              <PolicyShortInfo policy={policy} />
            </Col>
          </Row>
          <Row>
            <ErrorCol
              sm={4}
              name="cancellation_reason"
              error={errors['cancellation_reason']}
              when={errors['cancellation_reason'] && touched['cancellation_reason']}
            >
              <Label htmlFor="cancel-reason-select">Cancellation reason *</Label>
              <Field
                inputId="cancel-reason-select"
                name="cancellation_reason"
                component={Select}
                options={Translations.policyCancellationReasonOptions}
                title="Cancellation reason"
                placeholder="Please select an option"
                labelName="value"
                valueName="key"
              />
            </ErrorCol>
          </Row>
        </Container>
      )}
    />
  );
};

export default NonRenewModal;
