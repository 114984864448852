import { useMutation } from '@tanstack/react-query';

import { IMaticPolicy } from '../../interfaces/IPolicy';
import api from '../../services/api';

export const syncPolicy = async (policy: IMaticPolicy): Promise<null> => {
  return await api.post(`/api/frontend/policies/${policy.id}/sync`);
};

const useSyncPolicy = (onSuccess?: () => void) => useMutation({ mutationFn: syncPolicy, onSuccess });

export default useSyncPolicy;
