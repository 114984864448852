import { skipToken, useQuery } from '@tanstack/react-query';

import { QuotingAccess } from '../../interfaces/IQuotingAccess';
import api from '../../services/api';

export const getLeadQuotingAccess = (leadId: number | undefined): Promise<{ quoting_access: QuotingAccess }> =>
  api.get(`/api/frontend/leads/${leadId}/quoting_access`);

export const LEAD_QUOTING_ACCESS_QUERY_KEY = 'lead_quoting_access';
const useLeadQuotingAccess = (leadId: number | undefined) =>
  useQuery({
    queryKey: [LEAD_QUOTING_ACCESS_QUERY_KEY, leadId],
    queryFn: leadId ? () => getLeadQuotingAccess(leadId) : skipToken,
    select: data => data?.quoting_access
  });

export default useLeadQuotingAccess;
