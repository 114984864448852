import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import Text from '../../../components/core/Text';

const AssetDetailsPlaceholder = (): JSX.Element => (
  <FlexBox columnDirection>
    <Heading
      customCss={css`
        width: 100px;
      `}
    >
      <Skeleton />
    </Heading>
    {[1, 2, 3, 4].map(value => (
      <FlexBox key={value}>
        <Text
          customCss={css`
            width: 300px;
            padding-top: 10px;
          `}
        >
          <Skeleton />
        </Text>
      </FlexBox>
    ))}
  </FlexBox>
);

export default AssetDetailsPlaceholder;
