/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Container from '../../../../components/core/Container';
import Copy from '../../../../components/core/Copy';
import DescriptionList from '../../../../components/core/DescriptionList';
import FlexBox from '../../../../components/core/FlexBox';
import { WarningIcon } from '../../../../components/core/icons';
import Modal, { ModalSize } from '../../../../components/core/Modal';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { CarrierKey, ICarrier } from '../../../../interfaces/ICarrier';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

interface ExternalLinkModalProps {
  bindingUrl: string | null;
  externalId: string | null;
  notice: string | null;
  carrier: ICarrier | undefined;
  cancelBtnHandler: () => void;
}

const ExternalLinkModal = ({ bindingUrl, externalId, notice, carrier, cancelBtnHandler }: ExternalLinkModalProps) => {
  return (
    <Modal
      title={bindingUrl ? `Go to ${carrier?.name} website` : `Check ${carrier?.name} instructions`}
      size={ModalSize.large}
      returnFocusAfterClose={false}
      cancelHandler={cancelBtnHandler}
      confirmHandler={
        bindingUrl
          ? () => {
              window.open(bindingUrl, '_blank');
              cancelBtnHandler();
            }
          : undefined
      }
      confirmText="Go to website"
    >
      {notice && (
        <DescriptionList
          term={
            <FlexBox alignItemsCenter gap={spacings.px4}>
              <Text bold>Notice</Text>
              <WarningIcon color={colors.statusOrange} />
            </FlexBox>
          }
          details={<Text>{notice}</Text>}
        />
      )}
      {externalId && (
        <DescriptionList
          term={<Text bold>External ID</Text>}
          details={
            <Copy value={externalId}>
              <Text className="fs-mask">{externalId}</Text>
            </Copy>
          }
        />
      )}
      {bindingUrl && (
        <DescriptionList
          term={<Text bold>External URL</Text>}
          details={
            <Copy value={bindingUrl}>
              <Text
                customCss={css`
                  max-width: 600px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                `}
              >
                {bindingUrl}
              </Text>
            </Copy>
          }
        />
      )}
      {carrier?.key === CarrierKey.Universal && (
        <FlexBox ml={spacings.px4} columnDirection gap={spacings.px16}>
          <Container>
            <Paragraph bold>Roof Year</Paragraph>
            <Text>If roof year was manually entered or changed, proof of roof update is required</Text>
          </Container>
          <Container>
            <Paragraph bold>Prior Insurance</Paragraph>
            <Text>If YES is selected for Prior Insurance, proof of insurance is required</Text>
          </Container>
          <Container>
            <Paragraph bold>Loss History</Paragraph>
            <Text>
              If a loss is reported by carrier or customer, you must manually enter in the loss using `&quot;`add prior
              loss`&quot;` button
            </Text>
          </Container>
        </FlexBox>
      )}
    </Modal>
  );
};

export default ExternalLinkModal;
