import { NumericFormat, NumericFormatProps } from 'react-number-format';

import Input, { InputProps } from '../Base';

export type NumericInputProps = NumericFormatProps<InputProps>;

const NumericInput = ({ fsMask, ...rest }: NumericInputProps): JSX.Element => (
  <NumericFormat
    customInput={Input}
    className={fsMask ? 'fs-mask' : ''}
    allowLeadingZeros={false}
    allowNegative={false}
    thousandSeparator=","
    decimalSeparator="."
    {...rest}
  />
);

export default NumericInput;
