import Grid from '@mui/material/Grid';
import { FormikValues } from 'formik';
import * as React from 'react';

import { CheckboxField, MultiSelectField, NumericField, SelectField } from '../../../components/core/forms/fields';
import { DollarIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import { IRelatedPerson } from '../../../interfaces';
import { ICarrier } from '../../../interfaces/ICarrier';
import { coverageByKey, PolicyCoverageKey } from '../../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../../interfaces/IPolicyType';
import usePersonPriorPolicies from '../../../queries/people/person_policies/usePersonPriorPolicies';
import colors from '../../../theme/colors';
import { sizes } from '../../../theme/variables';
import { capitalize, moneyFormatter } from '../../../utils/formatter';

export enum HomeQuotingOptionsFields {
  Deductible = 'deductible',
  DwellingCoverage = 'dwelling_coverage',
  PersonalProperty = 'personal_property',
  Carriers = 'carriers',
  AllCarriers = 'all_carriers',
  Coinsured = 'coinsured'
}

const HomeQuotingOptions = ({
  personGid,
  homeGid,
  carriers,
  relatedPeople,
  formikValues
}: {
  personGid: string;
  homeGid: string;
  carriers: ICarrier[];
  formikValues: FormikValues;
  relatedPeople: IRelatedPerson[] | undefined;
}) => {
  const { data: policies } = usePersonPriorPolicies({
    personGid,
    filters: { policy_types: [PolicyType.Home], similar_policy_types: true, asset_gid: homeGid }
  });
  const recentPolicy = policies?.[0];

  const carriersOptions = React.useMemo(
    () => carriers.map(({ key, name }) => ({ key, value: name })) || [],
    [carriers]
  );

  const dwellingCoverage = coverageByKey(recentPolicy?.coverages, PolicyCoverageKey.Dwelling);

  return (
    <Grid container maxWidth={sizes.mediumFormInputWidth * 3}>
      <Grid container columnSpacing={2}>
        <Grid item xs={4}>
          <NumericField
            label="Deductible"
            id={`${homeGid}_${HomeQuotingOptionsFields.Deductible}`}
            name={`${homeGid}_${HomeQuotingOptionsFields.Deductible}`}
            valueIsNumber
            prefixIcon={<DollarIcon />}
          />
          {dwellingCoverage?.deductible_value && (
            <Text color={colors.grey60}>Current: {moneyFormatter(dwellingCoverage.deductible_value, true)}</Text>
          )}
        </Grid>
        <Grid item xs={4}>
          <NumericField
            label="Dwelling coverage"
            id={`${homeGid}_${HomeQuotingOptionsFields.DwellingCoverage}`}
            name={`${homeGid}_${HomeQuotingOptionsFields.DwellingCoverage}`}
            valueIsNumber
            prefixIcon={<DollarIcon />}
          />
          {dwellingCoverage?.limit_value && (
            <Text color={colors.grey60}>Current: {moneyFormatter(dwellingCoverage.limit_value, true)}</Text>
          )}
        </Grid>
        <Grid item xs={4}>
          <NumericField
            label="Personal property"
            id={`${homeGid}_${HomeQuotingOptionsFields.PersonalProperty}`}
            name={`${homeGid}_${HomeQuotingOptionsFields.PersonalProperty}`}
            valueIsNumber
            prefixIcon={<DollarIcon />}
          />
        </Grid>
        <Grid item xs={4}>
          <SelectField
            label="Coinsured"
            id={`${homeGid}_${HomeQuotingOptionsFields.Coinsured}`}
            name={`${homeGid}_${HomeQuotingOptionsFields.Coinsured}`}
            options={(relatedPeople || []).map(({ gid, first_name, last_name, kind }) => ({
              key: gid,
              value: `${first_name} ${last_name}`,
              description: capitalize(kind)
            }))}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={2} alignItems="end">
        <Grid item xs={8}>
          <MultiSelectField
            disabled={!!formikValues[`${homeGid}_${HomeQuotingOptionsFields.AllCarriers}`]}
            label="Carriers"
            id={`${homeGid}_${HomeQuotingOptionsFields.Carriers}`}
            name={`${homeGid}_${HomeQuotingOptionsFields.Carriers}`}
            options={carriersOptions}
            value={
              formikValues[`${homeGid}_${HomeQuotingOptionsFields.AllCarriers}`]
                ? ''
                : formikValues[`${homeGid}_${HomeQuotingOptionsFields.Carriers}`]
            }
          />
        </Grid>
        <Grid item xs={4}>
          <CheckboxField
            id={`${homeGid}_${HomeQuotingOptionsFields.AllCarriers}`}
            name={`${homeGid}_${HomeQuotingOptionsFields.AllCarriers}`}
            label="All carriers"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeQuotingOptions;
