import React from 'react';
import { useNavigate } from 'react-router-dom';

import useCallLog from '../../queries/call_logs/useCallLog';
import useCreateJohnDoeLead from '../../queries/call_logs/useCreateJohnDoeLead';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import alert from '../core/Alert';
import Button, { ButtonVariant } from '../core/buttons/Button';
import FlexBox from '../core/FlexBox';
import Text from '../core/Text';
import LeadClassificationCheckModal from './LeadClassificationCheckModal';

const salCreatedToast = () =>
  alert({
    title: 'SAL Traced Successfully',
    message: 'Thank you for logging a John Doe lead. Your precision is key to giving feedback on transfers!'
  }).success();

const LeadClassificationCheckBanner = ({ currentCallId }: { currentCallId: number }) => {
  const { data: callLog } = useCallLog(currentCallId);
  const { mutateAsync: createJohnDoeLead, isPending: isCreatingJohnDoeLead } = useCreateJohnDoeLead();
  const navigate = useNavigate();

  const [showLeadClassificationCheckModal, setShowLeadClassificationCheckModal] = React.useState(true);

  if (!callLog || !callLog.is_lead_classification_required) {
    return null;
  }

  return showLeadClassificationCheckModal ? (
    <LeadClassificationCheckModal
      createJohnDoeLead={async () => {
        await createJohnDoeLead(callLog.id);
        setShowLeadClassificationCheckModal(false);

        salCreatedToast();

        navigate('/leads', { replace: true });
      }}
      cancel={() => setShowLeadClassificationCheckModal(false)}
    />
  ) : (
    <FlexBox
      data-test-id="call-without-person-banner"
      backgroundColor={colors.violet}
      ph={spacings.px16}
      pv={spacings.px12}
      justifySpaceBetween
      alignItemsCenter
    >
      <Text color={colors.white} type="large">
        🚦 <b>Heads Up Before Your Next Call:</b> Please log your last call to unlock Twilio. You may not take another
        call without doing so.
      </Text>
      <Button
        variant={ButtonVariant.Secondary}
        loading={isCreatingJohnDoeLead}
        onClick={async () => {
          await createJohnDoeLead(callLog.id);

          salCreatedToast();

          navigate('/leads', { replace: true });
        }}
      >
        Log John Doe
      </Button>
    </FlexBox>
  );
};

export default LeadClassificationCheckBanner;
