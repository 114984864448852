import ColumnGenerator from '../ColumnGenerator';

const LeadSourceProduct = ColumnGenerator.simple({
  width: ColumnGenerator.WIDTH_XXL,
  accessor: 'source_product',
  Header: 'Source Product',
  Cell: ({ original: { source_product } }: any) => source_product
});

export default LeadSourceProduct;
