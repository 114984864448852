import { useMutation, UseMutationResult } from '@tanstack/react-query';

import { NoteKind } from '../../interfaces/INote';
import api from '../../services/api';

interface IPersonNote {
  person_gid: string;
  kind: NoteKind;
  description: string;
  lead_gid?: string;
}

const createPersonNote = async (data: IPersonNote) => {
  return await api.post(`/api/frontend/people/${data.person_gid}/notes`, {
    body: data
  });
};

const useCreatePersonNote = (onSuccess?: () => void): UseMutationResult<IPersonNote, any, IPersonNote> =>
  useMutation({ mutationFn: createPersonNote, onSuccess });

export default useCreatePersonNote;
