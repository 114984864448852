import { daysToNow } from '../../../../utils/formatter';
import ColumnGenerator from '../ColumnGenerator';

const LeadAge = ColumnGenerator.simple({
  Header: 'Age',
  accessor: 'created_at',
  Cell: ({ original: { created_at } }: any) => `${daysToNow(created_at)} day(s)`
});

export default LeadAge;
