import React from 'react';

import { useDetachVehicle } from '../../queries/people/personVehicles';
import Modal, { ModalSize } from '../core/Modal';
import Paragraph from '../core/Paragraph';

interface IVehicleDelete {
  cancelBtnHandler: () => void;
  confirmBtnHandler: (vehicleGid: string) => void;
  personGid: string;
  vehicleGid: string;
  leadId: number | undefined;
}

const VehicleDeleteModal = ({
  cancelBtnHandler,
  confirmBtnHandler,
  personGid,
  vehicleGid,
  leadId
}: IVehicleDelete): JSX.Element => {
  const { mutateAsync: detachVehicle, isPending: isDetachingVehicle } = useDetachVehicle();

  return (
    <Modal
      title="Do you want to delete the vehicle?"
      dangerModal
      size={ModalSize.large}
      cancelHandler={cancelBtnHandler}
      confirmHandler={async () => {
        await detachVehicle({ personGid, vehicleGid, leadId });
        await confirmBtnHandler(vehicleGid);
        cancelBtnHandler();
      }}
      confirmText="Yes, delete"
      confirmationInProgress={isDetachingVehicle}
    >
      <Paragraph>
        You&apos;re going to delete vehicle from the list. You will not see this vehicle in the list of assets related
        to this customer.
      </Paragraph>
    </Modal>
  );
};

export default VehicleDeleteModal;
