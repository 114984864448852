import { useMutation, useQueryClient } from '@tanstack/react-query';

import { IDataCollection } from '../../../interfaces';
import api from '../../../services/api';
import { updateDataCollectionQueryState } from './useDataCollection';

// Mutations ============================================================================

const attachHomeToDataCollection = ({
  assetGid,
  leadId
}: {
  assetGid: string;
  leadId: string | number;
}): Promise<{ data_collection: IDataCollection }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/home`, { body: { asset_gid: assetGid } });

export const useAttachHomeToDataCollection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: attachHomeToDataCollection,
    onSuccess: (result, variables) => updateDataCollectionQueryState(queryClient, result, variables)
  });
};

const detachHomeFromDataCollection = ({
  assetGid,
  leadId
}: {
  assetGid: string;
  leadId: string | number;
}): Promise<{ data_collection: IDataCollection }> =>
  api.delete(`/api/frontend/leads/${leadId}/data_collection/homes/${assetGid}`);

export const useDetachHomeFromDataCollection = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: detachHomeFromDataCollection,
    onSuccess: (result, variables) => updateDataCollectionQueryState(queryClient, result, variables)
  });
};
