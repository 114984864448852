import { useMutation, useQuery, UseQueryOptions, useSuspenseQuery } from '@tanstack/react-query';

import { Branded } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';
import { ScoutingStrategyStep } from '../scouting_strategy_steps/useScoutingStrategySteps';

export const SSC_QUERY_KEY = 'scouting_strategy_configs';

export interface ScoutingStrategy {
  id: number;
  name: string;
  key: string;
  scouting_strategy_steps: ScoutingStrategyStep[]; // // used in Config Constructor
  steps: ScoutingStrategyStep[]; // used in ScoutingReport
  priority: number;
  condition_groups?: ConditionGroup[];
}

export const useScoutingStrategyConfig = ({
  callLogId,
  personGid,
  leadGid,
  candidateGid,
  scoutingStrategyConfigKey,
  options
}: {
  callLogId?: string | number;
  personGid?: string;
  leadGid?: string;
  candidateGid?: string;
  scoutingStrategyConfigKey?: string;
  options?: Omit<UseQueryOptions<{ scouting_strategy_config: ScoutingStrategy }, any, any>, 'queryKey'>;
}) =>
  useQuery({
    queryKey: ['scouting_strategy_config', callLogId, personGid, leadGid, candidateGid, scoutingStrategyConfigKey],
    queryFn: (): Promise<{ scouting_strategy_config: ScoutingStrategy }> =>
      api.get(
        `/api/frontend/scouting_strategy_configs/pick_config?${toQueryParams({
          call_log_id: callLogId,
          person_gid: personGid,
          lead_gid: leadGid,
          candidate_gid: candidateGid,
          scouting_strategy_config_key: scoutingStrategyConfigKey
        })}`
      ),
    ...options,
    enabled: !!(callLogId || personGid || leadGid || candidateGid),
    select: data => data.scouting_strategy_config
  });

const getConfig = ({
  callLogId,
  personGid,
  leadGid,
  candidateGid,
  scoutingStrategyConfigKey
}: {
  callLogId?: string | number;
  personGid?: string;
  leadGid?: string;
  candidateGid?: string;
  scoutingStrategyConfigKey?: string;
}) => {
  return api.get(
    `/api/frontend/scouting_strategy_configs/pick_config?${toQueryParams({
      call_log_id: callLogId,
      person_gid: personGid,
      lead_gid: leadGid,
      candidate_gid: candidateGid,
      scouting_strategy_config_key: scoutingStrategyConfigKey
    })}`
  );
};

export const useSuspenseScoutingStrategyConfig = ({
  callLogId,
  personGid,
  leadGid,
  candidateGid,
  scoutingStrategyConfigKey
}: {
  callLogId?: string | number;
  personGid?: string;
  leadGid?: string;
  candidateGid?: string;
  scoutingStrategyConfigKey: string;
}) =>
  useSuspenseQuery({
    queryKey: ['scouting_strategy_config', callLogId, personGid, leadGid, candidateGid, scoutingStrategyConfigKey],
    queryFn: (): Promise<{ scouting_strategy_config: ScoutingStrategy }> =>
      getConfig({ callLogId, personGid, leadGid, candidateGid, scoutingStrategyConfigKey }),
    select: data => data.scouting_strategy_config
  });

export const useScoutingStrategyConfigsList = () =>
  useQuery({
    queryKey: [SSC_QUERY_KEY],
    queryFn: (): Promise<{ scouting_strategy_configs: ScoutingStrategy[] }> =>
      api.get('/api/frontend/scouting_strategy_configs'),
    select: data => data.scouting_strategy_configs.sort((a, b) => a.priority - b.priority)
  });

type ConfigVariableKey = Branded<string, 'ConfigVariableKey'>;

interface IScoutingConfigVariable {
  key: ConfigVariableKey;
  label: string;
  operations: Operations[];
  values: null | string[];
}
export const useScoutingStrategyVariables = () =>
  useQuery({
    queryKey: ['scouting_strategy_variables'],
    queryFn: (): Promise<IScoutingConfigVariable[]> => api.get('/api/frontend/scouting_strategy_configs/variables'),
    staleTime: 10 * 60 * 1000,
    select: data => new Map(data.map((variable: IScoutingConfigVariable) => [variable.key, variable]))
  });

export enum Operations {
  EQUAL = 'equal',
  NOT_EQUAL = 'not_equal',
  FILLED = 'filled',
  NOT_FILLED = 'not_filled',
  ONE_OF = 'one_of',
  NOT_ONE_OF = 'not_one_of',
  LESS_THAN = 'less_than',
  GREATER_THAN = 'greater_than',
  BEFORE = 'before',
  AFTER = 'after',
  YEARS_OLDER_THAN = 'years_older_than',
  YEARS_NEWER_THAN = 'years_newer_than',
  MATCH = 'match',
  NOT_MATCH = 'not_match'
}

export interface Condition {
  source: ConfigVariableKey;
  value: string | number | Array<string> | Array<number>;
  operation: Operations | '';
}
export interface ConditionGroup {
  conditions: Condition[];
}
interface ICreateScoutingStrategyConfig {
  key: string;
  name: string;
  steps: { step_id: number; position: number; condition_groups: ConditionGroup[] }[];
  priority?: number;
  condition_groups?: ConditionGroup[];
}

export const useCreateScoutingStrategyConfig = () =>
  useMutation({
    mutationFn: (params: ICreateScoutingStrategyConfig): Promise<{ scouting_strategy_config: ScoutingStrategy }> =>
      api.post('/api/frontend/scouting_strategy_configs', { body: params, allowEmpty: true })
  });

interface IUpdateScoutingStrategyConfig {
  id?: number;
  key: string;
  name?: string;
  steps?: { step_id: number; position: number; condition_groups: ConditionGroup[] }[];
  priority?: number;
  condition_groups?: ConditionGroup[];
}

export const useUpdateScoutingStrategyConfig = () =>
  useMutation({
    mutationFn: (params: IUpdateScoutingStrategyConfig) =>
      api.put(`/api/frontend/scouting_strategy_configs/${params.key}`, { body: params, allowEmpty: true })
  });

export const useDeleteScoutingStrategyConfig = (options?: {
  onSuccess?: (_data: any, variables: number | string) => void;
}) =>
  useMutation({
    mutationFn: (key: string) => api.delete(`/api/frontend/scouting_strategy_configs/${key}`),
    ...options
  });
