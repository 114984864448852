import './MoneyInput.scss';

import classNames from 'classnames';
// @ts-expect-error TODO
import Cleave from 'cleave.js/react';
import PropTypes from 'prop-types';
import React from 'react';

const MAX_PERCENT_VALUE = 100.0;

const INTEGER_DECIMAL_SCALE = 0;
const DEFAULT_DECIMAL_SCALE = 2;

class MoneyInput extends React.Component<any, any> {
  state = {
    cleaveInstance: null,
    isPercentageMaxValueFlag: false
  } as any;

  UNSAFE_componentWillReceiveProps() {
    if (this.state.isPercentageMaxValueFlag) {
      this.state.cleaveInstance.setRawValue(MAX_PERCENT_VALUE);
    }
  }

  onCleaveInit = (cleave: any) => this.setState({ cleaveInstance: cleave });

  onInput = ({ target: { name, rawValue: value } }: any) => {
    const { onChange, percentageMode } = this.props as any;

    this.setState(({ isPercentageMaxValueFlag }: any) => {
      if (!isPercentageMaxValueFlag && percentageMode && parseFloat(value) >= MAX_PERCENT_VALUE) {
        onChange({ target: { name, value: MAX_PERCENT_VALUE } });

        return { isPercentageMaxValueFlag: true };
      }

      onChange({ target: { name, value } });

      return { isPercentageMaxValueFlag: false };
    });
  };

  decimalScale = () => ((this.props as any).intOnly ? INTEGER_DECIMAL_SCALE : DEFAULT_DECIMAL_SCALE);

  render() {
    const { name, value, positiveOnly, disabled, withAddOn, className, maxLength, onBlur, id } = this.props as any;

    return (
      <Cleave
        id={id}
        name={name}
        value={value}
        disabled={disabled}
        className={classNames('money-input form-control', className, { 'money-input--with-add-on': withAddOn })}
        onInit={this.onCleaveInit}
        maxLength={maxLength}
        options={{
          numeral: true,
          numeralPositiveOnly: positiveOnly,
          numeralDecimalScale: this.decimalScale()
        }}
        onChange={this.onInput}
        onBlur={onBlur}
      />
    );
  }
}

// @ts-expect-error TODO
MoneyInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  positiveOnly: PropTypes.bool,
  intOnly: PropTypes.bool,
  withAddOn: PropTypes.bool,
  percentageMode: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  id: PropTypes.string
};

// @ts-expect-error TODO
MoneyInput.defaultProps = {
  intOnly: false,
  positiveOnly: true,
  percentageMode: false,
  withAddOn: false,
  disabled: false,
  value: '',
  className: '',
  maxLength: 16,
  id: null
};

export default MoneyInput;
