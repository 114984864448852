export enum TaskChannel {
  Email = 'email',
  Sms = 'sms'
}

export interface IQuickReply {
  id: number;
  title: string;
  subject: string;
  user_group: string;
  task_channel: TaskChannel;
  category: string;
  prefilled_content: string;
}
