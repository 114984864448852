import { useMutation } from '@tanstack/react-query';

import { IProductAnnouncement } from '../../interfaces';
import api from '../../services/api';

type ProductAnnouncementPayload = Omit<
  IProductAnnouncement,
  'id' | 'created_at' | 'updated_at' | 'author' | 'targeted_roles'
> & { targeted_roles_attributes: { role_id: number }[] };

const createProductAnnouncement = (params: ProductAnnouncementPayload) =>
  api.post('/api/frontend/product_announcements', { body: params });

const useCreateProductAnnouncement = () => useMutation({ mutationFn: createProductAnnouncement });

export default useCreateProductAnnouncement;
