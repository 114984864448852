/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import ButtonWithoutBorder from '../../../components/core/buttons/ButtonWithoutBorder';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import Heading from '../../../components/core/Heading';
import HotKey from '../../../components/core/HotKey';
import { CloseIcon } from '../../../components/core/icons';
import Text from '../../../components/core/Text';
import useEventListener from '../../../hooks/useEventListener';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';

const LoadingPage = ({ onClose }: { onClose: () => void }) => {
  useEventListener('keydown', event => {
    if (event.key === 'Escape') {
      onClose();
    }
  });

  return (
    <FlexBox fitParentHeight fitParentWidth columnDirection>
      <FlexBox p={spacings.px24} justifySpaceBetween borderBottom>
        <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
          <Container
            fitParentWidth
            css={css`
              width: ${sizes.mediumFormInputWidth * 2}px;
            `}
          >
            <Heading
              type="h3"
              customCss={css`
                width: 100%;
              `}
            >
              <Skeleton />
            </Heading>
          </Container>
        </SkeletonTheme>
        <FlexBox alignItemsCenter>
          <HotKey
            message="Press ESC"
            customCss={css`
              margin-right: ${spacings.px8}px;
            `}
          />
          <ButtonWithoutBorder onClick={onClose} aria-label="Close">
            <CloseIcon color={colors.black} width={40} height={40} />
          </ButtonWithoutBorder>
        </FlexBox>
      </FlexBox>
      <Container p={spacings.px24}>
        <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
          <FlexBox columnDirection maxWidth={sizes.mediumFormInputWidth * 2} gap={spacings.px32}>
            {[1, 2].map(number => (
              <div key={`assets_group_${number}`}>
                <Heading
                  type="h4"
                  customCss={css`
                    width: 120px;
                  `}
                >
                  <Skeleton />
                </Heading>
                <FlexBox columnDirection mt={spacings.px12} gap={spacings.px24}>
                  {[1, 2].map(number => (
                    <FlexBox key={`asset_to_select_${number}`}>
                      <Text
                        customCss={css`
                          width: ${spacings.px24}px;
                        `}
                      >
                        <Skeleton />
                      </Text>
                      <Text
                        ml={spacings.px8}
                        customCss={css`
                          width: 100%;
                        `}
                      >
                        <Skeleton />
                      </Text>
                    </FlexBox>
                  ))}
                </FlexBox>
              </div>
            ))}
          </FlexBox>
        </SkeletonTheme>
      </Container>
    </FlexBox>
  );
};

export default LoadingPage;
