import { Box, LinearProgress } from '@mui/material';
import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import Paragraph, { ParagraphType } from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import { IDocument, IMaticPolicy, IOpportunity } from '../../../../interfaces';
import { FileToUpload } from '../../../../interfaces/IDocument';
import { UpdateDocumentRequest } from '../../../../queries/people/person_documents/usePersonDocuments';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import Document from './Document';
import { getGroupDescription, groupAndSortDocumentsByOpportunities } from './helpers';

interface IDocumentsList {
  filesToUpload: FileToUpload[] | undefined;
  documents: IDocument[] | undefined;
  onDelete: (document: IDocument) => Promise<unknown>;
  onUpdate: (documentId: number, request: UpdateDocumentRequest) => Promise<unknown>;
  owners: { key: string; value: string; description: string | string[] | undefined }[];
  opportunities: IOpportunity[] | undefined;
  policies: IMaticPolicy[] | undefined;
  disabled?: boolean;
}

const DocumentsGroup = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <FlexBox columnDirection mv={spacings.px16} pb={spacings.px8} fitParentWidth borderBottom>
      <Paragraph bold type={ParagraphType.Large}>
        {title}
      </Paragraph>
      {children}
    </FlexBox>
  );
};

const DocumentsList = ({
  filesToUpload = [],
  documents = [],
  onDelete,
  onUpdate,
  owners,
  opportunities,
  policies,
  disabled = false
}: IDocumentsList) => {
  const { otherDocuments, documentsGroupedByOpportunity } = groupAndSortDocumentsByOpportunities({
    documents,
    opportunities,
    policies
  });

  return (
    <>
      {filesToUpload.map(file => {
        return (
          <FlexBox key={file.uid} columnDirection pv={spacings.px4}>
            <FlexBox gap={spacings.px8} pv={spacings.px12} alignItemsCenter>
              <Tag label={file.document_type_title?.toString() || 'Unknown'} backgroundColor={colors.violet} />
              <Text className="fs-mask" singleLine>
                {file.name}
              </Text>
            </FlexBox>
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          </FlexBox>
        );
      })}
      {Object.keys(documentsGroupedByOpportunity).map(key => (
        <DocumentsGroup title={key} key={key}>
          <Text className="fs-mask" type="small" color={colors.grey60}>
            {getGroupDescription({ documents: documentsGroupedByOpportunity[key]!, opportunities })}
          </Text>
          {documentsGroupedByOpportunity[key]!.map(document => (
            <Document
              document={document}
              key={document.id}
              onDelete={onDelete}
              onUpdate={onUpdate}
              owners={owners}
              opportunities={opportunities}
              policies={policies}
              disabled={disabled}
            />
          ))}
        </DocumentsGroup>
      ))}

      {!!otherDocuments?.length && (
        <DocumentsGroup title="Other">
          {otherDocuments.map(document => (
            <Document
              document={document}
              key={document.id}
              onDelete={onDelete}
              onUpdate={onUpdate}
              owners={owners}
              opportunities={opportunities}
              policies={policies}
              disabled={disabled}
            />
          ))}
        </DocumentsGroup>
      )}
    </>
  );
};

export default DocumentsList;
