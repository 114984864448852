import * as React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import useLastLeadId from '../../queries/people/useLastLead';

const PersonRedirect = (): JSX.Element => {
  const { gid } = useParams();
  const { data: lead, isFetching } = useLastLeadId(gid as string);

  return isFetching || !lead ? (null as unknown as JSX.Element) : <Navigate to={`/leads/${lead.id}`} replace />;
};

export default PersonRedirect;
