import {
  skipToken,
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryOptions
} from '@tanstack/react-query';

import { IPayloadAddress, IPerson } from '../../interfaces';
import api from '../../services/api';

type PayloadPerson = Omit<Partial<IPerson>, 'living_address' | 'mailing_address' | 'gender'>;

export const PERSON_QUERY_KEY = 'person';

interface PersonPayload extends PayloadPerson {
  middle_name?: string | null;
  date_of_birth?: string | null;
  ssn?: string | null;
  email?: string | null;
  phone?: string | null;
  secondary_phone?: string | null;
  business_phone?: string | null;
  gender?: string | null;
  education?: string | null;
  occupation_type?: string | null;
  occupation_since_date?: string | null;
  fico_score?: number | null;
  marital_status?: PayloadPerson['marital_status'] | null;
  license_number?: string | null;
  license_state?: string | null;
  license_status?: string | null;
  age_first_licensed?: number | null;
  primary_driven_vehicle_gid?: string | null;

  living_address?: IPayloadAddress | null;
  mailing_address?: IPayloadAddress | null;
}

export interface CreatePersonPayload extends PersonPayload {
  first_name: string;
  last_name: string;
}

export interface UpdatePersonPayload extends PersonPayload {
  gid: string;
  first_name?: string;
  last_name?: string;
}

export const getPerson = (gid: string): Promise<IPerson> => api.get(`/api/frontend/people/${gid}`);

const usePerson = (
  personGid: string | undefined,
  options?: Omit<UseQueryOptions<IPerson, any, IPerson, any>, 'queryKey'>
) =>
  useQuery({
    queryKey: [PERSON_QUERY_KEY, personGid],
    queryFn: personGid ? () => getPerson(personGid) : skipToken,
    ...options
  });

export const createPerson = (data: CreatePersonPayload) => api.post('/api/frontend/people', { body: data });

const syncPerson = (gid: string | number) => api.put(`/api/frontend/people/${gid}/sync`);

export const updatePerson = (data: UpdatePersonPayload) => api.put(`/api/frontend/people/${data.gid}`, { body: data });

export const useCreatePerson = (
  options?: UseMutationOptions<IPerson, any, CreatePersonPayload>
): UseMutationResult<IPerson, any, CreatePersonPayload> => useMutation({ mutationFn: createPerson, ...options });

export const useUpdatePerson = (
  options?: Omit<UseMutationOptions<{ person: IPerson }, any, UpdatePersonPayload>, 'mutationFn'>
): UseMutationResult<{ person: IPerson }, any, UpdatePersonPayload> =>
  useMutation({ mutationFn: updatePerson, ...options });

export const useSyncPerson = (
  options?: UseMutationOptions<IPerson, any, string | number>
): UseMutationResult<IPerson, any, string | number> => useMutation({ mutationFn: syncPerson, ...options });

export default usePerson;
