import { useQuery } from '@tanstack/react-query';

import { IUserLicense } from '../../interfaces';
import api from '../../services/api';

const useUserLicenses = () =>
  useQuery({
    queryKey: ['user_licenses'],
    queryFn: (): Promise<{ user_licenses: IUserLicense[] }> => api.get('/api/frontend/user_licenses'),
    select: data => data.user_licenses
  });

export default useUserLicenses;
