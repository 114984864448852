import { useMutation } from '@tanstack/react-query';

import { IMaticPolicy } from '../../../interfaces/IPolicy';
import api from '../../../services/api';

interface UploadDocumentsForSignature {
  person_gid: string;
  policy_id: number;

  document_ids: string[];
}

export const uploadDocumentsForSignature = (data: UploadDocumentsForSignature): Promise<{ policy: IMaticPolicy }> =>
  api.post(`/api/frontend/people/${data.person_gid}/policies/${data.policy_id}/documents_for_signature`, {
    body: data
  });

const useUploadDocumentsForSignature = (onSuccess?: () => void) =>
  useMutation({ mutationFn: uploadDocumentsForSignature, onSuccess });

export default useUploadDocumentsForSignature;
