import { skipToken, useQuery } from '@tanstack/react-query';

import { ICandidate } from '../../interfaces/ICandidate';
import api from '../../services/api';

const useCandidate = (gid?: string | number) =>
  useQuery({
    queryKey: ['candidate', gid],
    queryFn: gid ? (): Promise<{ candidate: ICandidate }> => api.get(`/api/frontend/candidates/${gid}`) : skipToken,
    select: data => data.candidate
  });

export default useCandidate;
