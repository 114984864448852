/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { v4 as uuidGenerator } from 'uuid';

import useQueryParams from '../../hooks/useQueryParams';
import { getOngoingCallLog } from '../../queries/call_logs/useOngoingCallLog';
import { getEngagement } from '../../queries/engagements/useEngagement';
import Loader from '../common/Loader';
import FlexBox from '../core/FlexBox';
import buildUrl from './urlBuilder';

const useRedirectUrl = () => {
  const searchParams = useQueryParams();

  const externalId = searchParams.get('external_id') || undefined;
  const personGid = searchParams.get('person_gid') || undefined;
  const leadGid = searchParams.get('lead_gid') || undefined;
  const candidateGid = searchParams.get('candidate_gid') || undefined;
  const criteria = searchParams.get('criteria') || undefined;
  const telemarketing = searchParams.get('telemarketing') || undefined;
  const engagementGid = searchParams.get('engagement_gid') || undefined;
  const taskSid = searchParams.get('taskSid') || undefined;

  const [redirectUrl, setRedirectUrl] = React.useState<string>();
  const randomizedKey = uuidGenerator();
  const keyRef = React.useRef(randomizedKey);
  keyRef.current = randomizedKey;

  React.useEffect(() => {
    const urlParams = { personGid, leadGid, candidateGid, criteria, telemarketing, engagementGid, taskSid };

    if (redirectUrl) {
      return;
    }

    const setUrl = (url: string) => {
      // Ignore work done if the key has changed
      if (keyRef.current !== randomizedKey) {
        return;
      }
      setRedirectUrl(url);
    };

    getOngoingCallLog().then(({ call_log }) => {
      // eslint-disable-next-line no-console
      console.log('Building Twilio Flex redirect url', urlParams, call_log?.external_id);

      if (call_log && (!taskSid || call_log.external_id === taskSid)) {
        setUrl(buildUrl({ currentCallId: call_log.id, ...urlParams }));
      } else if (engagementGid) {
        getEngagement(engagementGid).then(engagement => {
          // eslint-disable-next-line no-console
          console.log('Looked up engagement', engagement);

          if (engagement.type === 'lead') {
            setUrl(buildUrl({ personGid, leadGid: engagement.lead.gid, criteria }));
          } else if (engagement.type === 'candidate') {
            setUrl(buildUrl({ personGid, candidateGid: engagement.candidate.gid, criteria }));
          } else {
            setUrl(buildUrl({ personGid, leadGid, candidateGid, criteria }));
          }
        });
      } else {
        setUrl(buildUrl({ personGid, leadGid, candidateGid, criteria }));
      }
    });
  }, [
    candidateGid,
    criteria,
    externalId,
    leadGid,
    telemarketing,
    personGid,
    engagementGid,
    taskSid,
    randomizedKey,
    redirectUrl
  ]);

  return redirectUrl;
};

const TwilioFlexUrlRouter = () => {
  const { pathname, search } = useLocation();

  const redirectUrl = useRedirectUrl();

  if (redirectUrl) {
    const originalUrl = `${pathname}${search}`;
    // eslint-disable-next-line no-console
    console.log('Navigating from Twilio Flex URL router', originalUrl, redirectUrl);
  }

  return redirectUrl ? (
    <Navigate to={redirectUrl} replace />
  ) : (
    <FlexBox
      justifyCenter
      alignItemsCenter
      customCss={css`
        height: 100vh;
      `}
    >
      <Loader height={100} width={100} />
    </FlexBox>
  );
};

export default TwilioFlexUrlRouter;
