import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import alert from '../../../components/core/Alert';
import { ILead } from '../../../interfaces/ILead';
import api from '../../../services/api';

interface DuplicateLeadArgs {
  leadGid: string;
}

interface IResult {
  lead: ILead;
}

const duplicateLead = ({ leadGid }: DuplicateLeadArgs): Promise<IResult> => {
  return api.post(`/api/frontend/leads/${leadGid}/duplicate`).then(result => {
    if (result.httpStatus === 303) {
      alert({
        message: 'You have been redirected to the active lead of this customer.',
        title: 'Customer already exists in AMP'
      }).info();
    }
    return result;
  });
};

const useDuplicateLead = (
  options?: Omit<UseMutationOptions<IResult, unknown, DuplicateLeadArgs, unknown>, 'mutationFn'>
) => useMutation({ mutationFn: duplicateLead, ...options });

export default useDuplicateLead;
