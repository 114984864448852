import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IHome } from '../../interfaces/IHome';
import api from '../../services/api';

const useNonSyncedPolicyHome = (policyId: string | number, options?: UseQueryOptions<{ home: IHome }, any, any, any>) =>
  useQuery({
    queryKey: ['non_synced_policy_location', policyId],
    queryFn: (): Promise<{ home: IHome }> => api.get(`/api/frontend/policies/non_synced/${policyId}/home`),
    select: data => data.home,
    ...options
  });

export default useNonSyncedPolicyHome;
