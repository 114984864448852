/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { components } from 'react-select';

import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { TickIcon } from '../../../components/core/icons';
import {
  BasementIcon,
  CondoIcon,
  CrawlSpaceIcon,
  DuplexIcon,
  FlatRoofIcon,
  GableRoofIcon,
  GambrelRoofIcon,
  HipRoofIcon,
  NoFoundationIcon,
  PiersIcon,
  PlaceholderIcon,
  RowHomeIcon,
  SingleFamilyIcon,
  SlabIcon,
  TownHomeIcon,
  TriplexIcon
} from '../../../components/core/icons/PropertyIcons';
import Text from '../../../components/core/Text';
import featureFlags from '../../../constants/featureFlags';
import { usePopper } from '../../../hooks';
import {
  FoundationDatapointValues,
  PropertyTypeDatapointValues,
  RoofShapeDatapointValues
} from '../../../interfaces/IDatapoint';
import colors from '../../../theme/colors';

const pickSelectOptionIcon = (option: Record<string, string>) => {
  if (option.icon_url) {
    switch (option.value) {
      case PropertyTypeDatapointValues.SingleFamilyDetached:
        return <SingleFamilyIcon />;
      case PropertyTypeDatapointValues.Condominium:
        return <CondoIcon />;
      case PropertyTypeDatapointValues.Duplex:
        return <DuplexIcon />;
      case PropertyTypeDatapointValues.Triplex:
        return <TriplexIcon />;
      case PropertyTypeDatapointValues.Townhouse:
        return <TownHomeIcon />;
      case PropertyTypeDatapointValues.RowHome:
        return <RowHomeIcon />;
      case RoofShapeDatapointValues.Hip:
        return <HipRoofIcon />;
      case RoofShapeDatapointValues.Gable:
        return <GableRoofIcon />;
      case RoofShapeDatapointValues.Flat:
        return <FlatRoofIcon />;
      case RoofShapeDatapointValues.Gambrel:
        return <GambrelRoofIcon />;
      case RoofShapeDatapointValues.Shed:
        return <PlaceholderIcon />;
      default:
        return <PlaceholderIcon />;
    }
  } else {
    switch (option.value) {
      case FoundationDatapointValues.Basement:
        return <BasementIcon />;
      case FoundationDatapointValues.Slab:
        return <SlabIcon />;
      case FoundationDatapointValues.CrawlSpace:
        return <CrawlSpaceIcon />;
      case FoundationDatapointValues.NoFoundation:
        return <NoFoundationIcon />;
      case FoundationDatapointValues.Pilings:
        return <PiersIcon />;
      default:
        return null;
    }
  }
};

const PropertyInfoSelectOption = ({ children, data: option, ...rest }: any) => {
  const isMasked = (rest.selectProps as any)['fsMask'];
  const icon = featureFlags.homeDataCollectionHints ? pickSelectOptionIcon(option) : null;
  const image =
    option.icon_url && featureFlags.homeDataCollectionHints ? <img src={option.icon_url} alt={option.label} /> : null;
  const { setAnchorEl, triggerPopper, PopperComponent, popperProps } = usePopper({
    placement: 'right-start',
    disablePortal: false,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: false,
          altBoundary: true,
          tether: true
        }
      },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 4]
        }
      }
    ],
    style: { zIndex: 999 }
  });

  return (
    <div onMouseEnter={triggerPopper} onMouseLeave={() => setAnchorEl(null)}>
      <components.Option {...rest} className={isMasked ? 'fs-mask' : ''}>
        <FlexBox fitParentWidth columnDirection>
          <FlexBox justifySpaceBetween fitParentWidth alignItemsCenter>
            <div>{children}</div>
            <Container>{icon}</Container>
            {rest.isSelected && !icon && <TickIcon color={colors.white} />}
          </FlexBox>

          {image && (
            <PopperComponent {...popperProps}>
              <div
                css={css`
                  background-color: ${colors.white};
                  padding: 2px;
                `}
              >
                {image}
              </div>
            </PopperComponent>
          )}
          <div>
            {option.description && (
              <div>
                <Text color={rest.isSelected ? colors.white : colors.grey60} type="tiny">
                  {option.description}
                </Text>
              </div>
            )}
          </div>
        </FlexBox>
      </components.Option>
    </div>
  );
};

export default PropertyInfoSelectOption;
