import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { SelectField } from '../../../../components/core/forms/fields';
import Text from '../../../../components/core/Text';
import { IOpportunity } from '../../../../interfaces/IOpportunity';
import { spacings } from '../../../../theme/variables';

const LifeCoverage = ({ opportunity }: { opportunity: IOpportunity }) => {
  return (
    <Container pv={spacings.px4}>
      <Text bold>Quoting coverage</Text>
      <FlexBox mt={spacings.px12} columnDirection>
        <FlexBox ml={spacings['-px4']} alignItemsCenter gap={spacings.px12}>
          <SelectField
            inline
            required
            label="Coverage limit"
            id={`opportunities.${opportunity.id}.face_amount`}
            key={`opportunities.${opportunity.id}.face_amount`}
            name={`opportunities.${opportunity.id}.face_amount`}
            options={[
              {
                key: 100000,
                value: '$100,000'
              },
              {
                key: 150000,
                value: '$150,000'
              },
              {
                key: 200000,
                value: '$200,000'
              },
              {
                key: 250000,
                value: '$250,000'
              },
              {
                key: 300000,
                value: '$300,000'
              },
              {
                key: 400000,
                value: '$400,000'
              },
              {
                key: 500000,
                value: '$500,000'
              },
              {
                key: 600000,
                value: '$600,000'
              },
              {
                key: 700000,
                value: '$700,000'
              },
              {
                key: 800000,
                value: '$800,000'
              },
              {
                key: 900000,
                value: '$900,000'
              },
              {
                key: 1000000,
                value: '$1,000,000'
              },
              {
                key: 5000000,
                value: '$5,000,000'
              },
              {
                key: 10000000,
                value: '$10,000,000'
              }
            ]}
          />
        </FlexBox>
        <FlexBox ml={spacings['-px4']} alignItemsCenter gap={spacings.px12}>
          <SelectField
            inline
            required
            label="Term length"
            id={`opportunities.${opportunity.id}.term`}
            key={`opportunities.${opportunity.id}.term`}
            name={`opportunities.${opportunity.id}.term`}
            options={[
              {
                key: 10,
                value: '10 years'
              },
              {
                key: 15,
                value: '15 years'
              },
              {
                key: 20,
                value: '20 years'
              },
              {
                key: 25,
                value: '25 years'
              },
              {
                key: 30,
                value: '30 years'
              }
            ]}
          />
        </FlexBox>
      </FlexBox>
    </Container>
  );
};

export default LifeCoverage;
