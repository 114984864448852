import { useFormikContext } from 'formik';
import * as React from 'react';
import { create } from 'zustand';

import { useDebouncedValue } from '../../../hooks';

interface IPersistFormState {
  [key: string]: Record<string, any>;
  getValues: (key: string) => any;
  setValues: (key: string, values: any) => void;
}

const usePersistFormValues = create<IPersistFormState>()((set, get) => ({
  setValues: (key, values) => set(state => ({ ...state, [key]: values })),
  getValues: key => get()[key]
}));

export const PersistValues = ({ formKey }: { formKey: string }) => {
  const { setValues } = usePersistFormValues();
  const { values } = useFormikContext();
  const debouncedValues = useDebouncedValue(values, 500);

  React.useEffect(() => {
    setValues(formKey, debouncedValues);
  }, [formKey, setValues, debouncedValues]);

  return null;
};

export default usePersistFormValues;
