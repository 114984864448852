import { useQuery } from '@tanstack/react-query';

import api from '../../services/api';

interface Response {
  lead:
    | {
        id: number;
        gid: string;
      }
    | undefined;
}
export const getLastLeadId = (personGid: string): Promise<Response> =>
  api.get(`/api/frontend/people/${personGid}/last_lead_id`);

const useLastLeadId = (personGid: string | undefined) =>
  useQuery({
    queryKey: ['last_lead_id', personGid],
    queryFn: () => getLastLeadId(personGid!),
    select: data => data.lead,
    enabled: !!personGid
  });

export default useLastLeadId;
