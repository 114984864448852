import './Checkbox.scss';

import classNames from 'classnames';
import * as React from 'react';
import { Input } from 'reactstrap';

interface CheckboxProps {
  name: string;
  value: boolean;
  disabled?: boolean;
  onChange: (args: any) => void;
  label?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  value = false,
  disabled = false,
  onChange,
  label = '',
  ...rest
}) => {
  return (
    <label {...rest} htmlFor={name} className={classNames('checkbox-container', { 'with-own-label': label })}>
      <Input
        className="original-checkbox"
        name={name}
        id={name}
        checked={value}
        onChange={({ target: { name, checked: value } }) => onChange({ target: { name, value } })}
        type="checkbox"
        disabled={disabled}
      />
      <span className="custom-checkbox" />
      {label && <span className="label-content">{label}</span>}
    </label>
  );
};

export default Checkbox;
