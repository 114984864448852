import { useSuspenseQuery } from '@tanstack/react-query';

import api from '../../../services/api';

interface IInteractionsStatusMessage {
  message: string;
}

export const getInteractionsStatusMessage = (
  personGid: string | undefined
): Promise<{ interactions_status: IInteractionsStatusMessage }> =>
  api.get(`/api/frontend/people/${personGid}/omni_channel_interactions/status`);

const useSuspenseInteractionsStatusMessage = (personGid: string | undefined) =>
  useSuspenseQuery({
    queryKey: ['message', personGid],
    queryFn: () => getInteractionsStatusMessage(personGid),
    select: data => data.interactions_status
  });

export default useSuspenseInteractionsStatusMessage;
