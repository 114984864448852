/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useMatch, useNavigate } from 'react-router-dom';

import { Link } from '../../../components/common';
import DropDown from '../../../components/core/buttons/DropDown';
import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import {
  AppsIcon,
  CalendarIcon,
  ClockwiseClockIcon,
  DollarIcon,
  MaticLogo,
  MaticLogoPink,
  QuestionMarkHollowIcon,
  QuestionMarkIcon,
  SearchIcon,
  ShieldCheckIcon,
  TaskIcon,
  UserIcon
} from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import EntraUserAvatar from '../../../components/UserAvatar/EntraUserAvatar';
import { Configuration } from '../../../constants';
import featureFlags from '../../../constants/featureFlags';
import { useToggle } from '../../../hooks';
import useNotifications from '../../../queries/notifications/useNotifications';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { borderRadius, spacings } from '../../../theme/variables';
import NotificationsDrawer from '../../GuidedSellingExperience/_components/NotificationsDrawer';
import { PeopleSearchOpenedFrom } from '../../PeopleSearch/navigation';
import NewAnnouncementNotification from '../../ProductAnnouncements/_components/NewAnnouncementNotification';
import useProductAnnouncementsForUser from '../../ProductAnnouncements/_hooks/useProductAnnouncementsForUser';

const navigationAnchorCss = css`
  display: flex;
  flex-direction: column;
  padding: 8px 2px;
  width: 100%;
  align-items: center;
  border-radius: ${borderRadius}px;
  &:hover {
    background-color: rgba(255, 255, 255, 0.12);
  }
  &:focus {
    background-color: rgba(255, 255, 255, 0.12);
  }
`;

const GlobalNavigation = () => {
  const navigate = useNavigate();

  const { data: notifications } = useNotifications();
  const isUnreadNotificationsPresent = notifications?.some(({ read }) => !read);
  const [drawerOpened, toggleOpened] = useToggle(false);

  const dropDownOptions = [
    {
      label: 'CRM',
      value: 'crm',
      action: () => {
        navigate('/leads');
      }
    },
    {
      label: 'Dashboards',
      value: 'dashboards',
      action: () => {
        navigate('/dashboards');
      }
    },
    {
      label: 'Feature flags',
      value: 'feature_flags',
      action: () => {
        navigate('/feature_flags');
      }
    },
    {
      label: 'Scouting report scripts',
      value: 'scouting_report_scripts',
      action: () => {
        navigate('/scouting_report_scripts');
      }
    },
    featureFlags.productAnnouncements && {
      label: 'Product announcements',
      value: 'product_announcements',
      action: () => {
        navigate('/product_announcements_admin');
      }
    },
    {
      label: 'Coverages mappings',
      value: 'coverages_mappings',
      action: () => {
        navigate('/coverages_mappings');
      }
    }
  ].filter(Boolean);

  const { isUnreadAnnouncementPresent, areAnnouncementsPresent, markProductAnnouncementsAsRead } =
    useProductAnnouncementsForUser();

  const isStaging = () => Configuration.environment === 'staging';
  const background = isStaging()
    ? 'linear-gradient(0deg, #DC74E2 2.21%, #4077FF 101.84%)'
    : 'linear-gradient(180deg, #330e80 0%, #156eea 100%)';

  const isOnProductAnnouncementsRoute = useMatch('/product_announcements/*');
  const isOnPeopleSearchRoute = useMatch('/people_search/*');

  return (
    <>
      <FlexBox
        justifySpaceBetween
        columnDirection
        alignItemsCenter
        fitParentHeight
        data-testid="global-navigation-testid"
        customCss={css`
          background: ${background};
          padding: 8px 6px;
        `}
      >
        <FlexBox columnDirection alignItemsCenter gap={spacings.px12}>
          <Link
            to="/leads"
            data-testid="leads-dashboard-link"
            css={css`
              display: flex;
              margin: ${spacings.px16}px 0;
              width: 100%;
              justify-content: center;
            `}
          >
            {isStaging() ? <MaticLogoPink /> : <MaticLogo />}
          </Link>
          <Link
            to="/people_search"
            state={{ openedFrom: PeopleSearchOpenedFrom.GlobalNavigation }}
            replace={!!isOnPeopleSearchRoute} // This ensures correct back navigation from search
            data-testid="people-search-link"
            aria-label="search"
            css={navigationAnchorCss}
          >
            <SearchIcon color={colors.white} width={20} height={20} />
            <Paragraph color={colors.white} type="small">
              Search
            </Paragraph>
          </Link>
          <Container
            customCss={css`
              width: 52px;
              height: 1px;
              flex-shrink: 0;
              opacity: 0.2;
              background: ${colors.white};
            `}
          />
          <Link to="/leads" data-testid="sales-dashboard-link" css={navigationAnchorCss}>
            <DollarIcon color={colors.white} width={20} height={20} />
            <Paragraph color={colors.white} type="small">
              Sales
            </Paragraph>
          </Link>
          <Link to="/scheduled_calls" data-testid="calls-dashboard-link" css={navigationAnchorCss}>
            <CalendarIcon color={colors.white} width={20} height={20} />
            <Paragraph color={colors.white} type="small">
              Calls
            </Paragraph>
          </Link>
          <Link to="/tasks" data-testid="tasks-dashboard-link" css={navigationAnchorCss}>
            <TaskIcon color={colors.white} width={20} height={20} />
            <Paragraph color={colors.white} type="small">
              Tasks
            </Paragraph>
          </Link>
          <Link to="/policies" data-testid="policies-dashboard-link" css={navigationAnchorCss}>
            <ShieldCheckIcon color={colors.white} width={20} height={20} />
            <Paragraph color={colors.white} type="small">
              Policies
            </Paragraph>
          </Link>
          <Link to="/customers" data-testid="people-dashboard-link" css={navigationAnchorCss}>
            <UserIcon color={colors.white} width={20} height={20} />
            <Paragraph color={colors.white} type="small">
              People
            </Paragraph>
          </Link>
          <Link to="/leads/recent" data-testid="recent-leads-dashboard-link" css={navigationAnchorCss}>
            <ClockwiseClockIcon color={colors.white} width={20} height={20} />
            <Paragraph color={colors.white} type="small">
              Recent
            </Paragraph>
          </Link>
        </FlexBox>

        <FlexBox columnDirection gap={spacings.px8}>
          {!!notifications?.length && (
            <IconButton
              p={spacings.px16}
              onClick={() => toggleOpened()}
              icon={drawerOpened ? ButtonIcons.Bell : ButtonIcons.BellHollow}
              color={colors.white}
              badge={isUnreadNotificationsPresent}
              customCss={css`
                &:hover {
                  background-color: rgba(255, 255, 255, 0.12);
                }
              `}
            />
          )}
          {areAnnouncementsPresent && (
            <Container>
              <Link
                to="/product_announcements"
                data-testid="product-announcements-link"
                css={css`
                  ${navigationAnchorCss}
                  padding: 16px 8px;
                  position: relative;
                `}
              >
                {isOnProductAnnouncementsRoute ? (
                  <QuestionMarkIcon color={colors.white} width={20} height={20} />
                ) : (
                  <QuestionMarkHollowIcon color={colors.white} width={20} height={20} />
                )}
                {isUnreadAnnouncementPresent && (
                  <Container
                    customCss={css`
                      position: absolute;

                      top: 8px;
                      right: 8px;
                      width: 8px;
                      height: 8px;

                      border-radius: 4px;

                      background-color: ${colors.aqua};
                    `}
                  />
                )}
              </Link>
              {isUnreadAnnouncementPresent && (
                <NewAnnouncementNotification onConfirm={markProductAnnouncementsAsRead} />
              )}
            </Container>
          )}
          {authInfo.features.amp_ui_product_tabs && (
            <DropDown
              options={dropDownOptions}
              onSelected={option => {
                option.action && option.action();
              }}
              iconChildren
              customCss={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 16px;

                border-radius: ${borderRadius}px;
                &:hover {
                  background-color: rgba(255, 255, 255, 0.12);
                }
                &:focus {
                  background-color: rgba(255, 255, 255, 0.12);
                }
              `}
              optionsCss={css`
                margin-top: -${46 * 6}px;
              `}
            >
              <AppsIcon color={colors.white} width={20} height={20} />
            </DropDown>
          )}
          <FlexBox mb={spacings.px24} justifyCenter>
            <EntraUserAvatar />
          </FlexBox>
        </FlexBox>

        {!!notifications?.length && (
          <NotificationsDrawer notifications={notifications} opened={drawerOpened} toggleOpened={toggleOpened} />
        )}
      </FlexBox>
    </>
  );
};

export default GlobalNavigation;
