/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import HomeLinks from '../../../../../components/common/HomeLinks';
import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import IconButton, { ButtonIcons } from '../../../../../components/core/buttons/IconButton';
import CollapsingContainer from '../../../../../components/core/CollapsingContainer';
import Copy from '../../../../../components/core/Copy';
import CopyableAddress from '../../../../../components/core/CopyableAddress';
import FlexBox from '../../../../../components/core/FlexBox';
import { HomeIcon, VisibilityOffIcon } from '../../../../../components/core/icons';
import Paragraph, { ParagraphType } from '../../../../../components/core/Paragraph';
import QuestionVerificationStatusIcon, {
  QUESTION_VERIFICATION_STATUS_TOOLTIP
} from '../../../../../components/core/QuestionVerificationStatusIcon';
import Text from '../../../../../components/core/Text';
import { IOpportunity, IPerson, IQuestion, IRelatedPerson } from '../../../../../interfaces';
import DatapointKey from '../../../../../interfaces/IDatapoint';
import { isExtendedHomeInfoAvailable } from '../../../../../interfaces/IHome';
import { isOpportunityClosed } from '../../../../../interfaces/IOpportunity';
import { IUIFlow, IUIFlowElement, IUIFlowTextBox } from '../../../../../interfaces/IUIFlow';
import { PERSON_QUERY_KEY } from '../../../../../queries/people/usePerson';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import { llcTrustPair } from '../../../_helpers';
import EmptyAnswer from '../_components/EmptyAnswer';
import QuestionAnswerPair from '../_components/QuestionAnswerPair';
import UIFlowQuestionAnswerPair from '../_components/UIFlowQuestionAnswerPair';
import HomeEligibility from './HomeEligibility';
import HomeSectionIcon from './HomeSectionIcon';

interface HomeProps {
  personGid: string;
  homeOpportunity: IOpportunity;
  homeProfileUIFlow: IUIFlow;
  homeDetailsUIFlow: IUIFlow;
  homeEligibilityUIFlow: IUIFlow;
  isEditModeAvailable: boolean;
  isCustomerDataCompletenessHigh: boolean;
  isExtraSmall: boolean;
  relatedPeople: IRelatedPerson[] | undefined;

  onEditHomeDetails: () => void;
  onEditHomeEligibility: () => void;
}

const Home = ({
  personGid,
  homeOpportunity,
  homeProfileUIFlow,
  homeDetailsUIFlow,
  homeEligibilityUIFlow,
  relatedPeople,
  isEditModeAvailable,
  isCustomerDataCompletenessHigh,
  onEditHomeDetails,
  onEditHomeEligibility,
  isExtraSmall
}: HomeProps) => {
  const home = homeOpportunity.assets![0];

  const propertyOwnershipTypeElement = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyOwnershipType);
  const propertyOnTheDeedElement = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyOnTheDeed);
  const propertyTypeElement = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyType);
  const propertySquareFootageElement = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertySquareFootage);
  const propertyYearBuiltElement = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyYearBuilt);
  const propertyPurchaseDateElement = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyPurchaseDate);

  const propertyTrustNameQuestion = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyTrustName);
  const propertyLLCNameQuestion = homeProfileUIFlow.questionForDatapoint(DatapointKey.PropertyLLCName);

  const queryClient = useQueryClient();
  const person = queryClient.getQueryData<IPerson>([PERSON_QUERY_KEY, personGid]);

  const onTheDeedAnswerLabel = (() => {
    const answer = homeProfileUIFlow.initialValues()[DatapointKey.PropertyOnTheDeed] as string[] | undefined;

    if (!answer) {
      return '';
    }

    const gidNamePair = (relatedPeople || []).reduce(
      (acc, relatedPerson) => ({
        ...acc,
        [relatedPerson.gid]: relatedPerson.name
      }),
      {
        [personGid]: person?.name
      } as Record<string, string>
    );

    return answer
      .map(gidOrString => gidNamePair[gidOrString] || llcTrustPair[gidOrString as keyof typeof llcTrustPair])
      .filter(Boolean)
      .join(', ');
  })();

  const isLLCNameShown = propertyLLCNameQuestion && onTheDeedAnswerLabel.includes('LLC');

  const isTrustNameShown = propertyTrustNameQuestion && onTheDeedAnswerLabel.includes('Trust');

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  const isOpportunityDataCollectionDisabled = !homeOpportunity.is_data_collection_enabled;

  const isOpportunityEditingEnabled =
    isEditModeAvailable && !isOpportunityClosed(homeOpportunity) && !isOpportunityDataCollectionDisabled;

  if (!home) {
    return null;
  }

  return (
    <FlexBox gap={spacings.px12} columnDirection>
      <FlexBox gap={spacings.px8} alignItemsCenter justifySpaceBetween>
        <FlexBox
          gap={spacings.px8}
          alignItemsCenter
          customCss={css`
            flex-wrap: wrap;
          `}
        >
          {isOpportunityDataCollectionDisabled && <VisibilityOffIcon />}
          <Text type="small" bold color={isOpportunityDataCollectionDisabled ? colors.grey60 : colors.black}>
            {homeOpportunity.title}
          </Text>
          <CopyableAddress
            textType="small"
            textColor={isOpportunityDataCollectionDisabled ? colors.grey60 : colors.black}
            address={home.address}
          />
        </FlexBox>
        <FlexBox alignItemsCenter>
          <HomeLinks homeGid={home.gid} personGid={personGid} />
          <IconButton
            data-testid="collect-info-tab-home-edit-button"
            disabled={!isOpportunityEditingEnabled}
            icon={ButtonIcons.Edit}
            color={colors.black}
            {...(!isOpportunityEditingEnabled && {
              'data-tip': 'Edit is not available at this moment',
              'data-for': 'edit-hint'
            })}
            onClick={event => {
              event.stopPropagation();
              onEditHomeDetails();
            }}
          />
          <Tooltip id="edit-hint" />
        </FlexBox>
      </FlexBox>

      <FlexBox gap={spacings.px4} alignItemsCenter>
        <HomeIcon />
        <Text type="small" bold>
          Basic info
        </Text>
      </FlexBox>

      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid item xs={isExtraSmall ? 12 : 6}>
          <FlexBox gap={spacings.px8} columnDirection>
            {propertyOwnershipTypeElement && (
              <UIFlowQuestionAnswerPair
                textType="small"
                questionElement={propertyOwnershipTypeElement}
                answers={homeProfileUIFlow?.answers}
                isCustomerDataCompletenessHigh={isCustomerDataCompletenessHigh}
              />
            )}
            {isExtendedHomeInfoAvailable(home) && (
              <>
                {propertyOnTheDeedElement && (
                  <QuestionAnswerPair
                    questionLabel={
                      <Text type="small" color={colors.grey60}>
                        {propertyOnTheDeedElement.content.alt_label || propertyOnTheDeedElement.content.label}
                      </Text>
                    }
                    answerLabel={
                      <>
                        {propertyOnTheDeedElement.content.verification_status && (
                          <QuestionVerificationStatusIcon
                            verificationStatus={propertyOnTheDeedElement.content.verification_status}
                          />
                        )}
                        {onTheDeedAnswerLabel ? (
                          <Copy
                            type="small"
                            value={onTheDeedAnswerLabel}
                            testId={`collect-info-tab-${propertyOnTheDeedElement.content.key}`}
                          >
                            <Text type="small" className="fs-mask">
                              {onTheDeedAnswerLabel}
                            </Text>
                          </Copy>
                        ) : (
                          <EmptyAnswer textType="small" />
                        )}
                      </>
                    }
                  />
                )}
                {isTrustNameShown && (
                  <UIFlowQuestionAnswerPair
                    textType="small"
                    questionElement={propertyTrustNameQuestion}
                    answers={homeProfileUIFlow?.answers}
                    isCustomerDataCompletenessHigh={isCustomerDataCompletenessHigh}
                  />
                )}
                {isLLCNameShown && (
                  <UIFlowQuestionAnswerPair
                    textType="small"
                    questionElement={propertyLLCNameQuestion}
                    answers={homeProfileUIFlow?.answers}
                    isCustomerDataCompletenessHigh={isCustomerDataCompletenessHigh}
                  />
                )}
                {propertyTypeElement && (
                  <UIFlowQuestionAnswerPair
                    textType="small"
                    questionElement={propertyTypeElement}
                    answers={homeProfileUIFlow?.answers}
                    isCustomerDataCompletenessHigh={isCustomerDataCompletenessHigh}
                  />
                )}
              </>
            )}
          </FlexBox>
        </Grid>
        {isExtendedHomeInfoAvailable(home) && (
          <Grid item xs={isExtraSmall ? 12 : 6}>
            <FlexBox gap={spacings.px8} columnDirection>
              {propertySquareFootageElement && (
                <UIFlowQuestionAnswerPair
                  textType="small"
                  questionElement={propertySquareFootageElement}
                  answers={homeProfileUIFlow.answers}
                  isCustomerDataCompletenessHigh={isCustomerDataCompletenessHigh}
                />
              )}
              {propertyYearBuiltElement && (
                <UIFlowQuestionAnswerPair
                  textType="small"
                  questionElement={propertyYearBuiltElement}
                  answers={homeProfileUIFlow.answers}
                  isCustomerDataCompletenessHigh={isCustomerDataCompletenessHigh}
                />
              )}
              {propertyPurchaseDateElement && (
                <UIFlowQuestionAnswerPair
                  textType="small"
                  questionElement={propertyPurchaseDateElement}
                  answers={homeProfileUIFlow.answers}
                  isCustomerDataCompletenessHigh={isCustomerDataCompletenessHigh}
                />
              )}
            </FlexBox>
          </Grid>
        )}
      </Grid>

      {isExtendedHomeInfoAvailable(home) && (
        <CollapsingContainer
          title="Full home profile"
          iconPosition="left"
          openedByDefault={false}
          titleSize={ParagraphType.Small}
          preserveChildrenInClosedState
          maxContentHeight={1400}
        >
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            css={css`
              margin-top: ${-spacings.px8}px;
            `}
          >
            {homeDetailsUIFlow?.containers.map(container => {
              return (
                <Grid key={container.key} item xs={isExtraSmall ? 12 : 6}>
                  <FlexBox columnDirection gap={spacings.px8}>
                    {container.elements.map(element => {
                      if (element.kind === 'text_box') {
                        const textBox = element.content as IUIFlowTextBox;

                        return (
                          <FlexBox key={element.key} gap={spacings.px4} alignItemsCenter>
                            <HomeSectionIcon sectionKey={element.key} />
                            <Paragraph type="small" bold mb={spacings.px0}>
                              {textBox.heading}
                            </Paragraph>
                          </FlexBox>
                        );
                      }

                      const questionElement = element as IUIFlowElement & { content: IQuestion };

                      const input = homeDetailsUIFlow.inputForQuestion({
                        element,
                        formValues: {}
                      });

                      return input ? (
                        <UIFlowQuestionAnswerPair
                          key={element.key}
                          textType="small"
                          questionElement={questionElement}
                          answers={homeDetailsUIFlow?.answers}
                        />
                      ) : (
                        <React.Fragment key={element.key} />
                      );
                    })}
                  </FlexBox>
                </Grid>
              );
            })}
          </Grid>
        </CollapsingContainer>
      )}

      {isExtendedHomeInfoAvailable(home) && homeEligibilityUIFlow && (
        <HomeEligibility
          homeOpportunity={homeOpportunity}
          homeEligibilityUIFlow={homeEligibilityUIFlow}
          isEditModeAvailable={isOpportunityEditingEnabled}
          onEdit={() => onEditHomeEligibility()}
        />
      )}

      <Tooltip id={QUESTION_VERIFICATION_STATUS_TOOLTIP} />
    </FlexBox>
  );
};

export default Home;
