// TODO: @ilko.f move almost all constants to customer.js

const LAST_NAME = 'last_name';
const PHONE = 'phone';
const EMAIL = 'email';

const ADDRESS1 = 'address1';
const ADDRESS2 = 'address2';
const STATE = 'state';
const ZIP = 'zip';
const CITY = 'city';
const GENDER = 'gender';

const LIVING_ADDRESS_ID = 'living_address_id';
const LIVING_ADDRESS_LINE1 = 'living_address_line1';
const LIVING_ADDRESS_LINE2 = 'living_address_line2';
const LIVING_ADDRESS_STATE = 'living_address_state';
const LIVING_ADDRESS_ZIP = 'living_address_zip';
const LIVING_ADDRESS_CITY = 'living_address_city';

const MAILING_ADDRESS_ID = 'mailing_address_id';
const MAILING_ADDRESS_LINE1 = 'mailing_address_line1';
const MAILING_ADDRESS_LINE2 = 'mailing_address_line2';
const MAILING_ADDRESS_STATE = 'mailing_address_state';
const MAILING_ADDRESS_ZIP = 'mailing_address_zip';
const MAILING_ADDRESS_CITY = 'mailing_address_city';

const EMAIL_UNIQUENESS_FIELDS = [LAST_NAME, EMAIL];
const PHONE_UNIQUENESS_FIELDS = [LAST_NAME, PHONE];
const UNIQUENESS_FIELDS = [LAST_NAME, PHONE, EMAIL];

const customerForm = {
  ADDRESS1,
  ADDRESS2,
  STATE,
  ZIP,
  CITY,
  GENDER,

  LIVING_ADDRESS_ID,
  LIVING_ADDRESS_LINE1,
  LIVING_ADDRESS_LINE2,
  LIVING_ADDRESS_STATE,
  LIVING_ADDRESS_ZIP,
  LIVING_ADDRESS_CITY,

  MAILING_ADDRESS_ID,
  MAILING_ADDRESS_LINE1,
  MAILING_ADDRESS_LINE2,
  MAILING_ADDRESS_STATE,
  MAILING_ADDRESS_ZIP,
  MAILING_ADDRESS_CITY,

  EMAIL_UNIQUENESS_FIELDS,
  PHONE_UNIQUENESS_FIELDS,
  UNIQUENESS_FIELDS
};

export default customerForm;
