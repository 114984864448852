import { useQuery } from '@tanstack/react-query';

import api from '../../services/api';

export interface IDashboard {
  dashboard_url: string;
  enabled: boolean;
}

const useDashboards = () =>
  useQuery({
    queryKey: ['dashboards'],
    queryFn: (): Promise<{ dashboards: IDashboard[] }> => api.get('/api/frontend/dashboards'),
    select: data => data.dashboards
  });

export default useDashboards;
