import { useQuery } from '@tanstack/react-query';

import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

export const FLORIDA_HOME_QUALIFICATION_GROUP = 'amp_home_profile_v2';
export const HOME_UNDERWRITING_QUESTIONS_QUALIFICATION_GROUP = 'amp_home_underwriting_questions_v2';
export const AUTO_UNDERWRITING_QUESTIONS_QUALIFICATION_GROUP = 'amp_auto_underwriting_questions_v1';

export interface Qualification {
  code: string;
  result: 'qualified' | 'disqualified';
  agent_explanation: string;
  customer_explanation: string;
  disqualification_datapoints: string[] | null;
}

interface QualificationPayload {
  personGid: string | undefined;
  assetGid: string | undefined;
  leadGid: string | undefined;
  qualificationGroupKey: string | undefined;
  enabled?: boolean;
}

export const qualifyPersonAsset = ({ personGid, assetGid, leadGid, qualificationGroupKey }: QualificationPayload) =>
  typeof personGid === 'undefined' ||
  typeof assetGid === 'undefined' ||
  typeof leadGid === 'undefined' ||
  typeof qualificationGroupKey === 'undefined'
    ? Promise.reject(new Error('Invalid parameters personGid, assetGid, leadGid, qualificationGroupKey'))
    : (api.get(
        `/api/frontend/people/${personGid}/homes/${assetGid}/qualification?${toQueryParams({
          lead_gid: leadGid,
          qualification_group_key: qualificationGroupKey
        })}`
      ) as Promise<{
        qualification: Qualification;
      }>);

export const PERSON_ASSET_QUALIFICATION_QUERY_KEY = 'person_asset_qualification';

const usePersonAssetQualification = ({
  personGid,
  assetGid,
  leadGid,
  qualificationGroupKey,
  enabled = true
}: QualificationPayload) =>
  useQuery({
    queryKey: [PERSON_ASSET_QUALIFICATION_QUERY_KEY, personGid, assetGid, leadGid, qualificationGroupKey],
    queryFn: () => qualifyPersonAsset({ personGid, assetGid, leadGid, qualificationGroupKey }),
    enabled: !!personGid && !!assetGid && !!leadGid && !!qualificationGroupKey && enabled,
    select: data => data.qualification
  });

export default usePersonAssetQualification;
