/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Tooltip from '../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize } from '../../../components/core/buttons/Button';
import DropDown from '../../../components/core/buttons/DropDown';
import FormModal from '../../../components/core/FormModal';
import Text from '../../../components/core/Text';
import featureFlags from '../../../constants/featureFlags';
import useToggle from '../../../hooks/useToggle';
import { InsuranceCategory } from '../../../interfaces/IPolicyType';
import { LicensingTypes } from '../../../interfaces/IUser';
import useFindOrCreateLead from '../../../queries/people/person_leads/useFindOrCreateLead';
import usePersonActiveLead from '../../../queries/people/usePersonActiveLead';
import analytics from '../../../services/analytics';
import authInfo from '../../../services/authInfo';
import { humanize } from '../../../utils/formatter';

const CreateCustomerLeadButton = ({ personGid }: { personGid: string }): JSX.Element => {
  const [insuranceCategory, setInsuranceCategory] = React.useState<InsuranceCategory>();
  const isUserLifeLicensed = authInfo.licensingTypes?.includes(LicensingTypes.Life);
  const navigate = useNavigate();

  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);
  const { data: activePLLead, isPending: activePLLeadisPending } = usePersonActiveLead({
    personGid,
    insuranceCategory: InsuranceCategory.PersonalLines
  });

  const { data: activeLifeLead, isPending: activeLifeLeadisPending } = usePersonActiveLead({
    personGid,
    insuranceCategory: InsuranceCategory.Life
  });

  const { mutateAsync: createLead } = useFindOrCreateLead(({ lead: { id, gid } }) => {
    analytics.track('New Lead was created', { from: 'customer_page_with_1AL_FF', lead_gid: gid });
    navigate(featureFlags.scoutingReportRedirect ? `/scouting_report?lead_gid=${gid}` : `/leads/${id}/summary`);
  });

  return (
    <>
      <div
        // eslint-disable-next-line max-len
        data-tip={`This customer already has active lead${isUserLifeLicensed ? 's' : ''}`}
        data-for="new-lead-tooltip"
      >
        {isUserLifeLicensed ? (
          <DropDown
            options={[
              {
                label: 'Lead(Personal lines)',
                value: InsuranceCategory.PersonalLines,
                disabled: activePLLeadisPending || !!activePLLead
              },
              {
                label: 'Lead(Life)',
                value: InsuranceCategory.Life,
                disabled: activeLifeLeadisPending || !isUserLifeLicensed || !!activeLifeLead
              }
            ]}
            onSelected={selected => {
              toggleShowConfirmationModal();
              setInsuranceCategory(selected.value);
            }}
            size={ButtonSize.Small}
            optionsCss={css`
              right: 0;
              min-width: 100px;
            `}
          >
            + Create
          </DropDown>
        ) : (
          <Button
            size={ButtonSize.Small}
            onClick={toggleShowConfirmationModal}
            disabled={activePLLeadisPending || !!activePLLead}
          >
            + New Lead
          </Button>
        )}
      </div>

      {showConfirmationModal && (
        <FormModal
          confirmHandler={() => createLead({ personGid, assigneeId: authInfo.currentUserId!, insuranceCategory })}
          cancelHandler={toggleShowConfirmationModal}
          title="Confirmation"
          confirmText="Confirm"
          initialValues={{}}
          renderForm={() => <Text>Are you sure you want to create a new {humanize(insuranceCategory)} lead?</Text>}
        />
      )}
      {((isUserLifeLicensed && !!activeLifeLead) || !!activePLLead) && <Tooltip id="new-lead-tooltip" />}
    </>
  );
};

export default CreateCustomerLeadButton;
