import * as yup from 'yup';

import FlexBox from '../../components/core/FlexBox';
import FormModal from '../../components/core/FormModal';
import PhoneField from '../../components/core/forms/fields/PhoneField';
import { ModalSize } from '../../components/core/Modal';
import PersonNavLink from '../../components/PersonNavLink';
import { IPerson } from '../../interfaces/IPerson';
import authInfo from '../../services/authInfo';
import { spacings } from '../../theme/variables';
import { phoneFormatter } from '../../utils/formatter';
import { phoneField } from '../../utils/yupRules';

interface IPhoneEditor {
  cancelBtnHandler: () => void;
  confirmBtnHandler: (values: { phone: string; secondary_phone: string; business_phone: string }) => void;
  person: IPerson;
}

const PhoneEditor = ({ person, cancelBtnHandler, confirmBtnHandler }: IPhoneEditor): JSX.Element => {
  return (
    <FormModal
      confirmText="Modify phones"
      size={ModalSize.large}
      cancelHandler={cancelBtnHandler}
      confirmHandler={confirmBtnHandler}
      customHeader={<PersonNavLink person={person} linkPrefix="Modify phones for" />}
      initialValues={{
        phone: phoneFormatter(person.phone) || '',
        secondary_phone: phoneFormatter(person.secondary_phone) || '',
        business_phone: phoneFormatter(person.business_phone) || ''
      }}
      validationSchema={yup.object({
        phone: phoneField({ loose: authInfo.features.loose_phone_validation }),
        secondary_phone: phoneField({ loose: authInfo.features.loose_phone_validation }),
        business_phone: phoneField({ loose: authInfo.features.loose_phone_validation })
      })}
      renderForm={() => (
        <FlexBox mh={spacings.px8} columnDirection gap={spacings.px32}>
          <PhoneField id="modify-phone" name="phone" label="Phone" />
          <PhoneField id="secondary-phone" name="secondary_phone" label="Secondary Phone" />
          <PhoneField id="business-phone" name="business_phone" label="Business phone" />
        </FlexBox>
      )}
    />
  );
};

export default PhoneEditor;
