import { useQuery } from '@tanstack/react-query';

import { IncidentType } from '../../interfaces/IIncidentType';
import api from '../../services/api';

const getAccidentTypes = (): Promise<IncidentType[]> => api.get('/api/frontend/accident_types');

const useAccidentTypes = () =>
  useQuery({ queryKey: ['accident_types'], queryFn: () => getAccidentTypes(), staleTime: Infinity });

export default useAccidentTypes;
