/* eslint-disable react/prop-types */
import React from 'react';

import featureFlags from '../../../../constants/featureFlags';
import { ActionsCell, DashboardLeadTask, Note } from '../../index';
import ColumnGenerator from '../ColumnGenerator';

const lastActionsCell = ({
  original: { lead_id, lead_gid, latest_note_description, latest_task_description, latest_task_completed }
}: any) => {
  return (
    <ActionsCell className="fs-mask">
      <Note
        note={latest_note_description}
        url={featureFlags.scoutingReportRedirect ? `/scouting_report?lead_gid=${lead_gid}` : `/leads/${lead_id}/notes`}
      />
      {latest_task_description && (
        <DashboardLeadTask
          task_description={latest_task_description}
          task_completed={latest_task_completed}
          url={
            featureFlags.scoutingReportRedirect ? `/scouting_report?lead_gid=${lead_gid}` : `/leads/${lead_id}/tasks`
          }
        />
      )}
    </ActionsCell>
  );
};

const DashboardLeadLastActions = ColumnGenerator.simple({
  sortable: false,
  Cell: lastActionsCell
});

export default DashboardLeadLastActions;
