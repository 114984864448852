import { create } from 'zustand';

import { ITask } from '../../../interfaces';

interface IFaxTask extends ITask {
  fax: boolean;
  loan_gid: string;
}

const findTaskIndex = (tasks: IFaxTask[], task: ITask) => tasks.findIndex(t => t.id === task.id);

const useFaxTasks = create<{ faxTasks: IFaxTask[]; addTask: (task: ITask, fax: boolean, loan_gid: string) => void }>(
  set => ({
    faxTasks: [],
    addTask: (task, fax, loan_gid) =>
      set(state => {
        const updatedFaxTasks = state.faxTasks;
        const taskIndex = findTaskIndex(state.faxTasks, task);
        if (taskIndex !== -1) {
          const task = updatedFaxTasks[taskIndex];
          task && (task.fax = fax);
          task && (task.loan_gid = loan_gid);
        }
        return {
          faxTasks: taskIndex !== -1 ? updatedFaxTasks : [...state.faxTasks, { ...task, fax, loan_gid }]
        };
      })
  })
);

export default useFaxTasks;
