/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import React from 'react';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import ButtonWithoutBorder from '../../../../../components/core/buttons/ButtonWithoutBorder';
import CircledPolicyTypeIcon from '../../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import Tag from '../../../../../components/core/Tag';
import Text from '../../../../../components/core/Text';
import { IOpportunity, IPersonOnlineSession, IQuote } from '../../../../../interfaces';
import { opportunityDescription } from '../../../../../interfaces/IOpportunity';
import { IPersonOnlineSessionStatus } from '../../../../../interfaces/IPersonOnlineSession';
import { QuoteStatus } from '../../../../../interfaces/IQuote';
import { IQuotesRequest } from '../../../../../interfaces/IQuotesRequests';
import colors, { opacities } from '../../../../../theme/colors';
import { borderRadius, spacings } from '../../../../../theme/variables';
import { moneyFormatter } from '../../../../../utils/formatter';
import { isOpportunityQuotedFromAmp } from '../../../_helpers';
import QuotesModal from '../QuotesModal';
import CustomerChoiceHeader from './CustomerChoiceHeader';
import CustomerChoicePremiumAndCoverages from './CustomerChoicePremiumAndCoverages';

interface IDigitalProfileCustomerChoices {
  primaryOpportunity: IOpportunity;
  secondaryOpportunity: IOpportunity | undefined;
  personOnlineSession: IPersonOnlineSession;
  onlineSessionInitialQuote: IQuote | undefined;
  onlineSessionBestQuote: IQuote | undefined;
  customerChoices: IQuote[] | undefined;
  quotesRequests: IQuotesRequest[] | undefined;
  isPersonInterestedInCoreBundle: boolean;
}

const secondaryOpportunityNotice = ({
  secondaryOpportunity,
  quotesRequests,
  primaryOpportunityCustomerChoice
}: {
  secondaryOpportunity: IOpportunity;
  quotesRequests: IQuotesRequest[] | undefined;
  primaryOpportunityCustomerChoice: IQuote | undefined;
}) => {
  if (isOpportunityQuotedFromAmp({ opportunity: secondaryOpportunity, quotesRequests })) {
    return '🎉 Good job on seizing the opportunity to bundle!';
  }

  if (
    primaryOpportunityCustomerChoice &&
    primaryOpportunityCustomerChoice.attributes.bundle_options &&
    !primaryOpportunityCustomerChoice.attributes.bundle_options.monoline_enabled
  ) {
    // eslint-disable-next-line max-len
    return 'The customer chose the carrier that requires an auto bundle in this county. The customer was informed about this requirement.';
  }

  return 'The customer is interested in bundling, but no quote was selected.';
};

const DigitalProfileCustomerChoices = ({
  primaryOpportunity,
  secondaryOpportunity,
  personOnlineSession,
  onlineSessionInitialQuote,
  onlineSessionBestQuote,
  customerChoices,
  quotesRequests,
  isPersonInterestedInCoreBundle
}: IDigitalProfileCustomerChoices) => {
  const [quotesModalOpportunity, setQuotesModalOpportunity] = React.useState<IOpportunity | null>(null);

  const initialPremium = onlineSessionInitialQuote?.attributes?.premium;

  // Fallback to onlineSessionInitialQuote is necessary because of the scenario that should exist only on staging,
  // where OLB team creates fake OLB sessions without selecting quote on Direct Sales, which results in customerChoices
  // being absent before customer generates new quote on OLB.
  const primaryOpportunityCustomerChoice =
    customerChoices?.find(quote => quote.attributes.policy_type === primaryOpportunity.policy_type) ||
    onlineSessionInitialQuote;

  const isBestQuoteCarrierDifferentFromCustomerChoice =
    onlineSessionBestQuote?.attributes.carrier.id !== primaryOpportunityCustomerChoice?.attributes.carrier.id;

  const isOnlineSessionCompleted = personOnlineSession.status === IPersonOnlineSessionStatus.Success;
  const isOnlineSessionSuccessfullyCompleted = isOnlineSessionCompleted && onlineSessionBestQuote;
  const isCustomerChoiceSuccessful = primaryOpportunityCustomerChoice?.status === QuoteStatus.Success;

  // Actually we expect this is always an auto opportunity
  const secondaryOpportunityCustomerChoice = customerChoices?.find(
    quote => quote.attributes.policy_type === secondaryOpportunity?.policy_type
  );

  const isInitialBlockShown =
    (isBestQuoteCarrierDifferentFromCustomerChoice || !isOnlineSessionSuccessfullyCompleted) &&
    primaryOpportunityCustomerChoice;

  return (
    <FlexBox gap={spacings.px12} columnDirection>
      {(isInitialBlockShown || onlineSessionBestQuote) && (
        <FlexBox border roundBorder>
          <Grid container spacing={0}>
            {isInitialBlockShown && (
              <Grid item xs minWidth={480}>
                <FlexBox p={spacings.px8} gap={spacings.px8} fitParentHeight>
                  <CircledPolicyTypeIcon policyType={primaryOpportunityCustomerChoice.attributes.policy_type} />
                  <FlexBox gap={spacings.px4} columnDirection fitParentWidth>
                    <CustomerChoiceHeader customerChoice={primaryOpportunityCustomerChoice} />
                    <Text type="tiny" color={colors.grey60} className="fs-mask">
                      {opportunityDescription(primaryOpportunity)}
                    </Text>
                    {isOnlineSessionCompleted ? (
                      <Text type="small">Initial price they saw - {moneyFormatter(initialPremium, true)}</Text>
                    ) : (
                      <Text type="small">
                        The final premium is not yet ready as data collection is not yet complete.
                      </Text>
                    )}
                    {isCustomerChoiceSuccessful && (
                      <CustomerChoicePremiumAndCoverages
                        customerChoice={primaryOpportunityCustomerChoice}
                        isDataCollectionCompleted={isOnlineSessionCompleted}
                      />
                    )}
                    <Container>
                      <ButtonWithoutBorder
                        onClick={() => setQuotesModalOpportunity(primaryOpportunity)}
                        customCss={css`
                          padding: ${spacings.px8}px 0px;
                        `}
                      >
                        See customer’s quotes
                      </ButtonWithoutBorder>
                    </Container>
                  </FlexBox>
                  <div
                    css={css`
                      margin-right: -9px; // just trust me
                      border-right: 1px solid ${colors.grey10};
                    `}
                  />
                </FlexBox>
                <div
                  css={css`
                    margin-left: ${spacings.px8}px;
                    margin-right: ${spacings.px8}px;
                    border-bottom: 1px solid ${colors.grey10};
                  `}
                />
              </Grid>
            )}

            {onlineSessionBestQuote && (
              <Grid item xs minWidth={480}>
                <FlexBox p={spacings.px8} gap={spacings.px8}>
                  <CircledPolicyTypeIcon policyType={onlineSessionBestQuote.attributes.policy_type} />
                  <FlexBox gap={spacings.px4} columnDirection fitParentWidth>
                    <FlexBox alignItemsCenter justifySpaceBetween>
                      <CustomerChoiceHeader customerChoice={onlineSessionBestQuote} />
                      <Tag
                        label="Best premium"
                        backgroundColor={colors.statusGreen}
                        textColor={colors.white}
                        borderRadius={borderRadius}
                        textType="tiny"
                      />
                    </FlexBox>
                    <Text type="tiny" color={colors.grey60} className="fs-mask">
                      {opportunityDescription(primaryOpportunity)}
                    </Text>
                    {isBestQuoteCarrierDifferentFromCustomerChoice ? (
                      <Text type="small">
                        The customer initially chose a different carrier, but based on their collected information,{' '}
                        {onlineSessionBestQuote.attributes.carrier.name} offers a better premium.
                      </Text>
                    ) : (
                      <Text type="small">Initial price they saw - {moneyFormatter(initialPremium, true)}</Text>
                    )}
                    <CustomerChoicePremiumAndCoverages
                      customerChoice={onlineSessionBestQuote}
                      isDataCollectionCompleted={isOnlineSessionCompleted}
                    />
                    <Container>
                      <ButtonWithoutBorder
                        onClick={() => setQuotesModalOpportunity(primaryOpportunity)}
                        customCss={css`
                          padding: ${spacings.px8}px 0px;
                        `}
                      >
                        See customer’s quotes
                      </ButtonWithoutBorder>
                    </Container>
                  </FlexBox>
                </FlexBox>
              </Grid>
            )}
          </Grid>
        </FlexBox>
      )}

      {secondaryOpportunity && isPersonInterestedInCoreBundle && !secondaryOpportunityCustomerChoice && (
        <FlexBox
          ph={spacings.px8}
          pt={spacings.px8}
          pb={spacings.px20}
          gap={spacings.px8}
          border
          roundBorder
          backgroundColor={`${colors.violet}${opacities.opacity_5}`}
        >
          <CircledPolicyTypeIcon policyType={secondaryOpportunity.policy_type} color={colors.violet} />
          <FlexBox gap={spacings.px12} columnDirection fitParentWidth>
            <Paragraph bold>This customer is interested in bundling home and auto insurance</Paragraph>
            <Paragraph type="small">
              {secondaryOpportunityNotice({ secondaryOpportunity, quotesRequests, primaryOpportunityCustomerChoice })}
            </Paragraph>
          </FlexBox>
        </FlexBox>
      )}

      {secondaryOpportunity && secondaryOpportunityCustomerChoice && (
        <FlexBox border roundBorder>
          <FlexBox p={spacings.px8} gap={spacings.px8} fitParentHeight fitParentWidth>
            <CircledPolicyTypeIcon policyType={secondaryOpportunityCustomerChoice.attributes.policy_type} />
            <FlexBox gap={spacings.px4} columnDirection fitParentWidth>
              <FlexBox alignItemsCenter justifySpaceBetween>
                <CustomerChoiceHeader customerChoice={secondaryOpportunityCustomerChoice} />
                <Tag
                  label="Best premium"
                  backgroundColor={colors.statusGreen}
                  textColor={colors.white}
                  borderRadius={borderRadius}
                  textType="tiny"
                />
              </FlexBox>
              <Text type="tiny" color={colors.grey60} className="fs-mask">
                {opportunityDescription(secondaryOpportunity)}
              </Text>
              {secondaryOpportunityCustomerChoice.status === QuoteStatus.Success && (
                <CustomerChoicePremiumAndCoverages
                  customerChoice={secondaryOpportunityCustomerChoice}
                  isDataCollectionCompleted
                />
              )}
              <Container>
                <ButtonWithoutBorder
                  onClick={() => setQuotesModalOpportunity(secondaryOpportunity)}
                  customCss={css`
                    padding: ${spacings.px8}px 0px;
                  `}
                >
                  See customer’s quotes
                </ButtonWithoutBorder>
              </Container>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}

      {!primaryOpportunityCustomerChoice && !secondaryOpportunityCustomerChoice && !isPersonInterestedInCoreBundle && (
        <FlexBox p={spacings.px8} border roundBorder backgroundColor={`${colors.violet}${opacities.opacity_5}`}>
          <Paragraph bold>No quotes were selected by the customer</Paragraph>
        </FlexBox>
      )}

      {quotesModalOpportunity && (
        <QuotesModal
          opportunity={quotesModalOpportunity}
          customerQuotesOnly={true}
          onClose={() => setQuotesModalOpportunity(null)}
        />
      )}
      <Tooltip id="carrier-name-tooltip" />
      <Tooltip id="near-final-price-tooltip" />
      <Tooltip id="quoted-outdated-tooltip" />
    </FlexBox>
  );
};

export default DigitalProfileCustomerChoices;
