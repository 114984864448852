/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import cancelIcon from '../../assets/new_icons/close.svg';
import editIcon from '../../assets/new_icons/edit.svg';
import renewIcon from '../../assets/new_icons/renew.svg';
import generateIcon from '../../assets/new_icons/transaction.svg';
import transferIcon from '../../assets/new_icons/transfer.svg';
import { Link } from '../../components/common';
import { ButtonVariant } from '../../components/core/buttons/Button';
import DropDown from '../../components/core/buttons/DropDown';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import Heading from '../../components/core/Heading';
import { ArrowLeft2Icon, ArrowRightIcon, MoreIcon } from '../../components/core/icons';
import Paragraph from '../../components/core/Paragraph';
import HorizontalTabs, { TabType } from '../../components/core/Tabs/HorizontalTabs';
import Tag from '../../components/core/Tag';
import Text from '../../components/core/Text';
import ESignModal from '../../components/ESignModal';
import GenerateInvoiceModal from '../../components/GenerateInvoiceModal';
import { Translations } from '../../constants';
import { IPerson } from '../../interfaces/IPerson';
import { IMaticPolicy, POLICY_RENEWABLE_STATUSES, PolicyStatus } from '../../interfaces/IPolicy';
import { findPolicyTypeLabel } from '../../interfaces/IPolicyType';
import usePersonDocuments from '../../queries/people/person_documents/usePersonDocuments';
import usePoliciesDetails from '../../queries/policies/usePoliciesDetails';
import usePolicyTransactionsGroups from '../../queries/policies/usePolicyTransactionsGroups';
import { syncPolicy } from '../../queries/policies/useSyncPolicy';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import CustomerInfo from './CustomerInfo';
import Documents from './Documents';
import LineOfBusinessContainer from './LineOfBusinessContainer';
import NonSyncedPolicy from './NonSyncedPolicy';
import PolicyDetailsSectionPlaceholder from './PolicyDetailsSectionPlaceholder';
import SyncPolicyModal from './SyncPolicyModal';
import SyncStatus from './SyncStatus';
import TransactionsHistory from './TransactionsHistory';

interface IPolicyDetails {
  person: IPerson;
  policies: IMaticPolicy[];
}

const getPolicyStatusColor = (status: PolicyStatus) => {
  switch (status) {
    case PolicyStatus.BOUND:
      return colors.azure50;
    case PolicyStatus.ACTIVE:
      return colors.statusGreen;
    case PolicyStatus.CANCELLED:
    case PolicyStatus.NON_RENEWAL:
    case PolicyStatus.NOT_TAKEN:
      return colors.statusRed;
    case PolicyStatus.REWRITTEN:
    case PolicyStatus.RENEWED:
    default:
      return colors.grey30;
  }
};

const PolicyDetails = ({ person }: IPolicyDetails): JSX.Element => {
  const { search } = useLocation();

  const [showGenerateModal, setShowGenerateModal] = React.useState(false);
  const [showESignUploadModal, setShowESignUploadModal] = React.useState(false);
  const [showSyncModal, setShowSyncModal] = React.useState(false);

  const {
    isCurrentPolicySyncedWithIVANS,
    linesOfBusiness,
    policyTransactions,
    currentPolicy,
    policyId,
    isCurrentPolicyLoaded,
    policies
  } = usePoliciesDetails(person.gid);
  const lastTransaction = policyTransactions[0];

  const { data: transactionsGroups } = usePolicyTransactionsGroups(
    isCurrentPolicySyncedWithIVANS && policyId ? policyId : undefined
  );
  const { data: documents } = usePersonDocuments({ personGid: person.gid, policyId: currentPolicy?.id });
  const applicationDocuments = documents?.filter(document => document.document_type_key === 'application') || [];

  const navigate = useNavigate();
  const showTransactionHistory = isCurrentPolicySyncedWithIVANS && transactionsGroups?.length;
  const tabContent = [
    {
      tabName: 'Customer Info',
      content: <CustomerInfo person={person} isCurrentPolicySyncedWithIVANS={isCurrentPolicySyncedWithIVANS} />
    },
    currentPolicy && {
      tabName: 'Files',
      content: <Documents personGid={person.gid} policy={currentPolicy} />
    },
    showTransactionHistory && {
      tabName: 'Transaction history',
      content: <TransactionsHistory transactionsGroups={transactionsGroups || []} />
    }
  ].filter(Boolean) as TabType[];

  const adminPolicyActionsPossible = authInfo.features.edit_policy_status;
  const renewalPossible =
    adminPolicyActionsPossible && currentPolicy && POLICY_RENEWABLE_STATUSES.includes(currentPolicy.status);
  const rewritePossible = authInfo.features.rewrite_policies;
  const docuSignManageable = authInfo.features.send_policy_doc_for_esign;

  const generateLabel = (icon: string, label: string) => (
    <FlexBox alignItemsCenter>
      <Container mr={spacings.px12}>
        <img src={icon} alt={icon} />
      </Container>
      <Text>{label}</Text>
    </FlexBox>
  );
  const allowPostSaleCarriers = authInfo.features.allow_post_sale_carriers;
  const editPostSalePossible = currentPolicy && currentPolicy.carrier.post_sale ? !allowPostSaleCarriers : false;

  const dropDownOptions = [
    {
      value: 'edit',
      label: generateLabel(editIcon, 'Edit'),
      disabled: isCurrentPolicySyncedWithIVANS || editPostSalePossible,
      action: () => navigate(`../${policyId}/edit${search}`)
    },
    {
      value: 'generate invoice',
      label: generateLabel(generateIcon, 'Generate invoice'),
      disabled: !authInfo.features.generate_policy_invoice,
      action: () => setShowGenerateModal(true)
    },
    {
      value: 'eSign',
      label: generateLabel(editIcon, 'Send app for eSign'),
      action: () => setShowESignUploadModal(true),
      disabled: !docuSignManageable
    },
    {
      value: 'rewrite',
      label: generateLabel(renewIcon, 'Rewrite'),
      disabled: isCurrentPolicySyncedWithIVANS || !rewritePossible,
      action: () => navigate(`../${policyId}/rewrite${search}`)
    },
    {
      value: 'renew',
      label: generateLabel(renewIcon, 'Renew'),
      disabled: isCurrentPolicySyncedWithIVANS || !renewalPossible,
      action: () => navigate(`../${policyId}/renew${search}`)
    },
    {
      value: 'cancel',
      label: generateLabel(cancelIcon, 'Cancel'),
      disabled: isCurrentPolicySyncedWithIVANS || !adminPolicyActionsPossible,
      action: () => navigate(`../${policyId}/cancel${search}`)
    },
    {
      value: 'non_renew',
      label: generateLabel(cancelIcon, 'Non-renew'),
      disabled: isCurrentPolicySyncedWithIVANS || !adminPolicyActionsPossible,
      action: () => navigate(`../${policyId}/non_renew${search}`)
    },
    {
      value: 'sync',
      label: generateLabel(transferIcon, 'Sync with IVANS'),
      disabled: !currentPolicy?.carrier.supports_policy_sync,
      action: () => {
        if (currentPolicy) {
          syncPolicy(currentPolicy).then(() => {
            setShowSyncModal(true);
          });
        }
      }
    }
  ];

  const policyComponent = () => {
    if (!isCurrentPolicyLoaded) {
      return <PolicyDetailsSectionPlaceholder linesCount={5} />;
    }

    if (isCurrentPolicySyncedWithIVANS && currentPolicy) {
      return linesOfBusiness.map(lineOfBusiness => (
        <LineOfBusinessContainer
          key={lineOfBusiness.line_of_business_id}
          policy={currentPolicy}
          lineOfBusiness={lineOfBusiness}
          policyTransactions={policyTransactions}
        />
      ));
    }

    return <NonSyncedPolicy policy={currentPolicy!} />;
  };

  return (
    <div
      css={css`
        display: grid;
        grid-auto-columns: 1fr;
        grid-template-columns: 220px 1fr 420px;
        grid-template-rows: fit-content(116px) 1fr;
        grid-template-areas:
          'header header header'
          'left-pane main-pane right-pane';
        height: 100%;
        background-color: ${colors.white};
      `}
    >
      {currentPolicy && showGenerateModal && (
        <GenerateInvoiceModal handleOpened={setShowGenerateModal} person={person} policy={currentPolicy} />
      )}
      {currentPolicy && <SyncPolicyModal onCancel={() => setShowSyncModal(false)} isOpen={showSyncModal} />}
      {currentPolicy && (
        <ESignModal
          isOpened={showESignUploadModal}
          policy={currentPolicy}
          person={person}
          handleOpened={setShowESignUploadModal}
          documents={applicationDocuments}
        />
      )}

      <Container
        borderBottom
        css={css`
          grid-area: header;
        `}
      >
        <Container p={spacings.px24}>
          <FlexBox columnDirection>
            <div>
              <Link
                to={`..${search}`}
                css={css`
                  display: inline-block;
                `}
              >
                <ArrowLeft2Icon color={colors.grey60} />
                <Paragraph
                  type="small"
                  bold
                  color={colors.grey60}
                  customCss={css`
                    display: inline-block;
                  `}
                >
                  All policies
                </Paragraph>
              </Link>
            </div>
            <FlexBox justifySpaceBetween>
              <FlexBox
                gap={spacings.px12}
                customCss={css`
                  flex-flow: row wrap;
                `}
              >
                <Heading className="fs-mask" type="h3">
                  {person.name}
                </Heading>
                {currentPolicy && (
                  <FlexBox columnDirection justifyCenter>
                    <Tag
                      testId="policy-number-tag-testid"
                      backgroundColor={getPolicyStatusColor(currentPolicy.status)}
                      label={
                        <span className="fs-mask">
                          ${Translations.policyStatus(currentPolicy.status)} policy #${currentPolicy.policy_number}
                        </span>
                      }
                    />
                  </FlexBox>
                )}
              </FlexBox>
              <FlexBox alignItemsCenter>
                <FlexBox mr={spacings.px12}>
                  {currentPolicy && lastTransaction && (
                    <SyncStatus lastTransaction={lastTransaction} policy={currentPolicy} />
                  )}
                </FlexBox>
                <DropDown
                  noArrow
                  variant={ButtonVariant.Secondary}
                  loading={!isCurrentPolicyLoaded}
                  options={dropDownOptions}
                  optionsCss={css`
                    right: 24px;
                    width: 200px;
                  `}
                  onSelected={option => {
                    option.action && option.action();
                  }}
                >
                  <MoreIcon />
                </DropDown>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </Container>
      </Container>
      <div
        css={css`
          padding: 20px 24px 0;
          grid-area: left-pane;
          overflow-y: auto;
        `}
      >
        <Paragraph>Customer&apos;s policies</Paragraph>
        <div
          css={css`
            margin: 0 -24px;
          `}
        >
          {policies?.map(policy => {
            return (
              <NavLink key={policy.id} to={`../${policy.id}/details${search}`}>
                {({ isActive }) => (
                  <FlexBox
                    justifySpaceBetween
                    alignItemsCenter
                    pv={spacings.px12}
                    ph={spacings.px24}
                    backgroundColor={isActive ? colors.grey5 : colors.white}
                    customCss={css`
                      &:hover {
                        background-color: ${colors.grey5};
                      }
                    `}
                  >
                    <FlexBox
                      columnDirection
                      customCss={css`
                        min-width: 0px;
                      `}
                    >
                      <Paragraph
                        type="small"
                        customCss={css`
                          margin-bottom: 4px;
                          ::after {
                            display: inline-block;
                            width: 8px;
                            height: 8px;
                            margin: 0 8px;
                            background-color: ${getPolicyStatusColor(policy.status)};
                            border-radius: 4px;
                            content: '';
                          }
                        `}
                      >
                        {findPolicyTypeLabel(policy.policy_type)}
                      </Paragraph>
                      {policy.assets?.map(asset => (
                        <Paragraph type="small" color={colors.grey80} key={asset.gid}>
                          {asset.description_without_icon.toUpperCase()}
                        </Paragraph>
                      ))}
                    </FlexBox>
                    <div
                      css={css`
                        min-width: 20px;
                      `}
                    >
                      <ArrowRightIcon width={20} height={20} color={colors.black} />
                    </div>
                  </FlexBox>
                )}
              </NavLink>
            );
          })}
        </div>
      </div>
      <div
        css={css`
          grid-area: 'main-pane';
          overflow: hidden;
          display: grid;
          border-left: solid 1px ${colors.grey10};
          border-right: solid 1px ${colors.grey10};
        `}
      >
        <div
          css={css`
            overflow-y: auto;
            padding: 20px 24px 0;
          `}
        >
          {policyComponent()}
        </div>
      </div>

      <div
        css={css`
          grid-area: 'right-pane';
          display: grid;
          overflow: hidden;
        `}
      >
        <HorizontalTabs tabs={tabContent} />
      </div>
    </div>
  );
};

export default PolicyDetails;
