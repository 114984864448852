import { useMutation } from '@tanstack/react-query';

import api from '../../services/api';

const cancelScheduledInteraction = async ({
  personGid,
  contactFlowId,
  dialerActionId
}: {
  personGid: string;
  contactFlowId: string | number;
  dialerActionId?: string | number;
}) => {
  return await api.put(`/api/frontend/people/${personGid}/scheduled_interactions/${contactFlowId}/cancel`, {
    body: { dialer_action_id: dialerActionId }
  });
};

const useCancelScheduledInteraction = (onSuccess: () => void) =>
  useMutation({ mutationFn: cancelScheduledInteraction, onSuccess });

export default useCancelScheduledInteraction;
