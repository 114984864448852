import { useMutation } from '@tanstack/react-query';

import { ITask } from '../../../interfaces';
import api from '../../../services/api';
import { TaskPayload } from './useCreateTask';

const updateTask = async ({
  personGid,
  taskId,
  data
}: {
  personGid: string;
  taskId: number;
  data: TaskPayload;
}): Promise<{ task: ITask }> =>
  await api.put(`/api/frontend/people/${personGid}/tasks/${taskId}`, {
    body: data
  });

const useUpdateTask = (onSuccess: () => void) => useMutation({ mutationFn: updateTask, onSuccess });

export default useUpdateTask;
