import { ICurrentDisposition, IOpportunity, IUserWithRole } from '.';
import { IDisposition } from './IDisposition';
import { InsurableInterest } from './IPolicyType';

export enum DataCollectionStepsKey {
  CustomerProfile = 'customer_profile',
  HomeProfile = 'home_profile',
  HomeEligibility = 'home_eligibility',
  VehicleProfile = 'vehicle_profile',
  LifeProfile = 'life_profile',
  QuotingConfiguration = 'quoting_configuration'
}
export interface IDataCollection {
  completed: boolean;
  pages: IDataCollectionPage[];
  dispositions: IDisposition[];
  current_disposition: ICurrentDisposition;
  is_user_licensed: boolean;
  primary_state: string | null;
}

export enum IDataCollectionPageType {
  CustomerProfile = 'customer_profile',
  NeedsAssessment = 'needs_assessment',
  HomeProfile = 'home_profile',
  HomeEligibility = 'home_eligibility',
  HomeDetails = 'home_details',
  VehicleProfile = 'vehicle_profile',
  LifeProfile = 'life_profile',
  QuotingConfiguration = 'quoting_configuration'
}

export interface IDataCollectionPage {
  insurable_interest: InsurableInterest | null;
  key: DataCollectionStepsKey | string;
  parent_key: string | undefined;
  is_parent: boolean;
  label: string;
  action_label: string;
  page_type: IDataCollectionPageType;

  // Indicates that page opportunity has been disqualified. Applicable only to pages that support only one opportunity.
  is_disqualified: boolean;

  // Indicates that navigation to page is disabled from sidebar, because user has not completed previous page/s.
  is_navigation_disabled: boolean;

  // Indicates that page is completed by either AMP user or a customer via digital online flow.
  is_completed: boolean;

  // Indicates that there is enough data to allow user to complete this page.
  // Applicable only to HomeProfile and QuotingConfiguration pages.
  is_data_sufficient: boolean;

  // Indicates that page can be skipped based on a fact that it does not contain primary opportunity.
  is_skippable: boolean;

  // Indicates that data collection has not been disabled by a user.
  // This currently applies only to RCE (PropertyInformationPage) page.
  is_data_collection_enabled: boolean;

  opportunities: IDataCollectionOpportunity[] | undefined;
  originally_completed_by: IUserWithRole | undefined;
}

export interface IDataCollectionOpportunity extends IOpportunity {
  is_data_collection_completed: boolean;
}

export const firstUncompletedDataCollectionPage = (dataCollection: IDataCollection | undefined) =>
  dataCollection?.pages.find(page => !page.is_completed);
