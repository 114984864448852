import { useQuery } from '@tanstack/react-query';

import { Resources } from '../../../constants';
import api from '../../../services/api';

interface ChangeItem {
  diff: string;
  content: string;
}

interface Activity {
  id: number;
  author_name: null | string;
  item_type: string;
  remote_ip: null | string;
  changes: ChangeItem[];
  details: null | Record<string, string>;
  created_at: string;
  item_id: number;
  native: boolean;
  event: string;
}

const useLeadActivities = (leadId: string) =>
  useQuery({
    queryKey: ['activities', leadId],
    queryFn: (): Promise<{ activities: Activity[] }> => api.get(Resources.LEAD_ACTIVITIES.format({ leadId })),
    select: data => data.activities
  });

export default useLeadActivities;
