/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { labelStyle } from '../Base/Input.style';

export interface IRadioInput extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  value: string;
  fsMask?: boolean;
  description?: React.ReactNode;
}

const radioLabelStyle = labelStyle();
const RadioInput = ({ description, label, fsMask, ...props }: IRadioInput): JSX.Element => {
  return (
    <>
      <label
        css={css`
          display: flex;
          cursor: pointer;
        `}
        className={fsMask ? 'fs-mask' : ''}
      >
        <input
          {...props}
          type="radio"
          css={css`
            width: 20px;
            height: 20px;
            cursor: pointer;

            &:disabled {
              cursor: not-allowed;
            }
          `}
        />
        <span css={radioLabelStyle}>
          {label}
          {description}
        </span>
      </label>
    </>
  );
};

export default RadioInput;
