import * as React from 'react';

import useGuidedSellingExperienceData, {
  GuidedSellingExperienceData
} from '../pages/GuidedSellingExperience/_hooks/useGuidedSellingExperienceData';

export const GuidedSellingExperienceContext = React.createContext<GuidedSellingExperienceData | null>(null);

const GuidedSellingExperienceContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const data = useGuidedSellingExperienceData();

  return <GuidedSellingExperienceContext.Provider value={data}>{children}</GuidedSellingExperienceContext.Provider>;
};

const useGuidedSellingExperienceContext = (): GuidedSellingExperienceData => {
  const data = React.useContext(GuidedSellingExperienceContext);

  return data as GuidedSellingExperienceData;
};

export { GuidedSellingExperienceContextProvider, useGuidedSellingExperienceContext };
