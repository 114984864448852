/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useFormikContext } from 'formik';
import * as React from 'react';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import SystemMessage from '../../../../components/core/Alert/SystemMessage';
import Button, { ButtonSize, ButtonVariant } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { CheckboxField } from '../../../../components/core/forms/fields';
import { WarningIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import { opportunityCoveragesForAsset, OpportunityCoveragesTier } from '../../../../interfaces/IOpportunity';
import { isAutoAssetNotQuotable, IVehicle } from '../../../../interfaces/IVehicle';
import analytics from '../../../../services/analytics';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import {
  isOpportunityDataCollectionCompleted,
  isOpportunityQuotingInProgress
} from '../../../GuidedSellingExperience/_helpers';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import { IQuotingConfigurationFormValues } from '../_interfaces/IQuotingConfigurationOpportunity';
import OpportunityCurrentInsurance from '../OpportunityCurrentInsurance';
import OpportunityCustomerChoice from '../OpportunityCustomerChoice';
import OpportunityQuotingProgressMessage from '../OpportunityQuotingProgressMessage';
import AutoCoverage from './AutoCoverage';
import AutoInsuredPeople from './AutoInsuredPeople';
import AutoInsuredVehicles from './AutoInsuredVehicles';

export const autoOpportunityInitialCoverages = (
  opportunity: ConfigurationComponentProps['opportunity']
): ConfigurationComponentProps['opportunity']['agent_selected_coverages'] => {
  if (opportunity.agent_selected_coverages) {
    // This is needed so that if new vehicle is added after quotes where ran, then new vehicle will be initialized with
    // default coverages.
    return (
      opportunity.assets?.map(asset => ({
        asset_gid: asset.gid,
        coverages_tier:
          opportunityCoveragesForAsset(opportunity, asset.gid)?.coverages_tier || OpportunityCoveragesTier.Default,
        coverages: opportunityCoveragesForAsset(opportunity, asset.gid)?.coverages || null
      })) || null
    );
  }

  const customerChoiceCoverageTier = opportunity.customerChoice?.attributes?.coverages_tier;
  if (customerChoiceCoverageTier) {
    return (
      opportunity.assets?.map(asset => ({
        asset_gid: asset.gid,
        coverages_tier: customerChoiceCoverageTier,
        coverages: null
      })) || null
    );
  }

  return (
    opportunity.assets?.map(asset => ({
      asset_gid: asset.gid,
      coverages_tier: OpportunityCoveragesTier.Default,
      coverages: null
    })) || null
  );
};

const AutoOpportunity = ({
  person,
  opportunity,
  quotesRequests,
  refetchCurrentPolicies,
  onMoveToOpportunity
}: ConfigurationComponentProps) => {
  const vehicles = opportunity.assets;
  const garagingAddress = vehicles?.[0]?.address;

  const { values } = useFormikContext<IQuotingConfigurationFormValues>();
  const opportunityValues = values.opportunities[opportunity.id.toString()]!;

  const [vehiclesWithoutCoverages, setVehiclesWithoutCoverages] = React.useState<string[]>([]);

  const isOpportunityEnabled = opportunityValues.enabled;
  const selectedVehicles = vehicles?.filter(vehicle => opportunityValues.assets_gids?.includes(vehicle.gid));
  const disabledVehicles = vehicles?.filter(vehicle => isAutoAssetNotQuotable((vehicle as IVehicle).kind));

  const vehiclesWithoutCoveragesPresent =
    vehiclesWithoutCoverages.length > 0
      ? (selectedVehicles?.map(v => v.gid) || []).some(gid => vehiclesWithoutCoverages.includes(gid))
      : false;

  const isDataCollectionCompleted = isOpportunityDataCollectionCompleted({ opportunity });
  const isQuotingInProgress = isOpportunityQuotingInProgress({ opportunity, quotesRequests });

  const noAppointedCarriers = opportunity.disqualification_reason === 'no_carriers_appointments';

  return (
    <FlexBox pv={spacings.px8} ph={spacings.px12} gap={spacings.px12}>
      <FlexBox
        columnDirection
        gap={spacings.px12}
        customCss={css`
          flex-grow: 1;
        `}
      >
        <FlexBox justifySpaceBetween alignItemsCenter gap={spacings.px12}>
          <FlexBox alignItemsCenter>
            <CheckboxField
              inline
              key={`opportunities.${opportunity.id}.enabled`}
              id={`opportunities.${opportunity.id}.enabled`}
              name={`opportunities.${opportunity.id}.enabled`}
              disabled={!isDataCollectionCompleted || isQuotingInProgress || !!opportunity.disqualification_reason}
              label={
                <FlexBox gap={spacings.px8} alignItemsCenter>
                  <Text bold type="large">
                    {opportunity.title}
                  </Text>
                  {opportunity.primary && (
                    <Tag
                      label="Primary opportunity"
                      transparent
                      backgroundColor={colors.violet}
                      textColor={colors.violet}
                      mt={spacings.px4}
                    />
                  )}
                </FlexBox>
              }
            />
          </FlexBox>
          {garagingAddress && (
            <Text className="fs-mask" color={colors.grey60}>
              Garaging address: {garagingAddress.full}
            </Text>
          )}
        </FlexBox>
        {isOpportunityEnabled && (
          <>
            {vehiclesWithoutCoveragesPresent ? (
              <>
                <FlexBox>
                  <SystemMessage
                    type="warning"
                    heading="No quoting integrations available in the state. We will provide you with manual carriers."
                  />
                </FlexBox>
                <AutoInsuredVehicles opportunity={opportunity} />
                <div>
                  <Text bold>Drivers</Text>
                  <AutoInsuredPeople person={person} opportunity={opportunity} />
                </div>
              </>
            ) : (
              <>
                <AutoInsuredVehicles opportunity={opportunity} />
                <div>
                  <Text bold>Drivers</Text>
                  <AutoInsuredPeople person={person} opportunity={opportunity} />
                </div>
                {selectedVehicles?.map(vehicle => {
                  return (
                    <React.Fragment key={`${opportunity.id}.${vehicle.gid}`}>
                      <Paragraph bold>{vehicle.description_without_icon}</Paragraph>
                      <AutoCoverage
                        person={person}
                        vehicle={vehicle as IVehicle}
                        opportunity={opportunity}
                        setVehiclesWithoutCoverages={setVehiclesWithoutCoverages}
                      />
                    </React.Fragment>
                  );
                })}
                {disabledVehicles?.map(vehicle => (
                  <FlexBox gap={spacings.px4} key={vehicle.gid}>
                    <Text bold>{vehicle.description_without_icon}</Text>
                    <div data-tip="Invalid to quote in AMP" data-place="top" data-for="not-quotable-auto-tooltip-text">
                      <WarningIcon color={colors.statusOrange} />
                      <Tooltip id="not-quotable-auto-tooltip-text" />
                    </div>
                  </FlexBox>
                ))}
              </>
            )}
          </>
        )}
        {!isDataCollectionCompleted && (
          <FlexBox mt={spacings.px24} columnDirection>
            <Text>Information about drivers and vehicles was not collected</Text>
            <Container mt={spacings.px8}>
              <Button
                size={ButtonSize.Small}
                variant={ButtonVariant.Secondary}
                onClick={() => {
                  analytics.track('Collect vehicle profile clicked', {
                    person_gid: person.gid,
                    place: 'guided_selling_experience',
                    page: 'quoting_configuration'
                  });
                  return onMoveToOpportunity(opportunity);
                }}
              >
                Collect vehicle profile
              </Button>
            </Container>
          </FlexBox>
        )}
        {isQuotingInProgress && <OpportunityQuotingProgressMessage />}
        {noAppointedCarriers && (
          <SystemMessage
            type="warning"
            // eslint-disable-next-line max-len
            heading="You can't quote this opportunity because no integrated or non-integrated carriers in this State are available."
          />
        )}
      </FlexBox>
      <FlexBox gap={spacings.px12} columnDirection>
        <OpportunityCustomerChoice opportunity={opportunity} />
        <OpportunityCurrentInsurance
          person={person}
          opportunity={opportunity}
          refetchCurrentPolicies={refetchCurrentPolicies}
        />
      </FlexBox>
    </FlexBox>
  );
};

export default AutoOpportunity;
