/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import * as yup from 'yup';

import Button, { ButtonSize, ButtonVariant } from '../../components/core/buttons/Button';
import IconButton, { ButtonIcons } from '../../components/core/buttons/IconButton';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { InputField, MultiSelectField, NumericField, SelectField } from '../../components/core/forms/fields';
import { DollarIcon } from '../../components/core/icons';
import Text from '../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IQuote } from '../../interfaces';
import {
  coverageByKey,
  DeductibleType,
  LimitType,
  notRequiredHomeCoverages,
  PolicyCoverageKey
} from '../../interfaces/IPolicyCoverage';
import { PolicyType } from '../../interfaces/IPolicyType';
import { QuoteSource } from '../../interfaces/IQuote';
import useCarriers from '../../queries/carriers/useCarriers';
import { AGGREGATED_QUOTES_QUERY_KEY } from '../../queries/people/person_quotes/useAggregatedQuotes';
import { useCreateQuote } from '../../queries/people/person_quotes/useCreateQuote';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { moneyFormatter } from '../../utils/formatter';
import { REQUIRED_MESSAGE, requiredField } from '../../utils/yupRules';

const buildInitialValues = (quote?: IQuote) => ({
  carrier_key: quote?.attributes.carrier.key || '',
  premium: quote?.attributes.premium || '',
  [PolicyCoverageKey.Dwelling]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.limit_value || '',
  deductible: coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Dwelling)?.deductible_value || '',
  [PolicyCoverageKey.OtherStructures]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.OtherStructures)?.limit_value || '',
  [PolicyCoverageKey.PersonalProperty]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalProperty)?.limit_value || '',
  [PolicyCoverageKey.LossOfUse]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.LossOfUse)?.limit_value || '',
  [PolicyCoverageKey.PersonalLiability]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.PersonalLiability)?.limit_value || '',
  [PolicyCoverageKey.MedicalPayments]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.MedicalPayments)?.limit_value || '',
  included_coverages: quote?.attributes.coverages
    ?.map(coverage =>
      notRequiredHomeCoverages.includes(coverage.key) &&
      coverage.deductible_description !== 'Not included' &&
      coverage.limit_description !== 'Not included'
        ? coverage.key
        : undefined
    )
    .filter(Boolean) || [PolicyCoverageKey.WindHail],
  excluded_coverages:
    quote?.attributes.coverages
      ?.map(coverage =>
        coverage.deductible_description === 'Not included' || coverage.limit_description === 'Not included'
          ? coverage.key
          : undefined
      )
      .filter(Boolean) || [],
  [PolicyCoverageKey.Earthquake]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Earthquake)?.limit_value || '',
  [PolicyCoverageKey.Flood]: coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Flood)?.limit_value || '',
  [PolicyCoverageKey.Hurricane]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.Hurricane)?.limit_value || '',
  [PolicyCoverageKey.WaterBackup]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.WaterBackup)?.limit_value || '',
  [PolicyCoverageKey.WindHail]:
    coverageByKey(quote?.attributes.coverages, PolicyCoverageKey.WindHail)?.limit_value || '',
  agent_bridge_url: quote?.attributes.binding_url || ''
});

const isIncludedValueDisabled = (key: PolicyCoverageKey, values: ReturnType<typeof buildInitialValues>) => {
  return values.excluded_coverages.includes(key) ? true : false;
};

const isExcludedValueDisabled = (key: PolicyCoverageKey, values: ReturnType<typeof buildInitialValues>) => {
  return values.included_coverages.includes(key) ? true : false;
};

const buildCoverages = ({
  dwelling,
  other_structures,
  personal_property,
  loss_of_use,
  personal_liability,
  deductible,
  medical_payments,
  included_coverages,
  excluded_coverages,
  ...values
}: ReturnType<typeof buildInitialValues>) => {
  const dwellingNode =
    dwelling || deductible
      ? {
          key: PolicyCoverageKey.Dwelling,
          limit_value: dwelling || 0,
          deductible_value: deductible || 0,
          limit_description: moneyFormatter(dwelling, true),
          deductible_description: moneyFormatter(deductible, true),
          limit_type: LimitType.MoneyLimit,
          deductible_type: DeductibleType.Flat
        }
      : undefined;
  const otherStructuresNode = other_structures
    ? {
        key: PolicyCoverageKey.OtherStructures,
        limit_value: other_structures,
        deductible_value: 0,
        limit_description: moneyFormatter(other_structures, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const personalPropertyNode = personal_liability
    ? {
        key: PolicyCoverageKey.PersonalProperty,
        limit_value: personal_property,
        deductible_value: 0,
        limit_description: moneyFormatter(personal_property, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const lossOfUseNode = loss_of_use
    ? {
        key: PolicyCoverageKey.LossOfUse,
        limit_value: loss_of_use,
        deductible_value: 0,
        limit_description: moneyFormatter(loss_of_use, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const personalLiability = personal_liability
    ? {
        key: PolicyCoverageKey.PersonalLiability,
        limit_value: personal_liability,
        deductible_value: 0,
        limit_description: moneyFormatter(personal_liability, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;
  const medicalPayments = medical_payments
    ? {
        key: PolicyCoverageKey.MedicalPayments,
        limit_value: medical_payments,
        deductible_value: 0,
        limit_description: moneyFormatter(medical_payments, true),
        limit_type: LimitType.MoneyLimit,
        deductible_description: undefined,
        deductible_type: undefined
      }
    : undefined;

  const includedCoverages =
    included_coverages?.map(key => {
      return {
        key,
        limit_value: values[key as keyof typeof values] || 0,
        deductible_value: values[key as keyof typeof values] || 0,
        limit_description: values[key as keyof typeof values]
          ? moneyFormatter(values[key as keyof typeof values], true)
          : 'Included',
        deductible_description: values[key as keyof typeof values]
          ? moneyFormatter(values[key as keyof typeof values], true)
          : 'Included'
      };
    }) || [];

  const excludedCoverages =
    excluded_coverages?.map(key => ({
      key,
      limit_value: 0,
      deductible_value: 0,
      limit_description: 'Not included',
      deductible_description: 'Not included'
    })) || [];

  return [
    dwellingNode,
    otherStructuresNode,
    personalPropertyNode,
    lossOfUseNode,
    personalLiability,
    medicalPayments,
    ...includedCoverages,
    ...excludedCoverages
  ].filter(Boolean);
};

const HomeQuoteForm = ({
  policyType,
  setOpenQuoteEditor,
  quote,
  assetGid,
  setSelectedQuoteKey,
  quotes
}: {
  policyType: PolicyType;
  setOpenQuoteEditor: React.Dispatch<React.SetStateAction<boolean>>;
  quote?: IQuote;
  assetGid: string;
  setSelectedQuoteKey: React.Dispatch<React.SetStateAction<string>>;
  quotes: IQuote[];
  selectedQuoteKey: string | undefined;
}) => {
  const queryClient = useQueryClient();
  const { personGid, leadGid } = useGuidedSellingExperienceContext();
  const carriersToExclude = quotes.map(quote => quote.attributes.carrier.key);
  const { data: carriers, isPending: isPendingCarriers } = useCarriers({
    policy_type: policyType,
    appointed: true
  });
  const carrierOptions = React.useMemo(() => {
    return (carriers || [])
      .filter(
        carrier => !carriersToExclude.find(key => key === carrier.key) || carrier.key === quote?.attributes.carrier.key
      )
      .map(({ key, name }) => ({ key, value: name }));
  }, [carriers, carriersToExclude, quote?.attributes.carrier.key]);

  const { mutateAsync: createQuote } = useCreateQuote();

  return (
    <Container
      border
      borderColor={colors.azure50}
      boxShadow
      customCss={css`
        border-radius: ${spacings.px8}px;
        max-width: 400px;
      `}
      backgroundColor={colors.ghostWhite}
      p={spacings.px12}
    >
      <BaseForm
        pt={spacings.px0}
        pr={spacings.px0}
        pb={spacings.px0}
        pl={spacings.px0}
        initialValues={buildInitialValues(quote)}
        onSubmit={values => {
          createQuote({
            personGid: personGid!,
            data: {
              premium: values.premium,
              source: QuoteSource.AU,
              policy_type: policyType,
              carrier_key: values.carrier_key,
              engagement_gid: leadGid!,
              coverages: buildCoverages(values),
              assets: [{ gid: assetGid, coverages: buildCoverages(values) }],
              agent_bridge_url: values.agent_bridge_url
            }
          }).then(data =>
            queryClient.invalidateQueries({ queryKey: [AGGREGATED_QUOTES_QUERY_KEY, personGid] }).then(() => {
              setSelectedQuoteKey(data.quote.gid);
            })
          );

          setOpenQuoteEditor(false);
        }}
        validationSchema={yup.object().shape({
          carrier_key: requiredField,
          premium: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          deductible: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.Dwelling]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.OtherStructures]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.PersonalProperty]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.LossOfUse]: yup.number().min(1, 'must be greater than zero').required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.PersonalLiability]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE),
          [PolicyCoverageKey.MedicalPayments]: yup
            .number()
            .min(1, 'must be greater than zero')
            .required(REQUIRED_MESSAGE)
        })}
        customControls
        renderForm={({ values }) => {
          return (
            <FlexBox columnDirection gap={spacings.px12}>
              <FlexBox justifySpaceBetween>
                <Text bold>Add insurance details</Text>
                <IconButton
                  icon={ButtonIcons.Close}
                  color={colors.black}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                />
              </FlexBox>
              <FlexBox gap={spacings.px4} columnDirection>
                <SelectField
                  name="carrier_key"
                  id="carrier_key"
                  label="Carrier"
                  options={carrierOptions}
                  inline
                  ordered
                  isLoading={isPendingCarriers}
                  customCss={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                  required
                  menuMinWidth="180px"
                />
                <InputField name="agent_bridge_url" id="agent_bridge_url" label="Carrier portal URL" inline />
                <NumericField
                  name="premium"
                  id="premium"
                  label="Premium(full period)"
                  inline
                  valueIsNumber
                  prefixIcon={<DollarIcon />}
                  required
                />
                <NumericField
                  name="deductible"
                  id="deductible"
                  label="Deductible"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.Dwelling}
                  id={PolicyCoverageKey.Dwelling}
                  label="Dwelling"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.OtherStructures}
                  id={PolicyCoverageKey.OtherStructures}
                  label="Other structures"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.PersonalProperty}
                  id={PolicyCoverageKey.PersonalProperty}
                  label="Personal property"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.LossOfUse}
                  id={PolicyCoverageKey.LossOfUse}
                  label="Loss of use"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.PersonalLiability}
                  id={PolicyCoverageKey.PersonalLiability}
                  label="Personal liability"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                  required
                />
                <NumericField
                  name={PolicyCoverageKey.MedicalPayments}
                  id={PolicyCoverageKey.MedicalPayments}
                  label="Medical payments"
                  inline
                  valueIsNumericString
                  prefixIcon={<DollarIcon />}
                  decimalScale={0}
                  required
                />
                <MultiSelectField
                  name="included_coverages"
                  id="included_coverages"
                  label="Coverages included"
                  options={[
                    {
                      key: PolicyCoverageKey.WindHail,
                      value: 'Wind/Hail',
                      isDisabled: isIncludedValueDisabled(PolicyCoverageKey.WindHail, values)
                    },
                    {
                      key: PolicyCoverageKey.Hurricane,
                      value: 'Hurricane',
                      isDisabled: isIncludedValueDisabled(PolicyCoverageKey.Hurricane, values)
                    },
                    {
                      key: PolicyCoverageKey.WaterBackup,
                      value: 'Water backup',
                      isDisabled: isIncludedValueDisabled(PolicyCoverageKey.WaterBackup, values)
                    },
                    {
                      key: PolicyCoverageKey.Earthquake,
                      value: 'Earthquake',
                      isDisabled: isIncludedValueDisabled(PolicyCoverageKey.Earthquake, values)
                    },
                    {
                      key: PolicyCoverageKey.Flood,
                      value: 'Flood',
                      isDisabled: isIncludedValueDisabled(PolicyCoverageKey.Flood, values)
                    }
                  ]}
                  inline
                  css={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                />
                {values.included_coverages.includes(PolicyCoverageKey.WindHail) && (
                  <NumericField
                    name={PolicyCoverageKey.WindHail}
                    id={PolicyCoverageKey.WindHail}
                    label="Wind/Hail"
                    inline
                    valueIsNumericString
                    prefixIcon={<DollarIcon />}
                    decimalScale={0}
                    required
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.Hurricane) && (
                  <NumericField
                    name={PolicyCoverageKey.Hurricane}
                    id={PolicyCoverageKey.Hurricane}
                    label="Hurricane"
                    inline
                    valueIsNumericString
                    prefixIcon={<DollarIcon />}
                    decimalScale={0}
                    required
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.WaterBackup) && (
                  <NumericField
                    name={PolicyCoverageKey.WaterBackup}
                    id={PolicyCoverageKey.WaterBackup}
                    label="Water backup"
                    inline
                    valueIsNumericString
                    prefixIcon={<DollarIcon />}
                    decimalScale={0}
                    required
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.Earthquake) && (
                  <NumericField
                    name={PolicyCoverageKey.Earthquake}
                    id={PolicyCoverageKey.Earthquake}
                    label="Earthquake"
                    inline
                    valueIsNumericString
                    prefixIcon={<DollarIcon />}
                    decimalScale={0}
                    required
                  />
                )}
                {values.included_coverages.includes(PolicyCoverageKey.Flood) && (
                  <NumericField
                    name={PolicyCoverageKey.Flood}
                    id={PolicyCoverageKey.Flood}
                    label="Flood"
                    inline
                    valueIsNumericString
                    prefixIcon={<DollarIcon />}
                    decimalScale={0}
                    required
                  />
                )}
                <MultiSelectField
                  name="excluded_coverages"
                  id="excluded_coverages"
                  label="Coverages excluded"
                  options={[
                    {
                      key: PolicyCoverageKey.WindHail,
                      value: 'Wind/Hail',
                      isDisabled: isExcludedValueDisabled(PolicyCoverageKey.WindHail, values)
                    },
                    {
                      key: PolicyCoverageKey.Hurricane,
                      value: 'Hurricane',
                      isDisabled: isExcludedValueDisabled(PolicyCoverageKey.Hurricane, values)
                    },

                    {
                      key: PolicyCoverageKey.WaterBackup,
                      value: 'Water backup',
                      isDisabled: isExcludedValueDisabled(PolicyCoverageKey.WaterBackup, values)
                    },
                    {
                      key: PolicyCoverageKey.Earthquake,
                      value: 'Earthquake',
                      isDisabled: isExcludedValueDisabled(PolicyCoverageKey.Earthquake, values)
                    },
                    {
                      key: PolicyCoverageKey.Flood,
                      value: 'Flood',
                      isDisabled: isExcludedValueDisabled(PolicyCoverageKey.Flood, values)
                    }
                  ]}
                  inline
                  css={css`
                    background-color: ${colors.white};
                    border-radius: 4px;
                  `}
                />
              </FlexBox>
              <FlexBox gap={spacings.px4} justifyRight>
                <Button
                  type="button"
                  size={ButtonSize.Small}
                  variant={ButtonVariant.SecondaryDanger}
                  onClick={() => {
                    setOpenQuoteEditor(false);
                  }}
                >
                  Cancel
                </Button>
                <Button type="submit" size={ButtonSize.Small}>
                  Save
                </Button>
              </FlexBox>
            </FlexBox>
          );
        }}
      />
    </Container>
  );
};

export default HomeQuoteForm;
