/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Blockquote from '../../../components/core/Blockquote';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import { RadioGroupField } from '../../../components/core/forms/fields';
import Paragraph from '../../../components/core/Paragraph';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { usePolicyDisclosure } from '../../../queries/disclosures/useDisclosure';
import colors from '../../../theme/colors';
import { defaultFontValues } from '../../../theme/typography';
import { spacings } from '../../../theme/variables';

export const LEGAL_CONSENT_FIELD_KEY = 'legal_consent';
const PolicyLegalConsent = ({ disabled = false }: { disabled?: boolean }) => {
  const { data } = usePolicyDisclosure();
  const { person } = useGuidedSellingExperienceContext();

  return (
    <>
      {data?.prompt && person && (
        <FlexBox gap={spacings.px16} columnDirection maxWidth={700}>
          <Paragraph type="large" bold>
            {data.prompt}
          </Paragraph>
          <RadioGroupField
            required
            testId="legal-consent"
            preserveErrorSpace={false}
            id={LEGAL_CONSENT_FIELD_KEY}
            name={LEGAL_CONSENT_FIELD_KEY}
            options={[
              { id: 'false', value: 'false', label: 'No', disabled },
              { id: 'true', value: 'true', label: 'Yes', disabled }
            ]}
            label={() => (
              <Container
                ml={spacings.px32}
                customCss={css`
                  font-size: ${defaultFontValues.fontSize};
                  white-space: break-spaces;
                `}
              >
                <Blockquote
                  text={data.content.replace('{{person_name}}', person.first_name)}
                  textColor={colors.grey80}
                />
              </Container>
            )}
          />
        </FlexBox>
      )}
    </>
  );
};

export default PolicyLegalConsent;
