import { useMutation, UseMutationResult } from '@tanstack/react-query';

import alert from '../../../components/core/Alert';
import { DispositionType } from '../../../interfaces/IDisposition';
import { ILead } from '../../../interfaces/ILead';
import { InsuranceCategory } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';

interface IPayload {
  personGid: string;
  candidateGid?: string;
  insuranceCategory?: InsuranceCategory;
  assigneeId: number;
  initialDisposition?: DispositionType.NewRequest | DispositionType.ContactMade;
}

interface IResult {
  lead: ILead;
}

const createLead = ({
  personGid,
  candidateGid,
  insuranceCategory,
  assigneeId,
  initialDisposition
}: IPayload): Promise<IResult> => {
  return api
    .post(`/api/frontend/people/${personGid}/leads`, {
      body: {
        candidate_gid: candidateGid,
        insurance_category: insuranceCategory,
        assignee_id: assigneeId,
        initial_disposition: initialDisposition
      }
    })
    .then(result => {
      if (result.httpStatus === 303) {
        alert({
          message: 'You have been redirected to the active lead of this customer.',
          title: 'Customer already exists in AMP'
        }).info();
      }
      return result;
    });
};

const useFindOrCreateLead = (onSuccess?: ({ lead }: IResult) => void): UseMutationResult<IResult, any, IPayload> =>
  useMutation({ mutationFn: createLead, onSuccess });

export default useFindOrCreateLead;
