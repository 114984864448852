import { css } from '@emotion/react';
import * as React from 'react';

import usePopper from '../../../hooks/usePopper';
import {
  dateFormatter,
  DISPLAY_DATE_FORMAT,
  INTERNAL_DATE_FORMAT,
  INTERNAL_DATE_FORMAT_REGEXP
} from '../../../utils/formatter';
import Calendar from '../../core/Calendar/Calendar';
import DateInput from './DateInput';

interface DatePickerProps {
  onChange(args: any): void;
  value?: string | Date;
  name: string;
  id: string;
  onBlur(): void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  testId?: string;
}

const getValue = (value?: string | Date) => {
  if (!value) {
    return '';
  }

  if (value instanceof Date) {
    return value;
  }

  let valueDate;
  if (value.match(INTERNAL_DATE_FORMAT_REGEXP)) {
    valueDate = new Date(dateFormatter(value, DISPLAY_DATE_FORMAT));
  } else {
    valueDate = new Date(value);
  }

  if (isNaN(valueDate.getTime())) {
    return '';
  }

  return valueDate;
};

const DatePicker = ({
  onChange,
  value,
  name,
  id,
  placeholder = DISPLAY_DATE_FORMAT,
  disabled,
  className,
  testId,
  ...rest
}: DatePickerProps): JSX.Element => {
  const { setAnchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper();

  const onDayChange = (value: string) => {
    onChange({
      target: {
        name,
        value
      }
    });
  };

  return (
    <div className={className} ref={elementRef}>
      <DateInput
        id={id}
        data-testid={testId}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        value={value && value.toString().trimEnd().length >= 10 ? dateFormatter(value, DISPLAY_DATE_FORMAT) : value}
        onChange={(e: React.ChangeEvent<any>) => {
          const { value } = e.target;

          if (value && value.toString().trimEnd().length === 10) {
            onDayChange(dateFormatter(value, INTERNAL_DATE_FORMAT));
          } else {
            onDayChange(value as string);
          }
        }}
        onClick={triggerPopper}
      />
      <PopperComponent {...popperProps}>
        <Calendar
          {...rest}
          onDateChange={date => {
            if (Array.isArray(date)) {
              return;
            }

            onDayChange(dateFormatter(date, INTERNAL_DATE_FORMAT));

            setAnchorEl(null);
          }}
          size="small"
          date={getValue(value) || null}
          customCss={css`
            padding: 8px;
          `}
        />
      </PopperComponent>
    </div>
  );
};

export default DatePicker;
