import { skipToken, useQuery } from '@tanstack/react-query';

import { Engagement } from '../../interfaces/IEngagement';
import api from '../../services/api';

export const getEngagement = (engagementGid: string): Promise<Engagement> =>
  api.get(`/api/frontend/engagements/${engagementGid}`);

const useEngagement = (engagementGid: string | undefined) =>
  useQuery({
    queryKey: ['engagement_by_gid', engagementGid],
    queryFn: engagementGid ? () => getEngagement(engagementGid as string) : skipToken
  });

export default useEngagement;
