import * as React from 'react';

import { useEntraIdToken } from '../auth/entraAuthConfig';
import { setupCableConsumer } from '../utils/actioncable';

const useEntraActionCableConsumer = () => {
  const { idToken } = useEntraIdToken();

  const [[token, currentCable], setCurrentCable] = React.useState(() => [idToken, setupCableConsumer(idToken)]);

  React.useEffect(() => {
    if (idToken !== token) {
      setCurrentCable(([_, previousCable]) => {
        previousCable?.disconnect();

        return [idToken, setupCableConsumer(idToken)];
      });
    }
  }, [idToken, token, currentCable]);

  return currentCable;
};

export default useEntraActionCableConsumer;
