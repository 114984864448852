import './StarIndicator.scss';

import classNames from 'classnames';
import debounce from 'debounce';
import React from 'react';

import colors from '../../../theme/colors';
import { StarIcon } from '../../core/icons';

const DEBOUNCE_TIME = 1000;

const StarIndicator = ({ starred = false, onChange, className = '' }: any) => {
  return (
    <div
      onClick={debounce(() => onChange(!starred), DEBOUNCE_TIME)}
      className={classNames(className, 'star-indicator')}
    >
      <StarIcon color={starred ? colors.sun : undefined} fill={starred ? colors.sun : colors.white} />
    </div>
  );
};

export default StarIndicator;
