/** @jsxImportSource @emotion/react */
import Grid from '@mui/material/Grid';
import { FormikProps } from 'formik';
import * as React from 'react';

import usePremiumConfirmation from '../../hooks/usePremiumConfirmation';
import { IPolicy } from '../../interfaces';
import { PolicyCoverageKey } from '../../interfaces/IPolicyCoverage';
import { isInsurableInterestRealProperty, isInsurableInterestVehicle, PolicyType } from '../../interfaces/IPolicyType';
import useCarriers from '../../queries/carriers/useCarriers';
import { DateInputField, InputField, NumericField, SelectField } from '../core/forms/fields';
import { DollarIcon } from '../core/icons';
import Select from '../core/inputs/Select';

interface MainFormProps {
  selectedPolicyType?: PolicyType;
  priorPolicy?: IPolicy;
  formikProps: FormikProps<any>;
}

const MainForm = ({ selectedPolicyType, priorPolicy, formikProps }: MainFormProps) => {
  const { data: carriers = [], isPending: isPendingCarriers } = useCarriers({ post_sale: true });

  const policyType = priorPolicy?.policy_type || selectedPolicyType;

  const { ConfirmationModal, validatePremiumCallback } = usePremiumConfirmation({ isOptional: true });

  return (
    <>
      <Grid item container xs={12} columnSpacing={2} justifyContent="space-between">
        <Grid item xs={6}>
          <SelectField
            label="Carrier"
            id="carrier_gid"
            name="carrier_gid"
            placeholder=""
            options={carriers.map(({ gid, name }) => ({ key: gid, value: name }))}
            ordered
            isLoading={isPendingCarriers}
          />
        </Grid>
        <Grid item xs={6}>
          <NumericField
            label="Premium"
            id="premium"
            name="premium"
            valueIsNumericString
            prefixIcon={<DollarIcon />}
            fieldProps={{
              validate: value => validatePremiumCallback(value || 0)
            }}
            selectPrefix={
              <Select
                onChange={e => {
                  formikProps.setFieldValue('premium_period', e);
                }}
                value={formikProps.values.premium_period}
                isPrefix
                options={[
                  { key: 1, value: 'Monthly' },
                  { key: 6, value: 'Half yearly' },
                  { key: 12, value: 'Yearly' }
                ]}
              />
            }
          />
          <ConfirmationModal />
        </Grid>
        <Grid item xs={6}>
          <DateInputField label="Expiration date" id="expiration_date" name="expiration_date" />
        </Grid>
        <Grid item xs={6}>
          <InputField label="Policy number" id="policy_number" name="policy_number" fsMask />
        </Grid>
      </Grid>

      {!!policyType && isInsurableInterestRealProperty(policyType) && (
        <Grid item container xs={12} columnSpacing={2} justifyContent="space-between">
          <Grid item xs={4}>
            <NumericField
              label="Dwelling"
              // PolicyCoverageKey.Dwelling - limit value
              id={PolicyCoverageKey.Dwelling}
              name={PolicyCoverageKey.Dwelling}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Other structures"
              id={PolicyCoverageKey.OtherStructures}
              name={PolicyCoverageKey.OtherStructures}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Personal property"
              id={PolicyCoverageKey.PersonalProperty}
              name={PolicyCoverageKey.PersonalProperty}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Loss of use"
              id={PolicyCoverageKey.LossOfUse}
              name={PolicyCoverageKey.LossOfUse}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Personal liability"
              id={PolicyCoverageKey.PersonalLiability}
              name={PolicyCoverageKey.PersonalLiability}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Medical payments"
              id={PolicyCoverageKey.MedicalPayments}
              name={PolicyCoverageKey.MedicalPayments}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Deductible"
              // PolicyCoverageKey.Dwelling - deductible_value
              id="deductible"
              name="deductible"
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
        </Grid>
      )}
      {!!policyType && isInsurableInterestVehicle(policyType) && (
        <Grid item container xs={12} columnSpacing={2} justifyContent="space-between">
          <Grid item xs={4}>
            <NumericField
              label="Property damage"
              id={PolicyCoverageKey.PropertyDamage}
              name={PolicyCoverageKey.PropertyDamage}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Bodily injury limit (per person)"
              id={PolicyCoverageKey.BiPerPerson}
              name={PolicyCoverageKey.BiPerPerson}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
          <Grid item xs={4}>
            <NumericField
              label="Bodily injury limit (per accident)"
              id={PolicyCoverageKey.BiPerAccident}
              name={PolicyCoverageKey.BiPerAccident}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
        </Grid>
      )}
      {policyType === PolicyType.PAF && (
        <Grid item container xs={12} columnSpacing={2} justifyContent="space-between">
          <Grid item xs={4}>
            <NumericField
              label="Coverage"
              id={PolicyCoverageKey.Jewelry}
              name={PolicyCoverageKey.Jewelry}
              valueIsNumericString
              prefixIcon={<DollarIcon />}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default MainForm;
