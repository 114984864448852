import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import { IAddress } from '../../interfaces/IAddress';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

interface IvansHome {
  id: number;
  location_id: string;
  year_built?: string;
  address: IAddress;
}

const usePolicyIvansHome = (
  policyId: number,
  lineOfBusinessId: number,
  transactionId: number,
  options?: UseQueryOptions<{ ivans_home: IvansHome }, any, any, any>
) =>
  useQuery({
    queryKey: ['policy_ivans_home', policyId, lineOfBusinessId, transactionId],
    queryFn: (): Promise<{ ivans_home: IvansHome }> =>
      api.get(
        `/api/frontend/policies/${policyId}/home/${lineOfBusinessId}/ivans_home?${toQueryParams({
          transaction_id: transactionId
        })}`
      ),
    select: data => data.ivans_home,
    ...options
  });

export default usePolicyIvansHome;
