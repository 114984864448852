import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import React from 'react';

import FiltersSubHeader from '../../components/common/FiltersSubHeader/FiltersSubHeader';
import Pagination from '../../components/common/Pagination/Pagination';
import Container from '../../components/core/Container';
import TablePlaceholder from '../../components/TablePlaceholder';
import { SORT_ORDER_DESC } from '../../constants';
import { useDocumentTitle } from '../../hooks';
import useUpdatePersonTask from '../../queries/people/person_tasks/useUpdateTask';
import { TASK_REMINDERS_QUERY_KEY } from '../../queries/tasks/useTaskReminders';
import useTasks, {
  TasksDashboardFilters,
  TasksDashboardResponse,
  TasksDashboardSorting
} from '../../queries/tasks/useTasks';
import { spacings } from '../../theme/variables';
import TasksFilter from './TasksFilter';
import TasksList from './TasksList';

const TASKS_PER_PAGE = 25;
const TASKS_FILTERS_STORAGE_KEY = 'tasksFilters';
const DEFAULT_SORTING = { sort_column: 'due_date', sort_order: SORT_ORDER_DESC };
const TWELVE_HOURS = 12 * 60 * 60 * 1000;

const defaultFilters = () => {
  const date_from = moment().subtract('30', 'days').toDate();
  const date_to = moment().toDate();

  return { date_from, date_to } as any as TasksDashboardFilters;
};

const restoreFilters = () => {
  const filters = JSON.parse(localStorage.getItem(TASKS_FILTERS_STORAGE_KEY) || '{}') || ({} as any);
  const dateNow = Date.now();

  if (dateNow - filters.createdAt < TWELVE_HOURS) {
    return filters;
  }

  return { ...filters, createdAt: dateNow, ...defaultFilters() };
};

const currentlyDisplayedTasksMessage = (tasksDashboard: TasksDashboardResponse | undefined, currentPage: number) => {
  const { tasks, count } = tasksDashboard || {};
  const start = tasks?.length ? (currentPage - 1) * TASKS_PER_PAGE + 1 : 0;
  const end = tasks?.length ? (currentPage - 1) * TASKS_PER_PAGE + tasks.length : 0;

  return `Displaying tasks ${start} - ${end} of ${count} in total`;
};

const TasksDashboard = () => {
  const [page, setPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<TasksDashboardSorting>(DEFAULT_SORTING);
  const [filters, setFilters] = React.useState<TasksDashboardFilters>(() => restoreFilters());
  useDocumentTitle('Tasks dashboard');
  const queryClient = useQueryClient();

  const {
    data: tasksDashboard,
    refetch: refetchTasks,
    isLoading
  } = useTasks({
    sort_column: sorting.sort_column,
    sort_order: sorting.sort_order,
    agent_ids: filters?.agent_ids,
    kinds: filters?.kinds,
    completed: filters?.completed,
    date_from: filters?.date_from,
    date_to: filters?.date_to,
    priorities: filters?.priorities,
    page
  });

  const { mutate: updatePersonTask } = useUpdatePersonTask(() => {
    refetchTasks();
    queryClient.invalidateQueries({ queryKey: [TASK_REMINDERS_QUERY_KEY] });
  });

  return (
    <Container fitParentWidth>
      <FiltersSubHeader headerText="Tasks Dashboard">
        <TasksFilter
          filters={filters}
          onFilterReset={() => {
            setFilters(defaultFilters());
            localStorage.setItem(TASKS_FILTERS_STORAGE_KEY, JSON.stringify(defaultFilters()));
          }}
          onFilterSubmit={filters => {
            setPage(1);
            setFilters(filters);
            localStorage.setItem(TASKS_FILTERS_STORAGE_KEY, JSON.stringify(filters));
          }}
          message={currentlyDisplayedTasksMessage(tasksDashboard, page)}
        />
      </FiltersSubHeader>
      <Container fitParentWidth mt={spacings.px8} ph={spacings.px8}>
        {isLoading ? (
          <TablePlaceholder testId="tasks-dashboard-loader" />
        ) : (
          <>
            <TasksList
              tasks={tasksDashboard?.tasks}
              refetchTasks={refetchTasks}
              onComplete={task =>
                updatePersonTask({
                  personGid: task.person_gid,
                  taskId: task.id,
                  data: { ...task, completed: !task.completed }
                })
              }
              onSortingChange={(sorting: TasksDashboardSorting) => setSorting(sorting)}
              defaultSorting={sorting}
            />
            <Pagination
              current={page - 1}
              pages={tasksDashboard?.pages}
              onPageChange={({ selected }: { selected: number }) => setPage(selected + 1)}
            />
          </>
        )}
      </Container>
    </Container>
  );
};

export default TasksDashboard;
