import classNames from 'classnames';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Col, Container, Modal as SModal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { dig, verifyPromise } from '../../../utils/object';

const FormModal = (props: any) => {
  const onSubmit = (values: any, { setSubmitting, ...rest }: any) =>
    verifyPromise(props.confirmHandler(values, rest)).catch(() => setSubmitting(false));

  return (
    <SModal
      isOpen={props.open}
      onClosed={props.onClosed}
      toggle={props.cancelHandler}
      size={props.size}
      backdrop={true}
      className={classNames(props.className)}
      returnFocusAfterClose={props.returnFocusAfterClose}
      data-testid={props.testId}
    >
      <ModalHeader tag="h6" toggle={props.cancelHandler}>
        {props.title}
      </ModalHeader>
      <Formik
        innerRef={props.formRef}
        initialValues={props.initialValues}
        validationSchema={props.validationSchema}
        validate={props.validate}
        enableReinitialize={props.enableReinitialize}
        onSubmit={onSubmit}
      >
        {formProps => {
          const formValues = dig(formProps, 'values') || {};
          const showErrors = Object.keys(formProps.touched).length === Object.keys(formValues).length;

          return (
            <Form autoComplete="off">
              <ModalBody className={props.bodyClassNames}>{props.renderForm({ ...formProps, showErrors })}</ModalBody>
              <ModalFooter>
                <Container>
                  <Col sm={12} className="btn-container d-flex">
                    {props.deleteHandler && (
                      <div className="flex-grow-1 flex-shrink-0">
                        <Button onClick={props.deleteHandler} color="danger" className="qa-delete-button">
                          {props.deleteText}
                        </Button>
                      </div>
                    )}
                    <div>
                      <Button
                        onClick={props.cancelHandler}
                        className="cancel-button"
                        data-testid={`${props.testId}-cancel`}
                      >
                        {props.cancelText}
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        className="confirm-button"
                        disabled={formProps.isSubmitting}
                        data-testid={`${props.testId}-submit`}
                      >
                        {props.confirmText}
                      </Button>
                    </div>
                  </Col>
                </Container>
              </ModalFooter>
            </Form>
          );
        }}
      </Formik>
    </SModal>
  );
};

FormModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string,
  cancelText: PropTypes.string,
  cancelHandler: PropTypes.func,
  confirmText: PropTypes.string,
  confirmHandler: PropTypes.func,
  deleteText: PropTypes.string,
  deleteHandler: PropTypes.func,
  className: PropTypes.string,
  initialValues: PropTypes.any,
  validationSchema: PropTypes.any,
  validate: PropTypes.any,
  enableReinitialize: PropTypes.bool,
  renderForm: PropTypes.func.isRequired,
  bodyClassNames: PropTypes.string,
  formRef: PropTypes.func,
  returnFocusAfterClose: PropTypes.bool,
  onClosed: PropTypes.func,
  testId: PropTypes.string
};

FormModal.defaultProps = {
  open: false,
  enableReinitialize: false,
  returnFocusAfterClose: true,
  title: '',
  size: 'lg',
  message: '',
  cancelText: 'Cancel',
  cancelHandler: null,
  confirmText: 'Submit',
  confirmHandler: null,
  deleteText: 'Delete',
  deleteHandler: null,
  color: 'primary',
  className: '',
  isSubmitting: false,
  initialValues: null,
  validationSchema: null,
  validate: null,
  confirmationInProgress: false,
  bodyClassNames: '',
  formRef: () => {},
  onClosed: () => {},
  testId: ''
};

export default FormModal;
