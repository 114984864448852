/** @jsxImportSource @emotion/react */
import './NewTooltip.scss';

import { ClassNames, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import colors from '../../../theme/colors';

interface Props extends TooltipProps {
  innerRef?: any;
  theme?: 'dark' | 'light' | 'custom';
  primaryColor?: (typeof colors)[keyof typeof colors];
  secondaryColor?: (typeof colors)[keyof typeof colors];
  customCss?: SerializedStyles;
}

const Tooltip = ({
  children,
  theme = 'dark',
  effect = 'solid',
  primaryColor = colors.white,
  secondaryColor = colors.black,
  innerRef,
  customCss,
  ...props
}: Props) => (
  <ClassNames>
    {({ css }) => (
      <ReactTooltip
        {...props}
        effect={effect}
        className={
          theme === 'dark'
            ? 'c-tooltip'
            : css`
                background-color: ${primaryColor} !important;
                color: ${secondaryColor} !important;
                border-radius: 0 !important;
                opacity: 1 !important;
                filter: drop-shadow(2px 4px 0px rgba(0, 0, 0, 0.12));
                white-space: pre-wrap;
                z-index: 10000 !important;
                padding: 12px !important;

                &.__react_component_tooltip.place-left::after {
                  border-left: 8px solid ${primaryColor} !important;
                }

                &.__react_component_tooltip.place-left::before {
                  border-left: 8px solid ${primaryColor} !important;
                }

                &.__react_component_tooltip.place-right::after {
                  border-right: 8px solid ${primaryColor} !important;
                }

                &.__react_component_tooltip.place-right::before {
                  border-right: 8px solid ${primaryColor} !important;
                }

                &.__react_component_tooltip.place-top::after {
                  border-top: 8px solid ${primaryColor} !important;
                }

                &.__react_component_tooltip.place-top::before {
                  border-top: 8px solid ${primaryColor} !important;
                }

                &.__react_component_tooltip.place-bottom::after {
                  border-bottom: 8px solid ${primaryColor} !important;
                }

                &.__react_component_tooltip.place-bottom::before {
                  top: -8px !important;
                  margin-left: -8px !important;
                  border-right: 8px solid transparent !important;
                  border-bottom: 8px solid ${primaryColor} !important;
                  border-left: 8px solid transparent !important;
                }

                ${customCss};
              `
        }
        ref={innerRef}
      >
        {children}
      </ReactTooltip>
    )}
  </ClassNames>
);

export default Tooltip;
