/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { components } from 'react-select';
import * as yup from 'yup';

import disconnectedImage from '../../assets/img/disconnected.svg';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import FormModal from '../../components/core/FormModal';
import { InputField, MultiSelectField, SelectField } from '../../components/core/forms/fields';
import { ModalSize } from '../../components/core/Modal';
import Paragraph from '../../components/core/Paragraph';
import Text from '../../components/core/Text';
import { IPerson, IRelatedPerson } from '../../interfaces';
import { IQuickReply, TaskChannel } from '../../interfaces/IQuickReply';
import { Consent, useActiveTCPAConsents } from '../../queries/disclosures/useDisclosure';
import { useSendEmail, useSendSMS } from '../../queries/omni_channel_interactions/useInteractions';
import { useCreateQuickReply } from '../../queries/omni_channel_interactions/useQuickReplies';
import { useTrackProposalSent } from '../../queries/people/proposals/usePersonProposal';
import usePersonRelatedPeople from '../../queries/people/usePersonRelatedPeople';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { phoneFormatter } from '../../utils/formatter';
import parseHtmlString from '../../utils/htmlStringParser';
import { REQUIRED_MESSAGE } from '../../utils/yupRules';

const getReply = ({ quickReplies, channel }: { quickReplies?: (IQuickReply | undefined)[]; channel: TaskChannel }) => {
  return quickReplies?.find(reply => reply?.task_channel === channel);
};

const getToOptions = (
  relatedPeople: IRelatedPerson[] | undefined,
  channel: TaskChannel,
  person: IPerson,
  tcpaConsents: Record<string, Consent[] | undefined> | undefined
) => {
  const toOptions = [person, ...(relatedPeople || [])]
    .filter(contact => {
      if (channel === TaskChannel.Email && contact.email) {
        return contact;
      }
      if (channel === TaskChannel.Sms && contact.phone) {
        return contact;
      }
      return null;
    })
    .map(contact => ({
      key: channel === TaskChannel.Email ? contact.email : contact.phone,
      value: contact.name,
      description: channel === TaskChannel.Email ? contact.email : phoneFormatter(contact.phone),
      isSelected: tcpaConsents?.[contact.gid]?.length ? contact.gid === person.gid : false,
      personGid: contact.gid,
      tcpaConsentObtained: channel === TaskChannel.Sms ? tcpaConsents?.[contact.gid]?.length : true,
      isDisabled: channel === TaskChannel.Sms ? !tcpaConsents?.[contact.gid]?.length : false
    }));

  return toOptions;
};

const PhoneNumberOption = ({ children, data: option, ...rest }: any) => {
  return (
    <components.Option {...rest}>
      <FlexBox columnDirection>
        {children}
        {option.description && (
          <FlexBox gap={spacings.px8}>
            <Text type="tiny" color={rest.isDisabled ? colors.grey60 : colors.black}>
              {option.description}
            </Text>
            {!option.tcpaConsentObtained && (
              <Text color={colors.statusOrange} type="tiny">
                No TCPA Consent obtained
              </Text>
            )}
          </FlexBox>
        )}
      </FlexBox>
      <input
        type="checkbox"
        checked={rest.isSelected}
        disabled={rest.isDisabled}
        readOnly
        css={css`
          height: 20px;
          width: 20px;
          min-height: 20px;
          min-width: 20px;
        `}
      />
    </components.Option>
  );
};

const NewMessage = ({
  cancelHandler,
  person,
  proposalGid,
  proposalUrl,
  leadGid,
  onProposalSent,
  trackProposalSending
}: {
  cancelHandler: () => void;
  person: IPerson;
  proposalGid: string;
  proposalUrl: string;
  leadGid: string;
  onProposalSent: () => void;
  trackProposalSending: boolean;
}) => {
  const { mutateAsync: createQuickReply, isPending: isPendingQuickReply } = useCreateQuickReply();
  const { mutateAsync: trackProposalSent } = useTrackProposalSent();
  const [quickReplies, setQuickReplies] = React.useState<(IQuickReply | undefined)[]>();
  const [initialTo, setInitialTo] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!quickReplies) {
      createQuickReply({
        personGid: person.gid,
        proposalUrl,
        personFirstName: person.first_name
      }).then(data => {
        setQuickReplies(data.quick_replies);
      });
    }
  }, [createQuickReply, person.gid, proposalUrl, person.first_name, quickReplies]);

  const { data: relatedPeople, isPending: isPendingRelatedPeople } = usePersonRelatedPeople(person.gid);
  const { data: tcpaConsents } = useActiveTCPAConsents({
    peopleGids: [person.gid, ...(relatedPeople || []).map(p => p.gid)]
  });

  React.useEffect(() => {
    const toValue = person.email && !relatedPeople?.some(p => p.email === person.email) ? person.email : person.phone;
    setInitialTo(toValue ? [toValue] : []);
  }, [person.email, person.phone, relatedPeople]);

  const { mutateAsync: sendEmail } = useSendEmail();
  const { mutateAsync: sendSms } = useSendSMS();

  return (
    <FormModal
      title="New Message"
      cancelHandler={cancelHandler}
      confirmText="Send"
      size={ModalSize.large}
      confirmHandler={async values => {
        if (values.channel === TaskChannel.Email) {
          await sendEmail({
            to: values.to.filter(Boolean).map(to => ({ address: to })),
            emailSubject: values.subject,
            emailBody: getReply({ quickReplies, channel: values.channel })?.prefilled_content || '',
            personGid: person.gid,
            engagementGid: leadGid
          });
        } else {
          await Promise.all(
            values.to.filter(Boolean).map(to =>
              sendSms({
                to: [{ address: to }],
                smsBody: getReply({ quickReplies, channel: values.channel })?.prefilled_content || '',
                personGid: person.gid,
                engagementGid: leadGid
              })
            )
          );
        }

        trackProposalSending &&
          (await trackProposalSent({ personGid: person.gid, proposalGid }).then(() => onProposalSent()));

        cancelHandler();
      }}
      initialValues={{
        channel: TaskChannel.Email,
        subject: quickReplies?.[0]?.subject || '',
        to: initialTo
      }}
      validationSchema={yup.object().shape({
        channel: yup.string().required(),
        subject: yup.string().required(),
        to: yup.array().min(1, REQUIRED_MESSAGE)
      })}
      renderForm={({ values, setFieldValue }) => {
        const handleChannelChange = (channel: TaskChannel) => {
          const toValue = channel === TaskChannel.Email ? person.email : person.phone;
          if (toValue && !values.to.includes(toValue)) {
            if (channel === TaskChannel.Sms && !tcpaConsents?.[person.gid]?.length) {
              setFieldValue('to', []);
            } else {
              setFieldValue('to', [toValue]);
            }
          } else {
            setFieldValue(
              'to',
              (values.to as string[]).filter(to => to !== toValue)
            );
          }
        };

        return (
          <FlexBox
            columnDirection
            css={css`
              overflow-y: auto;
              max-height: 500px;
              padding: 8px;
            `}
          >
            <FlexBox justifySpaceBetween mb={spacings.px12}>
              <Container
                customCss={css`
                  width: 49%;
                `}
              >
                <MultiSelectField
                  fsMask
                  label="To"
                  id="to"
                  name="to"
                  options={getToOptions(relatedPeople, values.channel, person, tcpaConsents)}
                  disabled={isPendingRelatedPeople}
                  isLoading={isPendingRelatedPeople}
                  required
                  preserveErrorSpace={false}
                  customComponents={
                    values.channel === TaskChannel.Sms
                      ? { Option: PhoneNumberOption as unknown as React.ReactNode }
                      : {}
                  }
                />
              </Container>
              <Container
                customCss={css`
                  width: 49%;
                `}
              >
                <SelectField
                  name="channel"
                  label="Channel"
                  id="channel"
                  options={[
                    { key: TaskChannel.Email, value: 'Email' },
                    { key: TaskChannel.Sms, value: 'SMS' }
                  ]}
                  required
                  preserveErrorSpace={false}
                  onChange={e => {
                    const channel = e.target.value as TaskChannel;
                    setFieldValue('channel', channel);
                    handleChannelChange(channel);
                  }}
                />
              </Container>
            </FlexBox>

            {values.channel === TaskChannel.Email && (
              <InputField name="subject" id="subject" label="Subject" disabled required preserveErrorSpace={false} />
            )}

            {quickReplies && quickReplies.length > 0 ? (
              <Paragraph
                customCss={css`
                  white-space: pre-wrap;
                  overflow: scroll;
                  border-top: 1px solid ${colors.grey10};
                  margin-top: ${spacings.px16}px;
                  padding-top: ${spacings.px24}px;
                `}
              >
                {parseHtmlString(
                  getReply({ quickReplies, channel: values.channel })?.prefilled_content.replace(/<p><br><\/p>/g, '') ||
                    '',
                  () => {},
                  true
                )}
              </Paragraph>
            ) : (
              <FlexBox columnDirection gap={spacings.px24} alignItemsCenter>
                {!isPendingQuickReply && (
                  <>
                    <img src={disconnectedImage} alt="Transfer" width={460} />
                    <Text bold>Oops! That’s on Us</Text>
                    <Text
                      customCss={css`
                        width: 600px;
                        text-align: center;
                      `}
                    >
                      We couldn’t load the information because we couldn’t recognize your details in the telephony
                      system. It looks like there might be an issue with your contact information.
                    </Text>
                  </>
                )}
              </FlexBox>
            )}
          </FlexBox>
        );
      }}
    />
  );
};

export default NewMessage;
