import './styles.scss';

import PropTypes from 'prop-types';
import React from 'react';

const Step = ({ children, ...props }: any) => (
  <React.Fragment>{typeof children === 'function' ? children(props) : children}</React.Fragment>
);

Step.displayName = 'Step';

Step.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType]).isRequired,
  onStepForward: PropTypes.func,
  onStepBack: PropTypes.func,
  skipIf: PropTypes.func
};

Step.defaultProps = {
  name: '',
  onStepForward: () => {},
  onStepBack: () => {},
  skipIf: null
};

export default Step;
