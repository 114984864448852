import React from 'react';

import openInNewIcon from '../../../assets/new_icons/open-in-new.svg';
import { DropDownMenu } from '../../../components/common';
import { Configuration } from '../../../constants';
import usePersonAmsId from '../../../queries/people/usePersonAmsId';

const PersonExternalLinks = ({ personGid }: { personGid: string }) => {
  const { data: amsId } = usePersonAmsId(personGid);

  const customerExternalLinks = [
    {
      icon: openInNewIcon,
      label: 'Origin',
      action: () => window.open(`${Configuration.originAdminUrl}people/${personGid}`, '_blank')
    },
    {
      icon: openInNewIcon,
      label: 'AMS 360',
      action: () => window.open(`${Configuration.ams360AdminUrl}${amsId}`, '_blank'),
      disabled: !Configuration.ams360AdminUrl || !amsId
    }
  ];

  return <DropDownMenu items={customerExternalLinks} wrapperTestId="external-links" />;
};

export default PersonExternalLinks;
