/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import HomeLinks from '../../components/common/HomeLinks';
import Container from '../../components/core/Container';
import CopyableAddress from '../../components/core/CopyableAddress';
import FlexBox from '../../components/core/FlexBox';
import FormLoader from '../../components/core/FormLoader';
import Heading from '../../components/core/Heading';
import Tag from '../../components/core/Tag';
import Text from '../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IDocument, IMaticPolicy, IOpportunity } from '../../interfaces';
import { isHomeOpportunity, isVehicleOpportunity } from '../../interfaces/IOpportunity';
import { findPolicyTypeLabel } from '../../interfaces/IPolicyType';
import { LEAD_OPPORTUNITIES_QUERY_KEY } from '../../queries/leads/opportunities/useLeadOpportunities';
import usePersonDocuments from '../../queries/people/person_documents/usePersonDocuments';
import usePersonMaticPolicies from '../../queries/people/person_policies/usePersonMaticPolicies';
import analytics from '../../services/analytics';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { DataCollectionStepper } from '../GuidedDataCollection/_hooks/useDataCollectionStepper';
import CreateHome from './_components/CreateHome';
import CreateVehicle from './_components/CreateVehicle';
import useFinalizeStepper from './_hooks/useFinalizeStepper';
import AutoPolicyForm, { AutoValues } from './AutoPolicyForm';
import DefaultPolicyForm, { DefaultPolicyValues } from './DefaultPolicyForm';
import HomePolicyForm, { HomeValues } from './HomePolicyForm';
import SkippedAssetForm from './SkippedAssetForm';

const EditorHeading = ({
  opportunity,
  isDataEditingForbidden
}: {
  opportunity: IOpportunity;
  isDataEditingForbidden: boolean;
}) => {
  const isHome = isHomeOpportunity(opportunity);
  const isAuto = isVehicleOpportunity(opportunity);
  const { personGid } = useGuidedSellingExperienceContext();

  return (
    <FlexBox columnDirection gap={spacings.px24}>
      <FlexBox gap={spacings.px8} alignItemsCenter>
        <Heading>{findPolicyTypeLabel(opportunity.policy_type)}</Heading>
        {opportunity.primary && (
          <Tag
            label="Primary opportunity"
            transparent
            backgroundColor={colors.violet}
            textColor={colors.violet}
            mt={spacings.px4}
          />
        )}
        {isHome && !isDataEditingForbidden && opportunity.assets?.length === 0 && <CreateHome />}
        {isAuto && !isDataEditingForbidden && opportunity.is_data_collection_enabled && (
          <CreateVehicle opportunity={opportunity} />
        )}
      </FlexBox>
      {isHome && opportunity.assets && opportunity.assets[0] && personGid && (
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <Text bold>{opportunity.title}</Text>
          <CopyableAddress address={opportunity.assets[0].address} />
          <HomeLinks personGid={personGid} homeGid={opportunity.assets[0].gid} />
        </FlexBox>
      )}
    </FlexBox>
  );
};

const FormForOpportunity = (props: {
  opportunity: IOpportunity;
  policy?: IMaticPolicy;
  onSubmit: (values: HomeValues | AutoValues | DefaultPolicyValues | Record<string, never>) => void;
  children?: React.ReactNode;
  documents: IDocument[];
  refetchPolicies: ReturnType<typeof usePersonMaticPolicies>['refetch'];
  isDataEditingForbidden: boolean;
  showPolicyDisclosure: boolean;
  dataCollectionStepper: DataCollectionStepper;
}) => {
  if (!props.opportunity.is_data_collection_enabled) {
    return <SkippedAssetForm {...props} opportunity={props.opportunity} />;
  }

  if (isHomeOpportunity(props.opportunity)) {
    // ts being dumb here
    return <HomePolicyForm {...props} opportunity={props.opportunity} />;
  }

  if (isVehicleOpportunity(props.opportunity)) {
    return <AutoPolicyForm {...props} opportunity={props.opportunity} />;
  }

  return <DefaultPolicyForm {...props} />;
};

type FinalizeStepperReturnType = ReturnType<typeof useFinalizeStepper>;
type IPolicyEditor = FinalizeStepperReturnType & {
  activeOpportunity: NonNullable<FinalizeStepperReturnType['activeOpportunity']>;
  isDataEditingForbidden: boolean;
  showPolicyDisclosure: boolean;
  dataCollectionStepper: DataCollectionStepper;
};

const PolicyEditor = ({
  activeOpportunity: opportunity,
  goToNext,
  isLastStep,
  isDataEditingForbidden,
  showPolicyDisclosure,
  dataCollectionStepper
}: IPolicyEditor): JSX.Element => {
  const { lead, personGid } = useGuidedSellingExperienceContext();
  const queryClient = useQueryClient();

  const {
    data: policies,
    isFetchedAfterMount: isPoliciesFetched,
    refetch: refetchPolicies
  } = usePersonMaticPolicies({
    personGid: personGid!,
    filters: { policy_types: [opportunity.policy_type], lead_id: lead!.id }
  });

  const { data: documents = [] } = usePersonDocuments({ personGid });

  // TODO: replace this by back-end function that provides policy that is linked to a opportunity, or provides
  // matching policy.
  const firstPolicy = policies?.find(policy => {
    const policyAssetsGids = policy.assets?.map(asset => asset.gid);
    return policyAssetsGids?.every(gid => opportunity.assets?.some(a => a.gid === gid));
  });

  return (
    <>
      {!isPoliciesFetched && (
        <Container pt={spacings.px24} pl={spacings.px24}>
          <FormLoader />
        </Container>
      )}
      {isPoliciesFetched && (
        <Container
          fitParentHeight
          css={css`
            overflow: auto;
          `}
        >
          <FormForOpportunity
            isDataEditingForbidden={isDataEditingForbidden}
            showPolicyDisclosure={showPolicyDisclosure}
            documents={documents}
            opportunity={opportunity}
            policy={firstPolicy}
            onSubmit={values => {
              if (values.status === 'sold') {
                analytics.track(`Policy ${firstPolicy ? 'updated' : 'sold'}`, {
                  with_uploaded_files: !!values.filesToUpload.length
                });
              }
              queryClient.invalidateQueries({ queryKey: [LEAD_OPPORTUNITIES_QUERY_KEY] });
              !isLastStep && goToNext();
            }}
            refetchPolicies={refetchPolicies}
            dataCollectionStepper={dataCollectionStepper}
          >
            <EditorHeading opportunity={opportunity} isDataEditingForbidden={isDataEditingForbidden} />
          </FormForOpportunity>
        </Container>
      )}
    </>
  );
};

export default PolicyEditor;
