import { Popper, PopperProps } from '@mui/material';
import * as React from 'react';

import useOnClickOutside from './useOnClickOutside';

const usePopper = (customPopperProps?: Partial<PopperProps>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const triggerPopper = React.useCallback(
    ({ target, currentTarget }: { target: EventTarget; currentTarget: HTMLElement | null }) => {
      if (target instanceof Element) {
        setAnchorEl(currentAnchorEl => (currentAnchorEl ? null : (target as HTMLElement)));
      } else {
        setAnchorEl(currentAnchorEl => (currentAnchorEl ? null : currentTarget));
      }
    },
    []
  );

  const elementRef = React.useRef<HTMLDivElement>(null);
  const clickOutsideHandler = React.useCallback(() => setAnchorEl(null), []);
  useOnClickOutside(elementRef, clickOutsideHandler);

  const popperId = React.useId();

  const open = Boolean(anchorEl);

  const popperProps = {
    id: popperId,
    open,
    anchorEl,
    disablePortal: true,
    modifiers: [
      {
        name: 'preventOverflow',
        enabled: true,
        options: {
          altAxis: true,
          altBoundary: true,
          tether: true,
          rootBoundary: 'document',
          padding: 8
        }
      }
    ],
    style: { zIndex: 9999 },
    ...customPopperProps
  };

  return {
    setAnchorEl,
    anchorEl,
    triggerPopper,
    open,
    popperId: open ? popperId : undefined,
    popperProps,
    PopperComponent: Popper,
    elementRef
  };
};
export default usePopper;
