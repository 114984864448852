import './NewPagination.scss';

import React from 'react';

const AVAILABLE_BTN_COLOR = '#4D4C4B';
const UNAVAILABLE_BTN_COLOR = '#E0DDDC';

interface PageData {
  currentPage: number;
  totalPages: number;
  pageLimit: number;
  totalRecords: number;
}
interface Props {
  pageLimit: number;
  totalRecords: number;
  currentPage: number;
  onPageChanged: (data: PageData) => void;
}

const NewPagination: React.FC<Props> = ({ pageLimit, totalRecords, currentPage, onPageChanged }) => {
  if (!pageLimit || !totalRecords || !currentPage || !onPageChanged) {
    return null;
  }

  const totalPages = Math.ceil(totalRecords / pageLimit);

  const pageLabel = (page: number) => {
    const startRecord = (page - 1) * pageLimit;
    const endRecord = page * pageLimit;

    const fromRecord = Math.max(startRecord, 1);
    const toRecord = Math.min(endRecord, totalRecords);

    return `${fromRecord} - ${toRecord} of ${totalRecords}`;
  };

  const gotoPage = (currentPage: number) => {
    const paginationData = {
      currentPage,
      totalPages,
      pageLimit,
      totalRecords
    };

    onPageChanged(paginationData);
    window.scrollTo(0, 0);
  };

  const backDisabled = currentPage <= 1;
  const forwardDisabled = currentPage >= totalPages;

  if (totalRecords <= pageLimit || totalPages === 1) {
    return null;
  }

  return (
    <nav>
      <ul className="new-pagination">
        <li key="prev" className="page-item">
          <button
            className="pagination-button"
            aria-label="Previous"
            onClick={() => gotoPage(currentPage - 1)}
            disabled={backDisabled}
          >
            <span aria-hidden="true">
              <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.33325 15.6667L1.66658 9.00002L8.33325 2.33335"
                  stroke={backDisabled ? UNAVAILABLE_BTN_COLOR : AVAILABLE_BTN_COLOR}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </li>

        <li key={currentPage} className="page-item">
          <span className="pagination-label">{pageLabel(currentPage)}</span>
        </li>

        <li key="next" className="page-item">
          <button
            className="pagination-button"
            aria-label="Next"
            onClick={() => gotoPage(currentPage + 1)}
            disabled={forwardDisabled}
          >
            <span aria-hidden="true">
              <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.66675 2.33331L8.33342 8.99998L1.66675 15.6666"
                  stroke={forwardDisabled ? UNAVAILABLE_BTN_COLOR : AVAILABLE_BTN_COLOR}
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default NewPagination;
