/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import FlexBox from '../../../../components/core/FlexBox';
import Heading from '../../../../components/core/Heading';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { Spacings, spacings } from '../../../../theme/variables';

const TabSkeleton = (spacingsProps: Spacings) => (
  <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
    <FlexBox columnDirection gap={spacings.px16} {...spacingsProps}>
      {[1, 2, 3].map(index => (
        <FlexBox key={index} columnDirection>
          <Heading
            type="h4"
            customCss={css`
              width: 100%;
            `}
          >
            <Skeleton />
          </Heading>
          <Text
            pr={spacings.px28}
            customCss={css`
              width: 100%;
            `}
          >
            <Skeleton count={2} />
          </Text>
        </FlexBox>
      ))}
    </FlexBox>
  </SkeletonTheme>
);

export default TabSkeleton;
