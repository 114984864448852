import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import api from '../../services/api';

export const getPersonId = (personGid: string | undefined): Promise<{ id: number }> =>
  api.get(`/api/frontend/people/${personGid}/id`);

const usePersonId = (personGid: string | undefined, options?: UseQueryOptions<{ id: number }, any, any, any>) =>
  useQuery({
    queryKey: ['person_id', personGid],
    queryFn: personGid ? () => getPersonId(personGid) : skipToken,
    ...options,
    select: data => data.id
  });

export default usePersonId;
