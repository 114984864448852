import { css } from '@emotion/react';
import * as React from 'react';
import { PatternFormat } from 'react-number-format';

import usePopper from '../../../../hooks/usePopper';
import {
  dateFormatter,
  DISPLAY_DATE_FORMAT,
  INTERNAL_DATE_FORMAT,
  INTERNAL_DATE_FORMAT_REGEXP
} from '../../../../utils/formatter';
import Calendar from '../../Calendar/Calendar';
import BaseInput, { InputProps } from '../Base';

export interface DateInputProps extends InputProps {
  onDayChange: (value: Date | string) => void;
  inputProps?: any;
  value?: string;
}

const getValue = (value?: string) => {
  if (!value) {
    return '';
  }

  let valueDate;
  if (value.match(INTERNAL_DATE_FORMAT_REGEXP)) {
    valueDate = new Date(dateFormatter(value, DISPLAY_DATE_FORMAT));
  } else {
    valueDate = new Date(value);
  }

  if (isNaN(valueDate.getTime())) {
    return '';
  }

  return valueDate;
};

const formatDisplayValue = (value?: string) => {
  if (!value) {
    return '';
  }

  if (value.trimEnd().length === 10) {
    return dateFormatter(value, DISPLAY_DATE_FORMAT);
  }

  return value;
};

/**
 * Everything you have to know to understand this file
 * Set Chrome locale to Mountain Time
 * log new Date('2020-10-10')
 * Fri Oct 09 2020 17:00:00 GMT-0700 (Pacific Daylight Time)
 * log new Date('10/10/2020')
 * Sat Oct 10 2020 00:00:00 GMT-0700 (Pacific Daylight Time)
 */

const DateInput = ({
  value,
  placeholder,
  onDayChange,
  inputProps,
  inline,
  testId,
  inputSize,
  ...rest
}: DateInputProps) => {
  const deferredCalendarDateValue = React.useDeferredValue(getValue(value) || null);
  const { setAnchorEl, PopperComponent, popperProps, elementRef } = usePopper();

  return (
    <div ref={elementRef}>
      <PatternFormat
        {...{ ...inputProps, inline, inputSize }}
        type="text"
        customInput={BaseInput}
        format="##/##/####"
        value={formatDisplayValue(value)}
        data-testid={testId}
        onChange={(e: React.ChangeEvent<InputProps>) => {
          const { value } = e.target;

          if (value && value.toString().trimEnd().length === 10) {
            onDayChange(dateFormatter(value, INTERNAL_DATE_FORMAT));
          } else {
            onDayChange(value as string);
          }
        }}
        placeholder={placeholder || DISPLAY_DATE_FORMAT}
        onFocus={e => {
          setAnchorEl(e.currentTarget || e.target);
        }}
        onBlur={e => {
          // If the focus is not on the calendar, close the calendar
          if (!elementRef.current?.contains(e.relatedTarget)) {
            setAnchorEl(null);
          }
        }}
      />

      <PopperComponent {...popperProps}>
        <Calendar
          {...rest}
          fsMask={inputProps.fsMask}
          onDateChange={date => {
            if (Array.isArray(date)) {
              return;
            }

            onDayChange(dateFormatter(date, INTERNAL_DATE_FORMAT));
            setAnchorEl(null);
          }}
          size="small"
          date={deferredCalendarDateValue}
          customCss={css`
            padding: 8px;
          `}
        />
      </PopperComponent>
    </div>
  );
};

export default DateInput;
