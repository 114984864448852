import { useQuery } from '@tanstack/react-query';

import { IUser } from '../../interfaces/IUser';
import api from '../../services/api';

const getAssignee = (agentId: number) => {
  return api.get(`/api/frontend/assignees/${agentId}`);
};

const useFindAssignee = (id: number) =>
  useQuery({ queryKey: ['agent', id], queryFn: (): Promise<IUser> => getAssignee(id) });

export default useFindAssignee;
