import React from 'react';

import { EmailIcon, PhoneIcon, PinIcon, SettingsIcon } from '../../components/core/icons';
import { NoteKind } from '../../interfaces/INote';

const NoteKindIcon = ({ kind }: { kind: NoteKind }) => {
  switch (kind) {
    case NoteKind.Memo:
    case NoteKind.Partner:
      return <PinIcon />;
    case NoteKind.Email:
      return <EmailIcon />;
    case NoteKind.Phone:
      return <PhoneIcon />;
    case NoteKind.System:
      return <SettingsIcon />;
    default:
      return <React.Fragment />;
  }
};

export default NoteKindIcon;
