import moment from 'moment';

import alert from '../../../components/core/Alert';
import { IMaticPolicy, IOpportunity, Pretty } from '../../../interfaces';
import { FileToUpload, IDocument, PolicyFileToProcess } from '../../../interfaces/IDocument';
import { OpportunityStatus } from '../../../interfaces/IOpportunity';
import { PolicyType, possiblePolicyTerms } from '../../../interfaces/IPolicyType';
import { processFiles } from '../../../queries/people/person_policies/useUpdatePolicy';
import { ICalendarData } from '../../../queries/scheduled_calls/useCalendar';
import { errorItems } from '../../../utils/alertFromApiResponse';
import { AutoValues } from '../AutoPolicyForm';
import { DefaultPolicyValues } from '../DefaultPolicyForm';
import { HomeValues } from '../HomePolicyForm';

const getPeriodLabel = (period: number) => {
  if (period % 12) {
    return `${period} months`;
  }

  const years = period / 12;

  return `${years} year${years === 1 ? '' : 's'}`;
};
export const getPeriodOptions = (policyType: PolicyType) => {
  const terms = possiblePolicyTerms(policyType).map(period => ({
    key: period as number | string,
    value: getPeriodLabel(period)
  }));
  terms.push({ key: 'custom', value: 'Custom' });

  return terms;
};

export const getPredefinedPeriod = (policy: { effective_date: string; expiration_date: string }) => {
  const calculatedPeriod = moment(policy.expiration_date).diff(moment(policy.effective_date), 'months', true);

  if (calculatedPeriod % 6 === 0) {
    return calculatedPeriod;
  }

  return 'custom';
};

export const STATUS_OPTIONS = [
  { id: 'no', value: 'lost', label: 'No' },
  { id: 'yes', value: 'sold', label: 'Yes' }
] as const;

export const NEW_CLOSING_OPTIONS = [
  { key: true, value: 'Yes' },
  { key: false, value: 'No' }
] as const;

export const uploadDocuments = async ({
  personGid,
  policyId,
  files,
  filesToUpload,
  onSuccess
}: {
  personGid: string;
  policyId: number;
  files: (IDocument | PolicyFileToProcess)[];
  filesToUpload: FileToUpload[];
  onSuccess: () => void;
}) => {
  await processFiles({
    person_gid: personGid,
    policy_id: policyId,
    files: [...files, ...filesToUpload] as any
  })
    .then(() => {
      onSuccess();
    })
    .catch(error => {
      if (error.response) {
        const data = error.response.data;

        if (data.status === 'error') {
          const message = data.message;

          message instanceof Array
            ? alert({ title: 'Documents upload failed', items: errorItems(message) }).error()
            : alert({ title: 'Documents upload failed', message }).error();
        } else if (data.status === 'fail') {
          const message = errorItems(data.data);
          alert({ title: 'Documents upload failed', items: message }).error();
        } else if (data.errors instanceof Array) {
          alert({ title: 'Documents upload failed', items: data.errors }).error();
        }
      } else if (error.request) {
        alert({ title: 'Documents upload failed', message: 'Please check your internet connection' }).error();
      } else {
        alert({ title: 'Documents upload failed', message: error.message }).error();
      }
    });
};

export const buildLocalStorageKey = (opportunityId: IOpportunity['id'] | string) => `policy-${opportunityId}`;

export const retrievePolicyFromLocalStorage = (opportunityId: IOpportunity['id']) => {
  const key = buildLocalStorageKey(opportunityId);
  const stringifiedPolicy = localStorage.getItem(key);

  if (stringifiedPolicy) {
    const parsedPolicy = JSON.parse(stringifiedPolicy) as Pretty<
      (HomeValues & AutoValues & DefaultPolicyValues) & {
        timestamp: number;
      }
    > | null;

    if (parsedPolicy) {
      const timestamp = parsedPolicy.timestamp || 0;
      // 5 minutes = 5 * 60 * 1000 = 300000;
      if (Date.now() - timestamp < 300000) {
        const { timestamp, ...rest } = parsedPolicy;

        return rest;
      } else {
        localStorage.removeItem(key);
      }
    }
  }

  return null;
};

export const calculateDueTime = (calendarData: ICalendarData | undefined) => {
  if (!calendarData) {
    return '';
  }

  const lastAvailableSpot = calendarData.day.spots.findLast(spot => spot.status === 'available');
  const firstUnavailableSpot = calendarData.day.spots.find(spot => spot.status === 'unavailable');

  return (
    lastAvailableSpot?.start_time ||
    firstUnavailableSpot?.start_time ||
    moment().endOf('day').subtract(30, 'minutes').toISOString()
  );
};

export const getOpportunityStatusInitialValue = ({
  opportunity,
  policy,
  cachedStatus
}: {
  opportunity: IOpportunity;
  policy: IMaticPolicy | undefined;
  cachedStatus: 'lost' | 'sold' | undefined;
}) => {
  if (policy) {
    return OpportunityStatus.Sold as (typeof STATUS_OPTIONS)[number]['value'];
  }

  return (
    cachedStatus ||
    (([OpportunityStatus.Lost, OpportunityStatus.Sold].includes(opportunity.status)
      ? opportunity.status
      : '') as (typeof STATUS_OPTIONS)[number]['value'])
  );
};
