/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import CollapsingContainer from '../../../../components/core/CollapsingContainer';
import FlexBox from '../../../../components/core/FlexBox';
import { QuoteIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';

const Quote = ({ text }: { text: string }) => (
  <Paragraph
    m={spacings.px4}
    css={css`
      position: relative;
      display: flex;
    `}
  >
    <QuoteIcon
      color={colors.grey30}
      css={css`
        margin: 8px 8px 0 0;
        min-width: 20px;
      `}
    />
    {text}
  </Paragraph>
);

const QuotesObjections = () => (
  <>
    <Paragraph bold>Can you email it to me?</Paragraph>
    <Quote text="Are you by your computer now so we can review it together?" />
    <Quote text="Do you have any questions about the coverages?" />
    <Quote text="Most people don't understand the benefits of insurance, so I like to be there to explain" />
    <Quote text="What would an email show you that we haven't covered?" />

    <Paragraph bold>Price objection</Paragraph>
    <Quote text="Do you have a renewal upcoming soon?" />
    <Quote text="Is the price the only thing stopping you from purchasing?" />
    <Quote text="This is only [$] per month for a better policy" />
    <Quote text="We are offering you better coverages" />
    <Quote text="We offer excellent customer service" />
    <Quote text="We shop your insurance every year" />

    <Paragraph bold>I need to check with my spouse</Paragraph>
    <Quote text="Do you agree that this is a better policy for you and your family?" />
    <Quote
      text="Do you think your spouse would mind if you came back with a better insurance policy
that better protects your family"
    />
    <Quote text="Is your spouse around right now?" />
    <Quote text="What are some questions you think your spouse will have?" />
  </>
);

const ObjectionsGuideTab = ({ panel }: { panel: 'rapport' | 'quotes' }): JSX.Element => {
  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Help</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        {panel === 'quotes' ? (
          <FlexBox gap={spacings.px12} columnDirection>
            <CollapsingContainer title="Pitching ancillaries">
              <>
                <Paragraph bold>Pet insurance</Paragraph>
                <Quote
                  text="Do you have pet insurance?
              We have a good carrier Embrace that covers dogs and cats.
              The process is really quick and they are a reliable carrier, they paid 92% of our claims in 2021.
              I can quickly check for you some good options, you can consider later."
                />
              </>
            </CollapsingContainer>
            <CollapsingContainer title="Objections" openedByDefault>
              <QuotesObjections />
            </CollapsingContainer>
          </FlexBox>
        ) : (
          <>
            <Paragraph bold>Now is not a good time</Paragraph>
            <Quote text="I'm seeing some really good quotes for you here, it should only be a few minute" />
            <Quote text="We're almost done and this will only take a few minutes" />
            <Quote
              text="When my customers need to think about it they have questions about price, effective date or
              coverage. Which can I help you with?"
            />
            <Quote text="[Customer Name] I'm happy to set a follow up appointment if you're considering purchasing" />

            <Paragraph bold>Not interested</Paragraph>
            <Quote text="On average we save our customers 25% per year on their home insurance" />
            <Quote text="We're partnered with over [40] carriers, I'm sure we can find you a great fit" />
            <Quote text="We're partnered with your mortgage lender" />
            <Quote text="When's the last time you shopped your auto and home insurance?" />

            <Paragraph bold>Price objection</Paragraph>
            <Quote text="Do you have a renewal upcoming soon?" />
            <Quote text="We are offering you better coverages" />
            <Quote text="We offer excellent customer service" />
            <Quote text="We shop your insurance every year" />
          </>
        )}
      </VerticalTabContent>
    </>
  );
};

export default ObjectionsGuideTab;
