import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

interface Premium {
  line_of_business_acord_type: string;
  full_term_premium?: number;
  premium?: number;
  written_premium?: number;
}

const usePolicyTransactionPremiums = (
  policyId: number,
  transactionId?: number,
  options?: UseQueryOptions<{ premiums: Premium[] }, any, any, any>
) =>
  useQuery({
    queryKey: ['policy_transaction_premiums', policyId, transactionId],
    queryFn: transactionId
      ? (): Promise<{ premiums: Premium[] }> =>
          api.get(
            `/api/frontend/policies/${policyId}/premiums?${toQueryParams({
              transaction_id: transactionId
            })}`
          )
      : skipToken,
    ...options,
    select: data => data.premiums
  });

export default usePolicyTransactionPremiums;
