import * as R from 'ramda';
import * as React from 'react';

function useDeepEqualMemo<T>(value: T) {
  const ref = React.useRef(value);

  if (!R.equals(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
}

export default useDeepEqualMemo;
