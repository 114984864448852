import React from 'react';

import useViolationTypes from '../../../../../../queries/violation_types/useViolationTypes';
import ViolationField from '../ViolationField';

const ViolationWithOptions = (props: any) => {
  const { state } = props.formValue;

  const { data: violationTypes, isLoading } = useViolationTypes({ state });

  return <ViolationField {...props} loading={isLoading} options={violationTypes} />;
};

export default ViolationWithOptions;
