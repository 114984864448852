import { skipToken, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { IRelatedPerson } from '../../../interfaces/IPerson';
import api from '../../../services/api';

export const DATA_COLLECTION_DRIVERS_QUERY_KEY = 'data_collection_drivers';

// Mutation ===============================================================================

const updateDataCollectionDrivers = ({
  leadId,
  peopleGids
}: {
  leadId: number | string;
  peopleGids: string[];
}): Promise<{ related_people: IRelatedPerson[] }> =>
  api.put(`/api/frontend/leads/${leadId}/data_collection/drivers`, {
    body: { people_gids: peopleGids }
  });

export const useUpdateDataCollectionDrivers = (onSuccess?: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateDataCollectionDrivers,
    onSuccess: (result, variables) => {
      queryClient.setQueryData([DATA_COLLECTION_DRIVERS_QUERY_KEY, variables.leadId.toString()], () => result);

      onSuccess?.();
    }
  });
};

// Readers ===============================================================================

const getDataCollectionDrivers = ({
  leadId
}: {
  leadId: number | string;
}): Promise<{ related_people: IRelatedPerson[] }> => api.get(`/api/frontend/leads/${leadId}/data_collection/drivers`);

const useDataCollectionDrivers = ({ leadId }: { leadId?: number | string }) =>
  useQuery({
    /* eslint-disable-next-line @tanstack/query/exhaustive-deps  */
    queryKey: [DATA_COLLECTION_DRIVERS_QUERY_KEY, leadId?.toString()],
    queryFn: leadId ? () => getDataCollectionDrivers({ leadId: leadId! }) : skipToken,
    select: data => data.related_people
  });

export default useDataCollectionDrivers;
