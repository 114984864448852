/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import featureFlags from '../../constants/featureFlags';
import { usePopper } from '../../hooks';
import useCreateContactAttempt, { MediaType } from '../../queries/contact_attempts/useCreateContactAttempt';
import { getLastLeadId } from '../../queries/people/useLastLead';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { phoneFormatter } from '../../utils/formatter';
import alert from './Alert';
import ButtonWithoutBorder from './buttons/ButtonWithoutBorder';
import Container from './Container';
import FlexBox from './FlexBox';
import { ArrowDownIcon } from './icons';
import Paragraph from './Paragraph';

interface ITelephonyActions<T extends React.ReactNode> {
  phone?: string | null;
  email?: string | null;
  personGid: string;
  children: T;
}

const buttonCss = css`
  color: ${colors.black};
  padding: ${spacings.px8}px;
  width: 100%;
  text-align: left;
  &:hover {
    background-color: ${colors.grey10};
  }
`;

const TelephonyActions = <T extends React.ReactNode>({ phone, email, personGid, children }: ITelephonyActions<T>) => {
  const { mutate: createContactAttempt } = useCreateContactAttempt({
    onSuccess: () => alert({ message: 'Request successfully passed to Twilio' }).success()
  });

  const { anchorEl, setAnchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    placement: 'bottom-end',
    disablePortal: true
  });

  if ((!phone && !email) || !featureFlags.clickToTwilioEmail) {
    return <>{children}</>;
  }

  return (
    <>
      {children}
      <Container
        innerRef={elementRef}
        customCss={css`
          display: inline-block;
        `}
      >
        <ButtonWithoutBorder
          data-testid={`telephony-${phone ? 'phone' : 'email'}`}
          onClick={e => {
            triggerPopper(e);
            e.stopPropagation();
          }}
          customCss={css`
            color: ${colors.black};
          `}
        >
          <ArrowDownIcon
            css={css`
              ${anchorEl && 'transform: rotate(180deg)'};
              transition: transform 0.2s ease-in-out;
            `}
          />
        </ButtonWithoutBorder>

        <PopperComponent {...popperProps}>
          <FlexBox
            columnDirection
            backgroundColor={colors.white}
            roundBorder
            border
            boxShadow
            alignItemsFlexStart
            customCss={css`
              width: 300px;
            `}
            className="fs-mask"
          >
            {phone && (
              <>
                <ButtonWithoutBorder
                  data-testid="call-button"
                  customCss={buttonCss}
                  onClick={e => {
                    getLastLeadId(personGid).then(({ lead }) => {
                      if (lead) {
                        createContactAttempt({
                          phone,
                          personGid,
                          leadGid: lead.gid,
                          mediaType: MediaType.Call
                        });
                      } else {
                        alert({ message: 'No lead associated with this customer' }).error();
                      }
                    });

                    setAnchorEl(null);
                    e.stopPropagation();
                  }}
                >
                  <Paragraph>Call {phoneFormatter(phone)}</Paragraph>
                </ButtonWithoutBorder>
                <ButtonWithoutBorder
                  data-testid="send-sms-button"
                  customCss={buttonCss}
                  onClick={e => {
                    getLastLeadId(personGid).then(({ lead }) => {
                      if (lead) {
                        createContactAttempt({
                          phone,
                          personGid,
                          leadGid: lead.gid,
                          mediaType: MediaType.SMS
                        });
                      } else {
                        alert({ message: 'No lead associated with this customer' }).error();
                      }
                    });
                    setAnchorEl(null);
                    e.stopPropagation();
                  }}
                >
                  <Paragraph>SMS {phoneFormatter(phone)}</Paragraph>
                </ButtonWithoutBorder>
                <CopyToClipboard
                  text={phone}
                  onCopy={
                    alert({ message: 'Copied to clipboard', options: { position: 'bottom-center', autoClose: 500 } })
                      .success
                  }
                >
                  <ButtonWithoutBorder
                    data-testid="copy-button"
                    customCss={buttonCss}
                    onClick={e => {
                      setAnchorEl(null);
                      e.stopPropagation();
                    }}
                  >
                    <Paragraph>Copy phone number</Paragraph>
                  </ButtonWithoutBorder>
                </CopyToClipboard>
              </>
            )}
            {email && (
              <>
                <ButtonWithoutBorder
                  data-testid="email-button"
                  customCss={buttonCss}
                  onClick={e => {
                    getLastLeadId(personGid).then(({ lead }) => {
                      if (lead) {
                        createContactAttempt({
                          email,
                          personGid,
                          leadGid: lead.gid,
                          mediaType: MediaType.Email
                        });
                      } else {
                        alert({ message: 'No lead associated with this customer' }).error();
                      }
                    });
                    setAnchorEl(null);
                    e.stopPropagation();
                  }}
                >
                  <Paragraph singleLine>Email {email}</Paragraph>
                </ButtonWithoutBorder>
                <CopyToClipboard
                  text={email}
                  onCopy={
                    alert({ message: 'Copied to clipboard', options: { position: 'bottom-center', autoClose: 500 } })
                      .success
                  }
                >
                  <ButtonWithoutBorder
                    data-testid="copy-button"
                    customCss={buttonCss}
                    onClick={e => {
                      setAnchorEl(null);
                      e.stopPropagation();
                    }}
                  >
                    <Paragraph singleLine>Copy email address</Paragraph>
                  </ButtonWithoutBorder>
                </CopyToClipboard>
              </>
            )}
          </FlexBox>
        </PopperComponent>
      </Container>
    </>
  );
};

export default TelephonyActions;
