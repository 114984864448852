import * as Sentry from '@sentry/react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Paragraph from '../../../../components/core/Paragraph';
import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { InsurableInterest } from '../../../../interfaces/IPolicyType';
import useDataCollection from '../../../../queries/leads/data_collection/useDataCollection';
import usePersonOpportunities from '../../../../queries/people/person_opportunities/usePersonOpportunities';
import { spacings } from '../../../../theme/variables';
import VerticalTabContent from '../../_components/VerticalTabs/VerticalTabContent';
import VerticalTabHeader from '../../_components/VerticalTabs/VerticalTabHeader';
import TabSkeleton from '../_components/TabSkeleton';
import Notes from '../NotesTab/Notes';
import AutoFirstLeadBrandInfo from './AutoFirstLeadBrandInfo';
import ContextInfo from './ContextInfo';
import CurrentPolicies from './CurrentPolicies';
import HomeFirstLeadBrandInfo from './HomeFirstLeadBrandInfo';
import OpportunitiesInfo from './OpportunitiesInfo';
import PersonInfo from './PersonInfo';

const CatchUpTab = () => {
  const { personGid, callLogId, sourceDimensions, lead, leadGid, candidateGid } = useGuidedSellingExperienceContext();
  const { data: opportunities, isFetching: isFetchingOpportunities } = usePersonOpportunities({
    personGid,
    leadGid,
    candidateGid
  });
  const { data: dataCollection, isFetching: isFetchingDataCollection } = useDataCollection(lead?.id);

  const primaryOpportunity = opportunities?.find(({ primary }) => primary);

  const isFetching = isFetchingOpportunities || isFetchingDataCollection;

  return (
    <>
      <VerticalTabHeader>
        <Paragraph type="large">Catch up</Paragraph>
      </VerticalTabHeader>
      <VerticalTabContent>
        {isFetching ? (
          <TabSkeleton />
        ) : (
          <FlexBox data-testid="basic-info" gap={spacings.px24} columnDirection>
            {(personGid || callLogId) && (
              <Container data-testid="person">
                <PersonInfo />
              </Container>
            )}
            {personGid && (
              <OpportunitiesInfo personGid={personGid} dataCollection={dataCollection} opportunities={opportunities} />
            )}
            {primaryOpportunity?.insurable_interest !== InsurableInterest.Vehicle && sourceDimensions?.brand && (
              <HomeFirstLeadBrandInfo brandKey={sourceDimensions.brand} />
            )}
            {primaryOpportunity?.insurable_interest === InsurableInterest.Vehicle && (
              <AutoFirstLeadBrandInfo brandKey={sourceDimensions?.brand} primaryOpportunity={primaryOpportunity} />
            )}

            <Sentry.ErrorBoundary
              fallback={() => (
                <>
                  <Paragraph bold>Could not retrieve context info due to unexpected error.</Paragraph>
                </>
              )}
            >
              <ContextInfo />
            </Sentry.ErrorBoundary>

            {personGid && opportunities && (
              <Container data-testid="current-policies">
                <CurrentPolicies personGid={personGid} opportunities={opportunities} />
              </Container>
            )}
            {personGid && (
              <>
                <FlexBox>
                  <Text type="large" bold>
                    Notes
                  </Text>
                </FlexBox>
                <Container data-testid="notes">
                  <Notes />
                </Container>
              </>
            )}
          </FlexBox>
        )}
      </VerticalTabContent>
    </>
  );
};

export default CatchUpTab;
