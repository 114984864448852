import { FC } from 'react';

// eslint-disable-next-line max-len
import { useVehicleProfileAnswersWithVerificationAnswers } from '../_hooks/useVehicleProfileAnswersWithVerificationAnswers';

const VerificationAnswersProvider = ({
  personGid,
  vehicleGids,
  driversGids,
  children
}: {
  children: FC<ReturnType<typeof useVehicleProfileAnswersWithVerificationAnswers>>;
  personGid: string;
  vehicleGids: string[];
  driversGids: string[];
}) => {
  const answersWithVerificationStatus = useVehicleProfileAnswersWithVerificationAnswers({
    personGid,
    vehicleGids,
    driversGids
  });

  return children(answersWithVerificationStatus);
};

export default VerificationAnswersProvider;
