import { useMutation, useQuery } from '@tanstack/react-query';

import { IRelatedPerson, RelationKind } from '../../interfaces/IPerson';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';
import { CreatePersonPayload } from './usePerson';

interface Filter {
  kinds?: RelationKind[];
  related_gids?: string[];
}

export interface RelatedPersonPayload extends CreatePersonPayload {
  kind?: string | null;
  cocustomer?: boolean | null;
}

export const getPersonRelatedPeople = (
  personGid: string,
  filter?: Filter | null
): Promise<{ related_people: IRelatedPerson[] }> => {
  const url = `/api/frontend/people/${personGid}/related_people`;
  const params = toQueryParams(filter);
  return api.get(`${url}?${params}`);
};

export const createRelatedPerson = ({
  sourcePersonGid,
  data
}: {
  sourcePersonGid: string;
  data: RelatedPersonPayload;
}): Promise<{ related_person: IRelatedPerson }> =>
  api.post(`/api/frontend/people/${sourcePersonGid}/related_people`, { body: data });

export const updateRelatedPerson = ({
  sourcePersonGid,
  relatedPersonGid,
  data
}: {
  sourcePersonGid: string;
  relatedPersonGid: string;
  data: RelatedPersonPayload;
}): Promise<{ related_person: IRelatedPerson }> =>
  api.put(`/api/frontend/people/${sourcePersonGid}/related_people/${relatedPersonGid}`, {
    body: data
  });

export const updateRelationKind = ({
  sourcePersonGid,
  relatedPersonGid,
  relationKind,
  cocustomer
}: {
  sourcePersonGid: string;
  relatedPersonGid: string;
  relationKind: string | null;
  cocustomer?: boolean | null;
}): Promise<{ related_person: IRelatedPerson }> =>
  api.put(`/api/frontend/people/${sourcePersonGid}/related_people/${relatedPersonGid}/relation_kind`, {
    body: { kind: relationKind, cocustomer }
  });

const removeRelatedPerson = ({
  sourcePersonGid,
  relatedPersonGid
}: {
  sourcePersonGid: string;
  relatedPersonGid: string;
}): Promise<null> => api.delete(`/api/frontend/people/${sourcePersonGid}/related_people/${relatedPersonGid}`);

export const PERSON_RELATED_PEOPLE_QUERY_KEY = 'person_related_people';

const usePersonRelatedPeople = (personGid: string | undefined, filter: Filter | null = {}) =>
  useQuery({
    queryKey: [PERSON_RELATED_PEOPLE_QUERY_KEY, personGid, filter],
    queryFn: () => getPersonRelatedPeople(personGid as string, filter),
    enabled: !!personGid,
    select: data => data.related_people
  });

export const useCreateRelatedPerson = () => useMutation({ mutationFn: createRelatedPerson });

export const useUpdateRelatedPerson = () => useMutation({ mutationFn: updateRelatedPerson });

export const useRemoveRelatedPerson = () => useMutation({ mutationFn: removeRelatedPerson });

export default usePersonRelatedPeople;
