/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../../../components/common/Tooltip/NewTooltip';
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import { usePopoversQueue } from '../../../../../hooks';
import colors from '../../../../../theme/colors';
import { borderRadius, spacings } from '../../../../../theme/variables';

const TOOLTIP_ID = 'collected-by';

const CollectedBy = ({ message, onConfirm }: { message: string; onConfirm: () => void }) => {
  const tipRef = React.useRef<any>(null);
  const outerRef = React.useRef<any>(null);

  const { active, addPopover, removePopover } = usePopoversQueue();

  React.useEffect(() => {
    if (active === TOOLTIP_ID) {
      outerRef.current && ReactTooltip.show(outerRef.current);
    }
  }, [active]);

  React.useEffect(() => {
    addPopover(TOOLTIP_ID);

    return () => {
      removePopover(TOOLTIP_ID);
    };
  }, [removePopover, addPopover]);

  if (active !== TOOLTIP_ID) {
    return null;
  }

  return (
    <>
      <div data-tip data-for={TOOLTIP_ID} ref={outerRef} />
      <Tooltip
        id={TOOLTIP_ID}
        place="left"
        innerRef={tipRef}
        clickable
        scrollHide={false}
        theme="custom"
        primaryColor={colors.violet}
        secondaryColor={colors.violet}
        customCss={css`
          border-radius: ${borderRadius}px !important;
          z-index: 1000 !important;
        `}
      >
        <Container
          customCss={css`
            max-width: 282px;
          `}
        >
          <Paragraph color={colors.white} mb={spacings.px8}>
            {message}
          </Paragraph>
          <FlexBox
            customCss={css`
              justify-content: flex-end;
            `}
          >
            <Button
              data-testid="collected-by-confirm-button"
              onClick={e => {
                tipRef.current.tooltipRef = null;

                ReactTooltip.hide(tipRef.current);
                ReactTooltip.hide(outerRef.current);
                onConfirm();
                removePopover(TOOLTIP_ID);
                e.stopPropagation();
              }}
              customCss={css`
                color: ${colors.white};
                background-color: ${colors.violet};
                border-color: ${colors.white};
              `}
              variant={ButtonVariant.Secondary}
              size={ButtonSize.Small}
            >
              Got it!
            </Button>
          </FlexBox>
        </Container>
      </Tooltip>
    </>
  );
};

export default CollectedBy;
