import classNames from 'classnames';
import React from 'react';

import { Copy } from '../../../../components/common';
import FlexBox from '../../../../components/core/FlexBox';
import TelephonyTooltip from '../../../../components/core/TelephonyTooltip';
import { spacings } from '../../../../theme/variables';
import { phoneFormatter } from '../../../../utils/formatter';

const Phone = ({
  phone,
  personGid,
  textPrefix = '',
  testId = '',
  className = ''
}: {
  phone: string;
  personGid: string;
  textPrefix?: string;
  testId?: string;
  className?: string;
}) => (
  <FlexBox alignItemsCenter gap={spacings.px4}>
    {textPrefix}
    <TelephonyTooltip phone={phone} personGid={personGid}>
      <Copy
        testId={testId}
        className={classNames('fs-mask text-truncate align-middle', className)}
        value={phoneFormatter(phone)}
      />
    </TelephonyTooltip>
  </FlexBox>
);

export default Phone;
