/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import lostImage from '../../assets/img/lost.svg';
import transferImage from '../../assets/img/transfer.svg';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import Heading from '../../components/core/Heading';
import Paragraph from '../../components/core/Paragraph';
import Tag from '../../components/core/Tag';
import { isEndDisposition } from '../../components/DispositionsModals/dispositionsHelper';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { useConstant } from '../../hooks';
import { IDataCollection } from '../../interfaces';
import { IDataCollectionPageType } from '../../interfaces/IDataCollection';
import usePersonAllPolicies from '../../queries/people/person_policies/usePersonAllPolicies';
import colors from '../../theme/colors';
import { smallParagraphCss } from '../../theme/typography';
import { borderRadius, sizes, spacings } from '../../theme/variables';
import { isImageCached } from '../../utils/object';
import { useCurrentDispositionSubscriptionContext } from '../GuidedSellingExperience/_hooks';

const MESSAGES = [
  "🌟 You're a superstar on that call transfer!",
  '💥 Boom! Nailed that handoff like a pro!',
  '🚀 You just rocketed that transfer to success!',
  "💪 You're strong with those smooth transitions!",
  '🎯 Spot on! Another perfect transfer!',
  "🔥 On fire! You're blazing a trail with those calls!",
  '🦄 You make call transfers look magical!',
  '💡 Brilliant move on that last transfer!',
  '🎉 Transfer champion in the house!',
  "⚡️ You're electrifying with those calls!",
  '🏆 Award-winning transfer skills!',
  '🙌 You deserve a round of applause for that one!',
  '🎸 Rocking those transfers every time!',
  '👏 Clapping for your impeccable transfer timing!',
  "💫 You're a shining star in the call transfer galaxy!",
  "🍀 You've got the luck of the transfer charm!",
  '🎈 Pop! That transfer was like a celebration!',
  '🥇 Taking the gold for seamless call transfers!',
  '🤩 Dazzling performance on that call transfer!',
  '🏁 You crossed the finish line perfectly with that one!',
  "🏈 Another perfect pass - you're amazing!",
  '🌪️ You swept that customer right into the right hands!',
  '💼 Deal-maker on the call transfer front!',
  '🕺 Smooth moves on the call transfer dance floor!',
  '✨ Sparkling transfer!',
  "👩‍🎤 You're the maestro of call transfers!",
  '🧗 You climbed that call transfer mountain like a champ!',
  '🎱 You pocketed that transfer with precision!',
  '🚤 Speeding through those transfers with finesse!',
  '🛠️ Building bridges with every transfer!',
  '📈 Upping the game with each call transfer!',
  '✋ High five for a smooth handoff!',
  '🚦 Green light means go, and you went above and beyond!',
  '⏳ Timed to perfection, like an expertly flipped hourglass!',
  "🌟 You're leading the way in star-quality transfers!",
  '🍾 Popping bottles for that excellent call transfer!',
  '🐝 Busy as a bee and sweet with the transfers!',
  "🎼 You're composing symphonies with those smooth transitions!",
  "🤘 You're the reason we're a great team!",
  '🍪 Cookie-cutter perfect transfer every time!',
  '🦸‍♀️ Way to go, call-transfer hero!',
  '🎨 Painting success with every call canvas!',
  '🏍️ Revving up the engines with super-fast transfers!',
  "🌐 You're the world-class link in our call chain!",
  '🛳️ Smooth sailing on the transfer seas!',
  '🥂 Cheers to you and your flawless transferring!',
  '📝 A+ for that transfer report!',
  '🌞 You bring sunshine to every transfer!',
  '🍀 A four-leaf clover transfer – rare and amazing!',
  '🎢 Thrilling ride to the perfect agent handoff!'
];

const SummaryRow = ({ title, color, result }: { title: string; color: string; result: string }) => (
  <dl
    css={css`
      display: grid;
      grid-template-columns: 200px 1fr;
      margin-bottom: 4px;
      gap: 0 4px;

      :first-of-type {
        margin-top: 12px;
      }
      :last-of-type {
        margin-bottom: 0;
      }
    `}
  >
    <dt
      css={css`
        ${smallParagraphCss};
        margin-right: 12px;
        ::after {
          content: '';
        }
      `}
    >
      {title}
    </dt>
    <dd
      css={css`
        ${smallParagraphCss};
        color: ${color};
      `}
    >
      {result}
    </dd>
  </dl>
);

const SuperISRFinishPage = ({ dataCollection }: { dataCollection: IDataCollection }) => {
  const { personGid, person } = useGuidedSellingExperienceContext();
  const [soldImageLoaded, setSoldImageLoaded] = React.useState(() => isImageCached(transferImage));
  const [lostImageLoaded, setLostImageLoaded] = React.useState(() => isImageCached(lostImage));
  const randomMessage = useConstant(() => MESSAGES[Math.floor(Math.random() * MESSAGES.length)]);
  const navigate = useNavigate();

  const homeProfilePage = dataCollection.pages.find(page => page.page_type === IDataCollectionPageType.HomeProfile);
  const homeEligibilityPage = dataCollection.pages.find(
    page => page.page_type === IDataCollectionPageType.HomeEligibility
  );
  const vehicleProfilePage = dataCollection.pages.find(
    page => page.page_type === IDataCollectionPageType.VehicleProfile
  );

  const policyTypes = dataCollection.pages
    .filter(page =>
      [IDataCollectionPageType.HomeProfile, IDataCollectionPageType.VehicleProfile].includes(page.page_type)
    )
    .flatMap(page => page.opportunities)
    .filter(Boolean)
    .map(opp => opp.policy_type);

  const { data: personPolicies } = usePersonAllPolicies({
    personGid: personGid!,
    filters: { policy_types: policyTypes }
  });

  const assetsGidsFromPolicies = personPolicies?.flatMap(policy => (policy.assets || []).map(asset => asset.gid)) || [];

  const { currentDisposition } = useCurrentDispositionSubscriptionContext();
  const lostLeadFlow = isEndDisposition(currentDisposition?.disposition_type);

  const showSkeleton = !personPolicies && (!soldImageLoaded || !lostImageLoaded);

  return (
    <BaseForm
      pb={spacings.px24}
      pt={spacings.px24}
      pl={spacings.px24}
      pr={spacings.px24}
      controlsAlignment="right"
      type="fullPage"
      controlsWidth={sizes.mediumFormInputWidth}
      initialValues={{}}
      submitText="Got it! Go to sales dashboard"
      submitTestId="close-data-collection-button"
      onSubmit={() => {
        return navigate('/leads');
      }}
      renderForm={() => {
        return (
          <FlexBox columnDirection gap={spacings.px24}>
            {lostLeadFlow ? (
              <img src={lostImage} alt="Transfer" width={360} onLoad={() => setLostImageLoaded(true)} />
            ) : (
              <img src={transferImage} alt="Transfer" width={590} onLoad={() => setSoldImageLoaded(true)} />
            )}
            {showSkeleton && (
              <Container m={spacings.px24}>
                <Skeleton height={300} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
                <Skeleton count={6} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
              </Container>
            )}
            {!showSkeleton && lostLeadFlow && (
              <FlexBox columnDirection gap={spacings.px12}>
                <FlexBox pl={spacings.px28} columnDirection gap={spacings.px12}>
                  <Heading type="h4">You&apos;ve ended the talk</Heading>
                  <Paragraph>
                    You&apos;ve politely ended the talk, knowing our products don&apos;t fit the customer&apos;s needs
                    now.
                  </Paragraph>
                  <FlexBox gap={spacings.px16} alignItemsCenter>
                    <Container
                      customCss={css`
                        width: 250px;
                        min-width: 250px;
                      `}
                    >
                      {person?.name}
                    </Container>
                    {currentDisposition && (
                      <Tag
                        label={currentDisposition?.label}
                        data-testid="current-disposition-tag"
                        backgroundColor={colors.statusRed}
                        borderRadius={borderRadius}
                        textType="tiny"
                        customCss={css`
                          padding: 2px ${spacings.px8}px;
                          height: fit-content;
                        `}
                      />
                    )}
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            )}
            {!showSkeleton && !lostLeadFlow && (
              <FlexBox columnDirection gap={spacings.px12}>
                <FlexBox pl={spacings.px28} columnDirection gap={spacings.px4}>
                  <Heading type="h4">{randomMessage}</Heading>
                  <Paragraph>This is the list of tasks you&apos;ve accomplished.</Paragraph>
                </FlexBox>
                <FlexBox pl={spacings.px28} columnDirection gap={spacings.px4}>
                  {homeProfilePage &&
                    (homeProfilePage.opportunities || []).map(opp => {
                      const dataCollectionResult =
                        homeProfilePage.is_completed && opp.is_data_collection_enabled ? 'Completed' : 'Skipped';

                      const firstAssetGid = (opp.assets || [])[0]?.gid || '';
                      const policyPresence = assetsGidsFromPolicies.includes(firstAssetGid);

                      const eligibilityResult =
                        homeEligibilityPage?.is_completed && dataCollectionResult === 'Completed'
                          ? 'Completed'
                          : 'Skipped';

                      return (
                        <React.Fragment key={`home-profile-${opp.id}`}>
                          <SummaryRow
                            color={dataCollectionResult === 'Completed' ? colors.statusGreen : colors.black}
                            title={`${opp.title} collection`}
                            result={dataCollectionResult}
                          />
                          <SummaryRow
                            color={policyPresence ? colors.statusGreen : colors.black}
                            title={`${opp.title} insurance`}
                            result={policyPresence ? 'Completed' : 'Skipped'}
                          />
                          <SummaryRow
                            color={eligibilityResult === 'Completed' ? colors.statusGreen : colors.black}
                            title={`${opp.title} eligibility`}
                            result={eligibilityResult}
                          />
                        </React.Fragment>
                      );
                    })}
                  {vehicleProfilePage &&
                    (vehicleProfilePage.opportunities || []).map(opp => {
                      const dataCollectionResult =
                        vehicleProfilePage.is_completed && opp.is_data_collection_enabled ? 'Completed' : 'Skipped';

                      const policyPresence = (opp.assets || []).some(asset =>
                        assetsGidsFromPolicies.includes(asset.gid)
                      );

                      return (
                        <React.Fragment key={`vehicle-profile-${opp.id}`}>
                          <SummaryRow
                            color={dataCollectionResult === 'Completed' ? colors.statusGreen : colors.black}
                            title={`${opp.title} collection`}
                            result={dataCollectionResult}
                          />
                          <SummaryRow
                            color={policyPresence ? colors.statusGreen : colors.black}
                            title={`${opp.title} insurance`}
                            result={policyPresence ? 'Completed' : 'Skipped'}
                          />
                        </React.Fragment>
                      );
                    })}
                </FlexBox>
              </FlexBox>
            )}
          </FlexBox>
        );
      }}
    />
  );
};

export default SuperISRFinishPage;
