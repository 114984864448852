import { ClassNames } from '@emotion/react';
import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import SystemMessage from '../../../components/core/Alert/SystemMessage';
import Button, { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import Copy from '../../../components/core/Copy';
import CopyableAddress from '../../../components/core/CopyableAddress';
import FlexBox from '../../../components/core/FlexBox';
import Paragraph from '../../../components/core/Paragraph';
import Tag from '../../../components/core/Tag';
import Text from '../../../components/core/Text';
import { Translations } from '../../../constants';
import { useToggle } from '../../../hooks';
import { ILoan, IMaticPolicy, ITask } from '../../../interfaces';
import { servicerOrLender } from '../../../interfaces/ILender';
import { findPolicyType } from '../../../interfaces/IPolicyType';
import { PartnerKeys } from '../../../interfaces/ISourceDimensions';
import useLead from '../../../queries/leads/useLead';
import useVerifiedLenders from '../../../queries/lenders/useVerifiedLenders';
import usePersonDocuments from '../../../queries/people/person_documents/usePersonDocuments';
import usePersonLoans, { PERSON_LOANS_QUERY_KEY } from '../../../queries/people/usePersonLoans';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import LoanEditor from '../../Customer/CustomerLoans/LoanEditor';
import useFaxTasks from '../_contexts/useFaxTasks';
import SelectLoanModal from './SelectLoanModal';

const FaxTaskDetails = ({
  personGid,
  assetGid,
  leadId,
  task,
  policy,
  isDataEditingForbidden
}: {
  personGid: string;
  assetGid: string | undefined;
  leadId: number;
  task: ITask;
  policy: IMaticPolicy;
  isDataEditingForbidden: boolean;
}) => {
  const queryClient = useQueryClient();
  const [editorOpened, toggleEditorOpened] = useToggle(false);
  const [selectLoanModalOpened, toggleSelectLoanModalOpened] = useToggle(false);
  const paymentMethod = Translations.paymentMethod(policy.payment_method).toLowerCase();

  const { data: lead } = useLead(leadId);
  const { data: verifiedLenders, isFetching: isLoadingVerifiedLenders } = useVerifiedLenders();
  const { data: loans, isFetching: isLoadingPersonLoans } = usePersonLoans({ personGid, assetGid });
  const { data: documents } = usePersonDocuments({ personGid, policyId: policy.id });
  const documentsAllowedForFax = documents?.filter(document => document.document_type_allowed_for_fax) || [];

  const [selectedLoan, setSelectedLoan] = React.useState<ILoan | null>(null);

  React.useEffect(() => {
    setSelectedLoan(currentLoan => {
      if (currentLoan) {
        return loans?.find(loan => loan.gid === currentLoan.gid) || null;
      }

      return loans?.[0] || null;
    });
  }, [loans]);

  const isFetching = isLoadingPersonLoans && isLoadingVerifiedLenders;

  const currentCompany = servicerOrLender({ servicer: selectedLoan?.servicer, lender: selectedLoan?.lender });

  const selectedVerifiedCompany = verifiedLenders?.find(lender => lender.gid === currentCompany?.gid);
  const mortgageeAddress = selectedVerifiedCompany?.mortgagee_address;
  const faxNumber = currentCompany?.fax_number;
  const areDocumentsPresent =
    !!policy.files &&
    policy.files.length > 0 &&
    !!policy.files.find(file => file.document_type_key === 'carrier_dec_page');

  let displayedInfo = null;

  const isFaxable =
    !isFetching &&
    !!currentCompany &&
    !!selectedLoan?.loan_number &&
    !!faxNumber &&
    !!areDocumentsPresent &&
    !task.completed &&
    !isDataEditingForbidden;

  const [checked, setChecked] = React.useState(isFaxable);
  const { addTask } = useFaxTasks();

  React.useEffect(() => {
    addTask(task, checked, selectedLoan?.gid || '');
  }, [task, addTask, checked, selectedLoan]);

  React.useEffect(() => {
    setChecked(isFaxable);
  }, [isFaxable]);

  React.useEffect(() => () => useFaxTasks.setState({ faxTasks: [] }), []);

  if (!currentCompany || !selectedLoan?.loan_number) {
    displayedInfo = (
      <SystemMessage
        type="warning"
        heading="Missing loan information"
        mv={spacings.px8}
        description={
          <>
            <Container>
              You have selected {paymentMethod} payment, but the loan information is missing. If you do not add it, we
              will not send the required documents to the mortgage company. This will result in a non-payment
              cancellation and the policy will be pending. A task will be created to fax this information manually if
              not uploaded now.
            </Container>
            <Button
              variant={ButtonVariant.Text}
              mt={spacings.px8}
              onClick={toggleEditorOpened}
              disabled={!assetGid || isDataEditingForbidden}
            >
              Add information
            </Button>
          </>
        }
      />
    );
  } else if (!faxNumber && lead?.source_dimensions?.partner !== PartnerKeys.Valon) {
    displayedInfo = (
      <SystemMessage
        type="error"
        heading="No fax number for lender"
        mv={spacings.px8}
        description={
          <Container>There is no fax number on file for this lender. You must manually fax the documents.</Container>
        }
      />
    );
  } else if (!areDocumentsPresent) {
    displayedInfo = (
      <SystemMessage
        type="warning"
        heading="Missing documents"
        mv={spacings.px8}
        description={
          <Container>
            Required documents have not been uploaded to AMP. These documents are required by the mortgage company and
            if not received will result in a non-payment cancellation and the policy will be pending. A task will be
            created to fax these documents manually if not uploaded now.
          </Container>
        }
      />
    );
  } else if (currentCompany && selectedLoan?.loan_number && faxNumber && areDocumentsPresent) {
    displayedInfo = (
      <FlexBox
        backgroundColor={colors.grey10}
        roundBorder
        p={spacings.px12}
        mv={spacings.px8}
        gap={spacings.px8}
        columnDirection
      >
        <Paragraph>Lender</Paragraph>
        <FlexBox alignItemsCenter gap={spacings.px8}>
          <Paragraph bold>
            Loan #{selectedLoan.loan_number} with {currentCompany.name}
          </Paragraph>
          {loans && loans.length > 1 && (
            <Button
              variant={ButtonVariant.Text}
              onClick={() => toggleSelectLoanModalOpened()}
              disabled={isDataEditingForbidden}
            >
              Change loan
            </Button>
          )}
        </FlexBox>
        <ClassNames>
          {({ css }) => (
            <Copy
              value={task.description}
              className={css`
                padding: 0;
              `}
            >
              <Paragraph className="fs-mask">{task.description}</Paragraph>
            </Copy>
          )}
        </ClassNames>
        <FlexBox justifySpaceBetween gap={spacings.px20}>
          <Paragraph color={colors.grey60}>Lender address</Paragraph>
          {mortgageeAddress && <CopyableAddress address={mortgageeAddress} />}
        </FlexBox>
        {documentsAllowedForFax.length ? (
          <FlexBox columnDirection gap={spacings.px8}>
            <Paragraph bold>Documents to fax</Paragraph>
            {documentsAllowedForFax.map(document => (
              <FlexBox key={document.id} gap={spacings.px8} alignItemsCenter>
                <Tag label={document.document_type_title?.toString() || 'Unknown'} backgroundColor={colors.violet} />
                <Text className="fs-mask" singleLine>
                  {document.name}
                </Text>
              </FlexBox>
            ))}
          </FlexBox>
        ) : null}
      </FlexBox>
    );
  }

  if (isFetching) {
    return <Skeleton count={2} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} />;
  }

  return (
    <>
      {displayedInfo}

      {editorOpened && (assetGid || selectedLoan) && (
        <LoanEditor
          personGid={personGid}
          assetGid={assetGid}
          cancelHandler={toggleEditorOpened}
          loan={selectedLoan || undefined}
          confirmHandler={() => {
            toggleEditorOpened();
            queryClient.invalidateQueries({ queryKey: [PERSON_LOANS_QUERY_KEY, personGid, assetGid] });
          }}
        />
      )}
      {loans && selectLoanModalOpened && (
        <SelectLoanModal
          selectedLoanGid={selectedLoan?.gid || ''}
          cancelHandler={() => toggleSelectLoanModalOpened()}
          confirmHandler={loan => {
            setSelectedLoan(loan);
            toggleSelectLoanModalOpened();
          }}
          loans={loans}
          title={`Select loan for ${findPolicyType(policy.policy_type)?.name.toLowerCase() || '-'}`}
        />
      )}
    </>
  );
};

export default FaxTaskDetails;
