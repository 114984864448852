/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import FlexBox from '../../../../../components/core/FlexBox';
import Paragraph from '../../../../../components/core/Paragraph';
import featureFlags from '../../../../../constants/featureFlags';
import { useToggle } from '../../../../../hooks';
import { ILead, IOpportunity } from '../../../../../interfaces';
import { isAutoAsset } from '../../../../../interfaces/IPersonAsset';
import { notMotorcycleOrTrailer } from '../../../../../interfaces/IVehicle';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
// eslint-disable-next-line max-len
import { useVehiclesWithVerificationStatus } from '../../../../GuidedDataCollection/VehicleProfilePage/_hooks/useVehicleProfileAnswersWithVerificationAnswers';
import AdditionalAnswers from './AdditionaAnswers';
import VehiclesForm from './Form';
import NonAutoVehicle from './NonAutoVehicle';
import Vehicle from './Vehicle';

interface VehiclesProps {
  vehicleOpportunities: IOpportunity[];
  personGid: string;
  lead: ILead | undefined;
  isEditModeAvailable: boolean;
}

const Vehicles = ({ vehicleOpportunities, personGid, lead, isEditModeAvailable }: VehiclesProps) => {
  const vehicles = vehicleOpportunities
    .flatMap(({ assets }) => assets)
    .filter(Boolean)
    .filter(isAutoAsset);

  const answersWithVerificationStatus = useVehiclesWithVerificationStatus({
    personGid,
    vehicleGids: vehicles.map(({ gid }) => gid)
  });

  const autos = vehicles.filter(v => notMotorcycleOrTrailer(v.kind));
  const nonAutoVehicles = vehicles.filter(v => !notMotorcycleOrTrailer(v.kind));

  const [editMode, toggleEdit] = useToggle(false);

  if (autos.length === 0 && nonAutoVehicles.length === 0) {
    return (
      <Paragraph
        mt={spacings.px24}
        color={colors.grey60}
        customCss={css`
          text-align: center;
        `}
      >
        No vehicles added
      </Paragraph>
    );
  }

  return editMode ? (
    <VehiclesForm
      key={vehicles
        .map(({ gid }) => gid)
        .sort()
        .join('')}
      vehicles={vehicles}
      personGid={personGid}
      toggleEdit={toggleEdit}
      leadId={lead?.id}
      leadGid={lead?.gid}
    />
  ) : (
    <FlexBox columnDirection gap={spacings.px12}>
      {autos.map((vehicle, index) => (
        <Vehicle
          key={vehicle.gid}
          vehicle={vehicle}
          index={index}
          isEditModeAvailable={isEditModeAvailable}
          toggleEdit={toggleEdit}
          answers={answersWithVerificationStatus[vehicle.gid]}
        />
      ))}
      {nonAutoVehicles.map((vehicle, index) => (
        <NonAutoVehicle
          key={vehicle.gid}
          vehicle={vehicle}
          index={autos.length + index}
          isEditModeAvailable={isEditModeAvailable}
          toggleEdit={toggleEdit}
          answers={answersWithVerificationStatus[vehicle.gid]}
        />
      ))}
      {featureFlags.vehicleAdditionalQuestions && autos.length ? <AdditionalAnswers personGid={personGid} /> : null}
    </FlexBox>
  );
};

export default Vehicles;
