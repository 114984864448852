import { skipToken, useQuery, UseQueryOptions } from '@tanstack/react-query';

import { ICustomerDataCompleteness } from '../../../interfaces';
import api from '../../../services/api';
import { toQueryParams } from '../../../utils/queryParams';

export const CUSTOMER_DATA_COMPLETENESS_QUERY_KEY = 'data_collection_completeness';

const getCustomerDataCompleteness = ({
  personGid,
  engagementGid
}: {
  personGid: string;
  engagementGid: string;
}): Promise<{ customer_data_completeness: ICustomerDataCompleteness }> => {
  const queryParams = toQueryParams({ engagement_gid: engagementGid });
  return api.get(`/api/frontend/people/${personGid}/online_sessions/customer_data_completeness?${queryParams}`);
};

const useCustomerDataCompleteness = ({
  personGid,
  engagementGid,
  options
}: {
  personGid: string | undefined;
  engagementGid: string | undefined;
  options?: UseQueryOptions<{ customer_data_completeness: ICustomerDataCompleteness }, any, any, any>;
}) =>
  useQuery({
    /* eslint-disable-next-line @tanstack/query/exhaustive-deps  */
    queryKey: [CUSTOMER_DATA_COMPLETENESS_QUERY_KEY, personGid, personGid],
    queryFn:
      !!personGid && !!engagementGid
        ? () => getCustomerDataCompleteness({ personGid: personGid!, engagementGid: engagementGid! })
        : skipToken,
    ...options,
    select: data => data.customer_data_completeness
  });

export default useCustomerDataCompleteness;
