import { FormikProps } from 'formik';
import * as React from 'react';

import { IAddress, IPerson } from '../../interfaces';
import { IPrefilledDriver, IPrefilledVehicle } from '../../queries/people/usePrefillVehiclesAndDrivers';
import FormModal, { FormModalProps } from '../core/FormModal';
import { ModalSize } from '../core/Modal';
import { initialValues, ManualVehicle, useVehiclesForm, validationSchema } from './utils';
import VehiclesFormRenderer from './VehiclesFormRenderer';

interface Props {
  personGid: string;
  person: IPerson;
  leadGid: string;
  isLoading: FormModalProps<ReturnType<typeof initialValues>>['confirmationInProgress'];
  cancelHandler: FormModalProps<ReturnType<typeof initialValues>>['cancelHandler'];
  confirmHandler: ({
    vehicles,
    prefilledVehicles,
    prefilledVehiclesGarageAddress,
    prefilledDrivers
  }: {
    vehicles: ManualVehicle[];
    prefilledVehiclesGarageAddress: Omit<IAddress, 'id' | 'full'>;
    prefilledVehicles: IPrefilledVehicle[];
    prefilledDrivers: IPrefilledDriver[];
  }) => void;
}

// For now should be used with enableReinitialize on Formik
const AddVehiclesModal = ({ personGid, person, leadGid, cancelHandler, confirmHandler, isLoading }: Props) => {
  const vehiclesFormData = useVehiclesForm({
    personGid,
    leadGid
  });

  const firstPersonAddress = vehiclesFormData.personAddresses?.[0];

  return (
    <FormModal
      returnFocusAfterClose={false}
      size={ModalSize.extra}
      confirmationInProgress={isLoading}
      initialValues={initialValues({
        dateOfBirth: person.date_of_birth,
        prefilledAddress: firstPersonAddress
      })}
      title="Add vehicles"
      confirmText="Add selected"
      confirmHandler={({ vehicles, prefilled_vehicles_garage_address, prefilled_vehicles, prefilled_drivers }) =>
        confirmHandler({
          vehicles,
          prefilledVehiclesGarageAddress: prefilled_vehicles_garage_address,
          prefilledVehicles: prefilled_vehicles.filter(({ confirmed }) => confirmed).map(({ vehicle }) => vehicle),
          prefilledDrivers: prefilled_drivers.filter(({ confirmed }) => confirmed).map(({ driver }) => driver)
        })
      }
      cancelHandler={cancelHandler}
      validationSchema={validationSchema({
        withPrefillValidation: vehiclesFormData.showPrefill
      })}
      renderForm={(props: FormikProps<ReturnType<typeof initialValues>>) => {
        return vehiclesFormData.personAddresses ? (
          <VehiclesFormRenderer
            {...props}
            {...vehiclesFormData}
            personAddresses={vehiclesFormData.personAddresses}
            person={person}
            personGid={personGid}
          />
        ) : (
          <div>Loading</div>
        );
      }}
    />
  );
};

export default AddVehiclesModal;
