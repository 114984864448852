import React from 'react';

import useTrackLeadOpened from '../queries/leads/useTrackLeadOpened';
import RecentLeadManager from '../services/RecentLeadManager';

const useTrackOpeningLead = ({
  leadGid,
  leadId
}: {
  leadGid: string | undefined;
  leadId: number | string | undefined;
}) => {
  const { mutate: trackLeadOpened } = useTrackLeadOpened();

  React.useEffect(() => {
    if (leadGid) {
      trackLeadOpened(leadGid);
    }
  }, [leadGid, trackLeadOpened]);

  React.useEffect(() => {
    if (leadId) {
      RecentLeadManager.addLead(leadId.toString());
    }
  }, [leadId]);
};

export default useTrackOpeningLead;
