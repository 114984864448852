/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { KeyboardEvent, useState } from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { defaultFontCss } from '../../../../theme/typography';
import { borderRadius } from '../../../../theme/variables';

interface ICreateNoteTextarea {
  onSubmit: (value: string) => void;
  resizeForbidden?: boolean;
  height?: number;
  width?: number;
  textareaId?: string;
}

const CreateNoteTextarea = ({
  onSubmit,
  resizeForbidden,
  height = 72,
  width,
  textareaId
}: ICreateNoteTextarea): JSX.Element => {
  const [value, setValue] = useState('');

  const onKeyDown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit(value);
      setValue('');
    }
  };

  const containsNumber = /\d/.test(value);

  return (
    <Container fitParentWidth>
      <textarea
        css={css`
          ${defaultFontCss}
          padding: 8px;
          width: ${width ? `${width}px` : '100%'};
          min-height: ${height}px;
          ${resizeForbidden ? 'resize: none;' : ''}
          background: ${colors.white};
          border: 1px solid ${colors.black};
          box-sizing: border-box;
          border-radius: ${borderRadius}px;
          outline-color: ${colors.azure50};
          outline-offset: 3px;

          ::placeholder {
            font-style: italic;
          }
        `}
        placeholder="Leave your note here"
        onKeyDown={onKeyDown}
        value={value}
        onChange={e => setValue(e.target.value)}
        id={textareaId}
      />
      <FlexBox justifyRight={!containsNumber} justifySpaceBetween={containsNumber}>
        {containsNumber && (
          <Text type="small" color={colors.statusOrange}>
            Verify that note does not contain sensitive data. For example, credit card or banking information
          </Text>
        )}
        <Text type="small" color={colors.grey30}>
          Press Enter to send
        </Text>
      </FlexBox>
    </Container>
  );
};

export default CreateNoteTextarea;
