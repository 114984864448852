import { AtLeast } from '.';

export interface IAddressSuggestion {
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
  entries: number | string;
  name?: string;
}

export interface IAddress {
  line1: string;
  line2: string | null | undefined;
  city: string;
  state: string;
  zip: string;
  full: string;
}

export type IPayloadAddress = AtLeast<Omit<IAddress, 'full'>, 'line1' | 'city' | 'state' | 'zip'>;

export interface IPersonAddress extends IAddress {
  address_key: 'current' | 'mailing' | 'home' | 'garaging';
  name: string;
}

interface Response {
  status: number;
  data: any;
}

export interface ISuggestionResponse extends Response {
  data: {
    suggestions: IAddressSuggestion[];
  };
}

export const areAddressesEqual = (leftAddress: IAddress, rightAddress: IAddress) =>
  leftAddress?.line1 === rightAddress?.line1 &&
  leftAddress?.line2 === rightAddress?.line2 &&
  leftAddress?.city === rightAddress?.city &&
  leftAddress?.state === rightAddress?.state &&
  leftAddress?.zip === rightAddress?.zip;
