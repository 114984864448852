/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import React from 'react';

import { IAddressSuggestion } from '../../../../../interfaces';
import colors from '../../../../../theme/colors';
import FlexBox from '../../../FlexBox';
import { ArrowDownIcon } from '../../../icons';
import Text from '../../../Text';
import { addressOption, azureColor, caret } from './AddressOption.style';

export const ADDRESS_OPTION_TESTID = 'address-option';

const getOptionString = ({ street_line, secondary, city, state, zipcode, entries }: IAddressSuggestion): string => {
  const secondaryStr = secondary ? ` ${secondary}` : '';
  const entriesStr = +entries > 1 ? ` (${entries})` : '';
  const ending = [state, zipcode].filter(Boolean).join(' ');

  return `${street_line}${secondaryStr}${entriesStr} ${city}${ending ? `, ${ending}` : ''}`;
};

const renderOptionLabel = (
  inputValue = '',
  optionString: string,
  entries: number,
  name: string | undefined
): JSX.Element => {
  const inputLow = inputValue.toLowerCase().trim();
  const optionLow = optionString.toLowerCase().trim();
  let i = 0;

  for (; i < optionString.length; i++) {
    if (inputLow[i] === undefined || inputLow[i] !== optionLow[i]) {
      break;
    }
  }

  const common = optionString.slice(0, i);
  const suggested: (string | JSX.Element)[] = optionString.slice(i).split(`(${entries})`);

  if (entries > 1) {
    suggested.splice(
      1,
      0,
      <span css={azureColor} key="entries">
        ({entries} entries)
      </span>
    );
  }

  return (
    <div>
      <FlexBox>
        {common && <strong data-testid="address-bold">{common}</strong>}
        {suggested.map(sub => sub)}
        {entries > 1 && <ArrowDownIcon css={caret} />}
      </FlexBox>
      {name && (
        <Text type="small" color={colors.grey60}>
          {name}
        </Text>
      )}
    </div>
  );
};

interface Props {
  inputValue?: string;
  className?: string;
  option: IAddressSuggestion;
  onSelect(option: IAddressSuggestion, optionString: string): void;
  customCSS?: SerializedStyles;
}

const AddressOption: React.FC<Props> = ({ inputValue = '', option, onSelect, customCSS }) => {
  const optionString = getOptionString(option);

  return (
    <button
      type="button"
      css={[addressOption, customCSS]}
      data-testid={ADDRESS_OPTION_TESTID}
      onClick={() => onSelect(option, optionString)}
      title={optionString}
      className="fs-mask"
    >
      {renderOptionLabel(inputValue, optionString, +option.entries, option.name)}
    </button>
  );
};

export default AddressOption;
