/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { omit, pick } from 'ramda';
import * as React from 'react';

import colors from '../../../theme/colors';
import { spacingKeys, Spacings, spacings, spacingsCss } from '../../../theme/variables';
import Loader from '../../common/Loader';
import Text, { TextProps } from '../Text';

export interface IButton extends React.HTMLProps<HTMLButtonElement>, Spacings {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  type?: 'button' | 'submit';
  textType?: TextProps['type'];
  customCss?: SerializedStyles;
  ref?: React.Ref<any>;
}

const ButtonWithoutBorder = ({
  children,
  onClick,
  disabled,
  loading = false,
  type = 'button',
  textType,
  customCss,
  ...rest
}: IButton): JSX.Element => {
  const spacingProps = pick(spacingKeys, rest);
  const restOfProps = omit(spacingKeys, rest);
  return (
    <button
      {...restOfProps}
      type={type}
      disabled={disabled || loading}
      onClick={onClick}
      css={css`
        border: none;
        background: transparent;
        ${spacingsCss(spacingProps)};
        color: ${disabled ? colors.grey30 : colors.azure50};
        &:hover {
          color: ${disabled ? colors.grey30 : colors.blueHoverLink};
        }
        &:disabled:hover {
          cursor: not-allowed;
        }
        ${customCss}
      `}
    >
      <Text
        type={textType}
        bold
        customCss={css`
          color: inherit;
          line-height: inherit;
        `}
      >
        {loading ? (
          <Loader
            width={`${spacings.px16}px`}
            height={`${spacings.px16}px`}
            customCss={css`
              display: inline;
            `}
          />
        ) : (
          children
        )}
      </Text>
    </button>
  );
};

export default React.forwardRef((props: IButton, ref) => ButtonWithoutBorder({ ...props, ref }));
