import { IHome } from './IHome';
import { isAutoAsset } from './IPersonAsset';
import { InsurableInterest, isInsurableInterestVehicle, PolicyType, Requirement } from './IPolicyType';
import { generateAutoDescription, IVehicle } from './IVehicle';

export enum OpportunitySource {
  Amp = 'amp',
  PostSales = 'post_sales',
  RecommendationsEngine = 'recommendations_engine'
}

export enum OpportunityVerificationStatus {
  Ineligible = 'ineligible',
  Qualified = 'qualified',
  NotVerified = 'not_verified',
  Skipped = 'skipped'
}

export enum OpportunityStatus {
  NewRequest = 'new_request',
  NotQualified = 'not_qualified',
  Qualified = 'qualified',
  Lost = 'lost',
  Sold = 'sold'
}

export enum OpportunityCoveragesTier {
  // homes
  MatchCurrentInsurance = 'match_current_insurance',
  MatchRCE = 'match_rce',
  Custom = 'custom',

  // vehicles
  Basic = 'basic',
  Default = 'default',
  Premium = 'premium',
  NonStandard = 'non_standard',
  Mixed = 'mixed',

  // Reserved for opportunities where input coverages are not supported,
  // but we still need to track that quoting was initiated.
  Quoted = 'quoted'
}

export const realPropertyCoverageTierOptions = [
  {
    key: OpportunityCoveragesTier.MatchCurrentInsurance,
    value: 'Match current insurance'
  },
  {
    key: OpportunityCoveragesTier.MatchRCE,
    value: 'Match RCE'
  },
  {
    key: OpportunityCoveragesTier.Custom,
    value: 'Custom'
  }
] as const;

export interface ISelectedAssetCoverage {
  key: string;
  limit_option_gid: string | null;
  limit_value: string | null;
  deductible_value: string | null;
}

export interface ISelectedCoverages {
  asset_gid: string | null;
  coverages_tier: OpportunityCoveragesTier | null;
  coverages: ISelectedAssetCoverage[] | null;
}

export interface IOpportunity {
  id: number;
  created_at: string;
  source: OpportunitySource;
  assets: (IHome | IVehicle)[] | null;
  policy_type: PolicyType;
  requirement: Requirement;
  insurable_interest: InsurableInterest;
  title: string;
  primary: boolean;
  state: string | null;
  status: OpportunityStatus;
  reason: string | null;
  is_opened: boolean;
  disqualification_reason: 'no_carriers_appointments' | string | null;
  disqualification_message: string | null;
  qualification_available: boolean;
  is_data_collection_enabled: boolean;
  is_user_licensed: boolean;
  agent_selected_coverages: ISelectedCoverages[] | null;
  primary_insured_gid: string | null;
}

export interface RealPropertyOpportunity extends IOpportunity {
  assets: IHome[] | null;
}

export interface VehicleOpportunity extends IOpportunity {
  assets: IVehicle[] | null;
}

export interface PAFOpportunity extends IOpportunity {
  assets: null;
}

export interface LifeOpportunity extends IOpportunity {
  assets: null;
}

export const opportunityDescription = ({
  policy_type,
  assets
}: {
  policy_type: IOpportunity['policy_type'];
  assets: IOpportunity['assets'];
}) => {
  if (isInsurableInterestVehicle(policy_type)) {
    return generateAutoDescription((assets || []).map(a => (isAutoAsset(a) ? a : null)).filter(Boolean));
  }

  return assets?.[0]?.address?.full;
};
export const isOpportunityClosed = (opportunity: IOpportunity) => {
  return [OpportunityStatus.Lost, OpportunityStatus.Sold, OpportunityStatus.NotQualified].includes(opportunity.status);
};

export const isHomeOpportunity = (op: IOpportunity): op is RealPropertyOpportunity =>
  op.insurable_interest === InsurableInterest.RealProperty;

export const isVehicleOpportunity = (op: IOpportunity): op is VehicleOpportunity =>
  op.insurable_interest === InsurableInterest.Vehicle;

export const isPAFOpportunity = (op: IOpportunity): op is PAFOpportunity =>
  op.insurable_interest === InsurableInterest.PersonalBelongings;

export const opportunityCoveragesForAsset = (
  { agent_selected_coverages }: { agent_selected_coverages: IOpportunity['agent_selected_coverages'] },
  asset_gid: string | undefined
) => {
  return agent_selected_coverages?.find(c => c.asset_gid === asset_gid);
};
