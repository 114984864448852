import DashboardLeadLastActions from './DashboardLeadLastActions';
import LeadAge from './LeadAge';
import LeadDisposition from './LeadDisposition';
import LeadId from './LeadId';
import LeadLender from './LeadLender';
import LeadPartner from './LeadPartner';
import LeadSourceProduct from './LeadSourceProduct';

const tableColumns = {
  LeadId,
  LeadAge,
  LeadLender,
  LeadPartner,
  LeadSourceProduct,
  LeadDisposition,
  DashboardLeadLastActions
};

export default tableColumns;
