/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import IconButton, { ButtonIcons } from '../../../components/core/buttons/IconButton';
import Copy from '../../../components/core/Copy';
import FlexBox from '../../../components/core/FlexBox';
import { TaskIcon } from '../../../components/core/icons';
import Paragraph from '../../../components/core/Paragraph';
import { useToggle } from '../../../hooks';
import { IMaticPolicy, IPerson, ITask, IUser } from '../../../interfaces';
import { TaskKind } from '../../../interfaces/ITask';
import { TaskPayload } from '../../../queries/people/person_tasks/useCreateTask';
import analytics from '../../../services/analytics';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import { capitalize, DASHBOARD_DATE_TIME_FORMAT, dateFormatter, dateTimeFormatter } from '../../../utils/formatter';
import TaskEditor from '../../Lead/LeadTasks/TaskEditor';
import FaxTaskDetails from './FaxTaskDetails';
import InvoiceTaskDetails from './InvoiceTaskDetails';

const formatTaskDueDate = (task: ITask) =>
  task.due_time ? dateTimeFormatter(task.due_time, DASHBOARD_DATE_TIME_FORMAT) : dateFormatter(task.due_date);

const PolicyTask = ({
  task,
  assignees,
  leadId,
  policy,
  person,
  updateTask,
  homeAssetGid,
  isDataEditingForbidden
}: {
  task: ITask;
  assignees: IUser[];
  leadId: number;
  person: IPerson;
  policy: IMaticPolicy;
  updateTask: ({
    personGid,
    taskId,
    data
  }: {
    personGid: string;
    taskId: number;
    data: TaskPayload;
  }) => Promise<{ task: ITask }>;
  homeAssetGid: string | undefined;
  isDataEditingForbidden: boolean;
}) => {
  const [editTask, toggleEditTask] = useToggle(false);

  const generateTaskDescription = () => {
    if (task.kind === TaskKind.Fax) {
      return (
        <FaxTaskDetails
          personGid={person.gid}
          assetGid={homeAssetGid}
          leadId={leadId}
          task={task}
          policy={policy}
          isDataEditingForbidden={isDataEditingForbidden}
        />
      );
    } else if (task.kind === TaskKind.TrailingDoc) {
      return (
        <InvoiceTaskDetails
          assetGid={homeAssetGid}
          policy={policy}
          person={person}
          isDataEditingForbidden={isDataEditingForbidden}
          task={task}
        />
      );
    } else {
      return (
        <FlexBox backgroundColor={colors.grey5} roundBorder p={spacings.px12} mv={spacings.px8} columnDirection>
          <Copy value={task.description}>
            <Paragraph>{task.description}</Paragraph>
          </Copy>
        </FlexBox>
      );
    }
  };

  return (
    <FlexBox columnDirection>
      <FlexBox justifySpaceBetween gap={spacings.px20}>
        <FlexBox gap={spacings.px8} alignItemsCenter>
          <TaskIcon
            css={css`
              min-width: ${spacings.px20}px;
              min-height: ${spacings.px20}px;
            `}
          />
          <Paragraph crossed={task.completed} singleLine>
            {capitalize(task.kind)}
          </Paragraph>
          <Paragraph color={colors.grey60} crossed={task.completed} singleLine>
            Assigned to {task.assignee.name}
          </Paragraph>
        </FlexBox>
        <FlexBox gap={spacings.px4} alignItemsCenter>
          <Paragraph crossed={task.completed} singleLine>
            Deadline: {formatTaskDueDate(task)}
          </Paragraph>
          <IconButton
            disabled={isDataEditingForbidden}
            icon={ButtonIcons.Edit}
            onClick={() => {
              analytics.track('Final look, edit task clicked');
              toggleEditTask();
            }}
            focus={editTask}
          />
        </FlexBox>
      </FlexBox>
      {generateTaskDescription()}
      {editTask && (
        <TaskEditor
          open={editTask}
          task={task}
          assignees={assignees}
          cancelBtnHandler={toggleEditTask}
          confirmBtnHandler={data => {
            updateTask({
              personGid: person.gid,
              taskId: task.id,
              data: { ...data, lead_id: leadId, policy_id: policy.id } as TaskPayload
            });
            toggleEditTask();
          }}
          person={person}
          leadId={leadId}
        />
      )}
    </FlexBox>
  );
};

export default PolicyTask;
