import { ICarrier } from '../../../../interfaces';
import { CarrierKey } from '../../../../interfaces/ICarrier';
import CSAACarrierInstructions from './CSAACarrierInstructions';
import ObieCarrierInstructions from './ObieCarrierInstructions';
import UniversalCarrierConsent from './UniversalCarrierConsent';

const CarrierInstructions = ({ carriersList, carrierId }: { carriersList: ICarrier[]; carrierId: number }) => {
  return (
    <>
      {carriersList.find(c => c.id === carrierId)?.key === CarrierKey.Universal && <UniversalCarrierConsent />}
      {carriersList.find(c => c.id === carrierId)?.key === CarrierKey.Obie && <ObieCarrierInstructions />}
      {carriersList.find(c => c.id === carrierId)?.key === CarrierKey.CSAA && <CSAACarrierInstructions />}
    </>
  );
};

export default CarrierInstructions;
