import { skipToken, useQuery } from '@tanstack/react-query';

import { ILead } from '../../interfaces/ILead';
import { InsuranceCategory } from '../../interfaces/IPolicyType';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const usePersonActiveLead = ({
  personGid,
  insuranceCategory
}: {
  personGid: string | undefined;
  insuranceCategory: InsuranceCategory | undefined;
}) =>
  useQuery({
    queryKey: ['person_active_lead', personGid, insuranceCategory],
    queryFn: personGid
      ? (): Promise<{ lead: ILead }> =>
          api.get(
            `/api/frontend/people/${personGid}/active_lead?${toQueryParams({ insurance_category: insuranceCategory })}`
          )
      : skipToken,
    select: data => data.lead
  });

export default usePersonActiveLead;
