/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Sparkles from '../../../../../components/core/Sparkles';
import Tag from '../../../../../components/core/Tag';
import { IQuote } from '../../../../../interfaces';
import colors from '../../../../../theme/colors';
import { borderRadius, spacings } from '../../../../../theme/variables';
import { isQuoteSelectedByCustomer } from '../../_helpers';

interface IQuoteRowProps {
  quote: IQuote;
}

const InProgressQuoteRow = ({ quote }: IQuoteRowProps) => {
  const {
    attributes: { carrier }
  } = quote;

  const isQuoteSelected = isQuoteSelectedByCustomer(quote);

  return (
    <SkeletonTheme baseColor={colors.grey10} highlightColor={colors.grey5}>
      <FlexBox
        columnDirection
        borderBottom={!isQuoteSelected}
        roundBorder
        backgroundColor={colors.white}
        border={!!isQuoteSelected}
        borderColor={isQuoteSelected ? colors.violet : colors.grey10}
        mt={isQuoteSelected ? spacings.px12 : spacings.px0}
      >
        <FlexBox
          justifySpaceBetween
          ph={spacings.px16}
          pv={spacings.px6}
          alignItemsCenter
          customCss={css`
            position: relative;
          `}
        >
          {isQuoteSelected && (
            <Tag
              label="Customer’s choice"
              backgroundColor={colors.violet}
              borderRadius={borderRadius}
              customCss={css`
                left: ${spacings.px8}px;
                top: ${spacings['-px12']}px;
                position: absolute;
              `}
            />
          )}
          <Container
            pv={spacings.px4}
            customCss={css`
              min-width: 140px;
            `}
          >
            <span data-tip={carrier?.name} data-for="carrier-name-tooltip">
              <div
                css={css`
                  background-position: left;
                  background-repeat: no-repeat;
                  background-size: contain;
                  height: 30px;
                  margin: 0 auto;
                  width: 100px;
                  filter: grayscale(100%);
                  ${carrier?.logo ? `background-image: url(${carrier.logo})` : ''};
                `}
                title={carrier?.name}
              />
            </span>
          </Container>

          <Container
            pl={spacings.px8}
            pv={spacings.px4}
            customCss={css`
              min-width: 140px;
            `}
          />

          <Container
            pl={spacings.px8}
            customCss={css`
              min-width: 180px;
            `}
          >
            <Skeleton
              css={css`
                width: 60px;
              `}
            />
          </Container>

          <Container
            pl={spacings.px8}
            customCss={css`
              min-width: 140px;
            `}
          >
            <Skeleton
              css={css`
                width: 70px;
              `}
            />
          </Container>

          <Container
            pl={spacings.px8}
            customCss={css`
              min-width: 140px;
            `}
          >
            <Skeleton
              css={css`
                width: 70px;
              `}
            />
          </Container>

          <Container
            color={colors.grey60}
            pl={spacings.px8}
            customCss={css`
              min-width: 210px;
            `}
          >
            <Skeleton
              css={css`
                width: 60px;
              `}
            />
          </Container>
          <Container
            pl={spacings.px8}
            pv={spacings.px4}
            customCss={css`
              min-width: 180px;
            `}
          >
            <Sparkles type="success" />
          </Container>
        </FlexBox>
      </FlexBox>
    </SkeletonTheme>
  );
};

export default InProgressQuoteRow;
