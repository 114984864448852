/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Draggable from 'react-draggable';

import useQueryParams from '../../hooks/useQueryParams';
import { LeadTypes } from '../../interfaces/ISourceDimensions';
import useCallLog from '../../queries/call_logs/useCallLog';
import useCallParticipants from '../../queries/call_logs/useCallParticipants';
import useLeadDispositions from '../../queries/leads/dispositions/useLeadDispositions';
import useLead from '../../queries/leads/useLead';
import usePerson from '../../queries/people/usePerson';
import { Subscription } from '../../services/ActionCable';
import { ActionCableContext } from '../../utils/actioncable';
import { phoneFormatter } from '../../utils/formatter';
import CallIndicator from '../core/CallIndicator';
import { isAuthorizedUserFirstInConference } from '../core/CallIndicator/helpers';
import { dispositionLabel, isEndDisposition, isLeadTransferred } from '../DispositionsModals/dispositionsHelper';

interface TwilioCallIndicatorProps {
  currentCallId: number;
}

const TwilioCallIndicator = ({ currentCallId }: TwilioCallIndicatorProps) => {
  const { data: currentCallLog } = useCallLog(currentCallId);
  const {
    person_gid: personGid,
    lead_id: leadId,
    lead_gid: leadGid,
    is_call_ended: isCallEnded
  } = currentCallLog || {};
  const { data: person } = usePerson(personGid);
  const { data: lead } = useLead(leadGid);

  const isFakeLead = lead?.source_dimensions?.lead_type === LeadTypes.UnknownEngagement;
  const callTitle =
    personGid && !isFakeLead ? person?.name : phoneFormatter(currentCallLog?.external_phone) || 'Unknown customer';

  const { data: leadDispositions, refetch: refetchDispositions } = useLeadDispositions(leadId);
  const { data: participants } = useCallParticipants(currentCallId);

  const queryParams = useQueryParams();
  const callLogIdFromQuery = queryParams.get('call_log_id');

  const isConference = (participants?.length || 0) > 1;
  const isOutsideOfCurrentCall = !!currentCallLog?.id && callLogIdFromQuery !== currentCallId?.toString();
  const showIndicator = Boolean(currentCallLog && (isOutsideOfCurrentCall || isConference));

  const lastCallParticipant = participants?.[participants?.length - 1];
  const firstCallParticipant = participants?.[0];

  const [collapsed, setCollapsed] = React.useState(false);

  React.useEffect(() => {
    setCollapsed(false);
  }, [currentCallLog?.id]);

  React.useEffect(() => {
    if (isConference) {
      setCollapsed(false);
    }
  }, [isConference]);

  const cable = React.useContext(ActionCableContext);

  React.useEffect(() => {
    let summaryChannel: Subscription;

    if (leadId && cable) {
      summaryChannel = cable.subscriptions.create(
        {
          channel: 'LeadSummaryChannel',
          lead_id: leadId
        },
        {
          received: () => {
            refetchDispositions();
          }
        }
      );
    }

    return () => {
      if (leadId && cable) {
        summaryChannel.unsubscribe();
      }
    };
  }, [cable, leadId, refetchDispositions]);

  const cannotAssignToAgent =
    isLeadTransferred(leadDispositions?.dispositions || []) ||
    isEndDisposition(leadDispositions?.current_disposition?.disposition_type);

  const getCallType = () => {
    const singleParticipant = participants?.length === 1;
    const personLeadCall = isConference && isAuthorizedUserFirstInConference(participants || []);
    const personJoinedCall = isConference && !isAuthorizedUserFirstInConference(participants || []);

    if (isCallEnded) {
      return 'Call ended';
    }
    if (cannotAssignToAgent) {
      return dispositionLabel(leadDispositions?.current_disposition);
    }
    if (singleParticipant) {
      return 'Ongoing call';
    }
    if (personLeadCall) {
      return lastCallParticipant
        ? `${lastCallParticipant.role_label} ${lastCallParticipant.name} joined the call`
        : 'Agent joined the call'; // Should never happen, but placing validation just in case.
    }
    if (personJoinedCall) {
      return firstCallParticipant
        ? `You joined the call with ${firstCallParticipant.role_label} ${firstCallParticipant.name}`
        : 'You joined the call'; // Should never happen, but placing validation just in case.
    }
    return 'Ongoing call';
  };

  return (
    <Draggable axis="y" bounds="parent">
      <div
        css={css`
          position: fixed;
          bottom: 16px;
          right: 0px;
          ${showIndicator ? 'z-index: 9999;' : 'visibility: collapse;'}
        `}
      >
        {showIndicator && (
          <CallIndicator
            currentCallLog={currentCallLog!}
            title={callTitle}
            type={getCallType()}
            isFakeLead={isFakeLead}
            participants={participants}
            isConference={isConference}
            canAssignToAgent={!cannotAssignToAgent}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
          />
        )}
      </div>
    </Draggable>
  );
};

export default TwilioCallIndicator;
