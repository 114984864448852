import React from 'react';

import empty1 from '../../../assets/img/empty/empty.png';
import empty2 from '../../../assets/img/empty/empty@2x.png';
import empty3 from '../../../assets/img/empty/empty@3x.png';
import colors from '../../../theme/colors';
import { spacings } from '../../../theme/variables';
import FlexBox from '../../core/FlexBox';
import Text from '../../core/Text';

const EmptyResults = ({ message = 'No results yet' }: { message?: string }) => (
  <FlexBox columnDirection alignItemsCenter justifyCenter pt={spacings.px24}>
    <img alt="Empty" className="empty-image" src={empty1} srcSet={`${empty1} 1x, ${empty2} 2x, ${empty3} 3x`} />
    <Text bold type="large" color={colors.grey60}>
      {message}
    </Text>
  </FlexBox>
);

export default EmptyResults;
