import React from 'react';

import Modal, { ModalSize } from '../../../components/core/Modal';
import Paragraph from '../../../components/core/Paragraph';
import { useDetachVehicleFromDataCollection } from '../../../queries/leads/data_collection/useDataCollectionVehicles';
import AssetAssociatedInfo from './AssetAssociatedInfo';

interface IVehicleDelete {
  confirmBtnHandler?: (vehicleGid: string) => void;
  cancelBtnHandler: () => void;
  leadId: string | number;
  vehicleGid: string;
  personGid: string;
}

const VehicleDeleteModal = ({
  confirmBtnHandler,
  cancelBtnHandler,
  leadId,
  vehicleGid,
  personGid
}: IVehicleDelete): JSX.Element => {
  const { mutateAsync: detachVehicle, isPending: isDetachingVehicle } = useDetachVehicleFromDataCollection();

  return (
    <Modal
      title="Do you want to delete the vehicle?"
      dangerModal
      size={ModalSize.large}
      cancelHandler={cancelBtnHandler}
      confirmHandler={async () => {
        await detachVehicle({ leadId, assetGid: vehicleGid });
        confirmBtnHandler && (await confirmBtnHandler(vehicleGid));
        cancelBtnHandler();
      }}
      confirmText="Yes, delete"
      confirmationInProgress={isDetachingVehicle}
    >
      <Paragraph>
        You&apos;re going to delete vehicle from the list. You will not see this vehicle in the list of assets related
        to this customer.
      </Paragraph>
      <AssetAssociatedInfo personGid={personGid} assetGid={vehicleGid} />
    </Modal>
  );
};

export default VehicleDeleteModal;
