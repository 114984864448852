import { useNavigate } from 'react-router-dom';

import Button, { ButtonSize } from '../../../../components/core/buttons/Button';
import Modal from '../../../../components/core/Modal';
import featureFlags from '../../../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { useToggle } from '../../../../hooks';
import useDuplicateLead from '../../../../queries/leads/duplicate/useDuplicateLead';
import analytics from '../../../../services/analytics';
import guidedSellingExperienceNavigation from '../../navigation';

const ReopenButton = () => {
  const navigate = useNavigate();
  const { lead } = useGuidedSellingExperienceContext();
  const [showConfirmationModal, toggleShowConfirmationModal] = useToggle(false);
  const { mutateAsync: duplicateLead, isPending: isLeadDuplicating } = useDuplicateLead();

  const duplicateConfirmHandler = () => {
    if (lead) {
      duplicateLead({ leadGid: lead.gid }).then(data => {
        const {
          lead: { gid: leadGid }
        } = data;

        navigate(guidedSellingExperienceNavigation.forLead({ lead_gid: leadGid }));
        analytics.track('New Lead was created', { from: 'scouting_report_reopen', lead_gid: leadGid });
      });
    }
    toggleShowConfirmationModal();
  };

  return (
    <>
      <Button loading={isLeadDuplicating} onClick={toggleShowConfirmationModal} size={ButtonSize.Small}>
        {featureFlags.cstGuidedSellingExperience ? 'Service' : 'Reopen'}
      </Button>
      {showConfirmationModal && (
        <Modal
          confirmHandler={duplicateConfirmHandler}
          cancelHandler={toggleShowConfirmationModal}
          title="Confirmation"
          confirmText="Confirm"
        >
          Are you sure you want to {featureFlags.cstGuidedSellingExperience ? 'service' : 'reopen'} this lead?
        </Modal>
      )}
    </>
  );
};

export default ReopenButton;
