/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import Loader from '../../../../components/common/Loader';
import Button, { ButtonVariant } from '../../../../components/core/buttons/Button';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { CheckIcon, CircleIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';

export const MENU_TRANSITION_DURATION = '300ms';
const SUB_MENU_TRANSITION_DURATION = '200ms';

interface IMenuItem {
  IconComponent: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  label: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  menuOpened: boolean;
  active: boolean;
  completed: boolean;
  disabled: boolean;
  progress?: boolean;
  itemsAvailable?: boolean;
  children?: React.ReactNode;
  submenuItemsCount?: number;
  collectedByComponent?: React.ReactNode;
}

const MenuItem = ({
  IconComponent,
  label,
  onClick,
  menuOpened,
  active,
  completed,
  disabled,
  progress = false,
  itemsAvailable = false,
  children,
  submenuItemsCount = 0,
  collectedByComponent
}: IMenuItem) => {
  let menuItemColor;

  if (disabled) {
    menuItemColor = colors.grey30;
  } else if (active) {
    menuItemColor = colors.azure50;
  } else {
    menuItemColor = colors.black;
  }

  return (
    <Container>
      {!menuOpened && collectedByComponent}
      <Button
        variant={ButtonVariant.Text}
        onClick={event => {
          event.stopPropagation();
          onClick(event);
        }}
        disabled={disabled}
      >
        <FlexBox pv={spacings.px0} gap={spacings.px8}>
          <Container>
            <IconComponent
              css={css`
                width: ${menuOpened ? spacings.px0 : spacings.px24}px;
                min-width: ${menuOpened ? spacings.px0 : spacings.px20}px;
                min-height: ${spacings.px20}px;

                color: ${menuItemColor};

                transition-property: width, min-width, min-height;
                transition-duration: ${MENU_TRANSITION_DURATION};
                transition-timing-function: ease;
              `}
            />
            <Container
              customCss={css`
                position: absolute;
                top: -3px;
                right: -3px;
                width: 6px;
                height: 6px;
                border-radius: 3px;
                background-color: ${colors.statusGreen};

                visibility: ${itemsAvailable && !menuOpened ? 'visible' : 'hidden'};
                opacity: ${menuOpened ? 0 : 1};
                transition-property: visibility, opacity;
                transition-duration: ${MENU_TRANSITION_DURATION};
                transition-timing-function: ease;
              `}
            />
          </Container>
          {progress && menuOpened && <Loader width={spacings.px20} height={spacings.px20} />}
          {!progress && completed && <CheckIcon color={colors.statusGreen} />}
          {!progress && !completed && <CircleIcon color={disabled ? colors.grey30 : colors.azure50} />}
          <Text
            bold
            type="small"
            singleLine
            color={menuItemColor}
            customCss={css`
              text-align: left;
              visibility: ${menuOpened ? 'visible' : 'hidden'};
              opacity: ${menuOpened ? 1 : 0};
              width: ${menuOpened ? '140px' : '0px'};

              transition-property: visibility, opacity, width;
              transition-duration: ${MENU_TRANSITION_DURATION};
              transition-timing-function: ease;
            `}
          >
            {label}
          </Text>
        </FlexBox>
      </Button>
      <Container
        ml={spacings.px16}
        customCss={css`
          max-height: ${menuOpened ? `${submenuItemsCount * 40}px` : '0px'};
          visibility: ${menuOpened ? 'visible' : 'hidden'};
          opacity: ${menuOpened ? 1 : 0};
          overflow-x: clip;

          transition-property: visibility, opacity, max-height;
          transition-duration: ${SUB_MENU_TRANSITION_DURATION};
          transition-timing-function: ease-out;
        `}
      >
        {children}
      </Container>
    </Container>
  );
};

export default MenuItem;
