/** @jsxImportSource @emotion/react */
import { SerializedStyles } from '@emotion/react';
import { Field, FieldProps } from 'formik';

import { QuestionVerificationStatus } from '../../../../interfaces/IWorkflow';
import { InputSize, LabelSize, spacings } from '../../../../theme/variables';
import Select, { SelectProps } from '../../inputs/Select';
import FormDescription from './FormDescription';
import FormError from './FormError';
import LabeledInput from './LabeledInput';

export interface SelectFieldProps extends Partial<SelectProps> {
  label: string | JSX.Element;
  id: string;
  name: string;
  required?: boolean;
  options: readonly Record<string, any>[];
  requireTouchToShowError?: boolean;
  secondary?: boolean;
  preserveErrorSpace?: boolean;
  customLabelCss?: SerializedStyles;
  labelSize?: LabelSize;
  inputSize?: InputSize;
  testId?: string;
  description?: string;
  info?: string;
  verificationStatus?: QuestionVerificationStatus;
}

const SelectField = ({
  label,
  id,
  name,
  required,
  requireTouchToShowError = true,
  inline,
  secondary = false,
  preserveErrorSpace = true,
  customLabelCss,
  labelSize,
  inputSize,
  testId,
  description,
  info,
  verificationStatus,
  ...rest
}: SelectFieldProps): JSX.Element => {
  return (
    <LabeledInput
      id={id}
      required={required}
      label={label}
      inline={inline}
      secondary={secondary}
      disabled={rest.disabled}
      customCss={customLabelCss}
      labelSize={labelSize}
      inputSize={inputSize}
      info={info}
      verificationStatus={verificationStatus}
    >
      <Field name={name}>
        {({ field, meta, form }: FieldProps) => {
          const hasError = meta.error && (requireTouchToShowError ? meta.touched : true);

          return (
            <>
              <Select
                {...field}
                {...rest}
                controlTestId={testId}
                disabled={rest.disabled || form.status === 'disabled'}
                onBlur={() => form.setFieldTouched(name, true)}
                inputId={id}
                inline={inline}
                inputSize={inputSize}
                hasError={!!hasError}
                aria-invalid={!!hasError}
                aria-errormessage={`${id}-error`}
              />
              {inline && !!hasError && <FormError id={id} hasError={!!hasError} error={meta.error} />}
              {!inline && (preserveErrorSpace || !!hasError) && (
                <FormError id={id} hasError={!!hasError} error={meta.error} />
              )}
              {description && <FormDescription description={description} mt={spacings.px4} />}
            </>
          );
        }}
      </Field>
    </LabeledInput>
  );
};

export default SelectField;
