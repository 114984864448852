import { css } from '@emotion/react';

import { spacings } from '../../../../theme/variables';

export const contextGridStyle = css`
  display: grid;

  grid-template-rows: auto auto auto auto;
  grid-template-columns: auto 123px 1fr;

  gap: ${spacings.px16}px ${spacings.px12}px;
`;
