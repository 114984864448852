/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import { ICallNote, INote } from '../../interfaces';
import authInfo from '../../services/authInfo';
import colors from '../../theme/colors';
import { spacings } from '../../theme/variables';
import { DASHBOARD_DATE_TIME_FORMAT, dateTimeFormatter } from '../../utils/formatter';
import FlexBox from './FlexBox';
import Text from './Text';

const Note = ({ note }: { note: INote | ICallNote }): JSX.Element => {
  const agent =
    note.agent?.email === authInfo.currentUserEmail
      ? 'you'
      : note.agent?.name || ('partner_signature' in note && note?.partner_signature) || 'unknown user';

  return (
    <FlexBox
      columnDirection
      justifySpaceBetween
      p={spacings.px8}
      roundBorder
      border
      borderColor={colors.sun}
      fitParentWidth
      alignItemsFlexStart
      css={css`
        white-space: pre-wrap;
        overflow-wrap: anywhere;
        min-height: 66px;
        background: rgba(249, 225, 75, 0.12);
      `}
    >
      <Text className="fs-mask" mb={spacings.px16}>
        {note.description}
      </Text>
      <FlexBox justifySpaceBetween fitParentWidth>
        <Text color={colors.grey60}>by {agent}</Text>
        <Text color={colors.grey60}>on {dateTimeFormatter(note.created_at, DASHBOARD_DATE_TIME_FORMAT)}</Text>
      </FlexBox>
    </FlexBox>
  );
};

export default Note;
