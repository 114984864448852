/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import ReactTooltip from 'react-tooltip';

import Tooltip from '../../../../components/common/Tooltip/NewTooltip';
import CircledPolicyTypeIcon from '../../../../components/core/CircledPolicyTypeIcon';
import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ErrorIcon, ShieldCheckIcon } from '../../../../components/core/icons';
import Paragraph from '../../../../components/core/Paragraph';
import Tag from '../../../../components/core/Tag';
import Text from '../../../../components/core/Text';
import featureFlags from '../../../../constants/featureFlags';
import { IPersonOnlineSession } from '../../../../interfaces';
import { IPersonOnlineSessionStatus } from '../../../../interfaces/IPersonOnlineSession';
import { QuoteKind } from '../../../../interfaces/IQuote';
import useQuote from '../../../../queries/people/person_quotes/useQuote';
import useQuotesRequests from '../../../../queries/quotes_requests/useQuotesRequests';
import colors from '../../../../theme/colors';
import { borderRadius, spacings } from '../../../../theme/variables';
import { moneyFormatter, relativeTimeFormatter } from '../../../../utils/formatter';
import ExternalLinkModal from '../../../GuidedQuoting/Quotes/_components/ExternalLinkModal';
import Bridge from '../../../GuidedQuoting/Quotes/_components/QuoteRow/Bridge';
import DeprecatedStatus from '../../../GuidedQuoting/Quotes/_components/QuoteRow/DeprecatedStatus';
import Status from '../../../GuidedQuoting/Quotes/_components/QuoteRow/Status';
import { isQuoteEligibleOrPartlyEligible } from '../../../GuidedQuoting/Quotes/_helpers';
import useCustomerChoices from '../../../GuidedQuoting/Quotes/_hooks/useCustomerChoices';

interface OnlineBindSessionContextInfo {
  personGid: string;
  leadGid: string;
  personOnlineSession: IPersonOnlineSession;
}

const buildOnlineSessionStatus = (personOnlineSession: IPersonOnlineSession) => {
  if (personOnlineSession.status === IPersonOnlineSessionStatus.Success) {
    return 'Customer has successfully bought policy via online portal';
  }

  if (['final_quote', 'check_out'].includes(personOnlineSession.current_page_key)) {
    return 'Customer requested quotes via online portal';
  }

  return 'Customer is in progress of collecting data to buy policy via online portal';
};

const OnlineBindSessionContextInfo = ({ personGid, leadGid, personOnlineSession }: OnlineBindSessionContextInfo) => {
  const { data: initialQuote } = useQuote({ personGid, quoteGid: personOnlineSession.original_quote_gid });
  const { data: quotesRequests } = useQuotesRequests({ personGid, leadGid });
  const { customerChoices } = useCustomerChoices({ personGid, leadGid, quotesRequests });

  const customerChoiceQuote = customerChoices?.[0];

  const [showBridgeModal, setShowBridgeModal] = React.useState(false);

  const onlineSessionStatus = buildOnlineSessionStatus(personOnlineSession);
  const initialPremium = initialQuote?.attributes?.premium;

  const isQuoteFinalized = customerChoiceQuote && customerChoiceQuote.kind === QuoteKind.RC2;
  const isBindNotSuccessful = personOnlineSession.failure_reason === 'bind_not_successful';

  React.useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Container data-testid="context">
      <Paragraph type="large" bold>
        Context
      </Paragraph>

      <FlexBox mt={spacings.px8} gap={spacings.px8} justifySpaceBetween>
        <Paragraph>{onlineSessionStatus}</Paragraph>
        <Text
          color={colors.grey60}
          type="small"
          customCss={css`
            min-width: 80px;
            text-align: right;
          `}
        >
          {relativeTimeFormatter(personOnlineSession.last_interaction_at)}
        </Text>
      </FlexBox>

      {initialPremium && (
        <Paragraph
          type="small"
          color={colors.grey60}
          mt={spacings.px8}
        >{`Initial quote price they saw was ${moneyFormatter(initialPremium, true)}`}</Paragraph>
      )}

      {isQuoteFinalized && (
        <FlexBox mt={spacings.px32} gap={spacings.px8}>
          <FlexBox id="policy-type">
            <CircledPolicyTypeIcon policyType={customerChoiceQuote.attributes.policy_type} />
          </FlexBox>
          <FlexBox fitParentWidth gap={spacings.px4} id="else" columnDirection>
            <FlexBox id="carrier + tags" justifySpaceBetween>
              <FlexBox
                columnDirection
                customCss={css`
                  width: 120px;
                `}
                id="carrier-name + premium"
              >
                <FlexBox gap={spacings.px4} alignItemsCenter>
                  <span
                    data-tip={customerChoiceQuote.attributes.carrier.name}
                    data-for="customers-choice-carrier-name-tooltip"
                    css={css`
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      overflow: hidden;
                    `}
                  >
                    <Text bold>{customerChoiceQuote.attributes.carrier.name}</Text>
                  </span>
                  <Bridge quote={customerChoiceQuote} compact onBridge={() => setShowBridgeModal(true)} />
                </FlexBox>
                {!personOnlineSession.failure_reason && isQuoteEligibleOrPartlyEligible(customerChoiceQuote) && (
                  <FlexBox alignItemsCenter gap={spacings.px4}>
                    <ShieldCheckIcon
                      color={colors.statusGreen}
                      data-tip="Near final price with reports"
                      data-for="near-final-price-tooltip"
                    />
                    <Text>{moneyFormatter(customerChoiceQuote.attributes.premium, true)}</Text>
                  </FlexBox>
                )}
              </FlexBox>
              <FlexBox
                gap={spacings.px4}
                columnDirection
                customCss={css`
                  align-items: flex-end;
                `}
              >
                <Tag label="Customer's choice" backgroundColor={colors.violet} borderRadius={borderRadius} />
              </FlexBox>
            </FlexBox>
            {featureFlags.newQuotesUI ? (
              <Status quote={customerChoiceQuote} />
            ) : (
              <DeprecatedStatus
                quote={customerChoiceQuote}
                withIcon
                tagCss={css`
                  width: 100%;
                `}
              />
            )}

            {isBindNotSuccessful && (
              <Tag
                transparent={true}
                textColor={colors.statusRed}
                backgroundColor={colors.statusRed}
                prefix={<ErrorIcon color={colors.statusRed} />}
                label="Payment was not successful, try to bind again."
                singleLine={false}
                customCss={css`
                  width: 100%;
                `}
              />
            )}
          </FlexBox>
        </FlexBox>
      )}
      {!isQuoteFinalized && initialQuote && (
        <FlexBox mt={spacings.px32}>
          <CircledPolicyTypeIcon policyType={personOnlineSession.policy_type} />
          <FlexBox columnDirection fitParentWidth ml={spacings.px8} gap={spacings.px4}>
            <FlexBox alignItemsCenter justifySpaceBetween>
              <span
                data-tip={initialQuote.attributes.carrier.name}
                data-for="customers-choice-carrier-name-tooltip"
                css={css`
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  width: 120px;
                `}
              >
                <Text bold>{initialQuote.attributes.carrier.name}</Text>
              </span>
              <Tag label="Customer's choice" backgroundColor={colors.violet} borderRadius={borderRadius} />
            </FlexBox>
            {personOnlineSession.failure_reason === 'primary_insured_disqualified' ? (
              <Tag
                data-tip={`Rejected due to ${personOnlineSession.carrier?.name}'s underwriting rules.`}
                data-for="session-failure-reason-tooltip"
                transparent={true}
                textColor={colors.statusRed}
                backgroundColor={colors.statusRed}
                prefix={<ErrorIcon color={colors.statusRed} />}
                label={`Rejected due to ${personOnlineSession.carrier?.name}'s underwriting rules.`}
                singleLine={false}
                customCss={css`
                  width: 100%;
                `}
              />
            ) : (
              <Text>The final premium is not yet ready as data collection is not yet complete.</Text>
            )}
          </FlexBox>
        </FlexBox>
      )}
      <Tooltip id="customers-choice-carrier-name-tooltip" />
      <Tooltip id="near-final-price-tooltip" />
      <Tooltip id="session-failure-reason-tooltip" />
      {showBridgeModal && customerChoiceQuote && (
        <ExternalLinkModal
          bindingUrl={customerChoiceQuote.attributes.binding_url}
          externalId={customerChoiceQuote.attributes.external_id}
          notice={customerChoiceQuote.attributes.notice}
          carrier={customerChoiceQuote.attributes.carrier}
          cancelBtnHandler={() => setShowBridgeModal(false)}
        />
      )}
    </Container>
  );
};

export default OnlineBindSessionContextInfo;
