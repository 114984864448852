import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { isInsurableInterestRealProperty, isInsurableInterestVehicle } from '../../../interfaces/IPolicyType';
import { spacings } from '../../../theme/variables';
import NonSyncedPolicyHome from '../NonSyncedPolicyHome';
import NonSyncedPolicyVehicles from '../NonSyncedPolicyVehicles';

const pickAssetsComponent = (policy: IMaticPolicy) => {
  if (isInsurableInterestRealProperty(policy.policy_type)) {
    return NonSyncedPolicyHome;
  }

  if (isInsurableInterestVehicle(policy.policy_type)) {
    return NonSyncedPolicyVehicles;
  }
};

const NonSyncedPolicyAssetsContainer = ({ policy }: { policy: IMaticPolicy }) => {
  const AssetsComponent = pickAssetsComponent(policy);

  if (!AssetsComponent) {
    return null;
  }

  return (
    <FlexBox pb={spacings.px16}>
      <AssetsComponent policy={policy} />
    </FlexBox>
  );
};

export default NonSyncedPolicyAssetsContainer;
