import moment from 'moment';

import { MAX_AGE_DRIVER, MIN_AGE_DRIVER } from '../utils/yupRules';
import { IAddress } from './IAddress';

export enum MaritalStatus {
  Married = 'married',
  Single = 'single',
  DomesticPartnership = 'domestic_partnership',
  Divorced = 'divorced',
  Widowed = 'widowed',
  Separated = 'separated'
}

export enum Gender {
  Male = 'male',
  Female = 'female',
  NonBinary = 'nonbinary'
}

export enum RelationKind {
  Spouse = 'spouse',
  DomesticPartner = 'domestic_partner',
  Child = 'child',
  Parent = 'parent',
  Relative = 'relative',
  Other = 'other'
}

export interface IPerson {
  gid: string;

  first_name: string;
  middle_name: string | null;
  last_name: string;
  name: string;
  email: string | null;
  phone: string | null;
  secondary_phone: string | null;
  business_phone: string | null;
  date_of_birth: string | null;
  ssn: string | null;

  living_address: IAddress | null;
  mailing_address: IAddress | null;

  marital_status: MaritalStatus | null;
  fico_score: number | null;
  gender: Gender | null;
  education: string | null;
  occupation_type: string | null;
  occupation_since_date: string | null;
  employer_name: string | null;

  license_status: string | null;
  license_number: string | null;
  license_state: string | null;
  age_first_licensed: number | null;

  primary_driven_vehicle_gid: string | null;
}

export interface IPersonRelation {
  kind: RelationKind | null;
  cocustomer: boolean | null;
}

export type IRelatedPerson = IPerson & IPersonRelation;

export interface IPersonWithDncStatus extends IPerson {
  do_not_contact: boolean;
}

export const isPersonMarried = (maritalStatus?: IPerson['marital_status']) => maritalStatus === MaritalStatus.Married;

export const personAge = ({ date_of_birth }: { date_of_birth: IPerson['date_of_birth'] }) => {
  if (!date_of_birth) {
    return null;
  }

  return moment().diff(moment(date_of_birth), 'years');
};

export const isPersonAgedToDrive = ({ date_of_birth }: { date_of_birth: IPerson['date_of_birth'] }) => {
  if (!date_of_birth) {
    return true;
  }

  const age = personAge({ date_of_birth }) || 0;

  return age >= MIN_AGE_DRIVER && age <= MAX_AGE_DRIVER;
};

export const isDriverInfoCollected = ({ date_of_birth, gender, marital_status }: IPerson) =>
  !!date_of_birth && !!gender && !!marital_status;

export const presentPersonPhone = (person: IPerson | undefined) =>
  person?.phone || person?.secondary_phone || person?.business_phone || undefined;
