import * as React from 'react';

import authInfo from '../../services/authInfo';

const KEYS = {
  staging: '543357534ID',
  production: '993388774ID'
} as const;

const UserGuiding = ({ environment }: { environment: 'staging' | 'production' }) => {
  React.useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `(function (g, u, i, d, e, s) {
                if (${import.meta.env.DEV}) return;
                g[e] = g[e] || [];
                var f = u.getElementsByTagName(i)[0];
                var k = u.createElement(i);
                k.async = true;
                k.src = "https://static.userguiding.com/media/user-guiding-" + s + "-embedded.js";
                f.parentNode.insertBefore(k, f);
                if (g[d]) return;
                var ug = (g[d] = { q: [] });
                ug.c = function (n) {
                    return function () {
                        ug.q.push([n, arguments]);
                    };
                };
                var m = ["previewGuide", "finishPreview", "track", "triggerNps", "hideChecklist", "launchChecklist"];
                for (var j = 0; j < m.length; j += 1) {
                    ug[m[j]] = ug.c(m[j]);
                }
            })(window, document, "script", "userGuiding", "userGuidingLayer", "${KEYS[environment]}");
            `;

    if (!(window as any).userGuiding) {
      window.document.head.appendChild(script);
      // @ts-expect-error 3rd party script
      window.onUserGuidingLoad = () => {
        // @ts-expect-error 3rd party script
        window.userGuiding.identify(`${authInfo.currentUserId}`, {
          name: authInfo.currentUserName,
          email: authInfo.currentUserEmail,
          role: authInfo.role
        });
      };
    }
  }, [environment]);

  return null;
};

export default UserGuiding;
