import { useQuery } from '@tanstack/react-query';

import { IPartner } from '../../interfaces';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

const usePartners = (term?: string) =>
  useQuery({
    queryKey: ['partners', { term }],
    queryFn: (): Promise<IPartner[]> => api.get(`/api/frontend/partners?${toQueryParams({ term })}`),
    staleTime: Infinity
  });

export default usePartners;
