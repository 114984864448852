import { useQueryClient } from '@tanstack/react-query';
import * as React from 'react';

import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import useLeadDispositions from '../../../queries/leads/dispositions/useLeadDispositions';
import useUpdateLeadDisposition, {
  DispositionUpdate
} from '../../../queries/leads/dispositions/useUpdateLeadDisposition';
import { LEAD_QUOTING_ACCESS_QUERY_KEY } from '../../../queries/leads/useLeadQuotingAccess';
import { Subscription } from '../../../services/ActionCable';
import analytics from '../../../services/analytics';
import { ActionCableContext } from '../../../utils/actioncable';

const useCurrentDispositionSubscription = () => {
  const { callLogId, leadGid, lead } = useGuidedSellingExperienceContext();
  const leadId = lead?.id;
  const { data: leadDispositions, refetch: refetchCurrentDisposition } = useLeadDispositions(leadId);

  const { mutateAsync: updateLeadDisposition, isPending: isUpdatingDisposition } = useUpdateLeadDisposition();

  const cable = React.useContext(ActionCableContext);
  const queryClient = useQueryClient();

  const updateDisposition = React.useCallback(
    (options: DispositionUpdate) => {
      analytics.track('Disposition changed', {
        call_log_id: callLogId,
        lead_gid: leadGid,
        place: 'scouting_report',
        disposition_type: options.disposition_type
      });

      return updateLeadDisposition(options);
    },
    [updateLeadDisposition, callLogId, leadGid]
  );

  React.useEffect(() => {
    let summaryChannel: Subscription;
    if (leadId && cable) {
      summaryChannel = cable.subscriptions.create(
        {
          channel: 'LeadSummaryChannel',
          lead_id: leadId
        },
        {
          received: () => {
            refetchCurrentDisposition();
            queryClient.invalidateQueries({ queryKey: [LEAD_QUOTING_ACCESS_QUERY_KEY, leadId] });
          }
        }
      );
    }

    return () => {
      if (leadId && cable) {
        summaryChannel.unsubscribe();
      }
    };
  }, [cable, leadId, refetchCurrentDisposition, queryClient]);

  const values = React.useMemo(
    () => ({
      leadDispositions,
      currentDisposition: leadDispositions?.current_disposition,
      updateDisposition,
      refetchCurrentDisposition,
      isUpdatingDisposition
    }),
    [leadDispositions, updateDisposition, refetchCurrentDisposition, isUpdatingDisposition]
  );

  return values;
};

export const CurrentDispositionSubscriptionContext = React.createContext<ReturnType<
  typeof useCurrentDispositionSubscription
> | null>(null);

const CurrentDispositionSubscriptionContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const data = useCurrentDispositionSubscription();

  return (
    <CurrentDispositionSubscriptionContext.Provider value={data}>
      {children}
    </CurrentDispositionSubscriptionContext.Provider>
  );
};

const useCurrentDispositionSubscriptionContext = () => {
  const data = React.useContext(CurrentDispositionSubscriptionContext);
  if (!data) {
    throw new Error('CurrentDispositionSubscriptionContext: No value provided');
  }
  return data;
};

export { CurrentDispositionSubscriptionContextProvider, useCurrentDispositionSubscriptionContext };
