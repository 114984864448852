import './AutoEditor.scss';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { ADDRESS_KEY_GARAGING, AutoForm, QUOTING_REQUIRED_MESSAGE, Translations } from '../../constants';
import {
  API_TO_SMARTY_STREETS_MAPPING,
  CITY,
  GARAGING_ADDRESS,
  GARAGING_ADDRESS_ATTRIBUTES,
  LINE1,
  STATE,
  ZIP
} from '../../constants/addressForm';
import { ANNUAL_MILEAGE, DAYS_PER_WEEK_DRIVEN, ONE_WAY_MILEAGE, OWNERSHIP, USAGE } from '../../constants/auto';
import { COMMUTE_DESTINATIONS, UsageType } from '../../interfaces/IPersonAsset';
import autoSearchEntities from '../../services/autoSearchEntities';
import CustomPropTypes from '../../utils/customPropTypes';
import { createSmartyStreetsMapping, dig, except, rejectEmpty, renameKeys } from '../../utils/object';
import { combine, requiredField, zipField } from '../../utils/yupRules';
import {
  calculateCommutingMileage,
  calculateDaysPerWeek,
  calculateMileage,
  hasWorkOrSchoolValue
} from '../AnnualMileageCalculator/helpers';
import { CopyField, ErrorCol, FormModal } from '../common';
import SmartyStreetsAddress from '../common/Address/Address';
import { AsyncSelect, Checkbox, Input, NumberInput, Select } from '../common/FormikComponents/FormikComponents';
import { generateInitialValues as generateInitialGaragingAddress } from '../common/nestedForms/Address';
import PersonNavLink from '../PersonNavLink';

const AUTO_SEARCH = 'auto_search';
const VEHICLE_BASIC_INFO_ERROR = 'Vehicle info or VIN are required';
const VIN_MIN_SYMBOLS_LENGTH = 9;

const findGaragingAddress = (addresses: any[]) =>
  addresses.find((item: { address_key: string }) => item.address_key === ADDRESS_KEY_GARAGING);

class AutoEditor extends React.Component<any, any> {
  formik = null;
  state = {
    editingVehicle: false,
    customVehicle: false,
    allYears: [],
    allMakes: [],
    allModels: [],
    allSubmodels: []
  };

  componentDidMount() {
    Promise.all([autoSearchEntities.getAllMakes(), autoSearchEntities.getAllYears()]).then(([makes, years]) => {
      this.setState({
        allMakes: rejectEmpty(makes).map((make: any) => ({ key: `${make}`, value: `${make}` })),
        allYears: rejectEmpty(years).map((year: any) => ({ key: `${year}`, value: `${year}` }))
      });
    });
  }

  initialGaragingAddress = () =>
    generateInitialGaragingAddress(
      dig(this.selectedAuto(), GARAGING_ADDRESS) || findGaragingAddress(this.props.customerAddresses)
    );

  selectedAuto = () => this.props.auto || {};

  generateInitialValues = () => ({
    id: this.selectedAuto().id || '',
    year: this.selectedAuto().year ? this.selectedAuto().year.toString() : undefined,
    make: this.selectedAuto().make || undefined,
    model: this.selectedAuto().model || undefined,
    submodel: this.selectedAuto().submodel || undefined,
    vin: this.selectedAuto().vin || '',
    auto_search: this.autoTitle() || '',
    ownership: this.selectedAuto().ownership,
    usage: this.selectedAuto().usage || AutoForm.AUTO_WORK_USAGE,
    one_way_mileage: this.selectedAuto().one_way_mileage || '',
    days_per_week_driven: this.selectedAuto().days_per_week_driven || '',
    annual_mileage: this.selectedAuto().annual_mileage || '',
    used_for_ridesharing: !!this.selectedAuto().used_for_ridesharing,
    plate_type: this.selectedAuto().plate_type || '',
    plate_number: this.selectedAuto().plate_number || '',
    [GARAGING_ADDRESS]: this.initialGaragingAddress()
  });

  onSubmit = (values: any) => {
    const garagingAddressValues = dig(values, GARAGING_ADDRESS) || {};
    const restValues = except(values, GARAGING_ADDRESS, AUTO_SEARCH);

    const normalizedParams = {
      ...restValues,
      [GARAGING_ADDRESS_ATTRIBUTES]: garagingAddressValues
    };

    return this.props.confirmBtnHandler(normalizedParams);
  };

  autoSearchView = (params: { errors?: any; showErrors?: any; values: any; setFieldValue?: any }) => {
    return !this.state.editingVehicle && !!params.values.id ? (
      this.staticAutoView(params as any)
    ) : (
      <>
        {!this.state.customVehicle && this.searchVehicleView(params)}
        {this.state.customVehicle && this.customVehicleView(params)}
        <div
          onClick={this.customVehicleOnChangeHandler.bind(this, params)}
          className="qa-toggle-custom-vehicle custom-vehicle-button u-cursor-pointer mt-n1 d-inline-block"
        >
          {this.state.customVehicle ? AutoForm.SEARCH_AUTOMATICALLY_MESSAGE : AutoForm.ENTER_MANUALLY_MESSAGE}
        </div>
      </>
    );
  };

  autoTitle = (vehicle = this.selectedAuto()) => {
    return [vehicle.year, vehicle.make, vehicle.model, vehicle.submodel].join(' ');
  };

  staticAutoView = (params: { setFieldValue: any; values: any }) => (
    <>
      <div className="mb-2 font-weight-bold text-truncate">{this.autoTitle()}</div>
      <div
        onClick={this.editVehicleOnChangeHandler.bind(this, params)}
        className="qa-edit-auto custom-vehicle-button u-cursor-pointer"
      >
        {AutoForm.EDIT_AUTO_SEARCH_ENTITY}
      </div>
    </>
  );

  onVehicleUpdate = (vehicle = {}, setFieldValue: (arg0: string, arg1: any) => void) => {
    const { make, model, submodel, year } = vehicle as any;

    setFieldValue('make', make || undefined);
    setFieldValue('model', model || undefined);
    setFieldValue('submodel', submodel || undefined);
    setFieldValue('year', year || undefined);
  };

  searchVehicleView = ({ errors, setFieldValue, showErrors, values }: any) => (
    <Row>
      <ErrorCol id={AUTO_SEARCH} sm={12} error={errors[AUTO_SEARCH]} when={showErrors}>
        <Label>Vehicle</Label>
        <Field
          component={AsyncSelect}
          name={AUTO_SEARCH}
          asyncAction={autoSearchEntities.get}
          optionsNormalizer={({ auto_search_entities }: any) =>
            (auto_search_entities || []).map((vehicle: { year: any; make: any; model: any; submodel: any }) => ({
              label: [vehicle.year, vehicle.make, vehicle.model, vehicle.submodel].join(' '),
              value: vehicle
            }))
          }
          onChange={(vehicle: object | undefined) => {
            this.onVehicleUpdate(vehicle, setFieldValue);
          }}
          className="qa-auto-search-entity-id"
          placeholder={AutoForm.AUTO_SEARCH_HINT}
          defaultValue={
            values.make || values.year
              ? {
                  value: this.autoTitle(values),
                  label: this.autoTitle(values)
                }
              : undefined
          }
        />
      </ErrorCol>
    </Row>
  );

  customVehicleView = ({ errors, showErrors, values }: any) => (
    <Row>
      <ErrorCol id="year" sm={3} error={errors.year} when={showErrors}>
        <Label>Year</Label>
        <Field
          component={Select}
          name="year"
          maxLength="4"
          defaultValue={values.year ? { label: values.year, value: values.year } : undefined}
          options={this.state.allYears}
          createOptionFromSearch
          showResetButton
          className="qa-auto-search-entity-attributes-year"
        />
      </ErrorCol>
      <ErrorCol id="make" sm={3} error={errors.make} when={showErrors}>
        <Label>Make</Label>
        <Field
          component={Select}
          name="make"
          placeholder="Ford"
          defaultValue={values.make ? { label: values.make, value: values.make } : undefined}
          options={this.state.allMakes}
          onChange={this.autoSearchMakeOnChangeHandler}
          createOptionFromSearch
          showResetButton
          className="qa-auto-search-entity-attributes-make"
        />
      </ErrorCol>
      <ErrorCol id="model" sm={3} error={errors.model} when={showErrors}>
        <Label>Model</Label>
        <Field
          component={Select}
          name="model"
          placeholder="Mustang"
          defaultValue={values.model ? { label: values.model, value: values.model } : undefined}
          options={this.state.allModels}
          onChange={(value: string | undefined) => this.autoSearchModelOnChangeHandler(value, values.make)}
          createOptionFromSearch
          showResetButton
          className="qa-auto-search-entity-attributes-model"
        />
      </ErrorCol>
      <ErrorCol id="submodel" sm={3} error={errors.submodel} when={showErrors}>
        <Label>Submodel</Label>
        <Field
          component={Select}
          name="submodel"
          placeholder="Coupe"
          options={this.state.allSubmodels}
          defaultValue={values.submodel ? { label: values.submodel, value: values.submodel } : undefined}
          createOptionFromSearch
          showResetButton
          className="qa-auto-search-entity-attributes-submodel"
        />
      </ErrorCol>
    </Row>
  );

  editVehicleOnChangeHandler = ({ setFieldValue, values }: any) => {
    // @ts-expect-error old code
    this.setState(({ editingVehicle }) => {
      setFieldValue('year', values.year);
      setFieldValue('make', values.make);
      setFieldValue('model', values.model);
      setFieldValue('submodel', values.submodel);

      return { editingVehicle: !editingVehicle };
    });
  };

  customVehicleOnChangeHandler = ({ setFieldValue, values }: any) => {
    // @ts-expect-error old code
    this.setState(({ customVehicle }) => {
      setFieldValue('year', values.year);
      setFieldValue('make', values.make);
      setFieldValue('model', values.model);
      setFieldValue('submodel', values.submodel);

      return { customVehicle: !customVehicle };
    });
  };

  autoSearchMakeOnChangeHandler = (value: string | undefined) => {
    if (!value) {
      return;
    }
    autoSearchEntities.getAllModels(value).then(models => {
      this.setState({ allModels: rejectEmpty(models).map((model: any) => ({ key: `${model}`, value: `${model}` })) });
    });
  };

  autoSearchModelOnChangeHandler = (value: string | undefined, make: string | undefined) => {
    if (!value || !make) {
      return;
    }
    autoSearchEntities.getAllSubmodels({ model: value, make }).then(submodels => {
      this.setState({
        allSubmodels: rejectEmpty(submodels).map((submodel: any) => ({ key: `${submodel}`, value: `${submodel}` }))
      });
    });
  };

  newOrCustomVehicleValidation = (values: { make: any; model: any; year: any; vin: any }) => {
    const { make, model, year, vin } = values;

    if (!(make && model && year) && !vin) {
      return {
        make: true,
        model: true,
        year: true,
        vin: true,
        vehicle_basic_info_error: VEHICLE_BASIC_INFO_ERROR
      };
    }

    return {};
  };
  filterVehicleOptions = (item: { label: string }, searchTerm: string | RegExp) =>
    !new RegExp(searchTerm).test(item.label);

  resetEditingVehicle = () => {
    this.setState({ editingVehicle: false });
  };

  render() {
    return (
      <FormModal
        className="auto-editor"
        open={this.props.open}
        cancelHandler={this.props.cancelBtnHandler}
        confirmHandler={this.onSubmit}
        onClosed={this.resetEditingVehicle}
        title={<PersonNavLink person={this.props.person} linkPrefix={`${AutoForm.AUTO_FORM_TITLE}.`} />}
        enableReinitialize
        initialValues={this.generateInitialValues()}
        validationSchema={yup.object().shape({
          vin: yup
            .string()
            .min(VIN_MIN_SYMBOLS_LENGTH, `should be equal or more than ${VIN_MIN_SYMBOLS_LENGTH} symbols length`),
          year: yup.number().min(1801, 'Should greater than 1800'),
          [USAGE]: yup.string(),
          [DAYS_PER_WEEK_DRIVEN]: yup.number().when(COMMUTE_DESTINATIONS, {
            is: () => true,
            then: schema =>
              schema.min(1, 'Should be greater or equal to 1 day').max(7, 'Should be less or equal to 7 days')
          }),
          [ANNUAL_MILEAGE]: yup.number().when('id', {
            is: (value: any) => !value,
            then: schema =>
              schema
                .min(1000, 'Should be equal or greater than 1,000 miles')
                .max(99998, 'Should be lower than 99,999 miles')
          }),
          [ONE_WAY_MILEAGE]: yup.number().positive('Must be greater than 0').max(399, 'Should be less than 400 miles'),
          [GARAGING_ADDRESS]: yup.object().shape({
            [LINE1]: requiredField,
            [CITY]: requiredField,
            [STATE]: requiredField,
            [ZIP]: combine(requiredField, zipField)
          })
        })}
        validate={this.newOrCustomVehicleValidation}
        formRef={formik => (this.formik = formik)}
        renderForm={({ errors, values, showErrors, setFieldValue }) => {
          return (
            <Container className="mt-3">
              <Row>
                <Col sm={2}>
                  Vehicle <br /> basic info *
                  {errors.vehicle_basic_info_error && showErrors ? (
                    <div className="custom-error-message">{errors.vehicle_basic_info_error}</div>
                  ) : null}
                </Col>
                <Col sm={10}>
                  {this.autoSearchView({ errors, showErrors, values, setFieldValue })}
                  <Row>
                    <ErrorCol id="vin" sm={12} error={errors.vin} when={showErrors}>
                      <Label>VIN</Label>
                      <CopyField value={values.vin}>
                        <Field component={Input} name="vin" className="fs-mask" />
                      </CopyField>
                    </ErrorCol>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col sm={2}>Vehicle additional info</Col>
                <Col sm={10}>
                  <Row>
                    <ErrorCol sm={3} id={USAGE} warning={QUOTING_REQUIRED_MESSAGE} when={!values[USAGE]}>
                      <Label>Vehicle usage</Label>
                      <Field
                        component={Select}
                        name={USAGE}
                        options={Translations.vehicleUsageOptions}
                        onChange={(value: UsageType) => {
                          setFieldValue(
                            ANNUAL_MILEAGE,
                            calculateMileage(value, values.days_per_week_driven, values.one_way_mileage)
                          );
                        }}
                      />
                    </ErrorCol>
                    {hasWorkOrSchoolValue(values.usage) && (
                      <ErrorCol sm={3} id={DAYS_PER_WEEK_DRIVEN} error={errors[DAYS_PER_WEEK_DRIVEN]} when={showErrors}>
                        <Label>Days driven weekly</Label>
                        <Field
                          component={NumberInput}
                          name={DAYS_PER_WEEK_DRIVEN}
                          onChange={(value: string) => {
                            setFieldValue(ANNUAL_MILEAGE, calculateCommutingMileage(value, values.one_way_mileage));
                          }}
                        />
                      </ErrorCol>
                    )}
                    {hasWorkOrSchoolValue(values.usage) && (
                      <ErrorCol sm={3} id={ONE_WAY_MILEAGE} error={errors[ONE_WAY_MILEAGE]} when={showErrors}>
                        <Label>One-way mileage</Label>
                        <Field
                          component={NumberInput}
                          name={ONE_WAY_MILEAGE}
                          onChange={(value: string) => {
                            setFieldValue(
                              ANNUAL_MILEAGE,
                              calculateCommutingMileage(values.days_per_week_driven, value)
                            );
                          }}
                        />
                      </ErrorCol>
                    )}
                    <ErrorCol
                      sm={3}
                      id={ANNUAL_MILEAGE}
                      warning={!errors[ANNUAL_MILEAGE] && QUOTING_REQUIRED_MESSAGE}
                      error={errors[ANNUAL_MILEAGE]}
                      when
                    >
                      <Label>Annual Mileage</Label>
                      <Field
                        component={NumberInput}
                        name={ANNUAL_MILEAGE}
                        onChange={(value: string) => {
                          setFieldValue(
                            DAYS_PER_WEEK_DRIVEN,
                            calculateDaysPerWeek(values.usage, value, values.one_way_mileage)
                          );
                        }}
                      />
                    </ErrorCol>
                  </Row>
                  <Row>
                    <ErrorCol sm={4}>
                      <Label>Plate type</Label>
                      <Field component={Input} name="plate_type" className="fs-mask" />
                    </ErrorCol>
                    <ErrorCol sm={4}>
                      <Label>Plate number</Label>
                      <Field component={Input} name="plate_number" className="fs-mask" />
                    </ErrorCol>
                  </Row>
                  <Row>
                    <ErrorCol sm={4} id={OWNERSHIP} warning={QUOTING_REQUIRED_MESSAGE} when={!values[OWNERSHIP]}>
                      <Label>Ownership *</Label>
                      <Field
                        component={Select}
                        name={OWNERSHIP}
                        options={Translations.vehicleOwnershipOptions}
                        className="qa-auto-editor-ownership"
                      />
                    </ErrorCol>
                    <ErrorCol sm={8}>
                      <div className="d-flex align-items-center pt-3" style={{ height: '100%' }}>
                        <Field name="used_for_ridesharing" component={Checkbox} />
                        <span className="ml-2">Used for ridesharing</span>
                      </div>
                    </ErrorCol>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col sm={2}>Garaging address *</Col>
                <Col sm={10}>
                  <SmartyStreetsAddress
                    label="Garaging address *"
                    placeholder="Search for an address"
                    // @ts-expect-error old code
                    defaultValue={renameKeys(API_TO_SMARTY_STREETS_MAPPING, this.initialGaragingAddress())}
                    defaultOptions={this.props.customerAddresses.map((address: any) =>
                      renameKeys(API_TO_SMARTY_STREETS_MAPPING, address)
                    )}
                    fallbackNames={createSmartyStreetsMapping(`${GARAGING_ADDRESS}.`)}
                  />
                </Col>
              </Row>
            </Container>
          );
        }}
      />
    );
  }
}
// @ts-expect-error old code
AutoEditor.propTypes = {
  open: PropTypes.bool,
  auto: CustomPropTypes.Auto,
  cancelBtnHandler: PropTypes.func.isRequired,
  confirmBtnHandler: PropTypes.func.isRequired,
  customerAddresses: PropTypes.array.isRequired,
  person: PropTypes.object
};

// @ts-expect-error old code
AutoEditor.defaultProps = {
  open: false,
  auto: {},
  person: null
};

export default AutoEditor;
