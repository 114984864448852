/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Container from '../../../../../components/core/Container';
import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import featureFlags from '../../../../../constants/featureFlags';
import { IQuote } from '../../../../../interfaces';
import { QuoteState, QuoteStatus } from '../../../../../interfaces/IQuote';
import colors from '../../../../../theme/colors';
import { spacings } from '../../../../../theme/variables';
import useQuotesModalView from '../../_hooks/useModalView';
import useSelectedQuote from '../../_hooks/useSelectedQuote';
import Bridge from './Bridge';
import Coverage from './Coverage';
import DeprecatedStatus from './DeprecatedStatus';
import InProgressQuoteRow from './InProgressQuoteRow';
import Premium from './Premium';
import Savings from './Savings';
import Status from './Status';

interface IQuoteRowProps {
  quote: IQuote;
  isPremiumAnnual?: boolean;
}

const LifeQuoteRow = ({ quote, isPremiumAnnual }: IQuoteRowProps) => {
  const { quotesModalView } = useQuotesModalView();
  const { setSelectedQuoteGid } = useSelectedQuote();

  const { attributes, state } = quote;
  const { carrier } = attributes;

  const isQuoteInfoAvailable = quote.status === QuoteStatus.Success;

  if (state === QuoteState.Started) {
    return <InProgressQuoteRow quote={quote} />;
  }

  return (
    <FlexBox
      columnDirection
      borderBottom
      roundBorder
      backgroundColor={colors.white}
      borderColor={colors.grey10}
      mt={spacings.px0}
      customCss={css`
        min-width: fit-content;
      `}
    >
      <FlexBox
        justifySpaceBetween
        ph={spacings.px16}
        pv={spacings.px6}
        alignItemsCenter
        customCss={css`
          position: relative;
        `}
      >
        <Container
          pv={spacings.px4}
          customCss={css`
            min-width: 140px;
          `}
        >
          <span data-tip={carrier.name} data-for="carrier-name-tooltip">
            <div
              css={css`
                background-position: left;
                background-repeat: no-repeat;
                background-size: contain;
                height: 30px;
                margin: 0 auto;
                width: 100px;
                filter: grayscale(100%);
                ${carrier.logo ? `background-image: url(${carrier.logo})` : ''};
              `}
              title={carrier.name}
            />
          </span>
        </Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 140px;
          `}
        >
          {featureFlags.newQuotesUI ? <Status quote={quote} /> : <DeprecatedStatus quote={quote} />}
        </Container>

        <Container
          color={colors.grey60}
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 180px;
          `}
        >
          {isQuoteInfoAvailable && <Premium quote={quote} isPremiumAnnual={isPremiumAnnual} />}
          {!isQuoteInfoAvailable && attributes.promotion_message && (
            <Container
              customCss={css`
                width: 180px;
              `}
            >
              <Text
                customCss={css`
                  white-space: nowrap;
                `}
              >
                {attributes.promotion_message}
              </Text>
            </Container>
          )}
        </Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 140px;
          `}
        >
          {isQuoteInfoAvailable && <Coverage quote={quote} />}
        </Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 140px;
          `}
        >
          {null}
        </Container>

        <Container
          pl={spacings.px8}
          pv={spacings.px4}
          customCss={css`
            min-width: 210px;
          `}
        >
          {isQuoteInfoAvailable && <Savings quote={quote} />}
        </Container>

        {!quotesModalView && (
          <Container
            pl={spacings.px8}
            pv={spacings.px4}
            customCss={css`
              min-width: 180px;
            `}
          >
            <Bridge quote={quote} onBridge={quoteGid => setSelectedQuoteGid(quoteGid)} />
          </Container>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default LifeQuoteRow;
