/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import colors from '../../theme/colors';
import Container, { IContainer } from './Container';
import { QuoteIcon } from './icons';

interface TagProps extends IContainer {
  text: JSX.Element | string;
  textColor?: (typeof colors)[keyof typeof colors];
  iconBacking?: number;
}

const ICON_WIDTH = 20;

const Blockquote = ({ iconBacking = 6, text, textColor, ...rest }: TagProps) => {
  return (
    <Container
      {...rest}
      customCss={css`
        position: relative;
        ${textColor && `color: ${textColor};`}
      `}
    >
      <blockquote
        css={css`
          margin: 0;
        `}
      >
        <QuoteIcon
          color={colors.violet}
          css={css`
            position: absolute;
            margin-left: -${ICON_WIDTH + 12}px;
            margin-top: ${iconBacking}px;
          `}
        />
        {text}
      </blockquote>
    </Container>
  );
};

export default Blockquote;
