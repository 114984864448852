import { skipToken, useQuery } from '@tanstack/react-query';

import { RateReviewSubscription } from '../../interfaces/IRateReviewSubscription';
import api from '../../services/api';

const getRateReviewSubscription = (
  personGid: string | undefined
): Promise<{ rate_review_subscription: RateReviewSubscription }> =>
  api.get(`/api/frontend/people/${personGid}/rate_review_subscription`);

const useRateReviewSubscription = (personGid: string | undefined) =>
  useQuery({
    queryKey: ['person_rate_review_subscription', personGid],
    queryFn: personGid ? () => getRateReviewSubscription(personGid) : skipToken,
    select: data => data.rate_review_subscription
  });

export default useRateReviewSubscription;
