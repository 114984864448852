/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import HomeLinks from '../../../../../components/common/HomeLinks';
import Button, { ButtonSize, ButtonVariant } from '../../../../../components/core/buttons/Button';
import CopyableAddress from '../../../../../components/core/CopyableAddress';
import FlexBox from '../../../../../components/core/FlexBox';
import Text from '../../../../../components/core/Text';
import { IHome, IOpportunity } from '../../../../../interfaces';
import { spacings } from '../../../../../theme/variables';

interface HomeFormTitleProps {
  personGid: string;
  homeOpportunity: IOpportunity;
  home: IHome;
  isSubmitting: boolean;
  onClose: () => void;
  submitForm: () => void;
}

const HomeFormTitle = ({ personGid, homeOpportunity, home, isSubmitting, onClose, submitForm }: HomeFormTitleProps) => (
  <>
    <FlexBox justifySpaceBetween alignItemsCenter>
      <Text type="small" bold>
        Home
      </Text>
      <FlexBox gap={spacings.px8}>
        <Button
          data-testid="cancel-panel-home-form"
          variant={ButtonVariant.Secondary}
          size={ButtonSize.Small}
          type="button"
          loading={isSubmitting}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          data-testid="submit-panel-home-form"
          variant={ButtonVariant.Default}
          size={ButtonSize.Small}
          type="submit"
          loading={isSubmitting}
          onClick={() => submitForm()}
        >
          Save
        </Button>
      </FlexBox>
    </FlexBox>

    <FlexBox gap={spacings.px8} alignItemsCenter justifySpaceBetween>
      <FlexBox
        gap={spacings.px8}
        alignItemsCenter
        customCss={css`
          flex-wrap: wrap;
        `}
      >
        <Text type="small" bold>
          {homeOpportunity.title}
        </Text>
        <CopyableAddress textType="small" address={home.address} />
      </FlexBox>
      <HomeLinks homeGid={home.gid} personGid={personGid} />
    </FlexBox>
  </>
);

export default HomeFormTitle;
