import { skipToken, useQuery } from '@tanstack/react-query';

import { MarketingOpportunities } from '../../interfaces/ISourceDimensions';
import api from '../../services/api';

interface MarketingOpportunity {
  label: string;
  description: string;
}

const useMarketingOpportunity = (key?: string) =>
  useQuery({
    queryKey: ['marketingOpportunity', key],
    queryFn:
      key && key !== MarketingOpportunities.Unknown
        ? (): Promise<{ marketing_opportunity: MarketingOpportunity }> =>
            api.get(`/api/frontend/marketing_opportunities/${key}`)
        : skipToken,
    select: data => data.marketing_opportunity
  });

export default useMarketingOpportunity;
