/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import * as yup from 'yup';

import SystemMessage from '../../../components/core/Alert/SystemMessage';
import Blockquote from '../../../components/core/Blockquote';
import { ButtonVariant } from '../../../components/core/buttons/Button';
import Container from '../../../components/core/Container';
import FlexBox from '../../../components/core/FlexBox';
import FormLoader from '../../../components/core/FormLoader';
import BaseForm from '../../../components/core/forms/BaseForm';
import { RadioGroupField } from '../../../components/core/forms/fields';
import Heading from '../../../components/core/Heading';
import { useGuidedSellingExperienceContext } from '../../../contexts/GuidedSellingExperienceContext';
import { Unpacked } from '../../../interfaces';
import { NO_YES_OPTIONS, YES_VALUE } from '../../../interfaces/IDatapoint';
import { MaritalStatus } from '../../../interfaces/IPerson';
import { PolicyType } from '../../../interfaces/IPolicyType';
import { acceptDisclosures, useActiveFCRAConsent, useFCRADisclosure } from '../../../queries/disclosures/useDisclosure';
import { useUpdateOpportunityCoverages } from '../../../queries/leads/data_collection/useDataCollectionOpportunities';
import {
  useLeadOpportunitiesCoinsureds,
  useUpdateLeadOpportunityCoinsureds
} from '../../../queries/leads/opportunities/useLeadOpportunities';
import useOpportunitiesWithPolicies from '../../../queries/leads/opportunities/useOpportunitiesWithPolicies';
import useQuotesRequests from '../../../queries/quotes_requests/useQuotesRequests';
import analytics from '../../../services/analytics';
import colors from '../../../theme/colors';
import { sizes, spacings } from '../../../theme/variables';
import { requiredField } from '../../../utils/yupRules';
import { findMatchingQuote } from '../../GuidedQuoting/Quotes/_helpers/quoteSavingsHelper';
import useCustomerChoices from '../../GuidedQuoting/Quotes/_hooks/useCustomerChoices';
import useQuotingAvailability from '../../GuidedQuoting/Quotes/_hooks/useQuotingAvailability';
import { StepContentProps } from '..';
import LeadWarnings from '../_components/LeadWarnings';
import ScrollToFirstError from '../_components/ScrollToFirstError';
import { selectConfiguredOpportunities, useStartConfiguredQuoting } from './_hooks/useStartConfiguredQuoting';
import {
  IOpportunityFormValues,
  IQuotingConfigurationFormValues
} from './_interfaces/IQuotingConfigurationOpportunity';
import { getPAFOpportunityId, opportunitiesInitialValues, STUB_FLOOD_PREFIX, STUB_PAF_OPPORTUNITY_ID } from './_utils';
import StubFloodOpportunity, { stubFloodOpportunityInitialValues } from './FloodOpportunity/StubFloodOpportunity';
import OpportunityConfigurationComponent from './OpportunityConfigurationComponent';
import StubPAFOpportunity, { stubOpportunityInitialValues } from './PAFOpportunity/StubPAFOpportunity';

const FCRA_ALERT_DESC =
  // eslint-disable-next-line
  "We cannot provide quotes without consent. This allows us to look up insurance score and claims history. Please note this does NOT impact credit score.";

const QuotingConfigurationPage = ({
  onSubmit,
  onMoveToOpportunity,
  dataCollection,
  page,
  isDataEditingForbidden
}: StepContentProps) => {
  const scrollToTopOnMount = React.useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      node.scrollIntoView(true);
    }
  }, []);
  const { personGid, person, lead, leadGid } = useGuidedSellingExperienceContext();

  const { data: fcraDisclosure, isPending: isPendingFcraDisclosure } = useFCRADisclosure();
  const {
    data: isFcraDisclosureAccepted,
    isLoading: isLoadingFcraConsent,
    refetch: refetchFcraConsent
  } = useActiveFCRAConsent(personGid);

  const { data: opportunitiesCoinsureds, isFetchedAfterMount: areCoinsuredsFetched } = useLeadOpportunitiesCoinsureds({
    leadId: lead?.id,
    opportunitiesIds: (page.opportunities || []).map(({ id }) => id)
  });

  const {
    opportunitiesWithPolicies = [],
    refetch: refetchCurrentPolicies,
    isPending: isPendingCurrentPolicies
  } = useOpportunitiesWithPolicies({
    personGid,
    opportunities: page.opportunities
  });

  const { data: quotesRequests, isPending: isPendingQuotesRequests } = useQuotesRequests({
    personGid: person!.gid,
    leadGid,
    options: {
      refetchInterval: query =>
        query.state.data?.quotes_requests && query.state.data.quotes_requests.some(({ finished_at }) => !finished_at)
          ? 5000
          : false
    }
  });

  const { customerChoices } = useCustomerChoices({ personGid, leadGid, quotesRequests });

  const quotingConfigurationOpportunities = opportunitiesWithPolicies.map((opportunity, index) => ({
    ...opportunity,
    coinsureds: opportunitiesCoinsureds?.[index]?.data?.people,
    customerChoice: findMatchingQuote(opportunity, customerChoices)
  }));

  const { mutateAsync: updateOpportunityCoinsureds } = useUpdateLeadOpportunityCoinsureds();
  const { mutateAsync: updateOpportunityCoverages } = useUpdateOpportunityCoverages();
  const { startConfiguredQuoting } = useStartConfiguredQuoting({ personGid: person!.gid, leadId: lead!.id });

  const offerStubPAFOpportunity =
    !quotingConfigurationOpportunities.some(({ policy_type }) => policy_type === PolicyType.PAF) &&
    !quotingConfigurationOpportunities.some(({ policy_type }) => policy_type === PolicyType.TermLife);

  const fireOrHomeOpportunities = quotingConfigurationOpportunities.filter(({ policy_type }) =>
    [PolicyType.Home, PolicyType.Fire].includes(policy_type)
  );

  const fireOrHomeOpportunitiesByAssetKey = fireOrHomeOpportunities.reduce(
    (prev, current) => ({
      ...prev,
      [(current.assets || []).map(a => a.gid).toString()]: current
    }),
    {} as Record<string, Unpacked<typeof fireOrHomeOpportunities>>
  );

  const floodOpportunities = quotingConfigurationOpportunities.filter(
    ({ policy_type }) => policy_type === PolicyType.Flood
  );

  const floodOpportunitiesByAssetKey = floodOpportunities.reduce(
    (prev, current) => ({
      ...prev,
      [(current.assets || []).map(a => a.gid).toString()]: current
    }),
    {} as Record<string, Unpacked<typeof floodOpportunities>>
  );

  const opportunitiesThatNeedAFloodOffer = Object.keys(fireOrHomeOpportunitiesByAssetKey)
    .filter(key => !floodOpportunitiesByAssetKey[key])
    .map(key => fireOrHomeOpportunitiesByAssetKey[key]);

  const offerFloodOpportunities = opportunitiesThatNeedAFloodOffer.length > 0;

  const stubFloodInitialValues = opportunitiesThatNeedAFloodOffer.reduce(
    (prev, current) => ({
      ...prev,
      [`${STUB_FLOOD_PREFIX}-${current!.id}`]: stubFloodOpportunityInitialValues({
        assets_gids: (current!.assets || []).map(a => a.gid),
        enabled: current!.is_data_collection_completed
      })
    }),
    {}
  );

  const { forbidQuoting } = useQuotingAvailability();

  if (
    !person ||
    !lead ||
    isPendingCurrentPolicies ||
    isPendingQuotesRequests ||
    !areCoinsuredsFetched ||
    isPendingFcraDisclosure ||
    isLoadingFcraConsent
  ) {
    return <FormLoader ph={spacings.px24} pv={spacings.px12} />;
  }

  const initialValues = {
    fcra: isFcraDisclosureAccepted ? YES_VALUE : '',
    paf_coverage_level: '',
    opportunities: {
      ...(offerFloodOpportunities ? stubFloodInitialValues : {}),
      ...(opportunitiesInitialValues({
        person,
        opportunities: quotingConfigurationOpportunities,
        quotesRequests
      }) || []),
      ...(offerStubPAFOpportunity
        ? { [STUB_PAF_OPPORTUNITY_ID]: stubOpportunityInitialValues(person.marital_status === MaritalStatus.Married) }
        : {})
    }
  } as IQuotingConfigurationFormValues & { fcra: string; paf_coverage_level: string };

  const PAFOpportunityId = getPAFOpportunityId(quotingConfigurationOpportunities);

  return (
    <BaseForm
      pt={spacings.px12}
      pr={spacings.px24}
      pb={60}
      pl={spacings.px24}
      type="fullPage"
      controlsAlignment="right"
      controlsWidth={sizes.mediumFormInputWidth}
      submitText="Request quotes"
      submitVariant={ButtonVariant.Default}
      submitTestId="request-quotes-button"
      initialValues={initialValues}
      validationSchema={yup.object().shape({
        fcra: requiredField.test('given', 'Consent must be given in order to run quotes', value => value === YES_VALUE),
        paf_coverage_level: yup.string().when('opportunities', {
          is: (opportunities: Record<string, IOpportunityFormValues>) => {
            const opportunity = opportunities[PAFOpportunityId || ''];
            if (opportunity && opportunity.enabled) {
              return !opportunity.agent_selected_coverages?.[0]?.coverages?.[0]?.limit_value;
            }

            return false;
          },
          then: schema => schema.required('Coverage level is required')
        })
      })}
      disabled={({ values }) => {
        if (!page.is_data_sufficient || isDataEditingForbidden) {
          return true;
        }

        return selectConfiguredOpportunities(quotingConfigurationOpportunities, values).length === 0 || forbidQuoting;
      }}
      onSubmit={async values => {
        const realOpportunitiesIds = Object.keys(values.opportunities)
          .filter(opportunityId => opportunityId !== STUB_PAF_OPPORTUNITY_ID)
          .filter(opportunityId => !opportunityId.startsWith(STUB_FLOOD_PREFIX))
          .filter(opportunityId => values.opportunities[opportunityId]!.enabled);

        realOpportunitiesIds.forEach(opportunityId => {
          if (
            values.opportunities[opportunityId]!.insured_gids?.sort().toString() !==
            initialValues.opportunities[opportunityId]!.insured_gids?.sort().toString()
          ) {
            analytics.track('Opportunity insured people updated', {
              person_gid: person.gid,
              insurable_interest: values.opportunities[opportunityId]!.insurable_interest,
              place: 'guided_selling_experience',
              page: 'quoting_configuration'
            });
          }
        });

        await Promise.all(
          realOpportunitiesIds.map(opportunityId =>
            updateOpportunityCoinsureds({
              leadId: lead.id,
              opportunityId: Number(opportunityId),
              peopleGids:
                values.opportunities[opportunityId]!.insured_gids?.filter(
                  insuredGid => insuredGid !== values.opportunities[opportunityId]!.primary_insured_gid
                ) || []
            })
          )
        );
        await Promise.all(
          realOpportunitiesIds.map(opportunityId => {
            const opportunityValues = values.opportunities[opportunityId]!;
            const agentSelectedCoverages = opportunityValues.agent_selected_coverages;

            if (!agentSelectedCoverages) {
              return Promise.resolve();
            }

            return updateOpportunityCoverages({
              leadId: lead.id,
              opportunityId: Number(opportunityId),
              coverages: agentSelectedCoverages
            });
          })
        );

        if (!isFcraDisclosureAccepted) {
          await acceptDisclosures({ personGid: personGid!, disclosures: [fcraDisclosure!] });
        }

        refetchFcraConsent();

        const isQuotingStarted = await startConfiguredQuoting(quotingConfigurationOpportunities, values);

        analytics.track('Quotes request started', {
          lead_gid: lead?.gid,
          person_gid: person?.gid,
          place: 'guided_selling_experience',
          page: 'quoting_configuration'
        });

        if (isQuotingStarted) {
          await onSubmit();
        }
      }}
      renderForm={({ values }) => {
        // We need to make sure that only opportunities that have been initialized via initialValues are rendered.
        // Otherwise, crash will occur when creating PAF opportunity and then refreshing data collection object.
        const realOpportunities = quotingConfigurationOpportunities.filter(opp =>
          Object.keys(values.opportunities).includes(opp.id.toString())
        );

        return (
          <FlexBox
            columnDirection
            ref={scrollToTopOnMount}
            customCss={css`
              scroll-margin-top: 24px;
            `}
          >
            <LeadWarnings dataCollection={dataCollection} />

            {fcraDisclosure && !isFcraDisclosureAccepted && (
              <>
                <SystemMessage type="error" heading="Consent was not granted" description={FCRA_ALERT_DESC} />
                <Container mt={spacings.px24} ml={spacings.px32} mb={spacings.px24}>
                  <RadioGroupField
                    label={() => (
                      <Blockquote
                        text={
                          <Heading
                            customCss={css`
                              &::after {
                                color: ${colors.statusRed};
                                content: '*';
                              }
                            `}
                          >
                            {fcraDisclosure.prompt}
                          </Heading>
                        }
                      />
                    )}
                    inline
                    required
                    id="fcra"
                    name="fcra"
                    options={NO_YES_OPTIONS}
                  />
                </Container>
              </>
            )}
            <Heading type="h4" mb={spacings.px24}>
              {page.label}
            </Heading>
            <FlexBox columnDirection gap={spacings.px24}>
              {realOpportunities?.map((opportunity, index) => {
                return (
                  <React.Fragment key={opportunity.id}>
                    {index > 0 && (
                      <div
                        css={css`
                          width: 100%;
                          height: 2px;
                          background-color: ${colors.grey30};
                        `}
                      />
                    )}
                    <OpportunityConfigurationComponent
                      person={person}
                      leadId={lead.id}
                      opportunity={opportunity!}
                      quotesRequests={quotesRequests || []}
                      refetchCurrentPolicies={refetchCurrentPolicies}
                      onMoveToOpportunity={onMoveToOpportunity}
                    />
                  </React.Fragment>
                );
              })}
              {offerStubPAFOpportunity && (
                <>
                  <div
                    css={css`
                      width: 100%;
                      height: 2px;
                      background-color: ${colors.grey30};
                    `}
                  />
                  <StubPAFOpportunity person={person} />
                </>
              )}
              {offerFloodOpportunities && (
                <>
                  <div
                    css={css`
                      width: 100%;
                      height: 2px;
                      background-color: ${colors.grey30};
                    `}
                  />
                  {opportunitiesThatNeedAFloodOffer.map(opp =>
                    opp?.assets?.[0]?.address ? (
                      <StubFloodOpportunity
                        key={opp.id}
                        person={person}
                        opportunity={opp}
                        onMoveToOpportunity={onMoveToOpportunity}
                      />
                    ) : null
                  )}
                </>
              )}
            </FlexBox>
            <ScrollToFirstError />
          </FlexBox>
        );
      }}
    />
  );
};
export default QuotingConfigurationPage;
