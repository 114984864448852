import * as React from 'react';

import Copy from '../../../../../components/core/Copy';
import CopyableAddress from '../../../../../components/core/CopyableAddress';
import { isTagPresent } from '../../../../../components/core/forms/uiFlowBuilder';
import { answerForDatapoint, uiValueForQuestion } from '../../../../../components/core/forms/workflowHelpers';
import QuestionVerificationStatusIcon from '../../../../../components/core/QuestionVerificationStatusIcon';
import Text, { TextProps } from '../../../../../components/core/Text';
import { IAddress, IAnswer, IQuestion } from '../../../../../interfaces';
import { IUIFlowElement } from '../../../../../interfaces/IUIFlow';
import { QuestionType, QuestionVerificationStatus } from '../../../../../interfaces/IWorkflow';
import colors from '../../../../../theme/colors';
import EmptyAnswer from './EmptyAnswer';
import QuestionAnswerPair from './QuestionAnswerPair';

interface UIFlowQuestionAnswerPairProps {
  questionElement: IUIFlowElement & { content: IQuestion };
  answers: IAnswer[];
  textType?: TextProps['type'];
  textColor?: TextProps['color'];
  isCustomerDataCompletenessHigh?: boolean;
}

const AnswerLabel = ({
  question,
  answer,
  textType,
  fsMask
}: {
  question: IQuestion;
  answer: IAnswer;
  textType: UIFlowQuestionAnswerPairProps['textType'];
  fsMask?: boolean;
}) => {
  if (question.type === QuestionType.Address) {
    return (
      <CopyableAddress
        textType={textType}
        address={answer.value as Partial<IAddress>}
        data-testid={`collect-info-tab-${question.key}`}
      />
    );
  }

  const answerLabel = uiValueForQuestion(question, answer);

  return (
    <Copy type={textType} value={answerLabel} testId={`collect-info-tab-${question.key}`}>
      <Text type={textType} className={fsMask ? 'fs-mask' : ''}>
        {answerLabel}
      </Text>
    </Copy>
  );
};

const UIFlowQuestionAnswerPair = ({
  questionElement: { tags, content: question },
  answers,
  textType,
  textColor,
  isCustomerDataCompletenessHigh
}: UIFlowQuestionAnswerPairProps) => {
  const existingAnswer = answerForDatapoint(answers, question.key);

  // Explanation:
  // Some datapoints are never being processed via CRE on CX platform solutions, although they are being verified by
  // a customer.
  // These are: "property_address", "person_mailing_address", "property_ownership_type".
  //
  // In order for verification status to be displayed properly for these datapoints, code below overrides verification
  // status to be "Verified", in case when data completeness is high.
  const verificationStatus =
    existingAnswer && isCustomerDataCompletenessHigh
      ? QuestionVerificationStatus.Verified
      : question.verification_status;

  return (
    <QuestionAnswerPair
      questionLabel={
        <Text type={textType} color={textColor || colors.grey60}>
          {question.alt_label || question.label}
        </Text>
      }
      answerLabel={
        <>
          {verificationStatus && <QuestionVerificationStatusIcon verificationStatus={verificationStatus} />}
          {existingAnswer ? (
            <AnswerLabel
              textType={textType}
              fsMask={isTagPresent(tags, 'mask')}
              question={question}
              answer={existingAnswer}
            />
          ) : (
            <EmptyAnswer textType={textType} />
          )}
        </>
      }
    />
  );
};

export default UIFlowQuestionAnswerPair;
