/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { NavLink } from 'reactstrap';

import { IPerson } from '../../interfaces/IPerson';
import colors from '../../theme/colors';
import { ellipsis } from '../../utils/formatter';
import FlexBox from '../core/FlexBox';

const MAX_NAME_LENGTH = 30;

interface IPersonNavLink {
  person: IPerson;
  linkPrefix: string;
}

const PersonNavLink = ({ person, linkPrefix }: IPersonNavLink): JSX.Element => {
  return (
    <FlexBox pt={8} pl={8}>
      <span>{linkPrefix} Customer:</span>
      <NavLink
        to={`/people/${person.gid}`}
        tag={RouterLink}
        className="fs-mask"
        data-testid="customer-nav-name-link"
        css={css`
          position: relative;
          bottom: 8px;
          right: 8px;
          color: ${colors.azure50};
        `}
      >
        {ellipsis(person.name, MAX_NAME_LENGTH)}
      </NavLink>
    </FlexBox>
  );
};

export default PersonNavLink;
