import { DatapointsUsageKey } from '../../../../interfaces/IDatapoint';
import { usePeopleAnswers, useVehicleAnswers } from '../../../../queries/answers/useAnswers';
import { deepMerge } from '../../../../utils/object';

export const useVehiclesWithVerificationStatus = ({
  personGid,
  vehicleGids
}: {
  personGid: string;
  vehicleGids: string[];
}) => {
  const vehiclesAnswers = useVehicleAnswers({
    datapointsUsageKey: DatapointsUsageKey.VehicleProfileInfo,
    vehicleGids,
    personGid
  });

  return vehiclesAnswers;
};

const useDriversWithVerificationStatus = ({ driversGids }: { driversGids: string[] }) => {
  const driversAnswers = usePeopleAnswers({
    datapointsUsageKey: DatapointsUsageKey.CustomerProfileInfo,
    peopleGids: driversGids
  });

  return driversAnswers;
};

export const useAdditionalPersonVehicleAnswers = ({ personGid }: { personGid: string }) => {
  const additionalAnswers = usePeopleAnswers({
    datapointsUsageKey: DatapointsUsageKey.VehicleProfileAdditionalInfo,
    peopleGids: [personGid]
  });

  return additionalAnswers;
};

export const useVehicleProfileAnswersWithVerificationAnswers = ({
  personGid,
  vehicleGids,
  driversGids
}: {
  personGid: string;
  vehicleGids: string[];
  driversGids: string[];
}) => {
  const vehiclesAnswers = useVehiclesWithVerificationStatus({
    personGid,
    vehicleGids
  });

  const driversAnswers = useDriversWithVerificationStatus({
    driversGids: [personGid, ...driversGids]
  });

  const personAdditionalAnswers = useAdditionalPersonVehicleAnswers({ personGid });

  return { ...vehiclesAnswers, ...deepMerge(driversAnswers, personAdditionalAnswers) };
};
