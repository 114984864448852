import PropTypes from 'prop-types';

import { CITY, LINE1, LINE2, STATE, ZIP } from '../constants/addressForm';

const formikInjectedPropsTypes = PropTypes.shape({
  dirty: PropTypes.bool.isRequired,
  errors: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleReset: PropTypes.func.isRequired,
  // eslint-disable-next-line no-restricted-syntax
  handleSubmit: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  resetForm: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  submitCount: PropTypes.number.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setStatus: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  setTouched: PropTypes.func.isRequired,
  setValues: PropTypes.func.isRequired,
  status: PropTypes.any,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  validateForm: PropTypes.func.isRequired,
  validateField: PropTypes.func.isRequired
});

const Address = PropTypes.shape({
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string
});

const Assignee = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired
});

const defaultPersonPropTypes = {
  middle_name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  marital_status: PropTypes.string,
  date_of_birth: PropTypes.string,
  ssn: PropTypes.string,
  fico_score: PropTypes.number,
  gender: PropTypes.string,
  kind: PropTypes.string,
  education: PropTypes.string,
  occupation_type: PropTypes.string,
  occupation_since_date: PropTypes.string,
  license_status: PropTypes.string,
  license_number: PropTypes.string,
  license_state: PropTypes.string,
  age_first_licensed: PropTypes.number,
  primary_driven_vehicle_id: PropTypes.number
};

const Person = PropTypes.shape({
  id: PropTypes.number.isRequired,
  cocustomer: PropTypes.bool.isRequired,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  ...defaultPersonPropTypes
});

const PersonFromAugmentation = PropTypes.shape({
  id: PropTypes.number,
  cocustomer: PropTypes.bool,
  first_name: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  ...defaultPersonPropTypes
});

const Task = PropTypes.shape({
  id: PropTypes.number,
  agent_id: PropTypes.number,
  assignee: Assignee,
  kind: PropTypes.string,
  description: PropTypes.string,
  due_date: PropTypes.string,
  due_time: PropTypes.string,
  completed: PropTypes.bool
});

const Auto = PropTypes.shape({
  id: PropTypes.number,
  year: PropTypes.string,
  make: PropTypes.string,
  model: PropTypes.string,
  submodel: PropTypes.string,
  address: Address,
  vin: PropTypes.string,
  ownership: PropTypes.string,
  use: PropTypes.string,
  annual_mileage: PropTypes.number,
  used_for_ridesharing: PropTypes.bool,
  days_per_week_driven: PropTypes.number,
  kind: PropTypes.string
});

const childrenElements = PropTypes.oneOfType([
  PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.bool])),
  PropTypes.element,
  PropTypes.string
]);

const customerAddresses = PropTypes.arrayOf(
  PropTypes.shape({
    [LINE1]: PropTypes.string,
    [LINE2]: PropTypes.string,
    [CITY]: PropTypes.string,
    [STATE]: PropTypes.string,
    [ZIP]: PropTypes.string
  })
);

const customPropTypes = {
  Person,
  PersonFromAugmentation,
  Assignee,
  Task,
  Auto,
  formikInjectedPropsTypes,
  childrenElements,
  customerAddresses,
  DocumentType
};

export default customPropTypes;
