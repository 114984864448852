import * as React from 'react';

import Heading from '../../../components/core/Heading';
import { LineOfBusiness } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import usePolicyVehicleCoverages from '../../../queries/policies/usePolicyVehicleCoverages';
import CoverageSection from '../CoverageSection';
import {
  autoDeductiblesSelector,
  autoDiscountsSelector,
  autoExcludedSelector,
  autoOtherLimitsSelector,
  autoSpecialLiabilityLimitsSelector
} from '../helpers';
import PolicyDetailsSectionPlaceholder from '../PolicyDetailsSectionPlaceholder';

const VehicleSection = ({
  lineOfBusiness,
  lastTransaction,
  vehicle
}: {
  lineOfBusiness: LineOfBusiness;
  lastTransaction: PolicyTransaction;
  vehicle: any;
}): JSX.Element => {
  const { data: deductibles, isLoading: isLoadingDeductibles } = usePolicyVehicleCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    vehicle.id,
    {
      select: autoDeductiblesSelector
    }
  );
  const { data: excluded, isLoading: isLoadingExcluded } = usePolicyVehicleCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    vehicle.id,
    {
      select: autoExcludedSelector
    }
  );
  const { data: specialLiabilityLimits, isLoading: isLoadingSpecialLiabilityLimits } = usePolicyVehicleCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    vehicle.id,
    {
      select: autoSpecialLiabilityLimitsSelector
    }
  );
  const { data: otherLimits, isLoading: isLoadingOtherLimits } = usePolicyVehicleCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    vehicle.id,
    {
      select: autoOtherLimitsSelector
    }
  );
  const { data: discounts, isLoading: isLoadingDiscounts } = usePolicyVehicleCoverages(
    lastTransaction.policy_id,
    lineOfBusiness.id,
    lastTransaction.id,
    vehicle.id,
    {
      select: autoDiscountsSelector
    }
  );

  const showLoader =
    isLoadingDiscounts ||
    isLoadingOtherLimits ||
    isLoadingSpecialLiabilityLimits ||
    isLoadingExcluded ||
    isLoadingDeductibles;

  return (
    <div>
      <Heading>{`${vehicle.year}, ${vehicle.make}, ${vehicle.model}`}</Heading>
      {showLoader ? (
        <PolicyDetailsSectionPlaceholder linesCount={5} />
      ) : (
        <>
          {!!discounts?.length && <CoverageSection heading="Discounts" isDeductible coverages={discounts} />}
          {!!deductibles?.length && <CoverageSection heading="Deductibles" isDeductible coverages={deductibles} />}
          {!!excluded?.length && <CoverageSection heading="Excluded" coverages={excluded} />}
          {!!specialLiabilityLimits?.length && (
            <CoverageSection heading="Special liability limits" coverages={specialLiabilityLimits} />
          )}
          {!!otherLimits?.length && <CoverageSection heading="Other limits" coverages={otherLimits} />}
        </>
      )}
    </div>
  );
};

export default VehicleSection;
