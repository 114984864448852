import { useMutation } from '@tanstack/react-query';

import { PolicyFileToProcess } from '../../../interfaces/IDocument';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { PolicyType } from '../../../interfaces/IPolicyType';
import api from '../../../services/api';
import { processFiles } from './useUpdatePolicy';

interface RewritePolicyRequest {
  person_gid: string;
  policy_id: number;

  policy_number: string;
  effective_date: string;
  expiration_date: string;
  premium: string;
  agent_id: string;
  sale_date: string;
  policy_type: PolicyType;
  cancellation_date: string | undefined;
  cancellation_reason: string | undefined;
  payment_method: string | undefined;

  deductible_type: string | undefined;
  deductible_value: number | undefined;
  primary_coverage: number | undefined;
  asset_gids: string[];
  state: string | undefined;
  carrier_id: number;
  new_closing: boolean | undefined;

  document_rules_policies_attributes: any[] | undefined;

  files: PolicyFileToProcess[];

  lead_id: number | undefined;
}

const rewritePolicy = (data: RewritePolicyRequest): Promise<{ policy: IMaticPolicy }> =>
  api.post(`/api/frontend/people/${data.person_gid}/policies/${data.policy_id}/rewrite`, { body: data });

const useRewritePolicy = (onSuccess?: () => void) => {
  return useMutation({
    mutationFn: rewritePolicy,
    onSuccess: async (response, variables) => {
      // TODO: check behavior of migrating existing files over to new policy
      await processFiles({ ...variables, policy_id: response.policy.id });
      onSuccess?.();
    }
  });
};

export default useRewritePolicy;
