import Paragraph from '../../../../../components/core/Paragraph';
import colors from '../../../../../theme/colors';

const MyCoverageInfo = () => (
  <Paragraph type="small" singleLine>
    Open{' '}
    <a
      href="https://mycoverageinfo.com/agent"
      target="_blank"
      style={{ fontWeight: 'bold', color: colors.azure50 }}
      rel="noreferrer"
    >
      mycoverageinfo.com
    </a>
  </Paragraph>
);

export default MyCoverageInfo;
