import ReactPaginate from 'react-paginate';

interface IPagination {
  pages?: number;
  current?: number;
  onPageChange: (selectedItem: { selected: number }) => void;
}
const Pagination = ({ pages = 0, current = 0, onPageChange }: IPagination) =>
  pages > 1 ? (
    <ReactPaginate
      previousLabel="Previous"
      nextLabel="Next"
      breakLabel="..."
      pageCount={pages}
      forcePage={current}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      onPageChange={onPageChange}
      containerClassName="pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      breakClassName="page-item"
      previousClassName="page-item"
      nextClassName="page-item"
      previousLinkClassName="page-link"
      nextLinkClassName="page-link"
      hrefBuilder={() => '#'}
      disableInitialCallback
    />
  ) : null;

export default Pagination;
