import authInfo from '../../../services/authInfo';

interface IFeatures {
  person_sensitive_data_available?: boolean;
}

const SensitiveInfoWrapper = ({ children }: { children: any }) => {
  const features: IFeatures = authInfo.features;

  return features.person_sensitive_data_available ? children : null;
};

export default SensitiveInfoWrapper;
