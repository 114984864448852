import Grid from '@mui/material/Grid';
import * as React from 'react';

import { DateInputField, InputField, SelectField } from '../../../components/core/forms/fields';
import { Translations } from '../../../constants';
import { IPerson, IVehicle } from '../../../interfaces';
import { DriverFields } from './formHelpers';

const DriverEditor = ({
  driver: { gid },
  selectedVehicles,
  showRelationship
}: {
  driver: IPerson;
  selectedVehicles: IVehicle[] | undefined;
  showRelationship: boolean;
}) => (
  <>
    <Grid container columnSpacing={2}>
      <Grid item xs={3}>
        <DateInputField
          required
          label="DOB"
          id={`${gid}_${DriverFields.DateOfBirth}`}
          name={`${gid}_${DriverFields.DateOfBirth}`}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          required
          label="Gender"
          id={`${gid}_${DriverFields.Gender}`}
          name={`${gid}_${DriverFields.Gender}`}
          options={Translations.genderOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          required
          label="Marital status"
          id={`${gid}_${DriverFields.MaritalStatus}`}
          name={`${gid}_${DriverFields.MaritalStatus}`}
          options={Translations.maritalStatusOptions}
        />
      </Grid>
      {showRelationship && (
        <Grid item xs={3}>
          <SelectField
            required
            label="Relationship to customer"
            id={`${gid}_${DriverFields.Relationship}`}
            name={`${gid}_${DriverFields.Relationship}`}
            options={Translations.relationshipOptions}
          />
        </Grid>
      )}
    </Grid>
    <Grid container columnSpacing={2}>
      <Grid item xs={3}>
        <SelectField
          required
          label="Education"
          id={`${gid}_${DriverFields.Education}`}
          name={`${gid}_${DriverFields.Education}`}
          options={Translations.educationOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          required
          label="Occupation type"
          id={`${gid}_${DriverFields.OccupationType}`}
          name={`${gid}_${DriverFields.OccupationType}`}
          options={Translations.occupationTypeOptions}
        />
      </Grid>
      <Grid item xs={3}>
        <DateInputField
          label="Current job start date"
          id={`${gid}_${DriverFields.OccupationSinceDate}`}
          name={`${gid}_${DriverFields.OccupationSinceDate}`}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          required
          label="License status"
          id={`${gid}_${DriverFields.LicenseStatus}`}
          name={`${gid}_${DriverFields.LicenseStatus}`}
          options={Translations.licenseStatusOptions}
        />
      </Grid>
    </Grid>
    <Grid container columnSpacing={2}>
      <Grid item xs={3}>
        <InputField
          label="License number"
          id={`${gid}_${DriverFields.LicenseNumber}`}
          name={`${gid}_${DriverFields.LicenseNumber}`}
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          label="License state"
          id={`${gid}_${DriverFields.LicenseState}`}
          name={`${gid}_${DriverFields.LicenseState}`}
          options={Translations.usaStates}
        />
      </Grid>
      <Grid item xs={3}>
        <InputField
          label="Age first licensed"
          id={`${gid}_${DriverFields.AgeFirstLicensed}`}
          name={`${gid}_${DriverFields.AgeFirstLicensed}`}
          type="number"
        />
      </Grid>
      <Grid item xs={3}>
        <SelectField
          label="Primary driven vehicle"
          id={`${gid}_${DriverFields.PrimaryDrivenVehicleGid}`}
          name={`${gid}_${DriverFields.PrimaryDrivenVehicleGid}`}
          options={selectedVehicles?.map(({ gid, description }) => ({ key: gid, value: description })) || []}
        />
      </Grid>
    </Grid>
  </>
);

export default DriverEditor;
