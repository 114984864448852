/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Outlet as ChildRoute } from 'react-router-dom';

import AppOutdatedBanner from '../../components/AppOutdatedBanner';
import FlexBox from '../../components/core/FlexBox';
import LeadClassificationCheck from '../../components/LeadClassificationCheck';
import GlobalNavigation from './GlobalNavigation';

const AppRoot = () => {
  return (
    <div
      css={css`
        height: 100%;
        display: grid;
        grid-template-columns: 64px 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
          'global_banner global_banner'
          'global_navigation route';
      `}
    >
      <div
        css={css`
          grid-area: global_banner;
        `}
      >
        <AppOutdatedBanner />
        <LeadClassificationCheck />
      </div>
      <div
        css={css`
          grid-area: global_navigation;
        `}
      >
        <GlobalNavigation />
      </div>
      <FlexBox
        fitParentHeight
        columnDirection
        css={css`
          grid-area: route;
          overflow: auto;
        `}
      >
        <ChildRoute />
      </FlexBox>
    </div>
  );
};

export default AppRoot;
