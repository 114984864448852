/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useNavigate } from 'react-router-dom';

import lostImage from '../../assets/img/lost.svg';
import transferImage from '../../assets/img/transfer.svg';
import Container from '../../components/core/Container';
import FlexBox from '../../components/core/FlexBox';
import BaseForm from '../../components/core/forms/BaseForm';
import { TextareaField } from '../../components/core/forms/fields';
import Heading from '../../components/core/Heading';
import { StarIcon } from '../../components/core/icons';
import Label from '../../components/core/Label';
import Paragraph from '../../components/core/Paragraph';
import Tag from '../../components/core/Tag';
import featureFlags from '../../constants/featureFlags';
import { useGuidedSellingExperienceContext } from '../../contexts/GuidedSellingExperienceContext';
import { IDataCollection } from '../../interfaces';
import { IOpportunity, OpportunityStatus } from '../../interfaces/IOpportunity';
import { UserRole } from '../../interfaces/IUser';
import { useCreateLeadFeedback, useLeadFeedbacks } from '../../queries/leads/feedback/useLeadFeedback';
import useLeadOpportunities from '../../queries/leads/opportunities/useLeadOpportunities';
import colors from '../../theme/colors';
import { borderRadius, sizes, spacings } from '../../theme/variables';
import { isImageCached } from '../../utils/object';
import { useCurrentDispositionSubscriptionContext } from '../GuidedSellingExperience/_hooks';
import Opportunities from '../GuidedSellingExperience/Summary/Context/Opportunities';
import useDisplayFinalizedSalesPage from './_contexts/useFinalizedPage';

const FinalizedSalesPage = ({
  dataCollection,
  showOpportunities = true
}: {
  dataCollection: IDataCollection;
  showOpportunities?: boolean;
}) => {
  const [soldImageLoaded, setSoldImageLoaded] = React.useState(() => isImageCached(transferImage));
  const [lostImageLoaded, setLostImageLoaded] = React.useState(() => isImageCached(lostImage));
  const navigate = useNavigate();
  const { lead, person } = useGuidedSellingExperienceContext();
  const { data: opportunities } = useLeadOpportunities({
    leadId: lead!.id
  });
  const { currentDisposition } = useCurrentDispositionSubscriptionContext();

  React.useEffect(
    () => () =>
      useDisplayFinalizedSalesPage.setState({
        displayFinalizedSalesPage: false
      }),
    []
  );

  const { data: feedbacks = [], isPending: isPendingFeedback } = useLeadFeedbacks({ leadGid: lead!.gid });
  const lastFeedback = feedbacks[0];

  const { mutate: createLeadFeedback } = useCreateLeadFeedback();

  if (!opportunities) {
    return (
      <Container m={spacings.px24}>
        <Skeleton height={300} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
        <Skeleton count={6} height={50} baseColor={colors.grey10} highlightColor={colors.grey5} width="50%" />
      </Container>
    );
  }
  const isSold = opportunities.some((opportunity: IOpportunity) => opportunity.status === OpportunityStatus.Sold);

  const rateISRWorkIsShown =
    featureFlags.superISRFeedbackUI &&
    dataCollection.pages.some(page => page.originally_completed_by?.role === UserRole.SuperISR);

  return (
    <FlexBox columnDirection gap={spacings.px24} fitParentHeight>
      <BaseForm
        pb={spacings.px24}
        pt={spacings.px24}
        pl={spacings.px24}
        pr={spacings.px24}
        type="fullPage"
        controlsAlignment="right"
        controlsWidth={sizes.mediumFormInputWidth}
        enableReinitialize
        loading={isPendingFeedback}
        initialValues={{ evaluation: lastFeedback?.evaluation.toString() || '', feedback: lastFeedback?.text || '' }}
        submitText="Got it! Go to sales dashboard"
        submitTestId="finalize-lead-button"
        onSubmit={({ evaluation, feedback }) => {
          if (!lastFeedback && rateISRWorkIsShown && lead && (evaluation || feedback)) {
            createLeadFeedback({
              leadGid: lead.gid,
              evaluation,
              text: feedback
            });
          }

          return navigate('/leads');
        }}
        renderForm={({ values, setFieldValue }) => {
          return (
            <>
              {isSold ? (
                <img src={transferImage} alt="Transfer" width={590} onLoad={() => setSoldImageLoaded(true)} />
              ) : (
                <img src={lostImage} alt="Transfer" width={360} onLoad={() => setLostImageLoaded(true)} />
              )}

              <FlexBox gap={spacings.px12} columnDirection fitParentHeight p={spacings.px24} maxWidth={928}>
                {isSold && soldImageLoaded && (
                  <Container>
                    <Heading>Fantastic work!</Heading>
                    <Paragraph>
                      You&apos;ve just made insurance simpler and more accessible for another customer. Your dedication
                      and expertise continue to bring peace of mind to those we serve!
                    </Paragraph>
                  </Container>
                )}

                {!isSold && lostImageLoaded && (
                  <Container>
                    <Heading>You&apos;ve ended the conversation</Heading>
                    <Paragraph>
                      You&apos;ve politely ended the conversation, knowing our products don&apos;t fit the
                      customer&apos;s needs now.
                    </Paragraph>
                  </Container>
                )}

                {((isSold && soldImageLoaded) || (!isSold && lostImageLoaded)) && (
                  <>
                    <FlexBox gap={spacings.px16} alignItemsCenter>
                      <Container
                        customCss={css`
                          width: 250px;
                          min-width: 250px;
                        `}
                      >
                        {person?.name}
                      </Container>
                      {currentDisposition && (
                        <Tag
                          label={currentDisposition?.label}
                          backgroundColor={isSold ? colors.statusGreen : colors.statusRed}
                          borderRadius={borderRadius}
                          data-testId="current-disposition-tag"
                          textType="tiny"
                          customCss={css`
                            padding: 2px ${spacings.px8}px;
                            height: fit-content;
                          `}
                        />
                      )}
                    </FlexBox>
                    {showOpportunities && (
                      <React.Suspense
                        fallback={
                          <Skeleton
                            count={5}
                            baseColor={colors.grey10}
                            highlightColor={colors.grey5}
                            style={{ height: '30px', width: '50%', lineHeight: 0 }}
                          />
                        }
                      >
                        <Paragraph bold>Opportunities</Paragraph>
                        <Opportunities displayQuotes={false} />
                        {rateISRWorkIsShown && (
                          <>
                            <Paragraph bold>ISR Work</Paragraph>
                            <FlexBox gap={spacings.px12} columnDirection>
                              <FlexBox gap={spacings.px12}>
                                <Label
                                  htmlFor="evaluation-5"
                                  customCss={css`
                                    white-space: wrap;
                                    width: 250px;
                                  `}
                                >
                                  Select how satisfied you are with the Super ISR&apos;s work.
                                </Label>
                                <FlexBox
                                  gap={spacings.px8}
                                  customCss={css`
                                    flex-direction: row-reverse;
                                  `}
                                >
                                  {['5', '4', '3', '2', '1'].map(rate => (
                                    <button
                                      type="button"
                                      id={`evaluation-${rate}`}
                                      key={rate}
                                      disabled={!!lastFeedback}
                                      onClick={e => {
                                        e.currentTarget.blur();
                                        setFieldValue('evaluation', rate);
                                      }}
                                      css={css`
                                        background: none;
                                        border: none;
                                        padding: 0;
                                        &:disabled {
                                          cursor: not-allowed;
                                        }
                                        &:focus,
                                        &:hover {
                                          > svg {
                                            color: ${colors.sun} !important;
                                            fill: ${colors.sun} !important;
                                          }
                                        }

                                        &:focus ~ button,
                                        &:hover ~ button {
                                          > svg {
                                            color: ${colors.sun} !important;
                                            fill: ${colors.sun} !important;
                                          }
                                        }
                                      `}
                                    >
                                      <StarIcon
                                        color={values.evaluation >= rate ? colors.sun : undefined}
                                        fill={values.evaluation >= rate ? colors.sun : colors.white}
                                      />
                                    </button>
                                  ))}
                                </FlexBox>
                              </FlexBox>
                              <Container
                                customCss={css`
                                  padding-left: 262px;
                                `}
                              >
                                <TextareaField
                                  disabled={!!lastFeedback}
                                  label="Write detailed feedback"
                                  name="feedback"
                                  id="feedback"
                                />
                              </Container>
                            </FlexBox>
                          </>
                        )}
                      </React.Suspense>
                    )}
                  </>
                )}
              </FlexBox>
            </>
          );
        }}
      />
    </FlexBox>
  );
};

export default FinalizedSalesPage;
