import { IOpportunity, OpportunityCoveragesTier } from './IOpportunity';
import { PolicyType } from './IPolicyType';
import { QuoteKind } from './IQuote';
import { SourceProducts } from './ISourceDimensions';

interface HomeQuotingRequest {
  asset_gid: string;
  dwelling?: string | null;
  personal_property?: string | null;
  deductible_value?: string | null;
  carrier_keys?: string[] | null;
  test_carrier_keys?: string[];
  quote_kind?: string;
  coinsured_gid?: string;

  opportunity_id?: IOpportunity['id'];
}

interface AutoQuotingRequest {
  vehicles: { asset_gid: string; coverages?: Record<string, string>; coverages_tier?: OpportunityCoveragesTier }[];
  drivers_gids: string[] | undefined;
  prior_policy_expiration_date?: string;

  opportunity_id?: IOpportunity['id'];
}

interface MotoQuotingRequest {
  vehicles: { asset_gid: string }[];
  opportunity_id?: IOpportunity['id'];
}

interface RVQuotingRequest {
  vehicles: { asset_gid: string }[];
  opportunity_id?: IOpportunity['id'];
}

interface PafQuotingRequest {
  opportunity_id: IOpportunity['id'];
}

interface PetQuotingRequest {
  opportunity_id: IOpportunity['id'];
}

interface UmbrellaQuotingRequest {
  asset_gid: string;
  opportunity_id?: IOpportunity['id'];
}

export enum CustomerQuotesRequesters {
  Olb = 'olb',
  OnlineBind = 'online_bind',
  DigitalProfile = 'digital_profile'
}

type QuotesRequester = CustomerQuotesRequesters | SourceProducts;

export interface StartQuotingRequest {
  homes?: HomeQuotingRequest[] | null;
  autos?: AutoQuotingRequest[] | null;
  motorcycles?: MotoQuotingRequest[] | null;
  recreational_vehicles?: RVQuotingRequest[] | null;
  paf?: number | string | null;
  new_paf?: PafQuotingRequest | null;
  pet?: PetQuotingRequest | null;
  umbrella?: UmbrellaQuotingRequest | null;
}

export interface StartQuotingResponse {
  quotes_request: IQuotesRequest;
}

export interface SchedulingError {
  gid: string;
  error_message: string;
  assets_gids: string[];
  policy_type: PolicyType;
  state_code: string | null;
  primary_insured_gid: string;
  kind: QuoteKind;
}

export interface IQuotingReference {
  gid: string;
  policy_type: PolicyType;
  state_code: string | null;
  assets_gids: string[] | null;
  kind: QuoteKind;
  created_at: string;
  updated_at: string;
}

export interface IQuotesRequest {
  gid: string;
  source_product: QuotesRequester;
  quoting_references: IQuotingReference[] | null;
  scheduling_errors: SchedulingError[] | null;
  options: StartQuotingRequest | null;
  created_at: string;
  finished_at: string | null;
  is_archived: boolean;
}

export const isQuotesRequestedByCustomer = ({ source_product }: { source_product: IQuotesRequest['source_product'] }) =>
  [CustomerQuotesRequesters.Olb, CustomerQuotesRequesters.OnlineBind, CustomerQuotesRequesters.DigitalProfile].includes(
    source_product
  );

export const isQuotesRequestedByAmp = ({ source_product }: { source_product: IQuotesRequest['source_product'] }) =>
  source_product === SourceProducts.AgentManual;
