/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import parse, { attributesToProps, domToReact, HTMLReactParserOptions } from 'html-react-parser';
import * as React from 'react';

import Heading from '../components/core/Heading';
import { highlightedCss } from '../theme/typography';

const options = {
  replace: (domNode: any) => {
    if (domNode.name === 'highlighted' || domNode.name === 'em') {
      return (
        <span
          css={css`
            ${highlightedCss}
            margin-left: 2px;
            margin-right: 2px;
          `}
        >
          {domToReact(domNode.children, options)}
        </span>
      );
    }

    if (domNode.name === 'a') {
      const props = attributesToProps(domNode.attribs);
      return <a {...props}>{domToReact(domNode.children, options)}</a>;
    }

    if (domNode.name === 'b') {
      return <b>{domToReact(domNode.children, options)}</b>;
    }

    if (domNode.name === 'p') {
      return <p>{domToReact(domNode.children, options)}</p>;
    }

    if (domNode.name === 'li') {
      return <li>{domToReact(domNode.children, options)}</li>;
    }

    if (domNode.name === 'h4') {
      return <Heading type="h4">{domToReact(domNode.children, options)}</Heading>;
    }

    if (domNode.name === 'strong') {
      return (
        <span
          css={css`
            font-weight: bold;
          `}
        >
          {domToReact(domNode.children, options)}
        </span>
      );
    }

    if (domNode.name === 'u') {
      return (
        <span
          css={css`
            text-decoration: underline;
          `}
        >
          {domToReact(domNode.children, options)}
        </span>
      );
    }

    if (domNode.name === 'mask') {
      return <span className="fs-mask">{domToReact(domNode.children, options)}</span>;
    }

    if (domNode.name === 'span') {
      const props = attributesToProps(domNode.attribs);
      return <span {...props}>{domToReact(domNode.children, options)}</span>;
    }

    if (domNode.type === 'text') {
      return <span>{domNode.data}</span>;
    }

    return domToReact(domNode.children, options);
  }
} as HTMLReactParserOptions;

const parseHtmlString = (
  text: string,
  handleError?: (e: any) => void,
  ignoreCustomOptions?: boolean
): string | JSX.Element | JSX.Element[] | undefined => {
  // catch an error that is thrown when the text is not a valid html
  try {
    return parse(text, ignoreCustomOptions ? undefined : options);
  } catch (e: any) {
    if (handleError) {
      handleError(e);
    } else {
      throw e;
    }
  }
};

export default parseHtmlString;
