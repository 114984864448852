/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import React from 'react';

import { QuestionVerificationStatus } from '../../../../interfaces/IWorkflow';
import colors from '../../../../theme/colors';
import { InputSize, LabelSize, secondaryInputMargin, sizes, spacings } from '../../../../theme/variables';
import Tooltip from '../../../common/Tooltip/NewTooltip';
import Container from '../../Container';
import FlexBox from '../../FlexBox';
import { InfoIcon } from '../../icons';
import Label, { LabelProps } from '../../Label';
import QuestionVerificationStatusIcon from '../../QuestionVerificationStatusIcon';

export interface LabeledInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: LabelProps['children'];
  required: boolean | undefined;
  inline: boolean | undefined;
  secondary: boolean | undefined;
  disabled: boolean | undefined;
  inputSize?: InputSize;
  labelSize?: LabelSize;
  customCss?: SerializedStyles;
  info?: string;
  verificationStatus?: QuestionVerificationStatus;
}

const labelWidth = (labelSize: LabelSize, secondary: boolean) => {
  return secondary ? sizes[labelSize] - secondaryInputMargin : sizes[labelSize];
};

const LabeledInput = ({
  id,
  label,
  required,
  inline = false,
  secondary = false,
  disabled = false,
  children,
  inputSize = InputSize.Medium,
  labelSize = LabelSize.Medium,
  customCss,
  info,
  verificationStatus,
  ...rest
}: LabeledInputProps) => {
  const reservedSpaceForInfo = info ? spacings.px24 : spacings.px0;

  return inline ? (
    <FlexBox
      alignItemsBaseline
      customCss={css`
        flex-grow: 1;
      `}
    >
      <Label
        htmlFor={id}
        required={!!required}
        pv={spacings.px8}
        ph={spacings.px4}
        ml={secondary ? secondaryInputMargin : spacings.px0}
        customCss={css`
          flex: 0 0 ${labelWidth(labelSize, secondary) - reservedSpaceForInfo}px;
          color: ${secondary || disabled ? colors.grey60 : colors.black};
          white-space: normal;
          overflow-wrap: break-word;
          ${customCss}
        `}
      >
        {label}
      </Label>
      {verificationStatus && (
        <Container>
          <QuestionVerificationStatusIcon verificationStatus={verificationStatus} disabled={disabled} />
        </Container>
      )}
      {!!info && (
        <Container
          ml={spacings.px4}
          customCss={css`
            position: relative;
            bottom: 2px;
          `}
        >
          <InfoIcon color={colors.black} data-for={`info-${id}`} data-tip={info} />
          <Tooltip id={`info-${id}`} />
        </Container>
      )}
      <Container
        css={css`
          margin-left: ${labelSize === LabelSize.Small ? spacings.px4 : spacings.px12}px;
          flex: 0 1 ${sizes[inputSize]}px;
        `}
      >
        {children}
      </Container>
    </FlexBox>
  ) : (
    <Container {...rest}>
      <Label htmlFor={id} required={!!required} mb={4} customCss={customCss}>
        {label}
      </Label>
      {children}
    </Container>
  );
};

export default LabeledInput;
