import React from 'react';

import { spacings } from '../../theme/variables';
import Loader from '../common/Loader';
import FlexBox from '../core/FlexBox';
import { MaticLogo } from '../core/icons';
import Paragraph from '../core/Paragraph';

const LoadingPage = ({ message }: { message: string }) => (
  <FlexBox alignItemsCenter justifyCenter columnDirection fitParentHeight gap={spacings.px16}>
    <FlexBox gap={spacings.px12} alignItemsCenter>
      <MaticLogo />
      <Paragraph type="large">{message}</Paragraph>
    </FlexBox>
    <Loader width={48} height={48} />
  </FlexBox>
);

export default LoadingPage;
