import * as React from 'react';

import FlexBox from '../../../components/core/FlexBox';
import { IMaticPolicy } from '../../../interfaces/IPolicy';
import { LineOfBusiness, LineOfBusinessResourceType } from '../../../queries/policies/usePolicyLinesOfBusiness';
import { PolicyTransaction } from '../../../queries/policies/usePolicyTransactions';
import { spacings } from '../../../theme/variables';
import NonSyncedPolicyHome from '../NonSyncedPolicyHome';
import SyncedPolicyVehicles from '../SyncedPolicyVehicles';

const SyncedPolicyAssetsContainer = ({
  lineOfBusiness,
  policyTransactions,
  policy
}: {
  lineOfBusiness: LineOfBusiness;
  policyTransactions: PolicyTransaction[];
  policy: IMaticPolicy;
}): JSX.Element => {
  const lastTransaction = policyTransactions[0];

  return (
    <FlexBox pb={spacings.px16}>
      {lineOfBusiness.resource_type &&
        [LineOfBusinessResourceType.Home, LineOfBusinessResourceType.Umbrella].includes(
          lineOfBusiness.resource_type
        ) && <NonSyncedPolicyHome policy={policy} />}
      {lineOfBusiness.resource_type === LineOfBusinessResourceType.Auto && lastTransaction && (
        <SyncedPolicyVehicles lastTransaction={lastTransaction} lineOfBusiness={lineOfBusiness} />
      )}
    </FlexBox>
  );
};

export default SyncedPolicyAssetsContainer;
