import { FullStory, isInitialized as isFullstoryInitialized } from '@fullstory/browser';
import * as Sentry from '@sentry/react';

import { Resources } from '../constants';
import { setupAnalytics } from '../utils/initializers/application';
import api from './api';
import authInfo, { IAuthInfo } from './authInfo';

interface AuthResponse {
  current_agent_id: number;
  current_agent_name: string;
  current_agent_first_name: string;
  current_agent_last_name: string;
  current_agent_email: string;
  features: IAuthInfo['features'];
  role: IAuthInfo['role'];
  role_category: IAuthInfo['roleCategory'];
  role_label: string;
  date_of_birth: string;
  licensing_types: IAuthInfo['licensingTypes'];
}
const auth = {
  authenticate() {
    return api
      .post(Resources.AUTH.format())
      .then(
        ({
          current_agent_id,
          current_agent_name,
          current_agent_first_name,
          current_agent_last_name,
          current_agent_email,
          features,
          role,
          role_category,
          role_label,
          date_of_birth,
          licensing_types
        }: AuthResponse) => {
          authInfo.currentUserId = current_agent_id;
          authInfo.currentUserName = current_agent_name;
          authInfo.currentUserFirstName = current_agent_first_name;
          authInfo.currentUserLastName = current_agent_last_name;
          authInfo.currentUserEmail = current_agent_email;
          authInfo.features = features;
          authInfo.role = role;
          authInfo.roleCategory = role_category;
          authInfo.roleLabel = role_label;
          authInfo.dateOfBirth = date_of_birth;
          authInfo.licensingTypes = licensing_types;

          setupAnalytics(window.__amp_config);

          Sentry.isInitialized() && Sentry.setUser({ id: current_agent_id.toString(), email: current_agent_email });
          if (isFullstoryInitialized()) {
            if (features.fullstory_session_recording) {
              FullStory('setIdentity', {
                uid: current_agent_id.toString(),
                properties: {
                  displayName: current_agent_name,
                  email: current_agent_email,
                  role
                }
              });
            } else {
              FullStory('shutdown');
            }
          }

          // for chrome extension
          localStorage.setItem('authInfo', JSON.stringify(authInfo));
        }
      );
  }
};

export default auth;
