/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';
import Skeleton from 'react-loading-skeleton';

import FlexBox from '../core/FlexBox';
import Heading from '../core/Heading';

const TablePlaceholder = ({ testId }: { testId: string }): JSX.Element => (
  <div
    data-testid={testId}
    css={css`
      width: 100%;
    `}
  >
    <FlexBox justifyRight>
      <Heading
        type="h3"
        customCss={css`
          width: 130px;
        `}
      >
        <Skeleton />
      </Heading>
    </FlexBox>
    <FlexBox
      mt={32}
      customCss={css`
        justify-content: space-between;
      `}
    >
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
    </FlexBox>
    <FlexBox
      mt={16}
      customCss={css`
        justify-content: space-between;
      `}
    >
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
      <Heading
        type="h3"
        customCss={css`
          width: 20%;
        `}
      >
        <Skeleton />
      </Heading>
    </FlexBox>
  </div>
);

export default TablePlaceholder;
