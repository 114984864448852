import { useMutation } from '@tanstack/react-query';

import api from '../../services/api';

interface MergePersonPayload {
  origin_person_gid: string;
  destination_gid: string;
}
const mergePerson = ({ origin_person_gid, destination_gid }: MergePersonPayload) =>
  api.post(`/api/frontend/people/${origin_person_gid}/merge/`, {
    body: { destination_gid }
  });

const useMergePerson = () => useMutation({ mutationFn: mergePerson });

export default useMergePerson;
