import featureFlags from '../../../../constants/featureFlags';
import { IOpportunity, IQuote } from '../../../../interfaces';
import { opportunityCoveragesForAsset } from '../../../../interfaces/IOpportunity';
import { coverageByKey, PolicyCoverageKey } from '../../../../interfaces/IPolicyCoverage';
import { isInsurableInterestRealProperty, isInsurableInterestVehicle } from '../../../../interfaces/IPolicyType';
import colors from '../../../../theme/colors';
import { moneyFormatter } from '../../../../utils/formatter';

export const getCoverageBackgroundColor = ({
  quote: {
    attributes: { policy_type, coverages, asset_gids }
  },
  opportunity
}: {
  quote: IQuote;
  opportunity?: IOpportunity;
}) => {
  if (featureFlags.newQuotesUI && asset_gids?.[0]) {
    const assetGid = asset_gids?.[0];
    const selectedCoverages = opportunity && opportunityCoveragesForAsset(opportunity, assetGid);
    if (isInsurableInterestRealProperty(policy_type)) {
      const dwellingCoverage = coverageByKey(coverages, PolicyCoverageKey.Dwelling);
      const selectedDwellingCoverage = coverageByKey(
        selectedCoverages?.coverages,
        PolicyCoverageKey.Dwelling
      )?.limit_value;

      const isRequestedCoverageDifferent =
        selectedDwellingCoverage && selectedDwellingCoverage !== dwellingCoverage?.limit_value;
      if (isRequestedCoverageDifferent) {
        return colors.azure95;
      }
    }

    if (isInsurableInterestVehicle(policy_type)) {
      const pdCoverage = coverageByKey(coverages, PolicyCoverageKey.PropertyDamage);

      const selectedPDCoverage = coverageByKey(
        selectedCoverages?.coverages,
        PolicyCoverageKey.PropertyDamage
      )?.limit_value;

      const isRequestedCoverageDifferent = selectedPDCoverage && selectedPDCoverage !== pdCoverage?.limit_value;
      if (featureFlags.newQuotesUI && isRequestedCoverageDifferent) {
        return colors.azure95;
      }
    }
  }
  return 'transparent';
};

export const getDwellingBackgroundColor = ({
  quote: {
    attributes: { policy_type, coverages, asset_gids }
  },
  opportunity
}: {
  quote: IQuote;
  opportunity?: IOpportunity;
}) => {
  if (featureFlags.newQuotesUI && asset_gids?.[0]) {
    const assetGid = asset_gids?.[0];
    const selectedCoverages = opportunity && opportunityCoveragesForAsset(opportunity, assetGid);
    if (isInsurableInterestRealProperty(policy_type)) {
      const dwellingCoverage = coverageByKey(coverages, PolicyCoverageKey.Dwelling);
      const selectedDwellingCoverage = coverageByKey(
        selectedCoverages?.coverages,
        PolicyCoverageKey.Dwelling
      )?.deductible_value;

      const isRequestedDwellingDifferent =
        selectedDwellingCoverage &&
        selectedDwellingCoverage !== dwellingCoverage?.deductible_description &&
        moneyFormatter(selectedDwellingCoverage, true) !== dwellingCoverage?.deductible_description;

      if (isRequestedDwellingDifferent) {
        return colors.azure95;
      }
    }
  }

  return 'transparent';
};
