import { Field, Form, Formik, setNestedObjectValues } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { LICENSE_NUMBER_MAX_LENGTH, QUOTING_REQUIRED_MESSAGE, Translations } from '../../constants';
import authInfo from '../../services/authInfo';
import CustomPropTypes from '../../utils/customPropTypes';
import { getFieldValueGenerator } from '../../utils/formHelpers';
import {
  deprecatedPhoneField,
  emailField,
  getTodayDate,
  minDate,
  minDOBField,
  requiredField,
  ssnField
} from '../../utils/yupRules';
import { ErrorCol } from '../common';
import {
  Checkbox,
  Cleave,
  DatePicker,
  Input,
  NumberInput,
  PhoneInput,
  Select
} from '../common/FormikComponents/FormikComponents';
import Incidents, {
  generateInitialValues as generateIncidentsInitialValues,
  INCIDENTS_NESTED_FORM_NAME,
  normalizeValuesBeforeSend as normalizeIncidentsValuesBeforeSend,
  validationSchema as incidentsValidationSchema
} from '../common/nestedForms/Incidents';
import SensitiveInfoWrapper from '../core/SensitiveInfoWrapper';

const BLANK = '';

class PersonEditor extends React.Component<any, any> {
  state = {
    hasErrors: false,
    hasCustomMailingAddress: false
  };

  componentDidMount() {
    // @ts-expect-error old code
    this.formik && this.formik.setTouched(setNestedObjectValues(this.formik.values));
  }

  getData = () => this.formatValues((this as any).formik.values);

  validate = () => {
    const hasErrors = !!Object.keys((this as any).formik.errors).length;
    this.setState({ hasErrors });

    return !hasErrors;
  };

  formatValues = (values: any) => {
    const inicidentsAttributes = normalizeIncidentsValuesBeforeSend(values);

    return {
      ...values,
      ...inicidentsAttributes
    };
  };

  customMailingAddressOnChangeHandler = () => {
    this.setState(({ hasCustomMailingAddress }: any) => ({ hasCustomMailingAddress: !hasCustomMailingAddress }));
  };

  generateInitialValues = () => {
    const person = this.props.person || {};

    return {
      id: person.id || BLANK,
      cocustomer: person.cocustomer,
      first_name: person.first_name || BLANK,
      middle_name: person.middle_name || BLANK,
      last_name: person.last_name || BLANK,
      marital_status: person.marital_status || BLANK,
      gender: person.gender || BLANK,
      kind: person.kind || BLANK,
      date_of_birth: person.date_of_birth || BLANK,
      ssn: person.ssn || BLANK,
      fico_score: person.fico_score || BLANK,
      email: person.email || BLANK,
      phone: person.phone || BLANK,
      education: person.education || BLANK,
      occupation_type: person.occupation_type || BLANK,
      occupation_since_date: person.occupation_since_date || BLANK,
      license_status: person.license_status || BLANK,
      license_number: person.license_number || BLANK,
      license_state: person.license_state || BLANK,
      age_first_licensed: person.age_first_licensed || BLANK,
      [INCIDENTS_NESTED_FORM_NAME]: generateIncidentsInitialValues(person)
    };
  };

  createFormikRef = (ref: any) => {
    (this as any).formik = ref;
  };

  getFieldValue(fieldName: string) {
    if (!(this as any).formik) {
      return false;
    }

    const getFieldValue = getFieldValueGenerator((this as any).formik);
    return getFieldValue(fieldName);
  }

  render() {
    return (
      // @ts-expect-error old code
      <Formik
        initialValues={this.generateInitialValues()}
        validationSchema={yup.object().shape({
          first_name: requiredField,
          last_name: requiredField,
          ssn: ssnField,
          email: emailField,
          phone: deprecatedPhoneField({ loose: authInfo.features.loose_phone_validation }),
          date_of_birth: minDOBField(),
          [INCIDENTS_NESTED_FORM_NAME]: incidentsValidationSchema,
          occupation_since_date: yup
            .date()
            .min(minDate, "Date can't be earlier than 1800")
            .max(getTodayDate(), "Date can't be later than today")
        })}
        enableReinitialize
        innerRef={this.createFormikRef}
      >
        {({ errors }) => (
          <Form className={this.props.className} autoComplete="off">
            <Row>
              <ErrorCol sm={2} className="section-title">
                Contact Info
              </ErrorCol>
              <ErrorCol sm={9}>
                <Row>
                  <ErrorCol sm={8} id="email" error={errors.email as any} when={this.state.hasErrors}>
                    <Label>Email</Label>
                    <Field
                      component={Input}
                      name="email"
                      className="fs-mask"
                      onChangeInputModifier={(value: string) => value.trim()}
                    />
                  </ErrorCol>
                  <ErrorCol sm={4} id="phone" error={errors.phone as any} when={this.state.hasErrors}>
                    <Label>Phone</Label>
                    <Field component={PhoneInput} name="phone" className="fs-mask" />
                  </ErrorCol>
                </Row>
                <Row>
                  <ErrorCol>
                    <div className="d-flex align-items-center mb-4">
                      <Field component={Checkbox} name="cocustomer" />
                      <span className="ml-2">Co-customer</span>
                    </div>
                  </ErrorCol>
                </Row>
              </ErrorCol>
            </Row>
            <Row>
              <ErrorCol sm={2} className="section-title">
                Personal Info
              </ErrorCol>
              <ErrorCol sm={9}>
                <Row>
                  <ErrorCol id="first_name" sm={4} error={errors.first_name as any} when={this.state.hasErrors}>
                    <Label>First Name *</Label>
                    <Field name="first_name" component={Input} className="fs-mask" />
                  </ErrorCol>
                  <ErrorCol sm={4}>
                    <Label>Middle Name</Label>
                    <Field name="middle_name" component={Input} className="fs-mask" />
                  </ErrorCol>
                  <ErrorCol id="last_name" sm={4} error={errors.last_name as any} when={this.state.hasErrors}>
                    <Label>Last Name *</Label>
                    <Field name="last_name" component={Input} className="fs-mask" />
                  </ErrorCol>
                </Row>
                <Row>
                  <ErrorCol
                    sm={4}
                    warning={!errors.date_of_birth && QUOTING_REQUIRED_MESSAGE}
                    when
                    error={errors.date_of_birth as any}
                  >
                    <Label>DOB</Label>
                    <Field
                      component={DatePicker}
                      name="date_of_birth"
                      maxDate={getTodayDate()}
                      minDate={minDate}
                      className="fs-mask"
                    />
                  </ErrorCol>
                  <SensitiveInfoWrapper>
                    <ErrorCol sm={4} error={errors.ssn as any} when={this.state.hasErrors}>
                      <Label>SSN</Label>
                      <Field
                        component={Cleave}
                        className="form-control fs-mask"
                        name="ssn"
                        options={{
                          numericOnly: true,
                          delimiter: '-',
                          blocks: [3, 2, 4]
                        }}
                      />
                    </ErrorCol>
                  </SensitiveInfoWrapper>
                  <ErrorCol sm={4}>
                    <Label>Fico Score</Label>
                    <Field
                      component={Input}
                      name="fico_score"
                      className="fs-mask"
                      disabled={!authInfo.features.edit_fico_score}
                    />
                  </ErrorCol>
                </Row>
                <Row>
                  <ErrorCol
                    sm={4}
                    warning={!this.getFieldValue('gender') && QUOTING_REQUIRED_MESSAGE}
                    when={!this.getFieldValue('gender')}
                  >
                    <Label>Gender</Label>
                    <Field component={Select} name="gender" options={Translations.genderOptions} placeholder="Select" />
                  </ErrorCol>
                  <ErrorCol
                    sm={4}
                    warning={!this.getFieldValue('marital_status') && QUOTING_REQUIRED_MESSAGE}
                    when={!this.getFieldValue('marital_status')}
                  >
                    <Label>Marital Status</Label>
                    <Field
                      component={Select}
                      name="marital_status"
                      options={Translations.maritalStatusOptions}
                      placeholder="Select"
                    />
                  </ErrorCol>
                  <ErrorCol
                    sm={4}
                    warning={!this.getFieldValue('kind') && QUOTING_REQUIRED_MESSAGE}
                    when={!this.getFieldValue('kind')}
                  >
                    <Label>Relationship to customer</Label>
                    <Field
                      component={Select}
                      name="kind"
                      options={Translations.relationshipOptions}
                      placeholder="Select"
                    />
                  </ErrorCol>
                </Row>

                <Row>
                  <ErrorCol
                    sm={4}
                    warning={!this.getFieldValue('education') && QUOTING_REQUIRED_MESSAGE}
                    when={!this.getFieldValue('education')}
                  >
                    <Label>Education</Label>
                    <Field
                      component={Select}
                      name="education"
                      options={Translations.educationOptions}
                      placeholder="Select"
                    />
                  </ErrorCol>
                  <ErrorCol
                    sm={4}
                    warning={!this.getFieldValue('occupation_type') && QUOTING_REQUIRED_MESSAGE}
                    when={!this.getFieldValue('occupation_type')}
                  >
                    <Label>Occupation type</Label>
                    <Field
                      component={Select}
                      name="occupation_type"
                      options={Translations.occupationTypeOptions}
                      placeholder="Select"
                    />
                  </ErrorCol>
                  <ErrorCol sm={4} error={errors.occupation_since_date as any} when>
                    <Label>Current job start date</Label>
                    <Field
                      component={DatePicker}
                      name="occupation_since_date"
                      minDate={minDate}
                      maxDate={getTodayDate()}
                    />
                  </ErrorCol>
                </Row>
              </ErrorCol>
            </Row>

            <SensitiveInfoWrapper>
              <Row>
                <ErrorCol sm={2} className="section-title">
                  Driver Info
                </ErrorCol>
                <ErrorCol sm={9}>
                  <Row>
                    <ErrorCol
                      sm={4}
                      warning={!this.getFieldValue('license_status') && QUOTING_REQUIRED_MESSAGE}
                      when={!this.getFieldValue('license_status')}
                    >
                      <Label>License status</Label>
                      <Field
                        component={Select}
                        name="license_status"
                        options={Translations.licenseStatusOptions}
                        placeholder="Select"
                      />
                    </ErrorCol>
                    <ErrorCol sm={4}>
                      <Label>License number</Label>
                      <Field
                        component={Input}
                        maxLength={LICENSE_NUMBER_MAX_LENGTH}
                        name="license_number"
                        className="fs-mask"
                      />
                    </ErrorCol>
                    <ErrorCol sm={4}>
                      <Label>License state</Label>
                      <Field
                        component={Select}
                        name="license_state"
                        options={Translations.usaStates}
                        placeholder="Select"
                        className="fs-mask"
                      />
                    </ErrorCol>
                  </Row>
                  <Row>
                    <ErrorCol sm={4}>
                      <Label>Age first licensed</Label>
                      <Field component={NumberInput} name="age_first_licensed" className="fs-mask" />
                    </ErrorCol>
                  </Row>
                </ErrorCol>
              </Row>
              <Row className="mt-4">
                <ErrorCol sm={2} className="section-title">
                  Incidents
                </ErrorCol>
                <ErrorCol sm={6}>
                  {/* @ts-expect-error old code */}
                  <Incidents
                    formName={INCIDENTS_NESTED_FORM_NAME}
                    residentialState={this.props.customerLivingAddressState}
                  />
                </ErrorCol>
              </Row>
            </SensitiveInfoWrapper>
          </Form>
        )}
      </Formik>
    );
  }
}

// @ts-expect-error old code
PersonEditor.propTypes = {
  person: CustomPropTypes.Person,
  customerLivingAddressState: PropTypes.string,
  className: PropTypes.string
};

// @ts-expect-error old code
PersonEditor.defaultProps = {
  person: null,
  className: null,
  customerLivingAddressState: null
};

export default PersonEditor;
