/* eslint react/prop-types: 0 */
// @ts-expect-error old code
import CleaveComponent from 'cleave.js/react';
import React from 'react';
import { Input as InputComponent } from 'reactstrap';

import AsyncSelectComponent from '../AsyncSelect/AsyncSelect';
import CheckboxComponent from '../Checkbox/Checkbox';
import DatePickerComponent from '../DatePicker/DatePicker';
import MoneyInputComponent from '../MoneyInput/MoneyInput';
import MultiSelectComponent from '../MultiSelect/MultiSelect';
import NumberInputComponent from '../NumberInput/NumberInput';
import PhoneInputComponent from '../PhoneInput/PhoneInput';
import SelectComponent from '../Select/Select';
import TypeAddOnComponent from '../TypeAddOn/TypeAddOn';

const defaultFunction = (value: any) => value;

/* eslint-disable prettier/prettier */
const formikAdapter = (WrappedComponent: any) => ({
  field,
  form,
  onChangeInputModifier = defaultFunction,
  onChange = defaultFunction,
  onBlur = defaultFunction,
  shouldValidateOnChange = true,
  shouldValidateOnBlur = true,
  ...rest
}: any) => {
  const handleChange = ({ target: { rawValue, value } }: any) => {
    const inputValue = onChangeInputModifier(rawValue || value);
    form.setFieldValue(field.name, inputValue, shouldValidateOnChange);
    onChange(inputValue);
  };

  const handleBlur = ({ target: { rawValue, value } }: any) => {
    const inputValue = onChangeInputModifier(rawValue || value);
    form.setFieldTouched(field.name, true, shouldValidateOnBlur);
    onBlur(field.name, inputValue);
  };

  const props = Object.assign(rest, {
    onChange: handleChange,
    onBlur: handleBlur,
    name: field.name,
    value: field.value
  });

  return <WrappedComponent {...props} />;
};
/* eslint-enable prettier/prettier */

export const Input = formikAdapter(InputComponent);
export const Cleave = formikAdapter(CleaveComponent);
export const Select = formikAdapter(SelectComponent);
export const AsyncSelect = formikAdapter(AsyncSelectComponent);
export const MultiSelect = formikAdapter(MultiSelectComponent);
export const DatePicker = formikAdapter(DatePickerComponent);
export const PhoneInput = formikAdapter(PhoneInputComponent);
export const MoneyInput = formikAdapter(MoneyInputComponent);
export const Checkbox = formikAdapter(CheckboxComponent);
export const NumberInput = formikAdapter(NumberInputComponent);
export const TypeAddOn = formikAdapter(TypeAddOnComponent);
