import { useQuery } from '@tanstack/react-query';

import { ICarrier } from '../../interfaces/ICarrier';
import { PolicyType } from '../../interfaces/IPolicyType';
import api from '../../services/api';
import { toQueryParams } from '../../utils/queryParams';

interface CarriersFilters {
  appointed?: boolean;
  appointed_in_past?: boolean;
  quotable?: boolean;
  post_sale?: boolean;
  policy_type?: PolicyType;
  state?: string;
}

export const getCarriers = (filters?: CarriersFilters): Promise<{ carriers: ICarrier[] }> =>
  api.get(`/api/frontend/carriers?${toQueryParams({ ...filters })}`);

const useCarriers = (filters?: CarriersFilters) =>
  useQuery({
    queryKey: ['carriers', filters],
    queryFn: () => getCarriers(filters),
    select: data => data.carriers,
    staleTime: Infinity
  });

export default useCarriers;
