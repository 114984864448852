/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import * as React from 'react';

import FlexBox from '../../../../components/core/FlexBox';
import { ClockIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { useGuidedSellingExperienceContext } from '../../../../contexts/GuidedSellingExperienceContext';
import { presentPersonPhone } from '../../../../interfaces/IPerson';
import usePersonTimezone from '../../../../queries/people/usePersonTimezone';
import { spacings } from '../../../../theme/variables';
import { calculateTimeWithOffset } from '../../../../utils/formatter';

const PersonTimeHeader = (): JSX.Element | null => {
  const { personGid, person, leadGid, candidateGid } = useGuidedSellingExperienceContext();

  const { data } = usePersonTimezone({
    personGid,
    engagementGid: leadGid || candidateGid,
    phone: presentPersonPhone(person)
  });
  const offset = data?.utc_offset_hours;
  const [time, setTime] = React.useState('');

  React.useEffect(() => {
    offset && setTime(calculateTimeWithOffset(offset));
    const timer = setInterval(() => {
      offset && setTime(calculateTimeWithOffset(offset));
    }, 1000 * 60);

    return () => {
      clearInterval(timer);
    };
  }, [offset]);

  return offset ? (
    <FlexBox
      alignItemsCenter
      gap={spacings.px4}
      customCss={css`
        white-space: nowrap;
      `}
    >
      <ClockIcon />
      <Text>{time}</Text>
      <Text>{data.abbreviation || data.display_name || data.name}</Text>
    </FlexBox>
  ) : null;
};

export default PersonTimeHeader;
