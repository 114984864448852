/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FastField, Field } from 'formik';
import React from 'react';
import { Label, Row } from 'reactstrap';
import * as yup from 'yup';

import { ErrorCol, Link } from '../../../components/common';
import {
  Checkbox,
  Cleave,
  DatePicker,
  Input,
  NumberInput,
  PhoneInput,
  Select
} from '../../../components/common/FormikComponents';
import Incidents, {
  generateInitialValues as generateIncidentsInitialValues,
  INCIDENTS_NESTED_FORM_NAME,
  normalizeValuesBeforeSend as normalizeIncidentsValuesBeforeSend,
  validationSchema as incidentsValidationSchema
} from '../../../components/common/nestedForms/Incidents';
import FlexBox from '../../../components/core/FlexBox';
import BaseForm from '../../../components/core/forms/BaseForm';
import Heading from '../../../components/core/Heading';
import SensitiveInfoWrapper from '../../../components/core/SensitiveInfoWrapper';
import { LICENSE_NUMBER_MAX_LENGTH, QUOTING_REQUIRED_MESSAGE, Translations } from '../../../constants';
import { IPerson, IPersonRelation, IRelatedPerson, MaritalStatus, RelationKind } from '../../../interfaces/IPerson';
import { UpdatePersonPayload } from '../../../queries/people/usePerson';
import usePersonDrivingRecord, { UpdateDrivingRecordPayload } from '../../../queries/people/usePersonDrivingRecord';
import { RelatedPersonPayload } from '../../../queries/people/usePersonRelatedPeople';
import authInfo from '../../../services/authInfo';
import colors from '../../../theme/colors';
import { sizes } from '../../../theme/variables';
import {
  deprecatedPhoneField,
  emailField,
  getTodayDate,
  minDate,
  minDOBField,
  requiredField,
  ssnField
} from '../../../utils/yupRules';

interface EditRelatedPersonModalProps {
  person: IPerson;
  relatedPerson: IRelatedPerson | undefined;
  onClose: () => void;
  onUpdatePerson: ({
    editedRelatedPerson,
    drivingRecord
  }: {
    editedRelatedPerson: UpdatePersonPayload;
    personRelation: IPersonRelation;
    drivingRecord: UpdateDrivingRecordPayload;
  }) => void;
  onCreatePerson: ({
    newRelatedPerson,
    drivingRecord
  }: {
    newRelatedPerson: RelatedPersonPayload;
    drivingRecord: UpdateDrivingRecordPayload;
  }) => void;
}

const EditRelatedPersonModal = ({
  person,
  relatedPerson,
  onClose,
  onUpdatePerson,
  onCreatePerson
}: EditRelatedPersonModalProps) => {
  const { data: drivingRecord } = usePersonDrivingRecord(relatedPerson?.gid);

  return (
    <BaseForm
      enableReinitialize
      type="fullScreen"
      cancelWithEscape
      header={
        <FlexBox>
          <Heading type="h3">{relatedPerson ? 'Edit person' : 'Add person'}. Customer:</Heading>
          <Link
            to={`/people/${person.gid}`}
            css={css`
              margin-left: 4px;
            `}
          >
            <Heading
              type="h3"
              color={colors.azure50}
              className="fs-mask"
              css={css`
                &:hover {
                  color: ${colors.blueHoverLink};
                }
              `}
            >
              {person.name}
            </Heading>
          </Link>
        </FlexBox>
      }
      pt={0}
      pr={0}
      pb={0}
      pl={0}
      initialValues={{
        gid: relatedPerson?.gid || '',
        cocustomer: relatedPerson?.cocustomer || false,
        first_name: relatedPerson?.first_name || '',
        middle_name: relatedPerson?.middle_name || '',
        last_name: relatedPerson?.last_name || '',
        marital_status: (relatedPerson?.marital_status || '') as MaritalStatus,
        gender: relatedPerson?.gender || '',
        kind: relatedPerson?.kind || '',
        date_of_birth: relatedPerson?.date_of_birth || '',
        ssn: relatedPerson?.ssn || '',
        fico_score: relatedPerson?.fico_score || '',
        email: relatedPerson?.email || '',
        phone: relatedPerson?.phone || '',
        education: relatedPerson?.education || '',
        occupation_type: relatedPerson?.occupation_type || '',
        occupation_since_date: relatedPerson?.occupation_since_date || '',
        license_status: relatedPerson?.license_status || '',
        license_number: relatedPerson?.license_number || '',
        license_state: relatedPerson?.license_state || '',
        age_first_licensed: relatedPerson?.age_first_licensed || '',
        [INCIDENTS_NESTED_FORM_NAME]: generateIncidentsInitialValues(drivingRecord)
      }}
      validationSchema={yup.object().shape({
        first_name: requiredField,
        last_name: requiredField,
        ssn: ssnField,
        email: emailField,
        phone: deprecatedPhoneField({ loose: authInfo.features.loose_phone_validation }),
        date_of_birth: minDOBField(),
        occupation_since_date: yup
          .date()
          .min(minDate, "Date can't be earlier than 1800")
          .max(getTodayDate(), "Date can't be later than today"),
        [INCIDENTS_NESTED_FORM_NAME]: incidentsValidationSchema
      })}
      onSubmit={values => {
        const incidentsAttributes = normalizeIncidentsValuesBeforeSend(values);

        const ficoScoreValue = values.fico_score ? parseInt(values.fico_score.toString()) : undefined;
        const ageFirstLicensedValue = values.age_first_licensed
          ? parseInt(values.age_first_licensed.toString())
          : undefined;

        const normalizedValues = { ...values, fico_score: ficoScoreValue, age_first_licensed: ageFirstLicensedValue };

        if (relatedPerson) {
          const { kind, cocustomer, ...editedRelatedPerson } = normalizedValues;

          return onUpdatePerson({
            editedRelatedPerson,
            personRelation: { kind: kind as RelationKind, cocustomer },
            drivingRecord: incidentsAttributes
          });
        }

        return onCreatePerson({ newRelatedPerson: normalizedValues, drivingRecord: incidentsAttributes });
      }}
      submitText={relatedPerson ? 'Edit person' : 'Add person'}
      cancelText="Cancel"
      cancelHandler={onClose}
      closeHandler={onClose}
      renderForm={formik => {
        const { values, errors } = formik;
        const todayDate = getTodayDate();
        return (
          <FlexBox fitParentWidth columnDirection alignItemsCenter>
            <div
              css={css`
                max-width: ${sizes.containerMaxWidth}px;
                width: 100%;
              `}
            >
              <Row>
                <ErrorCol sm={2} className="section-title">
                  Contact Info
                </ErrorCol>
                <ErrorCol sm={9}>
                  <Row>
                    <ErrorCol sm={8} id="email" error={errors.email} when={!!errors.email}>
                      <Label>Email</Label>
                      <Field
                        component={Input}
                        name="email"
                        className="fs-mask"
                        onChangeInputModifier={(value: string) => value.trim()}
                      />
                    </ErrorCol>
                    <ErrorCol sm={4} id="phone" error={errors.phone} when={!!errors.phone}>
                      <Label>Phone</Label>
                      <Field component={PhoneInput} name="phone" className="fs-mask" />
                    </ErrorCol>
                  </Row>
                  <Row>
                    <ErrorCol>
                      <div className="d-flex align-items-center mb-4">
                        <Field component={Checkbox} name="cocustomer" />
                        <span className="ml-2">Co-customer</span>
                      </div>
                    </ErrorCol>
                  </Row>
                </ErrorCol>
              </Row>
              <Row>
                <ErrorCol sm={2} className="section-title">
                  Personal Info
                </ErrorCol>
                <ErrorCol sm={9}>
                  <Row>
                    <ErrorCol id="first_name" sm={4} error={errors.first_name} when={!!errors.first_name}>
                      <Label>First Name *</Label>
                      <Field name="first_name" component={Input} className="fs-mask" />
                    </ErrorCol>
                    <ErrorCol sm={4}>
                      <Label>Middle Name</Label>
                      <Field name="middle_name" component={Input} className="fs-mask" />
                    </ErrorCol>
                    <ErrorCol id="last_name" sm={4} error={errors.last_name} when={!!errors.last_name}>
                      <Label>Last Name *</Label>
                      <Field name="last_name" component={Input} className="fs-mask" />
                    </ErrorCol>
                  </Row>
                  <Row>
                    <ErrorCol
                      sm={4}
                      warning={!errors.date_of_birth && QUOTING_REQUIRED_MESSAGE}
                      when={!!errors.date_of_birth}
                      error={errors.date_of_birth}
                    >
                      <Label>DOB</Label>
                      <FastField
                        component={DatePicker}
                        name="date_of_birth"
                        maxDate={todayDate}
                        minDate={minDate}
                        className="fs-mask"
                      />
                    </ErrorCol>
                    <SensitiveInfoWrapper>
                      <ErrorCol sm={4} error={errors.ssn} when={!!errors.ssn}>
                        <Label>SSN</Label>
                        <Field
                          component={Cleave}
                          className="form-control fs-mask"
                          name="ssn"
                          options={{
                            numericOnly: true,
                            delimiter: '-',
                            blocks: [3, 2, 4]
                          }}
                        />
                      </ErrorCol>
                    </SensitiveInfoWrapper>
                    <ErrorCol sm={4}>
                      <Label>Fico Score</Label>
                      <Field
                        component={Input}
                        name="fico_score"
                        className="fs-mask"
                        disabled={!authInfo.features.edit_fico_score}
                      />
                    </ErrorCol>
                  </Row>
                  <Row>
                    <ErrorCol sm={4} warning={!values.gender && QUOTING_REQUIRED_MESSAGE} when={!values.gender}>
                      <Label>Gender</Label>
                      <Field
                        component={Select}
                        name="gender"
                        options={Translations.genderOptions}
                        placeholder="Select"
                      />
                    </ErrorCol>
                    <ErrorCol
                      sm={4}
                      warning={!values.marital_status && QUOTING_REQUIRED_MESSAGE}
                      when={!values.marital_status}
                    >
                      <Label>Marital Status</Label>
                      <Field
                        component={Select}
                        name="marital_status"
                        options={Translations.maritalStatusOptions}
                        placeholder="Select"
                      />
                    </ErrorCol>
                    <ErrorCol sm={4} warning={!values.kind && QUOTING_REQUIRED_MESSAGE} when={!values.kind}>
                      <Label>Relationship to customer</Label>
                      <Field
                        component={Select}
                        name="kind"
                        options={Translations.relationshipOptions}
                        placeholder="Select"
                      />
                    </ErrorCol>
                  </Row>

                  <Row>
                    <ErrorCol sm={4} warning={!values.education && QUOTING_REQUIRED_MESSAGE} when={!values.education}>
                      <Label>Education</Label>
                      <Field
                        component={Select}
                        name="education"
                        options={Translations.educationOptions}
                        placeholder="Select"
                      />
                    </ErrorCol>
                    <ErrorCol
                      sm={4}
                      warning={!values.occupation_type && QUOTING_REQUIRED_MESSAGE}
                      when={!values.occupation_type}
                    >
                      <Label>Occupation type</Label>
                      <Field
                        component={Select}
                        name="occupation_type"
                        options={Translations.occupationTypeOptions}
                        placeholder="Select"
                      />
                    </ErrorCol>
                    <ErrorCol sm={4} error={errors.occupation_since_date} when>
                      <Label>Current job start date</Label>
                      <FastField
                        component={DatePicker}
                        name="occupation_since_date"
                        minDate={minDate}
                        maxDate={todayDate}
                      />
                    </ErrorCol>
                  </Row>
                </ErrorCol>
              </Row>

              <SensitiveInfoWrapper>
                <>
                  <Row>
                    <ErrorCol sm={2} className="section-title">
                      Driver Info
                    </ErrorCol>
                    <ErrorCol sm={9}>
                      <Row>
                        <ErrorCol
                          sm={4}
                          warning={!values.license_status && QUOTING_REQUIRED_MESSAGE}
                          when={!values.license_status}
                        >
                          <Label>License status</Label>
                          <Field
                            component={Select}
                            name="license_status"
                            options={Translations.licenseStatusOptions}
                            placeholder="Select"
                          />
                        </ErrorCol>
                        <ErrorCol sm={4}>
                          <Label>License number</Label>
                          <Field
                            component={Input}
                            maxLength={LICENSE_NUMBER_MAX_LENGTH}
                            name="license_number"
                            className="fs-mask"
                          />
                        </ErrorCol>
                        <ErrorCol sm={4}>
                          <Label>License state</Label>
                          <Field
                            component={Select}
                            name="license_state"
                            options={Translations.usaStates}
                            placeholder="Select"
                            className="fs-mask"
                          />
                        </ErrorCol>
                      </Row>
                      <Row>
                        <ErrorCol sm={4}>
                          <Label>Age first licensed</Label>
                          <Field component={NumberInput} name="age_first_licensed" className="fs-mask" />
                        </ErrorCol>
                      </Row>
                    </ErrorCol>
                  </Row>
                  <Row className="mt-4">
                    <ErrorCol sm={2} className="section-title">
                      Incidents
                    </ErrorCol>
                    <ErrorCol sm={6}>
                      <Incidents
                        index={null}
                        formik={formik}
                        formName={INCIDENTS_NESTED_FORM_NAME}
                        residentialState=""
                      />
                    </ErrorCol>
                  </Row>
                </>
              </SensitiveInfoWrapper>
            </div>
          </FlexBox>
        );
      }}
    />
  );
};

export default EditRelatedPersonModal;
