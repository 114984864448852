import { useQuery } from '@tanstack/react-query';

import { IDashboardTask } from '../../interfaces';
import api from '../../services/api';

export const TASK_REMINDERS_QUERY_KEY = 'task-reminders';

const useTaskReminders = () =>
  useQuery({
    queryKey: [TASK_REMINDERS_QUERY_KEY],
    queryFn: (): Promise<{ tasks: IDashboardTask[] }> => api.get('/api/frontend/tasks/reminders'),
    select: data => data.tasks,
    staleTime: Infinity
  });

export default useTaskReminders;
