/** @jsxImportSource @emotion/react */

import { spacings } from '../../../theme/variables';
import FlexBox from '../FlexBox';
import { AddIcon } from '../icons';
import Button, { ButtonSize, ButtonVariant } from './Button';

const AddButton = ({
  content,
  onClick,
  testId,
  disabled = false
}: {
  content?: string;
  onClick: () => void;
  testId?: string;
  disabled?: boolean;
}): JSX.Element => {
  return (
    <Button
      disabled={disabled}
      data-testid={testId}
      variant={ButtonVariant.Text}
      size={ButtonSize.Large}
      onClick={onClick}
      mh={spacings.px8}
    >
      <FlexBox gap={spacings.px4} alignItemsCenter>
        <AddIcon />
        {content}
      </FlexBox>
    </Button>
  );
};

export default AddButton;
