import { create } from 'zustand';

import { PolicyType } from '../../../interfaces/IPolicyType';

interface IPostSalesSyncHelp {
  carrierId: number | null;
  policyType: PolicyType | null;
  state: string | null;
  setPostSalesSyncValues: (values: Partial<IPostSalesSyncHelp>) => void;
}

const usePostSalesSync = create<IPostSalesSyncHelp>()(set => ({
  carrierId: null,
  policyType: null,
  state: null,
  setPostSalesSyncValues: values =>
    set(state => ({
      ...state,
      ...values
    }))
}));

export default usePostSalesSync;
