/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { ChevronLeft, ErrorIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { usePopper } from '../../../../hooks';
import { IQuote } from '../../../../interfaces';
import { findPolicyTypeLabel } from '../../../../interfaces/IPolicyType';
import { IQuotesRequest, SchedulingError } from '../../../../interfaces/IQuotesRequests';
import colors from '../../../../theme/colors';
import { spacings } from '../../../../theme/variables';
import StatusTooltip from '../_components/QuoteRow/StatusTooltip';

interface IQuotingErrors {
  quotesRequest: IQuotesRequest;
  errors: IQuote[] | undefined;
}

const QuotingErrorsByAssets = ({ quotesRequest, errors }: IQuotingErrors) => {
  const { anchorEl, triggerPopper, PopperComponent, popperProps, elementRef } = usePopper({
    style: { padding: '12px', zIndex: 999 }
  });
  const groupedSchedulingErrors =
    quotesRequest.scheduling_errors?.reduce(
      (acc, schedulingError) => {
        const { assets_gids } = schedulingError;
        const assetsKey = assets_gids.join(',');

        return {
          ...acc,
          [assetsKey]: [...(acc[assetsKey] || []), schedulingError]
        };
      },
      {} as Record<string, SchedulingError[]>
    ) || {};

  if (!quotesRequest.scheduling_errors?.length && !errors?.length) {
    return null;
  }

  const schedulingErrorsCount = quotesRequest.scheduling_errors?.length || 0;
  const quoteErrorsCount = errors?.length || 0;
  const errorsCount = schedulingErrorsCount + quoteErrorsCount;

  return (
    <FlexBox>
      <FlexBox
        alignItemsCenter
        gap={spacings.px4}
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          triggerPopper(e);
          e.stopPropagation();
        }}
        border
        customCss={css`
          border-radius: 8px;
          white-space: nowrap;
          &:hover {
            cursor: pointer;
          }
        `}
        ph={spacings.px6}
        pv={spacings.px6}
      >
        <ErrorIcon color={colors.statusRed} width={spacings.px24} height={spacings.px24} />
        <Text bold>Errors ({errorsCount})</Text>
        <ChevronLeft
          css={css`
            transform: rotate(${anchorEl ? '90deg' : '270deg'});
            transition: transform 0.2s;
          `}
          color={colors.black}
        />
      </FlexBox>

      <div ref={elementRef}>
        <PopperComponent {...popperProps}>
          <FlexBox
            ph={spacings.px24}
            pv={spacings.px12}
            gap={spacings.px8}
            roundBorder
            border
            boxShadow
            backgroundColor={colors.white}
            columnDirection
            customCss={css`
              max-width: 400px;
            `}
          >
            {Object.keys(groupedSchedulingErrors).map(key => {
              const schedulingErrors = groupedSchedulingErrors[key];
              const firstError = schedulingErrors?.[0];

              if (!firstError) {
                return null;
              }

              return (
                <div key={key}>
                  <Text
                    customCss={css`
                      white-space: pre-wrap;
                    `}
                  >
                    {findPolicyTypeLabel(firstError.policy_type)}
                  </Text>
                  <FlexBox columnDirection gap={spacings.px4} mt={spacings.px4}>
                    {schedulingErrors.map(({ gid, error_message }) => (
                      <Text key={gid}>{error_message}</Text>
                    ))}
                  </FlexBox>
                </div>
              );
            })}

            <Container mt={spacings.px8}>
              {errors?.map((quote, index) => {
                const {
                  gid: quoteGid,
                  attributes: { carrier }
                } = quote;
                return (
                  <React.Fragment key={quoteGid}>
                    <StatusTooltip quote={quote} />
                    <Text underline type="small" data-for={`${quoteGid}-eligibility`} data-tip>
                      {carrier.name}
                    </Text>
                    <Text type="small">{index < errors.length - 1 ? ', ' : ''}</Text>
                  </React.Fragment>
                );
              })}
            </Container>
          </FlexBox>
        </PopperComponent>
      </div>
    </FlexBox>
  );
};

export default React.memo(QuotingErrorsByAssets);
