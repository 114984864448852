import { uniqBy } from 'ramda';

import { CITY, LINE1, LINE2, STATE, ZIP } from '../../../constants/addressForm';
import { IUIFlowElement } from '../../../interfaces/IUIFlow';
import { IAnswer, IQuestion, QuestionType } from '../../../interfaces/IWorkflow';
import { dateFormatter, phoneFormatter } from '../../../utils/formatter';

export const answerForDatapoint = (answers: IAnswer[], datapointKey: string) =>
  answers.find(({ key }) => key === datapointKey);

export const questionWithSubQuestions = (
  questionElements: (IUIFlowElement & { content: IQuestion })[],
  questionKey: string
): (IUIFlowElement & { content: IQuestion })[] => {
  const questionElement = questionElements.find(({ content: { key } }) => key === questionKey);
  if (!questionElement) {
    return [];
  }

  const otherQuestionElements = questionElements.filter(({ content: { key } }) => key !== questionKey);
  const subQuestionElements = otherQuestionElements.filter(({ content: { visibility_conditions } }) =>
    visibility_conditions?.flat()?.some(({ source }) => source === questionKey)
  );

  if (subQuestionElements.length === 0) {
    return [questionElement];
  }

  return [
    questionElement,
    ...uniqBy(
      ({ content: { key } }) => key,
      subQuestionElements
        .map(subQuestionElement => questionWithSubQuestions([...subQuestionElements], subQuestionElement.content.key))
        .flat()
    )
  ];
};

export const uiValueForQuestion = ({ type, variants }: IQuestion, answer: IAnswer) => {
  const existingAnswer = answer.value;

  if (existingAnswer === '' || existingAnswer === undefined || existingAnswer === null) {
    return null;
  }

  switch (type) {
    case QuestionType.Checkbox:
      return existingAnswer ? 'Yes' : 'No';
    case QuestionType.Address: {
      const typedAnswer = existingAnswer as Record<string, string>;

      return [typedAnswer[LINE1], typedAnswer[LINE2], typedAnswer[CITY], typedAnswer[STATE], typedAnswer[ZIP]]
        .filter(Boolean)
        .join(', ');
    }
    case QuestionType.MultiSelect: {
      const typedAnswer = existingAnswer as string[];
      const answerOptions = variants?.filter(variant => typedAnswer.includes(variant.value));

      return answerOptions?.length ? Array.from(answerOptions, x => x.label).join(', ') : '—';
    }
    case QuestionType.Radio:
    case QuestionType.Select: {
      const answerOptions = variants?.filter(answerOption => answerOption.value === existingAnswer);
      return answerOptions?.[0]?.label;
    }
    case QuestionType.Date:
      return dateFormatter(existingAnswer);
    case QuestionType.Phone:
      return phoneFormatter(existingAnswer);
    case QuestionType.Text:
    case QuestionType.Number:
    case QuestionType.Email:
    case QuestionType.Slider:
    default:
      return existingAnswer;
  }
};
