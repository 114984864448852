/** @jsxImportSource @emotion/react */
import { ClassNames, css } from '@emotion/react';
import { useFormikContext } from 'formik';
import { FC, ReactElement, useContext, useEffect, useRef } from 'react';

import crossIcon from '../../../assets/new_icons/close.svg';
import deleteIcon from '../../../assets/new_icons/delete.svg';
import { WARM_GREY_5, WARM_GREY_50, WHITE } from '../../../constants/colors';
import { DocumentRule, RuleDemands } from '../../../queries/document_rules/useDocumentRules';
import useDocumentTypes from '../../../queries/document_types/useDocumentTypes';
import authInfo from '../../../services/authInfo';
import { borderRadius } from '../../../theme/variables';
import { Checkbox, DropDownMenu } from '../../common';
import { PDFContext } from '../../common/PDFViewer';
import Tooltip from '../../common/Tooltip/NewTooltip';
import { FullscreenIcon } from '../../core/icons';
import { DocumentFile } from './index';

interface Props {
  rule: DocumentRule;
  files: DocumentFile[];
  onDeleteHandler: (file: any) => void;
  onChangeHandler: (rule: DocumentRule) => void;
  optional?: boolean;
  other?: boolean;
}

const ellipsisCss = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const wrapperCss = (color: string) => css`
  display: flex;
  background-color: ${color};
  border: 1px solid ${WARM_GREY_50};
  border-radius: ${borderRadius}px;
  font-size: 16px;
  padding: 12px;
  margin-bottom: 4px;
  align-items: center;
`;

const innerWrapperCss = css`
  display: flex;
  flex: 1;
  margin-left: 12px;
  justify-content: flex-end;
  align-items: center;
  min-width: 0;
`;

const expandCss = css`
  background-color: transparent;
  border: 0;

  &:hover {
    opacity: 0.7;
  }
`;

const CHECKBOX_DISABLED_MSG = "You can't uncheck this document. It is required for the selected carrier.";
const COULD_NOT_COLLECT_MSG = 'The document cannot be collected';
const FILE_NOT_UPLOADED_MSG = 'File is not uploaded yet';
const DEC_PAGE_KEY = 'carrier_dec_page';

const RuleRow: FC<Props> = ({ rule, files, onDeleteHandler, onChangeHandler, optional, other }): ReactElement => {
  const { transformedData: documentTypes = [] } = useDocumentTypes(true);
  const [, setFileToPreview] = useContext(PDFContext);
  const {
    initialValues: { id }
  } = useFormikContext<{ id: number | undefined }>();
  const pdfIsShown = useRef(!!id);

  const currentDocument = documentTypes.find(type => type.id === rule.document_type_id);
  const title = currentDocument?.title;
  const couldNotCollectDocument = currentDocument?.could_not_collect;
  const previewAvailable = currentDocument?.key === DEC_PAGE_KEY;

  useEffect(() => {
    if (previewAvailable && files[0] && !pdfIsShown.current) {
      setFileToPreview(files[0]);
      pdfIsShown.current = true;
    }
  }, [previewAvailable, files, setFileToPreview]);

  const onChange = () => {
    if (rule.changeable || optional) {
      onChangeHandler({
        ...rule,
        demand: rule.demand === RuleDemands.Optional ? RuleDemands.Required : RuleDemands.Optional,
        changeable: true
      });
    }
  };

  const disabled = rule.demand === RuleDemands.Required && !files[0] && !optional && !rule.changeable;

  const showTooltip = disabled || (files[0] && !optional && !rule.changeable);
  const tooltipProps = showTooltip
    ? {
        'data-tip': CHECKBOX_DISABLED_MSG,
        'data-for': `tooltip-disabled-${rule.document_rule_id}`
      }
    : {};

  const renderFileItem = (file: DocumentFile) => (
    <>
      <span
        className="fs-mask"
        css={css`
          ${ellipsisCss}
          font-weight: bold;
        `}
      >
        {file.name}
      </span>
      {previewAvailable && (
        <button css={expandCss} type="button" onClick={() => setFileToPreview(file)}>
          <FullscreenIcon />
        </button>
      )}
      <DropDownMenu
        wrapperTestId="delete-file"
        right
        items={[
          {
            action: () => onDeleteHandler(file),
            icon: deleteIcon,
            label: 'Delete'
          }
        ]}
      />
    </>
  );

  const ariaLabel = (rule.could_not_collect && COULD_NOT_COLLECT_MSG) || files[0]?.name || FILE_NOT_UPLOADED_MSG;

  const couldNotCollectAvailable = authInfo.features.set_policy_documents_could_not_collect && couldNotCollectDocument;

  return title ? (
    <>
      <div css={wrapperCss(files[0] ? WHITE : WARM_GREY_5)}>
        {other ? (
          <span>{title}</span>
        ) : (
          <>
            <Checkbox
              {...tooltipProps}
              onChange={onChange}
              value={rule.demand === RuleDemands.Required}
              disabled={disabled}
              name={title}
              label={title}
            />
            {showTooltip && <Tooltip id={`tooltip-disabled-${rule.document_rule_id}`} />}
          </>
        )}

        <div css={innerWrapperCss} title={ariaLabel} aria-label={ariaLabel}>
          {!files[0] && (
            <>
              <span css={ellipsisCss}>
                {rule.could_not_collect
                  ? COULD_NOT_COLLECT_MSG
                  : rule.demand === RuleDemands.Required && FILE_NOT_UPLOADED_MSG}
              </span>
              {couldNotCollectAvailable && (
                <ClassNames>
                  {({ css }) => (
                    <DropDownMenu
                      wrapperTestId="rule-actions"
                      right
                      items={[
                        {
                          action: () => {
                            onChangeHandler({
                              ...rule,
                              could_not_collect: !rule.could_not_collect
                            });
                          },
                          icon: crossIcon,
                          label: rule.could_not_collect ? 'Undo' : 'Could not collect',
                          classes: rule.could_not_collect
                            ? css`
                                img {
                                  transform: rotateX(180deg);
                                }
                              `
                            : ''
                        }
                      ]}
                    />
                  )}
                </ClassNames>
              )}
            </>
          )}
          {files[0] && renderFileItem(files[0])}
        </div>
      </div>
      {files.map((file, index) => {
        if (index === 0) {
          return false;
        } else {
          return (
            <div
              title={file.name}
              key={file.uid}
              css={css`
                display: flex;
                justify-content: flex-end;
                padding: 5px 12px;
              `}
            >
              {renderFileItem(file)}
            </div>
          );
        }
      })}
    </>
  ) : (
    (null as unknown as ReactElement)
  );
};

export default RuleRow;
