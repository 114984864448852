import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { INotification } from '../../interfaces';
import api from '../../services/api';

const NOTIFICATIONS_QUERY_KEY = 'notifications';

const markNotificationsAsRead = ({
  notificationsIds
}: {
  notificationsIds: number[];
}): Promise<{ notifications: INotification[] }> =>
  api.put('/api/frontend/notifications/mark_as_read', { body: { notifications_ids: notificationsIds } });

export const useMarkNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: markNotificationsAsRead,
    onSuccess: result => queryClient.setQueryData([NOTIFICATIONS_QUERY_KEY], () => result)
  });
};

const getNotifications = (): Promise<{ notifications: INotification[] }> => api.get('/api/frontend/notifications');

const useNotifications = () =>
  useQuery({
    queryKey: [NOTIFICATIONS_QUERY_KEY],
    queryFn: () => getNotifications(),
    select: data => data.notifications
  });

export default useNotifications;
