/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import * as React from 'react';

import Container from '../../../../components/core/Container';
import FlexBox from '../../../../components/core/FlexBox';
import { NumericField } from '../../../../components/core/forms/fields';
import { DollarIcon } from '../../../../components/core/icons';
import Text from '../../../../components/core/Text';
import { OpportunityCoveragesTier, PAFOpportunity } from '../../../../interfaces/IOpportunity';
import { coverageByKey, PolicyCoverageKey } from '../../../../interfaces/IPolicyCoverage';
import colors from '../../../../theme/colors';
import { sizes, spacings } from '../../../../theme/variables';
import { moneyFormatter } from '../../../../utils/formatter';
import { ConfigurationComponentProps } from '../_interfaces/IConfigurationComponentProps';
import { IQuotingConfigurationFormValues } from '../_interfaces/IQuotingConfigurationOpportunity';
import { STUB_PAF_OPPORTUNITY_ID } from '../_utils';

const LEFT_COLUMN_SIZE = sizes.mediumLabelSize;
const GAP_SIZE = spacings.px12;
const SELECT_PADDINGS = 11;

const StubPAFCoverage = () => {
  const { values, setFieldValue } = useFormikContext<IQuotingConfigurationFormValues>();
  const selectedCoverages = values.opportunities[STUB_PAF_OPPORTUNITY_ID]!
    .agent_selected_coverages as PAFOpportunity['agent_selected_coverages'];

  const customJewelryCoverage = coverageByKey(selectedCoverages?.[0]?.coverages, PolicyCoverageKey.Jewelry);

  return (
    <Container pv={spacings.px4}>
      <Text bold>Quoting coverage</Text>
      <FlexBox alignItemsCenter>
        <NumericField
          inline
          label="Coverage level"
          id={`opportunities.${STUB_PAF_OPPORTUNITY_ID}.agent_selected_coverages`}
          name={`opportunities.${STUB_PAF_OPPORTUNITY_ID}.agent_selected_coverages`}
          valueIsNumericString
          prefixIcon={<DollarIcon />}
          value={customJewelryCoverage?.limit_value}
          onValueChange={({ value }) =>
            setFieldValue(`opportunities.${STUB_PAF_OPPORTUNITY_ID}.agent_selected_coverages`, [
              {
                asset_gid: null,
                coverages_tier: OpportunityCoveragesTier.Quoted,
                coverages: [
                  {
                    key: PolicyCoverageKey.Jewelry,
                    limit_value: value,
                    limit_option_gid: null,
                    deductible_value: null
                  }
                ]
              }
            ] satisfies ConfigurationComponentProps['opportunity']['agent_selected_coverages'])
          }
        />
      </FlexBox>
      <FlexBox
        mt={spacings.px16}
        customCss={css`
          flex: 1;
        `}
      >
        <div
          css={css`
            min-width: ${LEFT_COLUMN_SIZE + SELECT_PADDINGS + GAP_SIZE}px;
          `}
        />
        <FlexBox
          customCss={css`
            flex: 1;
          `}
        >
          <Grid container columnSpacing={1} rowSpacing={1} columns={5}>
            <Grid item xs={1}>
              <FlexBox gap={spacings.px4} columnDirection>
                <Text type="small" color={colors.grey60}>
                  Coverage
                </Text>
                <Text type="small">{moneyFormatter(customJewelryCoverage?.limit_value, true) || '—'}</Text>
              </FlexBox>
            </Grid>
            <Grid item xs={1}>
              <FlexBox gap={spacings.px4} columnDirection>
                <Text type="small" color={colors.grey60}>
                  Deductible
                </Text>
                <Text type="small">$0</Text>
              </FlexBox>
            </Grid>
          </Grid>
        </FlexBox>
      </FlexBox>
    </Container>
  );
};

export default StubPAFCoverage;
